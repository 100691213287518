import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../../locales/types';
import { ImportErrorLogProps } from '../import.card.types';

export const ImportErrorLog = (props: ImportErrorLogProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className='log'>
            {!!props.errorMessages && props.errorMessages.length > 0 &&
                <div>
                    {t(LocalizationKeys.Errors)}
                    <br />
                    {<ul>{<li>{props.errorMessages[0]}
                        <ul>
                            {props.errorMessages.slice(1).map((error, errorIndex) => <li key={errorIndex}>{error}</li>)}
                        </ul></li>}
                    </ul>}
                    <br />
                </div>}
        </div>
    );
}