import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { InlineSelect } from '../../ui-elements/inline-select';
import { InlineText } from '../../ui-elements/inline-text';
import { useDesignAreaInformation } from './design-area.hooks';
import { DesignAreaInformationProps } from './design-area.types';

export const DesignAreaInformation = (props: DesignAreaInformationProps): JSX.Element | null => {
    const { t } = useTranslation();
    const { designArea, countries, regions, onUpdate } = props;
    const {
        isDescriptionValid,
        isProjectIdValid,
        isValidCity,
        schrodingerMode,
        isNameValid,
        updateDesignAreaInformationWithValidation,
        validateProjectId,
        validateDescription,
        validateCity,
        validateName,
    } = useDesignAreaInformation(props);

    return (
        <React.Fragment>
            <InlineText
                id="designAreaName"
                label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.DesignAreaNameAbbr) })}
                value={designArea.name ?? ""}
                onUpdate={(value): void => updateDesignAreaInformationWithValidation(value, "name", validateName)}
                error={!isNameValid}
                errorMessage={t(LocalizationKeys.DesignAreaInfoEmptyPropertyError)}
                align={'right'}
            />
            <InlineText
                id="designAreaProjectId"
                label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.DesignAreaProjectId) })}
                value={designArea.projectId ?? ""}
                onUpdate={(value): void => updateDesignAreaInformationWithValidation(value, "projectId", validateProjectId)}
                error={!isProjectIdValid}
                errorMessage={!isProjectIdValid ? t(LocalizationKeys.DesignAreaInfoEmptyPropertyError) : undefined}
                align={'right'}
            />
            <InlineText
                id="designAreaDescription"
                label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.Description) })}
                value={designArea.description ?? ""}
                onUpdate={(value): void => updateDesignAreaInformationWithValidation(value, "description", validateDescription)}
                error={!isDescriptionValid}
                errorMessage={!isDescriptionValid ? t(LocalizationKeys.DesignAreaInfoEmptyPropertyError) : undefined}
                align={'right'}
            />
            <InlineSelect
                key="designAreaCountry"
                label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.Country) })}
                value={designArea.country ?? ""}
                values={countries}
                onUpdate={(value): void => onUpdate(value, "country")}
            />
            <InlineSelect
                key="designAreaRegion"
                label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.State) })}
                value={regions.includes(designArea.region) ? designArea.region : regions[0]}
                values={regions}
                onUpdate={(value): void => onUpdate(value, "region")}
            />
            <InlineText
                id="designAreaCity"
                label={schrodingerMode ? t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.City) }) : t(LocalizationKeys.City)}
                value={designArea.city ?? ""}
                onUpdate={(value): void => updateDesignAreaInformationWithValidation(value, "city", validateCity)}
                error={schrodingerMode && !isValidCity}
                errorMessage={schrodingerMode && !isValidCity ? t(LocalizationKeys.DesignAreaInfoEmptyPropertyError) : undefined}
                align={'right'}
            />
            <InlineText
                id="designAreaZipCode"
                label={t(LocalizationKeys.ZipCode)}
                value={designArea.zipCode ?? ""}
                onUpdate={(value): void => onUpdate(value, "zipCode")}
                align={'right'}
            />
        </React.Fragment>
    );
};