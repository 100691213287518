import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { FlexNapPretermBuildData } from '../data/preterm-build-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class PretermParentFibersProvided implements Validator<FlexNapPretermBuildData> {

    validate(entity: FlexNapPretermBuildData): ValidationResult | null {
        const parentNapPretermConnectedFibers = entity.parentBuild.naps.find(n => n.id === entity.pretermBuild.pretermLateral?.parentNapId)?.taps
            .flatMap(t => t.tethers.filter(tether => !tether.terminal).map(tether => tether.fiberCount)).reduce((a, b) => a + b, 0) ?? 0;

        const fibersUsedByPreterm = entity.pretermBuild.naps.flatMap(t => t.taps.flatMap(t => t.tethers.map(tether => tether.fiberCount))).reduce((a, b) => a + b, 0) ?? 0;

        // Check if the Parent Nap provides enough fibers for all Naps in the preterm build.
        const hasEnoughFibers = parentNapPretermConnectedFibers >= fibersUsedByPreterm;
        if (!hasEnoughFibers) {
            return this.validationResult(ValidationResultType.Error, entity.pretermBuild, i18next.t(LocalizationKeys.PretermLateralInvalidParentFibers, { buildId: entity.pretermBuild.id }));
        }

        return null;
    }

    canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapPretermBuildData && !!entity.pretermBuild.pretermLateral;
    }

    private validationResult(resultType: ValidationResultType, build: FlexNapBuildData, message: string): ValidationResult {
        return {
            buildId: build.id,
            itemId: null,
            itemType: ItemType.Build,
            shortDescription: message,
            type: resultType,
            extent: build.getExtent()
        }
    }
}