/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { DataValue } from '../../../models/datavalue';
import { StateModel } from '../../../redux/reducers';
import { FIXED_SLACK_LOCATION_SPAN_FEET } from '../../../helpers/cable-calculations-helper';
import { Units } from '../../../models/units';
import { CableLengthProps } from './cable-length-properties.types';

export const useCableLengthProperties = (props: CableLengthProps) => {
    const { build, isPreterm, fieldSlackUpdateProps, disabled } = props;
    const workspace = useSelector((state: StateModel) => state);
    const { displayUnits } = workspace.authentication;

    const [coSlackLabel, setCoSlackLabel] = useState(new DataValue(build.coSlack, build.slackUnit, 2).toUnit(displayUnits).value);
    const [fieldSlackValue, setFieldSlackValue] = useState(new DataValue(build.fieldSlack, build.slackUnit, 2).toUnit(displayUnits));
    const [slackLocationSpan, setSlackLocationSpan] = useState(isPreterm ? new DataValue(FIXED_SLACK_LOCATION_SPAN_FEET, Units.feet, 2).toUnit(displayUnits).value : undefined);

    useEffect(() => {
        setCoSlackLabel(new DataValue(build.coSlack, build.slackUnit, 2).toUnit(displayUnits).value);
    }, [build.coSlack, build.slackUnit, displayUnits]);

    useEffect(() => {
        const newValue = new DataValue(build.fieldSlack, build.slackUnit).toUnit(displayUnits);
        setFieldSlackValue(newValue);
    }, [build.fieldSlack, build.slackUnit, displayUnits]);

    useEffect(() => {
        setSlackLocationSpan(isPreterm ? new DataValue(FIXED_SLACK_LOCATION_SPAN_FEET, Units.feet).toUnit(displayUnits).value : undefined);
    }, [isPreterm, displayUnits]);

    return {
        coSlackLabel, fieldSlackValue, slackLocationSpan,
        fieldSlackUpdateProps, displayUnits, disabled
    };
}