export { };

declare global {
    interface String {
        toTitleCase(): string;
    }
}

// eslint-disable-next-line
String.prototype.toTitleCase = function (this: string): string {
    const words = this.split(' ');
    let result = "";
    for (const w of words) {
        const firstCharRemoved = w.substring(1);
        result += (w.charAt(0).toUpperCase() + firstCharRemoved + " ");
    }
    return result.trimEnd();
}