import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { csticAliases } from '../../../../locales/cstic-aliases';
import { LocalizationKeys } from '../../../../locales/types';
import { ConfigSession } from '../../../../models/config-session';
import { Cstic } from '../../../../models/cstic';
import { Material } from '../../../../models/material';
import { StateModel } from '../../../../redux/reducers';
import { ProgressData } from '../../../ui-elements/progress-indicator/progress-indicator.types';
import { PresetManagerContext } from '../../preset-manager.types';
import {
    PresetContext, PresetWizardCstics, PresetWizardNonEditableCstics, PresetWizardStepMaterials,
    PresetWizardSteps
} from '../preset-config/preset-config.types';

export const usePresetWizard = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState<PresetWizardSteps>(PresetWizardSteps.AttributeSelection);
    const [stepTitle, setStepTitle] = useState('');

    const [progressData, setProgressData] = useState<ProgressData[]>([]);
    const [stepCstics, setStepCstics] = useState<Cstic[] | undefined>();

    const presets = useSelector((state: StateModel) => state.schrodingerpreset.presets);

    const { session, loading, updateSession } = useContext(PresetContext);
    const { updateConfigMode } = useContext(PresetManagerContext)


    const filterWizardMaterials = (m: Material, step: PresetWizardSteps): boolean | undefined => {
        return PresetWizardStepMaterials[step]?.includes(m.id);
    };

    const createProgressData = useCallback((session: ConfigSession): ProgressData[] => {
        const cstics = session.materials.filter(m => filterWizardMaterials(m, PresetWizardSteps.AttributeSelection)).flatMap((m) => m.cstics);
        const csticIndex = cstics.findIndex((c) => !c.valueId && c.values?.length > 1);
        const progress = (csticIndex < 0 ? cstics : cstics.slice(0, csticIndex))
            .filter((c) => c.valueId && c.values?.length > 1)
            .map((c) => { return { id: c.id, title: csticAliases[c.id] ?? c.description, value: c.values.find((v) => v.id === c.valueId)?.shortDescription ?? '' } });

        if (step > PresetWizardSteps.PowerTetherAttributes) {
            const powerMaximumCstic = session.materials.filter(m => filterWizardMaterials(m, PresetWizardSteps.PowerTetherAttributes))?.flatMap(m => m.cstics).find(c => c.id === PresetWizardCstics.POWER_MAXIMUM);
            const powerTetherValue = powerMaximumCstic?.values.find(v => v.id === powerMaximumCstic.valueId)?.shortDescription;
            if (powerTetherValue) {
                progress.push({
                    id: PresetWizardSteps.PowerTetherAttributes.toString(),
                    title: t(LocalizationKeys.PowerTether),
                    value: powerTetherValue
                });
            }
        }

        if (step > PresetWizardSteps.OpticalTetherAttributes) {
            const fiberCountCstic = session.materials.filter(m => filterWizardMaterials(m, PresetWizardSteps.OpticalTetherAttributes))?.flatMap(m => m.cstics).find(c => c.id === PresetWizardCstics.TETHER_FIBER_COUNT);
            const opticalTetherValue = fiberCountCstic?.values.find(v => v.id === fiberCountCstic.valueId)?.shortDescription;
            if (opticalTetherValue) {
                progress.push({
                    id: PresetWizardSteps.OpticalTetherAttributes.toString(),
                    title: t(LocalizationKeys.OpticalTether),
                    value: opticalTetherValue
                });
            }
        }

        if (step > PresetWizardSteps.SlackSelection) {
            progress.push({
                id: PresetWizardSteps.SlackSelection.toString(),
                title: t(LocalizationKeys.Slack),
                value: session.coSlack.valueId ?? ''
            });
        }

        return progress;
    }, [step, t]);

    useEffect(() => {
        if (!session) {
            return;
        }
        const cstics = session.materials.filter(m => filterWizardMaterials(m, step))
            .flatMap((m) => m.cstics.filter(c =>
                step !== PresetWizardSteps.PropertiesValidation && !PresetWizardNonEditableCstics.some(nec => c.id === nec)
            )
            );

        if ((!cstics || cstics.length === 0)
            && (step === PresetWizardSteps.PowerTetherAttributes || step === PresetWizardSteps.OpticalTetherAttributes)) {
            setStep(step + 1);
            return;
        }

        const progressData = createProgressData(session);
        switch (step) {
            case PresetWizardSteps.AttributeSelection: {
                const cstic = cstics.find((c) => !c.valueId);
                if (cstic) {
                    progressData.push({ id: cstic.id, title: csticAliases[cstic.id] ?? cstic.description, value: '' });
                    setStepCstics([cstic]);
                } else {
                    setStep(step + 1);
                }
                break;
            }
            case PresetWizardSteps.PowerTetherAttributes:
                setStepTitle(t(LocalizationKeys.PowerTether));
                setStepCstics(cstics);
                progressData.push({ id: PresetWizardSteps.PowerTetherAttributes.toString(), title: stepTitle, value: '' });
                break;
            case PresetWizardSteps.OpticalTetherAttributes:
                setStepTitle(t(LocalizationKeys.OpticalTether));
                setStepCstics(cstics);
                progressData.push({ id: PresetWizardSteps.OpticalTetherAttributes.toString(), title: stepTitle, value: '' });
                break;
            case PresetWizardSteps.SlackSelection:
                setStepTitle(t(LocalizationKeys.Slack));
                progressData.push({ id: PresetWizardSteps.SlackSelection.toString(), title: stepTitle, value: '' });
                break;
            case PresetWizardSteps.PropertiesValidation:
            default:
                progressData.push({ id: PresetWizardSteps.PropertiesValidation.toString(), title: t(LocalizationKeys.CablePreset), value: '' });
                break;
        }

        setProgressData(progressData);
    }, [t, session, step, stepTitle, createProgressData]);

    const goToProperties = useCallback(() => {
        setProgressData([...progressData, { id: 'cable-preset', title: t(LocalizationKeys.CablePreset), value: '' }]);
        setStep(PresetWizardSteps.PropertiesValidation);
    }, [t, progressData]);

    const cancelPreset = useCallback(() => {
        updateConfigMode();
    }, [updateConfigMode]);

    const goToNextStep = useCallback(() => {
        setStep(step + 1);
    }, [step, setStep]);

    const goToPreviousStep = useCallback(async () => {
        const previousStep = step - 1;
        if (previousStep === PresetWizardSteps.AttributeSelection && session) {
            const pd = progressData[progressData.length - 2];
            const previousCstic = session?.materials.filter(m => filterWizardMaterials(m, PresetWizardSteps.AttributeSelection))?.flatMap(m => m.cstics).find(c => c.id === pd.id);
            if (previousCstic) {
                await updateSession(previousCstic.instanceId, previousCstic.id, '');
            }
        }
        setStep(previousStep);
    }, [step, progressData, session, setStep, updateSession]);

    const handleStepClick = useCallback(async (event: React.MouseEvent<any, MouseEvent>) => {
        if (!session) {
            return;
        }

        const id = event.currentTarget.id;
        if (Number.isInteger(+id)) {
            setStep(+id);
            return;
        }

        const attributeSelectionCstics = session.materials.filter(m => filterWizardMaterials(m, PresetWizardSteps.AttributeSelection)).flatMap((m) => m.cstics);
        const indexOfClickedCstic = progressData.findIndex(pd => pd.id === id);
        for (const pd of progressData.slice(indexOfClickedCstic)) {
            if (Number.isNaN(+pd.id)) {
                const pdCstic = attributeSelectionCstics.find(c => c.id === pd.id);
                if (pdCstic) {
                    await updateSession(pdCstic.instanceId, pdCstic.id, '');
                }
            } else {
                break;
            }
        }
        setStep(PresetWizardSteps.AttributeSelection);
    }, [session, progressData, updateSession]);

    return {
        step, stepTitle, stepCstics, loading, progressData, presets,
        handleStepClick, goToNextStep, goToPreviousStep,
        goToProperties, cancelPreset
    };
};