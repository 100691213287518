import { BuildType } from '../models/build-type';
import { BoreTool } from './bore-tool';
import { CabinetTool } from './cabinet-tool';
import { DesignAreaTool } from './design-area-tool';
import { FlexNAPCableTool } from './flexnap-cable-tool';
import { HandholeTool } from './handhole-tool';
import { ManholeTool } from './manhole-tool';
import { ModificationTool } from './modification-tool';
import { NAPTool } from './nap-tool';
import { PoleTool } from './pole-tool';
import { SchrodingerCableTool } from './schrodinger-cable-tool';
import { TapTool } from './schrodinger/tap-tool';
import { BulkCableTool } from './bulk-cable-tool';
import { SplicePointTool } from './splice-point-tool';
import { SelectionTool } from './selection-tool';
import { ToolType } from './tooltype';
import { TrenchTool } from './trench-tool';
import { VaultTool } from './vault-tool';

export interface ToolDefinition {
    toolType: ToolType;
    name: string;
    shortcut: string;
}

const PathTools = [TrenchTool.definition, BoreTool.definition];
const NetworkTools = [CabinetTool.definition, PoleTool.definition, ManholeTool.definition, HandholeTool.definition, VaultTool.definition];

const FlexNapTools = [
    [SelectionTool.definition],
    [ModificationTool.definition],
    [FlexNAPCableTool.definition, NAPTool.definition],
    [...PathTools, ...NetworkTools],
    [DesignAreaTool.definition],
];

const SchrodingerTools = [
    [SelectionTool.definition],
    [ModificationTool.definition],
    [SchrodingerCableTool.definition, TapTool.definition],
    [...PathTools, ...NetworkTools],
    [DesignAreaTool.definition],
];

const BulkTools = [
    [SelectionTool.definition],
    [ModificationTool.definition],
    [BulkCableTool.definition, SplicePointTool.definition],
    [...PathTools, ...NetworkTools],
    [DesignAreaTool.definition],
];

export const getTools = (buildType: BuildType): ToolDefinition[][] => {
    switch (buildType) {
        case BuildType.Schrodinger:
            return SchrodingerTools;
        case BuildType.Bulk:
            return BulkTools;
        case BuildType.FlexNap:
        default:
            return FlexNapTools;
    }
}
