import React from 'react';

export const ManholeIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect x="1.5" y="1.5" width="21" height="17" fill="none" stroke="#005293" strokeWidth="3" />
            <text x="4" y="14" fill="#005293" fontSize="10" fontFamily="'TheSansC4s', Arial">MH</text>
            <path transform="translate(6 12)" fill="#005293" d="M6,5.415c2,0,3.336.04,4.74,0C11.137,5.364,12,4.691,12,6A6,6,0,0,1,0,6c0-1.147.328-.52,1.2-.585C1.94,5.3,3.833,5.415,6,5.415Z" />
        </svg>
    );
};
