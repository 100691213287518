import { createSelector } from '@reduxjs/toolkit';

import { Nap } from '../models/nap';
import { napsSelector, segmentsSelector, selectedNapIdSelector } from './root.selectors';

export const selectedNapSelector = createSelector(
    selectedNapIdSelector,
    napsSelector,
    (napId, naps) =>
        naps.find((t: Nap) => t.id === napId)
)

export const selectedNapBuildOrderedElementIdsSelector = createSelector(
    selectedNapSelector,
    segmentsSelector,
    (selectedNap, segments) =>
        [...new Set(segments
            .filter((s) => !!selectedNap && s.buildId === selectedNap.buildId)
            .sort((a, b) => a.position - b.position)
            .flatMap(s => [s.fromId, s.toId]))
        ]
);

/**
 * Get all Naps (ordered by sequence in the build) from the same build as the selected Nap
 */
export const selectedNapOrderedSiblingsSelector = createSelector(
    selectedNapSelector,
    napsSelector,
    selectedNapBuildOrderedElementIdsSelector,
    (selectedNap, naps, orderedElementIds) =>
        naps.filter((t: Nap) => selectedNap && !!t.buildId && t.buildId === selectedNap.buildId)
            .sort((a, b) => orderedElementIds.indexOf(a.elementId) - orderedElementIds.indexOf(b.elementId)) // sort by the order in which they appear in the build
)