import './role-management-tool.scss';

import React from 'react';

import { Card, CardContent } from '@mui/material';
import { Radio, RadioGroup } from '@orbit/radio';

import CorningRoles from '../../redux/corning-roles.json';
import { useRoleManagementTool } from './role-management-tool.hooks';

export const RoleManagementTool = (): JSX.Element => {
    const { currentSelectedProductRole, handleRoleChange } = useRoleManagementTool();
    return (
        <Card className="role-management-tool-card">
            <CardContent>
                <RadioGroup
                    label="Select your roles:"
                    name="Flexnap-role"
                    value={currentSelectedProductRole}
                    onChange={handleRoleChange}
                    row
                >
                    <Radio key="flexnap-role" label="FlexNap Designer" value={CorningRoles.FlexNAP} checked={currentSelectedProductRole === CorningRoles.FlexNAP}></Radio>
                    <Radio key="schrodinger-role" label="Schrodinger Designer" value={CorningRoles.Schrodinger} checked={currentSelectedProductRole === CorningRoles.Schrodinger}></Radio>
                </RadioGroup>
            </CardContent>
        </Card>
    );
};

