import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@mui/material';

import { LocalizationKeys } from '../../locales/types';
import { ControlType } from '../../redux/overlay.state';
import { RemoveGisData } from '../remove-gis-data/remove-gis-data';
import { LayersMenu } from '../ui-elements/layers-menu';
import { BadgeType, ToolItem } from '../ui-elements/tool-item';
import { useGISMenuBar } from './gis-menu-bar.hooks';
import { GISMoreOptionsMenu } from './gis-more-options';
import buildInReviewIcon from './icons/icon_build_in_review.svg';
import infoIcon from './icons/icon_info.svg';
import layersIcon from './icons/icon_layers.svg';
import mapIcon from './icons/icon_map.svg';
import moreIcon from './icons/icon_more.svg';
import presetsIcon from './icons/icon_presets.svg';
import { NotificationPopup } from './notification-popup';
import { SearchComponent } from './search.component';

export const GisMenuBar = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        control,
        layers,
        notifications,
        simpleMap,
        reviewNotificationsCount,
        showReviews,
        showBuildsInReview,
        toggleMap,
        showPresets,
        showLayers,
        showMoreActions,
        showWarnings,
        onLayerItemSelected,
        onLayerGroupSelected
    } = useGISMenuBar();


    const renderLayersMenu = (): JSX.Element | null => {
        const isLayersMenu = control === ControlType.Layers;
        if (!isLayersMenu || !layers) {
            return null;
        }
        return (
            <ClickAwayListener onClickAway={showLayers}>
                <div>
                    <LayersMenu items={layers} visible={isLayersMenu} onLayerItemSelected={onLayerItemSelected} onLayerGroupSelected={onLayerGroupSelected} onCloseMenu={showLayers} />
                </div>
            </ClickAwayListener>
        );
    }

    const errorsAndWarningsText = t(LocalizationKeys.ErrorsAndWarnings);
    const buildReviewText = t(LocalizationKeys.BuildReview);
    const sateliteViewText = t(LocalizationKeys.SatelliteView);
    const mapViewText = t(LocalizationKeys.MapView);
    const presetText = t(LocalizationKeys.Preset);
    const layersText = t(LocalizationKeys.Layers);
    const moreOptionsText = t(LocalizationKeys.MoreOptions);

    const mapText = simpleMap ? sateliteViewText : mapViewText;

    return (
        <div className="menu-bar-container">
            <div className="menu-bar">
                <SearchComponent enabled={control === undefined} />
                <ToolItem source={<img src={infoIcon} alt={errorsAndWarningsText} />} active={control === ControlType.Warnings} badge={{ number: notifications?.length, type: BadgeType.Warning }} onClick={showWarnings} tooltip={errorsAndWarningsText.toLowerCase()} />
                {showReviews && <ToolItem source={<img src={buildInReviewIcon} alt={buildReviewText} />} active={control === ControlType.Review} badge={{ number: reviewNotificationsCount, type: BadgeType.Warning }} onClick={showBuildsInReview} tooltip={buildReviewText.toLowerCase()} />}
                <ToolItem source={<img src={mapIcon} alt={mapText} />} onClick={toggleMap} tooltip={mapText.toLowerCase()} />
                <ToolItem source={<img src={presetsIcon} alt={presetText} />} onClick={showPresets} tooltip={presetText.toLowerCase()} />
                <ToolItem source={<img src={layersIcon} alt={layersText} />} active={control === ControlType.Layers} onClick={showLayers} tooltip={layersText.toLowerCase()} />
                <ToolItem source={<img src={moreIcon} alt={moreOptionsText} />} active={control === ControlType.More} onClick={showMoreActions} tooltip={moreOptionsText.toLowerCase()} />
            </div>
            <GISMoreOptionsMenu open={control === ControlType.More} />
            <NotificationPopup />
            {renderLayersMenu()}
            <RemoveGisData />
        </div>
    );
};
