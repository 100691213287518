
import { ZoomToolProps } from "./zoom-tool.types"

export const useZoomToolComponent = (props: ZoomToolProps) =>  {
    const { map } = props;
    const zoomIn = (): void => {
        handleZoom(true)
    }
    const zoomOut = (): void => {
        handleZoom(false)
    }
    const handleZoom = (zoomIn: boolean) => {
        if (map) {
            const view = map.getView();
            const currentZoom = view.getZoom() || 1;
            const value = currentZoom + (zoomIn ? 1 : -1);
            const animation = {
                duration: 300,
                zoom: value,
            };
            view.animate(animation);
        }
    }

    return {zoomIn, zoomOut}
}
