import { ModifyEvent } from 'ol/interaction/Modify';

import { store } from '../dna';
import { PointHelper } from '../helpers/point-helper';
import { SegmentHelper } from '../helpers/segment-helper';
import { Bore } from '../models/bore';
import { MapItemDefinition } from '../models/map-item-definition';
import { ModifiableNode } from '../models/modifiable-node';
import { Path } from '../models/path';
import { PathType } from '../models/path-type';
import { PointLike } from '../models/pointlike';
import { Trench } from '../models/trench';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { LayerFactory } from '../openlayers/openlayer-layer.factory';
import { updateBore } from '../redux/bore.state';
import { updatePathCoordinates } from '../redux/path.actions';
import { updateSelectedVertex } from '../redux/selection.state';
import { updateTrench } from '../redux/trench.state';
import { Modifiable } from './modifiable';

export class ModifyPathTool implements Modifiable {
    private PERIMETER_RANGE_LIMIT_PIXEL = 3;
    private MIN_LINESTRING_LENGTH = 2;

    protected moveToLayerTypes = new Set<string>([
        LayerFactory.LAYER_HANDHOLES,
        LayerFactory.LAYER_MANHOLES
    ]);

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
    public modifyStart() { }

    public modifyEnd(event: ModifyEvent): void {
        const { map, coordinate: modifyEndCoordinate } = event.mapBrowserEvent;
        const { id, type, geometry } = event.features.getArray()?.[0].getProperties();
        const path = this.getPath(id, type);
        if (!path) {
            return;
        }
        const route = geometry.getCoordinates().map(([x, y]) => ({ x, y }));
        const firstPoint: PointLike = route[0];
        const lastPoint: PointLike = route.slice(-1)[0];
        
        // If the first or last node of the path is being moved to a target that is not a manhole or handhole, cancel the modification
        const firstTarget = PointHelper.findConnectedFeaturesFromCoordinates(map, [firstPoint], this.moveToLayerTypes);
        const lastTarget = PointHelper.findConnectedFeaturesFromCoordinates(map, [lastPoint], this.moveToLayerTypes);
        if (!firstTarget.length || !lastTarget.length || !firstTarget[0].elementCoordinates || !lastTarget[0].elementCoordinates || firstTarget[0].id === lastTarget[0].id) {
            this.resetPath(path, type);
            return;
        }

        // If the path contains a build or terminal segment, and the first or last node of the path were modified, cancel the modification
        if (this.selectedPathContainsSegment()) {
            if (
                    (modifyEndCoordinate[0] === firstPoint.x && modifyEndCoordinate[1] === firstPoint.y) ||
                    (modifyEndCoordinate[0] === lastPoint.x && modifyEndCoordinate[1] === lastPoint.y)
               ) {
                this.resetPath(path, type);
                return;
            }
        }

        // If the end of a path is dragged next close to a MH/HH, let the end snap to its coordinates instead of staying on an empty area of the map
        firstPoint.x = firstTarget[0].elementCoordinates.x;
        firstPoint.y = firstTarget[0].elementCoordinates.y;
        lastPoint.x = lastTarget[0].elementCoordinates.x;
        lastPoint.y = lastTarget[0].elementCoordinates.y;
        
        updatePathCoordinates(path, route, firstTarget[0].id, lastTarget[0].id)(store.dispatch);
    }

    public deleteNode(node: MapItemDefinition): boolean {
        const { id, type } = node;
        const path = this.getPath(id, type);
        const { selectedVertex } = store.getState().selection;

        if (!selectedVertex || !path) {
            return false;
        }

        const route = path.route.filter((p) => 
            Math.floor(Math.abs(p.x - selectedVertex.x)) > this.PERIMETER_RANGE_LIMIT_PIXEL ||
            Math.floor(Math.abs(p.y - selectedVertex.y)) > this.PERIMETER_RANGE_LIMIT_PIXEL
        );
        
        updatePathCoordinates(path, route, path.fromElementId, path.toElementId)(store.dispatch);        
        return true;
    }

    public getModifiableNode(features: MapItemDefinition[], point?: PointLike): ModifiableNode | undefined {
        if (!point) {
            return undefined;
        }

        const featureAndRoute = this.getSelectedFeatureAndRoute(features);
        if (!featureAndRoute) {
            return undefined;
        }
        const { feature: mapItem, route } = featureAndRoute;

        const pointInRoute = route
            .find((p) => Math.floor(Math.abs(p.x - point.x)) <= this.PERIMETER_RANGE_LIMIT_PIXEL
                && Math.floor(Math.abs(p.y - point.y)) <= this.PERIMETER_RANGE_LIMIT_PIXEL);
        if (pointInRoute) {
            let canDelete = route.length > this.MIN_LINESTRING_LENGTH;
            if (mapItem.type === InteractableFeatureType.TRENCH || mapItem.type === InteractableFeatureType.BORE) {
                const pointInRouteIndex = route.findIndex(p => p.x === pointInRoute.x && p.y === pointInRoute.y);
                canDelete = pointInRouteIndex !== 0 && pointInRouteIndex !== route.length - 1;
            }

            store.dispatch(updateSelectedVertex(point));
            return {
                mapItem,
                canDelete,
            };
        }
    }

    private getPath(id: number, type: string): Path | undefined {
        const state = store.getState();
        
        if (type === InteractableFeatureType.BORE) {
            const { bores } = state.bore;
            return bores.find((b) => b.id === id);
        }

        if (type === InteractableFeatureType.TRENCH) {
            const { trenches } = state.trench;
            return trenches.find((t) => t.id === id);
        }

        return undefined;
    }

    private getSelectedPath(): Path | undefined {
        const state = store.getState();
        const { selectedBoreId, selectedTrenchId } = state.selection;
        
        if (selectedBoreId) {
            const { bores } = state.bore;
            return bores.find((b) => b.id === selectedBoreId);
        }

        if (selectedTrenchId) {
            const { trenches } = state.trench;
            return trenches.find((t) => t.id === selectedTrenchId);
        }
        
        return undefined;
    }
    
    private selectedPathContainsSegment(): boolean {
        const selectedPath = this.getSelectedPath();

        const state = store.getState();
        const { segments } = state.build;
        const terminalSegments = state.taps.tethers.flatMap(t => t.terminal?.terminalExtension?.segments ?? []);
        
        return !!selectedPath && (!!SegmentHelper.getBuildSegmentsOnPath(selectedPath, segments).length || !!SegmentHelper.getTerminalSegmentsOnPath(selectedPath, terminalSegments).length);
    }

    private getInteractableFeatureType(pathType: PathType): InteractableFeatureType | undefined {
        if (pathType === PathType.Bore) return InteractableFeatureType.BORE;
        if (pathType === PathType.Trench) return InteractableFeatureType.TRENCH;
        return undefined;
    }

    private getSelectedFeatureAndRoute(features: MapItemDefinition[]): { feature: MapItemDefinition; route: PointLike[] } | undefined {
        const path = this.getSelectedPath();
        if (!path) {
            return undefined;
        }

        const featureType = this.getInteractableFeatureType(path.type);
        if (!featureType) {
            return undefined
        }

        const feature = features.find((f) => f.type === featureType && f.id === path.id);
        if (!feature) {
            return undefined;
        }
        const { route } = path;
        return { feature, route };
    }

    private resetPath(path: Path, type: InteractableFeatureType): void {
        switch (type) {
            case InteractableFeatureType.BORE:
                store.dispatch(updateBore({ ...(path as Bore) }));
                break;
            case InteractableFeatureType.TRENCH:
                store.dispatch(updateTrench({ ...(path as Trench) }));
                break;
        }
    }
}
