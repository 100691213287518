import './pixi.scss';

import React from 'react';
import { Provider, useStore } from 'react-redux';

import { Stage } from '@inlet/react-pixi';

import { usePixiComponent } from './pixi.hooks';
import { PixiComponentProps } from './pixi.types';
import { Viewport } from './viewport/viewport';

export const PixiComponent: React.FC<PixiComponentProps> = (props) => {
    const { stageProps, viewportRef } = usePixiComponent(props);
    const store = useStore();
    return (
        <div id="pixi-container">
            <Stage {...stageProps}>
                <Provider store={store}>
                    <Viewport viewportRef={viewportRef}>
                        {props.children}
                    </Viewport>
                </Provider>
            </Stage>
        </div>
    );
}