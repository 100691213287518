import { PluggableMap } from 'ol';
import { Pixel } from 'ol/pixel';

import { store } from '../dna';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { createSchrodingerBuild } from '../redux/build.state';
import { BaseCableTool } from './base-cable-tool';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class SchrodingerCableTool extends BaseCableTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.SchrodingerCable,
        name: 'Faraday',
        shortcut: 'c',
    };

    public static finishCable(elementIds: number[]) {
        const state = store.getState();
        const workspaceId = state.workspace.currentWorkspace?.id;
        if (!workspaceId) {
            throw new Error('Can\'t create a build without a parent workspace');
        }
        const uniqueElementIds = elementIds.distinct();
        const segments: Array<{ position: number; fromId: number; toId: number }> = [];
        for (let i = 1; i < uniqueElementIds.length; i++) {
            const position = i - 1;
            const fromId = uniqueElementIds[i - 1];
            const toId = uniqueElementIds[i];
            segments.push({ position, fromId, toId });
        }
        if (segments.length !== 0) {
            const tapsToAttach = state.tapsSchrodinger.taps
                .filter(({ buildId, elementId}) => !buildId && uniqueElementIds.includes(elementId));
            createSchrodingerBuild(workspaceId, segments, tapsToAttach)(store.dispatch);
        }
    }

    public findAccessPointAtPixel(map: PluggableMap, pixel: Pixel): number {
        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        const { schrodingerBuilds } = build;
        const selectedBuild = schrodingerBuilds.find((b) => b.id === selectedBuildId)!;

        if (selectedBuild) {
            const nodeFeature = map.getFeaturesAtPixel(pixel).find(f => f.get('type') === InteractableFeatureType.TAP_SCHRODINGER);
            if (nodeFeature) {
                return nodeFeature.get('id') as number;
            }
        }

        return super.findAccessPointAtPixel(map, pixel);
    }
}
