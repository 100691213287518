export enum ToolType {
    Selection,
    Modification,
    DesignArea,
    Cabinet,
    DropCable,
    NAP,
    FlexNAPCable,
    Manhole,
    Handhole,
    Vault,
    Trench,
    Bore,
    Pole,
    TapSchrodinger,
    SchrodingerCable,
    BulkCable,
    SplicePoint
}
