import i18next from 'i18next';

import { CableCalculationsHelper } from '../../helpers/cable-calculations-helper';
import { LocalizationKeys } from '../../locales/types';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { SegmentData } from '../data/segment-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class DesignSpanRiserValidator implements Validator<FlexNapBuildData> {

    private displayUnits: Units;

    constructor(units: Units) {
        this.displayUnits = units;
    }

    public validate(build: FlexNapBuildData): ValidationResult | null {
        const segments = build.segments;
        // All computations are forced to be in feet to avoid ambiguity and mismatching
        for (const segment of segments) {
            const designSpan = new DataValue(+(segment.designSpan.value), segment.designSpan.units).toUnit(Units.feet, 2);
            if (segment.riserSpan) {
                const riserSpan = new DataValue(segment.riserSpan.value, segment.riserSpan.units).toUnit(Units.feet);

                if (segment.measuredSpan) { // Prioritize the measured span before the calculated span
                    const measuredSpan = new DataValue(segment.measuredSpan.value, segment.measuredSpan.units).toUnit(Units.feet);
                    const minimum = new DataValue(measuredSpan.value + riserSpan.value, Units.feet, 2);
                    if (designSpan.value < minimum.value) {
                        return this.validationResult(segment, build, i18next.t(LocalizationKeys.DesignSpanRiserShouldBeMin, { minimum: minimum.format(this.displayUnits, 2) }), ValidationResultType.Error);
                    }
                }

                const calculatedSpan = CableCalculationsHelper.getDistanceBetween(segment.from.location, segment.to.location).toUnit(Units.feet);
                if (calculatedSpan) {
                    const minimum = new DataValue(+(calculatedSpan.value + riserSpan.value - 0.001), Units.feet, 2);
                    if (designSpan.value < minimum.value) {
                        return this.validationResult(segment, build, i18next.t(LocalizationKeys.DesignSpanRiserShouldBeMin, { minimum: minimum.format(this.displayUnits, 2) }), ValidationResultType.Error);
                    }
                }
            }
        }
        return null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapBuildData && entity.segments.some(s => !!s.riserSpan); // at least one segment with a riserSpan
    }

    private validationResult(segment: SegmentData, build: FlexNapBuildData, message: string, type: ValidationResultType): ValidationResult {
        const extent = build.getExtent();
        return { buildId: build.id, itemId: segment.id, itemType: ItemType.Segment, shortDescription: message, type, extent };
    }
}