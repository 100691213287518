import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BulkBuild } from '../../models/bulk/bulk-build';
import { FlexNapBuild } from '../../models/flexnap-build';
import {
    loadCabinetSplicePlanFromBuildId, loadCabinetSplicePlanFromCabinetId
} from '../../redux/cabinet-splice-plan.state';
import { flexNapPretermBuildsSelector } from '../../selectors/build.selectors';
import {
    bulkBuildsSelector, bulkSplicePlanSelector, cabinetSplicePlansSelector, flexnapBuildsSelector,
    flexNapSplicePlanSelector, selectedBuildIdSelector, workspaceSelector
} from '../../selectors/root.selectors';

export const CabinetSplicePlanManager = (): null => {

    const workspace = useSelector(workspaceSelector)
    const workspaceId = workspace.currentWorkspace?.id;
    const dispatch = useDispatch();

    const bulkBuilds = useSelector(bulkBuildsSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const cabinetSplicePlan = useSelector(cabinetSplicePlansSelector);
    const pretermBuilds = useSelector(flexNapPretermBuildsSelector);
    const selectedBuildId = useSelector(selectedBuildIdSelector);

    const flexNapSplicePlan = useSelector(flexNapSplicePlanSelector);
    const bulkSplicePlan = useSelector(bulkSplicePlanSelector);
    const [prevFlexNapSplicePlan, setPrevFlexNapSplicePlan] = useState({} as typeof flexNapSplicePlan);
    const [prevBulkSplicePlan, setPrevBulkSplicePlan] = useState({} as typeof bulkSplicePlan);
    const [prevFlexNapBuilds, setPrevFlexNapBuilds] = useState([] as FlexNapBuild[]);
    const [prevBulkBuilds, setPrevBulkBuilds] = useState([] as BulkBuild[]);
    const [prevCabinetSplicePlan, setPrevCabinetSplicePlan] = useState({} as typeof cabinetSplicePlan);
    const [prevPretermBuilds, setPrevPretermBuilds] = useState([] as FlexNapBuild[]);


    const updateCabinetSplicePlanFromBuildId = useCallback((buildId: number) => {
        workspaceId && dispatch(loadCabinetSplicePlanFromBuildId(workspaceId, buildId));
    }, [dispatch, workspaceId]);

    useEffect(() => {
        //check if there are any new flexNap builds in flexNapbuilds compare t prevFlexNapBuilds
        if (prevFlexNapBuilds.length !== flexNapBuilds.length) {
            const newFlexNapBuild = flexNapBuilds.find(n => !prevFlexNapBuilds.includes(n));
            if (newFlexNapBuild) {
                updateCabinetSplicePlanFromBuildId(newFlexNapBuild.id);
            }
            //check for deleted flexNap builds
            const deletedFlexNapBuild = prevFlexNapBuilds.find(n => !flexNapBuilds.includes(n));

            if (deletedFlexNapBuild) {
                // is connected to nap
                if (deletedFlexNapBuild.pretermLateral) {
                    updateCabinetSplicePlanFromBuildId(deletedFlexNapBuild.pretermLateral.parentBuildId);
                }
                // is connected to splice point
                else if (deletedFlexNapBuild.buildSplice) {
                    updateCabinetSplicePlanFromBuildId(deletedFlexNapBuild.buildSplice.parentBuildId);
                }
                //could be at the root of the cabinet
                else {
                    const splicePlan = Object.values(prevCabinetSplicePlan).find(p => p.builds.some(({ buildId }) => buildId === deletedFlexNapBuild.id));
                    if (splicePlan) {
                        workspaceId && dispatch(loadCabinetSplicePlanFromCabinetId(workspaceId, splicePlan.cabinetId));
                    }
                }
            }
        } else {

            //check if the previousFlexNapBuilds State's buildsplice has changed from the one in the currentFlexNapBuilds State
            //This means that the splice point has been added 
            const flexNapBuildWithBuildSpliceChanged = flexNapBuilds.find(b =>
                prevFlexNapBuilds.some(prev =>
                    prev.id === b.id &&
                    b.buildSplice !== prev.buildSplice
                )
            );

            //check if the currentFlexNapBuild State's buildsplice has changed from the one in the previousFlexNapBuilds State
            const flexNapBuildWithBuildSpliceRemoved = prevFlexNapBuilds.find(prev =>
                flexNapBuilds.some(b =>
                    b.id === prev.id &&
                    (b.buildSplice !== prev.buildSplice)
                )
            );

            const flexNapBuildFiberCountChanged = prevFlexNapBuilds.find(prev =>
                flexNapBuilds.some(b =>
                    b.id === prev.id &&
                    (b.fiberCount !== prev.fiberCount)
                )
            );


            if (flexNapBuildWithBuildSpliceChanged?.buildSplice) {
                updateCabinetSplicePlanFromBuildId(flexNapBuildWithBuildSpliceChanged.buildSplice.parentBuildId);
            }

            if (flexNapBuildWithBuildSpliceRemoved?.buildSplice) {
                updateCabinetSplicePlanFromBuildId(flexNapBuildWithBuildSpliceRemoved.buildSplice.parentBuildId);
            }

            if (flexNapBuildFiberCountChanged) {
                // build fiber count was modified, check if is a parent build
                updateCabinetSplicePlanFromBuildId(flexNapBuildFiberCountChanged.id);
            }

        }
        setPrevFlexNapBuilds(flexNapBuilds);
        setPrevCabinetSplicePlan(cabinetSplicePlan);
    }, [dispatch, flexNapBuilds, workspaceId, prevFlexNapBuilds, cabinetSplicePlan, prevCabinetSplicePlan, updateCabinetSplicePlanFromBuildId]);

    useEffect(() => {
        //check if there are any new Bulk builds in Bulkbuilds compare t prevFlexNapBuilds
        if (prevBulkBuilds.length !== bulkBuilds.length) {
            const newBulkBuilds = bulkBuilds.find(n => !prevBulkBuilds.includes(n));
            if (newBulkBuilds) {
                updateCabinetSplicePlanFromBuildId(newBulkBuilds.id);
            }
            //check for deleted bulkBuild builds
            const deletedBulkBuild = prevBulkBuilds.find(n => !bulkBuilds.includes(n));
            if (deletedBulkBuild) {
                //Is not at root of cabinet
                if (deletedBulkBuild.buildSplice) {
                    updateCabinetSplicePlanFromBuildId(deletedBulkBuild.buildSplice.parentBuildId);
                }
                //is at root of cabinet
                else {
                    const splicePlan = Object.values(prevCabinetSplicePlan).find(p => p.builds.some(({ buildId }) => buildId === deletedBulkBuild.id));
                    if (splicePlan) {
                        workspaceId && dispatch(loadCabinetSplicePlanFromCabinetId(workspaceId, splicePlan.cabinetId));
                    }
                }
            }

        } else {
            //check if the previousBulkBuilds State's buildsplice has changed from the one in the currentBulkBuilds State
            //This means that the splice point has been added 
            const bulkBuildWithBuildSpliceChanged = bulkBuilds.find(b =>
                prevBulkBuilds.some(prev =>
                    prev.id === b.id &&
                    b.buildSplice !== prev.buildSplice
                )
            );
            //check if the currentBulkBuild State's buildsplice has changed from the one in the previousBulkBuilds State
            const bulkBuildWithBuildSpliceRemoved = prevBulkBuilds.find(prev =>
                bulkBuilds.some(b =>
                    b.id === prev.id &&
                    (b.buildSplice !== prev.buildSplice)
                )
            );


            //check if the currentBulkBuild State's fibreCount has changed from the one in the previousBulkBuilds
            const bulkBuildFiberCountChanged = bulkBuilds.find(b =>
                prevBulkBuilds.some(prev =>
                    prev.id === b.id &&
                    b.fiberCount !== prev.fiberCount
                )
            );


            if (bulkBuildWithBuildSpliceChanged?.buildSplice) {

                updateCabinetSplicePlanFromBuildId(bulkBuildWithBuildSpliceChanged.buildSplice.parentBuildId);
            }

            if (bulkBuildWithBuildSpliceRemoved?.buildSplice) {
                updateCabinetSplicePlanFromBuildId(bulkBuildWithBuildSpliceRemoved.buildSplice.parentBuildId);
            }

            if (bulkBuildFiberCountChanged?.fiberCount) {
                updateCabinetSplicePlanFromBuildId(bulkBuildFiberCountChanged.id);
            }

        }
        setPrevBulkBuilds(bulkBuilds);
        setPrevCabinetSplicePlan(cabinetSplicePlan);
    }, [dispatch, bulkBuilds, prevBulkBuilds, cabinetSplicePlan, prevCabinetSplicePlan, updateCabinetSplicePlanFromBuildId, workspaceId]);


    //Update Preterm Lateral if it has been added or removed
    useEffect(() => {
        if (Math.abs(pretermBuilds.length - prevPretermBuilds.length) === 1) {
            const newPretermBuild = pretermBuilds.find(ptb => !prevPretermBuilds.includes(ptb));
            if (newPretermBuild && newPretermBuild.pretermLateral) {
                // new preterm was added
                updateCabinetSplicePlanFromBuildId(newPretermBuild.pretermLateral.parentBuildId);
            }
            const deletedPretermBuild = prevPretermBuilds.find(ptb => !pretermBuilds.includes(ptb));
            if (deletedPretermBuild && deletedPretermBuild.pretermLateral) {
                updateCabinetSplicePlanFromBuildId(deletedPretermBuild.pretermLateral.parentBuildId);
            }
        }
        setPrevPretermBuilds(pretermBuilds);
        setPrevFlexNapBuilds(flexNapBuilds);
    }, [dispatch, flexNapBuilds, pretermBuilds, prevFlexNapBuilds, prevPretermBuilds, selectedBuildId, updateCabinetSplicePlanFromBuildId]);


    useEffect(() => {
        //check if starting port has changed
        if (bulkSplicePlan) {
            if (bulkSplicePlan !== prevBulkSplicePlan) {
                updateCabinetSplicePlanFromBuildId(bulkSplicePlan?.buildId);
            }
        }

        if (flexNapSplicePlan) {
            if (flexNapSplicePlan !== prevFlexNapSplicePlan) {
                updateCabinetSplicePlanFromBuildId(flexNapSplicePlan?.buildId);
            }
        }

        setPrevBulkSplicePlan(bulkSplicePlan);
        setPrevFlexNapSplicePlan(flexNapSplicePlan);
    }, [bulkSplicePlan, prevBulkSplicePlan, flexNapSplicePlan, prevFlexNapSplicePlan, updateCabinetSplicePlanFromBuildId]);
    return null;
}