import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CableCalculationsHelper } from '../../../helpers/cable-calculations-helper';
import { LocalizationKeys } from '../../../locales/types';
import { BuildSegment } from '../../../models/build-segment';
import { DataValue } from '../../../models/datavalue';
import { ElementType } from '../../../models/element-type';
import { Units } from '../../../models/units';
import { patchSegment } from '../../../redux/build.state';
import { StateModel } from '../../../redux/reducers';
import { elementsSelector } from '../../../selectors/elements.selectors';
import { pathsSelector } from '../../../selectors/paths.selectors';
import { NotificationService } from '../../../services/notification.service';
import { ValidationResultType } from '../../../validation/validation-result-type';
import {
    elementTypesWithRiser, RISER_SPAN_MINIMUM_FEET, SegmentCardProps, SegmentValidationResult
} from './segment-card.types';

export const useSegmentCard = (props: SegmentCardProps) => {
    const { segment, collapsed, disabled } = props;

    const { t } = useTranslation();

    const elements = useSelector(elementsSelector);
    const paths = useSelector(pathsSelector);
    const displayUnits = useSelector((state: StateModel) => state.authentication.displayUnits);

    const [designSpan, setDesignSpan] = useState<DataValue>(new DataValue(segment.designSpan, segment.designSpanUnit)?.toUnit(displayUnits));
    const [slackLoop, setSlackLoop] = useState<DataValue>(new DataValue(segment.slackLoop, segment.slackUnit));
    const [riserSpan, setRiserSpan] = useState<DataValue>(new DataValue(segment.riserSpan ?? 0, segment.riserSpanUnit ?? displayUnits, 2));
    const [calculatedSpan, setCalculatedSpan] = useState<DataValue | undefined>(CableCalculationsHelper.calculateSegmentSpan(segment, elements, paths)?.toUnit(displayUnits));
    const [measuredSpan, setMeasuredSpan] = useState<DataValue | undefined>(segment.measuredSpan && segment.measuredSpanUnit ? new DataValue(segment.measuredSpan, segment.measuredSpanUnit) : CableCalculationsHelper.measureSegmentSpan(segment, paths)?.toUnit(displayUnits));
    const [designSpanIsValid, setDesignSpanIsValid] = useState(true);
    const [slackLoopIsValid, setSlackLoopIsValid] = useState(true);
    const [riserSpanErrorMsg, setRiserSpanErrorMsg] = useState<string>();

    useEffect(() => {
        setDesignSpan(new DataValue(segment.designSpan, segment.designSpanUnit).toUnit(displayUnits));
    }, [segment.designSpan, segment.designSpanUnit, displayUnits]);

    useEffect(() => {
        setSlackLoop(new DataValue(segment.slackLoop, segment.slackUnit).toUnit(displayUnits));
    }, [segment.slackLoop, segment.slackUnit, displayUnits]);

    useEffect(() => {
        setRiserSpan(new DataValue(segment.riserSpan ?? 0, segment.riserSpanUnit ?? displayUnits, 2).toUnit(displayUnits));
    }, [segment.riserSpan, segment.riserSpanUnit, displayUnits]);

    useEffect(() => {
        setCalculatedSpan(CableCalculationsHelper.calculateSegmentSpan(segment, elements, paths)?.toUnit(displayUnits));
    }, [segment, elements, paths, displayUnits]);

    useEffect(() => {
        setMeasuredSpan(segment.measuredSpan && segment.measuredSpanUnit ? new DataValue(segment.measuredSpan, segment.measuredSpanUnit) : CableCalculationsHelper.measureSegmentSpan(segment, paths)?.toUnit(displayUnits));
    }, [segment.measuredSpan, segment.measuredSpanUnit, paths, segment, displayUnits]);

    const getRiserSpanVisibility = useCallback((): boolean => {
        const elementIds = [segment.fromId, segment.toId];
        const hasRiserElement = !!elements.find(e => !!elementIds.find(id => id === e.id) && !!elementTypesWithRiser.find(elementType => elementType === e.type));
        const hasPole = !!elements.find(e => !!elementIds.find(id => id === e.id) && e.type === ElementType.Pole);
        const canHaveRiserSpan = hasRiserElement && hasPole;
        return canHaveRiserSpan;
    }, [segment, elements]);
    const [riserSpanVisible] = useState(() => getRiserSpanVisibility());

    const dispatch = useDispatch();

    const validateDesignSpan = useCallback((value: number): SegmentValidationResult => {
        const hasUndergroundElementOnFrom = !!elements.find(e => segment.fromId === e.id && (e.type === ElementType.Handhole || e.type === ElementType.Manhole || e.type === ElementType.Vault));
        const hasUndergroundElementOnTo = !!elements.find(e => segment.toId === e.id && (e.type === ElementType.Handhole || e.type === ElementType.Manhole || e.type === ElementType.Vault));
        if (isNaN(value) || Math.sign(value) <= 0) {
            setDesignSpanIsValid(false);
            return { valid: false };
        }
        setDesignSpanIsValid(true);
        if (hasUndergroundElementOnFrom && hasUndergroundElementOnTo) {
            if (measuredSpan) {
                const measuredValue = parseFloat(measuredSpan.value.toFixed(2));
                if (value < measuredValue) {
                    return { valid: false, status: ValidationResultType.Error, measure: t(LocalizationKeys.Measured) };
                }
                if (value > measuredValue) {
                    return { valid: true, status: ValidationResultType.Warning, measure: t(LocalizationKeys.Measured) };
                }
            }
            else if (calculatedSpan) {
                const calculatedValue = parseFloat(calculatedSpan.value.toFixed(2));
                if (value < calculatedValue) {
                    return { valid: false, status: ValidationResultType.Error, measure: t(LocalizationKeys.Calculated) };
                }
                if (value > calculatedValue) {
                    return { valid: true, status: ValidationResultType.Warning, measure: t(LocalizationKeys.Calculated) };
                }
            }
        }
        return { valid: true };
    }, [calculatedSpan, elements, measuredSpan, segment.fromId, segment.toId, t]);

    const validateRiserSpan = useCallback((value: any): boolean => {
        const valueInFeet = new DataValue(parseFloat(value), displayUnits).toUnit(Units.feet);
        if (!valueInFeet.value || Math.sign(value) < 0) {
            setRiserSpanErrorMsg(t(LocalizationKeys.OnlyPositiveValues));
            return false;
        }
        else if (valueInFeet.value < RISER_SPAN_MINIMUM_FEET) {
            const minimumMeasurement = new DataValue(RISER_SPAN_MINIMUM_FEET, Units.feet);
            setRiserSpanErrorMsg(t(LocalizationKeys.ValueNoLessThan, { minimum: minimumMeasurement.format(displayUnits, 2) }));
            return false;
        }
        else {
            setRiserSpanErrorMsg("");
        }
        return true;
    }, [t, displayUnits]);

    const validateSlackLoop = useCallback((value: any): boolean => {
        const valid = Math.sign(value) >= 0;
        setSlackLoopIsValid(valid);
        return valid;
    }, []);

    const handleDesignSpanUpdate = useCallback((value: string): void => {
        const updatedDesignSpan = new DataValue(parseFloat(value), displayUnits);
        const requiresUpdate = segment.designSpan !== updatedDesignSpan.value || segment.designSpanUnit !== displayUnits;
        const { valid: updateValidity, status: validationType, measure } = validateDesignSpan(updatedDesignSpan.value);
        if (!updateValidity && validationType === ValidationResultType.Error) {
            NotificationService.error(t(LocalizationKeys.BuriedDesignSpanError, { measure: measure }));
        }
        if (requiresUpdate && updateValidity) {
            const newSegment: BuildSegment = { ...segment, designSpan: updatedDesignSpan.value, designSpanUnit: displayUnits };
            patchSegment({ oldSegment: segment, newSegment }, true)(dispatch);
            setDesignSpan(updatedDesignSpan);
            if (validationType === ValidationResultType.Warning) {
                NotificationService.warning(t(LocalizationKeys.BuriedDesignSpanWarning, { measure: measure }));
            }
        }
    }, [displayUnits, segment, validateDesignSpan, t, dispatch]);

    const getDesignSpanWithRiser = useCallback((riserSpanValue: number): DataValue | undefined => {
        if (measuredSpan) {
            return new DataValue(measuredSpan.value + riserSpanValue, displayUnits, 2);
        } else if (calculatedSpan) {
            return new DataValue(calculatedSpan.value + riserSpanValue, displayUnits, 2);
        }
        return undefined;
    }, [displayUnits, measuredSpan, calculatedSpan]);

    const handleRiserSpanUpdate = useCallback((value: string): void => {
        const updatedRiserSpan = new DataValue(parseFloat(value), displayUnits);
        const requiresUpdate = segment.riserSpan !== updatedRiserSpan.value || segment.riserSpanUnit !== displayUnits;
        if (validateRiserSpan(updatedRiserSpan.value) && requiresUpdate) {
            const updatedDesignSpanValue = getDesignSpanWithRiser(updatedRiserSpan.value);
            const newSegment: BuildSegment = { ...segment, riserSpan: updatedRiserSpan.value, riserSpanUnit: displayUnits, designSpan: updatedDesignSpanValue?.value ?? segment.designSpan, designSpanUnit: updatedDesignSpanValue?.units ?? segment.designSpanUnit };
            setRiserSpan(updatedRiserSpan);
            if (updatedDesignSpanValue) {
                setDesignSpan(updatedDesignSpanValue);
            }
            patchSegment({ oldSegment: segment, newSegment }, true)(dispatch);
        }
    }, [displayUnits, segment, validateRiserSpan, getDesignSpanWithRiser, dispatch]);

    const handleSlackLoopUpdate = useCallback((value: string): void => {
        const updatedSlackLoop = new DataValue(parseFloat(value), displayUnits);
        const requiresUpdate = segment.slackLoop !== updatedSlackLoop.value || segment.slackUnit !== displayUnits;
        if (validateSlackLoop(updatedSlackLoop.value) && requiresUpdate) {
            patchSegment({ oldSegment: segment, newSegment: { ...segment, slackLoop: updatedSlackLoop.value ?? 0, slackUnit: displayUnits } }, true)(dispatch);
            setSlackLoop(updatedSlackLoop);
        }
    }, [displayUnits, segment, validateSlackLoop, dispatch]);

    return {
        segment, collapsed, slackLoop, slackLoopIsValid, designSpan, displayUnits, designSpanIsValid, calculatedSpan, measuredSpan, riserSpan, riserSpanErrorMsg, riserSpanVisible,
        validateDesignSpan, validateRiserSpan, validateSlackLoop, handleSlackLoopUpdate, handleDesignSpanUpdate, handleRiserSpanUpdate, disabled
    }
}