import './preset-manager.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedButton } from '@orbit/button';

import { LocalizationKeys } from '../../locales/types';
import { useCablePresetConfig } from './cable-preset-config.hooks';
import { CableWizardComponent } from './cable-wizard.component';
import { PartSelectionComponent } from './part-selection.component';
import { PresetConfigComponents } from './preset-manager.types';
import { PresetComponent } from './preset.component';
import { CardInputWithCount } from '../card/card-controls';
import { CardInputMaxLength } from '../card/card.types';

export const CablePresetConfig = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        handleConfigChange, handleStartGuide, handlePresetNameChange,
        onSavePreset, onCancelWizard,
        isInWizardMode, isPresetNameValid,
        presetName, currentComponent
    } = useCablePresetConfig();

    const renderPartSelection = (): JSX.Element => {
        return (
            <PartSelectionComponent
                onConfigChange={handleConfigChange}
                onSavePreset={onSavePreset}
                presetName={presetName}
            />
        );
    };

    const renderWizard = (): JSX.Element => {
        return (
            <CableWizardComponent presetName={presetName} onCancel={onCancelWizard} onSave={onSavePreset} />
        );
    };

    const renderConfigComponent = (currentComponent?: PresetConfigComponents): JSX.Element | null => {
        switch (currentComponent) {
            case PresetConfigComponents.Wizard:
                return renderWizard();
            case PresetConfigComponents.PartSelection:
                return renderPartSelection();
            default:
                return null;
        }
    };

    return (
        <div>
            {!isInWizardMode ?
                <PresetComponent title={t(LocalizationKeys.NewCablePreset)} titleClass={'primary-title'}>
                    <div className="guide-container">
                        <div className='prompt'>
                            {t(LocalizationKeys.NewCablePrompt)}
                        </div>
                        <OutlinedButton onClick={handleStartGuide}>{t(LocalizationKeys.StartGuide)}</OutlinedButton>
                    </div>
                </PresetComponent>
                : ''}

            <PresetComponent title={isInWizardMode ? t(LocalizationKeys.NewCablePreset) : t(LocalizationKeys.UsePartNumber)} titleClass={isInWizardMode ? 'primary-title' : 'secondary-title'}>
                {CardInputWithCount({
                    error: !isPresetNameValid,
                    helperText: isPresetNameValid ? undefined : t(LocalizationKeys.PresetNeedsAName),
                    label: t(LocalizationKeys.PresetName),
                    value: presetName,
                    name: 'presetName',
                    maxLength: CardInputMaxLength.PRESET_NAME,
                    onChange: handlePresetNameChange,
                })}
                {renderConfigComponent(currentComponent)}
            </PresetComponent>
        </div>
    );
};