import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { IFrameProps } from '../build/build.types';
import { Toolbar } from '../toolbar/toolbar';
import addIcon from '../toolbar/toolbar-item/assets/add.svg';
import deleteIcon from '../toolbar/toolbar-item/assets/delete.svg';
import editIcon from '../toolbar/toolbar-item/assets/edit.svg';
import propertiesIcon from '../toolbar/toolbar-item/assets/properties.svg';
import { ToolbarItem } from '../toolbar/toolbar-item/toolbar-item';
import { useSegmentToolbar } from './segment-toolbar.hooks';

export const SegmentToolbar = (props: IFrameProps) => {
    const { t } = useTranslation();
    const {
        position,
        editDisabled,
        addDisabled,
        deleteDisabled,
        handlePropertiesClick, handleAddClick, handleDeleteClick
    } = useSegmentToolbar(props);


    return (
        <Toolbar position={position}>
            <ToolbarItem position={{ x: 0, y: 0 }} index={0} icon={propertiesIcon} toolTip={t(LocalizationKeys.PropertiesIconToolTip)} onClick={handlePropertiesClick} />
            <ToolbarItem position={{ x: 0, y: 0 }} index={1} icon={editIcon} toolTip={t(LocalizationKeys.EditIconToolTip)} disabled={editDisabled} />
            <ToolbarItem position={{ x: 0, y: 0 }} index={2} icon={addIcon} toolTip={t(LocalizationKeys.AddIconToolTip)} onClick={handleAddClick} disabled={addDisabled} />
            <ToolbarItem position={{ x: 0, y: 0 }} index={3} icon={deleteIcon} toolTip={t(LocalizationKeys.DeleteIconToolTip)} onClick={handleDeleteClick} disabled={deleteDisabled} />
        </Toolbar>
    );
};
