import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LocalizationKeys } from "../../../locales/types";
import { DialogType, showDialog } from "../../../redux/overlay.state";
import {
    dialogSelector,
    cabinetSplicePlanSelector,
    flexnapBuildsSelector,
    bulkBuildsSelector,
    selectedSplicePlanCabinetId,
    workspaceSelector,
    bulkSplicePlanSelector,
    flexNapSplicePlansSelector,
} from "../../../selectors/root.selectors";
import { BuildType } from "../../../models/build-type";
import { FlexNapBuild } from "../../../models/flexnap-build";
import { BulkBuild } from "../../../models/bulk/bulk-build";
import { updateFlexNapSplicePlan } from "../../../redux/splice-plan.state";
import { cabinetsSelector } from "../../../selectors/elements.selectors";
import { FiberAssignmentType } from "../../../models/fiber-assignment-type";
import { loadCabinetSplicePlanFromCabinetId } from "../../../redux/cabinet-splice-plan.state";
import { updateSplicePlan as updateBulkSplicePlan } from "../../../redux/bulk/splice-plan.state";
import { FlexNapSplicePlan } from "../../../models/flexnap-splice-plan";
import { BulkSplicePlan } from "../../../models/bulk/splice-plan";

export interface CabinetPortNumberManualDialogProps {
    isOpen?: boolean;
    title: string;
    fiberCount?: number;
    buildId?: number;
    lowPortNumber: string;
    highPortNumber: string;
    includeUnused: boolean;
    unusedFibersLabel: string;
    handleLowPortNumberChange: (event) => void;
    handleHighPortNumberChange: (event) => void;
    handleIncludeUnusedCheckboxChange: (event) => void;
    onSave: () => void;
    onClose: () => void;
}

const getStartingPort = (fiberAssignment, low, high) => {
    switch (fiberAssignment) {
        case FiberAssignmentType.HighToLowCoToField:
            return high;
        case FiberAssignmentType.HighToLowFieldToCo:
            return low; // not implemented
        case FiberAssignmentType.LowToHighCoToField:
            return low; // not implemented
        case FiberAssignmentType.LowToHighFieldToCo:
            return high; // not implemented

        default:
            return high;
    }
};

export const useCabinetPortNumberManualDialog = (): CabinetPortNumberManualDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const dialog = useSelector(dialogSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const { currentBuildInManualPortEdit } = useSelector(cabinetSplicePlanSelector);
    const cabinets = useSelector(cabinetsSelector);
    const cabinetId = useSelector(selectedSplicePlanCabinetId);

    const flexnapBuilds = useSelector(flexnapBuildsSelector);
    const flexNapSplicePlans = useSelector(flexNapSplicePlansSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);
    const bulkSplicePlan = useSelector(bulkSplicePlanSelector);

    const [lowPortNumber, setLowPortNumber] = useState("");
    const [highPortNumber, setHighPortNumber] = useState("");
    const [includeUnused, setIncludeUnused] = useState(true);

    const builds = [...flexnapBuilds, ...bulkBuilds];
    const cabinet = cabinets.find((c) => c.id === cabinetId);

    let fiberCount: number | undefined = undefined;
    let build: FlexNapBuild | BulkBuild | undefined = undefined;
    let splicePlan: FlexNapSplicePlan | BulkSplicePlan | undefined = undefined;

    if (currentBuildInManualPortEdit) {
        build = builds.find((b) => b.id === currentBuildInManualPortEdit.buildId);

        if (build) {
            fiberCount = build.fiberCount;

            if (build.type === BuildType.Bulk) {
                splicePlan = bulkSplicePlan;
            } else if (build.type === BuildType.FlexNap) {
                splicePlan = flexNapSplicePlans[build.id];
            }
        }
    }

    const isOpen = dialog === DialogType.CabinetPortNumberManual && !!currentBuildInManualPortEdit;
    const title = `${t(LocalizationKeys.PortNumber)}: ${t(LocalizationKeys.BuildNumber, {
        buildId: currentBuildInManualPortEdit?.buildId,
    })}`;
    const unusedFibersLabel = `${t(LocalizationKeys.Unused)}: ${
        currentBuildInManualPortEdit?.unusedFibers ?? t(LocalizationKeys.Na)
    }`;

    useEffect(() => {
        if (splicePlan !== undefined && currentBuildInManualPortEdit) {
            setIncludeUnused(splicePlan.includeUnusedFibers);

            // assuming high to low, co to field
            setLowPortNumber(currentBuildInManualPortEdit.lowPortNumber?.toString() ?? "");
            setHighPortNumber(splicePlan.startingPortNumber.toString());
        }
    }, [currentBuildInManualPortEdit, splicePlan]);

    const handleLowPortNumberChange = useCallback(
        (event) => {
            const value = event.target.value;
            setLowPortNumber(value);
            if (fiberCount && currentBuildInManualPortEdit) {
                const high =
                    !includeUnused && currentBuildInManualPortEdit.unusedFibers !== undefined
                        ? +value + (fiberCount - currentBuildInManualPortEdit.unusedFibers) - 1
                        : +value + fiberCount - 1;
                setHighPortNumber(high.toString());
            }
        },
        [currentBuildInManualPortEdit, fiberCount, includeUnused]
    );

    const handleHighPortNumberChange = useCallback(
        (event) => {
            const value = event.target.value;
            setHighPortNumber(value);
            if (fiberCount && currentBuildInManualPortEdit) {
                const low =
                    !includeUnused && currentBuildInManualPortEdit.unusedFibers !== undefined
                        ? +value - (fiberCount - currentBuildInManualPortEdit.unusedFibers) + 1
                        : +value - fiberCount + 1;
                setLowPortNumber(low.toString());
            }
        },
        [currentBuildInManualPortEdit, fiberCount, includeUnused]
    );

    const handleIncludeUnusedCheckboxChange = useCallback(
        (event) => {
            const value = event.target.checked;
            setIncludeUnused(value);
            if (fiberCount && currentBuildInManualPortEdit && currentBuildInManualPortEdit.unusedFibers) {
                // assuming high to low, co to field - the starting port is high, it does not change
                // now including unused - expand range
                if (value) {
                    const newLow = +highPortNumber - fiberCount + 1;
                    setLowPortNumber(newLow.toString());
                }
                // now excluding unused - shorten range
                else {
                    const newLow = +highPortNumber - (fiberCount - currentBuildInManualPortEdit.unusedFibers) + 1;
                    if (newLow > +highPortNumber) {
                        setLowPortNumber(highPortNumber);
                    } else {
                        setLowPortNumber(newLow.toString());
                    }
                }
            }
        },
        [currentBuildInManualPortEdit, fiberCount, highPortNumber]
    );

    const onSave = useCallback(() => {
        if (build && currentWorkspace && cabinet) {
            if (build.type === BuildType.FlexNap) {
                const startingPort = +getStartingPort(cabinet.fiberAssignment, lowPortNumber, highPortNumber);
                dispatch(
                    updateFlexNapSplicePlan({
                        buildId: build.id,
                        fiberAssignment: cabinet.fiberAssignment,
                        startingPort,
                        includeUnusedFibers: includeUnused,
                    })
                );
                dispatch(loadCabinetSplicePlanFromCabinetId(currentWorkspace.id, cabinet.id));
            } else if (build.type === BuildType.Bulk) {
                const startingPort = +getStartingPort(cabinet.fiberAssignment, lowPortNumber, highPortNumber);
                dispatch(
                    updateBulkSplicePlan({
                        buildId: build.id,
                        fiberAssignment: cabinet.fiberAssignment,
                        startingPort,
                        includeUnusedFibers: includeUnused,
                    })
                );
                dispatch(loadCabinetSplicePlanFromCabinetId(currentWorkspace.id, cabinet.id));
            }
        }
        dispatch(showDialog());
    }, [build, cabinet, currentWorkspace, dispatch, highPortNumber, includeUnused, lowPortNumber]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    return {
        isOpen,
        title,
        fiberCount,
        buildId: currentBuildInManualPortEdit?.buildId,
        lowPortNumber,
        highPortNumber,
        includeUnused,
        unusedFibersLabel,
        handleLowPortNumberChange,
        handleHighPortNumberChange,
        handleIncludeUnusedCheckboxChange,
        onSave,
        onClose,
    };
};
