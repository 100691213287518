/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import {
    DeleteBuildsPayload as DeleteSchrodingerBuildsPayload
} from '../history/schrodinger/build/delete-builds-command';
import { DeleteCablePayload } from '../history/schrodinger/build/delete-cable-command';
import { LinkTapsPayload } from '../history/schrodinger/tap/link-tap-command';
import { UnlinkTapsPayload } from '../history/schrodinger/tap/unlink-tap-command';
import i18n from '../locales/i18n';
import { LocalizationKeys } from '../locales/types';
import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { BuildSplice } from '../models/build-splice';
import { BuildType } from '../models/build-type';
import { BulkBuild } from '../models/bulk/bulk-build';
import { DesignMode } from '../models/design-mode';
import { Element } from '../models/element';
import { FlexNapBuild } from '../models/flexnap-build';
import { PretermLateral } from '../models/preterm-lateral';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { Tap } from '../models/schrodinger/tap';
import { Units } from '../models/units';
import { WebServiceErrorHandlingBehavior } from '../services/abstract-web-v2.service';
import { BuildService, UpdatedBuildsResult } from '../services/build.service';
import { BulkBuildService } from '../services/bulk/bulk-build.service';
import { BulkSpliceService } from '../services/bulk/bulk-splice.service';
import { ExportService } from '../services/export.service';
import { FlexNapBuildService } from '../services/flexnap-build.service';
import { FlexNapPretermLateralService } from '../services/flexnap-preterm-lateral.service';
import { NotificationService } from '../services/notification.service';
import {
    AddCanvasBuildRequest, SchrodingerBuildService
} from '../services/schrodinger-build.service';
import {
    BuildSegmentRequest, CanvasBuildSegmentRequest, PatchSegmentRequest, SegmentService
} from '../services/segment.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import {
    deleteSplicePointsForBuilds, linkSplicePointsOnElementsToBuild,
    lockSplicePointsConnectedToBuilds, undoDeleteSplicePointsForBuilds, unlinkSplicePointsFromBuild,
    unlockSplicePointsConnectedToBuilds
} from './bulk/splice-point.state';
import { addDesignAreaBuild } from './design-area.state';
import { push } from './history.state';
import { DialogType, showDialog, showUpdateInfrastructureSnackbar } from './overlay.state';
import { loadPresets, notifyPresetAppliedToBuild } from './preset.state';
import {
    notifyPresetAppliedToBuild as notifySchrodingerPresetAppliedToBuild
} from './schrodinger-preset.state';
import {
    addSchrodingerTethers, addTaps, deleteTaps, linkTapsOnElementsToBuild, linkTapsToBuild,
    loadForBuild, lockTapsConnectedToBuilds, unlinkTapsFromBuild, unlockTapsConnectedToBuilds
} from './schrodinger/tap.state';
import { clearBuildValidationResults } from './schrodinger/validation.state';
import {
    clearSelection, selectBuild, selectBuilds, selectNap, selectPretermTether, selectSegment,
    selectSegments, selectTether, unselectSegment
} from './selection.state';
import {
    deleteNapsForBuilds, linkNapsOnElementsToBuild, lockNapsConnectedToBuilds, removeTapsAndTethers,
    replaceTapsAndTethers, undoDeleteNapsForBuild, undoDeleteNapsForBuilds, unlinkNapsFromBuild,
    unlockNapsConnectedToBuilds
} from './tap.state';
import { setBuildAwaiting, unsetBuildAwaiting } from './workspace.state';

export interface BuildState {
    displayedBuildId?: number;
    flexnapBuilds: FlexNapBuild[];
    schrodingerBuilds: SchrodingerBuild[];
    bulkBuilds: BulkBuild[];
    segments: BuildSegment[];
    tempCanvasBuildSpans?: number[]; // used when creating a new canvas build. The spans are used to temporarily draw the build on the canvas before it's actually created
    schrodingerBuildsLoaded: boolean;
}

const initialState: BuildState = {
    displayedBuildId: undefined,
    flexnapBuilds: [],
    schrodingerBuilds: [],
    bulkBuilds: [],
    segments: [],
    tempCanvasBuildSpans: undefined,
    schrodingerBuildsLoaded: false
};

const loadFlexNapBuildsReducer = (state: BuildState, action: PayloadAction<FlexNapBuild[] | undefined>) => { state.flexnapBuilds = action.payload || []; };
const loadSchrodingerBuildsReducer = (state: BuildState, action: PayloadAction<SchrodingerBuild[] | undefined>) => {
    state.schrodingerBuilds = action.payload || [];
    state.schrodingerBuildsLoaded = true;
};
const loadBulkBuildsReducer = (state: BuildState, action: PayloadAction<BulkBuild[] | undefined>) => { state.bulkBuilds = action.payload || []; };
const loadSegmentsReducer = (state: BuildState, action: PayloadAction<BuildSegment[] | undefined>) => { state.segments = action.payload || []; };
const loadNewCanvasBuildSpansReducer = (state: BuildState, action: PayloadAction<number[] | undefined>) => { state.tempCanvasBuildSpans = action.payload; };

function createFlexNapBuildReducer(state: BuildState, action: PayloadAction<FlexNapBuild>) {
    state.flexnapBuilds.push(action.payload);
}

function createSchrodingerBuildReducer(state: BuildState, action: PayloadAction<SchrodingerBuild>) {
    state.schrodingerBuilds.push(action.payload);
}

function createBulkBuildReducer(state: BuildState, action: PayloadAction<BulkBuild>) {
    state.bulkBuilds.push(action.payload);
}

function setDisplayedBuildIdReducer(state: BuildState, action: PayloadAction<number | undefined>) {
    state.displayedBuildId = action.payload;
}

function addSegmentsReducer(state: BuildState, action: PayloadAction<BuildSegment[]>) {
    const segments = action.payload;
    // filter out duplicate segments
    const newSegments = segments.filter((s) => state.segments.every((os) => s.id !== os.id));
    state.segments.push(...newSegments);
}

function setSchrodingerBuildsLoadedReducer(state: BuildState, action: PayloadAction<boolean | undefined>) {
    state.schrodingerBuildsLoaded = !!action.payload;
}

function replaceBuildSegmentsReducer(state: BuildState, action: PayloadAction<{ buildId: number; segments: BuildSegment[] }>) {
    const { buildId, segments } = action.payload;
    const positionToReplace = segments.map(s => s.position);
    const segmentsToReplace = state.segments.filter(s => s.buildId === buildId && positionToReplace.includes(s.position));
    state.segments = state.segments.filter(s => !segmentsToReplace.includes(s));
    state.segments.push(...segments);
}

function updateSegment(state: BuildState, segment: BuildSegment) {
    const sIdx = state.segments.findIndex((seg) => seg.id === segment.id);
    if (sIdx < 0) {
        state.segments.push(segment);
    }
    else {
        Object.assign(state.segments[sIdx], segment);
    }
}

function updateSegmentReducer(state: BuildState, action: PayloadAction<BuildSegment>) {
    const segment = action.payload;
    updateSegment(state, segment);
}

function updateSegmentsReducer(state: BuildState, action: PayloadAction<BuildSegment[]>) {
    const segments = action.payload;
    for (const segment of segments) {
        updateSegment(state, segment);
    }
}

function deleteSegmentReducer(state: BuildState, action: PayloadAction<number>) {
    const segmentId = action.payload;
    state.segments = state.segments.filter((s) => s.id !== segmentId);
}

function deleteFlexNapBuildReducer(state: BuildState, action: PayloadAction<number>) {
    const buildId = action.payload;
    state.flexnapBuilds = state.flexnapBuilds.filter((b) => b.id !== buildId);
    state.segments = state.segments.filter((s) => s.buildId !== buildId);
}

function deleteFlexNapBuildsReducer(state: BuildState, action: PayloadAction<number[]>) {
    const buildIds = action.payload;
    state.flexnapBuilds = state.flexnapBuilds.filter((b) => !buildIds.includes(b.id));
    state.segments = state.segments.filter((s) => !buildIds.includes(s.buildId));
}

function deleteSchrodingerBuildReducer(state: BuildState, action: PayloadAction<number>) {
    const buildId = action.payload;
    state.schrodingerBuilds = state.schrodingerBuilds.filter((b) => b.id !== buildId);
    state.segments = state.segments.filter((s) => s.buildId !== buildId);
    if (state.displayedBuildId && action.payload === state.displayedBuildId) {
        state.displayedBuildId = undefined;
    }
}

function deleteSchrodingerBuildsReducer(state: BuildState, action: PayloadAction<number[]>) {
    const buildIds = action.payload;
    state.schrodingerBuilds = state.schrodingerBuilds.filter((b) => !buildIds.includes(b.id));
    state.segments = state.segments.filter((s) => !buildIds.includes(s.buildId));
    if (state.displayedBuildId && action.payload.includes(state.displayedBuildId)) {
        state.displayedBuildId = undefined;
    }
}

function deleteBulkBuildReducer(state: BuildState, action: PayloadAction<number>) {
    const buildId = action.payload;
    state.bulkBuilds = state.bulkBuilds.filter(b => b.id !== buildId);
    state.segments = state.segments.filter(s => s.buildId !== buildId);
}

function deleteBulkBuildsReducer(state: BuildState, action: PayloadAction<number[]>) {
    const buildIds = action.payload;
    state.bulkBuilds = state.bulkBuilds.filter(b => !buildIds.includes(b.id));
    state.segments = state.segments.filter(s => !buildIds.includes(s.buildId));
}

function updateFlexNapBuildReducer(state: BuildState, action: PayloadAction<FlexNapBuild>) {
    const build = action.payload;
    const bIdx = state.flexnapBuilds.findIndex((b) => b.id === build.id);
    if (bIdx < 0) {
        state.flexnapBuilds.push(build);
    }
    else {
        Object.assign(state.flexnapBuilds[bIdx], build);
    }
}

function updateSchrodingerBuildReducer(state: BuildState, action: PayloadAction<SchrodingerBuild>) {
    const build = action.payload;
    const bIdx = state.schrodingerBuilds.findIndex((b) => b.id === build.id);
    if (bIdx < 0) {
        state.schrodingerBuilds.push(build);
    }
    else {
        Object.assign(state.schrodingerBuilds[bIdx], build);
    }
}

function updateBulkBuildReducer(state: BuildState, action: PayloadAction<BulkBuild>) {
    const build = action.payload;
    const bIdx = state.bulkBuilds.findIndex((b) => b.id === build.id);
    if (bIdx < 0) {
        state.bulkBuilds.push(build);
    }
    else {
        Object.assign(state.bulkBuilds[bIdx], build);
    }
}

function updateFlexNapBuildsReducer(state: BuildState, action: PayloadAction<FlexNapBuild[]>) {
    const updatedBuilds = action.payload;
    const updatedBuildIds = updatedBuilds.map((b) => b.id);
    const builds = state.flexnapBuilds.filter((b) => !updatedBuildIds.includes(b.id));
    builds.push(...updatedBuilds);
    state.flexnapBuilds = builds;
}

function updateSchrodingerBuildsReducer(state: BuildState, action: PayloadAction<SchrodingerBuild[]>) {
    const updatedBuilds = action.payload;
    const updatedBuildIds = updatedBuilds.map((b) => b.id);
    const builds = state.schrodingerBuilds.filter((b) => !updatedBuildIds.includes(b.id));
    builds.push(...updatedBuilds);
    state.schrodingerBuilds = builds;
}

function updateBulkBuildsReducer(state: BuildState, action: PayloadAction<BulkBuild[]>) {
    const updatedBuilds = action.payload;
    const updatedBuildIds = updatedBuilds.map(b => b.id);
    const builds = state.bulkBuilds.filter(b => !updatedBuildIds.includes(b.id));
    builds.push(...updatedBuilds);
    state.bulkBuilds = builds;
}

function revertFlexNapPretermLateral(state: BuildState, predicate: (build: FlexNapBuild) => boolean): void {
    const childBuilds = state.flexnapBuilds.filter(predicate);
    for (const build of childBuilds) {
        build.pretermLateral = undefined;
    }
}

function updateChildFlexNapPretermLateralsReducer(state: BuildState, action: PayloadAction<number[]>): void {
    const parentBuildIds = new Set(action.payload);
    revertFlexNapPretermLateral(state, (b) => !!b.pretermLateral && parentBuildIds.has(b.pretermLateral.parentBuildId));
}

function connectBuildToSplicePointReducer(state: BuildState, action: PayloadAction<{ buildId: number; buildSplice: BuildSplice | undefined }>): void {
    const { buildId, buildSplice } = action.payload;
    const flexNapBuild = state.flexnapBuilds.find(b => b.id === buildId);
    if (flexNapBuild) {
        flexNapBuild.buildSplice = buildSplice;
    }
    const bulkBuild = state.bulkBuilds.find(b => b.id === buildId);
    if (bulkBuild) {
        bulkBuild.buildSplice = buildSplice;
    }
}

function releaseBuildReducer(state: BuildState, action: PayloadAction<number>): void {
    const buildId = action.payload;
    const flexnapBuildIdx = state.flexnapBuilds.findIndex((b) => b.id === buildId);
    if (flexnapBuildIdx >= 0) {
        const flexnapBuild = state.flexnapBuilds[flexnapBuildIdx];
        flexnapBuild.modifiedById = null;
        Object.assign(state.flexnapBuilds[flexnapBuildIdx], flexnapBuild);
        return;
    }

    const schrodingerBuildIdx = state.schrodingerBuilds.findIndex((b) => b.id === buildId);
    if (schrodingerBuildIdx >= 0) {
        const schrodingerBuild = state.schrodingerBuilds[schrodingerBuildIdx];
        schrodingerBuild.modifiedById = null;
        Object.assign(state.schrodingerBuilds[schrodingerBuildIdx], schrodingerBuild);
    }
}

function loadFlexNapBuildFromImportReducer(state: BuildState, action: PayloadAction<FlexNapBuild>): void {
    state.flexnapBuilds.push(action.payload);
}

function loadBulkBuildFromImportReducer(state: BuildState, action: PayloadAction<BulkBuild>): void {
    state.bulkBuilds.push(action.payload);
}

function loadBuildSegmentsFromImportReducer(state: BuildState, action: PayloadAction<BuildSegment[]>): void {
    state.segments.push(...action.payload);
}

const { actions, reducer } = createSlice({
    name: 'build',
    initialState,
    reducers: {
        loadFlexNapBuilds: loadFlexNapBuildsReducer,
        loadSchrodingerBuilds: loadSchrodingerBuildsReducer,
        loadBulkBuilds: loadBulkBuildsReducer,
        loadSegments: loadSegmentsReducer,
        loadNewCanvasBuildSpans: loadNewCanvasBuildSpansReducer,
        createFlexNapBuild: createFlexNapBuildReducer,
        createSchrodingerBuild: createSchrodingerBuildReducer,
        createBulkBuild: createBulkBuildReducer,
        setDisplayedBuildId: setDisplayedBuildIdReducer,
        addSegments: addSegmentsReducer,
        replaceBuildSegments: replaceBuildSegmentsReducer,
        updateSegment: updateSegmentReducer,
        updateSegments: updateSegmentsReducer,
        deleteSegment: deleteSegmentReducer,
        deleteFlexNapBuild: deleteFlexNapBuildReducer,
        deleteFlexNapBuilds: deleteFlexNapBuildsReducer,
        deleteSchrodingerBuild: deleteSchrodingerBuildReducer,
        deleteSchrodingerBuilds: deleteSchrodingerBuildsReducer,
        deleteBulkBuild: deleteBulkBuildReducer,
        deleteBulkBuilds: deleteBulkBuildsReducer,
        updateFlexNapBuild: updateFlexNapBuildReducer,
        updateFlexNapBuilds: updateFlexNapBuildsReducer,
        updateSchrodingerBuild: updateSchrodingerBuildReducer,
        updateSchrodingerBuilds: updateSchrodingerBuildsReducer,
        updateBulkBuild: updateBulkBuildReducer,
        updateBulkBuilds: updateBulkBuildsReducer,
        updateChildFlexNapPretermLaterals: updateChildFlexNapPretermLateralsReducer,
        connectBuildToSplicePoint: connectBuildToSplicePointReducer,
        releaseBuild: releaseBuildReducer,
        loadFlexNapBuildFromImport: loadFlexNapBuildFromImportReducer,
        loadBulkBuildFromImport: loadBulkBuildFromImportReducer,
        loadBuildSegmentsFromImport: loadBuildSegmentsFromImportReducer,
        setSchrodingerBuildsLoaded: setSchrodingerBuildsLoadedReducer,
    },
});

export { reducer as BuildReducer };
export const {
    updateSegments,
    deleteFlexNapBuilds: deleteFlexNapBuildsAction,
    deleteBulkBuilds: deleteBulkBuildsAction,
    loadFlexNapBuildFromImport,
    loadBulkBuildFromImport,
    loadBuildSegmentsFromImport,
    loadNewCanvasBuildSpans,
    updateChildFlexNapPretermLaterals,
    setDisplayedBuildId,
    updateFlexNapBuilds,
    setSchrodingerBuildsLoaded
} = actions;

const createTempBuildId = () => Math.round(Math.random() * -1000);
// Temporary segments have an id of -1 (FlexNap), -2 (Schrodinger), or -3 (Bulk). They get replaced in the replaceBuildSegments action.
const createTempSegments = (buildId: number, fromTo: BuildSegmentRequest[], buildType = BuildType.FlexNap) => {
    return fromTo.map((s) => ({
        id: buildType === BuildType.Bulk ? -3 : buildType === BuildType.Schrodinger ? -2 : -1,
        buildId, position: s.position, fromId: s.fromId, toId: s.toId, designSpan: 0, designSpanUnit: Units.feet, slack: 0, slackLoop: 0, slackUnit: Units.feet
    }));
}

const unlockBuildsAction = (data: UpdatedBuildsResult, workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        if (data.flexNapBuilds && data.flexNapBuilds.length > 0) {
            dispatch(actions.updateFlexNapBuilds(data.flexNapBuilds));
            dispatch(unlockNapsConnectedToBuilds(data.flexNapBuilds.map((b) => b.id), workspaceId));
        }

        if (data.schrodingerBuilds && data.schrodingerBuilds.length > 0) {
            dispatch(actions.updateSchrodingerBuilds(data.schrodingerBuilds));
            dispatch(unlockTapsConnectedToBuilds(data.schrodingerBuilds.map((b) => b.id), workspaceId));
        }

        if (data.bulkBuilds && data.bulkBuilds.length > 0) {
            dispatch(actions.updateBulkBuilds(data.bulkBuilds));
            dispatch(unlockSplicePointsConnectedToBuilds(data.bulkBuilds.map(b => b.id), workspaceId));
        }

        if (data.errorMessages && data.errorMessages.length > 0) {
            data.errorMessages.forEach(message => NotificationService.error(message));
        }
    });
};

export const updateBuild = (build: Build) => {
    return async (dispatch: Dispatch): Promise<void> => {
        switch (build.type) {
            case BuildType.FlexNap:
                dispatch(actions.updateFlexNapBuild(build as FlexNapBuild));
                break;
            case BuildType.Schrodinger:
                dispatch(actions.updateSchrodingerBuild(build as SchrodingerBuild));
                break;
            case BuildType.Bulk:
                dispatch(actions.updateBulkBuild(build as BulkBuild));
                break;
        }
    };
};

export const createFlexNapBuild = (workspaceId: number, segments: BuildSegmentRequest[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const tempBuildId = createTempBuildId();
        dispatch(actions.addSegments(createTempSegments(tempBuildId, segments)));

        const result = await new FlexNapBuildService().addFlexNapBuild({ workspaceId, segments });
        if (result) {
            dispatch(actions.createFlexNapBuild(result.build));
            dispatch(actions.replaceBuildSegments({ buildId: tempBuildId, segments: result.segments }));
            const elementIds = result.segments.reduce((p, { fromId, toId }) => {
                fromId && p.add(fromId);
                toId && p.add(toId);
                return p;
            }, new Set<number>());
            dispatch(linkNapsOnElementsToBuild(Array.from(elementIds), result.build.id));
            dispatch(push({ type: CommandType.CreateFlexNapCable, payload: { id: result.build.id } }));
        }
    });
};

export const createSchrodingerBuild = (workspaceId: number, segments: BuildSegmentRequest[], taps: Tap[], ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.GeneratingBuild));
        const tempBuildId = createTempBuildId();
        dispatch(actions.addSegments(createTempSegments(tempBuildId, segments)));

        const service = new SchrodingerBuildService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);

        try {
            const result = await service.addSchrodingerBuild({ workspaceId, segments });
            if (result?.build) {
                dispatch(actions.createSchrodingerBuild(result.build));
                dispatch(actions.replaceBuildSegments({ buildId: tempBuildId, segments: result.segments }));
                if (taps.length) {
                    dispatch(linkTapsToBuild(taps, result.build.id, true));
                }
                NotificationService.success(i18n.t(LocalizationKeys.BuildSuccessfullyGenerated));
                if (!ignoreHistory) {
                    const linkTapsPayload: LinkTapsPayload = { taps, buildId: result.build.id };
                    dispatch(push({
                        type: CommandType.CreateSchrodingerBuild,
                        payload: { buildId: result.build.id, workspaceId: result.build.workspaceId, linkTapsPayload }
                    }));
                }
            }
        }
        catch (err) {
            // delete temp segments
            dispatch(actions.deleteSchrodingerBuild(tempBuildId));
            const _err = err as any;
            const reason: string | undefined = _err?.response?.data;
            if (reason) {
                NotificationService.error(reason);
            }
        }
        NotificationService.clear(loadingToast);
    });
};

export const createCanvasSchrodingerBuild = (request: AddCanvasBuildRequest) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.setSchrodingerBuildsLoaded());
        dispatch(actions.setDisplayedBuildId());
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.GeneratingBuild));
        const service = new SchrodingerBuildService();
        try {
            const result = await service.addCanvasSchrodingerBuild(request);
            if (result?.build) {
                dispatch(actions.createSchrodingerBuild(result.build));
                dispatch(actions.addSegments(result.segments));
                dispatch(setDisplayedBuildId(result.build.id));
                dispatch(addDesignAreaBuild({ designAreaId: request.designAreaId, buildId: result.build.id }))
                NotificationService.success(i18n.t(LocalizationKeys.BuildSuccessfullyGenerated));
            }
        } catch (err) {
            const _err = err as any;
            const reason: string | undefined = _err?.response?.data;
            if (reason) {
                NotificationService.error(reason);
            }
        } finally {
            dispatch(actions.setSchrodingerBuildsLoaded(true));
            NotificationService.clear(loadingToast);
        }

    });
};

export const createBulkBuild = (workspaceId: number, segments: BuildSegmentRequest[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const tempBuildId = createTempBuildId();
        dispatch(actions.addSegments(createTempSegments(tempBuildId, segments, BuildType.Bulk)));

        const result = await new BulkBuildService().addBulkBuild({ workspaceId, segments });
        if (result) {
            dispatch(actions.createBulkBuild(result.build));
            dispatch(actions.replaceBuildSegments({ buildId: tempBuildId, segments: result.segments }));
            const elementIds = result.segments.reduce((p, { fromId, toId }) => {
                fromId && p.add(fromId);
                toId && p.add(toId);
                return p;
            }, new Set<number>());
            dispatch(linkSplicePointsOnElementsToBuild([...elementIds], result.build.id));
        }
    });
};

export const createSegment = (buildId: number, createRequest: BuildSegmentRequest) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SegmentService();
        const data = await service.createSegment(buildId, createRequest);
        if (data) {
            dispatch(actions.addSegments([data]));
            const { fromId, toId } = data;
            const elementIds = [
                ...(fromId ? [fromId] : []),
                ...(toId ? [toId] : []),
            ];
            dispatch(linkTapsOnElementsToBuild(elementIds, buildId));
        }
    });
};

export const createCanvasSegment = (buildId: number, createRequest: CanvasBuildSegmentRequest) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(setBuildAwaiting(buildId));

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingSpan));
        const service = new SegmentService();
        const data = await service.createCanvasSegment(buildId, createRequest);
        if (data) {
            const { newSegmentId, segments, build } = data;
            dispatch(actions.replaceBuildSegments({ buildId, segments }));
            dispatch(actions.updateSchrodingerBuild(build));
            const segment = segments.find((s) => s.id === newSegmentId);
            NotificationService.success(i18n.t(LocalizationKeys.SpanSuccessfullyAdded));
            dispatch(push({ type: CommandType.AddCanvasSpan, payload: { segment: segment, awaitSessionUpdate: !!buildId } }));
        }

        dispatch(unsetBuildAwaiting(buildId));
        NotificationService.clear(loadingToast);
    });
};

export const createSegments = async (dispatch: Dispatch, buildId: number, createRequests: BuildSegmentRequest[], buildType?: BuildType, awaitSessionUpdate = false): Promise<BuildSegment[]> => {
    awaitSessionUpdate && dispatch(setBuildAwaiting(buildId));
    dispatch(actions.addSegments(createTempSegments(buildId, createRequests, buildType))); // Pre-update for the UI
    const service = new SegmentService();
    const data = await service.createSegments(buildId, createRequests);
    if (data) {
        dispatch(actions.replaceBuildSegments({ buildId: buildId, segments: data }));
        dispatch(selectSegments(data.map(s => s.id)));
        return data;
    }
    awaitSessionUpdate && dispatch(unsetBuildAwaiting(buildId));
    return [];
};

export const loadBuilds = (workspaceId: number, designMode = DesignMode.GISMode) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const data = await service.getBuildsByWorkspace(workspaceId, designMode);
        if (data) {
            const { flexNapBuilds, schrodingerBuilds, bulkBuilds, buildSegments } = data;
            flexNapBuilds && dispatch(actions.loadFlexNapBuilds(flexNapBuilds));
            schrodingerBuilds && dispatch(actions.loadSchrodingerBuilds(schrodingerBuilds));
            bulkBuilds && dispatch(actions.loadBulkBuilds(bulkBuilds));
            buildSegments && dispatch(actions.loadSegments(buildSegments));
        }
    });
};

export const patchSegment = (patchRequest: PatchSegmentRequest, awaitSessionUpdate?: boolean) => {
    return createSecuredAsyncAction(async (dispatch) => {
        if (awaitSessionUpdate) {
            dispatch(setBuildAwaiting(patchRequest.oldSegment.buildId));
        }

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Updating));
        const service = new SegmentService();
        const { oldSegment, newSegment, ignoreSelected } = patchRequest;
        const data = await service.patchSegment(oldSegment, newSegment);
        if (data) {
            const { segment, build } = data;
            dispatch(actions.updateSegment(segment));
            if (!ignoreSelected) {
                dispatch(selectSegment(segment.id));
            }

            switch (build.type) {
                case BuildType.FlexNap:
                    dispatch(actions.updateFlexNapBuild(build as FlexNapBuild));
                    break;
                case BuildType.Schrodinger:
                    dispatch(actions.updateSchrodingerBuild(build as SchrodingerBuild));
                    break;
            }

            if (data.warningMessage) {
                NotificationService.infoPermanent(data.warningMessage);
            }
        }
        NotificationService.clear(loadingToast);
        if (awaitSessionUpdate) {
            dispatch(unsetBuildAwaiting(patchRequest.oldSegment.buildId));
        }

    });
};

export const createTemporaryPatchedSegments = (patchRequests: PatchSegmentRequest[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.updateSegments(patchRequests.map(r => r.newSegment)));
    });
};

export const loadBuildSegments = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SegmentService();
        const buildSegments = await service.getBuildSegments(buildId);
        if (buildSegments) {
            dispatch(actions.updateSegments(buildSegments));
        }
    });
};

export const patchSegments = (patchRequests: PatchSegmentRequest[], createTemporarySegments = true, awaitSessionUpdate = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const buildIds = patchRequests.flatMap(({ oldSegment, newSegment }) => [oldSegment.buildId, newSegment.buildId]).distinct();
        if (awaitSessionUpdate) {
            for (const buildId of buildIds) dispatch(setBuildAwaiting(buildId));
        }

        if (createTemporarySegments) {
            dispatch(actions.updateSegments(patchRequests.map(r => r.newSegment))); // Pre-update for the UI
        }
        const segmentService = new SegmentService();
        const updatedSegments: BuildSegment[] = [];
        const updatedBuilds: Build[] = [];
        for (const patchRequest of patchRequests) {
            const { oldSegment, newSegment } = patchRequest;
            const data = await segmentService.patchSegment(oldSegment, newSegment);
            if (data) {
                const { segment, build } = data;
                updatedSegments.push(segment);
                if (!updatedBuilds.some(b => b.id === build.id)) {
                    updatedBuilds.push(build);
                }
            }
        }
        dispatch(actions.updateSegments(updatedSegments));

        const flexNapBuilds = updatedBuilds.filter((b) => b.type === BuildType.FlexNap) as FlexNapBuild[];
        flexNapBuilds.length && dispatch(actions.updateFlexNapBuilds(flexNapBuilds));

        const schrodingerBuilds = updatedBuilds.filter((b) => b.type === BuildType.Schrodinger) as SchrodingerBuild[];
        schrodingerBuilds.length && dispatch(actions.updateSchrodingerBuilds(schrodingerBuilds));

        if (awaitSessionUpdate) {
            for (const buildId of buildIds) dispatch(unsetBuildAwaiting(buildId));
        }
    });
};

export const deleteSegment = (segment: BuildSegment, deleteFirstLocation: boolean, designMode = DesignMode.GISMode, deletedTap?: Tap, awaitSessionUpdate?: boolean) => {
    return createSecuredAsyncAction(async (dispatch) => {
        awaitSessionUpdate && dispatch(setBuildAwaiting(segment.buildId));

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.DeletingSpan));
        const service = new SegmentService();
        const build = await service.deleteSegment(segment.id, { deleteFirstLocation });
        dispatch(actions.deleteSegment(segment.id));
        dispatch(unselectSegment(segment.id));
        if (build && build.type === BuildType.Schrodinger) {
            await loadBuildSegments(build.id)(dispatch);
            dispatch(actions.updateSchrodingerBuild(build as SchrodingerBuild));
            if (designMode === DesignMode.CanvasMode) {
                dispatch(push({ type: CommandType.DeleteCanvasSpan, payload: { segment, tap: deletedTap, awaitSessionUpdate } }));
            }
            NotificationService.success(i18n.t(LocalizationKeys.SpanSuccessfullyDeleted));
        }

        awaitSessionUpdate && dispatch(unsetBuildAwaiting(segment.buildId));
        NotificationService.clear(loadingToast);
    });
};

export const undoDeleteSegment = (segmentToUndo: BuildSegment, restoreFirstLocation: boolean, awaitSessionUpdate?: boolean) => {
    return createSecuredAsyncAction(async (dispatch) => {
        awaitSessionUpdate && dispatch(setBuildAwaiting(segmentToUndo.buildId));

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingSpan));
        const service = new SegmentService();
        const segments = await service.undoDeleteSegment(segmentToUndo.id, { restoreFirstLocation });
        if (segments) {
            dispatch(actions.updateSegments(segments));
            NotificationService.success(i18n.t(LocalizationKeys.SpanSuccessfullyAdded));
        }

        awaitSessionUpdate && dispatch(unsetBuildAwaiting(segmentToUndo.buildId));
        NotificationService.clear(loadingToast);
    });
};

/**
 * Deletes FlexNAP cable and unlinks NAPs.
 * @param buildId The Build Id to delete
 */
export const deleteFlexNapCable = (buildId: number, childPretermBuilds: FlexNapBuild[], ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapBuildService();
        const res = await requestIsSuccess(service, service.deleteBuild(buildId));
        if (res) {
            dispatch(unlinkNapsFromBuild(buildId));
            dispatch(actions.deleteFlexNapBuild(buildId));
            childPretermBuilds.forEach(b => dispatch(revertPretermLateral(b.id)));
            dispatch(clearSelection());
            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.DeleteFlexNapCable, payload: { id: buildId, childPretermBuilds } }));
            }
        }
    });
};

export const undoDeleteFlexNapCable = (buildId: number, childPretermBuilds: FlexNapBuild[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapBuildService();
        const res = await service.undoDeleteBuild(buildId);

        if (res) {
            dispatch(actions.createFlexNapBuild(res.build));
            dispatch(actions.addSegments(res.segments));
            const elementIds = res.segments.reduce((p, { fromId, toId }) => {
                fromId && p.add(fromId);
                toId && p.add(toId);
                return p;
            }, new Set<number>());
            dispatch(linkNapsOnElementsToBuild(Array.from(elementIds), buildId));
            childPretermBuilds.forEach(b => b.pretermLateral && dispatch(undoRevertPretermLateral(b.id, b.pretermLateral)));
        }
    });
};

export const deleteSchrodingerCable = (buildId: number, workspaceId: number, taps: Tap[], ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.DeletingCable))
        const service = new SchrodingerBuildService();
        const res = await requestIsSuccess(service, service.deleteBuild(buildId));
        if (res) {
            dispatch(unlinkTapsFromBuild(taps))
            dispatch(actions.deleteSchrodingerBuild(buildId));
            dispatch(clearSelection());
            if (workspaceId) {
                dispatch(clearBuildValidationResults({ workspaceId, buildIds: [buildId] }));
            }
            if (!ignoreHistory) {
                const unlinkTapsPayload: UnlinkTapsPayload = { taps, buildId };
                const payload: DeleteCablePayload = { buildId, workspaceId, unlinkTapsPayload };
                dispatch(push({ type: CommandType.DeleteSchrodingerCable, payload }));
            }
        }
        NotificationService.clear(loadingToast);
    });
};

export const undoDeleteSchrodingerCable = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SchrodingerBuildService();
        const res = await service.undoDeleteBuild(buildId);
        if (res) {
            dispatch(actions.createSchrodingerBuild(res.build));
            dispatch(actions.addSegments(res.segments));
        }
    });
};

/**
 * Deletes Bulk cable and unlinks Splice Points.
 * @param buildId The Build Id to delete 
 */
export const deleteBulkCable = (buildId: number, childBuildIds: number[] = []) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkBuildService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.deleteBuild(buildId);
            dispatch(actions.deleteBulkBuild(buildId));
            dispatch(clearSelection());
            if (childBuildIds) {
                childBuildIds.forEach(b => dispatch(disconnectBuildFromSplicePoint(b)));
            }
            dispatch(unlinkSplicePointsFromBuild(buildId));
        } catch (error) {
            NotificationService.error(`Error deleting Bulk cable: ${error}`);
        }
    });
};

/**
 * Deletes FlexNap build and linked NAPs.
 * @param buildIds The FlexNap Build Ids to delete
 */
export const deleteFlexNapBuilds = (buildIds: number[], childPretermBuilds: FlexNapBuild[], ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(deleteNapsForBuilds(buildIds));
        const service = new FlexNapBuildService();
        const res = await requestIsSuccess(service, service.deleteBuilds(buildIds));
        if (res) {
            dispatch(actions.deleteFlexNapBuilds(buildIds));
            childPretermBuilds.forEach(b => dispatch(revertPretermLateral(b.id)));
            dispatch(selectSegment());
            dispatch(selectBuild());
            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.DeleteFlexNapBuild, payload: { ids: buildIds, childPretermBuilds } }));
            }
        }
    });
};

export const undoDeleteFlexNapBuilds = (buildIds: number[], childPretermBuilds: FlexNapBuild[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapBuildService();
        const res = await service.undoDeleteBuilds(buildIds);
        if (res) {
            dispatch(actions.updateFlexNapBuilds(res.builds));
            dispatch(actions.addSegments(res.segments));
            dispatch(undoDeleteNapsForBuilds(buildIds));
            childPretermBuilds.forEach(b => b.pretermLateral && dispatch(undoRevertPretermLateral(b.id, b.pretermLateral)));
        }
    });
};

/**
 * Deletes Schrodinger build and linked TAPs.
 * @param buildIds The Schrodinger Build Ids to delete
 */
export const deleteSchrodingerBuilds = (buildIds: number[], tapIds: number[], workspaceId?: number, ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SchrodingerBuildService();
        const res = await requestIsSuccess(service, service.deleteBuilds(buildIds, tapIds));
        if (res) {
            dispatch(actions.deleteSchrodingerBuilds(buildIds));
            dispatch(deleteTaps(tapIds));
            dispatch(selectSegment());
            dispatch(selectBuild());
            if (workspaceId) {
                dispatch(clearBuildValidationResults({ workspaceId, buildIds }));
            }
            if (!ignoreHistory) {
                const payload: DeleteSchrodingerBuildsPayload = { buildIds, tapIds, workspaceId };
                dispatch(push({ type: CommandType.DeleteSchrodingerBuilds, payload }));
            }
        }
    });
};

export const undoDeleteSchrodingerBuilds = (buildIds: number[], tapIds: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.RestoringBuilds));
        const service = new SchrodingerBuildService();
        const res = await service.undoDeleteBuilds(buildIds, tapIds);
        if (res) {
            dispatch(actions.updateSchrodingerBuilds(res.builds));
            dispatch(actions.addSegments(res.segments));
            dispatch(addTaps(res.taps));
            dispatch(addSchrodingerTethers(res.tethers));
            NotificationService.success(i18n.t(LocalizationKeys.BuildsRestored));
        }
        else {
            NotificationService.error(i18n.t(LocalizationKeys.UnableToRestoreBuilds));
        }
        NotificationService.clear(loadingToast);
    });
}

/**
 * Deletes Bulk Builds and linked Splice Points.
 * @param buildIds The Bulk Build Ids to delete
 */
export const deleteBulkBuilds = (buildIds: number[], childBuildIds: number[] = []) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkBuildService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.deleteBuilds(buildIds);
            dispatch(actions.deleteBulkBuilds(buildIds));
            dispatch(selectSegment());
            dispatch(selectBuild());
            if (childBuildIds) {
                childBuildIds.forEach(b => dispatch(disconnectBuildFromSplicePoint(b)));
            }
            dispatch(deleteSplicePointsForBuilds(buildIds));
        } catch (error) {
            NotificationService.error(`Error deleting builds ${buildIds.join(', ')}: ${error}`)
        }
    });
};

/**
 * Export the updated builds information (CCS build id) that were imported into hybris.
 * We will only need to show the updated FlexNapBuild in this situation because multiple
 * hybris project keys can be associated with one of our projects. 
 * @param designAreaConfigurationId Identifer of the newly imported builds into hybris
 * @returns 
 */
export const exportUpdatedData = (designAreaConfigurationId: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new ExportService();
        const updatedBuilds = await service.exportUpdatedData(designAreaConfigurationId);
        if (updatedBuilds) {
            dispatch(actions.updateFlexNapBuilds(updatedBuilds));
            dispatch(push({ type: CommandType.ExportFlexNapBuild, payload: { builds: updatedBuilds } }));
        }
    });
};

export const updateFlexNapBuild = (currentBuild: FlexNapBuild, updatedBuild: FlexNapBuild) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapBuildService();
        const build = await service.updateBuild(currentBuild, updatedBuild);
        if (build) {
            dispatch(actions.updateFlexNapBuild(build));
        }
    });
};

export const updateSchrodingerBuild = (currentBuild: SchrodingerBuild, updatedBuild: SchrodingerBuild) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SchrodingerBuildService();
        const build = await service.updateBuild(currentBuild, updatedBuild);
        if (build) {
            dispatch(actions.updateSchrodingerBuild(build));
        }
    });
};

export const updateBulkBuild = (currentBuild: BulkBuild, updatedBuild: BulkBuild) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkBuildService();
        const build = await service.updateBuild(currentBuild, updatedBuild);
        if (build) {
            dispatch(actions.updateBulkBuild(build));
        }
    });
};

export const updateSchrodingerBuildFromSession = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SchrodingerBuildService();
        const build = await service.updateBuildFromSession(buildId);
        if (build) {
            dispatch(actions.updateSchrodingerBuild(build));
        }
    });
};

export const applyBuildPreset = (buildId: number, presetId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapBuildService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        const build = await service.applyPreset(buildId, presetId);
        try {
            if (build) {
                dispatch(actions.updateFlexNapBuild(build));
                dispatch(loadPresets());
                dispatch(notifyPresetAppliedToBuild({ presetId: presetId, buildId: buildId }));
            }
        }
        catch (err) {
            const _err = err as any;
            const reason: string | undefined = _err?.response?.data;
            NotificationService.error('Unable to apply preset:', reason || 'Unset reason');
        }
    });
};

export const applySchrodingerBuildPreset = (buildId: number, presetId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {

        dispatch(setBuildAwaiting(buildId));

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Updating));
        const service = new SchrodingerBuildService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const build = await service.applyPreset(buildId, presetId);
            if (build) {
                dispatch(actions.updateSchrodingerBuild(build));
                dispatch(loadForBuild(buildId));
                dispatch(notifySchrodingerPresetAppliedToBuild({ presetId: presetId, buildId: buildId }));
            }
        }
        catch (err) {
            const _err = err as any;
            const reason: string | undefined = _err?.response?.data;
            NotificationService.error('Unable to apply preset:', reason || 'Unset reason');
        }
        NotificationService.clear(loadingToast);
        dispatch(unsetBuildAwaiting(buildId));
    });
};

export const lockBuilds = (buildIds: number[], workspaceId: number, ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.LockingBuilds));
        const service = new BuildService();
        const data = await service.lockBuilds(buildIds, workspaceId);
        if (data) {
            if (data.flexNapBuilds && data.flexNapBuilds.length > 0) {
                dispatch(actions.updateFlexNapBuilds(data.flexNapBuilds));
                dispatch(lockNapsConnectedToBuilds(data.flexNapBuilds.map((b) => b.id), workspaceId));
            }

            if (data.schrodingerBuilds && data.schrodingerBuilds.length > 0) {
                dispatch(actions.updateSchrodingerBuilds(data.schrodingerBuilds));
                dispatch(lockTapsConnectedToBuilds(data.schrodingerBuilds.map((b) => b.id), workspaceId));
            }

            if (data.bulkBuilds && data.bulkBuilds.length > 0) {
                dispatch(actions.updateBulkBuilds(data.bulkBuilds));
                dispatch(lockSplicePointsConnectedToBuilds(data.bulkBuilds.map(b => b.id), workspaceId));
            }

            if (data.errorMessages && data.errorMessages.length > 0) {
                data.errorMessages.forEach(message => NotificationService.error(message));
            }

            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.LockBuild, payload: { ids: buildIds, workspaceId } }));
            }
        }
        NotificationService.clear(loadingToast);
    });
};

export const unlockBuilds = (buildIds: number[], workspaceId: number, ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.UnlockingBuilds));
        const service = new BuildService();
        const data = await service.unlockBuilds(buildIds, workspaceId);
        if (data) {
            if (data.confirmUpdateOnBuildIds?.length) {
                NotificationService.clear(loadingToast);
                dispatch(selectBuilds(data.confirmUpdateOnBuildIds));
                dispatch(showDialog(DialogType.UnlockBuild));
            }

            dispatch(unlockBuildsAction(data, workspaceId));

            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.UnlockBuild, payload: { ids: buildIds, workspaceId } }));
            }
        }
        NotificationService.clear(loadingToast);
    });
};

export const unlockBuildsEs = (buildIds: number[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.UnlockingBuilds));
        const service = new BuildService();
        const data = await service.unlockBuildsEs(buildIds, workspaceId);
        if (data) {
            dispatch(unlockBuildsAction(data, workspaceId));
        }
        NotificationService.clear(loadingToast);
    });
};

export const modifyBuild = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const modifiedBuild = await service.modifyBuild(buildId);
        if (modifiedBuild?.type === BuildType.FlexNap) {
            dispatch(actions.updateFlexNapBuild(modifiedBuild as FlexNapBuild));
        }
        if (modifiedBuild?.type === BuildType.Schrodinger) {
            dispatch(actions.updateSchrodingerBuild(modifiedBuild as SchrodingerBuild));
        }
        if (modifiedBuild?.type === BuildType.Bulk) {
            dispatch(actions.updateBulkBuild(modifiedBuild as BulkBuild));
        }
    });
}

export const releaseBuild = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.releaseBuild(buildId));
        const service = new BuildService();
        const releasedBuild = await service.releaseBuild(buildId);
        if (releasedBuild?.type === BuildType.FlexNap) {
            dispatch(actions.updateFlexNapBuild(releasedBuild as FlexNapBuild));
        }
        if (releasedBuild?.type === BuildType.Schrodinger) {
            dispatch(actions.updateSchrodingerBuild(releasedBuild as SchrodingerBuild));
        }
    });
}

export const convertToPretermLateral = (buildId: number, parentNapId: number, parentBuildId: number, ignoreHistory = false, isImporting = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapPretermLateralService();
        if (isImporting) { service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError); }
        const config = await service.convertToPretermLateral(buildId, parentNapId, parentBuildId);
        if (config) {
            const { build, taps, tethers } = config;
            dispatch(actions.updateFlexNapBuild(build));
            if (build.pretermLateral) {
                dispatch(replaceTapsAndTethers({ taps: taps ?? [], tethers: tethers ?? [] }));
                if (!isImporting) {
                    dispatch(selectNap(parentNapId));
                    dispatch(selectTether(build.pretermLateral.parentTetherIds[0]));
                }
                if (!ignoreHistory) {
                    dispatch(push({ type: CommandType.CreatePreterm, payload: { id: buildId, parentNapId, parentBuildId } }));
                }
            }
            if (!isImporting) {
                NotificationService.success(`Build ${build.id} was converted to a preterm lateral.`);
            }
        }
    });
};

export const updateFlexNapPretermLateral = (buildId: number, legType: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapPretermLateralService();
        const build = await service.updatePretermLateral(buildId, legType);
        if (build) {
            dispatch(actions.updateFlexNapBuild(build));
        }
    });
};

export const revertPretermLateral = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapPretermLateralService();
        const build = await service.revertPretermLateral(buildId);
        if (build) {
            dispatch(actions.updateFlexNapBuild(build));
            NotificationService.success(`Preterm lateral build ${build.id} was reverted.`);
        }
    });
};

export const undoRevertPretermLateral = (buildId: number, pretermLateral: PretermLateral) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapPretermLateralService();
        const build = await service.undoRevertPretermLateral(buildId, pretermLateral);
        if (build) {
            dispatch(actions.updateFlexNapBuild(build));
            NotificationService.success(`Preterm lateral build ${build.id} was reverted.`);
        }
    });
};

export const connectBuildToSplicePoint = (buildId: number, splicePointId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkSpliceService();
        const buildSplice = await service.connectBuildToSplicePoint(buildId, splicePointId);
        if (buildSplice) {
            dispatch(actions.connectBuildToSplicePoint({ buildId, buildSplice }));
        }
    });
};

export const disconnectBuildFromSplicePoint = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkSpliceService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.disconnectBuildFromSplicePoint(buildId);
            dispatch(actions.connectBuildToSplicePoint({ buildId, buildSplice: undefined }));
        } catch (error) {
            NotificationService.error(`Error disconnecting build from splice point: ${error}`);
        }
    });
};

export const applyPretermConfig = (buildId: number, fiberCount: number, startingIndex: number, previousTetherIds?: number[], otherPretermTetherIdsOnNap?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapPretermLateralService();
        const config = await service.applyPretermConfig(buildId, fiberCount, startingIndex);
        if (config) {
            const { build, taps, tethers } = config;
            dispatch(actions.updateFlexNapBuild(build));
            if (build.pretermLateral) {
                dispatch(replaceTapsAndTethers({ taps: taps ?? [], tethers: tethers ?? [] }));
                dispatch(selectPretermTether(build.pretermLateral.parentTetherIds));
                if (previousTetherIds) {
                    dispatch(removeTapsAndTethers({
                        tetherIds: previousTetherIds.filter(t => !build.pretermLateral?.parentTetherIds.includes(t)),
                        tapIdsToKeep: taps.map(t => t.id),
                        otherPretermTetherIdsOnNap
                    }));
                }
            }
            NotificationService.success(`Preterm config applied.`);
        }
    });
};

export const undoExportFlexNapBuilds = (builds: FlexNapBuild[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.UnlockingBuilds));
        const service = new BuildService();
        const data = await service.unlockBuilds(builds.map((b) => b.id), workspaceId);
        if (data) {
            dispatch(unlockBuildsAction(data, workspaceId));

            for (const build of builds) {
                const updatedBuild: FlexNapBuild = { ...build, hybrisCcsBuildId: null, hybrisProjectKey: null, uploadedDateTime: null };
                updateFlexNapBuild(build, updatedBuild)(dispatch);
            }
        }
        NotificationService.clear(loadingToast);
    });
}

export const redoExportFlexNapBuilds = (builds: FlexNapBuild[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.LockingBuilds));
        const service = new BuildService();
        const flexNapService = new FlexNapBuildService();
        for (const buildToRedo of builds) {
            // updateBuild requires the current and updated builds to create a patch for updating the difference
            // we can recreate the undone build (current) by setting the build id, project key and uploaded date to null
            const undoneBuild: FlexNapBuild = { ...buildToRedo, hybrisCcsBuildId: null, hybrisProjectKey: null, uploadedDateTime: null };
            const updatedBuild = await flexNapService.updateBuild(undoneBuild, buildToRedo);
            if (updatedBuild) {
                dispatch(actions.updateFlexNapBuild(updatedBuild));
            }
        }

        const data = await service.lockBuilds(builds.map((b) => b.id), workspaceId);
        if (data) {
            if (data.flexNapBuilds && data.flexNapBuilds.length > 0) {
                dispatch(actions.updateFlexNapBuilds(data.flexNapBuilds));
                dispatch(lockNapsConnectedToBuilds(data.flexNapBuilds.map((b) => b.id), workspaceId));
            }

            if (data.errorMessages && data.errorMessages.length > 0) {
                data.errorMessages.forEach(message => NotificationService.error(message));
            }
        }
        NotificationService.clear(loadingToast);
    });
}

export const updateBuildSegmentsConnectedToElement = (element: Element) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new SegmentService();
        const segments = await service.updateConnectedToElement(element.id);
        segments && dispatch(updateSegments(segments));
        segments?.length && showUpdateInfrastructureSnackbar(element.tagOverride ?? element.tag)(dispatch);
    };
};

export const uploadBuild = (buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Loading))
        const service = new SchrodingerBuildService();
        const build = await service.uploadBuild(buildId);
        if (build) {
            dispatch(actions.updateSchrodingerBuild(build));
            const { id: buildId, catalogCode } = build;
            NotificationService.success(i18n.t(LocalizationKeys.BuildUploadSuccess, { buildId, catalogCode }));
        }
        NotificationService.clear(loadingToast);
    };
};

export const convertToFlexnapBuild = (bulkBuildId: number, flexnapBuildId?: number, childBuilds?: Build[], ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Loading));
        const service = new BulkBuildService();
        const result = await service.convertToFlexnapBuild({ bulkBuildId, flexnapBuildId });
        if (result) {
            dispatch(actions.createFlexNapBuild(result.build));
            dispatch(actions.replaceBuildSegments({ buildId: bulkBuildId, segments: result.segments }));
            const elementIds = result.segments.reduce((p, { fromId, toId }) => {
                fromId && p.add(fromId);
                toId && p.add(toId);
                return p;
            }, new Set<number>());

            dispatch(actions.deleteBulkBuild(bulkBuildId));
            dispatch(selectSegment());
            dispatch(selectBuild());

            childBuilds?.forEach(b => dispatch(disconnectBuildFromSplicePoint(b.id)));

            dispatch(deleteSplicePointsForBuilds([bulkBuildId]));
            // Loads FlexNap Naps. Does undo delete on the Naps if they were deleted
            dispatch(undoDeleteNapsForBuild(result.build.id));
            dispatch(linkNapsOnElementsToBuild(Array.from(elementIds), result.build.id));
            dispatch(push({ type: CommandType.ConvertBulkToFlexnapCable, payload: { bulkId: bulkBuildId, fnapId: result.build.id, childBuilds } }));
            NotificationService.success(i18n.t(LocalizationKeys.BuildConversionSuccess));
        }
        NotificationService.clear(loadingToast);
    });
};

export const undoConvertToFlexnapBuild = (bulkBuildId: number, flexnapBuildId: number, childBuilds?: Build[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Loading));
        const service = new BulkBuildService();
        const result = await service.undoConvertToFlexnapBuild({ bulkBuildId, flexnapBuildId });
        if (result) {
            dispatch(actions.createBulkBuild(result.build));
            dispatch(actions.replaceBuildSegments({ buildId: flexnapBuildId, segments: result.segments }));
            const elementIds = result.segments.reduce((p, { fromId, toId }) => {
                fromId && p.add(fromId);
                toId && p.add(toId);
                return p;
            }, new Set<number>());

            dispatch(actions.deleteFlexNapBuild(flexnapBuildId));
            dispatch(deleteNapsForBuilds([flexnapBuildId]));
            dispatch(undoDeleteSplicePointsForBuilds([bulkBuildId]));
            dispatch(linkSplicePointsOnElementsToBuild(Array.from(elementIds), result.build.id))
            dispatch(clearSelection());
            childBuilds?.forEach(b => b.buildSplice?.parentSplicePointId && dispatch(connectBuildToSplicePoint(b.id, b.buildSplice?.parentSplicePointId)));
            NotificationService.success(i18n.t(LocalizationKeys.UndoBuildConversionSuccess));
        }
        NotificationService.clear(loadingToast);
    });
};