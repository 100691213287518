import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Cstic } from "../../../../models/cstic";
import { PresetContext, PresetWizardMaterials, PresetWizardCstics } from '../preset-config/preset-config.types';
import { LocalizationKeys } from '../../../../locales/types';
import { StateModel } from '../../../../redux/reducers';
import { UnitInterpreter } from "../../../../helpers/unit-interpreter";
import { ConfigSession } from '../../../../models/config-session';
import { Units } from '../../../../models/units';
import { SlackProps } from './slack.types';

export const useSlack = ({ onPreviousClick, onNextClick }: SlackProps) => {
    const { t } = useTranslation();
    const displayUnits = useSelector((state: StateModel) => state.authentication.displayUnits);
    const [coSlack, setCoSlack] = useState(0);
    const [fieldSlack, setFieldSlack] = useState(0);
    const [coCstic, setCoCstic] = useState<Cstic | undefined>();
    const [fieldCstic, setFieldCstic] = useState<Cstic | undefined>();

    const [coMinimum, setCoMinimum] = useState(0);
    const [fieldMinimum, setFieldMinimum] = useState(0);

    const [coMinimumLabel, setCoMinimumLabel] = useState("");
    const [fieldMinimumLabel, setFieldMinimumLabel] = useState("");

    const [nextDisabled, setNextDisabled] = useState(true);

    const { session, loading, updateSession } = useContext(PresetContext);

    const setCo = useCallback((session: ConfigSession, units: Units) => {
        const coSlack = session.coSlack;
        const coMinimum = session.coSlackMinimum;
        if (coSlack && coMinimum) {
            setCoCstic(coSlack);
            setCoSlack(parseFloat(coSlack.valueId ?? "0"));
            const minimum = parseFloat(coMinimum.valueId ?? "0");
            setCoMinimum(minimum);
            setCoMinimumLabel(t(LocalizationKeys.MinimalValue, { value: `${minimum} ${UnitInterpreter.toShort(units)}` }));
        }
    }, [t]);

    const setField = useCallback((session: ConfigSession, units: Units) => {
        const fieldSlack = session.fieldSlack;
        const fieldMinimum = session.fieldSlackMinimum;
        if (fieldSlack && fieldMinimum) {
            setFieldCstic(fieldSlack);
            setFieldSlack(parseFloat(fieldSlack.valueId ?? "0"))
            const minimum = parseFloat(fieldMinimum.valueId ?? "0");
            setFieldMinimum(minimum);
            setFieldMinimumLabel(t(LocalizationKeys.MinimalValue, { value: `${minimum} ${UnitInterpreter.toShort(units)}` }));
        }
    }, [t]);

    useEffect(() => {
        if (!session) {
            return;
        }
        setCo(session, displayUnits);
        setField(session, displayUnits);

    }, [session, displayUnits, setCo, setField]);

    useEffect(() => {
        setNextDisabled(coSlack < coMinimum || fieldSlack < fieldMinimum);
    }, [coSlack, coMinimum, fieldSlack, fieldMinimum]);

    const handleCoSlackChange = useCallback((event: React.ChangeEvent<any>) => {
        setCoSlack(+event.target.value);
    }, []);

    const handleFieldSlackChange = useCallback((event: React.ChangeEvent<any>) => {
        setFieldSlack(+event.target.value);
    }, []);

    const handleNextClick = useCallback(async () => {
        if (coCstic && fieldCstic) {
            await updateSession(coCstic.instanceId, coCstic.id, coSlack.toString());
            await updateSession(fieldCstic.instanceId, fieldCstic.id, fieldSlack.toString());
            if (session) {
                const dnetAssemblyMaterial = session.materials.find(m => m.id === PresetWizardMaterials.DNET_ASSEMBLY);
                if (dnetAssemblyMaterial) {
                    await updateSession(dnetAssemblyMaterial.instanceId, PresetWizardCstics.START_PLAN_YN, "Y");
                }
            }

            if (onNextClick) {
                onNextClick();
            }
        }
    }, [session, coCstic, fieldCstic, coSlack, fieldSlack, updateSession, onNextClick]);

    return {
        coSlack, fieldSlack, coMinimum, fieldMinimum, coMinimumLabel, fieldMinimumLabel, loading, nextDisabled,
        handleCoSlackChange, handleFieldSlackChange, handlePreviousClick: onPreviousClick, handleNextClick
    };
};