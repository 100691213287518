import { Graphics, IPointData } from 'pixi.js';

import { DropShadowFilter } from '@pixi/filter-drop-shadow';

import { TOOLBAR_PROPORTIONS } from '../build/build.types';

export const useToolbar = (position: IPointData, numberOfChildren: number) => {
    const { x, y } = position;

    const width = numberOfChildren * TOOLBAR_PROPORTIONS.ITEM_WIDTH;
    const height = TOOLBAR_PROPORTIONS.ITEM_HEIGHT;
    const xPosition = x - (width / 2);
    const yPosition = y - (height / 2);

    const drawBackground = (graphics: Graphics) => {
        graphics.clear();
        graphics.beginFill(0xE1F5FC, 1);
        graphics.drawRoundedRect(0, 0, width, height, TOOLBAR_PROPORTIONS.ROUNDED_CORNER);
        graphics.filters = [
            new DropShadowFilter({
                color: 0x000000,
                alpha: 0.2,
                blur: 0.4,
                distance: 1.3,
                rotation: 90
            }),
            new DropShadowFilter({
                color: 0x000000,
                alpha: 0.3,
                blur: 0,
                distance: 1,
                rotation: 90
            })
        ];
        graphics.endFill();
    };
    
    return {
        xPosition, yPosition,
        width, height,
        drawBackground
    };
};