import './preset-cstic-group.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectChangeEvent } from '@mui/material';
import { FilledTextField } from '@orbit/text-field';
import { MainPalettes } from '@orbit/theme-provider';

import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { Select } from '../../../ui-elements/select';
import { PresetNavigationFooter } from '../../preset-navigation-footer';
import { PresetWizardSteps, PresetWizardTextCstics } from '../preset-config/preset-config.types';
import { usePresetCsticGroup } from './preset-cstic-group.hooks';
import { PresetCsticGroupProps, PresetCsticOverrides } from './preset-cstic.types';

export const PresetCsticGroup = (props: PresetCsticGroupProps): JSX.Element => {
    const { t } = useTranslation();

    const { step, label, progressData, goToPreviousStep } = props;

    const { loading, orderedCstics, isNextDisabled, powerTetherLegLength, updateCstic, setPowerTetherLegLength, handleNextClick } = usePresetCsticGroup(props);

    return (<div className="cstic-group-container">
        {!orderedCstics ? null :
            <>
                <div className="subheader">
                    <label>{label}</label>
                </div>
                {orderedCstics.map(cstic => {
                    return (PresetWizardTextCstics.find(csticId => cstic.id === csticId && step === PresetWizardSteps.PowerTetherAttributes) ?
                        <FilledTextField
                            palette={MainPalettes.primary}
                            type='number'
                            key={`${cstic.instanceId}_${cstic.id}`}
                            name={cstic.description}
                            value={Number(powerTetherLegLength)}
                            label={PresetCsticOverrides[cstic.id] ? t(PresetCsticOverrides[cstic.id].label) : cstic.description}
                            restrictions={[{ validate: (inputValue: string) => Number(inputValue) < 0 }]}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setPowerTetherLegLength(event.target.value)}
                            disabled={loading}
                            fullWidth
                        />
                        :
                        <Select
                            key={`${cstic.instanceId}_${cstic.id}`}
                            name={cstic.description}
                            label={PresetCsticOverrides[cstic.id] ? t(PresetCsticOverrides[cstic.id].label) : cstic.description}
                            value={cstic.values.find(v => v.id === cstic.valueId) ? cstic.valueId : ''}
                            values={cstic.values.filter(v => v.id).map(v => v.id)}
                            displayValues={cstic.values.filter(v => v.id).map(v => v.shortDescription || '')}
                            onChange={(event: SelectChangeEvent<any>): void => { updateCstic(cstic, event.target.value) }}
                            disabled={loading}
                        />
                    );
                })}
            </>
        }
        {(loading && !orderedCstics) && <div className="dna-spinner-container"><DnaSpinner /></div>}
        <PresetNavigationFooter
            previousDisabled={loading}
            nextDisabled={loading || isNextDisabled}
            showPrevious={progressData.length > 1}
            onPreviousClick={goToPreviousStep}
            onNextClick={handleNextClick}
        />
    </div>);
}