import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../../locales/types';
import { BuildSegment } from '../../../../models/build-segment';
import { deleteSegment, undoDeleteSegment } from '../../../../redux/build.state';
import { Command } from '../../../command';

interface AddSpanCommandPayload {
    segment: BuildSegment;
    awaitSessionUpdate: boolean;
}

export class AddSpanCommand extends Command {
    private segment: BuildSegment;
    private awaitSessionUpdate: boolean;

    constructor({ segment, awaitSessionUpdate }: AddSpanCommandPayload) {
        super();
        this.segment = segment;
        this.awaitSessionUpdate = awaitSessionUpdate;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoSpanAddition };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoSpanAddition };
    }

    public undo(dispatch: Dispatch): void {
        deleteSegment(this.segment, false, undefined, undefined, this.awaitSessionUpdate)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        undoDeleteSegment(this.segment, false, this.awaitSessionUpdate)(dispatch);
        super.redo(dispatch);
    }
}