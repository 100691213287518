import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Build } from '../../models/build';
import { StateModel } from '../../redux/reducers';
import { useAccessPointLocationId } from '../access-point-diagram/access-point-diagram.hooks';
import { SplicePointDiagramProps } from './splice-point-diagram.types';

export const useSplicePointDiagramHooks = (props: SplicePointDiagramProps) => {
    const { splicePoint } = props;
    
    const state = useSelector((state: StateModel) => state);
    const locationId = useAccessPointLocationId(state, splicePoint.elementId, splicePoint.buildId);
    const { flexnapBuilds, bulkBuilds } = state.build;

    const [connectedBuilds, setConnectedBuilds] = useState<Build[]>([]);

    useEffect(() => {
        setConnectedBuilds([...flexnapBuilds, ...bulkBuilds]
            .filter(b => b.buildSplice?.parentSplicePointId === splicePoint.id)
        );
    }, [splicePoint, flexnapBuilds, bulkBuilds]);

    return { 
        locationId, connectedBuilds
    };
};