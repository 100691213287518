import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useLockPermission } from '../../../../../../hooks/lock-permission.hooks';
import { BuildType } from '../../../../../../models/build-type';
import { lockBuilds } from '../../../../../../redux/build.state';
import { DialogType, showDialog } from '../../../../../../redux/overlay.state';
import { updateBuildValidation } from '../../../../../../redux/schrodinger/validation.state';
import { selectBuild } from '../../../../../../redux/selection.state';
import { DrawerMenuContext } from '../../../drawer-menu.types';
import { BuildToolbarProps } from './build-toolbar.types';

export const useBuildToolbar = (props: BuildToolbarProps) => {
    const { build, workspaceId } = props;
    const {
        selectedBuildIds,
        setSingleBuildIdToDelete,
        setIsDeleteDialogOpen,
        setSelectedBuildIds,
    } = useContext(DrawerMenuContext);
    const dispatch = useDispatch();
    const { lockButtonDisabled, unlockButtonDisabled } = useLockPermission();

    const { id: buildId, type: buildType } = build;
    const isSelected = selectedBuildIds.includes(build.id);

    const isLocked = !!build.lockedById;
    const isExported = buildType === BuildType.FlexNap && !!build.uploadedDateTime;
    const lockIcon = isLocked ? "lock" : "lock_open";

    const isDeleteDisabled = isSelected || !!build.lockedById;
    const isLockDisabled = isLocked ? unlockButtonDisabled(buildId) : lockButtonDisabled(buildId);
    const showExportedToolbar = isExported;

    const onDeleteClick = useCallback(() => {
        setSingleBuildIdToDelete(buildId);
        setIsDeleteDialogOpen(true);
    }, [buildId, setIsDeleteDialogOpen, setSingleBuildIdToDelete]);

    const lockBuild = useCallback(async (): Promise<void> => {
        if (buildType === BuildType.Schrodinger) {
            await updateBuildValidation(workspaceId, buildId)(dispatch);
        }
        dispatch(lockBuilds([buildId], workspaceId));
    }, [dispatch, buildId, buildType, workspaceId]);

    const unlockBuild = useCallback(() => {
        dispatch(selectBuild(buildId));
        dispatch(showDialog(DialogType.UnlockBuild));
    }, [dispatch, buildId]);

    const onLockIconClick = useCallback(() => {
        if (isLocked) {
            unlockBuild();
        } else {
            lockBuild();
        }
    }, [isLocked, unlockBuild, lockBuild]);

    const onSelectionChanged = useCallback((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newSelectedBuildIds = checked
            ? [...selectedBuildIds, buildId]
            : selectedBuildIds.filter((i) => i !== buildId);
        setSelectedBuildIds(newSelectedBuildIds);
    }, [buildId, selectedBuildIds, setSelectedBuildIds]);

    return { isExported, showExportedToolbar, onDeleteClick, onLockIconClick, lockIcon, isDeleteDisabled, isLockDisabled, isSelected, onSelectionChanged };
};
