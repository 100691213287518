import { SplicePoint } from '../../models/splice-point';
import { SecuredService } from '../abstract-secured-v2.service';

export class SplicePointService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/splicepoint';

    public getAll(workspaceId: number): Promise<SplicePoint[] | undefined> {
        return this.get(`${this.baseUrl}?workspaceId=${workspaceId}`);
    }
    
    public addSplicePoint(elementId: number, workspaceId: number, buildId: number | null = null): Promise<SplicePoint | undefined> {
        return this.post(this.baseUrl, { elementId, workspaceId, buildId });
    }

    public deleteSplicePoint(splicePointId: number): Promise<undefined> {
        return this.delete(`${this.baseUrl}/${splicePointId}`);
    }

    public deleteSplicePointsForBuilds(buildIds: number[]): Promise<undefined> {
        return this.post(`${this.baseUrl}/delete`, { buildIds });
    }

    public undoDeleteSplicePointsForBuilds(buildIds: number[]): Promise<SplicePoint[] | undefined> {
        return this.post(`${this.baseUrl}/undo/delete`, { buildIds });
    }

    public moveSplicePoint(splicePointId: number, toElementId: number, toBuildId: number | null): Promise<SplicePoint | undefined> {
        return this.post(`${this.baseUrl}/move/${splicePointId}`, { toElementId, toBuildId });
    }

    public linkSplicePointToBuild(splicePointId: number, buildId: number): Promise<void> {
        return this.post(`${this.baseUrl}/link/${splicePointId}/build/${buildId}`);
    }

    public unlinkSplicePointFromBuild(splicePointId: number, buildId: number): Promise<void> {
        return this.post(`${this.baseUrl}/unlink/${splicePointId}/build/${buildId}`);
    }

    public linkSplicePointsOnElementsToBuild(elementIds: number[], buildId: number): Promise<SplicePoint[] | undefined> {
        return this.post(`${this.baseUrl}/linkto`, { elementIds, buildId });
    }

    public unlinkSplicePointsFromBuild(buildId: number): Promise<void> {
        return this.post(`${this.baseUrl}/unlink/${buildId}`);
    }

    public lockSplicePointsConnectedToBuilds(buildIds: number[], workspaceId: number): Promise<SplicePoint[] | undefined> {
        return this.post(`${this.baseUrl}/lock`, { buildIds, workspaceId });
    }

    public unlockSplicePointsConnectedToBuilds(buildIds: number[], workspaceId: number): Promise<SplicePoint[] | undefined> {
        return this.post(`${this.baseUrl}/unlock`, { buildIds, workspaceId });
    }

    public modifySplicePoint(splicePointId: number): Promise<SplicePoint | undefined> {
        return this.post(`${this.baseUrl}/modify`, { splicePointId });
    }

    public releaseSplicePoint(splicePointId: number): Promise<SplicePoint | undefined> {
        return this.post(`${this.baseUrl}/release`, { splicePointId });
    }
}