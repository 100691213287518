import React from 'react';

import { ScrollDrag } from '../scroll-drag';
import { ProgressItem } from '../progress-item/progress-item';
import { ProgressIndicatorProps } from './progress-indicator.types';
import './progress-indicator.scss';

export const ProgressIndicator = (props: ProgressIndicatorProps): JSX.Element => {
    const { data, selectedIndex, onClick } = props;

    return (
        <ScrollDrag className="progress-container">
            {data.map((data, index) => <ProgressItem key={data.id} data={data} index={index} selectedIndex={selectedIndex} onClick={onClick} />)}
        </ScrollDrag>
    )
}