import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class PartValidator implements Validator<FlexNapBuildData> {

    public validate(build: FlexNapBuildData): ValidationResult | null {
        if (!build.part) {
            return {
                buildId: build.id,
                itemId: null,
                itemType: ItemType.Build,
                shortDescription: i18next.t(LocalizationKeys.ErrorInvalidPartNumber),
                type: ValidationResultType.Error,
                extent: build.getExtent()
            };
        }

        return null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapBuildData;
    }
}
