import React from 'react';

import { Container } from '@inlet/react-pixi';

import { cableStartLocation, CanvasBuildProps, IFrameProps } from './build.types';
import { CO } from './co/co';
import { Location } from './location/location';
import { Segment } from './segment/segment';
import { Slack } from './slack/slack';
import { SlackType } from './slack/slack.types';
import { StatusButton } from './status-button/status-button';

export const Build: React.FC<IFrameProps & CanvasBuildProps> = (props) => {
    const cableLocation = cableStartLocation(props.position);
    const {
        build,
        segments, temporarySpans,
        displayUnits,
        position,
    } = props;

    const drawCo = !!(temporarySpans || segments?.length);
    const drawBuild = !temporarySpans && !!build;

    return (
        <Container sortableChildren={true}>
            {drawCo && <CO position={props.position} />}
            {drawBuild && build?.lockedById && (
                <StatusButton
                    build={build}
                    position={cableLocation}
                />
            )}
            {drawBuild && (
                <Slack
                    type={SlackType.CO}
                    index={0}
                    span={build?.coSlack}
                    unit={build?.slackUnit}
                    position={position}
                    locked={!!build?.lockedById}
                />
            )}
            {
                temporarySpans ? (
                    temporarySpans.length ? (
                        temporarySpans.map((span, index) => (
                            <Segment
                                key={index}
                                segmentId={undefined}
                                index={index}
                                span={span}
                                unit={displayUnits}
                                position={position}
                                disabled={true}
                            />
                        ))
                    ) : <Location position={position} index={0} disabled={true} />
                ) : (
                    segments?.sort((a, b) => a.position - b.position).map((segment, index) => (
                        <Segment
                            key={index}
                            segmentId={segment.id}
                            index={index}
                            span={segment.designSpan}
                            unit={segment.designSpanUnit}
                            position={position}
                            locked={!!build?.lockedById}
                        />
                    ))
                )
            }
            {drawBuild && (
                <Slack
                    type={SlackType.FIELD}
                    index={segments?.length ?? 0}
                    span={build?.fieldSlack}
                    unit={build?.slackUnit}
                    position={position}
                    locked={!!build?.lockedById}
                />
            )}
        </Container>
    );
};
