import * as rfc from 'rfc6902';

import { Handhole, HandholeData } from '../models/handhole';
import { PointLike } from '../models/pointlike';
import { SecuredService } from './abstract-secured-v2.service';

export class HandholeService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/handhole';

    public getAll(): Promise<Handhole[] | undefined> {
        return this.get<Handhole[]>(this.baseUrl);
    }

    public getHandholes(bbox: number[], required?: number[]): Promise<Handhole[] | undefined> {
        return this.post<Handhole[]>(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public getHandholesByIds(ids: number[]): Promise<Handhole[] | undefined> {
        return this.post<Handhole[]>(`${this.baseUrl}/byids`, ids);
    }

    public addHandhole(point: PointLike): Promise<Handhole | undefined> {
        return this.post(this.baseUrl, point);
    }

    public addHandholes(data: HandholeData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/import`, { data, importedGisDataId });
    }

    public patchHandhole(oldHandhole: Handhole, newHandhole: Handhole): Promise<Handhole | undefined> {
        const patch = rfc.createPatch(oldHandhole, newHandhole);
        return this.patch(`${this.baseUrl}/${oldHandhole.id}`, patch);
    }
}
