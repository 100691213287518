import i18next from 'i18next';

import { LocalizationKeys } from '../locales/types';
import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { BuildType } from '../models/build-type';
import { Nap } from '../models/nap';
import { Tap } from '../models/tap';
import { Tether } from '../models/tether';

export class BuilderHelper {
    public static getBuildDisplayId(build: Build | undefined): string {
        if (build?.type === BuildType.FlexNap) {
            return i18next.t(LocalizationKeys.BuildNumber, { buildId: build.id });
        }
        
        if (build?.type === BuildType.Schrodinger) {
            return i18next.t(LocalizationKeys.BuildNumber, { buildId: build.id });
        }
        
        if (build?.type === BuildType.Bulk) {
            return i18next.t(LocalizationKeys.BulkBuildNumber, { buildId: build.id });
        }
        
        return i18next.t(LocalizationKeys.NotApplicable);
    }

    public static getBuildsFromSegments(builds: Build[], segments: BuildSegment[]): Build[] {
        const buildIds = new Set(segments.map(s => s.buildId))
        return builds.filter(b => buildIds.has(b.id));
    }

    public static getBuildFiberCount(buildNaps: Nap[], taps: Tap[], tethers: Tether[]): number {
        return buildNaps.reduce((fibers, nap) => {
            const tapsBuild = taps.filter(t => t.napId === nap.id);
            const tethersBuild = tethers.filter(t => tapsBuild.some(ta => ta.id === t.tapId));
            return fibers + tethersBuild.reduce((sum, t) => sum + t.fiberCount, 0);
        }, 0)
    }
}
