/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useSscSession } from '../../../../hooks/ssc-session.hooks';
import { addPreset } from '../../../../redux/schrodinger-preset.state';
import { schrodingerPresetSelector } from '../../../../selectors/root.selectors';
import { PresetManagerContext } from '../../preset-manager.types';
import { LocalizationKeys } from '../../../../locales/types';
import { CardInputMaxLength } from '../../../card/card.types';

export const usePresetConfig = () => {
    const { t } = useTranslation();

    const schrodingerPresets = useSelector(schrodingerPresetSelector);
    const [presetName, setPresetName] = useState(t(LocalizationKeys.DefaultPresetName, { index: schrodingerPresets.presets.length + 1 }).toString());

    const isPresetNameValid = presetName && presetName.trim().length !== 0;
    const dispatch = useDispatch();

    const { updateConfigMode } = useContext(PresetManagerContext);

    const { session, loading, updateSession, undoCstics } = useSscSession({ errorCallback: updateConfigMode });

    const savePreset = useCallback((isDefault: boolean) => {
        if (isPresetNameValid && presetName.length <= CardInputMaxLength.PRESET_NAME) {
            if (session) {
                addPreset(session.sessionId, presetName, isDefault)(dispatch);
            }
            updateConfigMode();
        } else {
            setPresetName("");
        }
    }, [dispatch, session, presetName, updateConfigMode, isPresetNameValid]);

    const handlePresetNameChange = useCallback((event: React.ChangeEvent<any>): void => {
        const presetName = event.target?.value;
        if (presetName.length <= CardInputMaxLength.PRESET_NAME) {
            setPresetName(presetName);
        }
    }, []);

    return {
        presetName, handlePresetNameChange, isPresetNameValid,
        session, updateSession, loading, undoCstics, savePreset
    };
};