import { Dispatch } from 'redux';

import { redo, undo } from '../redux/history.state';

export abstract class Command implements UndoCommand, RedoCommand {
    public isSubCommand = false;
    public undoMessage: CommandMessage = { message: "" };
    public redoMessage: CommandMessage = { message: "" };

    public undo(dispatch: Dispatch): void {
        !this.isSubCommand && dispatch(undo());
    }

    public redo(dispatch: Dispatch): void {
        !this.isSubCommand && dispatch(redo());
    }
}

export interface UndoCommand {
    undoMessage: CommandMessage;
    undo: (dispatch: Dispatch, undoHistory: boolean) => void;
}

export interface RedoCommand {
    redoMessage: CommandMessage;
    redo: (dispatch: Dispatch, redoHistory: boolean) => void;
}

interface CommandMessage {
    message: string;
    options?: any;
}
