import React from "react"

export const SplicePlanIcon = ({ disabled }: { disabled?: boolean }) => {
    const fill = disabled ? "#00000042" : "#005293";
    return (
        <svg id="splice-plan-icon" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
            <defs>
                <clipPath id="clippath">
                    <rect width="24" height="24" fill="none" strokeWidth={"0px"} />
                </clipPath>
            </defs>
            <g clipPath="url(#clippath)">
                <path d="m4.22,4.87c.79-.62,1.78-.93,2.78-.86h2s0,0,0,0h13v2h-1v6c.55,0,1,.45,1,1v7h-4v-7c0-.55.45-1,1-1v-6h-3v6c.55,0,1,.45,1,1v7h-4v-7c0-.55.45-1,1-1v-6h-3v6c.55,0,1,.45,1,1v7h-4v-7c0-.55.45-1,1-1v-6h-2s0,0,0,0c-.57.02-1.12.25-1.52.65-.32.37-.48.85-.48,1.35h0s0,0,0,0v4c.55,0,1,.45,1,1v7H2v-7c0-.55.45-1,1-1v-2h0v-2c-.04-1.17.4-2.3,1.22-3.14Z" fill={fill} fillRule="evenodd" strokeWidth="0px" />
            </g>
        </svg>
    )
}