import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { TetherTerminalContainerProps } from '../../nap-diagram.types';

export const useTetherTerminalContainer = ({ tapIndex, tetherIndex, tetherId, selectedTetherId, children, onClick }: TetherTerminalContainerProps) => {
    const offset = (tapIndex === 1 ? 0 : 175);
    const [x, setX] = useState(offset + (tetherIndex === 1 ? 16 : 97));
    const [y] = useState(70);
    const [height] = useState(264);
    const [width] = useState(72);
    const [showEditButton, setShowEditButton] = useState(!!tetherId);

    const [className, setClassName] = useState(
        classnames('tether-terminal-container', {
            selected: selectedTetherId && selectedTetherId === tetherId
    }));

    useEffect(() => {
        setClassName(
            classnames('tether-terminal-container', {
                selected: selectedTetherId && selectedTetherId === tetherId
            })
        );
    }, [tetherId, selectedTetherId]);

    useEffect(() => {
        setX(offset + (tetherIndex === 1 ? 16 : 97));
    }, [offset, tetherIndex]);

    useEffect(() => {
        setShowEditButton(!!tetherId);
    }, [tetherId]);

    const handleOnClick = useCallback((): void => {
        if (onClick) {
            onClick(tetherId);
        }
    }, [tetherId, onClick]);

    return { x, y, height, width, className, children, showEditButton, onClick: handleOnClick };
};