import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { moveTap } from '../../../redux/schrodinger/tap.state';
import { Command } from '../../command';

export interface MoveTapPayload {
    tapId: number;
    fromElementId: number;
    toElementId: number;
    fromBuildId: number | null;
    toBuildId: number | null;
}

export class MoveTapCommand extends  Command {
    private tapId: number;
    private fromElementId: number;
    private toElementId: number;
    private fromBuildId: number | null;
    private toBuildId: number | null;

    constructor({ tapId, fromElementId, toElementId, fromBuildId, toBuildId }: MoveTapPayload) {
        super();
        this.tapId = tapId;
        this.fromElementId = fromElementId;
        this.toElementId = toElementId;
        this.fromBuildId = fromBuildId;
        this.toBuildId = toBuildId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoTapMove };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoTapMove };
        if (fromElementId === toElementId) {
            if (fromBuildId && !toBuildId) {
                this.undoMessage = { message: UndoRedoLocalizationKeys.UndoTapDisconnection };
                this.undoMessage = { message: UndoRedoLocalizationKeys.RedoTapDisconnection };
            }
            else if (!fromBuildId && toBuildId) {
                this.undoMessage = { message: UndoRedoLocalizationKeys.UndoTapConnection };
                this.redoMessage = { message: UndoRedoLocalizationKeys.RedoTapConnection };
            }
        }
    }

    public async undo(dispatch: Dispatch) {
        await moveTap(this.tapId, this.toElementId, this.fromElementId, this.toBuildId, this.fromBuildId, true)(dispatch);
        super.undo(dispatch);
    }

    public async redo(dispatch: Dispatch) {
        await moveTap(this.tapId, this.fromElementId, this.toElementId, this.fromBuildId, this.toBuildId, true)(dispatch);
        super.redo(dispatch);
    }
}
