import { BoreData } from '../models/bore';
import { BuildSegment } from '../models/build-segment';
import { BuildType } from '../models/build-type';
import { BulkBuild } from '../models/bulk/bulk-build';
import { CabinetData } from '../models/cabinet';
import { DesignArea } from '../models/design-area';
import { FlexNapBuild } from '../models/flexnap-build';
import {
    GeoJsonFeature, GeoJsonFeatureCollection, GeoJsonFeatureGeometry
} from '../models/geojson';
import { HandholeData } from '../models/handhole';
import { ImportedGisData } from '../models/imported-gis-data';
import { ManholeData } from '../models/manhole';
import { Nap } from '../models/nap';
import { ParcelData } from '../models/parcel';
import { PoleData } from '../models/pole';
import { PoleSpanData } from '../models/polespan';
import { SplicePoint } from '../models/splice-point';
import { Tap } from '../models/tap';
import { Tether } from '../models/tether';
import { TrenchData } from '../models/trench';
import { VaultData } from '../models/vault';
import { SecuredService } from './abstract-secured-v2.service';

export class ImportService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/import';

    public filter(payload: ImportPayload): Promise<ImportPayload | undefined> {
        return this.post(`${this.baseUrl}/filter`, payload);
    }

    public getImportedGisData(): Promise<ImportedGisData[] | undefined> {
        return this.get(`${this.baseUrl}/gisdata`);
    }

    public addImportedGisData(fileName: string): Promise<ImportedGisData | undefined> {
        return this.post(`${this.baseUrl}/gisdata`, { fileName });
    }

    public deleteImportedGisData(importedGisDataId: number): Promise<DeleteImportedGisDataResult | undefined> {
        return this.delete(`${this.baseUrl}/gisdata/${importedGisDataId}`);
    }

    public getParentBuildOptions(buildPayload: ImportBuildPayload, accessPoints: GeoJsonFeature[]): Promise<string[] | undefined> {
        return this.post(`${this.baseUrl}/parentBuildOptions`, { buildPayload, accessPoints });
    }

    public getParentAccessPointId(buildId: number, parentBuildId: number): Promise<number | undefined> {
        return this.post(`${this.baseUrl}/parentConnectionIds`, { buildId, parentBuildId });
    }

    public importFlexNapBuild(request: ImportBuildRequest): Promise<ImportFlexNapBuildResponse | undefined> {
        return this.post(`${this.baseUrl}/flexnap`, request);
    }

    public importBulkBuild(request: ImportBuildRequest): Promise<ImportBulkBuildResponse | undefined> {
        return this.post(`${this.baseUrl}/bulk`, request);
    }

    public importDesignArea(request: ImportDesignAreasRequest): Promise<DesignArea[] | undefined> {
        return this.post(`${this.baseUrl}/designareas`, request);
    }

    public async transformToCorningFormatAsync(file: File): Promise<GeoJsonFeatureCollection | undefined> {
        const fd = new FormData();
        fd.append('importFile', file);
        return this.post<GeoJsonFeatureCollection | undefined>(`${this.baseUrl}/transform`, fd);
    }
}

export interface ImportPayload {
    poles: PoleData[];
    poleSpans: PoleSpanData[];
    parcels: ParcelData[];
    manholes: ManholeData[];
    handholes: HandholeData[];
    cabinets: CabinetData[];
    vaults: VaultData[];
    trenches: TrenchData[];
    bores: BoreData[];
    foundDuplicateTags?: boolean;
    fileName?: string;
}

interface DeleteImportedGisDataResult {
    deletedFlexNapBuildIds: number[];
    deletedBulkBuildIds: number[];
    deletedNapIds: number[];
    deletedPathIds: number[];
    deletedDesignAreaIds: number[];
}

export interface ImportDesignFileFormat {
    elements: GeoJsonFeatureCollection;
    paths: GeoJsonFeatureCollection;
    builds: GeoJsonFeatureCollection;
    accessPoints: GeoJsonFeatureCollection;
    slackLoops: GeoJsonFeatureCollection;
    designAreas: GeoJsonFeatureCollection;
}

export interface ImportBuildPayload {
    id: string;
    buildType: BuildType;
    cableName: string;
    fiberCount: string;
    calculatedLength: number;
    measuredLength: number;

    geometry: GeoJsonFeatureGeometry;

    slackLoops: GeoJsonFeature[];
    accessPoints: GeoJsonFeature[];
}

export interface ImportBuildRequest extends ImportBuildPayload {
    presetId: number;
    workspaceId: number;
    parentCustomerBuildId: string;
}

export interface ImportDesignAreaPayload {
    id: string;
    name: string;
    projectId: string;
    description: string;
    country: string;
    region: string;
    city: string;
    zipCode: string;
    color: string;
    
    geometry: GeoJsonFeatureGeometry;
}

export interface ImportDesignAreasRequest {
    designAreaPayloads: ImportDesignAreaPayload[];
    workspaceId: number;
    importedGisDataId?: number;
}
export interface BuildToImportRow {
    buildType: BuildType,
    customerBuildId: string;
    fiberCount: number;
    accessPointCount: number;
    parentCustomerBuildIdOptions: string[] | undefined;
}

export interface ImportFlexNapBuildResponse {
    build: FlexNapBuild;
    segments: BuildSegment[];
    naps: Nap[];
    taps: Tap[];
    tethers: Tether[];
    warnings: string[];
    errors: string[];
}

export interface ImportBulkBuildResponse {
    build: BulkBuild;
    segments: BuildSegment[];
    splicePoints: SplicePoint[];
    warnings: string[];
    errors: string[];
}