import { IPointData } from 'pixi.js';

import { UnitInterpreter } from '../../../../helpers/unit-interpreter';
import {
    cableStartLocation, IFrameProps, LOCATION_PROPORTIONS, SEGMENT_PROPORTIONS
} from '../build.types';
import { CanvasTextStyles } from '../build.styles';
import slackCoLockedOutline from './assets/slack-co-locked-outline.svg';
import slackCo from './assets/slack-co.svg';
import slackFieldLockedOutline from './assets/slack-field-locked-outline.svg';
import slackField from './assets/slack-field.svg';
import { SlackProps, SlackType } from './slack.types';

export const getLocationPosition = (index: number, type: SlackType, position: IPointData) => {
    const isStart = type === SlackType.CO;
    const { x, y } = position;
    let yPos = y;
    let xPos = x + ((LOCATION_PROPORTIONS.GAP) - LOCATION_PROPORTIONS.RADIUS);
    if (!isStart) {
        xPos += LOCATION_PROPORTIONS.GAP * index;
        yPos -= SEGMENT_PROPORTIONS.HEIGHT_END / 2;
    }
    return { x: xPos, y: yPos }
}

export const useSlack = (props: IFrameProps & SlackProps) => {
    const { position, index, type, span, unit, locked } = props;
    const cableLocation = cableStartLocation(position);
    const { x: xPositionSegment, y: yPositionSegment } = getLocationPosition(index, type, cableLocation);

    let xPositionText = 0;
    let yPositionText = yPositionSegment + SEGMENT_PROPORTIONS.TEXT_TOP_POSITION;
    const textStyle = locked ? CanvasTextStyles.smallSemiBold : CanvasTextStyles.smallSemiBoldBlue;

    const spanText = span !== undefined ? `${span} ${unit ? UnitInterpreter.toShort(unit) : ''}` : '';
    const anchor = { x: 0, y: 0.5 };
    const anchorText = { x: 0.5, y: 0 };
    const image = getImage(type);
    const outlineImage = locked && getOutlineImage(type);

    switch (type) {
        case SlackType.CO:
            xPositionText = xPositionSegment - (SEGMENT_PROPORTIONS.WIDTH_START / 2);
            anchor.x = 1;
            anchorText.x = 0;
            break;
        case SlackType.FIELD:
            xPositionText = xPositionSegment + (SEGMENT_PROPORTIONS.WIDTH_END / 2);
            yPositionText += SEGMENT_PROPORTIONS.HEIGHT_END / 2;
            break;
    }

    return {
        xPositionSegment,
        yPositionSegment,
        xPositionText,
        yPositionText,
        textStyle,
        anchor,
        anchorText,
        zIndex: index,
        image,
        spanText,
        outlineImage,
    };
};

const getImage = (type: SlackType): string => {
    switch (type) {
        case SlackType.CO: return slackCo;
        case SlackType.FIELD: return slackField;
    }
}

const getOutlineImage = (type: SlackType): string => {
    switch (type) {
        case SlackType.CO: return slackCoLockedOutline;
        case SlackType.FIELD: return slackFieldLockedOutline;
    }
}
