import './import-geojson-content.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';

import { LocalizationKeys } from '../../../../locales/types';
import {
    GeoJsonStepsEnum, geoJsonStepTitles, ImportDialogContentProps
} from '../import.card.types';
import { useImportGeojsonDialogContent } from './import-geojson-content.hooks';
import { ImportErrorLog } from './import-step-error-log';
import { ImportStepSave } from './import-step-save';
import { ImportStepSelectPresets } from './import-step-select-presets';
import { ImportStepDesignAreas } from './import-step-design-areas';

export const ImportGeojsonContent = (props: ImportDialogContentProps): JSX.Element => {
    const { t } = useTranslation();
    const { errorMessages,
        importedInfraDataTotal,
        importedInfraDataHasDuplicates,
        importStatuses,
        designAreasToImportRows,
        presets,
        buildsToImportRows,
        buildTypes,
        designAreasAccessPointsCount,
        setSelectedBuilds 
    } = useImportGeojsonDialogContent(props);
    return (
        <div className='dialog-content import-geojson-file-dialog-content'>
            <div className='header'>
                {
                    geoJsonStepTitles.map((title, index) => (
                        <div className={props.stepCount < index ? 'step disabled' : 'step'} key={index}><span className={props.stepCount < index ? 'step-index disabled' : 'step-index'}>{index + 1}</span>{title}</div>
                    ))
                }
            </div>
            <div className='content'>
                {props.stepCount === GeoJsonStepsEnum.Infrastructure &&
                    <div>
                        {!!importedInfraDataTotal && <Alert severity='info'>{t(LocalizationKeys.AboutToImportXElements, importedInfraDataTotal.toString())} </Alert>}
                        {importedInfraDataHasDuplicates && <Alert severity='warning'>{t(LocalizationKeys.FoundDuplicateTagNotImported)}</Alert>}
                        <div>{errorMessages}</div>
                    </div>
                }
                {props.stepCount === GeoJsonStepsEnum.DesignAreas &&
                    <ImportStepDesignAreas designAreas={designAreasToImportRows} designAreasAccessPointsCount={designAreasAccessPointsCount} />
                }
                {props.stepCount === GeoJsonStepsEnum.SelectPresets &&
                    <ImportStepSelectPresets setSelectedBuildsResult={setSelectedBuilds} buildsToImportRows={buildsToImportRows} presets={presets} setDisableNext={props.setDisableNext} />
                }
                {props.stepCount === GeoJsonStepsEnum.Save &&
                    <ImportStepSave importStatuses={importStatuses} buildTypes={buildTypes} />
                }
                {props.stepCount === GeoJsonStepsEnum.Error &&
                    <ImportErrorLog errorMessages={errorMessages} />
                }
            </div>
        </div>
    );
}