import './no-schrodinger-preset-warning.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { DesignMode } from '../../../models/design-mode';
import { DialogComponent } from '../dialog.component';
import { useNoDefaultPresetWarningDialog } from './no-default-preset-warning.hooks';

export const NoDefaultPresetWarningDialog = (): JSX.Element => {
    const { t } = useTranslation();
    const { open, designMode, buildType, onPresetOpen, onNegativeClick, onClose } = useNoDefaultPresetWarningDialog();

    return (
        <DialogComponent
            open={open}
            title={t(LocalizationKeys.MissingDefaultPreset)}
            message={buildType === BuildType.Schrodinger ? t(LocalizationKeys.MissingDefaultPresetDescription) : t(LocalizationKeys.MissingDefaultPresetDescription)}
            positiveTitle={t(LocalizationKeys.OpenPreset)}
            negativeTitle={designMode === DesignMode.CanvasMode ? t(LocalizationKeys.BackToGisMode) : t(LocalizationKeys.Close)}
            onClose={onClose}
            onNegativeClick={onNegativeClick}
            onPositiveClick={onPresetOpen}
        />
    );
};
