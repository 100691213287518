import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BulkBuild } from '../../../models/bulk/bulk-build';
import { lockBuilds, unlockBuilds, updateBulkBuild } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { selectBuild, selectSegment } from '../../../redux/selection.state';
import {
    selectedBuildSplicePointsSelector, selectedBulkBuildConnectedFiberCountSelector
} from '../../../selectors/bulk/splice-points.selectors';
import { getDesignAreaDisplayText } from '../build/build-card.utility';
import { useLockPermission } from '../../../hooks/lock-permission.hooks';
import { defaultBulkBuildColor } from './bulk.types';

export const useBulkBuildCard = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: StateModel) => state);
    const { lockButtonDisabled, unlockButtonDisabled } = useLockPermission();

    const { userId } = state.authentication;
    const { selectedSegmentId } = state.selection;
    const { bulkBuilds, segments } = state.build;
    const selectedSegment = segments.find(s => s.id === selectedSegmentId);
    const selectedBuildId = selectedSegment?.buildId || state.selection.selectedBuildId;
    const build = selectedBuildId ? bulkBuilds.find(b => b.id === selectedBuildId) : undefined;
    const buildLocked = !!build?.lockedById;
    const editDisabled = build?.modifiedById !== userId || buildLocked;
    const unlockDisabled = !!(build && (buildLocked ? unlockButtonDisabled(build.id) : lockButtonDisabled(build.id)));
    const buildSegments = build ? segments.filter(s => s.buildId === selectedBuildId) : [];
    const buildSplicePoints = useSelector(selectedBuildSplicePointsSelector);
    const { selectedTool } = state.tool;
    const { currentWorkspace } = state.workspace;
    const designAreaDisplayText = getDesignAreaDisplayText(selectedBuildId, state.designarea);
    const connectedFiberCount = useSelector(selectedBulkBuildConnectedFiberCountSelector);
    const notEnoughFibers = (build?.fiberCount ?? 0) < connectedFiberCount;
    const splicePlanButtonDisabled = !buildSplicePoints.length || notEnoughFibers;

    const [collapsed, setCollapsed] = useState(false);

    const onDeleteBuild = useCallback((): void => {
        if (showDialog) {
            dispatch(showDialog(DialogType.DeleteBulkBuild));
        }
    }, [dispatch]);

    const onLockBuild = useCallback((): void => {
        if (build && lockBuilds && currentWorkspace) {
            lockBuilds([build.id], currentWorkspace.id)(dispatch);
        }
    }, [build, currentWorkspace, dispatch]);

    const onUnlockBuild = useCallback((): void => {
        if (build && unlockBuilds && currentWorkspace) {
            unlockBuilds([build.id], currentWorkspace.id)(dispatch);
        }
    }, [build, currentWorkspace, dispatch]);

    const onUpdateColor = useCallback((value: string): void => {
        if (build && updateBulkBuild) {
            const newBuild: BulkBuild = { ...build, cableColorHex: value };
            updateBulkBuild(build, newBuild)(dispatch);
        }
    }, [build, dispatch]);

    const onResetColor = useCallback((): void => {
        onUpdateColor(defaultBulkBuildColor);
    }, [onUpdateColor]);

    const toggleCollapse = useCallback((collapsed: boolean): void => {
        setCollapsed(collapsed);
    }, []);

    const unselectBuild = useCallback((): void => {
        if (selectBuild) {
            dispatch(selectBuild());
            dispatch(selectSegment());
        }
    }, [dispatch]);

    return {
        collapsed,
        editDisabled, unlockDisabled, buildLocked,
        build, buildSegments, selectedSegment, splicePoints: buildSplicePoints,
        selectedTool, splicePlanButtonDisabled,
        currentWorkspace, designAreaDisplayText,
        onDeleteBuild, onLockBuild, onUnlockBuild, onUpdateColor, onResetColor,
        toggleCollapse, unselectBuild
    }
};