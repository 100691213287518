import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';

import { LocalizationKeys } from '../../../locales/types';
import { CardPropertyInline } from '../../card/card-controls';
import { InlineText } from '../../ui-elements/inline-text';
import { useCableLengthProperties } from './cable-length-properties.hooks';
import { CableLengthProps } from './cable-length-properties.types';

export const CableLengthProperties = (props: CableLengthProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        coSlackLabel, fieldSlackValue, slackLocationSpan,
        fieldSlackUpdateProps, displayUnits, disabled
    } = useCableLengthProperties(props);

    return (
        <React.Fragment>
            {CardPropertyInline(t(LocalizationKeys.COSlack), coSlackLabel.toFixed(2), UnitInterpreter.toShort(displayUnits))}
            {
                fieldSlackUpdateProps ?
                    <InlineText
                        label={t(LocalizationKeys.FieldSlack)}
                        value={fieldSlackValue.value.toFixed(2)}
                        disabled={disabled}
                        units={UnitInterpreter.toShort(displayUnits)}
                        onUpdate={fieldSlackUpdateProps.handleFieldSlackUpdate}
                        onInputChange={fieldSlackUpdateProps.validateFieldSlack}
                        onCancel={() => fieldSlackUpdateProps.validateFieldSlack(fieldSlackValue.value)}
                        error={!fieldSlackUpdateProps.isFieldSlackValid}
                        errorMessage={t(LocalizationKeys.OnlyPositiveValues)}
                    />
                    :
                    CardPropertyInline(t(LocalizationKeys.FieldSlack), fieldSlackValue.value.toFixed(2), UnitInterpreter.toShort(displayUnits))
            }
            {slackLocationSpan && CardPropertyInline(t(LocalizationKeys.SlackLocationSpan), slackLocationSpan, UnitInterpreter.toShort(displayUnits))}
        </React.Fragment>
    );
};
