import classNames from 'classnames';
import React from 'react';

export interface CardFooterProps {
    children?: React.ReactNode;
    align?: "left" | "right";
}

export const CardFooterComponent = ({ children, align = 'right' }: CardFooterProps) => {
    const cs = classNames('card-footer', {
        'align-left': align === "left",
        'align-right': align === "right",
    });

    return (
        <div className={cs}>
            {children}
        </div>
    )
}