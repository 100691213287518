import { WorkspaceToolbarProps } from "./drawer-workspace-toolbar.types";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react"
import { DialogType, showDialog } from "../../../../../redux/overlay.state";
import { StateModel } from "../../../../../redux/reducers";
import { setEditingWorkspaceId } from "../../../../../redux/workspace.state";

export const useWorkspaceToolbar = (props: WorkspaceToolbarProps) => {
    const { workspace, isExpanded, setIsExpanded } = props;
    const { workspaces } = useSelector((state: StateModel) => state.workspace);
    const dispatch = useDispatch();

    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [expandIcon, setExpandIcon] = useState("expand_more");

    useEffect(() => {
        setDeleteDisabled(workspaces.length === 1);
    }, [workspaces.length]);

    const onDeleteClick = useCallback(() => {
        dispatch(showDialog(DialogType.WorkspaceDelete))
    }, [dispatch]);

    const onEditClick = useCallback(() => {
        dispatch(setEditingWorkspaceId(workspace.id))
    }, [dispatch, workspace.id]);

    useEffect(() => {
        if (isExpanded) {
            setExpandIcon("expand_less");
        } else {
            setExpandIcon("expand_more");
        }
    }, [isExpanded]);

    const onExpandClick = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [setIsExpanded, isExpanded]);

    return { deleteDisabled, onDeleteClick, onEditClick, expandIcon, onExpandClick, workspace };
}