import { MapClickEvent } from '../models/map-click-event';
import { store } from '../dna';
import { addManhole } from '../redux/manhole.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class ManholeTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Manhole,
        name: 'Manhole',
        shortcut: 'M',
    };

    public static execute(event: MapClickEvent): void {
        addManhole({ x: event.x, y: event.y })(store.dispatch);
    }
}
