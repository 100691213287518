import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { updateSplicePlan as updateBulkSplicePlan } from '../../../redux/bulk/splice-plan.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import {
    selectBuild, selectSegment, selectSplicePlanCabinet
} from '../../../redux/selection.state';
import {
    updateFlexNapSplicePlan, updatePretermBuildsSplicePlans
} from '../../../redux/splice-plan.state';
import {
    fiberCountBuildsSelector, selectedFiberCountBuildSelector
} from '../../../selectors/build.selectors';
import {
    bulkSplicePlanSelector, cabinetSplicePlanSelector, dialogSelector, flexNapSplicePlanSelector
} from '../../../selectors/root.selectors';
import { SplicePlanSetupDialogProps } from './splice-plan-dialog.types';

export const useSplicePlanSetupDialog = (): SplicePlanSetupDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const dialog = useSelector(dialogSelector);
    const flexnapSplicePlan = useSelector(flexNapSplicePlanSelector);
    const bulkSplicePlan = useSelector(bulkSplicePlanSelector);
    const builds = useSelector(fiberCountBuildsSelector);
    const build = useSelector(selectedFiberCountBuildSelector);
    const { buildIdRequestingCabinetSplicePlan, currentSplicePlan } = useSelector(cabinetSplicePlanSelector)

    const { fiberCount = 0, id: buildId } = build || {};
    const { fiberAssignment, startingPortNumber } = (build?.type === BuildType.FlexNap ? flexnapSplicePlan : bulkSplicePlan) || {};

    const [startingPort, setStartingPort] = useState<number | undefined>(startingPortNumber);

    const isOpen = dialog === DialogType.SplicePlanSetup;
    const positiveTitle = buildIdRequestingCabinetSplicePlan === buildId ? t(LocalizationKeys.OpenCabinetSplicePlan) : t(LocalizationKeys.Generate);

    const onClose = useCallback(() => dispatch(showDialog(DialogType.SplicePlan)), [dispatch]);

    const onCancel = useCallback(() => {
        onClose();
        setStartingPort(startingPortNumber);
    }, [startingPortNumber, onClose]);

    useEffect(() => {
        setStartingPort(startingPortNumber);
    }, [startingPortNumber]);

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const portNumber = e.currentTarget.value;
        const isEmpty = portNumber === "";
        if (!isEmpty && isNaN(Number(portNumber))) {
            return;
        }
        setStartingPort(!isEmpty ? Number(portNumber) : undefined);
    };

    const onSave = useCallback(() => {

        if (!buildId) {
            return;
        }

        if (build?.type === BuildType.FlexNap) {
            dispatch(updateFlexNapSplicePlan({ buildId, startingPort, includeUnusedFibers: true }));

            const flexnapBuilds = builds.filter((b) => b.type === BuildType.FlexNap) as FlexNapBuild[];
            const buildHasPreterm = flexnapBuilds.some(b => b.pretermLateral?.parentBuildId === buildId);
            if (buildHasPreterm) {
                dispatch(updatePretermBuildsSplicePlans(buildId, startingPort));
            }
        }
        else if (build?.type === BuildType.Bulk) {
            dispatch(updateBulkSplicePlan({ buildId, startingPort, includeUnusedFibers: true }));
        }

        onClose();
    }, [buildId, build, builds, dispatch, onClose, startingPort]);

    const isValid = buildIdRequestingCabinetSplicePlan === buildId || Boolean(fiberCount && startingPort && fiberCount <= startingPort && buildIdRequestingCabinetSplicePlan === -1);

    const onClickGoToCabinet = useCallback(() => {
        dispatch(showDialog());
        dispatch(selectBuild());
        dispatch(selectSegment());
        dispatch(selectSplicePlanCabinet(currentSplicePlan?.cabinetId));
    }, [currentSplicePlan, dispatch]);

    return {
        isOpen,
        positiveTitle,
        isValid,
        onCancel,
        onChange,
        onClose,
        onPositiveClick: buildIdRequestingCabinetSplicePlan === buildId ? onClickGoToCabinet : onSave,
        startingPort,
        fiberCount,
        fiberAssignment,
        buildId,
        buildIdRequestingCabinetSplicePlan
    }
}
