import './flexnap-terminal-extension-card.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonGroup } from '@mui/material';
import { TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { TerminalExtensionType } from '../../../models/terminal-extension-type';
import { CardContentComponent } from '../../card/card-content.component';
import { useFlexnapExtensionCard } from './flexnap-terminal-extension-card.hooks';
import { FlexnapTerminalExtensionCardProps } from './flexnap-terminal-extension-card.types';

export const FlexNapTerminalExtensionCard = (props: FlexnapTerminalExtensionCardProps): JSX.Element => {
    const { t } = useTranslation();

    const { terminalExtension } = props;

    const { onResetTerminalExtensionClick, switchTerminalExtensionType } = useFlexnapExtensionCard();

    return (
        <div className="nap-diagram-children no-header">
            <div className="card-separator"></div>
            <CardContentComponent className="inline">
                <TextButton className="negative" onClick={onResetTerminalExtensionClick}>{t(LocalizationKeys.Reset)}</TextButton>
                <ButtonGroup>
                    <TextButton className="button-group-button"
                        disabled={terminalExtension.terminalExtensionType === TerminalExtensionType.LongTail}
                        onClick={(): void => switchTerminalExtensionType(TerminalExtensionType.LongTail)}>
                        {t(LocalizationKeys.LongtailTerminalShort)}
                    </TextButton>
                    <TextButton className="button-group-button"
                        disabled={terminalExtension.terminalExtensionType === TerminalExtensionType.TetherExtender}
                        onClick={(): void => switchTerminalExtensionType(TerminalExtensionType.TetherExtender)}>
                        {t(LocalizationKeys.TetherExtenderShort)}
                    </TextButton>
                </ButtonGroup>
            </CardContentComponent>
        </div>
    );
};