import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateModel } from '../../redux/reducers';
import { updateBuildValidation } from '../../redux/schrodinger/validation.state';
import {
    selectedSchrodingerBuildSelector, selectedSchrodingerSegmentSelector
} from '../../selectors/schrodinger/build.selector';
import { selectedTapSelector } from '../../selectors/schrodinger/taps.selectors';
import { selectedTetherSelector } from '../../selectors/schrodinger/tether.selector';

export const ValidationManager = () => {
    const selectedBuild = useSelector(selectedSchrodingerBuildSelector);
    const selectedSegment = useSelector(selectedSchrodingerSegmentSelector);
    const selectedTap = useSelector(selectedTapSelector);
    const selectedTether = useSelector(selectedTetherSelector);
    const state = useSelector((state: StateModel) => state);
    const { buildIds } = state.validation;
    const { schrodingerBuilds, displayedBuildId, segments } = state.build
    const { taps } = state.tapsSchrodinger;
    const { tethers } = state.tapsSchrodinger;
    const workspaceId = state.workspace.currentWorkspace?.id;

    const [previousBuildSegments, setPreviousBuildSegments] = useState(segments);
    const [previousSelectedBuild, setPreviousSelectedBuild] = useState(selectedBuild);
    const [previousSelectedSegment, setPreviousSelectedSegment] = useState(selectedSegment);
    const [previousSelectedTap, setPreviousSelectedTap] = useState(selectedTap);
    const [previousSelectedTether, setPreviousSelectedTether] = useState(selectedTether);
    const [previousTaps, setPreviousTaps] = useState(taps);
    const [previousTethers, setPreviousTethers] = useState(tethers);

    const dispatch = useDispatch();

    const updateValidation = useCallback((buildId: number) => {
        const buildIsPresent = [
            ...schrodingerBuilds
        ].find(b => b.id === buildId);

        buildIsPresent && updateBuildValidation(workspaceId, buildId)(dispatch);
    }, [dispatch, schrodingerBuilds, workspaceId]);

    useEffect(() => {
        displayedBuildId && updateValidation(displayedBuildId);
    }, [displayedBuildId, updateValidation]);

    // Build selected for the first time in a session
    useEffect(() => {
        selectedBuild && !buildIds.includes(selectedBuild.id) && updateValidation(selectedBuild.id);
    }, [buildIds, selectedBuild, updateValidation]);

    // Build updated
    useEffect(() => {
        if (selectedBuild && previousSelectedBuild && (selectedBuild !== previousSelectedBuild) && (selectedBuild.id === previousSelectedBuild.id)) {
            updateValidation(selectedBuild.id);
        }
        setPreviousSelectedBuild(selectedBuild);
    }, [previousSelectedBuild, selectedBuild, updateValidation]);

    // Build segment updated
    useEffect(() => {
        if (selectedSegment && previousSelectedSegment && (selectedSegment !== previousSelectedSegment) && (selectedSegment.id === previousSelectedSegment.id)) {
            updateValidation(selectedSegment.buildId);
        }

        setPreviousSelectedSegment(selectedSegment);
    }, [previousSelectedSegment, selectedSegment, updateValidation]);

    // Build segment updated
    useEffect(() => {
        if (previousBuildSegments !== segments) {
            // find the segment that is not in previousBuildSegments anymore that is in segments
            let segmentToValidate: any = null;
            if (previousBuildSegments.length > segments.length) {
                segmentToValidate = previousBuildSegments.find(s => !segments.find(ps => ps.id === s.id));
            } else {
                segmentToValidate = segments.find(s => !previousBuildSegments.find(ps => ps.id === s.id));
            }

            segmentToValidate && updateValidation(segmentToValidate?.buildId);
            setPreviousBuildSegments(segments);
        }
    }, [segments, displayedBuildId, previousBuildSegments, updateValidation]);


    // TAP added or removed
    useEffect(() => {
        if (taps.length > previousTaps.length) {
            const newTap = taps.find((t) => !previousTaps.includes(t));
            newTap?.buildId && updateValidation(newTap.buildId);
        }
        if (taps.length < previousTaps.length) {
            const oldTap = previousTaps.find(t => !taps.includes(t));
            oldTap?.buildId && updateValidation(oldTap.buildId);
        }
        setPreviousTaps(taps);
    }, [taps, previousTaps, updateValidation]);

    // Tap updated
    useEffect(() => {
        if (selectedTap?.buildId && previousSelectedTap && (selectedTap !== previousSelectedTap) && (selectedTap.id === previousSelectedTap.id)) {
            updateValidation(selectedTap.buildId);
        }
        setPreviousSelectedTap(selectedTap);
    }, [previousSelectedTap, selectedTap, updateValidation]);

    // Tether added or removed
    useEffect(() => {
        if (tethers.length > previousTethers.length) {
            const newTether = tethers.find((t) => !previousTethers.includes(t));
            const tap = taps.find((t) => t.id === newTether?.tapId);
            tap?.buildId && updateValidation(tap.buildId);
        }
        if (tethers.length < previousTethers.length) {
            const oldTether = previousTethers.find((t) => !tethers.includes(t));
            const tap = taps.find((t) => t.id === oldTether?.tapId);
            tap?.buildId && updateValidation(tap.buildId);
        }
        setPreviousTethers(tethers);
    }, [previousTethers, tethers, taps, updateValidation]);

    // Tether updated
    useEffect(() => {
        if (selectedTether && previousSelectedTether && (selectedTether !== previousSelectedTether) && (selectedTether.id === previousSelectedTether.id)) {
            const tap = taps.find((t) => t.id === selectedTether.tapId);
            tap?.buildId && updateValidation(tap.buildId);
        }
        setPreviousSelectedTether(selectedTether);
    }, [previousSelectedTether, selectedTether, taps, updateValidation]);

    return null;
}