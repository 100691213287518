import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow } from '@mui/material';

import { LocalizationKeys } from '../../../../locales/types';
import { DesignAreasTableRowProps } from './design-areas-table-row.types';

export const DesignAreasTableHeader = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell className="build-list-table-cell table-column build-id">{t(LocalizationKeys.DesignArea)}</TableCell>
                <TableCell className="build-list-table-cell table-column address">{t(LocalizationKeys.StateRegion)}</TableCell>
                <TableCell className="build-list-table-cell table-column">{t(LocalizationKeys.City)}</TableCell>
                <TableCell className="build-list-table-cell table-column">{t(LocalizationKeys.AccessPoints)}</TableCell>
            </TableRow>
        </TableHead>
    );
};


export const DesignAreasTableRow = (props: DesignAreasTableRowProps): JSX.Element => {
    const { designArea, accessPointCount } = props;
    return (
        <Fragment>
            <TableRow className="build-list-item" key={designArea.id}>
                <TableCell className="build-list-table-cell">{designArea.name}</TableCell>
                <TableCell className="build-list-table-cell">{designArea.region}</TableCell>
                <TableCell className="build-list-table-cell">{designArea.city}</TableCell>
                <TableCell className="build-list-table-cell">{accessPointCount}</TableCell>
            </TableRow>
        </Fragment>
    );
};