import React, { Component } from 'react';

interface IScrollDragProps {
    className: string;
}

interface IScrollDragState {
    isScrolling: boolean;
    startX: number;
    scrollLeft: number;
}

export class ScrollDrag extends Component<IScrollDragProps, IScrollDragState> {

    private dragRef: React.RefObject<HTMLDivElement> = React.createRef();

    public state: IScrollDragState = {
        isScrolling: false,
        startX: 0,
        scrollLeft: 0,
    };

    private onMouseDown = (e: React.MouseEvent<any>) => {
        const offsetLeft = this.dragRef.current ? this.dragRef.current.offsetLeft : 0;
        const startX = e.pageX - offsetLeft;
        const scrollLeft = offsetLeft;
        this.setState({ isScrolling: true, startX, scrollLeft });
        e.stopPropagation();
        e.preventDefault();
    }

    private onMouseUp = (e: React.MouseEvent<any>) => {
        this.setState({ isScrolling: false });
        e.stopPropagation();
        e.preventDefault();
    }

    private onMouseMove = (e: React.MouseEvent<any>) => {
        const { isScrolling } = this.state;
        const current = this.dragRef.current;
        if (!isScrolling || !current) {
            return;
        }
        const { startX, scrollLeft } = this.state;
        const x = e.pageX - current.offsetLeft;
        current.scrollLeft = scrollLeft - x + startX;
        e.stopPropagation();
        e.preventDefault();
    }

    public render() {
        const { className } = this.props;
        return (
            <div
                ref={this.dragRef}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseUp}
                className={className}
            >

                {
                    // eslint-disable-next-line react/prop-types
                    React.Children.map(this.props.children, (c) => React.Children.only(c))
                }
            </div>
        );
    }
}
