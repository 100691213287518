import { FONT_STYLE } from './font.lib';

export class SvgDefinition {
    private readonly svgString: string;
    constructor(svgString: string) {
        if (!svgString.startsWith('data:image/svg+xml;utf8')) {
            svgString = 'data:image/svg+xml;utf8,' + svgString;
        }
        this.svgString = svgString;
    }

    public getRaw(): string {
        return this.svgString;
    }

    public get(text: string, strokeColor = 'none', fillColor = 'none', textColor = 'none', child = ''): string {
        return this.svgString
            .replace(/%TEXT%/g, text)
            .replace(/%TEXT_COLOR%/g, textColor)
            .replace(/%STROKE_COLOR%/g, strokeColor)
            .replace(/%FILL_COLOR%/g, fillColor)
            .replace(/%FONT_STYLE%/g, FONT_STYLE)
            .replace(/%CHILD%/g, child)
            .replace(/#/g, '%23');
    }

    public getPortNumber(portStart: string, strokeColor = 'none', fillColor = 'none', textColor = 'none', width = 44, height = 20, portEnd = ''): string {
        const svgPortNumberString = this.get(portStart, strokeColor, fillColor, textColor);
        return svgPortNumberString
            .replace(/%WIDTH%/g, width.toString())
            .replace(/%HEIGHT%/g, height.toString())
            .replace(/%WRAP_TEXT%/g, portEnd);
    }

    public getSelected(text: string, strokeColor = 'none', fillColor = 'none', textColor = 'none', selected = false): string {
        const iconDisplay = selected ? 'none' : 'block';
        const textDisplay = selected ? 'block' : 'none';
        return this.get(text, strokeColor, fillColor, textColor)
            .replace(/%ICON_DISPLAY%/g, iconDisplay)
            .replace(/%TEXT_DISPLAY%/g, textDisplay);
    }

    public getSegmentEndpoint(strokeColor = 'none', fillColor = 'none', strokeWidth: number): string {
        return this.get('', strokeColor, fillColor)
            .replace(/%STROKE_WIDTH%/g, strokeWidth.toString());
    }

    public getCallout({ text, fillColor, child, height, width }): string {
        return this.svgString
            .replace(/%WIDTH%/g, width.toString())
            .replace(/%HEIGHT%/g, height.toString())
            .replace(/%FILL_COLOR%/g, fillColor)
            .replace(/%CHILD%/g, child)
            .replace(/%TEXT%/g, text)
            .replace(/#/g, '%23');
    }

    public getErrorShadow(width, height): string {
        return this.get('')
            .replace(/%WIDTH%/g, width.toString())
            .replace(/%HEIGHT%/g, height.toString());
    }
           
    public getBuildIdContainer(child, width, height, rectWidth, rectHeight): string {
      return this.get('', 'none', 'none', 'none', child)
            .replace(/%WIDTH%/g, width)
            .replace(/%HEIGHT%/g, height)
            .replace(/%RWIDTH%/g, rectWidth)
            .replace(/%RHEIGHT%/g, rectHeight);
    }

    public getBuildIdTag(text, fillColor, height): string {
      return this.get(text, 'none', fillColor)
            .replace(/%HEIGHT%/g, height)
            .replace('data:image/svg+xml;utf8,\n', '');
    }
}

// no data-XYZ, use %23 instead of #. See validator.w3.org
export const POLE = new SvgDefinition(`
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
<defs>
    <clipPath id="circle-clip">
      <circle cx="50%" cy="50%" r="10" />
    </clipPath>
  </defs>
<circle cx="50%" cy="50%" r="10" stroke="%STROKE_COLOR%" fill="%FILL_COLOR%" stroke-width="3"/>
<line x2="24" y2="24" fill="none" stroke="%STROKE_COLOR%" stroke-width="2" clip-path="url(#circle-clip)"/>
<line x1="24" y2="24" fill="none" stroke="%STROKE_COLOR%" stroke-width="2" clip-path="url(#circle-clip)"/>
</svg>
`);
export const POLE_POWERED = new SvgDefinition(`
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
<circle cx="50%" cy="50%" r="10" stroke="%STROKE_COLOR%" fill="%FILL_COLOR%" stroke-width="3"/>
<path d="M7,2V9.7H8.468V16l3.426-8.4H9.936L11.894,2Z" fill="%STROKE_COLOR%" transform="translate(3 4)"/>
</svg>
`);
export const POLE_SMALL = new SvgDefinition(`
<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
<circle cx="50%" cy="50%" r="4" stroke="%STROKE_COLOR%" fill="%FILL_COLOR%" stroke-width="2"/>
</svg>
`);
export const POLE_REGION = new SvgDefinition(`
<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50%" cy="50%" r="10" fill="#f5ef3a" stroke="#676767" stroke-width="1"/>
      <rect x="10" y="3" width="2" height="14" fill="#676767"/>
      <rect x="6" y="5" width="10" height="1" fill="#676767"/>
      <rect x="6" y="7" width="10" height="1" fill="#676767"/>
      <rect x="8" y="17" width="6" height="2" fill="#676767"/>
</svg>
`);

export const DESIGN_AREA_REGION = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <circle fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" transform="translate(5 5)" cx="15" cy="15" r="15"/>
    <path fill="#fdfdfd" transform="translate(8 8)" d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z"/>
</svg>
`);

// let n = 5, r = 16, x = 16, y = 16; for (i = 0; i < n; i++) { console.log((y + r * Math.sin(2 * Math.PI * i / n)) + ', ' + ((r*2) - (x + r * Math.cos(2 * Math.PI * i / n)))); }
export const NAP = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52">
  <defs>
    <filter id="shadow" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse">
      <feOffset dy="6" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.302"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    %FONT_STYLE%
  </defs>
  <g filter="url(#shadow)">
    <polygon transform="translate(10 8)" fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" points="16,0 32,12 26,32 6,32 0,12" stroke-linejoin="round" stroke-width="2"/>
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="11" font-family="'TheSansC4s', Arial" letter-spacing="-0.02em">%TEXT%</text>
  </g>
</svg>
`);
export const NAP_SMALL = new SvgDefinition(`
<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
<polygon fill="%FILL_COLOR%" stroke="none" points="5,0 9.75,3.45 7.93,9.04 2.06,9.04 0.24,3.45"/>
</svg>
`);

export const TAP = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30">
    <ellipse fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" opacity="0.6" cx="50%" cy="50%" rx="20" ry="12"/>
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="11" font-family="'TheSansC4s', Arial" letter-spacing="-0.02em">%TEXT%</text>
</svg>
`);

export const PORT_NUMBER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="%WIDTH%" height="%HEIGHT%">
    <rect width="%WIDTH%" height="%HEIGHT%" rx="2" fill="%FILL_COLOR%" opacity=".9" />
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="9" font-family="'TheSansC4s', Arial" font-weight="700">
      %TEXT%
    </text>
</svg>
`);

export const WRAPPED_PORT_NUMBER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="%WIDTH%" height="%HEIGHT%">
    <rect width="%WIDTH%" height="%HEIGHT%" rx="2" fill="%FILL_COLOR%" opacity=".9" />
    <text x="50%" y="30%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="9" font-family="'TheSansC4s', Arial" font-weight="700">
      <tspan x="50%">%TEXT%</tspan>
      <tspan x="50%" dy="40%">%WRAP_TEXT%</tspan>
    </text>
</svg>
`);

export const TERMINAL = new SvgDefinition(`
<svg width="52" height="52" xmlns="http://www.w3.org/2000/svg">
<defs>
    <filter id="shadow" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse">
      <feOffset dy="6" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.302"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    %FONT_STYLE%
  </defs>
  <g filter="url(#shadow)">
    <circle cx="50%" cy="50%" r="15" stroke="%STROKE_COLOR%" fill="%FILL_COLOR%" stroke-width="2"/>
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="14" font-family="'TheSansC4s', Arial" letter-spacing="-0.02em">%TEXT%</text>
  </g>
  %CHILD%
</svg>
`);
export const TERMINAL_SMALL = new SvgDefinition(`
<svg width="8" height="8" xmlns="http://www.w3.org/2000/svg">
<circle cx="4" cy="4" r="2" fill="%FILL_COLOR%" stroke-width="0"/>
</svg>
`);
export const TERMINAL_EXTENSION = new SvgDefinition(`
<g>
  <circle cx="70%" cy="70%" r="8" fill="%FILL_COLOR%" stroke-width="0"/>
  <text x="70%" y="70%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="10" font-family="'TheSansC4s', Arial" letter-spacing="-0.02em">%TEXT%</text>
</g>
`);

export const SPLICE_POINT = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <g>
        <path fill="%FILL_COLOR%" d="M15,15,0,0V30ZM30,0,15,15,30,30Z"/>
    </g>
</svg>
`);

export const SPLICE_POINT_SMALL = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15">
    <g>
        <path fill="%FILL_COLOR%" d="M7.5,7.5,0,0V15ZM15,0,7.5,7.5,15,15Z"/>
    </g>
</svg>
`);

export const SLACK_LOOP = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
  <defs>
    %FONT_STYLE%
  </defs>
  <circle cx="15" cy="15" r="13" stroke="%STROKE_COLOR%" fill="rgba(224,224,224,0.5)" stroke-width="2"/>
  <text x="50%" y ="50%" text-anchor="middle" dominant-baseline="middle" font-size="11" font-family="'TheSansC4s', Arial" letter-spacing="-0.02em">%TEXT%</text>
</svg>
`);

export const CABLE_START = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="41" height="15">
	<line x2="40" fill="none" stroke="%STROKE_COLOR%" stroke-width="%STROKE_WIDTH%" transform="translate(0.5 7.5)"/>
	<g fill="%FILL_COLOR%" transform="translate(12) rotate(90)">
		<path stroke="none" d="M 14.09787559509277 11.5 L 0.9021254777908325 11.5 L 7.500000476837158 0.9434000253677368 L 14.09787559509277 11.5 Z"/>
		<path stroke="none" fill="%FILL_COLOR%" d="M 7.500000476837158 1.886799812316895 L 1.804250717163086 11 L 13.19575023651123 11 L 7.500000476837158 1.886799812316895 M 7.500000476837158 0 L 15 12 L 9.5367431640625e-07 12 L 7.500000476837158 0 Z"/>
	</g>
</svg>
`);

export const BULK_CABLE_START = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="36">
    <line x1="10" x2="50" fill="none" stroke="%STROKE_COLOR%" stroke-width="%STROKE_WIDTH%" transform="translate(0.5 18)"/>
    <circle r="8" stroke="%STROKE_COLOR%" fill="none" stroke-width="%STROKE_WIDTH%" transform="translate(10 10)"/>
</svg>
`);

export const CABLE_PRETERM_START = new SvgDefinition(`
<svg width="25" height="15" xmlns="http://www.w3.org/2000/svg">
  <line id="svg_1" x1="0.5" y1="7.5" y2="7.5" stroke-width="%STROKE_WIDTH%" stroke="%FILL_COLOR%" fill="none" x2="40.5"/>
  <g id="svg_2" transform="rotate(90, 12, 0)" fill="%FILL_COLOR%">
   <path id="svg_3" d="m26.09788,11.5l-13.19575,0l6.59787,-10.5566l6.59788,10.5566z"/>
   <path id="svg_4" d="m19.5,1.8868l-5.69575,9.1132l11.3915,0l-5.69575,-9.1132m0,-1.8868l7.5,12l-15,0l7.5,-12z" fill="%FILL_COLOR%"/>
  </g>
</svg>
`);

export const CABLE_END = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="52" height="30" viewBox="0 0 54 30">
    <path fill="none" stroke="%STROKE_COLOR%" stroke-width="%STROKE_WIDTH%" d="M-973.655,4507.5h45.335s4.6,0,4.664-5.321a5.221,5.221,0,0,0-4.664-5.679h-8.444" transform="translate(973.655 -4492.5)"/>
</svg>
`);

export const CABLE_START_DASHED = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40.5" height="15" viewBox="0 0 41 15" xml:space="preserve">
  <g transform="matrix(1 0 0 1 20.79 8)" id="JWtYiNMtRwMOlQKiWQL59"  >
    <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: %STROKE_COLOR%; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-2.97, -2.97)" d="M 5.94 5.94 L 0 5.94 L 0 0 L 5.94 0 z" stroke-linecap="round" />
  </g>
  <g transform="matrix(0 0.99 -0.99 0 6.27 7.5)" id="oxa3lUNTd_CBqNG_XUMzs"  >
    <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: %STROKE_COLOR%; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-7.5, -6.2)" d="M 14.1 11.5 L 0.9 11.5 L 7.5 0.9 Z" stroke-linecap="round" />
  </g>
  <g transform="matrix(0 0.99 -0.99 0 6.47 7.5)" id="1ao9xuEW_FXCPUjFkXHOq"  >
    <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: %STROKE_COLOR%; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-7.5, -6)" d="M 7.5 1.9 L 1.8 11 L 13.2 11 L 7.5 1.9 M 7.5 0 L 15 12 L 0 12 Z" stroke-linecap="round" />
  </g>
</svg>
`);

export const CABLE_END_DASHED = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="43" height="37.51675" viewBox="0 0 43 37.51675" xml:space="preserve">
  <g transform="matrix(1 0 0 1 21.4 11.8)" id="XkuLTAGsqI3u8SV2Oyipd"  >
    <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: %STROKE_COLOR%; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-20.85, -11.25)" d="M 30.00008 22.5 L 24.00006 22.5 L 24.00006 16.5 L 30.00008 16.5 z M 18.00005 22.5 L 12.000030000000002 22.5 L 12.000030000000002 16.5 L 18.00005 16.5 z M 6.00002 22.5 L 0 22.5 L 0 16.5 L 6.00002 16.5 z M 37.35009 20.85 L 34.05008 15.750000000000002 C 35.046350000000004 15.048630000000001 35.70006 13.959120000000002 35.85008 12.750000000000002 L 41.700089999999996 13.800000000000002 C 41.40217 16.690780000000004 39.800149999999995 19.287150000000004 37.35008 20.85 z M 35.55009 8.7 C 35.09225 7.509689999999999 34.08586 6.615129999999999 32.85008 6.299999999999999 L 34.950089999999996 0.7499999999999991 C 37.525679999999994 1.803109999999999 39.612989999999996 3.7833699999999992 40.80009999999999 6.299999999999999 z M 28.05007 6 L 22.050050000000002 6 L 22.050050000000002 0 L 28.05007 0 z" stroke-linecap="round" />
  </g>
</svg>
`);

export const SQUARE = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
  <defs>
    %FONT_STYLE%
  </defs>
  <rect x="1" y="1" width="14" height="14" rx="2" stroke="%STROKE_COLOR%" fill="%FILL_COLOR%" stroke-width="2"/>
  <text x="50%" y ="50%" text-anchor="middle" dominant-baseline="middle" font-size="11" font-family="'TheSansC4s', Arial" letter-spacing="-0.02em">%TEXT%</text>
</svg>
`);

export const SQUARE_SMALL = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8">
  <rect x="1" y="1" width="6" height="6" stroke="%STROKE_COLOR%" fill="%FILL_COLOR%" stroke-width="2"/>
</svg>
`);

export const CABINET = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <defs>
    %FONT_STYLE%
  </defs>
  <g transform="translate(9 5)">
    <g fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" >
      <rect width="24" height="24" rx="3" ry="3" stroke-width="3" />
      <line x2="24" y1="20" y2="20" stroke-width="6"/>
    </g>
    <text transform="translate(12 13)" text-anchor="middle" fill="%TEXT_COLOR%" font-size="10" font-family="'TheSansC4s', Arial">%TEXT%</text>
  </g>
</svg>
`);

export const CABINET_POWERED = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <g transform="translate(9 5)">
    <g fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" >
      <rect width="24" height="24" rx="3" ry="3" stroke-width="3" />
      <line x2="24" y1="20" y2="20" stroke-width="6"/>
    </g>
    <path d="M7,2V9.7H8.468V16l3.426-8.4H9.936L11.894,2Z" fill="%STROKE_COLOR%" transform="translate(2.5 0.5)"/>
  </g>
</svg>
`);

export const MANHOLE = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <defs>
    %FONT_STYLE%
  </defs>
  <g transform="translate(10 5)">
    <circle cx="11" cy="18" r="6" fill="%STROKE_COLOR%"/>
    <rect width="22" height="18" fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" stroke-width="3"/>
    <text x="3" y="13" fill="%STROKE_COLOR%" font-size="10" font-family="'TheSansC4s', Arial">MH</text>
  </g>
</svg>
`);

export const MANHOLE_POWERED = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <g transform="translate(10 5)">
    <circle cx="11" cy="18" r="6" fill="%STROKE_COLOR%"/>
    <rect width="22" height="18" fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" stroke-width="3"/>
    <path d="M7,2V9.7H8.468V16l3.426-8.4H9.936L11.894,2Z" fill="%STROKE_COLOR%" transform="translate(2 0.5)"/>
  </g>
</svg>
`);

export const HANDHOLE = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <defs>
    %FONT_STYLE%
  </defs>
  <g transform="translate(9 7)">
    <circle cx="5.5" cy="20.5" r="3.5" fill="%STROKE_COLOR%"/>
    <circle cx="18.5" cy="20.5" r="3.5" fill="%STROKE_COLOR%"/>
    <g fill="%FILL_COLOR%">
      <path d="M 22.5 18.5 L 1.5 18.5 L 1.5 1.5 L 22.5 1.5 L 22.5 18.5 Z" stroke="none"/>
      <path d="M 3 3 L 3 17 L 21 17 L 21 3 L 3 3 M 0 0 L 24 0 L 24 20 L 0 20 L 0 0 Z" stroke="none" fill="%STROKE_COLOR%"/>
    </g>
    <text x="5" y="14" fill="%STROKE_COLOR%" font-size="10" font-family="'TheSansC4s', Arial">HH</text>
  </g>
</svg>
`);

export const HANDHOLE_POWERED = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <g transform="translate(9 7)">
    <circle cx="5.5" cy="20.5" r="3.5" fill="%STROKE_COLOR%"/>
    <circle cx="18.5" cy="20.5" r="3.5" fill="%STROKE_COLOR%"/>
    <g fill="%FILL_COLOR%">
      <path d="M 22.5 18.5 L 1.5 18.5 L 1.5 1.5 L 22.5 1.5 L 22.5 18.5 Z" stroke="none"/>
      <path d="M 3 3 L 3 17 L 21 17 L 21 3 L 3 3 M 0 0 L 24 0 L 24 20 L 0 20 L 0 0 Z" stroke="none" fill="%STROKE_COLOR%"/>
    </g>
    <path d="M7,2V9.7H8.468V16l3.426-8.4H9.936L11.894,2Z" fill="%STROKE_COLOR%" transform="translate(2.5 1.5)"/>
  </g>
</svg>
`);

export const VAULT = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <g transform="translate(9 7)">
    <rect width="24" height="24" stroke="none" fill="%FILL_COLOR%"/>
    <rect x="1.5" y="1.5" width="21" height="21" fill="none" stroke="%STROKE_COLOR%" stroke-width="3"/>
    <g fill="%STROKE_COLOR%">
      <circle cx="9" cy="7" r="2"/>
      <circle cx="9" cy="17" r="2"/>
      <circle cx="15" cy="7" r="2"/>
      <circle cx="15" cy="17" r="2"/>
    </g>
  </g>
</svg>
`);

export const VAULT_POWERED = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42">
  <g transform="translate(9 7)">
    <rect width="24" height="24" stroke="none" fill="%FILL_COLOR%"/>
    <rect x="1.5" y="1.5" width="21" height="21" fill="none" stroke="%STROKE_COLOR%" stroke-width="3"/>
    <g fill="%STROKE_COLOR%">
      <path d="M7,2V9.7H8.468V16l3.426-8.4H9.936L11.894,2Z" fill="%STROKE_COLOR%" transform="translate(3 3)"/>
    </g>
  </g>
</svg>
`);

export const TAP_SCHRODINGER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52">
  <defs>
    <filter id="shadow" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse">
      <feOffset dy="6" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.302"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    %FONT_STYLE%
  </defs>
  <g filter="url(#shadow)">
    <path d="M27,0l9,16L27,32H9L0,16,9,0Z" transform="translate(8 9)" fill="%FILL_COLOR%"/>
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="%TEXT_COLOR%" font-size="11" font-family="'TheSansC4s', Arial">
        <tspan>%TEXT%</tspan>
    </text>
  </g>
</svg>
`);

export const TAP_SCHRODINGER_SMALL = new SvgDefinition(`
  <svg width="11" height="11" xmlns="http://www.w3.org/2000/svg">
    <polygon fill="%FILL_COLOR%" stroke="none" points="11,5.5 8.25,10.26 2.75,10.26 0,5.5 2.75,0.74 8.25,0.74"/>
  </svg>
`);

export const OPTICAL_SCHRODINGER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="50" viewBox="0 0 48 50">
  <defs>
    <filter id="a" x="0" y="2" width="48" height="48" filterUnits="userSpaceOnUse">
      <feOffset dy="6" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="b"/><feFlood flood-opacity="0.302"/>
      <feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/>
    </filter>
    %FONT_STYLE%
  </defs>
  <g transform="translate(4)">
    <rect fill="rgba(255,0,0,0)" width="40" height="40"/>
    <g filter="url(#a)" transform="matrix(1, 0, 0, 1, -4, 0)">
      <g fill="%FILL_COLOR%" transform="translate(9 5)">
        <path stroke="none" d="M 15 29 C 11.26047039031982 29 7.744760036468506 27.54375076293945 5.100510120391846 24.89949035644531 C 2.456249952316284 22.25523948669434 1 18.73953056335449 1 15 C 1 11.26047039031982 2.456249952316284 7.744760036468506 5.100510120391846 5.100510120391846 C 7.744760036468506 2.456249952316284 11.26047039031982 1 15 1 C 18.73953056335449 1 22.25523948669434 2.456249952316284 24.89949035644531 5.100510120391846 C 27.54375076293945 7.744760036468506 29 11.26047039031982 29 15 C 29 18.73953056335449 27.54375076293945 22.25523948669434 24.89949035644531 24.89949035644531 C 22.25523948669434 27.54375076293945 18.73953056335449 29 15 29 Z"/>
        <path fill="%STROKE_COLOR%" stroke="none" d="M 15 2 C 11.5275707244873 2 8.262990951538086 3.352239608764648 5.807609558105469 5.807609558105469 C 3.352239608764648 8.262990951538086 2 11.5275707244873 2 15 C 2 18.47243118286133 3.352239608764648 21.73701095581055 5.807609558105469 24.19239044189453 C 8.262990951538086 26.64776039123535 11.5275707244873 28 15 28 C 18.47243118286133 28 21.73701095581055 26.64776039123535 24.19239044189453 24.19239044189453 C 26.64776039123535 21.73701095581055 28 18.47243118286133 28 15 C 28 11.5275707244873 26.64776039123535 8.262990951538086 24.19239044189453 5.807609558105469 C 21.73701095581055 3.352239608764648 18.47243118286133 2 15 2 M 15 0 C 23.28426933288574 0 30 6.715730667114258 30 15 C 30 23.28426933288574 23.28426933288574 30 15 30 C 6.715730667114258 30 0 23.28426933288574 0 15 C 0 6.715730667114258 6.715730667114258 0 15 0 Z"/>
      </g>
    </g>
    <text display="%ICON_DISPLAY%" fill="%TEXT_COLOR%" font-size="20px" font-family="TimesNewRomanPS-BoldMT, 'Times New Roman'" font-weight="700" letter-spacing="-0.02em" transform="translate(20 27)">
      <tspan x="-6.67" y="0">T</tspan>
    </text>
    <text display="%TEXT_DISPLAY%" fill="%TEXT_COLOR%" font-size="14" font-family="Arial" text-anchor="middle" x="40%" y="50%">%TEXT%</text>
  </g>
</svg>
`);

export const OPTICAL_SCHRODINGER_SMALL = new SvgDefinition(`
  <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50%" cy="50%" r="3" fill="%FILL_COLOR%" />
  </svg>
`);

export const POWER_SCHRODINGER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="50" viewBox="0 0 48 50">
  <defs>
    <filter id="a" x="0" y="2" width="48" height="48" filterUnits="userSpaceOnUse">
      <feOffset dy="6" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="b"/>
      <feFlood flood-opacity="0.302"/>
      <feComposite operator="in" in2="b"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    %FONT_STYLE%
  </defs><g transform="translate(4)">
  <rect fill="rgba(255,255,255,0)" width="40" height="40"/>
    <g filter="url(#a)" transform="matrix(1, 0, 0, 1, -4, 0)">
      <g fill="%FILL_COLOR%" stroke="%STROKE_COLOR%" stroke-width="2px" transform="translate(9 5)">
        <circle stroke="none" cx="15" cy="15" r="15"/>
        <circle fill="none" cx="15" cy="15" r="14"/>
      </g>
    </g>
    <g transform="translate(8 8)">
      <rect fill="none" width="24" height="24"/>
      <path display="%ICON_DISPLAY%" fill="%TEXT_COLOR%" d="M7,2V12.967H9.091V21.94L13.97,9.976H11.182L13.97,2Z" transform="translate(2 1)"/>
      </g>
    <text display="%TEXT_DISPLAY%" fill="%TEXT_COLOR%" font-size="14" font-family="Arial" text-anchor="middle" x="40%" y="50%">%TEXT%</text>
  </g>
</svg>
`);

export const POWER_SCHRODINGER_SMALL = new SvgDefinition(`
  <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50%" cy="50%" r="3" fill="%FILL_COLOR%" />
  </svg>
`);

export const POWER_LABEL_SCHRODINGER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <rect fill="none" width="40" height="40"/>
  <g fill="#EFEDEB" stroke="#424242" opacity="0.85" transform="translate(0 10)">
    <rect stroke="none" width="40" height="20" rx="3"/>
    <rect fill="none" x="0.5" y="0.5" width="39" height="19" rx="2.5"/>
  </g>
  <text fill="#202020" font-size="10px" font-family="Roboto-Regular, Roboto" text-anchor="middle" transform="translate(20 24)">%TEXT%</text>
</svg>
`);

export const TRANSFORMER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
  <defs>
    <filter id="Rectangle_450" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse">
      <feOffset dy="6" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.302"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g>
    <rect x="9" y="3" width="30" height="30" rx="3" fill="%FILL_COLOR%" stroke="#424242" stroke-width="2" filter="url(#Rectangle_450)"/>
    <path d="M7,2V12.967H9.091V21.94L13.97,9.976H11.182L13.97,2Z" transform="translate(14 6)" fill="#ec7b11"/>
  </g>
</svg>
`);

export const LOCKED_BUILD = new SvgDefinition(`
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#005293"/>
<path d="M20.0003 13.3332H19.3337V11.9998C19.3337 10.1598 17.8403 8.6665 16.0003 8.6665C14.1603 8.6665 12.667 10.1598 12.667 11.9998V13.3332H12.0003C11.267 13.3332 10.667 13.9332 10.667 14.6665V21.3332C10.667 22.0665 11.267 22.6665 12.0003 22.6665H20.0003C20.7337 22.6665 21.3337 22.0665 21.3337 21.3332V14.6665C21.3337 13.9332 20.7337 13.3332 20.0003 13.3332ZM16.0003 19.3332C15.267 19.3332 14.667 18.7332 14.667 17.9998C14.667 17.2665 15.267 16.6665 16.0003 16.6665C16.7337 16.6665 17.3337 17.2665 17.3337 17.9998C17.3337 18.7332 16.7337 19.3332 16.0003 19.3332ZM18.067 13.3332H13.9337V11.9998C13.9337 10.8598 14.8603 9.93317 16.0003 9.93317C17.1403 9.93317 18.067 10.8598 18.067 11.9998V13.3332Z" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white"/>
</svg>
`);

export const EXPORTED_BUILD = new SvgDefinition(`
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#424242"/>
<path d="M16.667 13.9998L18.687 11.3065L16.667 8.6665V10.6665H13.3337V11.9998H16.667V13.9998ZM12.667 19.9998C11.9337 19.9998 11.3337 20.5998 11.3337 21.3332C11.3337 22.0665 11.9337 22.6665 12.667 22.6665C13.4003 22.6665 14.0003 22.0665 14.0003 21.3332C14.0003 20.5998 13.4003 19.9998 12.667 19.9998ZM19.3337 19.9998C18.6003 19.9998 18.0003 20.5998 18.0003 21.3332C18.0003 22.0665 18.6003 22.6665 19.3337 22.6665C20.067 22.6665 20.667 22.0665 20.667 21.3332C20.667 20.5998 20.067 19.9998 19.3337 19.9998ZM12.7803 17.8332L12.8003 17.7532L13.4003 16.6665H18.367C18.8537 16.6665 19.3003 16.4065 19.5337 15.9798L22.107 11.3065L20.947 10.6665H20.9403L20.207 11.9998L18.367 15.3332H13.687L13.6003 15.1532L12.107 11.9998L11.4737 10.6665L10.847 9.33317H8.66699V10.6665H10.0003L12.4003 15.7265L11.5003 17.3598C11.387 17.5532 11.3337 17.7732 11.3337 17.9998C11.3337 18.7332 11.9337 19.3332 12.667 19.3332H20.667V17.9998H12.947C12.8537 17.9998 12.7803 17.9265 12.7803 17.8332Z" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white"/>
</svg>
`);

export const UPLOADED_BUILD = new SvgDefinition(`
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#424242"/>
<path d="M16.667 13.9998L18.687 11.3065L16.667 8.6665V10.6665H13.3337V11.9998H16.667V13.9998ZM12.667 19.9998C11.9337 19.9998 11.3337 20.5998 11.3337 21.3332C11.3337 22.0665 11.9337 22.6665 12.667 22.6665C13.4003 22.6665 14.0003 22.0665 14.0003 21.3332C14.0003 20.5998 13.4003 19.9998 12.667 19.9998ZM19.3337 19.9998C18.6003 19.9998 18.0003 20.5998 18.0003 21.3332C18.0003 22.0665 18.6003 22.6665 19.3337 22.6665C20.067 22.6665 20.667 22.0665 20.667 21.3332C20.667 20.5998 20.067 19.9998 19.3337 19.9998ZM12.7803 17.8332L12.8003 17.7532L13.4003 16.6665H18.367C18.8537 16.6665 19.3003 16.4065 19.5337 15.9798L22.107 11.3065L20.947 10.6665H20.9403L20.207 11.9998L18.367 15.3332H13.687L13.6003 15.1532L12.107 11.9998L11.4737 10.6665L10.847 9.33317H8.66699V10.6665H10.0003L12.4003 15.7265L11.5003 17.3598C11.387 17.5532 11.3337 17.7732 11.3337 17.9998C11.3337 18.7332 11.9337 19.3332 12.667 19.3332H20.667V17.9998H12.947C12.8537 17.9998 12.7803 17.9265 12.7803 17.8332Z" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white"/>
</svg>
`);
export const APPROVED_BUILD = new SvgDefinition(`
<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.899902" width="31" height="31" rx="15.5" fill="#424242"/>
<g clip-path="url(#clip0_2341_27874)">
<path d="M16 8.3999C11.584 8.3999 8 11.9839 8 16.3999C8 20.8159 11.584 24.3999 16 24.3999C20.416 24.3999 24 20.8159 24 16.3999C24 11.9839 20.416 8.3999 16 8.3999ZM14.4 20.3999L10.4 16.3999L11.528 15.2719L14.4 18.1359L20.472 12.0639L21.6 13.1999L14.4 20.3999Z" fill="white"/>
</g>
<rect x="0.5" y="0.899902" width="31" height="31" rx="15.5" stroke="white"/>
<defs>
<clipPath id="clip0_2341_27874">
<rect width="16" height="16" fill="white" transform="translate(8 8.3999)"/>
</clipPath>
</defs>
</svg>
`);
export const REJECTED_BUILD = new SvgDefinition(`
<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.700195" width="31" height="31" rx="15.5" fill="#424242"/>
<g clip-path="url(#clip0_2341_27877)">
<path d="M23.3712 23.098L9.10238 8.82916L8 9.92378L9.76225 11.686C7.39447 15.2571 8.37263 20.0703 11.9515 22.4381C14.5444 24.1538 17.9214 24.1538 20.5143 22.4381L22.2766 24.2003L23.3712 23.098ZM15.1422 19.5346L11.8506 16.243L12.9452 15.1484L15.1422 17.3454L15.2819 17.2057L16.3765 18.3003L15.1422 19.5346ZM17.4711 15.0009L11.9592 9.48904C15.5303 7.12125 20.3435 8.10718 22.7113 11.6783C24.427 14.2712 24.427 17.6482 22.7113 20.2411L18.5657 16.0955L20.623 14.0383L19.5284 12.9437L17.4711 15.0009Z" fill="white"/>
</g>
<rect x="0.5" y="0.700195" width="31" height="31" rx="15.5" stroke="white"/>
<defs>
<clipPath id="clip0_2341_27877">
<rect width="16" height="16" fill="white" transform="translate(8 8.2002)"/>
</clipPath>
</defs>
</svg>
`);
export const IN_REVIEW_BUILD = new SvgDefinition(`
<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1.1001" width="31" height="31" rx="15.5" fill="#424242"/>
<path d="M20 23.2669V19.2669L17.3333 16.6003L19.9933 13.9269V9.93359H12V13.9336L14.6667 16.6003L12 19.2603V23.2669H20ZM13.3333 13.6003V11.2669H18.6667V13.6003L16 16.2669L13.3333 13.6003Z" fill="white"/>
<rect x="0.5" y="1.1001" width="31" height="31" rx="15.5" stroke="white"/>
</svg>
`);

export const CIRCLE_SHADOW = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="%WIDTH%" height="%HEIGHT%" viewBox="0 0 %WIDTH% %HEIGHT%">
  <defs>
    <style>
      .cls-1,.cls-2{fill:red;}.cls-1{opacity:0.4;}
    </style>
  </defs>
  <g>
    <g>
      <circle class="cls-1" cx="24" cy="24" r="24" />
      <path class="cls-2" d="M7.24,6.83l-.41.41a23.83,23.83,0,0,0-4,5.37l9.74-9.74A23.83,23.83,0,0,0,7.24,6.83Z" />
      <path class="cls-2" d="M19.72.39.39,19.72c-.11.55-.19,1.1-.25,1.66L21.38.14C20.82.2,20.27.28,19.72.39Z" />
      <path class="cls-2" d="M26.06.09l-26,26c0,.43.08.84.15,1.26L27.32.24C26.9.17,26.49.12,26.06.09Z" />
      <path class="cls-2" d="M31.12,1.07l-30,30.05c.1.35.22.71.35,1.05L32.17,1.43C31.83,1.3,31.47,1.18,31.12,1.07Z" />
      <path class="cls-2" d="M35.37,2.86,2.86,35.37c.16.31.33.6.52.9L36.27,3.38C36,3.19,35.68,3,35.37,2.86Z" />
      <path class="cls-2" d="M39,5.27,5.27,39c.21.26.42.52.65.77L39.77,5.92C39.52,5.69,39.26,5.48,39,5.27Z" />
      <path class="cls-2" d="M42.08,8.23,8.23,42.09c.25.22.51.43.77.64L42.73,9C42.52,8.74,42.31,8.48,42.08,8.23Z" />
      <path class="cls-2" d="M44.63,11.73l-32.9,32.9c.3.18.59.35.9.51L45.14,12.63C45,12.32,44.81,12,44.63,11.73Z" />
      <path class="cls-2" d="M46.57,15.83,15.83,46.57c.35.13.7.25,1.06.36l30-30C46.82,16.53,46.7,16.18,46.57,15.83Z" />
      <path class="cls-2" d="M47.76,20.68,20.68,47.76c.42.07.84.12,1.27.15l26-26C47.88,21.51,47.83,21.1,47.76,20.68Z" />
      <path class="cls-2" d="M28.29,47.61,47.61,28.28c.11-.55.19-1.1.25-1.66L26.62,47.86C27.19,47.8,27.74,47.72,28.29,47.61Z" />
      <path class="cls-2" d="M40.88,41.06l.18-.18a23.78,23.78,0,0,0,4.06-5.47l-9.71,9.71A23.78,23.78,0,0,0,40.88,41.06Z" />
    </g>
  </g>
</svg>`);

export const TAP_SHADOW = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="48" viewBox="0 0 55 48">
    <defs>
        <style>
            .cls-1,.cls-2{fill:red;}.cls-1{opacity:0.4;}
        </style>
    </defs>
    <g>
        <g>
            <polygon class="cls-1" points="54.36 23.99 54.36 24 40.86 48 13.5 48 0.01 24.02 0 24 13.5 0 40.86 0 54.36 23.99" />
            <polygon class="cls-2" points="14.66 0 0.57 25.04 0.01 24.02 13.52 0 14.66 0" />
            <polygon class="cls-2" points="19.5 0 3 29.33 2.42 28.32 18.36 0 19.5 0" />
            <polygon class="cls-2" points="24.34 0 5.42 33.63 4.83 32.61 23.18 0 24.34 0" />
            <polygon class="cls-2" points="29.16 0 7.83 37.93 7.25 36.91 28.02 0 29.16 0" />
            <polygon class="cls-2" points="34.01 0 10.26 42.23 9.68 41.21 32.85 0 34.01 0" />
            <polygon class="cls-2" points="38.84 0 12.66 46.53 12.1 45.51 37.69 0 38.84 0" />
            <polygon class="cls-2" points="42.27 2.5 16.68 48 15.53 48 41.69 1.48 42.27 2.5" />
            <polygon class="cls-2" points="44.67 6.8 21.5 48 20.36 48 44.1 5.78 44.67 6.8" />
            <polygon class="cls-2" points="47.09 11.1 26.34 48 25.2 48 46.52 10.08 47.09 11.1" />
            <polygon class="cls-2" points="49.55 15.45 31.23 48 30.09 48 48.98 14.43 49.55 15.45" />
            <polygon class="cls-2" points="51.94 19.7 36.02 48 34.87 48 51.37 18.68 51.94 19.7" />
            <polygon class="cls-2" points="54.35 23.99 54.35 24 40.84 48 39.7 48 53.77 22.98 54.35 23.99" />
        </g>
    </g>
</svg>`);

export const NAP_SHADOW = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
        <style>
            .cls-1,.cls-2{fill:red;}.cls-1{opacity:0.4;}
        </style>
    </defs>
    <g>
        <g>
            <path class="cls-1" d="M15.14,48A9.61,9.61,0,0,1,6,41.28L.43,23.41a9.65,9.65,0,0,1,3.36-10.5L18.16,2A9.63,9.63,0,0,1,29.83,2L44.2,12.91a9.64,9.64,0,0,1,3.36,10.51L42,41.23A9.6,9.6,0,0,1,32.85,48Z" />
            <path class="cls-2" d="M19.56,1.09A9.52,9.52,0,0,0,18.17,2l-14.38,11A8.75,8.75,0,0,0,2.56,14l0,0A9.44,9.44,0,0,0,.88,16.56L22.47.13A9.58,9.58,0,0,0,19.56,1.09Z" />
            <path class="cls-2" d="M27.21.55,0,21.26a7.46,7.46,0,0,0,.15,1.13L28.27,1A9.11,9.11,0,0,0,27.21.55Z" />
            <polygon class="cls-2" points="31.07 2.91 1.14 25.71 1.46 26.72 31.9 3.54 31.07 2.91" />
            <polygon class="cls-2" points="34.55 5.56 2.47 29.99 2.79 31.01 35.38 6.19 34.55 5.56" />
            <polygon class="cls-2" points="38.04 8.21 3.8 34.27 4.11 35.29 38.86 8.84 38.04 8.21" />
            <polygon class="cls-2" points="41.51 10.86 5.13 38.56 5.44 39.58 42.34 11.48 41.51 10.86" />
            <path class="cls-2" d="M45,13.54,6.58,42.75a7,7,0,0,0,.51.88L45.64,14.26A7.77,7.77,0,0,0,45,13.54Z" />
            <path class="cls-2" d="M47.31,17l-38,29a8.29,8.29,0,0,0,.86.6L47.65,18A6.93,6.93,0,0,0,47.31,17Z" />
            <path class="cls-2" d="M41.47,42.67l-6.76,5.15A9.57,9.57,0,0,0,41.47,42.67Z" />
            <polygon class="cls-2" points="29.17 48 43.28 37.26 43.79 35.61 27.52 48 29.17 48" />
            <polygon class="cls-2" points="22.21 48 45.42 30.32 45.93 28.68 20.56 48 22.21 48" />
            <path class="cls-2" d="M13.74,47.9a9.36,9.36,0,0,0,1.41.1h.1L47.57,23.4a8.83,8.83,0,0,0,.32-1.51Z" />
        </g>
    </g>
</svg>`);

export const HOLE_SHADOW = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
        <style>
            .cls-1,.cls-2{fill:red;}.cls-1{opacity:0.4;}
        </style>
    </defs>
    <g>
        <g>
            <path class="cls-1" d="M20,40a14,14,0,0,1-9.78-4H0V0H40V36H29.79A14,14,0,0,1,20,40Z" />
            <path class="cls-2" d="M15.13,39.12,40,14.25v1.41L16.19,39.47C15.83,39.37,15.48,39.25,15.13,39.12Z" />
            <polygon class="cls-2" points="31.8 36 40 27.81 40 29.22 33.22 36 31.8 36" />
            <polygon class="cls-2" points="38.59 36 40 34.59 40 36 38.59 36" />
            <path class="cls-2" d="M10.87,36.6,40,7.47V8.88L11.65,37.23Z" />
            <polygon class="cls-2" points="40 0.69 40 2.1 6.1 36 4.69 36 40 0.69" />
            <path class="cls-2" d="M21.08,40,40,21v1.42L22.7,39.74A12.59,12.59,0,0,1,21.08,40Z" />
            <polygon class="cls-2" points="14.98 0 0 14.97 0 13.57 13.57 0 14.98 0" />
            <polygon class="cls-2" points="21.76 0 0 21.76 0 20.35 20.34 0 21.76 0" />
            <polygon class="cls-2" points="1.42 0 0 1.42 0 0 1.42 0" />
            <polygon class="cls-2" points="35.32 0 0 35.32 0 33.9 33.9 0 35.32 0" />
            <polygon class="cls-2" points="8.19 0 0 8.2 0 6.78 6.79 0 8.19 0" />
            <polygon class="cls-2" points="28.54 0 0 28.54 0 27.12 27.12 0 28.54 0" />
        </g>
    </g>
</svg>`);

export const INFRASTRUCTURE_ID = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="44" height="14" viewBox="0 0 44 14">
  <rect width="44" height="14" rx="7" fill="#fff" opacity="0.74"/>
  <text x="50%" text-anchor="middle" fill="#4e4e50" font-size="10" font-family="'TheSansC4s', Arial"><tspan x="50%" y="10">%TEXT%</tspan></text>
</svg>`);

export const BUILD_CALLOUT = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="%WIDTH%" height="%HEIGHT%" viewBox="0 0 %WIDTH% %HEIGHT%">
    <g transform="translate(1.5 0.5)">
        <g transform="matrix(1, 0, 0, 1, -1.5, -0.5)">
            <rect width="4" height="%HEIGHT%" fill="%FILL_COLOR%" />
        </g>
        %CHILD%
    </g>
</svg>`);

export const BUILD_CALLOUT_NO_CABINET = new SvgDefinition(`
<path fill="#FFFBEF" d="M2.5-0.5h54c2.2,0,4,1.8,4,4v10c0,2.2-1.8,4-4,4h-54l0,0V-0.5L2.5-0.5z"/>
<g transform="translate(-791.5 -348.236)">
    <text transform="matrix(1 0 0 1 797.5 360.736)" fill="#424242" font-size="10" font-family="'TheSansC4s', Arial">%TEXT%</text>
</g>`);

export const BUILD_CALLOUT_CABINET = new SvgDefinition(`
<path fill="#FFFBEF" d="M2.5-0.5h54c2.2,0,4,1.8,4,4v24c0,2.2-1.8,4-4,4h-54l0,0V-0.5L2.5-0.5z"/>
<g transform="translate(-791.5 -335.236)">
    <text transform="matrix(1 0 0 1 797.5 360.736)" fill="#424242" font-size="10" font-family="'TheSansC4s', Arial">%TEXT%</text>
    <g transform="translate(801 339.236)">
        <text transform="matrix(1 0 0 1 10.3458 8.7337)" fill="#424242" font-size="10" font-family="'TheSansC4s', Arial">Cabinet</text>
        <path fill="#424242" d="M2.6,11.1l0.9-0.9L0,6.7h7.6V5.4H0l3.5-3.5L2.6,1.1l-5,5L2.6,11.1z"/>
    </g>
</g>`);

export const BUILD_CALLOUT_CABINET_ALT = new SvgDefinition(`
<path fill="#FFFBEF" d="M2.5-0.5h54c2.2,0,4,1.8,4,4v24c0,2.2-1.8,4-4,4h-54l0,0V-0.5L2.5-0.5z"/>
<g transform="translate(-791.5 -335.236)">
    <text transform="matrix(1 0 0 1 797.5 360.736)" fill="#424242" font-size="10" font-family="'TheSansC4s', Arial">%TEXT%</text>
    <g transform="translate(801 339.236)">
		<text transform="matrix(1 0 0 1 -2.8901 8.7337)" fill="#424242" font-size="10" font-family="'TheSansC4s', Arial">Cabinet</text>
		<path fill="#424242" d="M44.8,6.1l-5-5L38.9,2l3.5,3.5h-7.6v1.2h7.6l-3.5,3.5l0.9,0.9L44.8,6.1z"/>
	</g>
</g>`);

export const CABINET_CALLOUT = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="32" viewBox="0 0 36 32">
    <g transform="translate(1.5 0.5)">
        <g transform="matrix(1, 0, 0, 1, -1.5, -0.5)">
            <path fill="#EAFAF5" d="M4,0h28c2.2,0,4,1.8,4,4v24c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4C0,1.8,1.8,0,4,0z"/>
        </g>
        <text transform="matrix(1 0 0 1 6.973 13.5)" font-size="10" font-family="'TheSansC4s', Arial">LCP</text>
	    <text text-anchor="middle" font-size="10" font-family="'TheSansC4s', Arial"><tspan x="16" y="24">%TEXT%</tspan></text>
    </g>
</svg>`);

export const BUILD_ID_CALLOUT_CONTAINER = new SvgDefinition(`
<svg xmlns="http://www.w3.org/2000/svg" width="%WIDTH%" height="%HEIGHT%" viewBox="0 0 %WIDTH% %HEIGHT%">
  <defs>
  <filter id="f1" x="-0.2" y="-0.2" width="200%" height="200%">
    <feOffset result="offOut" in="SourceGraphic" dx="1" dy="3" />
    <feColorMatrix result="matrixOut" in="offOut" type="matrix"
      values="0.2 0.2 0.2 0 0
      0.2 0.2 0.2 0 0
      0.2 0.2 0.2 0 0
      0 0 0 1 0" />
    <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="3" />
    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
    </filter>
  </defs>
  <g>
    <rect x="3" y="2" rx="5" ry="5" width="%RWIDTH%" height="%RHEIGHT%" fill="#E6EEF4" filter="url(#f1)" />
    %CHILD%
  </g>
</svg>`);

export const BUILD_ID_CALLOUT_ELEMENT = new SvgDefinition(`
<g transform="translate(9 %HEIGHT%)">
  <g>
    <path fill="#000000" d="M7,0H7a7,7,0,0,1,7,7h0a7,7,0,0,1-7,7H7A7,7,0,0,1,0,7H0A7,7,0,0,1,7,0Z"/>
    <path fill="%FILL_COLOR%" d="M7,.5H7A6.5,6.5,0,0,1,13.5,7h0A6.5,6.5,0,0,1,7,13.5H7A6.5,6.5,0,0,1,.5,7h0A6.5,6.5,0,0,1,7,.5Z"/>
  </g>
  <text font-size="10" font-family="'TheSansC4s', Arial" transform="translate(24 11)">%TEXT%</text>
</g>`);