import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../../../../../models/design-mode';
import { zoomToDesignArea } from '../../../../../../redux/map.state';
import { clearSelection, selectDesignArea } from '../../../../../../redux/selection.state';
import { designAreaSelector, designModeSelector } from '../../../../../../selectors/root.selectors';
import { DesignAreaIconProps } from './design-area-icon.types';

export const useDesignAreaIcon = (props: DesignAreaIconProps) => {
    const { designAreaId } = props;
    const { designAreas } = useSelector(designAreaSelector);
    const designMode = useSelector(designModeSelector);

    const dispatch = useDispatch();

    const isCanvasMode = designMode === DesignMode.CanvasMode;

    const handleDesignAreaClick = useCallback(async () => {
        const designArea = designAreas.find((a) => a.id === designAreaId);
        if (designArea) {
            dispatch(clearSelection());
            dispatch(selectDesignArea(designAreaId));
            zoomToDesignArea(designArea)(dispatch);
        }
    }, [designAreaId, designAreas, dispatch]);

    return { handleDesignAreaClick, isCanvasMode }
};
