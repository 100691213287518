import { createSelector } from '@reduxjs/toolkit';

import { BuilderHelper } from '../helpers/build-helper';
import {
    FlexNapPortNumbersByElement, PortNumberRange, TetherLocation
} from '../models/flexnap-splice-plan';
import {
    flexnapBuildsSelector, flexNapSplicePlanSelector, flexNapSplicePlansSelector, napsSelector,
    segmentsSelector, tapsSelector, tethersSelector
} from './root.selectors';

interface TethersGroupBySubunitType {
    [subunitType: string]: TetherLocation[];
}

export const flexNapPortNumbersByElementSelector = createSelector(
    segmentsSelector,
    flexnapBuildsSelector,
    napsSelector,
    tapsSelector,
    tethersSelector,
    flexNapSplicePlansSelector,
    (segments, flexnapBuilds, naps, taps, tethers, plans) => {
        if (!segments.length && !flexnapBuilds.length && !naps.length && !taps.length && !tethers.length && !plans.length) {
            return;
        }

        // Retrieve port number range with element -> nap -> taps -> tethers -> sum of fibers
        const getPortNumberByElementId = (elementId: number, startingPort: number, fiberCount: number, buildId: number, cableColorHex?: string): PortNumberRange | undefined => {
            const napOnElement = naps.find(n => n.elementId === elementId && n.buildId === buildId);
            if (!napOnElement) {
                return;
            }

            const tapsOnElement = taps.filter(({ napId }) => napOnElement.id === napId);
            if (!tapsOnElement.length) {
                return;
            }

            const tethersOnElement = tethers.filter(({ tapId }) => tapsOnElement.some(({ id }) => tapId === id));
            if (!tapsOnElement.length) {
                return;
            }

            const portNumberSum = tethersOnElement.reduce((sum, tether) => sum + tether.fiberCount, -1);
            const start = startingPort - portNumberSum;
            const end = startingPort;

            return { start, end, fiberCount, cableColorHex };
        }

        // Rebuild segment graph to substract fibers in order
        const segmentsGraph = segments.filter(s => s.position === 0)
            .reduce((builded, { buildId }) => {
                if (buildId < 0) {
                    return builded;
                }

                const graphIds = segments.filter((s) => s.buildId === buildId)
                    .sort((a, b) => a.position - b.position)
                    .map(s => [s.fromId, s.toId])
                    .flat()
                    .distinct();

                if (!builded[buildId] && graphIds.length) {
                    builded[buildId] = graphIds;
                }

                return builded;
            }, {});

        const portNumbersByElement = Object.keys(segmentsGraph)
            // Retrieve port numbers in every builds...
            .reduce((portNumbers: FlexNapPortNumbersByElement, id) => {
                const buildId = +id;
                const { fiberCount, cableColorHex } = flexnapBuilds.find(b => b.id === buildId) || {};
                const napsBuild = naps.filter(n => n.buildId === buildId);
                if (!fiberCount || fiberCount < BuilderHelper.getBuildFiberCount(napsBuild, taps, tethers)) {
                    return portNumbers;
                }

                // ...for every elements 
                const portNumberRanges = segmentsGraph[buildId].reduce(
                    (ranges: { elementId: number; range: PortNumberRange }[], elementId: number) => {
                        const { startingPortNumber = fiberCount } = plans[buildId] || {};
                        const previousPortNumber = ranges[ranges.length - 1]?.range?.start - 1;
                        const startingPort = startingPortNumber > fiberCount ? startingPortNumber : fiberCount;
                        const nextPortNumber = previousPortNumber || startingPort;

                        if (!nextPortNumber) {
                            return ranges;
                        }

                        // Get port number range
                        const range = getPortNumberByElementId(elementId, nextPortNumber, fiberCount, buildId, cableColorHex);
                        if (range) {
                            ranges.push({ elementId, range });
                        }

                        return ranges;
                    }, []);

                if (portNumberRanges.length) {
                    // Transform port number array to indexed object
                    const portNumbersIndexedInBuild = portNumberRanges.reduce((portNumbersByElement, portNumber) => {
                        const { elementId, range } = portNumber;
                        portNumbersByElement[elementId] = range;
                        return portNumbersByElement;
                    }, {});
                    return { ...portNumbers, ...portNumbersIndexedInBuild }
                }

                return portNumbers;
            }, {});

        return portNumbersByElement;
    }
);

export const flexNapSplicePlanLocationsWithCategoriesSelector = createSelector(
    flexNapSplicePlanSelector,
    splicePlan => splicePlan?.locations
        // Discard locations with no tethers
        ?.filter(({ tethers }) => tethers.length > 0)
        // Improve locations definition and add categories before each aggregate
        .map((location, i) => {
            const { index, tethers, id: poleId } = location
            const indexLabel = `${index * 10 < 100 ? 0 : ''}${index * 10}`;

            // Group by tube color to create color categories
            const tethersGroupBySubunit: TethersGroupBySubunitType = tethers.reduce(
                (tGrouped: TethersGroupBySubunitType, t: TetherLocation) => {
                    const { subunitColor } = t;
                    const locationLabel = `${indexLabel} / ${poleId}`;
                    const tether = { ...t, locationLabel, isCategory: false, };

                    // Create new category if not existing
                    if (!tGrouped[subunitColor])
                        tGrouped[subunitColor] = [{
                            ...tether,
                            isCategory: true,
                            fiberId: `cat-${poleId}-${subunitColor}`,
                        }];
                    // Push every locations in the color category
                    tGrouped[subunitColor].push(tether);

                    return tGrouped;
                }, {});

            // Transform locations grouped to a flat location array
            const tethersAndCategories: TetherLocation[] = Object.values(tethersGroupBySubunit).flat();

            return {
                ...location,
                indexLabel,
                arrayIndex: i,
                tethers: tethersAndCategories,
            }
        })
        || []
);
