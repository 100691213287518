import { DBFParser, IGeoSet, SHPParser, ZIPParser } from '../../../../lib/shp2geojson/preprocess';

export class ShapefileReaderHelper {
    public static async loadZip(zipFile: File): Promise<IGeoSet[]> {
        if (!zipFile.size) {
            throw new Error('ZIP file is empty (0 bytes)');
        }
        const bufferPairs = await ZIPParser.load(zipFile);
        const sets: IGeoSet[] = [];
        for (const bufferPair of bufferPairs) {
            sets.push(this.loadBufferSet(bufferPair.shpName, bufferPair.shpBuffer, bufferPair.dbfName, bufferPair.dbfBuffer));
        }
        return sets;
    }

    public static async loadFileset(shpFile: File, dbfFile: File): Promise<IGeoSet> {
        if (!shpFile.size) {
            throw new Error('SHP file is empty (0 bytes)');
        }
        if (!dbfFile.size) {
            throw new Error('DBF file is empty (0 bytes)');
        }
        const dbfBuffer = await this.readFile(dbfFile);
        const shpBuffer = await this.readFile(shpFile);
        return this.loadBufferSet(shpFile.name, shpBuffer, dbfFile.name, dbfBuffer);
    }

    public static loadBufferSet(shpName: string, shpBuffer: ArrayBuffer, dbfName: string, dbfBuffer: ArrayBuffer): IGeoSet {
        const dbf = DBFParser.parse(dbfBuffer, dbfName, 'utf-8');
        const shp = SHPParser.parse(shpBuffer, shpName);
        return { dbf, shp };
    }

    private static readFile(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsArrayBuffer(file);
            fr.onload = (_: any) => {
                resolve(fr.result as ArrayBuffer);
            };
            fr.onerror = reject;
        });
    }
}
