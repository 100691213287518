import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Element } from '../models/element';
import { Parcel } from '../models/parcel';
import { PointLike } from '../models/pointlike';

export interface SelectionState {
    selectedPoleId?: number;
    selectedSegmentId?: number;
    selectedSegmentsIds?: number[];
    selectedBuildId?: number;
    selectedBuildIds?: number[];
    selectedNapId?: number;
    selectedTetherId?: number;
    selectedTerminalId?: number;
    selectedTerminalSegmentsIds?: number[];
    selectedTapId?: number;
    selectedParcel?: Parcel;
    selectedElement?: Element;
    selectedSplicePlanCabinetId?: number;
    selectedBoreId?: number;
    selectedTrenchId?: number;
    selectedDesignAreaId?: number;
    selectedPretermNapId?: number;
    draggedItemsIds?: number[];
    selectedVertex?: PointLike;
    /* Schrodinger */
    selectedTapIdSchrodinger?: number;
    selectedTetherIdSchrodinger?: number;
    selectedTerminalIdSchrodinger?: number;
    /* Bulk */
    selectedSplicePointId?: number;
}

const initialState: SelectionState = {};

function clearSelectionReducer(state: SelectionState, _: PayloadAction<undefined>) {
    state.selectedPoleId = undefined;
    state.selectedSegmentId = undefined;
    state.selectedSegmentsIds = undefined;
    state.selectedBuildId = undefined;
    state.selectedBuildIds = undefined;
    state.selectedNapId = undefined;
    state.selectedTetherId = undefined;
    state.selectedTerminalId = undefined;
    state.selectedTerminalSegmentsIds = undefined;
    state.selectedTapId = undefined;
    state.selectedParcel = undefined;
    state.selectedElement = undefined;
    state.selectedSplicePlanCabinetId = undefined;
    state.selectedBoreId = undefined;
    state.selectedTrenchId = undefined;
    state.selectedDesignAreaId = undefined;
    state.selectedPretermNapId = undefined;
    state.draggedItemsIds = undefined;
    state.selectedTapIdSchrodinger = undefined;
    state.selectedTerminalIdSchrodinger = undefined;
    state.selectedTetherIdSchrodinger = undefined;
    state.selectedSplicePointId = undefined;
}
function selectPoleReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedPoleId = action.payload;
}
function selectSegmentReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedSegmentId = action.payload;
}
function selectSegmentsReducer(state: SelectionState, action: PayloadAction<number[] | undefined>) {
    const segments = action.payload;
    if (state.selectedSegmentsIds && segments) {
        state.selectedSegmentsIds.push(...segments);
    } else {
        state.selectedSegmentsIds = action.payload;
    }
}
function unselectSegmentReducer(state: SelectionState, action: PayloadAction<number>) {
    const segmentId = action.payload;
    if (state.selectedSegmentId && state.selectedSegmentId === segmentId) {
        state.selectedSegmentId = undefined;
    }
    if (state.selectedSegmentsIds) {
        state.selectedSegmentsIds = state.selectedSegmentsIds.filter((id) => id !== segmentId);
    }
}
function selectBuildReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedBuildId = action.payload;
    state.selectedBuildIds = undefined;
}
function selectBuildsReducer(state: SelectionState, action: PayloadAction<number[] | undefined>) {
    state.selectedBuildIds = action.payload;
}
function selectNapReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedNapId = action.payload;
}
function selectTapReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedTapId = action.payload;
}
function selectTetherReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedTetherId = action.payload;
}
function selectSplicePointReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedSplicePointId = action.payload;
}
function selectPretermTetherReducer(state: SelectionState, action: PayloadAction<number[] | undefined>) {
    const pretermBuildTethers = action.payload;
    if (pretermBuildTethers && (state.selectedTetherId || state.selectedTetherId === 0) && !pretermBuildTethers.includes(state.selectedTetherId)) {
        state.selectedTetherId = pretermBuildTethers[pretermBuildTethers.length - 1];
    }
}
function selectTerminalReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedTerminalId = action.payload;
}
function selectTerminalSegmentsReducer(state: SelectionState, action: PayloadAction<number[] | undefined>) {
    state.selectedTerminalSegmentsIds = action.payload;
}
function addTerminalSegmentsToSelectionReducer(state: SelectionState, action: PayloadAction<number[] | undefined>) {
    if (action.payload) {
        if (state.selectedTerminalSegmentsIds) {
            state.selectedTerminalSegmentsIds.push(...action.payload);
            state.selectedTerminalSegmentsIds = state.selectedTerminalSegmentsIds.distinct();
        } else {
            state.selectedTerminalSegmentsIds = action.payload;
        }
    }
}
function unselectTerminalSegmentReducer(state: SelectionState, action: PayloadAction<number>) {
    const segmentId = action.payload;
    if (state.selectedTerminalSegmentsIds) {
        state.selectedTerminalSegmentsIds = state.selectedTerminalSegmentsIds.filter((id) => id !== segmentId);
    }
}
function selectParcelReducer(state: SelectionState, action: PayloadAction<Parcel | undefined>) {
    state.selectedParcel = action.payload;
}
function selectElementReducer(state: SelectionState, action: PayloadAction<Element | undefined>) {
    state.selectedElement = action.payload;
}
function selectSplicePlanCabinetReducer(state: SelectionState, action: PayloadAction<number | undefined>): void {
    state.selectedSplicePlanCabinetId = action.payload;
}
function updateSelectedElementReducer(state: SelectionState, action: PayloadAction<Element>) {
    if (state.selectedElement?.id === action.payload.id) {
        state.selectedElement = action.payload;
    }
    if (state.selectedParcel?.id === action.payload.id) {
        state.selectedParcel = action.payload as Parcel;
    }
}
function selectBoreReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedBoreId = action.payload;
}
function selectTrenchReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedTrenchId = action.payload;
}
function selectDesignAreaReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedDesignAreaId = action.payload;
}
function selectPretermNapReducer(state: SelectionState, action: PayloadAction<number | undefined>) {
    state.selectedPretermNapId = action.payload;
}
function dragItemsReducer(state: SelectionState, action: PayloadAction<number[] | undefined>) {
    state.draggedItemsIds = action.payload;
}

const selectSchrodingerTapReducer = (state: SelectionState, action: PayloadAction<number | undefined>): void => { state.selectedTapIdSchrodinger = action.payload; };
const selectSchrodingerTetherReducer = (state: SelectionState, action: PayloadAction<number | undefined>): void => { state.selectedTetherIdSchrodinger = action.payload; };
const selectSchrodingerTerminalReducer = (state: SelectionState, action: PayloadAction<number | undefined>): void => { state.selectedTerminalIdSchrodinger = action.payload; };
const updateSelectedVertexReducer = (state: SelectionState, action: PayloadAction<PointLike | undefined>): void => { state.selectedVertex = action.payload; };

const { actions, reducer } = createSlice({
    name: 'selection',
    initialState,
    reducers: {
        clearSelection: clearSelectionReducer,
        selectPole: selectPoleReducer,
        selectSegment: selectSegmentReducer,
        selectSegments: selectSegmentsReducer,
        unselectSegment: unselectSegmentReducer,
        selectBuild: selectBuildReducer,
        selectBuilds: selectBuildsReducer,
        selectNap: selectNapReducer,
        selectTap: selectTapReducer,
        selectTether: selectTetherReducer,
        selectPretermTether: selectPretermTetherReducer,
        selectTerminal: selectTerminalReducer,
        selectTerminalSegments: selectTerminalSegmentsReducer,
        addTerminalSegmentsToSelection: addTerminalSegmentsToSelectionReducer,
        unselectTerminalSegment: unselectTerminalSegmentReducer,
        selectParcel: selectParcelReducer,
        selectElement: selectElementReducer,
        selectSplicePlanCabinet: selectSplicePlanCabinetReducer,
        updateSelectedElement: updateSelectedElementReducer,
        selectBore: selectBoreReducer,
        selectTrench: selectTrenchReducer,
        selectDesignArea: selectDesignAreaReducer,
        selectPretermNap: selectPretermNapReducer,
        dragItems: dragItemsReducer,
        selectTapSchrodinger: selectSchrodingerTapReducer,
        selectTetherSchrodinger: selectSchrodingerTetherReducer,
        selectTerminalSchrodinger: selectSchrodingerTerminalReducer,
        selectSplicePoint: selectSplicePointReducer,
        updateSelectedVertex: updateSelectedVertexReducer,
    },
});

export const {
    selectSegment,
    selectSegments,
    unselectSegment,
    selectBuild,
    selectBuilds,
    selectPole,
    selectNap,
    selectTap,
    selectTether,
    selectPretermTether,
    selectTerminal,
    selectTerminalSegments,
    addTerminalSegmentsToSelection,
    unselectTerminalSegment,
    selectParcel,
    selectElement,
    selectSplicePlanCabinet,
    selectBore,
    selectTrench,
    selectDesignArea,
    selectPretermNap,
    dragItems,
    clearSelection,
    selectTapSchrodinger,
    selectTetherSchrodinger,
    selectTerminalSchrodinger,
    selectSplicePoint,
    updateSelectedElement,
    updateSelectedVertex,
} = actions;

export { reducer as SelectionReducer };
