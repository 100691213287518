import { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useDownload } from '../../../../../hooks/download.hooks';
import { useExport } from '../../../../../hooks/export.hooks';
import { useLockPermission } from '../../../../../hooks/lock-permission.hooks';
import { usePrevious } from '../../../../../hooks/util.hooks';
import { LocalizationKeys } from '../../../../../locales/types';
import { Build } from '../../../../../models/build';
import { BuildType } from '../../../../../models/build-type';
import { lockBuilds, unlockBuilds } from '../../../../../redux/build.state';
import { allBuildsSelector } from '../../../../../selectors/build.selectors';
import { designModeSelector, workspaceSelector, designAreaSelector } from '../../../../../selectors/root.selectors';
import { DrawerMenuContext, DrawerSelectionActionItemProps } from '../../drawer-menu.types';
import { DesignArea } from '../../../../../models/design-area';

export const useDrawerSelectionActionGroup = () => {
    const dispatch = useDispatch();
    const designMode = useSelector(designModeSelector);

    const { currentWorkspace } = useSelector(workspaceSelector);
    const builds = useSelector(allBuildsSelector);
    const designAreaState = useSelector(designAreaSelector);
    const { 
        isOptionsMenuOpen, 
        selectedBuildIds, 
        selectedDesignAreaIds, 
        selectedDesignAreaNames,
        setIsDeleteDialogOpen, 
        setIsOptionsMenuOpen, 
        setSelectedBuildIds, 
        setSelectedDesignAreaIds,
        setSelectedDesignAreaNames
    } = useContext(DrawerMenuContext);
    const { t } = useTranslation();
    const { lockButtonDisabled, unlockButtonDisabled } = useLockPermission();
    const { exportFlexNapBuilds, generateFlexNAPBOM } = useExport();
    const { downloadBuilds } = useDownload();

    const [selectedAll, setSelectedAll] = useState(false);

    const sortedBuilds = builds
        .filter((b) => b.designMode === designMode)
        .sort((a, b) => a.id - b.id) as Build[];

    const sortedDesignAreas = designAreaState.designAreas
        .filter((d) => d.designMode === designMode)
        .sort((a, b) => a.id - b.id) as DesignArea[];

    const previousSortedBuilds = usePrevious(sortedBuilds)

    const buildOptionsRef = createRef<HTMLDivElement>();
    const workspaceId = currentWorkspace?.id;
    const isFlexnapBuild = (buildId: number) => !!builds.find(({ id, type }) => id === buildId && type === BuildType.FlexNap);
    const isSchrodingerBuild = (buildId: number) => !!builds.find(({ id, type }) => id === buildId && type === BuildType.Schrodinger);
    const isLocked = (buildId: number) => !!builds.find(({ id }) => id === buildId)?.lockedById;

    const handleSelectAllClick = useCallback(() => {
        if (!selectedAll) {
            setSelectedBuildIds(sortedBuilds.map(b => b.id));
            setSelectedDesignAreaIds(sortedDesignAreas.map(d => d.id));
            setSelectedDesignAreaNames(sortedDesignAreas.map(d => d.name ?? d.projectId));
            setSelectedAll(true);
        }
        else {
            setSelectedBuildIds([]);
            setSelectedDesignAreaIds([]);
            setSelectedDesignAreaNames([]);
            setSelectedAll(false);
        }
    }, [selectedAll, setSelectedBuildIds, setSelectedDesignAreaIds, setSelectedDesignAreaNames, sortedBuilds, sortedDesignAreas]);

    const selectAllProps: DrawerSelectionActionItemProps = {
        label: !selectedAll ? t(LocalizationKeys.SelectAll) : t(LocalizationKeys.UnselectAll),
        disabled: false,
        onClick: handleSelectAllClick,
    };

    const lockProps: DrawerSelectionActionItemProps = {
        label: t(LocalizationKeys.Lock),
        disabled: !selectedBuildIds.length || selectedBuildIds.some((i) => lockButtonDisabled(i)),
        onClick: () => workspaceId && lockBuilds(selectedBuildIds, workspaceId)(dispatch),
    };

    const unlockProps: DrawerSelectionActionItemProps = {
        label: t(LocalizationKeys.Unlock),
        disabled: !selectedBuildIds.length || selectedBuildIds.some((i) => unlockButtonDisabled(i)),
        onClick: () => workspaceId && unlockBuilds(selectedBuildIds, workspaceId)(dispatch),
    };

    const exportProps: DrawerSelectionActionItemProps = {
        label: t(LocalizationKeys.SelectedBuildsExport),
        disabled: !selectedBuildIds.length || selectedBuildIds.some((i) => !isFlexnapBuild(i)),
        onClick: () => exportFlexNapBuilds(selectedBuildIds),
    };

    const downloadGISProps: DrawerSelectionActionItemProps = {
        label: t(LocalizationKeys.DownloadGisData),
        disabled: selectedBuildIds.some((i) => isSchrodingerBuild(i)) || (!selectedBuildIds.length && !selectedDesignAreaIds.length),
        onClick: () => downloadBuilds(selectedBuildIds, selectedDesignAreaIds, selectedDesignAreaNames),
    };
    
    const downloadBOMProps: DrawerSelectionActionItemProps = {
        label: t(LocalizationKeys.DownloadBOM),
        disabled: !selectedBuildIds.length || selectedBuildIds.some((i) => !isFlexnapBuild(i)),
        onClick: () => generateFlexNAPBOM(selectedBuildIds)
    }

    const deleteProps: DrawerSelectionActionItemProps = {
        label: t(LocalizationKeys.Delete),
        disabled: !selectedBuildIds.length || selectedBuildIds.some((i) => isLocked(i)),
        onClick: () => setIsDeleteDialogOpen(true),
    };

    const handleOptionsMenuClick = useCallback(() => {
        setIsOptionsMenuOpen(!isOptionsMenuOpen);
    }, [isOptionsMenuOpen, setIsOptionsMenuOpen]);

    useEffect(() => {
        if (!selectedAll && sortedBuilds.length === selectedBuildIds.length && JSON.stringify(sortedBuilds) !== JSON.stringify(previousSortedBuilds)) {
            setSelectedAll(true);
        }
        if (selectedAll && selectedBuildIds.length === 0) {
            setSelectedAll(false);
        }
    }, [previousSortedBuilds, selectedAll, selectedBuildIds.length, sortedBuilds, sortedBuilds.length])

    return {
        buildOptionsRef,
        selectAllProps,
        lockProps,
        unlockProps,
        exportProps,
        downloadGISProps,
        downloadBOMProps,
        deleteProps,
        isOptionsMenuOpen,
        handleOptionsMenuClick,
    };
};
