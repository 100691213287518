import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../../../../models/design-mode';
import { deleteSegment } from '../../../../../redux/build.state';
import { DialogType, showDialog } from '../../../../../redux/overlay.state';
import { deleteTap } from '../../../../../redux/schrodinger/tap.state';
import {
    designModeSelector, dialogSelector, segmentsSelector, selectedSegmentIdSelector,
    tapsSchrodingerSelector
} from '../../../../../selectors/root.selectors';

export const useDeleteCanvasSegmentDialog = () => {
    const dispatch = useDispatch();

    const dialog = useSelector(dialogSelector);
    const mode = useSelector(designModeSelector);
    const selectedSegmentId = useSelector(selectedSegmentIdSelector);
    const segments = useSelector(segmentsSelector);
    const selectedSegment = segments?.find(s => s.id === selectedSegmentId);
    const locationIndex = (selectedSegment?.position || 0) + 2;
    const taps = useSelector(tapsSchrodingerSelector);
    const tapOnToElement = taps.find(t => t.buildId === selectedSegment?.buildId && t.elementId === selectedSegment.toId);
    const isOpen = mode === DesignMode.CanvasMode &&
        dialog === DialogType.CanvasDeleteSegment &&
        !!selectedSegmentId;

    const handleCloseClick = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const handleDeleteClick = useCallback(async () => {
        if (selectedSegment) {
            if (tapOnToElement) {
                await deleteTap(tapOnToElement, true, true)(dispatch);
            }
            deleteSegment(selectedSegment, false, DesignMode.CanvasMode, tapOnToElement, true)(dispatch);
        }
        dispatch(showDialog());
    }, [dispatch, selectedSegment, tapOnToElement]);

    return {
        isOpen, locationIndex,
        handleCloseClick, handleDeleteClick
    };
};