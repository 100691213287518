import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { CpqInstallationType } from '../../models/cpqinstallationtype';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { SegmentData } from '../data/segment-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class SegmentSpanValidator implements Validator<SegmentData> {

    private installationTypes: CpqInstallationType[];
    private units: Units;

    constructor(installationTypes: CpqInstallationType[], units: Units) {
        this.installationTypes = installationTypes;
        this.units = units;
    }

    public validate(segment: SegmentData): ValidationResult | null {
        const installationType = this.installationTypes.find((i) => i.description === segment.installationType);
        if (!installationType) {
            throw new Error(`Installation type is invalid ${segment.installationType}`);
        }
        const { span_Maximum: maxSpan, span_Minimum: minSpan } = installationType;
        const measuredSpan = segment.measuredSpan?.toUnit(Units.feet, 2);
        const designSpan = segment.designSpan.toUnit(Units.feet, 2);
        if (designSpan.value !== 0) {
            if (designSpan.value > maxSpan) {
                const extent = segment.getExtent();
                const maximum = new DataValue(maxSpan, Units.feet);
                return {
                    buildId: segment.buildId,
                    itemId: segment.id,
                    itemType: ItemType.Segment,
                    shortDescription: i18next.t(LocalizationKeys.WarningDesignSpanExcessiveLength),
                    longDescription: i18next.t(LocalizationKeys.WarningDesignSpanExcessiveLengthLong, { length: maximum.format(this.units, 2) }),
                    type: ValidationResultType.Warning,
                    extent
                };
            }
            if (designSpan.value < minSpan) {
                const extent = segment.getExtent();
                const minimum = new DataValue(minSpan, Units.feet);
                return {
                    buildId: segment.buildId,
                    itemId: segment.id,
                    itemType: ItemType.Segment,
                    shortDescription: i18next.t(LocalizationKeys.ErrorDesignSpanInsufficientLength),
                    longDescription: i18next.t(LocalizationKeys.ErrorDesignSpanInsufficientLengthLong, { length: minimum.format(this.units, 2) }),
                    type: ValidationResultType.Error,
                    extent
                };
            }
            return null;
        }
        if (measuredSpan && measuredSpan.value > maxSpan) {
            const extent = segment.getExtent();
            const maximum = new DataValue(maxSpan, Units.feet);
            return {
                buildId: segment.buildId,
                itemId: segment.id,
                itemType: ItemType.Segment,
                shortDescription: i18next.t(LocalizationKeys.WarningSegmentSpanExcessiveLength),
                longDescription: i18next.t(LocalizationKeys.WarningSegmentSpanExcessiveLengthLong, { length: maximum.format(this.units, 2) }),
                type: ValidationResultType.Warning,
                extent
            };
        }
        if (measuredSpan && measuredSpan.value < minSpan) {
            const extent = segment.getExtent();
            const minimum = new DataValue(minSpan, Units.feet);
            return {
                buildId: segment.buildId,
                itemId: segment.id,
                itemType: ItemType.Segment,
                shortDescription: i18next.t(LocalizationKeys.ErrorSegmentSpanInsufficientLength),
                longDescription: i18next.t(LocalizationKeys.ErrorSegmentSpanInsufficientLengthLong, { length: minimum.format(this.units, 2) }),
                type: ValidationResultType.Error,
                extent
            };
        }
        return null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof SegmentData && entity.installationType !== null;
    }
}
