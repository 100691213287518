import { BuildType } from '../models/build-type';
import { CpqBuildConfiguration } from '../models/cpqbuildconfiguration';
import { CpqCablePart } from '../models/cpqcablepart';
import { CpqCountryState } from '../models/cpqcountrystate';
import { CpqInstallationType } from '../models/cpqinstallationtype';
import { CpqMultiportPart } from '../models/cpqmultiportpart';
import { CpqPretermPart } from '../models/cpqpretermpart';
import { CpqTetherPart } from '../models/cpqtetherpart';
import { SecuredService } from './abstract-secured-v2.service';

export class CpqService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/preset';

    public getCountryStates(buildType: BuildType): Promise<CpqCountryState[] | undefined> {
        return this.get(`${this.baseUrl}/countrystates/${buildType}`);
    }

    public getCableParts(soldTo?: string): Promise<CpqCablePart[] | undefined> {
        return this.post<CpqCablePart[]>(`${this.baseUrl}/parts`, { soldTo });
    }

    public getTetherParts(soldTo?: string): Promise<CpqTetherPart[] | undefined> {
        return this.post<CpqTetherPart[]>(`${this.baseUrl}/tethers`, { soldTo });
    }

    public getTethers(part: string, soldTo?: string): Promise<CpqTetherPart[] | undefined> {
        return this.post<CpqTetherPart[]>(`${this.baseUrl}/${part}/tethers`, { soldTo });
    }

    public getConfigurations(): Promise<CpqBuildConfiguration[] | undefined> {
        return this.get<CpqBuildConfiguration[]>(`${this.baseUrl}/configurations`);
    }

    public getInstallationTypes(): Promise<CpqInstallationType[] | undefined> {
        return this.get<CpqInstallationType[]>(`${this.baseUrl}/installationtypes`);
    }

    public getMultiportParts(): Promise<CpqMultiportPart[] | undefined> {
        return this.get<CpqMultiportPart[]>(`${this.baseUrl}/multiportparts`);
    }

    public getPretermsForCablePartNumber(cablePartNumber: string): Promise<CpqPretermPart[] | undefined> {
        const encoded = encodeURI(cablePartNumber);
        return this.get<CpqPretermPart[]>(`${this.baseUrl}/compatible/preterms/${encoded}`);
    }

    public updateFlexNapContracts(): Promise<string | undefined> {
        return this.post("api/cpq/updateflexnap");
    }

    public updateCpq(): Promise<string | undefined> {
        return this.post("api/cpq/updatecpq");
    }
}
