import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Terminal } from '../../../models/terminal';
import { StateModel } from '../../../redux/reducers';
import { selectTerminal, selectTether } from '../../../redux/selection.state';
import { addTerminal, deleteTerminal } from '../../../redux/tap.state';
import { LocalizationKeys } from '../../../locales/types';
import { FlexNapTerminalCardProps } from './flexnap-terminal-card.types';

export const useFlexNapTerminalCard = (props: FlexNapTerminalCardProps) => {
    const { t } = useTranslation();

    const { disabled, tether } = props;
    const terminalIcon = disabled ? require('./../../../icons/locked.png') : require('./../../../icons/terminal.png');
    const { selectedTerminalId } = useSelector((state: StateModel) => state.selection);
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [terminalCardTitle, setTerminalCardTitle] = useState("");

    useEffect(() => {
        const terminalExtensionType = tether?.terminal?.terminalExtension?.terminalExtensionType;
        setTerminalCardTitle(terminalExtensionType === 0 ? t(LocalizationKeys.LongtailTerminal) : t(LocalizationKeys.Terminal));
    }, [dispatch, t, tether]);

    const unselectTerminal = useCallback((): void => {
        dispatch(selectTerminal());
        dispatch(selectTether());
    }, [dispatch]);

    const addTerminalCallback = useCallback((tetherId: number) => {
        addTerminal(tetherId)(dispatch);
    }, [dispatch]);

    const deleteTerminalCallback = useCallback((terminal: Terminal) => {
        if (selectedTerminalId === terminal.id) {
            dispatch(selectTerminal());
        }
        deleteTerminal(terminal)(dispatch);
    }, [dispatch, selectedTerminalId]);

    const toggleCollapse = (): void => {
        const newCollapsed = !collapsed;
        setCollapsed(newCollapsed);
    }

    return {
        disabled, terminalCardTitle, terminalIcon, unselectTerminal, addTerminal: addTerminalCallback, deleteTerminal: deleteTerminalCallback,
        tether, toggleCollapse, collapsed
    };
};