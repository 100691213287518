import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { Typography } from '@orbit/theme-provider';

import { DateHelper } from '../../../helpers/date-helper';
import { LocalizationKeys, ReviewStatusLocalization } from '../../../locales/types';
import { ReviewStatus } from '../../../models/review-status';
import { TooltipDark } from '../../presets/preset/tooltip-dark';
import { ReviewListItemProps } from '../review-list.types';
import { useResolvedReviewListItem } from './resolved-review-list-item.hooks';

export const ResolvedReviewListItem = ({ review }: ReviewListItemProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        buildId,
        completionDateTime,
        completionNotes,
        status,
        handleDismissClick,
        handleShowBuildClick
    } = useResolvedReviewListItem(review);

    const approved = status === ReviewStatus.Approved;
    const rejected = status === ReviewStatus.Rejected;

    const statusIcon = approved ? require('../icon_approved.svg') : require('../icon_rejected.svg');

    const titleClass = classNames('status', {
        'approved': approved,
        'rejected': rejected
    })

    return (
        <div className={classNames('item', 'request')}>
            <div className='title resolved-tab'>
                <div className='title-info'>
                    <img alt='Status' src={statusIcon} />
                    <div className={titleClass}>
                        <Typography variant='buttonSmall' label={t(ReviewStatusLocalization[status])} />
                    </div>
                    <Typography variant='body1' label={` : ${t(LocalizationKeys.ReviewRequestTitle, {
                        buildId,
                        submissionDateTime: DateHelper.formatDateTimetoLocale(completionDateTime!),
                        interpolation: { escapeValue: false },
                    }
                    )}`} />
                </div>
                <div>
                    <TooltipDark arrow={true} placement="bottom" title={t(LocalizationKeys.LoadBuild)?.toLowerCase()}>
                        <TextButton onClick={handleShowBuildClick}><span className="material-icons">search</span></TextButton>
                    </TooltipDark>
                </div>
            </div>
            {completionNotes && <div className="message-resolved">{completionNotes}</div>}
            <div className="footer">
                <TextButton onClick={handleDismissClick}>{t(LocalizationKeys.Dismiss)}</TextButton>
            </div>
        </div>
    );
};