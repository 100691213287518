import './drawer-menu-header.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainedButton } from '@orbit/button';

import { LocalizationKeys } from '../../../../locales/types';
import { DesignMode } from '../../../../models/design-mode';
import { WorkspaceIcon } from '../../workspace/body/row/workspace-icon';
import { DrawerMenuHeaderProps as DrawerMenuHeaderProps2 } from '../drawer-menu.types';
import { useDrawerMenuHeader } from './drawer-menu-header.hooks';
import {
    DrawerSelectionActionGroup
} from './drawer-selection-action-group/drawer-selection-action-group';

export const DrawerMenuHeader = (props: DrawerMenuHeaderProps2) => {
    const { t } = useTranslation();
    const {
        titleLabel,
        workspaceName,
        designMode,
        handleShowAllClick,
        handleCloseClick,
        handleAddDesignAreaClick,
    } = useDrawerMenuHeader(props);

    return (
        <div className="drawer-header">
            <div className="drawer-menu-header">
                <div className="drawer-menu-workspace-container">
                    <WorkspaceIcon opacity={1} />
                    <div className="drawer-menu-workspace-info-container">
                        <div className="drawer-menu-workspace-name">
                            {workspaceName}
                        </div>
                        <button className="drawer-menu-show-workspaces clickable" onClick={handleShowAllClick}>
                            {t(LocalizationKeys.ShowAllWorkspaces)}
                        </button>
                    </div>
                </div>
                <button className="icon-button" onClick={handleCloseClick}>
                    <img alt="Close menu" src={require('../../../card/icon_close.svg')} />
                </button>
            </div>
            <div className="divider" />
            <div className="options">
                <label className="title">{titleLabel}</label>
                <DrawerSelectionActionGroup />
                {designMode === DesignMode.CanvasMode && (
                    <ContainedButton  className="add-design-area" onClick={handleAddDesignAreaClick}>
                        {t(LocalizationKeys.AddDesignArea)}
                    </ContainedButton>
                )}
            </div>
        </div>
    );
};
