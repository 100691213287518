import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@orbit/text-field';

import { BuilderHelper } from '../../../helpers/build-helper';
import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useRequestReviewDialog } from './request-review-dialog.hooks';

export const RequestReviewDialog = (): JSX.Element | null => {
    const {
        open,
        build,
        notes,
        handleClose,
        handleNotesChange,
        handleSubmitClick,
    } = useRequestReviewDialog();
    const { t } = useTranslation();

    return (
        <DialogComponent
            className="request-review-dialog"
            open={open}
            title={t(LocalizationKeys.RequestReviewDialogTitle, { build: BuilderHelper.getBuildDisplayId(build) })}
            positiveTitle={t(LocalizationKeys.Submit)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onClose={handleClose}
            onPositiveClick={handleSubmitClick}
            muiDialogProps={{ open: open, maxWidth: 'xs', fullWidth: true }}
        >
            <div style={{ width: '100%' }}>
                <TextField
                    palette="primary"
                    multiline
                    maxRows={4}
                    placeholder={t(LocalizationKeys.RequestReviewDialogNotesPlaceholder)}
                    value={notes}
                    onChange={handleNotesChange}
                    fullWidth={true}
                    margin={'normal'}
                />
            </div>
        </DialogComponent>
    );
};
