import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CabinetSplicePlanBuildInfo, CabinetSplicePlanHelper } from "../../../helpers/cabinet-splice-plan-helper";
import { LocalizationKeys } from "../../../locales/types";
import { getFiberAssignmentLabel } from "../../../models/fiber-assignment-type";
import { PortMode } from "../../../redux/cabinet-splice-plan.state";
import { DialogType, showDialog } from "../../../redux/overlay.state";
import { selectSplicePlanCabinet } from "../../../redux/selection.state";
import { selectedCabinetSelector } from "../../../selectors/elements.selectors";
import { cabinetSplicePlanSelector, selectedSplicePlanCabinetId } from "../../../selectors/root.selectors";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCabinetSplicePlanCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { cabinetSplicePlans } = useSelector(cabinetSplicePlanSelector);
    const cabinet = useSelector(selectedCabinetSelector);
    const portMode = cabinet && !cabinet.automaticSplicePlanMode ? PortMode.Manual : PortMode.Automatic;
    const cabinetId = useSelector(selectedSplicePlanCabinetId);

    const [builds, setBuilds] = useState<CabinetSplicePlanBuildInfo[]>([]);

    const cabinetSplicePlan = cabinetId ? cabinetSplicePlans[cabinetId] : null;

    const isCardOpen = !!cabinet;
    const titleLabel = t(LocalizationKeys.CabinetSplicePlanDialogTitle, {
        cabinet: cabinet?.tagOverride ?? cabinet?.tag ?? "",
    });
    const assignmentLabel = cabinet ? getFiberAssignmentLabel(cabinet.fiberAssignment) : "";
    const cabinetSizeLabel = t(LocalizationKeys.CabinetSplicePlanCabinetSizeLabel, {
        portCount: cabinet?.portCount ?? -1,
    });
    const totalFibers = cabinetSplicePlan?.builds.reduce((sum, b) => (sum += b.portCount), 0);
    const warningLabel =
        cabinet && totalFibers && cabinet.portCount < totalFibers
            ? t(LocalizationKeys.InsufficientCabinetSizeWarning)
            : undefined;
    const portModeLabel =
        portMode === PortMode.Automatic
            ? t(LocalizationKeys.CabinetSplicePlanPortNumberAutomatic)
            : t(LocalizationKeys.CabinetSplicePlanPortNumberManual);
    const showOrderArrows = portMode === PortMode.Automatic;

    useEffect(() => {
        const cabinetSplicePlan = cabinetId ? cabinetSplicePlans[cabinetId] : null;
        if (cabinetSplicePlan)
            setBuilds(CabinetSplicePlanHelper.buildsToBuildInfos(cabinetSplicePlan.builds, 0, 1, cabinet?.portCount));
    }, [cabinet, cabinetId, cabinetSplicePlans]);

    const onClose = useCallback(() => {
        dispatch(selectSplicePlanCabinet());
    }, [dispatch]);

    const handlePortModeClick = useCallback(() => {
        dispatch(showDialog(DialogType.CabinetPortMode));
    }, [dispatch]);

    return {
        portModeLabel,
        isCardOpen,
        titleLabel,
        cabinetSizeLabel,
        assignmentLabel,
        warningLabel,
        builds,
        showOrderArrows,
        onClose,
        handlePortModeClick,
        t,
    };
};
