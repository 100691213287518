import { Dispatch } from '@reduxjs/toolkit';

import { store } from '../dna';
import { BuilderHelper } from '../helpers/build-helper';
import { SegmentHelper } from '../helpers/segment-helper';
import { CommandType } from '../history/command-type';
import { Cabinet } from '../models/cabinet';
import { Element } from '../models/element';
import { ElementType } from '../models/element-type';
import { Handhole } from '../models/handhole';
import { Manhole } from '../models/manhole';
import { Pole } from '../models/pole';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { Vault } from '../models/vault';
import { ElementService } from '../services/element.service';
import { SchrodingerBuildService } from '../services/schrodinger-build.service';
import { SegmentService } from '../services/segment.service';
import { requestIsSuccess } from './action';
import {
    addCabinetAction, deleteCabinet, deleteImportedCabinets, loadCabinets, modifyCabinet
} from './cabinet.state';
import {
    addHandholeAction, deleteHandhole, deleteImportedHandholes, loadHandholes, modifyHandhole
} from './handhole.state';
import { push } from './history.state';
import {
    addManholeAction, deleteImportedManholes, deleteManhole, loadManholes, modifyManhole
} from './manhole.state';
import { deleteImportedParcels, deleteParcel, loadParcels } from './parcel.state';
import {
    addPoleAction, deleteImportedPoles, deleteImportedPoleSpans, deletePole, loadPoles, modifyPole
} from './pole.state';
import { deleteTapsWithElementId } from './schrodinger/tap.state';
import { deleteNapsWithElementId } from './tap.state';
import {
    addVaultAction, deleteImportedVaults, deleteVault, loadVaults, modifyVault
} from './vault.state';

export const loadElements = (bbox: number[], required?: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new ElementService();
        const elements = await service.getElements(bbox, required);
        if (elements) {
            dispatch(loadPoles(elements.poles));
            dispatch(loadCabinets(elements.cabinets));
            dispatch(loadParcels(elements.parcels));
            dispatch(loadManholes(elements.manholes));
            dispatch(loadHandholes(elements.handholes));
            dispatch(loadVaults(elements.vaults));
        }
    };
};

export const unloadElements = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(loadPoles([]));
        dispatch(loadCabinets([]));
        dispatch(loadParcels([]));
        dispatch(loadManholes([]));
        dispatch(loadHandholes([]));
        dispatch(loadVaults([]));
    };
};

export const updateSchrodingerBuildLocationIds = (element: Element): void => {
    const state = store.getState();
    const { segments, schrodingerBuilds } = state.build;
    const schrodingerBuildService = new SchrodingerBuildService();

    const segmentsOnElement = SegmentHelper.getSegmentsOnElement(element.id, segments);
    const schrodingerBuildIdsOnElement = (BuilderHelper.getBuildsFromSegments(schrodingerBuilds, segmentsOnElement) as SchrodingerBuild[])?.map(b => b.id);
    if (!schrodingerBuildIdsOnElement) {
        return;
    }
    const schrodingerSegmentsOnElement = segmentsOnElement.filter(s => schrodingerBuildIdsOnElement.includes(s.buildId));
    schrodingerSegmentsOnElement.forEach(s => schrodingerBuildService.updateLocationIdFromElement(s.buildId, s.id, element.id));
};

export const isElementUsedBySegment = async (id: number): Promise<boolean | undefined> => {
    return new SegmentService().anySegmentUsingElement(id);
};

export const deleteElement = (elementId: number, elementType: ElementType, ignoreHistory = false) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new ElementService();
        const successDeleteElement = await requestIsSuccess(service, service.deleteElement(elementId), true);
        if (successDeleteElement) {
            switch (elementType) {
                case ElementType.Pole:
                    dispatch(deletePole(elementId));
                    break;
                case ElementType.Cabinet:
                    dispatch(deleteCabinet(elementId));
                    break;
                case ElementType.Handhole:
                    dispatch(deleteHandhole(elementId));
                    break;
                case ElementType.Manhole:
                    dispatch(deleteManhole(elementId));
                    break;
                case ElementType.Parcel:
                    dispatch(deleteParcel(elementId));
                    break;
                case ElementType.Vault:
                    dispatch(deleteVault(elementId));
                    break;
            }
            const successAccessPointDelete = await requestIsSuccess(service, service.deleteAccessPoints(elementId), true);
            if (successAccessPointDelete) {
                dispatch(deleteNapsWithElementId(elementId));
                dispatch(deleteTapsWithElementId(elementId));
            }

            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.DeleteElement, payload: { id: elementId, type: elementType } }));
            }
        }
    };
};

export const undoDelete = (id: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new ElementService();
        const element = await service.undoDelete(id);
        if (!element) return;
        switch (element.type) {
            case ElementType.Pole:
                dispatch(addPoleAction(element as Pole));
                break;
            case ElementType.Cabinet:
                dispatch(addCabinetAction(element as Cabinet));
                break;
            case ElementType.Handhole:
                dispatch(addHandholeAction(element as Handhole));
                break;
            case ElementType.Manhole:
                dispatch(addManholeAction(element as Manhole));
                break;
            case ElementType.Vault:
                dispatch(addVaultAction(element as Vault));
                break;
        }
    };
}

export const deleteImportedElements = (importedGisDataId: number) => {
    return (dispatch: Dispatch): void => {
        dispatch(deleteImportedPoles(importedGisDataId));
        dispatch(deleteImportedPoleSpans(importedGisDataId));
        dispatch(deleteImportedCabinets(importedGisDataId));
        dispatch(deleteImportedHandholes(importedGisDataId));
        dispatch(deleteImportedManholes(importedGisDataId));
        dispatch(deleteImportedParcels(importedGisDataId));
        dispatch(deleteImportedVaults(importedGisDataId));
    };
};

export const updateElement = (oldElement: Element, newElement: Element) => {
    return async (dispatch: Dispatch): Promise<boolean> => {
        const service = new ElementService();
        const element = await service.patchElement(oldElement, newElement);
        switch (element?.type) {
            case ElementType.Cabinet: dispatch(modifyCabinet(element as Cabinet)); break;
            case ElementType.Handhole: dispatch(modifyHandhole(element as Handhole)); break;
            case ElementType.Manhole: dispatch(modifyManhole(element as Manhole)); break;
            case ElementType.Pole: dispatch(modifyPole(element as Pole)); break;
            case ElementType.Vault: dispatch(modifyVault(element as Vault)); break;
            case undefined: return false;
        }
        return true;
    };
};

export const loadMissingElement = (element: Element) => {
    return (dispatch: Dispatch): void => {
        switch (element?.type) {
            case ElementType.Cabinet: dispatch(modifyCabinet(element as Cabinet)); break;
            case ElementType.Handhole: dispatch(modifyHandhole(element as Handhole)); break;
            case ElementType.Manhole: dispatch(modifyManhole(element as Manhole)); break;
            case ElementType.Pole: dispatch(modifyPole(element as Pole)); break;
            case ElementType.Vault: dispatch(modifyVault(element as Vault)); break;
        }
    };
};