import { FeatureFlags } from '../models/feature-flags';
import { SecuredService } from './abstract-secured-v2.service';

export class FeatureFlagsService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/featureFlags';

    public async getFeatureFlags(): Promise<FeatureFlags | undefined> {
        return this.get(this.baseUrl);
    }
}
