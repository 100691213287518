import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../locales/types';
import { PretermDiagramProps } from '../../nap-diagram.types';
import { TextSvg } from '../text-svg';
import { TerminalLeg } from '../terminal-leg';
import { usePretermDiagramSvg } from './preterm-diagram-svg.hooks';

export const PretermDiagramSvg = (props: PretermDiagramProps): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        pretermBuild, showPretermBox, pretermWidth, pretermHeight, legTranslation, x, y, className
    } = usePretermDiagramSvg(props);

    if (!pretermBuild) {
        return <></>
    }

    return (
        <g id={'preterm-' + pretermBuild.id} className={className} transform={`translate(${x}, ${y})`}>
            <g transform={`translate(${legTranslation})`}>
                <TerminalLeg fill="#606060" longConnector={true} />
            </g>
            {
                showPretermBox ?
                    <g className="preterm-box" transform={'translate(0 98)'}>
                        <rect width={pretermWidth} height={pretermHeight} rx="2" fill="#404040" stroke="#9e9e9e" strokeWidth="1"/>
                        <TextSvg x={pretermWidth / 2} y={(pretermHeight / 2) - 8} text={`${t(LocalizationKeys.Build)}`} fontSize={12} />
                        <TextSvg x={pretermWidth / 2} y={(pretermHeight / 2) + 8} text={`${pretermBuild.id}`} fontSize={12} />
                    </g>
                    : null
            }
        </g>
    );
};
