import process from "process";
import { HttpTransportType, IHttpConnectionOptions } from "@microsoft/signalr";

export enum DnaHubConstants {
    GetConnectionId = "GetConnectionId",

    AddPreset = "AddPreset",
    UpdatePreset = "UpdatePreset",
    DeletePreset = "DeletePreset",
}

const isDevelopment = !process.env.REACT_APP_MULESOFT_PROXY_ENDPOINT;

const rootUrl = process.env.REACT_APP_MULESOFT_PROXY_ENDPOINT ?? "/";
export const dnaHubUrl = `${rootUrl}dnahub`;

export const hubOptions: IHttpConnectionOptions = {
    transport: isDevelopment ? HttpTransportType.LongPolling : HttpTransportType.WebSockets,
    skipNegotiation: !isDevelopment
};