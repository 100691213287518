import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { deleteFlexNapBuilds } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { selectedFlexNapBuildSelector } from '../../../selectors/build.selectors';
import { dialogSelector, flexnapBuildsSelector } from '../../../selectors/root.selectors';
import { DeleteBuildDialogProps } from './delete-build-dialog.types';

export const useDeleteFlexNapBuildDialog = (): DeleteBuildDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dialog = useSelector(dialogSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const build = useSelector(selectedFlexNapBuildSelector);
    const buildId = build?.id ?? -1;
    const isOpen = dialog === DialogType.DeleteFlexNapBuild && buildId > 0;
    const title = t(LocalizationKeys.DeleteBuild, { buildId: buildId });
    const childPretermBuilds = flexNapBuilds.filter((b) => !!b.pretermLateral && b.pretermLateral.parentBuildId === buildId);

    const onClose = useCallback(() => {
        dispatch(showDialog()); // closes the dialog; empty PayloadAction value
    }, [dispatch]);

    const onDeleteBuild = useCallback(() => {
        deleteFlexNapBuilds([buildId], childPretermBuilds)(dispatch);
        dispatch(showDialog()); // closes the dialog; empty PayloadAction value
    }, [buildId, childPretermBuilds, dispatch]);

    return {
        isOpen,
        onClose,
        onDeleteBuild,
        title,
    };
}
