import * as rfc from 'rfc6902';

import { Manhole, ManholeData } from '../models/manhole';
import { PointLike } from '../models/pointlike';
import { SecuredService } from './abstract-secured-v2.service';

export class ManholeService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/manhole';

    public getAll(): Promise<Manhole[] | undefined> {
        return this.get<Manhole[]>(this.baseUrl);
    }

    public getManholes(bbox: number[], required?: number[]): Promise<Manhole[] | undefined> {
        return this.post<Manhole[]>(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public getManholesByIds(ids: number[]): Promise<Manhole[] | undefined> {
        return this.post<Manhole[]>(`${this.baseUrl}/byids`, ids);
    }

    public addManhole(point: PointLike): Promise<Manhole | undefined> {
        return this.post(this.baseUrl, point);
    }

    public addManholes(data: ManholeData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/import`, { data, importedGisDataId });
    }

    public patchManhole(oldManhole: Manhole, newManhole: Manhole): Promise<Manhole | undefined> {
        const patch = rfc.createPatch(oldManhole, newManhole);
        return this.patch(`${this.baseUrl}/${oldManhole.id}`, patch);
    }
}
