import React, { useCallback } from 'react';
import { NapDiagramSvgProps } from '../nap-diagram.types';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { Tether } from '../../../models/tether';
import { TapDiagramSvg } from './tap-diagram-svg/tap-diagram-svg';
import { TetherDiagramSvg } from './tether-diagram-svg/tether-diagram-svg';
import { TerminalDiagramSvg } from './terminal-diagram-svg/terminal-diagram-svg';
import { useNapSvg } from './nap-svg.hooks';
import { TetherTerminalContainer } from './tether-terminal-container/tether-terminal-container';
import { DiagramAddButton } from './diagram-add-button';
import { DiagramDeleteButton } from './diagram-delete-button';
import { TextSvg } from './text-svg';

import '../nap-svg.scss';
import { PretermDiagramSvg } from './preterm-diagram-svg/preterm-diagram-svg';

export const NapDiagramSvg = (props: NapDiagramSvgProps): JSX.Element => {
    const {
        selectedTetherId, getTap, getTether, getTetherPretermBuild,
        selectTether, addTether, deleteTether, addDisabled,
        disabled, deleteDisabled, locationId
    } = useNapSvg(props);

    const buttonBar = useCallback((): JSX.Element => {
        return (
            <g className="btn-add-delete" transform="translate(-16, 48)">
                {!disabled && <DiagramAddButton onClick={addTether} disabled={addDisabled}/>}
                {!disabled && <DiagramDeleteButton onClick={deleteTether} disabled={deleteDisabled} /> }
            </g>
        );
    }, [addTether, deleteTether, disabled, deleteDisabled,addDisabled])

    const createTether = useCallback((tapIndex: number, tetherIndex: number, tether?: Tether): JSX.Element => {
        return (
            <TetherDiagramSvg
                tapIndex={tapIndex}
                tetherIndex={tetherIndex}
                tether={tether}
                selectedTetherId={selectedTetherId}
            />
        );
    }, [selectedTetherId]);

    const createTerminal = useCallback((tapIndex: number, tetherIndex: number, tether: Tether | undefined): JSX.Element => {
        return (
            <TerminalDiagramSvg
                tapIndex={tapIndex}
                tetherIndex={tetherIndex}
                tether={tether}
            />
        );
    }, []);

    const createPreterm = useCallback((tapIndex: number, tetherIndex: number, pretermTetherIds: number[], pretermBuild: FlexNapBuild, tether: Tether): JSX.Element => {
        return ( 
            <PretermDiagramSvg
                tapIndex={tapIndex}
                tetherIndex={tetherIndex}
                pretermTetherIds={pretermTetherIds}
                pretermBuild={pretermBuild}
                tether={tether}
            />
        );
    }, []);

    const createLocationLabel = (location: string): JSX.Element => {
        return (
            <g className="location-number" transform="translate(8)">
                <rect className="location" width="40" height="24" rx="4" />
                <TextSvg x={20} y={12} text={location} fontSize={10} />
            </g>
        );
    }

    const createCOLabel = (): JSX.Element => {
        return (
            <g transform="translate(310, 50)">
                <text className="CO-nap">CO</text>
                <g transform="translate(10, -17)">
                    <path d="M12,4,10.6,5.4,16.2,11H4v2H16.2l-5.6,5.6L12,20l8-8Z" />
                </g>
            </g>
        );
    }

    const createTap = useCallback((tapIndex: number): JSX.Element => {
        const tap = getTap(tapIndex);
        const tether1 = getTether(tapIndex, 1);
        const tether2 = getTether(tapIndex, 2);
        const tether1PretermBuild = tether1 ? getTetherPretermBuild(tether1.id) : undefined;
        const tether2PretermBuild = tether2 ? getTetherPretermBuild(tether2.id) : undefined;
        const transform = tapIndex === 2 ? 'translate(-14)' : undefined;

        return (
            <g transform={transform}>
                <TapDiagramSvg index={tapIndex} tap={tap} />
                <TetherTerminalContainer tapIndex={tapIndex} tetherIndex={1} tetherId={tether1?.id} selectedTetherId={selectedTetherId} onClick={selectTether}>
                    {createTether(tapIndex, 1, tether1)}
                    {tether1 && tether1PretermBuild?.pretermLateral?.parentTetherIds ? createPreterm(tapIndex, 1, tether1PretermBuild.pretermLateral.parentTetherIds, tether1PretermBuild, tether1) : createTerminal(tapIndex, 1, tether1)}
                </TetherTerminalContainer>
                <TetherTerminalContainer tapIndex={tapIndex} tetherIndex={2} tetherId={tether2?.id} selectedTetherId={selectedTetherId} onClick={selectTether}>
                    {createTether(tapIndex, 2, tether2)}
                    {tether2 && tether2PretermBuild?.pretermLateral?.parentTetherIds ? createPreterm(tapIndex, 2, tether2PretermBuild.pretermLateral.parentTetherIds, tether2PretermBuild, tether2) : createTerminal(tapIndex, 2, tether2)}
                </TetherTerminalContainer>
            </g>
        );
    }, [getTap, getTether, getTetherPretermBuild, createTerminal, createTether, createPreterm, selectTether, selectedTetherId]);

    return (
        <svg width="352" height="352" className="diagram">
            <defs>
                <filter id="btnShadow" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse">
                    <feOffset dy="6" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(0 8)">
                <line className="cable" y1="10" y2="10" x2="100%" strokeWidth="6" />
                {createTap(1)}
                {createTap(2)}
                {createLocationLabel(locationId)}
                {createCOLabel()}
            </g>
            {buttonBar()}
        </svg>
    );
}
