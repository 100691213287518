/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { StateModel } from "../../redux/reducers";
import { Nap } from '../../models/nap';
import { Tether } from '../../models/tether';
import { useAccessPointLocationId } from '../access-point-diagram/access-point-diagram.hooks';
import { NapDiagramProps } from './nap-diagram.types';

export const useNapDiagram = (props: NapDiagramProps) => {
    const { nap, pretermBuilds, disabled } = props;
    const { taps, tethers, selectedTetherId, selectedTerminalId, locationId } = useNapTapsTethers(nap);

    return {
        nap, taps, tethers, selectedTetherId, selectedTerminalId, pretermBuilds, locationId, disabled
    };
};

const useNapTapsTethers = (nap: Nap) => {
    const state = useSelector((state: StateModel) => state);
    const { taps, tethers } = state.taps;
    const { selectedTetherId, selectedTerminalId } = state.selection;
    const locationId = useAccessPointLocationId(state, nap.elementId, nap.buildId);

    const [napTaps, setNapTaps] = useState(taps.filter((t) => t.napId === nap.id));
    const [tapTethers, setTapTethers] = useState<Tether[]>([]);

    useEffect(() => {
        setNapTaps(taps.filter((t) => t.napId === nap.id));
    }, [nap, taps]);

    useEffect(() => {
        const napTapIds = new Set(napTaps.map((t) => t.id));
        setTapTethers(tethers.filter((t) => napTapIds.has(t.tapId)));
    }, [napTaps, tethers]);

    return { taps: napTaps, tethers: tapTethers, selectedTetherId, selectedTerminalId, locationId };
}