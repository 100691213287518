/* eslint-disable no-prototype-builtins */
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { ValidationResultService } from '../../services/validation-result.service';
import { ValidationResult } from '../../validation/validation-result';

export interface ValidationState {
    validationResults: { [workspaceId: number]: ValidationResult[] };
    buildIds: number[];
}

const initialState: ValidationState = { validationResults: {}, buildIds: [] };

function updateBuildValidationReducer(state: ValidationState, action: PayloadAction<{ workspaceId: number; buildId: number; validationResults: ValidationResult[] }>): void {
    const { workspaceId, buildId, validationResults } = action.payload;
    if (state.buildIds.includes(buildId) && !!state.validationResults[workspaceId]) {
        state.validationResults[workspaceId] = state.validationResults[workspaceId].filter(r => r.buildId !== buildId);
    }
    else {
        state.buildIds.push(buildId);
    }
    if (!state.validationResults[workspaceId]) {
        state.validationResults[workspaceId] = [];
    }
    state.validationResults[workspaceId].push(...validationResults);
}

function clearBuildValidationResultsReducer(state: ValidationState, action: PayloadAction<{ workspaceId: number; buildIds: number[] }>): void {
    const { workspaceId, buildIds } = action.payload;
    const buildIdsSet = new Set(buildIds);
    if (state.validationResults[workspaceId]) {
        state.validationResults[workspaceId] = state.validationResults[workspaceId].filter((vr) => vr.buildId && !buildIdsSet.has(vr.buildId));
    }
    state.buildIds = state.buildIds.filter(b => !buildIdsSet.has(b));
}

function resetBuildValidationResultsReducer(state: ValidationState): void {
    state.buildIds = initialState.buildIds;
    state.validationResults = initialState.validationResults;
}

const { actions, reducer } = createSlice({
    name: 'validation',
    initialState,
    reducers: {
        clearBuildValidationResults: clearBuildValidationResultsReducer,
        updateBuildValidation: updateBuildValidationReducer,
        resetBuildValidationResults: resetBuildValidationResultsReducer
    },
});

export { reducer as ValidationReducer };
export const { clearBuildValidationResults, resetBuildValidationResults } = actions;

export const updateBuildValidation = (workspaceId: number | undefined, buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const validationResultService = new ValidationResultService();
        const validationResults = await validationResultService.getBuildValidation(buildId) || [];
        if (!workspaceId) {
            return;
        }
        dispatch(actions.updateBuildValidation({ workspaceId, buildId, validationResults }));
    };
};
