import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useDeleteFlexNapBuildDialog } from './delete-flexnap-build-dialog.hooks';

export const DeleteFlexNapBuildDialog = (): JSX.Element | null =>  {
    const { t } = useTranslation();
    
    const {
        isOpen,
        onClose,
        onDeleteBuild,
        title,
    } = useDeleteFlexNapBuildDialog();

    return( !isOpen ? null :
        <DialogComponent
            open={isOpen}
            title={title}
            positiveTitle={t(LocalizationKeys.Delete)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={onDeleteBuild}
            onNegativeClick={onClose}
            onClose={onClose}
            negativeStyleButton
        />
    );
}