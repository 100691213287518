import './card.component.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { LocalizationKeys, TooltipLocalizationKeys } from '../../locales/types';
import { useCard } from './card.hooks';
import { CardComponentProps } from './card.types';

export const Card = (props: CardComponentProps) => {
    const { t } = useTranslation();
    const {
        id,
        hideToggleCollapse,
        cardClassName,
        collapsed,
        collapseTooltip,
        expandTooltip,
        children,
        onCloseClick,
        toggleCollapse,
    } = useCard(props);

    const defaultExpandTooltip = t(TooltipLocalizationKeys.Expand);
    const defaultCollapseTooltip = t(TooltipLocalizationKeys.Collapse);

    return (
        <div id={id} className={cardClassName}>
            <div className="card-controls">
                <Tooltip arrow={true} placement="bottom" title={t(LocalizationKeys.Close).toLowerCase()}>
                    <button className="icon-button" onClick={onCloseClick}>
                        <img alt="Close card" src={require('./icon_close.svg')} />
                    </button>
                </Tooltip>
                {!hideToggleCollapse && (
                    <Tooltip
                        arrow={true}
                        placement="bottom"
                        title={(collapsed ? expandTooltip ?? defaultExpandTooltip : collapseTooltip ?? defaultCollapseTooltip).toLowerCase()}
                    >
                        <button className="icon-button" onClick={toggleCollapse}>
                            {collapsed ? (
                                <img alt="Reveal card" src={require('./icon_open_chevron.svg')} />
                            ) : (
                                <img alt="Collapse card" src={require('./icon_close_chevron.svg')} />
                            )}
                        </button>
                    </Tooltip>
                )}
            </div>
            {children}
        </div>
    );
};
