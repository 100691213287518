import i18next from 'i18next';
import { useEffect, useState } from 'react';

import { CableCalculationsHelper } from '../../../helpers/cable-calculations-helper';
import { LocalizationKeys } from '../../../locales/types';
import { Build } from '../../../models/build';
import { BuildSegment } from '../../../models/build-segment';
import { DataValue } from '../../../models/datavalue';
import { Element } from '../../../models/element';
import { Path } from '../../../models/path';
import { DesignAreaState } from '../../../redux/design-area.state';
import { StateModel } from '../../../redux/reducers';

export const getDesignAreaDisplayText = (selectedBuildId: number | undefined, designarea: DesignAreaState): string => {
    const designAreaIds = selectedBuildId
        ? designarea.designAreaBuilds
            .filter((dab) => dab.buildIds.includes(selectedBuildId))
            .map((dab) => dab.designAreaId)
        : [];
    const designAreas = designAreaIds
        ? designarea.designAreas
            .filter((da) => designAreaIds.includes(da.id))
        : [];

    let designAreaDisplayText: string;
    if (designAreas.length === 0)
        designAreaDisplayText = i18next.t(LocalizationKeys.Unassigned);
    else if (designAreas.length > 1)
        designAreaDisplayText = i18next.t(LocalizationKeys.NotApplicable);
    else
        designAreaDisplayText = designAreas[0].name || designAreas[0].projectId;

    return designAreaDisplayText;
}

export const getDesignAreaId = (selectedBuildId: number, designarea: DesignAreaState): number => {
    const designAreaIds = designarea.designAreaBuilds
        .filter((dab) => dab.buildIds.includes(selectedBuildId))
        .map((dab) => dab.designAreaId)

    const designAreas = designarea.designAreas
        .filter((da) => designAreaIds.includes(da.id));

    if (designAreas.length === 1)
        return designAreas[0].id;
    return -1;
}

const useElements = (workspace: StateModel): Element[] => {
    const { poles } = workspace.pole;
    const { manholes } = workspace.manhole;
    const { handholes } = workspace.handhole;
    const { vaults } = workspace.vault;
    const { cabinets } = workspace.cabinet;
    const [elements, setElements] = useState([...poles, ...manholes, ...handholes, ...vaults, ...cabinets]);

    useEffect(() => {
        setElements([...poles, ...manholes, ...handholes, ...vaults, ...cabinets]);
        return (): void => {
            setElements([]);
        }
    }, [poles, manholes, handholes, vaults, cabinets]);

    return elements;
}

const usePaths = (workspace: StateModel): Path[] => {
    const { trenches } = workspace.trench;
    const { bores } = workspace.bore;
    const [paths, setPaths] = useState([...trenches, ...bores]);

    useEffect(() => {
        setPaths([...trenches, ...bores]);
        return (): void => {
            setPaths([]);
        }
    }, [trenches, bores]);

    return paths;
}

export const useTotalLength = (workspace: StateModel, build: Build, segments: BuildSegment[], isPreterm?: boolean): DataValue => {
    const paths = usePaths(workspace);
    const elements = useElements(workspace);
    const { displayUnits } = workspace.authentication;
    const [totalLength, setTotalLength] = useState(CableCalculationsHelper.calculateTotalCableLength(displayUnits, build, segments, elements, paths, isPreterm));

    useEffect(() => {
        setTotalLength(CableCalculationsHelper.calculateTotalCableLength(displayUnits, build, segments, elements, paths, isPreterm));
    }, [displayUnits, build, segments, elements, paths, isPreterm]);

    return totalLength;
}
