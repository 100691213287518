import { transform } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { Cabinet } from '../../../models/cabinet';
import { isOfType } from '../../../models/element';
import { ElementType } from '../../../models/element-type';
import { PowerStatus } from '../../../models/power-status';
import { updateCabinet } from '../../../redux/cabinet.state';
import { deleteElement } from '../../../redux/element.actions';
import { selectElement, selectSplicePlanCabinet } from '../../../redux/selection.state';
import {
    pathsSelector, segmentsSelector, selectedElementSelector
} from '../../../selectors/root.selectors';
import { CabinetCardHooks } from './cabinet-card.types';

export const useCabinetCard = (): CabinetCardHooks => {
    const selectedElement = useSelector(selectedElementSelector);
    const paths = useSelector(pathsSelector);
    const segments = useSelector(segmentsSelector);
    
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const cabinet = isOfType<Cabinet>(selectedElement, ElementType.Cabinet) ? selectedElement as Cabinet : undefined;
    const epsg4326 = new Projection({ code: 'EPSG:4326' });
    const epsg3857 = new Projection({ code: 'EPSG:3857' });
    const coordinates = cabinet ? transform([cabinet.x, cabinet.y], epsg3857, epsg4326) : [0, 0];
    const isConnectedToPath = !!paths.find((p) => p.fromElementId === cabinet?.id || p.toElementId === cabinet?.id);
    const isConnectedToSegment = !!segments.find((s) => s.fromId === cabinet?.id || s.toId === cabinet?.id);
    let titleDeleteBtn = '';
    if (isConnectedToPath) {
        titleDeleteBtn = t(LocalizationKeys.ConnectedPathWarning);
    } else if (isConnectedToSegment) {
        titleDeleteBtn = t(LocalizationKeys.ConnectedSegmentWarning);
    }
    const deleteDisabled = isConnectedToPath || isConnectedToSegment;
    const isImported = cabinet?.isImported ?? false;
    const portCountValues = [48, 72, 144, 288, 432, 576, 864];
    const splicePlanButtonDisabled = !isConnectedToSegment;
    
    const unselectCabinet = useCallback((): void => {
        dispatch(selectElement());
    }, [dispatch]);

    const deleteCabinet = useCallback(() => {
        cabinet && deleteElement(cabinet.id, ElementType.Cabinet)(dispatch);
        unselectCabinet();
        setShowDeleteDialog(false);
    }, [cabinet, dispatch, unselectCabinet]);

    const handleDeleteClick = useCallback((): void => {
        if (isImported) {
            deleteCabinet();
        }
        else {
            setShowDeleteDialog(true);
        }
    }, [deleteCabinet, isImported]);

    const handleDeleteDialogClose = (): void => {
        setShowDeleteDialog(false);
    };

    const handleIdOverrideUpdate = useCallback((value?: string) => {
        if (cabinet && value) {
            const updatedCabinet = {
                ...cabinet,
                tagOverride: value
            };
            dispatch(updateCabinet(cabinet, updatedCabinet));
        }
    }, [cabinet, dispatch]);

    const handlePowerStatusUpdate = useCallback((value?: string) => {
        const powerStatus: PowerStatus | undefined = value && PowerStatus[value];
        if (cabinet && powerStatus) {
            const updatedCabinet: Cabinet = {
                ...cabinet,
                powerStatus,
            };
            dispatch(updateCabinet(cabinet, updatedCabinet));
        }
    }, [cabinet, dispatch]);

    const handlePortCountUpdate = useCallback((value?: string) => {
        const portCount = value ? parseInt(value) : undefined;
        if (cabinet && portCount) {
            const updatedCabinet: Cabinet = {
                ...cabinet,
                portCount,
            };
            dispatch(updateCabinet(cabinet, updatedCabinet));
        }
    }, [cabinet, dispatch]);

    const handleSplicePlanClick = useCallback(() => {
        dispatch(selectElement());
        dispatch(selectSplicePlanCabinet(cabinet?.id))
    }, [cabinet, dispatch]);

    return {
        cabinet,
        coordinates,
        portCountValues,
        titleDeleteBtn,
        deleteDisabled,
        splicePlanButtonDisabled,
        showDeleteDialog,
        handleDeleteClick,
        handleDeleteDialogClose,
        unselectCabinet,
        handleIdOverrideUpdate,
        handlePowerStatusUpdate,
        handlePortCountUpdate,
        handleSplicePlanClick,
        deleteCabinet,
        t,
    };
};
