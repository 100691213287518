import * as ol from 'ol';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import TileSource from 'ol/source/Tile';
import BingConfiguration from './bingmaps.key.json';

export class BingMapsFactory {

    public static createBingMap(target: string | HTMLElement, roadMap: boolean): ol.Map {
        const layers = this.createBingMapLayers(roadMap);
        return new ol.Map({
            target,
            layers,
            view: new ol.View({
                zoom: 5,
                minZoom: 3,
                maxZoom: 20,
                projection: 'EPSG:3857',
            }),
            controls: [],
        });
    }

    private static getMaxZoomForImagerySet(imagerySet: string): number {
        switch (imagerySet) {
            case 'AerialWithLabels': return 19;
            case 'Road': return 20;
            default: throw new Error('Unhandled imagery set ' + imagerySet);
        }
    }
    private static createBingMapLayers(showRoadMap: boolean) {
        const aerialMap = this.createTileLayer(this.getBingMapsSource('AerialWithLabels'));
        const roadMap = this.createTileLayer(this.getBingMapsSource('Road'));
        if (showRoadMap) {
            aerialMap.setVisible(false);
        }
        else {
            roadMap.setVisible(false);
        }
        return [aerialMap, roadMap];
    }

    private static createTileLayer(source: TileSource): TileLayer {
        return new TileLayer({
            source,
        });
    }

    private static getBingMapsSource(imagerySet: string): BingMaps {
        return new BingMaps({
            key: BingConfiguration.key,
            imagerySet,
            maxZoom: this.getMaxZoomForImagerySet(imagerySet),
        });
    }
}
