import './build-row.scss';

import React from 'react';

import { BuildIcon } from './build-icon';
import { useBuildRow } from './build-row.hooks';
import { BuildRowProps } from './build-row.types';
import { BuildToolbar } from './build-toolbar';
import { BuildStatus } from './build-toolbar-status';

export const BuildRow = (props: BuildRowProps) => {
    const { rowClassName, onRowEnter, onRowLeave, displayId, workspaceId, lastUpdated, showTools, build } = useBuildRow(props);

    return (
        <div className={rowClassName} onMouseEnter={(): void => onRowEnter(build.id)} onMouseLeave={onRowLeave}>
            <BuildIcon build={build} />
            <div className="build-row-end">
                <div className="build-info-container">
                    <div className="row">
                        <div className="build-id">
                            {displayId}
                        </div>
                        {showTools ? (
                            <BuildToolbar
                                build={build}
                                workspaceId={workspaceId}
                            />
                        ) : (
                            build.lockedById && <BuildStatus build={build} />
                        )}
                    </div>
                    <div className="row">
                        <div className="build-last-modified">
                            {lastUpdated}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}