import './map-tools.scss';

import React, { Component } from 'react';

import { BulkCableTool } from '../../design-tools/bulk-cable-tool';
import { DesignAreaTool } from '../../design-tools/design-area-tool';
import { FlexNAPCableTool } from '../../design-tools/flexnap-cable-tool';
import { NAPTool } from '../../design-tools/nap-tool';
import { SchrodingerCableTool } from '../../design-tools/schrodinger-cable-tool';
import { ToolDefinition } from '../../design-tools/tool-definition';
import { ToolType } from '../../design-tools/tooltype';
import { BuildType } from '../../models/build-type';
import { RESOLUTION_MEDIUM } from '../../openlayers/constants';
import { MapType } from '../../openlayers/openlayer-factory.service';
import { connect, StateModel } from '../../redux/reducers';
import { clearSelection } from '../../redux/selection.state';
import { setBuildType, setSelectedTool } from '../../redux/tool.state';
import { ToolItem, TooltipPlacement } from '../ui-elements/tool-item';
import { ToolIconFactory } from './tool-icon-factory';

interface MapToolsProps {
    buildTools: ToolDefinition[][];
    map?: MapType;
}

const mapStateToProps = (state: StateModel) => {
    const { roles } = state.role;
    const { selectedTool, buildType, } = state.tool;
    const { presets } = state.preset;
    const defaultPreset = presets.find((p) => p.isDefault);
    const disableFlexNapTools = !!presets.length && !defaultPreset;
    const { resolution } = state.map;
    return { selectedTool, buildType, roles, resolution, disableFlexNapTools };
};

const mapDispatchToProps = {
    setSelectedTool,
    clearSelection,
    setBuildType,
};

type props = MapToolsProps & Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class MapToolsComponent extends Component<props> {

    private setSelectedTool = (tool: ToolType) => {
        this.props.setSelectedTool!(tool);
        this.props.clearSelection!();
    }

    private renderToolContent = (tool: ToolDefinition) => {
        const isSelected = this.props.selectedTool === tool.toolType;
        const props = {
            key: tool.toolType,
            source: <ToolIconFactory type={tool.toolType}/>,
            alt: tool.name + ' tool icon',
            active: isSelected,
            tooltip: tool.name,
            tooltipMoreInfo: tool.shortcut.toUpperCase(),
            tooltipPlacement: TooltipPlacement.Right,
            onClick: this.setSelectedTool.bind(this, tool.toolType),
            roles: this.props.roles ?? [],
            disabled: this.isDisabled(tool),
        }

        return <ToolItem {...props} />
    }

    private isDisabled = (tool: ToolDefinition): boolean => {
        const { resolution, disableFlexNapTools } = this.props;
        if (!resolution) {
            return false;
        }
        switch (tool) {
            case DesignAreaTool.definition: return resolution > RESOLUTION_MEDIUM;
            case FlexNAPCableTool.definition:
            case NAPTool.definition:
                return !!disableFlexNapTools;
            default: return false;
        }
    }

    private isCableTool = (tool: ToolDefinition): boolean => {
        return tool === FlexNAPCableTool.definition || tool === SchrodingerCableTool.definition || tool === BulkCableTool.definition;
    }

    private selectBuildType = (buildType: BuildType) => {
        this.props.setBuildType!(buildType);
        switch (buildType) {
            case BuildType.Schrodinger:
                this.setSelectedTool(ToolType.SchrodingerCable);
                break;
            case BuildType.Bulk:
                this.setSelectedTool(ToolType.BulkCable);
                break;
            case BuildType.FlexNap:
            default:
                this.setSelectedTool(ToolType.FlexNAPCable);
                break;
        }
    }

    private renderToolGroup = (index: number, tools: ToolDefinition[], last: boolean) => {
        return (
            <div key={index}>
                {tools.map((tool) => this.renderToolContent(tool))}
                {!last && <div className="tool-divider" />}
            </div>
        );
    }

    public render() {
        const { buildTools } = this.props;
        return (
            <div className="controls-container tools-control-container">
                {buildTools.map((group, i) => this.renderToolGroup(i, group, i === (buildTools.length - 1)))}
            </div>
        );
    }
}
