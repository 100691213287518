import './cabinet-splice-plan-card.scss';

import React from 'react';

import {
    Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { OutlinedButton } from '@orbit/button';
import { MainPalettes, Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardHeaderComponent } from '../../card/card-header.component';
import { DnaSpinner } from '../../ui-elements/dna-spinner';
import { useCabinetSplicePlanCard } from './cabinet-splice-plan-card.hooks';
import { CabinetSplicePlanRow } from './cabinet-splice-plan-row';

export const CabinetSplicePlanCard = (): JSX.Element | null => {
    const {
        portModeLabel,
        isCardOpen,
        titleLabel,
        cabinetSizeLabel,
        assignmentLabel,
        warningLabel,
        builds,
        showOrderArrows,
        onClose,
        handlePortModeClick,
        t,
    } = useCabinetSplicePlanCard();

    const title = <Typography variant='h6' label={titleLabel} />


    if (!isCardOpen) {
        return null;
    }

    return (
        <Card id="cabinet-splice-plan" onCloseClick={onClose}>
            <CardHeaderComponent title={title} subheader={false} />
            <CardContentComponent>
                <div className="splice-plan-setup">
                    <div className='splice-plan-setup-item'>
                        <OutlinedButton palette={MainPalettes.primary} onClick={handlePortModeClick}>
                            <div className='settings-button'>
                                <Icon>settings</Icon>
                                <span>{portModeLabel}</span>
                            </div>
                        </OutlinedButton>
                    </div>
                    <div className="splice-plan-setup-item">
                        <Typography variant='body1' label={assignmentLabel} />
                    </div>
                    <div className="splice-plan-setup-item">
                        <Typography variant='body1' label={cabinetSizeLabel} />
                    </div>
                </div>
                {builds ? (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t(LocalizationKeys.CableId)}</TableCell>
                                        <TableCell>{t(LocalizationKeys.Type)}</TableCell>
                                        <TableCell>{t(LocalizationKeys.FiberCount)}</TableCell>
                                        <TableCell>{t(LocalizationKeys.PortNumber)}</TableCell>
                                        {showOrderArrows &&
                                            <TableCell>{' '}</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {builds.map((b, i) => <CabinetSplicePlanRow key={i} {...b} />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {warningLabel && <div className="warning"><i className="material-icons">warning</i>{warningLabel}</div>}
                    </>
                ) : (
                    <div className="spinner-container">
                        <DnaSpinner />
                    </div>
                )}
            </CardContentComponent>
        </Card>
    );
};
