import { BulkBuild } from '../../../models/bulk/bulk-build';
import { BuildSegment } from '../../../models/build-segment';
import { CableType } from '../../../models/cable-type';

export interface BulkCableProps {
    build: BulkBuild;
    segments: BuildSegment[];
    collapsed?: boolean;
    disabled?: boolean;
}

export interface BulkCableType {
    value: CableType;
    display: string;
}

export const defaultBulkBuildColor = '#FF38FF';