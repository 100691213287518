import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSchrodingerSplicePlan } from '../../../../redux/schrodinger/splice-plan.state';
import { selectedBuildTapsSelector } from '../../../../selectors/schrodinger/taps.selectors';
import { splicePlanLocationsWithCategoriesSelector } from '../../../../selectors/schrodinger/splice-plan.selectors';
import { SplicePlanDialogContentHooks, SplicePlanDialogContentProps } from './splice-plan-table.types';

export const useSplicePlanDialogContent = (props: SplicePlanDialogContentProps): SplicePlanDialogContentHooks => {
    const {
        isDialogOpen, buildId, currentLocation,
        setCurrentLocation
    } = props;

    const locations = useSelector(splicePlanLocationsWithCategoriesSelector);
    const tethers = [...locations[currentLocation]?.tethers || []];
    const taps = useSelector(selectedBuildTapsSelector);

    const dispatch = useDispatch();
    
    useEffect(() => {
        isDialogOpen && buildId && taps.length && loadSchrodingerSplicePlan(buildId)(dispatch);
    }, [dispatch, buildId, taps, isDialogOpen]);

    return {
        tethers, locations, currentLocation,
        setCurrentLocation
    }
}
