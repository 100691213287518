import React from 'react';

import { Sprite } from '@inlet/react-pixi';

import { IFrameProps, LAYER_Z_INDICES } from '../build.types';
import { useStatusButton } from './status-button.hooks';
import { CanvasStatusButtonProps } from './status-button.types';

export const StatusButton: React.FC<IFrameProps & CanvasStatusButtonProps> = (props) => {
    const {
        xPositionButton,
        yPositionButton,
        anchor,
        image,
        interactive,
        handleMouseOver,
        handleMouseOut,
        handleClick
    } = useStatusButton(props);

    if (!image) return null;

    return (
        <Sprite
            image={image}
            x={xPositionButton}
            y={yPositionButton}
            anchor={anchor}
            interactive={interactive}
            cursor="pointer"
            click={handleClick}
            mouseover={handleMouseOver}
            mouseout={handleMouseOut}
            zIndex={LAYER_Z_INDICES.STATUS_BUTTON}
        />
    );
};
