import { createSelector } from "@reduxjs/toolkit";
import { viewportSelector } from "./root.selectors";

export const positionSelector = createSelector(
    viewportSelector,
    (viewport) => viewport.position
)

export const isHighlightedSelectorFactory = (index: number) => {
    return createSelector(
        viewportSelector,
        (viewport) => viewport.highlighted?.current === index
    )
}

export const isPreviewedSelectorFactory = (index: number) => {
    return createSelector(
        viewportSelector,
        (viewport) => viewport.highlighted?.preview === index
    )
}

export const isSelectHighlightedSelectorFactory = (index: number) => {
    return createSelector(
        viewportSelector,
        (viewport) => viewport.highlighted?.select === index
    )
}