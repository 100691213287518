import { Graphics, TextMetrics } from "pixi.js";
import { CanvasTextStyles } from "../../../build/build.styles";
import { TOOLBAR_PROPORTIONS, TOOLTIP_PROPORTIONS } from "../../../build/build.types";
import { TooltipProps } from "./tooltip.types";

export const useTooltip = ({ position: { x: xPos, y: yPos }, tooltipText }: TooltipProps) => {
    const { tooltipX, tooltipY } = { tooltipX: xPos, tooltipY: yPos - (TOOLBAR_PROPORTIONS.ITEM_HEIGHT / 2) }

    const textMetrics = TextMetrics.measureText(tooltipText, CanvasTextStyles.smallSemiLightWhite)
    const textRectWidth = textMetrics.width + CanvasTextStyles.smallSemiLightWhite.padding * 2
    const textRectX = tooltipX - (textRectWidth / 2);
    const textRectY = tooltipY - TOOLTIP_PROPORTIONS.TOOLTIP_HEIGHT - TOOLTIP_PROPORTIONS.ARROW_HEIGHT;
    const textRectHeight = TOOLTIP_PROPORTIONS.TOOLTIP_HEIGHT

    const drawTooltip = (graphics: Graphics) => {
        graphics.clear();
        graphics.beginFill(0x000000, 1);
        graphics.moveTo(tooltipX, tooltipY);
        graphics.lineTo(tooltipX + (TOOLTIP_PROPORTIONS.ARROW_WIDTH / 2), tooltipY - TOOLTIP_PROPORTIONS.ARROW_HEIGHT);
        graphics.lineTo(tooltipX - (TOOLTIP_PROPORTIONS.ARROW_WIDTH / 2), tooltipY - TOOLTIP_PROPORTIONS.ARROW_HEIGHT);
        graphics.lineTo(tooltipX, tooltipY);
        graphics.closePath();
        graphics.drawRoundedRect(textRectX, textRectY, textRectWidth, textRectHeight, TOOLBAR_PROPORTIONS.ROUNDED_CORNER);
        graphics.endFill();
    }

    return {
        textProps: {
            x: textRectX + CanvasTextStyles.smallSemiLightWhite.padding,
            y: textRectY + TOOLTIP_PROPORTIONS.TEXT_HEIGHT_OFFSET,
            style: CanvasTextStyles.smallSemiLightWhite,
            roundPixels: true
        },
        tooltipText,
        drawTooltip
    }
}