import React from 'react';
import { NoTerminalProps } from '../../nap-diagram.types';
import { TextSvg } from '../text-svg';
import { TerminalLeg } from '../terminal-leg';
import { useNoTerminalSvg } from './no-terminal-svg.hooks';

export const NoTerminalSvg = (props: NoTerminalProps): JSX.Element => {
    const { terminalIndex, x } = useNoTerminalSvg(props);

    return (
        <g id={'no-terminal' + terminalIndex} className="no-terminal" transform={`translate(${x}, 174)`}>
            <g transform="translate(22)">
                <TerminalLeg fill="none" />
            </g>
            <rect x="1" y="51" width="56" height="96" rx="6" fill="none" stroke="#9e9e9e" strokeWidth="1" />
            <g className="no-terminal-label" transform="translate(29, 91)">
                <TextSvg x={0} y={0} text="No" />
                <TextSvg x={0} y={16} text="Terminal" />
            </g>
        </g>
    );
};
