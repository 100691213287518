import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDisplayedBuildId, setSchrodingerBuildsLoaded } from '../../redux/build.state';
import { unloadElements } from '../../redux/element.actions';
import { clear } from '../../redux/history.state';
import { unloadPaths } from '../../redux/path.actions';
import { resetBuildValidationResults } from '../../redux/schrodinger/validation.state';
import { clearSelection } from '../../redux/selection.state';
import { designModeSelector } from '../../selectors/root.selectors';

export const DesignModeManager = (): null => {
    const dispatch = useDispatch();

    const designMode = useSelector(designModeSelector);

    useEffect(() => {
        dispatch(resetBuildValidationResults());
        dispatch(unloadElements());
        dispatch(unloadPaths());
        dispatch(setSchrodingerBuildsLoaded());
        dispatch(clearSelection());
        dispatch(setDisplayedBuildId());
        dispatch(clear());
    }, [designMode, dispatch]);

    return null;
};