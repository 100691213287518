import * as rfc from 'rfc6902';

import { Cabinet } from '../models/cabinet';
import { Element } from '../models/element';
import { toElementDto } from '../models/element-dto';
import { Handhole } from '../models/handhole';
import { Manhole } from '../models/manhole';
import { Parcel } from '../models/parcel';
import { Pole } from '../models/pole';
import { Vault } from '../models/vault';
import { SecuredService } from './abstract-secured-v2.service';

export class ElementService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/geo/element';

    public async getElements(bbox: number[], required?: number[]): Promise<ElementsDto | undefined> {
        return this.post(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public async patchElement(oldElement: Element, newElement: Element): Promise<Element | undefined> {
        const patch = rfc.createPatch(toElementDto(oldElement), toElementDto(newElement));
        return this.post(`${this.baseUrl}/${oldElement.id}`, patch);
    }

    public async deleteElement(elementId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${elementId}`);
    }

    public async deleteAccessPoints(elementId: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${elementId}/accesspoints`);
    }

    public async undoDelete<T extends Element>(elementId: number): Promise<T | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${elementId}`);
    }
}

export interface ElementsDto {
    poles: Pole[];
    manholes: Manhole[];
    handholes: Handhole[];
    cabinets: Cabinet[];
    parcels: Parcel[];
    vaults: Vault[];
}
