import i18next from 'i18next';
import { transform } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import React from 'react';

import { TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { ElementCardState } from '../../../models/element-card-state';
import { Parcel } from '../../../models/parcel';
import { deleteElement } from '../../../redux/element.actions';
import { updateParcel } from '../../../redux/parcel.state';
import { connect, StateModel } from '../../../redux/reducers';
import { selectParcel } from '../../../redux/selection.state';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { InlineText } from '../../ui-elements/inline-text';
import { DeleteElementDialog } from './delete-element-dialog';

const mapStateToProps = (state: StateModel) => {
    const { selectedParcel, selectedElement } = state.selection;
    const selectedElementId = selectedElement?.id;
    const isConnectedToPath = !!selectedElementId && !![...state.bore.bores, ...state.trench.trenches].find((p) => p.fromElementId === selectedElementId || p.toElementId === selectedElementId);
    const isConnectedToSegment = !!selectedElementId && !![...state.build.segments].find((s) => s.fromId === selectedElementId || s.toId === selectedElementId);
    return { selectedParcel, isConnectedToPath, isConnectedToSegment };
};
const mapDispatchToProps = {
    selectParcel,
    updateParcel,
    deleteElement
};
type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ParcelCardComponent extends React.Component<props, ElementCardState>  {
    private EPSG4326 = new Projection({ code: 'EPSG:4326' });
    private EPSG3857 = new Projection({ code: 'EPSG:3857' });

    constructor(props) {
        super(props);
        this.state = { deleteDialog: false };
    }

    public componentDidMount(): void {
        document.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    private handleKeyUp = (e: KeyboardEvent) => {
        const { selectedParcel, isConnectedToPath, isConnectedToSegment } = this.props;

        if (e.target instanceof HTMLInputElement) {
            return;
        }

        if (!selectedParcel || isConnectedToPath || isConnectedToSegment) {
            return;
        }

        if (e.key === 'Delete') {
            if (selectedParcel) {
                if (selectedParcel.isImported) {
                    this.showDeleteDialog(true);
                } else {
                    this.deleteParcel();
                }
            }
        }
    }

    private showDeleteDialog = (show: boolean) => {
        this.setState({ deleteDialog: show });
    }

    private unselectParcel = () => {
        this.props.selectParcel!();
    }

    private renderParcelProperties = () => {
        const { selectedParcel } = this.props;
        if (selectedParcel && selectedParcel.properties) {
            const properties = JSON.parse(selectedParcel.properties);
            const keys = Object.keys(properties);
            return (
                <div>
                    {keys.map((key) => properties[key] ? CardPropertyInline(key, properties[key]) : null)}
                </div>
            );
        }
        else {
            return null;
        }
    }

    private deleteParcel = () => {
        const { selectedParcel } = this.props;
        if (selectedParcel) {
            this.props.deleteElement!(selectedParcel.id, selectedParcel.type);
        }
        this.unselectParcel();
    }

    private handleIdOverrideUpdate = (value: string): void => {
        const { selectedParcel } = this.props;
        if (selectedParcel) {
            const newParcel: Parcel = { ...selectedParcel, tagOverride: value ? value : undefined };
            this.props.updateParcel!(selectedParcel, newParcel);
        }
    }

    public render() {
        const { selectedParcel, isConnectedToPath, isConnectedToSegment } = this.props;
        if (!selectedParcel) {
            return null;
        }
        const isImported = selectedParcel.isImported;
        const coordinates = transform([selectedParcel.x, selectedParcel.y], this.EPSG3857, this.EPSG4326);

        let titleDeleteBtn = '';
        if (isConnectedToPath) {
            titleDeleteBtn = i18next.t(LocalizationKeys.ConnectedPathWarning);
        } else if (isConnectedToSegment) {
            titleDeleteBtn = i18next.t(LocalizationKeys.ConnectedSegmentWarning);
        }
        const deleteBtn = (
            <div title={titleDeleteBtn}>
                <TextButton className="negative" disabled={isConnectedToPath || isConnectedToSegment} onClick={(): void => isImported ? this.showDeleteDialog(true) : this.deleteParcel()}>{i18next.t(LocalizationKeys.Delete)}</TextButton>
            </div>
        );
        return (
            <Card id="parcel-info" onCloseClick={this.unselectParcel}>
                <CardHeaderComponent title={i18next.t(LocalizationKeys.Parcel)} subheader={selectedParcel.tagOverride ?? selectedParcel.tag} icon={require('./../../../icons/parcel.png')} />
                <CardContentComponent>
                    <InlineText
                        id="idOverride"
                        label={i18next.t(LocalizationKeys.MandatoryField, { fieldName: i18next.t(LocalizationKeys.IDOverride) })}
                        value={selectedParcel.tagOverride ?? selectedParcel.tag}
                        align={'right'}
                        onUpdate={this.handleIdOverrideUpdate}
                    />
                    {CardPropertyInline(i18next.t(LocalizationKeys.Longitude), `${coordinates[0].toFixed(5)}`)}
                    {CardPropertyInline(i18next.t(LocalizationKeys.Latitude), `${coordinates[1].toFixed(5)}`)}
                    {this.renderParcelProperties()}
                    <div className="split-item extra-info">{i18next.t(LocalizationKeys.IdUsedForCableLabeling)}</div>
                    {deleteBtn}
                </CardContentComponent>
                <DeleteElementDialog open={this.state.deleteDialog} elementId={selectedParcel.id} onDelete={this.deleteParcel} onClose={(): void => this.showDeleteDialog(false)} />
            </Card>
        );
    }
}
