import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogType, showDialog } from "../../redux/overlay.state";
import { selectDefaultPreset, selectPreset } from "../../redux/preset.state";
import { selectDefaultPreset as selectDefaultSchrodingerPreset, selectPreset as selectSchrodingerPreset } from "../../redux/schrodinger-preset.state";
import { StateModel } from '../../redux/reducers';
import { PresetConfigMode } from './preset-manager.types';
import { PresetType } from './presets-list/presets-list.types';

export const usePresetManager = () => {
    const workspace = useSelector((state: StateModel) => state);
    const { dialog } = workspace.overlay;
    const { selectedPreset } = workspace.preset;
    const { selectedPreset: selectedSchrodingerPreset } = workspace.schrodingerpreset;

    const [selectedTab, setSelectedTab] = useState(PresetType.FlexNap);
    const [configMode, setConfigMode] = useState<PresetConfigMode | undefined>();

    const dispatch = useDispatch();

    const closePresets = useCallback((): void => {
        dispatch(showDialog())
        dispatch(selectPreset());
        dispatch(selectSchrodingerPreset());
        setConfigMode(undefined);
    }, [dispatch]);

    useEffect(() => {
        if (selectedPreset) {
            setConfigMode(undefined);
        }
    }, [selectedPreset]);

    useEffect(() => {
        if (selectedSchrodingerPreset) {
            setConfigMode(undefined);
        }
    }, [selectedSchrodingerPreset]);

    useEffect(() => {
        if (dialog === DialogType.Preset) {
            if (selectedTab === PresetType.FlexNap) {
                dispatch(selectDefaultPreset());
            }
            else if (selectedTab === PresetType.Schrodinger) {
                dispatch(selectDefaultSchrodingerPreset());
            }
        }
    }, [dispatch, dialog, selectedTab]);

    return {
        dialog, selectedPreset, configMode, selectedTab,
        closePresets, onConfigChange: setConfigMode, setSelectedTab
    };
};