import './card.component.scss';

import classNames from 'classnames';
import React from 'react';

interface CardComponentProps {
    id?: string;
    title: React.ReactNode;
    tooltip?: any;
    icon?: string;
    iconBorder?: boolean;
    subheader?: string | boolean;
    className?: string;
}

export class CardHeaderComponent extends React.Component<CardComponentProps> {

    public render(): JSX.Element {
        const { icon, iconBorder, title, subheader, className, tooltip } = this.props;
        const cs = classNames('card-header', className);
        return (
            <div className={cs} title={tooltip}>
                {icon ? <img className={iconBorder ? "icon-border" : undefined} alt="" src={icon} /> : null}
                <div>
                    {title}
                    <div className="card-subheader">
                        {subheader === false ? null : <label>{subheader ? subheader : 'Properties'}</label>}
                    </div>
                </div>
            </div>
        );
    }
}
