import './preterm-lateral.dialog.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useConvertToPretermLateralDialog } from './convert-to-preterm.hooks';

export const ConvertToPretermDialog = (): JSX.Element => {
    const { t } = useTranslation();
    const { open, onClose, onCancel, onConvert, pretermBuildId } = useConvertToPretermLateralDialog();

    return (
        <DialogComponent
            open={open}
            message={t(LocalizationKeys.ConvertBuildToPreterm, { buildId: pretermBuildId })}
            positiveTitle={t(LocalizationKeys.Convert)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onClose={onClose}
            onNegativeClick={onCancel}
            onPositiveClick={onConvert}
        />
    );
};
