import 'ol/ol.css';
import './map.component.scss';

import { MapBrowserEvent } from 'ol';
import * as Extent from 'ol/extent';
import Feature from 'ol/Feature';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import * as React from 'react';

import { BoreTool } from '../../design-tools/bore-tool';
import { BulkCableTool } from '../../design-tools/bulk-cable-tool';
import { DesignAreaTool } from '../../design-tools/design-area-tool';
import { DesignToolsExecuter as DesignToolExecuter } from '../../design-tools/design-tool-executer';
import { FlexNAPCableTool } from '../../design-tools/flexnap-cable-tool';
import { ModificationTool } from '../../design-tools/modification-tool';
import { NAPTool } from '../../design-tools/nap-tool';
import { PretermLateralTool } from '../../design-tools/preterm-lateral-tool';
import { SchrodingerCableTool } from '../../design-tools/schrodinger-cable-tool';
import { TapTool } from '../../design-tools/schrodinger/tap-tool';
import { SplicePointTool } from '../../design-tools/splice-point-tool';
import { ToolType } from '../../design-tools/tooltype';
import { TrenchTool } from '../../design-tools/trench-tool';
import { Build } from '../../models/build';
import { BuildType } from '../../models/build-type';
import { ElementType } from '../../models/element-type';
import { KnownLayers } from '../../models/known-layers';
import MapLayer from '../../models/layer';
import LayerCollection from '../../models/layer-collection';
import { LayerGroup } from '../../models/layer-group';
import { ImportContext, LayerContext, LayerUpdater } from '../../models/layer-updater';
import { MapClickEvent } from '../../models/map-click-event';
import { MapItemDefinition } from '../../models/map-item-definition';
import { ModifiableNode } from '../../models/modifiable-node';
import { BingMapsFactory } from '../../openlayers/bingmaps.factory';
import { RESOLUTION_CLOSE } from '../../openlayers/constants';
import { MapDragEndEvent, MapDragEvent } from '../../openlayers/drag-interaction';
import { InteractableFeatureType } from '../../openlayers/interactable-feature-type';
import { LayerType, MapType, OpenLayerFactory } from '../../openlayers/openlayer-factory.service';
import { FeatureType } from '../../openlayers/openlayer-feature.factory';
import { LayerFactory } from '../../openlayers/openlayer-layer.factory';
import { OpenlayerUtility } from '../../openlayers/openlayer-utility';
import {
    cancelSplicePointMove, loadAll as loadSplicePoints
} from '../../redux/bulk/splice-point.state';
import { addCabinet, getCabinetsByIds } from '../../redux/cabinet.state';
import CorningRoles from '../../redux/corning-roles.json';
import { loadDesignAreaBuilds } from '../../redux/design-area.state';
import { loadElements } from '../../redux/element.actions';
import { getHandholesByIds } from '../../redux/handhole.state';
import { registerLayer, unregisterLayer } from '../../redux/layer.state';
import { getManholesByIds } from '../../redux/manhole.state';
import {
    buildClicked, mapMove, notificationClicked, setResolution, zoomAt
} from '../../redux/map.state';
import { loadPaths } from '../../redux/path.actions';
import { getPolesByIds } from '../../redux/pole.state';
import { connect, StateModel } from '../../redux/reducers';
import { addRoles } from '../../redux/roles.state';
import { cancelTapMove } from '../../redux/schrodinger/tap.state';
import {
    clearSelection, dragItems, selectBuild, selectPole, selectSegment
} from '../../redux/selection.state';
import { addTap, cancelNapMove, loadTaps, loadTethers } from '../../redux/tap.state';
import { setBuildType } from '../../redux/tool.state';
import { getVaultsByIds } from '../../redux/vault.state';
import { flexNapPortNumbersByElementSelector } from '../../selectors/splice-plan.selectors';
import { AccessDeniedComponent } from '../notifications/access-denied.component';
import { GisOverlayComponent } from '../overlay/gis-overlay';
import { MapContextMenu } from '../ui-elements/map-context-menu';
import {
    BuildSelectionData, MapContextMenuData, NapEvent
} from '../ui-elements/map-context-menu.types';
import { RoleManagementTool } from '../ui-elements/role-management-tool';

interface MapState {
    contextMenu: boolean;
    clickX: number;
    clickY: number;
    data?: MapContextMenuData;
}

const ZOOM_ADJUSTMENT = -1.3;

const modifiableTypes = [
    InteractableFeatureType.FLEXNAP_SEGMENT,
    InteractableFeatureType.SCHRODINGER_SEGMENT,
    InteractableFeatureType.BULK_SEGMENT,
    InteractableFeatureType.BORE,
    InteractableFeatureType.TRENCH,
];
export const undergroundTypes: FeatureType[] = [InteractableFeatureType.HANDHOLE, InteractableFeatureType.MANHOLE, InteractableFeatureType.VAULT];
const elementTypes = [...undergroundTypes, InteractableFeatureType.POLE, InteractableFeatureType.CABINET];

const mapStateToProps = (state: StateModel) => {
    const { currentWorkspace } = state.workspace;
    const { designAreas } = state.designarea;
    const { poles } = state.pole;
    const { parcels } = state.parcel;
    const { naps, taps, tethers } = state.taps;
    const { taps: tapsSchrodinger, tethers: tethersSchrodinger } = state.tapsSchrodinger;
    const { splicePoints } = state.splicePoints;
    const { cabinets } = state.cabinet;
    const { flexnapBuilds, schrodingerBuilds, bulkBuilds, segments } = state.build;
    const { buildType, selectedTool } = state.tool;
    const {
        selectedSegmentId,
        selectedSegmentsIds,
        selectedBoreId,
        selectedTrenchId,
        selectedPoleId,
        selectedElement,
        selectedParcel,
        selectedNapId,
        selectedTerminalId,
        selectedTerminalSegmentsIds,
        selectedTapId,
        selectedDesignAreaId,
        selectedTapIdSchrodinger,
        selectedSplicePointId
    } = state.selection;
    const { desiredViewport, autoDropCable, simpleMap, isFullscreen, clickedBuild, resolution, clickedNotification } = state.map;
    const { parcels: importedParcels,
        poles: importedPoles,
        polespan: importedPoleSpans,
        cabinets: importedCabinets,
        manholes: importedManholes,
        handholes: importedHandholes,
        vaults: importedVaults,
        trenches: importedTrenches,
        bores: importedBores,
    } = state.import;
    const { layers } = state.layer;
    const { manholes } = state.manhole;
    const { handholes } = state.handhole;
    const { vaults } = state.vault;
    const { trenches } = state.trench;
    const { bores } = state.bore;
    const { roles, isRoleManagementMenuShown } = state.role;
    const flexNapPortNumbers = flexNapPortNumbersByElementSelector(state);
    const { status } = state.export;
    return {
        buildType, selectedTool, currentWorkspace, designAreas, naps, taps, tethers, tapsSchrodinger, tethersSchrodinger, splicePoints, cabinets, flexnapBuilds, schrodingerBuilds, bulkBuilds, segments, poles, parcels,
        manholes, handholes, vaults, trenches, bores,
        selectedSegmentId, selectedSegmentsIds, selectedBoreId, selectedTrenchId, selectedPoleId, selectedElement, selectedParcel, selectedNapId, selectedTerminalId, selectedTerminalSegmentsIds, selectedTapId, selectedDesignAreaId, selectedTapIdSchrodinger, selectedSplicePointId,
        desiredViewport, simpleMap, isFullscreen, clickedBuild, clickedNotification,
        importedPoles, importedPoleSpans, importedParcels,
        importedCabinets, importedManholes, importedHandholes, importedVaults,
        importedTrenches, importedBores,
        autoDropCable,
        flexNapPortNumbers,
        layers, roles, isRoleManagementMenuShown, exportStatus: status, resolution
    };
};

const mapDispatchToProps = {
    loadTaps,
    loadTethers,
    loadSplicePoints,
    cancelNapMove,
    cancelTapMove,
    cancelSplicePointMove,
    clearSelection,
    selectPole,
    selectSegment,
    selectBuild,
    dragItems,
    addTap,
    addCabinet,
    registerLayer,
    unregisterLayer,
    zoomAt,
    mapMove,
    buildClicked,
    notificationClicked,
    setResolution,
    loadElements,
    loadPaths,
    getPolesByIds,
    getCabinetsByIds,
    getHandholesByIds,
    getManholesByIds,
    getVaultsByIds,
    addRoles,
    loadDesignAreaBuilds,
    setBuildType
};
type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class MapComponent extends React.Component<props, MapState> {
    private map?: MapType;
    private layerCollection: LayerCollection = new LayerCollection();
    private modificationTool?: ModificationTool;


    private handholeLayer?: VectorLayer;
    private manholeLayer?: VectorLayer;
    private vaultLayer?: VectorLayer;
    private polesLayer?: VectorLayer;
    private cabinetLayer?: VectorLayer;
    private flexnapCablesLayer?: VectorLayer;
    private schrodingerCablesLayer?: VectorLayer;
    private bulkCablesLayer?: VectorLayer;
    private designAreasLayer?: VectorLayer;
    private undergroundLayers?: VectorLayer[];
    private elementLayers?: VectorLayer[];
    private modifiableLayers?: VectorLayer[];

    public state: MapState = {
        contextMenu: false,
        clickX: 0,
        clickY: 0,
        data: undefined
    };

    public componentDidMount() {
        const simpleMap = !!this.props.simpleMap;
        this.map = BingMapsFactory.createBingMap('mapContainer', simpleMap);
        this.loadMapPosition();
        OpenLayerFactory.addControls(this.map);
        this.registerLayers();
        OpenLayerFactory.addMapInteractions(this.map,
            this.singleClickListener,
            this.doubleClickListener,
            this.shiftClickListener,
            this.contextMenuListener,
            this.dragFilterListener,
            this.dragEndListener,
            this.pointerDragListener,
            this.mouseWheelListener
        );
        OpenLayerFactory.addMapMoveSubscription(this.map, this.mapMoveListener);
        this.updateMapTool();
        if (this.props.currentWorkspace && this.props.loadDesignAreaBuilds) {
            this.props.loadDesignAreaBuilds(this.props.currentWorkspace.id);
        }
    }

    public componentDidUpdate(prevProps: props): void {
        const {
            currentWorkspace, designAreas, poles, flexnapBuilds, schrodingerBuilds, bulkBuilds, segments, naps, taps, tethers, tapsSchrodinger, tethersSchrodinger, splicePoints, parcels, cabinets,
            manholes, handholes, vaults, trenches, bores,
            selectedTool, selectedSegmentId, selectedSegmentsIds, selectedPoleId, selectedElement, selectedParcel, selectedBoreId, selectedTrenchId, selectedTerminalId, selectedTerminalSegmentsIds, selectedTapId, selectedNapId, selectedDesignAreaId, selectedTapIdSchrodinger, selectedSplicePointId,
            clickedNotification,
            desiredViewport, simpleMap,
            importedPoles, importedParcels, importedPoleSpans,
            importedCabinets, importedManholes, importedHandholes, importedVaults,
            importedTrenches, importedBores,
            autoDropCable,
            flexNapPortNumbers,
            layers,
            isFullscreen,
            exportStatus,
            resolution,
            roles
        } = this.props;

        if (roles && roles !== prevProps.roles) {
            this.setupBuildTools(roles);
        }

        if (isFullscreen !== prevProps.isFullscreen || exportStatus !== prevProps.exportStatus) {
            setTimeout(() => this.map?.updateSize());
        }

        if (
            (currentWorkspace?.id !== prevProps.currentWorkspace?.id) ||

            (!!designAreas && !!prevProps.designAreas && Math.abs(designAreas.length - prevProps.designAreas.length) === 1) ||

            (!!flexnapBuilds && !!prevProps.flexnapBuilds && Math.abs(flexnapBuilds.length - prevProps.flexnapBuilds.length) === 1) ||
            (!!schrodingerBuilds && !!prevProps.schrodingerBuilds && Math.abs(schrodingerBuilds.length - prevProps.schrodingerBuilds.length) === 1) ||
            (!!bulkBuilds && !!prevProps.bulkBuilds && Math.abs(bulkBuilds.length - prevProps.bulkBuilds.length) === 1) ||

            (!!segments && !!prevProps.segments && Math.abs(segments.length - prevProps.segments.length) === 1)
        ) {
            if (currentWorkspace && this.props.loadDesignAreaBuilds) {
                this.props.loadDesignAreaBuilds(currentWorkspace.id);
            }
        }

        const layerContext: LayerContext = {
            designAreas: designAreas ?? [],
            poles: poles ?? [],
            flexNAPBuilds: flexnapBuilds ?? [],
            schrodingerBuilds: schrodingerBuilds ?? [],
            bulkBuilds: bulkBuilds ?? [],
            segments: segments ?? [],
            naps: naps ?? [],
            taps: taps ?? [],
            tethers: tethers ?? [],
            tapsSchrodinger: tapsSchrodinger ?? [],
            tethersSchrodinger: tethersSchrodinger ?? [],
            splicePoints: splicePoints ?? [],
            parcels: parcels ?? [],
            manholes: manholes ?? [],
            handholes: handholes ?? [],
            vaults: vaults ?? [],
            trenches: trenches ?? [],
            bores: bores ?? [],
            cabinets: cabinets ?? [],
            autoDropCable: autoDropCable,
            flexNapPortNumbers,
            resolution,
            clickedNotification
        };

        const importContext: ImportContext = {
            poles: importedPoles ?? [],
            poleSpans: importedPoleSpans ?? [],
            parcels: importedParcels ?? [],
            cabinets: importedCabinets ?? [],
            manholes: importedManholes ?? [],
            handholes: importedHandholes ?? [],
            vaults: importedVaults ?? [],
            trenches: importedTrenches ?? [],
            bores: importedBores ?? []
        };

        const previousLayerContext: LayerContext = {
            designAreas: prevProps.designAreas ?? [],
            poles: prevProps.poles ?? [],
            flexNAPBuilds: prevProps.flexnapBuilds ?? [],
            schrodingerBuilds: prevProps.schrodingerBuilds ?? [],
            bulkBuilds: prevProps.bulkBuilds ?? [],
            segments: prevProps.segments ?? [],
            naps: prevProps.naps ?? [],
            taps: prevProps.taps ?? [],
            tethers: prevProps.tethers ?? [],
            tapsSchrodinger: prevProps.tapsSchrodinger ?? [],
            tethersSchrodinger: prevProps.tethersSchrodinger ?? [],
            splicePoints: prevProps.splicePoints ?? [],
            parcels: prevProps.parcels ?? [],
            manholes: prevProps.manholes ?? [],
            handholes: prevProps.handholes ?? [],
            vaults: prevProps.vaults ?? [],
            trenches: prevProps.trenches ?? [],
            bores: prevProps.bores ?? [],
            cabinets: prevProps.cabinets ?? [],
            autoDropCable: prevProps.autoDropCable,
            flexNapPortNumbers: prevProps.flexNapPortNumbers,
            resolution: prevProps.resolution,
            clickedNotification: prevProps.clickedNotification
        };

        const previousImportContext: ImportContext = {
            poles: prevProps.importedPoles ?? [],
            poleSpans: prevProps.importedPoleSpans ?? [],
            parcels: prevProps.importedParcels ?? [],
            cabinets: prevProps.importedCabinets ?? [],
            manholes: prevProps.importedManholes ?? [],
            handholes: prevProps.importedHandholes ?? [],
            vaults: prevProps.importedVaults ?? [],
            trenches: prevProps.importedTrenches ?? [],
            bores: prevProps.importedBores ?? []
        };

        if (this.map) {
            const rolesChanged = this.props.roles !== prevProps.roles;
            if (rolesChanged) {
                this.updateRegisteredLayers();
            }

            const layerUpdater = new LayerUpdater(this.layerCollection, layerContext, importContext);
            layerUpdater.update(this.map, previousLayerContext, previousImportContext);
        }

        const simpleMapChanged = simpleMap !== prevProps.simpleMap;

        const selectedSegmentChanged = selectedSegmentId !== prevProps.selectedSegmentId;
        const selectedSegmentsChanged = selectedSegmentsIds !== prevProps.selectedSegmentsIds;
        const selectedDesignAreaChanged = selectedDesignAreaId !== prevProps.selectedDesignAreaId;
        const selectedBoreChanged = selectedBoreId !== prevProps.selectedBoreId;
        const selectedTrenchChanged = selectedTrenchId !== prevProps.selectedTrenchId;
        const selectedNapChanged = selectedNapId !== prevProps.selectedNapId;
        const selectedTapChanged = selectedTapId !== prevProps.selectedTapId;
        const selectedTapSchrodingerChanged = selectedTapIdSchrodinger !== prevProps.selectedTapIdSchrodinger;
        const selectedSplicePointChanged = selectedSplicePointId !== prevProps.selectedSplicePointId;
        const selectedTerminalChanged = selectedTerminalId !== prevProps.selectedTerminalId;
        const selectedTerminalSegmentChanged = selectedTerminalSegmentsIds !== prevProps.selectedTerminalSegmentsIds;
        const selectedPoleChanged = selectedPoleId !== prevProps.selectedPoleId;
        const selectedElementChanged = selectedElement !== prevProps.selectedElement;
        const selectedParcelChanged = selectedParcel !== prevProps.selectedParcel;

        const toolChanged = selectedTool !== prevProps.selectedTool;
        const desiredViewportChanged = desiredViewport !== prevProps.desiredViewport;

        const layersChanged = layers !== prevProps.layers;

        if (desiredViewportChanged && desiredViewport && this.map) {
            let bbox: number[][];
            if (desiredViewport.length === 4) {
                bbox = [[desiredViewport[0], desiredViewport[1]], [desiredViewport[2], desiredViewport[3]]];
            }
            else {
                bbox = [desiredViewport];
            }
            const viewExtent: Extent.Extent = Extent.boundingExtent(bbox);
            const resolutionForViewExtent = this.map.getView().getResolutionForExtent(viewExtent);
            const zoom = this.map?.getView()?.getZoomForResolution(resolutionForViewExtent);
            if (zoom) {
                const zoomAdjusted = zoom + ZOOM_ADJUSTMENT;
                this.map.getView().animate({
                    center: Extent.getCenter(viewExtent),
                    zoom: zoomAdjusted
                });
            }

            if (this.props.zoomAt) {
                this.props.zoomAt(undefined);
            }
        }
        if (simpleMapChanged && this.map) {
            OpenLayerFactory.toggleMapStyle(this.map);
        }

        if (toolChanged) {
            this.updateMapTool();
            this.resetContextMenu();
        }
        if (selectedSegmentChanged) {
            this.selectSegment(selectedSegmentId)
        }
        if (selectedSegmentsChanged) {
            this.selectSegments(selectedSegmentsIds);
            this.resetContextMenu();
        }
        if (selectedDesignAreaChanged) {
            this.selectDesignArea(selectedDesignAreaId);
            const snapToLayers = selectedDesignAreaId ? [this.designAreasLayer!] : this.modifiableLayers;
            OpenLayerFactory.updateMapSnapTool({ map: this.map!, snapToLayers });
            this.resetContextMenu();
        }
        if (selectedBoreChanged) {
            this.selectBore(selectedBoreId);
        }
        if (selectedTrenchChanged) {
            this.selectTrench(selectedTrenchId);
        }
        if (selectedNapChanged) {
            this.selectNap(selectedNapId);
        }
        if (selectedTerminalChanged) {
            this.selectTerminal(selectedTerminalId);
        }
        if (selectedTerminalSegmentChanged) {
            this.selectTerminalSegment(selectedTerminalSegmentsIds);
            this.resetContextMenu();
        }
        if (selectedTapChanged) {
            if (selectedTapId) {
                this.selectTap(selectedTapId);
            }
        }
        if (selectedTapSchrodingerChanged) {
            if (selectedTapIdSchrodinger) {
                this.selectTapSchrodinger(selectedTapIdSchrodinger);
            }
        }
        if (selectedSplicePointChanged) {
            this.selectSplicePoint(selectedSplicePointId);
        }
        if (selectedPoleChanged) {
            this.selectPole(selectedPoleId);
        }
        if (selectedElementChanged) {
            const usePrevSelected = !selectedElement && !!prevProps.selectedElement;
            const elementId = usePrevSelected ? undefined : selectedElement?.id;
            const elementType = usePrevSelected ? prevProps.selectedElement?.type : selectedElement?.type;
            this.selectElement(elementId, elementType);
        }
        if (selectedParcelChanged) {
            this.selectElement(selectedParcel?.id, selectedParcel?.type);
        }

        if (layersChanged && layers && this.map) {
            for (const layer of layers) {
                const realLayers = this.map.getLayers().getArray();
                for (const realLayer of realLayers) {
                    if (realLayer.get('id') === layer.id) {
                        realLayer.setVisible(layer.visible);
                    }
                }
            }
        }

        if (designAreas !== prevProps.designAreas) {
            this.resetContextMenu();
        }

        if (bores !== prevProps.bores || trenches !== prevProps.trenches) {
            this.resetContextMenu();
        }
    }

    private createFlexNAPLayers(): void {
        const isSpecial = true;
        const layerRole = [CorningRoles.FlexNAP];
        const flexNapBuildsOpenLayer = this.buildLayer(KnownLayers.FlexNAPCables, LayerFactory.createFlexNapBuildsLayer.bind(LayerFactory));
        const flexNapNapOpenLayer = this.buildLayer(KnownLayers.FlexNAP, LayerFactory.createNapLayer.bind(LayerFactory));
        const tapOpenLayer = this.buildLayer(KnownLayers.Tap, LayerFactory.createTapLayer.bind(LayerFactory));
        const portNumberOpenLayer = this.buildLayer(KnownLayers.PortNumbers, LayerFactory.createPortNumberLayer.bind(LayerFactory));
        const terminalsOpenLayer = this.buildLayer(KnownLayers.Terminals, LayerFactory.createTerminalLayer.bind(LayerFactory));
        const terminalSegmentsOpenLayer = this.buildLayer(KnownLayers.TerminalSegments, LayerFactory.createTerminalSegmentsLayer.bind(LayerFactory));

        const buildLayer = new MapLayer(flexNapBuildsOpenLayer, KnownLayers.FlexNAPCables, LayerGroup.Build, isSpecial);
        const napLayer = new MapLayer(flexNapNapOpenLayer, KnownLayers.FlexNAP, LayerGroup.Build, isSpecial);
        const tapsLayer = new MapLayer(tapOpenLayer, KnownLayers.Tap, LayerGroup.Build, isSpecial);
        const portNumbersLayer = new MapLayer(portNumberOpenLayer, KnownLayers.PortNumbers, LayerGroup.Build, isSpecial);
        const terminalsLayer = new MapLayer(terminalsOpenLayer, KnownLayers.Terminals, LayerGroup.Build, isSpecial);
        const terminalSegmentsLayer = new MapLayer(terminalSegmentsOpenLayer, KnownLayers.TerminalSegments, LayerGroup.Build, isSpecial);

        this.updateManagedLayers(layerRole, [buildLayer, terminalSegmentsLayer, napLayer, terminalsLayer, tapsLayer, portNumbersLayer]);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private buildLayer(name: string, createFunc: Function): VectorLayer {
        if (this.layerCollection.hasSpecialLayer(name)) {
            return this.layerCollection.getSpecialLayerByName<VectorLayer>(name);
        }

        return createFunc();
    }

    private createSchrodingerLayers(): void {
        const isSpecial = true;
        const layerRole = [CorningRoles.Schrodinger];

        const schrodingerBuildsOpenLayer = this.buildLayer(KnownLayers.SchrodingerCables, LayerFactory.createSchrodingerBuildsLayer.bind(LayerFactory));
        const schrodingerTapOpenLayer = this.buildLayer(KnownLayers.SchrodingerTAP, LayerFactory.createSchrodingerTapLayer.bind(LayerFactory));
        const powerTethersOpenLayer = this.buildLayer(KnownLayers.PowerTethers, LayerFactory.createDeviceLayer.bind(LayerFactory));
        const opticalTethersOpenLayer = this.buildLayer(KnownLayers.OpticalTethers, LayerFactory.createSchrodingerTerminalLayer.bind(LayerFactory));

        const buildLayer = new MapLayer(schrodingerBuildsOpenLayer, KnownLayers.SchrodingerCables, LayerGroup.Build, isSpecial);
        const tapLayer = new MapLayer(schrodingerTapOpenLayer, KnownLayers.SchrodingerTAP, LayerGroup.Build, isSpecial);
        const powerTethersLayer = new MapLayer(powerTethersOpenLayer, KnownLayers.PowerTethers, LayerGroup.Build, isSpecial);
        const opticalTethersLayer = new MapLayer(opticalTethersOpenLayer, KnownLayers.OpticalTethers, LayerGroup.Build, isSpecial);

        this.updateManagedLayers(layerRole, [buildLayer, tapLayer, powerTethersLayer, opticalTethersLayer]);
    }

    private createBulkLayers(): void {
        const isSpecial = true;
        const layerRoles = [CorningRoles.FlexNAP, CorningRoles.Schrodinger];

        const bulkBuildsOpenLayer = this.buildLayer(KnownLayers.BulkCables, LayerFactory.createBulkBuildsLayer.bind(LayerFactory));
        const splicePointsOpenLayer = this.buildLayer(KnownLayers.SplicePoints, LayerFactory.createSplicePointLayer.bind(LayerFactory));
        const buildLayer = new MapLayer(bulkBuildsOpenLayer, KnownLayers.BulkCables, LayerGroup.Build, isSpecial);
        const splicePointLayer = new MapLayer(splicePointsOpenLayer, KnownLayers.SplicePoints, LayerGroup.Build, isSpecial);

        this.updateManagedLayers(layerRoles, [buildLayer, splicePointLayer]);
    }

    private updateManagedLayers(layerRoles: string[], layers: MapLayer[]): void {

        // Initialize layers at least once
        for (const layer of layers) {
            if (!this.layerCollection.hasSpecialLayer(layer.name)) {
                this.layerCollection.add(layer);
            }
        }

        const roles = this.props.roles ?? [];
        if (roles && !roles.some(role => layerRoles.includes(role))) {
            for (const layer of layers) {
                const id = layer.layer.get('id');
                this.unregisterLayer(id);
            }
            return;
        }

        if (this.props.registerLayer && this.map) {
            const registeredMapLayers = this.map && this.map.getLayers().getArray();
            for (const layer of layers) {
                const id = layer.layer.get('id');
                if (!registeredMapLayers.some(l => l.get('id') === id)) {
                    this.registerLayer(layer.layer, layer.name, layer.group);
                }
            }
        }
    }

    private unregisterLayer(id: string): void {
        const map = this.map;
        if (map) {
            const layers = map.getLayers().getArray();
            const layer = layers.find(l => l.get('id') === id)
            if (this.props.unregisterLayer) {
                this.props.unregisterLayer(id);
            }
            if (layer) {
                map.removeLayer(layer);
            }
        }
    }

    private createGenericLayers(): void {
        // Feature layers - Creation
        this.layerCollection.add(new MapLayer(LayerFactory.createPolesLayer(), KnownLayers.Poles, LayerGroup.Infrastructure));
        this.layerCollection.add(new MapLayer(LayerFactory.createParcelLayer(), KnownLayers.Parcels, LayerGroup.Infrastructure));
        this.layerCollection.add(new MapLayer(LayerFactory.createDropCableLayer(), KnownLayers.DropCables, LayerGroup.Build));
        this.layerCollection.add(new MapLayer(LayerFactory.createCabinetLayer(), KnownLayers.Cabinets, LayerGroup.Infrastructure));
        this.layerCollection.add(new MapLayer(LayerFactory.createManholeLayer(), KnownLayers.Manholes, LayerGroup.Infrastructure));
        this.layerCollection.add(new MapLayer(LayerFactory.createHandholeLayer(), KnownLayers.Handholes, LayerGroup.Infrastructure));
        this.layerCollection.add(new MapLayer(LayerFactory.createVaultLayer(), KnownLayers.Vaults, LayerGroup.Infrastructure));
        this.layerCollection.add(new MapLayer(LayerFactory.createTrenchLayer(), KnownLayers.Trenches, LayerGroup.Paths));
        this.layerCollection.add(new MapLayer(LayerFactory.createBoreLayer(), KnownLayers.Bores, LayerGroup.Paths));
        this.layerCollection.add(new MapLayer(LayerFactory.createDesignAreasLayer(), KnownLayers.DesignAreas, LayerGroup.DesignAreas));
        this.layerCollection.add(new MapLayer(LayerFactory.createInfrastructureIdsLayer(), KnownLayers.InfrastructureIds, LayerGroup.Callouts));
        this.layerCollection.add(new MapLayer(LayerFactory.createBuildCalloutsLayer(), KnownLayers.BuildCallouts, LayerGroup.Callouts));
        this.layerCollection.add(new MapLayer(LayerFactory.createCabinetCalloutsLayer(), KnownLayers.CabinetCallouts, LayerGroup.Callouts));
        this.layerCollection.add(new MapLayer(LayerFactory.createBuildIdsLayer(), KnownLayers.BuildIds, LayerGroup.Callouts));
        this.layerCollection.add(new MapLayer(LayerFactory.createBuildStateLayer(), KnownLayers.BuildState, LayerGroup.Callouts));

        // "Special" layers
        const grayOutLayer = LayerFactory.createGrayoutLayer();
        grayOutLayer.setVisible(false);
        if (this.map) {
            this.map.addLayer(grayOutLayer);
        }
        this.layerCollection.add(new MapLayer(grayOutLayer, 'GrayOut', LayerGroup.Special, true));

        const importLayer = LayerFactory.createImportPreviewLayer();
        importLayer.setVisible(false);
        if (this.map) {
            this.map.addLayer(importLayer);
        }
        this.layerCollection.add(new MapLayer(importLayer, 'Import', LayerGroup.Special, true));

        const shadowLayer = LayerFactory.createShadowLayer();
        if (this.map) {
            this.map.addLayer(shadowLayer);
        }
        this.layerCollection.add(new MapLayer(shadowLayer, 'Shadow', LayerGroup.Special, true));

        this.handholeLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Handholes);
        this.manholeLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Manholes);
        this.vaultLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Vaults);
        this.polesLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Poles);
        this.cabinetLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Cabinets);
        this.flexnapCablesLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAPCables);
        this.schrodingerCablesLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerCables);
        this.bulkCablesLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.BulkCables);
        this.designAreasLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.DesignAreas);

        this.undergroundLayers = [this.handholeLayer, this.manholeLayer, this.vaultLayer];
        this.elementLayers = [...this.undergroundLayers, this.polesLayer, this.cabinetLayer];
        this.modifiableLayers = [...this.elementLayers, this.flexnapCablesLayer, this.schrodingerCablesLayer, this.bulkCablesLayer];
    }

    private updateRegisteredLayers(): void {
        this.createFlexNAPLayers();
        this.createSchrodingerLayers();
        this.createBulkLayers();
    }

    private registerLayers(): void {

        this.createFlexNAPLayers();
        this.createSchrodingerLayers();
        this.createBulkLayers();
        this.createGenericLayers();

        // Feature layers - Registration
        for (const mapLayer of this.layerCollection.layers) {
            this.registerLayer(mapLayer.layer, mapLayer.name, mapLayer.group);
        }
    }

    private registerLayer(layer: BaseLayer, name: string, group = 'Misc', visible = true): void {

        if (this.map) {
            this.map.addLayer(layer);

            const id = layer.get('id');
            if (!id) {
                throw new Error('A layer ID is required');
            }
            if (this.props.registerLayer) {
                this.props.registerLayer({ id, name, group, visible });
            }
        }
        else {
            throw new Error('Trying to register a layer to an undefinied map.');
        }
    }

    private singleClickListener = (event: MapClickEvent): void => {
        if (this.modificationTool) {
            this.modificationTool.execute(event);
        } else {
            if (this.props.selectedTool === ToolType.NAP || this.props.selectedTool === ToolType.TapSchrodinger || this.props.selectedTool === ToolType.SplicePoint) {
                event.contextMenuCallback = this.setContextMenuStateForNap.bind(this);
            }

            DesignToolExecuter.execute(this.props.selectedTool!, event);
        }
    }

    private doubleClickListener = (event: MapClickEvent): void => {
        DesignToolExecuter.execute(this.props.selectedTool!, event);
    }

    private shiftClickListener = (event: MapClickEvent): void => {
        const tool = new PretermLateralTool();
        tool.execute(event);
    }

    private contextMenuListener = (event: MapClickEvent): void => {
        let node: ModifiableNode | undefined;

        const modifiableFeatureTypes: FeatureType[] = [
            InteractableFeatureType.FLEXNAP_SEGMENT,
            InteractableFeatureType.SCHRODINGER_SEGMENT,
            InteractableFeatureType.BULK_SEGMENT,
            InteractableFeatureType.TERMINAL_SEGMENT,
            InteractableFeatureType.HANDHOLE,
            InteractableFeatureType.MANHOLE,
            InteractableFeatureType.POLE,
            InteractableFeatureType.VAULT,
            InteractableFeatureType.CABINET,
            InteractableFeatureType.DESIGN_AREA,
            InteractableFeatureType.NAP,
            InteractableFeatureType.TAP_SCHRODINGER,
            InteractableFeatureType.SPLICE_POINT,
            InteractableFeatureType.BORE,
            InteractableFeatureType.TRENCH,
        ];
        const modifiableFeatures = event.items.filter(i => modifiableFeatureTypes.includes(i.type));

        const allowedFeatures: FeatureType[] = [
            InteractableFeatureType.FLEXNAP_SEGMENT,
            InteractableFeatureType.SCHRODINGER_SEGMENT,
            InteractableFeatureType.BULK_SEGMENT,
            InteractableFeatureType.TERMINAL_SEGMENT
        ]
        const features = event.items.filter(i => allowedFeatures.includes(i.type));

        switch (this.props.selectedTool) {
            case ToolType.Modification: {
                this.resetContextMenu();

                // if a build is selected for modification, show the delete node context menu
                node = this.modificationTool?.getModifiableNode(modifiableFeatures, { x: event.x, y: event.y });
                if (node) {
                    this.setContextMenuStateForNode(node);
                    break;
                }

                // if a build is not already selected, and a NAP/TAP/SplicePoint has been clicked, show the NAP/TAP/SplicePoint move context menu
                const selectedNap = modifiableFeatures.find(f => f.type === InteractableFeatureType.NAP || f.type === InteractableFeatureType.TAP_SCHRODINGER || f.type === InteractableFeatureType.SPLICE_POINT);
                this.setContextMenuStateForNap({ nap: selectedNap });

                break;
            }
            default:
                this.setContextMenuStateForBuildSelection(features);
                break;

        }
    }

    private setContextMenuStateForBuildSelection(features: MapItemDefinition[]): void {
        this.setState({ data: { builds: this.getBuildSelection(features) } });
    }

    private setContextMenuStateForNode(node: ModifiableNode): void {
        this.setState({ data: { node } });
    }

    public setContextMenuStateForNap(event: unknown): void {
        if (!event) {
            return;
        }

        const napEvent = event as NapEvent;
        if (napEvent.nap && napEvent.nap.elementCoordinates) {
            this.setState({
                data: {
                    nap: {
                        napId: napEvent.nap.id,
                        elementId: napEvent.nap.elementId,
                        napCurrentBuildId: napEvent.nap.buildId,
                        builds: this.getBuildsOnElement(napEvent.nap.elementId, napEvent.nap.type),
                        draggingNap: napEvent.draggingNap,
                        type: napEvent.nap.type as InteractableFeatureType
                    }
                }, contextMenu: true
            });
        }
    }

    private getBuildSelection = (features: MapItemDefinition[]) => {
        const selectedData: BuildSelectionData[] = [];
        const { segments, flexnapBuilds, schrodingerBuilds, bulkBuilds } = this.props;
        const ids = features.filter((i) => !!i.buildId).map((i) => i.id);
        const builds: Build[] = [...flexnapBuilds!, ...schrodingerBuilds!, ...bulkBuilds!];
        for (const id of ids) {
            const segment = segments!.find((s) => s.id === id);
            if (segment) {
                const build = builds.find((b) => b.id === segment.buildId)!;
                selectedData.push({ segment: segment, build: build });
            }
        }

        return selectedData;
    }

    private getBuildsOnElement = (elementId: number, type: FeatureType): BuildSelectionData[] => {
        const selectedData: BuildSelectionData[] = [];

        const { segments, naps, tapsSchrodinger, splicePoints } = this.props;
        const builds: Build[] = this.getBuildsByFeatureType(type);

        const includedBuildIds: number[] = [];
        if (segments) {
            segments.filter(s => {
                if ((s.fromId === elementId || s.toId === elementId) && !includedBuildIds.includes(s.buildId)) {
                    includedBuildIds.push(s.buildId);
                    return true;
                }

                return false;
            }).forEach(s => {
                const build = builds.find((b) => b.id === s.buildId);
                if (build) {
                    const nap = build.type === BuildType.FlexNap ? naps?.find(n => n.buildId === build.id && n.elementId === elementId) : undefined;
                    const tap = build.type === BuildType.Schrodinger ? tapsSchrodinger?.find(n => n.buildId === build.id && n.elementId === elementId) : undefined;
                    const splicePoint = build.type === BuildType.Bulk ? splicePoints?.find(n => n.buildId === build.id && n.elementId === elementId) : undefined;
                    selectedData.push({
                        segment: s,
                        build,
                        nap, tap, splicePoint
                    });
                }
            });
        }

        return selectedData;
    };

    private dragFilterListener = (event: MapDragEvent): boolean => {
        const { selectedTool } = this.props;
        const allowed: FeatureType[] = [
            InteractableFeatureType.NAP,
            InteractableFeatureType.TAP_SCHRODINGER,
            InteractableFeatureType.SPLICE_POINT,
            InteractableFeatureType.TERMINAL,
            InteractableFeatureType.CABINET,
            InteractableFeatureType.POLE,
            InteractableFeatureType.MANHOLE,
            InteractableFeatureType.HANDHOLE,
            InteractableFeatureType.VAULT,
        ];
        if (selectedTool === ToolType.Modification && allowed.includes(event.item.type)) {
            return true;
        }
        return false
    }

    private dragEndListener = async (event: MapDragEndEvent): Promise<boolean> => {
        if (this.modificationTool) {
            if (event.item.type === InteractableFeatureType.NAP || event.item.type === InteractableFeatureType.TAP_SCHRODINGER || event.item.type === InteractableFeatureType.SPLICE_POINT) {
                return await this.modificationTool.drag(event, this.setContextMenuStateForNap.bind(this));
            }

            return await this.modificationTool.drag(event);
        }
        return false;
    }

    private pointerDragListener = (event: MapBrowserEvent<any>): boolean => {
        if (!this.map || !event.dragging) {
            return false;
        }

        if (this.props.selectedTool === ToolType.Modification) {
            const validDraggedFeatures = OpenLayerFactory.getModifiableFeaturesAtPixel(this.map, event.pixel);
            if (validDraggedFeatures.length > 0) {
                const draggedItemsIds = validDraggedFeatures.map(f => f.get('id') as number);
                this.props.dragItems!(draggedItemsIds);
            }
        }

        event.map.getViewport().style.cursor = 'move'; // cursor doesn't change until let-go, need to force redraw
        this.resetContextMenu();

        return true;
    }

    private mouseWheelListener = (e: WheelEvent) => {
        this.resetContextMenu();
    }

    private mapMoveListener = (bbox: number[]): void => {
        if (this.props.mapMove) {
            this.props.mapMove(bbox);
        }
        if (!this.map || bbox.length !== 4) {
            return;
        }
        this.saveMapPosition(bbox);
        const extentResolution = this.map.getView().getResolutionForExtent(bbox as [number, number, number, number]);
        if (this.props.setResolution) {
            this.props.setResolution(extentResolution);
        }
        if (extentResolution < RESOLUTION_CLOSE) {
            const { segments, naps, tapsSchrodinger, splicePoints } = this.props;
            const requiredIds: number[] = [];
            if (segments) {
                for (const { fromId, toId } of segments) {
                    fromId && requiredIds.push(fromId);
                    toId && requiredIds.push(toId);
                }
            }
            if (naps) {
                requiredIds.push(...naps.map((n) => n.elementId));
            }
            if (tapsSchrodinger) {
                requiredIds.push(...tapsSchrodinger.map((s) => s.elementId));
            }
            if (splicePoints) {
                requiredIds.push(...splicePoints.map(s => s.elementId));
            }
            const required = requiredIds.distinct();
            this.props.loadElements!(bbox, required);
            this.props.loadPaths!(bbox, required);
        } else if (this.props.clickedBuild) {
            // if the map moved because it is zooming to a build, and the view is zoomed out enough to not load the elements by default
            // load the elements of the selected build only, so that its cables are visible
            const { clickedBuild, segments = [] } = this.props;
            const buildSegments = segments.filter(({ buildId }) => buildId === clickedBuild);
            const elementIds: number[] = [];
            for (const { fromId, toId } of buildSegments) {
                fromId && elementIds.push(fromId);
                toId && elementIds.push(toId);
            }
            if (elementIds.length) {
                this.props.getPolesByIds!(elementIds);
                this.props.getManholesByIds!(elementIds);
                this.props.getHandholesByIds!(elementIds);
                this.props.getVaultsByIds!(elementIds);
                this.props.getCabinetsByIds!(elementIds);
            }
        }
        this.props.buildClicked!(undefined);
    }

    private loadMapPosition(): void {
        const center = fromLonLat([-87, 45]);  // Des Moines
        const previousPositon = localStorage.getItem("previousPosition");
        const zoomLevel = localStorage.getItem("zoomLevel");
        const mapView = this.map?.getView();
        if (mapView) {
            if (previousPositon && zoomLevel) {
                mapView.setCenter(JSON.parse(previousPositon));
                mapView.setZoom(JSON.parse(zoomLevel));
            } else {
                mapView.setCenter(center);
            }
        }
    }

    private saveMapPosition(bbox: number[]): void {
        const mapView = this.map?.getView();
        if (mapView) {
            localStorage.setItem("previousPosition", JSON.stringify(mapView.getCenter()));
            localStorage.setItem("previousBbox", JSON.stringify(bbox));
            localStorage.setItem("zoomLevel", JSON.stringify(mapView.getZoom()))
        }
    }

    private updateMapTool() {
        const { selectedTool } = this.props;
        if (!this.map) {
            throw new Error('Tools need a map');
        }
        if (selectedTool !== ToolType.Modification) {
            this.modificationTool = undefined;
        }

        switch (selectedTool) {
            case ToolType.Selection:
                OpenLayerFactory.resetMapTool({ map: this.map });
                break;
            case ToolType.Modification:
                this.modificationTool = new ModificationTool();
                OpenLayerFactory.updateMapSelectTool({ map: this.map, snapToLayers: this.elementLayers, crossCursor: false });
                OpenLayerFactory.updateMapModifyTool({
                    map: this.map,
                    snapToLayers: this.modifiableLayers,
                    targetTypes: modifiableTypes,
                    modificationTool: this.modificationTool
                });
                break;
            case ToolType.TapSchrodinger:
            case ToolType.NAP:
            case ToolType.SplicePoint:
                OpenLayerFactory.updateMapSelectTool({ map: this.map, snapToLayers: this.elementLayers, crossCursor: true });
                break;
            case ToolType.FlexNAPCable:
                OpenLayerFactory.updateMapDrawTool({
                    map: this.map,
                    snapToLayers: this.elementLayers,
                    targetTypes: elementTypes,
                    drawEnd: (features: any[]) => FlexNAPCableTool.finishCable(features.map((f) => OpenlayerUtility.getFeatureDefinition(f).id))
                });
                break;
            case ToolType.SchrodingerCable:
                OpenLayerFactory.updateMapDrawTool({
                    map: this.map,
                    snapToLayers: this.elementLayers,
                    targetTypes: elementTypes,
                    drawEnd: (features: any[]) => SchrodingerCableTool.finishCable(features.map((f) => OpenlayerUtility.getFeatureDefinition(f).id))
                });
                break;
            case ToolType.BulkCable:
                OpenLayerFactory.updateMapDrawTool({
                    map: this.map,
                    snapToLayers: this.elementLayers,
                    targetTypes: elementTypes,
                    drawEnd: (features: any[]) => BulkCableTool.finishCable(features.map((f) => OpenlayerUtility.getFeatureDefinition(f).id))
                });
                break;
            case ToolType.Pole:
            case ToolType.Cabinet:
            case ToolType.Manhole:
            case ToolType.Handhole:
            case ToolType.Vault:
                OpenLayerFactory.updateMapSelectTool({ map: this.map, crossCursor: true });
                break;
            case ToolType.Trench:
                OpenLayerFactory.updateMapDrawTool({
                    map: this.map,
                    snapToLayers: this.undergroundLayers,
                    targetTypes: undergroundTypes,
                    featuresToConnect: 2,
                    finishCondition: () => false,
                    drawEnd: (features, geom) => OpenLayerFactory.createNewPath(features, geom, TrenchTool.createNewTrench)
                });
                break;
            case ToolType.Bore:
                OpenLayerFactory.updateMapDrawTool({
                    map: this.map,
                    snapToLayers: this.undergroundLayers,
                    targetTypes: undergroundTypes,
                    featuresToConnect: 2,
                    finishCondition: () => false,
                    drawEnd: (features, geom) => OpenLayerFactory.createNewPath(features, geom, BoreTool.createNewBore)
                });
                break;
            case ToolType.DesignArea:
                OpenLayerFactory.updateMapPolygonDrawTool({
                    map: this.map,
                    snapToLayers: [this.designAreasLayer!],
                    drawEnd: (_, geom) => OpenLayerFactory.createNewPolygon(geom, DesignAreaTool.createNewDesignArea)
                });
                break;
        }
        OpenLayerFactory.clearSelectedFeatures(this.map!);
    }

    private selectSegment(id: number | undefined) {
        const segmentsLayers = [
            this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAPCables),
            this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerCables),
            this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.BulkCables)
        ];

        if (this.map) {
            for (const segmentsLayer of segmentsLayers) {
                if (this.select(this.map, segmentsLayer, id, 'id')) return;
            }
        }
    }
    private selectSegments(ids?: number[]) {
        const segmentsLayers = [
            this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAPCables),
            this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerCables),
            this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.BulkCables)
        ];

        if (this.map) {
            for (const segmentsLayer of segmentsLayers) {
                if (this.selectMultiple(this.map, segmentsLayer, 'id', ids)) return;
            }
        }
    }
    private selectDesignArea(id: number | undefined) {
        if (!this.map || !this.designAreasLayer) {
            return;
        }
        this.select(this.map, this.designAreasLayer, id, 'id');
    }
    private selectBore(id: number | undefined) {
        const boreLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Bores);
        if (this.map) {
            this.select(this.map, boreLayer, id, 'id');
        }
    }
    private selectTrench(id: number | undefined) {
        const trenchLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Trenches);
        if (this.map) {
            this.select(this.map, trenchLayer, id, 'id');
        }
    }
    private selectTerminal(terminalId: number | undefined) {
        const terminalLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.Terminals);
        if (this.map) {
            this.select(this.map, terminalLayer, terminalId, 'id');
        }
    }
    private selectTerminalSegment(terminalSegmentsIds: number[] | undefined) {
        const terminalSegmentLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.TerminalSegments);
        if (this.map) {
            this.selectMultiple(this.map, terminalSegmentLayer, 'id', terminalSegmentsIds);
        }
    }
    private selectTap(id: number | undefined) {
        const tapLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.Tap);
        if (this.map) {
            this.select(this.map, tapLayer, id, 'id');
        }
    }
    private selectTapSchrodinger(id: number | undefined) {
        const tapSchrodingerLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerTAP);
        if (this.map) {
            this.select(this.map, tapSchrodingerLayer, id, 'id');
        }
    }
    private selectNap(napId: number | undefined) {
        const napLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAP);
        if (this.map) {
            this.select(this.map, napLayer, napId, 'id');
        }
    }

    private selectSplicePoint(splicePointId: number | undefined) {
        const splicePointLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SplicePoints);
        if (this.map) {
            this.select(this.map, splicePointLayer, splicePointId, 'id');
        }
    }

    private selectPole(id: number | undefined) {
        const poleLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Poles);
        if (this.map) {
            this.select(this.map, poleLayer, id, 'id');
        }
    }

    private selectElement(id: number | undefined, type: ElementType | undefined) {
        const elementLayer = this.getLayerForElementType(type);
        if (this.map && elementLayer) {
            this.select(this.map, elementLayer, id, 'id');
        }
    }

    private select(map: MapType, layer: LayerType, id: number | undefined, keyName: string): boolean {
        const f = id === undefined ? undefined : layer.getSource().getFeatures().find((t) => t.get(keyName) === id);
        return OpenLayerFactory.selectFeature(map, f);
    }

    private selectMultiple(map: MapType, layer: LayerType, keyName: string, ids?: number[]): boolean {
        const f = ids === undefined ? undefined : layer.getSource().getFeatures().filter((t) => ids.includes(t.get(keyName)));
        return OpenLayerFactory.selectFeatures(map, f);
    }

    private getLayerForElementType(type: ElementType | undefined): VectorLayer | null {
        if (!type) return null;
        let layerName = KnownLayers.Poles;
        switch (type) {
            case ElementType.Cabinet:
                layerName = KnownLayers.Cabinets;
                break;
            case ElementType.Handhole:
                layerName = KnownLayers.Handholes;
                break;
            case ElementType.Manhole:
                layerName = KnownLayers.Manholes;
                break;
            case ElementType.Parcel:
                layerName = KnownLayers.Parcels;
                break;
            case ElementType.Vault:
                layerName = KnownLayers.Vaults;
                break;
        }
        return this.layerCollection.getLayerByName<VectorLayer>(layerName);
    }

    private onContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ contextMenu: true, clickX: event.clientX, clickY: event.clientY });
        return false;
    }

    private onMapClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        this.setState({ clickX: event.clientX, clickY: event.clientY });
        this.resetContextMenu();
    }

    private resetContextMenu = () => {
        const { contextMenu, data } = this.state;

        if (data?.nap?.draggingNap) {
            data.nap.draggingNap = false;
            return;
        }

        if (contextMenu) {
            if (data?.nap?.napId) {
                if (data.nap.type === InteractableFeatureType.NAP && this.props.cancelNapMove) {
                    this.props.cancelNapMove(data.nap.napId);
                } else if (data.nap.type === InteractableFeatureType.TAP_SCHRODINGER && this.props.cancelTapMove) {
                    this.props.cancelTapMove(data.nap.napId);
                } else if (data.nap.type === InteractableFeatureType.SPLICE_POINT && this.props.cancelSplicePointMove) {
                    this.props.cancelSplicePointMove(data.nap.napId);
                }
            }

            this.setState({
                contextMenu: false,
                data: undefined
            });
        }
    }

    private highlightBuild = (build: Build): void => {
        // Get all elements associated with build and their ids
        const buildSegments = this.props.segments?.filter(s => s.buildId === build.id);
        const segmentIdsToHighlight = buildSegments?.map(s => s.id);

        const buildNaps = this.props.naps?.filter(n => n.buildId === build.id);
        const napIdsToHighlight = buildNaps?.map(s => s.id);
        const portNumberIdsToHighlight = buildNaps?.map(s => s.elementId);

        const buildNapTaps = build.type === BuildType.FlexNap ? this.props.taps?.filter(t => napIdsToHighlight?.includes(t.napId)) : undefined;
        const flexNapTapIdsToHighlight = buildNapTaps?.map(t => t.id);
        const buildTethers = build.type === BuildType.FlexNap ? this.props.tethers?.filter(t => flexNapTapIdsToHighlight?.includes(t.tapId)) : undefined;
        const terminalIdsToHighlight = buildTethers?.map(t => t.terminal?.id);

        const buildTaps = build.type === BuildType.Schrodinger ? this.props.tapsSchrodinger?.filter(t => t.buildId === build.id) : undefined;
        const schrodingerTapIdsToHighlight = buildTaps?.map(t => t.id);

        const buildSplicePoints = build.type === BuildType.Bulk ? this.props.splicePoints?.filter(s => s.buildId === build.id) : undefined;
        const bulkSplicePointIdsToHighlight = buildSplicePoints?.map(s => s.id);

        const fromElements = buildSegments?.map(s => s.fromId);
        const toElements = buildSegments?.map(s => s.toId);
        const infrastructureElementIdsToHighlight = fromElements?.length && toElements?.length ? new Set([...fromElements, ...toElements]) : undefined;

        const buildTrenchIdsToHighlight = this.props.trenches?.filter(tr => infrastructureElementIdsToHighlight?.has(tr.fromElementId) || infrastructureElementIdsToHighlight?.has(tr.toElementId)).map(tr => tr.id);
        const buildBoreIdsToHighlight = this.props.bores?.filter(b => infrastructureElementIdsToHighlight?.has(b.fromElementId) || infrastructureElementIdsToHighlight?.has(b.toElementId)).map(b => b.id);


        const segmentLayer = this.getSegmentsLayerByBuildType(build.type);
        const napLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAP);
        const tapLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.Tap);
        const schrodingerTapLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerTAP);
        const splicePointLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SplicePoints);
        const terminalLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.Terminals);
        const terminalExtensionLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.TerminalSegments);
        const poleLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Poles);
        const manholeLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Manholes);
        const handholeLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Handholes);
        const trenchLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Trenches);
        const boreLayer = this.layerCollection.getLayerByName<VectorLayer>(KnownLayers.Bores);
        const portNumberLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.PortNumbers);

        // Get all features together
        const segmentFeatures = segmentLayer.getSource().getFeatures().filter((t) => segmentIdsToHighlight?.includes(t.get('id')));
        const napFeatures = napLayer.getSource().getFeatures().filter((t) => napIdsToHighlight?.includes(t.get('id')));
        const tapFeatures = tapLayer.getSource().getFeatures().filter((t) => flexNapTapIdsToHighlight?.includes(t.get('id')));
        const tapSchrodingerFeatures = schrodingerTapLayer.getSource().getFeatures().filter((t) => schrodingerTapIdsToHighlight?.includes(t.get('id')));
        const splicePointFeatures = splicePointLayer.getSource().getFeatures().filter(t => bulkSplicePointIdsToHighlight?.includes(t.get('id')));
        const terminalFeatures = terminalLayer.getSource().getFeatures().filter((t) => terminalIdsToHighlight?.includes(t.get('id')));
        const terminalExtensionFeatures = terminalExtensionLayer.getSource().getFeatures().filter((t) => terminalIdsToHighlight?.includes(t.get('terminalId')));
        const poleFeatures = poleLayer.getSource().getFeatures().filter((t) => infrastructureElementIdsToHighlight?.has(t.get('id')));
        const manholeFeatures = manholeLayer.getSource().getFeatures().filter((t) => infrastructureElementIdsToHighlight?.has(t.get('id')));
        const handholeFeatures = handholeLayer.getSource().getFeatures().filter((t) => infrastructureElementIdsToHighlight?.has(t.get('id')));
        const trenchFeatures = trenchLayer.getSource().getFeatures().filter((t) => buildTrenchIdsToHighlight?.includes(t.get('id')));
        const boreFeatures = boreLayer.getSource().getFeatures().filter((t) => buildBoreIdsToHighlight?.includes(t.get('id')));
        const portNumberFeatures = portNumberLayer.getSource().getFeatures().filter((t) => portNumberIdsToHighlight?.includes(t.get('elementId')));

        // Select features in OL
        OpenLayerFactory.selectFeatures(this.map!, [...segmentFeatures, ...napFeatures, ...tapFeatures, ...terminalFeatures, ...poleFeatures, ...manholeFeatures, ...handholeFeatures,
        ...trenchFeatures, ...boreFeatures, ...tapSchrodingerFeatures, ...splicePointFeatures, ...terminalExtensionFeatures, ...portNumberFeatures]);
    }

    private unhighlightBuild = (build: Build): void => {
        OpenLayerFactory.clearSelectedFeatures(this.map!);
    }

    private bringBuildToFront = (buildId: number, type: BuildType) => {
        const layer = this.getSegmentsLayerByBuildType(type);

        OpenLayerFactory.clearSelectedFeatures(this.map!);
        OpenLayerFactory.bringFeaturesToFront(layer, buildId, 'buildId');

        this.reorderSelectedData();
    }

    private sendBuildToBack = (buildId: number, type: BuildType) => {
        const layer = this.getSegmentsLayerByBuildType(type);

        OpenLayerFactory.clearSelectedFeatures(this.map!);
        OpenLayerFactory.sendFeaturesToBack(layer, buildId, 'buildId');

        this.reorderSelectedData();
    }

    private reorderSelectedData = () => {
        const { data } = this.state;
        if (data && data.builds) {
            const flexnapSegmentsLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAPCables);
            const schrodingerSegmentsLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerCables);
            const bulkSegmentsLayer = this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.BulkCables);
            // getFeatures() returns an unordered list, therefore we must sort by Uid

            const flexnap = flexnapSegmentsLayer!.sortFeaturesByUid();
            const schrodinger = schrodingerSegmentsLayer.sortFeaturesByUid();
            const bulk = bulkSegmentsLayer.sortFeaturesByUid();
            const features = [...flexnap, ...schrodinger, ...bulk];
            const reordered = data.builds.slice(0).sort(this.reorderPredicate.bind(this, features));
            this.setState({ data: { ...data, builds: reordered } });
        }
    }

    private moveFeature = (featureType: InteractableFeatureType, id: number, elementId: number, buildId?: number): void => {
        if (this.modificationTool) {
            switch (featureType) {
                case InteractableFeatureType.NAP:
                    this.modificationTool.moveNap(id, elementId, buildId ?? null);
                    break;
                case InteractableFeatureType.TAP_SCHRODINGER:
                    this.modificationTool.moveTap(id, elementId, buildId ?? null);
                    break;
                case InteractableFeatureType.SPLICE_POINT:
                    this.modificationTool.moveSplicePoint(id, elementId, buildId ?? null);
                    break;
            }
        }

        this.resetContextMenu();
    };

    private createFeature = (featureType: InteractableFeatureType, elementId: number, buildId?: number): void => {
        switch (featureType) {
            case InteractableFeatureType.NAP:
                NAPTool.createNap(elementId, buildId);
                break;
            case InteractableFeatureType.TAP_SCHRODINGER:
                TapTool.createTap(elementId, buildId);
                break;
            case InteractableFeatureType.SPLICE_POINT:
                SplicePointTool.createSplicePoint(elementId, buildId);
                break;
        }

        this.resetContextMenu();
    };

    private reorderPredicate = (features: Feature[], a: BuildSelectionData, b: BuildSelectionData): number => {
        const first = features.findIndex((f) => f.get('id') === b.segment.id);
        const second = features.findIndex((f) => f.get('id') === a.segment.id);
        return first - second;
    }

    private renderContextMenu = () => {
        const { contextMenu, data, clickX, clickY } = this.state;
        return (
            <MapContextMenu
                open={contextMenu}
                data={data}
                x={clickX}
                y={clickY}
                onSelected={this.resetContextMenu}
                onBringToFront={this.bringBuildToFront}
                onSendToBack={this.sendBuildToBack}
                onDeleteNode={(n) => this.modificationTool?.deleteNode(n)}
                onMove={this.moveFeature}
                onCreate={this.createFeature}
                onHighlight={this.highlightBuild}
                onUnhighlight={this.unhighlightBuild}
            />
        );
    }

    public render(): JSX.Element {
        const roles = this.props.roles ?? [];
        const isRoleManagementMenuShown = this.props.isRoleManagementMenuShown;
        return (
            <div>
                {isRoleManagementMenuShown ? <RoleManagementTool /> : null}
                {roles.length < 1 ? <AccessDeniedComponent /> : null}
                {this.map ? <GisOverlayComponent map={this.map} /> : null}
                <div id="mapContainer" className="fullscreen-map" onContextMenu={this.onContextMenu} onClick={this.onMapClick} />
                {this.renderContextMenu()}
            </div>
        );
    }

    private setupBuildTools(roles: string[]): void {
        const { buildType, setBuildType } = this.props;
        const isFlexNap = buildType === BuildType.FlexNap
        const expectedRole = isFlexNap ? CorningRoles.FlexNAP : CorningRoles.Schrodinger;
        if (!roles.includes(expectedRole) && setBuildType) {
            setBuildType(isFlexNap ? BuildType.Schrodinger : BuildType.FlexNap);
        }
    }

    private getBuildsByFeatureType = (type: FeatureType): Build[] => {
        switch (type) {
            case InteractableFeatureType.TAP_SCHRODINGER:
                return this.props.schrodingerBuilds ?? [];
            case InteractableFeatureType.SPLICE_POINT:
                return this.props.bulkBuilds ?? [];
            case InteractableFeatureType.NAP:
            default:
                return this.props.flexnapBuilds ?? [];
        }
    };

    private getSegmentsLayerByBuildType = (type: BuildType): VectorLayer => {
        switch (type) {
            case BuildType.Schrodinger:
                return this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerCables);
            case BuildType.Bulk:
                return this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.BulkCables);
            case BuildType.FlexNap:
            default:
                return this.layerCollection.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAPCables);
        }
    };
}

