import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../locales/types';
import { DialogComponent } from './dialog.component';

interface ExportBuildErrorProps {
    open?: boolean;
    message?: string;
    onClose?: () => void;
}

export class ExportBuildErrorDialog extends React.Component<ExportBuildErrorProps> {
    private close = (): void => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    
    public render(): JSX.Element {
        const { t } = useTranslation();

        return (
            <DialogComponent
                open={!!this.props.open}
                title="Can't export build(s)"
                message={this.props.message}
                positiveTitle={t(LocalizationKeys.Ok)}
                onPositiveClick={this.close}
                onClose={this.close}
            />
        );
    }
}
