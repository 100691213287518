import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import VectorLayer from 'ol/layer/Vector';

import { Tap } from '../models/tap';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { LayerType, MapType, OpenLayerFactory } from '../openlayers/openlayer-factory.service';
import { FeatureFactory, FeatureType } from '../openlayers/openlayer-feature.factory';
import { LayerFactory } from '../openlayers/openlayer-layer.factory';
import { OpenlayerUtility } from '../openlayers/openlayer-utility';
import { StaticFeatureType } from '../openlayers/static-feature-type';
import { ItemType } from '../validation/item-type';
import { ValidationResult } from '../validation/validation-result';
import { Bore, BoreData } from './bore';
import { Build } from './build';
import { BuildSegment } from './build-segment';
import { BulkBuild } from './bulk/bulk-build';
import { Cabinet, CabinetData } from './cabinet';
import { DesignArea } from './design-area';
import { Element } from './element';
import { ErrorShadowType } from './error-shadow-type';
import { FlexNapBuild } from './flexnap-build';
import { FlexNapPortNumbersByElement } from './flexnap-splice-plan';
import { Handhole, HandholeData } from './handhole';
import { KnownLayers } from './known-layers';
import LayerCollection from './layer-collection';
import { Manhole, ManholeData } from './manhole';
import { Nap } from './nap';
import { Parcel, ParcelData } from './parcel';
import { Path, PathData } from './path';
import { PathType } from './path-type';
import { Pole, PoleData } from './pole';
import { PoleSpanData } from './polespan';
import { SchrodingerBuild } from './schrodinger-build';
import { Tap as TapSchrodinger } from './schrodinger/tap';
import { Tether as TetherSchrodinger } from './schrodinger/tether';
import { SplicePoint } from './splice-point';
import { Tether } from './tether';
import { Trench, TrenchData } from './trench';
import { Vault, VaultData } from './vault';

export interface LayerContext {
    designAreas: DesignArea[];
    poles: Pole[];
    flexNAPBuilds: FlexNapBuild[];
    flexNapPortNumbers?: FlexNapPortNumbersByElement;
    schrodingerBuilds: SchrodingerBuild[];
    bulkBuilds: BulkBuild[];
    segments: BuildSegment[];
    naps: Nap[];
    taps: Tap[];
    tethers: Tether[];
    tapsSchrodinger: TapSchrodinger[];
    tethersSchrodinger: TetherSchrodinger[];
    splicePoints: SplicePoint[];
    parcels: Parcel[];
    manholes: Manhole[];
    handholes: Handhole[];
    vaults: Vault[];
    trenches: Trench[];
    bores: Bore[];
    cabinets: Cabinet[];
    autoDropCable: boolean | undefined;
    resolution?: number | undefined;
    clickedNotification?: ValidationResult;
}

export interface ImportContext {
    poles: PoleData[];
    poleSpans: PoleSpanData[];
    parcels: ParcelData[];
    cabinets: CabinetData[];
    manholes: ManholeData[];
    handholes: HandholeData[];
    vaults: VaultData[];
    trenches: TrenchData[];
    bores: BoreData[];
}

export class LayerUpdater {

    #layerContext: LayerContext;
    #layers: LayerCollection;
    #importContext: ImportContext;

    constructor(layers: LayerCollection, layerContext: LayerContext, importContext: ImportContext) {
        this.#layerContext = layerContext;
        this.#layers = layers;
        this.#importContext = importContext;
    }

    public update(map: MapType, previousLayerContext: LayerContext, previousImportContext: ImportContext): void {
        const designAreasChanged = this.#layerContext.designAreas !== previousLayerContext.designAreas;
        const flexnapBuildsChanged = this.#layerContext.flexNAPBuilds !== previousLayerContext.flexNAPBuilds;
        const schrodingerBuildsChanged = this.#layerContext.schrodingerBuilds !== previousLayerContext.schrodingerBuilds;
        const bulkBuildsChanged = this.#layerContext.bulkBuilds !== previousLayerContext.bulkBuilds;
        const segmentsChanged = this.#layerContext.segments !== previousLayerContext.segments;
        const napsChanged = this.#layerContext.naps !== previousLayerContext.naps;
        const tapsChanged = this.#layerContext.taps !== previousLayerContext.taps;
        const tethersChanged = this.#layerContext.tethers !== previousLayerContext.tethers;
        const splicePointsChanged = this.#layerContext.splicePoints !== previousLayerContext.splicePoints;
        const polesChanged = this.#layerContext.poles !== previousLayerContext.poles;
        const parcelsChanged = this.#layerContext.parcels !== previousLayerContext.parcels;
        const cabinetsChanged = this.#layerContext.cabinets !== previousLayerContext.cabinets;
        const manholesChanged = this.#layerContext.manholes !== previousLayerContext.manholes;
        const handholesChanged = this.#layerContext.handholes !== previousLayerContext.handholes;
        const vaultsChanged = this.#layerContext.vaults !== previousLayerContext.vaults;
        const trenchesChanged = this.#layerContext.trenches !== previousLayerContext.trenches;
        const boresChanged = this.#layerContext.bores !== previousLayerContext.bores;
        const flexNapPortNumbersChanged = this.#layerContext.flexNapPortNumbers !== previousLayerContext.flexNapPortNumbers;
        const autoDropChanged = this.#layerContext.autoDropCable !== previousLayerContext.autoDropCable;
        const tapsSchrodingerChanged = this.#layerContext.tapsSchrodinger !== previousLayerContext.tapsSchrodinger
            || this.#layerContext.tethersSchrodinger !== previousLayerContext.tethersSchrodinger;
        const notificationClickedChanged = this.#layerContext.clickedNotification !== previousLayerContext.clickedNotification;

        const polesInitialized = this.#layerContext.poles.length > 0 && previousLayerContext.poles.length === 0;
        const parcelsInitialized = this.#layerContext.parcels.length > 0 && previousLayerContext.parcels.length === 0;
        const cabinetsInitialized = this.#layerContext.cabinets.length > 0 && previousLayerContext.cabinets.length === 0;
        const manholesInitialized = this.#layerContext.manholes.length > 0 && previousLayerContext.manholes.length === 0;
        const handholesInitialized = this.#layerContext.handholes.length > 0 && previousLayerContext.handholes.length === 0;
        const vaultsInitialized = this.#layerContext.vaults.length > 0 && previousLayerContext.vaults.length === 0;

        const trenchesInitialized = this.#layerContext.trenches.length > 0 && previousLayerContext.trenches.length === 0;
        const boresInitialized = this.#layerContext.bores.length > 0 && previousLayerContext.bores.length === 0;

        const elementsInitialized = polesInitialized || parcelsInitialized || cabinetsInitialized || manholesInitialized || handholesInitialized || vaultsInitialized;
        const pathsInitialized = boresInitialized || trenchesInitialized;

        const elementsChanged = polesChanged || cabinetsChanged || manholesChanged || handholesChanged || vaultsChanged;
        const pathsChanged = trenchesChanged || boresChanged;
        const importedChanged =
            this.#importContext.poles !== previousImportContext.poles
            || this.#importContext.poleSpans !== previousImportContext.poleSpans
            || this.#importContext.parcels !== previousImportContext.parcels
            || this.#importContext.cabinets !== previousImportContext.cabinets
            || this.#importContext.manholes !== previousImportContext.manholes
            || this.#importContext.handholes !== previousImportContext.handholes
            || this.#importContext.vaults !== previousImportContext.vaults
            || this.#importContext.trenches !== previousImportContext.trenches
            || this.#importContext.bores !== previousImportContext.bores;

        // detect when we need to change the Style of a feature when the resolution (zoom level) changes. We know that we only need
        // to get a new Style when the distanceIndex based on the resolution changes. Certain features are removed from the display
        // and others are smaller as we zoom out.
        const resolutionDistanceIndexChanged = OpenlayerUtility.getResolutionDistanceIndex(this.#layerContext.resolution) !== OpenlayerUtility.getResolutionDistanceIndex(previousLayerContext.resolution);
        const resolutionDistanceChanged = OpenlayerUtility.checkResolutionDelta(this.#layerContext.resolution, previousLayerContext.resolution, 0.06);

        if (resolutionDistanceIndexChanged || designAreasChanged) {
            this.updateDesignAreasLayer(map);
        }
        if (resolutionDistanceIndexChanged || polesChanged) {
            this.updatePolesLayer(map); // depends on taps and poles
        }

        if (resolutionDistanceIndexChanged || flexnapBuildsChanged || segmentsChanged || elementsInitialized || pathsChanged) {
            this.updateFlexNapSegmentsLayer(map); // depends on builds, segments, elements, and paths
        }
        if (resolutionDistanceIndexChanged || schrodingerBuildsChanged || segmentsChanged || elementsInitialized || pathsChanged) {
            this.updateSchrodingerSegmentsLayer(map); // depends on builds, segments, elements, and paths
        }
        if (resolutionDistanceIndexChanged || bulkBuildsChanged || segmentsChanged || elementsInitialized || pathsInitialized) {
            this.updateBulkSegmentsLayer(map); // depends on builds, segments, elements, and paths
        }
        if (resolutionDistanceIndexChanged || flexnapBuildsChanged || schrodingerBuildsChanged || bulkBuildsChanged || segmentsChanged || elementsInitialized || pathsInitialized || resolutionDistanceChanged) {
            this.updateBuildStateLayer(map, this.#layerContext.resolution);
        }
        if (resolutionDistanceIndexChanged || flexNapPortNumbersChanged || polesChanged || handholesChanged || manholesChanged || vaultsChanged) {
            this.updatePortNumberLayer(this.#layerContext.resolution);
        }
        if (resolutionDistanceIndexChanged || napsChanged || tapsChanged || tethersChanged || polesChanged) {
            this.updateNapLayer(map); // depends on taps and poles
            this.updateTapLayer(map);
        }
        if (resolutionDistanceIndexChanged || napsChanged || tapsChanged || tethersChanged || polesChanged || flexnapBuildsChanged) {
            this.updateTerminalLayer(map);
            this.updateTerminalSegmentsLayer(map, this.#layerContext.resolution);
        }
        if (resolutionDistanceIndexChanged || splicePointsChanged || polesChanged) {
            this.updateSplicePointsLayer(map);
        }
        if (resolutionDistanceIndexChanged || cabinetsChanged) {
            const cabinetLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Cabinets);
            this.updatePoweredElementLayer(map, InteractableFeatureType.CABINET, cabinetLayer, this.#layerContext.cabinets);
        }
        if (resolutionDistanceIndexChanged || parcelsChanged || autoDropChanged) {
            this.updateParcelLayer(map);
        }
        if (resolutionDistanceIndexChanged || importedChanged) {
            this.updateImportLayer();
        }
        if (resolutionDistanceIndexChanged || manholesChanged) {
            const manholeLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Manholes);
            this.updatePoweredElementLayer(map, InteractableFeatureType.MANHOLE, manholeLayer, this.#layerContext.manholes);
        }
        if (resolutionDistanceIndexChanged || handholesChanged) {
            const handholeLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Handholes);
            this.updatePoweredElementLayer(map, InteractableFeatureType.HANDHOLE, handholeLayer, this.#layerContext.handholes);
        }
        if (resolutionDistanceIndexChanged || vaultsChanged) {
            const vaultLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Vaults);
            this.updatePoweredElementLayer(map, InteractableFeatureType.VAULT, vaultLayer, this.#layerContext.vaults);
        }
        if (resolutionDistanceIndexChanged || trenchesChanged) {
            const trenchLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Trenches);
            this.updatePathLayer(map, InteractableFeatureType.TRENCH, trenchLayer, this.#layerContext.trenches);
        }
        if (resolutionDistanceIndexChanged || boresChanged) {
            const boreLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Bores);
            this.updatePathLayer(map, InteractableFeatureType.BORE, boreLayer, this.#layerContext.bores);
        }
        if (resolutionDistanceIndexChanged || tapsSchrodingerChanged || elementsChanged) {
            this.updateTapSchrodingerLayer(map);
        }
        if (resolutionDistanceIndexChanged || notificationClickedChanged) {
            this.updateShadowLayer(map);
        }
        if (resolutionDistanceIndexChanged || elementsChanged || napsChanged) {
            this.updateInfrastructureIdsLayer(this.#layerContext.resolution);
        }
        if (resolutionDistanceIndexChanged || flexnapBuildsChanged || schrodingerBuildsChanged || bulkBuildsChanged || segmentsChanged || elementsChanged || pathsChanged) {
            this.updateBuildCalloutsLayer();
            this.updateBuildIdsLayer(this.#layerContext.resolution);
        }
        if (resolutionDistanceIndexChanged || cabinetsChanged) {
            this.updateCabinetCalloutsLayer();
        }
    }

    private updateDesignAreasLayer(map: MapType): void {
        const designAreasLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.DesignAreas);
        const designAreas = this.#layerContext.designAreas;
        const daFeatures = FeatureFactory.createDesignAreaFeatures(designAreas, this.#layerContext.resolution);
        this.replaceAndRefreshFeatures(map, designAreasLayer, daFeatures, InteractableFeatureType.DESIGN_AREA, 'id');
    }

    private updateFlexNapSegmentsLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.FlexNAPCables) || !this.#layers.getSpecialLayerByName(KnownLayers.FlexNAPCables).getVisible()) {
            return;
        }

        const flexnapSegmentsLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAPCables);
        const { flexNAPBuilds, segments, poles, manholes, handholes, cabinets, vaults, bores, trenches } = this.#layerContext;
        const elements = [...poles, ...manholes, ...handholes, ...cabinets, ...vaults];
        const paths = [...bores, ...trenches];
        const sortedBuilds = [...flexNAPBuilds].sort(this.sortBuildsByLastModified);
        const segmentFeatures = FeatureFactory.createFlexNapSegmentFeatures(sortedBuilds, segments, elements, paths, this.#layerContext.resolution);
        this.replaceAndRefreshFeatures(map, flexnapSegmentsLayer, segmentFeatures, InteractableFeatureType.FLEXNAP_SEGMENT, 'id');
    }

    private updateSchrodingerSegmentsLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.SchrodingerCables) || !this.#layers.getSpecialLayerByName(KnownLayers.SchrodingerCables).getVisible()) {
            return;
        }

        const schrodingerSegmentsLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerCables);
        const { schrodingerBuilds, segments, poles, manholes, handholes, cabinets, vaults, bores, trenches } = this.#layerContext;
        const elements = [...poles, ...manholes, ...handholes, ...cabinets, ...vaults];
        const paths = [...bores, ...trenches];
        const sortedBuilds = [...schrodingerBuilds].sort(this.sortBuildsByLastModified);
        const segmentFeatures = FeatureFactory.createSchrodingerSegmentFeatures(sortedBuilds, segments, elements, paths, this.#layerContext.resolution);
        this.replaceAndRefreshFeatures(map, schrodingerSegmentsLayer, segmentFeatures, InteractableFeatureType.SCHRODINGER_SEGMENT, 'id');
    }

    private updateBulkSegmentsLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.BulkCables) || !this.#layers.getSpecialLayerByName(KnownLayers.BulkCables).getVisible()) {
            return;
        }

        const bulkSegmentsLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.BulkCables);
        const { bulkBuilds, segments, poles, manholes, handholes, cabinets, vaults, bores, trenches } = this.#layerContext;
        const elements = [...poles, ...manholes, ...handholes, ...cabinets, ...vaults];
        const paths = [...bores, ...trenches];
        const sortedBuilds = [...bulkBuilds].sort(this.sortBuildsByLastModified);
        const segmentFeatures = FeatureFactory.createBulkSegmentFeatures(sortedBuilds, segments, elements, paths, this.#layerContext.resolution);
        this.replaceAndRefreshFeatures(map, bulkSegmentsLayer, segmentFeatures, InteractableFeatureType.BULK_SEGMENT, 'id');
    }

    private updateBuildStateLayer(map: MapType, resolution: number | undefined): void {
        const buildStateLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.BuildState);
        const { flexNAPBuilds, schrodingerBuilds, bulkBuilds, segments, poles, manholes, handholes, cabinets, vaults, bores, trenches } = this.#layerContext;
        const elements = [...poles, ...manholes, ...handholes, ...cabinets, ...vaults];
        const paths = [...bores, ...trenches];
        const allBuilds = [...flexNAPBuilds, ...schrodingerBuilds, ...bulkBuilds];

        const specialStatusBuilds: Build[] = [];
        for (const build of allBuilds) {
            if (build.lockedById) specialStatusBuilds.push(build);
        }

        const statusFeatures = FeatureFactory.createBuildStatusFeatures(specialStatusBuilds, segments, elements, paths, resolution);
        const featureTypes = [StaticFeatureType.UPLOADED_BUILD, StaticFeatureType.APPROVED_BUILD, InteractableFeatureType.REJECTED_BUILD,
        StaticFeatureType.IN_REVIEW_BUILD, InteractableFeatureType.EXPORTED_BUILD, InteractableFeatureType.LOCKED_BUILD];
        this.replaceAndRefreshFeatureTypes(map, buildStateLayer, statusFeatures, featureTypes, 'buildId');
    }

    private updatePolesLayer(map: MapType): void {
        const polesLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Poles);
        const poles = this.#layerContext.poles;
        const poleFeatures = poles.map((p) => FeatureFactory.createElement(p, InteractableFeatureType.POLE, this.#layerContext.resolution, { powerStatus: p.powerStatus }));
        this.replaceAndRefreshFeatures(map, polesLayer, poleFeatures, InteractableFeatureType.POLE, 'id');
    }

    private updateNapLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.FlexNAP) || !this.#layers.getSpecialLayerByName(KnownLayers.FlexNAP).getVisible()) {
            return;
        }

        const napLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.FlexNAP);
        const { poles, manholes, handholes, vaults, naps, taps, tethers, flexNAPBuilds } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults];
        const napFeatures = FeatureFactory.createNapFeatures(naps, taps, tethers, flexNAPBuilds, elements, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(napLayer, napFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, napFeatures, InteractableFeatureType.NAP, 'id');
    }

    private updateTapLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.Tap) || !this.#layers.getSpecialLayerByName(KnownLayers.Tap).getVisible()) {
            return;
        }
        const tapLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.Tap);
        const { poles, manholes, handholes, vaults, naps, taps, tethers, flexNAPBuilds } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults];
        const tapFeatures = FeatureFactory.createTapFeatures(naps, taps, tethers, elements, flexNAPBuilds, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(tapLayer, tapFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, tapFeatures, InteractableFeatureType.TAP, 'id');
    }

    private updatePortNumberLayer(resolution?: number): void {
        const portNumbersLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.PortNumbers);
        if (!portNumbersLayer || !portNumbersLayer.getVisible() || !resolution) {
            return;
        }
        const { poles, manholes, handholes, vaults, flexNapPortNumbers } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults];
        const portNumberFeatures = FeatureFactory.createPortNumberFeatures(elements, flexNapPortNumbers, resolution);
        LayerFactory.replaceFeatures(portNumbersLayer, portNumberFeatures);
    }

    private updateTerminalLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.Terminals) || !this.#layers.getSpecialLayerByName(KnownLayers.Terminals).getVisible()) {
            return;
        }
        const terminalLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.Terminals);
        const { poles, manholes, handholes, vaults, naps, taps, tethers, flexNAPBuilds } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults];
        const terminalFeatures = FeatureFactory.createTerminalFeatures(naps, taps, tethers, flexNAPBuilds, elements, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(terminalLayer, terminalFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, terminalFeatures, InteractableFeatureType.TERMINAL, 'tetherId');
    }

    private updateTerminalSegmentsLayer(map: MapType, resolution?: number): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.TerminalSegments) || !this.#layers.getSpecialLayerByName(KnownLayers.TerminalSegments).getVisible()) {
            return;
        }
        if (!resolution) {
            resolution = 1.5;
        }
        const terminalSegmentsLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.TerminalSegments);
        const { poles, manholes, handholes, vaults, tethers, segments, taps, naps, flexNAPBuilds, bores, trenches } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults];
        const paths = [...bores, ...trenches];
        const terminalSegmentFeatures = FeatureFactory.createTerminalSegmentFeatures(tethers, elements, paths, segments, taps, naps, flexNAPBuilds, resolution);
        LayerFactory.replaceFeatures(terminalSegmentsLayer, terminalSegmentFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, terminalSegmentFeatures, InteractableFeatureType.TERMINAL_SEGMENT, 'id');
    }

    private updateSplicePointsLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.BulkCables) || !this.#layers.getSpecialLayerByName(KnownLayers.BulkCables).getVisible()) {
            return;
        }

        const splicePointLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.SplicePoints);
        const { poles, manholes, handholes, vaults, cabinets, splicePoints, bulkBuilds } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...manholes, ...vaults, ...cabinets];
        const splicePointFeatures = FeatureFactory.createSplicePointFeatures(splicePoints, bulkBuilds, elements, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(splicePointLayer, splicePointFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, splicePointFeatures, InteractableFeatureType.SPLICE_POINT, 'id');
    }

    private updatePoweredElementLayer(map: MapType, featureType: InteractableFeatureType, layer?: LayerType, elements?: Element[] | undefined): void {
        if (layer && elements) {
            const features = elements.map((e) => FeatureFactory.createPoweredElement(e, featureType, this.#layerContext.resolution));
            LayerFactory.replaceFeatures(layer, features);
            OpenLayerFactory.refreshSelectedFeatureType(map, features, featureType, 'id');
        }
    }

    private updateParcelLayer(map: MapType): void {
        const parcelLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.Parcels);
        const dropCableLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.DropCables);
        const { parcels, poles, autoDropCable } = this.#layerContext;

        if (autoDropCable) {
            dropCableLayer.setVisible(true);
            const dropCables = FeatureFactory.createDropCablesForPoles(poles, parcels, this.#layerContext.resolution);
            LayerFactory.replaceFeatures(dropCableLayer, dropCables);
        }
        else {
            dropCableLayer.setVisible(false);
        }
        const features = parcels.map((p) => FeatureFactory.createParcel(p, this.#layerContext.resolution));
        LayerFactory.replaceFeatures(parcelLayer, features);
        OpenLayerFactory.refreshSelectedFeatureType(map, features, InteractableFeatureType.PARCEL, 'id');
    }

    private updateImportLayer(): void {
        const grayOutLayer = this.#layers.getSpecialLayerByName<VectorLayer>('GrayOut');
        const importLayer = this.#layers.getSpecialLayerByName<VectorLayer>('Import');

        let { poles, parcels, cabinets, manholes, handholes, vaults, trenches, bores } = this.#importContext;
        poles = poles || [];
        parcels = parcels || [];
        cabinets = cabinets || [];
        manholes = manholes || [];
        handholes = handholes || [];
        vaults = vaults || [];
        trenches = trenches || [];
        bores = bores || [];
        const createdDate = new Date(Date.now());
        const importedFeatures: Array<Feature<Geometry>> = [];
        if (poles.length) {
            importedFeatures.push(...poles.map((p) => FeatureFactory.createPole(p as Pole, this.#layerContext.resolution)));
        }
        if (parcels.length) {
            importedFeatures.push(...parcels.map((p) => FeatureFactory.createParcel(p as Parcel, this.#layerContext.resolution)));
        }
        if (cabinets.length) {
            importedFeatures.push(...cabinets.map((c) => FeatureFactory.createCabinet(c as Cabinet, this.#layerContext.resolution)));
        }
        if (manholes.length) {
            importedFeatures.push(...manholes.map((m) => FeatureFactory.createManhole(m as Manhole, this.#layerContext.resolution)));
        }
        if (handholes.length) {
            importedFeatures.push(...handholes.map((h) => FeatureFactory.createHandhole(h as Handhole, this.#layerContext.resolution)));
        }
        if (vaults.length) {
            importedFeatures.push(...vaults.map((v) => FeatureFactory.createVault(v as Vault, this.#layerContext.resolution)));
        }
        if (trenches.length) {
            importedFeatures.push(...this.createPathFeatures<Trench>(trenches, createdDate, PathType.Trench, InteractableFeatureType.TRENCH));
        }
        if (bores.length) {
            importedFeatures.push(...this.createPathFeatures<Bore>(bores, createdDate, PathType.Bore, InteractableFeatureType.BORE));
        }

        if (importedFeatures.length > 0) {
            grayOutLayer.setVisible(true);
            importLayer.setVisible(true);
            for (const f of importedFeatures) {
                f.set('isImported', true);
            }
            LayerFactory.replaceFeatures(importLayer, importedFeatures);
        }
        else {
            grayOutLayer.setVisible(false);
            importLayer.setVisible(false);
            importLayer.getSource().clear();
        }
    }

    private updatePathLayer(map: MapType, featureType: InteractableFeatureType, layer?: LayerType, paths?: Path[] | undefined): void {
        if (layer && paths) {
            this.replaceAndRefreshFeatures(map, layer, paths.map((p) => FeatureFactory.createPath(p, featureType, this.#layerContext.resolution)), featureType, 'id');
        }
    }

    private updateTapSchrodingerLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.SchrodingerTAP) || !this.#layers.getSpecialLayerByName(KnownLayers.SchrodingerTAP).getVisible()) {
            return;
        }

        const tapLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.SchrodingerTAP);
        const { tapsSchrodinger, tethersSchrodinger, schrodingerBuilds, poles, manholes, handholes, vaults, cabinets } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults, ...cabinets];
        const tapFeatures = FeatureFactory.createTapSchrodingerFeatures(tapsSchrodinger, tethersSchrodinger, schrodingerBuilds, elements, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(tapLayer, tapFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, tapFeatures, InteractableFeatureType.TAP_SCHRODINGER, 'id');

        this.updateTapPowerLayer(map, tapsSchrodinger, tethersSchrodinger, elements);
        this.updateTapOpticalLayer(map, tapsSchrodinger, tethersSchrodinger, elements);
    }

    private updateTapPowerLayer(map: MapType, taps: TapSchrodinger[], tethers: TetherSchrodinger[], elements: Element[]): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.PowerTethers) || !this.#layers.getSpecialLayerByName(KnownLayers.PowerTethers).getVisible()) {
            return;
        }
        const powerTetherLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.PowerTethers);
        const powerTetherFeatures = FeatureFactory.createTapPowerFeatures(taps, tethers, elements, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(powerTetherLayer, powerTetherFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, powerTetherFeatures, InteractableFeatureType.POWER_SCHRODINGER, 'elementId');
    }

    private updateTapOpticalLayer(map: MapType, taps: TapSchrodinger[], tethers: TetherSchrodinger[], elements: Element[]): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.OpticalTethers) || !this.#layers.getSpecialLayerByName(KnownLayers.OpticalTethers).getVisible()) {
            return;
        }
        const opticalTetherLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.OpticalTethers);
        const opticalTetherFeatures = FeatureFactory.createTapOpticalFeatures(taps, tethers, elements, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(opticalTetherLayer, opticalTetherFeatures);
        OpenLayerFactory.refreshSelectedFeatureType(map, opticalTetherFeatures, InteractableFeatureType.OPTICAL_SCHRODINGER, 'elementId');
    }

    private updateShadowLayer(map: MapType): void {
        if (!this.#layers.hasSpecialLayer(KnownLayers.Shadow) || !this.#layers.getSpecialLayerByName(KnownLayers.Shadow).getVisible()) {
            return;
        }
        const shadowsLayer = this.#layers.getSpecialLayerByName<VectorLayer>(KnownLayers.Shadow);
        const { poles, manholes, handholes, vaults, cabinets, naps, trenches, bores } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults, ...cabinets];
        const paths: Path[] = [...trenches, ...bores];

        const { clickedNotification, tapsSchrodinger, tethersSchrodinger, segments } = this.#layerContext;
        const shadowFeatures: Feature<Geometry>[] = [];
        let elementId: number | undefined;
        let errorType: ErrorShadowType | undefined;
        const segmentsToHighlight: BuildSegment[] = [];
        const pathsToHighlight: Path[] = [];
        if (clickedNotification) {
            if (clickedNotification.itemType === ItemType.Nap) {
                elementId = naps.find(n => n.id === clickedNotification.itemId)?.elementId;
                errorType = ErrorShadowType.NAP;
            }
            if (clickedNotification.itemType === ItemType.SchrodingerTap) {
                elementId = tapsSchrodinger.find(t => t.id === clickedNotification.itemId)?.elementId;
                errorType = ErrorShadowType.TAP;
            }
            if (clickedNotification.itemType === ItemType.SchrodingerTether) {
                const tapId = tethersSchrodinger.find(t => t.id === clickedNotification.itemId)?.tapId;
                elementId = tapsSchrodinger.find(t => t.id === tapId)?.elementId;
                errorType = ErrorShadowType.TAP;
            }
            if (clickedNotification.itemType === ItemType.Segment) {
                const segment = segments.find(s => s.id === clickedNotification.itemId)
                const connectedPath = segment ? FeatureFactory.getSegmentPath(segment, paths) : undefined;
                if (connectedPath) {
                    pathsToHighlight.push(connectedPath);
                }
                else if (segment) {
                    segmentsToHighlight.push(segment);
                }
            }
            if (clickedNotification.itemType === ItemType.Build) {
                const buildSegments = segments.filter(s => s.buildId === clickedNotification.buildId);
                for (const segment of buildSegments) {
                    const connectedPath = FeatureFactory.getSegmentPath(segment, paths);
                    if (connectedPath) {
                        pathsToHighlight.push(connectedPath);
                    }
                    else {
                        segmentsToHighlight.push(segment);
                    }
                }
            }

        }

        const element = elements.find(e => e.id === elementId);
        if (element) {
            switch (errorType) {
                case ErrorShadowType.NAP:
                    shadowFeatures.push(FeatureFactory.createShadowForElement(element, StaticFeatureType.NAP_SHADOW, this.#layerContext.resolution, '')); break;
                case ErrorShadowType.TAP:
                    shadowFeatures.push(FeatureFactory.createShadowForElement(element, StaticFeatureType.TAP_SHADOW, this.#layerContext.resolution, '')); break;
                case ErrorShadowType.POLE:
                    shadowFeatures.push(FeatureFactory.createShadowForElement(element, StaticFeatureType.CIRCLE_SHADOW, this.#layerContext.resolution, '')); break;
                case ErrorShadowType.MANHOLE:
                case ErrorShadowType.HANDHOLE:
                    shadowFeatures.push(FeatureFactory.createShadowForElement(element, StaticFeatureType.HOLE_SHADOW, this.#layerContext.resolution, '')); break;
                default:
                    shadowFeatures.push(FeatureFactory.createShadowForElement(element, StaticFeatureType.CIRCLE_SHADOW, this.#layerContext.resolution, '')); break;
            }
        }
        for (const segment of segmentsToHighlight) {
            const from = elements.find((p) => p.id === segment.fromId);
            const to = elements.find((p) => p.id === segment.toId);
            if (!from || !to) continue;
            shadowFeatures.push(FeatureFactory.createShadowForSegment(segment, from, to, StaticFeatureType.CABLE_SHADOW, this.#layerContext.resolution, ''));
        }
        for (const path of pathsToHighlight) {
            const from = elements.find((p) => p.id === path.fromElementId);
            const to = elements.find((p) => p.id === path.toElementId);
            if (!from || !to) continue;
            shadowFeatures.push(FeatureFactory.createShadowForPath(path, StaticFeatureType.CABLE_SHADOW, this.#layerContext.resolution, {}));
        }
        LayerFactory.replaceFeatures(shadowsLayer, shadowFeatures);
        this.replaceAndRefreshFeatureTypes(map, shadowsLayer, shadowFeatures, [StaticFeatureType.CIRCLE_SHADOW, StaticFeatureType.CABLE_SHADOW], 'elementId');
    }

    private updateInfrastructureIdsLayer(resolution: number | undefined): void {
        if (!this.#layers.hasLayer(KnownLayers.InfrastructureIds) || !this.#layers.getLayerByName(KnownLayers.InfrastructureIds).getVisible() || !resolution) {
            return;
        }
        const { poles, manholes, handholes, vaults, naps } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults];
        const napElementIds = new Set(naps.map((n) => n.elementId));

        const infrastructureIdsLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.InfrastructureIds);
        const infrastructureIdFeatures = FeatureFactory.createInfrastructureIdFeatures(elements.filter((e) => !napElementIds.has(e.id)), resolution);
        LayerFactory.replaceFeatures(infrastructureIdsLayer, infrastructureIdFeatures);
    }

    private updateBuildCalloutsLayer(): void {
        if (!this.#layers.hasLayer(KnownLayers.BuildCallouts) || !this.#layers.getLayerByName(KnownLayers.BuildCallouts).getVisible()) {
            return;
        }

        const { poles, manholes, handholes, vaults, cabinets, flexNAPBuilds, schrodingerBuilds, bulkBuilds, segments, trenches, bores } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults, ...cabinets];
        const paths: Path[] = [...trenches, ...bores];

        const buildCalloutsLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.BuildCallouts);
        const buildCalloutFeatures = FeatureFactory.createBuildCalloutFeatures([...flexNAPBuilds, ...schrodingerBuilds, ...bulkBuilds], segments, elements, paths, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(buildCalloutsLayer, buildCalloutFeatures);
    }

    private updateCabinetCalloutsLayer(): void {
        if (!this.#layers.hasLayer(KnownLayers.CabinetCallouts) || !this.#layers.getLayerByName(KnownLayers.CabinetCallouts).getVisible()) {
            return;
        }
        const { cabinets } = this.#layerContext;

        const cabinetCalloutsLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.CabinetCallouts);
        const cabinetCalloutFeatures = FeatureFactory.createCabinetCalloutFeatures(cabinets, this.#layerContext.resolution);
        LayerFactory.replaceFeatures(cabinetCalloutsLayer, cabinetCalloutFeatures);
    }

    private updateBuildIdsLayer(resolution: number | undefined): void {
        if (!this.#layers.hasLayer(KnownLayers.BuildIds) || !resolution || !this.#layers.getLayerByName(KnownLayers.BuildIds).getVisible()) {
            return;
        }
        const { poles, manholes, handholes, vaults, cabinets, segments, flexNAPBuilds, schrodingerBuilds, bulkBuilds } = this.#layerContext;
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults, ...cabinets];
        const allBuilds = [...flexNAPBuilds, ...schrodingerBuilds, ...bulkBuilds];

        const buildIdsLayer = this.#layers.getLayerByName<VectorLayer>(KnownLayers.BuildIds);
        const buildIdsFeatures = FeatureFactory.createBuildIdsFeatures(segments, elements, allBuilds, resolution);
        LayerFactory.replaceFeatures(buildIdsLayer, buildIdsFeatures);
    }

    private createPathFeatures<T extends Path>(paths: PathData[], createdDate: Date, type: PathType, featureType: InteractableFeatureType): Array<Feature<Geometry>> {
        const fakePaths = paths.map((p) => ({ route: p.route, groupId: -1, userId: -1, id: -1, createdDate, isImported: true, type })) as T[];
        return fakePaths.map((p) => FeatureFactory.createPath(p, featureType, this.#layerContext.resolution));
    }

    private replaceAndRefreshFeatures(
        map: MapType,
        layer: LayerType,
        features: Array<Feature<Geometry>>,
        featureType: InteractableFeatureType,
        idKey: string,
        clear = true): void {

        this.replaceAndRefreshFeatureTypes(map, layer, features, [featureType], idKey, clear);
    }

    private replaceAndRefreshFeatureTypes(
        map: MapType,
        layer: LayerType,
        features: Array<Feature<Geometry>>,
        featureTypes: FeatureType[],
        idKey: string,
        clear = true): void {

        LayerFactory.replaceFeatures(layer, features, clear);
        const filteredFeatures = features.filter((f) => f.get('type') in InteractableFeatureType);
        if (map) {
            OpenLayerFactory.refreshSelectedFeatures(map, filteredFeatures, featureTypes, idKey);
        }
    }

    private sortBuildsByLastModified(currentBuild: Build, nextBuild: Build): number {
        if (currentBuild.modifiedById === nextBuild.modifiedById) {
            return currentBuild.lastModified > nextBuild.lastModified ? 1 : -1;
        }

        if (!currentBuild.modifiedById) {
            return -1;
        }
        if (!nextBuild.modifiedById) {
            return 1;
        }

        return 0;
    }
}