import './dna-switch.scss';

import React, { Component } from 'react';

import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material';

interface ISwitchProps {
    id: any;
    label: string;
    checked: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<any>) => void;
}

export class DNASwitch extends Component<ISwitchProps> {

    public render() {
        const { id, label, checked, disabled, onChange } = this.props;
        return (
            <div className="switch-container">
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row={true}>
                        <FormControlLabel
                            value="end"
                            control={<Switch id={id} color="primary" checked={checked} disabled={disabled} onChange={onChange} />}
                            label={label}
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </div>
        );
    }
}
