import { BuildSplice } from "../../models/build-splice";
import { SecuredService } from "../abstract-secured-v2.service";

export class BulkSpliceService extends SecuredService {
    private readonly baseUrl = `${this.rootUrl}api/buildsplice`;

    public connectBuildToSplicePoint(buildId: number, splicePointId: number): Promise<BuildSplice | undefined> {
        return this.post(`${this.baseUrl}/connect`, { buildId, splicePointId });
    }

    public disconnectBuildFromSplicePoint(buildId: number): Promise<void> {
        return this.post(`${this.baseUrl}/disconnect`, { buildId });
    }
}