import i18next from 'i18next';
import React, { Component } from 'react';

import { SelectChangeEvent, Tooltip } from '@mui/material';
import { OutlinedButton, TextButton } from '@orbit/button';

import { UnitInterpreter } from '../../helpers/unit-interpreter';
import { LocalizationKeys } from '../../locales/types';
import { CpqInstallationType } from '../../models/cpqinstallationtype';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { EditText } from '../ui-elements/edit-text';
import { SelectWithHelp } from '../ui-elements/select-with-help/select-with-help';
import { PresetDefinition } from './preset-definition';

export interface CableValues {
    coSlack: number;
    fieldSlack: number;
    slackUnit: Units;
    fiberCount: number;
    tetherFiberCount: number;
    installationType: CpqInstallationType;
}

export interface FiberCountData {
    coSlack?: number;
    fieldSlack?: number;
    slackUnit: Units;
    fiberCount?: number;
    tetherFiberCount?: number;
    fiberCounts: number[];
    tetherFiberCounts: number[];
    installationType: CpqInstallationType;
}

interface FiberCountProps {
    data: FiberCountData;
    displayUnits: Units;
    currentStepIndex: number;
    previousLabel?: string;
    onPreviousClicked?: () => void;
    onNextClicked?: (cableValues: CableValues) => void;
}

interface FiberCountState {
    coSlack: number;
    fieldSlack: number;
    fiberCount: number;
    tetherFiberCount: number;
}

export class FiberCountComponent extends Component<FiberCountProps, FiberCountState> {

    constructor(props: FiberCountProps) {
        super(props);
        this.state = this.initState(props.data, props.displayUnits);
    }

    public componentDidUpdate(prevProps: FiberCountProps): void {
        const { data, displayUnits } = this.props;
        if (data !== prevProps.data) {
            const state = this.initState(data, displayUnits);
            this.setState({ ...state });
        }
    }

    private initState = (data: FiberCountData, units: Units): FiberCountState => {
        const it = data.installationType;
        const minCoSlack = new DataValue(data.coSlack ?? it.cO_Slack_Minimum, data.coSlack ? data.slackUnit : Units.feet).toUnit(units);
        const minFieldSlack = new DataValue(data.fieldSlack ?? it.field_Slack_Minimum, data.fieldSlack ? data.slackUnit : Units.feet).toUnit(units);
        return {
            coSlack: +minCoSlack.value.toFixed(3),
            fieldSlack: +minFieldSlack.value.toFixed(3),
            fiberCount: data.fiberCount ?? data.fiberCounts[0],
            tetherFiberCount: data.tetherFiberCount ?? data.tetherFiberCounts[0],
        };
    }

    private handleInputChange = (event: SelectChangeEvent<any>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name && value) {
            this.setState({ [name]: value } as any as FiberCountState);
        }
    }

    private onNextClicked = (): void => {
        if (this.props.onNextClicked) {
            const { coSlack, fieldSlack, fiberCount, tetherFiberCount } = this.state;
            const { data, displayUnits } = this.props;
            const cableValues: CableValues = { coSlack, fieldSlack, fiberCount: +fiberCount, tetherFiberCount: +tetherFiberCount, slackUnit: displayUnits!, installationType: data.installationType };
            this.props.onNextClicked(cableValues);
        }
    }

    private helpIcon = require('./icon_help.svg');

    private renderHelpIcon = (helpText?: string): JSX.Element => {
        return (
            <Tooltip className="tooltip" title={<span className="caption">{helpText}</span>} placement="left">
                <button className="icon-button">
                    <img alt="Help" src={this.helpIcon} />
                </button>
            </Tooltip>
        );
    }

    public render(): JSX.Element {
        const { data, currentStepIndex, previousLabel, onPreviousClicked, displayUnits } = this.props;
        const { coSlack, fieldSlack, fiberCount, tetherFiberCount } = this.state;
        const cableLabel = `${currentStepIndex + 1}. Cable default values:`;
        const tetherLabel = `${currentStepIndex + 1}.1 Tether default value:`;

        const it = data.installationType;

        const minCoSlack = new DataValue(it ? it.cO_Slack_Minimum : 50, Units.feet).toUnit(displayUnits);
        const minFieldSlack = new DataValue(it ? it.field_Slack_Minimum : 15, Units.feet).toUnit(displayUnits);
        const coUnitShort = UnitInterpreter.toShort(minCoSlack.units);
        const fieldUnitShort = UnitInterpreter.toShort(minFieldSlack.units);

        const coSlackLabel = `CO Slack (${coUnitShort})`;
        const fieldSlackLabel = `Field Slack (${fieldUnitShort})`;

        const coSlackHelperText = `${minCoSlack.format(minCoSlack.units, 3)} is the minimal value`;
        const fieldSlackHelperText = `${minFieldSlack.format(minFieldSlack.units, 3)} is the minimal value`;
        const isValid = coSlack >= minCoSlack.value
            && fieldSlack >= minFieldSlack.value
            && data.fiberCounts.length > 0
            && data.tetherFiberCounts.length > 0;
        const previousIcon = <img alt="Previous" src={require('./icon_chevron_left.svg')} />;
        const coSlackHelp = PresetDefinition.help['CO Slack'];
        const fieldSlackHelp = PresetDefinition.help['Field Slack'];
        const fiberCountHelp = PresetDefinition.help['Cable Fiber Count'];
        const tetherFiberCountHelp = PresetDefinition.help['Tether Fiber Count'];
        return (
            <div className="fiber-count-container">
                <div className="cable-values">
                    <span>{cableLabel}</span>
                    <EditText
                        label={coSlackLabel}
                        value={coSlack}
                        type="number"
                        name="coSlack"
                        min={minCoSlack.value}
                        helperText={coSlackHelperText}
                        onChange={this.handleInputChange}
                        trailingIcon={this.renderHelpIcon(coSlackHelp)}
                    />
                    <EditText
                        label={fieldSlackLabel}
                        value={fieldSlack}
                        type="number"
                        name="fieldSlack"
                        min={minFieldSlack.value}
                        helperText={fieldSlackHelperText}
                        onChange={this.handleInputChange}
                        trailingIcon={this.renderHelpIcon(fieldSlackHelp)}
                    />
                    <SelectWithHelp
                        label={i18next.t(LocalizationKeys.FiberCount)}
                        name='fiberCount'
                        value={fiberCount}
                        values={data.fiberCounts}
                        onChange={this.handleInputChange}
                        helpText={fiberCountHelp} />
                </div>
                <div className="tether-values">
                    <span>{tetherLabel}</span>
                    <SelectWithHelp
                        label={i18next.t(LocalizationKeys.FiberCount)}
                        name='tetherFiberCount'
                        value={tetherFiberCount}
                        values={data.tetherFiberCounts}
                        onChange={this.handleInputChange}
                        helpText={tetherFiberCountHelp} />
                </div>
                <div className="navigation-container">
                    <TextButton startIcon={previousIcon} onClick={onPreviousClicked}>{previousLabel || 'Previous'}</TextButton>
                    <OutlinedButton onClick={this.onNextClicked} disabled={!isValid}>Next</OutlinedButton>
                </div>
            </div>
        );
    }
}
