import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { Tap } from '../../../models/schrodinger/tap';
import { deleteTether, undoDeleteTether } from '../../../redux/schrodinger/tap.state';
import { Command } from '../../command';

interface CreateTetherCommandPayload {
    id: number;
    tap: Tap
}

export class CreateTetherCommand extends Command {
    private id: number;
    private tap: Tap;

    constructor({ id, tap }: CreateTetherCommandPayload) {
        super();
        this.id = id;
        this.tap = tap;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoTetherCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoTetherCreation };
    }

    public undo(dispatch: Dispatch): void {
        deleteTether(this.id, this.tap, true)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        undoDeleteTether(this.id, this.tap)(dispatch);
        super.redo(dispatch);
    }
}