import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import { PointLike } from '../models/pointlike';
import { Pole, PoleData } from '../models/pole';
import { PoleSpan, PoleSpanData } from '../models/polespan';
import { GeoserverService } from '../services/geoserver.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { updateSchrodingerBuildLocationIds } from './element.actions';
import { push } from './history.state';
import { IMPORT_LOAD_THRESHOLD } from './import.state';

export interface PoleState {
    poles: Pole[];
    poleSpans: PoleSpan[];
}
const initialState: PoleState = {
    poles: [],
    poleSpans: [],
};

const addPoleReducer = (state: PoleState, action: PayloadAction<Pole>) => { state.poles.push(action.payload); };
const loadPolesReducer = (state: PoleState, action: PayloadAction<Pole[] | undefined>) => { state.poles = action.payload || []; };
const loadPoleSpansReducer = (state: PoleState, action: PayloadAction<PoleSpan[] | undefined>) => { state.poleSpans = action.payload || []; };
const deletePoleReducer = (state: PoleState, action: PayloadAction<number>) => {
    state.poles = state.poles.filter((p) => p.id !== action.payload);
};

const deleteImportedPolesReducer = (state: PoleState, action: PayloadAction<number>) => {
    state.poles = state.poles.filter((p) => p.importedGisDataId !== action.payload);
};

const deleteImportedPoleSpansReducer = (state: PoleState, action: PayloadAction<number>) => {
    state.poleSpans = state.poleSpans.filter((p) => p.importedGisDataId !== action.payload);
};

const updatePoleReducer = (state: PoleState, action: PayloadAction<Pole>) => {
    const pole: Pole = action.payload;
    const mIdx = state.poles.findIndex((p) => p.id === pole.id);
    if (mIdx < 0) {
        state.poles.push(pole);
    }
    else {
        state.poles[mIdx] = pole;
    }
};

const { actions, reducer } = createSlice({
    name: 'pole',
    initialState,
    reducers: {
        addPole: addPoleReducer,
        loadPoles: loadPolesReducer,
        loadPoleSpans: loadPoleSpansReducer,
        deletePole: deletePoleReducer,
        updatePole: updatePoleReducer,
        deleteImportedPoles: deleteImportedPolesReducer,
        deleteImportedPoleSpans: deleteImportedPoleSpansReducer,
    },
});
export { reducer as PoleReducer };
export const { loadPoleSpans, loadPoles, addPole: addPoleAction, deletePole, deleteImportedPoles, deleteImportedPoleSpans, updatePole: modifyPole } = actions;

export const getPoles = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new GeoserverService();
    const poles = await service.getPolesIn(bbox, required);
    if (poles) {
        dispatch(actions.loadPoles(poles));
    }
};

export const getPolesByIds = (ids: number[]) => async dispatch => {
    const service = new GeoserverService();
    const poles = await service.getPolesByIds(ids);
    if (poles) {
        poles.forEach(pole => dispatch(actions.updatePole(pole)));
    }
};

export const getPoleSpans = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new GeoserverService();
    const poleSpans = await service.getPoleSpansIn(bbox, required);
    if (poleSpans) {
        dispatch(actions.loadPoleSpans(poleSpans));
    }
};

export const addPole = (point: PointLike) => async dispatch => {
    const service = new GeoserverService();
    const pole = await service.addPole(point);
    if (pole) {
        dispatch(actions.addPole(pole));
        dispatch(push({ type: CommandType.CreateElement, payload: { id: pole.id, type: pole.type } }));
    }
};

export const addPoles = (poleData: PoleData[], importedGisId?: number, bbox?: number[]) => async dispatch => {
    const service = new GeoserverService();
    const success = await requestIsSuccess(service, service.importPoles(poleData, importedGisId), true);
    if (poleData.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
        getPoles(bbox)(dispatch);
    }
};

export const addPoleSpans = (poleData: PoleData[], poleSpanData: PoleSpanData[], importedGisId?: number, bbox?: number[]) => async dispatch => {
    const service = new GeoserverService();
    const success = await requestIsSuccess(service, service.addPoleSpans({ poles: poleData, poleSpans: poleSpanData, importedGisDataId: importedGisId }), true);
    if (poleSpanData.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
        getPoles(bbox)(dispatch);
        getPoleSpans(bbox)(dispatch);
    }
};

export const updatePole = (oldPole: Pole, newPole: Pole) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new GeoserverService();
        const pole = await service.patchPole(oldPole, newPole);
        if (pole) {
            dispatch(actions.updatePole(pole));
            if (oldPole.tagOverride !== pole.tagOverride) {
                updateSchrodingerBuildLocationIds(pole);
            }
        }
    });
};
