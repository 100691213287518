import { HybrisAccount } from '../models/hybris-account';
import { HybrisCustomer } from '../models/hybris-customer';
import { Units } from '../models/units';

export class CustomerStorageService {
    private static SS_HYBRIS_CUSTOMER_KEY = 'hybris_customer';
    public static storeHybrisCustomerInfo = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hybrisInfo = (window as any).hybriscustomer;
        if (hybrisInfo) {
            const account: HybrisAccount = { uid: hybrisInfo.accountUid, name: hybrisInfo.accountName, flexnapSpecificId: hybrisInfo.flexnapSpecificId, soldTo: hybrisInfo.soldTo, unitOfMeasure: hybrisInfo.unitOfMeasure === "FEET" || hybrisInfo.unitOfMeasure === "FOT" ? Units.feet : Units.meters };
            const customer: HybrisCustomer = { uid: hybrisInfo.customerUid, name: hybrisInfo.customerName, email: hybrisInfo.customerEmail, account };
            window.sessionStorage.setItem(CustomerStorageService.SS_HYBRIS_CUSTOMER_KEY, JSON.stringify(customer));
        }
    }

    public static getHybrisCustomer = (): HybrisCustomer | undefined => {
        const hybrisCustomer = window.sessionStorage.getItem(CustomerStorageService.SS_HYBRIS_CUSTOMER_KEY);
        if (!hybrisCustomer) return undefined;
        return JSON.parse(hybrisCustomer);
    }
}
