import i18next from 'i18next';

import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ItemType } from "../item-type";
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from "../validation-result-type";
import { LocalizationKeys } from '../../locales/types';
import { FlexNapSplicePlan } from '../../models/flexnap-splice-plan';
import { Validator } from './validator';


export class FiberCountValidator implements Validator<FlexNapBuildData> {

    private flexNapSplicePlans: {[buildId: string]: FlexNapSplicePlan};

    constructor(splicePlans: {[buildId: string]: FlexNapSplicePlan}){
        this.flexNapSplicePlans = splicePlans;
    }

    public validate(build: FlexNapBuildData): ValidationResult | null {
        const fiberCount = build.fiberCount;
        const buildSplicePlan = this.flexNapSplicePlans[build.id];
        if (buildSplicePlan && fiberCount > buildSplicePlan.startingPortNumber) {
            const extent = build.getExtent();
            return { 
                buildId: build.id,
                itemId: null,
                itemType: ItemType.Build,
                shortDescription: i18next.t(LocalizationKeys.FiberCountStartingPortWarningShort),
                longDescription:  i18next.t(LocalizationKeys.FiberCountStartingPortWarningLong),
                type: ValidationResultType.Warning, 
                extent 
            };
        }
        return null;
    }

    public canValidate(entity: any): boolean {
        return entity instanceof FlexNapBuildData;
    }
}