import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogType, showDialog } from '../../../redux/overlay.state';
import { selectBuild, selectTapSchrodinger } from '../../../redux/selection.state';
import { displayedBuildSelector, displayedBuildAwaitingSessionUpdateSelector } from '../../../selectors/build.selectors';
import { segmentsSelector, selectedSegmentIdSelector } from '../../../selectors/root.selectors';
import { IFrameProps } from '../build/build.types';

export const useSegmentToolbar = ({ position }: IFrameProps) => {

    const segments = useSelector(segmentsSelector);
    const selectedSegmentId = useSelector(selectedSegmentIdSelector);

    const build = useSelector(displayedBuildSelector);
    const isBuildLocked = !!build?.lockedById;

    const awaitingSessionUpdate = useSelector(displayedBuildAwaitingSessionUpdateSelector);

    const dispatch = useDispatch();

    const selectedSegment = segments.find(({ id }) => id === selectedSegmentId);
    const otherSegmentsOnBuild = segments.some(s => s.buildId === selectedSegment?.buildId && s.id !== selectedSegment?.id);

    const handlePropertiesClick = useCallback(() => {
        selectedSegment && dispatch(selectBuild(selectedSegment.buildId));
        dispatch(selectTapSchrodinger())
    }, [dispatch, selectedSegment]);

    const handleAddClick = useCallback(() => {
        dispatch(showDialog(DialogType.CanvasAddSegment));
    }, [dispatch]);

    const handleDeleteClick = useCallback(() => {
        dispatch(showDialog(DialogType.CanvasDeleteSegment));
    }, [dispatch]);

    return {
        position,
        editDisabled: isBuildLocked || awaitingSessionUpdate,
        addDisabled: isBuildLocked || awaitingSessionUpdate,
        deleteDisabled: !otherSegmentsOnBuild || isBuildLocked || awaitingSessionUpdate,
        handlePropertiesClick, handleAddClick, handleDeleteClick
    }
};
