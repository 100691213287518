import { Review } from '../models/review';
import { ReviewStatus } from '../models/review-status';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { SecuredService } from './abstract-secured-v2.service';

export class ReviewService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/review';

    public startReview(request: StartReviewRequest): Promise<BuildReviewResponse | undefined> {
        return this.post(this.baseUrl, request);
    }

    public finishReview(reviewId: number, request: FinishReviewRequest): Promise<BuildReviewResponse | undefined> {
        return this.post(`${this.baseUrl}/${reviewId}`, request);
    }

    public archiveReview(reviewId: number): Promise<Review | undefined> {
        return this.post(`${this.baseUrl}/${reviewId}/archive`);
    }

    public dismissReviews(reviewIds: number[]): Promise<Review[] | undefined> {
        return this.post(`${this.baseUrl}/dismiss`, { reviewIds });
    }

    public getReviewsInUserGroup(): Promise<Review[] | undefined> {
        return this.get(this.baseUrl);
    }
}

export interface StartReviewRequest {
    buildId: number;
    notes?: string;
}

export interface FinishReviewRequest {
    status: ReviewStatus;
    notes?: string;
}

export interface BuildReviewResponse {
    build: SchrodingerBuild;
    review: Review;
}
