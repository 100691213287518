import { MapClickEvent } from '../models/map-click-event';
import { store } from '../dna';
import { addCabinet } from '../redux/cabinet.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class CabinetTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Cabinet,
        name: 'Cabinet',
        shortcut: 'K',
    };

    public static execute(event: MapClickEvent): void {
        addCabinet(event.x, event.y)(store.dispatch);
    }
}
