import './no-schrodinger-preset-warning.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { DesignMode } from '../../../models/design-mode';
import { DialogComponent } from '../dialog.component';
import { useNoPresetWarningDialog } from './no-preset-warning.hooks';

export const NoPresetWarningDialog = (): JSX.Element => {
    const { t } = useTranslation();
    const { open, designMode, buildType, onPresetOpen, onNegativeClick, onClose } = useNoPresetWarningDialog();

    return (
        <DialogComponent
            open={open}
            title={t(LocalizationKeys.MissingPreset)}
            message={buildType === BuildType.Schrodinger ? t(LocalizationKeys.MissingSchrodingerPresetDescription) : t(LocalizationKeys.MissingPresetDescription)}
            positiveTitle={t(LocalizationKeys.CreatePreset)}
            negativeTitle={designMode === DesignMode.CanvasMode ? t(LocalizationKeys.BackToGisMode) : t(LocalizationKeys.Close)}
            onClose={onClose}
            onNegativeClick={onNegativeClick}
            onPositiveClick={onPresetOpen}
        />
    );
};
