import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { DataValue } from '../../../../models/datavalue';
import { ConfigurationTypes } from '../../../../models/schrodinger/configuration-type';
import { applySchrodingerBuildPreset, deleteSchrodingerCable } from '../../../../redux/build.state';
import {
    authenticationSelector, tapsSchrodingerSelector, workspaceSelector
} from '../../../../selectors/root.selectors';
import { presetsSelector } from '../../../../selectors/schrodinger/presets.selector';
import { SelectObjectProps } from '../../../ui-elements/select-object';
import { SchrodingerCableProps } from './schrodinger-cable-card.types';

export const useSchrodingerCableComponent = (props: SchrodingerCableProps) => {

    const { t } = useTranslation();

    const { build, subunitCount, collapsed, disabled } = props;

    const { displayUnits } = useSelector(authenticationSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const taps = useSelector(tapsSchrodingerSelector);
    
    const workspaceId = currentWorkspace?.id;
    const { partNumber, cableLength, cableLengthUnit } = build;
    const length = new DataValue(cableLength, cableLengthUnit);
    const configurationType = ConfigurationTypes.find(({ value }) => value === build.configurationType)?.display;

    const dispatch = useDispatch();

    const presets = useSelector(presetsSelector);

    const handlePresetChange = useCallback((value: any) => {
        const presetId = +value;
        applySchrodingerBuildPreset(build.id, presetId)(dispatch);
    }, [build, dispatch]);

    const onDeleteCableClick = useCallback((): void => {
        if (!build) {
            throw new Error('SchrodingerCableCard.onDeleteCableClick, deleting non-existent cable');
        }
        if (!workspaceId) {
            throw new Error('Workspace not found, cannot delete cable.');
        }
        const buildTaps = taps.filter(({ buildId }) => buildId === build.id);
        deleteSchrodingerCable(build.id, workspaceId, buildTaps)(dispatch);
    }, [build, dispatch, taps, workspaceId]);

    const presetsOptions: SelectObjectProps = {
        label: t(LocalizationKeys.Preset),
        name: 'cablePreset',
        disabled: disabled,
        value: '',
        values: presets ?? [],
        valueProp: 'id',
        displayProp: 'name',
        emptyChoice: build.presetDescription,
        onChange: handlePresetChange,
    };

    return { partNumber, length, displayUnits, build, subunitCount, collapsed, disabled, presetsOptions, configurationType, onDeleteCableClick };
}