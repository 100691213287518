import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BuildType } from '../models/build-type';
import { CpqBuildConfiguration } from '../models/cpqbuildconfiguration';
import { CpqCablePart } from '../models/cpqcablepart';
import { CpqCountryState } from '../models/cpqcountrystate';
import { CpqInstallationType } from '../models/cpqinstallationtype';
import { CpqMultiportPart } from '../models/cpqmultiportpart';
import { CpqTetherPart } from '../models/cpqtetherpart';
import { CpqService } from '../services/cpq.service';
import { createSecuredAsyncAction } from './action';

export interface CpqState {
    cableParts: CpqCablePart[];
    tetherParts: CpqTetherPart[];
    configurations: CpqBuildConfiguration[];
    installationTypes: CpqInstallationType[];
    countryStates: CpqCountryState[];
    multiportParts: CpqMultiportPart[];
}

const initialState: CpqState = {
    cableParts: [],
    tetherParts: [],
    configurations: [],
    installationTypes: [],
    countryStates: [],
    multiportParts: []
};

const loadPartsReducer = (state: CpqState, action: PayloadAction<CpqCablePart[]>) => { state.cableParts = action.payload; };
const loadTetherPartsReducer = (state: CpqState, action: PayloadAction<CpqTetherPart[]>) => { state.tetherParts = action.payload; };
const loadConfigurationsReducer = (state: CpqState, action: PayloadAction<CpqBuildConfiguration[]>) => { state.configurations = action.payload; };
const loadInstallationTypesReducer = (state: CpqState, action: PayloadAction<CpqInstallationType[]>) => { state.installationTypes = action.payload; };
const loadCountryStatesReducer = (state: CpqState, action: PayloadAction<CpqCountryState[]>) => { state.countryStates = action.payload; };
const loadMultiportPartsReducer = (state: CpqState, action: PayloadAction<CpqMultiportPart[]>) => { state.multiportParts = action.payload; };

const { actions, reducer } = createSlice({
    name: 'preset',
    initialState,
    reducers: {
        loadParts: loadPartsReducer,
        loadTetherParts: loadTetherPartsReducer,
        loadConfigurations: loadConfigurationsReducer,
        loadInstallationTypes: loadInstallationTypesReducer,
        loadCountryStates: loadCountryStatesReducer,
        loadMultiportParts: loadMultiportPartsReducer
    },
});

export { reducer as CpqReducer };

export const loadCableParts = (soldTo?: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CpqService();
        const parts = await service.getCableParts(soldTo);
        if (parts) {
            dispatch(actions.loadParts(parts));
        }
    });
};

export const loadTetherParts = (soldTo?: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CpqService();
        const parts = await service.getTetherParts(soldTo);
        if (parts) {
            dispatch(actions.loadTetherParts(parts));
        }
    });
};

export const loadConfigurations = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CpqService();
        const configurations = await service.getConfigurations();
        if (configurations) {
            dispatch(actions.loadConfigurations(configurations));
        }
    });
};

export const loadInstallationTypes = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CpqService();
        const installationTypes = await service.getInstallationTypes();
        if (installationTypes) {
            dispatch(actions.loadInstallationTypes(installationTypes));
        }
    });
};

export const loadCountryStates = (buildType: BuildType) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CpqService();
        const countryStates = await service.getCountryStates(buildType);
        if (countryStates) {
            const sortedCountries = countryStates.sort((a, b) => a.country.toLocaleLowerCase() > b.country.toLocaleLowerCase() ? 1 : -1);
            dispatch(actions.loadCountryStates(sortedCountries));
        }
    });
};

export const loadMultiportParts = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CpqService();
        const multiportParts = await service.getMultiportParts();
        if (multiportParts) {
            dispatch(actions.loadMultiportParts(multiportParts));
        }
    });
};
