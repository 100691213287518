import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../../locales/types';
import { PresetComponent } from '../../preset.component';
import { PresetWizard } from '../preset-wizard/preset-wizard';
import { usePresetConfig } from './preset-config.hooks';
import { PresetContext } from './preset-config.types';
import { CardInputWithCount } from '../../../card/card-controls';
import { CardInputMaxLength } from '../../../card/card.types';

export const PresetConfig = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        presetName, handlePresetNameChange, isPresetNameValid,
        session, updateSession, loading, undoCstics, savePreset
    } = usePresetConfig();

    return (
        <PresetContext.Provider value={{
            session, presetName, updateSession, loading, undoCstics, savePreset
        }}>
            <PresetComponent title={t(LocalizationKeys.NewCablePreset)}>
                {CardInputWithCount({
                    error: !isPresetNameValid,
                    helperText: isPresetNameValid ? undefined : t(LocalizationKeys.PresetNeedsAName),
                    label: t(LocalizationKeys.PresetName),
                    value: presetName,
                    name: 'presetName',
                    maxLength: CardInputMaxLength.PRESET_NAME,
                    onChange: handlePresetNameChange,
                })}
                <PresetWizard />
            </PresetComponent>
        </PresetContext.Provider>
    );
};
