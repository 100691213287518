import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../../dialogs/dialog.component';

export const DeleteElementDialog = (props: DeleteElementProps): JSX.Element | null => {
    const { t } = useTranslation();

    const rootElement = document.getElementById('react-app-root');

    return !props.open || !rootElement ? null :
        createPortal(
            <DialogComponent
                open={props.open}
                title={t(LocalizationKeys.DeleteElementTitle, { elementId: props.elementId })}
                message={t(LocalizationKeys.DeleteElementConfirmation)}
                positiveTitle={t(LocalizationKeys.Delete)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={props.onDelete}
                onNegativeClick={props.onClose}
                negativeStyleButton
            />,
            rootElement
        );
};

export interface DeleteElementProps {
    open: boolean;
    elementId: number;
    onDelete: () => void;
    onClose: () => void;
}