import { useSelector, useDispatch } from "react-redux";
import { hybrisCustomerSelector, userIdSelector } from "../../selectors/root.selectors";
import { useEffect } from "react";
import { loadUserId } from "../../redux/authentication.state";

export const UserManager = () => {
    const hybrisCustomer = useSelector(hybrisCustomerSelector);
    const userId = useSelector(userIdSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (hybrisCustomer && !userId) {
            loadUserId()(dispatch);
        }
    }, [hybrisCustomer, userId, dispatch]);

    return null;
}