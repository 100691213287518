import { ConfigSession } from '../models/config-session';
import { SchrodingerPreset } from '../models/schrodinger-preset';
import { SecuredService } from './abstract-secured-v2.service';

export interface UpdatePresetRequest {
    isDefault: boolean;
    coSlack: number;
    fieldSlack: number;
    presetName: string;
}

export class SchrodingerPresetService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/schrodingerpreset';

    public getAll(): Promise<SchrodingerPreset[] | undefined> {
        return this.get(this.baseUrl);
    }

    public startPreset(): Promise<ConfigSession | undefined> {
        return this.post(`${this.baseUrl}/new`);
    }

    public next(sessionId: string, instanceId: string, csticId: string, valueId: string): Promise<ConfigSession | undefined> {
        return this.post(`${this.baseUrl}/next`, { sessionId, instanceId, csticId, valueId });
    }

    public addPreset(sessionId: string, name: string, isDefault: boolean): Promise<SchrodingerPreset | undefined> {
        return this.post(this.baseUrl, {sessionId, name, isDefault });
    }

    public deletePreset(id: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${id}`);
    }

    public applyPresetToSession(sessionId: string, presetId: number): Promise<ConfigSession | undefined> {
        return this.post(`${this.baseUrl}/${presetId}/session`, { sessionId });
    }

    public updatePreset(presetId: number, request: UpdatePresetRequest): Promise<SchrodingerPreset | undefined>{
        return this.post(`${this.baseUrl}/${presetId}`, request);
    }
}