import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../../dialogs/dialog.component';
import { FlexNapCableDeleteProps } from './flexnap.types';

export const FlexNapCableDeleteDialog = (props: FlexNapCableDeleteProps): JSX.Element | null => {
    const { t } = useTranslation();

    const rootElement = document.getElementById('react-app-root');

    return !props.open || !rootElement ? null :
        createPortal(
        <DialogComponent
            open={props.open}
            title={t(LocalizationKeys.DeleteCable, { buildId: props.buildId })}
            positiveTitle={t(LocalizationKeys.Delete)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={props.onPositiveClick}
            onNegativeClick={props.onClose}
            negativeStyleButton
        />,
        rootElement
        );
};
