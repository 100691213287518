import './drawer-workspace-subheader.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@mui/material';
import { ContainedButton } from '@orbit/button';

import { LocalizationKeys } from '../../../../locales/types';
import { WorkspaceInfoDialog } from '../../../dialogs/workspace/workspace-information-dialog';
import { WorkspaceDrawerFilter } from '../filter/drawer-workspace-filter';
import { useWorkspaceDrawerSubheader } from './drawer-workspace-subheader.hooks';

export const WorkspaceDrawerSubHeader = () => {
    const { t } = useTranslation();
    const { title, selectedWorkspaceId, onNewWorkspaceClick } = useWorkspaceDrawerSubheader();

    return (
        <div className="workspace-drawer-subheader">
            <div className="workspace-drawer-subheader-title-container">
                <div className="workspace-drawer-subheader-nb-workspaces">
                    {title}
                </div>
                <div>
                    <ContainedButton className="workspace-drawer-subheader-new-workspace" onClick={onNewWorkspaceClick}>
                        {t(LocalizationKeys.NewWorkspace)}
                    </ContainedButton>
                </div>
            </div>
            <WorkspaceDrawerFilter />
            {selectedWorkspaceId && <WorkspaceInfoDialog workspaceId={selectedWorkspaceId} />}
            <Divider />
        </div>
    );
}