import './popup-menu.scss';

import React, { Component } from 'react';

interface PopupMenuProps {
    items: PopupMenuItem[];
    visible?: boolean;
    onMenuItemSelected: (id: string) => void;
}

export class PopupMenu extends Component<PopupMenuProps> {

    private handleItemClick = (e: React.MouseEvent<any>): void => {
        const id = e.currentTarget.id;
        if (!id) {
            throw new Error('PopupMenu.handleItemClick, no id set for list item');
        }
        this.props.onMenuItemSelected!(id);
    }

    private renderItem = (item: string, id: string, icon?: React.ReactNode, divider = false): JSX.Element => {
        let className = "menu-item";
        if (divider) {
            className += " item-divider";
        }
        return (
            <li key={id} id={id} className={className} onClick={this.handleItemClick}>
                <div className="menu-item-icon">{icon}</div><span>{item}</span>
            </li>
        );
    }

    private renderList = (): JSX.Element => {
        return (
            <ul>
                {this.props.items.map((i) => this.renderItem(i.label, i.id, i.icon, i.divider))}
            </ul>
        );
    }

    public render(): JSX.Element | null {
        if (this.props.visible === false) {
            return null;
        }
        return (
            <div className="popup-menu">
                {this.renderList()}
            </div>
        );
    }
}

export interface PopupMenuItem {
    id: string;
    label: string;
    icon?: React.ReactNode;
    divider?: boolean;
}
