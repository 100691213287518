import { Dispatch } from 'redux';

import { SegmentModificationResult } from '../../design-tools/base-cable-tool';
import { store } from '../../dna';
import { UndoRedoLocalizationKeys } from '../../locales/types';
import { BuildSegment } from '../../models/build-segment';
import { BuildType } from '../../models/build-type';
import { FlexNapBuild } from '../../models/flexnap-build';
import {
    deleteSegment, patchSegments, revertPretermLateral, undoDeleteSegment, undoRevertPretermLateral
} from '../../redux/build.state';
import {
    linkSplicePointToBuild, unlinkSplicePointFromBuild
} from '../../redux/bulk/splice-point.state';
import { linkTapsToBuild, unlinkTapFromBuild } from '../../redux/schrodinger/tap.state';
import { linkNapToBuild, unlinkNapFromBuild } from '../../redux/tap.state';
import { PatchSegmentRequest } from '../../services/segment.service';
import { Command } from '../command';

export interface ModifyCablePayload {
    buildId: number;
    buildType?: BuildType;
    type: SegmentModificationResult;
    newSegments: BuildSegment[];
    patchedSegments: PatchSegmentRequest[];
    childPretermBuilds: FlexNapBuild[];
    startAccessPointId: number;
    endAccessPointId: number;
    awaitSessionUpdate?: boolean;
}

export class ModifyCableCommand extends Command {
    private buildId: number;
    private buildType?: BuildType;
    private resultType: SegmentModificationResult;
    private newSegments: BuildSegment[];
    private patchedSegments: PatchSegmentRequest[];
    private childPretermBuilds: FlexNapBuild[];
    private startAccessPointId: number;
    private endAccessPointId: number;
    private awaitSessionUpdate?: boolean;

    constructor({ buildId, buildType, type: resultType, newSegments, patchedSegments, childPretermBuilds, startAccessPointId, endAccessPointId, awaitSessionUpdate }: ModifyCablePayload) {
        super();
        this.buildId = buildId;
        this.buildType = buildType;
        this.resultType = resultType;
        this.newSegments = newSegments;
        this.patchedSegments = patchedSegments;
        this.childPretermBuilds = childPretermBuilds;
        this.startAccessPointId = startAccessPointId;
        this.endAccessPointId = endAccessPointId;
        this.awaitSessionUpdate = awaitSessionUpdate;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildEditing };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildEditing };
    }

    public async undo(dispatch: Dispatch): Promise<void> {
        await this.handleAccessPoints(dispatch, this.endAccessPointId, this.startAccessPointId);
        const undoUpdatedSegments: PatchSegmentRequest[] = this.patchedSegments.map((s) => ({ oldSegment: s.newSegment, newSegment: s.oldSegment }));
        switch (this.resultType) {
            case SegmentModificationResult.CreateUpdate: {
                await patchSegments(undoUpdatedSegments, false, this.awaitSessionUpdate)(dispatch);
                this.newSegments.forEach((segment) => deleteSegment(segment, false, undefined, undefined, this.awaitSessionUpdate)(dispatch));
                break;
            }
            case SegmentModificationResult.Update:
                await patchSegments(undoUpdatedSegments, false, this.awaitSessionUpdate)(dispatch);
                this.childPretermBuilds.forEach((b) => b.pretermLateral && undoRevertPretermLateral(b.id, b.pretermLateral)(dispatch));
                break;
        }
        super.undo(dispatch);
    }

    public async redo(dispatch: Dispatch): Promise<void> {
        switch (this.resultType) {
            case SegmentModificationResult.CreateUpdate: {
                this.newSegments.forEach((segment) => undoDeleteSegment(segment, false)(dispatch));
                await patchSegments(this.patchedSegments, false, this.awaitSessionUpdate)(dispatch);
                break;
            }
            case SegmentModificationResult.Update:
                this.childPretermBuilds.forEach((b) => revertPretermLateral(b.id)(dispatch)); // since we soft-delete build, we need to explicitly make call to revert preterm
                await patchSegments(this.patchedSegments, false, this.awaitSessionUpdate)(dispatch);
                break;
        }
        await this.handleAccessPoints(dispatch, this.startAccessPointId, this.endAccessPointId);
        super.redo(dispatch);
    }

    private async handleAccessPoints(dispatch: Dispatch, unlinkAccessPointId: number, linkAccessPointId: number) {
        if (unlinkAccessPointId > 0) {
            switch (this.buildType) {
                case BuildType.FlexNap: unlinkNapFromBuild(unlinkAccessPointId, this.buildId)(dispatch); break;
                case BuildType.Bulk: unlinkSplicePointFromBuild(unlinkAccessPointId, this.buildId)(dispatch); break;
                case BuildType.Schrodinger: unlinkTapFromBuild(unlinkAccessPointId, this.buildId)(dispatch); break;
            }
        }
        if (linkAccessPointId > 0) {
            switch (this.buildType) {
                case BuildType.FlexNap: linkNapToBuild(linkAccessPointId, this.buildId)(dispatch); break;
                case BuildType.Bulk: linkSplicePointToBuild(linkAccessPointId, this.buildId)(dispatch); break;
                case BuildType.Schrodinger: {
                    const tap = store.getState().tapsSchrodinger.taps.find(({ id }) => id === linkAccessPointId);
                    tap && linkTapsToBuild([tap], this.buildId, true)(dispatch);
                    break;
                }
            }
        }
    }
}