
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BoreData } from '../models/bore';
import { CabinetData } from '../models/cabinet';
import { HandholeData } from '../models/handhole';
import { ImportedGisData } from '../models/imported-gis-data';
import { ManholeData } from '../models/manhole';
import { ParcelData } from '../models/parcel';
import { PoleData } from '../models/pole';
import { PoleSpanData } from '../models/polespan';
import { TrenchData } from '../models/trench';
import { VaultData } from '../models/vault';
import { WebServiceErrorHandlingBehavior } from '../services/abstract-web-v2.service';
import { ImportPayload, ImportService } from '../services/import.service';
import { createSecuredAsyncAction } from './action';
import { deleteBores } from './bore.state';
import { deleteBulkBuildsAction, deleteFlexNapBuildsAction, updateChildFlexNapPretermLaterals } from './build.state';
import { deleteImportedElements } from './element.actions';
import { DialogType, showDialog } from './overlay.state';
import { deleteImportedPaths } from './path.actions';
import { deleteNaps, unlinkNapsFromBuilds } from './tap.state';
import { deleteTrenches } from './trench.state';
import { deleteDesignAreas } from './design-area.state';

export interface ImportState {
    poles?: PoleData[];
    polespan?: PoleSpanData[];
    parcels?: ParcelData[];
    manholes?: ManholeData[];
    handholes?: HandholeData[];
    vaults?: VaultData[];
    cabinets?: CabinetData[];
    trenches?: TrenchData[];
    bores?: BoreData[];
    foundDuplicates?: boolean;
    isParsing: boolean;
    fileName?: string;
    importedGisData: ImportedGisData[];
    isRemovingGisData: boolean;
    importError?: string;
    importedGisDataToRemove?: number;
}

const initialState: ImportState = {
    poles: undefined,
    polespan: undefined,
    parcels: undefined,
    manholes: undefined,
    handholes: undefined,
    vaults: undefined,
    cabinets: undefined,
    trenches: undefined,
    bores: undefined,
    foundDuplicates: false,
    isParsing: false,
    fileName: "",
    importedGisData: [],
    isRemovingGisData: false,
    importError: ""
};

export const IMPORT_LOAD_THRESHOLD = 500;

function importPolesReducer(state: ImportState, action: PayloadAction<PoleData[]>): void {
    state.poles = action.payload;
}

function importPoleSpansReducer(state: ImportState, action: PayloadAction<PoleSpanData[]>): void {
    state.polespan = action.payload;
}

function importParcelsReducer(state: ImportState, action: PayloadAction<ParcelData[]>): void {
    state.parcels = action.payload;
}

function importManholesReducer(state: ImportState, action: PayloadAction<ManholeData[]>): void {
    state.manholes = action.payload;
}

function importHandholesReducer(state: ImportState, action: PayloadAction<HandholeData[]>): void {
    state.handholes = action.payload;
}

function importVaultsReducer(state: ImportState, action: PayloadAction<VaultData[]>): void {
    state.vaults = action.payload;
}

function importCabinetsReducer(state: ImportState, action: PayloadAction<CabinetData[]>): void {
    state.cabinets = action.payload;
}

function importTrenchesReducer(state: ImportState, action: PayloadAction<TrenchData[]>): void {
    state.trenches = action.payload;
}

function importBoresReducer(state: ImportState, action: PayloadAction<BoreData[]>): void {
    state.bores = action.payload;
}

function setFoundDuplicatesReducer(state: ImportState, action: PayloadAction<boolean>): void {
    state.foundDuplicates = action.payload;
}

function setIsParsingReducer(state: ImportState, action: PayloadAction<boolean>): void {
    state.isParsing = action.payload;
}

function setFileNameReducer(state: ImportState, action: PayloadAction<string | undefined>): void {
    state.fileName = action.payload;
}

function loadImportedGisDataReducer(state: ImportState, action: PayloadAction<ImportedGisData[]>): void {
    state.importedGisData = action.payload.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
}

function addImportedGisDataReducer(state: ImportState, action: PayloadAction<ImportedGisData>): void {
    state.importedGisData = [...state.importedGisData, action.payload].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
}

function deleteImportedGisDataReducer(state: ImportState, action: PayloadAction<number>): void {
    state.importedGisData = state.importedGisData.filter((i) => i.id !== action.payload);
}

function setIsRemovingGisDataReducer(state: ImportState, action: PayloadAction<boolean>): void {
    state.isRemovingGisData = action.payload;
}

function setErrorReducer(state: ImportState, action: PayloadAction<string>): void {
    state.importError = action.payload;
}

function setImportedGisDataToRemoveReducer(state: ImportState, action: PayloadAction<number | undefined>): void {
    state.importedGisDataToRemove = action.payload;
}

function clearImportedFileValuesReducer(state: ImportState): void {
    state.poles = undefined;
    state.polespan = undefined;
    state.parcels = undefined;
    state.manholes = undefined;
    state.handholes = undefined;
    state.vaults = undefined;
    state.cabinets = undefined;
    state.trenches = undefined;
    state.bores = undefined;
    state.foundDuplicates = false;
    state.isParsing = false;
    state.fileName = "";
    state.importError = "";
}

const { actions, reducer } = createSlice({
    name: 'import',
    initialState,
    reducers: {
        importPoles: importPolesReducer,
        importPoleSpans: importPoleSpansReducer,
        importParcels: importParcelsReducer,
        importManholes: importManholesReducer,
        importHandholes: importHandholesReducer,
        importVaults: importVaultsReducer,
        importCabinets: importCabinetsReducer,
        importTrenches: importTrenchesReducer,
        importBores: importBoresReducer,
        setFoundDuplicates: setFoundDuplicatesReducer,
        setIsParsing: setIsParsingReducer,
        setFileName: setFileNameReducer,
        clearImportedFileValues: clearImportedFileValuesReducer,
        loadImportedGisData: loadImportedGisDataReducer,
        addImportedGisData: addImportedGisDataReducer,
        deleteImportedGisData: deleteImportedGisDataReducer,
        setIsRemovingGisData: setIsRemovingGisDataReducer,
        setError: setErrorReducer,
        setImportedGisDataToRemove: setImportedGisDataToRemoveReducer
    },
});

export const filter = (payload: ImportPayload) => {
    return createSecuredAsyncAction(async (dispatch) => {
        try {
            const importService = new ImportService();
            importService.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
            const filteredPayload = await importService.filter(payload);
            if (filteredPayload) {
                const { poles, poleSpans, parcels, manholes, handholes, cabinets, vaults, trenches, bores, foundDuplicateTags } = filteredPayload;
                dispatch(actions.setFoundDuplicates(foundDuplicateTags ?? false));
                if (poles?.length > 0) {
                    dispatch(actions.importPoles(poles));
                    if (poleSpans.length > 0) {
                        dispatch(actions.importPoleSpans(poleSpans));
                    }
                }
                if (parcels?.length > 0) {
                    dispatch(actions.importParcels(parcels));
                }
                if (manholes?.length > 0) {
                    dispatch(actions.importManholes(manholes));
                }
                if (handholes?.length > 0) {
                    dispatch(actions.importHandholes(handholes));
                }
                if (cabinets?.length > 0) {
                    dispatch(actions.importCabinets(cabinets));
                }
                if (vaults?.length > 0) {
                    dispatch(actions.importVaults(vaults));
                }
                if (trenches?.length > 0) {
                    dispatch(actions.importTrenches(trenches));
                }
                if (bores?.length > 0) {
                    dispatch(actions.importBores(bores));
                }
                if (payload.fileName) {
                    dispatch(actions.setFileName(payload.fileName));
                }
            }
        }
        catch (err) {
            if (err instanceof Error) {
                dispatch(actions.setError(err.message));
            }
        }
    });
};

export const loadImportedGisData = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new ImportService();
        const importedGisData = await service.getImportedGisData();
        if (importedGisData) {
            dispatch(actions.loadImportedGisData(importedGisData));
        }
    });
}

export const deleteImportedGisData = (importedGisDataId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.setIsRemovingGisData(true));
        dispatch(showDialog(DialogType.RemoveImportedGisDataProgress));
        const service = new ImportService();
        const res = await service.deleteImportedGisData(importedGisDataId);
        if (res) {
            dispatch(actions.deleteImportedGisData(importedGisDataId));

            dispatch(deleteFlexNapBuildsAction(res.deletedFlexNapBuildIds));
            dispatch(updateChildFlexNapPretermLaterals(res.deletedFlexNapBuildIds));

            dispatch(deleteBulkBuildsAction(res.deletedBulkBuildIds));

            dispatch(deleteImportedElements(importedGisDataId));
            dispatch(deleteImportedPaths(importedGisDataId));

            dispatch(unlinkNapsFromBuilds(res.deletedFlexNapBuildIds));
            dispatch(deleteNaps(res.deletedNapIds));

            dispatch(deleteBores(res.deletedPathIds));
            dispatch(deleteTrenches(res.deletedPathIds));

            dispatch(deleteDesignAreas(res.deletedDesignAreaIds));

            dispatch(showDialog(DialogType.RemoveImportedGisDataSuccess));
        }
        else {
            dispatch(showDialog());
        }
        dispatch(actions.setIsRemovingGisData(false));
    });
}

export { reducer as ImportReducer };
export const { importPoles, importPoleSpans, importParcels, importManholes, importHandholes, importVaults, importCabinets, importTrenches, importBores, setFoundDuplicates, setIsParsing, clearImportedFileValues, addImportedGisData, setFileName, setImportedGisDataToRemove } = actions;
