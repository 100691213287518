import { useEffect, useState } from 'react';
import { SplicePointDiagramSvgProps } from '../splice-point-diagram.types';
import { Build } from '../../../models/build';

const PAGE_SIZE = 4;

export const useSplicePointDiagramSvg = (props: SplicePointDiagramSvgProps) => {
    const { locationId, splicedBuilds } = props;

    const [page, setPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [shownBuilds, setShownBuilds] = useState<Build[]>([]);

    useEffect(() => {
        if (splicedBuilds) {
            setShownBuilds(splicedBuilds.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE));
        }
    }, [splicedBuilds, page])

    useEffect(() => {
        if (!splicedBuilds) {
            setNumberOfPages(0);
        } else {
            setNumberOfPages(Math.ceil(splicedBuilds.length / PAGE_SIZE));
        }
    }, [splicedBuilds]);

    return {
        locationId, shownBuilds,
        page, numberOfPages, setPage
    };
};