import { Dispatch } from 'redux';
import { Command } from '../../command';
import { undoConvertToFlexnapBuild, convertToFlexnapBuild } from '../../../redux/build.state';
import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { Build } from '../../../models/build';

export class ConvertBuildCommand extends Command {
    private bulkBuildId: number;
    private flexnapBuildId: number;
    private childBuilds: Build[];

    constructor({ bulkId, fnapId, childBuilds }) {
        super();
        this.bulkBuildId = bulkId;
        this.flexnapBuildId = fnapId;
        this.childBuilds = childBuilds;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoConvertBulkBuild };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoConvertBulkBuild };
    }

    public undo(dispatch: Dispatch): void {
        undoConvertToFlexnapBuild(this.bulkBuildId, this.flexnapBuildId, this.childBuilds)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        convertToFlexnapBuild(this.bulkBuildId, this.flexnapBuildId, this.childBuilds)(dispatch);
        super.redo(dispatch);
    }
}