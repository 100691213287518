import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../locales/types';
import { IWorkspaceDescriptionProps } from "./drawer-workspace-description.types";

import './drawer-workspace-description.scss';

export const WorkspaceDescription = (props: IWorkspaceDescriptionProps) => {
    const { t } = useTranslation();
    const { description, location, nbDAs, nbBuilds, nbExports, exportsPercentage } = props;
    return (
        <div className="workspace-drawer-desc-container">
            <div className="workspace-drawer-workspace-desc">
                {description}
            </div>
            <div className="workspace-drawer-workspace-location">
                {location}
            </div>
            <div className="workspace-drawer-workspace-info-container">
                <div className="workspace-drawer-workspace-das">
                    {t(LocalizationKeys.WorkspaceDAs, { nbDAs: nbDAs })}
                </div>
                <div className="workspace-drawer-workspace-builds">
                    {t(LocalizationKeys.WorkspaceBuilds, { nbBuilds: nbBuilds })}
                </div>
                <div className="workspace-drawer-workspace-exports">
                    {t(LocalizationKeys.WorkspaceExports, { nbExports: nbExports, exportsPercentage: exportsPercentage })}
                </div>
            </div>
        </div>
    );
}