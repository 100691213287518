import React from 'react';

export const SplicePointIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect width="24" height="24" fill="rgba(255, 255, 255, 0)" />
            <path d="M 10 7 l 10 -7 V 14 Z M 0 0 l 10 7 L 0 14 Z" transform="translate(2,5)" fill="#005293" />
        </svg>
    );
};
