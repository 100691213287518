export enum AnalyticsEventType {
    selectContent = 'select_content',
    buildDownload = 'build_download',
    fileDownload = 'file_download'
}

export enum AnalyticsContentType {
    designTool = 'design tool'
}

export enum AnalyticsAction {
    runDesigner = 'run designer',
    savePresets = 'save presets',
    importGisData = 'import GIS data',
    removeGisData = 'remove GIS data',
    import = 'import',
    openWorkspace = 'open workspace',
    exitToDesignAreas = 'exit to design areas',
    reenterDesigns = 'reenter designs'
}

export class AnalyticsHelper {
    private static dataLayer = window.dataLayer ?? [];

    public static pushSelectContentEvent = async (action: AnalyticsAction) => {
        AnalyticsHelper.dataLayer.push({
            'event': AnalyticsEventType.selectContent,
            'content_type': AnalyticsContentType.designTool,
            'action': action
        });
    };

    public static pushBuildDownloadEvent = async (buildIds: string[], designAreaIds: string[]) => {
        AnalyticsHelper.dataLayer.push({
            'event': AnalyticsEventType.buildDownload,
            'build_ids': buildIds.sort().join(','),
            'designarea_ids': designAreaIds.sort().join(',')
        });
    };

    public static pushFileDownloadEvent = async (extension: string, name: string, linkText: string) => {
        AnalyticsHelper.dataLayer.push({
            'event': AnalyticsEventType.fileDownload,
            'file_extension': extension,
            'file_name': name,
            'link_text': linkText
        });
    }
}