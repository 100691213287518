import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { CardContentComponent } from '../../card/card-content.component';
import { CardHeaderComponent } from '../../card/card-header.component';
import { TetherComponent } from '../../cards/tether.component';
import { useFlexNapTetherCard } from './flexnap-tether-card.hooks';
import { FlexNapTetherCardProps } from './flexnap-tether-card.types';

export const FlexNapTetherCard = (props: FlexNapTetherCardProps): JSX.Element => {
    const { t } = useTranslation();
    const { tetherIcon, tapIndex, tetherIndex, tether, unselectTether, disabled, viewOnlyMode, collapsed, toggleCollapse } = useFlexNapTetherCard(props);
    const collapseBtn =
        <button className="icon-button" onClick={toggleCollapse}>
            {
                collapsed ?
                    <img alt="Reveal card" src={require('./../../card/icon_open_chevron.svg')} /> :
                    <img alt="Collapse card" src={require('./../../card/icon_close_chevron.svg')} />
            }
        </button>;
    const cs = classNames({ collapsed: !!collapsed });

    return (
        <div className="nap-diagram-children">
            <div className="card-separator"></div>
            <div className="header-with-button">
                <CardHeaderComponent title={t(LocalizationKeys.TetherNumber, { tapIndex: tapIndex ?? -1, tetherIndex: tetherIndex ?? -1 })} icon={tetherIcon} iconBorder={true} />
                {collapseBtn}
                <button className="icon-button" onClick={unselectTether}><img alt="Close tether" src={require('./../../card/icon_close.svg')} /></button>
            </div>
            <CardContentComponent className={cs}>
                <TetherComponent tether={tether} disabled={disabled} viewOnlyMode={viewOnlyMode} />
            </CardContentComponent>
        </div>
    );
};
