import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectChangeEvent } from '@mui/material';

import { useCountryStates } from '../../../hooks/country-states.hooks';
import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { Workspace } from '../../../models/workspace';
import { StateModel } from '../../../redux/reducers';
import { createWorkspace, setEditingWorkspaceId, updateWorkspace } from '../../../redux/workspace.state';
import { AddWorkspaceRequest } from '../../../services/workspace.service';
import { WorkspaceInformationDialogProps } from './workspace-dialog.types';


const NON_ALPHA_NUM_REGEX = new RegExp('[^A-Za-z0-9]');
const NON_ALPHA_REGEX = new RegExp('[^A-Za-z\\s]');
const SPECIAL_CHAR_REGEX = new RegExp('[^A-Za-z0-9\\s]');

const getWorkspaceName = (workspacesNames: string[]) => {
    let workspaceNumber = 1;
    let workspaceName = '';
    do {
        workspaceName = (i18next.t(LocalizationKeys.DefaultWorkspaceName, { workspaceNumber: workspaceNumber }) as string);
        workspaceNumber++;
    } while (workspacesNames.includes(workspaceName));

    return workspaceName;
}

export const useWorkspaceInfoDialog = (props: WorkspaceInformationDialogProps) => {
    const dispatch = useDispatch();

    const { workspaceId: id } = props;
    const { workspaces } = useSelector((state: StateModel) => state.workspace);
    const { buildType } = useSelector((state: StateModel) => state.tool);

    const schrodingerMode = buildType === BuildType.Schrodinger;
    const isCityMandatory = schrodingerMode;
    const workspacesNames = workspaces.map((p) => p.name);
    const defaultWorkspaceName = getWorkspaceName(workspacesNames);
    const workspace = workspaces.find(w => w.id === id);

    // Fields
    const [name, setName] = useState(workspace?.name ?? defaultWorkspaceName);
    const [workspaceId, setWorkspaceId] = useState(workspace?.workspaceId ?? "");
    const [description, setDescription] = useState(workspace?.description ?? "");
    const [zipCode, setZipCode] = useState(workspace?.zipCode ?? "");
    const [city, setCity] = useState(workspace?.city ?? "");
    const [country, setCountry] = useState(workspace?.country ?? "");
    const [state, setState] = useState(workspace?.region ?? "");

    // Validation
    const [isNameInvalid, setIsNameInvalid] = useState(false);
    const [isWorkspaceIdInvalid, setIsWorkspaceIdInvalid] = useState(false);
    const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false);
    const [isZipCodeInvalid, setIsZipCodeInvalid] = useState(false);
    const [isCityInvalid, setIsCityInvalid] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const { countries, states, resetCountryStates } = useCountryStates(workspace, country);

    const canSave = (
        !isNameInvalid &&
        (!!workspaceId && !!workspaceId.trim() && !isWorkspaceIdInvalid) &&
        (!!description && !!description.trim() && !isDescriptionInvalid) &&
        !isZipCodeInvalid &&
        !!country &&
        !!state &&
        ((!isCityMandatory || (!!city && !!city.trim())) && !isCityInvalid)
    );

    const reset = useCallback(() => {
        setWorkspaceId("");
        setName(defaultWorkspaceName);
        setDescription("");
        setCity("");
        setCountry("");
        setState("");
        setZipCode("");
        resetCountryStates();
    }, [resetCountryStates, defaultWorkspaceName]);

    const onClose = useCallback(() => {
        dispatch(setEditingWorkspaceId());
    }, [dispatch]);

    const onCancel = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    const addWorkspace = useCallback((workspaceRequest: AddWorkspaceRequest) => {
        createWorkspace(workspaceRequest)(dispatch);
    }, [dispatch]);

    const saveWorkspace = useCallback((workspace: Workspace, workspaceRequest: AddWorkspaceRequest) => {
        const newWorkspace: Workspace = {
            id: id,
            ...workspaceRequest
        }
        updateWorkspace(workspace, newWorkspace)(dispatch);
    }, [id, dispatch]);

    const onSave = useCallback(() => {
        const workspaceRequest: AddWorkspaceRequest = {
            workspaceId: workspaceId,
            name: name,
            description: description,
            country: country,
            region: state,
            city: city,
            zipCode: zipCode,
        }
        if (workspace) {
            saveWorkspace(workspace, workspaceRequest);
        } else {
            addWorkspace(workspaceRequest);
            reset();
        }
        onClose();
    }, [workspaceId, name, description, country, state, city, zipCode, workspace, saveWorkspace, addWorkspace, reset, onClose]);

    useEffect(() => {
        if (workspace?.country) {
            setCountry(workspace?.country);
        }
    }, [workspace, countries]);

    useEffect(() => {
        const workspaceRegion = states.find((s) => s === workspace?.region);
        if (workspaceRegion) {
            setState(workspaceRegion);
        }
    }, [workspace, states]);

    const onNameInputChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setName(entry);
        setIsNameInvalid(SPECIAL_CHAR_REGEX.test(entry));
    }, []);

    const onWorkspaceIdInputChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setWorkspaceId(entry);
        setIsWorkspaceIdInvalid(NON_ALPHA_NUM_REGEX.test(entry) || entry.length === 0);
    }, []);

    const onDescInputChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setDescription(entry);
        setIsDescriptionInvalid(entry.length === 0);
    }, []);

    const onZipCodeInputChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setZipCode(entry);
        setIsZipCodeInvalid(SPECIAL_CHAR_REGEX.test(entry));
    }, []);

    const onCityInputChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setCity(entry);
        setIsCityInvalid(NON_ALPHA_REGEX.test(entry) || (isCityMandatory && entry.length === 0))
    }, [isCityMandatory]);

    const onCountryValueChanged = useCallback((e: SelectChangeEvent<any>) => {
        const entry = e.target.value;
        setCountry(entry);
    }, []);

    const onStateValueChanged = useCallback((e: SelectChangeEvent<any>) => {
        const entry = e.target.value;
        setState(entry);
    }, []);

    return {
        id,
        onClose, onCancel, onSave,
        workspaceId, onWorkspaceIdInputChanged, isWorkspaceIdInvalid,
        name, onNameInputChanged, isNameInvalid,
        description, onDescInputChanged, isDescriptionInvalid,
        zipCode, onZipCodeInputChanged, isZipCodeInvalid,
        city, onCityInputChanged, isCityInvalid, isCityMandatory,
        country, onCountryValueChanged,
        state, onStateValueChanged,
        countries, states,
        canSave
    };
};
