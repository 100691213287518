import { store } from '../dna';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';
import { FeatureType } from './../openlayers/openlayer-feature.factory';
import { InteractableFeatureType } from './../openlayers/interactable-feature-type';
import { MapClickEvent } from './../models/map-click-event';
import { SegmentHelper } from './../helpers/segment-helper';
import { BuilderHelper } from './../helpers/build-helper';
import { BulkBuild } from '../models/bulk/bulk-build';
import { addSplicePoint } from '../redux/bulk/splice-point.state';

export class SplicePointTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.SplicePoint,
        name: 'Splice Point',
        shortcut: 'n'
    };

    public static alloweElementTypes: FeatureType[] = [
        InteractableFeatureType.HANDHOLE,
        InteractableFeatureType.MANHOLE,
        InteractableFeatureType.POLE,
        InteractableFeatureType.VAULT,
        InteractableFeatureType.CABINET
    ];

    public static execute(event: MapClickEvent): void {
        const selectedFeatures = event.items;
        if (!selectedFeatures) {
            return;
        }

        const elementId = selectedFeatures
            .filter(f => this.alloweElementTypes.includes(f.type))
            .map(f => f.id)
            .find(f => !!f);
        if (!elementId || elementId < 1) {
            return;
        }

        const elementCoordinates = { x: event.x, y: event.y };

        const state = store.getState();
        const { segments, bulkBuilds } = state.build;
        const { splicePoints } = state.splicePoints;
        const segmentsOnElement = SegmentHelper.getSegmentsOnElement(elementId, segments);
        const buildsOnElement = BuilderHelper.getBuildsFromSegments(bulkBuilds, segmentsOnElement) as BulkBuild[];

        if (buildsOnElement?.length > 1 || splicePoints.some(s => s.buildId === buildsOnElement[0]?.id && s.elementId === elementId)) {
            if (event.contextMenuCallback) {
                event.contextMenuCallback({
                    nap: {
                        elementId,
                        type: InteractableFeatureType.SPLICE_POINT,
                        isCluster: false,
                        elementCoordinates
                    },
                    draggingNap: false
                });
            }

            return;
        }

        this.createSplicePoint(elementId, buildsOnElement[0]?.id);
    }

    public static createSplicePoint(elementId: number, buildId?: number): void {
        const state = store.getState();

        const { currentWorkspace } = state.workspace;
        if (!currentWorkspace?.id) {
            return;
        }

        addSplicePoint(elementId, currentWorkspace.id, buildId)(store.dispatch);
    }
}