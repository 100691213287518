import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

export const TooltipLight = withStyles(() => ({
    tooltip: {
        boxSizing: 'border-box',
        minWidth: 64,
        padding: '4 6',
        fontSize: 12,
        fontFamily: 'TheSansC4s',
        fontWeight: 200,
        textAlign: 'center',
        backgroundColor: '#efedebcc',
        color: '#4E4E50',
        boxShadow: '0',
        borderRadius: '4px',
    },
    arrow: {
        color: '#efedebcc',
    },
}))(Tooltip);