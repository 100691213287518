import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsAction, AnalyticsHelper } from '../../helpers/analytics-helper';
import { DesignMode } from '../../models/design-mode';
import { logout, toggleDisplayUnits } from '../../redux/authentication.state';
import CorningRoles from '../../redux/corning-roles.json';
import { toggleFullscreen } from '../../redux/map.state';
import { ControlType, DialogType, showControl, showDialog } from '../../redux/overlay.state';
import { StateModel } from '../../redux/reducers';
import { updateLastUsedDesignMode } from '../../redux/workspace.state';
import { designModeSelector } from '../../selectors/root.selectors';
import { CpqService } from '../../services/cpq.service';
import { NotificationService } from '../../services/notification.service';
import { GISMoreOptionsMenuProps } from './gis-more-options';

export enum MenuActions {
    AboutThisApplication = 'AboutThisApplication',
    ImportGISData = 'ImportGISData',
    RemoveGISData = 'RemoveGISData',
    ToggleUnits = 'ToggleUnits',
    ToggleFullscreen = 'ToggleFullscreen',
    ExitDesigner = 'ExitDesigner',
    UpdateContracts = 'UpdateContracts',
    UpdateCpq = 'UpdateCpq',
    SwitchToCanvas = 'SwitchToCanvas',
    SwitchToMap = 'SwitchToMap',
    Logout = 'Logout'
}

export const useGISMoreOptionsMenu = (props: GISMoreOptionsMenuProps) => {
    const dispatch = useDispatch();

    const { open } = props;

    const { currentWorkspace } = useSelector((state: StateModel) => state.workspace);
    const { displayUnits, customer } = useSelector((state: StateModel) => state.authentication);
    const { roles } = useSelector((state: StateModel) => state.role);
    const designMode = useSelector(designModeSelector);
    const { isFullscreen } = useSelector((state: StateModel) => state.map);

    const [isUpdatingContracts, setIsUpdatingContracts] = useState(false);
    const [isUpdatingCpq, setIsUpdatingCpq] = useState(false);

    const hasCustomer = !!customer;
    const isFlexNapDesignAdmin = roles ? roles.includes(CorningRoles.FlexNAPAdmin) : false;
    const hasSchrodingerRole = roles ? roles.some((role) => role === CorningRoles.Schrodinger) : false;
    const isGISMode = designMode === DesignMode.GISMode;


    const updateContracts = useCallback((): void => {
        setIsUpdatingContracts(true);
        const service = new CpqService();
        service.updateFlexNapContracts()
            .then((res) => {
                setIsUpdatingContracts(false);
                if (res) {
                    NotificationService.success(res);
                }
                else {
                    NotificationService.error("Failed to update FlexNAP Contracts.");
                }
            })
            .catch((err) => {
                setIsUpdatingContracts(false);
                NotificationService.error(err);
            });
    }, []);

    const updateCpq = useCallback((): void => {
        setIsUpdatingCpq(true);
        const service = new CpqService();
        service.updateCpq()
            .then((res) => {
                setIsUpdatingCpq(false);
                if (res) {
                    NotificationService.success(res);
                }
                else {
                    NotificationService.error("Failed to update CPQ.");
                }
            })
            .catch((err) => {
                setIsUpdatingCpq(false);
                NotificationService.error(err);
            });
    }, []);

    const handleSelectedMenuItem = useCallback((id: string): void => {
        if (id === MenuActions.AboutThisApplication) {
            dispatch(showDialog(DialogType.About));
            dispatch(showControl());
        }
        else if (id === MenuActions.ImportGISData) {
            AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.importGisData);
            dispatch(showDialog(DialogType.Import));
            dispatch(showControl());
        }
        else if (id === MenuActions.RemoveGISData) {
            AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.removeGisData);
            dispatch(showControl(ControlType.RemoveGisData));
        }
        else if (id === MenuActions.ToggleUnits) {
            dispatch(toggleDisplayUnits());
        }
        else if (id === MenuActions.ToggleFullscreen) {
            dispatch(toggleFullscreen());
            dispatch(showControl());
        }
        else if (id === MenuActions.ExitDesigner) {
            AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.exitToDesignAreas);
            const href = window.location.href.includes('optical-communications')
                ? '/optical-communications/US/en/DNET'
                : '/corningstorefront/corning/en/USD/design-areas';
            window.location.href = href;
        }
        else if (id === MenuActions.UpdateContracts) {
            updateContracts();
        }
        else if (id === MenuActions.UpdateCpq) {
            updateCpq();
        }
        else if (id === MenuActions.SwitchToCanvas) {
            dispatch(showDialog());
            dispatch(showControl());
            if (currentWorkspace) {
                dispatch(updateLastUsedDesignMode(DesignMode.CanvasMode))
            }
        }
        else if (id === MenuActions.SwitchToMap) {
            dispatch(showDialog());
            dispatch(showControl());
            if (currentWorkspace) {
                dispatch(updateLastUsedDesignMode(DesignMode.GISMode))
            }
        }
        else if (id === MenuActions.Logout) {
            dispatch(showDialog());
            dispatch(showControl());
            dispatch(logout());
        }
    }, [currentWorkspace, dispatch, updateContracts, updateCpq]);

    return { open, isUpdatingContracts, isUpdatingCpq, displayUnits, isFullscreen, hasCustomer, hasSchrodingerRole, isFlexNapDesignAdmin, isGISMode, handleSelectedMenuItem }
}