import i18next from "i18next";
import React from "react";
import { LocalizationKeys } from "../../locales/types";
import { PopupMenuItem } from "../ui-elements/popup-menu";
import { useCanvasMoreOptionsMenu } from "./canvas-more-options.hooks";
import { MenuActions } from "./gis-more-options.hooks";
import { MoreOptionsMenu } from "./more-options";
import aboutIcon from './icons/icon_about.svg';
import canvasModeIcon from './icons/icon_canvas_mode.svg';
import gisModeIcon from './icons/icon_gis_mode.svg';
import enterFullscreenIcon from './icons/icon_fullscreen.svg';
import exitFullscreenIcon from './icons/icon_fullscreen_exit.svg';
import exitDesignerIcon from './icons/icon_exit_to_design_areas.svg';
import closeIcon from './icons/icon_close.svg';


export interface CanvasMoreOptionsMenuProps {
    open: boolean;
}

export const CanvasMoreOptionsMenu = (props: CanvasMoreOptionsMenuProps): JSX.Element | null => {
    const { open, isGISMode, hasCustomer, isFullscreen, handleSelectedMenuItem } = useCanvasMoreOptionsMenu(props);

    const aboutApplicationStr = i18next.t(LocalizationKeys.AboutThisApplication);
    const exitToDaStr = i18next.t(LocalizationKeys.ExitToMyDesignAreas);
    const switchDesignModeStr = isGISMode ? i18next.t(LocalizationKeys.SwitchToCanvasMode) : i18next.t(LocalizationKeys.SwitchToGISMode);
    const logoutStr = i18next.t(LocalizationKeys.Logout);
    const designModeId = isGISMode ? MenuActions.SwitchToCanvas : MenuActions.SwitchToMap;
    const designModeIcon = isGISMode ? canvasModeIcon : gisModeIcon;

    const menuItems: PopupMenuItem[] = [];
    menuItems.push({ label: aboutApplicationStr, id: MenuActions.AboutThisApplication, icon: <img src={aboutIcon} alt={aboutApplicationStr}></img> });
    menuItems.push({ label: switchDesignModeStr, id: designModeId, icon: <img src={designModeIcon} alt={switchDesignModeStr} /> });

    if (hasCustomer) {
        const fullscreenIcon = isFullscreen ? exitFullscreenIcon : enterFullscreenIcon;
        const fullscreenLabel = isFullscreen ? i18next.t(LocalizationKeys.ExitFullscreen) : i18next.t(LocalizationKeys.EnterFullscreen);
        menuItems.push({ label: fullscreenLabel, id: MenuActions.ToggleFullscreen, icon: <img src={fullscreenIcon} alt={fullscreenLabel} /> });
        menuItems.push({ label: exitToDaStr, id: MenuActions.ExitDesigner, icon: <img src={exitDesignerIcon} alt={exitToDaStr} />, divider: true });
    }
    else {
        menuItems.push({ label: logoutStr, id: MenuActions.Logout, icon: <img src={closeIcon} alt={logoutStr}></img>, divider: true });
    }

    return (
        <div>
            <MoreOptionsMenu open={open} options={menuItems} handleSelectedOption={handleSelectedMenuItem} />
        </div>
    );
}