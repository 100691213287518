import './remove-gis-data.scss';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener, ListItemText, List, ListItem, ListItemIcon } from '@mui/material';
import { IconButton } from '@orbit/icon-button';
import { MainPalettes, MainThemeTokens } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../locales/types';
import { Card } from '../card/card';
import { CardContentComponent } from '../card/card-content.component';
import { CardHeaderComponent } from '../card/card-header.component';
import { DescriptionIcon } from './icons/description-icon';
import iconDelete from './icons/icon_delete.svg';
import { useRemoveGisData } from './remove-gis-data.hooks';

export const RemoveGisData = (): JSX.Element | null => {
    const { t } = useTranslation();
    const { open, importedGisData, isRemovingGisData, handleControlClose, handleDelete } = useRemoveGisData();

    const listItemClasses = classNames('remove-gis-data-element', {
        'disabled': isRemovingGisData
    })

    const deleteAction = (id: number) => {
        return (<IconButton aria-label="delete" palette={MainPalettes.error} token={MainThemeTokens.dark} onClick={handleDelete(id)} icon={<img className="icon-delete" src={iconDelete} alt={t(LocalizationKeys.Delete)} />} placement={'bottom'} />)
    }

    return (
        <>
            {!open ? null :
                <ClickAwayListener onClickAway={handleControlClose}>
                    <div id="gis-data-remove" className="card-container">
                        <Card id="remove-gis-data" className="remove-gis-data" hideToggleCollapse={true} onCloseClick={handleControlClose}>
                            <CardHeaderComponent title={t(LocalizationKeys.RemoveImportedGisData)} subheader={false} />
                            <CardContentComponent>
                                <List sx={{ position: 'relative', overflow: 'auto', maxHeight: 300 }}>
                                    {importedGisData.map((i, idx) => (
                                        <ListItem key={idx} secondaryAction={deleteAction(i.id)} className={listItemClasses}>
                                            <ListItemIcon>
                                                <DescriptionIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={i.fileName} secondary={(new Date(i.timestamp)).toShortString()} />
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContentComponent>
                        </Card>
                    </div>
                </ClickAwayListener>
            }
        </>
    );
};
