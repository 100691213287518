import React from 'react';
import { ProgressItemProps, ProgressItemHooks } from "./progress-item.types";

export const useProgressItem = (props: ProgressItemProps): ProgressItemHooks => {
    const { data, index, selectedIndex, onClick } = props;
    const first = index === 0;
    const selected = index === selectedIndex;
    const onProgressItemClick = selected ? undefined : onClick;
    const ref: React.RefObject<HTMLDivElement> = React.createRef();

    const onLoad = () => {
        if (selected && ref.current) {
            ref.current.scrollIntoView();
        }
    };

    return {
        data, first, selected, ref,
        onClick: onProgressItemClick, onLoad
    };
};