import i18next from 'i18next';
import { ModifyEvent } from 'ol/interaction/Modify';

import { undergroundTypes } from '../components/map/map.component';
import { store } from '../dna';
import { LocalizationKeys } from '../locales/types';
import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { Element } from '../models/element';
import { MapClickEvent } from '../models/map-click-event';
import { MapItemDefinition } from '../models/map-item-definition';
import { ModifiableNode } from '../models/modifiable-node';
import { PointLike } from '../models/pointlike';
import { MapDragEndEvent } from '../openlayers/drag-interaction';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { FeatureType } from '../openlayers/openlayer-feature.factory';
import {
    disconnectBuildFromSplicePoint, revertPretermLateral, updateBuildSegmentsConnectedToElement
} from '../redux/build.state';
import { moveSplicePoint } from '../redux/bulk/splice-point.state';
import { updateElement } from '../redux/element.actions';
import { notificationClicked } from '../redux/map.state';
import { updatePathsConnectedToElement } from '../redux/path.actions';
import { moveTap } from '../redux/schrodinger/tap.state';
import {
    clearSelection, selectBore, selectBuild, selectDesignArea, selectSegments, selectTerminal,
    selectTerminalSegments, selectTrench
} from '../redux/selection.state';
import { moveNap, moveTerminal, resetNapExtenders } from '../redux/tap.state';
import { BulkCableTool } from './bulk-cable-tool';
import { DesignAreaTool } from './design-area-tool';
import { FlexNAPCableTool } from './flexnap-cable-tool';
import { Modifiable, ModificationToolDictionary } from './modifiable';
import { ModifyPathTool } from './modify-path-tool';
import { SchrodingerCableTool } from './schrodinger-cable-tool';
import { TerminalCableTool } from './terminal-cable-tool';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class ModificationTool implements Modifiable {
    public static definition: ToolDefinition = {
        toolType: ToolType.Modification,
        name: i18next.t(LocalizationKeys.Modification),
        shortcut: 'E',
    };

    private modificationTools: ModificationToolDictionary = {
        [InteractableFeatureType.FLEXNAP_SEGMENT]: {
            tool: new FlexNAPCableTool()
        },
        [InteractableFeatureType.SCHRODINGER_SEGMENT]: {
            tool: new SchrodingerCableTool()
        },
        [InteractableFeatureType.BULK_SEGMENT]: {
            tool: new BulkCableTool()
        },
        [InteractableFeatureType.DESIGN_AREA]: {
            tool: new DesignAreaTool()
        },
        [InteractableFeatureType.TERMINAL_SEGMENT]: {
            tool: new TerminalCableTool()
        },
        [InteractableFeatureType.BORE]: {
            tool: new ModifyPathTool()
        },
        [InteractableFeatureType.TRENCH]: {
            tool: new ModifyPathTool()
        },
    }

    private modifiableTypes = new Set<FeatureType>([
        InteractableFeatureType.NAP,
        InteractableFeatureType.TAP_SCHRODINGER,
        InteractableFeatureType.SPLICE_POINT,
        InteractableFeatureType.FLEXNAP_SEGMENT,
        InteractableFeatureType.SCHRODINGER_SEGMENT,
        InteractableFeatureType.BULK_SEGMENT,
        InteractableFeatureType.DESIGN_AREA,
        InteractableFeatureType.TERMINAL,
        InteractableFeatureType.TERMINAL_SEGMENT,
        InteractableFeatureType.CABINET,
        InteractableFeatureType.POLE,
        InteractableFeatureType.MANHOLE,
        InteractableFeatureType.HANDHOLE,
        InteractableFeatureType.VAULT,
        InteractableFeatureType.BORE,
        InteractableFeatureType.TRENCH,
    ]);

    private moveToTypes = new Set<FeatureType>([
        InteractableFeatureType.HANDHOLE,
        InteractableFeatureType.MANHOLE,
        InteractableFeatureType.POLE,
        InteractableFeatureType.VAULT,
        InteractableFeatureType.CABINET
    ]);

    private selectedType = "";

    public execute(event: MapClickEvent): void {
        const selectedItem = event.items && event.items.length && event.items[0];
        store.dispatch(clearSelection());
        store.dispatch(notificationClicked());
        if (!selectedItem) {
            return;
        }

        const featureId = selectedItem.id;
        if (!featureId) {
            return;
        }

        const isTemporaryItem = !selectedItem.id || selectedItem.id < 0;
        if (this.modifiableTypes.has(selectedItem.type) && !selectedItem.isLocked && !isTemporaryItem) {
            switch (selectedItem.type) {
                case InteractableFeatureType.FLEXNAP_SEGMENT:
                case InteractableFeatureType.SCHRODINGER_SEGMENT:
                case InteractableFeatureType.BULK_SEGMENT:
                    if (selectedItem.buildId) {
                        this.selectSegmentsFromBuild(selectedItem.buildId);
                    }
                    break;
                case InteractableFeatureType.DESIGN_AREA:
                    store.dispatch(selectDesignArea(featureId));
                    break;
                case InteractableFeatureType.TERMINAL_SEGMENT:
                    if (selectedItem.terminalId) {
                        store.dispatch(selectTerminal(selectedItem.terminalId));
                        this.selectSegmentsFromTerminal(selectedItem.terminalId);
                    }
                    break;
                case InteractableFeatureType.BORE:
                    store.dispatch(selectBore(featureId));
                    break;
                case InteractableFeatureType.TRENCH:
                    store.dispatch(selectTrench(featureId));
                    break;
            }
            this.selectedType = selectedItem.type;
        }
    }

    private selectSegmentsFromBuild(buildId: number): void {
        const { segments } = store.getState().build;
        const segmentsIds = segments.filter(s => s.buildId === buildId).map(s => s.id);
        store.dispatch(selectSegments(segmentsIds));
        store.dispatch(selectBuild(buildId));
    }

    private selectSegmentsFromTerminal(terminalId: number): void {
        const { tethers } = store.getState().taps;
        const segmentIds = tethers?.find(t => t?.terminal?.id === terminalId)?.terminal?.terminalExtension?.segments?.map(s => s.id);
        if (!segmentIds) {
            return;
        }

        store.dispatch(selectTerminalSegments(segmentIds));
    }

    public modifyStart(event: ModifyEvent): void {
        if (this.modificationTools[this.selectedType]) {
            const modificationTool = this.modificationTools[this.selectedType].tool;
            modificationTool.modifyStart(event);
        } else {
            console.warn("This feature type is not supported for modification");
        }
    }

    public modifyEnd(event: ModifyEvent): void {
        if (this.modificationTools[this.selectedType]) {
            const modificationTool = this.modificationTools[this.selectedType].tool;
            modificationTool.modifyEnd(event);
        } else {
            console.warn("This feature type is not supported for modification");
        }
    }

    public getModifiableNode(features: MapItemDefinition[], point?: PointLike): ModifiableNode | undefined {
        if (this.modificationTools[this.selectedType]) {
            const modificationTool = this.modificationTools[this.selectedType].tool;
            return modificationTool.getModifiableNode(features, point);
        } else {
            console.warn("This feature type is not supported for modification");
        }
    }

    public deleteNode(node: MapItemDefinition): boolean {
        if (this.modificationTools[this.selectedType]) {
            const modificationTool = this.modificationTools[this.selectedType].tool;
            return modificationTool.deleteNode(node);
        } else {
            console.warn("This feature type cannot be deleted");
            return false;
        }
    }

    public moveNap(napId: number, toElementId: number, toBuildId: number | null): void {
        const { flexnapBuilds } = store.getState().build;
        const { naps } = store.getState().taps;

        const nap = naps.find((n) => n.id === napId);
        if (!nap) return;

        const pretermBuilds = flexnapBuilds.filter(b => b.pretermLateral?.parentNapId === napId);

        moveNap(napId, nap.elementId, toElementId, nap.buildId, toBuildId, pretermBuilds)(store.dispatch)
            .then(() => {
                if (nap.elementId === toElementId) {
                    resetNapExtenders(napId)(store.dispatch);
                }
            });

        pretermBuilds.forEach((b) => revertPretermLateral(b.id)(store.dispatch));
    }

    public moveTap(tapId: number, toElementId: number, toBuildId: number | null): void {
        const { taps } = store.getState().tapsSchrodinger;
        const tap = taps.find(({ id }) => id === tapId);
        if (tap) {
            const { elementId: fromElementId, buildId: fromBuildId } = tap;
            moveTap(tapId, fromElementId, toElementId, fromBuildId, toBuildId)(store.dispatch);
        }
    }

    public moveSplicePoint(splicePointId: number, toElementId: number, toBuildId: number | null): void {
        const { bulkBuilds, flexnapBuilds } = store.getState().build;

        moveSplicePoint(splicePointId, toElementId, toBuildId)(store.dispatch);

        [...bulkBuilds, ...flexnapBuilds]
            .filter(b => b.buildSplice?.parentSplicePointId === splicePointId)
            .forEach(b => disconnectBuildFromSplicePoint(b.id)(store.dispatch));
    }

    public async drag(event: MapDragEndEvent, contextMenuCallback?: (obj: unknown) => void): Promise<boolean> {
        const toElement = event.nearby.find(i => this.moveToTypes.has(i.type));
        const fromElement = event.item.elementId;
        if (!toElement || fromElement === toElement.id) {
            return false;
        }

        if (!this.modifiableTypes.has(event.item.type)) {
            console.warn('Dragging unexpected item', event);
            return false;
        }

        if (event.item.type === InteractableFeatureType.NAP || event.item.type === InteractableFeatureType.TAP_SCHRODINGER || event.item.type === InteractableFeatureType.SPLICE_POINT) {
            const accessPointId = event.item.id;
            const elementCoordinates = event.endAt ? { x: event.endAt.x, y: event.endAt.y } : undefined;

            const state = store.getState();
            const { segments, flexnapBuilds, schrodingerBuilds, bulkBuilds } = state.build;
            const { naps } = state.taps;
            const { taps } = state.tapsSchrodinger;
            const { splicePoints } = state.splicePoints;

            const buildsAtToElement = this.getBuildsOnNetworkElement(
                toElement,
                segments,
                event.item.type === InteractableFeatureType.NAP ? flexnapBuilds
                    : event.item.type === InteractableFeatureType.TAP_SCHRODINGER ? schrodingerBuilds
                        : event.item.type === InteractableFeatureType.SPLICE_POINT ? bulkBuilds
                            : []);

            if (buildsAtToElement?.length > 1
                || naps.some(n => n.buildId === buildsAtToElement[0]?.id && n.elementId === toElement.id)
                || taps.some(t => t.buildId === buildsAtToElement[0]?.id && t.elementId === toElement.id)
                || splicePoints.some(s => s.buildId === buildsAtToElement[0]?.id && s.elementId === toElement.id)) {
                if (contextMenuCallback) {
                    contextMenuCallback({
                        nap: {
                            id: accessPointId,
                            elementId: toElement.id,
                            type: event.item.type,
                            isCluster: false,
                            elementCoordinates
                        },
                        draggingNap: true
                    });
                }
            } else {
                if (buildsAtToElement[0]?.lockedById) {
                    return false;
                }
                switch (event.item.type) {
                    case InteractableFeatureType.TAP_SCHRODINGER:
                        this.moveTap(accessPointId, toElement.id, buildsAtToElement[0]?.id);
                        break;
                    case InteractableFeatureType.SPLICE_POINT:
                        this.moveSplicePoint(accessPointId, toElement.id, buildsAtToElement[0]?.id);
                        break;
                    case InteractableFeatureType.NAP:
                        this.moveNap(accessPointId, toElement.id, buildsAtToElement[0]?.id);
                        break;
                }
            }

            return true;
        } else if (event.item.type === InteractableFeatureType.TERMINAL) {
            const terminalId = event.item.id;
            moveTerminal(terminalId, toElement.id)(store.dispatch);
            return true;
        } else if (event.endAtCoordinate) {
            const element = this.getElement(event.item);
            const [x, y] = event.endAtCoordinate;
            if (element && x !== undefined && y !== undefined) {
                const movedElement = { ...element, x, y };
                const success = await updateElement(element, movedElement)(store.dispatch);
                if (success) {
                    undergroundTypes.includes(event.item.type) && updatePathsConnectedToElement(element.id)(store.dispatch);
                    updateBuildSegmentsConnectedToElement(element)(store.dispatch);
                    return true;
                }
            }
        }

        return false;
    }

    public getBuildsOnNetworkElement(networkElement: MapItemDefinition, segments: BuildSegment[], builds: Build[]): Build[] {
        const segmentBuilds = segments
            .filter((s) => s.fromId === networkElement.id || s.toId === networkElement.id)
            .map((s) => builds.find((b) => b.id === s.buildId))
            .filter((b) => !!b)
            .distinct();
        return segmentBuilds as Build[];
    }

    private getElement(item: MapItemDefinition): Element | undefined {
        const idMatch = (element: Element): boolean => element.id === item.id;
        switch (item.type) {
            case InteractableFeatureType.CABINET: return store.getState().cabinet.cabinets.find(idMatch);
            case InteractableFeatureType.HANDHOLE: return store.getState().handhole.handholes.find(idMatch);
            case InteractableFeatureType.MANHOLE: return store.getState().manhole.manholes.find(idMatch);
            case InteractableFeatureType.POLE: return store.getState().pole.poles.find(idMatch);
            case InteractableFeatureType.VAULT: return store.getState().vault.vaults.find(idMatch);
            default: return undefined;
        }
    }
}
