import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { deleteBulkBuilds, deleteBulkCable } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { selectedBulkBuildSelector } from '../../../selectors/build.selectors';
import { dialogSelector } from '../../../selectors/root.selectors';
import { DeleteBuildDialogProps } from './delete-build-dialog.types';

export const useDeleteBulkBuildDialog = (): DeleteBuildDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dialog = useSelector(dialogSelector);
    const { flexnapBuilds, bulkBuilds } = useSelector((state: StateModel) => state.build);
    const build = useSelector(selectedBulkBuildSelector);
    const buildId = build?.id ?? -1;
    const isOpen = (dialog === DialogType.DeleteBulkBuild || dialog === DialogType.DeleteBulkCable) && buildId > 0;
    const title = dialog === DialogType.DeleteBulkBuild
                                ? t(LocalizationKeys.DeleteBulkBuild, { buildId })
                                : t(LocalizationKeys.DeleteBulkCable, { buildId });

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onDeleteBuild = useCallback(() => {
        const childBuildIds = [...flexnapBuilds, ...bulkBuilds]
                                .filter(b => b.buildSplice?.parentBuildId === buildId)
                                .map(b => b.id);

        dialog === DialogType.DeleteBulkBuild
                        ? dispatch(deleteBulkBuilds([buildId], childBuildIds))
                        : dispatch(deleteBulkCable(buildId, childBuildIds));
        dispatch(showDialog());
    }, [buildId, flexnapBuilds, bulkBuilds, dialog, dispatch]);

    return {
        isOpen,
        onClose, onDeleteBuild,
        title
    }
};