import React from 'react';

import { BuildIconFactory } from './build-icon-factory';
import { useBuildIcon } from './build-icon.hooks';
import { BuildIconProps } from './build-icon.types';

export const BuildIcon = (props: BuildIconProps) => {
    const {
        build,
        handleBuildClick,
    } = useBuildIcon(props);

    return (
        <BuildIconFactory
            build={build}
            onClick={handleBuildClick}
        />
    );
};
