import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsAction, AnalyticsHelper } from '../../helpers/analytics-helper';
import { DesignMode } from '../../models/design-mode';
import { toggleFullscreen } from '../../redux/map.state';
import { DialogType, showControl, showDialog } from '../../redux/overlay.state';
import { StateModel } from '../../redux/reducers';
import { updateLastUsedDesignMode } from '../../redux/workspace.state';
import { designModeSelector } from '../../selectors/root.selectors';
import { logout } from '../../redux/authentication.state';
import { CanvasMoreOptionsMenuProps } from './canvas-more-options';
import { MenuActions } from './gis-more-options.hooks';

export const useCanvasMoreOptionsMenu = (props: CanvasMoreOptionsMenuProps) => {
    const dispatch = useDispatch();

    const { open } = props;

    const { currentWorkspace } = useSelector((state: StateModel) => state.workspace);
    const { customer } = useSelector((state: StateModel) => state.authentication);
    const designMode = useSelector(designModeSelector);
    const { isFullscreen } = useSelector((state: StateModel) => state.map);

    const hasCustomer = !!customer;
    const isGISMode = designMode === DesignMode.GISMode;

    const handleSelectedMenuItem = useCallback((id: string): void => {
        if (id === MenuActions.AboutThisApplication) {
            dispatch(showDialog(DialogType.About));
            dispatch(showControl());
        }
        else if (id === MenuActions.ToggleFullscreen) {
            dispatch(toggleFullscreen());
        }
        else if (id === MenuActions.ExitDesigner) {
            AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.exitToDesignAreas);
            const href = window.location.href.includes('optical-communications')
                ? '/optical-communications/US/en/DNET'
                : '/corningstorefront/corning/en/USD/design-areas';
            window.location.href = href;
        }
        else if (id === MenuActions.SwitchToCanvas) {
            // show dialog to confirm and set state to canvas mode 
            dispatch(showDialog());
            dispatch(showControl());
            if (currentWorkspace) {
                dispatch(updateLastUsedDesignMode(DesignMode.CanvasMode))
            }
        }
        else if (id === MenuActions.SwitchToMap) {
            // show dialog to confirm and set state to map mode
            dispatch(showDialog());
            dispatch(showControl());
            if (currentWorkspace) {
                dispatch(updateLastUsedDesignMode(DesignMode.GISMode))
            }
        }
        else if (id === MenuActions.Logout) {
            dispatch(showDialog());
            dispatch(showControl());
            dispatch(logout());
        }
    }, [currentWorkspace, dispatch]);

    return { open, isGISMode, hasCustomer, isFullscreen, handleSelectedMenuItem }
}