import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Build } from '../../../../models/build';
import { DesignMode } from '../../../../models/design-mode';
import { DrawerType } from '../../../../redux/overlay.state';
import { loadWorkspaceBuildNapLastUpdated } from '../../../../redux/tap.state';
import {
    bulkBuildsSelector, designAreaSelector, designModeSelector, flexnapBuildsSelector,
    overlaySelector, schrodingerBuildsSelector, workspaceSelector
} from '../../../../selectors/root.selectors';

export const useDrawerMenuBody = () => {
    const dispatch = useDispatch();

    const designMode = useSelector(designModeSelector);
    const { designAreas: allDesignAreas, unassignedBuilds: unassignedBuildsIds } = useSelector(designAreaSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const schrodingerBuilds = useSelector(schrodingerBuildsSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);
    const { drawer } = useSelector(overlaySelector);
    const { currentWorkspace } = useSelector(workspaceSelector);

    const showUnassignedBuilds = designMode !== DesignMode.CanvasMode;

    const designAreas = allDesignAreas
        .filter((a) => a.designMode === designMode)
        .sort((a, b) => a.id - b.id);

    const sortedBuilds = [...flexNapBuilds, ...schrodingerBuilds, ...bulkBuilds]
        .filter((b) => b.designMode === designMode)
        .sort((a, b) => a.id - b.id) as Build[];

    const unassignedBuilds = sortedBuilds.filter(b => unassignedBuildsIds.includes(b.id));

    useEffect(() => {
        if (drawer === DrawerType.MainMenu && currentWorkspace) {
            loadWorkspaceBuildNapLastUpdated(currentWorkspace?.id)(dispatch);
        }
    }, [drawer, currentWorkspace, dispatch]);

    return { designAreas, unassignedBuilds, showUnassignedBuilds };
};
