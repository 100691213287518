import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

export enum DrawerType {
    MainMenu,
    WorkspaceDrawer
}

export enum DialogType {
    BuildInfo,
    WorkspaceInfo,
    WorkspaceDelete,
    Import,
    Preset,
    UnlockBuild,
    About,
    Thirdparty,
    ConvertToPretermLateral,
    RevertPretermLateral,
    SplicePlan,
    SplicePlanSetup,
    DeleteFlexNapBuild,
    DeleteSchrodingerBuild,
    DeleteBulkBuild,
    DeleteBulkCable,
    ResetTerminalExtension,
    RemoveImportedGisDataProgress,
    RemoveImportedGisDataSuccess,
    DownloadGisData,
    CreateNewBuild,
    CanvasAddSegment,
    CanvasDeleteSegment,
    UploadBuild,
    ReviewRequest,
    ConvertBulkBuild,
    RemoveGisData,
    CabinetPortMode,
    CabinetPortNumberManual,
    CabinetPortNumberAutomatic
}

export enum ControlType {
    Warnings,
    Layers,
    More,
    RemoveGisData,
    Review,
    ReviewNotification,
}

export enum SnackbarDesignation {
    UpdateInfrastructure,
}

export interface SnackbarPayload {
    designation: SnackbarDesignation;
}

export interface UpdateInfrastructureSnackbarPayload extends SnackbarPayload {
    elementTag: string;
}

export interface OverlayState {
    drawer?: DrawerType;
    dialog?: DialogType;
    control?: ControlType;
    snackbarPayload?: SnackbarPayload;
    showReviewNotification?: boolean;
}

const initialState: OverlayState = {
    drawer: undefined,
    dialog: undefined,
    control: undefined,
    showReviewNotification: true,
};

function showDrawerReducer(state: OverlayState, action: PayloadAction<DrawerType | undefined>): void {
    state.drawer = action.payload;
}

function showDialogReducer(state: OverlayState, action: PayloadAction<DialogType | undefined>): void {
    state.dialog = action.payload;
}

function showControlReducer(state: OverlayState, action: PayloadAction<ControlType | undefined>): void {
    state.control = action.payload;
}

function setSnackbarPayloadReducer(state: OverlayState, action: PayloadAction<SnackbarPayload | undefined>): void {
    state.snackbarPayload = action.payload;
}

function setShowNotificationPopupReducer(state: OverlayState, action: PayloadAction<boolean | undefined>): void {
    state.showReviewNotification = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'overlay',
    initialState,
    reducers: {
        showDrawer: showDrawerReducer,
        showDialog: showDialogReducer,
        showControl: showControlReducer,
        setSnackbarPayload: setSnackbarPayloadReducer,
        setShowNotificationPopup: setShowNotificationPopupReducer
    },
});

export { reducer as OverlayReducer };
export const { showDrawer, showControl, showDialog, setSnackbarPayload, setShowNotificationPopup } = actions;

export const showUpdateInfrastructureSnackbar = (elementTag: string) => {
    return (dispatch: Dispatch): void => {
        const snackbarPayload: UpdateInfrastructureSnackbarPayload = {
            designation: SnackbarDesignation.UpdateInfrastructure,
            elementTag,
        };
        dispatch(setSnackbarPayload(snackbarPayload));
    };
};

export const closeSnackbar = () => {
    return (dispatch: Dispatch): void => {
        dispatch(setSnackbarPayload());
    }
};
