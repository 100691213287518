import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTools } from '../design-tools/tool-definition';
import { ToolType } from '../design-tools/tooltype';
import { DesignMode } from '../models/design-mode';
import { showControl, showDialog } from '../redux/overlay.state';
import { StateModel } from '../redux/reducers';
import { clearSelection } from '../redux/selection.state';
import { setSelectedTool } from '../redux/tool.state';
import { designModeSelector, overlaySelector } from '../selectors/root.selectors';
import { useHistoryTool } from './map-tools/history-tool/history-tool.hooks';
import { usePanTool } from './map-tools/pan-tool.hooks';
import { PanKeys } from './map-tools/pan-tool.types';

export const Shortcuts = () => {
    const designMode = useSelector(designModeSelector);
    const { undo, redo } = useHistoryTool();
    const { handleKeyDown: handleCanvasKeyDown } = useCanvasShortcuts();
    const { handleKeyDown: handleGISKeyDown } = useGISShortcuts();

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.target instanceof HTMLInputElement) {
            return;
        }

        if (e.ctrlKey && e.key === 'z') {
            undo();
        }
        else if (e.ctrlKey && e.key === 'y') {
            redo();
        }

        if (designMode === DesignMode.GISMode) {
            handleGISKeyDown(e);
        }
        else if (designMode === DesignMode.CanvasMode) {
            handleCanvasKeyDown(e);
        }
    }, [undo, redo, designMode, handleCanvasKeyDown, handleGISKeyDown]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return (): void => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return null;
}

const useGISShortcuts = () => {
    const { buildType } = useSelector((state: StateModel) => state.tool);
    const { dialog, control } = useSelector(overlaySelector);

    const dispatch = useDispatch();

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (dialog === undefined) {
            const tools = getTools(buildType);
            tools.flat().forEach((t) => {
                if (e.key && t.shortcut.toUpperCase() === e.key.toUpperCase()) {
                    dispatch(setSelectedTool(t.toolType));
                    return;
                }
            });
        }

        if (e.key === 'Escape') {
            if (dialog === undefined && control === undefined) {
                dispatch(setSelectedTool(ToolType.Selection));
                dispatch(clearSelection());
                return;
            }

            dispatch(showControl());
            dispatch(showDialog());
        }
    }, [dispatch, dialog, buildType, control]);

    return { handleKeyDown }
}

const useCanvasShortcuts = () => {
    const { handlePanShortcut } = usePanTool();

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (Object.values(PanKeys).includes(e.key as PanKeys)) {
            handlePanShortcut(e);
        }
    }, [handlePanShortcut])

    return { handleKeyDown }
}
