import React from 'react';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { getPowerStatus, powerStatuses } from '../../../models/power-status';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { DeleteElementDialog } from '../element/delete-element-dialog';
import { InlineText } from '../../ui-elements/inline-text';
import { InlineSelect } from '../../ui-elements/inline-select';
import { useCabinetCard } from './cabinet-card.hooks';

export const CabinetCard = (): JSX.Element | null => {
    const {
        cabinet,
        coordinates,
        portCountValues,
        titleDeleteBtn,
        deleteDisabled,
        splicePlanButtonDisabled,
        showDeleteDialog,
        handleDeleteClick,
        handleDeleteDialogClose,
        unselectCabinet,
        handleIdOverrideUpdate,
        handlePowerStatusUpdate,
        handlePortCountUpdate,
        handleSplicePlanClick,
        deleteCabinet,
        t,
    } = useCabinetCard();

    if (!cabinet) {
        return null;
    }

    return (
        <Card id="cabinet-info" onCloseClick={unselectCabinet}>
            <CardHeaderComponent title={t(LocalizationKeys.Cabinet)} icon={require('./../../../icons/cabinet.png')} subheader={cabinet.tagOverride ?? cabinet.tag} />
            <CardContentComponent>
                <InlineText
                    id="idOverride"
                    label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.IDOverride) })}
                    value={cabinet.tagOverride ?? cabinet.tag}
                    align={'right'}
                    onUpdate={handleIdOverrideUpdate}
                />
                {CardPropertyInline(t(LocalizationKeys.Longitude), `${coordinates[0].toFixed(5)}`)}
                {CardPropertyInline(t(LocalizationKeys.Latitude), `${coordinates[1].toFixed(5)}`)}
                <InlineSelect
                    key="powerStatus"
                    label={t(LocalizationKeys.Power)}
                    value={cabinet.powerStatus}
                    values={powerStatuses}
                    displayValues={powerStatuses.map(getPowerStatus)}
                    onUpdate={handlePowerStatusUpdate}
                    maxHeight={144}
                />
                <InlineSelect
                    key="portCount"
                    label={t(LocalizationKeys.Size)}
                    value={cabinet.portCount}
                    values={portCountValues}
                    onUpdate={handlePortCountUpdate}
                    maxHeight={96}
                />
                <div className="split-item extra-info">{t(LocalizationKeys.IdUsedForCableLabeling)}</div>
                <div className="card-footer align-left">
                    <div title={titleDeleteBtn}>
                        <TextButton palette={MainPalettes.error} disabled={deleteDisabled} onClick={handleDeleteClick}>
                            <i className="material-icons">delete</i>
                        </TextButton>
                    </div>
                    <TextButton disabled={splicePlanButtonDisabled} onClick={handleSplicePlanClick}>{t(LocalizationKeys.SplicePlanButton)}</TextButton>
                </div>
            </CardContentComponent>
            <DeleteElementDialog
                open={showDeleteDialog}
                elementId={cabinet.id}
                onDelete={deleteCabinet}
                onClose={handleDeleteDialogClose}
            />
        </Card>
    );
};
