import { Viewport } from 'pixi-viewport';
import { IApplicationOptions } from 'pixi.js';
import { useRef } from 'react';

import { PixiComponentProps } from './pixi.types';
import { useWindowSize } from './viewport/viewport.hooks';

export const usePixiComponent = ({ width, height }: PixiComponentProps) => {
    const options: IApplicationOptions = { backgroundColor: 0xFFFFFF, antialias: true };
    const viewportRef = useRef<Viewport>();
    const windowSize = useWindowSize();

    // -6 because if we have the width/height exactly, scrollbar will take extra space.
    if (!width && !height) {
        width = windowSize.width - 6;
        height = windowSize.height - 6;
    }

    return {
        stageProps: {
            id: "pixi-stage",
            width,
            height,
            options
        },
        viewportRef,
    };
};