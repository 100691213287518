import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import { Handhole, HandholeData } from '../models/handhole';
import { PointLike } from '../models/pointlike';
import { HandholeService } from '../services/handhole.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { updateSchrodingerBuildLocationIds } from './element.actions';
import { push } from './history.state';
import { IMPORT_LOAD_THRESHOLD } from './import.state';
import { updateSelectedElement } from './selection.state';

export interface HandholeState {
    handholes: Handhole[];
}

const initialState: HandholeState = {
    handholes: [],
};

const loadAllReducer = (state: HandholeState, action: PayloadAction<Handhole[] | undefined>) => { state.handholes = action.payload || []; };

const addHandholeReducer = (state: HandholeState, action: PayloadAction<Handhole>) => { state.handholes.push(action.payload); };

const addHandholesReducer = (state: HandholeState, action: PayloadAction<Handhole[]>) => { state.handholes.push(...action.payload); };

const updateHandholeReducer = (state: HandholeState, action: PayloadAction<Handhole>) => {
    const handhole: Handhole = action.payload;
    const hIdx = state.handholes.findIndex((p) => p.id === handhole.id);
    if (hIdx < 0) {
        state.handholes.push(handhole);
    }
    else {
        state.handholes[hIdx] = handhole;
    }
};

const deleteHandholeReducer = (state: HandholeState, action: PayloadAction<number>) => {
    state.handholes = state.handholes.filter((m) => m.id !== action.payload);
};

const deleteImportedHandholesReducer = (state: HandholeState, action: PayloadAction<number>) => {
    state.handholes = state.handholes.filter((m) => m.importedGisDataId !== action.payload);
};

const { actions, reducer } = createSlice({
    name: 'handhole',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        addHandhole: addHandholeReducer,
        addHandholes: addHandholesReducer,
        updateHandhole: updateHandholeReducer,
        deleteHandhole: deleteHandholeReducer,
        deleteImportedHandholes: deleteImportedHandholesReducer,
    },
});

export { reducer as HandholeReducer };
export const { loadAll: loadHandholes, addHandhole: addHandholeAction, deleteHandhole, deleteImportedHandholes, updateHandhole: modifyHandhole } = actions;

export const getHandholes = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new HandholeService();
    const handholes = await service.getHandholes(bbox, required);
    if (handholes) {
        dispatch(actions.loadAll(handholes));
    }
};

export const getHandholesByIds = (ids: number[]) => async dispatch => {
    const service = new HandholeService();
    const handholes = await service.getHandholesByIds(ids);
    if (handholes) {
        handholes.forEach(handhole => dispatch(actions.updateHandhole(handhole)));
    }
};

export const addHandhole = (point: PointLike) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new HandholeService();
        const handhole = await service.addHandhole(point);
        if (handhole) {
            dispatch(actions.addHandhole(handhole));
            dispatch(push({ type: CommandType.CreateElement, payload: { id: handhole.id, type: handhole.type } }));
        }
    });
};

export const addHandholes = (data: HandholeData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new HandholeService();
        const success = await requestIsSuccess(service, service.addHandholes(data, importedGisId), true);
        if (data.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
            getHandholes(bbox)(dispatch);
        }
    });
};

export const updateHandhole = (oldHandhole: Handhole, newHandhole: Handhole) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new HandholeService();
        const handhole = await service.patchHandhole(oldHandhole, newHandhole);
        if (handhole) {
            dispatch(actions.updateHandhole(handhole));
            dispatch(updateSelectedElement(handhole));
            if (oldHandhole.tagOverride !== handhole.tagOverride) {
                updateSchrodingerBuildLocationIds(handhole);
            }
        }
    });
};
