import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ContainedButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../../dialogs/dialog.component';
import { useRemoveGisDataDialog } from './remove-gis-data-dialog.hooks';

export const RemoveGisDataDialog = (): JSX.Element | null => {
    const { t } = useTranslation();
    const { isOpen, fileName, timestamp, onClose, onLocateOnMap, onRemove } = useRemoveGisDataDialog();
    return (
        <DialogComponent
            className='remove-gis-data-dialog'
            open={isOpen}
            title={t(LocalizationKeys.RemoveLastImportedGisData)}
            positiveTitle={t(LocalizationKeys.Remove)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={onRemove}
            onNegativeClick={onClose}
            onClose={onClose}
            negativeStyleButton
        >
            <div className='content'>
                {/* <Typography variant={'body1'} label={t(LocalizationKeys.RemoveLastImportedGisDataMessage, { fileName, timestamp, interpolation: { escapeValue: false } })} /> */}
                <p className='message'>
                    <Trans t={t} i18nKey={LocalizationKeys.RemoveLastImportedGisDataMessage} values={{ fileName, timestamp }} tOptions={{ interpolation: { escapeValue: false } }} />
                </p>
                <ContainedButton className="positive-button" onClick={onLocateOnMap}>{t(LocalizationKeys.LocateOnMap)}</ContainedButton>
            </div>
        </DialogComponent>
    );
};
