import { SchrodingerSplicePlan } from '../../models/schrodinger/splice-plan';
import { SecuredService } from '../abstract-secured-v2.service';

export class SchrodingerSplicePlanService extends SecuredService {
    private readonly baseUrl = this.rootUrl + "api/schrodingerspliceplan";

    public getSplicePlan(buildId: number): Promise<SchrodingerSplicePlan | undefined> {
        return this.get(`${this.baseUrl}/build/${buildId}/locations`);
    }
}
