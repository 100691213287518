import '@orbit/dialog/build/index.css';
import './dialog.component.scss';

import React, { Component } from 'react';

import { DialogProps as MuiDialogProps } from '@mui/material';
import { Dialog, IDialogActions } from '@orbit/dialog';

interface DialogProps {
    open: boolean;
    title?: string;
    message?: string;
    positiveTitle?: string;
    negativeTitle?: string;
    onPositiveClick?: () => void;
    onNegativeClick?: () => void;
    onClose?: () => void;
    hideCloseButton?: boolean;
    disablePositive?: boolean;
    className?: string;
    negativeStyleButton?: boolean;
    muiDialogProps?: Partial<MuiDialogProps>;
}

export class DialogComponent extends Component<DialogProps> {

    public render(): JSX.Element {
        // eslint-disable-next-line react/prop-types
        const { children, open, title, message, negativeStyleButton, positiveTitle, negativeTitle, onNegativeClick, onPositiveClick, onClose, hideCloseButton, disablePositive, className, muiDialogProps } = this.props;

        const dialogActions: IDialogActions = {
            confirmText: positiveTitle ?? '',
            onConfirmClick: onPositiveClick ? onPositiveClick : () => { return; },
            cancelText: negativeTitle,
            onCancelClick: onNegativeClick ?? onClose,
            disableConfirm: disablePositive,
            actionGuidance: {
                button: 'confirm',
                severity: negativeStyleButton ? 'critical' : 'standard'
            }
        };

        return (
            <Dialog open={open} title={title} message={message} actions={positiveTitle ? dialogActions : undefined} onClose={onClose} hideCloseButton={hideCloseButton} className={`dialog ${className || ''}`} {...muiDialogProps}>
                {children}
            </Dialog>
        );
    }
}
