import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SplicePlanLocation } from '../../../models/flexnap-splice-plan';
import { DialogType } from '../../../redux/overlay.state';
import { dialogSelector } from '../../../selectors/root.selectors';
import { SplicePlanNavigationHooks } from './splice-plan-dialog.types';

const getDisplayedLocations = (
    currentIndex: number,
    displayedLimit: number,
    locations: SplicePlanLocation[]
) => {
    const isTruncated = locations.length > displayedLimit;
    const currentChunk = Math.floor(
        (currentIndex - (Number.isFinite(currentIndex / displayedLimit) ? 1 : 0)) / displayedLimit
    );

    let start = (currentChunk * displayedLimit) + 1;
    let end = start + displayedLimit > locations.length ? locations.length : start + displayedLimit;
    const isEndTruncated = isTruncated ? locations.length !== end : false;
    const isStartTruncated = isTruncated ? currentIndex > displayedLimit : false;

    if (!isStartTruncated) {
        start = 0;
        end = displayedLimit + 1;
    }
    const displayedLocations = isTruncated ? locations.slice(start, end) : locations;
    const width = isTruncated ? (displayedLimit + 2) * 40 : 'inherit';

    return { isStartTruncated, isEndTruncated, displayedLocations, width };
}

export const useSplicePlanNavigation = (props): SplicePlanNavigationHooks => {
    const dialog = useSelector(dialogSelector);
    const isDialogOpen = dialog === DialogType.SplicePlan;
    const { setCurrentLocation, currentLocation, locations, } = props;
    const displayedLimit = 14;

    const isFirstLocation = currentLocation === 0;
    const setFirstLocation = useCallback(() => setCurrentLocation(0), [setCurrentLocation]);
    const lastLocation = locations.length - 1;
    const isLastLocation = currentLocation === lastLocation;
    const setLastLocation = useCallback(() => setCurrentLocation(lastLocation), [setCurrentLocation, lastLocation]);
    const setPreviousLocation = useCallback(() =>
        setCurrentLocation(isFirstLocation ? 0 : currentLocation - 1),
        [setCurrentLocation, currentLocation, isFirstLocation]
    );
    const setNextLocation = useCallback(() =>
        setCurrentLocation(isLastLocation ? lastLocation : currentLocation + 1),
        [setCurrentLocation, currentLocation, lastLocation, isLastLocation]
    );

    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        if (!isDialogOpen) {
            return;
        }

        const { key, shiftKey, } = e;
        if (shiftKey && key === 'ArrowLeft') {
            setPreviousLocation();
        }
        if (shiftKey && key === 'ArrowRight') {
            setNextLocation();
        }
    }, [setPreviousLocation, setNextLocation, isDialogOpen]);
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
    const { isStartTruncated, isEndTruncated, displayedLocations, width } = getDisplayedLocations(currentLocation, displayedLimit, locations);

    return {
        setFirstLocation,
        setLastLocation,
        setPreviousLocation,
        setNextLocation,
        isLastLocation,
        isFirstLocation,
        isStartTruncated,
        isEndTruncated,
        displayedLocations,
        width,
    };
}
