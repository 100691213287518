import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DownloadState {
    buildIds: number[];
    designAreaNames: string[];
    designAreaIds: number[];
}

const initialState: DownloadState = {
    buildIds: [],
    designAreaNames: [],
    designAreaIds: []
};

function setDownloadStateReducer(state: DownloadState, action: PayloadAction<DownloadState>): void {
    const { buildIds, designAreaNames, designAreaIds } = action.payload;
    state.buildIds = buildIds;
    state.designAreaNames = designAreaNames;
    state.designAreaIds = designAreaIds;
}

const { actions, reducer } = createSlice({
    name: 'download',
    initialState,
    reducers: {
        setDownloadState: setDownloadStateReducer,
    },
});

export { reducer as DownloadReducer };
export const { setDownloadState } = actions;
