import { Build } from '../../../models/build';
import { DesignArea } from '../../../models/design-area';
import { Tap } from '../../../models/schrodinger/tap';
import { Tether } from '../../../models/schrodinger/tether';

export interface DesignAreaColorSelectorProps {
    color?: string;
    onApplyColor: (value: any) => void;
    onClose: () => void;
}

export interface DesignAreaBuildsListProps {
    builds: Build[];
    taps: Tap[];
    tethers: Tether[];
}

export interface DeleteDesignAreaProps {
    open: boolean;
    designAreaId: number;
    onDelete: () => void;
    onClose: () => void;
}

export interface DesignAreaColorPropertyProps {
    designAreaColor: string;
    onUpdate: (value: any, property: string) => void;
}

export interface DesignAreaInformationProps {
    designArea: DesignArea;
    countries: string[];
    regions: string[];
    onUpdate: (value: any, property: string) => void;
}

export const defaultDesignAreaColors = [
    '#FEF445', '#EF7962', '#C861F5', '#7CB1EF', '#52F0F7', '#C7F28D',
    '#FBC734', '#F24827', '#9C53F2', '#2D9BF0', '#35CDD4', '#8FD14F',
    '#C19007', '#D1381B', '#760FEF', '#006AD6', '#2FA8AD', '#28AD09',
];
