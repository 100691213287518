import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CommandFactory } from '../../../history/command-factory';
import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { DesignMode } from '../../../models/design-mode';
import { clear } from '../../../redux/history.state';
import { StateModel } from '../../../redux/reducers';
import { awaitingSchrodingerSessionUpdatesSelector, buildSelector, designModeSelector } from '../../../selectors/root.selectors';

export const useHistoryTool = () => {
    const { past, future } = useSelector((state: StateModel) => state.history);
    const last = past[past.length - 1];
    const next = future[future.length - 1];

    const { tempCanvasBuildSpans, displayedBuildId } = useSelector(buildSelector);
    const designMode = useSelector(designModeSelector);
    const inCanvasNewBuildMode = designMode === DesignMode.CanvasMode && !!tempCanvasBuildSpans;

    const awaitingSessionUpdate = useSelector(awaitingSchrodingerSessionUpdatesSelector).length; // prevent undo redo during ANY SSC actions

    const undoCommand = CommandFactory.GetCommand(last);
    const redoCommand = CommandFactory.GetCommand(next);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (designMode === DesignMode.CanvasMode) {
            dispatch(clear());
        }
    }, [displayedBuildId, designMode, dispatch]);

    const onUndo = useCallback(() => {
        if (undoCommand) {
            undoCommand.undo(dispatch);
        }
    }, [dispatch, undoCommand]);

    const onRedo = useCallback(() => {
        if (redoCommand) {
            redoCommand.redo(dispatch);
        }
    }, [dispatch, redoCommand]);

    const undoMessage = undoCommand?.undoMessage;
    const redoMessage = redoCommand?.redoMessage;

    return {
        undo: onUndo,
        redo: onRedo,
        undoMessage: undoMessage ? t(UndoRedoLocalizationKeys.UndoShortcut, { message: t(undoMessage.message, undoMessage.options) }) : undefined,
        redoMessage: redoMessage ? t(UndoRedoLocalizationKeys.RedoShortcut, { message: t(redoMessage.message, redoMessage.options) }) : undefined,
        undoDisabled: !past.length || inCanvasNewBuildMode || !!awaitingSessionUpdate,
        redoDisabled: !future.length || inCanvasNewBuildMode || !!awaitingSessionUpdate
    };
};