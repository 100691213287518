import i18next from 'i18next';
import { LocalizationKeys } from "../locales/types";

export enum PowerStatus {
    None = 1,
    Installed = 2,
    NeedsTransformer = 3,
}

export function getPowerStatus(status: PowerStatus): string {
    switch (status) {
        case PowerStatus.None:
            return i18next.t(LocalizationKeys.NoPower);
        case PowerStatus.Installed:
            return i18next.t(LocalizationKeys.TransformerInstalled);
        case PowerStatus.NeedsTransformer:
            return i18next.t(LocalizationKeys.NewTransformer);
    }
}

export const powerStatuses = [
    PowerStatus.None,
    PowerStatus.Installed,
    PowerStatus.NeedsTransformer,
];
