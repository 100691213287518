import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../locales/types';
import { DesignArea } from '../../models/design-area';
import { updateDesignAreaCoordinates } from '../../redux/design-area.state';
import { Command } from '../command';

export class ModifyDesignAreaCommand extends Command {
    private oldDa: DesignArea;
    private newDa: DesignArea;

    constructor({ oldDa, newDa }) {
        super();
        this.oldDa = oldDa;
        this.newDa = newDa;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoDesignAreaEditing };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoDesignAreaEditing };
    }

    public undo(dispatch: Dispatch): void {
        updateDesignAreaCoordinates(this.newDa, this.oldDa, true)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        updateDesignAreaCoordinates(this.oldDa, this.newDa, true)(dispatch);
        super.redo(dispatch);
    }
}