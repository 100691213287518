import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { StateModel } from '../../../redux/reducers';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { changeWorkspace, deleteWorkspace } from '../../../redux/workspace.state';
import { WorkspaceDeleteDialogProps } from "./workspace-dialog.types";

export const useWorkspaceDeleteDialog = (props: WorkspaceDeleteDialogProps) => {
    const { workspace } = props;
    const state = useSelector((state: StateModel) => state);
    const { dialog } = state.overlay;
    const { currentWorkspace, workspaces } = state.workspace;
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [workspaceName, setWorkspaceName] = useState("");

    useEffect(() => {
        setOpen(dialog === DialogType.WorkspaceDelete);
    }, [dialog]);

    useEffect(() => {
        setWorkspaceName(workspace!.name ? workspace!.name : workspace!.workspaceId);
    }, [workspace]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    const onDelete = useCallback(() => {
        dispatch(deleteWorkspace(workspace!.id));
        onClose();

        if (currentWorkspace!.id === workspace!.id) {
            const newCurrentWorkspace = workspaces.find(p => p.id !== workspace!.id);
            if (newCurrentWorkspace) {
                changeWorkspace(newCurrentWorkspace)(dispatch);
            }
            else {
                dispatch(showDialog(DialogType.WorkspaceInfo));
            }
        }
    }, [dispatch, workspace, currentWorkspace, workspaces, onClose]);

    return { open, onClose, onCancel, onDelete, workspaceName };
}