import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsAction, AnalyticsHelper } from '../../../../../helpers/analytics-helper';
import { DateHelper } from '../../../../../helpers/date-helper';
import { LocalizationKeys } from '../../../../../locales/types';
import { Workspace } from '../../../../../models/workspace';
import {
    loadNewCanvasBuildSpans, setSchrodingerBuildsLoaded
} from '../../../../../redux/build.state';
import { DialogType, DrawerType, showDialog, showDrawer } from '../../../../../redux/overlay.state';
import { StateModel } from '../../../../../redux/reducers';
import { changeWorkspace } from '../../../../../redux/workspace.state';
import { WorkspaceRowProps } from './drawer-workspace-row.types';

export const useWorkspaceRow = (props: WorkspaceRowProps) => {
    const { workspace } = props;
    const { t } = useTranslation();
    const state = useSelector((state: StateModel) => state);
    const { designAreas } = state.designarea;
    const { flexnapBuilds, schrodingerBuilds } = state.build;
    const { drawer } = state.overlay;
    const { currentWorkspace } = state.workspace;
    const { dialog } = state.overlay
    const { showToolbar, onRowEnter, onRowLeave, showLoadWorkspaceIcon, onIconEnter, onIconLeave, onIconClick } = useRowEvents(workspace, dialog, currentWorkspace);

    const [rowClassName, setRowClassName] = useState("workspace-drawer-row");
    const [workspaceName, setWorkspaceName] = useState("");
    const [workspaceDate, setWorkspaceDate] = useState(t(LocalizationKeys.LastDateModified, { date: DateHelper.formatStringToLocale(workspace.lastModifiedDate, t(LocalizationKeys.NotApplicable)) }) as string);
    const [showLongDesc, setShowLongDesc] = useState(false);
    const [workspaceDesc, setWorkspaceDesc] = useState("");
    const [workspaceLocation, setWorkspaceLocation] = useState("");
    const [nbDAs, setNbDAs] = useState(0);
    const [nbBuilds, setNbBuilds] = useState(0);
    const [nbExports, setNbExports] = useState(0);
    const [exportsPercentage, setExportsPercentage] = useState(0);
    const [workspaceIconOpacity, setWorkspaceIconOpacity] = useState(1);

    useEffect(() => {
        if (currentWorkspace && currentWorkspace?.id === workspace.id) {
            setRowClassName("workspace-drawer-row loaded");
            setWorkspaceIconOpacity(1);
        } else {
            setRowClassName("workspace-drawer-row");
            setWorkspaceIconOpacity(0.7);
        }
    }, [currentWorkspace, workspace]);

    useEffect(() => {
        if (workspace) {
            setWorkspaceName(workspace.name ? workspace.name : workspace.workspaceId);
            setWorkspaceDate(t(LocalizationKeys.LastDateModified, { date: DateHelper.formatStringToLocale(workspace.lastModifiedDate, t(LocalizationKeys.NotApplicable)) }));
            setWorkspaceDesc(workspace.description);
            if (workspace.city.length > 0) {
                setWorkspaceLocation(t(LocalizationKeys.WorkspaceLocation, { city: workspace.city, state: workspace.region, country: workspace.country }));
            } else {
                setWorkspaceLocation(t(LocalizationKeys.WorkspaceStateCountry, { state: workspace.region, country: workspace.country }));
            }
        }
    }, [workspace, t]);

    useEffect(() => {
        if (drawer !== DrawerType.WorkspaceDrawer) {
            setShowLongDesc(false);
        }
    }, [drawer]);

    useEffect(() => {
        const foundDAs = designAreas.filter(a => a.workspaceId === workspace.id);
        if (foundDAs.length > 0) {
            setNbDAs(foundDAs.length);
        }
    }, [designAreas, workspace.id]);

    useEffect(() => {
        const foundFlexnapBuilds = flexnapBuilds.filter(f => f.workspaceId === workspace.id);
        const foundSchrodingerBuilds = schrodingerBuilds.filter(s => s.workspaceId === workspace.id);
        const nbBuilds = foundFlexnapBuilds.length + foundSchrodingerBuilds.length;
        setNbBuilds(nbBuilds);

        const exportedFlexnapBuilds = foundFlexnapBuilds.filter(f => f.uploadedDateTime);
        const exportedSchrodingerBuilds = foundSchrodingerBuilds.filter(s => s.uploadedDateTime);
        const nbExports = exportedFlexnapBuilds.length + exportedSchrodingerBuilds.length;
        setNbExports(nbExports);

        const exportsPercentage = nbBuilds > 0 && nbExports > 0 ? Math.round(nbExports / nbBuilds) : 0;
        setExportsPercentage(exportsPercentage);
    }, [flexnapBuilds, schrodingerBuilds, workspace]);

    return { rowClassName, onRowEnter, onRowLeave, showLoadWorkspaceIcon, onIconEnter, onIconLeave, onIconClick, workspace, workspaceName, workspaceDate, showToolbar, showLongDesc, setShowLongDesc, workspaceDesc, workspaceLocation, nbDAs, nbBuilds, nbExports, exportsPercentage, workspaceIconOpacity };
}

const useRowEvents = (workspace: Workspace, dialog: DialogType | undefined, currentWorkspace: Workspace | undefined) => {
    const dispatch = useDispatch();

    const [showToolbar, setShowToolbar] = useState(false);
    const [showLoadWorkspaceIcon, setShowLoadWorkspaceIcon] = useState(false);

    const onRowEnter = useCallback((hoveredWorkspaceId: number) => {
        if (hoveredWorkspaceId === workspace.id) {
            setShowToolbar(true);
        }
    }, [workspace]);

    const onRowLeave = useCallback(() => {
        if (dialog !== DialogType.WorkspaceInfo && dialog !== DialogType.WorkspaceDelete) {
            setShowToolbar(false);
        }
    }, [dialog]);

    const onIconEnter = useCallback((hoveredWorkspaceId: number) => {
        if (hoveredWorkspaceId === workspace.id && currentWorkspace!.id !== hoveredWorkspaceId) {
            setShowLoadWorkspaceIcon(true);
        }
    }, [workspace, currentWorkspace]);

    const onIconLeave = useCallback(() => {
        setShowLoadWorkspaceIcon(false);
    }, []);

    const onIconClick = useCallback(() => {
        if (currentWorkspace?.id !== workspace.id) {
            AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.openWorkspace);
            dispatch(setSchrodingerBuildsLoaded());
            dispatch(showDialog());
            dispatch(loadNewCanvasBuildSpans());
            dispatch(changeWorkspace(workspace));
            dispatch(showDrawer(DrawerType.MainMenu));
        }
    }, [currentWorkspace, workspace, dispatch]);

    return { showToolbar, onRowEnter, onRowLeave, showLoadWorkspaceIcon, onIconEnter, onIconLeave, onIconClick };
}