import * as rfc from 'rfc6902';
import { Tap } from '../models/tap';
import { Tether } from '../models/tether';
import { SecuredService } from './abstract-secured-v2.service';

export class TetherService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/tether';

    public getAllTethers(): Promise<Tether[] | undefined> {
        return this.get(this.baseUrl);
    }

    public addTether(napId: number): Promise<{ tether: Tether; tap: Tap | undefined } | undefined> {
        return this.post(this.baseUrl, { napId });
    }

    public patchTether(oldTether: Tether, newTether: Tether): Promise<Tether | undefined> {
        const patch = rfc.createPatch(oldTether, newTether);
        return this.patch(`${this.baseUrl}/${oldTether.id}`, patch);
    }

    public deleteTether(tether: Tether): Promise<void> {
        return this
            .delete(`${this.baseUrl}/${tether.id}`);
    }

    public getTetherConfiguration(tether: Tether): Promise<TetherConfigurationResult | undefined> {
        return this
            .get(`${this.baseUrl}/configuration/${tether.id}`);
    }
}

export interface TetherConfigurationResult {
    fiberCounts: number[];
    legLengths: {
        ft: number[];
        m: number[];
    };
}