import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class FlexNapNapsCountValidator implements Validator<FlexNapBuildData> {
    public validate(build: FlexNapBuildData): ValidationResult | null {
        const napsCount = build.naps.length;
        const maxNapCount = 18;
        if (napsCount > maxNapCount) {
            const extent = build.getExtent();
            return { 
                buildId: build.id,
                itemId: null,
                itemType: ItemType.Build,
                shortDescription: i18next.t(LocalizationKeys.RestrictFlexNapNaps, { maxNapCount: maxNapCount }), 
                type: ValidationResultType.Warning, 
                extent 
            };
        }

        return null;
    }

    public canValidate(entity: any): boolean {
        return entity instanceof FlexNapBuildData;
    }
}
