import { TFunction } from 'i18next';
import { LocalizationKeys } from '../locales/types';
import { Bore } from '../models/bore';
import { BuildSegment } from '../models/build-segment';
import { BulkBuild } from '../models/bulk/bulk-build';
import { Cabinet } from '../models/cabinet';
import { CpqCablePart } from '../models/cpqcablepart';
import { CpqTetherPart } from '../models/cpqtetherpart';
import { DataValue } from '../models/datavalue';
import { DesignArea } from '../models/design-area';
import { Element } from '../models/element';
import { FlexNapBuild } from '../models/flexnap-build';
import { Nap } from '../models/nap';
import { Pole } from '../models/pole';
import { PretermLateral } from '../models/preterm-lateral';
import { SplicePoint } from '../models/splice-point';
import { Tap } from '../models/tap';
import { Tether } from '../models/tether';
import { BoreData } from './data/bore-data';
import { BulkBuildData } from './data/bulk-build-data';
import { CabinetData } from './data/cabinet-data';
import { DesignAreaData } from './data/design-area-data';
import { ElementData } from './data/element-data';
import { FlexNapBuildData } from './data/flexnap-build-data';
import { NapData } from './data/nap-data';
import { PoleData } from './data/pole-data';
import { FlexNapPretermBuildData } from './data/preterm-build-data';
import { SegmentData } from './data/segment-data';
import { SplicePointData } from './data/splice-point-data';
import { TapData } from './data/tap-data';
import { TetherData } from './data/tether-data';
import { IValidationProps, SPECIAL_CHAR_REGEX, ONLY_ALPHA_REGEX, ONLY_NUM_REGEX } from './validation-helper.types';

export class ValidationHelper {
    public static createBuildData(build: FlexNapBuild, segments: BuildSegment[], naps: NapData[], elements: Element[], parts: CpqCablePart[], designAreas: DesignAreaData[], pretermLateral?: PretermLateral): FlexNapBuildData {
        const part = parts.find((p) => p.part === build.partNumber);
        const bSegments: SegmentData[] = [];
        segments.forEach((s) => {
            const segmentData = ValidationHelper.createSegmentData(s, elements, build.installationType);
            if (segmentData) {
                bSegments.push(segmentData);
            }
        });
        const bNaps = naps.filter((n) => n.buildId === build.id);
        return new FlexNapBuildData(build.id, build.name, new DataValue(build.coSlack, build.slackUnit), new DataValue(build.fieldSlack, build.slackUnit), build.fiberCount, bSegments, bNaps, designAreas, build.installationType, part, pretermLateral);
    }

    public static createBulkBuildData(build: BulkBuild, segments: BuildSegment[], designAreas: DesignAreaData[], splicePoints: SplicePointData[] | undefined, elements: Element[]): BulkBuildData {
        const buildSegments: SegmentData[] = [];
        segments.forEach(s => {
            const segmentData = ValidationHelper.createSegmentData(s, elements, build.installationType);
            if (segmentData) {
                buildSegments.push(segmentData);
            }
        });
        const buildSplicePoints = splicePoints?.filter(s => s.buildId === build.id);
        return new BulkBuildData(build.id, build.name, new DataValue(build.fieldSlack, build.slackUnit), buildSegments.sort((s1, s2) => s1.position - s2.position), designAreas, buildSplicePoints);
    }

    public static createPretermBuildData(flexnapBuildsData: FlexNapBuildData[]): FlexNapPretermBuildData[] {
        const pretermBuildsData: FlexNapPretermBuildData[] = [];
        for (const build of flexnapBuildsData) {
            if (build.pretermLateral) {
                const parentBuild = flexnapBuildsData.find(b => b.id === build.pretermLateral?.parentBuildId);
                if (parentBuild) {
                    pretermBuildsData.push(new FlexNapPretermBuildData(build, parentBuild));
                }
            }
        }
        return pretermBuildsData;
    }

    public static calculateCableLength(build: FlexNapBuildData): DataValue {
        const unit = build.coSlack.units;
        const totalLength = build.segments.map((s) => ValidationHelper.calculateSegmentLength(s)).reduce((prv, t) => prv + t.toUnit(unit).value, 0);
        return new DataValue(totalLength, unit);
    }

    private static calculateSegmentLength(segment: SegmentData): DataValue {
        const selectedsegmentSpan = segment.designSpan;
        const length = selectedsegmentSpan.value + segment.slackLoop.toUnit(selectedsegmentSpan.units).value;
        return new DataValue(length, selectedsegmentSpan.units);
    }

    private static createSegmentData(segment: BuildSegment, elements: Element[], installationType?: string): SegmentData | undefined {
        const { fromId, toId } = segment;
        const fromToElements = (fromId && toId) ? ValidationHelper.getFromToElements(fromId, toId, elements) : undefined;
        if (fromToElements) {
            const { from, to } = fromToElements;
            const measuredSpan = segment.measuredSpan && segment.measuredSpanUnit ? new DataValue(segment.measuredSpan, segment.measuredSpanUnit) : undefined;
            const fromData: ElementData = { id: from.id, location: { x: from.x, y: from.y } };
            const toData: ElementData = { id: to.id, location: { x: to.x, y: to.y } }
            return new SegmentData(segment.id, segment.buildId, segment.position, fromData, toData, segment.slackLoop, segment.slackUnit, segment.designSpan, segment.designSpanUnit, measuredSpan?.value, measuredSpan?.units, installationType, segment.riserSpan, segment.riserSpanUnit);
        }

        return undefined;
    }

    public static createNapData(nap: Nap, builds: FlexNapBuild[], taps: Tap[], tethers: Tether[], elements: Element[], tetherParts: CpqTetherPart[]): NapData | undefined {
        const element = elements.find((p) => p.id === nap.elementId);
        const build = builds.find(b => b.id === nap.buildId);
        const childPretermBuilds = builds.filter(b => b.pretermLateral?.parentNapId === nap.id && b.pretermLateral?.parentBuildId === build?.id);
        return element ? new NapData(nap.id, nap.buildId, element.id, { x: element.x, y: element.y }, ValidationHelper.createTapData(taps.filter((t) => t.napId === nap.id), build!, tethers, tetherParts), childPretermBuilds) : undefined;
    }

    private static createTapData(taps: Tap[], build: FlexNapBuild, tethers: Tether[], tetherParts: CpqTetherPart[]): TapData[] {
        return taps.map((t) => new TapData(t.id, t.tapIndex, ValidationHelper.createTetherData(tethers.filter((tether) => tether.tapId === t.id), build?.installationType, tetherParts)));
    }

    private static createTetherData(tethers: Tether[], installationType: string, tetherParts: CpqTetherPart[]): TetherData[] {
        return tethers.map((t) => new TetherData(t.id, t.legLength, t.legLengthUnit, t.loopback, t.tetherIndex, t.fiberCount, ValidationHelper.filterTetherParts(t, tetherParts, installationType), t.terminal));
    }

    private static filterTetherParts(t: Tether, tetherParts: CpqTetherPart[], installationType: string): CpqTetherPart | undefined {
        const availableParts = tetherParts.filter(tp => tp.tether_Item === t.partNumber);
        if (availableParts.length > 0) {
            const valid = availableParts.find(t => t.installation_Type === installationType);
            if (valid) {
                return valid;
            } else {
                return availableParts[0];
            }
        }
    }

    public static createSplicePointData(splicePoint: SplicePoint, elements: Element[]): SplicePointData {
        const element = elements.find(e => e.id === splicePoint.elementId);
        return new SplicePointData(splicePoint.id, splicePoint.buildId, splicePoint.elementId, { x: element?.x ?? 0, y: element?.y ?? 0 });
    }

    public static createPoleData(poles: Pole[]): PoleData[] {
        return poles.map((p) => new PoleData(p.id, p.tag, { x: p.x, y: p.y }));
    }

    public static createCabinetData(cabinets: Cabinet[]): CabinetData[] {
        return cabinets.map((c) => new CabinetData(c.id, { x: c.x, y: c.y }, c.portCount));
    }

    private static getFromToElements(fromId: number, toId: number, elements: Element[]): { from: Element; to: Element } | undefined {
        const from = elements.find((p) => p.id === fromId);
        const to = elements.find((p) => p.id === toId);
        return from && to ? { from, to } : undefined;
    }

    public static createBoreData(bores: Bore[]): BoreData[] {
        return bores.map((b) => new BoreData(b.id, b.route, b.measuredLength, b.lengthUnit, b.isImported, b.conduitSize));
    }

    public static createDesignAreaData(designAreas: DesignArea[]): DesignAreaData[] {
        const designAreaData: DesignAreaData[] = [];
        for (const designArea of designAreas) {
            if (designArea.polygon) {
                const { id, workspaceId, projectId, description, country, region, polygon } = designArea;
                designAreaData.push(new DesignAreaData(id, workspaceId, projectId, description, country, region, polygon));
            }
        }
        return designAreaData;
    }

    public static validateString(value: string, t: TFunction, { empty = true, specialCharacters = true, characterType = 'alphanumeric' }: IValidationProps): string | undefined {
        if (!empty && !value.trim()) {
            return t(LocalizationKeys.Required)
        } else if (!specialCharacters && SPECIAL_CHAR_REGEX.test(value)) {
            return t(LocalizationKeys.NoSpecialChar) 
        } else if (characterType === 'numeric' && !ONLY_NUM_REGEX.test(value)) {
            return t(LocalizationKeys.NumericOnly)
        } else if (characterType === 'non-numeric' && !ONLY_ALPHA_REGEX.test(value)) {
            return t(LocalizationKeys.AlphaOnly)
        }

        return undefined
    }
}
