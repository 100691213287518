import React from 'react';

import { ClickAwayListener } from '@mui/material';
import { showControl } from '../../redux/overlay.state';
import { clearSelection } from '../../redux/selection.state';
import { PopupMenu, PopupMenuItem } from '../ui-elements/popup-menu';


export interface MoreOptionsProps {
    open: boolean;
    options: PopupMenuItem[];
    handleSelectedOption: (id: string) => void;
}

export const MoreOptionsMenu = (props: MoreOptionsProps): JSX.Element | null => {
    const { open, options, handleSelectedOption } = props;

    if (!open) {
        return null;
    }

    const closeOptionsMenu = () => {
        showControl(undefined);
        clearSelection();
    }
    
    return (
        <ClickAwayListener onClickAway={closeOptionsMenu}>
            <div>
                <PopupMenu items={options} visible={open} onMenuItemSelected={handleSelectedOption} />
            </div>
        </ClickAwayListener>
    );
}