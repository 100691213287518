import { useState } from 'react';
import { useSelector } from 'react-redux';

import { OpticalTether } from '../../../models/schrodinger/optical-tether';
import { PowerTether } from '../../../models/schrodinger/power-tether';
import { TetherType } from '../../../models/schrodinger/tether-type';
import { displayedBuildAwaitingSessionUpdateSelector } from '../../../selectors/build.selectors';
import { TetherSvgProps } from './tether-svg.types';

export const useTetherSvg = (props: TetherSvgProps) => {
    const { tether, pagePosition, index, selected, onClick } = props;

    const [hover, setHover] = useState(false);
    const awaitingSessionUpdate = useSelector(displayedBuildAwaitingSessionUpdateSelector);

    const showEditIcon = !selected && hover && !awaitingSessionUpdate;
    const backdropClass = selected ? 'selected' : hover ? 'hover' : null;
    const isOpticalTether = tether.type === TetherType.Optical
    let labelText = "";
    if (isOpticalTether) {
        if ((tether as OpticalTether)?.fiberCountShortDescription) {
            labelText = `${(tether as OpticalTether).fiberCountShortDescription}`
            labelText = labelText.replace(/\D+/, "F");
        }
    } else {
        if ((tether as PowerTether).pairsShortDescription) {
            labelText = `${(tether as PowerTether).pairsShortDescription}P`
        }
    }
    const tetherTypeClass = isOpticalTether ? "optical" : "power";

    const handleMouseEnter = (): void => {
        !awaitingSessionUpdate && setHover(true);
    }

    const handleMouseLeave = (): void => {
        !awaitingSessionUpdate && setHover(false);
    }

    const handleClick = (): void => {
        !awaitingSessionUpdate && onClick(tether.id);
    }

    return {
        subunitType: tether.type, pagePosition, index, selected, showEditIcon, backdropClass, labelText, tetherTypeClass,
        handleMouseEnter, handleMouseLeave, handleClick
    };
}
