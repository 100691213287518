import { useCallback, useEffect, useState } from 'react';

import { ColorSelectorProps } from './color-selector.types';

export const useSelectedColorUpdated = (props: ColorSelectorProps) => {
    const [selectedColor, setSelectedColor] = useState(props.color);

    useEffect(() => {
        setSelectedColor(props.color);
    }, [props.color]);

    const onColorUpdated = useCallback(() => {
        props.onApplyColor(selectedColor ?? "");
        props.onClose();
    }, [props, selectedColor]);

    return { selectedColor, onColorUpdated, setSelectedColor };
};

export const useColorSelector = () => {
    const [colorSelector, showColorSelector] = useState(false);

    return { colorSelector, showColorSelector };
};