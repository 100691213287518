import React from 'react';

import { PixiComponent } from '../../pixi/pixi';
import { useWindowSize } from '../../pixi/viewport/viewport.hooks';
import { VIEWPORT_X_BUFFER, VIEWPORT_Y_BUFFER } from '../../pixi/viewport/viewport.types';
import { CanvasOverlayComponent } from '../overlay/canvas-overlay';
import { Build } from './build/build';
import { useCanvas } from './canvas.hooks';

export const CanvasComponent = (): JSX.Element => {
    const {
        build,
        buildSegments, tempCanvasBuildSpans,
        displayUnits,
    } = useCanvas();

    const { width, height } = useWindowSize();

    return (
        <>
            <CanvasOverlayComponent />
            <PixiComponent>
                <Build
                    position={{ x: (width - VIEWPORT_X_BUFFER), y: (height - VIEWPORT_Y_BUFFER) }}
                    build={build}
                    segments={buildSegments}
                    temporarySpans={tempCanvasBuildSpans}
                    displayUnits={displayUnits}
                />
            </PixiComponent>
        </>
    );
}