import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../locales/types';
import { DialogComponent } from '../dialogs/dialog.component';
import { NapPretermDeleteProps } from './nap-diagram.types';

export const NapPretermDeleteDialog = (props: NapPretermDeleteProps): JSX.Element | null=> {
    const { t } = useTranslation();
    const { open, buildIds, onPositiveClick, onClose } = props;

    const rootElement = document.getElementById('react-app-root');

    return !props.open || !rootElement ? null :
        createPortal(
            <DialogComponent
                open={open}
                title={t(LocalizationKeys.DeleteNapWithPreterm, { buildIds: buildIds.join(', ') })}
                positiveTitle={t(LocalizationKeys.Delete)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={onPositiveClick}
                onClose={onClose}
                negativeStyleButton
            />,
            rootElement
        );
};
