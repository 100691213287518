import React from 'react';

export const BoreIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24">
            <g transform="translate(14 5)" fill="#005293">
                <path d="M 6.792899608612061 3.5 L 1.207099795341492 3.5 L 3.999999761581421 0.707099974155426 L 6.792899608612061 3.5 Z" stroke="none" />
                <path d="M 3.999999761581421 1.414220094680786 L 2.414219856262207 3 L 5.585779666900635 3 L 3.999999761581421 1.414220094680786 M 3.999999761581421 0 L 8 4 L -4.76837158203125e-07 4 L 3.999999761581421 0 Z" stroke="none" fill="#005293" />
            </g>
            <g transform="translate(10 20) rotate(180)" fill="#005293">
                <path d="M 6.792899608612061 3.5 L 1.207099795341492 3.5 L 3.999999761581421 0.707099974155426 L 6.792899608612061 3.5 Z" stroke="none" />
                <path d="M 3.999999761581421 1.414220094680786 L 2.414219856262207 3 L 5.585779666900635 3 L 3.999999761581421 1.414220094680786 M 3.999999761581421 0 L 8 4 L -4.76837158203125e-07 4 L 3.999999761581421 0 Z" stroke="none" fill="#005293" />
            </g>
            <path transform="translate(-24 -339)" d="M42,347.522v7.93a3.062,3.062,0,0,1-2.873,2.527c-2.527.174-3.137-2.527-3.137-2.527v-7.93a3.183,3.183,0,0,0-2.876-2.527,3.026,3.026,0,0,0-3.137,2.527v7.93" fill="none" stroke="#005293" strokeLinecap="round" strokeWidth="2" strokeDasharray="1 3" />
        </svg>
    );
};
