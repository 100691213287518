import { SchrodingerBuild } from '../../../models/schrodinger-build';

export interface BuildCardProps {
    build: SchrodingerBuild;
    segmentCount: number;
    designAreaDisplayText: string;
    nodeCount: number;
    collapsed?: boolean;
    disabled?: boolean;
    currentWorkspaceId?: number;
    toggleDeleteBuildDialog: () => void;
    onBuildInfoClick: () => void;
}

export const defaultBuildColors = [
    '#FFE04D', '#F49C69', '#FF78FB', '#9FCEF3', '#7CF8F0', '#9AEB44',
    '#FDC436', '#F37227', '#CF00F4', '#3D99D7', '#00C3BF', '#8CBE60',
    '#BA9039', '#AA4D21', '#7D0091', '#126495', '#008988', '#508937',
];
