import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { CsticValue } from '../../../../models/cstic-value';
import { PowerTetherOptions } from '../../../../models/schrodinger/power-tether-options';
import { updateSchrodingerBuildFromSession } from '../../../../redux/build.state';
import { StateModel } from '../../../../redux/reducers';
import { deleteTether, updateTether } from '../../../../redux/schrodinger/tap.state';
import { selectTetherSchrodinger } from '../../../../redux/selection.state';
import { setTapAwaiting, unsetTapAwaiting } from '../../../../redux/workspace.state';
import {
    selectedTapAwaitingSessionUpdateSelector
} from '../../../../selectors/schrodinger/taps.selectors';
import { TetherService } from '../../../../services/schrodinger/tether.service';
import { PowerTetherProps } from './power-tether-card.types';

export const usePowerTetherCard = (props: PowerTetherProps) => {
    const { selectedTether } = props;

    const { t } = useTranslation();
    const subheader = `${t(LocalizationKeys.Tether)} ${selectedTether.id}`;

    const workspace = useSelector((state: StateModel) => state);
    const tetherTap = workspace.tapsSchrodinger.taps.find(t => t.id === selectedTether.tapId);

    const [partNumber, setPartNumber] = useState<string>();
    const [lengthId, setLengthId] = useState<string>();
    const [maximumLoadId, setMaximumLoadId] = useState<string>();
    const [wireSizeId, setWireSizeId] = useState<string>();
    const [pairsId, setPairsId] = useState<string>();
    const [powerUnitId, setPowerUnitId] = useState<string>();
    const [wireSizeRecommendation, setWireSizeRecommendation] = useState<string>();
    const [pairsRecommendation, setPairsRecommendation] = useState<string>();

    const [powerTetherOptions, setPowerTetherOptions] = useState<PowerTetherOptions>();

    const disabledWhenAwaiting = useSelector(selectedTapAwaitingSessionUpdateSelector);

    const dispatch = useDispatch();

    const setSelectedOptions = useCallback(async (options: PowerTetherOptions): Promise<void> => {
        setLengthId(options.lengths.find(x => x.assigned)?.id);
        setMaximumLoadId(options.maximumLoads.find(x => x.assigned)?.id);
        setWireSizeId(options.wireSizes.find(x => x.assigned)?.id);
        setPairsId(options.pairsOptions.find(x => x.assigned)?.id);
        setPowerUnitId(options.powerUnits.find(x => x.assigned)?.id);
        setWireSizeRecommendation(options.recommendations?.conductorTypeRecommendation);
        setPairsRecommendation(options.recommendations?.numberOfConductorPairsRecommendation);
    }, []);

    const updatePowerTether = useCallback(async (selectedCsticValue?: CsticValue): Promise<void> => {
        const service = new TetherService();
        if (selectedCsticValue) {
            selectedCsticValue.assigned = true;
            if (tetherTap) dispatch(setTapAwaiting(tetherTap));
            const response = await service.updatePowerTether(selectedTether.id, selectedCsticValue);
            if (!response) {
                return;
            }
            dispatch(updateTether(response.tether));
            setPowerTetherOptions(response.options);
            setSelectedOptions(response.options);
            if (tetherTap) dispatch(unsetTapAwaiting(tetherTap));

            if (tetherTap?.buildId && response.tether.partNumber) {
                dispatch(updateSchrodingerBuildFromSession(tetherTap.buildId));
            }
        }
    }, [dispatch, selectedTether, tetherTap, setSelectedOptions]);

    const getOptions = useCallback(async (): Promise<void> => {
        if (tetherTap) dispatch(setTapAwaiting(tetherTap));
        const service = new TetherService();
        const options = await service.getPowerTetherOptions(selectedTether.id);
        if (options) {
            setPowerTetherOptions(options);
            setSelectedOptions(options);
        }
        if (tetherTap) dispatch(unsetTapAwaiting(tetherTap));
    }, [dispatch, selectedTether, tetherTap, setSelectedOptions]);

    useEffect(() => {
        // set the hook states based on the selectedTether state
        const {
            partNumber: partNumberState, lengthId: lengthIdState, maximumLoadId: maximumLoadIdState, wireSizeId: wireSizeIdState, pairsId: pairsIdState, powerUnitId: powerUnitIdState
        } = selectedTether;
        setPartNumber(partNumberState);
        setLengthId(lengthIdState);
        setMaximumLoadId(maximumLoadIdState);
        setWireSizeId(wireSizeIdState);
        setPairsId(pairsIdState);
        setPowerUnitId(powerUnitIdState);

        getOptions();
    }, [getOptions, selectedTether])

    const unselectTether = useCallback((): void => {
        dispatch(selectTetherSchrodinger());
    }, [dispatch]);

    const handleLengthChange = useCallback((value: any): void => {
        const updatedLegLength = powerTetherOptions?.lengths.find(l => l.id === value);
        updatePowerTether(updatedLegLength);
        setLengthId(updatedLegLength?.id);
    }, [powerTetherOptions, updatePowerTether]);

    const handleMaximumLoadChange = useCallback((value: string): void => {
        const selectedMaximumLoadCstic = powerTetherOptions?.maximumLoads.find(ml => ml.id === value);
        updatePowerTether(selectedMaximumLoadCstic);
        setMaximumLoadId(selectedMaximumLoadCstic?.id);
    }, [powerTetherOptions, updatePowerTether]);

    const handleWireSizeChange = useCallback((value: string): void => {
        const updatedWireSize = powerTetherOptions?.wireSizes.find(ws => ws.id === value);
        updatePowerTether(updatedWireSize);
        setWireSizeId(updatedWireSize?.id);
    }, [powerTetherOptions, updatePowerTether]);

    const handlePairsChange = useCallback((value: string): void => {
        const updatedPairs = powerTetherOptions?.pairsOptions.find(p => p.id === value);
        updatePowerTether(updatedPairs);
        setPairsId(updatedPairs?.id);
    }, [powerTetherOptions, updatePowerTether]);

    const handlePowerUnitChange = useCallback((value: string): void => {
        const updatedPowerUnit = powerTetherOptions?.powerUnits.find(p => p.id === value);
        updatePowerTether(updatedPowerUnit);
        setPowerUnitId(updatedPowerUnit?.id);
    }, [powerTetherOptions, updatePowerTether]);

    const handleDeleteTether = useCallback((): void => {
        if (tetherTap) {
            deleteTether(selectedTether.id, tetherTap)(dispatch);
            unselectTether();
        }
    }, [dispatch, selectedTether.id, tetherTap, unselectTether]);

    return {
        subheader, lengthId, maximumLoadId, wireSizeId, pairsId, powerUnitId, powerTetherOptions, partNumber, wireSizeRecommendation, pairsRecommendation, disabledWhenAwaiting,
        unselectTether, handleMaximumLoadChange, handleWireSizeChange, handlePairsChange, handleLengthChange, handlePowerUnitChange, handleDeleteTether
    };
}
