import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Workspace } from '../../../../models/workspace';
import { StateModel } from '../../../../redux/reducers';
import {
    alphabeticalFilter, defaultFilterOptions
} from '../sub-header/drawer-workspace-subheader.types';
import { WorkspaceDrawerFilterContext } from './drawer-workspace-filter-context';

const sortAlphabetically = (a: Workspace, b: Workspace): number => (a.name || a.workspaceId).localeCompare(b.name || b.workspaceId);

const sortByDateModified = (a: Workspace, b: Workspace): number => {
    const bDate = b.lastModifiedDate ?? 0;
    const aDate = a.lastModifiedDate ?? 0;
    return new Date(bDate).getTime() - new Date(aDate).getTime();
};

const filterByText = (p: Workspace, filter: string): boolean => {
    const lowerCaseFilter = filter.toLowerCase();
    return p.name.toLowerCase().includes(lowerCaseFilter)
        || p.workspaceId.toLowerCase().includes(lowerCaseFilter)
        || p.description.toLowerCase().includes(lowerCaseFilter)
        || p.zipCode?.toLowerCase().includes(lowerCaseFilter)
        || p.city.toLowerCase().includes(lowerCaseFilter)
        || p.country.toLowerCase().includes(lowerCaseFilter)
        || p.region.toLowerCase().includes(lowerCaseFilter);
};

const filterAndSortWorkspaces = (workspaces: Workspace[], sortFn: (a: Workspace, b: Workspace) => number, value?: string): Workspace[] => {
    const filteredWorkspaces = !value ? workspaces : workspaces.filter(p => filterByText(p, value));
    return filteredWorkspaces.slice().sort(sortFn);
};

export const useWorkspaceDrawerFilter = () => {
    const { workspaces } = useSelector((state: StateModel) => state.workspace);
    const { setWorkspaceList } = useContext(WorkspaceDrawerFilterContext);

    const [filterValue, setFilterValue] = useState<string>();
    const [selectedFilterOption, setSelectedFilterOption] = useState(alphabeticalFilter.value);

    useEffect(() => {
        const sortFn = selectedFilterOption === alphabeticalFilter.value ? sortAlphabetically : sortByDateModified;
        setWorkspaceList(filterAndSortWorkspaces(workspaces, sortFn, filterValue));
    }, [workspaces, setWorkspaceList, selectedFilterOption, filterValue]);

    const onFilterInputChanged = useCallback((e: React.ChangeEvent<any>) => {
        const value = e.currentTarget.value;
        setFilterValue(value);
    }, []);

    const onFilterOptionChanged = useCallback((e: React.ChangeEvent<any>) => {
        const value = e.currentTarget.value;
        setSelectedFilterOption(value);
    }, []);

    return { filterValue, filterOptions: defaultFilterOptions, selectedFilterOption, onFilterInputChanged, onFilterOptionChanged };
};