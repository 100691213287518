import React from 'react';
import { NapDiagramSvg } from './diagram/nap-diagram-svg';
import { useNapDiagram } from './nap-diagram.hooks';
import { NapDiagramProps } from './nap-diagram.types';

export const NapDiagram = (props: NapDiagramProps): JSX.Element => {
    const {
        nap, taps, tethers, selectedTetherId, selectedTerminalId, pretermBuilds, locationId, disabled
    } = useNapDiagram(props);
    return (
        <div className="nap-diagram">
            <NapDiagramSvg
                nap={nap}
                taps={taps}
                tethers={tethers}
                selectedTetherId={selectedTetherId}
                selectedTerminalId={selectedTerminalId}
                locationId={locationId}
                pretermBuilds={pretermBuilds}
                disabled={disabled}
            />
        </div>
    );
}
