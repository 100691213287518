import { BuildNapLastUpdated } from '../models/build-nap-last-updated';
import { Nap } from '../models/nap';
import { Tap } from '../models/tap';
import { Terminal } from '../models/terminal';
import { Tether } from '../models/tether';
import { SecuredService } from './abstract-secured-v2.service';

export interface ANTTResult {
    tap: Tap;
    nap: Nap;
    tether: Tether;
}
export class NapService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/nap';
    public getAll(workspaceId: number): Promise<NapsResult | undefined> {
        return this.get(`${this.baseUrl}?workspaceId=${workspaceId}`);
    }

    public undoDeleteNapsForBuild(buildId: number): Promise<Nap[] | undefined> {
        return this.post(`${this.baseUrl}/${buildId}`);
    }

    public addNapTapTether(elementId: number, workspaceId: number, buildId: number | null = null): Promise<ANTTResult | undefined> {
        return this.post<ANTTResult>(this.baseUrl + '/naptaptether', { elementId, workspaceId, buildId });
    }

    public applyPreset(buildId: number, presetId: number): Promise<Tether[] | undefined> {
        return this.post(this.baseUrl + `/${buildId}/preset/${presetId}`);
    }

    public deleteNap(napId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${napId}`);
    }

    public undoDeleteNap(napId: number): Promise<NapResult | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${napId}`);
    }

    public deleteNapsForBuilds(buildIds: number[]): Promise<void> {
        return this.post<void>(`${this.baseUrl}/delete`, { buildIds });
    }

    public undoDeleteNapsForBuilds(buildIds: number[]): Promise<NapsResult | undefined> {
        return this.post(`${this.baseUrl}/undo/delete`, { buildIds });
    }

    public isElementUsedByNaps(elementId: number): Promise<number | undefined> {
        return this.get(`${this.baseUrl}/iselementused/${elementId}`);
    }

    public linkNapsOnElementsToBuild(elementIds: number[], buildId: number): Promise<Tether[] | undefined> {
        return this.post(this.baseUrl + '/linkto', { elementIds, buildId });
    }

    public linkNapToBuild(napId: number, buildId: number): Promise<void> {
        return this.post(this.baseUrl + '/link/' + napId + '/build/' + buildId);
    }

    public unlinkNapFromBuild(napId: number, buildId: number): Promise<void> {
        return this.post(this.baseUrl + '/unlink/' + napId + '/build/' + buildId);
    }

    public unlinkNapsFromBuild(buildId: number): Promise<void> {
        return this.post(this.baseUrl + '/unlink/' + buildId);
    }

    public lockNapsConnectedToBuilds(buildIds: number[], workspaceId: number): Promise<Nap[] | undefined> {
        return this.post(this.baseUrl + '/lock', { buildIds, workspaceId });
    }

    public lockAllNaps(workspaceId: number): Promise<Nap[] | undefined> {
        return this.post(this.baseUrl + `/lockall/${workspaceId}`);
    }

    public unlockNapsConnectedToBuilds(buildIds: number[], workspaceId: number): Promise<Nap[] | undefined> {
        return this.post(this.baseUrl + '/unlock', { buildIds, workspaceId });
    }

    public unlockAllNaps(workspaceId: number): Promise<Nap[] | undefined> {
        return this.post(this.baseUrl + `/unlockall/${workspaceId}`);
    }

    public modifyNap(napId: number): Promise<Nap | undefined> {
        return this.post(this.baseUrl + '/modify', { napId });
    }

    public releaseNap(napId: number): Promise<Nap | undefined> {
        return this.post(this.baseUrl + '/release', { napId });
    }

    public resetNap(napId: number): Promise<{ tap: Tap | undefined; tether: Tether } | undefined> {
        return this.post(this.baseUrl + '/reset/' + napId);
    }

    public moveNap(napId: number, toElementId: number, toBuildId: number | null): Promise<Nap | undefined> {
        return this.post(this.baseUrl + '/move/' + napId, { toElementId, toBuildId });
    }

    public resetNapExtenders(napId: number): Promise<NapOperationDto | undefined> {
        return this.post(this.baseUrl + '/' + napId + '/resetextenders');
    }

    public getBuildNapLastUpdated(workspaceId: number): Promise<BuildNapLastUpdated[] | undefined> {
        return this.get(this.baseUrl + `/build-nap-last-updated/${workspaceId}`);
    }
}

export interface NapOperationDto {
    nap: Nap;
    terminals: Terminal[];
    warning?: string;
}

export interface NapResult {
    nap: Nap;
    taps: Tap[];
    tethers: Tether[];
}

export interface NapsResult {
    naps: Nap[];
    taps: Tap[];
    tethers: Tether[];
}