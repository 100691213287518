import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PathHelper } from '../../../helpers/path-helper';
import { SegmentHelper } from '../../../helpers/segment-helper';
import { Bore } from '../../../models/bore';
import { DataValue } from '../../../models/datavalue';
import { deletePath, updatePath } from '../../../redux/path.actions';
import { StateModel } from '../../../redux/reducers';
import { selectBore } from '../../../redux/selection.state';

export const useBoreCard = () => {
    const workspace = useSelector((state: StateModel) => state);
    const selectedBoreId = workspace.selection.selectedBoreId;
    const selectedBore = selectedBoreId ? workspace.bore.bores.find((b) => b.id === selectedBoreId) : undefined;
    const displayUnits = workspace.authentication.displayUnits;
    const buildSegments = workspace.build.segments;
    const terminalSegments = workspace.taps.tethers.flatMap(t => t.terminal?.terminalExtension?.segments ?? []);
    const { selectedTool } = workspace.tool;

    const [calculatedLength, setCalculatedLength] = useState<DataValue | undefined>(undefined);
    const [measuredLength, setMeasuredLength] = useState<DataValue | undefined>(undefined);
    const [measuredLengthIsValid, setMeasuredLengthIsValid] = useState(true);
    const [containsSegment, setContainsSegment] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedBore) {
            setCalculatedLength(PathHelper.calculatePathLength(selectedBore.route).toUnit(displayUnits));
            setMeasuredLength(new DataValue(selectedBore.measuredLength || 0, selectedBore.lengthUnit || displayUnits).toUnit(displayUnits));
            setMeasuredLengthIsValid(true);
        }
    }, [selectedBore, displayUnits]);

    useEffect(() => {
        setContainsSegment(!!selectedBore && (!!SegmentHelper.getBuildSegmentsOnPath(selectedBore, buildSegments).length || !!SegmentHelper.getTerminalSegmentsOnPath(selectedBore, terminalSegments).length));
    }, [selectedBore, buildSegments, terminalSegments]);

    const unselectBore = useCallback(() => {
        dispatch(selectBore());
    }, [dispatch]);

    const handleDeleteBore = useCallback(() => {
        if (selectedBore) {
            if (containsSegment) {
                return;
            }

            deletePath(selectedBore)(dispatch);
            unselectBore();
        }
    }, [dispatch, selectedBore, containsSegment, unselectBore]);

    const validateMeasuredLength = useCallback((value: any): boolean => {
        const valid = parseFloat(value) >= 0;
        setMeasuredLengthIsValid(valid);
        return valid;
    }, []);

    const handleLengthUpdate = useCallback((value: any) => {
        if(!selectedBore) {
            return;
        }
        if (validateMeasuredLength(value)) {
            const measuredLength = parseFloat(value) || 0;
            const newBore: Bore = { ...selectedBore, measuredLength: measuredLength, lengthUnit: displayUnits };
            updatePath(selectedBore, newBore)(dispatch);
            dispatch(selectBore(newBore.id));
        }
    }, [dispatch, selectedBore, displayUnits, validateMeasuredLength]);

    const handleIdOverrideUpdate = useCallback((value: string): void => {
        if (!selectedBore) {
            return;
        }

        const newBore: Bore = { ...selectedBore, tagOverride: value ? value : undefined };
        updatePath(selectedBore, newBore)(dispatch);
        dispatch(selectBore(newBore.id));
    }, [dispatch, selectedBore]);

    return {
        selectedBore, calculatedLength, measuredLength, measuredLengthIsValid, displayUnits, containsSegment, selectedTool,
        unselectBore, deleteBore: handleDeleteBore, handleLengthUpdate, handleIdOverrideUpdate, validateMeasuredLength,
    };
}