import { useEffect, useState } from 'react';
import { GenericSnackbarProps, SnackbarTypes } from './generic-snackbar.types';

const getSnackbarIcon = (type?: SnackbarTypes): string => {
    switch (type) {
        case SnackbarTypes.Information: return "info";
        case SnackbarTypes.Warning: return "warning";
        case SnackbarTypes.Error: return "cancel";
        case SnackbarTypes.Success: return "check_circle";
        default: return "";
    }
};

export const useGenericSnackbar = (props: GenericSnackbarProps) => {
    const { type, hideIcon, message, actionLabel, dismissIcon, onClose, onAction, open, timeout, showProgress } = props;
    const [icon, setIcon] = useState("");

    useEffect(() => {
        setIcon(getSnackbarIcon(type));
    }, [type]);

    return {
        type, hideIcon, message, icon, actionLabel, dismissIcon, onClose, onAction, open, timeout, showProgress
    };
};