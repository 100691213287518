import { Dispatch } from 'redux';
import { Command } from '../command';
import { deleteDesignArea, undoDelete } from '../../redux/design-area.state';
import { UndoRedoLocalizationKeys } from '../../locales/types';

export class CreateDesignAreaCommand extends Command {
    private id: number;

    constructor({ id }) {
        super();
        this.id = id;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoDesignAreaCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoDesignAreaCreation };
    }

    public undo(dispatch: Dispatch): void {
        deleteDesignArea(this.id, true)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        undoDelete(this.id)(dispatch);
        super.redo(dispatch);
    }
}