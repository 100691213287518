import { createSelector } from '@reduxjs/toolkit';
import { BuilderHelper } from '../helpers/build-helper';
import { selectedFlexNapBuildSelector, selectedSegmentsSortedSelector } from './build.selectors';
import {
    flexnapBuildsSelector, napsSelector, tapsSelector, tethersSelector,
    selectedBuildIdSelector, selectedNapIdSelector,
} from './root.selectors';

export const selectedBuildNapSelector = createSelector(
    selectedNapIdSelector,
    selectedBuildIdSelector,
    napsSelector,
    (napId, buildId, naps,) => naps.find((n) => n.id === napId || n.buildId === buildId)
)

export const selectedBuildIdFromNapSelector = createSelector(
    selectedBuildIdSelector,
    selectedBuildNapSelector,
    (buildId, nap,) => buildId || nap?.buildId,
)

export const selectedBuildNapsSelector = createSelector(
    selectedBuildIdFromNapSelector,
    napsSelector,
    (buildId, naps,) => naps.filter((n) => n.buildId === buildId) || []
)

export const selectedFlexNapBuildTethersFiberCountSelector = createSelector(
    selectedFlexNapBuildSelector,
    selectedBuildNapsSelector,
    tapsSelector,
    tethersSelector,
    (build, buildNaps, taps, tethers) => {
        if (!build?.fiberCount) {
            return 0;
        }
        return BuilderHelper.getBuildFiberCount(buildNaps, taps, tethers);
    }
);

export const possibleNapsToConvertToPretermSelector = createSelector(
    napsSelector,
    flexnapBuildsSelector,
    selectedFlexNapBuildSelector,
    selectedSegmentsSortedSelector,
    selectedBuildIdSelector,
    (naps, builds, buildToConvert, selectedSegments, selectedBuildId) => {
        // if the build is locked or already is a pretermLateral
        if (!buildToConvert || buildToConvert.lockedById || buildToConvert.pretermLateral || !!buildToConvert.buildSplice) {
            return [];
        }

        const [firstSegment] = selectedSegments;
        if(!firstSegment) {
            return [];
        }

        // Retrieve possible nap from first segment
        return naps.filter(n => 
            n.elementId === firstSegment.fromId &&
            // the NAP is assigned to a different build than the one being converted
            (!!n.buildId && selectedBuildId !== n.buildId) &&
            // parent build exists and is not locked
            !!builds.find((b) => b.id === n.buildId && !b.lockedById)
        );
    }
)