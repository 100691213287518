import React from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';

import { DesignArea } from '../../../../models/design-area';
import { DesignAreasTableHeader, DesignAreasTableRow } from './design-areas-table-row';
import { DesignAreaAccessPoints } from './design-areas-table-row.types';

export interface ImportStepDesignAreasProps {
    designAreas: DesignArea[];
    designAreasAccessPointsCount: DesignAreaAccessPoints[]
 }
export const ImportStepDesignAreas = (props: ImportStepDesignAreasProps): JSX.Element => {
    return (
        <div className='build-list'>
            <p>Build creation status:</p>
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <DesignAreasTableHeader />
                    <TableBody>
                        {props.designAreas?.map(da => 
                            <DesignAreasTableRow key={da.id} designArea={da} accessPointCount={props.designAreasAccessPointsCount.find((daap) => daap.designAreaName === da.name)?.accessPointCount ?? 0} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}