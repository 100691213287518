import { SecuredService } from '../abstract-secured-v2.service';
import { BulkSplicePlan } from '../../models/bulk/splice-plan';

export class SplicePlanService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/bulkspliceplan';

    public getSplicePlan(buildId: number): Promise<BulkSplicePlan | undefined> {
        return this.get(`${this.baseUrl}/build/${buildId}/locations`);
    }

    public updateSplicePlan(request: UpdateSplicePlanRequest): Promise<BulkSplicePlan | undefined> {
        return this.post(`${this.baseUrl}`, request);
    }
}

export interface UpdateSplicePlanRequest {
    buildId: number;
    fiberAssignment?: number;
    startingPort?: number;
    includeUnusedFibers: boolean;
}