import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigurationType } from '../../models/schrodinger/configuration-type';
import { addOpticalTether, addPowerTether } from '../../redux/schrodinger/tap.state';
import { selectTetherSchrodinger } from '../../redux/selection.state';
import {
    schrodingerBuildsSelector, selectedTapIdSchrodingerSelector,
    selectedTetherIdSchrodingerSelector, tapsSchrodingerSelector, tethersSchrodingerSelector
} from '../../selectors/root.selectors';
import {
    selectedTapAwaitingSessionUpdateSelector
} from '../../selectors/schrodinger/taps.selectors';
import { TapDiagramProps } from './tap-diagram.types';

const maxNumberOfTethersPerTap = 32;
const pageSize = 4;
const countPages = (length: number): number => {
    return Math.ceil(length / pageSize);
};
export const useTapDiagram = (props: TapDiagramProps) => {
    const { disabled, locationId } = props;
    const taps = useSelector(tapsSchrodingerSelector);
    const tethers = useSelector(tethersSchrodingerSelector);
    const selectedTapId = useSelector(selectedTapIdSchrodingerSelector);
    const selectedTetherId = useSelector(selectedTetherIdSchrodingerSelector);
    const disabledWhenAwaiting = useSelector(selectedTapAwaitingSessionUpdateSelector);
    const builds = useSelector(schrodingerBuildsSelector);
    const tap = taps.find((t) => t.id === selectedTapId);
    const tapTethers = tethers.filter((t) => t.tapId === selectedTapId);
    const build = builds.find((b) => b.id === tap?.buildId);

    const [page, setPage] = useState<number>(0);
    const [tetherCount, setTetherCount] = useState<number>(tapTethers.length);

    const dispatch = useDispatch();

    const disableAddButtons = disabled || disabledWhenAwaiting || tetherCount >= maxNumberOfTethersPerTap;
    const addPowerTetherDisabled = disableAddButtons || build?.configurationType === ConfigurationType.OpticalOnly || tap?.configurationType === ConfigurationType.OpticalOnly;
    const addOpticalTetherDisabled = disableAddButtons || build?.configurationType === ConfigurationType.PowerOnly || tap?.configurationType === ConfigurationType.PowerOnly;

    const selectTether = (tetherId?: number): void => {
        dispatch(selectTetherSchrodinger(tetherId));
    };

    const createPowerTether = useCallback((): void => {
        if (!tap) {
            return;
        }
        addPowerTether(tap)(dispatch);
    }, [dispatch, tap]);

    const createOpticalTether = useCallback((): void => {
        if (!tap) {
            return;
        }
        addOpticalTether(tap)(dispatch);
    }, [dispatch, tap]);

    const gotoLastPage = useCallback((): void => {
        if (tapTethers) {
            setPage(countPages(tapTethers.length) - 1);
        }
    }, [tapTethers]);

    useEffect(() => {
        if (tetherCount < tapTethers.length) {
            gotoLastPage();
        }
        else if (tetherCount > tapTethers.length) {
            if (countPages(tapTethers.length) === page) {
                setPage(page - 1);
            }
        }
        setTetherCount(tapTethers.length);
    }, [tapTethers.length, gotoLastPage, page, tetherCount]);

    return {
        pageSize, locationId, page, tethers: tapTethers, selectedTetherId, addPowerTetherDisabled, addOpticalTetherDisabled,
        setPage, selectTether, createPowerTether, createOpticalTether
    };
};
