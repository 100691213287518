import React, { useCallback, useEffect, useState } from 'react';

import { UploadDialogContentProps } from './import.card.types';

export const useUploadDialogContent = (props: UploadDialogContentProps) => {

    const [errorMsg, setErrorMsg] = useState<string>('');
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const [loadedFiles, setLoadedFiles] = useState<File[]>([]);
    const [prevFiles, setPrevFiles] = useState<File[]>([]);

    const fileUpload: React.RefObject<HTMLInputElement> = React.createRef();

    useEffect(() => {
        if (prevFiles !== loadedFiles) {
            props.setDisableNext(loadedFiles.length === 0);
            props.setFiles(loadedFiles);
        }
        setPrevFiles(loadedFiles);
    }, [loadedFiles, prevFiles, props, setPrevFiles])

    const validateAndAddFile = useCallback((files: FileList) => {
        const len = files.length;
        const invalidFiles: string[] = [];
        for (let i = 0; i < len; i++) {
            const file = files[i];
            if (file.name.toLowerCase().endsWith('.shp') ||
                file.name.toLowerCase().endsWith('.dbf') ||
                file.name.toLowerCase().endsWith('.zip') ||
                file.name.toLowerCase().endsWith('.json')
            ) {
                setLoadedFiles(previousFiles => [...previousFiles, file]);
            }
            else {
                invalidFiles.push(file.name);
            }
        }
        setIsDragging(false);
        if (invalidFiles.length > 0) {
            const isPlural = invalidFiles.length > 1;
            const auxiliary = isPlural ? 'are' : 'is an';
            setErrorMsg(`${invalidFiles.join(', ')} ${auxiliary} invalid file${isPlural ? 's' : ''}.`);
        }
        else {
            setErrorMsg('');
        }
    }, [setLoadedFiles]);

    const showFileSelection = useCallback(() => {
        if (fileUpload && fileUpload.current) {
            fileUpload.current.click();
        }
    }, [fileUpload]);

    const handleFileSelection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            validateAndAddFile(event.target.files);
            if (fileUpload.current) {
                fileUpload.current.value = '';
            }
        }
    }, [fileUpload, validateAndAddFile]);

    const onDrop = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        const len = event.dataTransfer.files.length;
        if (!len) {
            return;
        }
        validateAndAddFile(event.dataTransfer.files);
    }, [validateAndAddFile]);

    const onDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        !isDragging && setIsDragging(true);
    }, [isDragging, setIsDragging]);

    const onDragLeave = useCallback(() => isDragging && setIsDragging(false), [isDragging, setIsDragging]);

    return {
        errorMsg, isDragging, fileUpload, files: loadedFiles,
        showFileSelection, handleFileSelection, onDrop, onDragOver, onDragLeave,
        setFiles: setLoadedFiles
    }
}
