export enum StaticFeatureType {
    GEOPROJECT = 'GEOPROJECT',
    SLACK_LOOP = 'SLACK_LOOP',
    DROP_CABLE = 'DROP_CABLE',
    LOCK_OUTLINE = 'LOCK_OUTLINE',
    PORT_NUMBER = 'PORT_NUMBER',
    WRAPPED_PORT_NUMBER = 'WRAPPED_PORT_NUMBER',
    UPLOADED_BUILD = 'UPLOADED_BUILD',
    APPROVED_BUILD = 'APPROVED_BUILD',
    IN_REVIEW_BUILD = 'IN_REVIEW_BUILD',
    TERMINAL_EXTENSION = 'TERMINAL_EXTENSION',
    CIRCLE_SHADOW = 'CIRCLE_SHADOW',
    NAP_SHADOW = 'NAP_SHADOW',
    TAP_SHADOW = 'TAP_SHADOW',
    HOLE_SHADOW = 'HOLE_SHADOW',
    CABLE_SHADOW = 'CABLE_SHADOW',
    INFRASTRUCTURE_ID = 'INFRASTRUCTURE_ID',
    BUILD_CALLOUT = 'BUILD_CALLOUT',
    CABINET_CALLOUT = 'CABINET_CALLOUT',
    BUILD_ID ='BUILD_ID'
}