import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { CsticValue } from '../../../../models/cstic-value';
import { OpticalTetherOptions } from '../../../../models/schrodinger/optical-tether-options';
import { updateSchrodingerBuildFromSession } from '../../../../redux/build.state';
import { StateModel } from '../../../../redux/reducers';
import { deleteTether, updateTether } from '../../../../redux/schrodinger/tap.state';
import { selectTetherSchrodinger } from '../../../../redux/selection.state';
import { setTapAwaiting, unsetTapAwaiting } from '../../../../redux/workspace.state';
import {
    selectedTapAwaitingSessionUpdateSelector
} from '../../../../selectors/schrodinger/taps.selectors';
import { TetherService } from '../../../../services/schrodinger/tether.service';
import { OpticalTetherProps } from './optical-tether-card.types';

export const useOpticalTetherCard = (props: OpticalTetherProps) => {
    const { selectedTether } = props;

    const { t } = useTranslation();
    const subheader = `${t(LocalizationKeys.Tether)} ${selectedTether.id}`;

    const workspace = useSelector((state: StateModel) => state);
    const tetherTap = workspace?.tapsSchrodinger?.taps?.find(t => t.id === selectedTether.tapId);

    const [partNumber, setPartNumber] = useState<string>();
    const [lengthId, setLengthId] = useState<string>();
    const [fiberCountId, setFiberCountId] = useState<string>();
    const [tetherTypeId, setTetherTypeId] = useState<string>();

    const [opticalTetherOptions, setOpticalTetherOptions] = useState<OpticalTetherOptions>();

    const disabledWhenAwaiting = useSelector(selectedTapAwaitingSessionUpdateSelector);

    const dispatch = useDispatch();

    const setSelectedOptions = useCallback(async (options: OpticalTetherOptions): Promise<void> => {
        setLengthId(options.lengths.find(x => x.assigned)?.id);
        setFiberCountId(options.fiberCounts.find(x => x.assigned)?.id);
        setTetherTypeId(options.tetherTypes.find(x => x.assigned)?.id);
    }, []);

    const updateOpticalTether = useCallback(async (selectedCsticValue?: CsticValue): Promise<void> => {
        const service = new TetherService();
        if (selectedCsticValue) {
            selectedCsticValue.assigned = true;

            if (tetherTap) dispatch(setTapAwaiting(tetherTap));
            const response = await service.updateOpticalTether(selectedTether.id, selectedCsticValue);
            if (!response) {
                return;
            }
            dispatch(updateTether(response.tether));
            setOpticalTetherOptions(response.options);
            setSelectedOptions(response.options);
            if (tetherTap) dispatch(unsetTapAwaiting(tetherTap));

            if (tetherTap?.buildId && response.tether.partNumber) {
                dispatch(updateSchrodingerBuildFromSession(tetherTap.buildId));
            }
        }
    }, [dispatch, selectedTether, tetherTap, setSelectedOptions]);

    const getOptions = useCallback(async (): Promise<void> => {
        if (tetherTap) dispatch(setTapAwaiting(tetherTap));
        const service = new TetherService();
        const options = await service.getOpticalTetherOptions(selectedTether.id);
        if (options) {
            setOpticalTetherOptions(options);
            setSelectedOptions(options);
        }
        if (tetherTap) dispatch(unsetTapAwaiting(tetherTap));
    }, [dispatch, selectedTether, tetherTap, setSelectedOptions]);

    useEffect(() => {
        // set the hook states based on the selectedTether state
        const { partNumber: partNumberState, fiberCountId: fiberCountIdState, tetherTypeId: tetherTypeIdState, lengthId: lengthIdState } = selectedTether;
        setPartNumber(partNumberState);
        setLengthId(lengthIdState);
        setFiberCountId(fiberCountIdState);
        setTetherTypeId(tetherTypeIdState);

        getOptions();
    }, [getOptions, selectedTether])

    const unselectTether = useCallback((): void => {
        dispatch(selectTetherSchrodinger());
    }, [dispatch]);

    const handleFiberChange = useCallback((value: any): void => {
        const selectedFiberCountCstic = opticalTetherOptions?.fiberCounts.find(x => x.id === value);
        updateOpticalTether(selectedFiberCountCstic);
        setFiberCountId(value);
    }, [opticalTetherOptions, updateOpticalTether]);

    const handleTetherTypeChange = useCallback((value: any): void => {
        const selectedTetherOptionCstic = opticalTetherOptions?.tetherTypes.find(x => x.id === value);
        updateOpticalTether(selectedTetherOptionCstic);
        setTetherTypeId(value);
    }, [opticalTetherOptions, updateOpticalTether]);

    const handleLengthChange = useCallback((value: any): void => {
        const selectedLengthCstic = opticalTetherOptions?.lengths.find(x => x.id === value);
        updateOpticalTether(selectedLengthCstic);
        setLengthId(value);
    }, [opticalTetherOptions, updateOpticalTether]);

    const handleDeleteTether = useCallback((): void => {
        if (tetherTap) {
            deleteTether(selectedTether.id, tetherTap)(dispatch);
            unselectTether();
        }
    }, [dispatch, selectedTether.id, tetherTap, unselectTether]);

    return {
        subheader, lengthId, fiberCountId, tetherTypeId, opticalTetherOptions, partNumber, disabledWhenAwaiting,
        unselectTether, handleFiberChange, handleTetherTypeChange, handleLengthChange, handleDeleteTether
    };
}
