import i18next from 'i18next';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import { LocalizationKeys } from '../locales/types';
import { BuildNapLastUpdated } from '../models/build-nap-last-updated';
import { FlexNapBuild } from '../models/flexnap-build';
import { Nap } from '../models/nap';
import { Tap } from '../models/tap';
import { Terminal } from '../models/terminal';
import { TerminalExtension } from '../models/terminal-extension';
import { TerminalSegment } from '../models/terminal-segment';
import { Tether } from '../models/tether';
import { ANTTResult, NapService } from '../services/nap.service';
import { NotificationService } from '../services/notification.service';
import { TapService } from '../services/tap.service';
import { TerminalSegmentRequest, TerminalService } from '../services/terminal.service';
import { TetherService } from '../services/tether.service';
import { WebServiceErrorHandlingBehavior } from '../services/abstract-web-v2.service';
import { createSecuredAsyncAction, requestIsSuccess, customErrorRequest } from './action';
import { applyBuildPreset, revertPretermLateral, undoRevertPretermLateral } from './build.state';
import { push } from './history.state';
import {
    addTerminalSegmentsToSelection, clearSelection, selectTerminal, selectTerminalSegments,
    unselectTerminalSegment
} from './selection.state';

export interface TapState {
    naps: Nap[];
    taps: Tap[];
    tethers: Tether[];
    buildNapLastUpdateds: BuildNapLastUpdated[];
}

const initialState: TapState = {
    naps: [],
    taps: [],
    tethers: [],
    buildNapLastUpdateds: [],
};

function loadAllReducer(state: TapState, action: PayloadAction<{ naps: Nap[]; taps: Tap[]; tethers: Tether[] }>) {
    const { naps, taps, tethers } = action.payload;
    state.naps = naps;
    state.taps = taps;
    state.tethers = tethers;
}

//#region ---- NAPs

function __deleteTapAndTethers(state: TapState, tapId: number) {
    const tetherIdsToRemove = state.tethers.filter((t) => t.tapId === tapId).map((t) => t.id);
    state.taps = state.taps.filter((t) => t.id !== tapId); // remove tap
    state.tethers = state.tethers.filter((t) => !tetherIdsToRemove.includes(t.id)); // removed tethers
}
function __deleteTether(state: TapState, tetherId: number) {
    const tetherIdx = state.tethers.findIndex((t) => t.id === tetherId);
    const tether = state.tethers.splice(tetherIdx, 1)[0];
    const isLastTapTether = !state.tethers.filter((t) => t.tapId === tether.tapId).length;
    if (isLastTapTether) { // remove tether-less tap
        state.taps = state.taps.filter((t) => t.id !== tether.tapId);
    }
}

function loadNapsReducer(state: TapState, action: PayloadAction<Nap[] | undefined>) {
    state.naps = action.payload || [];
}

function undoDeleteNapsForBuildReducer(state: TapState, action: PayloadAction<Nap[]>) {
    state.naps.push(...action.payload);
}

function addNapTapTetherReducer(state: TapState, action: PayloadAction<ANTTResult>) {
    const res = action.payload;
    state.naps.push(res.nap);
    state.taps.push(res.tap);
    state.tethers.push(res.tether);
}

function deleteNapReducer(state: TapState, action: PayloadAction<number>) {
    const napId = action.payload;
    if (!napId) {
        throw new Error('Deleting nap requires a napid');
    }
    const toRemoveIdx = state.naps.findIndex((n) => n.id === napId);
    const toRemove = state.naps.splice(toRemoveIdx, 1)[0]; // removed nap
    const toRemoveTapIds = state.taps.filter((t) => t.napId === toRemove.id).map((t) => t.id);
    for (const tapIdToRemove of toRemoveTapIds) {
        __deleteTapAndTethers(state, tapIdToRemove);
    }
}

const deleteNapsWithElementIdReducer = (state: TapState, action: PayloadAction<number>) => {
    const elementId = action.payload;
    if (!elementId) {
        return;
    }

    const napIdsToRemove = new Set(state.naps.filter((nap) => nap.elementId === elementId).map(t => t.id));
    state.naps = state.naps.filter((tap) => tap.elementId !== elementId);

    const tapIdsToRemove = state.taps.filter((tether) => napIdsToRemove.has(tether.napId)).map(t => t.id);
    for (const tapIdToRemove of tapIdsToRemove) {
        __deleteTapAndTethers(state, tapIdToRemove);
    }
}

function resetNapReducer(state: TapState, action: PayloadAction<{ tap: Tap | undefined; tether: Tether }>) {
    const newTap = action.payload.tap;
    const newTether = action.payload.tether;
    if (!newTap) {
        throw new Error('Resetting a nap without a tap')
    }
    const tapIdsToRemove = state.taps.filter((t) => t.napId === newTap.napId).map((t) => t.id);
    state.taps = state.taps.filter((t) => !tapIdsToRemove.includes(t.id));
    state.tethers = state.tethers.filter((t) => !tapIdsToRemove.includes(t.id));
    state.taps.push(newTap);
    state.tethers.push(newTether);
}

function releaseNapReducer(state: TapState, action: PayloadAction<number>) {
    const napId = action.payload;
    const napIdx = state.taps.findIndex((t) => t.id === napId);
    if (napIdx >= 0) {
        const nap = state.naps[napIdx];
        nap.modifiedById = null;
        nap.lastModified = new Date(Date.now());
        state.naps[napIdx] = nap;
    }
}

function deleteNapsForBuildsReducer(state: TapState, action: PayloadAction<number[]>): void {
    const buildIds = action.payload;
    if (!buildIds) {
        throw new Error('Deleting naps requires a list of buildids');
    }
    const { first: napsToRemove, second: napsToKeep } = state.naps.partition((n) => !!n.buildId && buildIds.includes(n.buildId));
    const napIdsToRemove = napsToRemove.map((n) => n.id);
    state.naps = napsToKeep;
    const toRemoveTapIds = state.taps.filter((t) => napIdsToRemove.includes(t.napId)).map((t) => t.id);
    for (const tapIdToRemove of toRemoveTapIds) {
        __deleteTapAndTethers(state, tapIdToRemove);
    }
}
function deleteNapsReducer(state: TapState, action: PayloadAction<number[]>): void {
    const napIds = new Set(action.payload);
    const { first: napsToRemove, second: napsToKeep } = state.naps.partition((n) => napIds.has(n.id));
    const napIdsToRemove = new Set(napsToRemove.map((n) => n.id));
    state.naps = napsToKeep;
    const toRemoveTapIds = state.taps.filter((t) => napIdsToRemove.has(t.napId)).map((t) => t.id);
    for (const tapIdToRemove of toRemoveTapIds) {
        __deleteTapAndTethers(state, tapIdToRemove);
    }
}
function linkNapsToSegmentReducer(state: TapState, action: PayloadAction<{ elementIds: number[]; buildId: number }>): void {
    const { elementIds, buildId } = action.payload;
    const naps = state.naps.filter((n) => n.buildId === null && elementIds.includes(n.elementId));
    for (const nap of naps) {
        nap.buildId = buildId;
    }
}
function linkNapToBuildReducer(state: TapState, action: PayloadAction<{ napId: number; buildId: number }>): void {
    const { napId, buildId } = action.payload;
    const nap = state.naps.find(n => n.id === napId && n.buildId === null);
    if (nap) {
        nap.buildId = buildId;
    }
}
function unlinkNapFromBuildReducer(state: TapState, action: PayloadAction<{ napId: number; buildId: number }>): void {
    const { napId, buildId } = action.payload;
    const nap = state.naps.find(n => n.id === napId && n.buildId === buildId);
    if (nap) {
        nap.buildId = null;
    }
}
function unlinkNapsFromBuildReducer(state: TapState, action: PayloadAction<number>): void {
    const buildId = action.payload;
    const naps = state.naps.filter((n) => n.buildId === buildId);
    for (const nap of naps) {
        nap.buildId = null;
    }
}
function unlinkNapsFromBuildsReducer(state: TapState, action: PayloadAction<number[]>): void {
    const buildIds = new Set(action.payload);
    const naps = state.naps.filter((n) => n.buildId && buildIds.has(n.buildId));
    for (const nap of naps) {
        nap.buildId = null;
    }
}
function updateNapsReducer(state: TapState, action: PayloadAction<Nap[]>): void {
    const updatedNaps: Nap[] = action.payload;
    const updatedNapIds = updatedNaps.map((s) => s.id);
    const naps = state.naps.filter((s) => !updatedNapIds.includes(s.id));
    naps.push(...updatedNaps);
    state.naps = naps;
}

function updateNapReducer(state: TapState, action: PayloadAction<Nap>): void {
    const nap = action.payload;
    const idx = state.naps.findIndex(n => n.id === nap.id);
    if (idx === -1) {
        state.naps.push(nap);
    }
    else {
        state.naps[idx] = nap;
    }
}

//#endregion

//#region ---- TAP
function loadTapsReducer(state: TapState, action: PayloadAction<Tap[] | undefined>): void {
    state.taps = action.payload || [];
}

function updateTapsReducer(state: TapState, action: PayloadAction<Tap[] | undefined>): void {
    const tapsToUpdate = action.payload;
    if (!tapsToUpdate) {
        throw new Error('Update taps with no taps to update');
    }
    if (tapsToUpdate.some((t) => t.id < 0)) {
        throw new Error('Update taps with one or more non-presisted taps');
    }
    for (const updatedTap of tapsToUpdate) {
        const existingIndex = state.taps.findIndex((t) => t.id === updatedTap.id);
        if (existingIndex < 0) {
            state.taps.push(updatedTap); // new tap
        }
        else {
            Object.assign(state.taps[existingIndex], updatedTap);
        }
    }
}

function addTapReducer(state: TapState, action: PayloadAction<Tap>) {
    state.taps.push(action.payload);
}

function deleteTapReducer(state: TapState, action: PayloadAction<Tap>) {
    const deletedTap = action.payload;
    if (!deletedTap) {
        throw new Error('Deleting non-tap');
    }
    if (deletedTap.id < 0) {
        throw new Error('Deleting non-persisted tap');
    }
    __deleteTapAndTethers(state, deletedTap.id);
}
//#endregion

//#region ---- TETHER
function loadTethersReducer(state: TapState, action: PayloadAction<Tether[] | undefined>) {
    state.tethers = action.payload || [];
}

function updateTethersReducer(state: TapState, action: PayloadAction<Tether[]>) {
    const updatedTethers = action.payload;
    const changedFiberCount: Map<number, number> = new Map();
    for (const tether of updatedTethers) {
        const existingIndex = state.tethers.findIndex((t) => t.id === tether.id);
        const currentTether = state.tethers.find((t) => t.id === tether.id);
        if (existingIndex === -1) {
            state.tethers.push(tether);
        }
        else {
            state.tethers[existingIndex] = tether;
        }
        if (currentTether && currentTether.fiberCount !== tether.fiberCount) {
            if (!changedFiberCount.has(currentTether.fiberCount)) {
                changedFiberCount.set(currentTether.fiberCount, tether.fiberCount)
            }
        }
    }
    if (changedFiberCount.size !== 0) {
        const prevFiber = Array.from(changedFiberCount.keys()).sort().map(i => i + 'F').toString().replace(',', '/');
        const curFiber = Array.from(changedFiberCount.values()).sort().map(i => i + 'F').toString().replace(',', '/');
        NotificationService.warning(`${prevFiber} NAP tethers were upsized to ${curFiber} tethers respectively since RPX cables does not allow these options`);
    }
}

function addTetherReducer(state: TapState, action: PayloadAction<{ tether: Tether; tap: Tap | undefined }>) {
    const { taps, tethers } = state;
    const tether = action.payload.tether;
    const newTap = action.payload.tap;
    if (!taps) {
        throw new Error('Add tether without taps');
    }
    if (tether.tapId < 0) {
        throw new Error('Add tether without persisted tap');
    }
    if (newTap) { // add tether can create a tap
        if (taps.find((t) => t.id === newTap.id)) {
            throw new Error('Add tether returned a new tap that was already in the tap list');
        }
        taps.push(newTap);
    }
    const ownerTap = taps.find((t) => t.id === tether.tapId);
    if (!ownerTap || ownerTap.id < 0) {
        throw new Error('Add tether without persisted tap');
    }
    const tapTethers = tethers.filter((t) => t.tapId === ownerTap.id);
    if (tapTethers.length >= 2) {
        throw new Error('Add tether on tap with 2 or more tethers');
    }
    state.tethers.push(tether);
}

function patchTetherReducer(state: TapState, action: PayloadAction<Tether>) {
    const { tethers } = state;
    const tether = action.payload;
    if (!tether || tether.id < 0 || tether.tapId < 0) {
        throw new Error('Change tether without persisted tether');
    }
    const idx = tethers.findIndex((t) => t.id === tether.id);
    state.tethers[idx] = tether;
}

function deleteTetherReducer(state: TapState, action: PayloadAction<Tether | number>) {
    const { taps } = state;
    const tether = typeof action.payload === 'number' ?
        state.tethers.find((t) => t.id === action.payload) :
        action.payload;
    if (!tether) {
        throw new Error('Delete tether without tether in the first place');
    }
    if (!taps) {
        throw new Error('Delete tether without taps');
    }
    const ownerTap = taps.find((t) => t.id === tether.tapId);
    if (!ownerTap) {
        throw new Error('Delete tether without tap');
    }
    if (ownerTap.id < 0) {
        throw new Error('Delete tether without persisted tap');
    }
    __deleteTether(state, tether.id);
}
//#endregion

function addOrUpdateTerminalReducer(state: TapState, action: PayloadAction<Terminal>) {
    const terminal = action.payload;
    const tether = state.tethers.find((t) => t.id === terminal.tetherId);
    if (!tether) {
        throw new Error('Add/Update terminal unable to find corresponding tether');
    }
    tether.terminal = terminal;
}

function deleteTerminalReducer(state: TapState, action: PayloadAction<Terminal>) {
    const terminal = action.payload;
    const tether: Tether | undefined = state.tethers.find((t) => t.id === terminal.tetherId);
    if (!tether) {
        throw new Error('Delete terminal can\'t find tether');
    }
    tether.terminal = undefined;
}

function resetTerminalReducer(state: TapState, action: PayloadAction<number>) {
    // Update the state of the terminal so that it is redrawn if the operation on the back-end is not successful 
    const tether: Tether | undefined = state.tethers.find(t => t.terminal?.id === action.payload);
    if (tether?.terminal) {
        tether.terminal.id = 0;
        tether.terminal.id = action.payload;
    }
}

function replaceTerminalSegmentsReducer(state: TapState, action: PayloadAction<{ terminalId: number; segments: TerminalSegment[] }>) {
    const { terminalId, segments } = action.payload;
    const segmentsToReplace = segments.map(s => s.id);
    const stateSegments = state.tethers.find(t => t.terminal?.id === terminalId)?.terminal?.terminalExtension?.segments;
    if (stateSegments) {
        const segmentsToKeep = stateSegments.filter(s => !segmentsToReplace.includes(s.id));
        stateSegments.splice(0);
        stateSegments.push(...segmentsToKeep, ...segments);
    }
}

function updateTerminalSegmentsReducer(state: TapState, action: PayloadAction<{ terminalId: number; segments: TerminalSegment[] }>) {
    const { terminalId, segments } = action.payload;
    const stateSegments = state.tethers.find(t => t.terminal?.id === terminalId)?.terminal?.terminalExtension?.segments;
    if (stateSegments) {
        for (const segment of segments) {
            const segmentIndex = stateSegments.findIndex(s => s.id === segment.id);
            if (segmentIndex < 0) {
                stateSegments.push(segment);
            } else {
                Object.assign(stateSegments[segmentIndex], segment);
            }
        }
    }
}

function deleteTerminalSegmentReducer(state: TapState, action: PayloadAction<{ terminalId: number; segmentId: number }>) {
    const { terminalId, segmentId } = action.payload;
    const stateSegments = state.tethers.find(t => t.terminal?.id === terminalId)?.terminal?.terminalExtension?.segments;
    if (stateSegments) {
        const segmentsToKeep = stateSegments.filter(s => s.id !== segmentId);
        stateSegments.splice(0);
        stateSegments.push(...segmentsToKeep);
    }
}

function replaceTapsAndTethersReducer(state: TapState, action: PayloadAction<{ taps: Tap[]; tethers: Tether[] }>) {
    const { taps, tethers } = action.payload;
    const tapIds = taps.map(tp => tp.id);
    const tapsToKeep = state.taps.filter(t => !tapIds.includes(t.id));
    const tethersToKeep = state.tethers.filter((t) => !tethers.map(ts => ts.id).includes(t.id));

    state.taps = [...tapsToKeep, ...taps];
    state.tethers = [...tethersToKeep, ...tethers];
}

function removeTapsAndTethersReducer(state: TapState, action: PayloadAction<{ tetherIds: number[]; tapIdsToKeep?: number[]; otherPretermTetherIdsOnNap?: number[] }>) {
    const { tetherIds, tapIdsToKeep, otherPretermTetherIdsOnNap } = action.payload;
    const tethersToRemove = state.tethers.filter(t => tetherIds.includes(t.id));

    state.tethers = state.tethers.filter(t => !tetherIds.includes(t.id));

    state.taps = state.taps.filter(t =>
        tapIdsToKeep?.includes(t.id) ||
        !tethersToRemove.some(th => th.tapId === t.id) ||
        state.tethers.some(th => th.tapId === t.id && (!!th.terminal || otherPretermTetherIdsOnNap?.includes(th.id)))
    );
}

function cancelNapMoveReducer(state: TapState, action: PayloadAction<number>) {
    const nap = state.naps.find(n => n.id === action.payload);
    if (nap) {
        const originalElementId = nap.elementId;
        nap.elementId = 0;
        nap.elementId = originalElementId;
    }
}

function removeAllReducer(state: TapState) {
    state.naps = [];
    state.taps = [];
    state.tethers = [];
    state.buildNapLastUpdateds = [];
}

function loadNapsTapsAndTethersFromImportReducer(state: TapState, action: PayloadAction<{ naps: Nap[]; taps: Tap[]; tethers: Tether[] }>): void {
    const { naps, taps, tethers } = action.payload;
    state.naps.push(...naps);
    state.taps.push(...taps);
    state.tethers.push(...tethers);
}


function loadBuildNapLastUpdatedReducer(state: TapState, action: PayloadAction<BuildNapLastUpdated[]>): void {
    state.buildNapLastUpdateds = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'tap',
    initialState,
    reducers: {
        loadAll: loadAllReducer,

        loadTaps: loadTapsReducer,
        updateTaps: updateTapsReducer,
        addTap: addTapReducer,
        deleteTap: deleteTapReducer,

        loadNaps: loadNapsReducer,
        undoDeleteNapsForBuild: undoDeleteNapsForBuildReducer,
        addNapTapTether: addNapTapTetherReducer,
        deleteNap: deleteNapReducer,
        deleteNapsWithElementId: deleteNapsWithElementIdReducer,
        resetNap: resetNapReducer,
        releaseNap: releaseNapReducer,
        deleteNapsForBuilds: deleteNapsForBuildsReducer,
        deleteNaps: deleteNapsReducer,
        linkNapsToSegment: linkNapsToSegmentReducer,
        linkNapToBuild: linkNapToBuildReducer,
        unlinkNapFromBuild: unlinkNapFromBuildReducer,
        unlinkNapsFromBuild: unlinkNapsFromBuildReducer,
        unlinkNapsFromBuilds: unlinkNapsFromBuildsReducer,
        updateNaps: updateNapsReducer,
        updateNap: updateNapReducer,
        replaceTapsAndTethers: replaceTapsAndTethersReducer,
        removeTapsAndTethers: removeTapsAndTethersReducer,
        cancelNapMove: cancelNapMoveReducer,

        loadTethers: loadTethersReducer,
        updateTethers: updateTethersReducer,
        addTether: addTetherReducer,
        patchTether: patchTetherReducer,
        deleteTether: deleteTetherReducer,

        patchTerminal: addOrUpdateTerminalReducer,
        addTerminal: addOrUpdateTerminalReducer,
        moveTerminal: addOrUpdateTerminalReducer,
        updateTerminal: addOrUpdateTerminalReducer,
        deleteTerminal: deleteTerminalReducer,
        resetTerminal: resetTerminalReducer,
        replaceTerminalSegments: replaceTerminalSegmentsReducer,
        updateTerminalSegments: updateTerminalSegmentsReducer,
        deleteTerminalSegment: deleteTerminalSegmentReducer,

        removeAll: removeAllReducer,
        loadNapsTapsAndTethersFromImport: loadNapsTapsAndTethersFromImportReducer,

        loadBuildNapLastUpdated: loadBuildNapLastUpdatedReducer,
    },
});

export { reducer as TapsReducer };
export const { replaceTapsAndTethers, removeTapsAndTethers, deleteNapsWithElementId, updateTerminal, unlinkNapsFromBuilds, loadNapsTapsAndTethersFromImport, deleteNaps, removeAll } = actions;

export const loadAll = (workspaceId: number) => async dispatch => {
    const service = new NapService();
    const all = await service.getAll(workspaceId);
    all && dispatch(actions.loadAll(all));
};

export const loadTethers = () => async dispatch => {
    const service = new TetherService();
    const errorMessage = 'Unable to load tethers';
    const tethers = await customErrorRequest(service, service.getAllTethers(), errorMessage);
    tethers && dispatch(actions.loadTethers(tethers));
};

export const loadTaps = () => async dispatch => {
    const service = new TapService();
    const errorMessage = 'Unable to load TAPs';
    const taps = await customErrorRequest(service, service.getAllTaps(), errorMessage);
    taps && dispatch(actions.loadTaps(taps));
};

export const undoDeleteNapsForBuild = (buildId: number) => async dispatch => {
    const service = new NapService();
    const naps = await service.undoDeleteNapsForBuild(buildId);
    naps && dispatch(actions.undoDeleteNapsForBuild(naps));
};

export const addNapTapTether = (elementId: number, workspaceId: number, buildId: number | null = null) => async dispatch => {
    const service = new NapService();
    const errorMessage = 'Adding nap/tap/tether combo failed';
    const antt = await customErrorRequest(service, service.addNapTapTether(elementId, workspaceId, buildId), errorMessage);
    if (antt) {
        dispatch(actions.addNapTapTether(antt));
        dispatch(push({ type: CommandType.CreateFlexNapNap, payload: { id: antt.nap.id } }));
    }
};

export const addTap = (buildId: number | null, napId: number, tapIndex: number) => async dispatch => {
    const service = new TapService();
    const errorMessage = 'Adding tap failed';
    const tap = await customErrorRequest(service, service.addTap(buildId, napId, tapIndex), errorMessage);
    tap && dispatch(actions.addTap(tap));
};

export const addTerminal = (tetherId: number) => async dispatch => {
    const service = new TerminalService();
    const terminal = await service.addTerminal(tetherId);
    if (terminal) {
        dispatch(actions.addTerminal(terminal));
        dispatch(selectTerminal(terminal.id));
    }
};

export const deleteTerminal = (terminal: Terminal) => async dispatch => {
    const service = new TerminalService();
    const deleted = await service.deleteTerminal(terminal);
    if (deleted) {
        dispatch(actions.deleteTerminal(terminal));
    }
};

export const patchTerminal = (oldTerminal: Terminal, newTerminal: Terminal, reasonForUpdate?: string) => async dispatch => {
    const service = new TerminalService();
    const terminal = await service.patchTerminal(oldTerminal, newTerminal);
    if (terminal) {
        dispatch(actions.patchTerminal(terminal));

        if (reasonForUpdate) {
            NotificationService.info(reasonForUpdate);
        }
    }
};

export const moveTerminal = (terminalId: number, toElementId: number) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.extendTerminalToElement(terminalId, toElementId);
    if (operationResult?.terminal) {
        dispatch(actions.patchTerminal(operationResult.terminal));
        dispatch(clearSelection());

        dispatch(selectTerminalSegments(undefined));

        if (operationResult.warningMessage) {
            NotificationService.warning(operationResult.warningMessage);
        }
    } else {
        dispatch(actions.resetTerminal(terminalId));
    }
};

export const createTerminalSegments = (terminalId: number, createRequests: TerminalSegmentRequest[]) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.addTerminalSegments(terminalId, createRequests);
    if (operationResult) {
        if (operationResult.terminal) {
            await dispatch(actions.patchTerminal(operationResult.terminal));
        }
        if (operationResult.newSegments) {
            dispatch(actions.replaceTerminalSegments({ terminalId, segments: operationResult.newSegments }));
            dispatch(addTerminalSegmentsToSelection(operationResult.newSegments.map(s => s.id)));
        }
        if (operationResult.updatedSegments) {
            dispatch(actions.updateTerminalSegments({ terminalId, segments: operationResult.updatedSegments }));
            dispatch(addTerminalSegmentsToSelection(operationResult.updatedSegments.map(s => s.id)));
        }

        if (operationResult.warningMessage) {
            NotificationService.warning(operationResult.warningMessage);
        }
    } else {
        dispatch(actions.resetTerminal(terminalId));
    }
};

export const updateTerminalSegments = (terminalId: number, patchRequests: TerminalSegmentRequest[]) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.updateTerminalSegments(terminalId, patchRequests);
    if (operationResult?.updatedSegments) {
        if (operationResult.terminal) {
            await dispatch(actions.patchTerminal(operationResult.terminal));
        }
        dispatch(actions.updateTerminalSegments({ terminalId, segments: operationResult?.updatedSegments }));
        dispatch(addTerminalSegmentsToSelection(operationResult.updatedSegments.map(s => s.id)));

        if (operationResult.warningMessage) {
            NotificationService.warning(operationResult.warningMessage);
        }
    } else {
        dispatch(actions.resetTerminal(terminalId));
    }
};

export const patchTerminalSegment = (oldSegment: TerminalSegment, newSegment: TerminalSegment) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.patchTerminalSegment(oldSegment, newSegment);
    if (operationResult?.terminal) {
        dispatch(actions.patchTerminal(operationResult.terminal));

        if (operationResult.warningMessage) {
            NotificationService.infoPermanent(operationResult.warningMessage);
        }
    }
};

export const deleteTerminalSegment = (terminalId: number, segmentId: number) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.deleteTerminalSegment(terminalId, segmentId);
    if (operationResult?.terminal) {
        await dispatch(actions.patchTerminal(operationResult.terminal));
    }
    dispatch(actions.deleteTerminalSegment({ terminalId, segmentId }));
    dispatch(unselectTerminalSegment(segmentId));

};

export const patchTerminalExtension = (oldExtension: TerminalExtension, newExtension: TerminalExtension) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.patchTerminalExtension(oldExtension, newExtension);
    if (operationResult?.terminal) {
        dispatch(actions.patchTerminal(operationResult.terminal));
    }
};

export const resetTerminalExtension = (terminalId: number) => async dispatch => {
    const service = new TerminalService();
    const operationResult = await service.resetTerminalExtension(terminalId);
    if (operationResult?.terminal) {
        dispatch(actions.patchTerminal(operationResult.terminal));
    }
};

export const addTether = (napId: number) => async dispatch => {
    const service = new TetherService();
    const tetherAndTap = await service.addTether(napId);
    tetherAndTap && dispatch(actions.addTether(tetherAndTap));
};

export const patchTether = (oldTether: Tether, newTether: Tether) => async dispatch => {
    const service = new TetherService();
    service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
    try {
        const tether = await service.patchTether(oldTether, newTether);
        if (tether) {
            dispatch(actions.patchTether(tether));

            const terminal = tether.terminal;
            if (terminal && (terminal.portCount !== tether.fiberCount)) {
                if (terminal.matchTetherFiberCount || terminal.portCount < tether.fiberCount) {
                    const terminalPatchMessage = `${i18next.t(LocalizationKeys.TerminalPortUpdateMessageFromTether)}
                    ${(terminal.matchTetherFiberCount) ?
                            i18next.t(LocalizationKeys.TerminalPortUpdateMessageFromTetherReasonSetToMatch) :
                            i18next.t(LocalizationKeys.TerminalPortUpdateMessageFromTetherReasonMinimumValue)
                        }`;
                    patchTerminal(terminal, { ...terminal, portCount: tether.fiberCount }, terminalPatchMessage)(dispatch);
                }
            }
        }
    }
    catch (error) {
        const _err = error as any;
        const reason: string | undefined = _err?.response?.data;
        if (reason) {
            if (reason === 'gone') {
                NotificationService.error('Unable to modify this tether, it has been removed');
                dispatch(actions.deleteTether(oldTether));
            }
            else {
                NotificationService.error(`Unable to modify the tether: ${reason}`);
            }
        }
    }
};

export const applyPreset = (buildId: number, presetId: number) => async dispatch => {
    const service = new NapService();
    const errorMessage = 'Unable to apply preset on tethers';
    const tethers = await customErrorRequest(service, service.applyPreset(buildId, presetId), errorMessage);
    if (tethers) {
        dispatch(actions.updateTethers(tethers));
        applyBuildPreset(buildId, presetId)(dispatch);
    }
};

export const deleteNap = (napId: number, pretermBuilds?: FlexNapBuild[], ignoreHistory = false) => async dispatch => {
    const service = new NapService();
    const success = await requestIsSuccess(service, service.deleteNap(napId));
    if (success) {
        dispatch(actions.deleteNap(napId));
        if (pretermBuilds) {
            pretermBuilds.forEach(b => dispatch(revertPretermLateral(b.id)));
        }
        if (!ignoreHistory) {
            dispatch(push({ type: CommandType.DeleteFlexNapNap, payload: { id: napId, pretermBuilds } }));
        }
    }
};

export const undoDeleteNap = (napId: number, pretermBuilds?: FlexNapBuild[]) => async dispatch => {
    const service = new NapService();
    const napResult = await service.undoDeleteNap(napId);
    if (napResult) {
        const { nap, taps, tethers } = napResult;
        dispatch(actions.loadNapsTapsAndTethersFromImport({ naps: [nap], taps, tethers }));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (pretermBuilds) {
            pretermBuilds.forEach(b => !!b.pretermLateral && dispatch(undoRevertPretermLateral(b.id, b.pretermLateral!)));
        }
    }
};

export const resetNap = (napId: number) => async dispatch => {
    const service = new NapService();
    const nap = await service.resetNap(napId);
    nap && dispatch(actions.resetNap(nap));
};

export const deleteNapsForBuilds = (buildIds: number[]) => async dispatch => {
    const service = new NapService();
    const success = await requestIsSuccess(service, service.deleteNapsForBuilds(buildIds));
    success && dispatch(actions.deleteNapsForBuilds(buildIds));
};

export const undoDeleteNapsForBuilds = (buildIds: number[]) => async dispatch => {
    const service = new NapService();
    const napsResult = await service.undoDeleteNapsForBuilds(buildIds);
    napsResult && dispatch(actions.loadNapsTapsAndTethersFromImport(napsResult));
}

export const linkNapsOnElementsToBuild = (elementIds: number[], buildId: number) => async dispatch => {
    const service = new NapService();
    const errorMessage = 'Unable to apply preset on tethers';
    const tethers = await customErrorRequest(service, service.linkNapsOnElementsToBuild(elementIds, buildId), errorMessage);
    if (tethers) {
        dispatch(actions.linkNapsToSegment({ elementIds, buildId }));
        dispatch(actions.updateTethers(tethers))
    }
};

export const linkNapToBuild = (napId: number, buildId: number) => async dispatch => {
    const service = new NapService();
    const success = await requestIsSuccess(service, service.linkNapToBuild(napId, buildId));
    success && dispatch(actions.linkNapToBuild({ napId: napId, buildId: buildId }));
};

export const unlinkNapFromBuild = (napId: number, buildId: number) => async dispatch => {
    const service = new NapService();
    const success = await requestIsSuccess(service, service.unlinkNapFromBuild(napId, buildId));
    success && dispatch(actions.unlinkNapFromBuild({ napId: napId, buildId: buildId }));
};

export const unlinkNapsFromBuild = (buildId: number) => async dispatch => {
    const service = new NapService();
    const success = await requestIsSuccess(service, service.unlinkNapsFromBuild(buildId));
    success && dispatch(actions.unlinkNapsFromBuild(buildId));
};

export const deleteTether = (tether: Tether) => async dispatch => {
    const service = new TetherService();
    const success = await requestIsSuccess(service, service.deleteTether(tether));
    success && dispatch(actions.deleteTether(tether));
};

export const lockNapsConnectedToBuilds = (buildIds: number[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new NapService();
        const errorMessage = 'Unable to lock selected NAPs';
        const naps = await customErrorRequest(service, service.lockNapsConnectedToBuilds(buildIds, workspaceId), errorMessage);
        naps && dispatch(actions.updateNaps(naps));
    });
};

export const lockAllNaps = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new NapService();
        const errorMessage = 'Unable to lock NAPs';
        const naps = await customErrorRequest(service, service.lockAllNaps(workspaceId), errorMessage);
        naps && dispatch(actions.updateNaps(naps));
    });
};

export const unlockNapsConnectedToBuilds = (buildIds: number[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new NapService();
        const errorMessage = 'Unable to unlock selected NAPs';
        const naps = await customErrorRequest(service, service.unlockNapsConnectedToBuilds(buildIds, workspaceId), errorMessage);
        naps && dispatch(actions.updateNaps(naps));
    });
};

export const unlockAllNaps = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new NapService();
        const errorMessage = 'Unable to unlock NAPs';
        const naps = await customErrorRequest(service, service.unlockAllNaps(workspaceId), errorMessage);
        naps && dispatch(actions.updateNaps(naps));
    });
};

export const modifyNap = (napId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new NapService();
        const modifiedNap = await service.modifyNap(napId);
        if (modifiedNap) {
            dispatch(actions.updateNap(modifiedNap));
        }
    });
}

export const releaseNap = (napId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.releaseNap(napId));
        const service = new NapService();
        await service.releaseNap(napId);
    });
}

export const moveNap = (napId: number, fromElementId: number, toElementId: number, fromBuildId: number | null, toBuildId: number | null, pretermBuilds: FlexNapBuild[], ignoreHistory = false) => async dispatch => {
    const service = new NapService();
    const updatedNap = await service.moveNap(napId, toElementId, toBuildId);
    if (updatedNap) {
        dispatch(actions.updateNap(updatedNap));
        if (!ignoreHistory) {
            const payload = { id: napId, fromElementId, toElementId, fromBuildId, toBuildId, pretermBuilds };
            dispatch(push({ type: CommandType.MoveFlexNapNap, payload }));
        }
    } else {
        dispatch(resetNap(napId));
    }
}

export const cancelNapMove = (napId: number) => async dispatch => {
    dispatch(actions.cancelNapMove(napId));
}

export const resetNapExtenders = (napId: number) => async dispatch => {
    const service = new NapService();
    const napResetOperation = await service.resetNapExtenders(napId);
    if (napResetOperation?.terminals) {
        napResetOperation.terminals.forEach(t => dispatch(actions.patchTerminal(t)));

        if (napResetOperation.warning) {
            NotificationService.warning(napResetOperation.warning);
        }
    }
}

export const loadWorkspaceBuildNapLastUpdated = (workspaceId: number) => async dispatch => {
    const service = new NapService();
    const buildNapLastUpdates = await service.getBuildNapLastUpdated(workspaceId);
    buildNapLastUpdates && dispatch(actions.loadBuildNapLastUpdated(buildNapLastUpdates));
}
