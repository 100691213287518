import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Okta } from '../../okta/okta';
import { loginSuccessful } from '../../redux/authentication.state';
import { StateModel } from '../../redux/reducers';
import { AuthenticationService } from '../../services/authentication.service';
import { WebServiceErrorHandlingBehavior } from '../../services/abstract-web-v2.service';

interface RedirectResult {
    successful: boolean;
    error?: string;
    reason?: string;
}

const mapStateToProps = (state: StateModel) => {
    return {};
};
const mapDispatchToProps = {
    loginSuccessful,
};
type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class OpenIdCallbackComponent extends React.Component<props> {

    public state = {
        success: undefined,
        error: undefined,
        reason: undefined,
        isLogout: window.location.href.includes('/oid_logout'),
    };

    public componentDidMount() {
        this.go().then((res) => {
            this.setState({ success: res.successful, error: res.error, reason: res.reason });
        });
    }

    public async go(): Promise<RedirectResult> {
        if (!window.location.hash) {
            console.debug('Navigated to openid callback without any hash');
            return { successful: true };
        }
        const idToken = await Okta.getAndSaveTokenFromUrl();
        if (!idToken) {
            const parts = this.decodeHash();
            const error = parts.find((p) => p.key === 'error');
            const reason = parts.find((p) => p.key === 'error_description');
            console.warn('Callback expects a JWT token in the id_token hash of the url, but got: ', parts);
            return {
                successful: false,
                error: error && error.value,
                reason: reason && reason.value,
            };
        }

        const service = new AuthenticationService();
        try {
            service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
            const res = await service.oidLogin(idToken);
            if (res) {
                this.props.loginSuccessful!(res);
                return { successful: true };
            }
            else {
                return { successful: false, error: '', reason: res };
            }
        }
        catch (err) {
            const _err = err as any;
            const reason: string | undefined = _err?.response?.data;
            return { successful: false, error: '', reason: reason };
        }
    }

    private decodeHash(): Array<{ key: string; value: string }> {
        return window.location.hash
            .slice(1)
            .split('&')
            .map((s) => { const p = s.split('='); return { key: p[0], value: decodeURIComponent(p[1]).replace(/\+/g, ' ') }; });
    }

    public render() {
        const { success, reason, isLogout } = this.state;
        let content;
        if (isLogout) {
            content = (
                <div>
                    <h1>Logged out</h1>
                    <Link to="/login">Log back in</Link>
                </div>
            );
        }
        else if (success === undefined) {
            content = <h1>Processing...</h1>;
        }
        else if (success) {
            content = <Redirect to="/" />;
        }
        else {
            content = <div><h1>OpenID Connect process failed</h1><h2>{reason}</h2></div>;
        }

        return (
            <div className="login-container">
                <div className="login-content">
                    <div className="message">{content}</div>
                </div>
            </div>
        );
    }
}
