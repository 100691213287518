import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from '../../redux/reducers';

import { pathsSelector } from '../../selectors/root.selectors';
import { updatePathSegments } from '../../redux/path.actions';
import { Path } from '../../models/path';

export const PathManager = (): null => {
    const dispatch = useDispatch();
    const state = useSelector((state: StateModel) => state)

    const paths = useSelector(pathsSelector);
    const [prevPaths, setPrevPaths] = useState([] as Path[]);

    const { selectedBoreId, selectedTrenchId } = state.selection;

    useEffect(() => {
        const selectedPathId = selectedBoreId || selectedTrenchId;

        if (prevPaths.length && prevPaths.find(p => p.id === selectedPathId)?.measuredLength !== paths.find(p => p.id === selectedPathId)?.measuredLength) {
            if (selectedPathId) {
                updatePathSegments(selectedPathId)(dispatch);
            }
        }

        setPrevPaths(paths);
    }, [dispatch, paths, prevPaths, selectedTrenchId, selectedBoreId]);

    return null;
}