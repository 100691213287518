import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FileHelper } from '../../../../helpers/file-helper';
import i18n from '../../../../locales/i18n';
import { LocalizationKeys } from '../../../../locales/types';
import { logout } from '../../../../redux/authentication.state';
import { toggleShowErrors } from '../../../../redux/debug.state';
import { turnOnAutoDropCable } from '../../../../redux/map.state';
import { loadPresets } from '../../../../redux/preset.state';
import { isDevelopmentEnvironment, toggleRoleManagementMenu } from '../../../../redux/roles.state';
import { loadPresets as loadSchrodingerPresets } from '../../../../redux/schrodinger-preset.state';
import { displayedBuildIdSelector } from '../../../../selectors/build.selectors';
import {
    authenticationSelector, debugSelector, mapSelector,
    selectedBuildIdSelector, selectedTapIdSchrodingerSelector
} from '../../../../selectors/root.selectors';
import { DebugService } from '../../../../services/debug.service';
import { NotificationService } from '../../../../services/notification.service';
import { SchrodingerBuildService } from '../../../../services/schrodinger-build.service';

export const useDebugTools = () => {
    const { showErrors } = useSelector(debugSelector);
    const { email } = useSelector(authenticationSelector);
    const selectedBuildId = useSelector(selectedBuildIdSelector);
    const displayBuildId = useSelector(displayedBuildIdSelector);
    const selectedTapId = useSelector(selectedTapIdSchrodingerSelector);
    const { autoDropCable } = useSelector(mapSelector);

    const [buildDate] = useState("...");

    const dispatch = useDispatch();

    const toggleShowErrorsAndWarningsText = showErrors ? "Suppress error popups" : "Show error popups";
    const isAuthorizedToManageRoles = isDevelopmentEnvironment();
    const buildId = selectedBuildId ?? displayBuildId;

    const showSelectedBuildOrTapSessionId = useCallback(async () => {
        if (!buildId && !selectedTapId) {
            NotificationService.warning("Select a build or TAP.");
            return;
        }

        const loadingToast = NotificationService.loading("Retrieving session ID...");
        let sessionId: string | undefined = '';
        if (buildId) {
            sessionId = await new DebugService().getSessionIdByBuildIdAsync(buildId);
        } else if (selectedTapId) {
            sessionId = await new DebugService().getSessionIdByTapIdAsync(selectedTapId);
        }
        NotificationService.clear(loadingToast);
        if (sessionId) {
            navigator.clipboard.writeText(sessionId);
            NotificationService.info(`Session ID "${sessionId}" has been copied to the clipboard.`);
        } else {
            NotificationService.error("Unable to retrieve the session ID.");
        }
    }, [buildId, selectedTapId]);

    const exportSelectedBuildSessionAsCFG = useCallback(async () => {
        if (buildId) {
            const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Loading))
            const fileResult = await new SchrodingerBuildService().exportBuildSession(buildId);
            if (fileResult) {
                FileHelper.downloadFile(fileResult.filename, fileResult.data);
                NotificationService.success(i18n.t(LocalizationKeys.Done));
            }
            else {
                NotificationService.error(i18n.t(LocalizationKeys.Error));
            }
            NotificationService.clear(loadingToast);
        }
    }, [buildId]);

    const handleToggleShowErrorsClick = useCallback(() => {
        dispatch(toggleShowErrors());
    }, [dispatch]);

    const handleTurnOnAutoDropCableClick = useCallback(() => {
        dispatch(turnOnAutoDropCable(!autoDropCable));
    }, [autoDropCable, dispatch]);

    const handleLogoutClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const resetWorkspaces = useCallback(() => {
        if (window.confirm('Are you sure you want to delete all your builds and NAPs?')) {
            new DebugService().resetWorkspaces().then(() => NotificationService.info('Cleared'));
        }
    }, []);

    const resetGis = useCallback(async () => {
        const go = async () => {
            if (window.confirm('Are you sure you want to delete all the poles, spans, and parcels of your group, and every build of each user in your group?')) {
                const countRes = await new DebugService().countAffectedByResetGis();
                if (window.confirm(`Are you sure you want to delete ${countRes} user's builds?`)) {
                    await new DebugService().resetGis();
                    NotificationService.info('Cleared');
                    return true;
                }
            }
            return false;
        };
        go().then((reload) => {
            if (reload) {
                window.location.reload();
            }
        });
    }, []);

    const removeAllPresets = useCallback(async () => {
        if (window.confirm('Are you sure you want to delete all your presets?')) {
            await new DebugService().resetPresets();
            dispatch(loadPresets());
            dispatch(loadSchrodingerPresets());
            NotificationService.info('Presets Cleared');
        }
    }, [dispatch]);

    const removeAllDefaultPresets = useCallback(async () => {
        if (window.confirm('Are you sure you want to remove your default presets?')) {
            await new DebugService().resetDefaultPresets();
            dispatch(loadPresets());
            dispatch(loadSchrodingerPresets());
            NotificationService.info('Defaut Presets Removed');
        }
    }, [dispatch]);

    const uploadToConfigurator = useCallback(() => {
        window.parent.postMessage("ctcm.designer.uploadToConfigurator", window.location.href);
    }, []);

    const showRoleManagementTool = useCallback(() => {
        dispatch(toggleRoleManagementMenu());
    }, [dispatch]);

    const downloadSessionJson = useCallback(async () => {
        if (buildId) {
            const fileResult = await new DebugService().getSessionJsonByBuildIdAsync(buildId);
            fileResult && FileHelper.downloadFile(fileResult.filename, fileResult.data);
        }
    }, [buildId]);

    const endSession = useCallback(() => {
        if (buildId) {
            const service = new DebugService();
            service.endSessionAsync(buildId).then(() => {
                NotificationService.info("Session ended");
            });
        }
    }, [buildId]);

    return {
        toggleShowErrorsAndWarningsText,
        email,
        isAuthorizedToManageRoles,
        buildDate,
        showSelectedBuildOrTapSessionId,
        exportSelectedBuildSessionAsCFG,
        handleToggleShowErrorsClick,
        handleTurnOnAutoDropCableClick,
        handleLogoutClick,
        resetWorkspaces,
        resetGis,
        uploadToConfigurator,
        removeAllPresets,
        removeAllDefaultPresets,
        showRoleManagementTool,
        downloadSessionJson,
        endSession,
    };
};
