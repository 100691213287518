import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectTerminal, selectTether } from '../../../redux/selection.state';
import { FlexNapTetherCardProps } from './flexnap-tether-card.types';

export const useFlexNapTetherCard = (props: FlexNapTetherCardProps) => {
    const { tapIndex, tetherIndex, tether, disabled, viewOnlyMode } = props;
    const tetherIcon = disabled ? require('./../../../icons/locked.png') : require('./../../../icons/tether.png')
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);

    const unselectTether = useCallback((): void => {
        dispatch(selectTether());
        dispatch(selectTerminal());
    }, [dispatch]);


    const toggleCollapse = (): void => {
        const newCollapsed = !collapsed;
        setCollapsed(newCollapsed);
    }

    return { tetherIcon, tapIndex, tetherIndex, tether, unselectTether, disabled, viewOnlyMode, toggleCollapse, collapsed };
};