import React from 'react';

import { csticAliases } from '../../../../locales/cstic-aliases';
import { PresetWizardCstics } from '../preset-config/preset-config.types';
import { PresetAttribute } from './preset-attribute';
import { PresetAttributeGroupProps } from './preset-attributes.types';

export const PresetAttributeGroup = ({title, attributes}: PresetAttributeGroupProps): JSX.Element => {
    return (<div className="attribute-group">
        <span className="subtitle">{title}</span>
        <div>
            <span className="body">
                {attributes.find(a => a.csticId === PresetWizardCstics.PRODUCT_NUMBER)?.valueName}
            </span>
        </div>
        <div className="divider" />
        <div className="attributes">
            {attributes.map(a => a.csticId !== PresetWizardCstics.PRODUCT_NUMBER && a.valueName !== "" ? <PresetAttribute key={`${a.csticId}_${a.csticName}`} title={csticAliases[a.csticId] ?? a.csticName} description={a.valueName} /> : '')}
        </div>
    </div>);
};
