import React from 'react';

import { useDesignAreaIcon } from './design-area-icon.hooks';
import { DesignAreaIconProps } from './design-area-icon.types';
import { HoverDesignAreaIconPath } from './hover-design-area-icon-path';

export const DesignAreaIcon = (props: DesignAreaIconProps) => {
    const { handleDesignAreaClick, isCanvasMode } = useDesignAreaIcon(props);

    return (
        <>
            {isCanvasMode ?
                <div className="design-area-icon canvas" >
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        < rect width="42" height="42" rx="21" fill="#FBC734" />
                        <path d="M10.0226 28C11.0226 28 12.0826 28.08 13.4426 28.08C17.8626 28.08 20.9626 25.72 20.9626 21.1C20.9626 16.46 18.3826 14.44 14.0626 14.44C12.5826 14.44 11.0626 14.5 10.0226 14.52V28ZM11.8626 16C12.3626 15.98 13.1826 15.94 13.8826 15.94C17.3826 15.94 19.0226 17.78 19.0226 21.22C19.0226 24.88 16.8026 26.52 13.6426 26.52C13.1026 26.52 12.3626 26.48 11.8626 26.4V16ZM33.4727 28L28.6527 14.5H26.4127L21.5727 28H23.4527L24.5727 24.78H30.3127L31.4127 28H33.4727ZM29.8727 23.32H25.0327L26.8727 18.12C27.0727 17.48 27.2727 16.84 27.4327 16.24H27.4527C27.6327 16.84 27.8127 17.48 28.0327 18.14L29.8727 23.32Z" fill="white" />
                    </svg >
                </div>
                :
                <div className="design-area-icon" onClick={handleDesignAreaClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <g transform="translate(-16 -16)">
                            <circle fill="#fbc734" cx="20" cy="20" r="20" transform="translate(16 16)" />
                            <g className="design-area-icon-path" opacity="0.9" transform="translate(24 24)">
                                <path fill="none" d="M0,0H24V24H0Z" />
                                <path fill="#fdfdfd" d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z" />
                            </g>
                            <HoverDesignAreaIconPath />
                        </g>
                    </svg>

                </div>
            }

        </>

    );
}