import './drawer-workspace-body.scss';

import React, { useContext } from 'react';

import { Divider } from '@mui/material';

import { WorkspaceDrawerFilterContext } from '../filter/drawer-workspace-filter-context';
import { WorkspaceRow } from './row/drawer-workspace-row';

export const WorkspaceDrawerBody = () => {
    const { workspaceList } = useContext(WorkspaceDrawerFilterContext);
    
    return (
        <div className="workspace-drawer-body">
            { 
                workspaceList.map((p) => {
                    return (
                        <div key={"fragment-" + p.id}>
                            <WorkspaceRow workspace={p} />
                            <Divider variant="middle" />
                        </div>
                    );
                })
            }    
        </div>
    )
}
