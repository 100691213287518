import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Bore, BoreData } from '../models/bore';
import { PointLike } from '../models/pointlike';
import { BoreService } from '../services/bore.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { IMPORT_LOAD_THRESHOLD } from './import.state';

export interface BoreState {
    bores: Bore[];
}

const initialState: BoreState = {
    bores: [],
};

const loadAllReducer = (state: BoreState, action: PayloadAction<Bore[]>) => { state.bores = action.payload; };

const addBoreReducer = (state: BoreState, action: PayloadAction<Bore>) => { state.bores.push(action.payload); };

const addBoresReducer = (state: BoreState, action: PayloadAction<Bore[]>) => { state.bores.push(...action.payload); };

const updateBoreReducer = (state: BoreState, action: PayloadAction<Bore>) => {
    const bore: Bore = action.payload;
    const bIdx = state.bores.findIndex((b) => b.id === bore.id);
    if (bIdx < 0) {
        state.bores.push(bore);
    }
    else {
        state.bores[bIdx] = bore;
    }
};

const deleteBoreReducer = (state: BoreState, action: PayloadAction<number>) => {
    state.bores = state.bores.filter((t) => t.id !== action.payload);
};

const deleteBoresReducer = (state: BoreState, action: PayloadAction<number[]>) => {
    const boreIds = new Set(action.payload);
    state.bores = state.bores.filter((t) => !boreIds.has(t.id));
};

const deleteImportedBoresReducer = (state: BoreState, action: PayloadAction<number>) => {
    state.bores = state.bores.filter((t) => t.importedGisDataId !== action.payload);
};

const { actions, reducer } = createSlice({
    name: 'bore',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        addBore: addBoreReducer,
        addBores: addBoresReducer,
        updateBore: updateBoreReducer,
        deleteBore: deleteBoreReducer,
        deleteBores: deleteBoresReducer,
        deleteImportedBores: deleteImportedBoresReducer,
    },
});

export { reducer as BoreReducer };

export const { loadAll: loadBores, deleteBore, updateBore, deleteBores, deleteImportedBores } = actions;

export const getBores = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new BoreService();
    const bores = await service.getBores(bbox, required) || [];
    dispatch(actions.loadAll(bores));
};

export const addBore = (fromElementId: number, toElementId: number, points: PointLike[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BoreService();
        const bore = await service.addBore({fromElementId, toElementId, points});
        if (bore) {
            dispatch(actions.addBore(bore));
        }
    });
};

export const addBores = (data: BoreData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BoreService();
        const success = await requestIsSuccess(service, service.addBores(data, importedGisId));
        if (data.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
            getBores(bbox)(dispatch);
        }
    });
};
