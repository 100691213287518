import { PointLike } from '../../models/pointlike';
import { FlexNapBuild } from '../../models/flexnap-build';
import { Data } from './data';
import { TapData } from './tap-data';

export class NapData extends Data {
    public buildId: number | null;
    public elementId: number;
    public point: PointLike;
    public taps: TapData[];
    public childPretermBuilds?: FlexNapBuild[];

    constructor(id: number, buildId: number | null, elementId: number, point: PointLike, taps: TapData[], childPretermBuilds?: FlexNapBuild[]) {
        super(id);
        this.buildId = buildId;
        this.elementId = elementId;
        this.point = point;
        this.taps = taps;
        this.childPretermBuilds = childPretermBuilds;
    }

    public getExtent(): number[] {
        const { x, y } = this.point;
        return [x, y, x, y];
    }
}
