import { SearchBingService } from './search-bing.service';

export interface SearchResult {
    bbox: number[];
    name: string;
}

export interface AutoSuggestResult {
    type: string;
    address: string;
    formattedAddress: string;
    name: string;
}

export interface SearchServiceLike {
    search(query: string): Promise<SearchResult[]>;
    autocomplete(query: string, location: number[]): Promise<AutoSuggestResult[] | undefined>;
    findLocation(location: number[]): Promise<string | undefined>;
}

export class SearchService {

    searchServiceImpl: SearchServiceLike;

    public constructor() {
        this.searchServiceImpl = new SearchBingService();
    }

    public search(query: string): Promise<SearchResult[] | undefined> {
        if (!query || query.length === 1) {
            return Promise.resolve(undefined);
        }
        return this.searchServiceImpl.search(query);
    }

    public autocomplete(query: string, location: number[]): Promise<AutoSuggestResult[] | undefined> {
        if (!query || query.length === 1) {
            return Promise.resolve(undefined);
        }
        return this.searchServiceImpl.autocomplete(query, location);
    }

    public findLocation(location: number[]): Promise<string | undefined> {
        return this.searchServiceImpl.findLocation(location);
    }
}