import { createSelector } from '@reduxjs/toolkit';

import { StateModel } from '../redux/reducers';
import { schrodingerPresetSelector } from './root.selectors';

export const presetSelector = (state: StateModel) => state.preset;

export const presetsSelector = createSelector(
    presetSelector,
    preset => preset.presets
);

export const defaultPresetSelector = createSelector(
    presetsSelector,
    presets => presets.find((p) => p.isDefault)
);

export const schrodingerPresetSortedSelector = createSelector(
    schrodingerPresetSelector,
    (schrodingerpreset) => {
        const presets = [...schrodingerpreset?.presets]
        return presets.sort((a, b) => a.name > b.name ? 1 : -1)
    }
)