import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentHighlighted } from '../../redux/viewport.state';

import { ControlType, DialogType, showControl, showDialog } from '../../redux/overlay.state';
import { StateModel } from '../../redux/reducers';
import { clearSelection } from '../../redux/selection.state';
import { reviewNotificationsCountSelector } from '../../selectors/review.selectors';

export const useCanvasMenuBar = () => {
    const dispatch = useDispatch();

    const { control } = useSelector((state: StateModel) => state.overlay);
    const { layers } = useSelector((state: StateModel) => state.layer);
    const { notifications } = useSelector((state: StateModel) => state.notification);
    const reviewNotificationsCount = useSelector(reviewNotificationsCountSelector);

    const showPresets = useCallback((): void => {
        dispatch(showDialog(DialogType.Preset));
        dispatch(showControl());
    }, [dispatch]);

    const newCanvasBuild = useCallback((): void => {
        dispatch(showDialog(DialogType.CreateNewBuild));
        dispatch(showControl());
        dispatch(clearSelection());
        dispatch(setCurrentHighlighted(0))
    }, [dispatch]);

    const showMoreActions = useCallback((): void => {
        dispatch(showControl(control !== ControlType.More ? ControlType.More : undefined));
    }, [control, dispatch]);

    const showWarnings = useCallback((): void => {
        dispatch(showControl(control !== ControlType.Warnings ? ControlType.Warnings : undefined));
    }, [control, dispatch]);

    const showBuildsInReview = useCallback((): void => {
        dispatch(showControl(control !== ControlType.Review ? ControlType.Review : undefined));
    }, [control, dispatch])

    return {
        control,
        layers,
        notifications,
        reviewNotificationsCount,
        showPresets,
        newCanvasBuild,
        showMoreActions,
        showWarnings,
        showBuildsInReview
    };
}