import { createSelector } from '@reduxjs/toolkit';

import { SchrodingerReferenceType } from '../../models/schrodinger/reference-id';
import { Tap } from '../../models/schrodinger/tap';
import {
    awaitingSchrodingerSessionUpdatesSelector, segmentsSelector, selectedBuildIdSelector,
    selectedTapIdSchrodingerSelector, tapsSchrodingerSelector
} from '../root.selectors';

export const selectedTapSelector = createSelector(
    selectedTapIdSchrodingerSelector,
    tapsSchrodingerSelector,
    (tapId, taps) =>
        taps.find((t: Tap) => t.id === tapId)
)

export const selectedTapBuildOrderedElementIdsSelector = createSelector(
    selectedTapSelector,
    segmentsSelector,
    (selectedTap, segments) =>
        [...new Set(segments
            .filter((s) => !!selectedTap && s.buildId === selectedTap.buildId)
            .sort((a, b) => a.position - b.position)
            .flatMap(s => [s.fromId, s.toId]))
        ]
);

/**
 * Get all taps (ordered by sequence in the build) from the same build as the selected tap
 */
export const selectedTapOrderedSiblingsSelector = createSelector(
    selectedTapSelector,
    tapsSchrodingerSelector,
    selectedTapBuildOrderedElementIdsSelector,
    (selectedTap, taps, orderedElementIds) =>
        taps.filter((t: Tap) => selectedTap && !!t.buildId && t.buildId === selectedTap.buildId)
            .sort((a, b) => orderedElementIds.indexOf(a.elementId) - orderedElementIds.indexOf(b.elementId)) // sort by the order in which they appear in the build
)

export const selectedBuildTapSelector = createSelector(
    selectedTapIdSchrodingerSelector,
    selectedBuildIdSelector,
    tapsSchrodingerSelector,
    (tapId, buildId, taps) => taps.find((t) => t.id === tapId || t.buildId === buildId)
)

export const selectedBuildIdFromTapSelector = createSelector(
    selectedBuildIdSelector,
    selectedBuildTapSelector,
    (buildId, tap) => buildId || tap?.buildId,
)

export const selectedBuildTapsSelector = createSelector(
    selectedBuildIdFromTapSelector,
    tapsSchrodingerSelector,
    (buildId, taps) => taps.filter((t) => t.buildId === buildId) || []
)

export const selectedTapAwaitingSessionUpdateSelector = createSelector(
    selectedTapSelector,
    awaitingSchrodingerSessionUpdatesSelector,
    (tap, awaitingSchrodingerReferenceId) => tap?.buildId ? !!awaitingSchrodingerReferenceId.find(t => t.id === tap.buildId && t.type === SchrodingerReferenceType.Build)
        : !!awaitingSchrodingerReferenceId.find(t => t.id === tap?.id && t.type === SchrodingerReferenceType.Tap)
)
