import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../../locales/types';
import { SplicePlanNavigation } from '../splice-plan-navigation';
import { useSplicePlanDialogContent } from './splice-plan-dialog-content.hooks';
import { SplicePlanTable } from './splice-plan-table';
import { SplicePlanDialogContentProps } from './splice-plan-table.types';

export const SplicePlanDialogContent = (props: SplicePlanDialogContentProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        fibers, locations, currentLocation,
        setCurrentLocation
    } = useSplicePlanDialogContent(props);

    return (
        <>
            <SplicePlanTable fibers={fibers} />
            <SplicePlanNavigation
                locations={locations}
                currentLocation={currentLocation}
                setCurrentLocation={setCurrentLocation}
            />
            <div className="splice-plan-dialog-shortcuts">
                {t(LocalizationKeys.SplicePlanShortcuts)}
            </div>
        </>
    );
}
