import { useSelector } from 'react-redux';
import { selectedTapSelector } from '../../selectors/schrodinger/taps.selectors';

import {
    controlSelector, designModeSelector, overlaySelector, schrodingerBuildsSelector,
    selectedBuildIdSelector, selectedDesignAreaIdSelector
} from '../../selectors/root.selectors';

export const useDrawerRight = () => {
    const designMode = useSelector(designModeSelector);
    const selectedDesignAreaId = useSelector(selectedDesignAreaIdSelector);
    const selectedBuildId = useSelector(selectedBuildIdSelector);
    const selectedTap = useSelector(selectedTapSelector);
    const control = useSelector(controlSelector)
    const schrodingerBuilds = useSelector(schrodingerBuildsSelector);
    const { dialog } = useSelector(overlaySelector);

    const selectedSchrodingerBuild = schrodingerBuilds.find((b) => b.id === selectedBuildId);

    return {
        designMode,
        selectedSchrodingerBuild,
        selectedTap,
        control,
        selectedDesignAreaId,
        dialog
    };
};
