import React from 'react';
import { TerminalLegProps } from '../nap-diagram.types';

export const TerminalExtender = (props: TerminalLegProps): JSX.Element => {
    const fill = props.fill ?? "#606060";
    const stroke = props.stroke ?? "#9e9e9e";
    return (
        <g>
            <rect x="4.5" y="9.5" width="3" height="20" fill={fill} stroke={stroke} />
            <rect x="4.5" y="33.5" width="3" height="20" fill={fill} stroke={stroke} />
            <line x1="1" y1="27" x2="11" y2="32" stroke="#000000" />
            <line x1="1" y1="31" x2="11" y2="36" stroke="#000000" />
        </g>
    );
};
