import i18next from 'i18next';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocalizationKeys } from '../locales/types';
import { DesignMode } from '../models/design-mode';
import {
    SchrodingerReferenceId, SchrodingerReferenceType
} from '../models/schrodinger/reference-id';
import { Tap } from '../models/schrodinger/tap';
import { Workspace } from '../models/workspace';
import { NotificationService } from '../services/notification.service';
import { AddWorkspaceRequest, WorkspaceService } from '../services/workspace.service';
import { createSecuredAsyncAction } from './action';
import { setDisplayedBuildId } from './build.state';
import { clear as clearHistory } from './history.state';
import { zoomAt } from './map.state';

export interface WorkspaceState {
    currentWorkspace?: Workspace;
    workspaces: Workspace[];
    awaitingSchrodingerSessionUpdates: SchrodingerReferenceId[]; // used to block taps/builds from getting concurrent updates, which breaks the session.
    recentDesignMode?: DesignMode;
    editingWorkspaceId?: number;
}

const initialState: WorkspaceState = {
    currentWorkspace: undefined,
    workspaces: [],
    awaitingSchrodingerSessionUpdates: [],
    recentDesignMode: undefined,
};

function changeWorkspaceReducer(state: WorkspaceState, action: PayloadAction<Workspace | undefined>) {
    state.currentWorkspace = action.payload;
}

function loadWorkspacesReducer(state: WorkspaceState, action: PayloadAction<Workspace[]>) {
    state.workspaces = action.payload;
}

function createWorkspaceReducer(state: WorkspaceState, action: PayloadAction<Workspace>) {
    const workspace = action.payload;
    state.currentWorkspace = workspace;
    state.workspaces.push(workspace);
}

function updateWorkspaceReducer(state: WorkspaceState, action: PayloadAction<Workspace>) {
    const workspace = action.payload;
    const workspaceIndex = state.workspaces.findIndex((p) => p.id === workspace.id);
    if (workspaceIndex < 0) {
        state.workspaces.push(workspace);
    }
    else {
        state.workspaces[workspaceIndex] = workspace;
    }

    if (state.currentWorkspace && state.currentWorkspace.id === workspace.id) {
        state.currentWorkspace = workspace;
    }
}

function deleteWorkspaceReducer(state: WorkspaceState, action: PayloadAction<number>) {
    const workspaceId = action.payload;
    state.workspaces = state.workspaces.filter((p) => p.id !== workspaceId);
}

const setTapAwaitingReducer = (state: WorkspaceState, action: PayloadAction<Tap>): void => {
    const [referenceId, referenceType] = action.payload?.buildId ? [action.payload.buildId, SchrodingerReferenceType.Build] : [action.payload.id, SchrodingerReferenceType.Tap];
    const includesTapId = state.awaitingSchrodingerSessionUpdates.find(a => a.id === referenceId && a.type === referenceType);
    if (!includesTapId) {
        state.awaitingSchrodingerSessionUpdates.push({ id: referenceId, type: referenceType });
    }
};

const unsetTapAwaitingReducer = (state: WorkspaceState, action: PayloadAction<Tap>): void => {
    const [referenceId, referenceType] = action.payload?.buildId ? [action.payload.buildId, SchrodingerReferenceType.Build] : [action.payload.id, SchrodingerReferenceType.Tap];
    state.awaitingSchrodingerSessionUpdates = state.awaitingSchrodingerSessionUpdates.filter(r => !(r.id === referenceId && r.type === referenceType));
};

const setBuildAwaitingReducer = (state: WorkspaceState, action: PayloadAction<number>): void => {
    const [referenceId, referenceType] = [action.payload, SchrodingerReferenceType.Build];
    const includesBuildId = state.awaitingSchrodingerSessionUpdates.find(a => a.id === referenceId && a.type === referenceType);
    if (!includesBuildId) {
        state.awaitingSchrodingerSessionUpdates.push({ id: referenceId, type: referenceType });
    }
}

const unsetBuildAwaitingReducer = (state: WorkspaceState, action: PayloadAction<number>): void => {
    const [referenceId, referenceType] = [action.payload, SchrodingerReferenceType.Build];
    state.awaitingSchrodingerSessionUpdates = state.awaitingSchrodingerSessionUpdates.filter(r => !(r.id === referenceId && r.type === referenceType));
};

const setDesignModeReducer = (state: WorkspaceState, action: PayloadAction<DesignMode | undefined>): void => {
    const mode = action.payload;
    state.recentDesignMode = mode;
};

function setEditingWorkspaceIdReducer(state: WorkspaceState, action: PayloadAction<number | undefined>) {
    state.editingWorkspaceId = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        changeWorkspace: changeWorkspaceReducer,
        loadWorkspaces: loadWorkspacesReducer,
        createWorkspace: createWorkspaceReducer,
        updateWorkspace: updateWorkspaceReducer,
        deleteWorkspace: deleteWorkspaceReducer,
        setDesignMode: setDesignModeReducer,
        setTapAwaiting: setTapAwaitingReducer,
        unsetTapAwaiting: unsetTapAwaitingReducer,
        setEditingWorkspaceId: setEditingWorkspaceIdReducer,
        setBuildAwaiting: setBuildAwaitingReducer,
        unsetBuildAwaiting: unsetBuildAwaitingReducer
    },
});

export { reducer as WorkspaceReducer };
export const { setTapAwaiting, unsetTapAwaiting, setDesignMode, setEditingWorkspaceId, setBuildAwaiting, unsetBuildAwaiting } = actions;

export const updateInitialWorkspaceBbox = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const previousMapBbox = JSON.parse(localStorage.getItem("previousBbox") ?? "[]");
        const service = new WorkspaceService();
        const initialBbox = await service.updateWorkspaceInitialBbox(previousMapBbox);
        if (initialBbox && (initialBbox[0] !== previousMapBbox[0] || initialBbox[1] !== previousMapBbox[1] || initialBbox[2] !== previousMapBbox[2] || initialBbox[3] !== previousMapBbox[3])) {
            dispatch(zoomAt(initialBbox));
        }
    });
};

async function extraAutoCreateWorkspaceForDevelopment(workspaces: Workspace[]) {
    const service = new WorkspaceService();
    if (!workspaces.length) {
        const workspace = await service.addWorkspace({
            workspaceId: (i18next.t(LocalizationKeys.DefaultWorkspaceId, { workspaceId: 1 }) as string),
            name: (i18next.t(LocalizationKeys.DefaultWorkspaceName, { workspaceNumber: 1 }) as string),
            city: (i18next.t(LocalizationKeys.DefaultWorkspaceCity) as string),
            country: (i18next.t(LocalizationKeys.DefaultWorkspaceCountry) as string),
            description: (i18next.t(LocalizationKeys.DefaultWorkspaceDescription) as string),
            region: (i18next.t(LocalizationKeys.DefaultWorkspaceRegion) as string),
            zipCode: '',
        });
        if (workspace) {
            workspaces.push(workspace);
        }
        else {
            NotificationService.error('Unable to seed initial workspace');
        }
    }
}

async function extraAutoSelectFirstWorkspace(dispatch: any, workspaces: Workspace[]): Promise<Workspace | undefined> {
    if (workspaces.length) {
        const firstWorkspace = workspaces[0];
        dispatch(actions.changeWorkspace(firstWorkspace));
        return firstWorkspace;
    }
    else {
        NotificationService.error('Unable to preload a workspace');
        return undefined;
    }
}

async function autoSelectRecentWorkspace(dispatch: any, workspaces: Workspace[], recentWorkspaceId?: number) {
    const recentWorkspace = workspaces.find((p) => p.id === recentWorkspaceId);
    let workspaceId: number | undefined;
    if (!recentWorkspace) {
        const firstWorkspace = await extraAutoSelectFirstWorkspace(dispatch, workspaces);
        workspaceId = firstWorkspace?.id;
    }
    else {
        dispatch(actions.changeWorkspace(recentWorkspace));
        workspaceId = recentWorkspace.id;
    }
    if (workspaceId) {
        const service = new WorkspaceService();
        const updatedWorkspace = await service.getWorkspace(workspaceId);

        if (updatedWorkspace) {
            service.updateRecentWorkspace({ recentWorkspaceId: workspaceId });
            dispatch(actions.updateWorkspace(updatedWorkspace));

        }
    }
}

export const loadWorkspaces = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new WorkspaceService();
        await updateInitialWorkspaceBbox()(dispatch);
        const data = await service.getWorkspaces();
        if (data) {
            const { workspaces, recentWorkspace } = data;
            if (recentWorkspace?.recentDesignMode) {
                dispatch(setDesignMode(recentWorkspace.recentDesignMode));
            }
            await extraAutoCreateWorkspaceForDevelopment(workspaces);
            dispatch(actions.loadWorkspaces(workspaces));
            await autoSelectRecentWorkspace(dispatch, workspaces, recentWorkspace?.recentWorkspaceId);
        }
    });
};


export const createWorkspace = (workspaceRequest: AddWorkspaceRequest) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new WorkspaceService();
        const workspace = await service.addWorkspace(workspaceRequest);
        if (workspace) {
            dispatch(actions.createWorkspace(workspace));
        }
    });
}

export const updateWorkspace = (oldWorkspace: Workspace, newWorkspace: Workspace) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new WorkspaceService();
        const workspace = await service.updateWorkspace(oldWorkspace, newWorkspace);
        if (workspace) {
            dispatch(actions.updateWorkspace(workspace));
        }
    });
};

export const deleteWorkspace = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new WorkspaceService();
        if (await service.deleteWorkspace(workspaceId)) {
            dispatch(actions.deleteWorkspace(workspaceId));
        }
    });
};

export const changeWorkspace = (workspace: Workspace, zoomToInitialBbox = true) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.changeWorkspace(workspace));
        const service = new WorkspaceService();
        const updatedWorkspace = await service.getWorkspace(workspace.id);
        if (updatedWorkspace) {
            service.updateRecentWorkspace({ recentWorkspaceId: updatedWorkspace.id });
            zoomToInitialBbox && dispatch(updateInitialWorkspaceBbox());
            dispatch(setDisplayedBuildId());
            dispatch(actions.updateWorkspace(updatedWorkspace));
            dispatch(actions.changeWorkspace(updatedWorkspace));

            dispatch(clearHistory());
        }
    });
};


export const updateLastUsedDesignMode = (mode: DesignMode) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new WorkspaceService();
        const workspace = await service.updateRecentWorkspace({ recentDesignMode: mode });
        if (workspace) {
            dispatch(setDesignMode(workspace.recentDesignMode));
        }
    })
}

