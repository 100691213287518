import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexNapBuild } from '../../models/flexnap-build';
import { Nap } from '../../models/nap';
import { Tether } from '../../models/tether';
import { StateModel } from '../../redux/reducers';
import { deleteSplicePlan, updatePretermBuildsSplicePlans } from '../../redux/splice-plan.state';
import { flexNapPretermBuildsSelector } from '../../selectors/build.selectors';
import {
    flexnapBuildsSelector, napsSelector, selectedBuildIdSelector, tethersSelector
} from '../../selectors/root.selectors';

export const PretermManager = (): null => {
    const state = useSelector((state: StateModel) => state)
    const dispatch = useDispatch();
    const { selectedNapId, selectedTetherId } = state.selection;


    const naps = useSelector(napsSelector);
    const [selectedNap, setSelectedNap] = useState({} as Nap | undefined);
    const [prevNaps, setPrevNaps] = useState([] as Nap[]);

    const tethers = useSelector(tethersSelector);
    const [prevTether, setPrevTether] = useState({} as Tether | undefined);
    const [prevTethersCount, setPrevTethersCount] = useState(tethers.length);

    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const pretermBuilds = useSelector(flexNapPretermBuildsSelector);
    const selectedBuildId = useSelector(selectedBuildIdSelector);
    const [prevFlexNapBuilds, setPrevFlexNapBuilds] = useState([] as FlexNapBuild[]);
    const [prevPretermBuilds, setPrevPretermBuilds] = useState([] as FlexNapBuild[]);

    const updatePretermBuildsSplicePlansIfPreterm = useCallback((parentBuildId: number) => {
        if (flexNapBuilds.find(ptb => !!ptb.pretermLateral && ptb.pretermLateral?.parentBuildId === parentBuildId)) {

            updatePretermBuildsSplicePlans(parentBuildId)(dispatch);
        }
    }, [dispatch, flexNapBuilds]);

    useEffect(() => {
        if (prevNaps.length !== 0) {
            if (naps.length !== prevNaps.length) {
                // nap was added or deleted
                const newNap = naps.find(n => !prevNaps.includes(n));
                if (newNap && newNap.buildId) {
                    updatePretermBuildsSplicePlansIfPreterm(newNap.buildId);
                }
                const deletedNap = prevNaps.find(n => !naps.includes(n));
                if (deletedNap && deletedNap.buildId) {
                    updatePretermBuildsSplicePlansIfPreterm(deletedNap.buildId);
                }
            }
            else {
                // nap was modified
                const modifiedNap = naps.find(n => !prevNaps.find(prev => prev.id === n.id && prev.buildId === n.buildId));
                if (modifiedNap) {
                    if (modifiedNap?.buildId) {
                        updatePretermBuildsSplicePlansIfPreterm(modifiedNap.buildId);
                    }
                    const previousBuildId = prevNaps.find(n => n.id === modifiedNap.id)?.buildId;
                    if (previousBuildId) {
                        updatePretermBuildsSplicePlansIfPreterm(previousBuildId);
                    }
                }
            }
        }
        setSelectedNap(naps.find(n => n.id === selectedNapId));
        setPrevNaps(naps);
    }, [naps, prevNaps, selectedNapId, updatePretermBuildsSplicePlansIfPreterm]);

    useEffect(() => {
        const selectedTether = tethers.find(n => n.id === selectedTetherId);
        if (prevTether && prevTether.id === selectedTetherId) {
            if (selectedTether !== prevTether && !!selectedNap?.buildId) {
                // tether was modified
                updatePretermBuildsSplicePlansIfPreterm(selectedNap.buildId);
            }
        }
        else if (tethers.length !== prevTethersCount && !!selectedNap?.buildId) {
            // tether was added or deleted
            updatePretermBuildsSplicePlansIfPreterm(selectedNap.buildId);
        }
        setPrevTether(selectedTether);
        setPrevTethersCount(tethers.length);
    }, [selectedNap, tethers, prevTether, selectedTetherId, prevTethersCount, updatePretermBuildsSplicePlansIfPreterm]);

    useEffect(() => {
        if (pretermBuilds.length !== prevPretermBuilds.length) {
            const newPretermBuild = pretermBuilds.find(ptb => !prevPretermBuilds.includes(ptb));
            if (newPretermBuild && newPretermBuild.pretermLateral) {
                // new preterm was added
                updatePretermBuildsSplicePlans(newPretermBuild.pretermLateral.parentBuildId)(dispatch);
            }
            const deletedPretermBuild = prevPretermBuilds.find(ptb => !pretermBuilds.includes(ptb));
            if (deletedPretermBuild && deletedPretermBuild.pretermLateral) {
                deleteSplicePlan(deletedPretermBuild.id)(dispatch);
            }
        } else if (prevFlexNapBuilds.length !== 0) {
            const modifiedBuild = flexNapBuilds.find(b => b.id === selectedBuildId
                && !prevFlexNapBuilds.find(prev => prev.id === b.id && prev.fiberCount === b.fiberCount));
            if (modifiedBuild) {
                // build fiber count was modified, check if is a parent build
                updatePretermBuildsSplicePlansIfPreterm(modifiedBuild.id);
            }
        }
        setPrevPretermBuilds(pretermBuilds);
        setPrevFlexNapBuilds(flexNapBuilds);
    }, [dispatch, flexNapBuilds, pretermBuilds, prevFlexNapBuilds, prevPretermBuilds, selectedBuildId, updatePretermBuildsSplicePlansIfPreterm]);

    return null;
}