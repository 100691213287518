import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Review } from '../../../models/review';
import { dismissReviewNotification } from '../../../redux/review.state';
import { useShowReviewBuild } from '../review-list.hooks';

export const useResolvedReviewListItem = (review: Review) => {
    const dispatch = useDispatch();

    const { handleShowBuildClick } = useShowReviewBuild(review);

    const handleDismissClick = useCallback(() => {
        dismissReviewNotification([review.id])(dispatch);
    }, [dispatch, review.id]);

    return {
        ...review,
        handleDismissClick,
        handleShowBuildClick
    };
};