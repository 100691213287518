import * as rfc from 'rfc6902';

import { Cabinet, CabinetData } from '../models/cabinet';
import { PointLike } from '../models/pointlike';
import { SecuredService } from './abstract-secured-v2.service';

export class CabinetService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/cabinet';

    public getAll(): Promise<Cabinet[] | undefined> {
        return this.get<Cabinet[]>(this.baseUrl);
    }

    public getCabinets(bbox: number[], required?: number[]): Promise<Cabinet[] | undefined> {
        return this.post<Cabinet[]>(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public getCabinetsInDesignArea(polygon: PointLike[]): Promise<Cabinet[] | undefined> {
        return this.post<Cabinet[]>(`${this.baseUrl}/designarea`, { polygon });
    }

    public getCabinetsByIds(ids: number[]): Promise<Cabinet[] | undefined> {
        return this.post<Cabinet[]>(`${this.baseUrl}/byids`, ids);
    }

    public addCabinet(x: number, y: number): Promise<Cabinet | undefined> {
        return this.post<Cabinet>(this.baseUrl, { x, y });
    }

    public addCabinets(data: CabinetData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/import`, { data, importedGisDataId });
    }

    public patchCabinet(oldCabinet: Cabinet, newCabinet: Cabinet): Promise<Cabinet | undefined> {
        const patch = rfc.createPatch(oldCabinet, newCabinet);
        return this.patch(`${this.baseUrl}/${oldCabinet.id}`, patch);
    }
}
