export class PresetDefinition {

    public static attributes: { [s: string]: string } = {
        'Environment': 'Environment Type',
        'Installation': 'Installation Type',
        'Armor': 'Armor Type',
        'Cable': 'Cable Type',
        'Toneable': 'Toneable Type',
        'Support': 'Support Type',
        'Flame Retardant': 'Flame Retardant Type',
        'Fiber Mode': 'Fiber Mode Type',
        'Buffer-tube Fill': 'Buffer-tube Fill Type',
    };

    public static help: { [s: string]: string } = {
        'Cable Part Number': 'Select the cable part number here. You’ll select the number of fibers in a next step',
        'Tether Part Number': 'Select the tether part number related to your cable part number. You’ll select the number of fibers in a next step.',
        'CO Slack': 'This slack is typically used for splicing the cable into the HUB or Main Distribution network. This number can be changed to greater than 50 ft, but not less than.',
        'Field Slack': 'This slack is typically used on the Field End of the FlexNAP System cable. Default value for Aerial/Tray is 15 ft and for Buried/Duct is 25 ft. This can be changed to greater values, but not less.',
        'Cable Fiber Count': 'This is your default value for the preset, but it can be changed in the design.',
        'Tether Fiber Count': 'This is your default value for the preset, but it can be changed in the design.',
        'Environment Type': 'Select what type of environment this build will be deployed in.',
        'Installation Type': 'These options are based on the environment type.',
        'Duct Size': 'The options for duct size are:1.25-in, >1.25 –in. This refers to inner diameter of the duct.',
        'Armor Type': 'Select if armored cable is needed. If it is needed, select “Single”. If no armor is needed, select “N/A”. Only armored option is single jacket, single armor.',
        'Cable Type': 'The options in this field will change based on the environment and installation type chosen. The options are: Loose Tube, RPX® Ribbon.',
        'Fiber Mode Type': 'If the environment type is indoors, the choices are: Single-Mode OS2, 50 µm multimode OM3.',
        'Buffer-tube Fill Type': 'Select what buffer-tube fill type is needed. Depending on the previous choices made, this may be automatically generated. All possible options are: Gel-Free and Gel-Filled. Gel-filled is only offered on figure-8 cable type.',
        'Flame Retardant': '',
        'Support Type': '',
        'Toneable': '',
        'Loopback': 'Toggle loopback as Yes or No to select the right part number. Note that this cannot be changed in the design mode.'
    };
}
