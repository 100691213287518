import { useCallback, useContext, useEffect, useState } from 'react';

import { RadioProps } from '@orbit/radio';

import { PresetContext } from '../preset-config/preset-config.types';
import { PresetCsticProps } from './preset-cstic.types';

export const usePresetCstic = (props: PresetCsticProps) => {
    const { session, updateSession, loading } = useContext(PresetContext);

    const {cstic, progressData} = props;
    
    const [attributes, setAttributes] = useState<RadioProps[]>([]);
    const [selected, setSelected] = useState<string | undefined>();

    useEffect(() => {
        if (cstic) {
            setAttributes(cstic.values.filter((v) => !!v.id).map((v) => { return { label: v.shortDescription ?? "", key: v.id, value: v.shortDescription ?? "" } }) ?? []);
            setSelected(cstic.valueId ?? undefined);
        }
    }, [cstic]);

    const handleAttributeSelection = useCallback((event: React.ChangeEvent<any>): void => {
        const value = event.currentTarget.value;
        setSelected(value);
    }, []);

    const handleNextClick = useCallback(() => {
        if (cstic && selected) {
            const csticValue = cstic.values.find((v) => v.shortDescription === selected);
            if (csticValue) {
                updateSession(cstic.instanceId, cstic.id, csticValue.id);
            }
        }
    }, [cstic, selected, updateSession]);

    const handlePreviousClick = useCallback(() => {
        if (progressData.length > 1 && session) {
            const pd = progressData[progressData.length - 2];
            const cstics = session.materials.flatMap((m) => m.cstics);
            const cstic = cstics.find((c) => c.description === pd.title);
            if (cstic) {
                updateSession(cstic.instanceId, cstic.id, "");
            }
        }
    }, [session, progressData, updateSession]);

    return {
        attributes, selected,
        loading,
        handleAttributeSelection, handleNextClick, handlePreviousClick
    };
};