import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../locales/types';
import notch from './icons/icon-notification-notch.svg';
import { useNotificationPopup } from './notification-popup.hooks';

export const NotificationPopup = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        isOpen,
        reviewNotifications,
        notificationTextClasses,
        notchClasses,
        popupClasses,
        handleDismiss
    } = useNotificationPopup();

    return <>
        {isOpen &&
            <div>
                <img className={notchClasses} src={notch} alt='Notch' />
                <div className={popupClasses}>
                    <ul className={notificationTextClasses}>
                        {reviewNotifications.map((line, i) => line && (<li key={i}>{line}</li>))}
                    </ul>
                    <button onClick={handleDismiss}>{t(LocalizationKeys.Dismiss)}</button>
                </div>
            </div>
        }
    </>
}