import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UnitInterpreter } from '../../../../helpers/unit-interpreter';
import { LocalizationKeys } from '../../../../locales/types';
import {
    PresetContext, PresetWizardCstics, PresetWizardMaterials, PresetWizardStepMaterials, PresetWizardSteps
} from '../preset-config/preset-config.types';
import { AttributeGroup, PresetAttributesProps } from './preset-attributes.types';
import { CardInputMaxLength } from '../../../card/card.types';

export const usePresetAttributes = ({ isDefault: isDefaultPreset, disableDefault, onCancelClick }: PresetAttributesProps) => {
    const { t } = useTranslation();
    const { session, presetName, loading, savePreset } = useContext(PresetContext);

    const [isDefault, setIsDefault] = useState(!!isDefaultPreset);
    const [attributeGroups, setAttributeGroups] = useState<AttributeGroup[]>([]);

    const disabled = !presetName || presetName.trim().length === 0 || presetName.length > CardInputMaxLength.PRESET_NAME;
    useEffect(() => {
        if (!session) {
            return;
        }

        const attributeGroupLabelOverrides = {
            [PresetWizardSteps.AttributeSelection]: presetName,
            [PresetWizardSteps.PowerTetherAttributes]: t(LocalizationKeys.PowerTether),
            [PresetWizardSteps.OpticalTetherAttributes]: t(LocalizationKeys.OpticalTether)
        };

        const updatedAttributeGroups: AttributeGroup[] = [];

        for (const step in PresetWizardStepMaterials) {
            const stepLabel = PresetWizardSteps[step];
            const stepTitle = attributeGroupLabelOverrides[step] || stepLabel;

            const cableType = session.materials.find((m) => m.id === PresetWizardMaterials.DNET_ASSEMBLY)
                ?.cstics.find((c) => c.id === PresetWizardCstics.CONFIGURATION_TYPE_CODE)?.valueId;

            const skipAttribute = (cableType === 'O' && stepTitle === t(LocalizationKeys.PowerTether))
                || (cableType === 'P' && stepTitle === t(LocalizationKeys.OpticalTether))

            if (skipAttribute) continue;

            if (PresetWizardStepMaterials[step]) {
                PresetWizardStepMaterials[step].forEach(material => {
                    let attributeGroup = updatedAttributeGroups.find(a => a.stepLabel === stepLabel);
                    if (!attributeGroup) {
                        attributeGroup = { stepLabel, title: stepTitle, attributes: [] };
                        updatedAttributeGroups.push(attributeGroup);
                    }

                    attributeGroup.attributes.push(...session.materials.filter(m => m.id === material)?.flatMap(m => m.cstics).map((c) => {
                        return {
                            csticId: c.id,
                            csticName: c.description,
                            valueId: c.valueId,
                            valueName: c.values?.find((v) => v.id === c.valueId)?.shortDescription ?? ""
                        };
                    }));
                });
            }
        }

        const coSlack = session.coSlack;
        const coSlackValue = +(coSlack.valueId);
        const coSlackAttribute = { csticId: coSlack.id, csticName: t(LocalizationKeys.COSlack), valueId: coSlack.valueId, valueName: `${coSlackValue.toFixed(2)} ${UnitInterpreter.toShort(session.slackUnit)}` };
        const fieldSlack = session.fieldSlack;
        const fieldSlackValue = +(fieldSlack.valueId);
        const fieldSlackAttribute = { csticId: fieldSlack.id, csticName: t(LocalizationKeys.FieldSlack), valueId: fieldSlack.valueId, valueName: `${fieldSlackValue.toFixed(2)} ${UnitInterpreter.toShort(session.slackUnit)}` };
        updatedAttributeGroups.push({ stepLabel: PresetWizardSteps[PresetWizardSteps.SlackSelection], title: t(LocalizationKeys.Slack), attributes: [coSlackAttribute, fieldSlackAttribute] });

        setAttributeGroups(updatedAttributeGroups);
    }, [t, presetName, session]);

    useEffect(() => {
        setIsDefault(!!isDefaultPreset);
    }, [isDefaultPreset]);

    const toggleDefault = useCallback(() => {
        setIsDefault(!isDefault);
    }, [isDefault]);

    const handleSavePreset = useCallback(() => {
        savePreset(isDefault);
    }, [isDefault, savePreset]);

    return {
        loading,
        attributeGroups,
        isDefault,
        disableDefault,
        disabled,
        toggleDefault,
        handleCancelClick: onCancelClick,
        handleSavePreset
    };
}