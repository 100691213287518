import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Workspace } from '../models/workspace';
import { StateModel } from '../redux/reducers';

export const useCountryStates = (workspace: Workspace | undefined, country: string | undefined) => {
    const { countryStates } = useSelector((state: StateModel) => state.cpq);

    const initializeCountries = useCallback((): string[] => {
        return countryStates.map((c) => c.country);
    }, [countryStates]);

    const [countries, setCountries] = useState<string[]>(initializeCountries());
    const [states, setStates] = useState<string[]>([]);

    useEffect(() => {
        setCountries(initializeCountries());
    }, [countryStates, workspace, initializeCountries]);

    useEffect(() => {
        const countryState = countryStates.find((c) => c.country === country);
        if (countryState?.states) {
            const sortedStates = countryState.states.slice().sort();
            setStates(sortedStates);
        }
    }, [countryStates, country, workspace]);

    const resetCountryStates = useCallback(() => {
        const countries = initializeCountries();
        setCountries(countries);
        const states = countryStates.find((cs) => cs.country === countries[0])?.states ?? [];
        setStates(states);
    }, [countryStates, initializeCountries]);

    return { countries, states, resetCountryStates };
};
