import { ElementType } from './element-type';
import { PointLike } from './pointlike';

export interface Element extends PointLike {
    id: number;
    tag: string;
    tagOverride?: string;
    createdDate: Date;
    userId: number;
    groupId: number;
    isImported: boolean;
    type: ElementType;
    importedGisDataId?: number;
}

export interface ElementData extends PointLike {
    tag: string;
    properties: string;
}

export function isOfType<T extends Element>(element: Element | undefined, type: ElementType): element is T {
    return element !== undefined && element.type === type;
}
