import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { Units } from '../../../models/units';
import { CardCheckbox, CardPropertyInline, CardSlider } from '../../card/card-controls';
import { InlineText } from '../../ui-elements/inline-text';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';
import { InlineSelect } from '../../ui-elements/inline-select';
import { useTerminalCard } from './terminal-card.hooks';
import { TerminalCardProps } from './terminal-card.types';

export const TerminalCard = (props: TerminalCardProps): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        disabled, selectedTerminal, allowedPortCounts, portCount, calculatedSpan, measuredSpan, lengthIsValid, matchTetherFiberCount, isLongtail, availableCpqLengths, displayUnits, handleInputChange, handleSliderChange, commitSliderChanges, handleCheckboxChange, valueLabelFormat,
        handleAddTerminal, handleDeleteTerminal, validateLength
    } = useTerminalCard(props);

    const renderLength = useCallback((length: number, units: Units): JSX.Element => {
        if (isLongtail) {
            return <Fragment>
                <InlineSelect
                    key="length"
                    label={t(LocalizationKeys.Length)}
                    value={length}
                    values={availableCpqLengths}
                    units={UnitInterpreter.toShort(units)}
                    onUpdate={handleInputChange}
                    disabled={disabled}
                />
                {calculatedSpan ?
                    CardPropertyInline(t(LocalizationKeys.CalculatedSpan), calculatedSpan.toFixed(2), UnitInterpreter.toShort(displayUnits))
                    :
                    CardPropertyInline(t(LocalizationKeys.CalculatedSpan), "", t(LocalizationKeys.NotApplicable))
                }
                {measuredSpan ?
                    CardPropertyInline(t(LocalizationKeys.MeasuredSpan), measuredSpan?.toFixed(2), UnitInterpreter.toShort(displayUnits))
                    :
                    CardPropertyInline(t(LocalizationKeys.MeasuredSpan), "", t(LocalizationKeys.NotApplicable))
                }
            </Fragment>;
        } else {
            return (
                <InlineText
                    id="length"
                    label={t(LocalizationKeys.Length)}
                    value={length}
                    disabled={disabled}
                    units={UnitInterpreter.toShort(units)}
                    align={'right'}
                    onUpdate={handleInputChange}
                    onInputChange={validateLength}
                    onCancel={() => validateLength(length)}
                    error={!lengthIsValid}
                    errorMessage={t(LocalizationKeys.OnlyPositiveValues)}
                />
            );
        }
    }, [isLongtail, t, availableCpqLengths, handleInputChange, disabled, calculatedSpan, displayUnits, measuredSpan, validateLength, lengthIsValid]);

    const renderTerminalPort = useCallback((portCount: number): JSX.Element => {
        return (
            <InlineText
                id="length"
                label={t(LocalizationKeys.Ports)}
                value={portCount}
                align={'right'}
                disabled={true}
            />
        );
    }, [t]);

    if (selectedTerminal) {
        return (
            <div>
                {renderLength(selectedTerminal.length, selectedTerminal.lengthUnit)}
                {
                    <>
                        {
                            !matchTetherFiberCount && allowedPortCounts.length > 1 && (portCount <= allowedPortCounts[allowedPortCounts.length - 1].value || portCount === 12) ?
                                CardSlider(t(LocalizationKeys.Ports), 'portCount', allowedPortCounts[0].value, allowedPortCounts[allowedPortCounts.length - 1].value, allowedPortCounts, portCount, 2, handleSliderChange, commitSliderChanges, valueLabelFormat, matchTetherFiberCount || disabled) :
                                renderTerminalPort(portCount)
                        }
                        {CardCheckbox(t(LocalizationKeys.TerminalMatchPortNumberWithTetherFiberCount), matchTetherFiberCount, handleCheckboxChange, disabled)}
                        <div className="card-footer">
                            <TextButton palette={MainPalettes.error} disabled={disabled} onClick={handleDeleteTerminal}>
                                <i className="material-icons">delete</i>
                            </TextButton>
                        </div>
                    </>
                }
            </div>
        );
    }

    return (
        <div className="card-footer">
            <TextButton disabled={disabled} onClick={handleAddTerminal}>
                {t(LocalizationKeys.AddTerminal)}
            </TextButton>
        </div>
    );
}
