export enum CommandType {
    CreateFlexNapCable,
    DeleteFlexNapCable,
    DeleteFlexNapBuild,
    ExportFlexNapBuild,

    ModifyCable,
    DeleteSegmentNode,

    // AddNode,
    // DeleteNode,

    ConvertBulkToFlexnapCable,

    CreateSchrodingerBuild,
    ModifySchrodingerCable, // modify node/cable
    DeleteSchrodingerCable,
    DeleteSchrodingerBuilds,

    AddCanvasSpan,
    DeleteCanvasSpan,
    
    CreatePreterm,

    CreateFlexNapNap,
    MoveFlexNapNap,
    DeleteFlexNapNap,

    CreateSchrodingerTap,
    LinkSchrodingerTaps,
    DeleteSchrodingerTap,
    MoveSchrodingerTap,
    CreateSchrodingerTether,
    DeleteSchrodingerTether,
    
    LockBuild,
    UnlockBuild,

    CreateDesignArea,
    ModifyDesignArea,
    DeleteDesignArea,

    CreateElement,
    DeleteElement,

    CreatePath,
    DeletePath,
}