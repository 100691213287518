import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../locales/types';
import { ControlType } from '../../redux/overlay.state';
import { BadgeType, ToolItem } from '../ui-elements/tool-item';
import { useCanvasMenuBar } from './canvas-menu-bar.hooks';
import { CanvasMoreOptionsMenu } from './canvas-more-options';
import buildInReviewIcon from './icons/icon_build_in_review.svg';
import infoIcon from './icons/icon_info.svg';
import moreIcon from './icons/icon_more.svg';
import newBuildIcon from './icons/icon_new_build.svg';
import presetsIcon from './icons/icon_presets.svg';
import { NotificationPopup } from './notification-popup';

export const CanvasMenuBar = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        control,
        notifications,
        reviewNotificationsCount,
        newCanvasBuild,
        showPresets,
        showMoreActions,
        showWarnings,
        showBuildsInReview,
    } = useCanvasMenuBar();

    const errorsAndWarningsText = t(LocalizationKeys.ErrorsAndWarnings);
    const presetText = t(LocalizationKeys.Preset);
    const newBuildText = t(LocalizationKeys.NewBuild);
    const moreOptionsText = t(LocalizationKeys.MoreOptions);
    const buildReviewText = t(LocalizationKeys.BuildReview);

    return (
        <div className="menu-bar-container">
            <div className="menu-bar">
                <ToolItem source={<img src={infoIcon} alt={errorsAndWarningsText} />} active={control === ControlType.Warnings} badge={{ number: notifications?.length, type: BadgeType.Warning }} onClick={showWarnings} tooltip={errorsAndWarningsText.toLowerCase()} />
                <ToolItem source={<img src={buildInReviewIcon} alt={buildReviewText} />} active={control === ControlType.Review} badge={{ number: reviewNotificationsCount, type: BadgeType.Warning }} onClick={showBuildsInReview} tooltip={buildReviewText.toLowerCase()} />
                <ToolItem source={<img src={presetsIcon} alt={presetText} />} onClick={showPresets} tooltip={presetText.toLowerCase()} />
                <ToolItem source={<img src={newBuildIcon} alt={newBuildText} />} active={false} onClick={newCanvasBuild} tooltip={newBuildText.toLowerCase()} />
                <ToolItem source={<img src={moreIcon} alt={moreOptionsText} />} active={control === ControlType.More} onClick={showMoreActions} tooltip={moreOptionsText.toLowerCase()} />
            </div>
            <CanvasMoreOptionsMenu open={control === ControlType.More} />
            <NotificationPopup />
        </div>
    );
};
