import React from "react";
import { SplicePointDiagramSvg } from "./diagram/splice-point-diagram-svg";
import { useSplicePointDiagramHooks } from './splice-point-diagram.hooks';
import { SplicePointDiagramProps } from './splice-point-diagram.types';

export const SplicePointDiagram = (props: SplicePointDiagramProps): JSX.Element => {
    const { locationId, connectedBuilds } = useSplicePointDiagramHooks(props);

    return (
        <div className="splice-point-diagram">
            <SplicePointDiagramSvg locationId={locationId} splicedBuilds={connectedBuilds} />
        </div>
    );
};