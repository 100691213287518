import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../../locales/types';
import { BuildSegment } from '../../../../models/build-segment';
import { Tap } from '../../../../models/schrodinger/tap';
import { deleteSegment, undoDeleteSegment } from '../../../../redux/build.state';
import { deleteTap, undoDeleteTap } from '../../../../redux/schrodinger/tap.state';
import { Command } from '../../../command';

interface DeleteSpanCommandPayload {
    segment: BuildSegment;
    tap?: Tap;
    awaitSessionUpdate?: boolean;
}

export class DeleteSpanCommand extends Command {
    private segment: BuildSegment;
    private tap?: Tap;
    private awaitSessionUpdate?: boolean;

    constructor({ segment, tap, awaitSessionUpdate }: DeleteSpanCommandPayload) {
        super();
        this.segment = segment;
        this.tap = tap;
        this.awaitSessionUpdate = awaitSessionUpdate
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoSpanDeletion };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoSpanDeletion };
    }

    public async undo(dispatch: Dispatch): Promise<void> {
        await undoDeleteSegment(this.segment, false, this.awaitSessionUpdate)(dispatch);
        if (this.tap) {
            undoDeleteTap(this.tap, this.awaitSessionUpdate)(dispatch);
        }
        super.undo(dispatch);
    }

    public async redo(dispatch: Dispatch): Promise<void> {
        if (this.tap) {
            await deleteTap(this.tap, true, this.awaitSessionUpdate)(dispatch);
        }
        deleteSegment(this.segment, false, undefined, undefined, this.awaitSessionUpdate)(dispatch);
        super.redo(dispatch);
    }
}