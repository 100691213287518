import { createSelector } from '@reduxjs/toolkit';

import { ReviewStatus } from '../models/review-status';
import { authenticationSelector, reviewsSelector } from './root.selectors';

export const lastReviewSelectorFactory = (buildId: number) => createSelector(
    reviewsSelector,
    (reviews) => {
        const sortedBuildReviews = reviews
            .filter((r) => r.buildId === buildId)
            .sort((a, b) => new Date(b.submissionDateTime).getTime() - new Date(a.submissionDateTime).getTime());
        if (sortedBuildReviews.length)
            return sortedBuildReviews[0];
        return undefined;
    }
);

// All requests
const requestedReviewsSelector = createSelector(
    reviewsSelector,
    (reviews) => reviews.filter((r) => r.status === ReviewStatus.Open)
);

// All resolved reviews (also used for Archived tab)
export const resolvedReviewsSelector = createSelector(
    reviewsSelector,
    (reviews) => reviews.filter((r) => r.status === ReviewStatus.Approved || r.status === ReviewStatus.Rejected)
);

// Pending
export const designerReviewRequestsSelector = createSelector(
    authenticationSelector,
    requestedReviewsSelector,
    (authentication, reviews) => reviews.filter((r) => r.submittedById === authentication.userId)
);

// Requests
export const reviewerReviewRequestsSelector = createSelector(
    authenticationSelector,
    requestedReviewsSelector,
    (authentication, reviews) => reviews.filter((r) => r.submittedById !== authentication.userId)
);

// Requests that were just completed by the reviewer and still show in the Requests tab
export const reviewerResolvedReviewsSelector = createSelector(
    authenticationSelector,
    resolvedReviewsSelector,
    (authentication, reviews) => reviews.filter((r) => !r.completerDismissed && r.submittedById !== authentication.userId)
);

// Resolved
export const designerResolvedReviewsSelector = createSelector(
    authenticationSelector,
    resolvedReviewsSelector,
    (authentication, reviews) => reviews.filter((r) => !r.submitterDismissed && r.submittedById === authentication.userId)
);

// Requests + Pending + Resolved
export const reviewNotificationsCountSelector = createSelector(
    requestedReviewsSelector,
    designerResolvedReviewsSelector,
    (requests, resolved) => requests.length + resolved.length
);