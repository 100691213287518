import './import-customer-format-content.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import { CircularProgressIndeterminate } from '@orbit/progress-indicator';

import { MainPalettes } from '@orbit/theme-provider';
import { LocalizationKeys } from '../../../../locales/types';
import {
    CustomerFormatStepsEnum, customerFormatStepsTitles, ImportDialogContentProps
} from '../import.card.types';
import { useImportCustomerFormatContent } from './import-customer-format-content.hooks';

export const ImportCustomerFormatContent = (props: ImportDialogContentProps): JSX.Element => {
    const { t } = useTranslation();
    const { errorMessages, isLoading, importedInfraDataTotal, importedInfraDataHasDuplicates, logOutput } = useImportCustomerFormatContent(props);

    return (
        <div className='dialog-content import-customer-format-dialog-content'>
            <div className='header'>
                {
                    customerFormatStepsTitles.map((title, index) => (
                        <div className={props.stepCount < index ? 'step disabled' : 'step'} key={index}><span className={props.stepCount < index ? 'step-index disabled' : 'step-index'}>{index + 1}</span>{title}</div>
                    ))
                }
            </div>
            <div className='content'>
                {props.stepCount === CustomerFormatStepsEnum.Transformation && <Alert severity='info' variant='filled' className='secondary'>{t(LocalizationKeys.TransformingData)} </Alert>}
                {props.stepCount === CustomerFormatStepsEnum.Confirmation && !!importedInfraDataTotal && importedInfraDataTotal > 0 && <Alert severity='info' variant='filled' className='secondary'>{t(LocalizationKeys.AboutToImportXElements, { importedDataTotal: importedInfraDataTotal.toString() })} </Alert>}
                {props.stepCount === CustomerFormatStepsEnum.Confirmation && importedInfraDataHasDuplicates && <Alert severity='warning' variant='filled' className='secondary'>{t(LocalizationKeys.FoundDuplicateTagNotImported)}</Alert>}
                {props.stepCount >= CustomerFormatStepsEnum.Log &&
                    <div className='log'>
                        {isLoading && <CircularProgressIndeterminate palette={MainPalettes.primary} />}
                        {logOutput.length > 0 && <ul>{logOutput.map((log, index) => <li key={index}>{log}</li>)}</ul>}
                        {importedInfraDataHasDuplicates && <div>{t(LocalizationKeys.FoundDuplicateTagNotImported)}</div>}
                        {logOutput.length === 0 && <div>{t(LocalizationKeys.NoDataWasImported)}</div>}
                        {!!errorMessages && errorMessages.length > 0 && <div>Errors:<br />{<ul>{errorMessages.map((error, errorIndex) => <li key={errorIndex}>{error}</li>)}</ul>}<br /></div>}
                    </div>
                }
            </div>
        </div>
    );
}