import * as rfc from 'rfc6902';

import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { SecuredService } from './abstract-secured-v2.service';

export class SegmentService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/segment';

    public getBuildSegments(buildId: number): Promise<BuildSegment[] | undefined> {
        return this.get(`${this.baseUrl}/${buildId}`);
    }

    public createSegment(buildId: number, createRequest: BuildSegmentRequest): Promise<BuildSegment | undefined> {
        return this.post(`${this.baseUrl}/${buildId}`, createRequest);
    }

    public createCanvasSegment(buildId: number, createRequest: CanvasBuildSegmentRequest): Promise<CanvasBuildSegmentResult | undefined> {
        return this.post(`${this.baseUrl}/${buildId}/canvas`, createRequest);
    }

    public createSegments(buildId: number, createRequests: BuildSegmentRequest[]): Promise<BuildSegment[] | undefined> {
        return this.post(`${this.baseUrl}/createSegments/${buildId}`, createRequests);
    }

    public patchSegment(oldSegment: BuildSegment, newSegment: BuildSegment): Promise<{ segment: BuildSegment; build: Build; warningMessage: string } | undefined> {
        const patch = rfc.createPatch(oldSegment, newSegment);
        return this.patch(`${this.baseUrl}/patch/${oldSegment.id}`, patch);
    }

    public deleteSegment(segmentId: number, request: DeleteSegmentRequest): Promise<Build | undefined> {
        return this.post(`${this.baseUrl}/delete/${segmentId}`, request);
    }

    public undoDeleteSegment(segmentId: number, request: RestoreSegmentRequest): Promise<BuildSegment[] | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${segmentId}`, request);
    }

    public anySegmentUsingElement(elementId: number): Promise<boolean | undefined> {
        return this.get(`${this.baseUrl}/anysegmentusing/${elementId}`);
    }

    public updateConnectedToElement(elementId: number): Promise<BuildSegment[] | undefined> {
        return this.post(`${this.baseUrl}/connectedTo/${elementId}/resetSegments`);
    }
}

export interface BuildSegmentRequest {
    position: number;
    fromId: number;
    toId: number;
}

export interface CanvasBuildSegmentRequest {
    position: number;
    span: number;
}

export interface CanvasBuildSegmentResult {
    newSegmentId: number;
    segments: BuildSegment[];
    build: SchrodingerBuild;
}

export interface PatchSegmentRequest {
    oldSegment: BuildSegment;
    newSegment: BuildSegment;
    ignoreSelected?: boolean;
}

export interface DeleteSegmentRequest {
    deleteFirstLocation: boolean;
}

export interface RestoreSegmentRequest {
    restoreFirstLocation: boolean;
}