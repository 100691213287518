import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { DrawerType, showDrawer } from '../../../../redux/overlay.state';
import { clearSelection, selectDesignArea } from '../../../../redux/selection.state';
import {
    bulkBuildsSelector, designModeSelector, flexnapBuildsSelector, schrodingerBuildsSelector,
    workspaceSelector
} from '../../../../selectors/root.selectors';
import { DrawerMenuHeaderProps } from '../drawer-menu.types';

export const useDrawerMenuHeader = (props: DrawerMenuHeaderProps) => {
    const { onClose } = props;
    const { currentWorkspace } = useSelector(workspaceSelector);
    const designMode = useSelector(designModeSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const schrodingerBuilds = useSelector(schrodingerBuildsSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const workspaceName = useMemo(() => currentWorkspace?.name ?? currentWorkspace?.workspaceId ?? "", [currentWorkspace]);
    const currentModeBuilds = [...flexNapBuilds, ...schrodingerBuilds, ...bulkBuilds].filter((b) => b.designMode === designMode);
    const titleLabel = t(LocalizationKeys.NumberOfBuilds, { count: currentModeBuilds.length });

    const handleShowAllClick = useCallback(() => {
        onClose();
        dispatch(showDrawer(DrawerType.WorkspaceDrawer));
    }, [onClose, dispatch]);

    const handleCloseClick = useCallback(() => {
        onClose();
        dispatch(showDrawer());
    }, [onClose, dispatch]);

    const handleAddDesignAreaClick = useCallback(() => {
        dispatch(clearSelection());
        dispatch(selectDesignArea(-1));
    }, [dispatch]);

    return {
        titleLabel,
        workspaceName,
        designMode,
        handleShowAllClick,
        handleCloseClick,
        handleAddDesignAreaClick,
    };
};
