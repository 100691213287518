import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { CardCollapsibleComponent } from '../../card/card-collapsible.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardContextMenu } from '../../ui-elements/card-context-menu';
import { CableLengthProperties } from '../cable-length/cable-length-properties';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';
import { InlineSelect } from '../../ui-elements/inline-select';
import { useBulkCableCard } from './bulk-cable-card.hooks';
import { BulkCableProps } from './bulk.types';

export const BulkCableCard = (props: BulkCableProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        build,
        cableType, fiberCount,
        totalLength, displayUnits,
        cableTypes, availableFiberCounts,
        cardLabel, collapsed, disabled,
        setCableType, updateFiberCount,
        isFieldSlackValid, validateFieldSlack, handleFieldSlackUpdate,
        handleDeleteClick, handleButtonConnect, labelButtonConnect, disabledButtonConnect, handleButtonConvert,
        showConvertButton, contextMenuItems, contextMenuPosition, onCancelContextMenu
    } = useBulkCableCard(props);

    return (
        <>
            <CardCollapsibleComponent id="cable-info" label={cardLabel} collapsed={collapsed}>
                <InlineSelect
                    key="bulkCableType"
                    label={t(LocalizationKeys.Type)}
                    value={cableType}
                    values={cableTypes.map(t => t.value)}
                    displayValues={cableTypes.map(t => t.display)}
                    onUpdate={setCableType}
                    disabled={disabled}
                />
                <InlineSelect
                    key="bulkFiberCount"
                    label={t(LocalizationKeys.FiberCount)}
                    value={fiberCount}
                    values={availableFiberCounts[cableType]}
                    onUpdate={updateFiberCount}
                    disabled={disabled}
                />
                {CardPropertyInline(t(LocalizationKeys.Length), totalLength.value.toFixed(2), UnitInterpreter.toShort(displayUnits))}
                <CableLengthProperties build={build} isPreterm={false} fieldSlackUpdateProps={{ isFieldSlackValid, validateFieldSlack, handleFieldSlackUpdate }} disabled={disabled} />
                <div className="card-footer">
                    <TextButton palette={MainPalettes.error} className="negative card-icon-button" disabled={disabled} onClick={handleDeleteClick}><i className="material-icons">delete</i></TextButton>
                    <div>
                        {showConvertButton && <TextButton onClick={handleButtonConvert}>Convert</TextButton>}
                        <TextButton className="connect-button" disabled={disabledButtonConnect} onClick={handleButtonConnect}>
                            {labelButtonConnect}
                        </TextButton>
                    </div>
                </div>
            </CardCollapsibleComponent>
            {
                contextMenuItems.length ?
                    <CardContextMenu
                        title={t(LocalizationKeys.ConnectTo)}
                        position={{ bottom: contextMenuPosition.bottom, right: contextMenuPosition.right }}
                        items={contextMenuItems}
                        onCancel={onCancelContextMenu}
                    />
                    : null
            }
        </>
    )
};
