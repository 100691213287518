import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { CardPropertyInline } from '../../card/card-controls';
import { InlineSelect } from '../../ui-elements/inline-select';
import { useFlexNapPretermProperties } from './flexnap-preterm-properties.hooks';
import { FlexNapPretermProps } from './flexnap.types';

export const FlexNapPretermProperties = (props: FlexNapPretermProps): JSX.Element => {
    const { t } = useTranslation();
    const { connectorType, furcationType, legType, legTypes, pretermPartNumber, handleLegTypeChange, disabled } = useFlexNapPretermProperties(props);

    return (
        <React.Fragment>
            {CardPropertyInline(t(LocalizationKeys.PretermPartNumber), pretermPartNumber ?? "")}
            {CardPropertyInline(t(LocalizationKeys.ConnectorType), connectorType)}
            {CardPropertyInline(t(LocalizationKeys.FurcationType), furcationType)}
            {<InlineSelect
                key="legType"
                label={t(LocalizationKeys.LegType)}
                disabled={disabled}
                value={legType}
                values={legTypes}
                displayValues={[...legTypes]}
                onUpdate={handleLegTypeChange}
            />}
        </React.Fragment>
    );
};
