/* eslint-disable no-undef */
import * as ol from 'ol';
import ImageWrapper from 'ol/Image';
import BaseLayer from 'ol/layer/Base';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import Cluster from 'ol/source/Cluster';
import ImageWMS from 'ol/source/ImageWMS';
import Vector from 'ol/source/Vector';
import WMSServerType from 'ol/source/WMSServerType';

import { GeoserverService } from '../services/geoserver.service';
import { InteractableFeatureType } from './interactable-feature-type';
import { OpenlayerStyleFactory } from './openlayer-style.factory';

let index = 1;
const INDEX_DESIGN_AREA = index;
const INDEX_PARCEL = ++index;
const INDEX_TRENCH = ++index;
const INDEX_BORE = index;
const INDEX_BUILD_STATE = ++index;
const INDEX_SHADOW = ++index;
const INDEX_FLEXNAP_BUILD = ++index;
const INDEX_SCHRODINGER_BUILD = index;
const INDEX_BULK_BUILD = index;
const INDEX_TERMINAL_SEGMENTS = ++index;
const INDEX_GEO = ++index;
const INDEX_POLE = ++index;
const INDEX_MANHOLE = index;
const INDEX_HANDHOLE = index;
const INDEX_VAULT = index;
const INDEX_CABINET = index;
const INDEX_TAP = ++index;
const INDEX_PORT_NUMBERS = ++index;
const INDEX_TERMINAL = ++index;
const INDEX_SCHRODINGER_TERMINAL = index;
const INDEX_NAP = index;
const INDEX_SCHRODINGER_TAP = index;
const INDEX_SPLICE_POINT = index;
const INDEX_DEVICE = index;
const INDEX_POWER = index;
const INDEX_CALLOUTS = index;
const INDEX_IMPORTED = ++index;

export class LayerFactory {
    public static LAYER_WORKSPACES = 'workspaces';
    public static LAYER_DESIGN_AREAS = 'design_areas';
    public static LAYER_POLES = 'poles';
    public static LAYER_GEO_POLES = 'geo-poles';
    public static LAYER_NAPS = 'naps';
    public static LAYER_FLEXNAP_BUILDS = 'flexnap_segments';
    public static LAYER_SCHRODINGER_BUILDS = 'schrodinger_segments';
    public static LAYER_BULK_BUILDS = 'bulk_segments';
    public static LAYER_TAPS = 'taps';
    public static LAYER_PORT_NUMBERS = 'port_numbers';
    public static LAYER_TERMINALS = 'terminals';
    public static LAYER_TERMINAL_SEGMENTS = 'terminal_segments';
    public static LAYER_SCHRODINGER_TERMINALS = 'schrodinger_terminals';
    public static LAYER_DROPCABLES = 'dropcables';
    public static LAYER_PARCELS = 'parcels';
    public static LAYER_CABINETS = 'cabinets';
    public static LAYER_MANHOLES = 'manholes';
    public static LAYER_HANDHOLES = 'handholes';
    public static LAYER_VAULTS = 'vaults';
    public static LAYER_TRENCHES = 'trenches';
    public static LAYER_BORES = 'bores';
    public static LAYER_SCHRODINGER_TAPS = 'schrodinger_taps';
    public static LAYER_SPLICE_POINTS = 'splice_points';
    public static LAYER_DEVICES = 'devices';
    public static LAYER_POWER = 'power';
    public static LAYER_BUILD_STATE = 'build_states';
    public static LAYER_OUTDOOR_PLAN = 'outdoor-plan';
    public static LAYER_SHADOW = 'shadow';
    public static LAYER_INFRASTRUCTURE_IDS = 'infrastructure-ids';
    public static LAYER_BUILD_CALLOUTS = 'build-callouts';
    public static LAYER_CABINET_CALLOUTS = 'cabinet-callouts';
    public static LAYER_BUILD_IDS = 'build-ids';

    public static createGeoPolesLayer(): ImageLayer {
        return this.createGeoServerLayer('ctcm:Poles', WMSServerType.GEOSERVER, this.LAYER_POLES, INDEX_GEO);
    }

    public static createGeoParcelsLayer(): ImageLayer {
        return this.createGeoServerLayer('ctcm:Parcels', WMSServerType.GEOSERVER, this.LAYER_PARCELS, INDEX_GEO);
    }

    public static createGeoCabinetsLayer(): ImageLayer {
        return this.createGeoServerLayer('ctcm:Cabinets', WMSServerType.GEOSERVER, this.LAYER_CABINETS, INDEX_GEO);
    }

    public static createGeoManholesLayer(): ImageLayer {
        return this.createGeoServerLayer('ctcm:Manholes', WMSServerType.GEOSERVER, this.LAYER_MANHOLES, INDEX_GEO);
    }

    public static createGeoHandholesLayer(): ImageLayer {
        return this.createGeoServerLayer('ctcm:Handholes', WMSServerType.GEOSERVER, this.LAYER_HANDHOLES, INDEX_GEO);
    }

    public static createGeoVaultsLayer(): ImageLayer {
        return this.createGeoServerLayer('ctcm:Vaults', WMSServerType.GEOSERVER, this.LAYER_VAULTS, INDEX_GEO);
    }

    public static createDesignAreasLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_DESIGN_AREAS, INDEX_DESIGN_AREA);
    }

    public static createPolesLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_POLES, INDEX_POLE);
    }

    public static createFlexNapBuildsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_FLEXNAP_BUILDS, INDEX_FLEXNAP_BUILD);
    }

    public static createSchrodingerBuildsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_SCHRODINGER_BUILDS, INDEX_SCHRODINGER_BUILD);
    }

    public static createBulkBuildsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_BULK_BUILDS, INDEX_BULK_BUILD);
    }

    public static createNapLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_NAPS, INDEX_NAP);
    }

    public static createTapLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_TAPS, INDEX_TAP);
    }

    public static createPortNumberLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_PORT_NUMBERS, INDEX_PORT_NUMBERS);
    }

    public static createTerminalLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_TERMINALS, INDEX_TERMINAL);
    }

    public static createTerminalSegmentsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_TERMINAL_SEGMENTS, INDEX_TERMINAL_SEGMENTS);
    }

    public static createSchrodingerTerminalLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_SCHRODINGER_TERMINALS, INDEX_SCHRODINGER_TERMINAL);
    }

    public static createCabinetLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_CABINETS, INDEX_CABINET);
    }

    public static createManholeLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_MANHOLES, INDEX_MANHOLE);
    }

    public static createHandholeLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_HANDHOLES, INDEX_HANDHOLE);
    }

    public static createVaultLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_VAULTS, INDEX_VAULT);
    }

    public static getCreateElementLayerMethod(featureType: InteractableFeatureType): (() => VectorLayer) | undefined {
        switch (featureType) {
            case InteractableFeatureType.CABINET: return this.createCabinetLayer;
            case InteractableFeatureType.POLE: return this.createPolesLayer;
            case InteractableFeatureType.MANHOLE: return this.createManholeLayer;
            case InteractableFeatureType.HANDHOLE: return this.createHandholeLayer;
            case InteractableFeatureType.VAULT: return this.createVaultLayer;
            default: return undefined;
        }
    }

    public static createTrenchLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_TRENCHES, INDEX_TRENCH);
    }

    public static createBoreLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_BORES, INDEX_BORE);
    }

    public static createSchrodingerTapLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_SCHRODINGER_TAPS, INDEX_SCHRODINGER_TAP);
    }

    public static createSplicePointLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_SPLICE_POINTS, INDEX_SPLICE_POINT);
    }

    public static createDeviceLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_DEVICES, INDEX_DEVICE);
    }

    public static createPowerLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_POWER, INDEX_POWER);
    }

    public static createParcelLayer(): VectorLayer {
        const id = this.LAYER_PARCELS;
        const className = 'layer-' + id;
        const source = new Vector({ features: [] });
        const clusterSource = new Cluster({ source, distance: 20 });
        const vectorLayer = new VectorLayer({
            className,
            zIndex: INDEX_PARCEL,
            source: clusterSource,
            style: OpenlayerStyleFactory.createFeatureStyleFunction,
        });
        vectorLayer.set('id', id);
        return vectorLayer;
    }

    public static createDropCableLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_DROPCABLES, 0);
    }

    public static createBuildStateLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_BUILD_STATE, INDEX_BUILD_STATE);
    }

    public static createGrayoutLayer(): BaseLayer {
        return this.createFeatureLayer([], 'grayout', INDEX_IMPORTED - 1);
    }
    public static createImportPreviewLayer(): VectorLayer {
        return this.createFeatureLayer([], 'importpreview', INDEX_IMPORTED);
    }

    public static createShadowLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_SHADOW, INDEX_SHADOW);
    }

    public static createInfrastructureIdsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_INFRASTRUCTURE_IDS, INDEX_CALLOUTS);
    }

    public static createBuildCalloutsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_BUILD_CALLOUTS, INDEX_CALLOUTS);
    }

    public static createCabinetCalloutsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_CABINET_CALLOUTS, INDEX_CALLOUTS);
    }

    public static createBuildIdsLayer(): VectorLayer {
        return this.createFeatureLayer([], this.LAYER_BUILD_IDS, INDEX_CALLOUTS);
    }

    private static createFeatureLayer(features: ol.Feature[], id: string, zIndex: number): VectorLayer {
        const className = 'layer-' + id;
        const source = new Vector({ features });
        const vectorLayer = new VectorLayer({
            className,
            zIndex,
            source
        });
        vectorLayer.set('id', id);
        return vectorLayer;
    }

    public static replaceFeatures(layer: VectorLayer, newFeatures: ol.Feature[], clear = true): void {
        let source = layer.getSource();
        if (source instanceof Cluster) {
            source = source.getSource();
        }
        if (clear) {
            source.clear(true);
        }
        source.addFeatures(newFeatures);
    }

    private static customImageLoadFunction(wrapper: ImageWrapper): void {
        const geoserverService = new GeoserverService();
        geoserverService.getImageWms()
            .then((data) => {
                if (data) {
                    (wrapper as any).getImage().src = URL.createObjectURL(data);
                }
            });
    }

    private static createGeoServerLayer(layers: string, serverType: WMSServerType, id: string, zIndex: number, params?: any): ImageLayer {
        const url = new GeoserverService().geoserverUrl;
        const source = new ImageWMS({
            url,
            params: {
                LAYERS: layers,
                TILED: true,
                VERSION: '1.1.1',
                ...params,
            },
            serverType,
        });
        source.setImageLoadFunction(LayerFactory.customImageLoadFunction);
        const tileLayer = new ImageLayer({ source, zIndex });
        tileLayer.set('id', id);

        return tileLayer;
    }
}
