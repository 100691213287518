import { createContext } from 'react';

import { ConfigSession } from '../../../../models/config-session';
import { Cstic } from '../../../../models/cstic';

export enum PresetWizardSteps {
    AttributeSelection,
    PowerTetherAttributes,
    OpticalTetherAttributes,
    SlackSelection,
    PropertiesValidation,
}

export enum PresetWizardMaterials {
    DNET_ASSEMBLY = 'DNET_ASSEMBLY',
    POWER_DEMAND_REMOTE = 'POWER_DEMAND_REMOTE',
    POWER_CHANNEL = 'POWER_CHANNEL',
    POWER_TETHER = 'POWER_TETHER',
    OPTICAL_TETHER = 'OPTICAL_TETHER'
}

export enum PresetWizardCstics {
    CONFIGURATION_TYPE_CODE = 'CONFIGURATION_TYPE_CODE',
    POWER_MAXIMUM = 'POWER_MAXIMUM',
    CONDUCTOR_TYPE_CODE = 'CONDUCTOR_TYPE_CODE',
    NUMBER_OF_CONDUCTOR_PAIRS = 'NUMBER_OF_CONDUCTOR_PAIRS',
    LEG_LENGTH = 'LEG_LENGTH',
    POWER_UNIT_CODE = 'POWER_UNIT_CODE',
    TETHER_FIBER_COUNT = 'TETHER_FIBER_COUNT',
    CONNECTOR_TYPE_CODE = 'CONNECTOR_TYPE_CODE',
    START_PLAN_YN = 'START_PLAN_YN',
    PRODUCT_NUMBER = 'PRODUCT_NUMBER',
    SubunitFiberModeTypeCode = 'SUBUNIT_FIBER_MODE_TYPE_CODE',
    SubunitFillTypeCode = 'SUBUNIT_FILL_TYPE_CODE',
}

export const PresetWizardNonEditableCstics = [PresetWizardCstics.START_PLAN_YN, PresetWizardCstics.PRODUCT_NUMBER,
PresetWizardCstics.SubunitFiberModeTypeCode, PresetWizardCstics.SubunitFillTypeCode]; // These two Cstics are added to non-editable Cstics as a temporary fix: 
// In power-only mode, no value gets assigned to that Cstic. In theory, 
// it should be "N/A". In optical mode, values automatically get assigned.
export const PresetWizardTextCstics = []; // CSTICs have "select" type values by default. Add a CSTIC here to make it a text value

export const PresetWizardStepMaterials: Record<PresetWizardSteps, string[] | undefined> = {

    [PresetWizardSteps.AttributeSelection]: [PresetWizardMaterials.DNET_ASSEMBLY],
    [PresetWizardSteps.PowerTetherAttributes]: [PresetWizardMaterials.POWER_DEMAND_REMOTE, PresetWizardMaterials.POWER_CHANNEL, PresetWizardMaterials.POWER_TETHER],
    [PresetWizardSteps.OpticalTetherAttributes]: [PresetWizardMaterials.OPTICAL_TETHER],
    [PresetWizardSteps.SlackSelection]: undefined,
    [PresetWizardSteps.PropertiesValidation]: undefined
}

export interface PresetContextProps {
    session?: ConfigSession;
    presetName: string;
    updateSession: (instanceId: string, csticId: string, valueId: string) => Promise<void>;
    loading: boolean;
    undoCstics: (cstics: Cstic[]) => Promise<void>;
    savePreset: (isDefault: boolean) => void;
}

const defaultPresetContextProps: PresetContextProps = {
    presetName: "",
    updateSession: async () => undefined,
    loading: false,
    undoCstics: async () => undefined,
    savePreset: () => undefined,
};

export const PresetContext = createContext(defaultPresetContextProps);