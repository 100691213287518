import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { PartHelper } from '../../helpers/part-helper';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { NapData } from '../data/nap-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class TetherCompatbilityValidator implements Validator<FlexNapBuildData> {

    public validate(build: FlexNapBuildData): ValidationResult[] | null {
        if (!build.part) {
            console.warn('Build', build.id, 'has no part, cannot validate');
            return null;
        }
        const res: ValidationResult[] = [];

        for (const nap of build.naps) {
            const tethers = nap.taps.flatMap(t => t.tethers);
            for (const tether of tethers) {
                if (!tether.part) {
                    console.warn('Tether', tether.id, 'has no part, cannot validate');
                    continue;
                }
                if (!PartHelper.isCompatible(build.part, tether.part) || tether.part.installation_Type !== build.installationType) {
                    res.push(this.buildValidationResult(build, nap, tether.part.tether_Item))
                }
            }
        }
        return res;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapBuildData && entity.part !== null;
    }

    private buildValidationResult(build: FlexNapBuildData, nap: NapData, tetherPart: string): ValidationResult {
        const extent = nap.getExtent();
        return {
            buildId: build.id,
            itemId: nap.id,
            itemType: ItemType.Build,
            shortDescription: i18next.t(LocalizationKeys.ErrorTetherIncompatible),
            longDescription: i18next.t(LocalizationKeys.ErrorTetherIncompatibleLong, { tetherPart, buildId: build.id }),
            type: ValidationResultType.Error,
            extent
        };
    }
}
