import * as rfc from 'rfc6902';
import { Terminal } from '../models/terminal';
import { TerminalExtension } from '../models/terminal-extension';
import { TerminalSegment } from '../models/terminal-segment';
import { SecuredService } from './abstract-secured-v2.service';

export class TerminalService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/terminal';

    public addTerminal(tetherId: number): Promise<Terminal | undefined> {
        return this.post(`${this.baseUrl}/addto/${tetherId}`);
    }

    public patchTerminal(oldTerminal: Terminal, newTerminal: Terminal): Promise<Terminal | undefined> {
        const patch = rfc.createPatch(oldTerminal, newTerminal);
        return this.patch(`${this.baseUrl}/${oldTerminal.id}`, patch);
    }

    public deleteTerminal(terminal: Terminal): Promise<boolean | undefined> {
        return this.delete(`${this.baseUrl}/${terminal.id}`);
    }

    public extendTerminalToElement(terminalId: number, elementId: number): Promise<TerminalExtensionOperationDto | undefined> {
        return this.post(`${this.baseUrl}/${terminalId}/extendTo/${elementId}`);
    }

    public addTerminalSegments(terminalId: number, newSegmentRequests: TerminalSegmentRequest[]): Promise<TerminalExtensionOperationDto | undefined> {
        return this.post(`${this.baseUrl}/${terminalId}/addSegments`, newSegmentRequests);
    }

    public updateTerminalSegments(terminalId: number, updateSegmentRequests: TerminalSegmentRequest[]): Promise<TerminalExtensionOperationDto | undefined> {
        return this.post(`${this.baseUrl}/${terminalId}/updateSegments`, updateSegmentRequests);
    }

    public patchTerminalSegment(oldSegment: TerminalSegment, newSegment: TerminalSegment): Promise<TerminalExtensionOperationDto | undefined> {
        const patch = rfc.createPatch(oldSegment, newSegment);
        return this.patch(`${this.baseUrl}/${oldSegment.terminalId}/segment/${oldSegment.id}`, patch);
    }

    public deleteTerminalSegment(terminalId: number, terminalSegmentId: number): Promise<TerminalExtensionOperationDto | undefined> {
        return this.delete(`${this.baseUrl}/${terminalId}/segment/${terminalSegmentId}`);
    }

    public patchTerminalExtension(oldExtension: TerminalExtension, newExtension: TerminalExtension): Promise<TerminalExtensionOperationDto | undefined> {
        const patch = rfc.createPatch(oldExtension, newExtension);
        return this.post(`${this.baseUrl}/${oldExtension.terminalId}/extension/${oldExtension.id}`, patch);
    }

    public resetTerminalExtension(terminalId: number): Promise<TerminalExtensionOperationDto | undefined> {
        return this.post(`${this.baseUrl}/${terminalId}/resetextension`);
    }
}

export interface TerminalExtensionOperationDto {
    terminal?: Terminal;
    newSegments?: TerminalSegment[];
    updatedSegments?: TerminalSegment[];
    warningMessage?: string;
}

export interface TerminalSegmentRequest {
    position: number;
    fromId: number;
    toId: number;
    id?: number;
}