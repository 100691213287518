import React from 'react';

import { ToolType } from '../../design-tools/tooltype';
import { BoreIcon } from './icons/bore-icon';
import { CabinetIcon } from './icons/cabinet-icon';
import { DesignAreaIcon } from './icons/design-area-icon';
import { EditToolIcon } from './icons/edit-tool.icon';
import { FlexNapCableIcon } from './icons/flexnap-cable-icon';
import { HandholeIcon } from './icons/handhole-icon';
import { ManholeIcon } from './icons/manhole-icon';
import { NapIcon } from './icons/nap-icon';
import { PointerIcon } from './icons/pointer-icon';
import { PoleIcon } from './icons/pole-icon';
import { SchrodingerCableIcon } from './icons/schrodinger-cable-icon';
import { BulkCableIcon } from './icons/bulk-cable-icon';
import { SchrodingerTapIcon } from './icons/schrodinger-tap-icon';
import { SplicePointIcon } from './icons/splice-point-icon';
import { TrenchIcon } from './icons/trench-icon';
import { VaultIcon } from './icons/vault-icon';

interface ToolIconFactoryProps {
    type: ToolType;
}

export const ToolIconFactory = (props: ToolIconFactoryProps): JSX.Element | null => {
    switch (props.type) {
        case ToolType.Selection: return <PointerIcon />;
        case ToolType.FlexNAPCable: return <FlexNapCableIcon />;
        case ToolType.SchrodingerCable: return <SchrodingerCableIcon />;
        case ToolType.BulkCable: return <BulkCableIcon />;
        case ToolType.Cabinet: return <CabinetIcon />;
        case ToolType.NAP: return <NapIcon />;
        case ToolType.Manhole: return <ManholeIcon />;
        case ToolType.Handhole: return <HandholeIcon />;
        case ToolType.Vault: return <VaultIcon />;
        case ToolType.Pole: return <PoleIcon />;
        case ToolType.TapSchrodinger: return <SchrodingerTapIcon />;
        case ToolType.SplicePoint: return <SplicePointIcon />;
        case ToolType.Bore: return <BoreIcon />;
        case ToolType.Trench: return <TrenchIcon />;
        case ToolType.Modification: return <EditToolIcon />;
        case ToolType.DesignArea: return <DesignAreaIcon />;
        default: return null;
    }
};
