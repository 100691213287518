import LineString from 'ol/geom/LineString';
import { getLength } from 'ol/sphere';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignAreaHelper } from '../helpers/design-area-helper';
import { DesignArea } from '../models/design-area';
import { DesignMode } from '../models/design-mode';
import { Element } from '../models/element';
import { addNotifications, clearNotifications } from '../redux/notification.state';
import { displayedBuildIdSelector } from '../selectors/build.selectors';
import {
    cabinetsSelector, handholesSelector, manholesSelector, polesSelector, vaultsSelector
} from '../selectors/elements.selectors';
import {
    authenticationSelector, boresSelector, buildSelector, cabinetSplicePlansSelector,
    designAreaSelector, flexNapSplicePlansSelector, mapSelector,
    napsSelector, selectedBuildIdSelector, selectedDesignAreaIdSelector, splicePointsSelector,
    tapsSelector, tethersSelector, validationSelector, workspaceSelector
} from '../selectors/root.selectors';
import { BulkBuildData } from '../validation/data/bulk-build-data';
import { Data } from '../validation/data/data';
import { DesignAreaData } from '../validation/data/design-area-data';
import { FlexNapBuildData } from '../validation/data/flexnap-build-data';
import { NapData } from '../validation/data/nap-data';
import { SplicePointData } from '../validation/data/splice-point-data';
import { ValidationHelper } from '../validation/validation-helper';
import { ValidationResult } from '../validation/validation-result';
import { AssignedNapValidator } from '../validation/validators/assigned-nap-validator';
import { BufferTubeValidator } from '../validation/validators/buffer-tube-validator';
import {
    CabinetSplicePlanPortValidator
} from '../validation/validators/cabinet-splice-plan-port-validator';
import { DesignAreaBuildValidator } from '../validation/validators/design-area-build-validator';
import { DesignSpanRiserValidator } from '../validation/validators/design-span-riser-validator';
import { DuplicatePoleValidator } from '../validation/validators/duplicate-pole-validator';
import { FiberCountValidator } from '../validation/validators/fiber-count-validator';
import { FlexNapNapsCountValidator } from '../validation/validators/flexnap-naps-count-validator';
import { LooseTubeValidator } from '../validation/validators/loose-tube-validator';
import { MaxLengthValidator } from '../validation/validators/max-length-validator';
import { MeasuredLengthValidator } from '../validation/validators/measured-length-validator';
import { PartValidator } from '../validation/validators/part-validator';
import { PretermNapValidator } from '../validation/validators/preterm-nap-validator';
import { PretermNewTubeValidator } from '../validation/validators/preterm-new-tube-validator';
import {
    PretermParentFibersProvided
} from '../validation/validators/preterm-parent-fibers-provided';
import { SegmentSpanValidator } from '../validation/validators/segment-span-validator';
import { SideSlackValidator } from '../validation/validators/side-slack-validator';
import { SplicePointSlackValidator } from '../validation/validators/splice-point-slack-validator';
import {
    TetherCompatbilityValidator
} from '../validation/validators/tether-compatibility-validator';
import { TotalFiberCountValidator } from '../validation/validators/total-fiber-count-validator';
import { Validator } from '../validation/validators/validator';
import { ValidationProps } from './validation';

const GEOGRAPHIC_AREA_RADIUS = 3032;

const isInGeographicArea = (areaCenter: number[], point: number[]): boolean => {
    const distanceBetween = getLength(new LineString([areaCenter, point]));
    return distanceBetween <= GEOGRAPHIC_AREA_RADIUS;
}

export const useValidation = (props: ValidationProps) => {
    const dispatch = useDispatch();

    const { designAreas, designAreaBuilds } = useSelector(designAreaSelector);
    const { flexnapBuilds, bulkBuilds, segments } = useSelector(buildSelector);
    const { recentDesignMode, currentWorkspace } = useSelector(workspaceSelector);
    const naps = useSelector(napsSelector);
    const taps = useSelector(tapsSelector);
    const tethers = useSelector(tethersSelector);
    const splicePoints = useSelector(splicePointsSelector);
    const { displayUnits } = useSelector(authenticationSelector);
    const poles = useSelector(polesSelector);
    const bores = useSelector(boresSelector);
    const manholes = useSelector(manholesSelector);
    const handholes = useSelector(handholesSelector);
    const vaults = useSelector(vaultsSelector);
    const cabinets = useSelector(cabinetsSelector);
    const selectedBuildId = useSelector(selectedBuildIdSelector);
    const selectedDesignAreaId = useSelector(selectedDesignAreaIdSelector);
    const { viewport } = useSelector(mapSelector);
    const flexNapSplicePlans = useSelector(flexNapSplicePlansSelector);
    const cabinetSplicePlans = useSelector(cabinetSplicePlansSelector);
    const displayedBuildId = useSelector(displayedBuildIdSelector);
    const { validationResults: storeValidationResults } = useSelector(validationSelector)

    const getValidationResults = useCallback((): ValidationResult[] => {
        if (!storeValidationResults) return [];
        if (!currentWorkspace) return [];
        const validationResults: ValidationResult[] = storeValidationResults[currentWorkspace.id];
        return validationResults ?? [];
    }, [currentWorkspace, storeValidationResults]);

    const { cableParts, tetherParts, installationTypes } = props;

    const validators: Array<Validator<any>> = useMemo(() => [
        new LooseTubeValidator(),
        new BufferTubeValidator(),
        new TotalFiberCountValidator(),
        new MaxLengthValidator(0.8, displayUnits),
        new SegmentSpanValidator(installationTypes, displayUnits),
        new SideSlackValidator(installationTypes, displayUnits),
        new AssignedNapValidator(),
        new DuplicatePoleValidator(),
        new PartValidator(),
        new MeasuredLengthValidator(),
        new TetherCompatbilityValidator(),
        new DesignAreaBuildValidator(),
        new FlexNapNapsCountValidator(),
        new FiberCountValidator(flexNapSplicePlans),
        new PretermNewTubeValidator(),
        new PretermParentFibersProvided(),
        new PretermNapValidator(),
        new DesignSpanRiserValidator(displayUnits),
        new SplicePointSlackValidator(),
        new CabinetSplicePlanPortValidator(cabinetSplicePlans)
    ], [cabinetSplicePlans, displayUnits, flexNapSplicePlans, installationTypes]);

    const getDesignAreas = useCallback((buildId: number, designAreaData: DesignAreaData[]): DesignAreaData[] => {
        const designAreas = designAreaBuilds?.filter((da) => da.buildIds.includes(buildId)) ?? [];
        const designAreaIds = new Set(designAreas.map((da) => da.designAreaId));
        return designAreaData.filter((da) => designAreaIds.has(da.id));
    }, [designAreaBuilds]);

    const filterDataByBuilds = useCallback((flexNapBuildData: FlexNapBuildData[], bulkBuildData: BulkBuildData[], napData: NapData[], splicePointData: SplicePointData[], buildIds: Set<number>, designArea?: DesignArea) => {
        const designAreaPolygon = designArea?.polygon;

        const filteredFlexnapBuildData = flexNapBuildData.filter(b => buildIds.has(b.id));
        const filteredBulkBuildData = bulkBuildData.filter(b => buildIds.has(b.id));
        const flexNapPretermBuildData = filteredFlexnapBuildData ? ValidationHelper.createPretermBuildData(filteredFlexnapBuildData) : [];
        const segmentData = [...filteredFlexnapBuildData.flatMap(b => b.segments), ...filteredBulkBuildData.flatMap(b => b.segments)];

        const filteredSegments = segments.filter(s => buildIds.has(s.buildId));
        const filteredElementIds = new Set(filteredSegments.flatMap(s => [s.fromId, s.toId]));

        const filteredPoles = poles.filter(p => filteredElementIds.has(p.id) || (designAreaPolygon ? DesignAreaHelper.isPointInsidePolygon({ x: p.x, y: p.y }, designAreaPolygon) : false));
        const filteredCabinets = cabinets.filter(c => filteredElementIds.has(c.id) || (designAreaPolygon ? DesignAreaHelper.isPointInsidePolygon({ x: c.x, y: c.y }, designAreaPolygon) : false));
        const filteredBores = bores.filter(b => filteredElementIds.has(b.fromElementId) || filteredElementIds.has(b.toElementId) || (designAreaPolygon ? b.route.find(p => DesignAreaHelper.isPointInsidePolygon(p, designAreaPolygon)) : false));

        const filteredNapData = napData.filter(n => filteredElementIds.has(n.elementId) || (designAreaPolygon ? DesignAreaHelper.isPointInsidePolygon(n.point, designAreaPolygon) : false));
        const filteredSplicePointData = splicePointData.filter(s => filteredElementIds.has(s.elementId) || (designAreaPolygon ? DesignAreaHelper.isPointInsidePolygon(s.point, designAreaPolygon) : false));
        const filteredPoleData = ValidationHelper.createPoleData(filteredPoles);
        const filteredCabinetData = ValidationHelper.createCabinetData(filteredCabinets);
        const filteredBoreData = ValidationHelper.createBoreData(filteredBores);

        return [...filteredFlexnapBuildData, ...filteredBulkBuildData, ...flexNapPretermBuildData, ...filteredNapData, ...filteredSplicePointData, ...segmentData, ...filteredPoleData, ...filteredCabinetData, ...filteredBoreData];
    }, [bores, cabinets, poles, segments]);

    const validateData = useCallback((data: Data | Data[]): ValidationResult[] => {
        const notifications: ValidationResult[] = [];
        for (const v of validators) {
            if (v.canValidate(data)) {
                const res = v.validate(data);
                if (Array.isArray(res)) {
                    if (res.length > 0) {
                        notifications.push(...res);
                    }
                }
                else if (res) {
                    notifications.push(res);
                }
            }
        }
        return notifications;
    }, [validators]);

    const validateGis = useCallback((): void => {
        if (!cableParts || !tetherParts) {
            console.error('Cannot validate, no parts');
            return;
        }
        const elements: Element[] = [...poles, ...manholes, ...handholes, ...vaults, ...cabinets];
        if (!elements.length) {
            return;
        }
        const napData = naps.map((n) => ValidationHelper.createNapData(n, flexnapBuilds, taps, tethers, elements, tetherParts)).filter((n) => !!n) as NapData[];
        const splicePointData = splicePoints.map(s => ValidationHelper.createSplicePointData(s, elements));
        const designAreaData = ValidationHelper.createDesignAreaData(designAreas ?? []);
        const flexNapBuildData = flexnapBuilds.map((b) => ValidationHelper.createBuildData(b, segments.filter((s) => s.buildId === b.id), napData, elements, cableParts, getDesignAreas(b.id, designAreaData), b.pretermLateral));
        const bulkBuildData = bulkBuilds.map(b => ValidationHelper.createBulkBuildData(b, segments.filter(s => s.buildId === b.id), getDesignAreas(b.id, designAreaData), splicePointData?.filter(s => s.buildId === b.id), elements));
        let schrodingerBuildValidationResults = getValidationResults();

        // Filtering items from data for notifications
        const filteredData: Data[] = [];

        if (selectedDesignAreaId) {
            const selectedDA = designAreas.find(d => d.id === selectedDesignAreaId);
            const buildIdsInDA = new Set(designAreaBuilds.find(d => d.designAreaId === selectedDesignAreaId)?.buildIds);
            if (!buildIdsInDA || !selectedDA) {
                return;
            }
            filteredData.push(...filterDataByBuilds(flexNapBuildData, bulkBuildData, napData, splicePointData, buildIdsInDA, selectedDA));
        }
        else if (selectedBuildId) {
            filteredData.push(...filterDataByBuilds(flexNapBuildData, bulkBuildData, napData, splicePointData, new Set([selectedBuildId])));
            schrodingerBuildValidationResults = schrodingerBuildValidationResults.filter(b => b.buildId === selectedBuildId);
        }
        else {
            // Builds in area
            const viewportCenter = [(viewport[0] + viewport[2]) / 2, (viewport[1] + viewport[3]) / 2];
            const filteredFlexnapBuildData = flexNapBuildData.filter((b) => isInGeographicArea(viewportCenter, b.getExtentCenter()));
            const filteredBulkBuildData = bulkBuildData.filter(b => isInGeographicArea(viewportCenter, b.getExtentCenter()));
            const buildIdsInArea = filteredFlexnapBuildData.map(f => f.id);
            const flexNapPretermBuildData = elements.length && !!filteredFlexnapBuildData ? ValidationHelper.createPretermBuildData(filteredFlexnapBuildData) : [];
            const segmentData = filteredFlexnapBuildData.flatMap((b) => b.segments);

            // Elements in area
            const filteredNapData = napData.filter(n => buildIdsInArea.find(i => i === n.buildId) || isInGeographicArea(viewportCenter, [n.point.x, n.point.y]));
            const filteredSplicePointData = splicePointData.filter(s => buildIdsInArea.find(i => i === s.buildId) || isInGeographicArea(viewportCenter, [s.point.x, s.point.y]));
            const filteredPoleData = ValidationHelper.createPoleData(poles);
            const filteredCabinetData = ValidationHelper.createCabinetData(cabinets);
            const filteredBoreData = ValidationHelper.createBoreData(bores);
            filteredData.push(...filteredFlexnapBuildData, ...filteredBulkBuildData, ...flexNapPretermBuildData, ...filteredNapData, ...filteredSplicePointData, ...segmentData, ...filteredPoleData, ...filteredCabinetData, ...filteredBoreData);
        }

        const notifications: ValidationResult[] = [];
        for (const d of filteredData) {
            notifications.push(...validateData(d));
        }
        notifications.push(...schrodingerBuildValidationResults);
        dispatch(addNotifications(notifications));
    }, [cableParts, tetherParts, poles, manholes, handholes, vaults, cabinets, naps, splicePoints, designAreas, flexnapBuilds, bulkBuilds, getValidationResults, selectedDesignAreaId, selectedBuildId, dispatch, taps, tethers, segments, getDesignAreas, designAreaBuilds, filterDataByBuilds, viewport, bores, validateData]);

    const validateCanvas = useCallback((): void => {
        const validationResult = getValidationResults();
        const buildValidationResults = validationResult.filter(r => r.buildId === displayedBuildId);
        dispatch(addNotifications(buildValidationResults));
    }, [dispatch, getValidationResults, displayedBuildId]);

    useEffect(() => {
        dispatch(clearNotifications());
        if (recentDesignMode === DesignMode.GISMode) {
            validateGis();
        } else {
            validateCanvas()
        }
    }, [dispatch, validateGis, validateCanvas, recentDesignMode]);
}

