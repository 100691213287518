import React from 'react';
import { WorkspaceRowProps } from "./drawer-workspace-row.types";
import { useWorkspaceRow } from './drawer-workspace-row.hooks';
import { LoadWorkspaceIcon } from './load-workspace-icon';
import { WorkspaceIcon } from './workspace-icon';
import { WorkspaceToolbar } from '../toolbar/drawer-workspace-toolbar';
import { WorkspaceDescription } from '../description/drawer-workspace-description';

import './drawer-workspace-row.scss';

export const WorkspaceRow = (props: WorkspaceRowProps) => {
    const { rowClassName, onRowEnter, onRowLeave, showLoadWorkspaceIcon, onIconEnter, onIconLeave, onIconClick, workspace, workspaceName, workspaceDate, showToolbar, showLongDesc, setShowLongDesc, workspaceDesc, workspaceLocation, nbDAs, nbBuilds, nbExports, exportsPercentage, workspaceIconOpacity } = useWorkspaceRow(props);
    return (
        <div className={rowClassName} onMouseEnter={() => onRowEnter(workspace.id)} onMouseLeave={onRowLeave}>
            <div className="workspace-drawer-icon" onMouseEnter={() => onIconEnter(workspace.id)} onMouseLeave={onIconLeave} onClick={onIconClick}>
                {showLoadWorkspaceIcon ? <LoadWorkspaceIcon /> : <WorkspaceIcon opacity={workspaceIconOpacity} />}
            </div>
            <div className="workspace-drawer-row-end">
                <div className="workspace-drawer-short-desc-container">
                    <div className="workspace-drawer-short-desc">
                        <div className="workspace-drawer-workspace-name">
                            {workspaceName}
                        </div>
                        <div className="workspace-drawer-date-modified">
                            {workspaceDate}
                        </div>
                    </div>
                    {showToolbar ? <WorkspaceToolbar key={workspace.id} workspace={workspace} isExpanded={showLongDesc} setIsExpanded={setShowLongDesc} /> : null}
                </div>
                {showLongDesc ? <WorkspaceDescription description={workspaceDesc} location={workspaceLocation} nbDAs={nbDAs} nbBuilds={nbBuilds} nbExports={nbExports} exportsPercentage={exportsPercentage} /> : null}
            </div>
        </div>
    );
}