/* eslint-disable no-prototype-builtins */
import { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import { MapItemDefinition } from '../models/map-item-definition';
import { PointLike } from '../models/pointlike';
import { RESOLUTION_CLOSE, RESOLUTION_FAR, RESOLUTION_MEDIUM } from './constants';
import { FeatureType } from './openlayer-feature.factory';

const MAX_SIMILARITY_DISTANCE = 50; // max distance to consider two points to be groupable.

interface BuildCluster {
    x: number;
    y: number;
    buildIds: number[];
    active: boolean;
    initialLocation: PointLike;
}

export class OpenlayerUtility {
    public static getFeatureDefinition(feature: FeatureLike): MapItemDefinition {
        let type: FeatureType = feature.get('type');
        const id: number = feature.get('id');
        let isCluster = false;
        if (!type) {
            const features = feature.get('features') as FeatureLike[];
            if (features && features[0]) {
                type = features[0].get('type');
                isCluster = true;
            }
        }

        const featureGeometry = feature.getGeometry() as Point;
        return {
            type,
            id,
            isCluster,
            elementId: feature.get('elementId'),
            elementCoordinates: { x: featureGeometry.getCoordinates()[0], y: featureGeometry.getCoordinates()[1] },
            isImported: feature.get('isImported'),
            isLocked: feature.get('locked'),
            napId: feature.get('napId'),
            buildId: feature.get('buildId'),
            tetherIds: feature.get('tetherIds'),
            terminalId: feature.get('terminalId')
        };
    }

    public static getResolutionDistanceIndex(resolution: number | undefined): number {
        if (!resolution) {
            return 3;
        }

        if (resolution <= RESOLUTION_CLOSE) {
            return 0;
        }
        else if (resolution <= RESOLUTION_MEDIUM) {
            return 1;
        }
        else if (resolution <= RESOLUTION_FAR) {
            return 2;
        }
        else { // RESOLUTION_MACRO
            return 3;
        }
    }

    //check if resolutin has been change by a certain ammount, if so return true

    public static checkResolutionDelta(newResolution: number | undefined, previousResolution: number | undefined, delta: number): boolean {

        if (!newResolution || !previousResolution) {
            return false
        }
        if (newResolution >= RESOLUTION_CLOSE) {
            return false
        }
        if (Math.abs(newResolution - previousResolution) > delta) {
            return true
        }

        return false

    }



    // Grouping build ids using bottom up clustering.
    public static clusterSegmentHeads(segmentLocations: { [segmentId: number]: { point: PointLike; buildId: number } }) {
        // Step 1 - All points start as their own clusters.
        const clusters: BuildCluster[] = [];
        for (const segmentId in segmentLocations) {
            if (segmentLocations.hasOwnProperty(segmentId)) {
                const x = segmentLocations[segmentId].point.x;
                const y = segmentLocations[segmentId].point.y;
                clusters.push({ x: x, y: y, buildIds: [segmentLocations[segmentId].buildId], active: true, initialLocation: { x: x, y: y } })
            }
        }

        // Step 2 - Merge clusters based on straight line distances
        const agglomeratedClusters = this.naiveHAC(clusters);

        return agglomeratedClusters;
    }

    // Simple Hierarchical Agglomerative Clustering (HAC) algorithm with special constaint (MAX_SIMILARITY_DISTANCE)
    private static naiveHAC(clusters: BuildCluster[]) {
        const similarityMatrix: (number | null)[][] = Array.from<number | null>({ length: clusters.length }).map(() => Array.from<number | null>({ length: clusters.length }).fill(0));

        // Compute similarity matrix (similarity measure is smallest Euclidean distance)
        for (let i = 0; i < clusters.length; i++) {
            for (let j = i; j < clusters.length; j++) {
                if (i !== j) {
                    const distance = this.distanceBetweenClusters(clusters[i], clusters[j]);
                    similarityMatrix[i][j] = distance <= MAX_SIMILARITY_DISTANCE ? distance : null;
                    similarityMatrix[j][i] = distance <= MAX_SIMILARITY_DISTANCE ? distance : null;
                }
                else {
                    similarityMatrix[i][j] = null
                }
            }
        }

        // Merge most similar clusters and update
        for (let k = 0; k < clusters.length - 1; k++) {
            const { minRow, minCol, min } = this.findMinClusterDistance(similarityMatrix);
            if (min === null) {
                // No possible merges left
                break;
            }
            if (minRow !== minCol && clusters[minRow].active && clusters[minCol].active) {
                // Find new center point of cluster
                const cx = (clusters[minRow].x + clusters[minCol].x) / 2;
                const cy = (clusters[minRow].y + clusters[minCol].y) / 2;

                clusters[minRow].x = cx;
                clusters[minRow].y = cy;

                // Merge builds lists and deactivate one cluster
                clusters[minRow].buildIds.push(...clusters[minCol].buildIds);
                clusters[minCol].active = false;

                // Update similarity matrix along minRow row and col, and minCol row and col to complete deactivation
                for (let m = 0; m < clusters.length; m++) {
                    if (m !== minRow && similarityMatrix[minRow][m] !== null && similarityMatrix[m][minRow] !== null) {
                        const distance = this.distanceBetweenClusters(clusters[minRow], clusters[m]);
                        similarityMatrix[minRow][m] = distance;
                        similarityMatrix[m][minRow] = distance;
                    }
                    if (m !== minCol && similarityMatrix[minCol][m] !== null && similarityMatrix[m][minCol] !== null) {
                        similarityMatrix[m][minCol] = null;
                        similarityMatrix[minCol][m] = null;
                    }
                }
            }
        }
        return clusters.filter(c => c.active);
    }

    // Euclidean distance between two Clusters
    private static distanceBetweenClusters(c1: BuildCluster, c2: BuildCluster) {
        const vector = [c1.x - c2.x, c1.y - c2.y];
        return this.magnitude(vector);
    }

    // Minimum distance for clustering
    private static findMinClusterDistance(arr: (number | null)[][]) {
        let min: number | null = null;
        let minRow = 0;
        let minCol = 0;

        for (let i = 0; i < arr.length; i++) {
            for (let j = i + 1; j < arr.length; j++) {
                const current = arr[i][j];
                if (min === null || (current !== null && current < min)) {
                    min = current;
                    minRow = i;
                    minCol = j;
                }
            }
        }

        return { minRow, minCol, min };
    }

    public static convexHull(points: number[][]) {
        points = points.sort((a, b) => a[0] === b[0] ? a[1] - b[1] : a[0] - b[0]);

        const lower: number[][] = [];
        for (const point of points) {
            while (lower.length >= 2 && this.cross(lower[lower.length - 2], lower[lower.length - 1], point) <= 0) {
                lower.pop();
            }
            lower.push(point);
        }

        const upper: number[][] = [];
        for (let i = points.length - 1; i >= 0; i--) {
            const point = points[i];
            while (upper.length >= 2 && this.cross(upper[upper.length - 2], upper[upper.length - 1], point) <= 0) {
                upper.pop();
            }
            upper.push(point);
        }
        upper.pop();
        lower.pop();
        return lower.concat(upper);
    }

    /**
     * Removes duplicates from a list based off of a custom key. 
     * @param list Array to be operated on.
     * @param key Custom comparison key.
     * @returns Returns an array of unique elements.
     */
    private static uniqueBy(list: number[][], key): number[][] {
        const seen = {};
        return list.filter(function (item) {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
    }

    /**
     * Computes a point perpendicular to a vector at some specified distance from the vector line. The point will align with the endpoint of the vector.
     * @param to The end point of the vector.
     * @param from The start point of the vector.
     * @param dist The distance used to place the point.
     * @returns Returns the perpendicular point.
     * time Complexity - O(1)
     */
    public static computePerpendicularPoint(to: number[], from: number[], dist: number): number[] {
        const x = from[0] + dist * (to[1] - from[1]) / Math.sqrt(Math.pow(to[0] - from[0], 2) + Math.pow(to[1] - from[1], 2));
        const y = from[1] - dist * (to[0] - from[0]) / Math.sqrt(Math.pow(to[0] - from[0], 2) + Math.pow(to[1] - from[1], 2));
        return [x, y];
    }

    /**
     * Computes the angle between two vectors.
     * @param a First vector.
     * @param b Second vector.
     * @returns Returns the angle in radians between both vectors.
     */
    private static angleBetween(a: number[], b: number[]): number {
        const dotProduct = a[0] * b[0] + a[1] * b[1];
        const magnitudes = this.magnitude(a) * this.magnitude(b);
        let angle = Math.acos(dotProduct / magnitudes);
        if (Number.isNaN(angle)) {
            angle = 0;
        }
        return angle;
    }

    /**
     * Rotates a vector by a specified angle.
     * @param vec The vector to be rotated.
     * @param rad The angle in radians by which to rotate the vector.
     * @returns Returns a new rotated vector.
     */
    private static rotate(vec: number[], rad: number): number[] {
        const cos = Math.cos(rad);
        const sin = Math.sin(rad);
        return [Math.round(10000000 * (vec[0] * cos - vec[1] * sin) / 10000000), Math.round(10000000 * (vec[0] * sin + vec[1] * cos) / 10000000)];
    }

    /**
     * Shortcut method to compute the cross product of two-dimensional vectors.
     * @param a First vector (angle is drawn from this vector to the other).
     * @param b Second vector.
     * @returns Returns a signed value representing the cross product.
     */
    private static crossProduct2D(a: number[], b: number[]): number {
        return a[0] * b[1] - a[1] * b[0];
    }

    /**
     * Computes the magnitude of a vector.
     * @param vec Vector.
     * @returns Returns the value for the magnitude of the vector.
     */
    private static magnitude(vec: number[]): number {
        return Math.sqrt(Math.pow(vec[0], 2) + Math.pow(vec[1], 2));
    }

    /**
     * Computes a new point by affecting a specific point with a vector.
     * @param vec The vector representing the displacement to affect the point with.
     * @param point The point of reference to be translated.
     * @param factor Factor to affect the magnitude of the vector.
     * @returns Returns the new point.
     */
    public static applyVectorToPoint(vec: number[], point: number[], factor: number): number[] {
        return [factor * vec[0] + point[0], factor * vec[1] + point[1]];
    }

    /**
     * Computes a unit vector based off a specified vector.
     * @param vec Vector.
     * @returns Returns the unit vector.
     */
    public static normalize(vec: number[]): number[] {
        return [vec[0] / this.magnitude(vec), vec[1] / this.magnitude(vec)];
    }

    /**
     * Checks which of two vectors resembles a reference vector more (in terms of direction, not magnitude).
     * @param referenceVector Comparison vector.
     * @param headVector Vector connecting to the head of the path.
     * @param tailVector Vector connecting to the tail of the path.
     * @returns A value representing which vector is more similar.
     */
    private static checkVectorCriteria(referenceVector: number[], headVector: number[], tailVector: number[]): number {
        /**
         * The 0 case is assumed to be so improbable that it is likely to never occur and is not handled in the main algorithm. 
         * This is mostly due to the precision of the numbers and also due to the way the vectors are formed. They will likely never be identical.
         */
        let result = 0;
        if (this.angleBetween(referenceVector, headVector) < this.angleBetween(referenceVector, tailVector)) {
            result = -1;
        }
        else if (this.angleBetween(referenceVector, headVector) > this.angleBetween(referenceVector, tailVector)) {
            result = 1;
        }
        return result;
    }

    private static cross(a: number[], b: number[], o: number[]): number {
        return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
    }




    /**
    * This algorithm performs a custom computation to create a path around a line (e.g. build cable), or in other words a contour. 
    * This is different from a concave hull as order matters in the cloud of points created by the build.
    * @param points The cloud of 2D points representing the line.
    * @param basePadding The minimum amount of space between the contour and the actual line in point units (suggested value: 4).
    * @param resolution resolution of the current map.
    * @returns An ordered list of 2D points to be traced in the same order to create the contour.
    * 
    * reference - https://essence.handmade.network/blog/p/7388-generating_polygon_outlines
    */
    public static calculateOutlineHull(points: number[][], resolution: number | undefined, basePadding: number): number[][] {

        if (resolution) {
            resolution = resolution < RESOLUTION_CLOSE ? 8 * resolution : undefined;
        }

        /**
         * The algorithm requires the points to be sorted in order of the build segments (or poles) and for these points to be unique since most points
         * are computed using the previous and/or next points.
        */
        const uniquePoints: number[][] = this.uniqueBy(points, JSON.stringify);
        if (uniquePoints.length < 2) {
            return [];
        }
        /**
         * The path array that will store all the points that will be used to create the contour.
         */
        let path: number[][] = [];
        /**
         * The positiveSide array that will store the outside points of the build.
         */
        let positiveSide: number[][] = [];
        /**
         * The negativeSide array that will store the inside points of the build.
         */
        let negativeSide: number[][] = [];
        /**
         * The pointCounter is a counter that keeps track of the number of points that have been added to the negativeSide and pathOutisde arrays.
         */
        let pointCounter = 0;
        /**
         * The counter is a counter that keeps track of the index of uniquePoints.
         */
        let sourcePointCounter = 0

        /**
         * The algorithm will iterate through the points and create a path around the line.
         * 
         * There are 7 main phases:
         * 1. Create a path around the line on the outside.
         * 2. Create a path around the line on the inside.
         * 3. Remove all duplicates from the outside and inside paths.
         * 4. find and replace all intersection of these new points, in the positiveSide and negativeSide respectively.
         * 5. Reverse the inside path.
         * 6. Merge the outside and inside paths.
         * 7. Return the merged path.
         * 
         * Each unique point will fall under 3 categories: The first point, the last point, and all other points.
         * The first point will be the first point in the path, the last point will be the last point in the path, and all other points will be added to the path.
         * We start with two empty array called positiveSide and negativeSide. The positiveSide array will contain the points that are outside the line, and the negativeSide array will contain the points that are inside the line.
         * The algoritm will iterate through the list of uniquePoints and compute the Perpenducular Distance (PD) from each point of a distance of basePading.
         * It will compute the PD for each point and add it to the positiveSide (basePadding) or negativeSide (-basePadding) array.
         * 
         * For the case where the point is a firstPoint, the Padding Point will be given an extra padding of basePadding to push it more outward.
         * For the case where the point is a lastPoint, the Padding Point will be given an extra padding of basePadding to push it more inward.
         * 
         * Once we have filled the positiveSide and negativeSide arrays, we will find all intersection points in each array and replace them with new points.
         * 
         * 
         */
        for (const elementPoint of uniquePoints) {
            /**
            * Special case: First Pole
            * 
            * Here, we simply compute the vector pointing from the first pole to the second and find a point perpendicular to it. The basePadding will place it
            * some distance away from the acutal line, but it remains perpendicular. We do this on each side of the line, and this ensures a nice even look no matter the 
            * orientation of the build.
            */
            if (sourcePointCounter === 0) {
                const firstPoint = elementPoint;
                const nextPoint = uniquePoints[sourcePointCounter + 1];

                const vectorToFirstPoint = this.normalize([firstPoint[0] - nextPoint[0], firstPoint[1] - nextPoint[1]]);

                const PosPerpendicularFirstPoint = OpenlayerUtility.computePerpendicularPoint(nextPoint, firstPoint, basePadding);
                const NegPerpendicularFirstPoint = OpenlayerUtility.computePerpendicularPoint(nextPoint, firstPoint, -basePadding);

                const PosPerpendicularNextPoint = OpenlayerUtility.computePerpendicularPoint(firstPoint, nextPoint, basePadding);
                const NegPerpendicularNextPoint = OpenlayerUtility.computePerpendicularPoint(firstPoint, nextPoint, -basePadding);

                /**
                 *  After computing the perpendicular points, we will add them to the positiveSide and negativeSide arrays.
                 *  We will then apply a vector to the first padding points to push them more outward.
                 */
                if (resolution) {
                    const svgOutsideSurrounderPoint = this.applyVectorToPoint(vectorToFirstPoint, PosPerpendicularFirstPoint, resolution * (basePadding + 3));
                    const svgInsideSurrounderPoint = this.applyVectorToPoint(vectorToFirstPoint, NegPerpendicularFirstPoint, resolution * (basePadding + 3));
                    positiveSide[pointCounter] = svgOutsideSurrounderPoint;
                    negativeSide[pointCounter] = svgInsideSurrounderPoint;
                } else {
                    positiveSide[pointCounter] = NegPerpendicularFirstPoint;
                    negativeSide[pointCounter] = PosPerpendicularFirstPoint;
                }

                pointCounter++;
                positiveSide[pointCounter] = NegPerpendicularNextPoint;
                negativeSide[pointCounter] = PosPerpendicularNextPoint;

            }
            /**
            * Special Case: Last Pole
            * 
            * The process here is essentially the same as the first pole. We compute the vector pointing from the last pole to the previous one, then find a perpendicular point
            * to that line. However, order matters here, and this logic will come back later in a very similar way.
            * 
            * It works by considering the two perpendicular padded points computed.
            * 
            */
            else if (sourcePointCounter === uniquePoints.length - 1) {
                const prevPoint = uniquePoints[sourcePointCounter - 1];
                const lastPoint = elementPoint;
                const vectorFromPreviousPoint = [lastPoint[0] - prevPoint[0], lastPoint[1] - prevPoint[1]];
                const normalizedVectorFromPreviousPoint = this.normalize(vectorFromPreviousPoint);

                const PosPerpendicularLastPoint = OpenlayerUtility.computePerpendicularPoint(prevPoint, lastPoint, basePadding);
                const NegPerpendicularLastPoint = OpenlayerUtility.computePerpendicularPoint(prevPoint, lastPoint, -basePadding);

                const PosPerpendicularPrevPoint = OpenlayerUtility.computePerpendicularPoint(lastPoint, prevPoint, basePadding);
                const NegPerpendicularPrevPoint = OpenlayerUtility.computePerpendicularPoint(lastPoint, prevPoint, -basePadding);
                /**
                 * After computing the perpendicular points, we will add them to the positiveSide and negativeSide arrays.
                 * We will then apply a vector to the last padding points to push them more outward.
                 */
                if (resolution) {
                    const svgPosSurrounderPoint = this.applyVectorToPoint(normalizedVectorFromPreviousPoint, PosPerpendicularLastPoint, resolution * (basePadding + 3));
                    const svgNegSurrounderPoint = this.applyVectorToPoint(normalizedVectorFromPreviousPoint, NegPerpendicularLastPoint, resolution * (basePadding + 3));
                    positiveSide[pointCounter] = svgNegSurrounderPoint;
                    negativeSide[pointCounter] = svgPosSurrounderPoint;
                }
                positiveSide[pointCounter - 1] = PosPerpendicularPrevPoint;
                negativeSide[pointCounter - 1] = NegPerpendicularPrevPoint;


            }
            /**
             * Default Case: Any point within the build excluding head and tail.
             * 
             * In this case, the algorithm computes the vectors pointing from our current pole point to the previous and next ones. 
             * Then, we find the angle between them, and we find the bisector of this angle, where our first padded point will be placed.
             * 
             */
            else {
                const currentPoint = elementPoint;
                const nextPoint = uniquePoints[sourcePointCounter + 1];

                const PosPerpendicularCurrentPoint = OpenlayerUtility.computePerpendicularPoint(nextPoint, currentPoint, basePadding);
                const NegPerpendicularCurrentPoint = OpenlayerUtility.computePerpendicularPoint(nextPoint, currentPoint, -basePadding);

                const PosPerpendicularNextPoint = OpenlayerUtility.computePerpendicularPoint(currentPoint, nextPoint, basePadding);
                const NegPerpendicularNextPoint = OpenlayerUtility.computePerpendicularPoint(currentPoint, nextPoint, -basePadding);

                /**
                 * After computing the perpendicular points, we will add them to the positiveSide and negativeSide arrays.
                 */
                positiveSide[pointCounter] = PosPerpendicularCurrentPoint;
                negativeSide[pointCounter] = NegPerpendicularCurrentPoint;
                pointCounter++;
                positiveSide[pointCounter] = NegPerpendicularNextPoint;
                negativeSide[pointCounter] = PosPerpendicularNextPoint;
            }

            pointCounter++;
            sourcePointCounter++;
        }


        /**
         * We will filter out all duplicate points from the outside and inside paths, and remove all empty points
         * Time Complexity - O(n)
         */
        negativeSide = negativeSide.filter(point => point.length > 0).filter((point, index, array) => {
            return array.findIndex(p => p[0] === point[0] && p[1] === point[1]) === index;
        }
        );

        /**
         * We will filter out all duplicate points from the outside and inside paths, and remove all empty points
         * Time Complexity - O(n)
         */
        positiveSide = positiveSide.filter(point => point.length > 0).filter((point, index, array) => {
            return array.findIndex(p => p[0] === point[0] && p[1] === point[1]) === index;
        }
        );

        /**
         * Find and replace all intersection points in the outside and inside paths with new points.
         * This will handle sharp angles and round them up, it will also intercept the intersection points that is inside the sharp angles.
         * Time Complexity - O(n^2)
         */
        negativeSide = this.findAndReplaceIntersectionPoints(negativeSide, uniquePoints)
        /**
         * Reverse the inside path so that it is in the correct order.
         * Time Complexity - O(n)
        */
        negativeSide.reverse();
        positiveSide = this.findAndReplaceIntersectionPoints(positiveSide, uniquePoints)
        /**
         * Merge the outside and inside paths together.
         * Time Complexity - O(n)
         */
        path = positiveSide.concat(negativeSide);

        return path;
    }

    /*
    * @param points: number[][] - Array of points - each point is an array of two numbers - [x, y]
    * @param resolution: number | undefined
    * @param basePadding: number
    * @returns {number[][]}
    * @description Loop throught the path array, each two point makes a line, for each line check for an interseciton. 
    * time Complexity O(n * (n+M)) = O(n^2) where n is the number of points in the path and M is the number of points on one curve.
    */
    private static findAndReplaceIntersectionPoints(path: number[][], sourcePoints: number[][]): number[][] {
        let numOfPoints = path.length;
        // array to store index and intersection point
        const intersectionPoints: number[][] = [];
        /**
         * Loop throught the array, each two point makes a line, for each line check for an interseciton.
         * If the intersection point is not on either of the lines, this means it is a sharp angle and we will add a curve to that corner.
         * 
         * Once we compute the array of points that generates the curved segments, we will find the segment which is closer to the next point 
         * (the start of the roundedCornerPoints array, or the end of the roundedCornerPoints array).
         * if the length from the point at the start to the nextpoints is shorter than the length from the point at the end to the nextpoints, 
         * then we will reverse the order of the roundedCornerPoints array.
         */
        let elementCounter = 0;
        for (let counter = 0; counter < numOfPoints; counter += 2) { //Worst case would be O(n^2)
            let roundedCornerPoints: number[][] = [];

            if (counter <= numOfPoints - 4) {
                const firstPoint = path[counter]; //previous point
                const secondPoint = path[counter + 1]; // These are our target points
                const thirdPoint = path[counter + 2]; // These are our target points
                const fourthPoint = path[counter + 3];// nextPoint

                const line1 = [firstPoint, secondPoint];
                const line2 = [thirdPoint, fourthPoint];
                let intersectionPoint
                if (elementCounter + 2 < sourcePoints.length) {
                    //check if the 3 poles are on the same line - if so they are parralel, thus no intersection
                    if (!this.isPointOnTheLine(sourcePoints[elementCounter], [sourcePoints[elementCounter + 1], sourcePoints[elementCounter + 2]]) &&
                        !this.isPointOnTheLine(sourcePoints[elementCounter + 2], [sourcePoints[elementCounter + 1], sourcePoints[elementCounter]])) {
                        intersectionPoint = this.findIntersection(line1, line2);
                    }
                }

                if (intersectionPoint) {
                    if (this.isPointOnTheLine(intersectionPoint, line1) || this.isPointOnTheLine(intersectionPoint, line2)) {
                        intersectionPoints[counter + 1] = intersectionPoint;
                        intersectionPoints[counter + 2] = intersectionPoint;
                    }
                    else {

                        roundedCornerPoints = this.calculateRoundedCorner(secondPoint, intersectionPoint, thirdPoint, 4, 3);
                        if (roundedCornerPoints.length > 0) {

                            //check which is closer the next  (the end the point at the end of the array or the point at the start of the array)
                            const startPointOfCurve = roundedCornerPoints[0];
                            const endPointOfCurve = roundedCornerPoints[roundedCornerPoints.length - 1];

                            //calculate the vector of the start to the fourth point
                            const vectorStartToFourthPoint = [fourthPoint[0] - startPointOfCurve[0], fourthPoint[1] - startPointOfCurve[1]];
                            //calculate the vector of the end point to the fourth point
                            const vectorEndToFourthPoint = [fourthPoint[0] - endPointOfCurve[0], fourthPoint[1] - endPointOfCurve[1]];

                            //calculate the length of the vectorStartToFourthPoint
                            const lengthStartToFourthPoint = Math.floor(Math.sqrt(vectorStartToFourthPoint[0] * vectorStartToFourthPoint[0] + vectorStartToFourthPoint[1] * vectorStartToFourthPoint[1]));

                            //calculate the length of the vectorEndToFourthPoint
                            const lengthEndToFourthPoint = Math.floor(Math.sqrt(vectorEndToFourthPoint[0] * vectorEndToFourthPoint[0] + vectorEndToFourthPoint[1] * vectorEndToFourthPoint[1]));


                            //calculate the vector of the start to the first point
                            const vectorStartToFirstPoint = [firstPoint[0] - startPointOfCurve[0], firstPoint[1] - startPointOfCurve[1]];
                            //calculate the vector of the end point to the first point
                            const vectorEndToFirstPoint = [firstPoint[0] - endPointOfCurve[0], firstPoint[1] - endPointOfCurve[1]];

                            //calculate the length of the vectorStartToFirstPoint
                            const lengthStartToFirstPoint = Math.floor(Math.sqrt(vectorStartToFirstPoint[0] * vectorStartToFirstPoint[0] + vectorStartToFirstPoint[1] * vectorStartToFirstPoint[1]));

                            //calculate the length of the vectorEndToFirstPoint
                            const lengthEndToFirstPoint = Math.floor(Math.sqrt(vectorEndToFirstPoint[0] * vectorEndToFirstPoint[0] + vectorEndToFirstPoint[1] * vectorEndToFirstPoint[1]));


                            //check which is closer the start or end point
                            if ((lengthStartToFourthPoint < lengthEndToFourthPoint) || (lengthStartToFirstPoint > lengthEndToFirstPoint)) {
                                roundedCornerPoints.reverse() //O(n)
                            }

                            path.splice(counter + 1, 2, ...roundedCornerPoints); //O(n + M)
                            numOfPoints += roundedCornerPoints.length - 2;
                            counter += roundedCornerPoints.length - 2;
                        }
                    }
                } else {

                    const normalizedVectorCenterRadius = this.normalize([sourcePoints[elementCounter + 1][0] - sourcePoints[elementCounter][0], sourcePoints[elementCounter + 1][1] - sourcePoints[elementCounter][1]]);
                    const centerRadiusPoint = this.applyVectorToPoint(normalizedVectorCenterRadius, sourcePoints[elementCounter + 1], 70);

                    roundedCornerPoints = this.calculateRoundedCorner(secondPoint, centerRadiusPoint, thirdPoint, 8, 4);
                    if (roundedCornerPoints.length > 0) {

                        //check which is closer the next  (the end the point at the end of the array or the point at the start of the array)
                        const startPointOfCurve = roundedCornerPoints[0];
                        const endPointOfCurve = roundedCornerPoints[roundedCornerPoints.length - 1];

                        //calculate the vector of the start to the fourth point
                        const vectorStartToFourthPoint = [fourthPoint[0] - startPointOfCurve[0], fourthPoint[1] - startPointOfCurve[1]];
                        //calculate the vector of the end point to the fourth point
                        const vectorEndToFourthPoint = [fourthPoint[0] - endPointOfCurve[0], fourthPoint[1] - endPointOfCurve[1]];

                        //calculate the length of the vectorStartToFourthPoint
                        const lengthStartToFourthPoint = Math.floor(Math.sqrt(vectorStartToFourthPoint[0] * vectorStartToFourthPoint[0] + vectorStartToFourthPoint[1] * vectorStartToFourthPoint[1]));

                        //calculate the length of the vectorEndToFourthPoint
                        const lengthEndToFourthPoint = Math.floor(Math.sqrt(vectorEndToFourthPoint[0] * vectorEndToFourthPoint[0] + vectorEndToFourthPoint[1] * vectorEndToFourthPoint[1]));


                        //calculate the vector of the start to the first point
                        const vectorStartToFirstPoint = [firstPoint[0] - startPointOfCurve[0], firstPoint[1] - startPointOfCurve[1]];
                        //calculate the vector of the end point to the first point
                        const vectorEndToFirstPoint = [firstPoint[0] - endPointOfCurve[0], firstPoint[1] - endPointOfCurve[1]];

                        //calculate the length of the vectorStartToFirstPoint
                        const lengthStartToFirstPoint = Math.floor(Math.sqrt(vectorStartToFirstPoint[0] * vectorStartToFirstPoint[0] + vectorStartToFirstPoint[1] * vectorStartToFirstPoint[1]));

                        //calculate the length of the vectorEndToFirstPoint
                        const lengthEndToFirstPoint = Math.floor(Math.sqrt(vectorEndToFirstPoint[0] * vectorEndToFirstPoint[0] + vectorEndToFirstPoint[1] * vectorEndToFirstPoint[1]));


                        //check which is closer the start or end point
                        if ((lengthStartToFourthPoint < lengthEndToFourthPoint) || (lengthStartToFirstPoint > lengthEndToFirstPoint)) {
                            roundedCornerPoints.reverse() //O(n)
                        }

                        path.splice(counter + 1, 2, ...roundedCornerPoints); //O(n + M)
                        numOfPoints += roundedCornerPoints.length - 2;
                        counter += roundedCornerPoints.length - 2;
                    }
                }
            }
            elementCounter++;
        }

        // replace intersection points with the intersection point - O(n)
        for (let counter = 0; counter < intersectionPoints.length; counter++) {
            if (intersectionPoints[counter]) {
                path[counter] = intersectionPoints[counter];
            }
        }

        //remove duplicate points - O(n)
        path = path.filter(point => point.length > 0).filter((point, index, array) => {
            return array.findIndex(p => p[0] === point[0] && p[1] === point[1]) === index;
        }
        );

        return path;
    }

    /*
    * @param line1: number[][] - Array of two points - each point is an array of two numbers - [x, y]
    * @param line2: number[][] - Array of two points - each point is an array of two numbers - [x, y]
    * @returns {number[]}
    * @description Find the intersection point of two lines
    * time Complexity - O(1)
    */
    private static findIntersection(line1: number[][], line2: number[][]): undefined | number[] {

        const pointA1 = line1[0];
        const pointA2 = line1[1];

        const pointB1 = line2[0];
        const pointB2 = line2[1];

        // Line AB represented as a1x + b1y = c1
        const a1 = pointA2[1] - pointA1[1]; // y2 - y1
        const b1 = pointA1[0] - pointA2[0]; // x1 - x2
        const c1 = a1 * pointA1[0] + b1 * pointA1[1]; // a1x + b1y = c1

        // Line CD represented as a2x + b2y = c2

        const a2 = pointB2[1] - pointB1[1]; // y4 - y3
        const b2 = pointB1[0] - pointB2[0]; // x3 - x4

        const c2 = a2 * pointB1[0] + b2 * pointB1[1]; // a2x + b2y = c2


        // determinant
        const determinant = this.crossProduct2D([a1, a2], [b1, b2])
        if (determinant === 0) { // Lines are parallel
            return undefined;

        } else {
            const x = (b2 * c1 - b1 * c2) / determinant;
            const y = (a1 * c2 - a2 * c1) / determinant;
            return [x, y];

        }
    }

    /*
    * @param intersection: number[] - Array of two numbers - [x, y]
    * @param line: number[][] - Array of two points - each point is an array of two numbers - [x, y] - forms a line
    * @param distance: number
    * @description check if a point is in between two other points
    * time complexity O(1)
    */
    private static isPointOnTheLine(intersection: number[], line: number[][]): boolean {
        //Check whether intersection lies on the line
        const pointA = line[0];
        const pointB = line[1];
        const intersectionPoint = [intersection[0], intersection[1]];
        //distance from intersectionPoint to pointA 
        const distanceAC = Math.sqrt(Math.pow(pointA[0] - intersectionPoint[0], 2) + Math.pow(pointA[1] - intersectionPoint[1], 2));
        //distance from intersectionPoint to pointB
        const distanceBC = Math.sqrt(Math.pow(pointB[0] - intersectionPoint[0], 2) + Math.pow(pointB[1] - intersectionPoint[1], 2));



        //distance from point A to point B
        const distanceAB = Math.sqrt(Math.pow(pointB[0] - pointA[0], 2) + Math.pow(pointB[1] - pointA[1], 2));

        const value1 = Math.floor((distanceAC + distanceBC))
        const value2 = Math.floor(distanceAB);
        return value1 === value2
    }

    /**
    * @param pointA: number[] - Array of points - each point is an array of two numbers - [x, y]
    * @param pointB: number[] - Array of points - each point is an array of two numbers - [x, y] - This is the angled Point
    * @param pointC: number[] - Array of points - each point is an array of two numbers - [x, y] 
    * @param radius: number - Radius of the circle - This will determine the amount of curve
    * @param degreeFactor: number - This will determine the amount of points on the curve
    * @returns {number[][]}
    * @description calculate the rounded corner three points for a corner
    * time Complexity - O(n)
    * reference - https://stackoverflow.com/questions/24771828/how-to-calculate-rounded-corners-for-a-polygon
    */
    private static calculateRoundedCorner(pointA: number[], pointB: number[], pointC: number[], radius: number, degreeFactor: number): number[][] {

        //get vector for point A to point B
        const vectorAB = [pointB[0] - pointA[0], pointB[1] - pointA[1]];
        //get vector for point C to point B
        const vectorCB = [pointB[0] - pointC[0], pointB[1] - pointC[1]];

        let rad = radius;

        //get the length of the segment between angular point and the points of intersection with the circle of a given radius
        const halfAngle = (Math.atan2(vectorAB[1], vectorAB[0]) - Math.atan2(vectorCB[1], vectorCB[0])) / 2 // always positive, between 0 and PI
        //const degreeAngle = radiansAngle * (180 / Math.PI);

        const tan = Math.abs(Math.tan(halfAngle));
        let lengthOfSegment = rad / tan;

        //calculate length of VectorAB
        const lengthOfVectorAB = Math.sqrt(Math.pow(vectorAB[0], 2) + Math.pow(vectorAB[1], 2));
        //calculate length of VectorCB
        const lengthOfVectorCB = Math.sqrt(Math.pow(vectorCB[0], 2) + Math.pow(vectorCB[1], 2));

        const length = Math.min(lengthOfVectorAB, lengthOfVectorCB);

        if (lengthOfSegment > length) {
            lengthOfSegment = length;
            rad = lengthOfSegment * Math.abs(Math.tan(halfAngle));
        }

        const PO = Math.sqrt(Math.pow(rad, 2) + Math.pow(lengthOfSegment, 2));

        //Get the C1X and C1Y by the proportion between the coordinates of the vector, length of vector and the length of the segment:
        const C1X = pointB[0] - (pointB[0] - pointA[0]) * (lengthOfSegment / lengthOfVectorAB);
        const C1Y = pointB[1] - (pointB[1] - pointA[1]) * (lengthOfSegment / lengthOfVectorAB);

        //Get the C2X and C2Y by the proportion between the coordinates of the vector, length of vector and the length of the segment:
        const C2X = pointB[0] - (pointB[0] - pointC[0]) * (lengthOfSegment / lengthOfVectorCB);
        const C2Y = pointB[1] - (pointB[1] - pointC[1]) * (lengthOfSegment / lengthOfVectorCB);

        const dx = pointB[0] * 2 - C1X - C2X;
        const dy = pointB[1] * 2 - C1Y - C2Y;

        const PC = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

        // Center coordinate of the circle 
        const Ox = pointB[0] - (dx) * (PO / PC);
        const Oy = pointB[1] - (dy) * (PO / PC);

        /**
         * Start angle of the curved segment
         */
        let startAngle = Math.atan2(C1Y - Oy, C1X - Ox);
        /**
         * End angle of the curved segment
         */
        const endAngle = Math.atan2(C2Y - Oy, C2X - Ox);

        /**
         * sweep angle of the curved segment
         */
        let sweepAngle = endAngle - startAngle;

        if (sweepAngle < 0) {
            startAngle = endAngle;
            sweepAngle = -sweepAngle;
        }

        if (sweepAngle > Math.PI) {
            sweepAngle = -(2 * Math.PI - sweepAngle)
        }

        const pointsCount = Math.abs(sweepAngle * degreeFactor);
        const sign = Math.sign(sweepAngle);

        /**
         * Generate the points of the curve
         * Time Complexity - O(n)
         */
        const roundedPoints: number[][] = []
        for (let i = 0; i < pointsCount; i++) {
            const x = Ox + Math.cos(startAngle + sign * i / degreeFactor) * rad;
            const y = Oy + Math.sin(startAngle + sign * i / degreeFactor) * rad;
            roundedPoints[i] = [x, y];
        }

        return roundedPoints;

    }


}


