import * as math from 'mathjs';
import Feature, { FeatureLike } from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { getLength } from 'ol/sphere';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import RegularShape from 'ol/style/RegularShape';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import { ElementType } from '../models/element-type';
import { PowerStatus } from '../models/power-status';
import { TerminalExtensionType } from '../models/terminal-extension-type';
import { RESOLUTION_CLOSE, RESOLUTION_FAR, RESOLUTION_MACRO, RESOLUTION_MEDIUM } from './constants';
import { InteractableFeatureType } from './interactable-feature-type';
import { FeatureType } from './openlayer-feature.factory';
import { OpenlayerUtility } from './openlayer-utility';
import { StaticFeatureType } from './static-feature-type';
import * as Svg from './svg.lib';

const SELECTED_COLOR = '#2cb4ec';
const COLOR_USERCREATED = '#FFA329';
const COLOR_IMPORTED = '#F3EC0A';
const COLOR_CHARCOALSTROKE = '#424242';
const COLOR_SELECTEDSTROKE = '#005293';
const COLOR_SELECTEDFILL = '#E1F5FC';
const COLOR_POLEYELLOW = '#F3EC0A';
const COLOR_GRAYFILL = '#E0E0E0';
const COLOR_DISABLEDTEXT = '#BDBDBD';
const COLOR_ON_PRIMARY = '#202020';
const COLOR_ON_SECONDARY = '#FAFAFA';
const COLOR_ERRORFILL = '#E11725';
const COLOR_ERRORTEXT = '#FDFDFD';
const COLOR_POWER = '#EB8D64';
const COLOR_OPTICAL = '#38BCB8';
const COLOR_LONGTAIL_TERMINAL_SEGMENT = '#005293';
const COLOR_LONGTAIL_TERMINAL_ICON = '#38BCB8';
const COLOR_TETHER_EXTENDER = '#EA2EB9';
const COLOR_NONE = 'none';

const PORT_NUMBER_MIN_WIDTH = 36;
const PORT_NUMBER_MIN_HEIGHT = 20;
const PORT_NUMBER_MAX_HEIGHT = 30;
const PORT_NUMBER_PADDING = 4;
const PORT_NUMBER_ONE_DIGIT_WIDTH = 5;
const PORT_NUMBER_WRAPPED_LENGTH = 8;
const PORT_NUMBER_ADJUSTED_RESOLUTION = 0.5;

const MAX_ID_LENGTH = 7;
const BUILD_CALLOUT_WIDTH = 62;
const BUILD_CALLOUT_WIDTH_PADDED = BUILD_CALLOUT_WIDTH + 8;

enum MODE {
    DEFAULT,
    SELECTED,
    HOVERED,
    GHOST,
}

const descisionMatrix: DescisionMatrix = {
    [InteractableFeatureType.POLE]: [Svg.POLE, Svg.POLE_SMALL, null, null],
    [InteractableFeatureType.FLEXNAP_SEGMENT]: [null],
    [InteractableFeatureType.SCHRODINGER_SEGMENT]: [null],
    [InteractableFeatureType.BULK_SEGMENT]: [null],
    [InteractableFeatureType.TRENCH]: [null],
    [InteractableFeatureType.BORE]: [null],
    [InteractableFeatureType.NAP]: [Svg.NAP, Svg.NAP_SMALL, null],
    [InteractableFeatureType.TAP]: [Svg.TAP, null, null],
    [InteractableFeatureType.SPLICE_POINT]: [Svg.SPLICE_POINT, Svg.SPLICE_POINT_SMALL, null],
    [InteractableFeatureType.TERMINAL]: [Svg.TERMINAL, Svg.TERMINAL_SMALL, null],
    [StaticFeatureType.TERMINAL_EXTENSION]: [Svg.TERMINAL_EXTENSION, null, null],
    [InteractableFeatureType.TERMINAL_SEGMENT]: [null],
    [InteractableFeatureType.PARCEL]: [Svg.SQUARE, Svg.SQUARE_SMALL, null],
    [InteractableFeatureType.CABINET]: [Svg.CABINET, Svg.SQUARE_SMALL, null],
    [InteractableFeatureType.MANHOLE]: [Svg.MANHOLE, Svg.SQUARE_SMALL, null],
    [InteractableFeatureType.HANDHOLE]: [Svg.HANDHOLE, Svg.SQUARE_SMALL, null],
    [InteractableFeatureType.VAULT]: [Svg.VAULT, Svg.SQUARE_SMALL, null],
    [InteractableFeatureType.TAP_SCHRODINGER]: [Svg.TAP_SCHRODINGER, Svg.TAP_SCHRODINGER_SMALL, null],
    [InteractableFeatureType.POWER_SCHRODINGER]: [Svg.POWER_SCHRODINGER, Svg.POWER_SCHRODINGER_SMALL, null],
    [InteractableFeatureType.POWER_LABEL_SCHRODINGER]: [Svg.POWER_LABEL_SCHRODINGER, null, null],
    [InteractableFeatureType.OPTICAL_SCHRODINGER]: [Svg.OPTICAL_SCHRODINGER, Svg.OPTICAL_SCHRODINGER_SMALL, null],
    [InteractableFeatureType.LOCKED_BUILD]: [Svg.LOCKED_BUILD, Svg.LOCKED_BUILD, null],
    [StaticFeatureType.UPLOADED_BUILD]: [Svg.UPLOADED_BUILD, Svg.UPLOADED_BUILD, null],
    [StaticFeatureType.APPROVED_BUILD]: [Svg.APPROVED_BUILD, Svg.APPROVED_BUILD, null],
    [InteractableFeatureType.REJECTED_BUILD]: [Svg.REJECTED_BUILD, Svg.REJECTED_BUILD, null],
    [StaticFeatureType.IN_REVIEW_BUILD]: [Svg.IN_REVIEW_BUILD, Svg.IN_REVIEW_BUILD, null],
    [InteractableFeatureType.EXPORTED_BUILD]: [Svg.EXPORTED_BUILD, Svg.EXPORTED_BUILD, null],
    [InteractableFeatureType.DESIGN_AREA]: [null, null, Svg.DESIGN_AREA_REGION, Svg.DESIGN_AREA_REGION],
    [StaticFeatureType.PORT_NUMBER]: [Svg.PORT_NUMBER, null, null],
    [StaticFeatureType.WRAPPED_PORT_NUMBER]: [Svg.WRAPPED_PORT_NUMBER, null, null],
    [StaticFeatureType.SLACK_LOOP]: [null],
    [StaticFeatureType.GEOPROJECT]: [null],
    [StaticFeatureType.DROP_CABLE]: [null],
    [StaticFeatureType.LOCK_OUTLINE]: [null],
    [StaticFeatureType.CIRCLE_SHADOW]: [Svg.CIRCLE_SHADOW, null, null],
    [StaticFeatureType.NAP_SHADOW]: [Svg.NAP_SHADOW, null, null],
    [StaticFeatureType.TAP_SHADOW]: [Svg.TAP_SHADOW, null, null],
    [StaticFeatureType.HOLE_SHADOW]: [Svg.HOLE_SHADOW, null, null],
    [StaticFeatureType.CABLE_SHADOW]: [null],
    [StaticFeatureType.INFRASTRUCTURE_ID]: [Svg.INFRASTRUCTURE_ID, null, null],
    [StaticFeatureType.BUILD_CALLOUT]: [Svg.BUILD_CALLOUT, null, null],
    [StaticFeatureType.CABINET_CALLOUT]: [Svg.CABINET_CALLOUT, null, null],
    [StaticFeatureType.BUILD_ID]: [Svg.BUILD_ID_CALLOUT_ELEMENT, null, null],
};

export class OpenlayerStyleFactory {
    public static createFeatureStyleFunction(feature: FeatureLike, resolution: number): Style[] {
        const { type, isCluster } = OpenlayerUtility.getFeatureDefinition(feature);
        switch (type) {
            case StaticFeatureType.DROP_CABLE:
                return OpenlayerStyleFactory.createDropcable();
            case StaticFeatureType.GEOPROJECT:
                return [OpenlayerStyleFactory.createPolygon(1, 'black', 'rgba(255, 255, 255, 0.4')];
            case InteractableFeatureType.POLE:
                return [OpenlayerStyleFactory.createPole(feature, resolution)];
            case InteractableFeatureType.FLEXNAP_SEGMENT:
                return OpenlayerStyleFactory.createBuildSegment(feature, resolution);
            case InteractableFeatureType.SCHRODINGER_SEGMENT:
                return OpenlayerStyleFactory.createSchrodingerSegment(feature, resolution);
            case InteractableFeatureType.BULK_SEGMENT:
                return OpenlayerStyleFactory.createBuildSegment(feature, resolution);
            case InteractableFeatureType.NAP:
                return [OpenlayerStyleFactory.createNAP(feature, resolution)];
            case InteractableFeatureType.TAP:
                return [OpenlayerStyleFactory.createTap(feature, resolution)];
            case InteractableFeatureType.SPLICE_POINT:
                return [OpenlayerStyleFactory.createSplicePoint(feature, resolution)];
            case StaticFeatureType.PORT_NUMBER:
                return [OpenlayerStyleFactory.createPortNumber(feature, resolution)];
            case InteractableFeatureType.TERMINAL:
                return OpenlayerStyleFactory.createTerminal(feature, resolution);
            case InteractableFeatureType.TERMINAL_SEGMENT:
                return OpenlayerStyleFactory.createTerminalSegment(feature, resolution);
            case InteractableFeatureType.PARCEL:
                return [OpenlayerStyleFactory.createParcel(feature, resolution, isCluster)];
            case InteractableFeatureType.MANHOLE:
                return [OpenlayerStyleFactory.createManhole(feature, resolution)];
            case InteractableFeatureType.HANDHOLE:
                return [OpenlayerStyleFactory.createHandhole(feature, resolution)];
            case InteractableFeatureType.VAULT:
                return [OpenlayerStyleFactory.createVault(feature, resolution)];
            case InteractableFeatureType.CABINET:
                return [OpenlayerStyleFactory.createCabinet(feature, resolution)];
            case InteractableFeatureType.TRENCH:
            case InteractableFeatureType.BORE:
                return OpenlayerStyleFactory.createPath(feature, resolution, type);
            case InteractableFeatureType.TAP_SCHRODINGER:
                return [OpenlayerStyleFactory.createSchrodingerTap(feature, resolution)];
            case InteractableFeatureType.POWER_SCHRODINGER:
                return [OpenlayerStyleFactory.createTapPower(feature, resolution)];
            case InteractableFeatureType.POWER_LABEL_SCHRODINGER:
                return [OpenlayerStyleFactory.createTapPowerLabel(feature, resolution)];
            case InteractableFeatureType.OPTICAL_SCHRODINGER:
                return [OpenlayerStyleFactory.createTapOptical(feature, resolution)];
            case InteractableFeatureType.LOCKED_BUILD:
            case StaticFeatureType.UPLOADED_BUILD:
            case StaticFeatureType.APPROVED_BUILD:
            case InteractableFeatureType.REJECTED_BUILD:
            case StaticFeatureType.IN_REVIEW_BUILD:
            case InteractableFeatureType.EXPORTED_BUILD:
                return [OpenlayerStyleFactory.createStatusIcon(feature, resolution, type)];
            case StaticFeatureType.LOCK_OUTLINE:
                return [OpenlayerStyleFactory.createLockOutline(feature, resolution)];
            case InteractableFeatureType.DESIGN_AREA:
                return OpenlayerStyleFactory.createDesignArea(feature, resolution);
            case StaticFeatureType.CIRCLE_SHADOW:
            case StaticFeatureType.NAP_SHADOW:
            case StaticFeatureType.HOLE_SHADOW:
            case StaticFeatureType.TAP_SHADOW:
                return [OpenlayerStyleFactory.createFeatureShadow(feature, resolution)];
            case StaticFeatureType.CABLE_SHADOW:
                return OpenlayerStyleFactory.createCableShadow(feature, resolution);
            case StaticFeatureType.INFRASTRUCTURE_ID:
                return [OpenlayerStyleFactory.createInfrastructureId(feature, resolution)];
            case StaticFeatureType.BUILD_CALLOUT:
                return [OpenlayerStyleFactory.createBuildCallout(feature, resolution)];
            case StaticFeatureType.CABINET_CALLOUT:
                return [OpenlayerStyleFactory.createCabinetCallout(feature, resolution)];
            case StaticFeatureType.BUILD_ID:
                return [OpenlayerStyleFactory.createBuildId(feature, resolution)];
            default:
                console.warn('Unsupported feature type for the style function:', type);
                return [new Style()];
        }
    }

    public static createSelectedLayerStyleFunction(feature: FeatureLike, resolution: number): Style[] {
        const { type, isCluster } = OpenlayerUtility.getFeatureDefinition(feature);
        const allowedStaticFeatures: FeatureType[] = [StaticFeatureType.PORT_NUMBER, StaticFeatureType.UPLOADED_BUILD, StaticFeatureType.APPROVED_BUILD, StaticFeatureType.IN_REVIEW_BUILD, StaticFeatureType.LOCK_OUTLINE];
        if (type in StaticFeatureType && !allowedStaticFeatures.includes(type)) {
            throw new Error('Selecting static feature');
        }
        switch (type) {
            case StaticFeatureType.DROP_CABLE:
                return OpenlayerStyleFactory.createDropcable();
            case StaticFeatureType.GEOPROJECT:
                return [OpenlayerStyleFactory.createPolygon(1, 'orange', 'rgba(255, 255, 255, 1')];
            case InteractableFeatureType.POLE:
                return [OpenlayerStyleFactory.createPole(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.FLEXNAP_SEGMENT:
                return OpenlayerStyleFactory.createBuildSegment(feature, resolution, MODE.SELECTED);
            case InteractableFeatureType.SCHRODINGER_SEGMENT:
                return OpenlayerStyleFactory.createSchrodingerSegment(feature, resolution, MODE.SELECTED);
            case InteractableFeatureType.BULK_SEGMENT:
                return OpenlayerStyleFactory.createBuildSegment(feature, resolution, MODE.SELECTED);
            case InteractableFeatureType.NAP:
                return [OpenlayerStyleFactory.createNAP(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.TAP:
                return [OpenlayerStyleFactory.createTap(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.SPLICE_POINT:
                return [OpenlayerStyleFactory.createSplicePoint(feature, resolution, MODE.SELECTED)];
            case StaticFeatureType.PORT_NUMBER:
                return [OpenlayerStyleFactory.createPortNumber(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.TERMINAL:
                return OpenlayerStyleFactory.createTerminal(feature, resolution, MODE.SELECTED);
            case InteractableFeatureType.TERMINAL_SEGMENT:
                return OpenlayerStyleFactory.createTerminalSegment(feature, resolution, MODE.SELECTED);
            case InteractableFeatureType.PARCEL:
                return [OpenlayerStyleFactory.createParcel(feature, resolution, isCluster, MODE.SELECTED)];
            case InteractableFeatureType.MANHOLE:
                return [OpenlayerStyleFactory.createManhole(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.HANDHOLE:
                return [OpenlayerStyleFactory.createHandhole(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.VAULT:
                return [OpenlayerStyleFactory.createVault(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.CABINET:
                return [OpenlayerStyleFactory.createCabinet(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.TRENCH:
            case InteractableFeatureType.BORE:
                return OpenlayerStyleFactory.createPath(feature, resolution, type, MODE.SELECTED);
            case InteractableFeatureType.TAP_SCHRODINGER:
                return [OpenlayerStyleFactory.createSchrodingerTap(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.POWER_SCHRODINGER:
                return [OpenlayerStyleFactory.createTapPower(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.POWER_LABEL_SCHRODINGER:
                return [OpenlayerStyleFactory.createTapPowerLabel(feature, resolution)];
            case InteractableFeatureType.OPTICAL_SCHRODINGER:
                return [OpenlayerStyleFactory.createTapOptical(feature, resolution, MODE.SELECTED)];
            case InteractableFeatureType.LOCKED_BUILD:
            case StaticFeatureType.UPLOADED_BUILD:
            case StaticFeatureType.APPROVED_BUILD:
            case InteractableFeatureType.REJECTED_BUILD:
            case StaticFeatureType.IN_REVIEW_BUILD:
            case InteractableFeatureType.EXPORTED_BUILD:
                return [OpenlayerStyleFactory.createStatusIcon(feature, resolution, type)];
            case StaticFeatureType.LOCK_OUTLINE:
                return [OpenlayerStyleFactory.createLockOutline(feature, resolution)];
            case InteractableFeatureType.DESIGN_AREA:
                return OpenlayerStyleFactory.createDesignArea(feature, resolution, MODE.SELECTED);
            case StaticFeatureType.CIRCLE_SHADOW:
                return [OpenlayerStyleFactory.createFeatureShadow(feature, resolution)];
            case StaticFeatureType.CABLE_SHADOW:
                return OpenlayerStyleFactory.createCableShadow(feature, resolution);
            case StaticFeatureType.INFRASTRUCTURE_ID:
                return [OpenlayerStyleFactory.createInfrastructureId(feature, resolution)];
            case StaticFeatureType.BUILD_CALLOUT:
                return [OpenlayerStyleFactory.createBuildCallout(feature, resolution)];
            case StaticFeatureType.CABINET_CALLOUT:
                return [OpenlayerStyleFactory.createCabinetCallout(feature, resolution)];
            default:
                console.warn('Unsupported feature type for the style function:', type);
                return [new Style()];
        }
    }

    private static createPole(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        let svgDef = this.getSvg(InteractableFeatureType.POLE, resolution);
        if (!svgDef) {
            return new Style();
        }
        const powerStatus: PowerStatus = feature.get('powerStatus') || PowerStatus.None;
        if (powerStatus !== PowerStatus.None && resolution < RESOLUTION_CLOSE) {
            svgDef = Svg.POLE_POWERED;
        }
        const isImported = !!feature.get('isImported');
        const dragging = !!feature.get('dragging');
        const ghost = !!feature.get('ghost');
        let stroke: string;
        let fill: string;
        if (dragging) {
            mode = MODE.SELECTED;
        }
        else if (ghost) {
            mode = MODE.GHOST;
        }
        switch (mode) {
            case MODE.HOVERED:
                stroke = COLOR_CHARCOALSTROKE;
                fill = COLOR_POLEYELLOW;
                break;
            case MODE.SELECTED:
                stroke = COLOR_SELECTEDSTROKE;
                fill = COLOR_SELECTEDFILL;
                break;
            case MODE.GHOST:
                stroke = COLOR_DISABLEDTEXT;
                fill = COLOR_NONE;
                break;
            default:
                stroke = COLOR_CHARCOALSTROKE;
                fill = isImported ? COLOR_POLEYELLOW : COLOR_USERCREATED;
                break;
        }
        const svg = svgDef.get('', stroke, fill);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createNAP(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.NAP, resolution);
        if (!svgDef) {
            return new Style();
        }
        const text = feature.get('text');
        const isConnected = !!feature.get('buildId');

        let fill: string;
        switch (mode) {
            case MODE.HOVERED: fill = '#165A76'; break;
            case MODE.SELECTED: fill = SELECTED_COLOR; break;
            default: fill = isConnected ? '#000' : '#f5770a'; break;
        }
        if (feature.get('dragging')) {
            fill = SELECTED_COLOR;
        }
        const svg = svgDef.get(text, 'none', fill, COLOR_ON_SECONDARY);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createTap(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.TAP, resolution);
        if (!svgDef) {
            return new Style();
        }
        const text = feature.get('text');
        const left = !!feature.get('left');
        const anchorX = left ? 1.5 : -0.5;

        let stroke: string;
        let fill: string;
        let textColor;
        switch (mode) {
            case MODE.HOVERED: stroke = '#404040'; fill = '#EFEDEB'; break;
            case MODE.SELECTED: stroke = COLOR_SELECTEDSTROKE; fill = COLOR_SELECTEDFILL; textColor = COLOR_SELECTEDSTROKE; break;
            default: stroke = '#404040'; fill = '#EFEDEB'; textColor = '404040'; break;
        }
        const anchor = [anchorX, 0.5];
        const svg = svgDef.get(text, stroke, fill, textColor);
        
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createSplicePoint(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.SPLICE_POINT, resolution);
        if (!svgDef) {
            return new Style();
        }

        const isConnected = !!feature.get('buildId');

        let fill: string;
        switch (mode) {
            case MODE.HOVERED: fill = '#165A67'; break;
            case MODE.SELECTED: fill = SELECTED_COLOR; break;
            default: fill = isConnected ? '#000' : '#f5770a'; break;
        }
        if (feature.get('dragging')) {
            fill = SELECTED_COLOR;
        }

        const svg = svgDef.get('', 'none', fill, COLOR_ON_SECONDARY);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createPortNumber(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const portNumbers = feature.get('text');
        const portStartWithDash = portNumbers.indexOf('-') + 1;
        const isWrapped = portStartWithDash >= PORT_NUMBER_WRAPPED_LENGTH;
        let svgType, portStart, portEnd, height;
        if (isWrapped) {
            svgType = StaticFeatureType.WRAPPED_PORT_NUMBER;
            portStart = portNumbers.substring(0, portStartWithDash);
            portEnd = portNumbers.substring(portStartWithDash);
            height = PORT_NUMBER_MAX_HEIGHT;
        } else {
            svgType = StaticFeatureType.PORT_NUMBER;
            portStart = portNumbers;
            height = PORT_NUMBER_MIN_HEIGHT;
        }
        const svgDef = this.getSvg(svgType, resolution + PORT_NUMBER_ADJUSTED_RESOLUTION);
        if (!svgDef) {
            return new Style();
        }
        const width = portStart.length < PORT_NUMBER_WRAPPED_LENGTH ? (PORT_NUMBER_MIN_WIDTH + PORT_NUMBER_PADDING) : (portStart.length * PORT_NUMBER_ONE_DIGIT_WIDTH + PORT_NUMBER_PADDING);
        const fiberCount = feature.get('fiberCount');
        const cableColorHex = feature.get('cableColorHex');
        let { enabled: backgroundColor } = OpenlayerStyleFactory.getCableColor(fiberCount, cableColorHex);
        if (mode === MODE.SELECTED) {
            backgroundColor = SELECTED_COLOR;
        }
        const anchor = [0.5, -1.5];
        const color = { 36: COLOR_ON_PRIMARY, 96: COLOR_ON_PRIMARY, 216: COLOR_ON_PRIMARY, default: COLOR_ON_SECONDARY, }
        const textColor = color[fiberCount] || color.default;
        const svg = svgDef.getPortNumber(portStart, 'none', backgroundColor, textColor, width, height, portEnd);
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createTerminal(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style[] {
        const svgDef = this.getSvg(InteractableFeatureType.TERMINAL, resolution);
        if (!svgDef) {
            return [];
        }

        const dragging = !!feature.get('dragging');

        const text = feature.get('text');
        const top = !!feature.get('top');
        const middle = !!feature.get('middle');
        const left = !!feature.get('left');
        const anchorX = middle || dragging ? 0.50 : left ? 1.25 : -0.25;
        const anchorY = middle || dragging ? 0.50 : top ? 1.25 : -0.25;

        let stroke: string;
        let fill: string;
        const extensionType = feature.get('extensionType');

        const lineWidth = resolution < RESOLUTION_FAR ? 2 : 1;

        if (feature?.getGeometry()?.getType() === GeometryType.LINE_STRING) {
            return [...this.createLine(lineWidth, extensionType === TerminalExtensionType.TetherExtender ? COLOR_TETHER_EXTENDER : COLOR_LONGTAIL_TERMINAL_SEGMENT)];
        }

        let textColor;
        if (dragging) {
            mode = MODE.SELECTED;
        }
        switch (mode) {
            case MODE.HOVERED: stroke = '#80C4E0'; fill = '#1CB5CE'; break;
            case MODE.SELECTED: stroke = COLOR_SELECTEDSTROKE; fill = COLOR_SELECTEDFILL; textColor = COLOR_SELECTEDSTROKE; break;
            default: stroke = '#D6D6D6'; fill = COLOR_OPTICAL; textColor = COLOR_CHARCOALSTROKE; break;
        }
        const anchor = [anchorX, anchorY];

        let childSvg = '';
        const extensionSvgDef = this.getSvg(StaticFeatureType.TERMINAL_EXTENSION, resolution);
        if (extensionSvgDef) {
            switch (extensionType) {
                case TerminalExtensionType.LongTail:
                    childSvg = extensionSvgDef.get('L', 'none', COLOR_LONGTAIL_TERMINAL_ICON, "#404040");
                    break;
                case TerminalExtensionType.TetherExtender:
                    childSvg = extensionSvgDef.get('Ex', 'none', COLOR_TETHER_EXTENDER, "#404040");
                    break;
            }
        }

        const svg = svgDef.get(text, stroke, fill, textColor, childSvg);
        return [OpenlayerStyleFactory.styleFromSvg(svg, anchor)];
    }

    private static createTerminalSegment(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style[] {
        const terminalStyles: Style[] = [];

        const lineWidth = resolution < RESOLUTION_FAR ? 2 : 1;
        const dash = mode === MODE.DEFAULT ? [4, 10] : [];
        terminalStyles.push(...this.createLine(lineWidth, feature.get('extensionType') === TerminalExtensionType.LongTail ? COLOR_LONGTAIL_TERMINAL_SEGMENT : COLOR_TETHER_EXTENDER, undefined, dash));

        return terminalStyles;
    }

    private static createBuildSegment(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style[] {
        const cableColor: FiberColors = OpenlayerStyleFactory.getCableColor(feature.get('fiberCount'), feature.get('cableColorHex'));
        const segmentWidth = resolution < RESOLUTION_FAR ? 4 : 2;
        let color: string;
        switch (mode) {
            case MODE.HOVERED: color = cableColor.hover; break;
            case MODE.SELECTED: color = SELECTED_COLOR; break;
            default: color = cableColor.enabled; break;
        }
        return this.createSegment(feature, resolution, segmentWidth, color);
    }

    private static createSchrodingerSegment(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style[] {
        const isConfigured = !!feature.get('isConfigured');
        const cableColorHex = feature.get('cableColorHex');
        const defaultCableColor = '#202020';
        const cableColor = cableColorHex ? cableColorHex : defaultCableColor;
        const segmentWidth = resolution < RESOLUTION_FAR ? 6 : 3;
        const dash = isConfigured ? undefined : [1, 16];
        let color: string;
        switch (mode) {
            case MODE.SELECTED: color = SELECTED_COLOR; break;
            default: color = cableColor; break;
        }
        return this.createSegment(feature, resolution, segmentWidth, color, dash);
    }

    private static createSegment(feature: FeatureLike, resolution: number, width: number, color: string, dash?: number[]): Style[] {
        const segmentStyles: Style[] = [];
        segmentStyles.push(...OpenlayerStyleFactory.createLine(width, color, undefined, dash));
        const slackLoop = feature.get('text') || '0';
        if (!(slackLoop === '0' || resolution > RESOLUTION_CLOSE)) {
            const adjustedSegmentFraction = 0.25 * (resolution + 0.4);
            const point = (feature.getGeometry() as LineString).getCoordinateAt(adjustedSegmentFraction);
            segmentStyles.push(OpenlayerStyleFactory.createSlackLoop(slackLoop, color, point));
        }
        if (resolution <= RESOLUTION_CLOSE) {
            segmentStyles.push(...OpenlayerStyleFactory.createSegmentEndpoints(feature, resolution, color, width, !!dash));
        }
        return segmentStyles;
    }

    private static createDropcable(): Style[] {
        const color = 'black';
        const width = 2;
        const style = OpenlayerStyleFactory.createLine(width, color);
        return style;
    }

    private static createSlackLoop(text: string, color: string, point: number[]): Style {
        const svg = Svg.SLACK_LOOP.get(text, color);
        return OpenlayerStyleFactory.pointStyleFromSvg(svg, point);
    }

    private static createSegmentEndpoints(feature: FeatureLike, resolution: number, color: string, width: number, dashed?: boolean): Style[] {
        const styles: Style[] = [];
        const cableStart = !!feature.get('cablestart');
        const cableEnd = !!feature.get('cableend');
        const featureType = feature.get('type');
        
        if (cableStart || cableEnd) {
            const lineString = feature.getGeometry() as LineString;
            const coordinates = lineString.getCoordinates();
            if (cableStart) {
                const firstSegmentStart = coordinates[0];
                const firstSegmentEnd = coordinates[1];
                let dx = firstSegmentEnd[0] - firstSegmentStart[0];
                let dy = firstSegmentEnd[1] - firstSegmentStart[1];
                const isPretermLateral = feature.get('isPretermLateral');
                if (isPretermLateral) {
                    // reverse the direction of the slope to go towards the 
                    // inside of the segment and draw the arrow towards start pole
                    dx = -dx;
                    dy = -dy;
                }
                const slopeAngle = Math.atan2(dy, dx);

                const isChildSplice = feature.get('isChildSplice');

                let startDefinition: Svg.SvgDefinition | undefined = Svg.CABLE_START;

                if (featureType === InteractableFeatureType.BULK_SEGMENT) {
                    startDefinition = feature.get('startsAtCabinet') ? Svg.BULK_CABLE_START : undefined;
                } else if (isPretermLateral) {
                    startDefinition = Svg.CABLE_PRETERM_START;
                } else if (isChildSplice) {
                    startDefinition = undefined;
                } else if (dashed) {
                    startDefinition = Svg.CABLE_START_DASHED;
                }

                if (startDefinition) {
                    styles.push(OpenlayerStyleFactory.createCableStart(color, firstSegmentStart, -slopeAngle, width, startDefinition));
                }
            }
            if (cableEnd && featureType !== InteractableFeatureType.BULK_SEGMENT) {
                const lastSegmentStart = coordinates[coordinates.length - 2];
                const lastSegmentEnd = coordinates[coordinates.length - 1];
                const dx = lastSegmentEnd[0] - lastSegmentStart[0];
                const dy = lastSegmentEnd[1] - lastSegmentStart[1];
                const slopeAngle = Math.atan2(dy, dx);
                const endDefinition = dashed ? Svg.CABLE_END_DASHED : Svg.CABLE_END;
                styles.push(OpenlayerStyleFactory.createCableEnd(color, lastSegmentEnd, -slopeAngle, width, endDefinition));
            } else if (cableEnd && featureType === InteractableFeatureType.BULK_SEGMENT) {
                const fieldSlack = feature.get('fieldSlack');
                if (fieldSlack && !(fieldSlack === 0 || resolution > RESOLUTION_CLOSE)) {
                    const adjustedSegmentFraction = 1 + (resolution / 2.5);
                    const point = (feature.getGeometry() as LineString).getCoordinateAt(adjustedSegmentFraction);
                    styles.push(OpenlayerStyleFactory.createSlackLoop(fieldSlack, color, point));
                }
            }
        }
        return styles;
    }

    private static createCableStart(color: string, point: number[], rotation: number, width: number, svgDefinition: Svg.SvgDefinition): Style {
        const svg = svgDefinition.getSegmentEndpoint(color, color, width);
        return OpenlayerStyleFactory.pointStyleFromSvg(svg, point, [1, 0.5], rotation, true);
    }

    private static createCableEnd(color: string, point: number[], rotation: number, width: number, svgDefinition: Svg.SvgDefinition): Style {
        const svg = svgDefinition.getSegmentEndpoint(color, color, width);
        return OpenlayerStyleFactory.pointStyleFromSvg(svg, point, [0, 0.5], rotation, true);
    }

    private static createParcel(feature: FeatureLike, resolution: number, isCluster: boolean, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.PARCEL, resolution);
        if (!svgDef) {
            return new Style();
        }

        let units: number;
        let isImported: boolean;
        if (isCluster) {
            const features = feature.get('features');
            units = features.reduce((prev: number, curr: Feature) => prev + curr.get('units'), 0);
            isImported = features.reduce((prev: boolean, curr: Feature) => prev && curr.get('isImported'), true);
        }
        else {
            units = feature.get('units');
            isImported = !!feature.get('isImported');
        }

        const parcelOrange = '#FFAA31';
        let stroke: string;
        let fill: string;
        switch (mode) {
            case MODE.SELECTED: stroke = COLOR_SELECTEDSTROKE; fill = COLOR_GRAYFILL; break;
            default: stroke = parcelOrange; fill = COLOR_GRAYFILL; break;
        }
        if (resolution > RESOLUTION_CLOSE) {
            fill = stroke;
            stroke = 'none';
        }
        if (!isImported) {
            fill = COLOR_USERCREATED;
        }

        const svg = svgDef.get(units.toString(), stroke, fill);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createCabinet(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        let svgDef = this.getSvg(InteractableFeatureType.CABINET, resolution);
        const powerStatus: PowerStatus = feature.get('powerStatus') || PowerStatus.None;
        if (powerStatus !== PowerStatus.None && resolution < RESOLUTION_CLOSE) {
            svgDef = Svg.CABINET_POWERED;
        }
        if (!svgDef) {
            return new Style();
        }
        const isImported = !!feature.get('isImported');
        const dragging = !!feature.get('dragging');
        const ghost = !!feature.get('ghost');
        let stroke: string;
        let fill: string;
        let textColor: string;
        if (dragging) {
            mode = MODE.SELECTED;
        }
        else if (ghost) {
            mode = MODE.GHOST;
        }
        switch (mode) {
            case MODE.SELECTED:
                stroke = COLOR_SELECTEDSTROKE;
                fill = COLOR_SELECTEDFILL;
                textColor = COLOR_SELECTEDSTROKE;
                break;
            case MODE.GHOST:
                stroke = COLOR_DISABLEDTEXT;
                fill = COLOR_NONE;
                textColor = COLOR_DISABLEDTEXT;
                break;
            default:
                stroke = COLOR_CHARCOALSTROKE;
                fill = isImported ? '#0CD67F' : COLOR_USERCREATED;
                textColor = COLOR_CHARCOALSTROKE;
                break;
        }
        const svg = svgDef.get('C', stroke, fill, textColor);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createManhole(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        let svgDef = this.getSvg(InteractableFeatureType.MANHOLE, resolution);
        const powerStatus: PowerStatus = feature.get('powerStatus') || PowerStatus.None;
        if (powerStatus !== PowerStatus.None && resolution < RESOLUTION_CLOSE) {
            svgDef = Svg.MANHOLE_POWERED;
        }
        if (!svgDef) {
            return new Style();
        }
        const isImported = !!feature.get('isImported');
        const dragging = !!feature.get('dragging');
        const ghost = !!feature.get('ghost');
        let stroke: string;
        let fill: string;
        if (dragging) {
            mode = MODE.SELECTED;
        }
        else if (ghost) {
            mode = MODE.GHOST;
        }
        switch (mode) {
            case MODE.SELECTED:
                stroke = COLOR_SELECTEDSTROKE;
                fill = COLOR_SELECTEDFILL;
                break;
            case MODE.GHOST:
                stroke = COLOR_DISABLEDTEXT;
                fill = COLOR_NONE;
                break;
            default:
                stroke = COLOR_CHARCOALSTROKE;
                fill = isImported ? COLOR_IMPORTED : COLOR_USERCREATED;
                break;
        }
        const svg = svgDef.get('', stroke, fill);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createHandhole(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        let svgDef = this.getSvg(InteractableFeatureType.HANDHOLE, resolution);
        const powerStatus: PowerStatus = feature.get('powerStatus') || PowerStatus.None;
        if (powerStatus !== PowerStatus.None && resolution < RESOLUTION_CLOSE) {
            svgDef = Svg.HANDHOLE_POWERED;
        }
        if (!svgDef) {
            return new Style();
        }
        const isImported = !!feature.get('isImported');
        const dragging = !!feature.get('dragging');
        const ghost = !!feature.get('ghost');
        let stroke: string;
        let fill: string;
        if (dragging) {
            mode = MODE.SELECTED;
        }
        else if (ghost) {
            mode = MODE.GHOST;
        }
        switch (mode) {
            case MODE.SELECTED:
                stroke = COLOR_SELECTEDSTROKE;
                fill = COLOR_SELECTEDFILL;
                break;
            case MODE.GHOST:
                stroke = COLOR_DISABLEDTEXT;
                fill = COLOR_NONE;
                break;
            default:
                stroke = COLOR_CHARCOALSTROKE;
                fill = isImported ? COLOR_IMPORTED : COLOR_USERCREATED;
                break;
        }
        const svg = svgDef.get('', stroke, fill);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createVault(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        let svgDef = this.getSvg(InteractableFeatureType.VAULT, resolution);
        const powerStatus: PowerStatus = feature.get('powerStatus') || PowerStatus.None;
        if (powerStatus !== PowerStatus.None && resolution < RESOLUTION_CLOSE) {
            svgDef = Svg.VAULT_POWERED;
        }
        if (!svgDef) {
            return new Style();
        }
        const isImported = !!feature.get('isImported');
        const dragging = !!feature.get('dragging');
        const ghost = !!feature.get('ghost');
        let stroke: string;
        let fill: string;
        if (dragging) {
            mode = MODE.SELECTED;
        }
        else if (ghost) {
            mode = MODE.GHOST;
        }
        switch (mode) {
            case MODE.SELECTED:
                stroke = COLOR_SELECTEDSTROKE;
                fill = COLOR_SELECTEDFILL;
                break;
            case MODE.GHOST:
                stroke = COLOR_DISABLEDTEXT;
                fill = COLOR_NONE;
                break;
            default:
                stroke = COLOR_CHARCOALSTROKE;
                fill = isImported ? COLOR_IMPORTED : COLOR_USERCREATED;
                break;
        }
        const svg = svgDef.get('', stroke, fill);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createPath(feature: FeatureLike, resolution: number, type: InteractableFeatureType, mode: MODE = MODE.DEFAULT): Style[] {
        if (resolution > RESOLUTION_CLOSE) {
            return [];
        }

        const fill = mode === MODE.SELECTED ? 'rgba(84,47,175,0.7)' : 'rgba(84, 47, 15, 0.4)';
        const stroke = 'rgb(102, 234, 209)';
        const lineDash = type === InteractableFeatureType.BORE ? [4, 4] : undefined;
        const thickness = 14;
        const borderThickness = 2;
        const geom = feature.getGeometry() as LineString;
        return this.createBorderedPathLineStringStyle(geom, resolution, fill, stroke, lineDash, borderThickness, thickness);
    }

    private static createBorderedPathLineStringStyle(geom: LineString, resolution: number, fillColor: string, strokeColor: string, borderLineDash: number[] | undefined, borderThickness: number, pathThickness: number): Style[] {
        // draw the fill first so stroke overlaps it
        pathThickness += borderThickness * 2;
        const colors = [fillColor, strokeColor, strokeColor];
        const dashes = [undefined, borderLineDash, borderLineDash];
        const widths = [pathThickness, borderThickness, borderThickness];
        const borderDist = (pathThickness + borderThickness) / 2;
        const dists = [0, -1 * borderDist + borderThickness, borderDist - borderThickness];
        const styles: Style[] = [];
        for (let line = 0; line < colors.length; line++) {
            const width = widths[line];
            const dist = dists[line] * resolution;
            const dash = dashes[line];
            const coords: number[][] = [];
            let counter = 0;
            geom.forEachSegment((from: number[], to: number[]) => {
                const angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
                const newFrom = [
                    Math.sin(angle) * dist + from[0],
                    -Math.cos(angle) * dist + from[1],
                ];
                const newTo = [
                    Math.sin(angle) * dist + to[0],
                    -Math.cos(angle) * dist + to[1],
                ];
                coords.push(newFrom);
                coords.push(newTo);
                if (coords.length > 2) {
                    const intersection = math.intersect(coords[counter], coords[counter + 1], coords[counter + 2], coords[counter + 3]) as number[];
                    coords[counter + 1] = (intersection) ? intersection : coords[counter + 1];
                    coords[counter + 2] = (intersection) ? intersection : coords[counter + 2];
                    counter += 2;
                }
            });
            styles.push(
                new Style({
                    geometry: new LineString(coords),
                    stroke: new Stroke({
                        color: colors[line],
                        width,
                        lineDash: dash ? dash : undefined,
                        lineCap: 'butt',
                        lineJoin: 'miter',
                    }),
                }),
            );
        }
        return styles;
    }

    private static createSchrodingerTap(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.TAP_SCHRODINGER, resolution);
        if (!svgDef) {
            return new Style();
        }
        const tetherCount = feature.get('tetherCount');
        const isConnected = !!feature.get('buildId');

        let fill: string;
        switch (mode) {
            case MODE.HOVERED: fill = '#165A76'; break;
            case MODE.SELECTED: fill = SELECTED_COLOR; break;
            default: fill = isConnected ? '#000' : '#f5770a'; break;
        }
        if (feature.get('dragging')) {
            fill = SELECTED_COLOR;
        }
        const svg = svgDef.get(`${tetherCount}`, 'none', fill, COLOR_ON_SECONDARY)
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createTapPower(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.POWER_SCHRODINGER, resolution);
        if (!svgDef) {
            return new Style();
        }
        const hasEnoughPower = !!feature.get('hasEnoughPower');

        const anchorX = -0.3;
        const anchorY = 1.2;
        const text = feature.get('text');
        let fill: string;
        let textColor: string;
        let strokeColor: string;
        if (mode === MODE.SELECTED) {
            fill = COLOR_SELECTEDSTROKE;
            textColor = COLOR_ON_SECONDARY;
            strokeColor = 'none';
        }
        else if (hasEnoughPower) {
            fill = COLOR_POWER;
            textColor = COLOR_CHARCOALSTROKE;
            strokeColor = COLOR_CHARCOALSTROKE;
        }
        else {
            fill = COLOR_ERRORFILL;
            textColor = COLOR_ERRORTEXT;
            strokeColor = 'none';
        }
        const anchor = [anchorX, anchorY];
        const svg = svgDef.getSelected(text, strokeColor, fill, textColor, mode === MODE.SELECTED);
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createTapPowerLabel(feature: FeatureLike, resolution: number): Style {
        const svgDef = this.getSvg(InteractableFeatureType.POWER_LABEL_SCHRODINGER, resolution);
        if (!svgDef) {
            return new Style();
        }
        const text = feature.get('text');
        const anchor = [-0.5, 0.5];
        const svg = svgDef.get(text);
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createTransformer(mode: MODE = MODE.DEFAULT): Style {
        const svgDef = Svg.TRANSFORMER;
        const anchorX = 0.5;
        const anchorY = -0.3;
        let fill: string;
        switch (mode) {
            case MODE.SELECTED: fill = SELECTED_COLOR; break;
            default: fill = '#E0E0E048'; break;
        }
        const anchor = [anchorX, anchorY];
        const svg = svgDef.get('', 'none', fill);
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createTapOptical(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style {
        const svgDef = this.getSvg(InteractableFeatureType.OPTICAL_SCHRODINGER, resolution);
        if (!svgDef) {
            return new Style();
        }
        const isValid = !!feature.get('isValid');

        const anchorX = 1.3;
        const anchorY = 1.2;
        let fill: string;
        let textColor: string;
        let strokeColor: string;
        const text = feature.get('text');
        if (mode === MODE.SELECTED) {
            fill = COLOR_SELECTEDSTROKE;
            textColor = COLOR_ON_SECONDARY;
            strokeColor = 'none';
        }
        else if (isValid) {
            fill = COLOR_OPTICAL;
            textColor = COLOR_CHARCOALSTROKE;
            strokeColor = COLOR_CHARCOALSTROKE;
        }
        else {
            fill = COLOR_ERRORFILL;
            textColor = COLOR_ERRORTEXT;
            strokeColor = 'none';
        }
        const anchor = [anchorX, anchorY];
        const svg = svgDef.getSelected(text, strokeColor, fill, textColor, mode === MODE.SELECTED);
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createStatusIcon(feature: FeatureLike, resolution: number, type: FeatureType): Style {
        if (resolution > RESOLUTION_MEDIUM) {
            return new Style();
        }
        const svgDef = this.getSvg(type, resolution);
        if (!svgDef) {
            return new Style();
        }
        const svg = svgDef.get('', 'none', '#005293');
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createLockOutline(feature: FeatureLike, resolution: number): Style {
        if (resolution > RESOLUTION_MEDIUM) {
            return new Style();
        }
        return OpenlayerStyleFactory.createPolygon(2, '#9E9E9E', 'rgba(224, 224, 224, 0.5)', [4, 4]);
    }

    private static createDesignArea(feature: FeatureLike, resolution: number, mode: MODE = MODE.DEFAULT): Style[] {
        const svgDef = this.getSvg(InteractableFeatureType.DESIGN_AREA, resolution);
        const designAreaColor = feature.get('color');
        let color: string;
        let stroke: string;
        let fill: string | undefined;
        let dashes: number[] | undefined;
        switch (mode) {
            case MODE.SELECTED:
                color = SELECTED_COLOR;
                fill = `${SELECTED_COLOR}33`;
                stroke = SELECTED_COLOR;
                break;
            default:
                color = designAreaColor ?? '#ffca80';
                stroke = '#424242';
                dashes = [4, 10];
                break;
        }
        if (resolution > RESOLUTION_CLOSE) {
            fill = color + '33'; //add transparency
        }
        const styles: Style[] = [];
        if (resolution < RESOLUTION_MACRO) {
            styles.push(OpenlayerStyleFactory.createPolygon(4, color, fill, dashes));
        }
        if (resolution > RESOLUTION_MEDIUM && svgDef) {
            const iconFill = color;
            const midpoint = (feature.getGeometry() as Polygon).getFlatInteriorPoint();
            styles.push(OpenlayerStyleFactory.pointStyleFromSvg(svgDef.get('', stroke, iconFill), midpoint));
        }
        return styles;
    }

    private static createFeatureShadow(feature: FeatureLike, resolution: number): Style {
        const type = feature.get('type');
        const svgDef = this.getSvg(StaticFeatureType[type], resolution);
        if (!svgDef) {
            return new Style();
        }
        const svg = svgDef.getErrorShadow(48, 48);
        return OpenlayerStyleFactory.styleFromSvg(svg);
    }

    private static createCableShadow(feature: FeatureLike, resolution: number): Style[] {
        const segmentWidth = resolution < RESOLUTION_FAR ? 14 : 7;
        const fill = 'rgba(255, 0, 0, 0.66)';
        return OpenlayerStyleFactory.createLine(segmentWidth, fill, fill, undefined, 'round');
    }

    private static createInfrastructureId(feature: FeatureLike, resolution: number): Style {
        const svgDef = this.getSvg(StaticFeatureType.INFRASTRUCTURE_ID, resolution);
        if (resolution >= RESOLUTION_CLOSE || !svgDef) return new Style();

        const tag: string = feature.get('tag');
        if (!tag) {
            return new Style();
        }
        const elementType = feature.get('elementType') as ElementType;
        const svg = svgDef.get(tag.substring(0, Math.min(tag.length, MAX_ID_LENGTH)));
        const offsetY = elementType === ElementType.Pole ? 2 : elementType === ElementType.Manhole ? 2.4 : 2.2;
        const anchor = [0.5, offsetY];
        return OpenlayerStyleFactory.styleFromSvg(svg, anchor);
    }

    private static createBuildCallout(feature: FeatureLike, resolution: number): Style {
        const svgDef = this.getSvg(StaticFeatureType.BUILD_CALLOUT, resolution);
        if (resolution >= RESOLUTION_CLOSE || !svgDef) return new Style();
        
        const segment = feature.getGeometry() as LineString;
        const length = getLength(segment);
        // Don't render if the callout is longer/taller than the segment
        if (length / resolution < BUILD_CALLOUT_WIDTH_PADDED) return new Style();

        const fiberCount = +feature.get('fiberCount');
        const cableColorHex = feature.get('cableColorHex');
        const useAlt = !!feature.get('useAlt');
        const buildType = feature.get('buildType');
        const angle = +feature.get('angle');
        const connectedToCabinet = !!feature.get('connectedToCabinet');
        const { enabled: fillColor } = OpenlayerStyleFactory.getCableColor(fiberCount, cableColorHex);
        const callout = connectedToCabinet ? useAlt ? Svg.BUILD_CALLOUT_CABINET_ALT : Svg.BUILD_CALLOUT_CABINET : Svg.BUILD_CALLOUT_NO_CABINET;
        const height = connectedToCabinet ? 32 : 18;
        const svg = svgDef.getCallout({ text: `${fiberCount}f ${buildType}`, fillColor, child: callout.getRaw(), width: BUILD_CALLOUT_WIDTH, height });
        return OpenlayerStyleFactory.pointStyleFromSvg(svg, segment.getFlatMidpoint(), undefined, angle, true);
    }

    private static createCabinetCallout(feature: FeatureLike, resolution: number): Style {
        const svgDef = this.getSvg(StaticFeatureType.CABINET_CALLOUT, resolution);
        if (resolution >= RESOLUTION_CLOSE || !svgDef) return new Style();

        let portCount = +feature.get('portCount');
        portCount = isNaN(portCount) ? 0 : portCount;
        const svg = svgDef.get(`${portCount}f`);
        return OpenlayerStyleFactory.styleFromSvg(svg, [0.5, -0.4]);
    }

    private static createBuildId(feature: FeatureLike, resolution: number): Style {
        const buildIdTags: {id: string; fiberCount?: number; cableColorHex: string}[] = feature.get('buildIdTags');
        if (resolution >= RESOLUTION_CLOSE || !buildIdTags.length) return new Style();

        const containerSvgDef = Svg.BUILD_ID_CALLOUT_CONTAINER;
        const tagDefs: string[] = [];

        for (let i = 0; i < buildIdTags.length; i++) {
            const tag = buildIdTags[i];
            if (!tag) {
                continue;
            }
            
            const cableColor = OpenlayerStyleFactory.getCableColor(tag.fiberCount ?? 0, tag.cableColorHex);
            const tagSvgDef = this.getSvg(StaticFeatureType.BUILD_ID, resolution);

            const tagHeight = 12 + 28 * i;
            const svg = tagSvgDef?.getBuildIdTag(tag.id, cableColor.enabled, tagHeight.toString());
            if (svg) {
                tagDefs.push(svg);
            }
        }
        const rectHeight = 30 * tagDefs.length;
        const viewHeight = rectHeight * 1.25;
        const children = tagDefs.join('\n');
        const svgDef = containerSvgDef?.getBuildIdContainer(children, '133', viewHeight.toString(), '100', rectHeight.toString());
        return this.styleFromSvg(svgDef);
    }

    private static cachedStyles: {id: string, style: Style}[] = [];

    private static styleFromSvg(src: string, anchor: number[] = [0.5, 0.5]): Style {
        const id = `${src}-${anchor}`;
        const cachedStyle = this.cachedStyles.find(s => s.id === id);
        if (cachedStyle) return cachedStyle.style;

        const newStyle = {
            id,
            style: new Style({
                image: new Icon({
                    src,
                    anchor,
                }),
            })
        }
        this.cachedStyles.push(newStyle);
        return newStyle.style;
    }

    private static pointStyleFromSvg(src: string, point: number[], anchor: number[] = [0.5, 0.5], rotation?: number, rotateWithView?: boolean): Style {
        return new Style({
            geometry: new Point(point),
            image: new Icon({
                src,
                anchor,
                rotation,
                rotateWithView
            }),
        });
    }

    private static createPolygonFromFeatures(features: Feature[], strokeColor: string, strokeWidth: number, lineDash: number[]): Style {
        const points = features.map((f) => f.getGeometry()) as Point[];
        const pointNumbers = points.map((p) => p.getFlatCoordinates());
        const hull = OpenlayerUtility.convexHull(pointNumbers);
        const geometry = new Polygon([hull]);
        const stroke = new Stroke({
            color: strokeColor,
            width: strokeWidth,
            lineDash,
        });
        const style = new Style({
            geometry,
            stroke,
        });
        return style;
    }

    private static createPolygon(strokeWidth: number, color: string, fillColor?: string, dash?: number[]): Style {
        const stroke = new Stroke({
            color,
            width: strokeWidth,
            lineDash: dash,
            lineCap: 'butt',
        });
        const fill = !fillColor ? undefined : new Fill({
            color: fillColor,
        });
        const style = new Style({
            stroke,
            fill,
        });
        return style;
    }

    public static createCircleLine(width: number, stroke: string, fill: string): Style[] {
        return [
            new Style({
                stroke: new Stroke({
                    color: stroke,
                    width,
                }),
            }),
            new Style({
                stroke: new Stroke({
                    color: fill,
                    width: width - 2,
                }),
            }),
            new Style({
                image: new CircleStyle({
                    radius: width / 2,
                    fill: new Fill({
                        color: fill,
                    }),
                    stroke: new Stroke({
                        color: stroke,
                        width: 2,
                    }),
                }),
                zIndex: Infinity,
            }),
        ];
    }

    public static createCross(size: number, color: string): Style {
        return new Style({
            image: new RegularShape({
                stroke: new Stroke({ color, width: size / 5 }),
                points: 4,
                radius: size,
                radius2: 0,
                angle: 0,
            }),
        });
    }

    public static createLine(width: number, stroke: string, fill?: string, dash?: number[], lineCap: CanvasLineCap = 'square'): Style[] {
        fill = fill || stroke;
        return [new Style({
            stroke: new Stroke({
                color: stroke,
                width,
                lineDash: dash,
                lineCap,
            }),
        })];
    }

    public static getCableColor(fiberCount: number, cableColorHex?: string): FiberColors {
        if (cableColorHex) {
            return { enabled: cableColorHex, hover: cableColorHex };
        }
        switch (fiberCount) {
            case 12:
                return { enabled: '#0304e8', hover: '#333' };
            case 24:
                return { enabled: '#f98901', hover: '#86b492' };
            case 36:
                return { enabled: '#00f901', hover: '#333' };
            case 48:
                return { enabled: '#a83800', hover: '#5d828d' };
            case 60:
                return { enabled: '#656565', hover: '#333' };
            case 72:
                return { enabled: '#ff0000', hover: '#806c8d' };
            case 96:
                return { enabled: '#eeee00', hover: '#1ad2db' };
            case 144:
                return { enabled: '#8900fc', hover: '#4f6cd0' };
            case 192:
                return { enabled: '#f485ea', hover: '#333' };
            case 216:
                return { enabled: '#03e8e9', hover: '#333' };
            case 168:
            case 288:
                return { enabled: '#027ef2', hover: '#38c88d' };
            case 204:
            case 432:
                return { enabled: '#5302aa', hover: '#1a91e9' };
            default:
                return { enabled: '#333', hover: '#333' };
        }
    }

    private static getSvg(type: InteractableFeatureType | StaticFeatureType, resolution: number): Svg.SvgDefinition | null {
        const distanceIndex = OpenlayerUtility.getResolutionDistanceIndex(resolution);
        return descisionMatrix[type][distanceIndex];
    }
}
type DescisionMatrix = {
    [key in FeatureType]: Array<Svg.SvgDefinition | null>;
};
interface FiberColors {
    enabled: string;
    hover: string;
}
