import { TextButton } from '@orbit/button';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';


import { DateHelper } from '../../../helpers/date-helper';
import { LocalizationKeys } from '../../../locales/types';
import { TooltipDark } from '../../presets/preset/tooltip-dark';
import { ReviewListItemProps } from '../review-list.types';
import { usePendingReviewListItem } from './pending-review-list-item.hooks';

export const PendingReviewListItem = (props: ReviewListItemProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        buildId,
        submissionDateTime,
        submittedByName: name,
        submittedByEmail: email,
        submissionNotes,
        handleCancelReviewClick,
        handleShowBuildClick
    } = usePendingReviewListItem(props);

    return (
        <div className={classNames('item', 'request')}>
            <div className="title pending-tab">
                <div className='title-info'>
                    {t(LocalizationKeys.ReviewRequestTitle, {
                        buildId,
                        submissionDateTime: DateHelper.formatDateTimetoLocale(submissionDateTime),
                        interpolation: { escapeValue: false },
                    })}
                </div>
                <div>
                    <TooltipDark arrow={true} placement="bottom" title={t(LocalizationKeys.LoadBuild)?.toLowerCase()}>
                        <TextButton onClick={handleShowBuildClick}><span className="material-icons">search</span></TextButton>
                    </TooltipDark>
                </div>
            </div>
            <div className="submitter">{t(LocalizationKeys.ReviewRequestFrom, { name, email })}</div>
            <div className="message">{submissionNotes}</div>
            <div className="footer">
                <TextButton onClick={handleCancelReviewClick}>{t(LocalizationKeys.CancelReview)}</TextButton>
            </div>
        </div>
    );
};