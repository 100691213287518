import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { convertToPretermLateral } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { clearSelection } from '../../../redux/selection.state';

export const useConvertToPretermLateralDialog = () => {
    const workspace = useSelector((state: StateModel) => state);
    const { dialog } = workspace.overlay;
    const { selectedBuildId: pretermBuildId, selectedPretermNapId } = workspace.selection;
    const { naps } = workspace.taps;
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(dialog === DialogType.ConvertToPretermLateral);
    }, [dialog]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onCancel = useCallback(() => {
        onClose();
        dispatch(clearSelection());
    }, [dispatch, onClose]);

    const onConvert = useCallback(() => {
        const nap = naps.find((n) => n.id === selectedPretermNapId);
        if (pretermBuildId && nap && nap.buildId) {
            const parentNapId = nap.id;
            const parentBuildId = nap.buildId;
            convertToPretermLateral(pretermBuildId, parentNapId, parentBuildId)(dispatch);
        }
        onClose();
        dispatch(clearSelection());
    }, [dispatch, naps, pretermBuildId, selectedPretermNapId, onClose]);

    return { open, onClose, onCancel, onConvert, pretermBuildId: pretermBuildId ?? -1 };
}