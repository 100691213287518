import { PluggableMap } from 'ol';
import { Pixel } from 'ol/pixel';
import { store } from '../dna';
import { createBulkBuild } from '../redux/build.state';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { BaseCableTool } from './base-cable-tool';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class BulkCableTool extends BaseCableTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.BulkCable,
        name: 'Bulk',
        shortcut: 'c',
    };

    public static finishCable(elementIds: number[]): void {
        const state = store.getState();
        const workspaceId = state.workspace.currentWorkspace?.id;
        if (!workspaceId) {
            throw new Error('Can\'t create a build without a parent workspace');
        }
        elementIds = elementIds.filter((id, index) => elementIds.indexOf(id) === index);
        const segments: Array<{ position: number; fromId: number; toId: number }> = [];
        for (let i = 1; i < elementIds.length; i++) {
            const position = i - 1;
            const fromId = elementIds[i - 1];
            const toId = elementIds[i];
            segments.push({ position, fromId, toId });
        }
        if (segments.length !== 0) {
            createBulkBuild(workspaceId, segments)(store.dispatch);
        }
    }

    public findAccessPointAtPixel(map: PluggableMap, pixel: Pixel): number {
        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        const { bulkBuilds } = build;
        const selectedBuild = bulkBuilds.find((b) => b.id === selectedBuildId)!;

        if (selectedBuild) {
            const splicePointFeature = map.getFeaturesAtPixel(pixel).find(f => f.get('type') === InteractableFeatureType.SPLICE_POINT);
            if (splicePointFeature) {
                return splicePointFeature.get('id') as number;
            }
        }

        return super.findAccessPointAtPixel(map, pixel);
    }
}
