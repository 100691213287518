import * as rfc from 'rfc6902';

import { BuildSegment } from '../models/build-segment';
import { FlexNapBuild } from '../models/flexnap-build';
import { SecuredService } from './abstract-secured-v2.service';
import { AddBuildRequest } from './build.service';

export class FlexNapBuildService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/build/flexnap';

    public addFlexNapBuild(createFlexNapBuildRequest: AddBuildRequest): Promise<FlexNapBuildResult | undefined> {
        return this.post(`${this.baseUrl}`, createFlexNapBuildRequest);
    }

    public updateBuild(current: FlexNapBuild, updated: FlexNapBuild): Promise<FlexNapBuild | undefined> {
        const patch = rfc.createPatch(current, updated);
        return this.patch(`${this.baseUrl}/${current.id}`, patch);
    }

    public applyPreset(buildId: number, presetId: number): Promise<FlexNapBuild | undefined> {
        return this.post(`${this.baseUrl}/${buildId}/preset/${presetId}`);
    }

    public deleteBuild(buildId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${buildId}`);
    }

    public deleteBuilds(buildIds: number[]): Promise<void> {
        return this.post(this.baseUrl + '/delete', { buildIds });
    }

    public undoDeleteBuild(buildId: number): Promise<FlexNapBuildResult | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${buildId}`);
    }

    public undoDeleteBuilds(buildIds: number[]): Promise<FlexNapBuildsResult | undefined> {
        return this.post(`${this.baseUrl}/undo/delete`, { buildIds });
    }
}

export interface FlexNapBuildResult {
    build: FlexNapBuild;
    segments: BuildSegment[];
}

export interface FlexNapBuildsResult {
    builds: FlexNapBuild[];
    segments: BuildSegment[];
}