import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NapDiagramSvgProps } from '../nap-diagram.types';
import { selectTap, selectTerminal, selectTether } from "../../../redux/selection.state";
import { addTether, deleteTether } from "../../../redux/tap.state";
import { Tap } from '../../../models/tap';
import { Tether } from '../../../models/tether';
import { FlexNapBuild } from '../../../models/flexnap-build';

const isTetherConnectedToPretermBuild = (pretermBuilds: FlexNapBuild[] | undefined, tetherId: number): boolean | undefined => {
    return pretermBuilds?.flatMap(b => b.pretermLateral?.parentTetherIds).includes(tetherId);
}

export const useNapSvg = (props: NapDiagramSvgProps) => {
    const { nap, taps, tethers, selectedTetherId, selectedTerminalId, locationId, pretermBuilds, disabled } = props;

    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [addDisabled, setAddDisabled] = useState(false);

    const dispatch = useDispatch();
    const MAX_TETHER_LIST_LENGTH = 4;
    useEffect(() => {
        setDeleteDisabled(
            !tethers ||
            !tethers.some(t => !isTetherConnectedToPretermBuild(pretermBuilds, t.id))
        );
        setAddDisabled(tethers && tethers?.length === MAX_TETHER_LIST_LENGTH);
    }, [pretermBuilds, tethers]);

    const selectTetherCallback = useCallback((tetherId?: number) => {
        const tether = tethers.find((t) => t.id === tetherId);
        if (tether) {
            dispatch(selectTap(tether.tapId));
        }
        dispatch(selectTether(tetherId));
        dispatch(selectTerminal(tether?.terminal?.id));
    }, [dispatch, tethers]);

    const addTetherCallback = useCallback(() => {
        if (nap) {
            addTether(nap.id)(dispatch);
        }
        else {
            console.error('Cannot add tether without build or selected pole');
        }
    }, [dispatch, nap]);

    const getTether = useCallback((tapIndex: number, tetherIndex: number): Tether | undefined => {
        const tap = taps && taps.find((t) => t.tapIndex === tapIndex);
        return tap && tethers && tethers.find((t) => t.tapId === tap.id && t.tetherIndex === tetherIndex);
    }, [taps, tethers]);

    const getTetherPretermBuild = useCallback((tetherId: number): FlexNapBuild | undefined => {
        return pretermBuilds?.find(b => b.pretermLateral?.parentTetherIds.includes(tetherId));
    }, [pretermBuilds]);

    const deleteTetherCallback = useCallback(() => {
        const tap = taps.filter(t => tethers.some(th => th.tapId === t.id && !isTetherConnectedToPretermBuild(pretermBuilds, th.id))).sort((t1, t2) => t2.tapIndex - t1.tapIndex)[0];
        const tapIndex = tap.tapIndex;
        const tapTethers = tethers.filter((t) => t.tapId === tap?.id && !isTetherConnectedToPretermBuild(pretermBuilds, t.id))?.sort((t1, t2) => t2.tetherIndex - t1.tetherIndex);
        const tetherIndex = tapTethers && tapTethers.length > 0 ? tapTethers[0].tetherIndex : 2;
        const tether = getTether(tapIndex, tetherIndex);
        if (tether) {
            if (tether.terminal && selectedTerminalId === tether.terminal.id) {
                dispatch(selectTerminal());
            }
            if (selectedTetherId === tether.id) {
                dispatch(selectTether());
                dispatch(selectTap());
            }
            deleteTether(tether)(dispatch);
        }
        else {
            console.error('Cannot delete tether without tether');
        }
    }, [dispatch, selectedTerminalId, selectedTetherId, getTether, taps, tethers, pretermBuilds]);

    const getTap = useCallback((tapIndex: number): Tap | undefined => {
        return taps.find((t) => t.tapIndex === tapIndex);
    }, [taps]);

    return {
        selectedTetherId, getTap, getTether, getTetherPretermBuild,
        selectTether: selectTetherCallback, addTether: addTetherCallback, deleteTether: deleteTetherCallback,
        disabled, deleteDisabled, addDisabled, locationId
    };
};