import classNames from "classnames";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CabinetSplicePlanBuildInfo } from "../../../helpers/cabinet-splice-plan-helper";
import { LocalizationKeys } from "../../../locales/types";
import { BuildType } from "../../../models/build-type";
import { getSplicePlan } from "../../../redux/bulk/splice-plan.state";
import {
    PortMode,
    setCurrentBuildInManualPortEdit,
    updateCabinetSplicePlans,
} from "../../../redux/cabinet-splice-plan.state";
import { DialogType, showDialog } from "../../../redux/overlay.state";
import { selectedCabinetSelector } from "../../../selectors/elements.selectors";
import { bulkBuildsSelector, flexnapBuildsSelector, workspaceSelector } from "../../../selectors/root.selectors";

const getCableId = (buildId: number, buildType: BuildType, t: TFunction): string => {
    switch (buildType) {
        case BuildType.Bulk:
            return t(LocalizationKeys.BulkBuildNumber, { buildId: buildId.toString().padStart(4, "0") });
        default:
            return t(LocalizationKeys.BuildNumber, { buildId: buildId.toString().padStart(4, "0") });
    }
};

const getCableIdClassName = (depth: number): string | undefined => {
    if (depth === 0) return undefined;
    // Limit indenting to a certain depth.
    const indentLevel = Math.min(depth, 3);
    return `indent-${indentLevel}`;
};

const getBuildType = (buildType: BuildType, t: TFunction): string | undefined => {
    switch (buildType) {
        case BuildType.Bulk:
            return t(LocalizationKeys.Bulk);
        case BuildType.FlexNap:
            return t(LocalizationKeys.FlexNap);
        case BuildType.Schrodinger:
            return t(LocalizationKeys.Schrodinger);
        default:
            return undefined;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCabinetSplicePlanRow = (props: CabinetSplicePlanBuildInfo) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cabinet = useSelector(selectedCabinetSelector);
    const portMode = cabinet && !cabinet.automaticSplicePlanMode ? PortMode.Manual : PortMode.Automatic;
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);
    const builds = [...flexNapBuilds, ...bulkBuilds];

    const { currentWorkspace } = useSelector(workspaceSelector);

    const { build, depth, overlap } = props;
    const { buildId, type, lowPortNumber, portCount } = build;

    const cableId = getCableId(buildId, type, t);
    const cableIdClassName = getCableIdClassName(depth);
    const buildType = getBuildType(type, t);
    const fiberCount = builds.find((b) => b.id === buildId)?.fiberCount;
    const portRange = lowPortNumber
        ? t(LocalizationKeys.HyphenatedRange, { min: lowPortNumber, max: lowPortNumber + portCount - 1 })
        : t(LocalizationKeys.Unassigned);
    const portRangeClassName = classNames({
        overlap: overlap,
        "port-range": portMode === PortMode.Manual,
    });
    const showOrderArrows = portMode === PortMode.Automatic;

    const handlePortRangeClick = useCallback(() => {
        if (portMode === PortMode.Manual) {
            if (type === BuildType.Bulk) dispatch(getSplicePlan(build.buildId));
            dispatch(setCurrentBuildInManualPortEdit(build));
            dispatch(showDialog(DialogType.CabinetPortNumberManual));
        }
    }, [build, dispatch, portMode, type]);

    const onUpArrowClick = useCallback(() => {
        if (currentWorkspace?.id && buildId) {
            dispatch(updateCabinetSplicePlans(currentWorkspace?.id, buildId, 1));
        }
    }, [buildId, currentWorkspace, dispatch]);

    const onDownArrowClick = useCallback(() => {
        if (currentWorkspace?.id && buildId) {
            dispatch(updateCabinetSplicePlans(currentWorkspace?.id, buildId, 2));
        }
    }, [buildId, currentWorkspace, dispatch]);

    return {
        depth,
        cableId,
        cableIdClassName,
        buildType,
        fiberCount,
        portRange,
        portRangeClassName,
        showOrderArrows,
        handlePortRangeClick,
        onUpArrowClick,
        onDownArrowClick,
    };
};
