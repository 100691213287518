import { UserPreset, UserPresetData } from '../models/userpreset';
import { CableValues } from '../components/presets/fiber-count.component';
import { CpqCablePart } from '../models/cpqcablepart';
import { CpqTetherPart } from '../models/cpqtetherpart';
import { CpqBuildConfiguration } from '../models/cpqbuildconfiguration';
import { Units } from '../models/units';
import { PartHelper } from './part-helper';

export class PresetHelper {

    public static toDto(preset: UserPreset): UserPresetData {
        return {
            name: preset.name,
            coSlack: preset.coSlack,
            fieldSlack: preset.fieldSlack,
            slackUnit: preset.slackUnit,
            cablePartNumber: preset.cablePart,
            tetherPartNumber: preset.tetherPart,
            cableDescription: preset.cableDescription,
            tetherDescription: preset.tetherDescription,
            installationType: preset.installationType || '',
            isDefault: preset.isDefault,
        };
    }

    public static createFakePreset = (
        presetName: string,
        cableValues: CableValues,
        cablePart: string,
        tetherPart: string,
        units: Units,
        parts: CpqCablePart[],
        tetherParts: CpqTetherPart[],
        configurations: CpqBuildConfiguration[]): UserPreset | null => {
        const partNumber = cablePart.slice(12);
        const installationType = cableValues.installationType.description;
        const { part, tether, configuration } =
            PartHelper.getConfigurationWithParts(
                partNumber, tetherPart,
                +cableValues.fiberCount, +cableValues.tetherFiberCount,
                parts!, tetherParts!, configurations!,
                installationType);
        return {
            id: -1,
            name: presetName,
            coSlack: cableValues.coSlack,
            fieldSlack: cableValues.fieldSlack,
            slackUnit: units,
            isDefault: true,
            cablePart: part.part,
            tetherPart: tether.tether_Item,
            cableDescription: JSON.stringify(PartHelper.buildPartDescription(configuration)),
            tetherDescription: JSON.stringify(PartHelper.buildTetherPartDescription(tether)),
            installationType,
            userId: -1,
        };
    }
}
