import i18next from 'i18next';
import { BulkBuildData } from '../data/bulk-build-data';
import { ValidationResult } from '../validation-result';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { LocalizationKeys } from '../../locales/types';
import { ItemType } from '../item-type';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

const BULK_SLACK_MINIMUM_VALUE_FEET = 20;

export class SplicePointSlackValidator implements Validator<BulkBuildData> {
    public validate(build: BulkBuildData): ValidationResult[] | null {
        const warnings: ValidationResult[] = [];

        if (build.splicePoints) {
            build.splicePoints.forEach(splicePoint => {
                // If this is a mid-point Splice (not at the last location of the cable), check the Slack Loop of the following Build Segment
                const midSegment = build.segments.find(s => s.from.id === splicePoint.elementId);
                if (midSegment) {
                    const slackLoopFeet = midSegment.slackLoop.toUnit(Units.feet);
                    if (slackLoopFeet.value < BULK_SLACK_MINIMUM_VALUE_FEET) {
                        warnings.push({
                            buildId: build.id,
                            itemId: midSegment.id,
                            itemType: ItemType.Segment,
                            shortDescription: i18next.t(LocalizationKeys.SlackLoopInsufficient),
                            longDescription: i18next.t(LocalizationKeys.SlackLoopInsufficientLong, { value: midSegment.slackLoop.value, minimum: new DataValue(BULK_SLACK_MINIMUM_VALUE_FEET, midSegment.slackLoop.units).value }),
                            type: ValidationResultType.Warning,
                            extent: midSegment.getExtent()
                        });
                    }
                }
                // If this a tail Splice (at the last location of the cable), check the Field Slack of the Build
                const lastSegment = build.segments.length > 0 ? build.segments[build.segments.length - 1] : undefined;
                if (lastSegment && lastSegment.to.id === splicePoint.elementId) {
                    const fieldSlackFeet = build.fieldSlack.toUnit(Units.feet);
                    if (fieldSlackFeet.value < BULK_SLACK_MINIMUM_VALUE_FEET) {
                        warnings.push({
                            buildId: build.id,
                            itemId: null,
                            itemType: ItemType.Build,
                            shortDescription: i18next.t(LocalizationKeys.ErrorFieldSlackInsufficient),
                            longDescription: i18next.t(LocalizationKeys.ErrorFieldSlackInsufficientLong, { value: lastSegment.slackLoop.value, minimum: new DataValue(BULK_SLACK_MINIMUM_VALUE_FEET, lastSegment.slackLoop.units).value }),
                            type: ValidationResultType.Warning,
                            extent: lastSegment.getExtent()
                        });
                    }
                }
            });
        }

        return warnings;
    }

    public canValidate(entity: any): boolean {
        return entity instanceof BulkBuildData && !!(entity as BulkBuildData).splicePoints?.length;
    }
}