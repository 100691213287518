import React, { Component } from 'react';

import { BuildType } from '../../models/build-type';
import { BulkBuild } from '../../models/bulk/bulk-build';
import { FlexNapBuild } from '../../models/flexnap-build';
import { SchrodingerBuild } from '../../models/schrodinger-build';
import {
    updateBulkBuild, updateFlexNapBuild, updateSchrodingerBuild
} from '../../redux/build.state';
import { DialogType, showDialog } from '../../redux/overlay.state';
import { connect, StateModel } from '../../redux/reducers';
import { CardInput, CardInputWithCount } from '../card/card-controls';
import { CardInputMaxLength } from '../card/card.types';
import { DialogComponent } from './dialog.component';

interface BuildInformationState {
    cablename?: string;
    description?: string;
    streetname?: string;
    comments?: string;
    locked?: boolean;
}

const mapStateToProps = (state: StateModel) => {
    const { selectedBuildId } = state.selection;
    const { flexnapBuilds, schrodingerBuilds, bulkBuilds } = state.build;
    const { dialog } = state.overlay;
    const build = selectedBuildId ? [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds].find((b) => b.id === selectedBuildId) : undefined;
    return { build, dialog };
};

const mapDispatchToProps = {
    updateFlexNapBuild,
    updateSchrodingerBuild,
    updateBulkBuild,
    showDialog,
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BuildInformationDialog extends Component<props, BuildInformationState> {

    public state: BuildInformationState = {};

    public componentDidUpdate(prevProps: props) {
        const { dialog, build } = this.props;
        if (dialog !== prevProps.dialog && build) {
            this.setState({
                cablename: build.name || undefined,
                description: build.description || undefined,
                streetname: build.streetName || undefined,
                comments: build.comments || undefined,
                locked: !!build.lockedById,
            });
        }
    }

    private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;

        const maxLength = this.getMaxInputLength(name);
        if (maxLength && value.length <= maxLength) {
            this.setState({ [name]: value } as any as BuildInformationState);
        }
    }

    private getMaxInputLength = (name): number | undefined => {
        if (name === 'cablename')
            return CardInputMaxLength.CABLE_NAME;
        if (name === 'streetname')
            return CardInputMaxLength.BUILD_STREET_NAME;
        if (name === 'description')
            return CardInputMaxLength.BUILD_DESCRIPTION;
        if (name === 'comments')
            return CardInputMaxLength.BUILD_COMMENT;
    }
    private saveBuild = () => {
        const { build } = this.props;
        const { cablename, description, streetname, comments } = this.state;
        if (!build) {
            throw new Error('BuildInformationDialog.saveBuild, changing value for non-existent cable');
        }

        switch (build.type) {
            case BuildType.FlexNap: {
                const flexnapBuild = build as FlexNapBuild;
                this.props.updateFlexNapBuild!(flexnapBuild, { ...flexnapBuild, name: cablename || '', description: description || '', streetName: streetname || '', comments: comments || '' });
                break;
            }
            case BuildType.Schrodinger: {
                const schrodingerBuild = build as SchrodingerBuild;
                this.props.updateSchrodingerBuild!(schrodingerBuild, { ...schrodingerBuild, name: cablename || '', description: description || '', streetName: streetname || '', comments: comments || '' });
                break;
            }
            case BuildType.Bulk: {
                const bulkBuild = build as BulkBuild;
                this.props.updateBulkBuild!(bulkBuild, { ...bulkBuild, name: cablename || '', description: description || '', streetName: streetname || '', comments: comments || '' });
                break;
            }
        }

        this.props.showDialog!();
    }

    private cancel = () => {
        this.props.showDialog!();
    }

    public render() {
        const { cablename, description, streetname, comments, locked } = this.state;
        const { dialog } = this.props;
        return (
            <DialogComponent
                open={dialog === DialogType.BuildInfo}
                title="Build Information"
                positiveTitle="Save"
                negativeTitle="Cancel"
                onPositiveClick={this.saveBuild}
                onNegativeClick={this.cancel}
                onClose={this.cancel}
            >
                <div className="dialog-content">
                    <div className="split-span">
                        <div className="full-span">
                            {CardInputWithCount({ label: 'Cable Name/ID', value: cablename ?? "", name: 'cablename', maxLength: CardInputMaxLength.CABLE_NAME, onChange: this.handleChange, disabled: locked })}
                        </div>
                        <div className="full-span">
                            {CardInput({ label: 'Customer Build ID', value: '', name: 'customerBuildId', type: 'text', onChange: this.handleChange, disabled: true })}
                        </div>
                    </div>
                    <div className="full-span">
                        {CardInputWithCount({ label: 'Description', value: description ?? "", name: 'description', maxLength: CardInputMaxLength.BUILD_DESCRIPTION, onChange: this.handleChange, disabled: locked })}
                        {CardInputWithCount({ label: 'Street Name', value: streetname ?? "", name: 'streetname', maxLength: CardInputMaxLength.BUILD_STREET_NAME, onChange: this.handleChange, disabled: locked })}
                        {CardInputWithCount({ label: 'Comments', value: comments ?? "", name: 'comments', maxLength: CardInputMaxLength.BUILD_COMMENT, onChange: this.handleChange, disabled: locked })}
                    </div>
                </div>
            </DialogComponent>
        );
    }
}
