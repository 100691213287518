import React from 'react';

import { useTranslation } from 'react-i18next';
import closeIcon from '../card/icon_close.svg';
import { DialogType } from '../../redux/overlay.state';
import { LocalizationKeys } from '../../locales/types';
import { PresetsListComponent } from './presets-list/presets-list';
import { PresetConfigComponent } from './preset-config/preset-config';
import { usePresetManager } from './preset-manager.hooks';
import { PresetManagerContext } from './preset-manager.types';

import './preset-manager.scss';

export const PresetManager = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        dialog, configMode, selectedTab,
        closePresets, onConfigChange, setSelectedTab,
    } = usePresetManager();

    const className = dialog === DialogType.Preset ? 'preset open' : 'preset closed';
    return (
        <PresetManagerContext.Provider value={{ selectedTab, setSelectedTab, configMode, updateConfigMode: onConfigChange }}>
            <div className={className}>
                <div className="preset-panel">
                    <div className="preset-header">
                        <label className="title">{t(LocalizationKeys.ManagePresets)}</label>
                        <button className="icon-button" onClick={closePresets}>
                            <img alt="Close Presets" src={closeIcon} />
                        </button>
                    </div>
                    <div className="presets">
                        <PresetsListComponent />
                        <PresetConfigComponent />
                    </div>
                </div>
            </div>
        </PresetManagerContext.Provider>
    );
}
