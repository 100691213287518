import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../../../../../models/design-mode';
import { loadNewCanvasBuildSpans, setDisplayedBuildId } from '../../../../../../redux/build.state';
import { buildClicked, zoomToBuild } from '../../../../../../redux/map.state';
import { showDialog } from '../../../../../../redux/overlay.state';
import { clearSelection, selectBuild } from '../../../../../../redux/selection.state';
import { setCurrentHighlighted } from '../../../../../../redux/viewport.state';
import { designModeSelector } from '../../../../../../selectors/root.selectors';
import { BuildIconProps } from './build-icon.types';

export const useBuildIcon = (props: BuildIconProps) => {
    const { build } = props;
    const mode = useSelector(designModeSelector);
    const dispatch = useDispatch();

    const { id: buildId } = build;

    const handleBuildClick = useCallback((): void => {
        dispatch(clearSelection());
        if (mode === DesignMode.GISMode) {
            dispatch(selectBuild(buildId));
            dispatch(buildClicked(buildId));
            zoomToBuild(buildId)(dispatch);
        }
        else if (mode === DesignMode.CanvasMode) {
            dispatch(setCurrentHighlighted(0));
            dispatch(showDialog());
            dispatch(loadNewCanvasBuildSpans());
            dispatch(setDisplayedBuildId(buildId));
        }
    }, [buildId, dispatch, mode]);

    return {
        build,
        handleBuildClick,
    };
};
