import { createSelector } from '@reduxjs/toolkit';

import { Cabinet } from '../models/cabinet';
import { Element } from '../models/element';
import { Handhole } from '../models/handhole';
import { Manhole } from '../models/manhole';
import { Pole } from '../models/pole';
import { Vault } from '../models/vault';
import { StateModel } from '../redux/reducers';
import { selectedSplicePlanCabinetId } from './root.selectors';

export const polesSelector = (state: StateModel): Pole[] => state.pole.poles;
export const manholesSelector = (state: StateModel): Manhole[] => state.manhole.manholes;
export const handholesSelector = (state: StateModel): Handhole[] => state.handhole.handholes;
export const vaultsSelector = (state: StateModel): Vault[] => state.vault.vaults;
export const cabinetsSelector = (state: StateModel): Cabinet[] => state.cabinet.cabinets;

export const elementsSelector = createSelector(
    polesSelector,
    manholesSelector,
    handholesSelector,
    vaultsSelector,
    cabinetsSelector,
    (poles, manholes, handholes, vaults, cabinets): Element[] => [
        ...poles,
        ...manholes,
        ...handholes,
        ...vaults,
        ...cabinets,
    ],
);

export const selectedCabinetSelector = createSelector(cabinetsSelector, selectedSplicePlanCabinetId,
    (cabinets, cabinetId): Cabinet | undefined =>
        cabinets.find(c => c.id === cabinetId)
);