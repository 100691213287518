import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BuilderHelper } from '../../../../helpers/build-helper';
import { selectBuild, selectSegment } from '../../../../redux/selection.state';
import { displayedBuildAwaitingSessionUpdateSelector } from '../../../../selectors/build.selectors';
import { setCurrentHighlighted } from '../../../../redux/viewport.state';
import {
    authenticationSelector, segmentsSelector, selectedSegmentIdSelector
} from '../../../../selectors/root.selectors';
import { BuildCardProps } from './build-card.types';

export const useBuildCard = (props: BuildCardProps) => {
    const { build } = props;

    const { userId } = useSelector(authenticationSelector);
    const selectedSegmentId = useSelector(selectedSegmentIdSelector);
    const segments = useSelector(segmentsSelector);
    const awaitingSessionUpdate = useSelector(displayedBuildAwaitingSessionUpdateSelector);

    const [collapsed, setCollapsed] = useState();

    const dispatch = useDispatch();

    const title = BuilderHelper.getBuildDisplayId(build);
    const editDisabled = build.modifiedById !== userId || !!build.lockedById || awaitingSessionUpdate;
    const icon = editDisabled
        ? require('./../../../../icons/locked.png')
        : require('./../../../../icons/schrodinger_cable.png');
    const { subunitCount } = build;
    const segment = segments.find((s) => s.id === selectedSegmentId);

    const handleCloseClick = useCallback(() => {
        dispatch(selectBuild());
        dispatch(selectSegment());
        dispatch(setCurrentHighlighted());
    }, [dispatch]);

    const handleToggleCollapse = useCallback((collapsed) => {
        setCollapsed(collapsed)
    }, []);

    return {
        collapsed,
        title,
        icon,
        build,
        subunitCount,
        editDisabled,
        segment,
        handleCloseClick,
        handleToggleCollapse,
    };
};
