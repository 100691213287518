import './context-menu.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../locales/types';

export interface CardContextMenuItemProps {
    id: number;
    title: string;
    color?: string;
    onSelection: (id: number) => void;
}

export interface CardContextMenuProps {
    title: string;
    position: { left?: number; right?: number; top?: number; bottom?: number };
    items: CardContextMenuItemProps[];
    onCancel?: () => void;
}

export const CardContextMenu = (props: CardContextMenuProps): JSX.Element => {
    const { t } = useTranslation();
    
    const { title, position, items, onCancel } = props;
 
    const positionStyle = {
        right: position.right ? `${position.right}px` : undefined,
        left: position.left ? `${position.left}px` : undefined,
        top: position.top ? `${position.top}px` : undefined,
        bottom: position.bottom ? `${position.bottom}px` : undefined,
    }
    
    return (
        <div className="context-menu opaque" style={positionStyle}>
            <div className="context-menu-container">
                {
                    title &&
                        <div className="context-menu-title">
                            { title }
                        </div>
                }
                <div className="context-menu-options-list">
                    {
                        items.map(i =>
                            <CardContextMenuItem key={i.id} id={i.id} title={i.title} color={i.color} onSelection={i.onSelection} />
                        )
                    }
                    {
                        onCancel &&
                            <TextButton className="negative" onClick={onCancel}>{t(LocalizationKeys.Cancel)}</TextButton>
                    }
                </div>
            </div>
        </div>
    );
};

export const CardContextMenuItem = (props: CardContextMenuItemProps) => {
    const { id, color, title, onSelection } = props;
    const optionColorStyle = {
        backgroundColor: color
    }
    
    return <div className="context-menu-option" onClick={(): void => onSelection(id)}>
                <div className="context-menu-option-name-container">
                    {
                        color &&
                            <div className="context-menu-option-color" style={optionColorStyle} />
                    }
                    <div className="context-menu-option-name">
                        { title }
                    </div>
                </div>
            </div>
};