import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { Data } from './data';
import { ElementData } from './element-data';

export class SegmentData extends Data {
    public buildId: number;
    public position: number;
    public from: ElementData;
    public to: ElementData;
    public slackLoop: DataValue;
    public designSpan: DataValue;
    public measuredSpan?: DataValue;
    public installationType?: string;
    public riserSpan?: DataValue;

    constructor(id: number, buildId: number, position: number, from: ElementData, to: ElementData, slackLoop: number, slackUnit: Units, designSpan: number, designSpanUnit: Units, measuredSpan?: number, measuredSpanUnit?: Units, installationType?: string, riserSpan?: number, riserSpanUnit?: Units) {
        super(id);
        this.buildId = buildId;
        this.position = position;
        this.from = from;
        this.to = to;
        this.slackLoop = new DataValue(slackLoop, slackUnit);
        this.designSpan = new DataValue(designSpan, designSpanUnit);
        if (measuredSpan && measuredSpanUnit) {
            this.measuredSpan = new DataValue(measuredSpan, measuredSpanUnit);
        }
        if (riserSpan && riserSpanUnit) {
            this.riserSpan = new DataValue(riserSpan, riserSpanUnit);
        }
        this.installationType = installationType;
    }

    public getExtent(): number[] {
        const fromLocation = this.from.location;
        const toLocation = this.to.location;
        const minX = Math.min(fromLocation.x, toLocation.x);
        const minY = Math.min(fromLocation.y, toLocation.y);
        const maxX = Math.max(fromLocation.x, toLocation.x);
        const maxY = Math.max(fromLocation.y, toLocation.y);
        return [
            minX, minY,
            maxX, maxY,
        ];
    }
}
