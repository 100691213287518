import './drawer-workspace-header.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../../locales/types';
import { useWorkspaceDrawerHeader } from './drawer-workspace-header.hooks';

export const WorkspaceDrawerHeader = () => {
    const { t } = useTranslation();
    const { onPreviousClick, onCloseClick } = useWorkspaceDrawerHeader();
    return (
        <div className="workspace-drawer-header">
            <div className="workspace-drawer-header-title-container">
                <button className="icon-button" onClick={onPreviousClick}>
                    <img alt="Previous menu" src={require('./icon_chevron_left.svg')} />
                </button>
                <div className="workspace-drawer-header-title">
                    {t(LocalizationKeys.WorkspaceDrawerHeaderTitle)}
                </div>
            </div>
            <button className="icon-button" onClick={onCloseClick}>
                <img alt="Close menu" src={require('../../../card/icon_close.svg')} />
            </button>
        </div>
    )
}