import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../../../locales/types';
import { DialogComponent } from '../../../dialog.component';
import { useDeleteCanvasSegmentDialog } from './delete-segment-dialog.hooks';

export const DeleteCanvasSegmentDialog = (): JSX.Element | null => {
    const { t } = useTranslation();
    
    const {
        isOpen, locationIndex,
        handleCloseClick, handleDeleteClick
    } = useDeleteCanvasSegmentDialog();

    return (
        !isOpen ? null :
            <DialogComponent
                open={isOpen}
                onClose={handleCloseClick}
                negativeTitle={t(LocalizationKeys.Cancel)}
                positiveTitle={t(LocalizationKeys.Delete)}
                onNegativeClick={handleCloseClick}
                onPositiveClick={handleDeleteClick}
                title={t(LocalizationKeys.DeleteLocationTitle)}
                message={locationIndex ? t(LocalizationKeys.DeleteLocationConfirmation, { locationIndex: locationIndex.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) }) : t(LocalizationKeys.DeleteLocationConfirmationNoLocationIndex)}
                negativeStyleButton
            />
    );
};