import React from 'react';
import { useTranslation } from 'react-i18next';

import { Graphics, Sprite, Text } from '@inlet/react-pixi';

import { LocalizationKeys } from '../../../../locales/types';
import { CanvasTextStyles } from '../build.styles';
import { IFrameProps } from '../build.types';
import arrow from './assets/co-arrow.svg';
import { useCo } from './co.hooks';

export const CO: React.FC<IFrameProps> = (props) => {
    const { t } = useTranslation();
    const {
        drawFrame,
        xPositionText, yPositionText, xPositionArrow, yPositionArrow
    } = useCo(props);

    return (
        <>
            <Graphics draw={drawFrame} />
            <Text
                text={t(LocalizationKeys.Co)}
                x={xPositionText}
                y={yPositionText}
                anchor={{ x: 0.5, y: 0 }}
                roundPixels={true}
                style={CanvasTextStyles.mediumSemiBoldGrey}
            />
            <Sprite
                image={arrow}
                x={xPositionArrow}
                y={yPositionArrow}
                anchor={{ x: 0.5, y: 0 }}
            />
        </>
    )
};