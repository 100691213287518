import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setDownloadState } from '../redux/download.state';
import { DialogType, showDialog } from '../redux/overlay.state';

export const useDownload = () => {
    const dispatch = useDispatch();

    const downloadBuilds = useCallback((buildIds: number[], designAreaIds: number[], designAreaNames: string[]): void => {
        dispatch(setDownloadState({
            buildIds,
            designAreaNames: designAreaNames,
            designAreaIds: designAreaIds
        }));
        dispatch(showDialog(DialogType.DownloadGisData));
    }, [dispatch]);

    return { downloadBuilds };
};
