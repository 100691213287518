import './tool-item.scss';

import classNames from 'classnames';
import React, { Component } from 'react';

import { TooltipDark } from '../presets/preset/tooltip-dark';

export enum BadgeType {
    Warning,
    Notification
}

export enum TooltipPlacement {
    Bottom = 'bottom',
    Top = 'top',
    Right = 'right',
    Left = 'left'
}

export type Badge = {
    number: number;
    type: BadgeType;
}


export interface IToolItemProps {
    source?: any;
    active?: boolean;
    badge?: Badge;
    disabled?: boolean;
    tooltip?: string;
    tooltipMoreInfo?: string;
    tooltipPlacement?: TooltipPlacement;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}


export class ToolItem extends Component<IToolItemProps> {

    public render() {
        const { badge, disabled, tooltip, tooltipMoreInfo, tooltipPlacement } = this.props;
        const badgeNumber = badge?.number;

        const iconClass = classNames('icon-container', {
            'active': !!this.props.active
        });
        const badgeClass = classNames('badge', {
            'warning': badge?.type === BadgeType.Warning,
            'notification': badge?.type === BadgeType.Notification
        });
        const badgeNumberClass = classNames('badge-number', {
            'warning': badge?.type === BadgeType.Warning,
            'notification': badge?.type === BadgeType.Notification
        });

        const tooltipText = tooltip ? `${tooltip}${ tooltipMoreInfo ? ` (${tooltipMoreInfo})` : '' }` : '';

        const toolItem = (
            <div className="tool-item">
                <div className={iconClass} onClick={this.props.onClick} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
                    {this.props.source}
                    {badgeNumber && badgeNumber > 0 ? <div key={`badge${badgeNumber}`} className={badgeClass}><div className={badgeNumberClass}>{badgeNumber > 9 ? "9+" : badgeNumber}</div></div> : null}
                </div>
            </div>
        );

        const className = !disabled ? "tool-item-container" : "tool-item-container disabled";
        return (
            <div className={className}>
                {tooltipText
                    ? <TooltipDark arrow={true} placement={ tooltipPlacement ?? TooltipPlacement.Bottom } title={tooltipText}>
                        {toolItem}
                    </TooltipDark>
                    : toolItem}
            </div>
        );
    }
}
