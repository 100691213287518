import React from 'react';

import { ToolItem } from '../ui-elements/tool-item';
import { usePanTool } from './pan-tool.hooks';

export const PanTool = () => {
    const { toolItems } = usePanTool();
    return (
        <div className="controls-container map-control-container">
            { Object.values(toolItems).map(({ source: Icon, ...toolItemProps }, index) => <ToolItem key={index} {...toolItemProps} source={<Icon/>} /> ) }
        </div>
    );
}