import './build-toolbar.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@mui/material';

import { LocalizationKeys } from '../../../../../../locales/types';
import { useBuildToolbar } from './build-toolbar.hooks';
import { BuildToolbarProps } from './build-toolbar.types';

export const BuildToolbar = (props: BuildToolbarProps) => {
    const { t } = useTranslation();
    const { showExportedToolbar, onDeleteClick, onLockIconClick, lockIcon, isDeleteDisabled, isLockDisabled, isSelected, onSelectionChanged } = useBuildToolbar(props);
    return (
        <div className="build-toolbar-container">
            { 
                showExportedToolbar ?
                <div className="build-exported-toolbar">
                    <div className="build-exported">
                        {t(LocalizationKeys.Exported)}
                    </div>
                    <button className="icon-button" onClick={onLockIconClick}>
                        <i className="material-icons">{lockIcon}</i>
                    </button>
                </div>
                :
                <div className="build-toolbar">
                    <button className="icon-button" disabled={isDeleteDisabled} onClick={onDeleteClick}>
                        <i className="material-icons">delete</i>
                    </button>
                    <button className="icon-button" disabled={isLockDisabled} onClick={onLockIconClick}>
                        <i className="material-icons">{lockIcon}</i>
                    </button>
                    <div className="check-box">
                        <Checkbox checked={isSelected} onChange={onSelectionChanged} />
                    </div>
                </div>
            }
        </div>
    );
}