import React from 'react';

import { Graphics, Sprite, Text } from '@inlet/react-pixi';

import { IIndexedFrameProps } from '../build.types';
import { useLocation } from './location.hooks';
import { LocationProps } from './location.types';

export const Location: React.FC<IIndexedFrameProps & LocationProps> = (props) => {
    const {
        xPosition, yPosition,
        zIndex,
        text,
        style,
        image,
        highlightImage,
        highlightAlpha,
        scale,
        highlightScale,
        cursor,
        handleMouseOver,
        handleMouseOut,
        handleClick,
        drawBackgroundForText,
    } = useLocation(props);

    return <>
        <Sprite
            image={image}
            scale={scale}
            x={xPosition}
            y={yPosition}
            anchor={{ x: 0.5, y: 0.5 }}
            zIndex={zIndex}
            interactive={true}
            cursor={cursor}
            click={handleClick}
            mouseover={handleMouseOver}
            mouseout={handleMouseOut}
        />
        <Sprite
            alpha={highlightAlpha}
            image={highlightImage}
            scale={highlightScale}
            x={xPosition}
            y={yPosition}
            anchor={{ x: 0.5, y: 0.5 }}
            zIndex={zIndex + 1}
            cursor={cursor}
        />
        <Graphics draw={drawBackgroundForText} zIndex={zIndex + 3} />
        <Text
            text={text}
            x={xPosition}
            y={yPosition + 40}
            anchor={{ x: 0.5, y: 0.5 }}
            roundPixels={true}
            style={style()}
            zIndex={zIndex}
        />
    </>;
}
