import './about.component.scss';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DialogActions, DialogContent, Divider, Grid } from '@mui/material';

import { LocalizationKeys } from '../../locales/types';
import { DialogType, showDialog } from '../../redux/overlay.state';
import { overlaySelector } from '../../selectors/root.selectors';
import { DialogComponent } from '../dialogs/dialog.component';

export const AboutComponent = (): JSX.Element => {
    // Before converting to functional component, that was an AboutComponentState with a buildDate property that seemed to be unused
    // TODO Bring back buildDate property
    const buildDate = '';

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dialog } = useSelector(overlaySelector);

    const onClose = useCallback(() => {
        if (dialog === DialogType.About) {
            dispatch(showDialog());
        }
    }, [dialog, dispatch]);

    const displayThirdParty = useCallback(() => {
        dispatch(showDialog(DialogType.Thirdparty));
    }, [dispatch]);

    return (
        <DialogComponent
                className="dialog about-dialog"
                open={dialog === DialogType.About}
                onClose={onClose}
                title={t(LocalizationKeys.AboutThisApplication)}
        >
            <DialogContent className="dialog-content">
                <Grid item={true} container={true} xs={14} spacing={1}>
                    <Grid item={true} xs={3} className="brand-container">
                        <img className="brand" src={require('./corning_logo.png')} alt="Brand" />
                    </Grid>
                    <Grid item={true} xs={9}>
                        <div className="tool-title"> { t(LocalizationKeys.DesignTool) } </div>
                        {buildDate ? <div className="build-info">{ t(LocalizationKeys.LastBuild, { dateTime: buildDate }) }</div> : null}
                        <button className="thirdparty clickable" onClick={displayThirdParty}>{ t(LocalizationKeys.ThirdPartyOssCopyright) }</button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="about-footer dialog-footer">
                <Divider variant="middle" />
                <div className="text-footer">{ t(LocalizationKeys.CorningCopyright, { year: new Date().getFullYear() }) }</div>
            </DialogActions>
        </DialogComponent>
    );
};
