import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

export const TooltipDark = withStyles(() => ({
    tooltip: {
        boxSizing: 'border-box',
        minWidth: 64,
        padding: '4 6',
        fontSize: 12,
        fontFamily: 'TheSansC4s',
        fontWeight: 400,
        textAlign: 'center',
        backgroundColor: '#101010',
        color: '#E1F5FC',
        boxShadow: '0',
        borderRadius: '4px',
    },
    arrow: {
        color: '#101010',
    },
}))(Tooltip);