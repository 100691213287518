import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import splicePointIcon from '../../icons/bulk-cable.png';
import lockedIcon from '../../icons/locked.png';
import { LocalizationKeys } from '../../locales/types';
import { deleteSplicePoint } from '../../redux/bulk/splice-point.state';
import { StateModel } from '../../redux/reducers';
import { selectSplicePoint } from '../../redux/selection.state';
import {
    selectedSplicePointOrderedSiblingsSelector
} from '../../selectors/bulk/splice-points.selectors';
import { bulkBuildsSelector, userIdSelector } from '../../selectors/root.selectors';

export const useSplicePointCard = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const state = useSelector((state: StateModel) => state);
    const { splicePoints } = state.splicePoints;
    const { selectedSplicePointId } = state.selection;
    const splicePoint = splicePoints.find(s => s.id === selectedSplicePointId);
    const siblingSplicePoints = useSelector(selectedSplicePointOrderedSiblingsSelector);
    const splicePointIndex = (splicePoint && siblingSplicePoints) ? (siblingSplicePoints.findIndex(s => s.id === splicePoint.id) + 1) : 0;
    const userId = useSelector(userIdSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);
    const bulkBuild = bulkBuilds.find(b => b.id === splicePoint?.buildId);
    const editDisabled = bulkBuild ? bulkBuild.modifiedById !== userId || !!bulkBuild.lockedById : splicePoint?.modifiedById !== userId;
    const spliceCardIcon = editDisabled ? lockedIcon : splicePointIcon;

    const [splicePointTitleLabel, setSplicePointTitleLabel] = useState("");
    const [splicePointSubheaderLabel, setSplicePointSubheaderLabel] = useState("");

    useEffect(() => {
        setSplicePointTitleLabel(`${t(LocalizationKeys.Splice)} ${splicePointIndex || ""}`);
    }, [t, splicePointIndex]);

    useEffect(() => {
        setSplicePointSubheaderLabel(splicePoint?.buildId ? `${t(LocalizationKeys.BulkBuildNumber, { buildId: splicePoint.buildId })}` : t(LocalizationKeys.Unconnected));
    }, [t, splicePoint]);

    const handleDelete = useCallback((): void => {
        if (splicePoint) {
            deleteSplicePoint(splicePoint.id)(dispatch);
        }
    }, [splicePoint, dispatch]);

    const unselectSplicePoint = useCallback((): void => {
        dispatch(selectSplicePoint());
    }, [dispatch]);

    return {
        splicePoint,
        splicePointTitleLabel, splicePointSubheaderLabel, spliceCardIcon, editDisabled,
        handleDelete, unselectSplicePoint
    };
};