import './context-menu.scss';

import i18next from 'i18next';
import React from 'react';

import { ToolType } from '../../design-tools/tooltype';
import { BuilderHelper } from '../../helpers/build-helper';
import { LocalizationKeys } from '../../locales/types';
import { Build } from '../../models/build';
import { BuildType } from '../../models/build-type';
import { FlexNapBuild } from '../../models/flexnap-build';
import { MapItemDefinition } from '../../models/map-item-definition';
import { InteractableFeatureType } from '../../openlayers/interactable-feature-type';
import { OpenlayerStyleFactory } from '../../openlayers/openlayer-style.factory';
import { connect, StateModel } from '../../redux/reducers';
import { clearSelection } from '../../redux/selection.state';
import bringToFrontIcon from './icon_bring_to_front.svg';
import sendToBackIcon from './icon_send_to_back.svg';
import {
    BuildSelectionData, ContextMenuOptionProps, ContextMenuProps, MapMenuProps
} from './map-context-menu.types';

const mapStateToProps = (state: StateModel) => {
    const { selectedTool } = state.tool;
    const { awaitingSchrodingerSessionUpdates } = state.workspace;
    const isModifying = selectedTool === ToolType.Modification || selectedTool === ToolType.NAP || selectedTool === ToolType.TapSchrodinger || selectedTool === ToolType.SplicePoint;
    return { isModifying, awaitingSchrodingerSessionUpdates };
}

const mapDispatchToProps = {
    clearSelection
}

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>> & MapMenuProps;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class MapContextMenu extends React.Component<props, MapMenuProps> {
    public componentDidUpdate(prevProps: props): void {
        const contextMenu = document.getElementById("context-menu");
        if (contextMenu) {
            contextMenu.focus();
        }
    }

    private renderContextMenu = (props: ContextMenuProps) => {
        const { title, options, tips } = props;
        return (
            <div className="context-menu-container">
                {
                    title &&
                        <div className="context-menu-title">
                            {title}
                        </div>
                }
                <div className="context-menu-options-list">
                    {options.map(o => this.renderContextMenuOption(o))}
                </div>
                {
                    tips &&
                        <div className="context-menu-tips-container">
                            <div className="context-menu-tips">{tips}</div>
                        </div>
                }
            </div>
        );
    }

    private renderContextMenuOption = (props: ContextMenuOptionProps) => {
        const { key, disabled, selected, name, color, startIcon, startIconClick, endIcon, endIconClick, shortcut, onClick, onMouseEnter, onMouseLeave } = props;
        const optionClassName = disabled ? "context-menu-option disabled" : "context-menu-option";
        const iconsClassName = disabled ? "material-icons icons-disable" : "material-icons";
        const optionNameClassName = disabled ? "context-menu-option-name option-name-disable" : "context-menu-option-name";
        const optionColorStyle = {
            backgroundColor: color
        }
        return (
            <div key={key} className={`${optionClassName} ${disabled ? 'disabled' : ''} ${selected ? 'selected' : ''}`} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="context-menu-option-name-container">
                    {
                        color ?
                            <div className={`context-menu-option-color ${color === 'transparent' ? 'strikethrough' : ''}`} style={optionColorStyle} /> : null
                    }
                    {
                        startIcon ?
                            startIconClick ?
                                <button className="context-menu-option-start-icon icon-button" onClick={startIconClick}>
                                    <i className={iconsClassName}>
                                        {startIcon}
                                    </i>
                                </button>
                                :
                                <div className="context-menu-option-start-icon">
                                    <i className={iconsClassName}>
                                        {startIcon}
                                    </i>
                                </div>
                            : null
                    }
                    <div className={optionNameClassName}>
                        {name}
                    </div>
                </div>
                {
                    endIcon ?
                        endIconClick ?
                            <button className="context-menu-option-end-icon icon-button" onClick={endIconClick}>
                                <img className="material-icons" src={endIcon} alt="End Icon" />
                            </button>
                            :
                            <div className="context-menu-option-end-icon">
                                <img className="material-icons" src={endIcon} alt="End Icon" />
                            </div>
                        : null
                }
                {
                    shortcut ?
                        <div className="context-menu-option-shortcut">
                            {shortcut}
                        </div> : null
                }
            </div>
        );
    }

    private handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { isModifying, onDeleteNode, data } = this.props;
        switch (event.key) {
            case "Delete":
                if (isModifying && data && data.node && onDeleteNode) {
                    onDeleteNode(data.node.mapItem);
                }
                break;
        }
    }

    private onBringToFront = (buildId: number, type: BuildType, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.onBringToFront) {
            this.props.onBringToFront(buildId, type);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    private onSendToBack = (buildId: number, type: BuildType, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.onSendToBack) {
            this.props.onSendToBack(buildId, type);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    private getCableColor = (build: Build): string => {
        let fiberCount = 0;
        if (build.type === BuildType.FlexNap) {
            fiberCount = (build as FlexNapBuild).fiberCount;
        }
        return OpenlayerStyleFactory.getCableColor(fiberCount, build.cableColorHex).enabled;
    }

    private renderNapBuildContextMenuOption = (napId: number | undefined, type: InteractableFeatureType, elementId: number, data: BuildSelectionData, napCurrentBuildId?: number): ContextMenuOptionProps => {
        const { build } = data;
        let disabled = !!build.lockedById;
        let buildNumberLabel: string = LocalizationKeys.BuildNumber;

        switch (build.type) {
            case BuildType.FlexNap:
                disabled = disabled || !!data.nap;
                break;
            case BuildType.Schrodinger:
                disabled = disabled || !!data.tap;
                break;
            case BuildType.Bulk:
                buildNumberLabel = LocalizationKeys.BulkBuildNumber;
                disabled = disabled || !!data.splicePoint;
                break;
        }

        const selected = build.id === napCurrentBuildId;
        const onItemSelected = !(disabled || selected) ?
            napId && this.props.onMove ?
                this.props.onMove.bind(this, type, napId, elementId, build.id)
                : this.props.onCreate ?
                    this.props.onCreate.bind(this, type, elementId, build.id)
                    : undefined
            : undefined;
        return {
            key: "option-build-" + build.id,
            disabled: !selected && disabled,
            selected,
            name: i18next.t(buildNumberLabel, { buildId: build.id }),
            color: this.getCableColor(build),
            onClick: onItemSelected
        }
    }

    private renderBuildSelectionContextMenuOption = (data: BuildSelectionData, isFirst = false, isMultiple: boolean): ContextMenuOptionProps => {
        const { build, segment } = data;
        const onItemSelected = this.props.onSelected ? this.props.onSelected.bind(this) : undefined;
        const onHighlight = this.props.onHighlight ? this.props.onHighlight.bind(this, build) : undefined;
        const onUnhighlight = this.props.onUnhighlight ? this.props.onUnhighlight.bind(this, build) : undefined;
        const onArrangeClick = isFirst ? this.onSendToBack : this.onBringToFront;
        const arrangeIcon = isFirst ? sendToBackIcon : bringToFrontIcon;
        return {
            key: "option-segment-" + segment.id,
            name: BuilderHelper.getBuildDisplayId(build),
            color: this.getCableColor(build),
            endIcon: isMultiple ? arrangeIcon : undefined,
            endIconClick: onArrangeClick.bind(this, build.id, build.type),
            onClick: onItemSelected,
            onMouseEnter: onHighlight,
            onMouseLeave: onUnhighlight
        }
    }

    private renderBuildSelectionContextMenu = () => {
        this.props.clearSelection!();
        const { x, y, data } = this.props;
        const numOfBuilds = data?.builds?.length;
        if (!data || !(data.builds && numOfBuilds)) {
            return null;
        }
        const style = {
            top: `${y}px`,
            left: `${x + 5}px`,
        };
        const tips = i18next.t(LocalizationKeys.HighlightBuild) + (numOfBuilds > 1 ? " " + i18next.t(LocalizationKeys.HighlightIconsOrder) : "");
        return (
            <div className="context-menu build-selection" style={style}>
                {
                    this.renderContextMenu({
                        title: i18next.t(LocalizationKeys.Builds),
                        options: data.builds.map((b, i) => this.renderBuildSelectionContextMenuOption(b, i === 0, numOfBuilds > 1)),
                        tips: tips
                    })
                }
            </div>
        );
    }

    private onDeleteNode = (node: MapItemDefinition, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (this.props.onDeleteNode) {
            this.props.onDeleteNode(node);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    private renderModificationContextMenu = () => {
        const { x, y, isModifying, data } = this.props;

        if (!isModifying || !data) {
            return null;
        }

        const style = {
            top: `${y}px`,
            left: `${x + 5}px`,
        };

        if (data.node) {
            return (
                <div id="context-menu" className="context-menu modification delete" style={style} tabIndex={0} onKeyDown={this.handleKeyDown}>
                    {
                        this.renderContextMenu({
                            title: data.node.contextMenuTitle ?? i18next.t(LocalizationKeys.Node),
                            options: [
                                {
                                    key: "option-delete",
                                    name: i18next.t(LocalizationKeys.Delete),
                                    startIcon: "delete",
                                    shortcut: i18next.t(LocalizationKeys.DeleteShortcut),
                                    onClick: this.onDeleteNode.bind(this, data.node.mapItem),
                                    disabled: !data.node.canDelete || !!this.props.awaitingSchrodingerSessionUpdates?.length,
                                }
                            ]
                        })
                    }
                </div>
            );
        } else if (data.nap && data.nap.builds) {
            const nap = data.nap;
            const builds = data.nap.builds;
            builds.reduce((uniqueBuilds, b) => {
                if (!uniqueBuilds.some(ub => ub.build.id === b.build.id)) {
                    uniqueBuilds.push(b);
                }
                return uniqueBuilds;
            }, new Array<BuildSelectionData>());
            return (
                <div id="context-menu" className="context-menu modification" style={style} tabIndex={0}>
                    {
                        this.renderContextMenu({
                            title: i18next.t(
                                nap.napId ? LocalizationKeys.MoveTo : LocalizationKeys.AddTo,
                                {
                                    type: i18next.t(
                                        nap.type === InteractableFeatureType.TAP_SCHRODINGER ? LocalizationKeys.Tap : nap.type === InteractableFeatureType.SPLICE_POINT ? LocalizationKeys.SplicePoint : LocalizationKeys.Nap
                                    )
                                }),
                            options: [
                                ...builds.map((b) => this.renderNapBuildContextMenuOption(nap.napId, nap.type, nap.elementId, b, nap.napCurrentBuildId)),
                                {
                                    key: "option-no-build",
                                    name: i18next.t(LocalizationKeys.Unconnected),
                                    color: 'transparent',
                                    onClick: nap.napId && this.props.onMove ?
                                        this.props.onMove.bind(this, nap.type, nap.napId, nap.elementId, undefined)
                                        : this.props.onCreate ?
                                            this.props.onCreate.bind(this, nap.type, nap.elementId, undefined)
                                            : undefined
                                }
                            ]
                        })
                    }
                </div>
            );
        }

        return null;
    }

    public render() {
        const { open, isModifying } = this.props;
        if (!open) {
            return null;
        }

        return isModifying ? this.renderModificationContextMenu() : this.renderBuildSelectionContextMenu();
    }
}
