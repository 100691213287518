import { Mark } from '@mui/base';

import { Terminal } from '../../../models/terminal';
import { Tether } from '../../../models/tether';

export interface TerminalCardProps {
    disabled: boolean;
    tether?: Tether;
    onAddTerminal?: (tetherId: number) => void;
    onDeleteTerminal?: (terminal: Terminal) => void;
}

export const portCounts: Mark[] = [
    { value: 2, label: '2' },
    { value: 4, label: '4' },
    { value: 6, label: '6' },
    { value: 8, label: '8' },
    { value: 10, label: '12' },
];