import React from 'react';

export const CabinetIcon = (): JSX.Element => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g fill="none" stroke="#005293" >
                <rect x="1" y="1" width="22" height="22" rx="3" ry="3" strokeWidth="3" />
                <line x2="24" y1="20" y2="20" strokeWidth="6" />
            </g>
            <text transform="translate(12,13)" textAnchor="middle" fill="#005293" fontSize="10" fontFamily="'TheSansC4s', Arial">C</text>
        </svg>
    );
};
