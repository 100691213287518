/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import lockedIcon from '../../icons/locked.png';
import napIcon from '../../icons/nap.png';
import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuild } from '../../models/flexnap-build';
import { StateModel } from '../../redux/reducers';
import { selectNap } from '../../redux/selection.state';
import { deleteNap } from '../../redux/tap.state';
import { selectedNapOrderedSiblingsSelector } from '../../selectors/naps.selector';
import { flexnapBuildsSelector, userIdSelector } from '../../selectors/root.selectors';

export const useNapCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { nap, napIndex, tapIndex, tetherIndex, selectedTap, selectedTether, hasSelectedTether, hasSelectedTerminal, pretermBuilds, selectedTetherPretermBuild } = useSelectedNap();
    const [napTitleLabel, setNapTitleLabel] = useState("");
    const [deleteDialog, showDeleteDialog] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [pretermConfig, showPretermConfig] = useState(false);
    const userId = useSelector(userIdSelector);
    const flexnapBuilds = useSelector(flexnapBuildsSelector);
    const flexnapBuild = flexnapBuilds.find(b => b.id === nap?.buildId);
    const parentBuild = flexnapBuild?.pretermLateral ? flexnapBuilds.find(b => b.id === flexnapBuild.pretermLateral?.parentBuildId) : flexnapBuild;
    const editDisabled = !!flexnapBuild?.lockedById || (
                            parentBuild ? 
                                parentBuild.modifiedById !== userId || !!parentBuild.lockedById
                                : 
                                nap?.modifiedById !== userId
                        );
    const napCardIcon = editDisabled ? lockedIcon : napIcon;

    useEffect(() => {
        showPretermConfig(false);
    }, [nap]);

    useEffect(() => {
        setNapTitleLabel(`${t(LocalizationKeys.Nap)} ${napIndex || ""}`);
    }, [t, napIndex]);

    useEffect(() => {
        const controls = !(hasSelectedTerminal || (hasSelectedTether && selectedTap && selectedTether));
        setShowControls(controls);
    }, [hasSelectedTerminal, hasSelectedTether, selectedTap, selectedTether]);

    const unselectNap = useCallback((): void => {
        dispatch(selectNap());
    }, [dispatch]);

    const toggleDeleteDialog = useCallback((): void => {
        showDeleteDialog(!deleteDialog);
    }, [deleteDialog]);

    const togglePretermConfig = useCallback((): void => {
        showPretermConfig(!pretermConfig);
    }, [pretermConfig]);

    const deleteSelectedNap = useCallback((): void => {
        if (nap) {
            deleteNap(nap.id, pretermBuilds)(dispatch);
        }
        showDeleteDialog(false);
    }, [dispatch, nap, pretermBuilds]);

    const handleDelete = useCallback((): void => {
        if (pretermBuilds && pretermBuilds.length > 0) {
            toggleDeleteDialog();
        }
        else {
            deleteSelectedNap();
        }
    }, [toggleDeleteDialog, deleteSelectedNap, pretermBuilds]);

    return {
        nap, tapIndex, tetherIndex, selectedTap, selectedTether, hasSelectedTether, napTitleLabel, napCardIcon, pretermBuilds, selectedTetherPretermBuild, deleteDialog, showControls, pretermConfig,
        unselectNap, deleteNap: deleteSelectedNap, handleDelete, toggleDeleteDialog, togglePretermConfig, editDisabled,
    };
};

const useSelectedNap = () => {
    const state = useSelector((state: StateModel) => state);
    const { naps } = state.taps;
    const { selectedNapId, selectedTerminalId } = state.selection;
    const nap = naps.find((n) => n.id === selectedNapId);
    const siblingNaps = useSelector(selectedNapOrderedSiblingsSelector);
    const napIndex = (nap && siblingNaps) ? (siblingNaps.findIndex((t) => t.id === nap.id) + 1) : 0;

    const { tapIndex, tetherIndex, selectedTether, selectedTap, hasSelectedTether } = useSelectedTether(state);
    const pretermBuilds = usePretermNap(state, nap?.id);

    const [hasSelectedTerminal, setHasSelectedTerminal] = useState(!!selectedTerminalId);
    const [selectedTetherPretermBuild, setSelectedTetherPretermBuild] = useState(selectedTether ? pretermBuilds?.find(b => b.pretermLateral?.parentTetherIds.includes(selectedTether.id)): undefined);

    useEffect(() => {
        setHasSelectedTerminal(!!selectedTerminalId);
    }, [selectedTerminalId]);

    useEffect(() => {
        setSelectedTetherPretermBuild(selectedTether ? pretermBuilds?.find(b => b.pretermLateral?.parentTetherIds.includes(selectedTether.id)): undefined);
    }, [selectedTether, pretermBuilds]);

    return {
        nap,
        napIndex,
        tapIndex,
        tetherIndex,
        selectedTap,
        selectedTether,
        hasSelectedTether,
        hasSelectedTerminal,
        pretermBuilds,
        selectedTetherPretermBuild
    };
};

const useSelectedTether = (state: StateModel) => {
    const { taps, tethers } = state.taps;
    const { selectedTetherId } = state.selection;
    const [selectedTether, setSelectedTether] = useState(tethers.find((t) => t.id === selectedTetherId));
    const [selectedTap, setSelectedTap] = useState(selectedTether && taps.find((t) => t.id === selectedTether.tapId));
    const tetherIndex = selectedTether?.tetherIndex;
    const tapIndex = selectedTap?.tapIndex;

    useEffect(() => {
        setSelectedTether(selectedTetherId ? tethers.find((t) => t.id === selectedTetherId) : undefined);
    }, [tethers, selectedTetherId]);

    useEffect(() => {
        setSelectedTap(selectedTether ? taps.find((t) => t.id === selectedTether.tapId) : undefined);
    }, [taps, selectedTether]);

    const hasSelectedTether = selectedTetherId !== undefined;

    return { tapIndex, tetherIndex, selectedTether, selectedTap, hasSelectedTether };
}

const usePretermNap = (state: StateModel, napId?: number): FlexNapBuild[] | undefined => {
    const { flexnapBuilds } = state.build;
    const [pretermBuilds, setPretermBuilds] = useState(flexnapBuilds.filter((b) => b.pretermLateral?.parentNapId === napId));

    useEffect(() => {
        setPretermBuilds(flexnapBuilds.filter((b) => b.pretermLateral?.parentNapId === napId));
    }, [napId, flexnapBuilds]);
    return pretermBuilds;
}