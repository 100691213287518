import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { FlexNapPretermBuildData } from '../data/preterm-build-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class PretermNewTubeValidator implements Validator<FlexNapPretermBuildData> {
    validate(entity: FlexNapPretermBuildData): ValidationResult | null {
        if (entity.pretermBuild.pretermLateral) {
            const TUBE_FIBER_COUNT = 12;
            const pretermLateral = entity.pretermBuild.pretermLateral;
            const napElementId = entity.parentBuild.naps.find(n => n.id === pretermLateral.parentNapId)?.elementId;
            const lastPosition = entity.parentBuild.segments.find(s => s.from.id === napElementId)?.position ?? 0;
            if (!lastPosition && entity.parentBuild.fiberCount % TUBE_FIBER_COUNT !== 0) {
                const fibersLeftInTube = entity.parentBuild.fiberCount % TUBE_FIBER_COUNT;
                return this.validationResult(ValidationResultType.Warning, entity.pretermBuild, i18next.t(LocalizationKeys.PretermLateralInvalidTube, { buildId: entity.pretermBuild.id, fibersLeftInTube: fibersLeftInTube }));
            }

            const napsConnectedToBuildBeforeSelectedNap = entity.parentBuild.naps.filter(nap =>
                nap.id !== pretermLateral.parentNapId // only include the NAPs before the one that is connected to preterm
                && !!entity.parentBuild.segments.find(s => s.position < lastPosition && (s.from.id === nap.elementId || s.to.id === nap.elementId))); // segments before NAP that connected to preterm

            const fiberCountLeft = napsConnectedToBuildBeforeSelectedNap.flatMap(n => n.taps.flatMap(t => t.tethers.map(tether => tether.fiberCount)))
                .reduce((a, b) => a - b, entity.parentBuild.fiberCount);

            const fibersLeftInTube = fiberCountLeft % TUBE_FIBER_COUNT;
            if (fibersLeftInTube !== 0) {
                return this.validationResult(ValidationResultType.Warning, entity.pretermBuild, i18next.t(LocalizationKeys.PretermLateralInvalidTube, { buildId: entity.pretermBuild.id, fibersLeftInTube: fibersLeftInTube }))
            }
        }
        return null;
    }

    canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapPretermBuildData && !!entity.pretermBuild.pretermLateral;
    }

    private validationResult(resultType: ValidationResultType, build: FlexNapBuildData, message: string): ValidationResult {
        return {
            buildId: build.id,
            itemId: null,
            itemType: ItemType.Build,
            shortDescription: message,
            type: resultType,
            extent: build.getExtent()
        };
    }
}