import React, { createContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { StateModel } from '../../../../redux/reducers';
import { initialWorkspaceDrawerFilterProps } from './drawer-workspace-filter.types';

export const WorkspaceDrawerFilterContext = createContext(initialWorkspaceDrawerFilterProps);

// eslint-disable-next-line react/prop-types
export const WorkspaceDrawerFilterContextProvider = ({ children }) => {
    const { workspaces } = useSelector((state: StateModel) => state.workspace);
    const [workspaceList, setWorkspaceList] = useState(workspaces);
   
    return (
        <WorkspaceDrawerFilterContext.Provider value={{workspaceList, setWorkspaceList}}>
            {children}
        </WorkspaceDrawerFilterContext.Provider>
    );
}
