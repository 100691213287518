import i18n from 'i18next';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocalizationKeys } from '../locales/types';
import { Review } from '../models/review';
import { ReviewStatus } from '../models/review-status';
import { NotificationService } from '../services/notification.service';
import { ReviewService } from '../services/review.service';
import { updateBuild } from './build.state';

export interface ReviewState {
    reviews: Review[];
}

const initialState = {
    reviews: [],
};

const loadReviewsReducer = (state: ReviewState, action: PayloadAction<Review[]>) => {
    state.reviews = action.payload;
};

const addReviewReducer = (state: ReviewState, action: PayloadAction<Review>) => {
    state.reviews.push(action.payload);
};

const updateReviewReducer = (state: ReviewState, action: PayloadAction<Review>) => {
    const updatedReview = action.payload;
    const index = state.reviews.findIndex(({ id }) => id === updatedReview.id);
    if (index === -1) {
        state.reviews.push(updatedReview);
    }
    else {
        state.reviews[index] = updatedReview;
    }
};

const updateReviewsReducer = (state: ReviewState, action: PayloadAction<Review[]>) => {
    const updatedReviews = action.payload;
    const updatedReviewIds = new Set(updatedReviews.map(r => r.id));
    const unchangedReviews = state.reviews.filter((r) => !updatedReviewIds.has(r.id));
    state.reviews = [...unchangedReviews, ...updatedReviews];
};

const { actions, reducer } = createSlice({
    name: 'review',
    initialState,
    reducers: {
        loadReviews: loadReviewsReducer,
        addReview: addReviewReducer,
        updateReview: updateReviewReducer,
        updateReviews: updateReviewsReducer
    }
});

export { reducer as ReviewReducer };

export const { loadReviews } = actions;

export const startReview = (buildId: number, notes?: string) => async (dispatch) => {
    const service = new ReviewService();
    const response = await service.startReview({ buildId, notes });
    if (response) {
        NotificationService.success(i18n.t(LocalizationKeys.ReviewRequestSent));
        const { build, review } = response;
        dispatch(actions.addReview(review));
        dispatch(updateBuild(build));
    }
};

export const finishReview = (reviewId: number, status: ReviewStatus, notes?: string) => async (dispatch) => {
    const service = new ReviewService();
    const response = await service.finishReview(reviewId, { status, notes });
    if (response) {
        const { build, review } = response;
        if (review.status === ReviewStatus.Canceled) NotificationService.info(i18n.t(LocalizationKeys.ReviewRequestCanceled));
        dispatch(actions.updateReview(review));
        dispatch(updateBuild(build));
    }
};

export const archiveReview = (reviewId: number) => async (dispatch) => {
    const service = new ReviewService();
    const review = await service.archiveReview(reviewId);
    if (review) {
        dispatch(actions.updateReview(review));
    }
};

export const dismissReviewNotification = (reviewIds: number[]) => async (dispatch) => {
    const service = new ReviewService();
    const reviews = await service.dismissReviews(reviewIds);
    if (reviews) {
        dispatch(actions.updateReviews(reviews));
    }
};

export const getReviewsInUserGroup = () => async (dispatch) => {
    const service = new ReviewService();
    const response = await service.getReviewsInUserGroup();

    if (response)
        dispatch(loadReviews(response));
}
