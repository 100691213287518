import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleLayer } from '../../redux/layer.state';
import { toggleMapStyle } from '../../redux/map.state';
import { ControlType, DialogType, showControl, showDialog } from '../../redux/overlay.state';
import { StateModel } from '../../redux/reducers';
import { clearSelection } from '../../redux/selection.state';
import { mapSelector, rolesSelector, notificationSelector, overlaySelector } from '../../selectors/root.selectors';
import CorningRoles from '../../redux/corning-roles.json';
import { reviewNotificationsCountSelector } from '../../selectors/review.selectors';

export const useGISMenuBar = () => {
    const dispatch = useDispatch();

    const { control } = useSelector(overlaySelector);
    const { layers } = useSelector((state: StateModel) => state.layer);
    const { simpleMap } = useSelector(mapSelector);
    const { roles } = useSelector(rolesSelector);
    const { notifications } = useSelector(notificationSelector);
    const reviewNotificationsCount = useSelector(reviewNotificationsCountSelector);

    const showReviews = roles && (roles.some((role) => role === CorningRoles.Schrodinger));

    const toggleMap = useCallback((): void => {
        dispatch(toggleMapStyle());
    }, [dispatch]);

    const showPresets = useCallback((): void => {
        dispatch(showDialog(DialogType.Preset));
        dispatch(showControl());
        dispatch(clearSelection());
    }, [dispatch]);

    const showLayers = useCallback((): void => {
        dispatch(showControl(control !== ControlType.Layers ? ControlType.Layers : undefined));
        dispatch(clearSelection());
    }, [control, dispatch]);

    const onLayerItemSelected = useCallback((id: string) => {
        dispatch(toggleLayer(id));
    }, [dispatch]);

    const onLayerGroupSelected = useCallback((id: string) => {
        dispatch(toggleLayer(id));
    }, [dispatch]);

    const showMoreActions = useCallback((): void => {
        dispatch(showControl(control !== ControlType.More ? ControlType.More : undefined));
        dispatch(clearSelection());
    }, [control, dispatch]);

    const showWarnings = useCallback((): void => {
        dispatch(showControl(control !== ControlType.Warnings ? ControlType.Warnings : undefined));
    }, [control, dispatch]);

    const showBuildsInReview = useCallback((): void => {
        dispatch(showControl(control !== ControlType.Review ? ControlType.Review : undefined));
    }, [control, dispatch])

    return {
        control,
        layers,
        notifications,
        reviewNotificationsCount,
        showReviews,
        toggleMap,
        showPresets,
        showLayers,
        onLayerItemSelected,
        onLayerGroupSelected,
        showMoreActions,
        showWarnings,
        showBuildsInReview,
        simpleMap
    };
}