import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../../history/command-type';
import { MoveTapPayload } from '../../history/schrodinger/tap/move-tap-command';
import i18n from '../../locales/i18n';
import { LocalizationKeys } from '../../locales/types';
import { DesignMode } from '../../models/design-mode';
import { OpticalTether } from '../../models/schrodinger/optical-tether';
import { PowerTether } from '../../models/schrodinger/power-tether';
import { Tap } from '../../models/schrodinger/tap';
import { Tether } from '../../models/schrodinger/tether';
import { TetherType } from '../../models/schrodinger/tether-type';
import { NotificationService } from '../../services/notification.service';
import { TapService } from '../../services/schrodinger/tap.service';
import { TetherService } from '../../services/schrodinger/tether.service';
import { requestIsSuccess } from '../action';
import { updateSchrodingerBuildFromSession } from '../build.state';
import { push } from '../history.state';
import { selectTapSchrodinger } from '../selection.state';
import {
    setBuildAwaiting, setTapAwaiting, unsetBuildAwaiting, unsetTapAwaiting
} from '../workspace.state';

export interface TapState {
    taps: Tap[];
    tethers: Tether[];
    tapIdsInProgress: number[];
}

const initialState: TapState = {
    taps: [],
    tethers: [],
    tapIdsInProgress: []
};

const loadTapsReducer = (state: TapState, action: PayloadAction<Tap[]>): void => { state.taps = action.payload; };

const loadTethersReducer = (state: TapState, action: PayloadAction<Tether[]>): void => { state.tethers = action.payload; };

const addTapReducer = (state: TapState, action: PayloadAction<Tap>): void => { state.taps.push(action.payload); };

const addTapsReducer = (state: TapState, action: PayloadAction<Tap[]>): void => { state.taps.push(...action.payload); };

const addTetherReducer = (state: TapState, action: PayloadAction<Tether>): void => { state.tethers.push(action.payload); };

const addTethersReducer = (state: TapState, action: PayloadAction<Tether[]>): void => { state.tethers.push(...action.payload); };

const updateTapReducer = (state: TapState, action: PayloadAction<Tap>): void => {
    const tap: Tap = action.payload;
    const tapIdx = state.taps.findIndex((t) => t.id === tap.id);
    if (tapIdx < 0) {
        state.taps.push(tap);
    }
    else {
        state.taps[tapIdx] = tap;
    }
};

const releaseTapReducer = (state: TapState, action: PayloadAction<number>): void => {
    const tapId = action.payload;
    const tapIdx = state.taps.findIndex((t) => t.id === tapId);
    if (tapIdx >= 0) {
        const tap = state.taps[tapIdx];
        tap.modifiedById = null;
        tap.lastModified = new Date(Date.now());
        state.taps[tapIdx] = tap;
    }
}

const updateTapsReducer = (state: TapState, action: PayloadAction<Tap[]>): void => {
    const updatedTaps: Tap[] = action.payload;
    const updatedTapIds = updatedTaps.map((s) => s.id);
    const taps = state.taps.filter((s) => !updatedTapIds.includes(s.id));
    taps.push(...updatedTaps);
    state.taps = taps;
};

const updateTetherReducer = (state: TapState, action: PayloadAction<Tether>): void => {
    const tether: Tether = action.payload;
    const tetherIdx = state.tethers.findIndex((ap) => ap.id === tether.id);
    if (tetherIdx < 0) {
        state.tethers.push(tether);
    }
    else {
        state.tethers[tetherIdx] = tether;
    }
};

const updateTethersReducer = (state: TapState, action: PayloadAction<Tether[]>): void => {
    const updatedTethers: Tether[] = action.payload;
    const updatedTetherIds = updatedTethers.map((s) => s.id);
    const tethers = state.tethers.filter((s) => !updatedTetherIds.includes(s.id));
    tethers.push(...updatedTethers);
    state.tethers = tethers;
};

const deleteTapReducer = (state: TapState, action: PayloadAction<number>): void => {
    const tapId = action.payload;
    state.taps = state.taps.filter((tap) => tap.id !== tapId);
    state.tethers = state.tethers.filter((tether) => tether.tapId !== tapId);
};

const deleteTapsWithElementIdReducer = (state: TapState, action: PayloadAction<number>): void => {
    const elementId = action.payload;
    const tapIdsToRemove = new Set(state.taps.filter((tap) => tap.elementId === elementId).map(t => t.id));

    state.taps = state.taps.filter((tap) => tap.elementId !== elementId);
    state.tethers = state.tethers.filter((tether) => !tapIdsToRemove.has(tether.tapId));
};

const deleteTetherReducer = (state: TapState, action: PayloadAction<number>): void => {
    const tetherId = action.payload;
    state.tethers = state.tethers.filter((ap) => ap.id !== tetherId);
};

const deleteTapsReducer = (state: TapState, action: PayloadAction<number[]>): void => {
    const tapIds = action.payload;
    state.taps = state.taps.filter(({ id }) => !tapIds.includes(id));
    state.tethers = state.tethers.filter(({ tapId }) => !tapIds.includes(tapId));
};

const linkTapToBuildReducer = (state: TapState, action: PayloadAction<{ tapId: number; buildId: number }>): void => {
    const { tapId, buildId } = action.payload;
    const tap = state.taps.find(s => s.id === tapId && s.buildId === null);
    if (tap) {
        tap.buildId = buildId;
    }
}

const linkTapsToBuildReducer = (state: TapState, action: PayloadAction<{ elementIds: number[]; buildId: number }>): void => {
    const { elementIds, buildId } = action.payload;
    const taps = state.taps.filter((s) => s.buildId === null && elementIds.includes(s.elementId));
    for (const tap of taps) {
        tap.buildId = buildId;
    }
};

const linkTapsToBuildByIdReducer = (state: TapState, action: PayloadAction<{ tapIds: number[]; buildId: number }>): void => {
    const { tapIds, buildId } = action.payload;
    const taps = state.taps.filter(t => tapIds.includes(t.id));
    for (const tap of taps) {
        tap.buildId = buildId;
    }
};

const unlinkTapFromBuildReducer = (state: TapState, action: PayloadAction<{ tapId: number; buildId: number }>): void => {
    const { tapId, buildId } = action.payload;
    const tap = state.taps.find(s => s.id === tapId && s.buildId === buildId);
    if (tap) {
        tap.buildId = null;
    }
}

const unlinkTapsFromBuildReducer = (state: TapState, action: PayloadAction<number>): void => {
    const buildId = action.payload;
    const taps = state.taps.filter((s) => s.buildId === buildId);
    for (const tap of taps) {
        tap.buildId = null;
    }
};

const cancelTapMoveReducer = (state: TapState, action: PayloadAction<number>): void => {
    const tap = state.taps.find(n => n.id === action.payload);
    if (tap) {
        const originalElementId = tap.elementId;
        tap.elementId = 0;
        tap.elementId = originalElementId;
    }
};

const removeAllReducer = (state: TapState): void => {
    state.taps = [];
};

const { actions, reducer } = createSlice({
    name: 'tap',
    initialState,
    reducers: {
        loadSchrodingerTaps: loadTapsReducer,
        loadSchrodingerTethers: loadTethersReducer,
        addSchrodingerTap: addTapReducer,
        addTaps: addTapsReducer,
        addSchrodingerTether: addTetherReducer,
        addSchrodingerTethers: addTethersReducer,
        updateSchrodingerTap: updateTapReducer,
        updateSchrodingerTaps: updateTapsReducer,
        updateSchrodingerTethers: updateTethersReducer,
        updateTether: updateTetherReducer,
        deleteSchrodingerTap: deleteTapReducer,
        deleteTapsWithElementId: deleteTapsWithElementIdReducer,
        deleteTaps: deleteTapsReducer,
        deleteSchrodingerTether: deleteTetherReducer,
        linkTapsToSegment: linkTapsToBuildReducer,
        linkTapsToBuild: linkTapsToBuildByIdReducer,
        linkTapToBuild: linkTapToBuildReducer,
        unlinkTapFromBuild: unlinkTapFromBuildReducer,
        unlinkTapsFromBuild: unlinkTapsFromBuildReducer,
        releaseTap: releaseTapReducer,
        cancelTapMove: cancelTapMoveReducer,
        removeAll: removeAllReducer
    },
});

export { reducer as TapReducer };
export const { deleteTapsWithElementId, removeAll, addTaps, addSchrodingerTethers, deleteTaps } = actions;

export const loadAll = (workspaceId: number, designMode = DesignMode.GISMode) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const data = await service.getAll(workspaceId, designMode);
        if (!data) {
            return;
        }
        if (data.taps) {
            dispatch(actions.loadSchrodingerTaps(data.taps));
        }
        if (data.tethers) {
            dispatch(actions.loadSchrodingerTethers(data.tethers));
        }
    };
}

export const loadForBuild = (buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const data = await service.getForBuild(buildId);
        if (!data) {
            return;
        }
        if (data.taps) {
            dispatch(actions.updateSchrodingerTaps(data.taps));
        }
        if (data.tethers) {
            dispatch(actions.updateSchrodingerTethers(data.tethers));
        }
    };
};

export const addTap = (elementId: number, workspaceId: number, buildId: number | null = null, selectOnCreation = false) => {
    return async (dispatch: Dispatch): Promise<void> => {
        if (buildId) {
            dispatch(setBuildAwaiting(buildId));
        }

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingTap));
        const service = new TapService();
        const tap = await service.addTap(elementId, workspaceId, buildId);
        if (tap) {
            dispatch(actions.addSchrodingerTap(tap));
            selectOnCreation && dispatch(selectTapSchrodinger(tap.id));
            dispatch(push({ type: CommandType.CreateSchrodingerTap, payload: { tap: tap, awaitSessionUpdate: !!buildId } }));
            NotificationService.success(i18n.t(LocalizationKeys.TapSuccessfullyAdded));
        }

        if (buildId) {
            dispatch(unsetBuildAwaiting(buildId));
        }
        NotificationService.clear(loadingToast);
    };
};

export const addPowerTether = (tap: Tap) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const tapId = tap.id;
        dispatch(setTapAwaiting(tap));

        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingTether));
        const service = new TetherService();
        const tether = await service.createTether(tapId, TetherType.Power);
        if (tether) {
            dispatch(actions.addSchrodingerTether(tether));
            dispatch(push({ type: CommandType.CreateSchrodingerTether, payload: { id: tether.id, tap } }));
            NotificationService.success(i18n.t(LocalizationKeys.TetherSuccessfullyAdded));
        }
        dispatch(unsetTapAwaiting(tap));
        NotificationService.clear(loadingToast);
    };
};

export const addOpticalTether = (tap: Tap) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const tapId = tap.id;
        dispatch(setTapAwaiting(tap));
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingTether));
        const service = new TetherService();
        const tether = await service.createTether(tapId, TetherType.Optical);
        if (tether) {
            dispatch(actions.addSchrodingerTether(tether));
            dispatch(push({ type: CommandType.CreateSchrodingerTether, payload: { id: tether.id, tap } }));
            NotificationService.success(i18n.t(LocalizationKeys.TetherSuccessfullyAdded));
        }
        dispatch(unsetTapAwaiting(tap));
        NotificationService.clear(loadingToast);
    };
};

export const updateTether = (tether: Tether | PowerTether | OpticalTether) => {
    return async (dispatch: Dispatch): Promise<void> => {
        if (tether) {
            dispatch(actions.updateTether(tether));
        }
    };
}

export const patchTether = (oldTether: Tether, newTether: Tether | PowerTether | OpticalTether) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TetherService();
        const tether = await service.patchTether(oldTether, newTether);
        if (tether) {
            dispatch(actions.updateTether(tether));
        }
    };
};

export const deleteTap = (tap: Tap, ignoreHistory = false, awaitSessionUpdate?: boolean) => {
    return async (dispatch: Dispatch): Promise<void> => {
        if (awaitSessionUpdate) {
            dispatch(setTapAwaiting(tap));
        }
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.DeletingTap));
        const service = new TapService();
        const success = await requestIsSuccess(service, service.deleteTap(tap.id));
        if (success) {
            dispatch(actions.deleteSchrodingerTap(tap.id));
            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.DeleteSchrodingerTap, payload: { tap: tap, awaitSessionUpdate: awaitSessionUpdate } }));
            }
            NotificationService.success(i18n.t(LocalizationKeys.TapSuccessfullyDeleted));
        }

        if (awaitSessionUpdate) {
            dispatch(unsetTapAwaiting(tap));
        }
        NotificationService.clear(loadingToast);
    };
};

export const undoDeleteTap = (tapToUndo: Tap, awaitSessionUpdate?: boolean) => {
    return async (dispatch: Dispatch): Promise<void> => {
        if (awaitSessionUpdate) {
            dispatch(setTapAwaiting(tapToUndo));
        }
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingTap));
        const service = new TapService();
        const tap = await service.undoDeleteTap(tapToUndo.id);
        if (tap) {
            dispatch(actions.addSchrodingerTap(tap));
            dispatch(selectTapSchrodinger(tap.id));
            tap.tethers && dispatch(actions.addSchrodingerTethers(tap.tethers));
            tap.buildId && updateSchrodingerBuildFromSession(tap.buildId)(dispatch);
            NotificationService.success(i18n.t(LocalizationKeys.TapSuccessfullyAdded));
        }

        if (awaitSessionUpdate) {
            dispatch(unsetTapAwaiting(tapToUndo));
        }
        NotificationService.clear(loadingToast);
    };
};

export const deleteTether = (id: number, tap: Tap, ignoreHistory = false) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(setTapAwaiting(tap));
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.DeletingTether));
        const service = new TetherService();
        const success = await requestIsSuccess(service, service.deleteTether(id));
        if (success) {
            dispatch(actions.deleteSchrodingerTether(id));
            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.DeleteSchrodingerTether, payload: { id, tap } }));
            }
            NotificationService.success(i18n.t(LocalizationKeys.TetherSuccessfullyDeleted));
        }
        dispatch(unsetTapAwaiting(tap));
        NotificationService.clear(loadingToast);
    };
};

export const undoDeleteTether = (id: number, tap: Tap) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.AddingTether));
        const service = new TetherService();
        dispatch(setTapAwaiting(tap));
        const tether = await service.undoDeleteTether(id);
        if (tether) {
            dispatch(actions.addSchrodingerTether(tether));
            NotificationService.success(i18n.t(LocalizationKeys.TetherSuccessfullyAdded));
        }
        dispatch(unsetTapAwaiting(tap));
        NotificationService.clear(loadingToast);
    };
};

export const linkTapsOnElementsToBuild = (elementIds: number[], buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const success = await requestIsSuccess(service, service.linkTapsOnElementsToBuild(elementIds, buildId));
        if (success) {
            dispatch(actions.linkTapsToSegment({ elementIds, buildId }));
        }
    };
};

export const linkTapsToBuild = (taps: Tap[], buildId: number, ignoreHistory = false) => {
    return async (dispatch: Dispatch) => {
        dispatch(setBuildAwaiting(buildId));
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.LinkingTaps));
        const linkedTaps: Tap[] = taps.map((t) => ({ ...t, buildId }));
        dispatch(actions.updateSchrodingerTaps(linkedTaps));
        const service = new TapService();
        const tapIds = taps.map(({ id }) => id);
        const response = await service.linkTapsToBuild(tapIds, buildId);
        if (response) {
            dispatch(actions.updateSchrodingerTaps(response.taps));
            dispatch(actions.updateSchrodingerTethers(response.tethers));
            updateSchrodingerBuildFromSession(buildId)(dispatch);
            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.LinkSchrodingerTaps, payload: { tapIds, buildId } }));
            }
            NotificationService.success(i18n.t(LocalizationKeys.TapsSuccessfullyLinked));
        }
        else {
            dispatch(actions.updateSchrodingerTaps(taps));
            NotificationService.error(i18n.t(LocalizationKeys.LinkingTaps));
        }
        NotificationService.clear(loadingToast);
        dispatch(unsetBuildAwaiting(buildId));
    };
};

export const unlinkTapFromBuild = (tapId: number, buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(setBuildAwaiting(buildId));
        const service = new TapService();
        const success = await requestIsSuccess(service, service.unlinkTapFromBuild(tapId));
        if (success) {
            dispatch(actions.unlinkTapFromBuild({ tapId: tapId, buildId: buildId }));
        }
        dispatch(unsetBuildAwaiting(buildId));
    };
};

export const unlinkTapsFromBuild = (taps: Tap[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.UnlinkingTaps));
        const buildIds = taps.map(({ buildId }) => buildId).filter((i): i is number => i !== null).distinct();
        for (const buildId of buildIds) dispatch(setBuildAwaiting(buildId));

        const unlinkedTaps: Tap[] = taps.map((t) => ({ ...t, buildId: null }));
        dispatch(actions.updateSchrodingerTaps(unlinkedTaps));
        const service = new TapService();
        const response = await service.unlinkTapsFromBuilds(taps.map(({ id }) => id));
        if (response) {
            dispatch(actions.updateSchrodingerTaps(response.taps));
            dispatch(actions.updateSchrodingerTethers(response.tethers));
        }
        else {
            dispatch(actions.updateSchrodingerTaps(taps));
        }

        for (const buildId of buildIds) dispatch(unsetBuildAwaiting(buildId));
        NotificationService.clear(loadingToast);
    };
};

export const lockTapsConnectedToBuilds = (buildIds: number[], workspaceId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const taps = await service.lockTapsConnectedToBuilds(buildIds, workspaceId);
        if (taps) {
            dispatch(actions.updateSchrodingerTaps(taps));
        }
    };
};

export const lockAllTaps = (workspaceId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const taps = await service.lockAllTapsForWorkspace(workspaceId);
        if (taps) {
            dispatch(actions.updateSchrodingerTaps(taps));
        }
    };
};

export const unlockTapsConnectedToBuilds = (buildIds: number[], workspaceId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const taps = await service.unlockTapsConnectedToBuilds(buildIds, workspaceId);
        if (taps) {
            dispatch(actions.updateSchrodingerTaps(taps));
        }
    };
};

export const unlockAllTaps = (workspaceId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const taps = await service.unlockAllTapsForWorkspace(workspaceId);
        if (taps) {
            dispatch(actions.updateSchrodingerTaps(taps));
        }
    };
};

export const modifyTap = (tapId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new TapService();
        const modifiedTap = await service.modifyTap(tapId);
        if (modifiedTap) {
            dispatch(actions.updateSchrodingerTap(modifiedTap));
        }
    };
};

export const releaseTap = (tapId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(actions.releaseTap(tapId));
        const service = new TapService();
        await service.releaseTap(tapId);
    };
};

export const moveTap = (tapId: number, fromElementId: number, toElementId: number, fromBuildId: number | null, toBuildId: number | null, ignoreHistory = false) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const buildIds = [fromBuildId, toBuildId].filter((i): i is number => i !== null).distinct();
        for (const buildId of buildIds) dispatch(setBuildAwaiting(buildId));

        const service = new TapService();
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Updating));
        const updatedTap = await service.moveTap(tapId, toElementId, toBuildId);
        if (updatedTap) {
            dispatch(actions.updateSchrodingerTap(updatedTap));
            dispatch(actions.updateSchrodingerTethers(updatedTap.tethers));
            if (!ignoreHistory) {
                const payload: MoveTapPayload = { tapId, fromElementId, toElementId, fromBuildId, toBuildId };
                dispatch(push({ type: CommandType.MoveSchrodingerTap, payload }));
            }
        }
        NotificationService.clear(loadingToast);

        for (const buildId of buildIds) dispatch(unsetBuildAwaiting(buildId));
    };
};

export const cancelTapMove = (tapId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(actions.cancelTapMove(tapId));
    };
};