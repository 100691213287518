import React from 'react';

import { Sprite, Text } from '@inlet/react-pixi';

import { SegmentToolbar } from '../../segment-toolbar/segment-toolbar';
import { IFrameProps, SEGMENT_PROPORTIONS } from '../build.types';
import { Location } from '../location/location';
import { Tap } from '../tap/tap';
import { useSegment } from './segment.hooks';
import { CanvasSegmentProps } from './segment.types';

export const Segment: React.FC<IFrameProps & CanvasSegmentProps> = (props) => {
    const {
        xPositionSegment, yPositionSegment,
        xPositionText, yPositionText, textStyle,
        xPositionToolbar, yPositionToolbar,
        zIndex,
        image,
        spanText,
        hitArea,
        isSelected,
        index,
        statusImage,
        fromId,
        toId,
        fromTapId,
        toTapId,
        position,
        isSelectedForDelete,
        disabled,
        cursor,
        slackText,
        slackTextStyle,
        handleMouseOver,
        handleMouseOut,
        handleClick,
    } = useSegment(props);

    return <>
        {
            (index === 0) && (fromTapId ? (
                <Tap key={fromId} position={position} index={index} tapId={fromTapId} />
            ) : (
                <Location key={fromId} position={position} index={index} elementId={fromId} disabled={disabled} />
            ))
        }
        {
            statusImage && (
                <Sprite
                    image={statusImage}
                    x={xPositionSegment}
                    y={yPositionSegment}
                    anchor={{ x: 0, y: 0.5 }}
                    zIndex={zIndex - 4}
                />
            )
        }

        <Sprite
            image={image}
            x={xPositionSegment}
            y={yPositionSegment}
            anchor={{ x: 0, y: 0.5 }}
            interactive={true}
            click={handleClick}
            hitArea={hitArea}
            mouseover={handleMouseOver}
            mouseout={handleMouseOut}
            cursor={cursor}
            zIndex={zIndex}
        />
        <Text
            text={spanText}
            x={xPositionText}
            y={yPositionText}
            anchor={{ x: 0.5, y: 0 }}
            roundPixels={true}
            style={textStyle}
            zIndex={zIndex}
        />
        {!!slackText && (
            <Text
                text={slackText}
                x={xPositionSegment + SEGMENT_PROPORTIONS.SLACK_TEXT_X_OFFSET}
                y={yPositionSegment + SEGMENT_PROPORTIONS.SLACK_TEXT_Y_OFFSET}
                anchor={{ x: 0.5, y: 0.5 }}
                roundPixels={true}
                style={slackTextStyle}
            />
        )}
        {
            (toTapId ? (
                <Tap key={toId} position={position} index={index + 1} tapId={toTapId} />
            ) : (
                <Location key={toId} position={position} index={index + 1} elementId={toId} isSelectedForDelete={isSelectedForDelete} disabled={disabled} />
            ))
        }
        {isSelected && <SegmentToolbar position={{ x: xPositionToolbar, y: yPositionToolbar }} />}
    </>
};