import './flexnap-preterm-card.scss';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RadioProps } from '@orbit/radio';

import lockedIcon from '../../../icons/locked.png';
import pretermIcon from '../../../icons/preterm.png';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { applyPretermConfig, revertPretermLateral } from '../../../redux/build.state';
import { StateModel } from '../../../redux/reducers';
import { selectTether } from '../../../redux/selection.state';
import { deleteTether } from '../../../redux/tap.state';
import { FlexNapPretermCardProps } from './flexnap-preterm-card.types';

const PRETERM_FIBER_COUNT = 12;

const generateIndex = (tetherIndex: number, tapIndex: number): number => {
    return (tetherIndex * tapIndex) + (tetherIndex % tapIndex);
};

const generateFiberCounts = (maxFiberCount: number): RadioProps[] => {
    return [1, 2, 3, 4].map(i => {
        const itemFiberCount = i * PRETERM_FIBER_COUNT;
        return { value: `${i}`, label: `${itemFiberCount}`, key: `preterm-fiber-count-${itemFiberCount}`, disabled: i > maxFiberCount };
    });
};

const generateStartIndices = (pretermWidth: number, unavailableIndices?: number[]): RadioProps[] => {
    return [1, 2, 3, 4].map(i => {
        let disabled = 5 - i < pretermWidth;

        if (unavailableIndices?.length) {
            unavailableIndices.sort();

            disabled = unavailableIndices.includes(i) ||
                (unavailableIndices.find(un => un > i) ?? 5) - i < pretermWidth;
        }
        return { value: `${i}`, label: `${i}`, key: `preterm-start-index-${i}`, disabled };
    });
};

const generateSelectedValue = (value: number | undefined): string => {
    return `${value ?? 1}`;
};

const getOtherPretermTetherIds = (selectedPretermBuildId: number, napPretermBuilds?: FlexNapBuild[]): number[] | undefined => {
    return napPretermBuilds?.reduce((ids: number[], pretermBuild) => {
        if (pretermBuild.id !== selectedPretermBuildId && pretermBuild.pretermLateral?.parentTetherIds) {
            ids.push(...pretermBuild.pretermLateral.parentTetherIds);
        }

        return ids;
    }, [] as number[]);
};

export const useFlexNapPretermCard = (props: FlexNapPretermCardProps) => {
    const { disabled, build, napPretermBuilds } = props;
    const pretermCardIcon = disabled ? lockedIcon : pretermIcon;
    const { taps, tethers } = useSelector((state: StateModel) => state.taps);
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [fiberCountValues, setFiberCountValues] = useState(generateFiberCounts(4));
    const [startingIndexValues, setStartingIndexValues] = useState(generateStartIndices(build.pretermLateral?.parentTetherIds.length ?? 1));
    const [selectedFiberCount, setSelectedFiberCount] = useState(generateSelectedValue(build.pretermLateral?.parentTetherIds.length));
    const [selectedStartingIndex, setSelectedStartingIndex] = useState(generateSelectedValue(1));

    const pretermTetherIds = build.pretermLateral?.parentTetherIds;

    useEffect(() => {
        if (pretermTetherIds && pretermTetherIds.length > 0) {
            setSelectedFiberCount(generateSelectedValue(pretermTetherIds.length));

            const startingTether = tethers.find(t => t.id === pretermTetherIds[0]);
            const startingTap = taps.find(t => t.id === startingTether?.tapId);
            if (startingTap && startingTether) {
                setSelectedStartingIndex(generateSelectedValue(startingTether.tetherIndex + (startingTap.tapIndex === 1 ? 0 : startingTap.tapIndex)));
            }
        }
    }, [pretermTetherIds, tethers, taps, selectedFiberCount, selectedStartingIndex]);

    useEffect(() => {
        if (!build.pretermLateral) return;

        const pretermStartingTether = tethers.find(t => t.id === build.pretermLateral?.parentTetherIds[0]);
        const pretermStartingTap = taps.find(t => t.id === pretermStartingTether?.tapId);

        if (pretermStartingTap && pretermStartingTether) {
            const pretermTetherIndex = generateIndex(pretermStartingTether.tetherIndex, pretermStartingTap.tapIndex);
            let maxFiberCount = 5 - pretermTetherIndex;
            let maxFiberCountFound = false;
            const unavailableIndeces: number[] = [];
            const napTaps = taps.filter(t => t.napId === build.pretermLateral?.parentNapId).sort((t1, t2) => t1.tapIndex - t2.tapIndex);

            napTaps.forEach(t => {
                tethers.filter(th => th.tapId === t.id)
                    .sort((th1, th2) => th1.tetherIndex - th2.tetherIndex)
                    .filter(th =>
                        !!th.terminal ||
                        napPretermBuilds?.some(b => b.id !== build.id && b.pretermLateral?.parentTetherIds.includes(th.id))
                    )
                    .forEach(th => {
                        const tetherIndex = generateIndex(th.tetherIndex, t.tapIndex);
                        if (tetherIndex > pretermTetherIndex && !maxFiberCountFound) {
                            maxFiberCount = tetherIndex - pretermTetherIndex;
                            maxFiberCountFound = true;
                        }

                        unavailableIndeces.push(tetherIndex);
                    });
            });

            setFiberCountValues(generateFiberCounts(maxFiberCount));
            setStartingIndexValues(generateStartIndices(build.pretermLateral.parentTetherIds.length, unavailableIndeces));
        }
    }, [taps, tethers, napPretermBuilds, build]);

    const unselectPreterm = useCallback((): void => {
        dispatch(selectTether());
    }, [dispatch]);

    const revertPreterm = useCallback(() => {
        const pretermTetherIds = build.pretermLateral?.parentTetherIds;
        revertPretermLateral(build.id)(dispatch);

        if (pretermTetherIds) {
            pretermTetherIds.forEach(tetherId => {
                const tether = tethers.find(t => t.id === tetherId);
                if (tether) {
                    const tap = taps.find(t => t.id === tether.tapId);

                    if (tap?.tapIndex !== 1 || tether.tetherIndex !== 1) {
                        deleteTether(tether)(dispatch);
                    }
                }
            });
        }
    }, [dispatch, build, taps, tethers]);

    const handleFiberCountClick = useCallback((event: React.ChangeEvent<any>): void => {
        const value = event.currentTarget.value;

        applyPretermConfig(
            build.id,
            value * 12,
            +selectedStartingIndex,
            build.pretermLateral?.parentTetherIds,
            getOtherPretermTetherIds(build.id, napPretermBuilds)
        )(dispatch);
    }, [build, selectedStartingIndex, napPretermBuilds, dispatch]);

    const handleStartIndexClick = useCallback((event: React.ChangeEvent<any>): void => {
        const value = event.currentTarget.value;

        applyPretermConfig(
            build.id,
            +selectedFiberCount * 12,
            +value,
            build.pretermLateral?.parentTetherIds,
            getOtherPretermTetherIds(build.id, napPretermBuilds)
        )(dispatch);
    }, [build, selectedFiberCount, napPretermBuilds, dispatch]);

    const toggleCollapse = (): void => {
        const newCollapsed = !collapsed;
        setCollapsed(newCollapsed);
    }

    return {
        disabled, pretermCardIcon, unselectPreterm,
        build, toggleCollapse, collapsed,
        fiberCountValues, selectedFiberCount, startingIndexValues, selectedStartingIndex,
        handleFiberCountClick, handleStartIndexClick,
        revertPreterm
    };
};