import { CpqTetherPart } from '../../models/cpqtetherpart';
import { DataValue } from '../../models/datavalue';
import { Terminal } from '../../models/terminal';
import { Units } from '../../models/units';
import { Data } from './data';

export class TetherData extends Data {
    public legLength: DataValue;
    public loopback: string;
    public tetherIndex: number;
    public fiberCount: number;
    public part?: CpqTetherPart;
    public terminal?: Terminal;

    constructor(id: number, legLength: number, legLengthUnit: Units, loopback: string, tetherIndex: number, fiberCount: number, part?: CpqTetherPart, terminal?: Terminal) {
        super(id);
        this.legLength = new DataValue(legLength, legLengthUnit);
        this.loopback = loopback;
        this.tetherIndex = tetherIndex;
        this.fiberCount = fiberCount;
        this.terminal = terminal;
        this.part = part;
    }
}
