import i18next from 'i18next';
import { transform } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import React from 'react';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { isOfType } from '../../../models/element';
import { ElementCardState } from '../../../models/element-card-state';
import { ElementType } from '../../../models/element-type';
import { getPowerStatus, PowerStatus, powerStatuses } from '../../../models/power-status';
import { Vault } from '../../../models/vault';
import { deleteElement } from '../../../redux/element.actions';
import { connect, StateModel } from '../../../redux/reducers';
import { selectElement } from '../../../redux/selection.state';
import { updateVault } from '../../../redux/vault.state';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { InlineText } from '../../ui-elements/inline-text';
import { InlineSelect } from '../../ui-elements/inline-select';
import { DeleteElementDialog } from './delete-element-dialog';

const mapStateToProps = (state: StateModel) => {
    const { selectedElement } = state.selection;
    const selectedElementId = selectedElement?.id;
    const isConnectedToPath = !!selectedElementId && !![...state.bore.bores, ...state.trench.trenches].find((p) => p.fromElementId === selectedElementId || p.toElementId === selectedElementId);
    const isConnectedToSegment = !!selectedElementId && !![...state.build.segments].find((s) => s.fromId === selectedElementId || s.toId === selectedElementId);
    return { selectedElement, isConnectedToPath, isConnectedToSegment };
};
const mapDispatchToProps = {
    selectElement,
    updateVault,
    deleteElement
};
type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class VaultCardComponent extends React.Component<props, ElementCardState> {
    private EPSG4326 = new Projection({ code: 'EPSG:4326' });
    private EPSG3857 = new Projection({ code: 'EPSG:3857' });

    constructor(props) {
        super(props);
        this.state = { deleteDialog: false };
    }

    public componentDidMount(): void {
        document.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    private handleKeyUp = (e: KeyboardEvent): void => {
        const { selectedElement, isConnectedToPath, isConnectedToSegment } = this.props;

        if (e.target instanceof HTMLInputElement) {
            return;
        }

        if (!isOfType<Vault>(selectedElement, ElementType.Vault) || isConnectedToPath || isConnectedToSegment) {
            return;
        }

        if (e.key === 'Delete') {
            if (selectedElement) {
                if (selectedElement.isImported) {
                    this.showDeleteDialog(true);
                } else {
                    this.deleteVault();
                }
            }
        }
    }

    private showDeleteDialog = (show: boolean) => {
        this.setState({ deleteDialog: show });
    }

    private unselectVault = () => {
        this.props.selectElement!();
    }

    private deleteVault = () => {
        const { selectedElement } = this.props;
        if (selectedElement) {
            this.props.deleteElement!(selectedElement.id, selectedElement.type);
        }
        this.unselectVault();
    }

    private handlePowerStatusUpdate = (value?: string) => {
        const powerStatus: PowerStatus | undefined = value && PowerStatus[value];
        const { selectedElement } = this.props;
        if (selectedElement && powerStatus) {
            const vault = selectedElement as Vault;
            const newVault: Vault = { ...vault, powerStatus };
            this.props.updateVault!(vault, newVault);
        }
    }

    private handleIdOverrideUpdate = (value: string): void => {
        const { selectedElement } = this.props;
        if (selectedElement) {
            const vault = selectedElement as Vault;
            const newVault: Vault = { ...vault, tagOverride: value ? value : undefined };
            this.props.updateVault!(vault, newVault);
        }
    }

    public render() {
        const { selectedElement, isConnectedToPath, isConnectedToSegment } = this.props;
        if (!isOfType<Vault>(selectedElement, ElementType.Vault)) {
            return null;
        }

        const isImported = selectedElement.isImported;
        const powerStatus = selectedElement.powerStatus ?? PowerStatus.None;
        const coordinates = transform([selectedElement.x, selectedElement.y], this.EPSG3857, this.EPSG4326);
        let titleDeleteBtn = '';
        if (isConnectedToPath) {
            titleDeleteBtn = i18next.t(LocalizationKeys.ConnectedPathWarning);
        } else if (isConnectedToSegment) {
            titleDeleteBtn = i18next.t(LocalizationKeys.ConnectedSegmentWarning);
        }
        return (
            <Card id="vault-info" onCloseClick={this.unselectVault}>
                <CardHeaderComponent title="Vault" subheader={selectedElement.tagOverride ?? selectedElement.tag} icon={require('./../../../icons/underground.png')} />
                <CardContentComponent>
                    <InlineText
                        id="idOverride"
                        label={i18next.t(LocalizationKeys.MandatoryField, { fieldName: i18next.t(LocalizationKeys.IDOverride) })}
                        value={selectedElement.tagOverride ?? selectedElement.tag}
                        align={'right'}
                        onUpdate={this.handleIdOverrideUpdate}
                    />
                    {CardPropertyInline(i18next.t(LocalizationKeys.Longitude), `${coordinates[0].toFixed(5)}`)}
                    {CardPropertyInline(i18next.t(LocalizationKeys.Latitude), `${coordinates[1].toFixed(5)}`)}
                    <InlineSelect
                        key="powerStatus"
                        label={i18next.t(LocalizationKeys.Power)}
                        value={powerStatus}
                        values={powerStatuses}
                        displayValues={powerStatuses.map(getPowerStatus)}
                        onUpdate={this.handlePowerStatusUpdate}
                        maxHeight={96}
                    />
                    <div className="split-item extra-info">{i18next.t(LocalizationKeys.IdUsedForCableLabeling)}</div>
                    <div className="card-footer" title={titleDeleteBtn}>
                        <TextButton
                            palette={MainPalettes.error}
                            disabled={isConnectedToPath || isConnectedToSegment}
                            onClick={(): void => isImported ? this.showDeleteDialog(true) : this.deleteVault()}
                        >
                            <i className="material-icons">delete</i>
                        </TextButton>
                    </div>
                </CardContentComponent>
                <DeleteElementDialog open={this.state.deleteDialog} elementId={selectedElement.id} onDelete={this.deleteVault} onClose={(): void => this.showDeleteDialog(false)} />
            </Card>
        );
    }
}
