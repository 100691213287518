import * as rfc from 'rfc6902';

import { BuildSegment } from '../models/build-segment';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { Tap } from '../models/schrodinger/tap';
import { Tether } from '../models/schrodinger/tether';
import { SecuredService } from './abstract-secured-v2.service';
import { AddBuildRequest } from './build.service';
import { FileResult } from './file.result';

export class SchrodingerBuildService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/build/schrodinger';

    public addSchrodingerBuild(createSchrodingerBuildRequest: AddBuildRequest): Promise<AddSchrodingerBuildResponse | undefined> {
        return this.post(`${this.baseUrl}`, createSchrodingerBuildRequest);
    }

    public addCanvasSchrodingerBuild(createSchrodingerBuildRequest: AddCanvasBuildRequest): Promise<AddSchrodingerBuildResponse | undefined> {
        return this.post(`${this.baseUrl}/canvas`, createSchrodingerBuildRequest);
    }

    public updateBuild(current: SchrodingerBuild, updated: SchrodingerBuild): Promise<SchrodingerBuild | undefined> {
        const patch = rfc.createPatch(current, updated);
        return this.patch(`${this.baseUrl}/${current.id}`, patch);
    }

    public updateBuildFromSession(buildId: number): Promise<SchrodingerBuild | undefined> {
        return this.post(`${this.baseUrl}/${buildId}/session`);
    }

    public deleteBuild(buildId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${buildId}`);
    }

    public undoDeleteBuild(buildId: number): Promise<AddSchrodingerBuildResponse | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${buildId}`);
    }

    public deleteBuilds(buildIds: number[], tapIds: number[]): Promise<void> {
        return this.post(this.baseUrl + '/delete', { buildIds, tapIds });
    }

    public undoDeleteBuilds(buildIds: number[], tapIds: number[]): Promise<AddSchrodingerBuildsResponse | undefined> {
        return this.post(`${this.baseUrl}/undo/delete`, { buildIds, tapIds });
    }

    public applyPreset(buildId: number, presetId: number): Promise<SchrodingerBuild | undefined> {
        return this.post(`${this.baseUrl}/${buildId}/preset/${presetId}`);
    }

    public updateLocationIdFromElement(buildId: number, segmentId: number, elementId: number): Promise<void> {
        return this.post(`${this.baseUrl}/${buildId}/segment/${segmentId}/element/${elementId}`);
    }

    public exportBuildSession(buildId: number): Promise<FileResult | undefined> {
        return this.postDownload(`${this.baseUrl}/${buildId}/export`);
    }

    public uploadBuild(buildId: number): Promise<SchrodingerBuild | undefined> {
        return this.post(`${this.baseUrl}/${buildId}/upload`);
    }
}
export interface AddCanvasBuildRequest {
    workspaceId: number;
    designAreaId: number;
    presetId: number;
    spans: number[];
}

export interface AddSchrodingerBuildResponse {
    build: SchrodingerBuild;
    segments: BuildSegment[];
}

interface AddSchrodingerBuildsResponse {
    builds: SchrodingerBuild[];
    segments: BuildSegment[];
    taps: Tap[];
    tethers: Tether[];
}
