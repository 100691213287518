import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tether } from '../../models/schrodinger/tether';
import { updateSchrodingerBuildFromSession } from '../../redux/build.state';
import {
    designModeSelector, schrodingerBuildsSelector, tapsSchrodingerSelector,
    tethersSchrodingerSelector
} from '../../selectors/root.selectors';

export const PartManager = () => {
    const taps = useSelector(tapsSchrodingerSelector);
    const builds = useSelector(schrodingerBuildsSelector);
    const tethers = useSelector(tethersSchrodingerSelector);
    const designMode = useSelector(designModeSelector);

    const [previousTaps, setPreviousTaps] = useState(taps);
    const [previousTethers, setPreviousTethers] = useState(tethers);

    const dispatch = useDispatch();

    const isBuildInCurrentDesignMode = useCallback((buildId: number): boolean => {
        if (designMode === undefined) {
            return false;
        }

        return builds?.find(b => b.id === buildId)?.designMode === designMode;
    }, [builds, designMode]);

    useEffect(() => {
        const tapWithUpdatedBuildId = taps.find(tap => {
            const previousTap = previousTaps?.find(t => t.id === tap.id);
            if (previousTap) {
                return previousTap.buildId !== tap.buildId;
            }

            return false;
        });
        if (tapWithUpdatedBuildId) {
            if (tapWithUpdatedBuildId.buildId && isBuildInCurrentDesignMode(tapWithUpdatedBuildId.buildId)) {
                dispatch(updateSchrodingerBuildFromSession(tapWithUpdatedBuildId.buildId));
            }
            const previousTapBuildId = previousTaps.find(previousTap => previousTap.id === tapWithUpdatedBuildId.id)?.buildId;
            if (previousTapBuildId) {
                const buildStillExists = !!builds.find(b => b.id === previousTapBuildId);
                if (buildStillExists && isBuildInCurrentDesignMode(previousTapBuildId)) {
                    dispatch(updateSchrodingerBuildFromSession(previousTapBuildId));
                }
            }
        }
        const tapDeletedFromBuild = previousTaps.find(previousTap => !!taps && !!previousTap.buildId && !taps?.find(t => t.id === previousTap.id));
        const buildIsLocked = builds?.find(b => b.id === tapDeletedFromBuild?.buildId)?.lockedById;
        if (tapDeletedFromBuild?.buildId && isBuildInCurrentDesignMode(tapDeletedFromBuild.buildId) && !buildIsLocked) {
            dispatch(updateSchrodingerBuildFromSession(tapDeletedFromBuild.buildId));
        }
        setPreviousTaps(taps);
    }, [builds, dispatch, previousTaps, taps, isBuildInCurrentDesignMode]);

    useEffect(() => {
        let tether: Tether | undefined;

        // one tether is either added or deleted
        // if multiple tethers are added, then it is on load of the application, and updateSchrodingerBuildFromSession should not be executed
        switch (tethers.length - previousTethers.length) {
            case 1:
                tether = tethers.find(t => !previousTethers.some(pt => pt.id === t.id));
                break;
            case -1:
                tether = previousTethers.find(t => !tethers.some(pt => pt.id === t.id));
                break;
        }

        if (tether) {
            const tetherBuildId = taps.find(tap => tap.id === tether?.tapId)?.buildId;
            const buildIsLocked = builds?.find(b => b.id === tetherBuildId)?.lockedById;
            if (tetherBuildId && isBuildInCurrentDesignMode(tetherBuildId) && !buildIsLocked) {
                dispatch(updateSchrodingerBuildFromSession(tetherBuildId));
            }
        }

        setPreviousTethers(tethers);
    }, [dispatch, tethers, previousTethers, taps, isBuildInCurrentDesignMode, builds]);

    return null;
}