import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { StateModel } from '../../../redux/reducers';

import { DialogType, showDialog } from "../../../redux/overlay.state";
import { patchTerminalExtension } from '../../../redux/tap.state';
import { TerminalExtensionType } from '../../../models/terminal-extension-type';

export const useFlexnapExtensionCard = () => {
    const dispatch = useDispatch();

    const { selection, taps } = useSelector((state: StateModel) => state);
    const { selectedTerminalId } = selection;

    const onResetTerminalExtensionClick = useCallback((): void => {
        dispatch(showDialog(DialogType.ResetTerminalExtension));
    }, [dispatch]);

    const switchTerminalExtensionType = useCallback((type: TerminalExtensionType): void => {
        if (selectedTerminalId !== undefined) {
            const extension = taps?.tethers.find(t => t.terminal?.id === selectedTerminalId)?.terminal?.terminalExtension;
            if (extension) {
                dispatch(patchTerminalExtension(extension, {...extension, terminalExtensionType: type}));
            }
        }
    }, [dispatch, selectedTerminalId, taps]);

    return { onResetTerminalExtensionClick, switchTerminalExtensionType };
};