import { IPointData, Rectangle } from 'pixi.js';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UnitInterpreter } from '../../../../helpers/unit-interpreter';
import { BuildSegment } from '../../../../models/build-segment';
import { DialogType } from '../../../../redux/overlay.state';
import { selectSegment } from '../../../../redux/selection.state';
import { setSelectHighlighted } from '../../../../redux/viewport.state';
import {
    dialogSelector, mapSelector, segmentsSelector, selectedSegmentIdSelector, tapsSchrodingerSelector
} from '../../../../selectors/root.selectors';
import { ItemType } from '../../../../validation/item-type';
import { CanvasTextStyles } from '../build.styles';
import {
    cableStartLocation, IFrameProps, LOCATION_PROPORTIONS, SEGMENT_PROPORTIONS
} from '../build.types';
import deleteConnector from './assets/segment-connector-delete-highlight.svg';
import hoveredConnector from './assets/segment-connector-hovered.svg';
import selectedConnector from './assets/segment-connector-selected.svg';
import deleteConnectorSlack from './assets/segment-connector-slack-delete-highlight.svg';
import hoveredConnectorSlack from './assets/segment-connector-slack-hovered.svg';
import selectedConnectorSlack from './assets/segment-connector-slack-selected.svg';
import connectorSlack from './assets/segment-connector-slack.svg';
import connector from './assets/segment-connector.svg';
import connectorLockedOutline from './assets/segment-locked-outline.svg';
import connectorSlackLockedOutline from './assets/segment-slack-locked-outline.svg';
import { CanvasSegmentProps } from './segment.types';

export const getLocationPosition = (index: number, position: IPointData) => {
    const { x, y } = position;
    //! Note - test with new location svgs to confirm correct positions
    const xPos = x + LOCATION_PROPORTIONS.GAP * (index + 1) - LOCATION_PROPORTIONS.RADIUS;
    return { x: xPos, y }
}

export const useSegment = (props: IFrameProps & CanvasSegmentProps) => {
    const { position, segmentId, index, span, unit, disabled, locked } = props;
    const cableLocation = cableStartLocation(position);
    const { x: xPositionSegment, y: yPositionSegment } = getLocationPosition(index, cableLocation);

    const selectedSegmentId = useSelector(selectedSegmentIdSelector);
    const segments = useSelector(segmentsSelector);
    const taps = useSelector(tapsSchrodingerSelector);
    const { clickedNotification } = useSelector(mapSelector);

    const dialogType = useSelector(dialogSelector);
    const [isHovered, setIsHovered] = useState(false);

    const dispatch = useDispatch();

    const xPositionText = xPositionSegment + (SEGMENT_PROPORTIONS.WIDTH_CONNECTOR / 2);
    const yPositionText = yPositionSegment + SEGMENT_PROPORTIONS.TEXT_TOP_POSITION;
    const textStyle = locked ? CanvasTextStyles.smallSemiBold : CanvasTextStyles.smallSemiBoldBlue;
    const slackTextStyle = locked ? CanvasTextStyles.tinyQuarterBold : CanvasTextStyles.tinyQuarterBoldBlue;
    const spanText = span ? `${span} ${unit ? UnitInterpreter.toShort(unit) : ''}` : '';
    const isSelected = !!selectedSegmentId && selectedSegmentId === segmentId;

    const isSelectedForDelete = isSelected && dialogType === DialogType.CanvasDeleteSegment;
    const isInError = clickedNotification?.itemType === ItemType.Segment && clickedNotification?.itemId === segmentId;
    const hitArea = new Rectangle(0, -25, 300, 50);
    const segment = segments.find(({ id }) => id === segmentId);
    const fromId = segment?.fromId;
    const toId = segment?.toId;
    const slackText = !!segment?.slackLoop && getSlackText(segment);
    const image = getImage(isHovered, isSelected, !!disabled, isSelectedForDelete, !!segment?.slackLoop, isInError);
    const statusImage = locked && (segment?.slackLoop ? connectorSlackLockedOutline : connectorLockedOutline);
    const fromTapId = index === 0 ? taps.find(({ elementId }) => elementId === fromId)?.id : undefined;
    const toTapId = taps.find(({ elementId }) => elementId === toId)?.id;
    const cursor = !disabled ? 'pointer' : '';

    const handleMouseOver = () => setIsHovered(true);

    const handleMouseOut = () => setIsHovered(false);

    const xPositionToolbar = xPositionText;
    const yPositionToolbar = yPositionSegment + (segment?.slackLoop ? SEGMENT_PROPORTIONS.SLACK_TOOLBAR_Y_POSITION : SEGMENT_PROPORTIONS.TOOLBAR_Y_POSITION);

    const handleClick = useCallback(() => {
        if (segmentId) {
            dispatch(selectSegment(segmentId));
            dispatch(setSelectHighlighted(index + 1));
        }
    }, [dispatch, segmentId, index]);

    return {
        xPositionSegment, yPositionSegment,
        xPositionText, yPositionText, textStyle,
        xPositionToolbar, yPositionToolbar,
        zIndex: index,
        image,
        spanText,
        hitArea,
        isSelected,
        index,
        statusImage,
        fromId,
        toId,
        fromTapId,
        toTapId,
        position,
        isSelectedForDelete,
        disabled,
        cursor,
        slackText,
        slackTextStyle,
        handleMouseOver,
        handleMouseOut,
        handleClick,
    };
}

const getImage = (isHovered: boolean, isSelected: boolean, disabled: boolean, isSelectedForDelete: boolean, slack: boolean, error: boolean): string => {
    if (slack) {
        if (isSelectedForDelete) return deleteConnectorSlack;
        if (isSelected) return selectedConnectorSlack;
        if (isHovered && !disabled) return hoveredConnectorSlack;
        return connectorSlack;
    }
    else {
        if (isSelectedForDelete || error) return deleteConnector;
        if (isSelected) return selectedConnector;
        if (isHovered && !disabled) return hoveredConnector;
        return connector;
    }
};

const getSlackText = ({ slackLoop, slackUnit }: BuildSegment): string => {
    let magnitude = slackLoop.toFixed(0);
    if (slackLoop < 1) magnitude = "<1";
    if (slackLoop > 999) magnitude = ">999";
    return `${magnitude} ${UnitInterpreter.toShort(slackUnit)}`;
};
