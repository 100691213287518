import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { login } from '../../redux/authentication.state';
import { StateModel } from '../../redux/reducers';
import { OktaConfig } from '../../okta/okta.config';

import './login.component.scss';

const mapStateToProps = (state: StateModel) => {
    const { loggedIn, customer } = state.authentication;
    const hasCustomer = !!customer;
    return { loggedIn, hasCustomer };
};
const mapDispatchToProps = {
    login,
};
type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class LoginComponent extends React.Component<props> {
    public render(): JSX.Element | null {
        const { loggedIn, hasCustomer } = this.props;
        console.log('login page', loggedIn);
        if (loggedIn) {
            return <Redirect to="/" />;
        }

        OktaConfig.getConfig().then((config) => {
            if(!hasCustomer && !config.enabled) {
                console.error('No Hybris customer and Okta is disabled, the application may not function');
            }
        });

        this.props.login!();
        return null;
    }
}
