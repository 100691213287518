import axios from 'axios';

import { OktaSession } from '../models/okta-session';
import { Token } from '../models/token';
import { TokenLike } from '../models/tokenlike';

export class OktaStorageService {
    private static SS_OKTA_SESSION_KEY = 'okta_session';
    public static async loadOktaSession(): Promise<void> {
        if (!window.sessionStorage.getItem(this.SS_OKTA_SESSION_KEY)) {
            const axiosClient = axios.create();
            const res = await axiosClient.get<OktaSession>('https://corning.oktapreview.com/api/v1/sessions/me', { withCredentials: true });
            window.sessionStorage.setItem(this.SS_OKTA_SESSION_KEY, JSON.stringify(res.data));
        }
    }

    public static getOktaSession(): OktaSession | undefined {
        const oktaSession = window.sessionStorage.getItem(this.SS_OKTA_SESSION_KEY) ?? "";
        if (!oktaSession) return undefined;
        return JSON.parse(oktaSession);
    }

    private static oktaToken: string | undefined;
    private static LS_OKTA_TOKEN_KEY = 'okta_token';
    public static getToken(): Token | null {
        if (!OktaStorageService.oktaToken) {
            OktaStorageService.oktaToken = window.localStorage.getItem(this.LS_OKTA_TOKEN_KEY) || undefined;
            if (!OktaStorageService.oktaToken) {
                return null;
            }
        }

        return Token.fromJwt(OktaStorageService.oktaToken);
    }

    public static saveToken(token: TokenLike | null): void {
        if (token) {
            window.localStorage.setItem(this.LS_OKTA_TOKEN_KEY, token.token);
            OktaStorageService.oktaToken = token.token;
        }
        else {
            window.localStorage.removeItem(this.LS_OKTA_TOKEN_KEY);
            OktaStorageService.oktaToken = undefined;
        }
    }
}
