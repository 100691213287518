import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../models/design-mode';
import CorningRoles from '../../redux/corning-roles.json';
import { updateLastUsedDesignMode } from '../../redux/workspace.state';
import { designModeSelector, rolesSelector } from '../../selectors/root.selectors';
import { DesignModeSelectProps } from './design-mode-select';

export const useDesignModeSelect = (props: DesignModeSelectProps) => {
    const dispatch = useDispatch();

    const { workspace } = props;
    const designMode = useSelector(designModeSelector);
    const { roles } = useSelector(rolesSelector);
    const hasFlexNapRole = roles?.some((role) => role === CorningRoles.FlexNAP || role === CorningRoles.FlexNAPAdmin) ?? false;
    const [showSelect, setShowSelect] = useState(!(!designMode && hasFlexNapRole));

    useEffect(() => {
        const shouldUpdateFlexNapMode = !designMode && hasFlexNapRole;
        setShowSelect(!shouldUpdateFlexNapMode);
        if (shouldUpdateFlexNapMode) {
            updateLastUsedDesignMode(DesignMode.GISMode)(dispatch);
        }
    }, [designMode, hasFlexNapRole, dispatch]);

    const onMapModeClicked = useCallback(() => {
        if (workspace)
            updateLastUsedDesignMode(DesignMode.GISMode)(dispatch)
    }, [dispatch, workspace]);

    const onCanvasModeClicked = useCallback(() => {
        if (workspace)
            updateLastUsedDesignMode(DesignMode.CanvasMode)(dispatch)
    }, [dispatch, workspace]);


    return {
        showSelect,
        onMapModeClicked, onCanvasModeClicked
     };
}