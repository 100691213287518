import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Parcel, ParcelData } from '../models/parcel';
import { GeoserverService } from '../services/geoserver.service';
import { requestIsSuccess } from './action';
import { updateSchrodingerBuildLocationIds } from './element.actions';
import { IMPORT_LOAD_THRESHOLD } from './import.state';
import { updateSelectedElement } from './selection.state';

export interface ParcelState {
    parcels: Parcel[];
}
const initialState: ParcelState = {
    parcels: [],
};

const loadAllReducer = (state: ParcelState, action: PayloadAction<Parcel[] | undefined>) => { state.parcels = action.payload || []; };

const deleteParcelReducer = (state: ParcelState, action: PayloadAction<number>) => {
    state.parcels = state.parcels.filter((p) => p.id !== action.payload);
};

const deleteImportedParcelsReducer = (state: ParcelState, action: PayloadAction<number>) => {
    state.parcels = state.parcels.filter((p) => p.importedGisDataId !== action.payload);
};

const updateParcelReducer = (state: ParcelState, action: PayloadAction<Parcel>) => {
    const parcel: Parcel = action.payload;
    const hIdx = state.parcels.findIndex((p) => p.id === parcel.id);
    if (hIdx < 0) {
        state.parcels.push(parcel);
    }
    else {
        state.parcels[hIdx] = parcel;
    }
};

const { actions, reducer } = createSlice({
    name: 'parcel',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        deleteParcel: deleteParcelReducer,
        updateParcel: updateParcelReducer,
        deleteImportedParcels: deleteImportedParcelsReducer,
    },
});
export { reducer as ParcelReducer };
export const { loadAll: loadParcels, deleteParcel, deleteImportedParcels } = actions;

export const getParcels = (bbox: number[]) => async dispatch => {
    const service = new GeoserverService();
    const parcels = await service.getParcelsIn(bbox);
    dispatch(actions.loadAll(parcels));
};

export const addParcels = (parcelData: ParcelData[], importedGisId?: number, bbox?: number[]) => async dispatch => {
    const service = new GeoserverService();
    const success = await requestIsSuccess(service, service.importParcels(parcelData, importedGisId), true);
    if (parcelData.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
        getParcels(bbox)(dispatch);
    }
};

export const updateParcel = (oldParcel: Parcel, newParcel: Parcel) => async dispatch => {
    const service = new GeoserverService();
    const parcel = await service.patchParcel(oldParcel, newParcel);
    if (parcel) {
        dispatch(actions.updateParcel(parcel));
        dispatch(updateSelectedElement(parcel));
            if (oldParcel.tagOverride !== parcel.tagOverride) {
                updateSchrodingerBuildLocationIds(parcel);
            }
    }
};
