import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { convertToFlexnapBuild } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { selectedBulkBuildSelector } from '../../../selectors/build.selectors';
import { dialogSelector } from '../../../selectors/root.selectors';

export interface ConvertBuildDialogProps {
    onClose: () => void;
    onConvertBuild: () => void;
    isOpen?: boolean;
    title: string;
    message?: string;
}

export const useConvertBulkBuildDialog = (): ConvertBuildDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dialog = useSelector(dialogSelector);
    const { flexnapBuilds, bulkBuilds } = useSelector((state: StateModel) => state.build);
    const build = useSelector(selectedBulkBuildSelector);
    const buildId = build?.id ?? -1;
    const isOpen = (dialog === DialogType.ConvertBulkBuild) && buildId > 0;
    const title = t(LocalizationKeys.ConvertBulkBuild);
    const message = t(LocalizationKeys.ConvertBulkBuildMessage);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onConvertBuild = useCallback(() => {
        if (build) {
            const childBuilds = [...flexnapBuilds, ...bulkBuilds].filter(b => b.buildSplice?.parentBuildId === buildId);
            convertToFlexnapBuild(build.id, undefined, childBuilds)(dispatch)
            dispatch(showDialog());
        }
    }, [build, flexnapBuilds, bulkBuilds, dispatch, buildId]);

    return {
        isOpen,
        onClose, onConvertBuild,
        title,
        message
    }
};