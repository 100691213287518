import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import { Manhole, ManholeData } from '../models/manhole';
import { PointLike } from '../models/pointlike';
import { ManholeService } from '../services/manhole.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { updateSchrodingerBuildLocationIds } from './element.actions';
import { push } from './history.state';
import { IMPORT_LOAD_THRESHOLD } from './import.state';
import { updateSelectedElement } from './selection.state';

export interface ManholeState {
    manholes: Manhole[];
}

const initialState: ManholeState = {
    manholes: [],
};

const loadAllReducer = (state: ManholeState, action: PayloadAction<Manhole[] | undefined>) => { state.manholes = action.payload || []; };

const addManholeReducer = (state: ManholeState, action: PayloadAction<Manhole>) => { state.manholes.push(action.payload); };

const addManholesReducer = (state: ManholeState, action: PayloadAction<Manhole[]>) => { state.manholes.push(...action.payload); };

const updateManholeReducer = (state: ManholeState, action: PayloadAction<Manhole>) => {
    const manhole: Manhole = action.payload;
    const mIdx = state.manholes.findIndex((p) => p.id === manhole.id);
    if (mIdx < 0) {
        state.manholes.push(manhole);
    }
    else {
        state.manholes[mIdx] = manhole;
    }
};

const deleteManholeReducer = (state: ManholeState, action: PayloadAction<number>) => {
    state.manholes = state.manholes.filter((m) => m.id !== action.payload);
};

const deleteImportedManholesReducer = (state: ManholeState, action: PayloadAction<number>) => {
    state.manholes = state.manholes.filter((m) => m.importedGisDataId !== action.payload);
};

const { actions, reducer } = createSlice({
    name: 'manhole',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        addManhole: addManholeReducer,
        addManholes: addManholesReducer,
        updateManhole: updateManholeReducer,
        deleteManhole: deleteManholeReducer,
        deleteImportedManholes: deleteImportedManholesReducer,
    },
});

export { reducer as ManholeReducer };
export const { loadAll: loadManholes, addManhole: addManholeAction, deleteManhole, deleteImportedManholes, updateManhole: modifyManhole } = actions;

export const getManholes = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new ManholeService();
    const manholes = await service.getManholes(bbox, required);
    if (manholes) {
        dispatch(actions.loadAll(manholes));
    }
};

export const getManholesByIds = (ids: number[]) => async dispatch => {
    const service = new ManholeService();
    const manholes = await service.getManholesByIds(ids);
    if (manholes) {
        manholes.forEach(manhole => dispatch(actions.updateManhole(manhole)));
    }
};

export const addManhole = (point: PointLike) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new ManholeService();
        const manhole = await service.addManhole(point);
        if (manhole) {
            dispatch(actions.addManhole(manhole));
            dispatch(push({ type: CommandType.CreateElement, payload: { id: manhole.id, type: manhole.type } }));
        }
    });
};

export const addManholes = (data: ManholeData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new ManholeService();
        const success = await requestIsSuccess(service, service.addManholes(data, importedGisId), true);
        if (data.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
            getManholes(bbox)(dispatch);
        }
    });
};

export const updateManhole = (oldManhole: Manhole, newManhole: Manhole) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new ManholeService();
        const manhole = await service.patchManhole(oldManhole, newManhole);
        if (manhole) {
            dispatch(actions.updateManhole(manhole));
            dispatch(updateSelectedElement(manhole));
            if (oldManhole.tagOverride !== manhole.tagOverride) {
                updateSchrodingerBuildLocationIds(manhole);
            }
        }
    });
};
