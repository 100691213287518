import { createSlice, Dispatch } from '@reduxjs/toolkit';

export interface DebugState {
    showErrors: boolean;
}

const initialState: DebugState = {
    showErrors: true
};

const toggleShowErrorsReducer = (state: DebugState): void => { state.showErrors = !state.showErrors; };

const { actions, reducer } = createSlice({
    name: 'debug',
    initialState,
    reducers: {
        toggleShowErrors: toggleShowErrorsReducer,
    },
});

export { reducer as DebugReducer };

export const toggleShowErrors = () => {
    return (dispatch: Dispatch): void => {
        dispatch(actions.toggleShowErrors());
    }
}
