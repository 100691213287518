import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Table, TableBody, TableContainer, Tabs } from '@mui/material';

import { LocalizationKeys } from '../../../../locales/types';
import { BuildType } from '../../../../models/build-type';
import { CableType } from '../../../../models/cable-type';
import { ImportPresetOption, ImportStepSelectPresetsProps, ImportTabs } from '../import.card.types';
import { useImportStepSelectPreset } from './import-step-select-presets.hooks';
import { SelectPresetsTableHeader, SelectPresetsTableRow } from './select-presets-table-row';

export const ImportStepSelectPresets = (props: ImportStepSelectPresetsProps): JSX.Element => {
    const { t } = useTranslation();

    const { tab, selectedFlexnapBuildIds, selectedBulkBuildIds, selectedFlexnapPresetIds, selectedBulkPresetIds, selectedFlexnapParentIds, selectedBulkParentIds, onBuildSelectionChange, onSelectAllChange, onSelectedPresetChange, onApplyPresetToAll, onSelectedParentBuildIdChange, handleTabChange } = useImportStepSelectPreset(props);

    // Row Props
    const selectPresetOptionsPerBuild = tab === ImportTabs.Flexnap ? selectedFlexnapPresetIds : selectedBulkPresetIds;
    const selectParentIdOptionsPerBuild = tab === ImportTabs.Flexnap ? selectedFlexnapParentIds : selectedBulkParentIds;

    // Common
    const flexnapPresets: ImportPresetOption[] = useMemo(() => props.presets.map(p => (
        { value: p.id, display: p.name }
    )), [props.presets]);

    const bulkPresets: ImportPresetOption[] = useMemo(() => [
        { value: CableType.Ribbon, display: t(LocalizationKeys.Ribbon) },
        { value: CableType.LooseTube, display: t(LocalizationKeys.LooseTube) }
    ], [t]);

    const selectedBuildIds = tab === ImportTabs.Flexnap ? selectedFlexnapBuildIds : selectedBulkBuildIds;

    const presets = tab === ImportTabs.Flexnap ? flexnapPresets : bulkPresets;
    const tabMessage = tab === ImportTabs.Flexnap ? t(LocalizationKeys.BeforeImportingFlexNapBuildSelectPreset) : t(LocalizationKeys.BeforeImportingBulkBuildSelectPreset);

    const flexnapBuildsToImport = props.buildsToImportRows?.filter(b => b.buildType === BuildType.FlexNap) ?? [];
    const bulkBuildsToImport = props.buildsToImportRows?.filter(b => b.buildType === BuildType.Bulk) ?? [];
    const buildsToImport = tab === ImportTabs.Flexnap ? flexnapBuildsToImport : bulkBuildsToImport;

    const currentDisplayedBuildsToImport = tab === ImportTabs.Flexnap ? flexnapBuildsToImport : bulkBuildsToImport;

    return (
        <div>
            <div>
                <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
                    <Tab
                        label={t(LocalizationKeys.ImportFlexnapTab, {
                            flexnapCount: flexnapBuildsToImport.length > 99 ? "99+" : flexnapBuildsToImport.length
                        })}
                        value={ImportTabs.Flexnap}
                    />
                    <Tab
                        label={t(LocalizationKeys.ImportBulkTab, {
                            bulkCount: bulkBuildsToImport.length > 99 ? "99+" : bulkBuildsToImport.length
                        })}
                        value={ImportTabs.Bulk}
                    />
                </Tabs>
            </div>
            <div className='build-list'>
                <p>{tabMessage}</p>
                <TableContainer className="table-container">
                    <Table stickyHeader size="small">
                        <SelectPresetsTableHeader
                            tab={tab}
                            selectedBuildIds={selectedBuildIds}
                            parsedBuildsToImport={buildsToImport}
                            onSelectAllChange={onSelectAllChange}
                            presets={presets}
                            onApplyPresetToAll={onApplyPresetToAll}
                        />
                        <TableBody>
                            {currentDisplayedBuildsToImport.map(b =>
                                <SelectPresetsTableRow
                                    key={b.customerBuildId}
                                    parsedBuildToImport={b}
                                    selected={!!selectedBuildIds.find(s => s === b.customerBuildId)}
                                    presets={presets}
                                    selectedParentBuildIds={selectParentIdOptionsPerBuild}
                                    selectedPresetIds={selectPresetOptionsPerBuild}
                                    onBuildSelectionChange={onBuildSelectionChange}
                                    onSelectedPresetChange={onSelectedPresetChange}
                                    onSelectedParentBuildIdChange={onSelectedParentBuildIdChange}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}