import React from 'react';

export const HoverBuildIconPath = () => {
    return (
        <g transform="translate(-32 -16)" className="hover-build-icon-path">
            <circle fill="#005293" cx="20" cy="20" r="20" transform="translate(32 16)" opacity="0.8"/>
            <g transform="translate(40 24)">
                <path fill="#fdfdfd" d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm8.94,3A8.994,8.994,0,0,0,13,3.06V1H11V3.06A8.994,8.994,0,0,0,3.06,11H1v2H3.06A8.994,8.994,0,0,0,11,20.94V23h2V20.94A8.994,8.994,0,0,0,20.94,13H23V11H20.94ZM12,19a7,7,0,1,1,7-7A6.995,6.995,0,0,1,12,19Z" />
            </g>
        </g>
    );
}