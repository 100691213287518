import i18next from 'i18next';

import { ProjectionConverter } from '../helpers/projection-converter';
import { LocalizationKeys } from '../locales/types';
import { SecuredService } from './abstract-secured-v2.service';
import { AutoSuggestResult, SearchResult, SearchServiceLike } from './search.service';

const MAX_RESULTS = 7;

interface BingPoint {
    type: string;
    coordinates: number[];
}

interface BingAddress {
    adminDistrict: string;
    adminDistrict2: string;
    countryRegion: string;
    formattedAddress: string;
    locality: string;
}

interface BingGeocodePoint {
    type: string;
    coordinates: number[];
    calculationMethod: string;
    usageTypes: string[];
}

interface BingResource {
    __type: string;
    bbox: number[];
    name: string;
    point: BingPoint;
    address: BingAddress;
    confidence: string;
    entityType: string;
    geocodePoints: BingGeocodePoint[];
    matchCodes: string[];
}

export interface BingAutosuggestAddress {
    countryRegion: string;
    locality: string;
    adminDistrict: string;
    countryRegionIso2: string;
    postalCode: string;
    addressLine: string;
    formattedAddress: string;
}

export interface BingAutosuggestValue {
    __type: string;
    address: BingAutosuggestAddress;
    name: string;
}

interface BingLocationResource extends BingResource {
    __type: string;
    address: BingAddress;
    name: string;
}

export class SearchBingService extends SecuredService implements SearchServiceLike {
    private readonly baseUrl = this.rootUrl + 'api/mapsearch/bing';

    public formatSuggestion(suggestion: BingAutosuggestValue): string {
        const address = suggestion.address;
        if (suggestion.__type === "Place") {
            return `${address.locality}, ${address.adminDistrict}, ${address.countryRegion}`;
        }
        else if (suggestion.__type === "LocalBusiness") {
            return `${address.formattedAddress}, ${address.countryRegion}`;
        }
        return i18next.t(LocalizationKeys.NoResults);
    }

    public async search(query: string): Promise<SearchResult[]> {
        const bingSearchResults = await this.post<BingLocationResource[]>(`${this.baseUrl}/locations`, { query });
        if (bingSearchResults) {
            return bingSearchResults.map((bsr) => ({ bbox: ProjectionConverter.convertLatLongToMap(bsr.bbox), name: bsr.name })); // bbox is in lat/long from Bing
        }
        return [];
    }

    public async autocomplete(query: string, location: number[]): Promise<AutoSuggestResult[] | undefined> {
        if (location.length !== 2) {
            throw new Error('Autocomplete expects a lat/long location');
        }
        const res = await this.post<BingAutosuggestValue[]>(`${this.baseUrl}/autosuggest`, { query, longitude: location[0], latitude: location[1], maxResults: MAX_RESULTS });

        return res?.map(r => {
            const address = this.formatSuggestion(r);
            return {
                type: r.__type,
                address: address,
                formattedAddress: r.address.formattedAddress,
                name: r.name
            }
        });
    }

    public async findLocation(location: number[]): Promise<string | undefined> {
        if (location.length !== 2) {
            throw new Error('Find location expects a lat/long location');
        }
        const res = await this.post<BingLocationResource[]>(`${this.baseUrl}/locations`, { latitude: location[0], longitude: location[1] });

        const bingAddress = res && res[0].address;
        if (bingAddress) {
            return i18next.t(LocalizationKeys.WorkspaceLocation, { city: bingAddress.adminDistrict2, state: bingAddress.adminDistrict, country: bingAddress.countryRegion });
        }
        return i18next.t(LocalizationKeys.WorkspaceLocation, { city: "Failed", state: "Failed", country: "Failed" });
    }
}