import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { HybrisCustomer } from '../models/hybris-customer';
import { OktaSession } from '../models/okta-session';
import { Token } from '../models/token';
import { TokenLike } from '../models/tokenlike';
import { Units } from '../models/units';
import { Okta } from '../okta/okta';
import { OktaStorageService } from '../okta/okta.storage.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerStorageService } from '../services/customer.storage.service';
import { createSecuredAsyncAction } from './action';

export enum AuthenticationMode {
    OktaJwtToken = 1,
    HybrisCustomerHeaders = 2,
    HybrisCustomerHeadersWithOktaSession = 3
}

export interface AuthenticationState {
    loggedIn: boolean;
    failed: boolean;
    token?: string;
    userId?: number;
    email?: string;
    issuedAt?: number;
    expiresAt?: number;
    displayUnits: Units;
    customer?: HybrisCustomer;
    oktaSession?: OktaSession; // for hybris 20.05
    authenticationMode?: AuthenticationMode;
}

const loadToken = (token: Token | null, state: AuthenticationState): void => {
    state.token = token ? token.token : undefined;
    state.loggedIn = token ? !token.isExpired() : false;
    state.userId = token ? Number(token.sub) : undefined;
    state.email = token ? token.email : undefined;
    state.expiresAt = token ? token.exp * 1000 : undefined;
    state.issuedAt = token ? token.iat * 1000 : undefined;
    state.displayUnits = token ? token.dna_gun : Units.meters;
};

const loadHybrisCustomerInfo = (state: AuthenticationState): void => {
    CustomerStorageService.storeHybrisCustomerInfo();
    const customer: HybrisCustomer | undefined = CustomerStorageService.getHybrisCustomer();
    if (customer) {
        state.customer = customer;
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        state.expiresAt = date.getTime();
        state.loggedIn = true;
        state.displayUnits = customer.account.unitOfMeasure;
    }
}

const setAuthenticationMode = (initialState: AuthenticationState): void => {
    if (initialState.customer) {
        // eslint-disable-next-line no-undef
        if (process.env.REACT_APP_MULESOFT_PROXY_ENDPOINT !== undefined) {
            initialState.authenticationMode = AuthenticationMode.HybrisCustomerHeadersWithOktaSession;
        } else {
            initialState.authenticationMode = AuthenticationMode.HybrisCustomerHeaders;
        }
    } else {
        initialState.authenticationMode = AuthenticationMode.OktaJwtToken;
    }
}

const initialToken = OktaStorageService.getToken();
const initialState: AuthenticationState = { loggedIn: false, failed: false, displayUnits: Units.meters };
loadToken(initialToken, initialState);
loadHybrisCustomerInfo(initialState);
setAuthenticationMode(initialState);

function logoutReducer(state: AuthenticationState): void {
    OktaStorageService.saveToken(null);
    loadToken(null, state);
    state.customer = undefined;
}

function loginSuccessfulReducer(state: AuthenticationState, action: PayloadAction<string>): void {
    state.failed = false;
    const token = Token.fromJwt(action.payload);
    OktaStorageService.saveToken(token);
    loadToken(token, state);
}

function ssoLoginSuccessfulReducer(state: AuthenticationState, action: PayloadAction<TokenLike>): void {
    const data = action.payload;
    OktaStorageService.saveToken(data);
    state.failed = false;
    state.token = data ? data.token : undefined;
    state.userId = data ? Number(data.sub) : undefined;
    state.loggedIn = !!data;
    state.email = data ? data.email : undefined;
    state.expiresAt = data ? data.exp : undefined;
    state.issuedAt = data ? new Date().getTime() : undefined;

    state.displayUnits = Units.feet;
}

function loginFailedReducer(state: AuthenticationState): void {
    OktaStorageService.saveToken(null);
    loadToken(null, state);
    state.failed = true;
}

function toggleDisplayUnitsReducer(state: AuthenticationState): void {
    state.displayUnits = state.displayUnits === Units.meters ? Units.feet : Units.meters;
}

function setUserIdReducer(state: AuthenticationState, action: PayloadAction<number>): void {
    state.userId = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: logoutReducer,
        loginSuccessful: loginSuccessfulReducer,
        ssoLoginSuccessful: ssoLoginSuccessfulReducer,
        loginFailed: loginFailedReducer,
        toggleDisplayUnits: toggleDisplayUnitsReducer,
        setUserId: setUserIdReducer,
    },
});

export const login = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.logout());
    await Okta.loginWithRedirect();
};

export const logout = () => async (dispatch: Dispatch): Promise<void> => {
    const authService = new AuthenticationService();
    dispatch(actions.logout());
    await authService.logout();
    await Okta.logoutWithRedirect();
};

export const loadUserId = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const authService = new AuthenticationService();
        const userId = await authService.getUserId();
        userId && dispatch(actions.setUserId(userId));
    });
}
export { reducer as AuthenticationReducer };
export const { toggleDisplayUnits, loginSuccessful } = actions;
