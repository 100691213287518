import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { BulkBuild } from '../models/bulk/bulk-build';
import { DesignMode } from '../models/design-mode';
import { FlexNapBuild } from '../models/flexnap-build';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { SecuredService } from './abstract-secured-v2.service';
import { BuildSegmentRequest } from './segment.service';

export class BuildService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/build';

    public getBuildsByWorkspace(workspaceId: number, designMode: DesignMode): Promise<WorkspaceBuildsResult | undefined> {
        return this.get(`${this.baseUrl}/workspace/${workspaceId}/designMode/${designMode}`);
    }

    public lockBuilds(buildIds: number[], workspaceId: number): Promise<UpdatedBuildsResult | undefined> {
        return this.post(this.baseUrl + '/lock', { buildIds, workspaceId });
    }

    public unlockBuilds(buildIds: number[], workspaceId: number): Promise<UpdatedBuildsResult | undefined> {
        return this.post(this.baseUrl + '/unlock', { buildIds, workspaceId });
    }

    public unlockBuildsEs(buildIds: number[], workspaceId: number): Promise<UpdatedBuildsResult | undefined> {
        return this.post(this.baseUrl + '/unlock/es', { buildIds, workspaceId });
    }

    public modifyBuild(buildId: number): Promise<Build | undefined> {
        return this.post(this.baseUrl + '/modify', { buildId });
    }

    public releaseBuild(buildId: number): Promise<Build | undefined> {
        return this.post(this.baseUrl + '/release', { buildId });
    }

    public getBuildCoordinates(buildId: number): Promise<BuildCoordinates | undefined> {
        return this.get(this.baseUrl + '/' + buildId + '/coordinates');
    }
}

export interface AddBuildRequest {
    workspaceId: number;
    segments: BuildSegmentRequest[];
}

export interface WorkspaceBuildsResult {
    flexNapBuilds?: FlexNapBuild[];
    schrodingerBuilds?: SchrodingerBuild[];
    bulkBuilds?: BulkBuild[];
    buildSegments?: BuildSegment[];
}

export interface UpdatedBuildsResult {
    flexNapBuilds: FlexNapBuild[];
    schrodingerBuilds: SchrodingerBuild[];
    bulkBuilds: BulkBuild[];
    errorMessages: string[];
    confirmUpdateOnBuildIds?: number[];
}

export interface BuildCoordinates {
    xCoords: number[];
    yCoords: number[];
}

export interface SubmitForReviewRequest {
    notes: string;
}
