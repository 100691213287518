import { Viewport } from 'pixi-viewport';
import * as Pixi from 'pixi.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApp } from '@inlet/react-pixi';

import { clearSelection } from '../../redux/selection.state';
import { setCurrentHighlighted, setIsDragging, setPosition, setPreviewHighlighted, setScale, setSelectHighlighted } from '../../redux/viewport.state';
import { canvasLocationsSelector } from '../../selectors/build.selectors';
import { viewportSelector } from '../../selectors/root.selectors';
import { notificationClicked } from '../../redux/map.state';
import {
    IViewportComponentProps, IViewportDragEvent, VIEWPORT_X_BUFFER, VIEWPORT_Y_BUFFER
} from './viewport.types';

export const useViewport = ({ viewportRef: ref }: IViewportComponentProps) => {
    const app = useApp();
    const dispatch = useDispatch();
    const localRef = useRef(initializeViewport(app));
    const viewportStore = useSelector(viewportSelector);

    const { isDragging } = viewportStore;

    const handleClick = useCallback(() => {
        if (!isDragging) {
            dispatch(clearSelection());
            dispatch(setCurrentHighlighted());
            dispatch(setSelectHighlighted());
            dispatch(setPreviewHighlighted());
            dispatch(notificationClicked());
        }
    }, [dispatch, isDragging]);

    useEffect(() => {
        ref.current = localRef.current;
    }, [ref]);

    useEffect(() => {
        if (!ref.current) {
            ref.current = localRef.current;
        }
    }, [app, ref]);

    useEffect(() => {
        const viewport = localRef.current;
        const updateScale = () => dispatch(setScale(viewport.scale.x));
        viewport.addListener("zoomed-end", updateScale);
        return () => { viewport.removeListener("zoomed-end", updateScale) };
    }, [localRef, dispatch]);

    useDrag(localRef);
    return { ref: localRef, state: viewportStore, handleClick };
}

const useDrag = (viewportRef: React.RefObject<Viewport>) => {
    const dispatch = useDispatch();
    const locations = useSelector(canvasLocationsSelector);
    const { width: windowWidth } = useWindowSize();

    const dragStart = useCallback(() => {
        dispatch(setIsDragging(true));
    }, [dispatch])

    const dragEnd = useCallback(({ viewport }: IViewportDragEvent) => {
        dispatch(setIsDragging(false));
        dispatch(setPosition({ x: viewport.x, y: viewport.y }))
    }, [dispatch]);

    useEffect(() => {
        const viewport = viewportRef.current;
        if (viewport) {
            viewport.on('drag-start', dragStart);
            viewport.on('drag-end', dragEnd);

            if (locations.length) {
                viewport.worldWidth = (locations.length) * 300 + (2 * windowWidth);
            } else {
                // only CO
                viewport.worldWidth = (2 * windowWidth) - VIEWPORT_X_BUFFER;
            }

            return () => {
                viewport.removeListener('drag-start', dragStart);
                viewport.removeListener('drag-end', dragEnd);
            }
        }
    }, [viewportRef, locations, windowWidth, dragStart, dragEnd])
}

const initializeViewport = (app: Pixi.Application) => {
    const viewport = new Viewport({
        interaction: app.renderer.plugins.interaction,
        divWheel: document.getElementById("pixi-container") || undefined,
        screenHeight: app.view.height,
        screenWidth: app.view.width,
        worldHeight: 2 * app.view.height - (1.33 * VIEWPORT_Y_BUFFER)
    });

    viewport.drag({ wheel: false }).pinch()
        .clamp({ direction: 'all', underflow: 'top' });

    return viewport;
}

export const useWindowSize = () => {
    function getSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }
    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return windowSize;
}