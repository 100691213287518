import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ItemType } from '../item-type';
import { ValidationHelper } from '../validation-helper';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class MaxLengthValidator implements Validator<FlexNapBuildData> {

    private threshold: number;
    private units: Units;

    constructor(threshold = 1, units: Units = Units.feet) {
        this.threshold = threshold;
        this.units = units;
    }

    public validate(build: FlexNapBuildData): ValidationResult | null {
        const totalLength = ValidationHelper.calculateCableLength(build).toUnit(Units.feet);
        const maxLength = build.part!.max_Length;
        if (totalLength.value > maxLength) {
            const max = new DataValue(maxLength, Units.feet);
            const error = i18next.t(LocalizationKeys.ErrorCableExcessiveLength, { length: max.format(this.units, 2) });
            return this.buildValidationResult(build, error, ValidationResultType.Error);
        }
        else if (totalLength.value > maxLength * this.threshold) {
            const percentage = this.threshold * 100;
            const warning = i18next.t(LocalizationKeys.WarningCableExcessiveLength, { percentage: percentage.toFixed(0)});
            return this.buildValidationResult(build, warning, ValidationResultType.Warning);
        }
        return null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapBuildData && entity.part !== undefined;
    }

    private buildValidationResult(build: FlexNapBuildData, message: string, type: ValidationResultType): ValidationResult {
        const extent = build.getExtent();
        return {
            buildId: build.id,
            itemId: null,
            itemType: ItemType.Build,
            shortDescription: message,
            type,
            extent
        };
    }
}
