import i18next from 'i18next';
import React from 'react';
import BaseTable, { Column } from 'react-base-table';
import { LocalizationKeys } from '../../../locales/types';
import { ColorType, getColorLabel } from '../../../models/color-type';
import { TetherLocation } from '../../../models/flexnap-splice-plan';
import { DnaSpinner } from '../../ui-elements/dna-spinner';
import { PipeColor } from './pipe-color';
import { SplicePlanTableProps } from './splice-plan-dialog.types';
import { useSplicePlanTable } from './splice-plan-table-hooks';
import { TubeColor } from './tube-color';

import 'react-base-table/styles.css';

export const SplicePlanTable = (props: SplicePlanTableProps): JSX.Element | null => {
    const {
        rowHeight,
        tableHeight,
        tableData,
        frozenData,
        handleScroll,
        rowClassName,
        tableRef,
    } = useSplicePlanTable(props);

    return (
        <div className="splice-plan-dialog-table">
            <BaseTable
                ref={tableRef}
                fixed
                rowKey="fiberId"
                width={848}
                height={tableHeight}
                data={tableData}
                frozenData={frozenData}
                rowClassName={rowClassName}
                rowHeight={rowHeight}
                headerHeight={rowHeight}
                onScroll={handleScroll}
                emptyRenderer={<DnaSpinner />}
            >
                <Column
                    key="location"
                    dataKey="locationLabel"
                    className="splice-plan-dialog-table-cell-location"
                    width={150}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingLocation)}
                />
                <Column
                    key="tap"
                    dataKey="tapIndex"
                    width={55}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingTap)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="tether"
                    dataKey="tetherIndex"
                    width={75}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingTether)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="tube-color"
                    dataKey="subunitColor"
                    width={200}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingTubeColor)}
                    cellRenderer={({ cellData, rowData }) => (
                        <div className="splice-plan-dialog-table-cell-column">
                            {!(rowData as TetherLocation).isCategory && <TubeColor color={cellData} colorName = {(rowData as TetherLocation).subunitName} />}
                            <span>
                                {getColorLabel(cellData)}
                                        {(rowData as TetherLocation).subunitName.indexOf("Dash") < 0?"" : "/"+(i18next.t(cellData === ColorType.BLK
                                    ? LocalizationKeys.SplicePlanStripesWhite
                                    : LocalizationKeys.SplicePlanStripesBlack) 
                                )}
                            </span>
                        </div>
                    )}
                />
                <Column
                    key="fiber-color"
                    dataKey="fiberColor"
                    width={120}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberColor)}
                    cellRenderer={({ cellData, rowData }) => (
                        <>{!(rowData as TetherLocation).isCategory && (
                            <div className="splice-plan-dialog-table-cell-column">
                                <PipeColor color={cellData} />
                                <span>{getColorLabel(cellData)}</span>
                            </div>)}
                        </>
                    )}
                />
                <Column
                    key="fiber-id"
                    dataKey="fiberId"
                    width={144}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberId)}
                    cellRenderer={
                        ({ cellData, rowData }) => (
                            <>{!(rowData as TetherLocation).isCategory && cellData}</>
                        )
                    }
                />
                <Column
                    key="port"
                    dataKey="portNumber"
                    width={75}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingPort)}
                    align={Column.Alignment.RIGHT}
                    cellRenderer={
                        ({ cellData, rowData }) => (
                            <>{!(rowData as TetherLocation).isCategory && cellData}</>
                        )
                    }
                />
            </BaseTable>
        </div>
    )
};
