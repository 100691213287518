import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import BaseTable from 'react-base-table';
import { TetherLocation } from '../../../../models/schrodinger/splice-plan';
import { StickyRowIndexScrollTop } from '../splice-plan-dialog.types';
import { SplicePlanTableHooks, SplicePlanTableProps } from './splice-plan-table.types';

export const useSplicePlanTable = ({ tethers }: SplicePlanTableProps): SplicePlanTableHooks => {
    const rowHeight = 40;
    const tableHeight = rowHeight * 14; // 12 row + 1 Category + Header
    const [stickyIndex, setStickyIndex] = useState(0);
    const tableData = tethers.slice(1);
    const frozenData = tethers.slice(stickyIndex, stickyIndex + 1);
    const stickyRows = tethers.reduce((stickyRowObject: StickyRowIndexScrollTop[], { isCategory }, index) => {
        if (isCategory) {
            const scrollTop = rowHeight * index;
            stickyRowObject.unshift({ index, scrollTop });
        }

        return stickyRowObject
    }, []);
    const getClosestStickyRow = (s: StickyRowIndexScrollTop[], scrollValue: number): number =>
        s.find(({ scrollTop }) => scrollTop <= scrollValue)?.index || 0;

    const handleScroll = useCallback(({ scrollTop }) => {
        const index = getClosestStickyRow(stickyRows, scrollTop);
        index !== stickyIndex && setStickyIndex(index);
    }, [setStickyIndex, stickyIndex, stickyRows]);

    const rowClassName = useCallback(({ rowData: { isCategory } }) => {
        return classNames('splice-plan-dialog-table-row', { 'category subunit-sticky-row': isCategory });
    }, []);
    const tableRef = useRef<BaseTable<TetherLocation>>(null);
    useEffect(() => {
        if (tableRef && tableRef.current) {
            tableRef.current.scrollToTop(1)
        }
    }, [tethers]);

    return {
        rowHeight,
        tableHeight,
        tableData,
        frozenData,
        handleScroll,
        rowClassName,
        tableRef,
    }
}
