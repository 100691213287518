import classNames from 'classnames';
import React from 'react';

import { CardContentComponent } from '../../card/card-content.component';
import { CardHeaderComponent } from '../../card/card-header.component';
import { TerminalCard } from '../../cards/terminal/terminal-card';
import { useFlexNapTerminalCard } from './flexnap-terminal-card.hooks';
import { FlexNapTerminalCardProps } from './flexnap-terminal-card.types';

export const FlexNapTerminalCard = (props: FlexNapTerminalCardProps): JSX.Element => {
    const { disabled, terminalIcon, unselectTerminal, addTerminal, deleteTerminal, tether, terminalCardTitle, collapsed, toggleCollapse } = useFlexNapTerminalCard(props);
    const collapseBtn =
        <button className="icon-button" onClick={toggleCollapse}>
            {
                collapsed ?
                    <img alt="Reveal card" src={require('./../../card/icon_open_chevron.svg')} /> :
                    <img alt="Collapse card" src={require('./../../card/icon_close_chevron.svg')} />}
        </button>;
    const cs = classNames({ collapsed: !!collapsed });

    return (
        <div className="nap-diagram-children">
            <div className="card-separator"></div>
            <div className="header-with-button">
                <CardHeaderComponent title={terminalCardTitle} icon={terminalIcon} iconBorder={true} />
                {collapseBtn}
                <button className="icon-button" onClick={unselectTerminal}><img alt="Close terminal" src={require('./../../card/icon_close.svg')} /></button>
            </div>
            <CardContentComponent className={cs}>
                {
                    <TerminalCard disabled={disabled} tether={tether} onAddTerminal={addTerminal} onDeleteTerminal={deleteTerminal} />
                }
            </CardContentComponent>
        </div>
    );
};
