import { store } from '../dna';
import { PointLike } from '../models/pointlike';
import { addBore } from '../redux/bore.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class BoreTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Bore,
        name: 'Bore',
        shortcut: 'B',
    };

    public static createNewBore(points: PointLike[], startId: number, endId: number): void {
        addBore(startId, endId, points)(store.dispatch);
    }
}
