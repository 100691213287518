import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { DrawerType } from '../../../redux/overlay.state';
import { featureFlagsSelector, overlaySelector } from '../../../selectors/root.selectors';
import { DrawerMenuContextProps } from './drawer-menu.types';

export const useDrawerMenu = () => {
    const { drawer } = useSelector(overlaySelector);
    const { showDebugMenu } = useSelector(featureFlagsSelector);

    const [selectedBuildIds, setSelectedBuildIds] = useState<number[]>([]);
    const [selectedDesignAreaIds, setSelectedDesignAreaIds] = useState<number[]>([]);
    const [selectedDesignAreaNames, setSelectedDesignAreaNames] = useState<string[]>([]);
    const [singleBuildIdToDelete, setSingleBuildIdToDelete] = useState<number>();
    const [singleDesignAreaIdToDelete, setSingleDesignAreaIdToDelete] = useState<number>();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
    const [showDebugTools, setShowDebugTools] = useState(false);
    const [showExportBuildErrorDialog, setShowExportBuildErrorDialog] = useState<string>();

    const menuClass = classNames('left-side-drawer', { 'open': drawer === DrawerType.MainMenu });
    const debugIcon = showDebugTools ? require('../../card/icon_open_chevron.svg') : require('../../card/icon_close_chevron.svg');

    const drawerMenuContextProps: DrawerMenuContextProps = {
        selectedBuildIds,
        selectedDesignAreaIds,
        selectedDesignAreaNames,
        singleBuildIdToDelete,
        singleDesignAreaIdToDelete,
        isDeleteDialogOpen,
        isOptionsMenuOpen,
        setSelectedBuildIds,
        setSelectedDesignAreaIds,
        setSelectedDesignAreaNames,
        setSingleBuildIdToDelete,
        setSingleDesignAreaIdToDelete,
        setIsDeleteDialogOpen,
        setIsOptionsMenuOpen,
    };

    const handleCloseClick = useCallback(() => {
        setSelectedBuildIds([]);
        setSelectedDesignAreaIds([]);
        setSelectedDesignAreaNames([]);
    }, [setSelectedBuildIds]);

    const handleDebugClick = useCallback(() => {
        setShowDebugTools(!showDebugTools);
    }, [showDebugTools]);

    const handleCloseExportBuildErrorDialogClick = useCallback(() => {
        setShowExportBuildErrorDialog(undefined);
    }, []);

    return {
        menuClass,
        drawerMenuContextProps,
        showDebugMenu,
        debugIcon,
        showDebugTools,
        showExportBuildErrorDialog,
        handleCloseClick,
        handleDebugClick,
        handleCloseExportBuildErrorDialogClick,
    };
};
