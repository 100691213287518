import { ReviewStatus } from '../models/review-status';

export const LocalizationKeys = {
    DeleteCable: "delete-cable",
    DeleteBuild: "delete-build",
    DeleteBuildGeneric: "delete-build-generic",
    DeleteSchrodingerBuild: "delete-schrodinger-build",
    DeleteBulkCable: "delete-bulk-cable",
    DeleteBulkBuild: "delete-bulk-build",
    DeleteBuilds: "delete-builds",
    DeleteBuildsGeneric: "delete-builds-generic",
    DeleteNapWithPreterm: "delete-nap-with-preterm",
    DeleteElementTitle: "delete-element-title",
    DeleteElementConfirmation: "delete-element-confirmation",
    DeleteLocationTitle: "delete-location-title",
    DeleteLocationConfirmation: "delete-location-confirmation",
    DeleteLocationConfirmationNoLocationIndex: "delete-location-confirmation-no-location-index",
    Delete: "delete",
    DeleteShortcut: "delete-shortcut",
    Cancel: "cancel",
    Save: "save",
    Edit: "edit",
    Build: "build",
    Co: "co",
    BuildLocationIndex: "build-location-index",
    Generate: "generate",
    Locations: "locations",
    NAPs: "naps",
    Serving: "serving",
    Unlock: "unlock",
    Lock: "lock",
    First: 'first',
    Last: 'last',
    Previous: 'previous',
    Next: 'next',
    PanFirst: 'pan-first',
    PanPrevious: 'pan-previous',
    PanNext: 'pan-next',
    PanLast: 'pan-last',
    Ellipsis: 'ellipsis',
    Tip: 'tip',
    DeleteDesignArea: "delete-design-area",
    DeleteDesignAreaAndBuilds: "delete-design-area-and-builds",
    DeleteDesignAreaMessage: "delete-design-area-message",
    CreatingDesignArea: "creating-design-area",
    DeletingDesignArea: "deleting-design-area",
    UpdatingDesignArea: "updating-design-area",
    DesignAreaSuccessfullyCreated: "design-area-successfully-created",
    DesignAreaSuccessfullyDeleted: "design-area-successfully-deleted",
    DesignAreaSuccessfullyUpdated: "design-area-successfully-updated",
    DesignAreaInfoEmptyPropertyError: "design-area-info-empty-property-error",
    DesignAreaErrorDeleting: "design-area-error-deleting",
    BuildNumber: "build-number",
    BuildInfo: "build-info",
    BuildInformation: "build-information",
    BuildFibers: "build-fibers",
    BuildSubunits: "build-subunits",
    BulkBuildNumber: "bulk-build-number",
    Builds: "builds",
    BuildList: "build-list",
    Properties: "properties",
    SubunitFibers: "subunit-fibers",
    RadioAntennas: "radio-antennas",
    Parcels: "parcels",
    Undefined: "undefined",
    Na: "n-a",
    Color: "color",
    SelectAColor: "select-a-color",
    ApplyColor: "apply-color",
    ApplySlack: "apply-slack",
    Apply: "apply",
    ColorAqua: "color-aqua",
    ColorRose: "color-rose",
    ColorViolet: "color-violet",
    ColorYellow: "color-yellow",
    ColorBlack: "color-black",
    ColorRed: "color-red",
    ColorWhite: "color-white",
    ColorSlate: "color-slate",
    ColorBrown: "color-brown",
    ColorGreen: "color-green",
    ColorOrange: "color-orange",
    ColorBlue: "color-blue",
    ColorCopper: "color-copper",
    ColorNo: "color-no",
    CabinetId: "cabinet-id",
    CabinetIds: "cabinet-ids",
    Name: "name",
    React: "react",
    ReactBaseTable: "react-base-table",
    PixiJs: "pixi-js",
    PixiViewport: "pixi-viewport",
    BingMapsRESTToolkit: "bing-maps-rest-toolkit",
    NetTopologySuiteIOGeoJSON: "net-topology-suite-io-geojson",
    NetTopologySuiteIOGeoJSON4ST: "net-topology-suite-io-geojson4st",
    SwashbuckleAspNetCore: "swashbuckle-asp-net-core",
    SerilogAspNetCore: "serilog-asp-net-core",
    typescript: "typescript",
    Redux: "redux",
    Toastr: "toastr",
    JSZip: "jszip",
    Shp2geojsonJS: "shp2geojson-js",
    Shp2geojsonJSVersion: "shp2geojson-js-version",
    MathJS: "math-js",
    MathJSDisclaimer: "mathjs-disclaimer",
    ProJ4: "proj4",
    MaterialComponents: "material-component",
    MaterialUI: "material-ui",
    Axios: "axios",
    OpenLayers: "open-layers",
    OpenLayersDisclaimer: "openlayers-disclaimer",
    EFCore: "efcore",
    EFCoreVersion: "efcore-version",
    EFCoreDisclaimer: "efcore-disclaimer",
    NpgSQL: "npgsql",
    NpgSQLVersion: "npgsql-version",
    NpgSQLDisclaimer: "npgsql-disclaimer",
    NTS: "nts",
    NTSVersion: "nts-version",
    NTSDisclaimer: "nts-disclaimer",
    i18next: "i18next",
    RMWC: "rmwc",
    GenericDisclaimer: "generic-disclaimer",
    DefaultWorkspaceId: "default-workspace-id",
    DefaultWorkspaceName: "default-workspace-name",
    DefaultWorkspaceCity: "default-workspace-city",
    DefaultWorkspaceCountry: "default-workspace-country",
    DefaultWorkspaceDescription: "default-workspace-description",
    DefaultWorkspaceRegion: "default-workspace-region",
    ShowAllWorkspaces: "show-all-workspaces",
    WorkspaceDrawerHeaderTitle: "workspace-drawer-header-title",
    SingleWorkspace: "single-workspace",
    MultipleWorkspaces: "multiple-workspaces",
    NewWorkspace: "new-workspace",
    DefaultWorkspaceFilterValue: "default-workspace-filter-value",
    RecentFilter: "recent-filter",
    AlphabeticalFilter: "alphabetical-filter",
    LastDateModified: "last-date-modified",
    WorkspaceLocation: "workspace-location",
    WorkspaceStateCountry: "workspace-state-country",
    WorkspaceDAs: "workspace-das",
    WorkspaceBuilds: "workspace-builds",
    WorkspaceExports: "workspace-exports",
    WorkspaceInfoDialogTitle: "workspace-info-dialog-title",
    WorkspaceInfoHelperText: "workspace-info-helper-text",
    WorkspaceName: "workspace-name",
    WorkspaceID: "workspace-id",
    SplicePlanButton: "splice-plan-button",
    SplicePlanDialogTitle: "splice-plan-dialog-title",
    SplicePlanLocationIndex: 'splice-plan-location-index',
    SplicePlanHeadingLocation: "splice-plan-heading-location",
    SplicePlanHeadingTap: "splice-plan-heading-tap",
    SplicePlanHeadingTether: "splice-plan-heading-tether",
    SplicePlanHeadingTubeColor: "splice-plan-heading-tube-color",
    SplicePlanHeadingFiberColor: "splice-plan-heading-fiber-color",
    SplicePlanHeadingFiberId: "splice-plan-heading-fiber-id",
    SplicePlanHeadingPort: "splice-plan-heading-port",
    SplicePlanHeadingLayer: "splice-plan-heading-layer",
    SplicePlanHeadingSubunitNumber: "splice-plan-heading-subunit-number",
    SplicePlanHeadingFiberColorConductor: "splice-plan-heading-fiber-color-conductor",
    SplicePlanHeadingFiberPolarity: "splice-plan-heading-fiber-polarity",
    SplicePlanHeadingSplicePoint: "splice-plan-heading-splice-point",
    SplicePlanStripesBlack: "splice-plan-stripes-black",
    SplicePlanStripesWhite: "splice-plan-stripes-white",
    SplicePlanShortcuts: 'splice-plan-shortcuts',
    SplicePlanSetup: 'splice-plan-setup',
    SplicePlanPortStart: 'splice-plan-port-start',
    SplicePlanPortStartWith: 'splice-plan-port-start-with',
    SplicePlanPortStartError: 'splice-plan-port-start-error',
    SplicePlanBuildError: 'splice-plan-build-error',
    HighToLowCoToField: 'high-to-low-co-to-field',
    HighToLowFieldToCo: 'high-to-low-field-to-co',
    LowToHighCoToField: 'low-to-high-co-to-field',
    LowToHighFieldToCo: 'low-to-high-field-to-co',
    Description: "description",
    ZipCode: "zip-code",
    Address: "address",
    City: "city",
    Country: "country",
    State: "state",
    MandatoryField: "mandatory-field",
    WorkspaceDeleteMessageInfo: 'workspace-delete-message-info',
    QuestionMark: "question-mark",
    NotApplicable: "not-applicable",
    AboutThisApplication: "about-this-application",
    ImportGisData: "import-gis-data",
    RemoveGisData: "remove-gis-data",
    RemoveImportedGisData: "remove-imported-gis-data",
    RemoveLastImportedGisData: "remove-last-imported-gis-data",
    RemoveLastImportedGisDataMessage: "remove-last-imported-gis-data-message",
    RemoveGisDataProgressMessage: "remove-gis-data-progress-message",
    RemoveGisDataSuccessMessage: "remove-gis-data-success-message",
    Remove: "remove",
    LocateOnMap: "locate-on-map",
    Metric: "metric",
    Imperial: "imperial",
    ExitFullscreen: "exit-fullscreen",
    EnterFullscreen: "enter-fullscreen",
    ExitToMyDesignAreas: "exit-to-my-design-areas",
    UpdateContracts: "update-contracts",
    UpdateCpq: "update-cpq",
    Logout: "logout",
    NbUnassignedBuilds: "nb-unassigned-builds",
    Ordered: "ordered",
    Exported: "exported",
    ExportedBuild: "exported-build",
    UnlockBuild: "unlock-build",
    UnlockBuilds: "unlock-builds",
    RejectedBuild: "rejected-build",
    FlexnapCable: "flexnap-cable",
    Preterm: "preterm",
    PretermLateral: "preterm-lateral",
    PretermLateralNumber: "preterm-lateral-number",
    PretermLateralInvalidTube: "preterm-lateral-invalid-tube",
    PretermLateralInvalidParentFibers: "preterm-lateral-invalid-parent-fibers",
    PretermLateralUnusedFibers: "preterm-lateral-unused-fibers",
    Preset: "preset",
    Presets: "presets",
    DefaultPresetName: "default-preset-name",
    Fibers: "fibers",
    FiberAssignment: "fiber-assignment",
    FiberCount: "fiber-count",
    TetherStart: "tether-start",
    CopperCount: "copper-count",
    FillerCount: "filler-count",
    PartNumber: "part-number",
    CablePartNumber: "cable-part-number",
    TetherPartNumber: "tether-part-number",
    PretermPartNumber: "preterm-part-number",
    InstallationType: "installation-type",
    ConnectorType: "connector-type",
    FurcationType: "furcation-type",
    LegType: "leg-type",
    Length: "length",
    COSlack: "co-slack",
    FieldSlack: "field-slack",
    SlackLocationSpan: "slack-location-span",
    Modification: "modification",
    Modify: "modify",
    Node: "node",
    OnlyPositiveValues: "only-positive-values",
    Convert: "convert",
    ConvertBuildToPreterm: "convert-build-to-preterm",
    CreatePreterm: "create-preterm",
    RevertPreterm: "revert-preterm",
    RevertPretermConfirmation: "revert-preterm-confirmation",
    Connect: "connect",
    Disconnect: "disconnect",
    ConnectTo: "connect-to",
    Reset: "reset",
    ResetTitle: "reset-title",
    NapNumber: "nap-number",
    Terminal: "terminal",
    LongtailTerminal: "longtail-terminal",
    TetherExtender: "tether-extender",
    LongtailTerminalShort: "longtail-terminal-short",
    TetherExtenderShort: "tether-extender-short",
    TerminalMatchPortNumberWithTetherFiberCount: "terminal-match-port-number-with-tether-fiber-count",
    TerminalPortUpdateMessageMatchTetherPortCount: "terminal-port-update-message-match-tether-port-count",
    TerminalPortUpdateMessageFromTether: "terminal-port-update-message-from-tether",
    TerminalPortUpdateMessageFromTetherReasonSetToMatch: "terminal-port-update-message-from-tether-reason-set-to-match",
    TerminalPortUpdateMessageFromTetherReasonMinimumValue: "terminal-port-update-message-from-tether-reason-minimum-value",
    ResetTerminalExtension: "reset-terminal-extension",
    TetherNumber: "tether-number",
    TapNumber: "tap-number",
    PretermConfig: "preterm-config",
    PretermLateralBuildNumber: "preterm-lateral-build-number",
    PretermLateralBuildConfig: "preterm-lateral-build-config",
    Quadruple48F: "quadruple-48f",
    Triple36F: "triple-36f",
    Double24F: "double-24f",
    Single12F: "single-12f",
    TetherTerminalConfig: "tether-terminal-config",
    PretermConfigSingleTether: "preterm-config-single-tether",
    PretermConfigMultiTether: "preterm-config-multi-tether",
    PretermConfigWithTerminal: "preterm-config-with-terminal",
    ImportSaving: "import-saving",
    ImportSaved: "import-saved",
    ImportFailed: "import-failed",
    ImportConfirmation: "import-confirmation",
    ImportConfirmationWithDuplicates: "import-confirmation-with-duplicates",
    ImportNoUpdate: "import-no-update",
    Pole: "pole",
    Parcel: "parcel",
    Manhole: "manhole",
    Handhole: "handhole",
    Vault: "vault",
    Cabinet: "cabinet",
    Trench: "trench",
    Bore: "bore",
    ID: "id",
    IDOverride: "id-override",
    Longitude: "longitude",
    Latitude: "latitude",
    CalculatedLength: "calculated-length",
    MeasuredLength: "measured-length",
    CalculatedSpan: "calculated-span",
    MeasuredSpan: "measured-span",
    Calculated: "calculated",
    Measured: "measured",
    SlackLoop: "slack-loop",
    DesignSpan: "design-span",
    RiserSpan: "riser-span",
    Ports: "ports",
    Power: "power",
    ConnectedPathWarning: "connected-path-warning",
    ConnectedSegmentWarning: "connected-segment-warning",
    Ribbon: "ribbon",
    LooseTube: "loose-tube",
    LooseTube216FValidationMsg: "lt-216f-validation-msg",
    LooseTubeMaximumFibersValidationMsg: "lt-max-fibers-validation-msg",
    RestrictFlexNapNaps: "restrict-flexnap-naps",
    BufferTubeValidationMsg: "buffer-tube-validation-msg",
    ValueNoLessThan: "value-no-less-than",
    DesignSpanRiserShouldBeMin: "design-span-should-be-min",
    DesignSpanCaptionDefinition: "design-span-caption-definition",
    Unassigned: "unassigned",
    Type: "type",
    Optical: "optical",
    MaximumLoad: "maximum-load",
    WireSize: "wire-size",
    Pairs: "pairs",
    ManagePresets: "manage-presets",
    MinimalValue: "minimal-value",
    Cable: "cable",
    FlexNap: "flexnap",
    Schrodinger: "schrodinger",
    Bulk: "bulk",
    New: "new",
    NewCablePreset: "new-cable-preset",
    PresetNeedsAName: "preset-needs-a-name",
    PresetName: "preset-name",
    PresetWasDeleted: "preset-was-deleted",
    NoPresetAvailable: "no-preset-available",
    ToCreateAPresetClickOn: "to-create-a-preset-click-on",
    SetAsDefault: "set-as-default",
    Slack: "slack",
    CablePreset: "cable-preset",
    SavePreset: "save-preset",
    Default: "default",
    Subunits: "subunits",
    Recommendation: "recommendation",
    IdUsedForCableLabeling: "id-used-for-cable-labeling",
    DesignArea: "design-area",
    DefaultDesignArea: "default-design-area",
    DesignAreaInformation: "design-area-information",
    DesignAreaNameAbbr: "design-area-name-abbr",
    DesignAreaProjectId: "design-area-project-id",
    Taps: "taps",
    SplicePoints: "splice-points",
    NoTerminalLine1: "no-terminal-line1",
    NoTerminalLine2: "no-terminal-line2",
    GeneratingBuild: "generating-build",
    BuildSuccessfullyGenerated: "build-successfully-generated",
    BuildDesignAreaChangeSuccessfully: "build-design-area-change-successfully",
    AddingSpan: "adding-span",
    SpanSuccessfullyAdded: "span-successfully-added",
    DeletingSpan: "deleting-span",
    SpanSuccessfullyDeleted: "span-successfully-deleted",
    AddingTether: "adding-tether",
    TetherSuccessfullyAdded: "tether-successfully-added",
    DeletingTether: "deleting-tether",
    TetherSuccessfullyDeleted: "tether-successfully-deleted",
    AddingTap: "adding-tap",
    TapSuccessfullyAdded: "tap-successfully-added",
    TapsSuccessfullyLinked: "taps-successfully-linked",
    UnableToLinkTaps: "unable-to-link-taps",
    LinkingTaps: "linking-taps",
    DeletingTap: "deleting-tap",
    TapSuccessfullyDeleted: "tap-successfully-deleted",
    Updating: "updating",
    LockingBuilds: "locking-builds",
    UnlockingBuilds: "unlocking-builds",
    CCSBuildId: "css-build-id",
    HybrisProjectKey: "hybris-project-key",
    Errors: "errors",
    Warnings: "warnings",
    ErrorsAndWarnings: "errors-and-warnings",
    Nap: "nap",
    Tap: "tap",
    SplicePoint: 'splice-point',
    Splice: 'splice',
    Closure: 'closure',
    Segment: "segment",
    WarningUnconnectedNap: "warning-unconnected-nap",
    WarningDuplicatePoleIds: "warning-duplicate-pole-ids",
    WarningDuplicatePoleIdsLong: "warning-duplicate-pole-ids-long",
    ErrorCableExcessiveLength: "error-cable-excessive-length",
    WarningCableExcessiveLength: "warning-cable-excessive-length",
    WarningUnsetMeasuredLength: "warning-unset-measured-length",
    ErrorInvalidPartNumber: "error-invalid-part-number",
    WarningDesignSpanExcessiveLength: "warning-design-span-excessive-length",
    WarningDesignSpanExcessiveLengthLong: "warning-design-span-excessive-length-long",
    ErrorDesignSpanInsufficientLength: "error-design-span-insufficient-length",
    ErrorDesignSpanInsufficientLengthLong: "error-design-span-insufficient-length-long",
    WarningSegmentSpanExcessiveLength: "warning-segment-span-excessive-length",
    WarningSegmentSpanExcessiveLengthLong: "warning-segment-span-excessive-length-long",
    ErrorSegmentSpanInsufficientLength: "error-segment-span-insufficient-length",
    ErrorSegmentSpanInsufficientLengthLong: "error-segment-span-insufficient-length-long",
    ErrorCoSlackInsufficient: "error-co-slack-insufficient",
    ErrorCoSlackInsufficientLong: "error-co-slack-insufficient-long",
    ErrorFieldSlackInsufficient: "error-field-slack-insufficient",
    ErrorFieldSlackInsufficientLong: "error-field-slack-insufficient-long",
    SlackLoopInsufficient: "slack-loop-insufficient",
    SlackLoopInsufficientLong: "slack-loop-insufficient-long",
    ErrorTetherIncompatible: "error-tether-incompatible",
    ErrorTetherIncompatibleLong: "error-tether-incompatible-long",
    ErrorFiberCountInsufficient: "error-fiber-count-insufficient",
    ErrorFiberCountInsufficientLong: "error-fiber-count-insufficient-long",
    GisDataDupliate: "gis-data-duplicate",
    NapCannotBeModified: "nap-cannot-be-modified",
    SplicePointEmptyTitle: "splice-point-empty-title",
    SplicePointEmptyDescription: "splice-point-empty-description",
    Tether: "tether",
    PowerTether: "power-tether",
    OpticalTether: "optical-tether",
    DesignTool: "design-tool",
    PowerUnit: "power-unit",
    Downconverter: "downconverter",
    AddTerminal: "add-terminal",
    Loc: "loc",
    AddATether: "add-a-tether",
    DeleteATether: "delete-a-tether",
    SubunitCount: "subunit-count",
    SchrodingerCable: "schrodinger-cable",
    EditCablePreset: "edit-cable-preset",
    SelectedBuildsExport: "selected-builds-export",
    ApplyToAll: "apply-to-all",
    NoResults: "no-results",
    UsePartNumber: "use-part-number",
    Continue: "continue",
    StartGuide: "start-guide",
    NewCablePrompt: "new-cable-prompt",
    EndCableModification: "end-cable-modification",
    MoveTo: "move-to",
    AddTo: "add-to",
    Unconnected: "unconnected",
    HighlightBuild: "highlight-build",
    HighlightIconsOrder: "highlight-icons-order",
    Regenerate: "regenerate",
    NoDefaultPreset: "no-default-preset",
    PresetCount: "preset-count",
    SelectAPreset: "select-a-preset",
    CreatedBy: "created-by",
    Done: "done",
    Finish: "finish",
    Find: "find",
    FindOnMap: "find-on-map",
    NoDataWasImported: "no-data-imported",
    AboutToImportXElements: "about-to-import-x-elements",
    FoundDuplicateTagNotImported: "found-duplicate-tag-not-imported",
    BuriedDesignSpanError: "buried-design-span-error",
    BuriedDesignSpanWarning: "buried-design-span-warning",
    BeforeImportingFlexNapBuildSelectPreset: "before-importing-build-select-preset",
    Status: "status",
    Infrastructure: "infrastructure",
    CablePath: "cable-path",
    SplicePlan: "splice-plan",
    ManholeHandholeVault: "manhole-handhole-vault",
    TrenchBore: "trench-bore",
    DesignAreas: "design-areas",
    BuildS: "build-s",
    DesignAreaName: "design-area-name",
    DownloadBOM: "download-bom",
    DownloadGisData: "download-gis-data",
    Download: "download",
    DesignAreaAndBuild: "design-area-and-build",
    InfrastructureStar: "infrastructure-star",
    DownloadInfrastructureFootnoteDesignArea: "download-infrastructure-footnote-design-area",
    DownloadInfrastructureFootnoteBuild: "download-infrastructure-footnote-build",
    Summary: "summary",
    GeographicArea: "geographic-area",
    FilteringBy: "filtering-by",
    SelectFileFromComputer: "select-file-from-computer",
    DragAndDropFilesHere: "drag-and-drop-files-here",
    Complete: "complete",
    Error: "error",
    RevealErrors: "reveal-errors",
    HideErrors: "hide-errors",
    FiberCountStartingPortWarning: "fiber-count-starting-port-warning",
    FiberCountStartingPortWarningShort: "fiber-count-starting-port-warning-short",
    FiberCountStartingPortWarningLong: "fiber-count-starting-port-warning-long",
    LastUpdate: "last-update",
    LastBuild: "last-build",
    NoPower: "no-power",
    TransformerInstalled: "transformer-installed",
    NewTransformer: "new-transformer",
    Size: "size",
    CalculatedSpansUpdatedWarning: "calculated-spans-updated-warning",
    Undo: "undo",
    TransformingData: "transforming-data",
    CabinetSplicePlanDialogTitle: "cabinet-splice-plan-dialog-title",
    CabinetSplicePlanCabinetSizeLabel: "cabinet-splice-plan-cabinet-size-label",
    CabinetSplicePlanPortNumberManual: "cabinet-splice-plan-port-number-manual",
    CabinetSplicePlanPortNumberAutomatic: "cabinet-splice-plan-port-number-automatic",
    SplicePlanSetupLabel: "splice-plan-setup-label",
    HyphenatedRange: "hyphenated-range",
    CableId: "cable-id",
    PortNumber: "port-number",
    InsufficientCabinetSizeWarning: "insufficient-cabinet-size-warning",
    CabinetOverlappingPortNumbers: "cabinet-overlapping-port-numbers",
    SwitchToGISMode: "switch-to-gis-mode",
    SwitchToCanvasMode: "switch-to-canvas-mode",
    GISModeDescription: "gis-mode-description",
    CanvasModeDescription: "canvas-mode-description",
    StartGISMode: "start-gis-mode",
    StartCanvasMode: "start-canvas-mode",
    NumberOfBuilds: "number-of-builds",
    Menu: "menu",
    Search: "search",
    SearchByBuild: "search-by-build",
    SatelliteView: "satellite-view",
    MapView: "map-view",
    Layers: "layers",
    MoreOptions: "more-options",
    ZoomIn: "zoom-in",
    ZoomOut: "zoom-out",
    AddDesignArea: "add-design-area",
    AddNewLocation: "add-new-location",
    Information: "Information",
    Ok: "ok",
    Add: "add",
    NewBuild: "new-build",
    NewBuildNote: "new-build-note",
    CableLength: "cable-length",
    DesignAreaHelperText: "design-area-helper-text",
    PresetHelperText: "preset-helper-text",
    SpanLengthHelperText: "span-length-helper-text",
    Enter: "enter",
    Spans: "spans",
    CreateBuild: "create-build",
    SelectDesignArea: "select-design-area",
    DefaultDesignAreaDescriptionCanvas: "default-design-area-description-canvas",
    PropertiesIconToolTip: "properties-icon-tooltip",
    EditIconToolTip: "edit-icon-tooltip",
    AddIconToolTip: "add-icon-tooltip",
    DeleteIconToolTip: "delete-icon-tooltip",
    BackToGisMode: "back-to-gis-mode",
    CreatePreset: "create-preset",
    MissingSchrodingerPresetDescription: "missing-schrodinger-preset-description",
    OpenPreset: "open-preset",
    MissingDefaultPresetDescription: "missing-default-preset-description",
    MissingDefaultPreset: "missing-default-preset",
    Close: "close",
    MissingPreset: "missing-preset",
    MissingPresetDescription: "missing-preset-description",
    NoSpecialCharSpace: "no-special-char-or-space",
    NoSpecialChar: "no-special-char",
    AlphaOnly: "alpha-only",
    NumericOnly: "number-only",
    Required: "required",
    ThirdPartyOssCopyright: "third-party-oss-copyright",
    CorningCopyright: "corning-copyright",
    Component: "component",
    Version: "version",
    Disclaimer: "disclaimer",
    RequestReview: "request-review",
    RequestReviewDialogTitle: "request-review-dialog-title",
    RequestReviewDialogNotesPlaceholder: "request-review-dialog-notes-placeholder",
    CancelReview: "cancel-review",
    Submit: "submit",
    ReviewRequestSent: "review-request-sent",
    ReviewRequestCanceled: "review-request-canceled",
    BuildReview: "builds-in-review",
    ReviewList: "review-list",
    ReviewListPendingTabTitle: "review-list-pending-tab-title",
    ReviewListResolvedTabTitle: "review-list-resolved-tab-title",
    ReviewListRequestTabTitle: "review-list-request-tab-title",
    ReviewListArchiveTabTitle: "review-list-archive-tab-title",
    ReviewRequestTitle: "review-request-title",
    ReviewRequestFrom: "review-request-from",
    Resolved: "resolved",
    ReviewRequestResolved: "review-request-resolved",
    Dismiss: "dismiss",
    ReviewRequestApproveLabel: "review-request-approve-label",
    ReviewRequestRejectLabel: "review-request-reject-label",
    Approve: "approve",
    Reject: "reject",
    ReviewRequestNotePlaceholder: "review-request-note-placeholder",
    ReviewRequestNotification: "review-request-notification",
    ReviewResolvedNotification: "review-resolved-notification",
    LoadBuild: "load-build",
    RejectBuild: "reject-build",
    ApproveBuild: "approve-build",
    UploadBuild: "upload-build",
    Upload: "upload",
    UploadBuildNotice1: "upload-build-notice-1",
    UploadBuildNotice2: "upload-build-notice-2",
    Loading: "loading",
    BuildUploadSuccess: "build-upload-success",
    ReviewDateTime: "review-date-time",
    RequestDateTime: "request-date-time",
    NoReviewsFoundInArchive: "no-review-found-in-archive",
    NoReviewHistory: "no-review-history",
    ConfigurationType: "configuration-type",
    OpticalPowerHybrid: "optical-power-hybrid",
    OpticalOnly: "optical-only",
    PowerOnly: "power-only",
    RestoringBuilds: "restoring-builds",
    BuildsRestored: "builds-restored",
    UnableToRestoreBuilds: "unable-to-restore-builds",
    DeletingCable: "deleting-cable",
    UnlinkingTaps: "unlinking-taps",
    SelectAll: "select-all",
    UnselectAll: "unselect-all",
    BuildConversionSuccess: "build-conversion-success",
    UndoBuildConversionSuccess: "undo-build-conversion-success",
    ConvertBulkBuild: "convert-bulk-cable-to-flexnap",
    ConvertBulkBuildMessage: "convert-bulk-cable-to-flexnap-message",
    ImportFlexnapTab: "import-flexnap-tab",
    ImportBulkTab: "import-bulk-tab",
    SelectParentBuildId: "select-parent-build-id",
    ParentBuild: "parent-build",
    FiberType: "fiber-type",
    CableType: "cable-type",
    BeforeImportingBulkBuildSelectPreset: "bulk-builds-ready",
    FlexnapAndPreterm: "flexnap-and-preterm",
    Details: "details",
    StateRegion: "state-region",
    IncludeDesignAreaInfrastructure: "include-da-infrastructure",
    AccessPoints: "access-points",
    PortNumberSettings: "port-number-settings",
    PortNumberManualDescription: "port-number-manual-description",
    PortNumberAutomaticDescription: "port-number-automatic-description",
    Manual: "manual",
    Automatic: "automatic",
    AutomaticPortUpdateConfirmation: "automatic-port-update-confirmation",
    Low: "low",
    High: "high",
    Unused: "unused",
    Update: "update",
    IncludeUnusedFiber: "include-unused-fiber",
    AutomaticPortNumberUpdate: "automatic-port-number-update",
    GoToCabinetSplicePlanMessage: "go-to-cabinet-splice-plan-message",
    OpenCabinetSplicePlan: "open-cabinet-splice-plan"
} as const;

export const ReviewStatusLocalization = {
    [ReviewStatus.Open]: "in-review",
    [ReviewStatus.Canceled]: "canceled",
    [ReviewStatus.Rejected]: "rejected",
    [ReviewStatus.Approved]: "approved",
} as const;

export const UndoRedoLocalizationKeys = {
    UndoShortcut: "undo-shortcut",
    RedoShortcut: "redo-shortcut",
    UndoBuildCreation: "undo-build-creation",
    RedoBuildCreation: "redo-build-creation",
    UndoBuildEditing: "undo-build-editing",
    RedoBuildEditing: "redo-build-editing",
    UndoBuildDeleting: "undo-build-deleting",
    RedoBuildDeleting: "redo-build-deleting",
    UndoSpanAddition: "undo-span-addition",
    RedoSpanAddition: "redo-span-addition",
    UndoSpanDeletion: "undo-span-deletion",
    RedoSpanDeletion: "redo-span-deletion",
    UndoBuildExport: "undo-build-export",
    RedoBuildExport: "redo-build-export",
    UndoPretermCreation: "undo-preterm-creation",
    RedoPretermCreation: "redo-preterm-creation",
    UndoNapCreation: "undo-nap-creation",
    RedoNapCreation: "redo-nap-creation",
    UndoNapMoving: "undo-nap-moving",
    RedoNapMoving: "redo-nap-moving",
    UndoNapConnection: "undo-nap-connection",
    RedoNapConnection: "redo-nap-connection",
    UndoNapUnconnection: "undo-nap-unconnection",
    RedoNapUnconnection: "redo-nap-unconnection",
    UndoNapDeleting: "undo-nap-deleting",
    RedoNapDeleting: "redo-nap-deleting",
    UndoTapCreation: "undo-tap-creation",
    RedoTapCreation: "redo-tap-creation",
    UndoTapDeletion: "undo-tap-deletion",
    RedoTapDeletion: "redo-tap-deletion",
    UndoTetherCreation: "undo-tether-creation",
    RedoTetherCreation: "redo-tether-creation",
    UndoTetherDeletion: "undo-tether-deletion",
    RedoTetherDeletion: "redo-tether-deletion",
    UndoBuildLock: "undo-build-lock",
    RedoBuildLock: "redo-build-lock",
    UndoBuildUnlock: "undo-build-unlock",
    RedoBuildUnlock: "redo-build-unlock",
    UndoDesignAreaCreation: "undo-design-area-creation",
    RedoDesignAreaCreation: "redo-design-area-creation",
    UndoDesignAreaEditing: "undo-design-area-editing",
    RedoDesignAreaEditing: "redo-design-area-editing",
    UndoDesignAreaDeleting: "undo-design-area-deleting",
    RedoDesignAreaDeleting: "redo-design-area-deleting",
    UndoElementCreation: "undo-element-creation",
    RedoElementCreation: "redo-element-creation",
    UndoElementDeleting: "undo-element-deleting",
    RedoElementDeleting: "redo-element-deleting",
    UndoPathCreation: "undo-path-creation",
    RedoPathCreation: "redo-path-creation",
    UndoPathDeleting: "undo-path-deleting",
    RedoPathDeleting: "redo-path-deleting",
    UndoLinkTaps: "undo-link-taps",
    RedoLinkTaps: "redo-link-taps",
    UndoUnlinkTaps: "undo-unlink-taps",
    RedoUnlinkTaps: "redo-unlink-taps",
    UndoCableDeletion: "undo-cable-deletion",
    RedoCableDeletion: "redo-cable-deletion",
    UndoTapMove: "undo-tap-move",
    RedoTapMove: "redo-tap-move",
    UndoTapDisconnection: "undo-tap-disconnection",
    RedoTapDisconnection: "redo-tap-disconnection",
    UndoTapConnection: "undo-tap-connection",
    RedoTapConnection: "redo-tap-connection",
    UndoConvertBulkBuild: "undo-convert-bulk-build",
    RedoConvertBulkBuild: "redo-convert-bulk-build"
} as const;

export const TooltipLocalizationKeys = {
    Expand: "expand",
    ExpandAll: "expand-all",
    Collapse: "collapse",
    CollapseAll: "collapse-all",
    DeleteTap: "delete-tap",
    DeleteTether: "delete-tether",
    AddOpticalTether: "add-optical-tether",
    AddPowerTether: "add-power-tether",
} as const;
export type LocalizationKey = typeof LocalizationKeys[keyof typeof LocalizationKeys]
