import * as rfc from 'rfc6902';
import { DesignMode } from '../models/design-mode';
import { Workspace } from '../models/workspace';
import { RecentWorkspace } from './recent-workspace';
import { SecuredService } from './abstract-secured-v2.service';

export class WorkspaceService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/workspace';

    public getWorkspaces(): Promise<WorkspaceResponse | undefined> {
        return this.get(`${this.baseUrl}`);
    }

    public getWorkspace(id: number): Promise<Workspace | undefined> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    public addWorkspace(workspace: AddWorkspaceRequest): Promise<Workspace | undefined> {
        return this.post(this.baseUrl, workspace);
    }

    public updateWorkspace(current: Workspace, updated: Workspace): Promise<Workspace | undefined> {
        const patch = rfc.createPatch(current, updated);
        return this.patch(`${this.baseUrl}/${current.id}`, patch);
    }

    public deleteWorkspace(id: number): Promise<boolean | undefined> {
        return this.delete(`${this.baseUrl}/${id}`);
    }

    public updateWorkspaceBuilds(workspaceId: number, buildIds: number[]): Promise<number[] | undefined> {
        return this.post(`${this.baseUrl}/${workspaceId}/build/upload`, buildIds);
    }

    public updateRecentWorkspace(workspaceRequest: RecentWorkspaceRequest): Promise<RecentWorkspace | undefined> {
        return this.post(`${this.baseUrl}/recent`, workspaceRequest);
    }

    public updateWorkspaceInitialBbox(previousMapBbox: number[]): Promise<number[] | undefined> {
        return this.post(`${this.baseUrl}/recent/bbox`, { bbox: previousMapBbox });
    }
}


export interface AddWorkspaceRequest {
    workspaceId: string;
    name: string;
    description: string;
    country: string;
    region: string;
    city: string;
    zipCode?: string;
}

export interface WorkspaceResponse {
    workspaces: Workspace[];
    recentWorkspace: RecentWorkspace;
}

export interface RecentWorkspaceRequest {
    recentWorkspaceId?: number;
    recentDesignMode?: DesignMode;
}
