import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { TableCell, TableRow } from '@mui/material';
import { IconButton } from '@orbit/icon-button';
import { MainPalettes, MainThemeTokens } from '@orbit/theme-provider';

import { CabinetSplicePlanBuildInfo } from '../../../helpers/cabinet-splice-plan-helper';
import Down from '../../ui-elements/icon_arrow_downward.svg';
import Up from '../../ui-elements/icon_arrow_upward.svg';
import { useCabinetSplicePlanRow } from './cabinet-splice-plan-row.hooks';

export const CabinetSplicePlanRow = (props: CabinetSplicePlanBuildInfo): JSX.Element => {
    const {
        depth,
        cableId,
        cableIdClassName,
        buildType,
        fiberCount,
        portRange,
        portRangeClassName,
        showOrderArrows,
        handlePortRangeClick,
        onUpArrowClick,
        onDownArrowClick
    } = useCabinetSplicePlanRow(props);

    const arrowUpIcon: ReactNode = <img alt={"Move Up"} src={Up}></img>
    const arrowDownIcon: ReactNode = <img alt={"Move Down"} src={Down}></img>

    return (
        <TableRow className="cabinet-splice-plan-row">
            <TableCell className={classNames("cable-id-cell", cableIdClassName)}>
                {depth ? <i className="material-icons">subdirectory_arrow_right</i> : null}
                <button className="cable-id-button">{cableId}</button>
            </TableCell>
            <TableCell>{buildType}</TableCell>
            <TableCell>{fiberCount}</TableCell>
            <TableCell>
                <div className={portRangeClassName} onClick={handlePortRangeClick}>{portRange}</div>
            </TableCell>
            {showOrderArrows &&
                <TableCell>
                    <div className='order-arrows'>
                        <IconButton palette={MainPalettes.primary} token={MainThemeTokens.dark} disabled={false} placement='bottom' icon={arrowUpIcon} onClick={onUpArrowClick} />
                        <IconButton palette={MainPalettes.primary} token={MainThemeTokens.dark} disabled={false} placement='bottom' icon={arrowDownIcon} onClick={onDownArrowClick} />
                    </div>
                </TableCell>
            }
        </TableRow>
    );
};
