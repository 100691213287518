/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { Tooltip } from '@mui/material';
import { LocalizationKeys, TooltipLocalizationKeys } from '../../../../locales/types';
import { ElectricalUnits } from '../../../../models/electricalUnits';
import { Card } from '../../../card/card';
import { CardContentComponent } from '../../../card/card-content.component';
import { CardPropertyInline } from '../../../card/card-controls';
import { CardHeaderComponent } from '../../../card/card-header.component';
import { InlineSelect } from '../../../ui-elements/inline-select';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { usePowerTetherCard } from './power-tether-card.hooks';
import { PowerTetherProps } from './power-tether-card.types';

export const PowerTetherCard = (props: PowerTetherProps): JSX.Element | null => {
    const { selectedTetherNumber, disabled } = props;

    const { t } = useTranslation();

    const {
        subheader, lengthId, maximumLoadId, wireSizeId, pairsId, powerUnitId, powerTetherOptions, partNumber, wireSizeRecommendation, pairsRecommendation, disabledWhenAwaiting,
        unselectTether, handleMaximumLoadChange, handleWireSizeChange, handlePairsChange, handleLengthChange, handlePowerUnitChange, handleDeleteTether
    } = usePowerTetherCard(props);
    const tetherIcon = disabled ? require('../../../../icons/locked.png') : require('../../../../icons/power-device.png');

    const recommendations = <>
        {wireSizeRecommendation ? CardPropertyInline("Wire Size", wireSizeRecommendation) : CardPropertyInline("Wire Size", "", t(LocalizationKeys.NotApplicable))}
        {pairsRecommendation ? CardPropertyInline("Pairs", pairsRecommendation) : CardPropertyInline("Pairs", "", t(LocalizationKeys.NotApplicable))}
    </>
    return (
        <Card id="Tether-info" onCloseClick={unselectTether}>
            <CardHeaderComponent title={`${t(LocalizationKeys.Power)} ${selectedTetherNumber}`} subheader={subheader} icon={tetherIcon} iconBorder={true} />
            <CardContentComponent>
                {disabledWhenAwaiting ? (
                    <div className="spinner" ><DnaSpinner /></div>
                ) : (
                    <>
                        {partNumber ? CardPropertyInline(t(LocalizationKeys.PartNumber), partNumber) : CardPropertyInline(t(LocalizationKeys.PartNumber), "", t(LocalizationKeys.NotApplicable))}
                        {powerTetherOptions &&
                            <>
                                <InlineSelect
                                    key="maximumLoad"
                                    label={t(LocalizationKeys.MaximumLoad)}
                                    value={maximumLoadId ?? ""}
                                    values={powerTetherOptions.maximumLoads?.map(m => m.id)}
                                    displayValues={powerTetherOptions.maximumLoads?.map(m => m.shortDescription ?? m.id)}
                                    units={ElectricalUnits.watts}
                                    onUpdate={handleMaximumLoadChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                />
                                <InlineSelect
                                    key="wireSize"
                                    label={t(LocalizationKeys.WireSize)}
                                    value={wireSizeId ?? ""}
                                    values={powerTetherOptions.wireSizes?.map(w => w.id)}
                                    displayValues={powerTetherOptions.wireSizes?.map(w => w.shortDescription ?? w.id)}
                                    onUpdate={handleWireSizeChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                />
                                <InlineSelect
                                    key="pairs"
                                    label={t(LocalizationKeys.Pairs)}
                                    value={pairsId ?? ""}
                                    values={powerTetherOptions.pairsOptions?.map(p => p.id)}
                                    displayValues={powerTetherOptions.pairsOptions?.map(p => p.shortDescription ?? p.id)}
                                    onUpdate={handlePairsChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                />
                                <InlineSelect
                                    key="length"
                                    label={t(LocalizationKeys.Length)}
                                    value={lengthId ?? ""}
                                    values={powerTetherOptions.lengths.map(l => l.id)}
                                    displayValues={powerTetherOptions.lengths.map(l => l.shortDescription ?? l.id)}
                                    onUpdate={handleLengthChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                />
                                <InlineSelect
                                    key="powerUnit"
                                    label={t(LocalizationKeys.Downconverter)}
                                    value={powerUnitId ?? ""}
                                    values={powerTetherOptions.powerUnits?.map(p => p.id)}
                                    displayValues={powerTetherOptions.powerUnits?.map(p => p.shortDescription ?? p.id)}
                                    onUpdate={handlePowerUnitChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                />
                            </>}
                        <div className="card-footer">
                            <Tooltip arrow={true} placement="top" title={t(TooltipLocalizationKeys.DeleteTether)?.toLowerCase()}>
                                <div>
                                    <TextButton palette={MainPalettes.error} onClick={handleDeleteTether} disabled={disabled || disabledWhenAwaiting}>
                                        <i className="material-icons">delete</i>
                                    </TextButton>
                                </div>
                            </Tooltip>
                        </div>
                        {(wireSizeRecommendation || pairsRecommendation) ?
                            <div className="recommendation">
                                <span>{t(LocalizationKeys.Recommendation)}</span>
                                {recommendations}
                            </div>
                            :
                            ''
                        }
                    </>
                )}
            </CardContentComponent>
        </Card>
    );
}
