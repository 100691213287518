import { MapClickEvent } from '../models/map-click-event';
import { store } from '../dna';
import { addPole } from '../redux/pole.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class PoleTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.Pole,
        name: 'Pole',
        shortcut: 'P',
    };

    public static execute(event: MapClickEvent): void {
        addPole({ x: event.x, y: event.y })(store.dispatch);
    }
}
