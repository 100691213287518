import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SplicePoint } from '../../models/splice-point';
import { WebServiceErrorHandlingBehavior } from '../../services/abstract-web-v2.service';
import { SplicePointService } from '../../services/bulk/splice-point.service';
import { NotificationService } from '../../services/notification.service';
import { createSecuredAsyncAction } from '../action';

export interface SplicePointState {
    splicePoints: SplicePoint[];
}

const initialState: SplicePointState = {
    splicePoints: []
};

function loadAllReducer(state: SplicePointState, action: PayloadAction<SplicePoint[]>): void {
    const splicePoints = action.payload;
    state.splicePoints = splicePoints;
}

function addSplicePointsReducer(state: SplicePointState, action: PayloadAction<SplicePoint[]>) {
    state.splicePoints.push(...action.payload);
}

function removeAllReducer(state: SplicePointState): void {
    state.splicePoints = [];
}
function addSplicePointReducer(state: SplicePointState, action: PayloadAction<SplicePoint>): void {
    const splicePoint = action.payload;
    state.splicePoints.push(splicePoint);
}
function cancelSplicePointMoveReducer(state: SplicePointState, action: PayloadAction<number>): void {
    const splicePoint = state.splicePoints.find(s => s.id === action.payload);
    if (splicePoint) {
        const originalElementId = splicePoint.elementId;
        splicePoint.elementId = 0;
        splicePoint.elementId = originalElementId;
    }
}
function deleteSplicePointReducer(state: SplicePointState, action: PayloadAction<number>): void {
    const splicePointId = action.payload;
    if (!splicePointId) {
        return;
    }

    const toRemoveIdx = state.splicePoints.findIndex(s => s.id === splicePointId);
    state.splicePoints.splice(toRemoveIdx, 1);
}
function deleteSplicePointsForBuildsReducer(state: SplicePointState, action: PayloadAction<number[]>): void {
    const buildIds = action.payload;
    if (!buildIds) {
        return;
    }
    const splicePointsToKeep = state.splicePoints.filter(s => !s.buildId || !buildIds.includes(s.buildId));
    state.splicePoints = splicePointsToKeep;
}

function releaseSplicePointReducer(state: SplicePointState, action: PayloadAction<number>): void {
    const splicePointId = action.payload;
    const splicePointIdx = state.splicePoints.findIndex(s => s.id === splicePointId);
    if (splicePointIdx >= 0) {
        const splicePoint = state.splicePoints[splicePointIdx];
        splicePoint.modifiedById = null;
        splicePoint.lastModified = new Date(Date.now());
        state.splicePoints[splicePointIdx] = splicePoint;
    }
}
function updateSplicePointReducer(state: SplicePointState, action: PayloadAction<SplicePoint>): void {
    const splicePoint = action.payload;
    const idx = state.splicePoints.findIndex(s => s.id === splicePoint.id);
    if (idx === -1) {
        state.splicePoints.push(splicePoint);
    } else {
        state.splicePoints[idx] = splicePoint;
    }
}
function updateSplicePointsReducer(state: SplicePointState, action: PayloadAction<SplicePoint[]>): void {
    const updatedSplicePoints: SplicePoint[] = action.payload;
    const updatedSplicePointIds = updatedSplicePoints.map(s => s.id);
    const splicePoints = state.splicePoints.filter(s => !updatedSplicePointIds.includes(s.id));
    splicePoints.push(...updatedSplicePoints);
    state.splicePoints = splicePoints;
}
function linkSplicePointToBuildReducer(state: SplicePointState, action: PayloadAction<{ splicePointId: number; buildId: number }>): void {
    const { splicePointId, buildId } = action.payload;
    const splicePoint = state.splicePoints.find(s => s.id === splicePointId && !s.buildId);
    if (splicePoint) {
        splicePoint.buildId = buildId;
    }
}
function unlinkSplicePointFromBuildReducer(state: SplicePointState, action: PayloadAction<{ splicePointId: number; buildId: number }>): void {
    const { splicePointId, buildId } = action.payload;
    const splicePoint = state.splicePoints.find(s => s.id === splicePointId && s.buildId === buildId);
    if (splicePoint) {
        splicePoint.buildId = null;
    }
}

function unlinkSplicePointsFromBuildReducer(state: SplicePointState, action: PayloadAction<number>): void {
    const buildId = action.payload;
    state.splicePoints
        .filter(s => s.buildId === buildId)
        .forEach(s => s.buildId = null);
}

function loadSplicePointsFromImportReducer(state: SplicePointState, action: PayloadAction<SplicePoint[]>): void {
    const splicePoints = action.payload;
    state.splicePoints.push(...splicePoints);
}


const { actions, reducer } = createSlice({
    name: 'splice-point',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        removeAll: removeAllReducer,
        addSplicePoint: addSplicePointReducer,
        addSplicePoints: addSplicePointsReducer,
        cancelSplicePointMove: cancelSplicePointMoveReducer,
        deleteSplicePoint: deleteSplicePointReducer,
        deleteSplicePointsForBuilds: deleteSplicePointsForBuildsReducer,
        releaseSplicePoint: releaseSplicePointReducer,
        updateSplicePoint: updateSplicePointReducer,
        updateSplicePoints: updateSplicePointsReducer,
        linkSplicePointToBuild: linkSplicePointToBuildReducer,
        unlinkSplicePointFromBuild: unlinkSplicePointFromBuildReducer,
        unlinkSplicePointsFromBuild: unlinkSplicePointsFromBuildReducer,
        loadSplicePointsFromImport: loadSplicePointsFromImportReducer
    }
});

export { reducer as SplicePointsReducer };
export const { cancelSplicePointMove, removeAll, loadSplicePointsFromImport } = actions;

export const loadAll = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        const splicePoints = await service.getAll(workspaceId);
        if (splicePoints) {
            dispatch(actions.loadAll(splicePoints));
        }
    });
};

export const addSplicePoint = (elementId: number, workspaceId: number, buildId: number | null = null) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const splicePoint = await service.addSplicePoint(elementId, workspaceId, buildId);
            dispatch(actions.addSplicePoint(splicePoint!));
        } catch (error) {
            NotificationService.error(`Adding Splice Plan failed: ${error}`);
        }
    });
};

export const deleteSplicePoint = (splicePointId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.deleteSplicePoint(splicePointId);
            dispatch(actions.deleteSplicePoint(splicePointId));
        } catch (error) {
            NotificationService.error(`Deleting Splice Point failed: ${error}`);
        }
    });
};

export const deleteSplicePointsForBuilds = (buildIds: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            service.deleteSplicePointsForBuilds(buildIds);
            dispatch(actions.deleteSplicePointsForBuilds(buildIds));
        } catch (error) {
            NotificationService.error(`Deleting Build Splice Points failed: ${error}`);
        }
    });
};

export const undoDeleteSplicePointsForBuilds = (buildIds: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const splicePoints = await service.undoDeleteSplicePointsForBuilds(buildIds);
            splicePoints && dispatch(actions.addSplicePoints(splicePoints));
        } catch (error) {
            NotificationService.error(` Undo Deleting Build Splice Points failed: ${error}`);
        }
    });
};

export const moveSplicePoint = (splicePointId: number, toElementId: number, toBuildId: number | null) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const updatedSplicePoint = await service.moveSplicePoint(splicePointId, toElementId, toBuildId);
            dispatch(actions.updateSplicePoint(updatedSplicePoint!));
        } catch (error) {
            NotificationService.error(`Moving Splice Point failed: ${error}`);
        }
    });
};

export const linkSplicePointToBuild = (splicePointId: number, buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.linkSplicePointToBuild(splicePointId, buildId);
            dispatch(actions.linkSplicePointToBuild({ splicePointId, buildId }));
        } catch (error) {
            NotificationService.error(`Error linking splice point ${splicePointId} to build ${buildId}: ${error}`);
        }
    });
};

export const linkSplicePointsOnElementsToBuild = (elementIds: number[], buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        const splicePoints = await service.linkSplicePointsOnElementsToBuild(elementIds, buildId);
        if (splicePoints) {
            dispatch(actions.updateSplicePoints(splicePoints));
        }
    });
}

export const unlinkSplicePointFromBuild = (splicePointId: number, buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.unlinkSplicePointFromBuild(splicePointId, buildId);
            dispatch(actions.unlinkSplicePointFromBuild({ splicePointId, buildId }));
        } catch (error) {
            NotificationService.error(`Error unlinking splice point ${splicePointId} from build ${buildId}: ${error}`);
        }
    });
};

export const unlinkSplicePointsFromBuild = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.defaultNotifyOnError);
        try {
            await service.unlinkSplicePointsFromBuild(buildId);
            dispatch(actions.unlinkSplicePointsFromBuild(buildId));
        } catch (error) {
            NotificationService.error(`Error unlinking splice points from build ${buildId}: ${error}`);
        }
    });
};

export const lockSplicePointsConnectedToBuilds = (buildIds: number[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const splicePoints = await service.lockSplicePointsConnectedToBuilds(buildIds, workspaceId);
            if (splicePoints) {
                dispatch(actions.updateSplicePoints(splicePoints));
            }
        } catch (error) {
            NotificationService.error(`${error}`);
        }
    });
};

export const unlockSplicePointsConnectedToBuilds = (buildIds: number[], workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const splicePoints = await service.unlockSplicePointsConnectedToBuilds(buildIds, workspaceId);
            if (splicePoints) {
                dispatch(actions.updateSplicePoints(splicePoints));
            }
        } catch (error) {
            NotificationService.error(`${error}`);
        }
    });
};

export const modifySplicePoint = (splicePointId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new SplicePointService();
        const modifiedSplicePoint = await service.modifySplicePoint(splicePointId);
        if (modifiedSplicePoint) {
            dispatch(actions.updateSplicePoint(modifiedSplicePoint));
        }
    });
};

export const releaseSplicePoint = (splicePointId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.releaseSplicePoint(splicePointId));
        const service = new SplicePointService();
        await service.releaseSplicePoint(splicePointId);
    });
};
