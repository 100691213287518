import React from 'react';
import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, Tooltip } from '@mui/material';
import { TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../../../locales/types';
import { DesignMode } from '../../../../models/design-mode';
import { ReviewStatus } from '../../../../models/review-status';
import { CardCollapsibleComponent } from '../../../card/card-collapsible.component';
import { CardProperty, CardPropertyInline } from '../../../card/card-controls';
import { ColorSelectorProperty } from '../../../color-selector/color-selector-property';
import { InlineSelect } from '../../../ui-elements/inline-select';
import { BuildPropertiesProps, defaultBuildColors } from '../build/build-card.types';
import { useBuildProperties } from './build-properties.hooks';

export const BuildProperties = (props: BuildPropertiesProps) => {
    const { t } = useTranslation();
    const {
        build,
        collapsed,
        designAreaDisplayId,
        locationsCount,
        tapCount,
        serving,
        color,
        editDisabled,
        unlockDisabled,
        isLocked,
        splicePlanDisabled,
        designAreaList,
        designAreaDisplayText,
        isCanvasBuild,
        moreMenuAnchorEl,
        requestReviewDisabled,
        cancelReviewDisabled,
        uploadBuildDisabled,
        handleUpdateDesignArea,
        handleUpdateColor,
        handleResetColor,
        handleToggleDeleteBuildDialog,
        handleUnlockBuild,
        handleLockBuild,
        handleSplicePlanClick,
        handleBuildInfoClick,
        handleMoreMenuClick,
        handleMoreMenuClose,
        handleRequestReviewClick,
        handleCancelReviewClick,
        handleUploadBuildClick
    } = useBuildProperties(props);

    return (
        <CardCollapsibleComponent id="build-info" label={t(LocalizationKeys.Build)} collapsed={collapsed}>
            {isCanvasBuild ? (
                <InlineSelect
                    key={"design-area"}
                    label={t(LocalizationKeys.DesignArea)}
                    disabled={editDisabled}
                    value={designAreaDisplayId}
                    values={designAreaList.map(d => d.id)}
                    displayValues={designAreaList.map(d => `${d.name ?? d.projectId ?? d.id}`)}
                    onUpdate={handleUpdateDesignArea}
                />
            ) :
                (CardPropertyInline(t(LocalizationKeys.DesignArea), designAreaDisplayText))
            }
            {CardPropertyInline(t(LocalizationKeys.Locations), locationsCount)}
            {CardPropertyInline(t(LocalizationKeys.Taps), tapCount)}
            {CardPropertyInline(t(LocalizationKeys.Serving), serving)}
            {build.designMode === DesignMode.GISMode && CardProperty(t(LocalizationKeys.Color),
                <ColorSelectorProperty
                    color={color}
                    colorOptions={defaultBuildColors}
                    onUpdate={handleUpdateColor}
                    resetEnabled={true}
                    onReset={handleResetColor}
                />
            )}
            <div className="card-footer">
                <div className="footer-menu">
                    <Tooltip arrow placement="bottom" title={t(LocalizationKeys.Delete).toLowerCase()}>
                        <div>
                            <TextButton palette="error" disabled={editDisabled} onClick={handleToggleDeleteBuildDialog}>
                                <i className="material-icons">delete</i>
                            </TextButton>
                        </div>
                    </Tooltip>
                    <Tooltip arrow placement="bottom" title={t(isLocked ? LocalizationKeys.Unlock : LocalizationKeys.Lock).toLowerCase()}>
                        <div>
                            <TextButton disabled={unlockDisabled} onClick={isLocked ? handleUnlockBuild : handleLockBuild}>
                                {isLocked ? <i className="material-icons">lock_open</i> : <i className="material-icons">lock</i>}
                            </TextButton>
                        </div>
                    </Tooltip>
                </div>
                <Tooltip arrow placement="bottom" title={t(LocalizationKeys.MoreOptions).toLowerCase()}>
                    <div>
                        <TextButton onClick={handleMoreMenuClick}><i className="material-icons">more_vert</i></TextButton>
                    </div>
                </Tooltip>
                <Menu open={!!moreMenuAnchorEl} anchorEl={moreMenuAnchorEl} onClose={handleMoreMenuClose}>
                    {build.lastReviewStatus === ReviewStatus.Approved && isLocked ? (
							<MenuItem disabled={uploadBuildDisabled} onClick={handleUploadBuildClick}>{t(LocalizationKeys.UploadBuild)}</MenuItem>
						)
                        : build.lastReviewStatus === ReviewStatus.Open ? (
                            <MenuItem disabled={cancelReviewDisabled} onClick={handleCancelReviewClick}>{t(LocalizationKeys.CancelReview)}</MenuItem>
                        ) : (
                            <MenuItem disabled={requestReviewDisabled} onClick={handleRequestReviewClick}>{t(LocalizationKeys.RequestReview)}</MenuItem>
                        )
                    }
                    <MenuItem onClick={handleBuildInfoClick}>{t(LocalizationKeys.BuildInformation)}</MenuItem>
                    <MenuItem disabled={splicePlanDisabled} onClick={handleSplicePlanClick}>{t(LocalizationKeys.SplicePlan)}</MenuItem>
                </Menu>
            </div>
        </CardCollapsibleComponent>
    );
};
