import React, { useCallback } from 'react';
import { TetherDiagramProps } from '../../nap-diagram.types';
import { TerminalExtensionType } from '../../../../models/terminal-extension-type';
import { TextSvg } from '../text-svg';
import { useTetherDiagramSvg } from './tether-diagram-svg.hooks';

export const TetherDiagramSvg = (props: TetherDiagramProps): JSX.Element => {
    const {
        xOffset, className, path, tetherConnectorX, btnX, connected, isLeftTether,
        fiberCount, tether, pretermBuild
    } = useTetherDiagramSvg(props);

    const tether1 = useCallback((fill = "#606060", isExtended?: boolean): JSX.Element => {
        const path = isExtended ? "M6,137V70M6,66V14.54S5.58,8.92,9.53,5.35,19.17,2,19.17,2H26"
                                : "M6,137V14.54S5.58,8.92,9.53,5.35,19.17,2,19.17,2H26";
        return (
            <g transform="translate(13, 15)">
                <path d={path} fill="none" stroke="#9e9e9e" strokeWidth="4"/>
                <path d={path} fill="none" stroke="#9e9e9e" strokeWidth="4"/>
                <path d={path} fill="none" stroke={fill} strokeWidth="2"/>
                { 
                    isExtended ?
                        <g>
                            <line x1="1" y1="64" x2="11" y2="68" stroke="#000000" />
                            <line x1="1" y1="68" x2="11" y2="72" stroke="#000000" />
                        </g>
                        : null
                }
                <path d="M11,145.51H1a.5.5,0,0,1-.5-.5v-6a2.5,2.5,0,0,1,2.5-2.5H9a2.5,2.5,0,0,1,2.5,2.5v6A.5.5,0,0,1,11,145.51Z" fill={fill} stroke="#9e9e9e"/>
            </g>
        );
    }, []);

    const tether2 = useCallback((fill = "#606060", isExtended?: boolean): JSX.Element => {
        const path = isExtended ? "M15,2H10S6.2,1.85,4,4,2,10,2,10V34.63s0,3.44,1.86,5.42,5.63,2,5.63,2h57.6s4-.13,6,2S75,50,75,50v10m0,4v67"
                                : "M15,2H10S6.2,1.85,4,4,2,10,2,10V34.63s0,3.44,1.86,5.42,5.63,2,5.63,2h57.6s4-.13,6,2S75,50,75,50v81";
        return (
            <g transform="translate(24, 21)">
                <path d={path} fill="none" stroke="#9e9e9e" strokeWidth="4"/>
                <path d={path} fill="none" stroke={fill} strokeWidth="2"/>
                { 
                    isExtended ?
                        <g>
                            <line x1="70" y1="58" x2="80" y2="62" stroke="#000000" />
                            <line x1="70" y1="62" x2="80" y2="66" stroke="#000000" />
                        </g>
                        : null
                }
                <path d="M80,139.51H70a.5.5,0,0,1-.5-.5v-6a2.5,2.5,0,0,1,2.5-2.5h6a2.5,2.5,0,0,1,2.5,2.5v6A.5.5,0,0,1,80,139.51Z" fill={fill} stroke="#9e9e9e"/>
            </g>
        );
    }, []);

    return (
        <g transform={'translate(' + xOffset + ')'}>
            {
                !pretermBuild ?
                    <>
                        {
                            isLeftTether ?
                                tether ? tether1(undefined, tether.terminal?.terminalExtension?.terminalExtensionType === TerminalExtensionType.TetherExtender) : tether1("#E3E3E3")
                                :
                                tether ? tether2(undefined, tether.terminal?.terminalExtension?.terminalExtensionType === TerminalExtensionType.TetherExtender) : tether2("#E3E3E3")
                        }
                    </> :
                    <g className={className}>
                        <path d={path} strokeWidth="4" fill="none" />
                        <rect x={tetherConnectorX} y="132" width="12" height="15" rx="3" />
                    </g>
            }
            <g transform={`translate(${btnX}, ${pretermBuild ? 81 : 98})`}>
                {connected && fiberCount
                    ?
                    <g className="fibercount">
                        <circle cx="23" cy="18" r="18" />
                        <TextSvg x={23} y={18} text={fiberCount + 'F'} />
                    </g> : null
                }
            </g>
        </g>
    );
};
