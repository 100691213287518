import { DataValue } from '../../models/datavalue';
import { BuildData } from './build-data';
import { DesignAreaData } from './design-area-data';
import { SegmentData } from './segment-data';
import { SplicePointData } from './splice-point-data';

export class BulkBuildData extends BuildData {
    public splicePoints?: SplicePointData[];

    constructor(id: number, name: string, fieldSlack: DataValue, segments: SegmentData[], designAreas: DesignAreaData[], splicePoints?: SplicePointData[]) {
        super(id, name, fieldSlack, segments, designAreas);
        this.splicePoints = splicePoints;
    }
}