import './tap-svg.scss';

import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';

import { Tooltip } from '@mui/material';
import { LocalizationKeys, TooltipLocalizationKeys } from '../../locales/types';
import { Tether } from '../../models/schrodinger/tether';
import { TetherType } from '../../models/schrodinger/tether-type';
import { useTapDiagram } from './tap-diagram.hooks';
import { TapDiagramProps } from './tap-diagram.types';
import { TetherSvg, TetherSvgDefs } from './tether-svg/tether-svg';

const dimensions = {
    width: 352,
    height: 392
};

const TapDiagramDefs = (
    <>
        <filter id="btnShadow" x="0" y="-2" width="44" height="50" filterUnits="userSpaceOnUse">
            <feOffset dy="3" in="SourceAlpha" />
            <feGaussianBlur stdDeviation="2" result="blur" />
            <feFlood floodOpacity="0.3" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>
        <filter id="btnShadowClicked" x="0" y="-2" width="44" height="50" filterUnits="userSpaceOnUse">
            <feOffset dy="2" in="SourceAlpha" />
            <feGaussianBlur stdDeviation="1.5" result="blur" />
            <feFlood floodOpacity="0.4" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>
        <filter id="locationLabelShadow" x="0" y="0" width="67" height="51" filterUnits="userSpaceOnUse">
            <feOffset dy="3" in="SourceAlpha" />
            <feGaussianBlur stdDeviation="4.5" result="location-label-text" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="location-label-text" />
            <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient id="cableGradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#dbd4b8" stopOpacity="0.502" />
            <stop offset="1" stopColor="#2e2e2e" />
        </linearGradient>
        <pattern id="braidedCable" width="0.023" height="14">
            <g className="cable-subunit-power" transform="translate(-7.3)">
                <path className="cable-subunit-inner" d="M 3.738964796066284 13.48339939117432 L 0.5000047087669373 13.48339939117432 L 0.5000047087669373 12.96981334686279 L 7.572959899902344 0.4999992251396179 L 11.17457389831543 0.4999992251396179 L 3.738964796066284 13.48339939117432 Z" />
                <path className="cable-subunit-outer" d="M 3.44912052154541 12.98339939117432 L 10.31202793121338 0.9999992251396179 L 7.864191055297852 0.9999992251396179 L 1.067133069038391 12.98339939117432 L 3.44912052154541 12.98339939117432 M 4.028804779052734 13.98339939117432 L 4.687500222644303e-06 13.98339939117432 L 4.687500222644303e-06 12.83788967132568 L 7.281734466552734 -7.81249980263965e-07 L 12.0371150970459 -7.81249980263965e-07 L 4.028804779052734 13.98339939117432 Z" />
            </g>
            <g className="cable-subunit-optical" transform="translate(-4)">
                <path className="cable-subunit-inner" d="M 4.439898490905762 13.48339939117432 L 0.8611747026443481 13.48339939117432 L 8.272976875305176 0.4999992251396179 L 11.87549781799316 0.4999992251396179 L 4.439898490905762 13.48339939117432 Z" />
                <path className="cable-subunit-outer" d="M 4.150053977966309 12.98339939117432 L 11.01295280456543 0.9999992251396179 L 8.56326961517334 0.9999992251396179 L 1.72233521938324 12.98339939117432 L 4.150053977966309 12.98339939117432 M 4.729738712310791 13.98339939117432 L -1.44531247769919e-06 13.98339939117432 L 7.982668399810791 -7.81249980263965e-07 L 12.73803901672363 -7.81249980263965e-07 L 4.729738712310791 13.98339939117432 Z" />
            </g>
            <g className="cable-subunit-power" transform="translate(0)">
                <path className="cable-subunit-inner" d="M 4.439898490905762 13.48339939117432 L 0.8611747026443481 13.48339939117432 L 8.272976875305176 0.4999992251396179 L 11.87549781799316 0.4999992251396179 L 4.439898490905762 13.48339939117432 Z" />
                <path className="cable-subunit-outer" d="M 4.150053977966309 12.98339939117432 L 11.01295280456543 0.9999992251396179 L 8.56326961517334 0.9999992251396179 L 1.72233521938324 12.98339939117432 L 4.150053977966309 12.98339939117432 M 4.729738712310791 13.98339939117432 L -1.44531247769919e-06 13.98339939117432 L 7.982668399810791 -7.81249980263965e-07 L 12.73803901672363 -7.81249980263965e-07 L 4.729738712310791 13.98339939117432 Z" />
            </g>
            <g className="cable-subunit-optical" transform="translate(4)">
                <path className="cable-subunit-inner" d="M 4.439898490905762 13.48339939117432 L 0.8611747026443481 13.48339939117432 L 8.272976875305176 0.4999992251396179 L 11.87549781799316 0.4999992251396179 L 4.439898490905762 13.48339939117432 Z" />
                <path className="cable-subunit-outer" d="M 4.150053977966309 12.98339939117432 L 11.01295280456543 0.9999992251396179 L 8.56326961517334 0.9999992251396179 L 1.72233521938324 12.98339939117432 L 4.150053977966309 12.98339939117432 M 4.729738712310791 13.98339939117432 L -1.44531247769919e-06 13.98339939117432 L 7.982668399810791 -7.81249980263965e-07 L 12.73803901672363 -7.81249980263965e-07 L 4.729738712310791 13.98339939117432 Z" />
            </g>
        </pattern>
    </>
);

const createAddTetherButton = (x: number, y: number, tetherType: TetherType, addTether: () => void, disabled: boolean): JSX.Element => {
    const buttonClass = classNames(`btn-add-${tetherType === TetherType.Optical ? "optical" : "power"}`, { disabled });
    const crossClass = classNames("btn-add-cross", { disabled });
    return (
        <Tooltip arrow={true} placement="left" title={i18next.t(tetherType === TetherType.Optical ? TooltipLocalizationKeys.AddOpticalTether : TooltipLocalizationKeys.AddPowerTether)?.toLowerCase()}>
            <g className={buttonClass} onClick={disabled ? undefined : addTether} transform={`translate(${x}, ${y})`}>
                <circle cx="23" cy="18" r="20" />
                <line className={crossClass} x1="16" x2="30" y1="18" y2="18" />
                <line className={crossClass} x1="23" x2="23" y1="11" y2="25" />
            </g>

        </Tooltip>
    );
};

const buttonBar = (addPowerTether: () => void, addOpticalTether: () => void, addPowerTetherDisabled: boolean, addOpticalTetherDisabled: boolean): JSX.Element => {
    return (
        <g transform="translate(-18, 83)">
            {createAddTetherButton(0, 0, TetherType.Power, addPowerTether, addPowerTetherDisabled)}
            {createAddTetherButton(0, 48, TetherType.Optical, addOpticalTether, addOpticalTetherDisabled)}
        </g>
    )
};

const tetherCounter = (tetherCount: number): JSX.Element => {
    return (
        <g className="tether-counter" transform="translate(173, 5)">
            <rect x="-45" y="-12" width="90" height="24" rx="12" />
            <text x="0" y="0" textAnchor="middle" dominantBaseline="central">
                {tetherCount === 1 ? "1 tether" : `${tetherCount} tethers`}
            </text>
        </g>
    );
};

const createPagingButtons = (pageSize: number, tapCount: number, currentPage: number, setPage: (page: number) => void): JSX.Element => {
    const bouttonCount = Math.ceil(tapCount / pageSize);
    const buttons: JSX.Element[] = [];
    for (let i = 0; i < bouttonCount; i++) {
        buttons.push(<circle key={'page' + i} cx={i * 36} r="9" onClick={(): void => setPage(i)} className={'page-btn' + (i === currentPage ? ' current' : '')} />);
    }
    const w = 346;
    const c = w / 2;
    const x = c + 18 - (bouttonCount * 18);
    return (
        <g transform={`translate(${x}, 374)`}>
            {buttons}
        </g>
    );
};

const createCable = (): JSX.Element => {
    return (
        <g className="cable" transform="translate(0, 34)">
            <rect fill="url(#braidedCable)" width={dimensions.width} height="14" />
            <rect className="cable-gradient" width={dimensions.width} height="14" />
        </g>
    );
};

const createTethers = (pageSize: number, tethers: Tether[], page: number, selectedTetherId: number | undefined, selectTether: () => void): JSX.Element => {
    const start = page * pageSize;
    const finish = start + pageSize;
    return (
        <g transform="translate(0, -6)">
            {tethers.map((t, idx) => (start <= idx && idx < finish)
                ? <TetherSvg key={idx} tether={t} pagePosition={idx - start} index={idx + 1} selected={t.id === selectedTetherId} onClick={selectTether} />
                : null)
            }
        </g>
    );
}

const createLocationLabel = (location: string): JSX.Element => {
    return (
        <g className="location-label" transform="translate(155, 29)">
            <g className="container">
                <rect className="outline" width="40" height="24" rx="4" />
                <rect className="fill" width="39" height="23" rx="3.5" />
            </g>
            <text x="20" y="12" textAnchor="middle" dominantBaseline="central">
                {`${i18next.t(LocalizationKeys.Loc)} ${location}`}
            </text>
        </g>
    );
}

export const TapDiagram = (props: TapDiagramProps): JSX.Element => {
    const {
        pageSize, locationId, page, tethers, selectedTetherId, addPowerTetherDisabled, addOpticalTetherDisabled,
        setPage, selectTether, createPowerTether, createOpticalTether
    } = useTapDiagram(props);
    return (
        <div className="tap-diagram" style={{ height: dimensions.height }}>
            <svg width={dimensions.width} height={dimensions.height} className="diagram">
                <defs>
                    {TapDiagramDefs}
                    {TetherSvgDefs}
                </defs>
                <g transform="translate(0, 15)">
                    {buttonBar(createPowerTether, createOpticalTether, addPowerTetherDisabled, addOpticalTetherDisabled)}
                    {tetherCounter(tethers.length)}
                    {createCable()}
                    {createTethers(pageSize, tethers, page, selectedTetherId, selectTether)}
                    {createLocationLabel(locationId)}
                </g>
                {createPagingButtons(pageSize, tethers.length, page, setPage)}
            </svg>
        </div>
    );
};
