import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MainPalettes } from '@orbit/theme-provider';

import { useCountryStates } from '../../../../hooks/country-states.hooks';
import { LocalizationKeys } from '../../../../locales/types';
import { BuildType } from '../../../../models/build-type';
import { DesignArea } from '../../../../models/design-area';
import { DesignMode } from '../../../../models/design-mode';
import { addNewDesignArea, updateDesignArea } from '../../../../redux/design-area.state';
import { clearSelection, selectDesignArea } from '../../../../redux/selection.state';
import {
    buildTypeSelector, designAreaSelector, workspaceSelector
} from '../../../../selectors/root.selectors';
import { CreateDesignAreaRequest } from '../../../../services/design-area.service';
import { ValidationHelper } from '../../../../validation/validation-helper';
import { DesignAreaCardProps } from './design-area-card.types';

export const useDesignAreaCard = (props: DesignAreaCardProps) => {
    const { designAreaId } = props;

    const { designAreas } = useSelector(designAreaSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const buildType = useSelector(buildTypeSelector);
    const schrodingerMode = buildType === BuildType.Schrodinger;
    const isCityMandatory = schrodingerMode;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const designArea = designAreas.find((a) => a.id === designAreaId);
    const [name, setName] = useState(designArea?.name ?? t(LocalizationKeys.DefaultDesignArea, { index: designAreas.length + 1 }));
    const [projectId, setProjectId] = useState(designArea?.projectId ?? currentWorkspace?.workspaceId ?? "");
    const [description, setDescription] = useState(designArea?.description ?? currentWorkspace?.description ?? "");
    const [country, setCountry] = useState(designArea?.country ?? currentWorkspace?.country ?? "");
    const [region, setRegion] = useState(designArea?.region ?? currentWorkspace?.region ?? "");
    const [city, setCity] = useState<string>(designArea?.city ?? currentWorkspace?.city ?? "");
    const [zipCode, setZipCode] = useState<string>(designArea?.zipCode ?? currentWorkspace?.zipCode ?? "");

    // Validation
    const [nameHelperText, setNameHelperText] = useState<string | undefined>();
    const [projectIdHelperText, setProjectIdHelperText] = useState<string | undefined>();
    const [cityHelperText, setCityHelperText] = useState<string | undefined>();

    const { countries, states: regions, resetCountryStates } = useCountryStates(currentWorkspace, country);

    const canSave = (
        !nameHelperText &&
        !projectIdHelperText &&
        !!country &&
        !!region &&
        !cityHelperText
    );

    const reset = useCallback(() => {
        setProjectId("");
        setName("");
        setDescription("");
        setCity("");
        setCountry("");
        setRegion("");
        setZipCode("");
        resetCountryStates();
    }, [resetCountryStates]);

    const onClose = useCallback(() => {
        dispatch(clearSelection());
        dispatch(selectDesignArea());
    }, [dispatch]);

    const onCancel = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    const onSave = useCallback(() => {
        if (designArea) {
            const updatedDesignArea: DesignArea = {
                ...designArea,
                name,
                projectId,
                description,
                country,
                region,
                city,
                zipCode,
            };
            dispatch(updateDesignArea(designArea, updatedDesignArea));
        }
        else {
            const request: CreateDesignAreaRequest = {
                workspaceId: currentWorkspace?.id ?? 0,
                name,
                designMode: DesignMode.CanvasMode,
                projectId,
                description,
                country,
                region,
                city,
                zipCode,
            };
            dispatch(addNewDesignArea(request, false, false));
            reset();
        }
        onClose();
    }, [city, reset, onClose, country, currentWorkspace, description, designArea, dispatch, name, projectId, region, zipCode]);


    const headerTitle = t(LocalizationKeys.DesignAreaInformation);

    const onNameInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setName(entry);
        setNameHelperText(ValidationHelper.validateString(entry, t, { empty: false }));
    }, [t]);

    const onProjectIdInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setProjectId(entry);
        setProjectIdHelperText(ValidationHelper.validateString(entry, t, { empty: false, specialCharacters: false }))
    }, [t]);

    const onDescInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setDescription(entry);
    }, []);

    const onZipCodeInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setZipCode(entry);
    }, []);

    const onCityInputChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setCity(entry);
        setCityHelperText(ValidationHelper.validateString(entry, t, { empty: !isCityMandatory }))
    }, [t, isCityMandatory]);

    const onCountryValueChanged = useCallback((e: React.ChangeEvent<any>) => {
        const entry = e.target.value;
        setCountry(entry);
    }, []);

    const onRegionValueChanged = useCallback((e: React.ChangeEvent<any>) => {
        const entry = e.target.value;
        setRegion(entry);
    }, []);

    useEffect(() => {
        if (regions?.length && !regions.includes(region)) {
            setRegion(regions[0]);
        }
    }, [region, regions])

    return {
        dialogProps: {
            open: true,
            className: "workspace-info",
            title: headerTitle,
            positiveTitle: t(LocalizationKeys.Save),
            negativeTitle: t(LocalizationKeys.Cancel),
            onClose: onClose,
            onNegativeClick: onCancel,
            onPositiveClick: onSave,
            disablePositive: !canSave
        },
        nameProps: {
            name: 'design-area-name',
            palette: MainPalettes.primary,
            label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.DesignAreaName) }),
            value: name,
            maxLength: 25,
            error: !!nameHelperText,
            helperText: nameHelperText,
            onChange: onNameInputChanged
        },
        projectIdProps: {
            name: 'project-id',
            palette: MainPalettes.primary,
            label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.DesignAreaProjectId) }),
            value: projectId,
            maxLength: 20,
            error: !!projectIdHelperText,
            helperText: projectIdHelperText,
            onChange: onProjectIdInputChanged
        },
        descriptionProps: {
            name: 'description',
            palette: MainPalettes.primary,
            label: t(LocalizationKeys.Description),
            value: description,
            maxLength: 100,
            onChange: onDescInputChanged
        },
        zipCodeProps: {
            name: 'design-area-zip-code',
            palette: MainPalettes.primary,
            label: t(LocalizationKeys.ZipCode),
            value: zipCode,
            maxLength: 20,
            onChange: onZipCodeInputChanged
        },
        cityProps: {
            name:' design-area-city',
            palette: MainPalettes.primary,
            label: isCityMandatory ? t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.City) }) : t(LocalizationKeys.City),
            value: city,
            maxLength: 30,
            error: !!cityHelperText,
            helperText: cityHelperText,
            onChange: onCityInputChanged
        },
        countryProps: {
            label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.Country) }),
            palette: MainPalettes.primary,
            value: country,
            onChange: onCountryValueChanged
        },
        regionProps: {
            label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.State) }),
            palette: MainPalettes.primary,
            value: region,
            onChange: onRegionValueChanged
        },
        countries, regions,
    };
};
