import { useSelector, useDispatch } from 'react-redux';
import { StateModel } from '../../../redux/reducers';
import { useEffect, useState, useCallback } from "react";
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { clearSelection } from '../../../redux/selection.state';
import { revertPretermLateral } from '../../../redux/build.state';

export const useRevertPretermLateralDialog = () => {
    const workspace = useSelector((state: StateModel) => state);
    const { dialog } = workspace.overlay;
    const { selectedBuildId } = workspace.selection;
    const pretermBuildId = selectedBuildId ?? -1;
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        setOpen(dialog === DialogType.RevertPretermLateral);
    }, [dialog]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onCancel = useCallback(() => {
        onClose();
        dispatch(clearSelection());
    }, [dispatch, onClose]);

    const onRevertPretermLateral = useCallback((): void => {
        if (pretermBuildId !== -1) {
            revertPretermLateral(pretermBuildId)(dispatch);
        }
        onClose();
    }, [dispatch, pretermBuildId, onClose]);

    return { open, onClose, onCancel, onRevertPretermLateral, pretermBuildId };
}