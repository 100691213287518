import { Data } from './data';
import { TetherData } from './tether-data';

export class TapData extends Data {
    public tapIndex: number;
    public tethers: TetherData[];

    constructor(id: number, tapIndex: number, tethers: TetherData[]) {
        super(id);
        this.tapIndex = tapIndex;
        this.tethers = tethers;
    }
}
