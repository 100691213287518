import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsAction, AnalyticsHelper } from '../../helpers/analytics-helper';
import { PresetHelper } from '../../helpers/preset-helper';
import { LocalizationKeys } from '../../locales/types';
import { UserPreset } from '../../models/userpreset';
import { addPreset } from '../../redux/preset.state';
import { clear as clearWizardHistory } from '../../redux/wizard.state';
import { presetSelector } from '../../selectors/root.selectors';
import { PresetConfigComponents } from './preset-manager.types';
import { CardInputMaxLength } from '../card/card.types';

export const useCablePresetConfig = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const presets = useSelector(presetSelector);
    const [presetName, setPresetName] = useState(t(LocalizationKeys.DefaultPresetName, { index: presets.presets.length + 1 }).toString());
    const [currentComponent, setCurrentComponent] = useState<PresetConfigComponents | undefined>(PresetConfigComponents.PartSelection);
    const isPresetNameValid = presetName && presetName.trim().length !== 0;

    const onCancelWizard = useCallback((): void => {
        setCurrentComponent(PresetConfigComponents.PartSelection);
        dispatch(clearWizardHistory());
    }, [dispatch]);

    const onSavePreset = useCallback((preset: UserPreset, isDefault: boolean): void => {
        AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.savePresets);

        if (isPresetNameValid && presetName.length <= CardInputMaxLength.PRESET_NAME) {
            preset.name = presetName;
            preset.isDefault = isDefault;
            if (preset.id < 0) {
                const presetData = PresetHelper.toDto(preset);
                addPreset(presetData)(dispatch);
            }
        } else {
            setPresetName("");
        }
    }, [presetName, dispatch, isPresetNameValid]);

    const handlePresetNameChange = useCallback((event: React.ChangeEvent<any>): void => {
        const presetName = event.target?.value;
        if (presetName.length <= CardInputMaxLength.PRESET_NAME) {
            setPresetName(presetName);
        }
    }, []);

    const handleConfigChange = useCallback((component: PresetConfigComponents): void => {
        setCurrentComponent(component);
    }, []);

    const handleStartGuide = useCallback((): void => {
        setCurrentComponent(PresetConfigComponents.Wizard);
    }, []);

    const isInWizardMode = currentComponent === PresetConfigComponents.Wizard;

    return {
        handleConfigChange, handleStartGuide, handlePresetNameChange,
        onSavePreset, onCancelWizard,
        isInWizardMode, isPresetNameValid,
        presetName, currentComponent
    };
};