import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKey, LocalizationKeys } from '../../locales/types';
import { DesignMode } from '../../models/design-mode';
import { notificationClicked, zoomAt } from '../../redux/map.state';
import { clearSelection, selectBuild, selectSegment, selectTapSchrodinger, selectTetherSchrodinger } from '../../redux/selection.state';
import { setCurrentHighlighted } from '../../redux/viewport.state';
import { designModeSelector, segmentsSelector, tethersSchrodingerSelector, tapsSchrodingerSelector } from '../../selectors/root.selectors';
import { ItemType } from '../../validation/item-type';
import { ValidationResultType } from '../../validation/validation-result-type';
import { setThisCardState } from '../card/card.hooks';
import { NotificationElementProps } from './notification-element.types';

const getLocalizationKey = (itemType: ItemType): LocalizationKey => {
    if (itemType === ItemType.Bore) return LocalizationKeys.Bore;
    if (itemType === ItemType.Nap) return LocalizationKeys.Nap;
    if (itemType === ItemType.Pole) return LocalizationKeys.Pole;
    if (itemType === ItemType.SchrodingerTap) return LocalizationKeys.Tap;
    if (itemType === ItemType.SchrodingerTether) return LocalizationKeys.Tether;
    if (itemType === ItemType.Segment) return LocalizationKeys.Segment;
    if (itemType === ItemType.Trench) return LocalizationKeys.Trench;
    if (itemType === ItemType.Cabinet) return LocalizationKeys.Cabinet;
    return LocalizationKeys.NotApplicable;
};

export const useNotificationElement = (props: NotificationElementProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { notification } = props;
    const { buildId, buildDisplayId, itemType, itemId, shortDescription, longDescription } = notification;

    const designMode = useSelector(designModeSelector);
    const segments = useSelector(segmentsSelector);
    const tethers = useSelector(tethersSchrodingerSelector);
    const taps = useSelector(tapsSchrodingerSelector);

    const title = [
        buildId && `${t(LocalizationKeys.Build)} ${buildDisplayId ?? buildId}`,
        itemId && `${t(getLocalizationKey(itemType))} ${itemId}`
    ].filter(Boolean).join(" / ");
    const isError = notification.type === ValidationResultType.Error;
    const icon = isError ? require('../ui-elements/icon_error.svg') : require('../ui-elements/icon_warning.svg');
    const alt = isError ? "Error" : "Warning";
    const messageClass = isError ? 'error' : 'warning';
    const segment = segments.find(s => s.id === notification.itemId);
    const tether = tethers.find(t => t.id === notification.itemId);
    const tap = taps.find(t => t.id === tether?.tapId);

    const findTapLocationInSequence = useCallback(() => {
        const segment = segments.find(s => s.toId === tap?.elementId);
        return segment?.position !== undefined ? segment?.position + 1 : 0; // +1 for To position, default 0 and covers first location case
    }, [segments, tap]);

    const handleClick = useCallback((): void => {
        dispatch(notificationClicked(notification));
        dispatch(clearSelection());

        if (notification.itemType === ItemType.Segment && buildId && notification.itemId) {
            setThisCardState("build-info", true);
            setThisCardState("cable-info", true);
            setThisCardState("segment-info", false);
            segment && dispatch(selectSegment(segment.id));
            dispatch(selectBuild(buildId));

            if (designMode === DesignMode.GISMode) {
                dispatch(zoomAt(notification.extent));
            }
            else {
                segment && dispatch(setCurrentHighlighted(segment.position));
            }

        }

        if (notification.itemType === ItemType.SchrodingerTether && notification.itemId && tether && tap) {
            dispatch(selectTapSchrodinger(tap.id));
            dispatch(selectTetherSchrodinger(tether.id));

            if (designMode === DesignMode.GISMode) {
                dispatch(zoomAt(notification.extent));
            }
            else {
                const location = findTapLocationInSequence();
                dispatch(setCurrentHighlighted(location));
            }
        }
    }, [buildId, designMode, dispatch, findTapLocationInSequence, notification, segment, tap, tether]);


    return {
        title, shortDescription, longDescription, icon, alt, messageClass,
        handleClick
    };
}
