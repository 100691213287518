/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { PartHelper } from "../../../helpers/part-helper";
import { LocalizationKeys } from "../../../locales/types";
import { deleteFlexNapCable, updateFlexNapBuild, connectBuildToSplicePoint, disconnectBuildFromSplicePoint } from '../../../redux/build.state';
import { selectBuild, selectPretermNap, selectSegment, selectSplicePoint } from "../../../redux/selection.state";
import { StateModel } from "../../../redux/reducers";
import { applyPreset } from "../../../redux/tap.state";
import { DialogType, showDialog } from "../../../redux/overlay.state";
import { possibleNapsToConvertToPretermSelector } from "../../../selectors/taps.selectors";
import { useTotalLength } from '../build/build-card.utility';
import { NotificationService } from '../../../services/notification.service';
import { possibleSplicePointsToConnectSelector } from '../../../selectors/bulk/splice-points.selectors';
import { Nap } from "../../../models/nap";
import { SplicePoint } from '../../../models/splice-point';
import { CardContextMenuItemProps } from "../../ui-elements/card-context-menu";
import { AccessPointHelper } from "../../../helpers/access-point-helper";
import { FlexNapCableProps } from "./flexnap.types";

enum connectionType {
    NONE = 1,
    PRETERM = 2,
    SPLICE = 3
}

export const useFlexNapCableCard = (props: FlexNapCableProps) => {
    const { t } = useTranslation();
    const workspace: StateModel = useSelector((state: StateModel) => state);
    const possibleNaps: Nap[] = useSelector(possibleNapsToConvertToPretermSelector);
    const possibleSplicePoints: SplicePoint[] = useSelector(possibleSplicePointsToConnectSelector);
    const canConnectOrConvertToPreterm = possibleNaps.length || possibleSplicePoints.length;
    const { presets } = workspace.preset;
    const { flexnapBuilds, bulkBuilds, segments: buildSegments } = workspace.build;
    const { splicePoints } = workspace.splicePoints;
    const { displayUnits } = workspace.authentication;

    const { build, segments, collapsed, disabled } = props;

    const compatibleFiberCounts = useCompatibleFiberCounts(workspace, build.partNumber);

    const cardLabel = t(build.pretermLateral ? LocalizationKeys.PretermLateral : LocalizationKeys.FlexnapCable);
    const partNumberLabel = t(build.pretermLateral ? LocalizationKeys.CablePartNumber : LocalizationKeys.PartNumber);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const childPretermLaterals = flexnapBuilds.filter(b => !!b.pretermLateral && b.pretermLateral.parentBuildId === build.id);
    const buildSplicePlan = workspace.splicePlan.flexNapSplicePlans[build.id];

    const [contextMenuItems, setContextMenuItems] = useState<CardContextMenuItemProps[]>([]);
    const [contextMenuPosition, setContextMenuPosition] = useState({ right: 0, bottom: 0 });
    
    const [connection, setConnection] = useState<connectionType>(connectionType.NONE);

    useEffect(() => {
        if (build.pretermLateral) {
            setConnection(connectionType.PRETERM);
        } else if (build.buildSplice) {
            setConnection(connectionType.SPLICE);
        }
    }, [build.pretermLateral, build.buildSplice]);

    const dispatch = useDispatch();

    const handleFiberCountChange = useCallback((value: any): void => {
        if (buildSplicePlan && buildSplicePlan.startingPortNumber < value){
            NotificationService.warning(t(LocalizationKeys.FiberCountStartingPortWarning));
        }
        updateFlexNapBuild(build, { ...build, fiberCount: value })(dispatch);
    }, [buildSplicePlan, build, dispatch, t]);
    
    const handlePresetChange = useCallback((value: any) => {
        applyPreset(build.id, value)(dispatch);
    }, [build.id, dispatch]);

    const onDeleteCable = useCallback((): void => {
        deleteFlexNapCable(build.id, childPretermLaterals)(dispatch);
    }, [dispatch, build.id, childPretermLaterals]);

    const onDisconnect = useCallback((): void => {
        if (connection === connectionType.PRETERM) {
            dispatch(showDialog(DialogType.RevertPretermLateral));
        } else if (connection === connectionType.SPLICE) {
            disconnectBuildFromSplicePoint(build.id)(dispatch);
        }
    }, [connection, build.id, dispatch]);

    const toggleDeleteDialog = useCallback((): void => {
        setDeleteDialog(!deleteDialog);
    }, [deleteDialog]);

    const onDeleteCableClick = useCallback((): void => {
        if (childPretermLaterals.length) {
            toggleDeleteDialog();
        }
        else {
            onDeleteCable();
        }
    }, [toggleDeleteDialog, onDeleteCable, childPretermLaterals]);

    const showPretermDialog = useCallback((napId: number): void => {
        dispatch(selectPretermNap(napId));
        dispatch(showDialog(DialogType.ConvertToPretermLateral));
        setContextMenuItems([]);
    }, [dispatch]);
    
    const connectToSplicePoint = useCallback((splicePointId: number): void => {
        connectBuildToSplicePoint(build.id, splicePointId)(dispatch);
        setContextMenuItems([]);
        dispatch(selectBuild());
        dispatch(selectSegment());
        dispatch(selectSplicePoint(splicePointId));
    }, [build, dispatch]);

    const onCancelContextMenu = (): void => {
        setContextMenuItems([]);
    };

    const onConnect = useCallback((event: React.MouseEvent): void => {
        if (!possibleNaps.length && !possibleSplicePoints.length) {
            return;
        }
        if (possibleNaps.length === 1 && !possibleSplicePoints.length) {
            showPretermDialog(possibleNaps[0].id);
        } else if (possibleSplicePoints.length === 1 && !possibleNaps.length) {
            connectToSplicePoint(possibleSplicePoints[0].id);
        } else {
            const { innerWidth: pageWidth, innerHeight: pageHeight } = window;
            const { right, bottom } = event.currentTarget.getBoundingClientRect();
            setContextMenuPosition({ right: pageWidth - right, bottom: pageHeight - bottom });
            const napMenuItems = possibleNaps.map(n => AccessPointHelper.createNapContextMenuItemProps(n, flexnapBuilds, showPretermDialog, t));
            const splicePointMenuItems = possibleSplicePoints.map(s => AccessPointHelper.createSplicePointContextMenuItemProps(s, splicePoints, bulkBuilds, buildSegments, connectToSplicePoint, t));
            setContextMenuItems([...napMenuItems, ...splicePointMenuItems]);
        }
    }, [possibleNaps, possibleSplicePoints, flexnapBuilds, bulkBuilds, buildSegments, splicePoints, showPretermDialog, connectToSplicePoint, t]);

    const onButtonConnectClick = useCallback((event: React.MouseEvent): void => {
        if (connection !== connectionType.NONE) {
            onDisconnect();
        } else {
            onConnect(event);
        }
    }, [connection, onConnect, onDisconnect]);

    const labelButtonConnect = t(connection !== connectionType.NONE ? LocalizationKeys.Disconnect : LocalizationKeys.Connect);
    const disabledButtonConnect = disabled || 
                                (!(connection !== connectionType.NONE) && 
                                    !canConnectOrConvertToPreterm);
                                    
    const handleButtonConnect = disabledButtonConnect ? undefined : onButtonConnectClick;

    const totalLength = useTotalLength(workspace, build, segments, connection === connectionType.PRETERM);

    return {
        build, collapsed, presets, compatibleFiberCounts, cardLabel, partNumberLabel, deleteDialog, toggleDeleteDialog, isPreterm: (connection === connectionType.PRETERM), totalLength, displayUnits,
        handleFiberCountChange, handlePresetChange, onDeleteCableClick, onDeleteCable, labelButtonConnect, handleButtonConnect, disabledButtonConnect, disabled,
        contextMenuItems, contextMenuPosition, onCancelContextMenu
    };
};

const useCompatibleFiberCounts = (workspace: StateModel, partNumber: string): number[] => {
    const { cableParts } = workspace.cpq;
    const [fiberCounts, setFiberCounts] = useState<number[]>(PartHelper.getCableCompatibleFiberCounts(cableParts, partNumber).sort((a, b) => a - b));

    useEffect(() => {
        setFiberCounts(PartHelper.getCableCompatibleFiberCounts(cableParts, partNumber).sort((a, b) => a - b));
    }, [cableParts, partNumber]);

    return fiberCounts;
}
