import * as rfc from 'rfc6902';

import { PointLike } from '../models/pointlike';
import { Vault, VaultData } from '../models/vault';
import { SecuredService } from './abstract-secured-v2.service';

export class VaultService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/vault';

    public getAll(): Promise<Vault[] | undefined> {
        return this.get<Vault[]>(this.baseUrl);
    }

    public getVaults(bbox: number[], required?: number[]): Promise<Vault[] | undefined> {
        return this.post<Vault[]>(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public getVaultsByIds(ids: number[]): Promise<Vault[] | undefined> {
        return this.post<Vault[]>(`${this.baseUrl}/byids`, ids);
    }

    public addVault(point: PointLike): Promise<Vault | undefined> {
        return this.post(this.baseUrl, point);
    }

    public addVaults(data: VaultData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/import`, { data, importedGisDataId });
    }

    public patchVault(oldVault: Vault, newVault: Vault): Promise<Vault | undefined> {
        const patch = rfc.createPatch(oldVault, newVault);
        return this.patch(`${this.baseUrl}/${oldVault.id}`, patch);
    }
}
