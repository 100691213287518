import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FeatureFlags } from '../models/feature-flags';
import { FeatureFlagsService } from '../services/feature-flags.service';

export interface FeatureFlagState {
    featureFlags: FeatureFlags;
}

const initialState: FeatureFlagState = {
    featureFlags: {
        fmeImportFlexNap: false,
        showDebugMenu: false,
    },
};

function updateFeatureFlagsReducer(state: FeatureFlagState, action: PayloadAction<FeatureFlags>) {
    state.featureFlags = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        updateFeatureFlags: updateFeatureFlagsReducer,
    }
});

export { reducer as FeatureFlagsReducer };
export const { updateFeatureFlags } = actions;

export const loadFeatureFlags = () => async (dispatch) => {
    const service = new FeatureFlagsService();
    const res = await service.getFeatureFlags();
    if (res) {
        dispatch(actions.updateFeatureFlags(res));
    }
};
