import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainedButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { DrawerMenu } from '../../drawers/menu/drawer-menu';
import { ToolItem } from '../../ui-elements/tool-item';
import { HistoryTool } from '../history-tool/history-tool';
import { useMainMenu } from './main-menu.hooks';

export const MainMenu = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        menuTitle,
        showUploadBuild,
        uploadBuildDisabled,
        catalogCode,
        handleMenuButtonClicked,
        handleUploadBuildClick,
    } = useMainMenu();

    return (
        <div className='main-menu-container'>
            <div className='menu-history-container'>
                <div className="controls-container map-menu-container">
                    <ToolItem source={ <img src={require('../icons/icon_menu.svg')} alt={t(LocalizationKeys.Menu)?.toLowerCase()} /> } onClick={handleMenuButtonClicked} tooltip={t(LocalizationKeys.Menu)?.toLowerCase()} />
                    
                    <img className="app-icon" alt="Corning" src={require('./corning_logo_blue.png')} />
                    <label className="app-name">{t(LocalizationKeys.DesignTool)}</label>
                    {!!menuTitle && (
                        <>
                            <div className="menu-divider"></div>
                            <label className="title">{menuTitle}</label>
                        </>
                    )}
                    {showUploadBuild && (
                        <div className="upload-build-container">
                            <ContainedButton disabled={uploadBuildDisabled} onClick={handleUploadBuildClick}>
                                {catalogCode ?? t(LocalizationKeys.UploadBuild)}
                            </ContainedButton>
                        </div>
                    )}
                </div>
                <HistoryTool />
            </div>
            <DrawerMenu />
        </div>
    );
}
