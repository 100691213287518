import React from 'react';

import { Graphics, Sprite, Text } from '@inlet/react-pixi';

import { CanvasTextStyles } from '../build.styles';
import { IIndexedFrameProps } from '../build.types';
import opticalTether from './assets/optical-tether.svg';
import powerTether from './assets/power-tether.svg';
import { useTap } from './tap.hooks';
import { TapProps } from './tap.types';

export const Tap: React.FC<IIndexedFrameProps & TapProps> = (props) => {
    const {
        image,
        x,
        y,
        anchor,
        zIndex,
        tapCount,
        hasOpticalTether,
        hasPowerTether,
        highlightImage,
        highlightAlpha,
        highlightScale,
        text,
        drawBackgroundForText,
        handleClick
    } = useTap(props);

    return (
        <>
            <Sprite
                alpha={highlightAlpha}
                image={highlightImage}
                scale={highlightScale}
                x={x}
                y={y}
                anchor={anchor}
                zIndex={zIndex - 3}
                interactive={true}
            />
            <Graphics draw={drawBackgroundForText} zIndex={zIndex + 3} />
            <Text
                text={text}
                x={x}
                y={y + 40}
                anchor={{ x: 0.5, y: 0.5 }}
                roundPixels={true}
                style={CanvasTextStyles.locationDefaultText}
                zIndex={zIndex}
            />
            <Sprite
                image={image}
                scale={0.26}
                x={x}
                y={y}
                anchor={anchor}
                zIndex={zIndex}
                interactive={true}
                cursor={'pointer'}
                click={handleClick}
            />
            <Text
                text={tapCount}
                x={x}
                y={y}
                anchor={{ x: 0.5, y: 0.5 }}
                roundPixels={true}
                style={CanvasTextStyles.smallRegularWhite}
                zIndex={zIndex}
            />
            {hasOpticalTether && (
                <Sprite
                    image={opticalTether}
                    scale={0.5}
                    x={x - 37}
                    y={y - 22}
                    anchor={{ x: 0.5, y: 0.5 }}
                    zIndex={zIndex}
                />
            )}
            {hasPowerTether && (
                <Sprite
                    image={powerTether}
                    scale={0.5}
                    x={x + 37}
                    y={y - 22}
                    anchor={{ x: 0.5, y: 0.5 }}
                    zIndex={zIndex}
                />
            )}
        </>
    );
};
