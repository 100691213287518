import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { deleteSchrodingerBuilds } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { selectedSchrodingerBuildSelector } from '../../../selectors/build.selectors';
import {
    dialogSelector, tapsSchrodingerSelector, workspaceSelector
} from '../../../selectors/root.selectors';
import { DeleteBuildDialogProps } from './delete-build-dialog.types';

export const useDeleteSchrodingerBuildDialog = (): DeleteBuildDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dialog = useSelector(dialogSelector);
    const build = useSelector(selectedSchrodingerBuildSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const taps = useSelector(tapsSchrodingerSelector);
    const workspaceId = currentWorkspace?.id;
    const buildId = build?.id ?? -1;
    const isOpen = dialog === DialogType.DeleteSchrodingerBuild && buildId > 0;
    const title = t(LocalizationKeys.DeleteSchrodingerBuild, { buildId: buildId });

    const onClose = useCallback(() => {
        dispatch(showDialog()); // closes the dialog; empty PayloadAction value
    }, [dispatch]);

    const onDeleteBuild = useCallback(() => {
        const tapIds = taps.filter((t) => t.buildId === buildId).map(({ id }) => id);
        deleteSchrodingerBuilds([buildId], tapIds, workspaceId)(dispatch);
        dispatch(showDialog()); // closes the dialog; empty PayloadAction value
    }, [buildId, dispatch, taps, workspaceId]);

    return {
        isOpen,
        onClose,
        onDeleteBuild,
        title,
    };
}