import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ValidationResult } from '../validation/validation-result';

export interface NotificationState {
    notifications: ValidationResult[];
}

const initialState: NotificationState = { notifications: [] };

function clearNotificationsReducer(state: NotificationState, _: PayloadAction<undefined>) {
    state.notifications = [];
}

function addNotificationReducer(state: NotificationState, action: PayloadAction<ValidationResult>) {
    state.notifications.push(action.payload);
}

function addNotificationsReducer(state: NotificationState, action: PayloadAction<ValidationResult[]>) {
    state.notifications.push(...action.payload);
}

const { actions, reducer } = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        clearNotifications: clearNotificationsReducer,
        addNotification: addNotificationReducer,
        addNotifications: addNotificationsReducer,
    },
});

export { reducer as NotificationReducer };

export const { clearNotifications, addNotification, addNotifications } = actions;
