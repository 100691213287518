import { TextStyle } from 'pixi.js';

export class CanvasTextStyles {
    public static smallSemiLightWhite = new TextStyle({
        fill: 'rgba(255, 255, 255, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 10,
        fontWeight: '300',
        letterSpacing: 0.15,
        lineHeight: 24,
        dropShadow: false,
        padding: 8
    });

    public static mediumSemiLight = new TextStyle({
        fill: 'rgba(78, 78, 80, 0.9)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 24,
        fontWeight: '300',
        letterSpacing: 0.15,
        lineHeight: 24,
        dropShadow: false,
    });

    public static mediumSemiLightGray = new TextStyle({
        fill: 'rgba(176, 176, 176, 1)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 24,
        fontWeight: '300',
        letterSpacing: 0.15,
        lineHeight: 24,
        dropShadow: false,
    });

    public static smallSemiBold = new TextStyle({
        fill: 'rgba(78, 78, 80, 0.9)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 20,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 20
    });

    public static smallSemiBoldBlue = new TextStyle({
        fill: 'rgba(0, 82, 147, 1)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 20,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 20
    });

    public static tinyQuarterBold = new TextStyle({
        fill: 'rgba(78, 78, 80, 0.9)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 19
    });

    public static tinyQuarterBoldBlue = new TextStyle({
        fill: 'rgba(0, 82, 147, 1)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 19
    })

    public static mediumSemiBold = new TextStyle({
        fill: 'rgba(78, 78, 80, 0.9)',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 24,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24
    });


    public static mediumSemiBoldGrey = new TextStyle({
        fill: 'rgba(66, 66, 66, 1',
        fontFamily: 'TheSansC4s, sans-serif',
        fontSize: 24,
        fontWeight: '600',
        letterSpacing: 0.15,
        lineHeight: 24
    });

    public static largeRegularWhite = new TextStyle({
        fill: 'rgba(255, 255, 255, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 60,
        fontWeight: '400',
        letterSpacing: -0.5,
        lineHeight: 72
    });

    public static mediumRegularWhite = new TextStyle({
        fill: 'rgba(255, 255, 255, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 34,
        fontWeight: '400',
        letterSpacing: 0.25,
        lineHeight: 41.99
    });

    public static smallRegularWhite = new TextStyle({
        fill: 'rgba(255, 255, 255, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 20,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 20
    });

    public static locationDefaultText = new TextStyle({
        fill: 'rgba(66, 66, 66, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 20,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 20
    });

    public static locationHoverText = new TextStyle({
        fill: 'rgba(0, 82, 147, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 20,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 20
    });

    public static locationDisabledText = new TextStyle({
        fill: 'rgba(176, 176, 176, 1)',
        fontFamily: '"The Sans C4s", sans-serif',
        fontSize: 20,
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 20
    });
}