import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../models/design-mode';
import { loadBuilds } from '../redux/build.state';
import {
    loadAll as loadSplicePoints, removeAll as removeSplicePoints
} from '../redux/bulk/splice-point.state';
import {
    loadCabinetSplicePlans, removeCabinetSplicePlans
} from '../redux/cabinet-splice-plan.state';
import CorningRoles from '../redux/corning-roles.json';
import {
    loadCableParts, loadCountryStates, loadInstallationTypes, loadMultiportParts, loadTetherParts
} from '../redux/cpq.state';
import { loadDesignAreas } from '../redux/design-area.state';
import { loadFeatureFlags } from '../redux/feature-flags.state';
import { loadPresets } from '../redux/preset.state';
import { StateModel } from '../redux/reducers';
import { getReviewsInUserGroup } from '../redux/review.state';
import { loadRoles } from '../redux/roles.state';
import { loadPresets as loadSchrodingerPresets } from '../redux/schrodinger-preset.state';
import { loadAll as loadTapsTethersSchrodinger } from '../redux/schrodinger/tap.state';
import { loadFlexNapSplicePlans, removeAllSplicePlans } from '../redux/splice-plan.state';
import {
    loadAll as loadNapsTapsTethers, removeAll as removeAllNapsTapsTethers
} from '../redux/tap.state';
import { loadBuildType } from '../redux/tool.state';
import { loadWorkspaces } from '../redux/workspace.state';

export const useMainPage = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: StateModel) => state);
    const { customer } = state.authentication;
    const { currentWorkspace, workspaces } = state.workspace;
    const { cableParts, installationTypes, tetherParts } = state.cpq;
    const soldTo = customer?.account?.soldTo;
    const { buildType } = state.tool;
    const designMode = state.workspace.recentDesignMode;
    const { roles } = state.role;
    const hasSchrodingerRole = roles?.some((role) => role === CorningRoles.Schrodinger) ?? false;
    const hasRolesLoaded = !!roles && roles.length > 0;
    const currentWorkspaceId = currentWorkspace?.id;

    useEffect(() => {
        loadRoles()(dispatch);
        loadFeatureFlags()(dispatch);
        loadWorkspaces()(dispatch);
        loadPresets()(dispatch);
        loadSchrodingerPresets()(dispatch);
        loadInstallationTypes()(dispatch);
        loadMultiportParts()(dispatch);
        getReviewsInUserGroup()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        loadBuildType(roles)(dispatch);
    }, [dispatch, roles]);

    useEffect(() => {
        loadCountryStates(buildType)(dispatch); // reload country states because they are different for Schrodinger
    }, [buildType, dispatch]);

    useEffect(() => {
        if (currentWorkspaceId && designMode) {
            loadDesignAreas(currentWorkspaceId, designMode)(dispatch);
            loadBuilds(currentWorkspaceId, designMode)(dispatch);

            if (designMode === DesignMode.GISMode) {
                // FlexNAP-specific actions
                loadNapsTapsTethers(currentWorkspaceId)(dispatch);
                loadFlexNapSplicePlans(currentWorkspaceId)(dispatch);

                loadCabinetSplicePlans(currentWorkspaceId)(dispatch);
                loadSplicePoints(currentWorkspaceId)(dispatch);
            } else {
                dispatch(removeAllNapsTapsTethers());
                dispatch(removeAllSplicePlans());

                dispatch(removeCabinetSplicePlans());
                dispatch(removeSplicePoints());
            }

            // Schrodinger-specific actions
            loadTapsTethersSchrodinger(currentWorkspaceId, designMode)(dispatch);
        }
    }, [currentWorkspaceId, designMode, dispatch]);

    useEffect(() => {
        loadCableParts(soldTo)(dispatch);
        loadTetherParts(soldTo)(dispatch);
    }, [soldTo, dispatch]);


    return { currentWorkspace, workspaces, cableParts, tetherParts, installationTypes, soldTo, buildType, designMode, hasRolesLoaded, hasSchrodingerRole };
}
