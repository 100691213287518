import LineString from 'ol/geom/LineString';
import { getLength } from 'ol/sphere';

import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { DataValue } from '../models/datavalue';
import { Element } from '../models/element';
import { Path } from '../models/path';
import { PointLike } from '../models/pointlike';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { TerminalSegment } from '../models/terminal-segment';
import { Units } from '../models/units';
import { PathHelper } from './path-helper';

export interface SegmentSpan {
    calculated?: DataValue;
    measured?: DataValue;
}
export const FIXED_SLACK_LOCATION_SPAN_FEET = 30;

export class CableCalculationsHelper {
    public static getSegmentSpan(segment: BuildSegment, elements: Element[], paths: Path[]): SegmentSpan {
        return { measured: this.measureSegmentSpan(segment, paths), calculated: this.calculateSegmentSpan(segment, elements, paths) };
    }

    public static measureSegmentSpan(segment: BuildSegment, paths: Path[]): DataValue | undefined {
        const { fromId, toId, measuredSpan, measuredSpanUnit } = segment;
        const path = (fromId && toId) ? PathHelper.findConnectedPath(paths, fromId, toId) : undefined;
        if (path) {
            return (path.measuredLength > 0) ? new DataValue(path.measuredLength, path.lengthUnit) : undefined;
        }
        return (measuredSpan && measuredSpanUnit) ? new DataValue(measuredSpan, measuredSpanUnit) : undefined;
    }

    public static calculateSegmentSpan(segment: BuildSegment | TerminalSegment, elements: Element[], paths: Path[]): DataValue | undefined {
        const { fromId, toId } = segment;
        const path = (fromId && toId) ? PathHelper.findConnectedPath(paths, fromId, toId) : undefined;
        if (path) {
            return PathHelper.calculatePathLength(path.route);
        }
        return this.calculateSegmentLength(segment, elements);
    }

    private static calculateSegmentLength(segment: BuildSegment | TerminalSegment, elements: Element[]): DataValue | undefined {
        const from = elements.find((p) => p.id === segment.fromId);
        const to = elements.find((p) => p.id === segment.toId);
        if (!from || !to) {
            console.warn(`Unable to get segment length from element '${segment.fromId}' to element '${segment.toId}'.`);
            return undefined;
        }
        return this.getDistanceBetween(from, to);
    }

    public static getDistanceBetween(a: PointLike, b: PointLike, unit: Units = Units.meters, digits?: number): DataValue {
        const metricLength = getLength(new LineString([[a.x, a.y], [b.x, b.y]]));
        const metricDataValue = new DataValue(metricLength, Units.meters, digits);
        return unit === Units.meters ? metricDataValue : metricDataValue.toUnit(unit, digits);
    }

    public static calculateTotalCableLength(units: Units, build: Build, buildSegments: BuildSegment[], elements: Element[], paths: Path[], isPreterm?: boolean): DataValue {
        const schrodingerBuild = (build as SchrodingerBuild);
        if (schrodingerBuild.cableLength) {
            return new DataValue(schrodingerBuild.cableLength, schrodingerBuild.cableLengthUnit);
        }
        const totalLength = new DataValue(0, units);
        buildSegments.forEach((s) => {
            totalLength.value += this.calculateSegmentLengthWithSlack(s, units, elements, paths);
        });
        const coSlack = new DataValue(build.coSlack, build.slackUnit);
        const fieldSlack = new DataValue(build.fieldSlack, build.slackUnit);
        if (isPreterm) {
            totalLength.value += new DataValue(FIXED_SLACK_LOCATION_SPAN_FEET, Units.feet).toUnit(units).value;
        }
        totalLength.value += coSlack.toUnit(units).value + fieldSlack.toUnit(units).value;
        return totalLength;
    }

    private static calculateSegmentLengthWithSlack(segment: BuildSegment, units: Units, elements: Element[], paths: Path[]): number {
        const slackLoop = new DataValue(segment.slackLoop, segment.slackUnit);
        const designSpan = new DataValue(segment.designSpan, segment.designSpanUnit);
        if (designSpan.value !== 0) {
            return designSpan.toUnit(units).value + slackLoop.toUnit(units).value;
        }
        const { calculated, measured } = this.getSegmentSpan(segment, elements, paths);
        const segmentLength = measured ? measured : (calculated ? calculated : new DataValue(0, units));
        return segmentLength.toUnit(units).value + slackLoop.toUnit(units).value;
    }
}
