import { PointLike } from '../../models/pointlike';
import { Data } from './data';

export class SplicePointData extends Data {
    public buildId: number | null;
    public elementId: number;
    public point: PointLike;

    constructor(id: number, buildId: number | null, elementId: number, point: PointLike) {
        super(id);
        this.buildId = buildId;
        this.elementId = elementId;
        this.point = point;
    }

    public getExtent(): number[] {
        const { x, y } = this.point;
        return [x, y, x, y];
    }
}