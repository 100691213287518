import './design-area-row.scss';

import React from 'react';

import { Checkbox, Divider } from '@mui/material';

import { BuildRow } from '../build/build-row';
import { DesignAreaIcon } from './design-area-icon';
import { useDesignAreaRow } from './design-area-row.hooks';
import { DesignAreaRowProps } from './design-area-row.types';

export const DesignAreaRow = (props: DesignAreaRowProps) => {
    const {
        id,
        location,
        displayName,
        rowClassName,
        showBuilds,
        builds,
        showDelete,
        showSelect,
        rowSelected,
        expandDisabled,
        expandIcon,
        showEdit,
        handleRowEnter,
        handleRowLeave,
        handleDeleteClick,
        handleSelectionChanged,
        handleExpandClick,
        handleEditClick
    } = useDesignAreaRow(props);

    return (
        <div className="design-area-row-container">
            <div className={rowClassName} onMouseEnter={handleRowEnter} onMouseLeave={handleRowLeave}>
                <DesignAreaIcon designAreaId={id} />
                <div className="design-area-row-end">
                    <div className="design-area-info-container">
                        <div className="design-area-name">{displayName}</div>
                        <div className="design-area-location">{location}</div>
                    </div>
                    <div className="design-area-toolbar">
                        {showEdit && (
                            <button className="icon-button" onClick={handleEditClick}>
                                <i className="material-icons">edit</i>
                            </button>
                        )}
                        {showDelete && (
                            <button className="icon-button" onClick={handleDeleteClick}>
                                <i className="material-icons">delete</i>
                            </button>
                        )}
                        {showSelect && <Checkbox checked={rowSelected} onChange={handleSelectionChanged} />}
                        <button className="icon-button" disabled={expandDisabled} onClick={handleExpandClick}>
                            <i className="material-icons">{expandIcon}</i>
                        </button>
                    </div>
                </div>
            </div>
            {showBuilds && (
                <div className="design-area-builds-container">
                    {builds.map((b) => (
                        <div key={b.id}>
                            <Divider variant="middle" />
                            <BuildRow build={b} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
