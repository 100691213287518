import React from 'react';

export const VaultIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <rect x="1.5" y="1.5" width="21" height="21" fill="none" stroke="#005293" strokeWidth="3" />
            <circle cx="9" cy="7" r="2" fill="#005293" />
            <circle cx="9" cy="17" r="2" fill="#005293" />
            <circle cx="15" cy="7" r="2" fill="#005293" />
            <circle cx="15" cy="17" r="2" fill="#005293" />
        </svg>
    );
};
