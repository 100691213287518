import { SelectChangeEvent } from '@mui/material';

export interface CardSelectProps {
    label: string;
    name: string;
    value: any;
    values: any[];
    displayValues?: string[];
    disabled?: boolean;
    onChange: (event: SelectChangeEvent<any>) => void;
}

export interface CardInputProps {
    label: string;
    name: string;
    value: any;
    type?: string;
    min?: number;
    maxLength?: number;
    step?: string;
    disabled?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (value: any) => void;
    error?: boolean;
    helperText?: string;
    trailingIcon?: any;
    units?: string;
}

export interface CardErrorMessageProps {
    label: string;
}

export interface CardComponentProps {
    id: string;
    className?: string;
    hideToggleCollapse?: boolean;
    collapseTooltip?: string;
    expandTooltip?: string;
    children?: React.ReactNode;
    onCloseClick: () => void;
    onToggleCollapse?: (collapsed: boolean) => void;
}

export interface CardStateType {
    [index: string]: boolean;
}

export const CardInputMaxLength = {
    PRESET_NAME: 20,
    CABLE_NAME: 20,
    BUILD_STREET_NAME: 30,
    BUILD_DESCRIPTION: 100,
    BUILD_COMMENT: 255,
};