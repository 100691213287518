import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useResetTerminalExtensionDialog } from './reset-terminal-extension-dialog.hooks';

export const ResetTerminalExtensionDialog = (): JSX.Element => {
    const { t } = useTranslation();
    
    const { open, typeName, onCancel, onReset } = useResetTerminalExtensionDialog();

    return (
        <DialogComponent
            open={open}
            title={t(LocalizationKeys.ResetTitle, { type: typeName })}
            message={t(LocalizationKeys.ResetTerminalExtension, { type: typeName })}
            positiveTitle={t(LocalizationKeys.Reset)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onClose={onCancel}
            onNegativeClick={onCancel}
            onPositiveClick={onReset}
        />
    );
};