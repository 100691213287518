import { SelectChangeEvent } from '@mui/material';

import { BuildType } from '../../../models/build-type';
import { UserPreset } from '../../../models/userpreset';
import { BuildToImportRow } from '../../../services/import.service';

export interface UploadDialogContentProps {
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    setDisableNext: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ImportDialogContentProps {
    files: File[];
    stepCount: number;
    leftButtonClickCount: number;
    onClose: () => void;
    setIsLoadingStep: React.Dispatch<React.SetStateAction<boolean>>;
    setDisableNext: React.Dispatch<React.SetStateAction<boolean>>;
    setStepCount: React.Dispatch<React.SetStateAction<number>>;
    setLeftButtonTitle: React.Dispatch<React.SetStateAction<string>>;
    setPositiveButtonTitle: React.Dispatch<React.SetStateAction<string>>;
}

export interface ImportInfrastructureProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>;
    setLogOutput: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface SelectedPresetIds {
    [buildId: string]: string;
}
export interface SelectedParentIds {
    [buildId: string]: string;
}

export interface ImportStepSelectPresetsProps {
    buildsToImportRows: BuildToImportRow[] | undefined;
    presets: UserPreset[];
    setSelectedBuildsResult: React.Dispatch<React.SetStateAction<{ buildId: string; isSelected: boolean; presetId: string; parentCustomerBuildId: string; }[]>>;
    setDisableNext: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ImportStepSelectPresetsHeaderProps {
    tab: ImportTabs;
    selectedBuildIds: string[];
    parsedBuildsToImport: BuildToImportRow[] | undefined;
    presets: ImportPresetOption[];
    onSelectAllChange: React.Dispatch<React.ChangeEvent<HTMLInputElement>>;
    onApplyPresetToAll: (presetIdentifier: string) => void;
}

export interface ImportStepSelectPresetsRowProps {
    parsedBuildToImport: BuildToImportRow;
    selected?: boolean;
    presets: ImportPresetOption[];
    selectedPresetIds: SelectedPresetIds;
    selectedParentBuildIds: SelectedParentIds;
    onBuildSelectionChange: React.Dispatch<React.ChangeEvent<HTMLInputElement>>;
    onSelectedPresetChange: React.Dispatch<SelectChangeEvent<any>>;
    onSelectedParentBuildIdChange: React.Dispatch<SelectChangeEvent<any>>;
}

export interface ImportBuildStatus {
    buildId: string;
    importStatus: ImportStatus;
    importResult: string[];
}

export interface BuildTypes {
    [buildId: string]: BuildType
}
export interface ImportStepBuildsProgressProps {
    importStatuses: ImportBuildStatus[];
    buildTypes: BuildTypes;
}

export interface ImportErrorLogProps {
    errorMessages: string[];
}

export enum ShapefileStepsEnum {
    Upload = 0,
    Confirmation = 1,
    Log = 2
}

export type ImportPresetOption = {
    value: number;
    display: string;
}

export const shapefileStepsTitles = [
    'Upload',
    'Confirmation',
    'Log'
]

export const MAX_INFRASTRUCTURE_ELEMENTS = 60000;

export enum ImportStatus {
    Start,
    Complete,
    Error,
}

export enum GeoJsonStepsEnum {
    Upload = 0,
    Infrastructure = 1,
    DesignAreas = 2,
    SelectPresets = 3,
    Save = 4,
    Error = 6,
}

export const geoJsonStepTitles = [
    'Upload',
    'Infrastructure',
    'DesignAreas',
    'Presets',
    'Save',
]

export enum CustomerFormatStepsEnum {
    Upload = 0,
    Transformation = 1,
    Confirmation = 2,
    Log = 3
}

export enum ImportTabs {
    Flexnap = 0,
    Bulk = 1,
}

export const customerFormatStepsTitles = [
    'Upload',
    'Transformation',
    'Confirmation',
    'Log'
]

// Data Schema Format + What kind of features are being imported + File format
export enum ImportWorkflow {
    CorningFormatInfrastructureShapefile,
    CorningFormatInfrastructureAndDesignGeoJson,
    CustomerFormatInfrastructureAny
}
