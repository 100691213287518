import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { InlineSelect } from '../../ui-elements/inline-select';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';
import { FlexNapTetherCardProps } from './flexnap-tether-card.types';
import { useFlexNapTetherExtenderCard } from './flexnap-tether-extender-card.hooks';

export const FlexNapTetherExtenderCard = (props: FlexNapTetherCardProps): JSX.Element => {
    const { t } = useTranslation();

    const { tetherIcon, tether, disabled, collapsed, calculatedSpan, measuredSpan, availableCpqLengths, displayUnits, toggleCollapse, handleLengthChange } = useFlexNapTetherExtenderCard(props);
    const tetherExtender = tether.terminal?.terminalExtension;

    const collapseBtn =
        <button className="icon-button" onClick={toggleCollapse}>
            {
                collapsed ?
                    <img alt="Reveal card" src={require('./../../card/icon_open_chevron.svg')} /> :
                    <img alt="Collapse card" src={require('./../../card/icon_close_chevron.svg')} />
            }
        </button>;
    const cs = classNames({ collapsed: !!collapsed });

    return (
        <div className="nap-diagram-children">
            <div className="card-separator"></div>
            <div className="header-with-button">
                <CardHeaderComponent title={t(LocalizationKeys.TetherExtender)} icon={tetherIcon} iconBorder={true} />
                {collapseBtn}
            </div>
            <CardContentComponent className={cs}>
                <InlineSelect
                    key="length"
                    label={t(LocalizationKeys.Length)}
                    value={tetherExtender?.length ?? 0}
                    values={availableCpqLengths}
                    units={tetherExtender ? UnitInterpreter.toShort(tetherExtender.lengthUnit) : undefined}
                    onUpdate={handleLengthChange}
                    disabled={disabled}
                />
                {calculatedSpan ?
                    CardPropertyInline(t(LocalizationKeys.CalculatedSpan), calculatedSpan.toFixed(2), UnitInterpreter.toShort(displayUnits))
                    :
                    CardPropertyInline(t(LocalizationKeys.CalculatedSpan), "", t(LocalizationKeys.NotApplicable))
                }
                {measuredSpan ?
                    CardPropertyInline(t(LocalizationKeys.MeasuredSpan), measuredSpan?.toFixed(2), UnitInterpreter.toShort(displayUnits))
                    :
                    CardPropertyInline(t(LocalizationKeys.MeasuredSpan), "", t(LocalizationKeys.NotApplicable))
                }
            </CardContentComponent>
        </div>
    );
};
