import i18next from 'i18next';
import { ModifyEvent } from 'ol/interaction/Modify';

import { store } from '../dna';
import i18n from '../locales/i18n';
import { LocalizationKeys } from '../locales/types';
import { DesignArea } from '../models/design-area';
import { MapItemDefinition } from '../models/map-item-definition';
import { ModifiableNode } from '../models/modifiable-node';
import { PointLike } from '../models/pointlike';
import { MouseEventButton } from '../openlayers/event.types';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import {
    addNewDesignArea, deleteDesignArea, updateDesignAreaCoordinates
} from '../redux/design-area.state';
import { updateSelectedVertex } from '../redux/selection.state';
import { Modifiable } from './modifiable';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class DesignAreaTool implements Modifiable {
    /** The range limit of the perimeter around the point to decide if a point was selected */
    private PERIMETER_RANGE_LIMIT_PIXEL = 3;
    /** Minimum amount of pixels required to drag a segment of the Design Area (approximate) */
    private SEGMENT_DRAG_MINIMUM_PIXEL = 8;
    /** The minimum number of points in the polygon (with is +1 of the number ) */
    private MIN_POLYGON_LENGTH = 4;

    public static definition: ToolDefinition = {
        toolType: ToolType.DesignArea,
        name: 'Design Area',
        shortcut: 'd',
    };

    public static createNewDesignArea(polygon: PointLike[]): void {
        const { workspace, designarea } = store.getState();
        const workspaceId = workspace.currentWorkspace?.id;
        if (!workspaceId) {
            throw new Error('Can\'t create a design area without a parent workspace');
        }
        const { designAreas } = designarea;
        const name = i18n.t(LocalizationKeys.DefaultDesignArea, { index: designAreas.length + 1 });
        addNewDesignArea({ workspaceId, name, polygon })(store.dispatch);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-function-return-type
    public modifyStart() { }

    public modifyEnd(event: ModifyEvent): void {
        const { originalEvent } = event.mapBrowserEvent;
        if ((originalEvent as PointerEvent).button !== MouseEventButton.MAIN) {
            return;
        }

        // Ensure we dragged a segment of the design area far enough to require a new polygon node
        if (Math.abs(event.target.lastPixel_[0] - event.target.lastPointerEvent_.pixel_[0]) <= this.SEGMENT_DRAG_MINIMUM_PIXEL
            && Math.abs(event.target.lastPixel_[1] - event.target.lastPointerEvent_.pixel_[1]) <= this.SEGMENT_DRAG_MINIMUM_PIXEL) {
            return;
        }

        const { id, geometry } = event.features.getArray()?.[0].getProperties();
        const { designAreas } = store.getState().designarea;
        const designArea = designAreas?.find(({ id: designAreaId }) => id === designAreaId);
        if (!designArea) {
            return;
        }

        const coordinates: [number, number][] = geometry.getCoordinates().flat();
        const polygon = coordinates.map(([x, y]) => ({ x, y }));
        const newDesignArea: DesignArea = { ...designArea, polygon };

        updateDesignAreaCoordinates(designArea, newDesignArea)(store.dispatch);
    }

    public deleteNode(node): boolean {
        const state = store.getState();
        const { designAreas } = state.designarea;
        const { selectedVertex } = state.selection;
        const designArea = designAreas?.find(({ id: designAreaId }) => node.id === designAreaId);

        if (!designArea || !designArea.polygon) {
            return false;
        }

        if (!selectedVertex) {
            deleteDesignArea(designArea.id)(store.dispatch);
            return true;
        }

        const polygon = designArea.polygon
            .filter(d => Math.floor(Math.abs(d.x - selectedVertex?.x)) > this.PERIMETER_RANGE_LIMIT_PIXEL ||
                Math.floor(Math.abs(d.y - selectedVertex?.y)) > this.PERIMETER_RANGE_LIMIT_PIXEL)
        // The polygon PointLike[] definition requires the first point to also be duplicated as the last point in the array.
        // If the point that was deleted was the start point, it means two points were removed from the polygon array.
        if (designArea.polygon.length - polygon.length === 2) {
            // We need re-add the start point at the end of the polygon array. 
            polygon.push(polygon[0]);
        }

        const newDesignArea: DesignArea = { ...designArea, polygon };
        updateDesignAreaCoordinates(designArea, newDesignArea)(store.dispatch);

        store.dispatch(updateSelectedVertex()); // remove selected vertex from state after we deleted the node
        return true;
    }

    public getModifiableNode(features: MapItemDefinition[], point?: PointLike): ModifiableNode | undefined {
        const { designAreas } = store.getState().designarea;
        const { selectedDesignAreaId } = store.getState().selection;

        const designAreaMapItem = features.find(f => f.type === InteractableFeatureType.DESIGN_AREA && f.id === selectedDesignAreaId)
        const designArea = designAreas?.find(d => d.id === selectedDesignAreaId);

        if (!point) {
            return undefined;
        }

        if (designArea && point && designAreaMapItem && !!designArea.polygon) {
            const nodeOnDesignAreaPerimeter = designArea.polygon
                .find(d => Math.floor(Math.abs(d.x - point.x)) <= this.PERIMETER_RANGE_LIMIT_PIXEL
                    && Math.floor(Math.abs(d.y - point.y)) <= this.PERIMETER_RANGE_LIMIT_PIXEL);
            const canDelete = designArea.polygon.length > this.MIN_POLYGON_LENGTH;
            if (nodeOnDesignAreaPerimeter) {
                store.dispatch(updateSelectedVertex(point));
                return { mapItem: designAreaMapItem, canDelete: canDelete };
            } else {
                return { mapItem: designAreaMapItem, canDelete: true, contextMenuTitle: i18next.t(LocalizationKeys.DesignArea) + ' ' + designArea.id };
            }
        }

        return undefined;
    }
}
