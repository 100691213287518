import { useState, useEffect } from 'react';
import { TapDiagramProps } from '../../nap-diagram.types';

export const useTapDiagramSvg = (props: TapDiagramProps) => {
    const { index, tap } = props;

    const [className, setClassName] = useState('tap');
    const [x, setX] = useState(23 + (index === 1 ? 0 : 174));

    useEffect(() => {
        const connected = !!tap;
        setClassName('tap' + (connected ? ' connected' : ' disconnected'));
    }, [tap]);

    useEffect(() => {
        setX(23 + (index === 1 ? 0 : 174));
    }, [index]);

    return { tapIndex: index, className, x };
};