import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/types';

import { StateModel } from '../../../redux/reducers';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { resetTerminalExtension } from '../../../redux/tap.state';
import { TerminalExtensionType } from '../../../models/terminal-extension-type';

export const useResetTerminalExtensionDialog = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const workspace = useSelector((state: StateModel) => state);
    const { overlay, taps, selection} = workspace;
    const { dialog } = overlay;
    const { tethers } = taps;
    const { selectedTerminalId } = selection;
    const selectedTerminal = tethers?.find(t => t.terminal?.id === selectedTerminalId)?.terminal;

    const [open, setOpen] = useState(false);
    const [typeName, setTypeName] = useState("");

    useEffect(() => {
        setOpen(dialog === DialogType.ResetTerminalExtension);
    }, [dialog]);

    useEffect(() => {
        if (selectedTerminal?.terminalExtension) {
            setTypeName(selectedTerminal.terminalExtension.terminalExtensionType === TerminalExtensionType.LongTail ? t(LocalizationKeys.LongtailTerminal) : t(LocalizationKeys.TetherExtender));
        }
    }, [selectedTerminal, t]);

    const onCancel = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onReset = useCallback(() => {
        if (selectedTerminalId) {
            dispatch(resetTerminalExtension(selectedTerminalId));
            dispatch(showDialog());
        }
    }, [dispatch, selectedTerminalId])

    return { open, typeName, onCancel, onReset };
};