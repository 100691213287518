import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoryPayload } from '../history/history-payload';

const HISTORY_LIMIT = 10;

export interface HistoryState {
    past: HistoryPayload[];
    future: HistoryPayload[];
}

const initialState: HistoryState = {
    past: [],
    future: [],
};

const undoReducer = (state: HistoryState, _: PayloadAction): void => {
    const undone = state.past.pop();
    if (undone) {
        state.future.push(undone);
    }
};

const redoReducer = (state: HistoryState, _: PayloadAction): void => {
    const redone = state.future.pop();
    if (redone) {
        if (state.past.length === HISTORY_LIMIT) {
            state.past.shift();
        }
        state.past.push(redone);
    }
};

const pushReducer = (state: HistoryState, action: PayloadAction<HistoryPayload>): void => {
    state.future = [];
    if (state.past.length === HISTORY_LIMIT) {
        state.past.shift();
    }
    state.past.push(action.payload);
};

const clearReducer = (state: HistoryState, _: PayloadAction): void => {
    state.past = [];
    state.future = [];
};

const { actions, reducer } = createSlice({
    name: 'history',
    initialState,
    reducers: {
        undo: undoReducer,
        redo: redoReducer,
        push: pushReducer,
        clear: clearReducer,
    }
});

export { reducer as HistoryReducer };

export const { undo, redo, push, clear } = actions;