import React from 'react';

import { PipeColor } from './pipe-color';
import { TubeColorProps } from './splice-plan-dialog.types';

export const TubeColor = ({ color, colorName, stripeColor }: TubeColorProps): JSX.Element => {
    const isSolid = colorName.indexOf("Dash") < 0;
    const displayStripe = isSolid ? "none" : "block";
    const justifyContent = isSolid ? "center" : "space-between";

    return (
        <PipeColor color={color}>
            <div className="splice-plan-dialog-tube" style={{ justifyContent }}>
                <div className="splice-plan-dialog-tube-fiber-external" style={{ backgroundColor: stripeColor, display: displayStripe }} />
                <div className="splice-plan-dialog-tube-fiber-internal" />
                <div className="splice-plan-dialog-tube-fiber-external" style={{ backgroundColor: stripeColor, display: displayStripe }} />
            </div>
        </PipeColor>
    )
}