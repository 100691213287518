import { createSelector } from '@reduxjs/toolkit';
import { FiberLocation } from '../../models/bulk/splice-plan';
import { bulkSplicePlanSelector } from '../root.selectors';

interface FibersGroupBySubunitNumber {
    [subunitNumber: string]: FiberLocation[];
}

export const splicePlanLocationsWithCategoriesSelector = createSelector(
    bulkSplicePlanSelector,
    splicePlan => splicePlan?.locations
        // Discard locations with no fibers
        ?.filter(({ fibers }) => fibers.length > 0)
        // Improve locations definition and add categories before each aggregate
        .map((location, i) => {
            const { index, fibers, id: elementId } = location
            const indexLabel = `${index * 10 < 100 ? 0 : ''}${index * 10}`;

            // Group by subunit name to create color categories
            const fibersGroupBySubunit: FibersGroupBySubunitNumber = fibers.reduce(
                (tGrouped: FibersGroupBySubunitNumber, t: FiberLocation) => {
                    const { subunitName } = t;
                    const locationLabel = `${indexLabel} / ${elementId}`;
                    const key = `${t.fiberColor}-${t.fiberSequence}-${t.subunitNumber}`;
                    const tether = { ...t, locationLabel, isCategory: false, key };

                    // Create new category if not existing
                    if (!tGrouped[subunitName])
                        tGrouped[subunitName] = [{
                            ...tether,
                            key: `cat-${key}`,
                            isCategory: true,
                            fiberId: `cat-${elementId}-${subunitName}`,
                        }];
                    // Push every locations in the subunit category
                    tGrouped[subunitName].push(tether);

                    return tGrouped;
                }, {});

            // Transform locations grouped to a flat location array
            const fibersAndCategories: FiberLocation[] = Object.values(fibersGroupBySubunit).flat();

            return {
                ...location,
                indexLabel,
                arrayIndex: i,
                fibers: fibersAndCategories,
            }
        })
        || []
);