import React from "react";

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SplicePointDiagramSvgProps } from '../splice-point-diagram.types';
import { TextSvg } from "../../nap-diagram/diagram/text-svg";
import { LocalizationKeys } from '../../../locales/types';

import { Build } from '../../../models/build';
import { BuildType } from "../../../models/build-type";
import { useSplicePointDiagramSvg } from "./splice-point-diagram-svg.hooks";

import './splice-point-svg.scss';

const createLocationLabel = (location: string): JSX.Element => {
    return (
        <g className="location-number" transform="translate(44)">
            <rect className="location" width="40" height="24" rx="4" />
            <TextSvg x={20} y={12} text={location} fontSize={10} />
        </g>
    );
};

const createClosure = (label: string, connected?: boolean): JSX.Element => {
    return (
        <g className={`closure ${connected ? 'connected' : ''}`} transform="translate(137, 3)">
            <rect width="88" height="30" rx="4" />
            <TextSvg x={44} y={15} text={label} fontSize={14} />
        </g>
    );
};

const createCOLabel = (): JSX.Element => {
    return (
        <g transform="translate(310, 50)">
            <text className="CO-splice">CO</text>
            <g transform="translate(10, -17)">
                <path d="M12,4,10.6,5.4,16.2,11H4v2H16.2l-5.6,5.6L12,20l8-8Z" />
            </g>
        </g>
    );
};

const createBuild = (build: Build, x: number, t: TFunction): JSX.Element => {
    return (
        <g className="build-box" transform={`translate(${x} 240)`}>
            <rect width="68" height="24" rx="2" />
            <TextSvg x={34} y={12} text={`${build.type === BuildType.FlexNap ? t(LocalizationKeys.Build) : t(LocalizationKeys.Bulk)} ${build.id}`} fontSize={12} />
        </g>
    );
}

const createCable = (index: number, build: Build, t: TFunction): JSX.Element => {
    switch (index) {
        case 1:
            return (
                <g key={build.id} className="splice-cable" transform="translate(62 32)">
                    <path className="border" d="M119,0V26.64s0,3.44-1.86,5.41-5.63,2-5.63,2H9.91s-4-.13-6,1.95S2,42,2,42V240"/>
                    <path className="fill" d="M119,0c0,8.67,0,26.63,0,26.63s0,3.44-1.86,5.41-5.63,2-5.63,2H9.91s-4-.13-6,1.95S2,42,2,42V240"/>
                    {createBuild(build, -34, t)}
                </g>
            );
        case 2:
            return (
                <g key={build.id} className="splice-cable" transform="translate(140 32)">
                    <path className="border" d="M41,0V26.64s0,3.44-1.86,5.41-5.63,2-5.63,2H9.91s-4-.13-6,1.95S2,42,2,42V240"/>
                    <path className="fill" d="M41,0c0,8.67,0,26.63,0,26.63s0,3.44-1.86,5.41-5.63,2-5.63,2H9.91s-4-.13-6,1.95S2,42,2,42V240"/>
                    {createBuild(build, -34, t)}
                </g>
            );
        case 3:
            return (
                <g key={build.id} className="splice-cable" transform="translate(179 32)">
                    <path className="border" d="M2,0V26.64s0,3.44,1.86,5.41S9.5,34,9.5,34H33.1s4-.13,6,1.95S41,42,41,42V240" />
                    <path className="fill" d="M2,0C2,8.68,2,26.64,2,26.64s0,3.44,1.86,5.41S9.5,34,9.5,34H33.1s4-.13,6,1.95S41,42,41,42V240" />
                    {createBuild(build, 7, t)}
                </g>
            );
        case 4:
            return (
                <g key={build.id} className="splice-cable" transform="translate(179 32)">
                    <path className="border" d="M2,0V26.64s0,3.44,1.86,5.41S9.5,34,9.5,34H111.1s4-.13,6,1.95S119,42,119,42V240" />
                    <path className="fill" d="M2,0C2,8.68,2,26.64,2,26.64s0,3.44,1.86,5.41S9.5,34,9.5,34H111.1s4-.13,6,1.95S119,42,119,42V240" />
                    {createBuild(build, 85, t)}
                </g>
            );
    }

    return <></>;
};

const createPagingButtons = (numberOfPages: number, currentPage: number, setPage: (page: number) => void): JSX.Element => {
    if (numberOfPages > 1) {
        const buttons: JSX.Element[] = [];
        for (let i = 0; i < numberOfPages; i++) {
            buttons.push(<circle key={'page' + i} cx={i * 36} r="9" onClick={(): void => setPage(i)} className={'page-btn' + (i === currentPage ? ' current' : '')} />)
        }

        const x = 181 - ((numberOfPages - 1) * 36) / 2;
        return (
            <g transform={`translate(${x} 320)`}>
                {buttons}
            </g>
        );
    }
    
    return <></>
};

const emptySplicePoint = (title: string, description: string): JSX.Element => {
    return (
        <div className="splice-point-empty">
            <div className="text">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
            </div>
        </div>
    );
};

export const SplicePointDiagramSvg = (props: SplicePointDiagramSvgProps): JSX.Element => {
    const { 
        locationId, shownBuilds,
        page, numberOfPages, setPage
     } = useSplicePointDiagramSvg(props);
    const { t } = useTranslation();
    
    return (
        <div className="container">
            <svg className="diagram">
                <g transform="translate(0 8)">
                    {
                        shownBuilds.map((build: Build, index: number) => createCable(index + 1, build, t))
                    }
                    <line className="cable" y1="10" y2="10" x2="100%" strokeWidth="6" />
                    {createLocationLabel(locationId)}
                    {createClosure(t(LocalizationKeys.Closure), !!shownBuilds.length)}
                    {createCOLabel()}
                    {createPagingButtons(numberOfPages, page, setPage)}
                </g>
            </svg>
            {
                !shownBuilds.length ? 
                    emptySplicePoint(t(LocalizationKeys.SplicePointEmptyTitle), t(LocalizationKeys.SplicePointEmptyDescription)) 
                : 
                    null
            }
        </div>
    );
};