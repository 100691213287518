import * as rfc from 'rfc6902';
import { UserPreset, UserPresetData } from '../models/userpreset';
import { SecuredService } from './abstract-secured-v2.service';

export class PresetService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/preset';

    public getAll(): Promise<LoadAllPresetsResult | undefined> {
        return this.get(this.baseUrl);
    }

    public addPreset(preset: UserPresetData): Promise<UserPreset | undefined> {
        return this.post(this.baseUrl, preset);
    }

    public deletePreset(id: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${id}`);
    }

    public patchPreset(oldPreset: UserPreset, newPreset: UserPreset): Promise<UserPreset | undefined> {
        const patch = rfc.createPatch(oldPreset, newPreset);
        return this.patch(`${this.baseUrl}/${oldPreset.id}`, patch);
    }

    public setDefaultPreset(presetId: number): Promise<void> {
        return this.post(`${this.baseUrl}/default/${presetId}`);
    }
}

export interface LoadAllPresetsResult {
    presets: UserPreset[];
    defaultPresetId: number;
}
