import JwtDecode from 'jwt-decode';

import { TokenLike } from './tokenlike';
import { Units } from './units';

export class Token implements TokenLike {
    public token: string;
    public iat: number;
    public exp: number;
    public sub: string;
    public email: string;
    public dna_gid: string;
    public dna_gun: Units;

    constructor(itok: TokenLike) {
        this.token = itok.token;
        this.iat = itok.iat;
        this.exp = itok.exp;
        this.sub = itok.sub;
        this.email = itok.email;
        this.dna_gid = itok.dna_gid;
        this.dna_gun = itok.dna_gun;
    }

    public isExpired(): boolean {
        return !this.exp || (this.exp * 1000) < Date.now();
    }

    public static fromJwt(tokenStr: string): Token | null {
        try {
            const itoken = JwtDecode<TokenLike>(tokenStr);
            itoken.token = tokenStr;
            return new Token(itoken);
        }
        catch {
            console.error('Unable to create token from JWT string:', tokenStr);
            return null;
        }
    }
}
