import { Graphics, TextStyle } from 'pixi.js';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { addTap } from '../../../../redux/schrodinger/tap.state';
import { clearSelection, selectTetherSchrodinger } from '../../../../redux/selection.state';
import { setCurrentHighlighted, setPosition } from '../../../../redux/viewport.state';
import { displayedBuildSelector } from '../../../../selectors/build.selectors';
import { isHighlightedSelectorFactory, isPreviewedSelectorFactory, isSelectHighlightedSelectorFactory } from '../../../../selectors/viewport.selectors';
import { CanvasTextStyles } from '../build.styles';
import {
    CO_PROPORTIONS, HIGHLIGHT_DELETE_COLORS, HIGHLIGHT_DELETE_PROPORTIONS, HIGHLIGHT_PROPORTIONS,
    IIndexedFrameProps, LOCATION_COLORS, LOCATION_PROPORTIONS, LOCATION_TEXT_HIGHLIGHT_PROPORTIONS
} from '../build.types';
import locationDefault from './assets/Location-Default.svg';
import locationDelete from './assets/Location-Delete.svg';
import locationDisabled from './assets/Location-Disabled.svg';
import locationHighlightCurrent from './assets/Location-Highlight Current.svg';
import locationHighlightPreview from './assets/Location-Highlight-Preview.svg';
import locationHover from './assets/Location-Hover.svg';
import { LocationProps } from './location.types';

export const useLocation = ({ position, index, elementId, isSelectedForDelete, disabled }: IIndexedFrameProps & LocationProps) => {
    const dispatch = useDispatch();

    const { highlightAlpha, highlightImage, highlightScale, build, isBuildLocked, drawHighlightForText, setHighlightAlpha } = useHighlights({ position, index, isSelectedForDelete });

    const [isHovered, setIsHovered] = useState(false);

    const { x, y } = position;
    const xPosition = (x + CO_PROPORTIONS.WIDTH / 2) + ((index + 1) * LOCATION_PROPORTIONS.GAP) - (2 * LOCATION_PROPORTIONS.RADIUS);
    const yPosition = y + (CO_PROPORTIONS.HEIGHT / 2);
    const zIndex = index + 1; // +1 so that the location is drawn over the next segment

    const disableInteractions = disabled || isBuildLocked
    const cursor = !disableInteractions ? 'pointer' : ''

    const { t } = useTranslation();
    const text = t(LocalizationKeys.BuildLocationIndex, { locationIndex: (index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) });

    const locationTextStyle = (): TextStyle => {
        if (disabled) {
            return CanvasTextStyles.locationDefaultText;
        }

        if (isBuildLocked) {
            return CanvasTextStyles.locationDisabledText;
        }

        if (isHovered) {
            return CanvasTextStyles.locationHoverText;
        }
        return CanvasTextStyles.locationDefaultText;
    };

    const getImage = (): string => {
        if (disabled) {
            return locationDefault;
        }

        if (isBuildLocked) {
            return locationDisabled;
        }

        if (isHovered) {
            return locationHover;
        }

        if (isSelectedForDelete) {
            return locationDelete;
        }

        return locationDefault;
    };

    const locationImageScale = () => {
        return getImage() === locationDefault || getImage() === locationDisabled ? 0.26 : 0.36;
    };

    const drawBackgroundForText = useCallback((graphics: Graphics) => {
        graphics.clear();
        if (disableInteractions)
            return;

        if (isHovered) {
            graphics.beginFill(LOCATION_COLORS.HOVERED, HIGHLIGHT_PROPORTIONS.ALPHA);
        }

        else {
            drawHighlightForText(graphics);
        }

        graphics.drawRoundedRect(
            xPosition + LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.X_SHIFT,
            yPosition + LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.Y_SHIFT,
            LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.WIDTH,
            LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.HEIGHT,
            LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.RADIUS);
        graphics.endFill();
    }, [disableInteractions, drawHighlightForText, isHovered, xPosition, yPosition]);

    const handleMouseOver = () => {
        setIsHovered(true)
        setHighlightAlpha(0);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    }

    const handleClick = useCallback(() => {
        if (disableInteractions) {
            return;
        }

        if (elementId && build) {
            const { id: buildId, workspaceId } = build;
            dispatch(clearSelection());
            dispatch(selectTetherSchrodinger());
            dispatch(addTap(elementId, workspaceId, buildId, true));
            dispatch(setCurrentHighlighted(index));
        }
    }, [build, disableInteractions, dispatch, elementId, index]);

    const image = getImage();
    const scale = locationImageScale();

    return {
        xPosition, yPosition,
        zIndex,
        text,
        style: locationTextStyle,
        image,
        highlightImage,
        highlightAlpha,
        scale,
        highlightScale,
        isHovered,
        cursor,
        handleMouseOver,
        handleMouseOut,
        handleClick,
        drawBackgroundForText,
    };
};

export const useHighlights = ({ position, index, isSelectedForDelete }: IIndexedFrameProps & LocationProps) => {
    const dispatch = useDispatch();

    const isHighlighted = useSelector(isHighlightedSelectorFactory(index));
    const isPreviewed = useSelector(isPreviewedSelectorFactory(index));
    const isSelected = useSelector(isSelectHighlightedSelectorFactory(index));

    const build = useSelector(displayedBuildSelector);
    const isBuildLocked = !!build?.lockedById;

    const { x, y } = position;
    const xPosition = (x + CO_PROPORTIONS.WIDTH / 2) + ((index + 1) * LOCATION_PROPORTIONS.GAP) - (2 * LOCATION_PROPORTIONS.RADIUS);
    const yPosition = y + (CO_PROPORTIONS.HEIGHT / 2);

    const [highlightAlpha, setHighlightAlpha] = useState(0);
    const [timeoutFlag, setTimeoutFlag] = useState(false); // used in the case where a location is selected right after panning to it (timeout for fade out may not have ran yet).

    const highlightScale = (isHighlighted || isPreviewed || isSelected) ? 0.36 : 0.26;
    const highlightImage = (isHighlighted || isSelected) ?
        locationHighlightCurrent : isPreviewed ?
            locationHighlightPreview : isBuildLocked ?
                locationDisabled : locationDefault;

    const drawHighlightForText = useCallback((graphics: Graphics) => {
        if (isSelectedForDelete) {
            graphics.beginFill(HIGHLIGHT_DELETE_COLORS.DELETE_HIGHLIGHTED, HIGHLIGHT_DELETE_PROPORTIONS.ALPHA);
        }
        else if (isHighlighted || isSelected) {
            graphics.beginFill(LOCATION_COLORS.HIGHLIGHTED, HIGHLIGHT_PROPORTIONS.ALPHA * highlightAlpha);
        }
        else if (isPreviewed) {
            graphics.beginFill(LOCATION_COLORS.PREVIEWED, HIGHLIGHT_PROPORTIONS.ALPHA * highlightAlpha);
        }
    }, [highlightAlpha, isHighlighted, isPreviewed, isSelected, isSelectedForDelete]);

    useEffect(() => {
        if (isHighlighted || isSelected) {
            dispatch(setPosition({ x: xPosition, y: yPosition }));
            setHighlightAlpha(1);
            setTimeoutFlag(false)
            if (!isSelected) {
                setTimeout(() => {
                    setHighlightAlpha(0);
                    setTimeoutFlag(true)
                }, 2500);
            }
        }
    }, [dispatch, isHighlighted, isSelected, xPosition, yPosition]);

    useEffect(() => {
        if (isPreviewed) {
            setHighlightAlpha(1);
        }
    }, [isPreviewed]);

    useEffect(() => {
        if (isSelected && timeoutFlag) {
            setHighlightAlpha(1);
        }
    }, [timeoutFlag, isSelected]);

    return {
        highlightAlpha,
        highlightImage,
        highlightScale,
        build,
        isBuildLocked,
        drawHighlightForText,
        setHighlightAlpha
    }
}