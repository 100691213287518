import './notifications.component.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../locales/types';
import { DesignMode } from '../../models/design-mode';
import { Card } from '../card/card';
import { CardContentComponent } from '../card/card-content.component';
import { CardCheckbox } from '../card/card-controls';
import { CardHeaderComponent } from '../card/card-header.component';
import { NotificationElement } from './notification-element';
import { useNotifications } from './notifications.hooks';

export const Notifications = (): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        recentDesignMode, notifications, notificationFilter, showErrors, errorCount, showWarnings, warningCount,
        handleClose, handleErrorsToggle, handleWarningsToggle
    } = useNotifications();

    return (
        <Card id="warnings" className="notifications" onCloseClick={handleClose}>
            <CardHeaderComponent title={t(LocalizationKeys.ErrorsAndWarnings)} subheader={recentDesignMode === DesignMode.GISMode && `${t(LocalizationKeys.FilteringBy)}: ${notificationFilter}`} />
            <CardContentComponent>
                <div className="notification-filters">
                    {CardCheckbox(`${t(LocalizationKeys.Errors)} (${errorCount})`, showErrors, handleErrorsToggle)}
                    {CardCheckbox(`${t(LocalizationKeys.Warnings)} (${warningCount})`, showWarnings, handleWarningsToggle)}
                </div>
                <div className="notification-elements">
                    {notifications.map((n, idx) => <NotificationElement key={idx} notification={n} />)}
                </div>
            </CardContentComponent>
        </Card>
    );
}
