import { ElectricalUnits } from '../models/electricalUnits';
import { Units } from '../models/units';
import { UnitInterpreter } from './unit-interpreter';

type PrefixAndDivisor = [string, number];

export class NumberFormatter {
    public static formatSigFigs(value: number, units: Units | ElectricalUnits, sigFigs: number): string {
        const [prefix, divisor] = this.getPrefixAndDivisor(value);
        const outputValue = (value / divisor).toPrecision(sigFigs);
        return `${outputValue} ${prefix}${UnitInterpreter.toShort(units)}`;
    }

    private static getPrefixAndDivisor(value: number): PrefixAndDivisor {
        if (value >= 10 ** 9) return ['G', 10 ** 9];
        if (value >= 10 ** 6) return ['M', 10 ** 6];
        if (value >= 10 ** 3) return ['k', 10 ** 3];
        return ['', 1];
    }
}
