import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Cstic } from '../../../../models/cstic';
import {
    PresetContext, PresetWizardCstics, PresetWizardSteps
} from '../preset-config/preset-config.types';
import { PresetCsticGroupProps, PresetCsticOverrides } from './preset-cstic.types';

export const usePresetCsticGroup = (props: PresetCsticGroupProps) => {
    const { updateSession, loading } = useContext(PresetContext);

    const { step, cstics, goToNextStep } = props;

    const [orderedCstics, setOrderedCstics] = useState(cstics);
    const [powerTetherLegLength, setPowerTetherLegLength] = useState<string>();

    useEffect(() => {
        setOrderedCstics(cstics?.sort((c1, c2) =>
            (PresetCsticOverrides[c1.id] && PresetCsticOverrides[c2.id]) ?
                PresetCsticOverrides[c1.id].order - PresetCsticOverrides[c2.id].order
                : 0));
    }, [cstics]);

    const updateCstic = useCallback((cstic: Cstic, selectedValue: string): void => {
        updateSession(cstic.instanceId, cstic.id, selectedValue);
    }, [updateSession]);

    const isNextDisabled = useMemo((): boolean | undefined => {
        return cstics?.some(c => !c.valueId) && (!powerTetherLegLength || step !== PresetWizardSteps.PowerTetherAttributes);
    }, [cstics, powerTetherLegLength, step]);

    const handleNextClick = useCallback(() => {
        if (step === PresetWizardSteps.PowerTetherAttributes) {
            const csticLegLength = cstics?.find(c => c.id === PresetWizardCstics.LEG_LENGTH);
            if (powerTetherLegLength && csticLegLength) {
                updateCstic(csticLegLength, powerTetherLegLength);
            }
        }

        if (goToNextStep) {
            goToNextStep();
        }
    }, [cstics, powerTetherLegLength, step, updateCstic, goToNextStep])

    return {
        loading, orderedCstics,
        isNextDisabled, powerTetherLegLength,
        updateCstic, setPowerTetherLegLength,
        handleNextClick
    };
};