import { Dispatch } from 'redux';
import { Command } from '../../command';
import { deleteNap, undoDeleteNap } from '../../../redux/tap.state';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class CreateNapCommand extends Command {
    private napId: number;

    constructor({ id: napId }) {
        super();
        this.napId = napId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoNapCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoNapCreation };
    }

    public undo(dispatch: Dispatch): void {
        deleteNap(this.napId, undefined, true)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        undoDeleteNap(this.napId)(dispatch);
        super.redo(dispatch);
    }
}