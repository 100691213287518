import React from 'react';
import { TerminalExtensionType } from '../../../../models/terminal-extension-type';
import { TerminalDiagramProps } from '../../nap-diagram.types';
import { NoTerminalSvg } from '../no-terminal-svg/no-terminal-svg';
import { TextSvg } from '../text-svg';
import { TerminalLeg } from '../terminal-leg';
import { useTerminalDiagramSvg } from './terminal-diagram-svg.hooks';

export const TerminalDiagramSvg = (props: TerminalDiagramProps): JSX.Element | null => {
    const {
        terminal, tapIndex, tetherIndex, terminalIndex, portCount,
        terminalHeight, terminalWidth, legTranslation, connectorPadding, x, y, height, connectorObjs, className
    } = useTerminalDiagramSvg(props);

    if (!terminal) {
        return <NoTerminalSvg tapIndex={tapIndex} tetherIndex={tetherIndex}/>
    }
    
    return (
        <g id={'terminal' + terminalIndex} className={className} transform={`translate(${x}, ${y})`}>
            <g transform={`translate(${legTranslation})`}>
                <TerminalLeg fill="#606060" isExtended={terminal?.terminalExtension?.terminalExtensionType === TerminalExtensionType.LongTail} />
            </g>
            <g className="terminal-box" transform={'translate(0 ' + (height + 12) + ')'}>
                <rect width={terminalWidth} height={terminalHeight} rx="6" fill="#606060" stroke="#9e9e9e" strokeWidth="1"/>
                <g transform={'translate(' + connectorPadding + ' 15)'}>
                    {connectorObjs.map((co) => <circle key={co.key} className="connector" cx={co.x} cy={co.y} r="5" />)}
                </g>
                <TextSvg x={terminalWidth / 2} y={terminalHeight - 12} text={portCount + ' ports'} fontSize={12} />
            </g>
        </g>
    );
};
