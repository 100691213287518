import React, { Component } from 'react';

import { Tooltip } from '@mui/material';
import { OutlinedButton, TextButton } from '@orbit/button';
import { Radio, RadioGroup, RadioProps } from '@orbit/radio';

import { PartHelper } from '../../helpers/part-helper';
import { CpqBuildConfiguration } from '../../models/cpqbuildconfiguration';
import { CpqCablePart } from '../../models/cpqcablepart';
import { CpqInstallationType } from '../../models/cpqinstallationtype';
import { CpqTetherPart } from '../../models/cpqtetherpart';
import { Units } from '../../models/units';
import { UserPreset } from '../../models/userpreset';
import { connect, StateModel } from '../../redux/reducers';
import {
    clear as clearSelection, next as nextSelection, undo as undoSelection
} from '../../redux/wizard.state';
import { WizardService } from '../../services/wizard.service';
import { DnaSpinner } from '../ui-elements/dna-spinner';
import { ProgressIndicator } from '../ui-elements/progress-indicator/progress-indicator';
import { ProgressData } from '../ui-elements/progress-indicator/progress-indicator.types';
import { CableValues, FiberCountComponent, FiberCountData } from './fiber-count.component';
import { PresetDefinition } from './preset-definition';
import { EditorMode, PresetPropertiesEditorComponent } from './preset-properties-editor.component';

export interface PresetAttribute {
    name: string;
    options: string[];
    selected?: string;
}

enum CablePresetWizardSteps {
    AttributeSelection,
    FiberCount,
    PropertiesValidation,
}

interface CableWizardProps {
    presetName: string;
    onCancel: () => void;
    onSave: (preset: UserPreset, isDefault: boolean) => void;
}

interface CableWizardState {
    checked?: string;
    currentStep: CablePresetWizardSteps;
    progressData: ProgressData[];

    cableValues?: CableValues;
    cableParts: CpqCablePart[];
    tetherParts: CpqTetherPart[];
    configurations: CpqBuildConfiguration[];
    installationType?: CpqInstallationType;
}

const mapStateToProps = (state: StateModel) => {
    const { past, current } = state.wizard;
    const { displayUnits, customer } = state.authentication;
    const soldTo = customer?.account?.soldTo;
    return { past, current, displayUnits, soldTo };
};

const mapDispatchToProps = {
    undoSelection,
    nextSelection,
    clearSelection,
};

type props = CableWizardProps & Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CableWizardComponent extends Component<props, CableWizardState> {

    private static TITLE_FIBER_COUNT = 'Fiber Count';

    constructor(props: props) {
        super(props);
        if (props.nextSelection) {
            props.nextSelection(null, null, {}, props.soldTo);
        }
        this.state = {
            checked: undefined,
            currentStep: CablePresetWizardSteps.AttributeSelection,
            progressData: [],

            cableValues: undefined,
            cableParts: [],
            tetherParts: [],
            configurations: [],
            installationType: undefined,
        }
    }

    public componentDidUpdate(prevProps: props, prevState: CableWizardState): void {
        const { current, past } = this.props;
        const { currentStep } = this.state;
        if (current && past) {
            if (currentStep !== prevState.currentStep) {
                this.setState({ progressData: this.calculateProgressData() });
            }
            if (current !== prevProps.current) {
                if (current.name === CableWizardComponent.TITLE_FIBER_COUNT) {
                    this.loadParts(past);
                }
                else if (!current.options.length) {
                    this.props.clearSelection!();
                    this.props.onCancel!();
                }
                this.setState({ progressData: this.calculateProgressData() });
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.props.clearSelection) {
            this.props.clearSelection();
        }
    }

    private loadParts = (past: PresetAttribute[]): void => {
        const service = new WizardService();
        const options = past.reduce((map, p) => {
            map[p.name] = p.selected ?? "";
            return map;
        }, {});
        const installationType = past.find((p) => p.name === "Installation Type")?.selected ?? "";
        service.getFiberCounts(installationType, options, this.props.soldTo)
            .then((res) => {
                if (res) {
                    const { installationType, cableParts, tetherParts, configurations } = res;
                    this.setState({ currentStep: CablePresetWizardSteps.FiberCount, installationType, cableParts, tetherParts, configurations });
                }
            });
    }

    private calculateProgressData = (): ProgressData[] => {
        const { past, current } = this.props;
        const { currentStep, cableValues } = this.state;
        const progressData: ProgressData[] = [];
        progressData.push(...past!
            .filter((p) => p.options.length > 1)
            .map((p) => {
                return {
                    id: p.name, title: p.name, value: p.selected ?? ''
                }
            }));
        if (current && current.options.length > 1) {
            progressData.push({ id: current.name, title: current.name, value: '' });
        }

        if (currentStep === CablePresetWizardSteps.PropertiesValidation) {
            const fiberCount = !cableValues ? '' : `${cableValues.fiberCount}F / ${cableValues.tetherFiberCount}F`;
            progressData.push({ id: "fiber_count", title: 'Fiber Count', value: fiberCount });
            progressData.push({ id: "cable_preset", title: 'Cable Preset', value: '' });
        }
        else if (currentStep === CablePresetWizardSteps.FiberCount) {
            progressData.push({ id: "fiber_count", title: 'Fiber Count', value: '' });
        }
        return progressData;
    }

    private handleSelectedOption = (event: React.ChangeEvent<any>): void => {
        const value = event.currentTarget.value;
        this.setState({ checked: value });
    }

    private onNextClicked = (): void => {
        const { past, current, nextSelection, soldTo } = this.props;
        const { checked } = this.state;
        if (past && current && checked && nextSelection) {
            const options = past.reduce((map, p) => {
                map[p.name] = p.selected ?? "";
                return map;
            }, {});
            nextSelection(current.name, checked, options, soldTo);
        }
        this.setState({ checked: undefined });
    }

    private undoSelection = (title?: string): void => {
        const { past } = this.props;
        const { currentStep } = this.state;
        if (!past) {
            return;
        }
        if (currentStep === CablePresetWizardSteps.PropertiesValidation) {
            if (title === CableWizardComponent.TITLE_FIBER_COUNT) {
                this.setState({ currentStep: CablePresetWizardSteps.FiberCount });
                return;
            }
            else {
                this.setState({ currentStep: CablePresetWizardSteps.AttributeSelection });
            }
        }
        const pastIndex = past.findIndex((a) => a.name === title);
        const selectedAttribute = past[pastIndex];
        const selected = selectedAttribute ? selectedAttribute.selected : undefined;
        for (let i = past.length - 1; i >= pastIndex; i--) {
            this.props.undoSelection!();
        }
        if (currentStep === CablePresetWizardSteps.FiberCount) {
            this.setState({ currentStep: CablePresetWizardSteps.AttributeSelection });
        }
        this.setState({ checked: selected });
    }

    private onPreviousClicked = (): void => {
        const { past } = this.props;
        if (!past) {
            return;
        }
        if (past.length < 2) {
            this.props.clearSelection!();
            this.props.onCancel!();
        }
        else {
            const filteredPast = past.filter((p) => p.options.length > 1);
            this.undoSelection(filteredPast[filteredPast.length - 1].name);
        }
    }

    private handleStepClick = (event: React.MouseEvent<any>) => {
        const title = event.currentTarget.id;
        this.undoSelection(title);
    }

    private showPropertiesValidation = (cableValues: CableValues): void => {
        this.setState({ currentStep: CablePresetWizardSteps.PropertiesValidation, cableValues });
    }

    private createRadioProps(value: string): RadioProps {
        return { label: value, value };
    }

    private buildWizardLabel(count: number, name: string): string {
        return `${count}. Select the ${name.toLowerCase()}:`;
    }

    private renderAttributeSelection = (): JSX.Element | null => {
        const { current } = this.props;
        const { checked, progressData } = this.state;
        if (current && current.options) {
            if (current.options.length === 1) {
                return <div className="dna-spinner-container"><DnaSpinner /></div>;
            }
            const radioProps = current.options.map((key) => this.createRadioProps(key));
            const label = this.buildWizardLabel(progressData.length, current.name);
            const previousLabel = progressData.length > 2 ? progressData[progressData.length - 2].title : 'Previous';
            const previousIcon = <img alt="Previous" src={require('./icon_chevron_left.svg')} />;
            const help = PresetDefinition.help[current.name];
            return (
                <div className='radio-group-container'>
                    <div className='radio-group-subheader'>
                        <div>
                            <RadioGroup
                                label={label}
                                name={current.name}
                                onChange={this.handleSelectedOption}
                                value={checked}
                                row
                            >
                                {radioProps.map(o => <Radio {...o} key={o.name} checked={this.state.checked === o.value}></Radio>)}
                            </RadioGroup>
                        </div>
                        <Tooltip className="tooltip" title={<span className="caption">{help}</span>} placement="left">
                            <button className="icon-button"><img alt="Help" src={require('./icon_help.svg')} /></button>
                        </Tooltip>
                    </div>
                    <div className="navigation-container">
                        <TextButton startIcon={previousIcon} onClick={this.onPreviousClicked}>{previousLabel}</TextButton>
                        <OutlinedButton onClick={this.onNextClicked} disabled={checked === undefined}>Next</OutlinedButton>
                    </div>
                </div>
            );
        }
        return null;
    }

    private createFiberCountData = (): FiberCountData => {
        const { cableParts, tetherParts, installationType } = this.state;
        const fiberCounts = cableParts.map((c) => c.fiber_Count).filter((f, i, a) => a.indexOf(f) === i).sort((a, b) => a - b);
        const tetherFiberCounts = tetherParts.map((t) => t.fiber_Count).filter((f, i, a) => a.indexOf(f) === i).sort((a, b) => a - b);
        return {
            slackUnit: Units.feet,
            fiberCounts,
            tetherFiberCounts,
            installationType: installationType!,
        }
    }

    private renderFiberCountSelection = (): JSX.Element => {
        const progressData = this.state.progressData;
        const previousLabel = progressData.length > 1 ? progressData[progressData.length - 2].title : 'Previous';
        const data = this.createFiberCountData();
        return (
            <FiberCountComponent
                data={data}
                displayUnits={this.props.displayUnits!}
                currentStepIndex={progressData.length - 1}
                previousLabel={previousLabel}
                onPreviousClicked={this.onPreviousClicked}
                onNextClicked={this.showPropertiesValidation}
            />
        );
    }

    private createPreset = (): UserPreset | null => {
        const { cableValues, cableParts, tetherParts, configurations } = this.state;
        if (!cableValues) {
            return null;
        }
        const { fiberCount, tetherFiberCount } = cableValues;
        const cablePart = cableParts.find((c) => +c.fiber_Count === +fiberCount);
        if (!cablePart) {
            return null;
        }
        const configuration = configurations.find((c) => PartHelper.matchesConfiguration(cablePart, c));
        if (!configuration) {
            return null;
        }
        const tetherPart = tetherParts.find((t) => +t.fiber_Count === +tetherFiberCount &&
            PartHelper.isCompatible(cablePart, t) &&
            t.installation_Type === configuration.installation_Type &&
            t.taP_Type === configuration.taP_Type &&
            t.tether_Seq === "001");
        if (!tetherPart) {
            return null;
        }
        return {
            id: -1,
            name: this.props.presetName,
            coSlack: cableValues.coSlack,
            fieldSlack: cableValues.fieldSlack,
            slackUnit: cableValues.slackUnit,
            isDefault: true,
            cablePart: cablePart.part,
            tetherPart: tetherPart.tether_Item,
            cableDescription: JSON.stringify(PartHelper.buildPartDescription(configuration)),
            tetherDescription: JSON.stringify(PartHelper.buildTetherPartDescription(tetherPart)),
            installationType: configuration.installation_Type,
            userId: -1,
        };
    }

    private renderPropertiesValidation = (): JSX.Element | null => {
        const { displayUnits } = this.props;
        const preset = this.createPreset();
        return !preset ? null : (
            <PresetPropertiesEditorComponent
                mode={EditorMode.Editing}
                preset={preset}
                displayUnits={displayUnits!}
                onCancel={this.props.onCancel}
                onSavePreset={this.props.onSave}
            />
        );
    }

    private renderWizardStep = (): JSX.Element | null => {
        const { currentStep } = this.state;
        switch (currentStep) {
            case CablePresetWizardSteps.AttributeSelection:
                return this.renderAttributeSelection();
            case CablePresetWizardSteps.FiberCount:
                return this.renderFiberCountSelection();
            default:
                return this.renderPropertiesValidation();
        }
    }

    public render(): JSX.Element {
        const progressData = this.state.progressData;
        return (
            <div>
                <ProgressIndicator data={progressData} selectedIndex={progressData.length - 1} onClick={this.handleStepClick} />
                {this.renderWizardStep()}
            </div>
        );
    }
}
