import '../drawer.scss';

import React from 'react';

import { TextButton } from '@orbit/button';

import { ExportBuildErrorDialog } from '../../dialogs/export-build-error-dialog';
import { DrawerMenuBody } from './body/drawer-menu-body';
import { DebugTools } from './debug-tools/debug-tools';
import { DeleteBuildsDialog } from './delete-builds-dialog/delete-builds-dialog';
import { DeleteDesignAreasDialog } from './delete-design-area-dialog/delete-design-area-dialog';
import { useDrawerMenu } from './drawer-menu.hooks';
import { DrawerMenuContext } from './drawer-menu.types';
import { DrawerMenuHeader } from './header/drawer-menu-header';

export const DrawerMenu = (): JSX.Element => {
    const {
        menuClass,
        drawerMenuContextProps,
        showDebugMenu,
        debugIcon,
        showDebugTools,
        showExportBuildErrorDialog,
        handleCloseClick,
        handleDebugClick,
        handleCloseExportBuildErrorDialogClick,
    } = useDrawerMenu();

    return (
        <>
            <div className={menuClass}>
                <DrawerMenuContext.Provider value={drawerMenuContextProps}>
                    <DrawerMenuHeader onClose={handleCloseClick} />
                    <DrawerMenuBody />
                    <DeleteBuildsDialog />
                    <DeleteDesignAreasDialog />
                </DrawerMenuContext.Provider>
                {showDebugMenu && (
                    <TextButton className="icon-button" onClick={handleDebugClick}>
                        <img src={debugIcon} alt="Debug" />
                    </TextButton>
                )}
                {showDebugTools && <DebugTools />}
                {showExportBuildErrorDialog && (
                    <ExportBuildErrorDialog
                        open={!!showExportBuildErrorDialog}
                        message={showExportBuildErrorDialog}
                        onClose={handleCloseExportBuildErrorDialogClick}
                    />
                )}
            </div>
        </>
    );
};
