import { createSelector } from '@reduxjs/toolkit';
import { StateModel } from '../redux/reducers';

export const importSelector = (state: StateModel) => state.import;

export const isImportingSelector = createSelector(
    importSelector,
    importState => importState.poles || importState.parcels || importState.cabinets || importState.manholes || importState.handholes || importState.vaults || importState.trenches || importState.bores
);

export const importedGisDataSelector = createSelector(
    importSelector,
    importState => importState.importedGisData
);

export const isRemovingGisDataSelector = createSelector(
    importSelector,
    importState => importState.isRemovingGisData
);

export const importedGisDataToRemoveSelector = createSelector(
    importSelector,
    importState => importState.importedGisData.find(i => i.id === importState.importedGisDataToRemove)
);