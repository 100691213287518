import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { Translate } from 'ol/interaction';
import { TranslateEvent } from 'ol/interaction/Translate';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';

import { MapItemDefinition } from '../models/map-item-definition';
import { DragInteractionFeatureOptions } from './drag-interaction-feature-options';
import { LayerFactory } from './openlayer-layer.factory';
import { OpenlayerStyleFactory } from './openlayer-style.factory';
import { OpenlayerUtility } from './openlayer-utility';

export interface MapDragEvent {
    item: MapItemDefinition;
}

export interface MapDragEndEvent {
    item: MapItemDefinition;
    nearby: MapItemDefinition[];
    endAt?: { x: number; y: number };
    endAtCoordinate?: Coordinate;
}

const TEMP_DRAG_LAYER_INDEX = 3;

export class DragInteraction {
    public static add(map: Map, dragFilterCallback: (event: MapDragEvent) => boolean, dragEndCallback: (event: MapDragEndEvent) => Promise<boolean>): void {
        const filter = (f: FeatureLike): boolean => {
            const locked = !!f.get('locked');
            const def = OpenlayerUtility.getFeatureDefinition(f);
            return !locked && dragFilterCallback({ item: def });
        };
        const interaction = new Translate({ filter });
        let startGeom: Geometry | undefined;
        let translatingEvent: EventsKey;
        let drawLineOptions: DragInteractionFeatureOptions;
        interaction.on('translatestart', (evt: TranslateEvent) => {
            startGeom = evt?.features?.item(0)?.getGeometry()?.clone();
            for (const f of evt.features.getArray()) {
                f.set('dragging', true);
                f.setStyle(OpenlayerStyleFactory.createFeatureStyleFunction(f, evt.mapBrowserEvent.frameState.viewState.resolution ?? 1));
            }

            drawLineOptions = evt.features.item(0).get('drawLineOptions');
            if (drawLineOptions) {
                map.getLayers().insertAt(TEMP_DRAG_LAYER_INDEX, drawLineOptions.createLayerMethod.call(LayerFactory)); // temporary layer for the drag interaction line & ghost
                const lineLayer = map.getLayers().item(TEMP_DRAG_LAYER_INDEX);
                lineLayer.set('dragLayer', true, true);
                const f = evt.features.item(0);
                (f.getGeometry() as Point).setCoordinates(evt.coordinate); // if the element is translated from its position originally, center it on the mouse when dragging
                const lineLayerSource = (lineLayer as VectorLayer).getSource();
                const { originCoordinates, drawGhostFeature: drawGhost } = drawLineOptions;
                translatingEvent = interaction.on('translating', (evt: TranslateEvent) => {
                    lineLayerSource.clear();
                    if (originCoordinates) {
                        const pathFeature = new LineString([evt.coordinate, originCoordinates]);
                        const feature = new Feature(pathFeature);
                        feature.set("type", drawLineOptions.interactableFeatureType);
                        if (drawLineOptions.extraLineFeatureOptions) {
                            feature.setProperties(drawLineOptions.extraLineFeatureOptions);
                        }
                        feature.setStyle(OpenlayerStyleFactory.createFeatureStyleFunction(feature, evt.mapBrowserEvent.frameState.viewState.resolution ?? 1))
                        lineLayerSource.addFeature(feature);
                    }                    
                    if (drawGhost) {
                        const pointGeometry = new Point(originCoordinates);
                        const feature = new Feature(pointGeometry);
                        feature.set("type", drawLineOptions.interactableFeatureType);
                        feature.set("ghost", true);
                        if (drawLineOptions.extraLineFeatureOptions) {
                            feature.setProperties(drawLineOptions.extraLineFeatureOptions);
                        }
                        feature.setStyle(OpenlayerStyleFactory.createFeatureStyleFunction(feature, evt.mapBrowserEvent.frameState.viewState.resolution ?? 1))
                        lineLayerSource.addFeature(feature);
                    }
                });
            }
        });
        interaction.on('translateend', async (evt: TranslateEvent) => {
            const lineLayer = map.getLayers().item(TEMP_DRAG_LAYER_INDEX);
            if (lineLayer.get('dragLayer')) {
                map.getLayers().remove(lineLayer);
            }
            if (translatingEvent) {
                interaction.removeEventListener('translating', translatingEvent.listener);
            }

            if (evt.features.getLength() !== 1) {
                throw new Error('Translate only supports single feature manipulations');
            }

            const feature = evt.features.item(0);
            const item = OpenlayerUtility.getFeatureDefinition(feature);
            const nearby = map.getFeaturesAtPixel(evt.mapBrowserEvent.pixel, { hitTolerance: 15 }).map(OpenlayerUtility.getFeatureDefinition);
            const res = await dragEndCallback({
                item,
                nearby,
                endAt: { x: evt.mapBrowserEvent.pixel[0], y: evt.mapBrowserEvent.pixel[1] },
                endAtCoordinate: evt.mapBrowserEvent.coordinate
            });
            if(!res) {
                feature.setGeometry(startGeom);
                feature.set('dragging', false);
                feature.setStyle(OpenlayerStyleFactory.createFeatureStyleFunction(feature, evt.mapBrowserEvent.frameState.viewState.resolution ?? 1))
            }

            startGeom = undefined;
        });
        map.addInteraction(interaction);
    }
}