import React, { useCallback, useContext } from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';

import { DownloadLayersContext, LayerKey } from './download-gis-data-dialog.types';

export const DownloadLayerRow = (props: { layerKey: LayerKey; labelText: string }): JSX.Element => {
    const { layerKey, labelText } = props;
    const { includeLayers, onLayerClick } = useContext(DownloadLayersContext);

    const checked = layerKey === (includeLayers & layerKey);

    const onCheckboxClick = useCallback((): void => {
        onLayerClick(layerKey);
    }, [layerKey, onLayerClick]);
    
    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onClick={onCheckboxClick} />}
            label={labelText}
        />
    );
};
