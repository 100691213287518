import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BuildType } from '../../models/build-type';
import CorningRoles from '../../redux/corning-roles.json';
import { StateModel } from '../../redux/reducers';
import { addRoles, isDevelopmentEnvironment, removeRoles, toggleRoleManagementMenu } from '../../redux/roles.state';
import { setBuildType } from '../../redux/tool.state';
import { rolesSelector } from '../../selectors/root.selectors';

export const useRoleManagementTool = () => {

    const dispatch = useDispatch();
    const { buildType } = useSelector((state: StateModel) => state.tool);
    const { roles } = useSelector(rolesSelector);

    const [currentSelectedProductRole, setCurrentSelectedProductRole] = useState<string>(roles?.includes(CorningRoles.FlexNAP) ? CorningRoles.FlexNAP : CorningRoles.Schrodinger)

    const ensureProperBuildType = useCallback((role: string) => {
        if (buildType) {
            if (role === CorningRoles.FlexNAP && buildType !== BuildType.FlexNap) {
                dispatch(setBuildType(BuildType.FlexNap));
            }
            else if (role === CorningRoles.Schrodinger && buildType !== BuildType.Schrodinger) {
                dispatch(setBuildType(BuildType.Schrodinger));
            }
        }
    }, [dispatch, buildType]);

    const handleRoleChange = useCallback((event: React.ChangeEvent<any>): void => {
        const value = event.currentTarget.value as string;
        if (value) {
            setCurrentSelectedProductRole(value);

            const rolesToRemove: string[] = [];
            const rolesToAdd: string[] = [value];
            if (value === CorningRoles.FlexNAP) {
                rolesToRemove.push(CorningRoles.Schrodinger);
                isDevelopmentEnvironment() && rolesToAdd.push(CorningRoles.FlexNAPAdmin);
            } else {
                rolesToRemove.push(CorningRoles.FlexNAP);
                isDevelopmentEnvironment() && rolesToRemove.push(CorningRoles.FlexNAPAdmin);
            }
            dispatch(removeRoles(rolesToRemove));
            dispatch(addRoles(rolesToAdd));

            localStorage.setItem("role", value);
            ensureProperBuildType(value);

            dispatch(toggleRoleManagementMenu());
        }
    }, [dispatch, ensureProperBuildType]);


    return {
        currentSelectedProductRole,
        handleRoleChange
    };
}