import './workspace-information-dialog.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { CardInput, CardInputWithCount, CardSelect } from '../../card/card-controls';
import { DialogComponent } from '../dialog.component';
import { WorkspaceInformationDialogProps } from './workspace-dialog.types';
import { useWorkspaceInfoDialog } from './workspace-information-hooks';


export const WorkspaceInfoDialog = (props: WorkspaceInformationDialogProps) => {
    const { t } = useTranslation();
    const {
        onClose, onCancel, onSave,
        workspaceId, onWorkspaceIdInputChanged, isWorkspaceIdInvalid,
        name, onNameInputChanged, isNameInvalid,
        description, onDescInputChanged, isDescriptionInvalid,
        zipCode, onZipCodeInputChanged, isZipCodeInvalid,
        city, onCityInputChanged, isCityInvalid, isCityMandatory,
        country, onCountryValueChanged,
        state, onStateValueChanged,
        countries, states,
        canSave
    } = useWorkspaceInfoDialog(props);

    return (
        ReactDOM.createPortal(
            <DialogComponent
                open
                title={t(LocalizationKeys.WorkspaceInfoDialogTitle)}
                positiveTitle={t(LocalizationKeys.Save)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onClose={onClose}
                onNegativeClick={onCancel}
                onPositiveClick={onSave}
                disablePositive={!canSave}
                className="workspace-info"
            >
                <div className="workspace-info-container">
                    <div className="workspace-info-helper-text">
                        {t(LocalizationKeys.WorkspaceInfoHelperText)}
                    </div>
                    <div className="workspace-info-input-container">
                        <div className="workspace-info-input-field">
                            {CardInputWithCount({
                                label: t(LocalizationKeys.WorkspaceName),
                                name: "workspace-name",
                                value: name,
                                maxLength: 25,
                                error: isNameInvalid,
                                helperText: isNameInvalid ? t(LocalizationKeys.NoSpecialChar) : undefined,
                                onChange: onNameInputChanged
                            })}
                        </div>
                        <div className="workspace-info-input-field">
                            {CardInputWithCount({
                                label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.WorkspaceID) }),
                                name: "workspace-id",
                                value: workspaceId,
                                maxLength: 20,
                                error: isWorkspaceIdInvalid,
                                helperText: isWorkspaceIdInvalid ? (workspaceId.length === 0 ? t(LocalizationKeys.Required) : t(LocalizationKeys.NoSpecialCharSpace)) : undefined,
                                onChange: onWorkspaceIdInputChanged
                            })}
                        </div>
                    </div>
                    <div className="workspace-info-wide-input-field">
                        {CardInputWithCount({
                            label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.Description) }),
                            name: "workspace-desc",
                            value: description,
                            maxLength: 100,
                            error: isDescriptionInvalid,
                            helperText: isDescriptionInvalid ? (description.length === 0 ? t(LocalizationKeys.Required) : t(LocalizationKeys.NoSpecialChar)) : undefined,
                            onChange: onDescInputChanged
                        })}
                    </div>
                    <div className="workspace-info-input-container">
                        <div className="workspace-info-input-field">
                            {CardInput({
                                label: t(LocalizationKeys.ZipCode),
                                name: "workspace-zip-code",
                                value: zipCode,
                                error: isZipCodeInvalid,
                                helperText: isZipCodeInvalid ? t(LocalizationKeys.NoSpecialChar) : undefined,
                                onChange: onZipCodeInputChanged
                            })}
                        </div>
                        <div className="workspace-info-input-field">
                            {CardInputWithCount({
                                label: isCityMandatory ? t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.City) }) : t(LocalizationKeys.City),
                                name: "workspace-city",
                                value: city,
                                maxLength: 30,
                                error: isCityInvalid,
                                helperText: isCityInvalid ? ((isCityMandatory && city.length === 0) ? t(LocalizationKeys.Required) : t(LocalizationKeys.AlphaOnly)) : undefined,
                                onChange: onCityInputChanged
                            })}
                        </div>
                    </div>
                    <div className="workspace-info-cpq-container">
                        <div className="workspace-info-input-field">
                            {CardSelect({
                                label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.Country) }),
                                name: "workspace-country",
                                value: country,
                                values: countries,
                                onChange: onCountryValueChanged
                            })}
                        </div>
                        <div className="workspace-info-input-field">
                            {CardSelect({
                                label: t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.State) }),
                                name: "workspace-state",
                                value: state,
                                values: states,
                                onChange: onStateValueChanged
                            })}
                        </div>
                    </div>
                </div>
            </DialogComponent>
            , document.getElementById('react-app-root')!)
    );
}