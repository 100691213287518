import React from "react";

export const DownloadBOMIcon = ({ disabled }: { disabled?: boolean }) => {
    const fill = disabled ? "#00000042" : "#005293";
    return (
        <svg id="download-bom-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={24} height={24} viewBox="0 0 24 24" enableBackground={"new 0 0 24 24"} xmlSpace="preserve">
            <path fillRule="evenodd" clipRule={"evenodd"} fill={fill} d="M0.5,9h3V6h-3V9z M0.5,14h3v-3h-3V14z M3.5,19h-3v-3h3V19z M4.5,9h10V6h-10V9z M4.5,14h10v-3h-10V14z M14.5,19
            h-10v-3h10V19z M23.5,17.5V19h-8v-1.5H23.5z M21.2,10.6h2.3l-4,4.1l-4-4.1h2.3V6h3.4V10.6z"/>
        </svg>
    )
}