import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { LocalizationKeys } from '../../../../locales/types';
import { StateModel } from '../../../../redux/reducers';
import { setEditingWorkspaceId } from '../../../../redux/workspace.state';

export const useWorkspaceDrawerSubheader = () => {
    const { t } = useTranslation();
    const { workspaces, editingWorkspaceId: selectedWorkspaceId } = useSelector((state: StateModel) => state.workspace);
    const nbWorkspaces = workspaces.length;

    const dispatch = useDispatch();

    const [title, setTitle] = useState("");

    useEffect(() => {
        if (nbWorkspaces > 1) {
            setTitle(t(LocalizationKeys.MultipleWorkspaces, { nbWorkspaces: nbWorkspaces }));
        } else {
            setTitle(t(LocalizationKeys.SingleWorkspace));
        }
    }, [nbWorkspaces, t]);

    const onNewWorkspaceClick = useCallback(() => {
        dispatch(setEditingWorkspaceId(-1));
    }, [dispatch]);

    return { title, selectedWorkspaceId, onNewWorkspaceClick };
}