import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FlexNapBuild } from '../../../models/flexnap-build';
import { lockBuilds, unlockBuilds, updateFlexNapBuild } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { selectBuild, selectSegment } from '../../../redux/selection.state';
import { selectedFlexNapBuildTethersFiberCountSelector } from '../../../selectors/taps.selectors';
import { useLockPermission } from '../../../hooks/lock-permission.hooks';

export const useFlexNapBuildCard = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: StateModel) => state);
    const { userId } = useSelector((state: StateModel) => state.authentication);
    const { selectedSegmentId, selectedBuildId } = useSelector((state: StateModel) => state.selection);
    const { flexnapBuilds, segments } = useSelector((state: StateModel) => state.build);
    const { naps } = useSelector((state: StateModel) => state.taps);
    const { selectedTool } = useSelector((state: StateModel) => state.tool);
    const { currentWorkspace } = useSelector((state: StateModel) => state.workspace);
    const { clickedNotification } = useSelector((state: StateModel) => state.map);
    const tetherFiberCount = selectedFlexNapBuildTethersFiberCountSelector(state);
    const { lockButtonDisabled, unlockButtonDisabled } = useLockPermission();

    const segment = segments.find((s) => s.id === selectedSegmentId);
    const buildId = segment?.buildId || selectedBuildId;
    const build = buildId ? flexnapBuilds.find((b) => b.id === buildId) : undefined;
    const parentBuild = flexnapBuilds.find(b => b.id === build?.pretermLateral?.parentBuildId);
    const buildLocked = !!build?.lockedById;
    const editDisabled = (parentBuild ? parentBuild.modifiedById !== userId : build?.modifiedById !== userId) || buildLocked;
    const unlockDisabled = !!(build && (buildLocked ? unlockButtonDisabled(build.id) : lockButtonDisabled(build.id)));
    const buildSegments = build ? segments.filter((s) => s.buildId === buildId) : [];
    const buildNaps = build ? naps.filter((n) => n.buildId === buildId) : [];

    const [collapsed, setCollapsed] = useState();

    const unselectBuild = useCallback((): void => {
        dispatch(selectBuild());
        dispatch(selectSegment());
    }, [dispatch]);

    const onDeleteBuildClick = useCallback((): void => {
        dispatch(showDialog(DialogType.DeleteFlexNapBuild));
    }, [dispatch]);

    const onLockBuild = useCallback((): void => {
        if (build && currentWorkspace) {
            dispatch(lockBuilds([build.id], currentWorkspace.id));
        }
    }, [build, currentWorkspace, dispatch]);

    const onUnlockBuild = useCallback((): void => {
        if (build && currentWorkspace) {
            dispatch(unlockBuilds([build.id], currentWorkspace.id));
        }
    }, [build, currentWorkspace, dispatch]);

    const onUpdateColor = useCallback((value: string): void => {
        if (build) {
            const newBuild: FlexNapBuild = { ...build, cableColorHex: value };
            dispatch(updateFlexNapBuild(build, newBuild));
        }
    }, [build, dispatch]);

    const onResetColor = useCallback((): void => {
        onUpdateColor("");
    }, [onUpdateColor]);

    const toggleCollapse = useCallback((collapsed): void => {
        setCollapsed(collapsed);
    }, []);

    return {
        editDisabled,
        unlockDisabled,
        collapsed,
        buildLocked,
        build,
        segments: buildSegments,
        segment,
        naps: buildNaps,
        tetherFiberCount,
        selectedTool,
        clickedNotification,
        unselectBuild,
        onDeleteBuildClick,
        onLockBuild,
        onUnlockBuild,
        onUpdateColor,
        onResetColor,
        toggleCollapse
    }
}