import i18next from 'i18next';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { store } from '../dna';

import { BuilderHelper } from '../helpers/build-helper';
import { CableCalculationsHelper } from '../helpers/cable-calculations-helper';
import { NumberFormatter } from '../helpers/number-formatter';
import { LocalizationKeys } from '../locales/types';
import { Build } from '../models/build';
import { BuildSegment } from '../models/build-segment';
import { BuildType } from '../models/build-type';
import { BulkBuild } from '../models/bulk/bulk-build';
import { Cabinet } from '../models/cabinet';
import { DesignArea } from '../models/design-area';
import { ElectricalUnits } from '../models/electricalUnits';
import { Element } from '../models/element';
import { ElementType } from '../models/element-type';
import { FlexNapBuild } from '../models/flexnap-build';
import { FlexNapPortNumbersByElement, PortNumberRange } from '../models/flexnap-splice-plan';
import { Handhole } from '../models/handhole';
import { Manhole } from '../models/manhole';
import { Nap } from '../models/nap';
import { Parcel } from '../models/parcel';
import { Path } from '../models/path';
import { PointLike } from '../models/pointlike';
import { Pole } from '../models/pole';
import { ReviewStatus } from '../models/review-status';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { OpticalTether } from '../models/schrodinger/optical-tether';
import { PowerTether } from '../models/schrodinger/power-tether';
import { Tap as TapSchrodinger } from '../models/schrodinger/tap';
import { Tether as TetherSchrodinger } from '../models/schrodinger/tether';
import { TetherType } from '../models/schrodinger/tether-type';
import { SplicePoint } from '../models/splice-point';
import { Tap } from '../models/tap';
import { Terminal } from '../models/terminal';
import { TerminalSegment } from '../models/terminal-segment';
import { Tether } from '../models/tether';
import { Vault } from '../models/vault';
import { loadMissingElement } from '../redux/element.actions';
import { GeoProject } from '../services/geoserver.service';
import { RESOLUTION_CLOSE, RESOLUTION_MEDIUM, RESOLUTION_PRECISE } from './constants';
import { DragInteractionFeatureOptions } from './drag-interaction-feature-options';
import { InteractableFeatureType } from './interactable-feature-type';
import { LayerFactory } from './openlayer-layer.factory';
import { OpenlayerStyleFactory } from './openlayer-style.factory';
import { OpenlayerUtility } from './openlayer-utility';
import { StaticFeatureType } from './static-feature-type';

export type FeatureType = InteractableFeatureType | StaticFeatureType;
const BASE_POINT_PADDING = 4;

export class FeatureFactory {

    private static isBuildLocked(build?: Build): boolean {
        if (!build) {
            return false;
        }

        return !!build.lockedById || !!build.uploadedDateTime;
    }

    public static createDesignAreaFeatures(designAreas: DesignArea[], resolution: number | undefined): Feature[] {
        const features: Feature[] = [];

        for (const da of designAreas) {
            if (!da.polygon)
                continue;
            const polygon = da.polygon.map((p) => [p.x, p.y]);
            const feature = this.createPolygonFeature([polygon], InteractableFeatureType.DESIGN_AREA, resolution, { id: da.id, color: da.color, isImported: da.isImported });
            features.push(feature);
        }

        return features;
    }

    public static createFlexNapSegmentFeatures(builds: FlexNapBuild[], segments: BuildSegment[], elements: Element[], paths: Path[], resolution: number | undefined): Feature[] {
        if (!elements.length) {
            return [];
        }
        const features: Feature[] = [];
        // segments with id -1 are temporary FlexNap segments
        const tempSegments = segments.filter((s) => s.id === -1).sort((s1, s2) => s1.id - s2.id);
        tempSegments.forEach((tempSegment) => {
            const extraInfo = { fiberCount: -1, isPretermLateral: false, cablestart: tempSegment.position === 0, cableend: tempSegment.position === tempSegments.length - 1 };
            features.push(...FeatureFactory.createSegmentFeatures(tempSegment, extraInfo, InteractableFeatureType.FLEXNAP_SEGMENT, elements, paths, resolution));
        });
        for (const build of builds) {
            const locked = this.isBuildLocked(build);
            const buildSegments = segments.filter((s) => s.buildId === build.id).sort((s1, s2) => s1.id - s2.id);
            buildSegments.forEach((segment) => {
                const extraInfo = { fiberCount: build.fiberCount, cableColorHex: build.cableColorHex, isPretermLateral: !!build.pretermLateral, isChildSplice: !!build.buildSplice, locked, cablestart: segment.position === 0, cableend: segment.position === buildSegments.length - 1 };
                features.push(...FeatureFactory.createSegmentFeatures(segment, extraInfo, InteractableFeatureType.FLEXNAP_SEGMENT, elements, paths, resolution));
            });
        }
        return features;
    }

    public static createSchrodingerSegmentFeatures(builds: SchrodingerBuild[], segments: BuildSegment[], elements: Element[], paths: Path[], resolution: number | undefined): Feature[] {
        if (!elements.length) {
            return [];
        }
        const features: Feature[] = [];
        // segments with id -2 are temporary Schrodinger segments
        const tempSegments = segments.filter((s) => s.id === -2).sort((s1, s2) => s1.id - s2.id);
        tempSegments.forEach((tempSegment) => {
            const extraInfo = { isConfigured: false, locked: false, cablestart: tempSegment.position === 0, cableend: tempSegment.position === tempSegments.length - 1 };
            features.push(...FeatureFactory.createSegmentFeatures(tempSegment, extraInfo, InteractableFeatureType.SCHRODINGER_SEGMENT, elements, paths, resolution));
        });
        for (const build of builds) {
            const buildSegments = segments.filter((s) => s.buildId === build.id).sort((s1, s2) => s1.id - s2.id);
            buildSegments.forEach((segment) => {
                const isConfigured = build.partNumber !== undefined && build.partNumber?.length > 0;
                const locked = this.isBuildLocked(build);
                const extraInfo = { isConfigured, locked, cablestart: segment.position === 0, cableend: segment.position === buildSegments.length - 1, cableColorHex: build.cableColorHex };
                features.push(...FeatureFactory.createSegmentFeatures(segment, extraInfo, InteractableFeatureType.SCHRODINGER_SEGMENT, elements, paths, resolution));
            });
        }
        return features;
    }

    public static createBulkSegmentFeatures(builds: BulkBuild[], segments: BuildSegment[], elements: Element[], paths: Path[], resolution: number | undefined): Feature[] {
        if (!elements.length) {
            return [];
        }
        const features: Feature[] = [];
        // segments with id -3 are temporary Bulk segments
        const tempSegments = segments.filter((s) => s.id === -3).sort((s1, s2) => s1.id - s2.id);
        tempSegments.forEach((tempSegment) => {
            const extraInfo = { fiberCount: -1, cablestart: tempSegment.position === 0, cableend: tempSegment.position === tempSegments.length - 1 };
            features.push(...FeatureFactory.createSegmentFeatures(tempSegment, extraInfo, InteractableFeatureType.BULK_SEGMENT, elements, paths, resolution));
        });
        for (const build of builds) {
            const locked = this.isBuildLocked(build);
            const buildSegments = segments.filter((s) => s.buildId === build.id).sort((s1, s2) => s1.id - s2.id);
            buildSegments.forEach((segment) => {
                const fromElement = elements.find(e => e.id === segment.fromId);
                const extraInfo = { cableColorHex: build.cableColorHex, locked, cablestart: segment.position === 0, cableend: segment.position === buildSegments.length - 1, startsAtCabinet: fromElement?.type === ElementType.Cabinet, fieldSlack: build.fieldSlack };
                features.push(...FeatureFactory.createSegmentFeatures(segment, extraInfo, InteractableFeatureType.BULK_SEGMENT, elements, paths, resolution));
            });
        }
        return features;
    }

    private static createSegmentFeatures(segment: BuildSegment, extraInfo: any, type: InteractableFeatureType, elements: Element[], paths: Path[], resolution: number | undefined): Feature[] {
        const from = elements.find((p) => p.id === segment.fromId);
        const to = elements.find((p) => p.id === segment.toId);

        if (!from && !to) {
            console.warn('Adding builds layer, unable to find element associated to temp segment');
            return [];
        }
        if (!segment.from || !segment.to) {
            console.warn('Adding builds layer, unable to find element associated to temp segment');
            return [];
        }
        if (!from){
            store.dispatch(loadMissingElement(segment.from));
        }
        else if (!to){
            store.dispatch(loadMissingElement(segment.to));
        } 

        const path = FeatureFactory.getSegmentPath(segment, paths);
        if (path) {
            let route = path.route;
            if (path.fromElementId === segment.to.id || path.toElementId === segment.from.id) {
                route = [...path.route].reverse();
            }
            return FeatureFactory.createBuildPath(segment, route, type, resolution, extraInfo);
        }
        return FeatureFactory.createBuildSegment(segment, segment.from, segment.to, type, resolution, extraInfo);
    }

    public static getSegmentPath(segment: BuildSegment | TerminalSegment, paths: Path[]): Path | undefined {
        return paths.find((p) => p.fromElementId === segment.fromId && p.toElementId === segment.toId)
            || paths.find((p) => p.fromElementId === segment.toId && p.toElementId === segment.fromId);
        // bidirectional
    }

    public static createTerminalFeatures(naps: Nap[], taps: Tap[], tethers: Tether[], builds: FlexNapBuild[], elements: Element[], resolution: number | undefined): Feature[] {
        const tethersWithTerminals = tethers.filter((t) => t.terminal);
        const tetherFeatures: Feature[] = [];

        for (const element of elements) {
            const nap = naps.find((n) => n.elementId === element.id);
            if (!nap) {
                continue;
            }

            const isParentBuildLocked = !!nap.buildId && this.isBuildLocked(builds.find(b => b.id === nap.buildId));
            const locked = !!nap.lockedById || !!builds.filter(b => !!b.pretermLateral).find(b => b.pretermLateral?.parentNapId === nap.id) || isParentBuildLocked;
            const poleTaps = taps.filter((t) => t.napId === nap.id);

            for (const tap of poleTaps) {
                const tapTethers = tethersWithTerminals.filter((t) => t.tapId === tap.id);
                for (const tether of tapTethers) {
                    const elementIdWithExtendedTerminal = tether.terminal?.terminalExtension?.elementId;
                    const elementWithExtendedTerminal = elements.find(e => e.id === elementIdWithExtendedTerminal);
                    if (elementIdWithExtendedTerminal && !elements.find(e => e.id === elementIdWithExtendedTerminal)) {
                        break; // terminal is extended to an element that is not loaded on the map. Do not create a feature for it
                    }
                    tetherFeatures.push(FeatureFactory.createTerminalForElement(nap, tap, tether, elementWithExtendedTerminal ?? element, resolution, locked));
                }
            }
        }
        return tetherFeatures;
    }

    public static createTerminalSegmentFeatures(tethers: Tether[], elements: Element[], paths: Path[], buildSegments: BuildSegment[], taps: Tap[], naps: Nap[], builds: FlexNapBuild[], resolution: number | undefined): Feature[] {
        const tethersWithTerminalSegments = tethers.filter(t => !!t.terminal?.terminalExtension?.segments);
        const tetherSegmentFeatures: Feature[] = [];

        for (const nap of naps) {
            const isParentBuildLocked = !!nap.buildId && this.isBuildLocked(builds.find(b => b.id === nap.buildId));
            const locked = !!nap.lockedById || isParentBuildLocked;
            const poleTaps = taps.filter((t) => t.napId === nap.id);

            for (const tap of poleTaps) {
                const tapTerminals = tethersWithTerminalSegments.filter((t) => t.tapId === tap.id).map(t => t.terminal);
                for (const terminal of tapTerminals) {
                    tetherSegmentFeatures.push(...FeatureFactory.createTerminalSegments(terminal?.terminalExtension?.segments, terminal, elements, paths, buildSegments, resolution, locked));
                }
            }
        }

        return tetherSegmentFeatures;
    }

    private static createTerminalSegments(segments: TerminalSegment[] | undefined, terminal: Terminal | undefined, elements: Element[], paths: Path[], buildSegments: BuildSegment[], resolution: number | undefined, locked: boolean | undefined): Feature[] {
        if (!segments || !terminal) {
            return [];
        }

        const features: Feature[] = [];
        const dist = (!resolution || resolution < RESOLUTION_MEDIUM) ? 0.7 : 2;

        segments.forEach(s => {
            const from = elements.find(e => e.id === s.fromId);
            const to = elements.find(e => e.id === s.toId);
            if (from && to) {
                const path = FeatureFactory.getSegmentPath(s, paths);
                let route: PointLike[] | undefined = undefined;
                if (path) {
                    route = path.route;
                    if (path.fromElementId === to.id || path.toElementId === from.id) {
                        route = [...path.route].reverse();
                    }
                }

                const featureProperties = {
                    id: s.id,
                    position: s.position,
                    firstSegment: s.position === 0,
                    lastSegment: s.position === (segments.length - 1),
                    terminalId: terminal.id,
                    extensionType: terminal.terminalExtension?.terminalExtensionType,
                    locked
                };
                const feature = route ?
                    this.createLineStringFeature(route, InteractableFeatureType.TERMINAL_SEGMENT, resolution, featureProperties) :
                    this.createLineFeature(from, to, InteractableFeatureType.TERMINAL_SEGMENT, resolution, featureProperties);

                if (buildSegments.find(b => (b.fromId === s.fromId && b.toId === s.toId) || (b.fromId === s.toId && b.toId === s.fromId))) {
                    const newPoint = OpenlayerUtility.computePerpendicularPoint([to.x, to.y], [from.x, from.y], dist);
                    const geometry = feature.getGeometry();
                    if (feature && geometry) {
                        geometry.translate(from.x - newPoint[0], from.y - newPoint[1]);
                    }
                }
                features.push(feature);
            }
        });

        return features;
    }

    public static createNapFeatures(naps: Nap[], taps: Tap[], tethers: Tether[], builds: FlexNapBuild[], elements: Element[], resolution: number | undefined): Feature[] {
        const features: Feature[] = [];
        for (const element of elements) {
            const nap = naps.find((n) => n.elementId === element.id);
            if (!nap) {
                continue;
            }
            const parentBuildLocked = this.isBuildLocked(builds.find(b => b.id === nap.buildId));
            const locked = !!nap.lockedById || parentBuildLocked;
            const poleTaps = taps.filter((t) => t.napId === nap.id);
            const poleTapIds = poleTaps.map((t) => t.id);
            const tapTethers = tethers.filter((t) => poleTapIds.includes(t.tapId));
            const napFeature = FeatureFactory.createNapFeatureForElement(nap, tapTethers, element, resolution, locked);
            features.push(napFeature);
        }
        return features;
    }

    public static createTapFeatures(naps: Nap[], taps: Tap[], tethers: Tether[], elements: Element[], flexNapBuilds: FlexNapBuild[], resolution: number | undefined): Feature[] {
        const tapFeatures: Feature[] = [];
        for (const element of elements) {
            const nap = naps.find((n) => n.elementId === element.id);
            if (!nap) {
                continue;
            }
            const locked = !!nap.lockedById;
            const napTaps = taps.filter((t) => t.napId === nap.id);
            for (const tap of napTaps) {
                const tapTethers = tethers.filter((t) => t.tapId === tap.id).sort((a, b) => a.tetherIndex - b.tetherIndex);
                tapFeatures.push(FeatureFactory.createTapFeatureForElement(nap, tap, tapTethers, element, resolution, locked));
            }
        }
        return tapFeatures;
    }

    public static createPortNumberFeatures(elements: Element[], flexNapPortNumbers: FlexNapPortNumbersByElement | undefined, resolution: number | undefined): Feature[] {
        if (!resolution || resolution > RESOLUTION_PRECISE || !flexNapPortNumbers) {
            return [];
        }
        return elements.reduce((features: Feature[], element) => {
            const portRange = flexNapPortNumbers[element.id]

            if (!portRange) {
                return features;
            }

            features.push(FeatureFactory.createPortNumberFeatureForElement(element, portRange, resolution));
            return features;
        }, []);
    }

    public static createSplicePointFeatures(splicePoints: SplicePoint[], builds: BulkBuild[], elements: Element[], resolution: number | undefined): Feature[] {
        const features: Feature[] = [];
        for (const element of elements) {
            const splicePoint = splicePoints.find(s => s.elementId === element.id);
            if (!splicePoint) {
                continue;
            }
            const parentBuildLocked = this.isBuildLocked(builds.find(b => b.id === splicePoint.buildId));
            const locked = !!splicePoint.lockedById || parentBuildLocked;
            const splicePointFeature = FeatureFactory.createSplicePointFeatureForElement(splicePoint, element, resolution, locked);
            features.push(splicePointFeature);
        }

        return features;
    }

    public static createGeoProject(project: GeoProject, resolution: number | undefined): Feature {
        return this.createPolygonFeature([project.coordinates], StaticFeatureType.GEOPROJECT, resolution, { id: project.id });
    }

    public static createPole(pole: Pole, resolution: number | undefined): Feature {
        return this.createPoweredElement(pole, InteractableFeatureType.POLE, resolution);
    }

    public static createParcel(parcel: Parcel, resolution: number | undefined): Feature {
        const units = parcel.totalUnits;
        return this.createElement(parcel, InteractableFeatureType.PARCEL, resolution, { units });
    }

    public static createCabinet(cabinet: Cabinet, resolution: number | undefined): Feature {
        return this.createPoweredElement(cabinet, InteractableFeatureType.CABINET, resolution);
    }

    public static createHandhole(handhole: Handhole, resolution: number | undefined): Feature {
        return this.createPoweredElement(handhole, InteractableFeatureType.HANDHOLE, resolution);
    }

    public static createManhole(manhole: Manhole, resolution: number | undefined): Feature {
        return this.createPoweredElement(manhole, InteractableFeatureType.MANHOLE, resolution);
    }

    public static createVault(vault: Vault, resolution: number | undefined): Feature {
        return this.createPoweredElement(vault, InteractableFeatureType.VAULT, resolution);
    }

    public static createPoweredElement(element: Element, featureType: InteractableFeatureType, resolution: number | undefined, extraInfo?: any): Feature {
        const powerStatus = (element as any).powerStatus;
        if (powerStatus === undefined) {
            throw new Error('Trying to create power element feature from non-powered element.');
        }

        return this.createElement(element, featureType, resolution, { ...extraInfo, powerStatus });
    }

    public static createElement(element: Element, featureType: InteractableFeatureType, resolution: number | undefined, extraInfo?: any): Feature {
        const createLayerMethod = LayerFactory.getCreateElementLayerMethod(featureType);
        const drawLineOptions: DragInteractionFeatureOptions | undefined = createLayerMethod && {
            originCoordinates: [element.x, element.y],
            createLayerMethod,
            interactableFeatureType: featureType,
            drawGhostFeature: true,
        };
        const feature = this.createPointFeature(element, featureType, resolution, { id: element.id, isImported: element.isImported, drawLineOptions, ...extraInfo });
        return feature;
    }

    public static createPath(path: Path, featureType: InteractableFeatureType, resolution: number | undefined): Feature {
        const feature = this.createLineStringFeature(path.route, featureType, resolution, { id: path.id, isImported: path.isImported });
        return feature;
    }

    public static createTapSchrodingerFeatures(taps: TapSchrodinger[], tethers: TetherSchrodinger[], builds: SchrodingerBuild[], elements: Element[], resolution: number | undefined): Feature[] {
        const features: Feature[] = [];
        for (const element of elements) {
            const tap = taps.find((s) => s.elementId === element.id);
            if (!tap) {
                continue;
            }
            const parentBuildLocked = this.isBuildLocked(builds.find(b => b.id === tap.buildId));
            const tapTethers = tethers.filter((t) => t.tapId === tap.id);
            const powerTethers = tapTethers.filter((t) => t.type === TetherType.Power);
            const opticalTethers = tapTethers.filter((t) => t.type === TetherType.Optical) as OpticalTether[];
            const fiberCount = opticalTethers.map((s) => parseInt(s.fiberCountShortDescription ?? "0")).reduce((prv, fc) => prv + fc, 0);
            const extraInfo = { id: tap.id, elementId: element.id, buildId: tap.buildId, fiberCount, copperCount: powerTethers.length, tetherCount: tapTethers.length, locked: parentBuildLocked };
            features.push(this.createPointFeature(element, InteractableFeatureType.TAP_SCHRODINGER, resolution, extraInfo));
        }
        return features;
    }

    public static createTapPowerFeatures(taps: TapSchrodinger[], tethers: TetherSchrodinger[], elements: Element[], resolution: number | undefined): Feature[] {
        const features: Feature[] = [];
        for (const element of elements) {
            const tap = taps.find((t) => t.elementId === element.id);
            if (!tap) {
                continue;
            }
            const locked = !!tap.lockedById;
            const tapTethers = tethers.filter((t) => t.tapId === tap.id && t.type === TetherType.Power);
            const powerTethers = tapTethers as PowerTether[];
            const count = powerTethers.length;
            if (count > 0) {
                const hasEnoughPower = true; // TODO get hasEnoughPower value from SSC
                features.push(FeatureFactory.createPowerForElement(tap, element, hasEnoughPower, `${count}`, resolution, locked));
                const totalPower = powerTethers.reduce((a, t) => a + parseInt(t.maximumLoadShortDescription?.replace(",", "") ?? "0"), 0);
                const totalPowerText = NumberFormatter.formatSigFigs(totalPower, ElectricalUnits.watts, 3);
                features.push(FeatureFactory.createPowerLabelForElement(element, totalPowerText, resolution));
            }
        }

        return features;
    }

    public static createTapOpticalFeatures(taps: TapSchrodinger[], tethers: TetherSchrodinger[], elements: Element[], resolution: number | undefined): Feature[] {
        const features: Feature[] = [];

        for (const element of elements) {
            const tap = taps.find((t) => t.elementId === element.id);
            if (!tap) {
                continue;
            }
            const locked = !!tap.lockedById;
            const opticalTethers = tethers.filter((tether) => tether.type === TetherType.Optical && tether.tapId === tap.id) as OpticalTether[];
            if (opticalTethers.length > 0) {
                const isValid = true;
                features.push(FeatureFactory.createOpticalForElement(tap, element, isValid, `${opticalTethers.length}`, resolution, locked));
            }
        }

        return features;
    }

    public static createBuildSegment(segment: BuildSegment, from: PointLike, to: PointLike, featureType: InteractableFeatureType, resolution: number | undefined, extra: any): Feature[] {
        const text = `${segment.slackLoop}`;
        const extraInfo = { buildId: segment.buildId, id: segment.id, text, ...extra };
        const feature = this.createLineFeature(from, to, featureType, resolution, extraInfo);
        return [feature];
    }

    public static createBuildPath(segment: BuildSegment, pathRoute: PointLike[], featureType: InteractableFeatureType, resolution: number | undefined, extra: any): Feature[] {
        const text = `${segment.slackLoop}`;
        const extraInfo = { buildId: segment.buildId, id: segment.id, text, ...extra };
        const feature = this.createLineStringFeature(pathRoute, featureType, resolution, extraInfo);
        return [feature];
    }

    public static createNapFeatureForElement(nap: Nap, tethers: Tether[], element: Element, resolution: number | undefined, locked = false): Feature {
        const text = `${tethers.length}`;
        return this.createPointFeature(element, InteractableFeatureType.NAP, resolution, { text, id: nap.id, elementId: element.id, buildId: nap.buildId, locked });
    }

    public static createTapFeatureForElement(nap: Nap, tap: Tap, tethers: Tether[], element: Element, resolution: number | undefined, locked = false): Feature {
        const left = tap.tapIndex === 1;
        let firstTap = "0";
        let secondTap = "0";
        for (const tether of tethers) {
            if (tether.tetherIndex === 1) {
                firstTap = tether.fiberCount.toString();
            } else {
                secondTap = tether.fiberCount.toString();
            }
        }
        const text = `${firstTap}/${secondTap}`;
        const tetherIds = tethers.map((t) => t.id);
        const feature = this.createPointFeature(element, InteractableFeatureType.TAP, resolution, {
            text, elementId: element.id, buildId: nap.buildId, napId: nap.id, id: tap.id, tetherIds: tetherIds, left, locked
        });
        return feature;
    }

    public static createSplicePointFeatureForElement(splicePoint: SplicePoint, element: Element, resolution: number | undefined, locked = false): Feature {
        return this.createPointFeature(element, InteractableFeatureType.SPLICE_POINT, resolution, { id: splicePoint.id, elementId: element.id, buildId: splicePoint.buildId, locked });
    }

    public static createPortNumberFeatureForElement(element: Element, portNumberRange: PortNumberRange, resolution: number | undefined): Feature {
        const { start, end, fiberCount, cableColorHex } = portNumberRange;
        const text = `${start}-${end}`;
        return this.createPointFeature(element, StaticFeatureType.PORT_NUMBER, resolution, { elementId: element.id, text, fiberCount, cableColorHex });
    }

    public static createDropCablesForPoles(poles: Pole[], parcels: Parcel[], resolution: number | undefined): Feature[] {
        const dist = 25;
        const features: Feature[] = [];
        const poleParcels: { [key: number]: { pole: Pole; parcels: Parcel[] } } = {};

        for (const parcel of parcels) {
            let closestPole: Pole | null = null;
            let closestDistance = -1;
            for (const pole of poles) {
                const d = CableCalculationsHelper.getDistanceBetween(parcel, pole).value;
                if (d < closestDistance || closestDistance === -1) {
                    closestDistance = d;
                    closestPole = pole;
                }
            }
            if (!closestPole || closestDistance > dist) {
                continue;
            }
            if (!poleParcels[closestPole.id]) {
                poleParcels[closestPole.id] = { pole: closestPole, parcels: [] };
            }
            poleParcels[closestPole.id].parcels.push(parcel);
        }
        for (const ps of Object.values(poleParcels)) {
            const parcelPoints = ps.parcels.map((p) => [p.x, p.y]);
            // const polygonPoints = this.convexHull(parcelPoints); // polygon way
            // const p = this.createPolygonFeature([polygonPoints], StaticFeatureType.SERVING_AREA); // polygon way
            for (const pt of parcelPoints) { // segment way
                const seg = this.createLineFeature(ps.pole, { x: pt[0], y: pt[1] }, StaticFeatureType.DROP_CABLE, resolution); // segment way
                features.push(seg); // segment way
            } // segment way
            // features.push(p); // polygon way
        }
        return features;
    }

    public static createBuildStatusFeatures(builds: Build[], segments: BuildSegment[], elements: Element[], paths: Path[], resolution?: number | undefined): Feature[] {
        if (!elements.length || !builds.length) {
            return [];
        }
        const features: Feature[] = [];
        for (const build of builds) {
            let buildSegments = segments.filter((s) => s.buildId === build.id) || [];
            buildSegments = buildSegments.sort((a, b) => a.position - b.position);
            const points: PointLike[] = [];
            for (const segment of buildSegments) {
                const from = elements.find((p) => p.id === segment.fromId);
                const to = elements.find((p) => p.id === segment.toId);
                const path = FeatureFactory.getSegmentPath(segment, paths);

                if (!from || !to) {
                    console.warn('Adding lock layer, unable to find element associated to segment');
                    continue;
                }
                const segmentPoints: PointLike[] = path
                    ? path.route.flatMap((r) => [{ x: r.x, y: r.y }])
                    : [{ x: from.x, y: from.y }, { x: to.x, y: to.y }];
                points.push(...segmentPoints);
            }
            const paddedPoints = this.convertPoints(points);
            const statusIconPoint = this.computeStatusIconPoint(paddedPoints, resolution);
            const hull = OpenlayerUtility.calculateOutlineHull(paddedPoints, resolution, BASE_POINT_PADDING);

            if (!hull.length) {
                continue;
            }
            const polygon = this.createPolygonFeature([hull], StaticFeatureType.LOCK_OUTLINE, resolution);

            features.push(polygon);

            const featureType = this.getFeatureTypeFromBuildStatus(build);
            if (featureType) {
                const status = this.createPointFeature({ x: statusIconPoint[0], y: statusIconPoint[1] }, featureType, resolution, { id: build.id, buildId: build.id });
                features.push(status);
            }
        }
        return features;
    }

    private static getFeatureTypeFromBuildStatus(build: Build): FeatureType | undefined {
        if (build.type === BuildType.Schrodinger) {
            const schrodingerBuild = (build as SchrodingerBuild);
            if (schrodingerBuild.catalogCode)
                return StaticFeatureType.UPLOADED_BUILD;
            if (schrodingerBuild.lastReviewStatus === ReviewStatus.Approved)
                return StaticFeatureType.APPROVED_BUILD;
            if (schrodingerBuild.lastReviewStatus === ReviewStatus.Rejected)
                return InteractableFeatureType.REJECTED_BUILD;
            if (schrodingerBuild.lastReviewStatus === ReviewStatus.Open)
                return StaticFeatureType.IN_REVIEW_BUILD;
        }
        if (build.uploadedDateTime)
            return InteractableFeatureType.EXPORTED_BUILD;
        if (build.lockedById)
            return InteractableFeatureType.LOCKED_BUILD;
        return undefined;
    }

    private static convertPoints(points: PointLike[]): number[][] {
        const numberPoints: number[][] = [];
        for (const p of points) {
            numberPoints.push([p.x, p.y]);
        }
        return numberPoints;
    }

    private static computeStatusIconPoint(points: number[][], resolution: number | undefined): number[] {
        if (points.length < 2) {
            return [];
        }

        // Factors are arbitrarily chosen to best fit the look.
        if (resolution) {
            resolution = resolution < RESOLUTION_CLOSE ? 100 * resolution : 35 * resolution;
        }

        const lastPoint = points[(points.length - 1)];
        const beforeLastPoint = points[(points.length - 2)];

        const x = lastPoint[0] - beforeLastPoint[0];
        const y = lastPoint[1] - beforeLastPoint[1];

        let directionalVector: number[] = [];
        directionalVector.push(x, y);
        directionalVector = OpenlayerUtility.normalize(directionalVector);
        return OpenlayerUtility.applyVectorToPoint(directionalVector, lastPoint, resolution ?? 1);
    }

    public static createTerminalForElement(nap: Nap, tap: Tap, tether: Tether, element: Element, resolution: number | undefined, locked = false): Feature {
        const terminal = tether.terminal;
        if (!terminal) {
            throw new Error('Trying to create terminal for tether without a terminal');
        }

        const top = tether.tetherIndex === 1;
        const left = tap.tapIndex === 1;
        const middle = !!terminal.terminalExtension;
        const dragInteractionLFeatureOptions: DragInteractionFeatureOptions = {
            originCoordinates: [element.x, element.y],
            createLayerMethod: LayerFactory.createTerminalLayer,
            interactableFeatureType: InteractableFeatureType.TERMINAL,
            extraLineFeatureOptions: {
                extensionType: terminal.terminalExtension?.terminalExtensionType
            },
            drawGhostFeature: false,
        };

        return this.createPointFeature(element, InteractableFeatureType.TERMINAL, resolution, {
            text: terminal.portCount,
            elementId: element.id,
            buildId: nap.buildId,
            napId: nap.id,
            tetherId: tether.id,
            id: terminal.id,
            top,
            left,
            middle,
            extensionType: terminal.terminalExtension?.terminalExtensionType,
            locked, drawLineOptions: dragInteractionLFeatureOptions
        });
    }

    public static createPowerForElement(tap: TapSchrodinger, element: Element, hasEnoughPower: boolean, text: string, resolution: number | undefined, locked = false): Feature {
        const feature = this.createPointFeature(element, InteractableFeatureType.POWER_SCHRODINGER, resolution, { elementId: element.id, id: tap.id, hasEnoughPower, text, locked });
        return feature;
    }

    public static createPowerLabelForElement(element: Element, text, resolution: number | undefined): Feature {
        const feature = this.createPointFeature(element, InteractableFeatureType.POWER_LABEL_SCHRODINGER, resolution, { text });
        return feature;
    }

    public static createOpticalForElement(tap: TapSchrodinger, element: Element, isValid: boolean, text: string, resolution: number | undefined, locked = false): Feature {
        const feature = this.createPointFeature(element, InteractableFeatureType.OPTICAL_SCHRODINGER, resolution, { elementId: element.id, id: tap.id, isValid, text, locked });
        return feature;
    }

    public static createShadowForElement(element: Element, featureType: StaticFeatureType, resolution: number | undefined, extra: any): Feature {
        const extraInfo = { id: element.id, ...extra };
        return this.createPointFeature(element, featureType, resolution, extraInfo);
    }

    public static createShadowForSegment(segment: BuildSegment, from: PointLike, to: PointLike, featureType: StaticFeatureType, resolution: number | undefined, extra: any): Feature {
        const extraInfo = { buildId: segment.buildId, id: segment.id, ...extra };
        return this.createLineFeature(from, to, featureType, resolution, extraInfo);
    }

    public static createShadowForPath(path: Path, featureType: StaticFeatureType, resolution: number | undefined, extra: any): Feature {
        return this.createLineStringFeature(path.route, featureType, resolution, extra);
    }

    public static createInfrastructureIdFeatures(elements: Element[], resolution: number | undefined): Feature[] {
        if (!resolution || resolution > RESOLUTION_PRECISE) {
            return [];
        }
        const features: Feature[] = elements.map((e) => this.createPointFeature(e, StaticFeatureType.INFRASTRUCTURE_ID, resolution, { id: e.id, tag: e.tagOverride ?? e.tag, elementType: e.type }));
        return features;
    }

    public static createBuildCalloutFeatures(builds: Build[], segments: BuildSegment[], elements: Element[], paths: Path[], resolution: number | undefined): Feature[] {
        let buildsDictionary: { [id: number]: Build } = builds.reduce((dictionary, build) => {
            dictionary[build.id] = build;
            return dictionary;
        }, {});
        let elementsDictionary: { [id: number]: Element } = elements.reduce((dictionary, element) => {
            dictionary[element.id] = element;
            return dictionary;
        }, {});

        let buildCabinetMapping: { [buildId: number]: boolean } = {};

        const features: Feature[] = [];
        for (const s of segments) {
            const { buildId, fromId, toId, position, id } = s;
            const build = buildsDictionary[buildId];
            if (!build) continue;

            const fromElement: Element | undefined = fromId ? elementsDictionary[fromId] : undefined;
            const toElement: Element | undefined = toId ? elementsDictionary[toId] : undefined;

            if (fromElement?.type === ElementType.Cabinet) {
                buildCabinetMapping[build.id] = true;
            }
            else if (toElement?.type === ElementType.Cabinet) {
                buildCabinetMapping[build.id] = true;
            }

            if ((position + 1) % 3 !== 0 || !fromElement || !toElement) continue;

            let from: PointLike = fromElement;
            let to: PointLike = toElement;

            const path = FeatureFactory.getSegmentPath(s, paths);
            if (path) {
                let firstPathSegment = path.route;
                if (path.fromElementId === toElement.id || path.toElementId === fromElement.id) {
                    firstPathSegment = [...path.route].reverse();
                }
                [from, to] = firstPathSegment;
            }

            const connectedToCabinet = !!buildCabinetMapping[build.id];

            const dx = to.x - from.x;
            const dy = to.y - from.y;
            const angle = Math.atan2(-dy, dx) + (dx > 0 ? 0 : Math.PI);
            const useAlt = dx === 0 ? dy > 0 : dx <= 0;
            const fiberCount = build.type === BuildType.FlexNap ?
                (build as FlexNapBuild).fiberCount
                :
                build.type === BuildType.Bulk ?
                    (build as BulkBuild).fiberCount
                    :
                    undefined;
            if (build.type !== BuildType.Schrodinger) {
                const extraInfo = { id, fiberCount, cableColorHex: build.cableColorHex, useAlt, angle, buildType: this.getBuildTypeLabel(build.type), connectedToCabinet };
                const feature = this.createLineFeature(from, to, StaticFeatureType.BUILD_CALLOUT, resolution, extraInfo);
                features.push(feature);
            }
        }

        buildsDictionary = {};
        elementsDictionary = {};
        buildCabinetMapping = {};

        return features;
    }

    public static createCabinetCalloutFeatures(cabinets: Cabinet[], resolution: number | undefined): Feature[] {
        const features: Feature[] = cabinets.map((c) => this.createPointFeature(c, StaticFeatureType.CABINET_CALLOUT, resolution, { id: c.id, portCount: c.portCount }));
        return features;
    }

    public static createBuildIdsFeatures(segments: BuildSegment[], elements: Element[], builds: Build[], resolution: number): Feature[] {
        const features: Feature[] = [];
        const idDisplayInfo: { [buildId: number]: { id: string; fiberCount?: number; cableColorHex: string } } = {};

        // Creating information to be displayed on callout for all builds
        for (const build of builds) {
            if (build.type === BuildType.FlexNap) {
                const flexnapBuild = build as FlexNapBuild;
                const buildId = build.id.toString();
                const hybrisId = flexnapBuild.hybrisCcsBuildId;

                let displayId = hybrisId ? hybrisId : `${i18next.t(LocalizationKeys.Build)} ${buildId}`;
                if (hybrisId && hybrisId.length > 9) {
                    displayId = `${hybrisId.substring(0, 3)}...${hybrisId.substring(hybrisId.length - 5, hybrisId.length)}`;
                }
                else if (buildId.length > 4) {
                    displayId = `${i18next.t(LocalizationKeys.Build)} ...${buildId.substring(buildId.length - 4, buildId.length)}`;
                }

                idDisplayInfo[build.id] = {
                    id: displayId,
                    fiberCount: flexnapBuild.fiberCount,
                    cableColorHex: flexnapBuild.cableColorHex
                };
            }
            else if (build.type === BuildType.Schrodinger) {
                const schrodingerBuild = build as SchrodingerBuild;
                const displayId = BuilderHelper.getBuildDisplayId(build);

                idDisplayInfo[build.id] = {
                    id: displayId,
                    fiberCount: undefined,
                    cableColorHex: schrodingerBuild.cableColorHex
                };
            }
            else {
                idDisplayInfo[build.id] = {
                    id: `${i18next.t(LocalizationKeys.Build)} ${build.id.toString()}`,
                    fiberCount: undefined,
                    cableColorHex: build.cableColorHex
                };
            }
        }

        // Resolution is used as a scalefactor to determine how far the callout is placed relative to builds.
        if (resolution) {
            resolution = resolution < (RESOLUTION_CLOSE - 0.5) ? 100 * resolution : 35 * resolution;
        }

        // Creating callouts and grouping
        const headSegments = segments.filter(s => s.position === 0);
        const segmentLocationDictionary: { [segmentId: number]: { point: PointLike; buildId: number } } = {};
        for (const segment of headSegments) {
            const fromElement = elements.find(e => e.id === segment.fromId);
            const toElement = elements.find(e => e.id === segment.toId);
            if (fromElement && toElement) {
                // Compute vector used to find placement of build id callout
                const displacementVector = [fromElement.x - toElement.x, fromElement.y - toElement.y];
                const unitVector = OpenlayerUtility.normalize(displacementVector);
                const tagLocation = OpenlayerUtility.applyVectorToPoint(unitVector, [fromElement.x, fromElement.y], resolution);
                segmentLocationDictionary[segment.id] = {
                    point: { x: tagLocation[0], y: tagLocation[1] },
                    buildId: segment.buildId
                };
            }
        }

        // Group the different head segments to be displayed in grouped or individual callouts using bottom-up clustering. Every head segment starts as a singleton cluster, and are
        // merged progressively until none can be merged (based on distance). Every resulting cluster is turned into a callout.
        const clusters = OpenlayerUtility.clusterSegmentHeads(segmentLocationDictionary);
        for (const cluster of clusters) {
            const buildIdsInCluster = cluster.buildIds;
            const extraInfoForCluster = {
                buildIdTags: buildIdsInCluster.map(i => idDisplayInfo[i])
            }
            // The callout is placed at the location of the head segment contained in the initial singleton cluster (before any merging occured). 
            features.push(this.createPointFeature(cluster.initialLocation, StaticFeatureType.BUILD_ID, resolution, extraInfoForCluster))
        }
        return features;
    }

    private static createPointFeature(point: PointLike, type: FeatureType, resolution: number | undefined, extraInfo?: any) {
        const feature = this.createFeature(this.createPoint(point), type, resolution, extraInfo);
        return feature;
    }

    private static createLineFeature(from: PointLike, to: PointLike, type: FeatureType, resolution: number | undefined, extraInfo?: any) {
        const feature = this.createFeature(this.createLine(from, to), type, resolution, extraInfo);
        return feature;
    }

    private static createLineStringFeature(route: PointLike[], type: FeatureType, resolution: number | undefined, extraInfo?: any) {
        const feature = this.createFeature(this.createLineString(route), type, resolution, extraInfo);
        return feature;
    }

    private static createPolygonFeature(vertices: number[] | number[][][], type: FeatureType, resolution: number | undefined, extraInfo?: any): Feature {
        const polygon = new Polygon(vertices);
        const feature = this.createFeature(polygon, type, resolution, extraInfo);
        return feature;
    }

    private static createFeature(geometry: Geometry, type: FeatureType, resolution: number | undefined, extraInfo?: any): Feature {
        const feature = new Feature({
            geometry,
            type,
            selectable: type in InteractableFeatureType,
            ...extraInfo,
        });
        feature.setStyle(OpenlayerStyleFactory.createFeatureStyleFunction(feature, resolution ?? 1));
        return feature;
    }

    private static createPoint(point: PointLike): Point {
        return new Point([point.x, point.y]);
    }

    private static createLine(from: PointLike, to: PointLike): LineString {
        return new LineString([[from.x, from.y], [to.x, to.y]]);
    }

    private static createLineString(route: PointLike[]): LineString {
        return new LineString(route.map((r) => [r.x, r.y]));
    }

    private static getBuildTypeLabel(type: BuildType): string {
        switch (type) {
            case BuildType.FlexNap:
                return "FNAP";
            case BuildType.Schrodinger:
                return "FRDAY";
            case BuildType.Bulk:
                return "BULK";
            default:
                return "";
        }
    }
}
