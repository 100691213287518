import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../locales/types';
import { ToolItem } from '../ui-elements/tool-item';
import { useZoomToolComponent } from './zoom-tool.hooks';
import { ZoomToolProps } from './zoom-tool.types';

export const ZoomTool = (props:ZoomToolProps) => {
    const { t } = useTranslation()
    const { zoomIn, zoomOut } = useZoomToolComponent(props)

    return (
        <div className="controls-container map-control-container">
            <ToolItem source={<img src={require('./icons/icon_plus.svg')} alt="Zoom in" />} onClick={zoomIn} tooltip={t(LocalizationKeys.ZoomIn).toLowerCase()} />
            <ToolItem source={<img src={require('./icons/icon_minus.svg')} alt="Zoom out" />} onClick={zoomOut} tooltip={t(LocalizationKeys.ZoomOut).toLowerCase()} />
        </div>
    );
}


