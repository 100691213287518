import { LocalizationKeys } from '../../../../locales/types';
import { Cstic } from '../../../../models/cstic';
import { ProgressData } from '../../../ui-elements/progress-indicator/progress-indicator.types';
import { PresetWizardCstics, PresetWizardSteps } from '../preset-config/preset-config.types';

export interface PresetCsticProps {
    cstic?: Cstic;
    progressData: ProgressData[];
}

export interface PresetCsticGroupProps {
    step: PresetWizardSteps;
    label: string;
    cstics?: Cstic[];
    progressData: ProgressData[];
    goToNextStep: () => void;
    goToPreviousStep: () => void;
}

export interface PresetCsticOverride {
    label: string;
    order: number;
}

export const PresetCsticOverrides: Record<string, PresetCsticOverride> = {
    [PresetWizardCstics.POWER_MAXIMUM]: { label: LocalizationKeys.MaximumLoad, order: 1 },
    [PresetWizardCstics.CONDUCTOR_TYPE_CODE]: { label: LocalizationKeys.WireSize, order: 2 },
    [PresetWizardCstics.NUMBER_OF_CONDUCTOR_PAIRS]: { label: LocalizationKeys.Pairs, order: 3 },
    [PresetWizardCstics.POWER_UNIT_CODE]: { label: LocalizationKeys.Downconverter, order: 5 },

    [PresetWizardCstics.TETHER_FIBER_COUNT]: { label: LocalizationKeys.FiberCount, order: 1 },
    [PresetWizardCstics.CONNECTOR_TYPE_CODE]: { label: LocalizationKeys.ConnectorType, order: 2 },

    [PresetWizardCstics.LEG_LENGTH]: { label: LocalizationKeys.Length, order: 4 }
}