import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { DialogComponent } from '../dialog.component';
import { LocalizationKeys } from '../../../locales/types';
import { WorkspaceDeleteDialogProps } from './workspace-dialog.types';
import { useWorkspaceDeleteDialog } from './workspace-delete-hooks';

import './workspace-delete-dialog.scss';

export const WorkspaceDeleteDialog = (props: WorkspaceDeleteDialogProps) => {
    const { t } = useTranslation();
    const { open, onClose, onCancel, onDelete, workspaceName } = useWorkspaceDeleteDialog(props);
    return (
        ReactDOM.createPortal(
            <DialogComponent
                open={open}
                positiveTitle={t(LocalizationKeys.Delete)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onClose={onClose}
                onNegativeClick={onCancel}
                onPositiveClick={onDelete}
                className="workspace-delete"
                negativeStyleButton
            >
                <div className="workspace-delete-container">
                    <div className="workspace-delete-message-info">
                        {t(LocalizationKeys.WorkspaceDeleteMessageInfo)}
                    </div>
                    <div className="workspace-delete-message-container">
                        {t(LocalizationKeys.Delete)}
                        <div className="workspace-message-workspace-name">
                            {workspaceName}
                        </div>
                        {t(LocalizationKeys.QuestionMark)}
                    </div>
                </div>
            </DialogComponent>
            , document.getElementById('react-app-root')!)
    );
}