import './download-gis-data-dialog.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { DialogType } from '../../../redux/overlay.state';
import { DialogComponent } from '../dialog.component';
import { useDownloadGisDataDialog } from './download-gis-data-dialog.hooks';
import { DownloadLayersContext, LayerKey } from './download-gis-data-dialog.types';
import { DownloadLayerRow } from './download-layer-row';

export const DownloadGisDataDialog = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        dialog,
        includeLayers,
        buildId,
        buildIds,
        designAreaCount,
        designAreaNames,
        onLayerClick,
        onCloseClick,
        onDownloadClick,
    } = useDownloadGisDataDialog();

    return (
        <DialogComponent
            open={dialog === DialogType.DownloadGisData}
            title={t(LocalizationKeys.DownloadGisData)}
            positiveTitle={t(LocalizationKeys.Download)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={onDownloadClick}
            onNegativeClick={onCloseClick}
            className="download-gis-data-dialog"
        >
            <div className="dialog-content">
                <DownloadLayersContext.Provider value={{ includeLayers, onLayerClick }}>
                    {!!designAreaCount &&
                        <div className="download-design-area">
                            <DownloadLayerRow layerKey={LayerKey.DesignArea} labelText={t(LocalizationKeys.DesignArea)} />
                            <DownloadLayerRow layerKey={LayerKey.DAInfrastructure} labelText={t(LocalizationKeys.IncludeDesignAreaInfrastructure)} />
                        </div>
                    }
                    <div className="download-layer-column-container">
                        <div className="download-layer-column">
                            <Typography variant="body1" label={t(LocalizationKeys.FlexnapAndPreterm)} />
                            <DownloadLayerRow layerKey={LayerKey.FlexnapCablePath} labelText={t(LocalizationKeys.CablePath)} />
                            <DownloadLayerRow layerKey={LayerKey.Nap} labelText={t(LocalizationKeys.NAPs)} />
                            <DownloadLayerRow layerKey={LayerKey.FlexnapSplicePlan} labelText={t(LocalizationKeys.SplicePlan)} />
                        </div>
                        <div className="download-layer-column">
                            <Typography variant="body1" label={t(LocalizationKeys.Bulk)} />
                            <DownloadLayerRow layerKey={LayerKey.BulkCablePath} labelText={t(LocalizationKeys.CablePath)} />
                            <DownloadLayerRow layerKey={LayerKey.SplicePoint} labelText={t(LocalizationKeys.SplicePoints)} />
                            <DownloadLayerRow layerKey={LayerKey.BulkSplicePlan} labelText={t(LocalizationKeys.SplicePlan)} />
                        </div>
                        <div className="download-layer-column">
                            <Typography variant="body1" label={t(LocalizationKeys.InfrastructureStar)} />
                            <DownloadLayerRow layerKey={LayerKey.Pole} labelText={t(LocalizationKeys.Pole)} />
                            <DownloadLayerRow layerKey={LayerKey.ManholeHandholeVault} labelText={t(LocalizationKeys.ManholeHandholeVault)} />
                            <DownloadLayerRow layerKey={LayerKey.TrenchBore} labelText={t(LocalizationKeys.TrenchBore)} />
                            <DownloadLayerRow layerKey={LayerKey.Cabinet} labelText={t(LocalizationKeys.Cabinet)} />
                        </div>
                    </div>
                </DownloadLayersContext.Provider>
                <span className="footnote">
                    {t(designAreaCount ? LocalizationKeys.DownloadInfrastructureFootnoteDesignArea : LocalizationKeys.DownloadInfrastructureFootnoteBuild)}
                </span>
                <div className="summary-row-container">
                    <h2>{t(LocalizationKeys.Summary)}</h2>
                    <div className="summary-row">
                        {designAreaCount > 1 && <span>{`${t(LocalizationKeys.DesignAreas)}: ${designAreaNames.join(', ')}`}</span>}
                        {designAreaCount === 1 && designAreaNames[0] && <span>{`${t(LocalizationKeys.DesignArea)}: ${designAreaNames[0]}`}</span>}
                        {buildIds?.length > 0 && <span>{`${t(LocalizationKeys.BuildS)}: ${[...buildIds].sort().join(', ')}`}</span>}
                        {!buildIds?.length && buildId && <span>{`${t(LocalizationKeys.Build)}: ${buildId}`}</span>}
                    </div>
                </div>
            </div>
        </DialogComponent>
    );
}
