import './drawer-menu-body.scss';

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@mui/material';

import { LocalizationKeys } from '../../../../locales/types';
import { useDrawerMenuBody } from './drawer-menu-body.hooks';
import { BuildRow } from './row/build/build-row';
import { DesignAreaRow } from './row/design-area/design-area-row';

export const DrawerMenuBody = () => {
    const { t } = useTranslation();
    const { designAreas, unassignedBuilds, showUnassignedBuilds } = useDrawerMenuBody();
    return (
        <div className="drawer-menu-body">
            <div className="design-area-container">
                {
                    designAreas.map((d) => {
                        return (
                            <Fragment key={"fragment-" + d.id}>
                                <DesignAreaRow designArea={d} />
                                {designAreas.indexOf(d) !== designAreas.length - 1 ? <Divider variant="middle" /> : null}
                            </Fragment>
                        );
                    })
                }
            </div>
            {showUnassignedBuilds &&
                <>
                    <Divider />
                    <div className="unassigned-builds-container">
                        <div className="unassigned-builds-title">
                            {t(LocalizationKeys.NbUnassignedBuilds, { nbUnassignedBuilds: unassignedBuilds.length })}
                        </div>
                        {
                            unassignedBuilds.map((b) => {
                                return (
                                    <Fragment key={"fragment-" + b.id}>
                                        <BuildRow build={b} />
                                        {unassignedBuilds.indexOf(b) !== unassignedBuilds.length - 1 ? <Divider variant="middle" /> : null}
                                    </Fragment>
                                );
                            })
                        }
                    </div>
                </>
            }
            <Divider />
        </div>
    )
}