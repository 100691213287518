import React from 'react';

import { Sprite, Text } from '@inlet/react-pixi';

import { IFrameProps } from '../build.types';
import { useSlack } from './slack.hooks';
import { SlackProps } from './slack.types';

export const Slack: React.FC<IFrameProps & SlackProps> = (props) => {
    const {
        xPositionSegment,
        yPositionSegment,
        xPositionText,
        yPositionText,
        textStyle,
        anchor,
        anchorText,
        zIndex,
        image,
        spanText,
        outlineImage,
    } = useSlack(props);

    return (
        <>
            {outlineImage && (
                <Sprite
                    image={outlineImage}
                    x={xPositionSegment}
                    y={yPositionSegment}
                    anchor={anchor}
                    zIndex={zIndex - 4}
                />
            )}
            <Sprite
                image={image}
                x={xPositionSegment}
                y={yPositionSegment}
                anchor={anchor}
                zIndex={zIndex}
            />
            <Text
                text={spanText}
                x={xPositionText}
                y={yPositionText}
                anchor={anchorText}
                roundPixels={true}
                style={textStyle}
                zIndex={zIndex}
            />
        </>
    );
};
