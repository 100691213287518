import React from 'react';

export const BulkCableIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g>
                <path d="M0,0H24V24H0Z" fill="none" />
                <path d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z" fill="#005293" />
                <path d="M-549-86l2-2h10v2Z" transform="translate(557 107)" fill="#005293" />
            </g>
        </svg>
    );
};
