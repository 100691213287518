import { WebService, WebServiceErrorHandlingBehavior } from '../services/abstract-web-v2.service';
import { NotificationService } from '../services/notification.service';

type DispatchType = (action: any) => void;
type ActionType = ((dispatch: DispatchType) => any);

export const createSecuredAsyncAction = (action: ActionType) => (async (dispatch: DispatchType) => {
    /*const token = AuthenticationService.getToken();
    if (!token) {
        console.warn('Secured action detected missing token');
        dispatch(logout());
        return Promise.resolve();
    }
    if (token.isExpired()) {
        console.warn('Secured action detected expired token');
        NotificationService.info('Your session has expired, please log back in');
        dispatch(logout());
        return Promise.resolve();
    }*/
    return action(dispatch);
});

export const requestIsSuccess = async <T>(service: WebService, request: Promise<T>, notifyError = false): Promise<boolean> => {
    try {
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        await request;
        return true;
    } catch (error) {
        const _err = error as any;
        if (notifyError && _err?.response?.data) {
            const responseData = _err.response.data;
            if (responseData) {
                NotificationService.error(responseData);
            }
        }
        return false;
    }
};

export const customErrorRequest = async <T>(service: WebService, request: Promise<T>, customErrorMessage: string): Promise<T | undefined> => {
    try {
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        return await request;
    } catch (error) {
        NotificationService.error(customErrorMessage);
        return undefined;
    }
};
