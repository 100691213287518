import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsHelper } from '../../../helpers/analytics-helper';
import { FileHelper } from '../../../helpers/file-helper';
import { LocalizationKeys } from '../../../locales/types';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { dialogSelector, downloadSelector, flexnapBuildsSelector } from '../../../selectors/root.selectors';
import { ExportService } from '../../../services/export.service';
import { NotificationService } from '../../../services/notification.service';
import { DownloadGisDataDialogHooks, LayerKey } from './download-gis-data-dialog.types';

export const useDownloadGisDataDialog = (): DownloadGisDataDialogHooks => {
    const { t } = useTranslation();
    const dialog = useSelector(dialogSelector);
    const {
        buildIds,
        designAreaNames,
        designAreaIds
    } = useSelector(downloadSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const dispatch = useDispatch();

    const [includeLayers, setIncludeLayers] = useState(LayerKey.None);
    const [includeDAInfrastructure, setIncludeDAInfrastructure] = useState(true);

    const buildCount = buildIds.length;
    const buildId = buildIds[0];

    const formatBuildIds = useCallback((buildIds: number[]): string[] => {
        return buildIds.map(buildId => flexNapBuilds.find(b => b.id === buildId)?.hybrisCcsBuildId ?? `${t(LocalizationKeys.Build)} ${buildId}`);
    }, [flexNapBuilds, t]);
    
    const formatDesignAreaIds = useCallback((daIds: number[]): string[] => {
        return daIds.map(daId => `${t(LocalizationKeys.DesignArea)} ${daId}`);
    }, [t]);

    useEffect(() => {
        if (dialog === DialogType.DownloadGisData) {
            setIncludeLayers(
                (designAreaIds.length ? LayerKey.DesignArea : LayerKey.None) |
                LayerKey.FlexnapCablePath |
                LayerKey.Nap |
                LayerKey.FlexnapSplicePlan |
                LayerKey.Pole |
                LayerKey.ManholeHandholeVault |
                LayerKey.TrenchBore |
                LayerKey.Cabinet |
                LayerKey.BulkCablePath |
                LayerKey.BulkSplicePlan |
                LayerKey.SplicePoint | 
                LayerKey.DAInfrastructure
            );
        }
    }, [designAreaIds.length, dialog]);

    useEffect(() => {
        if (dialog === DialogType.DownloadGisData) {
            setIncludeDAInfrastructure(LayerKey.DAInfrastructure === (includeLayers & LayerKey.DAInfrastructure) && !!designAreaIds.length)
        }
    }, [designAreaIds.length, dialog, includeLayers]);

    const onLayerClick = (layerKey: LayerKey): void => {
        if (layerKey === (includeLayers & layerKey)) {
            setIncludeLayers(includeLayers & ~layerKey);
        }
        else {
            setIncludeLayers(includeLayers | layerKey);
        }
    };

    const onCloseClick = (): void => {
        dispatch(showDialog());
    };

    const onDownloadClick = useCallback(async (): Promise<void> => {
        const analyticsBuildIds = formatBuildIds(buildIds);
        const analyticsDesignAreaIds = formatDesignAreaIds(designAreaIds);
        AnalyticsHelper.pushBuildDownloadEvent(analyticsBuildIds, analyticsDesignAreaIds);

        try {
            const service = new ExportService();
            const fileResult = await service.getJsonFile({
                buildIds,
                entireDesignArea: includeDAInfrastructure,
                designAreaIds,
                includeLayers
            });
    
            if (fileResult) {
                AnalyticsHelper.pushFileDownloadEvent('json', fileResult.filename, LocalizationKeys.Download);
    
                FileHelper.downloadFile(fileResult.filename, fileResult.data);
            }
            dispatch(showDialog());
        } catch (error) {
            NotificationService.serviceError(error, "Can't download GIS data");
        }
    }, [formatBuildIds, buildIds, formatDesignAreaIds, designAreaIds, includeDAInfrastructure, includeLayers, dispatch]);

    return {
        dialog,
        includeLayers,
        buildCount,
        buildId,
        buildIds,
        designAreaCount: designAreaIds.length,
        designAreaNames,
        onLayerClick,
        onCloseClick,
        onDownloadClick,
    };
}
