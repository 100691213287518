import React, { useCallback } from 'react';

import { ProgressIndicator } from '../../../ui-elements/progress-indicator/progress-indicator';
import { PresetAttributes } from '../preset-attributes/preset-attributes';
import { PresetWizardSteps } from '../preset-config/preset-config.types';
import { PresetCstic } from '../preset-cstic/preset-cstic';
import { PresetCsticGroup } from '../preset-cstic/preset-cstic-group';
import { Slack } from '../slack/slack';
import { usePresetWizard } from './preset-wizard.hooks';

export const PresetWizard = (): JSX.Element => {
    const {
        step, stepTitle, stepCstics, progressData, presets,
        handleStepClick, goToNextStep, goToPreviousStep,
        goToProperties, cancelPreset
    } = usePresetWizard();

    const renderStep = useCallback((): React.ReactNode => {
        switch (step) {
            case PresetWizardSteps.AttributeSelection:
                return (
                    <PresetCstic
                        cstic={stepCstics ? stepCstics[0] : undefined}
                        progressData={progressData}
                    />
                );
            case PresetWizardSteps.PowerTetherAttributes:
            case PresetWizardSteps.OpticalTetherAttributes:
                return <PresetCsticGroup
                    step={step}
                    label={stepTitle}
                    cstics={stepCstics}
                    progressData={progressData}
                    goToNextStep={goToNextStep}
                    goToPreviousStep={goToPreviousStep}
                />;
            case PresetWizardSteps.SlackSelection:
                return <Slack onPreviousClick={goToPreviousStep} onNextClick={goToProperties} />;
            case PresetWizardSteps.PropertiesValidation:
                return <PresetAttributes isDefault={!presets.length} disableDefault={!presets.length} onCancelClick={cancelPreset} />;
        }
    }, [step, stepTitle, stepCstics, progressData, presets.length,
        goToProperties, cancelPreset, goToNextStep, goToPreviousStep]);

    return (
        <>
            <ProgressIndicator data={progressData} selectedIndex={progressData.length - 1} onClick={handleStepClick} />
            {renderStep()}
        </>
    );
};
