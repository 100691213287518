import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import CorningRoles from './corning-roles.json';

export interface RoleState {
    roles: string[] | undefined;
    isRoleManagementMenuShown: boolean;
}

export const isDevelopmentEnvironment = (): boolean => {
    return window.location.hostname === 'localhost' ||
        window.location.hostname === 'dna.ctcmdev.com' ||
        window.location.hostname === 'dna-demo.ctcmdev.com';
    // window.location.hostname === 'hybris5.ctcmdev.com'
}

export const isFaradayProductionEnvironment = (): boolean => {
    return window.location.hostname === 'dna-prod.ctcmdev.com';
}

const getDefaultRoles = (): string[] | undefined => {
    const roles: string[] = [];
    if (isDevelopmentEnvironment()) {
        const localStorageRole = localStorage.getItem("role");
        if (localStorageRole) {
            if (localStorageRole === CorningRoles.FlexNAP) {
                roles.push(CorningRoles.FlexNAPAdmin);
                roles.push(CorningRoles.FlexNAP)
            } else {
                roles.push(CorningRoles.Schrodinger);
            }
        } else {
            const defaultLocalhostProductRole = CorningRoles.Schrodinger;
            localStorage.setItem("role", defaultLocalhostProductRole);
            roles.push(defaultLocalhostProductRole);
        }

        return roles;
    }
    else if (isFaradayProductionEnvironment()) {
        roles.push(CorningRoles.Schrodinger);
        return roles;
    }
    return undefined;
}

const initialState: RoleState = {
    roles: getDefaultRoles(),
    isRoleManagementMenuShown: false
};

const addRolesReducer = (state: RoleState, action: PayloadAction<string[] | undefined>) => {
    const roles = action.payload;
    if (roles) {
        for (const role of roles) {
            if (!state.roles) {
                state.roles = [];
            }
            if (!state.roles.some(r => r === role)) {
                state.roles.push(role);
            }
        }
    }
}

const removeRolesReducer = (state: RoleState, action: PayloadAction<string[]>) => {
    const rolesToRemove = action.payload;
    let roles = state.roles;
    for (const roleToRemove of rolesToRemove) {
        if (roles) {
            roles = roles.filter(e => e !== roleToRemove);
        }
    }

    state.roles = roles;
}

const roleManagementMenuToggleReducer = (state: RoleState, action: PayloadAction<void>) => {
    const currentState = state.isRoleManagementMenuShown;
    state.isRoleManagementMenuShown = !currentState;
}

const { actions, reducer } = createSlice({
    name: 'role',
    initialState,
    reducers: {
        addRoles: addRolesReducer,
        removeRoles: removeRolesReducer,
        toggleRoleManagementMenu: roleManagementMenuToggleReducer
    },
});

export { reducer as RoleReducer };
export const { addRoles, removeRoles, toggleRoleManagementMenu } = actions;

export const loadRoles = () => async (dispatch) => {
    const config = window.ctcmDesignerToolConfiguration;
    let rolesToAdd: string[] = [];
    if (config?.roles) {
        rolesToAdd = config.roles as string[];
        dispatch(actions.addRoles(rolesToAdd));
    }
    else {
        console.error("Unable to add roles. From config: " + config)
    }
}
