import { Dispatch } from 'redux';
import { Command } from '../../command';
import { deleteFlexNapCable, undoDeleteFlexNapCable } from '../../../redux/build.state';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class CreateBuildCommand extends Command {
    private buildId: number;

    constructor({ id: buildId }) {
        super();
        this.buildId = buildId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildCreation };
    }

    public undo(dispatch: Dispatch): void {
        deleteFlexNapCable(this.buildId, [], true)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        undoDeleteFlexNapCable(this.buildId, [])(dispatch);
        super.redo(dispatch);
    }
}