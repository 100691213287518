import './inline-edit.scss';

import React from 'react';
import { MainPalettes, Typography } from '@orbit/theme-provider';
import { InlineText as OrbitInlineText, InlineTextProps as OrbitInlineTextProps } from '@orbit/inline-edit';
import classNames from 'classnames';


interface DNAInlineTextProps {
    label: string;
}
type InlineTextProps = DNAInlineTextProps & OrbitInlineTextProps;


export const InlineText = (props: InlineTextProps) => {

    const { label, palette = MainPalettes.primary, value, disabled, align = 'right', errorMessage, swapActions, maxLength, units, error, isEditing, onUpdate, onConfirm, onCancel, onInputChange, onFocus } = props;

    const inlineClasses = classNames({
        'inline-text-container': true,
    });

    const propClasses = classNames({
        'inline-prop': true,
    })

    return (
        <div className={propClasses}>
            <div className='prop-label'>
                <Typography label={label} variant='body1' />
            </div>
            <div className={inlineClasses}>
                <OrbitInlineText
                    palette={palette}
                    value={value}
                    disabled={disabled}
                    align={align}
                    isEditing={isEditing}
                    errorMessage={errorMessage}
                    swapActions={swapActions}
                    units={units}
                    error={error}
                    maxLength={maxLength}
                    onUpdate={onUpdate}
                    onConfirm={onConfirm}
                    onInputChange={onInputChange}
                    onCancel={onCancel}
                    onFocus={onFocus}
                />
            </div>
        </div>
    )
}