/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export interface DrawerMenuHeaderProps {
    onClose: () => void;
}

export interface DrawerSelectionActionItemProps {
    label: string;
    disabled: boolean;
    onClick: () => void;
    icon?: JSX.Element;
}

export interface DrawerMenuContextProps {
    selectedBuildIds: number[];
    selectedDesignAreaIds: number[];
    selectedDesignAreaNames: string[];
    singleBuildIdToDelete: number | undefined;
    singleDesignAreaIdToDelete: number | undefined;
    isDeleteDialogOpen: boolean;
    isOptionsMenuOpen: boolean;
    setSelectedBuildIds: (selectedBuildIds: number[]) => void;
    setSelectedDesignAreaIds: (selectedDesignAreaIds: number[]) => void;
    setSelectedDesignAreaNames: (selectedDesignAreaNames: string[]) => void;
    setSingleBuildIdToDelete: (singleBuildIdToDelete: number | undefined) => void;
    setSingleDesignAreaIdToDelete: (singleDesignAreaIdToDelete: number | undefined) => void;
    setIsDeleteDialogOpen: (isDeleteDialogOpen: boolean) => void;
    setIsOptionsMenuOpen: (isOptionsMenuOpen: boolean) => void;
}

export const initialDrawerMenuContextProps: DrawerMenuContextProps = {
    selectedBuildIds: [],
    selectedDesignAreaIds: [],
    selectedDesignAreaNames: [],
    singleBuildIdToDelete: undefined,
    singleDesignAreaIdToDelete: undefined,
    isDeleteDialogOpen: false,
    isOptionsMenuOpen: false,
    setSelectedBuildIds: () => { },
    setSelectedDesignAreaIds: () => { },
    setSelectedDesignAreaNames: () => { },
    setSingleBuildIdToDelete: () => { },
    setSingleDesignAreaIdToDelete: () => { },
    setIsDeleteDialogOpen: () => { },
    setIsOptionsMenuOpen: () => { },
};

export const DrawerMenuContext = createContext(initialDrawerMenuContextProps);
