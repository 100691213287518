import React from 'react';
import { PresetAttributeProps } from './preset-attributes.types';

export const PresetAttribute = ({ title, description }: PresetAttributeProps): JSX.Element => {
    return (
        <div className="attribute">
            <span className="attribute-title">{title}</span>
            <span className="attribute-value">{description}<br /></span>
        </div>
    );
};
