import * as ol from 'ol';
import { ScaleLine } from 'ol/control';
import { Units } from 'ol/control/ScaleLine';
import { click, never, platformModifierKeyOnly } from 'ol/events/condition';
import { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { DragBox, Draw, Interaction, Modify, Select, Snap } from 'ol/interaction';
import { DragBoxEvent } from 'ol/interaction/DragBox';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Layer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { Pixel } from 'ol/pixel';
import Source from 'ol/source/Source';
import Vector from 'ol/source/Vector';

import { MapClickEvent, MapClickEventType } from '../models/map-click-event';
import { PointLike } from '../models/pointlike';
import { DragInteraction, MapDragEndEvent, MapDragEvent } from './drag-interaction';
import { InteractableFeatureType } from './interactable-feature-type';
import { ModifiableFeatureType } from './modifiable-feature-type';
import {
    MapActionProps, MapDrawActionProps, MapModifyActionProps, MapProps, MapSelectActionProps
} from './openlayer-factory.types';
import { FeatureType } from './openlayer-feature.factory';
import { LayerFactory } from './openlayer-layer.factory';
import { OpenlayerStyleFactory } from './openlayer-style.factory';
import { OpenlayerUtility } from './openlayer-utility';

export { Map as MapType } from 'ol';
export { VectorLayer as LayerType };

export interface OpenlayerClickEvent {
    isDoubleClick: boolean;
    x: number;
    y: number;
    selectedFeatures?: FeatureLike[];
}

const DEFAULT_HIT_TOLERANCE = 10;
const DEFAULT_SNAP_TOLERANCE = 15;

export class OpenLayerFactory {

    public static toggleMapStyle(map: ol.Map) {
        map.getLayers().forEach((l) => {
            if (l instanceof TileLayer) {
                l.setVisible(!l.getVisible());
            }
        });
    }

    public static addControls(map: ol.Map) {
        if (map.getControls().getLength()) {
            console.warn('addControls: Map already has controls');
        }
        const usScale = new ScaleLine({ units: Units.US, className: 'us-scale' });
        const metricScale = new ScaleLine({ units: Units.METRIC, className: 'metric-scale' });
        map.getControls().clear();
        map.addControl(usScale);
        map.addControl(metricScale);
    }

    public static addMapInteractions(
        map: ol.Map,
        singleClickCallback: (event: MapClickEvent) => void,
        doubleClickCallback: (event: MapClickEvent) => void,
        shiftClickCallback: (event: MapClickEvent) => void,
        contextMenuCallback: (event: MapClickEvent) => void,
        dragFilterCallback: (event: MapDragEvent) => boolean,
        dragEndCallback: (event: MapDragEndEvent) => Promise<boolean>,
        pointerDragCallback: (event: ol.MapBrowserEvent) => boolean,
        mouseWheelCallback: (event: WheelEvent) => void): void {
        this.addGenericInteractions(map, pointerDragCallback);
        this.addSelectInteraction(map,
            (e: OpenlayerClickEvent) => singleClickCallback(this.olClickEventToInternalMapClickEvent(e)),
            (e: OpenlayerClickEvent) => doubleClickCallback(this.olClickEventToInternalMapClickEvent(e)),
            (e: OpenlayerClickEvent) => shiftClickCallback(this.olClickEventToInternalMapClickEvent(e)),
            (e: OpenlayerClickEvent) => contextMenuCallback(this.olClickEventToInternalMapClickEvent(e))
        );
        this.addEventListeners(map, mouseWheelCallback);
        this.addHoverInteraction(map);
        this.addBoxSelectMapInteraction(map);
        DragInteraction.add(map, dragFilterCallback, dragEndCallback);
    }

    public static addMapMoveSubscription(map: ol.Map, callback: (bbox: number[]) => void): void {
        map.on('moveend', (evt: ol.MapEvent) => callback(evt?.frameState?.extent ?? []));
    }

    private static olClickEventToInternalMapClickEvent(event: OpenlayerClickEvent): MapClickEvent {
        return {
            type: event.isDoubleClick ? MapClickEventType.DOUBLE_CLICK : MapClickEventType.SINGLE_CLICK,
            x: event.x,
            y: event.y,
            items: event.selectedFeatures ? event.selectedFeatures.map((f) => OpenlayerUtility.getFeatureDefinition(f)) : [],
        };
    }

    //#region Map Interactions
    private static addGenericInteractions(map: ol.Map, pointerDragCallback: (event: ol.MapBrowserEvent) => boolean) {
        map.on('pointerdrag', pointerDragCallback);
        map.on('pointermove', (e) => { e.map.getViewport().style.cursor = ''; }); // cursor doesn't change until let-go, need to force redraw
    }

    private static selectInteractionFilter = (feature: FeatureLike, _layer: Layer<Source>): boolean => {
        const clusteredFeatures = feature.get('features');
        return (feature.get('type') in InteractableFeatureType
            || (clusteredFeatures && clusteredFeatures.length === 1));
    }

    private static addSelectInteraction(
        map: ol.Map,
        singleClickCallback: (e: OpenlayerClickEvent) => void,
        doubleClickCallback: (e: OpenlayerClickEvent) => void,
        shiftClickCallback: (e: OpenlayerClickEvent) => void,
        contextMenuCallback: (e: OpenlayerClickEvent) => void): void {

        // selecting
        const selectInteraction = new Select({
            condition: click,
            hitTolerance: DEFAULT_HIT_TOLERANCE,
            filter: OpenLayerFactory.selectInteractionFilter,
            style: OpenlayerStyleFactory.createSelectedLayerStyleFunction,
        });

        const browserEventToClickEvent = (e: ol.MapBrowserEvent, isDouble: boolean): OpenlayerClickEvent => {
            const featuresAtPixel = map.getFeaturesAtPixel(e.pixel, { hitTolerance: DEFAULT_HIT_TOLERANCE, layerFilter: () => true }) || [];
            const selectedFeatures = featuresAtPixel.filter((f) => f.get('type') in InteractableFeatureType);
            const clusteredFeatures =
                featuresAtPixel
                    .filter((f) => f.get('features') && f.get('features').length === 1)
                    .flatMap((f) => f.get('features'));
            const coords = map.getCoordinateFromPixel(e.pixel);
            const cev: OpenlayerClickEvent = {
                isDoubleClick: isDouble,
                x: coords[0],
                y: coords[1],
                selectedFeatures: [...selectedFeatures, ...clusteredFeatures],
            };
            return cev;
        };

        map.on('singleclick', (e: ol.MapBrowserEvent) => {
            const cev = browserEventToClickEvent(e, false);
            if ((e.originalEvent as MouseEvent).shiftKey) {
                shiftClickCallback(cev);
            }
            else {
                singleClickCallback(cev);
            }
        });
        map.on('dblclick', (e: ol.MapBrowserEvent) => {
            const cev = browserEventToClickEvent(e, true);
            doubleClickCallback(cev);
        });
        map.on('contextmenu', (e: ol.MapBrowserEvent) => {
            const cev = browserEventToClickEvent(e, false);
            contextMenuCallback(cev);
        });

        map.addInteraction(selectInteraction);
    }

    private static addEventListeners = (map: ol.Map, mouseWheelCallback: (e: WheelEvent) => void) => {
        map.getViewport().addEventListener("wheel", mouseWheelCallback);
    }

    private static enableSelectInteraction = (props: MapProps) => OpenLayerFactory.setSelectInteractionActive(props, true);
    private static disableSelectInteraction = (props: MapProps) => OpenLayerFactory.setSelectInteractionActive(props, false);
    private static setSelectInteractionActive(props: MapProps, active: boolean) {
        const { map } = props;
        map.getInteractions().forEach((i) => {
            if (i instanceof Select) {
                i.setActive(active);
            }
        });
    }

    public static refreshSelectedFeatureType(map: ol.Map, newFeatures: ol.Feature[], type: FeatureType, idKey: string) {
        this.refreshSelectedFeatures(map, newFeatures, [type], idKey);
    }

    // Selected features are duplicates of existing features. If the existing feature is changed, the corresponding selected feature needs to be updated
    public static refreshSelectedFeatures(map: ol.Map, newFeatures: ol.Feature[], types: FeatureType[], idKey: string) {
        const selectInteraction = map.getInteractions().getArray().find((i) => i instanceof Select) as Select;
        if (!selectInteraction) {
            console.warn('Trying to refresh selected features without a Select interaction');
            return;
        }

        const selectedFeatures = selectInteraction.getFeatures();
        const selectedTargetFeatures = selectedFeatures.getArray().filter((f) => types.includes(OpenlayerUtility.getFeatureDefinition(f).type));

        for (const selected of selectedTargetFeatures) { // unselect features no longer present
            if (!newFeatures.find((newF) => newF.get(idKey) === selected.get(idKey))) {
                selectedFeatures.remove(selected);
            }
        }

        const selectedCount = selectedFeatures.getLength();
        for (const newF of newFeatures) { // refresh features still present
            for (let i = 0; i < selectedCount; i++) {
                const f = selectedFeatures.item(i);
                if (types.includes(f.get('type')) && f.get(idKey) === newF.get(idKey)) {
                    selectedFeatures.setAt(i, newF);
                }
            }
        }
    }

    public static bringFeaturesToFront(layer: VectorLayer, id: number, idKey: string) {
        const source = layer.getSource();
        const features = layer.sortFeaturesByUid();
        const { first, second } = features.partition((f) => f.get(idKey) === id);
        source.clear();
        // last feature in layer is in front
        source.addFeatures(second.map((f) => f));
        source.addFeatures(first.map((f) => f.clone()));
    }

    public static sendFeaturesToBack(layer: VectorLayer, id: number, idKey: string) {
        const source = layer.getSource();
        const features = layer.sortFeaturesByUid();
        const { first, second } = features.partition((f) => f.get(idKey) === id);
        source.clear();
        // first feature in layer is at the back
        source.addFeatures(first.map((f) => f.clone()));
        source.addFeatures(second.map((f) => f.clone()));
    }

    private static addHoverInteraction(map: ol.Map) {
        map.on('pointermove', (e: ol.MapBrowserEvent) => {
            const hoveredFeatures = map.getFeaturesAtPixel(e.pixel, { hitTolerance: DEFAULT_HIT_TOLERANCE, layerFilter: () => true });
            if (!hoveredFeatures || !hoveredFeatures.length) {
                map.getViewport().style.cursor = '';
                return;
            }
            const validHoveredFeatures = hoveredFeatures.filter((f) => {
                return OpenlayerUtility.getFeatureDefinition(f).type in InteractableFeatureType;
            });
            if (!validHoveredFeatures.length) {
                map.getViewport().style.cursor = '';
                return;
            }
            map.getViewport().style.cursor = 'pointer';
        });
    }

    private static addBoxSelectMapInteraction(map: ol.Map) {
        const onBoxEnd = (box: DragBox, event?: any) => {
            const selectInteraction = map.getInteractions().getArray().find((i) => i instanceof Select) as Select;
            if (!selectInteraction) {
                throw new Error('Box select interaction cannot exist without a select interaction');
            }
            const selectedFeaturesCollection = selectInteraction.getFeatures();
            selectedFeaturesCollection.clear();
            const layers = map.getLayers().getArray().filter((bl) => bl instanceof VectorLayer) as VectorLayer[];
            const extent = box.getGeometry().getExtent();
            for (const layer of layers) {
                const intersectedFeatures = layer.getSource().getFeaturesInExtent(extent).filter((f) => f.get('type') in InteractableFeatureType);
                selectedFeaturesCollection.extend(intersectedFeatures);
            }
        };
        const dragBox = new DragBox({
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onBoxEnd: () => { },
            condition: platformModifierKeyOnly,
        });
        dragBox.on('boxdrag', (e: DragBoxEvent) => {
            e.mapBrowserEvent.map.getViewport().style.cursor = 'crosshair';
            onBoxEnd(dragBox, e);
        });
        map.addInteraction(dragBox);
    }
    //#endregion

    public static resetMapTool(props: MapProps) {
        this.removeInteractions(props);
        this.enableSelectInteraction(props);
    }

    public static updateMapSelectTool(props: MapSelectActionProps): void {
        const { map, crossCursor } = props;
        this.removeInteractions(props);
        this.disableSelectInteraction(props);
        if (crossCursor) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.updateMapPointInteration({ map: map, finishCondition: () => true, drawEnd: () => { } });
        }
        this.updateMapSnapInteraction(props);
    }

    public static updateMapModifyTool(props: MapModifyActionProps): void {
        this.removeInteractions(props);
        this.updateMapModifyInteraction(props);
        this.updateMapSnapInteraction(props);
    }

    public static updateMapDrawTool(props: MapDrawActionProps) {
        this.removeInteractions(props);
        this.disableSelectInteraction(props);
        this.updateMapDrawInteraction(props);
        this.updateMapSnapInteraction(props);
    }

    public static updateMapPolygonDrawTool(props: MapDrawActionProps): void {
        this.removeInteractions(props);
        this.disableSelectInteraction(props);
        this.updateMapDrawPolygonInteraction(props);
        this.updateMapSnapInteraction(props);
    }

    public static updateMapSnapTool(props: MapActionProps): void {
        this.removeSnapInteractions(props);
        this.updateMapSnapInteraction(props);
    }

    public static getModifiableFeaturesAtPixel(map: ol.Map, pixel: Pixel) {
        const features = map.getFeaturesAtPixel(pixel, { hitTolerance: DEFAULT_HIT_TOLERANCE, layerFilter: () => true });
        return features.filter(f => f.get('type') in ModifiableFeatureType && !f.get('locked'));
    }

    public static clearSelectedFeatures(map: ol.Map) {
        map.getInteractions().forEach((i) => {
            if (i instanceof Select) {
                i.getFeatures().clear();
            }
        });
    }

    public static selectFeature(map: ol.Map, featureToSelect: ol.Feature | undefined): boolean {
        const selectInteraction = map.getInteractions().getArray().find((i) => i instanceof Select) as Select;
        if (!selectInteraction) {
            console.warn('Trying to select feature without a Select interaction');
            return false;
        }
        const collection = selectInteraction.getFeatures();
        collection.clear();
        if (featureToSelect) {
            collection.push(featureToSelect);
            return true;
        }
        return false;
    }

    public static selectFeatures(map: ol.Map, featuresToSelect?: ol.Feature[]): boolean {
        const selectInteraction = map.getInteractions().getArray().find((i) => i instanceof Select) as Select;
        if (!selectInteraction) {
            console.warn('Trying to select feature without a Select interaction');
            return false;
        }
        const collection = selectInteraction.getFeatures();
        collection.clear();
        if (featuresToSelect && featuresToSelect.length > 0) {
            for (const featureToSelect of featuresToSelect) {
                collection.push(featureToSelect);
            }
            return true;
        }
        return false;
    }

    private static removeInteractions(props: MapProps) {
        const { map } = props;
        const toRemove: Interaction[] = [];
        map.getInteractions().forEach((i) => {
            if (i instanceof Draw) {
                i.finishDrawing();
                toRemove.push(i);
            }
            else if (i instanceof Snap || i instanceof Modify || i instanceof DragBox) {
                toRemove.push(i);
            }
        });
        toRemove.forEach((d) => {
            map.removeInteraction(d);
        });
    }

    private static removeSnapInteractions({ map }: MapProps) {
        const toRemove: Interaction[] = [];
        map.getInteractions().forEach((i) => {
            if (i instanceof Snap) {
                toRemove.push(i);
            }
        });
        toRemove.forEach((d) => {
            map.removeInteraction(d);
        });
    }

    private static updateMapModifyInteraction(props: MapModifyActionProps): void {
        const { map, modificationTool } = props;

        const select = map.getInteractions().getArray().find((i) => i instanceof Select) as Select;
        if (!select) {
            console.warn('Trying to modify selected features without a Select interaction');
            return;
        }

        const modify = new Modify({
            features: select.getFeatures(),
            style: OpenlayerStyleFactory.createCircleLine(10, '#005293', '#fafafa'),
            condition: (event: ol.MapBrowserEvent): boolean => {
                const selectedTerminalSegments = map.getFeaturesAtPixel(event.pixel, {
                    hitTolerance: DEFAULT_HIT_TOLERANCE,
                    layerFilter: (layer: Layer): boolean => {
                        return layer.get('id') === LayerFactory.LAYER_TERMINAL_SEGMENTS;
                    }
                });
                if (selectedTerminalSegments?.length) {
                    // Disable modification of the first and last nodes of an extended terminal's segments
                    // They are the nodes connected to the parent NAP and Terminal
                    const p0 = event.pixel;
                    const terminalSegmentGeometry = (selectedTerminalSegments[0].getGeometry() as LineString);

                    if (selectedTerminalSegments[0].get('firstSegment')) {
                        const firstNodeCoordinates = terminalSegmentGeometry.getFirstCoordinate();
                        if (this.pixelsIntersect(p0, map.getPixelFromCoordinate(firstNodeCoordinates))) {
                            return false;
                        }
                    }
                    if (selectedTerminalSegments[0].get('lastSegment')) {
                        const lastNodeCoordinates = terminalSegmentGeometry.getLastCoordinate();
                        if (this.pixelsIntersect(p0,  map.getPixelFromCoordinate(lastNodeCoordinates))) {
                            return false;
                        }
                    }
                }
                return true;
            }
        })

        modify.on('modifystart', (e: ModifyEvent) => {
            modificationTool.modifyStart(e);
        });

        modify.on('modifyend', (e: ModifyEvent) => {
            modificationTool.modifyEnd(e);
        });

        map.addInteraction(modify);
    }

    private static pixelsIntersect(p0: Pixel, p1: Pixel): boolean {
        const dx = p0[0]-p1[0];
        const dy = p0[1]-p1[1];
        if (Math.sqrt(dx * dx + dy * dy) <= DEFAULT_HIT_TOLERANCE) {
            return true;
        }

        return false
    }

    private static updateMapPointInteration(props: MapDrawActionProps) {
        const { map, finishCondition, drawEnd } = props;
        let features: ol.Feature[] = [];
        const condition = (): boolean => true;
        const source = new Vector();
        const style = OpenlayerStyleFactory.createCross(10, 'black');
        const draw = new Draw({ source, type: GeometryType.POINT, condition, finishCondition, snapTolerance: DEFAULT_SNAP_TOLERANCE, style });
        draw.on('drawend', (event: DrawEvent) => {
            drawEnd(features, event.feature.getGeometry());
            features = [];
        });
        map.addInteraction(draw);
    }

    private static updateMapDrawInteraction(props: MapDrawActionProps) {
        const { map, targetTypes, featuresToConnect, finishCondition, drawEnd } = props;
        let features: ol.Feature[] = [];
        const condition = (e: ol.MapBrowserEvent): boolean => {
            let feature: ol.Feature | undefined;
            const hasType = e.target.forEachFeatureAtPixel(e.pixel, (f: ol.Feature) => {
                const featureType = f.get('type');
                const isValid = targetTypes?.includes(featureType);
                if (isValid) {
                    feature = f;
                }
                return isValid;
            });

            if (feature) {
                if (!features.includes(feature)) {
                    features.push(feature);
                    if (featuresToConnect && features.length >= featuresToConnect) {
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        draw.finishDrawing();
                        return false;
                    }
                }
                else {
                    return false;
                }
            }

            return hasType || (featuresToConnect && features.length > 0);
        };

        const source = new Vector();
        const style = OpenlayerStyleFactory.createCircleLine(10, 'black', 'orange');
        const finish = finishCondition || never; // prevent finish via single clicking twice at the same location
        const draw = new Draw({ source, type: GeometryType.LINE_STRING, condition, finishCondition: finish, snapTolerance: DEFAULT_SNAP_TOLERANCE, style });
        draw.on('drawend', (event: DrawEvent) => {
            if (drawEnd) {
                drawEnd(features, event.feature.getGeometry());
            }
            features = [];
        });
        map.addInteraction(draw);
    }

    private static updateMapDrawPolygonInteraction(props: MapDrawActionProps): void {
        const { map, drawEnd } = props;
        const source = new Vector();
        const draw = new Draw({ source, type: GeometryType.POLYGON });
        draw.on('drawend', (event: DrawEvent) => {
            if (drawEnd) {
                drawEnd([], event.feature.getGeometry());
            }
        });
        map.addInteraction(draw);
    }

    private static updateMapSnapInteraction(props: MapActionProps) {
        const { map, snapToLayers: layers } = props;
        if (!layers) {
            return;
        }
        layers.forEach((l) => {
            const snap = new Snap({
                source: l.getSource(),
                pixelTolerance: DEFAULT_SNAP_TOLERANCE,
            });
            map.addInteraction(snap);
        });
    }

    public static createNewPath(features: ol.Feature[], geometry: Geometry | undefined, execute: (points: PointLike[], startId: number, endId: number) => void) {
        if (features.length > 1 && geometry && geometry instanceof LineString) {
            const lineString = geometry as LineString;
            const points = lineString.getCoordinates().map((c) => {
                return { x: c[0], y: c[1] };
            });
            const endPoint = (features[1].getGeometry() as Point).getFirstCoordinate();
            points.push({ x: endPoint[0], y: endPoint[1] });
            const startId = +features[0].get('id');
            const endId = +features[1].get('id');
            execute(points, startId, endId);
        }
    }

    public static createNewPolygon(geometry: Geometry | undefined, execute: (points: PointLike[]) => void): void {
        if (geometry && geometry instanceof Polygon) {
            const polygon = geometry as Polygon;
            const points = polygon.getCoordinates()[0].map((c) => {
                return { x: c[0], y: c[1] };
            });

            // Has to have at least 3 unique points since first and last are the same for polygons
            if (points.length > 3) {
                execute(points);
            }
        }
    }
}
