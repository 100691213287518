import React from 'react';

import { Radio, RadioGroup } from '@orbit/radio';

import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { PresetNavigationFooter } from '../../preset-navigation-footer';
import { usePresetCstic } from './preset-cstic.hooks';
import { PresetCsticProps } from './preset-cstic.types';

export const PresetCstic = (props: PresetCsticProps): JSX.Element => {
    const { cstic, progressData } = props;

    const { attributes, selected, loading, handleAttributeSelection, handlePreviousClick, handleNextClick } = usePresetCstic(props);

    return (<>
        {loading || !cstic ? null :
            <div className='radio-group-container'>
                <RadioGroup
                    label={cstic.description}
                    name={cstic.description ?? ""}
                    onChange={handleAttributeSelection}
                    row
                >
                    {attributes.map(o => <Radio {...o} key={o.name} disabled={loading} checked={selected === o.value}></Radio>)}
                </RadioGroup>
            </div>
        }
        {loading && <div className="dna-spinner-container"><DnaSpinner /></div>}
        <PresetNavigationFooter
            previousDisabled={loading}
            nextDisabled={loading || !selected}
            showPrevious={progressData.length > 1}
            onPreviousClick={handlePreviousClick}
            onNextClick={handleNextClick}
        />
    </>);
}