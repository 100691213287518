import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { BoreData } from '../data/bore-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class MeasuredLengthValidator implements Validator<BoreData> {

    public validate(bore: BoreData): ValidationResult | null {
        if (bore.measuredLength && bore.measuredLength > 0) {
            return null;
        }
        const extent = bore.getExtent();
        return {
            buildId: null,
            itemId: bore.id,
            itemType: ItemType.Bore,
            shortDescription: i18next.t(LocalizationKeys.WarningUnsetMeasuredLength),
            type: ValidationResultType.Warning,
            extent
        };
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof BoreData;
    }
}
