import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { Radio, RadioGroup } from '@orbit/radio';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { useFlexNapPretermCard } from './flexnap-preterm-card.hooks';
import { FlexNapPretermCardProps } from './flexnap-preterm-card.types';

export const FlexNapPretermCard = (props: FlexNapPretermCardProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        disabled, pretermCardIcon, unselectPreterm,
        build, collapsed, toggleCollapse,
        fiberCountValues, selectedFiberCount, startingIndexValues, selectedStartingIndex,
        handleFiberCountClick, handleStartIndexClick,
        revertPreterm
    } = useFlexNapPretermCard(props);
    const collapseBtn =
        <button className="icon-button" onClick={toggleCollapse}>
            {
                collapsed ?
                    <img alt="Reveal card" src={require('./../../card/icon_open_chevron.svg')} /> :
                    <img alt="Collapse card" src={require('./../../card/icon_close_chevron.svg')} />}
        </button>;
    const cs = classNames({ collapsed: !!collapsed });

    return (
        <div className="nap-diagram-children preterm">
            <div className="card-separator"></div>
            <div className="header-with-button">
                <CardHeaderComponent title={t(LocalizationKeys.Preterm)} icon={pretermCardIcon} iconBorder={true} />
                {collapseBtn}
                <button className="icon-button" onClick={unselectPreterm}><img alt="Close terminal" src={require('./../../card/icon_close.svg')} /></button>
            </div>
            <CardContentComponent className={cs}>
                {CardPropertyInline(t(LocalizationKeys.Build), build.id)}
                <div className='radio-group-container'>
                    <RadioGroup
                        label={t(LocalizationKeys.FiberCount)}
                        name="fiber-count"
                        value={selectedFiberCount}
                        onChange={handleFiberCountClick}
                        row
                    >
                        {fiberCountValues.map(o => <Radio {...o} key={o.name} checked={selectedFiberCount === o.value}></Radio>)}
                    </RadioGroup>
                    <RadioGroup
                        label={t(LocalizationKeys.TetherStart)}
                        name="tether-start"
                        value={selectedStartingIndex}
                        onChange={handleStartIndexClick}
                        row
                    >
                        {startingIndexValues.map(o => <Radio {...o} key={o.name} checked={selectedStartingIndex === o.value}></Radio>)}
                    </RadioGroup>
                </div>
                <div className="card-footer">
                    <TextButton palette={MainPalettes.error} disabled={disabled} onClick={revertPreterm}>
                        <i className="material-icons">delete</i>
                    </TextButton>
                </div>
            </CardContentComponent>
        </div>
    );
};
