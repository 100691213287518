import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteImportedGisData } from '../../../redux/import.state';
import { dialogSelector } from '../../../selectors/root.selectors';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { importedGisDataToRemoveSelector } from '../../../selectors/import.selectors';

export const useRemoveGisDataDialog = () => {
    const dispatch = useDispatch();

    const dialog = useSelector(dialogSelector);
    const importedGisData = useSelector(importedGisDataToRemoveSelector);

    const isOpen = dialog === DialogType.RemoveGisData && !!importedGisData;
    const fileName = importedGisData?.fileName ?? '';
    const timestamp = (new Date(importedGisData?.timestamp ?? '')).toShortString();

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onRemove = useCallback(() => {
        if (importedGisData)
            deleteImportedGisData(importedGisData.id)(dispatch);
        onClose();
    }, [dispatch, importedGisData, onClose]);

    const onLocateOnMap = useCallback(() => {
        onClose();
    }, [onClose]);

    return {
        isOpen,
        fileName,
        timestamp,
        onClose,
        onRemove,
        onLocateOnMap,
    }
};