import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SchrodingerPreset } from '../models/schrodinger-preset';
import { NotificationService } from '../services/notification.service';
import {
    SchrodingerPresetService, UpdatePresetRequest
} from '../services/schrodinger-preset.service';

export interface SchrodingerPresetState {
    selectedPreset?: SchrodingerPreset;
    presets: SchrodingerPreset[];
    loaded: boolean;
}

const initialState: SchrodingerPresetState = {
    selectedPreset: undefined,
    presets: [],
    loaded: false
};

const loadPresetsReducer = (state: SchrodingerPresetState, action: PayloadAction<SchrodingerPreset[]>): void => {
    state.presets = action.payload;
    state.loaded = true;
};

const addPresetReducer = (state: SchrodingerPresetState, action: PayloadAction<SchrodingerPreset>): void => {
    const preset = action.payload;
    if (preset.isDefault) {
        for (const otherPreset of state.presets) {
            otherPreset.isDefault = false;
        }
    }
    state.presets.push(preset);
};

const deletePresetReducer = (state: SchrodingerPresetState, action: PayloadAction<number>): void => {
    state.presets = state.presets.filter((p) => p.id !== action.payload);
}

const selectPresetReducer = (state: SchrodingerPresetState, action: PayloadAction<SchrodingerPreset | undefined>): void => {
    state.selectedPreset = action.payload;
};

const selectDefaultPresetReducer = (state: SchrodingerPresetState): void => {
    const defaultPreset = state.presets.find((p) => p.isDefault);
    state.selectedPreset = defaultPreset;
};

const notifyPresetAppliedToBuildReducer = (state: SchrodingerPresetState, action: PayloadAction<{ presetId: number, buildId: number }>) => {
    const { presetId, buildId } = action.payload;
    const preset = state.presets.find(p => p.id === presetId);
    if (preset) {
        NotificationService.success(`${preset.name} applied to the build ${buildId}`);
    }
}

const updatePresetReducer = (state: SchrodingerPresetState, action: PayloadAction<SchrodingerPreset>) => {
    const preset = action.payload;
    const pIdx = state.presets.findIndex((p) => p.id === preset.id);
    if (preset.isDefault) {
        state.presets
            .filter((p) => p.id !== preset.id)
            .forEach((p) => p.isDefault = false);
    }
    if (pIdx < 0) {
        state.presets.push(preset);
    }
    else {
        state.presets[pIdx] = preset;
    }
    state.selectedPreset = preset;
};

const { actions, reducer } = createSlice({
    name: 'schrodingerpreset',
    initialState,
    reducers: {
        loadPresets: loadPresetsReducer,
        addPreset: addPresetReducer,
        deletePreset: deletePresetReducer,
        selectPreset: selectPresetReducer,
        selectDefaultPreset: selectDefaultPresetReducer,
        notifyPresetAppliedToBuild: notifyPresetAppliedToBuildReducer,
        updatePreset: updatePresetReducer
    },
});

export { reducer as SchrodingerPresetReducer };

export const loadPresets = () => {
    return (async (dispatch): Promise<void> => {
        const service = new SchrodingerPresetService();
        const presets = await service.getAll() || [];
        dispatch(actions.loadPresets(presets));
    });
};

export const addPreset = (sessionId: string, name: string, isDefault: boolean) => {
    return (async (dispatch): Promise<void> => {
        const service = new SchrodingerPresetService();
        const preset = await service.addPreset(sessionId, name, isDefault);
        if (preset) {
            dispatch(actions.addPreset(preset));
            dispatch(actions.selectPreset(preset));
        }
    });
};

export const deletePreset = (id: number) => {
    return (async (dispatch): Promise<void> => {
        const service = new SchrodingerPresetService();
        await service.deletePreset(id);
        dispatch(actions.deletePreset(id));
        dispatch(actions.selectDefaultPreset());
    });
};

export const updatePreset = (presetId: number, request: UpdatePresetRequest) => {
    return (async (dispatch): Promise<void> => {
        const service = new SchrodingerPresetService();
        const preset = await service.updatePreset(presetId, request);
        if (preset) {
            dispatch(actions.updatePreset(preset));
        }
    });
};

export const { selectPreset, selectDefaultPreset, notifyPresetAppliedToBuild } = actions;
