import React from 'react';
import { useTranslation } from 'react-i18next';

import { Radio, RadioGroup } from '@orbit/radio';

import { LocalizationKeys } from '../../../../locales/types';
import { EditText } from '../../../ui-elements/edit-text';
import { useWorkspaceDrawerFilter } from './drawer-workspace-filter.hooks';

export const WorkspaceDrawerFilter = () => {
    const { t } = useTranslation();
    const {
        filterValue,
        filterOptions,
        selectedFilterOption,
        onFilterInputChanged,
        onFilterOptionChanged,
    } = useWorkspaceDrawerFilter();

    return (
        <div className="workspace-drawer-subheader-filter-container">
            <div className="workspace-drawer-subheader-workspace-filter">
                <EditText
                    label={t(LocalizationKeys.DefaultWorkspaceFilterValue)}
                    type="text"
                    name="workspaceFilter"
                    value={filterValue}
                    small={true}
                    onChange={onFilterInputChanged}
                    variant="outlined"
                />
            </div>
            <div className="workspace-drawer-subheader-workspace-filter-options">
                <RadioGroup
                    name="workspaceFilterOptions"
                    onChange={onFilterOptionChanged}
                    row
                    sx={{ flexWrap: 'nowrap' }}
                >
                    {filterOptions.map((o, i) => <Radio {...o} key={i} checked={selectedFilterOption === o.value}></Radio>)}
                </RadioGroup>
            </div>
        </div>
    );
}
