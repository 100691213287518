// ItemType numeric values correspond to those in Workspace.Model.ItemType
export enum ItemType {
    Build = 0,
    Pole = 1,
    Segment = 2,
    Nap = 3,
    Bore = 4,
    Trench = 5,
    SchrodingerTap = 6,
    SchrodingerTether = 7,
    Cabinet = 8
}
