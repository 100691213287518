import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteDesignArea } from '../../../../redux/design-area.state';
import { workspaceSelector } from '../../../../selectors/root.selectors';
import { DrawerMenuContext } from '../drawer-menu.types';

export const useDeleteDesignAreasDialog = () => {
    const { currentWorkspace } = useSelector(workspaceSelector);

    const {
        isDeleteDialogOpen,
        singleDesignAreaIdToDelete,
        setIsDeleteDialogOpen,
        setSingleDesignAreaIdToDelete,
    } = useContext(DrawerMenuContext);

    const dispatch = useDispatch();

    const { t } = useTranslation();
    
    
    const open = !!singleDesignAreaIdToDelete && isDeleteDialogOpen;

    const handleCloseClick = useCallback((): void => {
        setIsDeleteDialogOpen(false);
        setSingleDesignAreaIdToDelete(undefined);
    }, [setIsDeleteDialogOpen, setSingleDesignAreaIdToDelete]);

    const handleDeleteDesignAreasClick = useCallback(() => {
        handleCloseClick();
        singleDesignAreaIdToDelete && deleteDesignArea(singleDesignAreaIdToDelete)(dispatch);

    }, [dispatch, handleCloseClick, singleDesignAreaIdToDelete]);

    return {
        currentWorkspace,
        open,
        handleDeleteDesignAreasClick,
        handleCloseClick,
        t,
    };
};
