import i18next from 'i18next';
import React from 'react';

import { LocalizationKeys } from '../../locales/types';
import { Units } from '../../models/units';
import { DnaSpinner } from '../ui-elements/dna-spinner';
import { PopupMenuItem } from '../ui-elements/popup-menu';
import { MenuActions, useGISMoreOptionsMenu } from './gis-more-options.hooks';
import aboutIcon from './icons/icon_about.svg';
import canvasModeIcon from './icons/icon_canvas_mode.svg';
import exitDesignerIcon from './icons/icon_exit_to_design_areas.svg';
import enterFullscreenIcon from './icons/icon_fullscreen.svg';
import exitFullscreenIcon from './icons/icon_fullscreen_exit.svg';
import gisModeIcon from './icons/icon_gis_mode.svg';
import imperialIcon from './icons/icon_imperial.svg';
import importGisIcon from './icons/icon_import_gis.svg';
import metricIcon from './icons/icon_metric.svg';
import removeGisIcon from './icons/icon_remove_gis.svg';
import closeIcon from './icons/icon_close.svg';
import { MoreOptionsMenu } from './more-options';

export interface GISMoreOptionsMenuProps {
    open: boolean;
}

export const GISMoreOptionsMenu = (props: GISMoreOptionsMenuProps): JSX.Element | null => {
    const { open, isUpdatingContracts, isUpdatingCpq, displayUnits, isFullscreen, hasCustomer, hasSchrodingerRole, isFlexNapDesignAdmin, isGISMode, handleSelectedMenuItem } = useGISMoreOptionsMenu(props);

    const aboutApplicationStr = i18next.t(LocalizationKeys.AboutThisApplication);
    const importGisStr = i18next.t(LocalizationKeys.ImportGisData);
    const removeGisStr = i18next.t(LocalizationKeys.RemoveGisData);
    const exitToDaStr = i18next.t(LocalizationKeys.ExitToMyDesignAreas);
    const logoutStr = i18next.t(LocalizationKeys.Logout);
    const switchDesignModeStr = isGISMode ? i18next.t(LocalizationKeys.SwitchToCanvasMode) : i18next.t(LocalizationKeys.SwitchToGISMode);
    const designModeId = isGISMode ? MenuActions.SwitchToCanvas : MenuActions.SwitchToMap;
    const designModeIcon = isGISMode ? canvasModeIcon : gisModeIcon;

    const menuItems: PopupMenuItem[] = [];
    menuItems.push({ label: aboutApplicationStr, id: MenuActions.AboutThisApplication, icon: <img src={aboutIcon} alt={aboutApplicationStr}></img> });
    menuItems.push({ label: importGisStr, id: MenuActions.ImportGISData, icon: <img src={importGisIcon} alt={importGisStr} /> });
    menuItems.push({ label: removeGisStr, id: MenuActions.RemoveGISData, icon: <img src={removeGisIcon} alt={removeGisStr} /> });
    if (hasSchrodingerRole) {
        menuItems.push({ label: switchDesignModeStr, id: designModeId, icon: <img src={designModeIcon} alt={switchDesignModeStr} /> });
    }

    if (!hasCustomer) {
        const unitsIcon = displayUnits === Units.meters ? metricIcon : imperialIcon;
        const units = displayUnits === Units.meters ? i18next.t(LocalizationKeys.Metric) : i18next.t(LocalizationKeys.Imperial);
        menuItems.push({ label: units, id: MenuActions.ToggleUnits, icon: <img src={unitsIcon} alt={units} /> });
    }
    else {
        const fullscreenIcon = isFullscreen ? exitFullscreenIcon : enterFullscreenIcon;
        const fullscreenLabel = isFullscreen ? i18next.t(LocalizationKeys.ExitFullscreen) : i18next.t(LocalizationKeys.EnterFullscreen);
        menuItems.push({ label: fullscreenLabel, id: MenuActions.ToggleFullscreen, icon: <img src={fullscreenIcon} alt={fullscreenLabel} /> });
        menuItems.push({ label: exitToDaStr, id: MenuActions.ExitDesigner, icon: <img src={exitDesignerIcon} alt={exitToDaStr} />, divider: true });
    }
    if (isFlexNapDesignAdmin) {
        menuItems.push({ label: i18next.t(LocalizationKeys.UpdateContracts), id: MenuActions.UpdateContracts, icon: isUpdatingContracts ? <DnaSpinner width={20} /> : undefined, divider: true });
        menuItems.push({ label: i18next.t(LocalizationKeys.UpdateCpq), id: MenuActions.UpdateCpq, icon: isUpdatingCpq ? <DnaSpinner width={20} /> : undefined, divider: true });
    }
    if (!hasCustomer) {
        menuItems.push({ label: logoutStr, id: MenuActions.Logout, icon: <img src={closeIcon} alt={logoutStr}></img>, divider: true });
    }

    if (!open) {
        return null;
    }

    return (
        <div>
            <MoreOptionsMenu open={open} options={menuItems} handleSelectedOption={handleSelectedMenuItem} />
        </div>
    );
}