import { CabinetSplicePlanBuild } from '../models/cabinet-splice-plan';

export interface CabinetSplicePlanBuildInfo {
    build: CabinetSplicePlanBuild;
    depth: number;
    overlap: boolean;
}

export class CabinetSplicePlanHelper {
    public static formatStringToLocale(utcStrDate?: string, defaultStr = ''): string {
        if (!utcStrDate) {
            return defaultStr;
        }

        return new Date(utcStrDate)?.toLocaleDateString() ?? defaultStr;
    }

    public static buildsToBuildInfos(builds: CabinetSplicePlanBuild[], depth: number, minPort?: number, maxPort?: number): CabinetSplicePlanBuildInfo[] {
        let result: CabinetSplicePlanBuildInfo[] = [];
        const sortedBuilds = [...builds].sort((a, b) => {
            const { lowPortNumber: lowPortNumberA = 0, buildId: buildIdA } = a;
            const { lowPortNumber: lowPortNumberB = 0, buildId: buildIdB } = b;
            if (lowPortNumberA === lowPortNumberB) return buildIdB - buildIdA;
            return lowPortNumberB - lowPortNumberA;
        });
        sortedBuilds.forEach((b) => {
            const lowPort = b.lowPortNumber;
            const highPort = lowPort ? lowPort + b.portCount - 1 : undefined;
            const unassigned = !lowPort;
            const lowerLimitExceeded = minPort && lowPort && lowPort < minPort;
            const upperLimitExceeded = maxPort && highPort && highPort > maxPort;
            const overlappedSibling = !!builds.find((x) => this.portRangesOverlap(b, x));
            result.push({
                build: b,
                depth,
                overlap: unassigned || lowerLimitExceeded || upperLimitExceeded || overlappedSibling
            });
            if (Array.isArray(b.childBuilds)) {
                result = result.concat(this.buildsToBuildInfos(b.childBuilds, depth + 1, lowPort, highPort));
            }
        });
        return result;
    }

    private static portRangesOverlap = (a: CabinetSplicePlanBuild, b: CabinetSplicePlanBuild): boolean => {
        if (a.buildId === b.buildId) {
            return false; // A build can't overlap its own port range.
        }
        if (a.lowPortNumber && b.lowPortNumber) {
            const aStart = a.lowPortNumber;
            const aFinish = aStart + a.portCount - 1;
            const bStart = b.lowPortNumber;
            const bFinish = bStart + b.portCount - 1;
            return (aStart <= bFinish) && (aFinish >= bStart);
        }
        return false;
    };
}
