import { IToolItemProps } from '../ui-elements/tool-item';

export enum PanKeys {
    ArrowLeft = "ArrowLeft",
    ArrowRight = "ArrowRight"
}

export type IPanTool = {
    toolItems: {
        first: IToolItemProps;
        previous: IToolItemProps;
        next: IToolItemProps;
        last: IToolItemProps;
    };
    handlePanShortcut: (e: KeyboardEvent) => void;
}

export enum PanControls {
    First,
    Previous,
    Next,
    Last
}