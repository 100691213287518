import * as rfc from 'rfc6902';

import { DesignArea } from '../models/design-area';
import { DesignAreaBuilds } from '../models/design-area-builds';
import { DesignMode } from '../models/design-mode';
import { PointLike } from '../models/pointlike';
import { SecuredService } from './abstract-secured-v2.service';

export class DesignAreaService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/designarea';

    public getAll(workspaceId: number, designMode: DesignMode): Promise<DesignArea[] | undefined> {
        return this.get<DesignArea[]>(`${this.baseUrl}/${workspaceId}/designMode/${designMode}`);
    }

    public getDesignAreaBuilds(workspaceId: number): Promise<DesignAreaBuildsResult | undefined> {
        return this.get<DesignAreaBuildsResult>(`${this.baseUrl}/builds/${workspaceId}`);
    }

    public getCanvasDesignAreaBuilds(workspaceId: number): Promise<DesignAreaBuildsResult | undefined> {
        return this.get<DesignAreaBuildsResult>(`${this.baseUrl}/builds/canvas/${workspaceId}`);
    }

    public addDesignArea(request: CreateDesignAreaRequest): Promise<DesignArea | undefined> {
        return this.post(this.baseUrl, request);
    }

    public patchDesignArea(oldDa: DesignArea, newDa: DesignArea): Promise<DesignArea | undefined> {
        const patch = rfc.createPatch(oldDa, newDa);
        return this.patch(`${this.baseUrl}/${oldDa.id}`, patch);
    }

    public updateDesignAreaCoordinates(da: DesignArea): Promise<DesignArea | undefined> {
        const coordinates = da.polygon;
        return this.post(`${this.baseUrl}/${da.id}/coordinates`, { coordinates });
    }

    public updateCanvasDesignArea(request: UpdateBuildDesignAreaRequest): Promise<DesignAreaBuildsResult | undefined> {
        return this.post(`${this.baseUrl}/builds/canvas/design-area`, request);
    }

    public deleteDesignArea(id: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${id}`);
    }

    public undoDelete(id: number): Promise<DesignArea | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${id}`);
    }
}

export interface UpdateBuildDesignAreaRequest {
    designAreaId: number;
    buildId: number;
}
export interface DesignAreaBuildsResult {
    designAreas: DesignAreaBuilds[];
    unassignedBuildIds: number[];
}

export interface CreateDesignAreaRequest {
    workspaceId: number;
    designMode?: DesignMode;
    polygon?: PointLike[];
    name?: string;
    projectId?: string;
    description?: string;
    country?: string;
    region?: string;
    city?: string;
    zipCode?: string;
    color?: string;
}
