import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCanvasDesignAreaBuilds } from '../../redux/design-area.state';
import { StateModel } from '../../redux/reducers';
import { displayedBuildSelector } from '../../selectors/build.selectors';
import { buildSelector, workspaceSelector } from '../../selectors/root.selectors';

export const useCanvas = () => {
    const dispatch = useDispatch();

    const { currentWorkspace } = useSelector(workspaceSelector);
    const { displayUnits } = useSelector((state: StateModel) => state.authentication);
    const {
        tempCanvasBuildSpans, segments
    } = useSelector(buildSelector);
    const build = useSelector(displayedBuildSelector);

    const buildSegments = segments.filter(({ buildId }) => buildId === build?.id);

    const currentWorkspaceId = currentWorkspace?.id;
    useEffect(() => {
        if (currentWorkspaceId) {
            loadCanvasDesignAreaBuilds(currentWorkspaceId)(dispatch);
        }
    }, [currentWorkspaceId, dispatch]);

    return {
        build,
        buildSegments, tempCanvasBuildSpans,
        displayUnits,
    };
};