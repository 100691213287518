import { Typography } from '@orbit/theme-provider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './cabinet-port-number-automatic.scss';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useCabinetPortNumberAutomaticDialog } from './cabinet-port-number-automatic-dialog.hooks';

export const CabinetPortNumberAutomaticDialog = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        isOpen,
        title,
        onSave,
        onClose,
    } = useCabinetPortNumberAutomaticDialog();

    return (
        !isOpen ? null :
            <DialogComponent
                open={isOpen}
                title={title}
                positiveTitle={t(LocalizationKeys.Update)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={onSave}
                onNegativeClick={onClose}
                onClose={onClose}
            >
                <div className="auto-port-number-content">
                    <Typography variant={'body2'} label={t(LocalizationKeys.AutomaticPortUpdateConfirmation)} />
                </div>
            </DialogComponent>
    );
};