import { IPointData } from 'pixi.js';

import { BuildSegment } from '../../../models/build-segment';
import { SchrodingerBuild } from '../../../models/schrodinger-build';
import { Units } from '../../../models/units';

// Unit for the values is pixels

export const CABLE_START_POSITION = {
    x: -22,
    y: 0
};

export const CO_PROPORTIONS = {
    WIDTH: 64,
    HEIGHT: 128,
    TEXT_TOP_POSITION: 22,
    ARROW_TOP_POSITION: 74.28
};

export const LOCATION_PROPORTIONS = {
    RADIUS: 22,
    BORDER: 4,
    GAP: 332

};

export const LOCATION_TEXT_HIGHLIGHT_PROPORTIONS = {
    RADIUS: 4,
    WIDTH: 42,
    HEIGHT: 23,
    X_SHIFT: - 21,
    Y_SHIFT: 28
};

export const SEGMENT_PROPORTIONS = {
    WIDTH_CONNECTOR: 322,
    WIDTH_START: 222,
    WIDTH_END: 189,
    HEIGHT_END: 24,
    TEXT_TOP_POSITION: 28,
    TOOLBAR_Y_POSITION: -54,
    SLACK_TOOLBAR_Y_POSITION: -66,
    SLACK_TEXT_X_OFFSET: 88,
    SLACK_TEXT_Y_OFFSET: 3,
};

export const HIGHLIGHT_PROPORTIONS = {
    RADIUS: 44,
    BORDER: 24,
    ALPHA: 0.2
}

export const LOCATION_COLORS = {
    DEFAULT: 0xFAFAFA,
    HIGHLIGHTED: 0x005293,
    HOVERED: 0x005293,
    BORDER: 0x616161,
    LOCKED: 0xebebeb,
    BORDER_LOCKED: 0xB0B0B0,
    PREVIEWED: 0x000000
};

export const HIGHLIGHT_DELETE_COLORS = {
    DELETE_HIGHLIGHTED: 0xC60C30
}
export const HIGHLIGHT_DELETE_PROPORTIONS = {
    RADIUS: 44,
    BORDER: 24,
    ALPHA: 0.2,
    WIDTH: 300,
    HEIGHT: 25,
    SEGMENT_X_POSITION: 0,
    SEGMENT_Y_POSITION: -13
}



export const TOOLBAR_PROPORTIONS = {
    ITEM_WIDTH: 48,
    ITEM_HEIGHT: 48,
    ROUNDED_CORNER: 4
}

export const TOOLTIP_PROPORTIONS = {
    ARROW_HEIGHT: 6,
    ARROW_WIDTH: 12,
    TOOLTIP_HEIGHT: 22,
    TEXT_HEIGHT_OFFSET: 6
}

export const BUTTON_PROPORTIONS = {
    X_AXIS: 85,
    Y_AXIS: -18,
};

export const LAYER_Z_INDICES = {
    STATUS_BUTTON: 1
}

export const cableStartLocation = (buildPosition: IPointData): IPointData => {
    return {
        x: buildPosition.x + CABLE_START_POSITION.x + (CO_PROPORTIONS.WIDTH / 2),
        y: buildPosition.y + CABLE_START_POSITION.y + (CO_PROPORTIONS.HEIGHT / 2)
    };
};

export interface IFrameProps {
    position: IPointData;
}

export interface IIndexedFrameProps extends IFrameProps {
    index: number;
}

export interface CanvasBuildProps {
    build?: SchrodingerBuild;
    segments?: BuildSegment[];
    temporarySpans?: number[];
    displayUnits?: Units;
}