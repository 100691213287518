import './preterm-lateral.dialog.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useRevertPretermLateralDialog } from './revert-preterm.hooks';

export const RevertPretermDialog = (): JSX.Element => {
    const { t } = useTranslation();
    const { open, onClose, onCancel, onRevertPretermLateral, pretermBuildId } = useRevertPretermLateralDialog();

    return (
        <DialogComponent
            open={open}
            message={t(LocalizationKeys.RevertPretermConfirmation, { buildId: pretermBuildId })}
            positiveTitle={t(LocalizationKeys.RevertPreterm)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onClose={onClose}
            onNegativeClick={onCancel}
            onPositiveClick={onRevertPretermLateral}
        />
    );
};
