import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogType, showDialog } from '../../../redux/overlay.state';

import { startReview } from '../../../redux/review.state';
import { activeBuildSelector } from '../../../selectors/build.selectors';
import { overlaySelector } from '../../../selectors/root.selectors';

export const useRequestReviewDialog = () => {
    const dispatch = useDispatch();

    const { dialog } = useSelector(overlaySelector);
    const build = useSelector(activeBuildSelector);

    const [notes, setNotes] = useState("");

    const open = dialog === DialogType.ReviewRequest;

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.currentTarget.value);
    };

    const handleClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const handleSubmitClick = useCallback(() => {
        if (build)
            dispatch(startReview(build.id, notes));
        handleClose();
    }, [build, dispatch, handleClose, notes]);


    return {
        open,
        build,
        notes,
        handleClose,
        handleNotesChange,
        handleSubmitClick,
    }
};
