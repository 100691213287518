import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../locales/types';
import { DesignMode } from '../../models/design-mode';
import { Review } from '../../models/review';
import CorningRoles from '../../redux/corning-roles.json';
import { ControlType, setShowNotificationPopup, showControl } from '../../redux/overlay.state';
import {
    designerResolvedReviewsSelector, reviewerReviewRequestsSelector
} from '../../selectors/review.selectors';
import { designModeSelector, overlaySelector, rolesSelector } from '../../selectors/root.selectors';

const setReviews = (reviews) => {
    if (shouldUpdateStorage(reviews)) {
        localStorage.setItem('reviews', JSON.stringify(reviews));
    }
}

const getReviews = () => {
    const reviews = localStorage.getItem('reviews');
    if (reviews !== null) {
        return JSON.parse(reviews);
    }
    else {
        return undefined;
    }
}

const shouldUpdateStorage = (reviewRequests: Review[]) => {
    const reviews = getReviews();
    if (!reviews || reviews.length !== reviewRequests.length) {
        return true;
    }
    else {
        reviews.sort((a, b) => a.buildId - b.buildId);
        reviewRequests.sort((a, b) => a.buildId - b.buildId);
        for (let i = 0; i < reviews.length; i++) {
            if (JSON.stringify(reviews[i]) !== JSON.stringify(reviewRequests[i]))
                return true;
        }
    }
    return false;
}

export const useNotificationPopup = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { control } = useSelector(overlaySelector);
    const { roles } = useSelector(rolesSelector);
    const requestedReviews = useSelector(reviewerReviewRequestsSelector);
    const resolvedReviews = useSelector(designerResolvedReviewsSelector);
    const designMode = useSelector(designModeSelector);
    const { showReviewNotification } = useSelector(overlaySelector);

    const showReviews = roles && (roles.some((role) => role === CorningRoles.Schrodinger));
    const showRequested = requestedReviews.length > 0;
    const showResolved = resolvedReviews.length > 0;

    const isOpen = control === ControlType.ReviewNotification;
    const requestedText = showRequested ? t(LocalizationKeys.ReviewRequestNotification, { reviewCount: requestedReviews.length }) : undefined;
    const resolvedText = showResolved ? t(LocalizationKeys.ReviewResolvedNotification, { resolvedCount: resolvedReviews.length }) : undefined;

    const notificationTextClasses = classNames({
        'single': !showRequested || !showResolved
    });

    const notchClasses = classNames({
        'notch': true,
        'gis': designMode === DesignMode.GISMode,
        'canvas': designMode === DesignMode.CanvasMode
    });

    const popupClasses = classNames({
        'popup': true,
        'single': !showRequested || !showResolved
    })

    const handleDismiss = useCallback((): void => {
        setReviews([...resolvedReviews, ...requestedReviews]);
        dispatch(setShowNotificationPopup(false));
        dispatch(showControl());
    }, [dispatch, resolvedReviews, requestedReviews]);

    useEffect(() => {
        const cacheFlag = shouldUpdateStorage([...resolvedReviews, ...requestedReviews]);
        if (showReviewNotification && cacheFlag && showReviews && (showRequested || showResolved)) {
            dispatch(showControl(ControlType.ReviewNotification));
        }
    }, [dispatch, resolvedReviews, requestedReviews, showRequested, showResolved, showReviews, showReviewNotification])

    return {
        isOpen,
        reviewNotifications: [requestedText, resolvedText],
        notificationTextClasses,
        notchClasses,
        popupClasses,
        handleDismiss
    }
}