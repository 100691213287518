import { MapClickEvent } from '../models/map-click-event';
import { store } from '../dna';
import { addVault } from '../redux/vault.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class VaultTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Vault,
        name: 'Vault',
        shortcut: 'V',
    };

    public static execute(event: MapClickEvent): void {
        addVault({ x: event.x, y: event.y })(store.dispatch);
    }
}
