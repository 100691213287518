import './edit-text.scss';

import classNames from 'classnames';
import React from 'react';

import { FilledTextField, OutlinedTextField, TextField } from '@orbit/text-field';
import { Typography } from '@orbit/theme-provider';

interface EditTextProps {
    label?: string;
    value: any;
    type: string;
    name: string;
    min?: number;
    max?: number;
    disabled?: boolean;
    trailingIcon?: any;
    units?: string;
    helperText?: string;
    error?: boolean;
    step?: number | string;
    small?: boolean;
    characterCounter?: number;
    maxCharacterLength?: number;
    variant?: 'filled' | 'outlined' | 'standard';
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<any>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const EditText = (props: EditTextProps) => {

    const { label, value, type, name, small, disabled, helperText, characterCounter, maxCharacterLength, step, variant, onKeyDown, onChange, trailingIcon, units, error, onFocus, onBlur } = props;

    const textFieldClasses = classNames({
        'card-input': maxCharacterLength,
        'helper-text-error': helperText && error,
        'helper-counter': maxCharacterLength && !helperText && (characterCounter !== undefined),
        'max-characters': maxCharacterLength && (characterCounter === maxCharacterLength),
    })

    const getHelperText = () => {
        return <Typography variant='helperText' label={helperText ? helperText : maxCharacterLength ? `${characterCounter}/${maxCharacterLength}` : ""} />
    }

    let TextFieldVariant = FilledTextField;
    switch (variant) {
        case "outlined":
            TextFieldVariant = OutlinedTextField;
            break;
        case "standard":
            TextFieldVariant = TextField;
            break;
    }

    return (
        <div className="edit-text-container">
            <div className={`text-field-container ${units ? 'with-units' : ''}`}>
                <TextFieldVariant
                    className={textFieldClasses}
                    label={label}
                    palette="primary"
                    helperText={getHelperText()}
                    inputProps={{ maxLength: maxCharacterLength, step: step }}
                    value={value}
                    error={error}
                    name={name}
                    type={type}
                    disabled={disabled}
                    size={small ? "small" : "medium"}
                    onKeyDown={onKeyDown} onChange={onChange} onFocus={onFocus} onBlur={onBlur}
                    units={units}
                    adornmentPosition="end"
                />
            </div>
            {trailingIcon}
        </div>);
}

