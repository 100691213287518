import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { Tap } from '../../../models/schrodinger/tap';
import { linkTapsToBuild, unlinkTapsFromBuild } from '../../../redux/schrodinger/tap.state';
import { Command } from '../../command';

export interface LinkTapsPayload {
    isSubCommand?: boolean;
    taps: Tap[];
    buildId: number;
}

export class LinkTapsCommand extends Command {
    private taps: Tap[];
    private buildId: number;

    constructor ({ taps, buildId, isSubCommand = false }: LinkTapsPayload) {
        super();
        this.isSubCommand = isSubCommand;
        this.taps = taps;
        this.buildId = buildId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoLinkTaps };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoLinkTaps };
    }

    public undo(dispatch: Dispatch): void {
        unlinkTapsFromBuild(this.taps)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        linkTapsToBuild(this.taps, this.buildId, true)(dispatch);
        super.redo(dispatch);
    }
}