import React from 'react';

import { ToolItem } from '../../ui-elements/tool-item';
import { Redo } from '../icons/redo';
import { Undo } from '../icons/undo';
import { useHistoryTool } from './history-tool.hooks';

export const HistoryTool = (): JSX.Element | null => {
    const {
        undo,
        redo,
        undoMessage,
        redoMessage,
        undoDisabled,
        redoDisabled
    } = useHistoryTool();

    return (
        <div className="history-container">
            <ToolItem source={<Undo />} tooltip={undoMessage} onClick={undo} disabled={undoDisabled} />
            <ToolItem source={<Redo />} tooltip={redoMessage} onClick={redo} disabled={redoDisabled} />
        </div>
    );
};
