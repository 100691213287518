import React from 'react';

import { CabinetSplicePlanManager } from './cabinet-splice-plan-manager';
import { DesignModeManager } from './design-mode-manager';
import { EditManager } from './edit-manager';
import { PartManager } from './part-manager';
import { PathManager } from './path-manager';
import { PretermManager } from './preterm-manager';
import { SplicePointManager } from './splice-point-manager';
import { UserManager } from './user-manager';
import { ValidationManager } from './validation-manager';
import { WorkspaceManager } from './workspace-manager';

export const DataManager = () => {
    return (
        <>
            <UserManager />
            <EditManager />
            <PretermManager />
            <PathManager />
            <ValidationManager />
            <PartManager />
            <SplicePointManager />
            <CabinetSplicePlanManager />
            <DesignModeManager />
            <WorkspaceManager />
        </>
    );
}