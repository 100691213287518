import { Nap } from "../models/nap";
import { Tap } from "../models/tap";
import { Tether } from "../models/tether";
import { SecuredService } from './abstract-secured-v2.service';

export interface ANTTResult {
    tap: Tap;
    nap: Nap;
    tether: Tether;
}
export class TapService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/tap';

    public addTap(buildId: number | null, napId: number, tapIndex: number): Promise<Tap | undefined> {
        return this.post<Tap>(this.baseUrl, { buildId, napId, tapIndex });
    }

    public deleteTap(tap: Tap): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${tap.id}`);
    }

    public getAllTaps(): Promise<Tap[] | undefined> {
        return this.get(this.baseUrl);
    }
}
