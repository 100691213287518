import React from 'react';
import { WorkspaceDeleteDialog } from '../../../../dialogs/workspace/workspace-delete-dialog';
import { WorkspaceToolbarProps } from './drawer-workspace-toolbar.types';
import { useWorkspaceToolbar } from './drawer-workspace-toolbar.hooks';

import './drawer-workspace-toolbar.scss';

export const WorkspaceToolbar = (props: WorkspaceToolbarProps) => {
    const { deleteDisabled, onDeleteClick, onEditClick, expandIcon, onExpandClick, workspace } = useWorkspaceToolbar(props);
    return (
        <div className="workspace-drawer-toolbar">
            <button className="icon-button" disabled={deleteDisabled} onClick={onDeleteClick}>
                <i className="material-icons">delete</i>
            </button>
            <button className="icon-button" onClick={onEditClick}>
                <i className="material-icons">edit</i>
            </button>
            <button className="icon-button" onClick={onExpandClick}>
                <i className="material-icons">{expandIcon}</i>
            </button>
            <WorkspaceDeleteDialog workspace={workspace} />
        </div>
    );
}