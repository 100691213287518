import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { deleteSchrodingerCable, undoDeleteSchrodingerCable } from '../../../redux/build.state';
import { Command } from '../../command';
import { UnlinkTapsCommand, UnlinkTapsPayload } from '../tap/unlink-tap-command';

export interface DeleteCablePayload {
    buildId: number;
    workspaceId: number;
    unlinkTapsPayload: UnlinkTapsPayload;
}

export class DeleteCableCommand extends Command {
    private buildId: number;
    private workspaceId: number;
    private unlinkTapsCommand: UnlinkTapsCommand

    constructor({ buildId, workspaceId, unlinkTapsPayload }: DeleteCablePayload) {
        super();
        this.buildId = buildId;
        this.workspaceId = workspaceId;
        this.unlinkTapsCommand = new UnlinkTapsCommand({ ...unlinkTapsPayload, isSubCommand: true });
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoCableDeletion };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoCableDeletion };
    }

    public async undo(dispatch: Dispatch): Promise<void> {
        await undoDeleteSchrodingerCable(this.buildId)(dispatch);
        this.unlinkTapsCommand.undo(dispatch);
        super.undo(dispatch);
    }

    public async redo(dispatch: Dispatch): Promise<void> {
        this.unlinkTapsCommand.redo(dispatch);
        await deleteSchrodingerCable(this.buildId, this.workspaceId, [], true)(dispatch);
        super.redo(dispatch);
    }
}
