import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonGroup } from '@mui/material';
import { Snackbar } from '@rmwc/snackbar/dist/snackbar';
import { ContainedButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { SnackbarTypes } from '../../ui-elements/generic-snackbar/generic-snackbar.types';
import { useCableSelectionSnackbar } from './cable-selection-snackbar.hooks';


export const CableSelectionSnackbar = (): JSX.Element | null => {
    const { t } = useTranslation();
    const { open, actions } = useCableSelectionSnackbar();

    return (
        <div className="snackbar-container">
            <Snackbar
                open={open}
                className={`generic-snackbar ${SnackbarTypes.Selection}`}
                action={
                    <Fragment>
                        <ButtonGroup className="generic-snackbar-action-button-group">
                                {
                                    actions.map(action => 
                                        <ContainedButton key={`${action.label}-${action.value}`} className="button-group-button"
                                            disabled={action.onProperty === action.value}
                                            onClick={action.onAction}
                                            value={action.value}
                                        >
                                            {action.label}
                                        </ContainedButton>
                                    )
                                }
                        </ButtonGroup>
                        <div className="generic-snackbar-tip"><strong>{t(LocalizationKeys.Tip)}</strong>{t(LocalizationKeys.EndCableModification)}</div>
                    </Fragment>
                }
                dismissIcon={false}
                timeout={-1}
            />
        </div>
    );
};