import { Dispatch } from 'redux';
import { Command } from '../../command';
import { undoDeleteFlexNapCable, deleteFlexNapCable } from '../../../redux/build.state';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class DeleteCableCommand extends Command {
    private buildId: number;
    private childPretermBuilds: FlexNapBuild[];

    constructor({ id: buildId, childPretermBuilds }) {
        super();
        this.buildId = buildId;
        this.childPretermBuilds = childPretermBuilds;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildDeleting };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildDeleting };
    }

    public undo(dispatch: Dispatch): void {
        undoDeleteFlexNapCable(this.buildId, this.childPretermBuilds)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        deleteFlexNapCable(this.buildId, this.childPretermBuilds, true)(dispatch);
        super.redo(dispatch);
    }
}