import React from 'react';
import { useTranslation } from 'react-i18next';
import { TapDiagramProps } from '../../nap-diagram.types';
import { LocalizationKeys } from '../../../../locales/types';
import { TextSvg } from '../text-svg';
import { useTapDiagramSvg } from './tap-diagram-svg.hooks';

export const TapDiagramSvg = (props: TapDiagramProps): JSX.Element => {
    const { t } = useTranslation();
    const { tapIndex, className, x } = useTapDiagramSvg(props);

    return (
        <g transform={`translate(${x + 50} 3)`}>
            <rect className={className} width="88" height="30" rx="4" />
            <TextSvg x={44} y={15} text={t(LocalizationKeys.TapNumber, { tapIndex: tapIndex })} />
        </g>
    );
};
