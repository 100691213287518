import React from 'react';
import './dna-spinner.scss';

interface SpinnerProps {
    width?: number;
}

export class DnaSpinner extends React.Component<SpinnerProps> {
    public render(): JSX.Element {
        return (
            <svg className="dna-spinner" width={this.props.width ?? 36} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="4" strokeLinecap="round" cx="18" cy="18" r="16"></circle>
            </svg>
        );
    }
}
