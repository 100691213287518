import React from 'react';

export const WorkspaceIcon = (props: { opacity: number }) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g transform="translate(-632 -271)">
                    <circle opacity={props.opacity} fill="#005692" cx="20" cy="20" r="20" transform="translate(632 271)"/>
                    <g opacity="0.9" transform="translate(640 279)">
                        <rect fill="none" width="24" height="24"/>
                        <path fill="#fdfdfd" d="M20,6H12L10,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,20,6ZM14,16H6V14h8Zm4-4H6V10H18Z"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}