import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { deleteSchrodingerBuilds, undoDeleteSchrodingerBuilds } from '../../../redux/build.state';
import { Command } from '../../command';

export interface DeleteBuildsPayload {
    buildIds: number[],
    tapIds: number[],
    workspaceId?: number,
}

export class DeleteBuildsCommand extends Command {
    private buildIds: number[];
    private tapIds: number[];
    private workspaceId?: number;

    constructor({ buildIds, tapIds, workspaceId }: DeleteBuildsPayload) {
        super();
        this.buildIds = buildIds;
        this.tapIds = tapIds;
        this.workspaceId = workspaceId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildDeleting };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildDeleting };
    }

    public undo(dispatch: Dispatch): void {
        undoDeleteSchrodingerBuilds(this.buildIds, this.tapIds)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        deleteSchrodingerBuilds(this.buildIds, this.tapIds, this.workspaceId, true)(dispatch);
        super.redo(dispatch);
    }
}
