import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ToolType } from '../design-tools/tooltype';
import { BuildType } from '../models/build-type';
import CorningRoles from '../redux/corning-roles.json';

export interface ToolState {
    buildType: BuildType;
    selectedTool: ToolType;
}

const initialBuildType = localStorage.getItem('build-type');
const initialState: ToolState = {
    buildType: +(initialBuildType ?? BuildType.FlexNap),
    selectedTool: ToolType.Selection,
};

function setBuildTypeReducer(state: ToolState, action: PayloadAction<BuildType>) {
    state.buildType = action.payload;
    localStorage.setItem('build-type', action.payload.toString());
}

function changeSelectedTool(state: ToolState, action: PayloadAction<ToolType>) {
    state.selectedTool = action.payload;
}

const toolSlice = createSlice({
    name: 'tool',
    initialState,
    reducers: {
        setBuildType: setBuildTypeReducer,
        setSelectedTool: changeSelectedTool,
    },
});

const { actions, reducer } = toolSlice;
export const { setBuildType, setSelectedTool } = actions;
export { reducer as ToolReducer };

export const loadBuildType = (roles: string[] | undefined) => async (dispatch) => {
    if (roles?.some((role) => role === CorningRoles.Schrodinger)) {
        dispatch(setBuildType(BuildType.Schrodinger));
    }
    else if (roles?.some((role) => role === CorningRoles.FlexNAP || role === CorningRoles.FlexNAPAdmin)) {
        dispatch(setBuildType(BuildType.FlexNap));
    }
};
