import { store } from '../dna';
import { MapClickEvent } from '../models/map-click-event';
import { MapItemDefinition } from '../models/map-item-definition';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { DialogType, showDialog } from '../redux/overlay.state';
import { StateModel } from '../redux/reducers';
import { clearSelection, selectPretermNap } from '../redux/selection.state';
import { NotificationService } from '../services/notification.service';

export class PretermLateralTool {

    public execute(event: MapClickEvent): void {
        const selectedItem = event.items && event.items.length && event.items[0]; // first feature is topmost
        const state = store.getState();
        const selectedBuildId = state.selection.selectedBuildId;
        if (!selectedItem) {
            return;
        }
        const featureId = selectedItem.id;

        if (!featureId || !selectedBuildId) {
            return;
        }

        if (this.canConvertToPreterm(state, selectedBuildId, featureId, selectedItem)) {
            store.dispatch(selectPretermNap(featureId));
            store.dispatch(showDialog(DialogType.ConvertToPretermLateral));
        }
        else {
            store.dispatch(clearSelection());
            NotificationService.error("Impossible to create a preterm lateral to the selected NAP.");
        }
    }

    private canConvertToPreterm(state: StateModel, selectedBuildId: number, selectedNapId: number, selectedItem: MapItemDefinition): boolean {
        const builds = state.build.flexnapBuilds;
        const naps = state.taps.naps;
        const segments = state.build.segments;

        const selectedNapBuildId = selectedItem.buildId;
        // if the NAP isn't assigned or the NAP is already assigned to the build
        if (selectedItem.type !== InteractableFeatureType.NAP || !selectedNapBuildId || selectedBuildId === selectedNapBuildId) {
            return false;
        }

        // if the build is locked or already is a pretermLateral
        const buildToConvert = builds.find((b) => b.id === selectedBuildId);
        if (!buildToConvert || buildToConvert.lockedById || buildToConvert.pretermLateral) {
            return false;
        }

        const nap = naps.find((n) => n.id === selectedNapId);
        const parentBuild = builds.find((b) => b.id === nap?.buildId);
        if (!nap || !parentBuild) {
            return false;
        }

        // check if preterm lateral already exists on that NAP
        const hasExistingPreterm = builds.some((b) => b.pretermLateral?.parentNapId === selectedNapId);
        if (hasExistingPreterm) {
            return false;
        }

        const sortedBuildSegments = segments.filter((s) => s.buildId === selectedBuildId).sort((a, b) => a.position - b.position);
        // check if build has first pole location (CO end) at the parent NAP
        const isFirstSegment = sortedBuildSegments.length > 0 && sortedBuildSegments[0].fromId === nap.elementId;
        if (!isFirstSegment) {
            return false;
        }

        return true;
    }

}
