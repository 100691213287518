import './layers-menu.scss';

import React, { Component } from 'react';

import { Layer } from '../../redux/layer.state';

interface ILayersMenuState {
    hiddenGroups: string[];
}

interface ILayersMenuProps {
    items: Layer[];
    visible?: boolean;
    onLayerItemSelected: (id: string) => void;
    onLayerGroupSelected: (group: string) => void;
    onCloseMenu?: () => void;
}

export class LayersMenu extends Component<ILayersMenuProps, ILayersMenuState> {

    public state: ILayersMenuState = {
        hiddenGroups: [],
    };

    constructor(props: any) {
        super(props);
        const savedHiddenLayersStr = localStorage.getItem('hidden-layers');
        let savedHiddenLayers: string[];
        try {
            savedHiddenLayers = savedHiddenLayersStr ? JSON.parse(savedHiddenLayersStr) : [];
        }
        catch {
            savedHiddenLayers = [];
        }
        this.state.hiddenGroups = savedHiddenLayers;
    }

    private renderLayer = (layer: Layer) => {
        const onClick = () => {
            this.props.onLayerItemSelected!(layer.id);
        };
        return (
            <li key={layer.id} id={layer.id} className="item" onClick={onClick}>
                <img alt={layer.visible ? 'Enabled' : 'Disabled'} src={layer.visible ? require('./icon_show.svg') : require('./icon_hide.svg')} /><span>{layer.name}</span>
            </li>
        );
    }

    private renderLayerGroup = (group: string, layers: Layer[]) => {
        const isHidden = this.state.hiddenGroups.includes(group);
        const inner = isHidden ? null : layers.map((l) => this.renderLayer(l));
        const onClickCollapse = () => {
            const newGroups = isHidden ?
                this.state.hiddenGroups.filter((g) => g !== group) :
                [...this.state.hiddenGroups, group];
            localStorage.setItem('hidden-layers', JSON.stringify(newGroups));
            this.setState({ hiddenGroups: newGroups });
        };
        const visible = layers.some((l) => l.visible);
        const visibilityIcon = visible ? require('./icon_show.svg') : require('./icon_hide.svg');

        const onClickHide = (e: React.MouseEvent<any>) => {
            e.stopPropagation();
            this.props.onLayerGroupSelected(group);
        };

        return (
            <ul key={group}>
                <li id={group} className="group" onClick={onClickCollapse}>
                    <img className="icon" alt={group + ' Group'} src={require('./icon_folder.svg')} />
                    <img className="icon" alt={group + ' Group'} src={visibilityIcon} onClick={onClickHide} />
                    {group}
                </li>
                {inner}
            </ul>
        );
    }

    private renderList = () => {
        const groupedItems = this.groupByGroup(this.props.items);
        const groups = Object.keys(groupedItems);
        return groups.map((g) => this.renderLayerGroup(g, groupedItems[g]));
    }

    public render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <div className="layers-menu">
                <div className="layers-menu-header">
                    <span><label className="title">Layers</label></span>
                    <button className="icon-button" onClick={this.props.onCloseMenu}>
                        <img alt="Close menu" src={require('./../card/icon_close.svg')} />
                    </button>
                </div>
                {this.renderList()}
            </div>
        );
    }

    private groupByGroup(arr: Layer[]): { [group: string]: Layer[] } {
        const init: { [group: string]: Layer[] } = {};
        return arr.reduce((prev, l) => {
            if (!prev[l.group]) {
                prev[l.group] = [];
            }
            prev[l.group].push(l);
            return prev;
        }, init);
    }
}
