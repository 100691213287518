/*
 * Inspired by the shp.js , dbf.js by Mano Marks
 *
 * I found there were something wrong to show chinese characters from DBF file,
 * so i added some code that is needed to deal with this problem.
 *
 * Created by Gipong <sheu781230@gmail.com>
 *
 */

import jszip from 'jszip';

import { GeoJsonFeatureCollection } from '../../models/geojson';

export class GeoJsonConverter {
    public static convert(data: IGeoSet): GeoJsonFeatureCollection {
        return this.toGeojson(data);
    }

    private static toGeojson(geojsonData: { shp: ISHPData, dbf: IDBFData }) {
        const geojson: any = {};
        const features: any[] = [];

        const shpRecords = geojsonData.shp.records;
        const dbfRecords = geojsonData.dbf.records;

        geojson.type = 'FeatureCollection';
        const min = this.transCoord(geojsonData.shp.minX, geojsonData.shp.minY);
        const max = this.transCoord(geojsonData.shp.maxX, geojsonData.shp.maxY);
        geojson.bbox = [
            min.x,
            min.y,
            max.x,
            max.y,
        ];

        geojson.features = features;

        for (let i = 0; i < shpRecords.length; i++) {
            const feature: any = {};
            const geometry: any = {};
            const shape = shpRecords[i].shape;
            feature.type = 'Feature';
            feature.properties = dbfRecords[i];
            feature.geometry = geometry;

            if (!shape) {
                continue;
            }

            if (shape.type === 1) {
                geometry.type = 'Point';
                const point = shape.content as IPoint;
                const pointCoords = this.transCoord(point.x, point.y);
                geometry.coordinates = [
                    pointCoords.x, pointCoords.y,
                ];
            } else if (shape.type === 3 || shape.type === 8) {
                geometry.type = (shape.type === 3 ? 'LineString' : 'MultiPoint');
                geometry.coordinates = [];
                const line = shape.content as IPolygon;
                for (let j = 0; j < line.points.length; j += 2) {
                    const reprj = this.transCoord(line.points[j], line.points[j + 1]);
                    geometry.coordinates.push(reprj.x, reprj.y);
                }
            } else if (shape.type === 5) {
                geometry.type = 'Polygon';
                geometry.coordinates = [];
                const polygon = shape.content as IPolygon;

                for (let pts = 0; pts < polygon.parts.length; pts++) {
                    const partsIndex = polygon.parts[pts];
                    const part: number[][] = [];

                    for (let j = partsIndex * 2; j < (polygon.parts[pts + 1] * 2 || polygon.points.length); j += 2) {
                        const points = polygon.points;
                        const reprj = this.transCoord(points[j], points[j + 1]);
                        part.push([reprj.x, reprj.y]);
                    }
                    geometry.coordinates.push(part);

                }
            }

            if ('coordinates' in feature.geometry) {
                features.push(feature);
            }
        }
        return geojson;
    }

    private static transCoord(x: number, y: number) {
        return { x, y };
    }
}

// tslint:disable-next-line: max-classes-per-file
type ZipLoadResult = { shpName: string, shpBuffer: ArrayBuffer, dbfName: string, dbfBuffer: ArrayBuffer };
export class ZIPParser {
    public static async load(zipFile: File): Promise<ZipLoadResult[]> {
        const result: ZipLoadResult[] = [];
        const zip = await jszip.loadAsync(zipFile);
        const shpFiles = zip.file(/\.shp$/i);
        const dbfFiles = zip.file(/\.dbf$/i);

        if (!zip.files || !Object.keys(zip.files).length) {
            throw new Error('ZIP file is empty');
        }
        if (!shpFiles.length && !dbfFiles.length) {
            throw new Error('ZIP file contains neither .shp or .dbf files');
        }
        if (!shpFiles.length) {
            throw new Error('ZIP file contains no .shp files');
        }
        if (!dbfFiles.length) {
            throw new Error('ZIP file contains no .dbf files');
        }

        const pairs: { dbfFile: any, shpFile: any }[] = [];
        for (const dbfFile of dbfFiles) {
            const shpFilename = dbfFile.name.replace('.dbf', '.shp');
            const shpFile = shpFiles.find((f) => f.name === shpFilename);
            if (!shpFile) {
                throw new Error(`Unable to find SHP file '${shpFilename}' for DBF file '${dbfFile.name}'`);
            }
        }

        for (const shpFile of shpFiles) {
            const dbfFilename = shpFile.name.replace('.shp', '.dbf');
            const dbfFile = dbfFiles.find((f) => f.name === dbfFilename);
            if (!dbfFile) {
                throw new Error(`Unable to find DBF file '${dbfFilename}' for SHP file '${shpFile.name}'`);
            }
            pairs.push({ dbfFile, shpFile });
        }

        for await (const pair of pairs) {
            const shpName = pair.shpFile.name;
            const dbfName = pair.dbfFile.name;
            const shpBuffer = await pair.shpFile.async('arraybuffer');
            const dbfBuffer = await pair.dbfFile.async('arraybuffer');
            result.push({ shpName, shpBuffer, dbfName, dbfBuffer });
        }

        return result;
    }
}

// Shapefile parser, following the specification at
// http://www.esri.com/library/whitepapers/pdfs/shapefile.pdf
// tslint:disable-next-line: max-classes-per-file
export class SHP {
    public static NULL = 0;
    public static POINT = 1;
    public static POLYLINE = 3;
    public static POLYGON = 5;

    public static getShapeName(id: number) {
        switch (id) {
            case 0: return 'NULL';
            case 1: return 'POINT';
            case 3: return 'POLYLINE';
            case 5: return 'POLYGON';
            default: throw new Error('Unknown shape type: ' + id);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class SHPParser {
    public static parse(arrayBuffer: ArrayBuffer, url: string): ISHPData {
        const dv = new DataView(arrayBuffer);
        let idx = 0;

        const fileName = url;
        const fileCode = dv.getInt32(idx, false);
        if (fileCode !== 9994) {
            throw (new Error('Unknown file code: ' + fileCode));
        }
        idx += 6 * 4;
        const wordLength = dv.getInt32(idx, false);
        const byteLength = wordLength * 2;
        idx += 4;
        const version = dv.getInt32(idx, true);
        idx += 4;
        const shapeType = dv.getInt32(idx, true);
        idx += 4;
        const minX = dv.getFloat64(idx, true);
        const minY = dv.getFloat64(idx + 8, true);
        const maxX = dv.getFloat64(idx + 16, true);
        const maxY = dv.getFloat64(idx + 24, true);
        const minZ = dv.getFloat64(idx + 32, true);
        const maxZ = dv.getFloat64(idx + 40, true);
        const minM = dv.getFloat64(idx + 48, true);
        const maxM = dv.getFloat64(idx + 56, true);
        idx += 8 * 8;
        const records: IRecord[] = [];
        while (idx < byteLength) {
            const num = dv.getInt32(idx, false);
            idx += 4;
            const length = dv.getInt32(idx, false);
            idx += 4;
            const record: IRecord = { number: num, length, shape: null };
            try {
                record.shape = this.parseShape(dv, idx);
            }
            catch (e) {
                let errorMsg = "";
                if (e instanceof Error) {
                    errorMsg = e.message;
                }
                throw new Error("Error while preprocessing file - " + errorMsg);
            }
            idx += length * 2;
            records.push(record);
        }
        const shpData: ISHPData = {
            fileName,
            fileCode,
            wordLength,
            byteLength,
            version,
            shapeType,
            minX, minY,
            maxX, maxY,
            minZ, maxZ,
            minM, maxM,
            records,
        };
        return shpData;
    }

    private static parseShape(dv: DataView, idx: number) {
        const shape: IShape = {
            type: dv.getInt32(idx, true),
            content: null,
        };
        idx += 4;
        switch (shape.type) {
            case SHP.NULL: // Null
                break;

            case SHP.POINT: // Point (x,y)
                shape.content = {
                    x: dv.getFloat64(idx, true),
                    y: dv.getFloat64(idx + 8, true),
                };
                break;
            case SHP.POLYLINE: // Polyline (MBR, partCount, pointCount, parts, points)
            case SHP.POLYGON: // Polygon (MBR, partCount, pointCount, parts, points)
                // eslint-disable-next-line no-case-declarations
                const c: IPolygon = {
                    minX: dv.getFloat64(idx, true),
                    minY: dv.getFloat64(idx + 8, true),
                    maxX: dv.getFloat64(idx + 16, true),
                    maxY: dv.getFloat64(idx + 24, true),
                    parts: new Int32Array(dv.getInt32(idx + 32, true)),
                    points: new Float64Array(dv.getInt32(idx + 36, true) * 2),
                };
                idx += 40;
                for (let i = 0; i < c.parts.length; i++) {
                    c.parts[i] = dv.getInt32(idx, true);
                    idx += 4;
                }
                for (let i = 0; i < c.points.length; i++) {
                    c.points[i] = dv.getFloat64(idx, true);
                    idx += 8;
                }
                shape.content = c;
                break;

            case 8: // MultiPoint (MBR, pointCount, points)
            case 11: // PointZ (X, Y, Z, M)
            case 13: // PolylineZ
            case 15: // PolygonZ
            case 18: // MultiPointZ
            case 21: // PointM (X, Y, M)
            case 23: // PolylineM
            case 25: // PolygonM
            case 28: // MultiPointM
            case 31: // MultiPatch
                throw new Error('Shape type not supported: '
                    + shape.type + ':' +
                    + SHP.getShapeName(shape.type));
            default:
                throw new Error('Unknown shape type at ' + (idx - 4) + ': ' + shape.type);
        }
        return shape;
    }
}

/**
 * @fileoverview Parses a .dbf file based on the xbase standards as documented
 * here: http://www.clicketyclick.dk/databases/xbase/format/dbf.html
 * @author Mano Marks
 */

// tslint:disable-next-line: max-classes-per-file
export class DBFParser {
    public static parse(arrayBuffer: ArrayBuffer, fileName: string, encoding: string): IDBFData {
        const dv = new DataView(arrayBuffer);

        const version = dv.getInt8(0);
        const year = dv.getUint8(1) + 1900;
        const month = dv.getUint8(2);
        const day = dv.getUint8(3);

        const numberOfRecords = dv.getInt32(4, true);
        const bytesInHeader = dv.getInt16(8, true);
        const bytesInRecord = dv.getInt16(10, true);
        // 12-13 reserved
        const incompleteTransation = dv.getUint8(14);
        const encryptionFlag = dv.getUint8(15);
        // 16-27 reserved
        const mdxFlag = dv.getUint8(28); // 28
        const languageDriverId = dv.getUint8(29); // 29
        // 30-31 reserved
        const fields = this.parseFields(arrayBuffer, bytesInHeader, encoding);

        const recordBytes = arrayBuffer.slice(bytesInHeader);

        const records = this.parseRecords(recordBytes, numberOfRecords, fields, encoding);

        const dbfData: IDBFData = {
            fileName,
            version,
            year, month, day,
            numberOfRecords,
            bytesInHeader, bytesInRecord,
            incompleteTransation,
            encryptionFlag,
            mdxFlag,
            languageDriverId,
            fields,
            fieldpos: 33,
            records,
        };
        return dbfData;
    }

    private static parseFields(arrayBuffer: ArrayBuffer, bytesInHeader: number, encoding: string): IField[] {
        const fieldDescriptorArray = arrayBuffer.slice(32, bytesInHeader - 1); // exclude terminator 0x0D / 13
        const fieldCount = fieldDescriptorArray.byteLength / 32;
        const fields: IField[] = [];
        for (let i = 0; i < fieldCount; i++) {
            const offset2 = i * 32;
            const fieldBytes = fieldDescriptorArray.slice(offset2, offset2 + 32);
            fields.push(this.parseField(fieldBytes, encoding));
        }
        return fields;
    }

    private static parseField(fieldBytes: ArrayBuffer, encoding = 'utf-8'): IField {
        if (fieldBytes.byteLength !== 32) {
            throw new Error('Parse field expects a 32-byte array buffer');
        }
        const decoder = new TextDecoder(encoding);
        const dv = new DataView(fieldBytes);
        const name = decoder.decode(fieldBytes.slice(0, 10)).replace(/\W/g, '');
        const type = String.fromCharCode(dv.getUint8(11));
        const fieldLength = dv.getUint8(16);

        return { name, type, fieldLength, indexFieldFlag: -1, setFieldFlag: -1, workAreaId: -1 };
    }

    private static parseRecords(recordBytes: ArrayBuffer, numberOfRecords: number, fields: IField[], encoding: string): IProperty[] {
        const records: IProperty[] = [];
        const decoder = new TextDecoder(encoding);
        const recordSize = (1 + fields.reduce((p, f) => p + f.fieldLength, 0));

        for (let i = 0; i < numberOfRecords; i++) {
            const thisRecordBytes = recordBytes.slice(1 + i * recordSize, (i + 1) * recordSize);
            const record: IProperty = {};
            let sizeSoFar = 0;
            for (const field of fields) {
                const valueBytes = thisRecordBytes.slice(sizeSoFar, sizeSoFar + field.fieldLength);
                sizeSoFar += field.fieldLength;
                const value = decoder.decode(valueBytes);

                if (field.type === 'C') {
                    record[field.name] = value.trim();
                }
                else if (field.type === 'N' || field.type === 'F') {
                    record[field.name] = +value.trim();
                }
                else {
                    console.error('Unhandled field type', field.type, 'with value', value);
                    record[field.name] = value.trim();
                }
            }
            records.push(record);
        }
        return records;
    }
}

export interface IGeoSet {
    dbf: IDBFData;
    shp: ISHPData;
}

export interface IDBFData {
    fileName: string;
    version: number;
    year: number;
    month: number;
    day: number;
    numberOfRecords: number;
    bytesInHeader: number;
    bytesInRecord: number;
    incompleteTransation: number;
    encryptionFlag: number;
    mdxFlag: number;
    languageDriverId: number;
    fields: IField[];
    fieldpos: number;
    records: IProperty[];
}
export interface IField {
    name: string;
    type: string;
    fieldLength: number;
    workAreaId: number;
    setFieldFlag: number;
    indexFieldFlag: number;
}

export interface IProperty {
    [key: string]: string | number;
}

export interface IPolygon {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
    parts: Int32Array;
    points: Float64Array;
}
export interface IPoint {
    x: number;
    y: number;
}

export interface IShape {
    type: number;
    content: IPoint | IPolygon | null;
}

export interface IRecord {
    number: number;
    length: number;
    shape: IShape | null;
}

export interface ISHPData {
    fileName: string;
    fileCode: number;
    wordLength: number;
    byteLength: number;
    version: number;
    shapeType: number;
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    minZ: number;
    maxZ: number;
    minM: number;
    maxM: number;
    records: IRecord[];
}
