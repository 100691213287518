import React from 'react';

export const SchrodingerCableIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g>
                <path d="M 3 17.3 V 21 H 6.8 L 17.8 9.9 L 14.1 6.2 Z M 20.7 7 a 1 1 0 0 0 0 -1.4 L 18.4 3.3 a 1 1 0 0 0 -1.4 0 L 15.1 5.1 l 3.8 3.8 l 1.8 -1.8 Z" fill="#005293" />
                <path d="M 0 0 H 24 V 24 H 0 Z" fill="none" />
                <path d="M -549 -84 l 3 -3 v 3 Z" transform="translate(557,105)" fill="#005293" />
                <rect width="3" height="3" transform="translate(12,18)" fill="#005293" />
                <rect width="3" height="3" transform="translate(16,18)" fill="#005293" />
            </g>
        </svg>
    );
};
