import { store } from '../dna';
import { PointLike } from '../models/pointlike';
import { addTrench } from '../redux/trench.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class TrenchTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Trench,
        name: 'Trench',
        shortcut: 'T',
    };

    public static createNewTrench(points: PointLike[], startId: number, endId: number) {
        addTrench(startId, endId, points)(store.dispatch);
    }
}
