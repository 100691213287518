import './splice-point-card.scss';

import React from 'react';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { Card } from '../card/card';
import { CardContentComponent } from '../card/card-content.component';
import { CardHeaderComponent } from '../card/card-header.component';
import { useSplicePointCard } from './splice-point-card.hooks';
import { SplicePointDiagram } from './splice-point-diagram';

export const SplicePointCard = (): JSX.Element | null => {
    const { 
        splicePoint,
        splicePointTitleLabel, splicePointSubheaderLabel, spliceCardIcon, editDisabled,
        handleDelete, unselectSplicePoint
    } = useSplicePointCard();
    
    return !splicePoint ? null : (
        <>
            <Card id="splice-point-card" className="splice-point-card" onCloseClick={unselectSplicePoint}>
                <CardHeaderComponent title={splicePointTitleLabel} icon={spliceCardIcon} iconBorder={true} subheader={splicePointSubheaderLabel} />
                <CardContentComponent className="splice-point-card-diagram">
                    <SplicePointDiagram splicePoint={splicePoint} disabled={editDisabled} />
                    <div className="card-button-container card-footer">
                        <TextButton palette={MainPalettes.error} disabled={editDisabled} onClick={handleDelete}>
                            <i className="material-icons">delete</i>
                        </TextButton>
                    </div>
                </CardContentComponent>
            </Card>
        </>
    );
};