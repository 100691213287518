import { Data } from './data';
import { FlexNapBuildData } from './flexnap-build-data';

export class FlexNapPretermBuildData extends Data {
    public pretermBuild: FlexNapBuildData;
    public parentBuild: FlexNapBuildData;

    constructor(pretermBuild: FlexNapBuildData, parentBuild: FlexNapBuildData) {
        super(pretermBuild.id);
        this.pretermBuild = pretermBuild;
        this.parentBuild = parentBuild;
    }
}