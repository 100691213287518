import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ExportState {
    status?: string;
    designAreaConfigurationId?: string;
    progress?: number;
    error?: string;
    excelFile?: File;
}

const initialState: ExportState = {};

function updateExportStateReducer(state: ExportState, action: PayloadAction<ExportState>): void {
    const newState = action.payload;
    state.status = newState.status;
    state.designAreaConfigurationId = newState.designAreaConfigurationId;
    state.progress = newState.progress;
    state.error = newState.error;
}

function clearReducer(state: ExportState, _: PayloadAction<undefined>): void {
    state.excelFile = undefined;
    state.status = undefined;
    state.designAreaConfigurationId = undefined;
    state.progress = undefined;
    state.error = undefined;
}

function setExcelFileReducer(state: ExportState, action: PayloadAction<File>): void {
    state.excelFile = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'export',
    initialState,
    reducers: {
        updateExportState: updateExportStateReducer,
        clear: clearReducer,
        setExcelFile: setExcelFileReducer,
    },
});

export { reducer as ExportReducer };
export const { updateExportState, clear, setExcelFile } = actions;
