import { fromLonLat } from 'ol/proj';
import Projection from 'ol/proj/Projection';

import { BoreData } from '../models/bore';
import { CabinetData } from '../models/cabinet';
import { ElementData } from '../models/element';
import { GeoJsonFeature } from '../models/geojson';
import { HandholeData } from '../models/handhole';
import { ManholeData } from '../models/manhole';
import { ParcelData } from '../models/parcel';
import { PathData } from '../models/path';
import { PointLike } from '../models/pointlike';
import { PoleData } from '../models/pole';
import { PoleSpanData } from '../models/polespan';
import { PowerStatus } from '../models/power-status';
import { SurveyType } from '../models/survey-type';
import { TrenchData } from '../models/trench';
import { VaultData } from '../models/vault';

export class GeoJsonFeatureConverter {

    private static EPSG3857 = new Projection({ code: 'EPSG:3857' });

    public static bboxToUtm(bbox: number[]): number[] {
        const min = [bbox[0], bbox[1]];
        const minU = fromLonLat([+min[0], +min[1]], GeoJsonFeatureConverter.EPSG3857);
        const max = [bbox[2], bbox[3]];
        const maxU = fromLonLat([+max[0], +max[1]], GeoJsonFeatureConverter.EPSG3857);
        return [...minU, ...maxU];
    }

    private static findKey(object: any, keyName: string): string | undefined {
        const lowerKey = keyName.toLowerCase().trim();
        return Object.keys(object).find((k) => k.toLowerCase().trim() === lowerKey);
    }

    private static deleteKey(object: { [s: string]: any }, keyName: string): void {
        const key = this.findKey(object, keyName);
        if (key) {
            delete object[key];
        }
    }

    private static getAndDeleteKeyValue(object: { [s: string]: any }, keyName: string): string {
        const key = this.findKey(object, keyName);
        if (!key) {
            throw new Error(`Imported feature missing '${keyName}' attribute`);
        }
        const value = object[key];
        delete object[key];
        return value;
    }

    public static convert(features: GeoJsonFeature[], tags: string[]): ImportedData {
        const poles: PoleData[] = [];
        const parcels: ParcelData[] = [];
        const poleSpans: PoleSpanData[] = [];
        const manholes: ManholeData[] = [];
        const handholes: HandholeData[] = [];
        const vaults: VaultData[] = [];
        const cabinets: CabinetData[] = [];
        const trenches: TrenchData[] = [];
        const bores: BoreData[] = [];
        const duplicates: ElementData[] = [];
        for (const f of features) {
            try {
                const type = +this.getAndDeleteKeyValue(f.properties, 'type');
                if (isNaN(type)) {
                    throw new Error('Imported feature \'type\' attribute should be a number.');
                }
                if (type === SurveyType.Pole) {
                    const importedPole = GeoJsonFeatureConverter.convertToPole(f);
                    const poleTagLower = importedPole.tag.toLowerCase();
                    if (!tags.includes(poleTagLower)) {
                        poles.push(importedPole);
                        tags.push(poleTagLower);
                    } else {
                        duplicates.push(importedPole);
                    }
                } else if (type === SurveyType.Parcel) {
                    const importedParcel = GeoJsonFeatureConverter.convertToParcel(f);
                    const parcelTagLower = importedParcel.tag.toLowerCase();
                    if (!tags.includes(parcelTagLower)) {
                        parcels.push(importedParcel);
                        tags.push(parcelTagLower);
                    } else {
                        duplicates.push(importedParcel);
                    }
                } else if (type === SurveyType.PoleSpan) {
                    const importedPoleSpan = GeoJsonFeatureConverter.convertToPoleSpan(f);
                    poleSpans.push(importedPoleSpan);
                } else if (type === SurveyType.Manhole) {
                    const importedManhole = GeoJsonFeatureConverter.convertToManhole(f);
                    const manholeTagLower = importedManhole.tag.toLowerCase();
                    if (!tags.includes(manholeTagLower)) {
                        manholes.push(importedManhole);
                        tags.push(manholeTagLower);
                    } else {
                        duplicates.push(importedManhole);
                    }
                } else if (type === SurveyType.Handhole) {
                    const importedHandhole = GeoJsonFeatureConverter.convertToHandhole(f);
                    const handholeTagLower = importedHandhole.tag.toLowerCase();
                    if (!tags.includes(handholeTagLower)) {
                        handholes.push(importedHandhole);
                        tags.push(handholeTagLower);
                    } else {
                        duplicates.push(importedHandhole);
                    }
                } else if (type === SurveyType.Vault) {
                    const importedVault = GeoJsonFeatureConverter.convertToVault(f);
                    const vaultTagLower = importedVault.tag.toLowerCase();
                    if (!tags.includes(vaultTagLower)) {
                        vaults.push(importedVault);
                        tags.push(vaultTagLower);
                    } else {
                        duplicates.push(importedVault);
                    }
                } else if (type === SurveyType.Cabinet) {
                    const importedCabinet = GeoJsonFeatureConverter.convertToCabinet(f);
                    const cabinetTagLower = importedCabinet.tag.toLowerCase();
                    if (!tags.includes(cabinetTagLower)) {
                        cabinets.push(importedCabinet);
                        tags.push(cabinetTagLower);
                    } else {
                        duplicates.push(importedCabinet);
                    }
                } else if (type === SurveyType.Trench) {
                    const importedTrench = GeoJsonFeatureConverter.convertToPath<TrenchData>(f);
                    trenches.push(importedTrench);
                } else if (type === SurveyType.Bore) {
                    const importedBore = GeoJsonFeatureConverter.convertToPath<BoreData>(f);
                    bores.push(importedBore);
                }
            } catch (err) {
                if (err instanceof Error) {
                    throw new Error(err.message + ": " + JSON.stringify(f));
                }
            }
        }
        return { poles, parcels, poleSpans, manholes, handholes, vaults, cabinets, trenches, bores, foundDuplicates: duplicates.length > 0 };
    }

    private static convertToPole(feature: GeoJsonFeature): PoleData {
        const properties = feature.properties;
        let tag = '';
        try {
            tag = this.getAndDeleteKeyValue(properties, 'tag');
        } catch (err) {
            tag = this.getAndDeleteKeyValue(properties, 'id');
        }
        const powerStatus = GeoJsonFeatureConverter.parsePowerStatus(feature.properties);
        this.deleteKey(properties, 'longitude');
        this.deleteKey(properties, 'latitude');
        this.deleteKey(properties, 'hasPower');
        const [x, y] = fromLonLat(feature.geometry.coordinates, GeoJsonFeatureConverter.EPSG3857);
        const pole: PoleData = {
            tag,
            x,
            y,
            powerStatus,
            properties: JSON.stringify(properties), // need to exclude tag and lat lon
        };
        return pole;
    }

    private static convertToParcel(feature: GeoJsonFeature): ParcelData {
        const properties = feature.properties;
        let tag = '';
        try {
            tag = this.getAndDeleteKeyValue(properties, 'tag');
        } catch (err) {
            tag = this.getAndDeleteKeyValue(properties, 'id');
        }
        const totalUnits = +this.getAndDeleteKeyValue(properties, 'totalUnits');
        this.deleteKey(properties, 'longitude');
        this.deleteKey(properties, 'latitude');
        const [x, y] = fromLonLat(feature.geometry.coordinates, GeoJsonFeatureConverter.EPSG3857);
        const parcel: ParcelData = {
            tag,
            totalUnits,
            x,
            y,
            properties: JSON.stringify(properties),
        };

        return parcel;
    }

    private static convertToPoleSpan(feature: GeoJsonFeature): PoleSpanData {
        const properties = feature.properties;
        const fromId = this.getAndDeleteKeyValue(properties, 'fromId');
        const toId = this.getAndDeleteKeyValue(properties, 'toId');
        const span = +this.getAndDeleteKeyValue(properties, 'span');
        const units = this.getAndDeleteKeyValue(properties, 'units');
        const poleSpan: PoleSpanData = {
            fromTag: fromId,
            toTag: toId,
            span,
            units,
            properties: JSON.stringify(properties),
        };

        return poleSpan;
    }

    private static convertToManhole(feature: GeoJsonFeature): ManholeData {
        const manhole = GeoJsonFeatureConverter.convertToElement<ManholeData>(feature);
        manhole.powerStatus = GeoJsonFeatureConverter.parsePowerStatus(feature.properties);
        this.deleteKey(feature.properties, 'hasPower');

        return manhole;
    }

    private static convertToHandhole(feature: GeoJsonFeature): HandholeData {
        const handhole = GeoJsonFeatureConverter.convertToElement<HandholeData>(feature);
        handhole.powerStatus = GeoJsonFeatureConverter.parsePowerStatus(feature.properties);
        this.deleteKey(feature.properties, 'hasPower');

        return handhole;
    }

    private static convertToVault(feature: GeoJsonFeature): VaultData {
        const vault = GeoJsonFeatureConverter.convertToElement<VaultData>(feature);
        vault.powerStatus = GeoJsonFeatureConverter.parsePowerStatus(feature.properties);
        this.deleteKey(feature.properties, 'hasPower');

        return vault;
    }

    private static convertToCabinet(feature: GeoJsonFeature): CabinetData {
        const cabinet = GeoJsonFeatureConverter.convertToElement<CabinetData>(feature);
        cabinet.powerStatus = GeoJsonFeatureConverter.parsePowerStatus(feature.properties);
        cabinet.portCount = feature.properties.portCount ?? 432
        this.deleteKey(feature.properties, 'hasPower');
        this.deleteKey(feature.properties, "portCount");

        return cabinet;
    }

    private static convertToElement<T extends ElementData>(feature: GeoJsonFeature): T {
        const properties = feature.properties;
        let tag = '';
        try {
            tag = this.getAndDeleteKeyValue(properties, 'tag');
        } catch (err) {
            tag = this.getAndDeleteKeyValue(properties, 'id');
        }
        this.deleteKey(properties, 'longitude');
        this.deleteKey(properties, 'latitude');
        const [x, y] = fromLonLat(feature.geometry.coordinates, GeoJsonFeatureConverter.EPSG3857);
        const element: ElementData = {
            tag,
            x,
            y,
            properties: JSON.stringify(properties),
        };

        return element as T;
    }

    private static convertToPath<T extends PathData>(feature: GeoJsonFeature): T {
        const properties = feature.properties;
        let length: number | undefined;
        let units: string | undefined;
        try {
            length = +this.getAndDeleteKeyValue(properties, 'length');
            units = this.getAndDeleteKeyValue(properties, 'units');
        } catch (err) {
            // ignore path measured length and units if not specified
        }
        let tag = '';
        try {
            tag = this.getAndDeleteKeyValue(properties, 'tag');
        } catch (err) {
            tag = this.getAndDeleteKeyValue(properties, 'id');
        }
        this.deleteKey(properties, 'longitude');
        this.deleteKey(properties, 'latitude');
        const coordinates = feature.geometry.coordinates;
        const route: PointLike[] = [];
        if (feature.geometry.type === 'LineString') {
            for (const coordinate of coordinates) {
                const [x, y] = fromLonLat([coordinate[0], coordinate[1]], GeoJsonFeatureConverter.EPSG3857);
                route.push({ x, y });
            }
        } else {
            // MultiLineString
            for (let i = 0; i < coordinates.length; i += 2) {
                const [x, y] = fromLonLat([coordinates[i], coordinates[i + 1]], GeoJsonFeatureConverter.EPSG3857);
                route.push({ x, y });
            }
        }
        const path: PathData = {
            tag,
            route,
            measuredLength: length,
            lengthUnit: units,
            properties: JSON.stringify(properties),
        };
        return path as T;
    }

    private static parsePowerStatus(p: any): PowerStatus {
        return p.hasPower === 'true' ? PowerStatus.Installed : PowerStatus.None;
    }
}

export interface ImportedData {
    poles: PoleData[];
    parcels: ParcelData[];
    poleSpans: PoleSpanData[];
    manholes: ManholeData[];
    handholes: HandholeData[];
    vaults: VaultData[];
    cabinets: CabinetData[];
    trenches: TrenchData[];
    bores: BoreData[];
    foundDuplicates: boolean;
}
