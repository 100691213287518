import './nap-card.scss';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../locales/types';
import { TerminalExtensionType } from '../../models/terminal-extension-type';
import { Card } from '../card/card';
import { CardContentComponent } from '../card/card-content.component';
import { CardHeaderComponent } from '../card/card-header.component';
import { useNapCard } from './nap-card.hooks';
import { NapDiagram } from './nap-diagram';
import { NapPretermDeleteDialog } from './nap-preterm-delete.dialog';
import { FlexNapPretermCard } from './preterm/flexnap-preterm-card';
import { FlexNapTerminalCard } from './terminal/flexnap-terminal-card';
import { FlexNapTerminalExtensionCard } from './terminal/flexnap-terminal-extension-card';
import { FlexNapTetherCard } from './tether/flexnap-tether-card';
import { FlexNapTetherExtenderCard } from './tether/flexnap-tether-extender-card';

export const NapCard = (): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        nap, tapIndex, tetherIndex, selectedTap, selectedTether, hasSelectedTether, napTitleLabel, napCardIcon, pretermBuilds, selectedTetherPretermBuild, deleteDialog, showControls,
        unselectNap, deleteNap, handleDelete, toggleDeleteDialog, editDisabled
    } = useNapCard();

    const createChild = useCallback((): React.ReactNode => {
        if (hasSelectedTether && selectedTap && selectedTether) {
            return (
                <>
                    <FlexNapTetherCard tether={selectedTether} tapIndex={tapIndex} tetherIndex={tetherIndex} disabled={editDisabled} viewOnlyMode={!!selectedTetherPretermBuild} />
                    {
                        selectedTether.terminal?.terminalExtension?.terminalExtensionType === TerminalExtensionType.TetherExtender ?
                            <FlexNapTetherExtenderCard tether={selectedTether} disabled={editDisabled} />
                            :
                            undefined
                    }
                    {
                        selectedTetherPretermBuild?.pretermLateral?.parentTetherIds.includes(selectedTether.id) ?
                            <FlexNapPretermCard disabled={editDisabled} build={selectedTetherPretermBuild} napPretermBuilds={pretermBuilds} />
                            :
                            <FlexNapTerminalCard disabled={editDisabled} tether={selectedTether} />
                    }
                    {
                        selectedTether.terminal?.terminalExtension && !editDisabled ?
                            <FlexNapTerminalExtensionCard terminalExtension={selectedTether.terminal.terminalExtension} />
                            :
                            undefined
                    }
                </>
            );
        }

        return null;
    }, [hasSelectedTether, selectedTap, selectedTether, selectedTetherPretermBuild, pretermBuilds, tapIndex, tetherIndex, editDisabled]);

    return !nap ? null : (
        <React.Fragment>
            <Card id="nap-card" className="nap-card" onCloseClick={unselectNap}>
                <CardHeaderComponent title={napTitleLabel} icon={napCardIcon} iconBorder={true} subheader={nap.buildId ? `build ${nap.buildId}` : `${t(LocalizationKeys.Unconnected)}`} />
                <CardContentComponent className="nap-card-diagram">
                    <NapDiagram nap={nap} pretermBuilds={pretermBuilds} disabled={editDisabled} />
                    {showControls && (
                        <div className="card-button-container card-footer">
                            <TextButton palette={MainPalettes.error} disabled={editDisabled} onClick={handleDelete}>
                                <i className="material-icons">delete</i>
                            </TextButton>
                        </div>
                    )}
                </CardContentComponent>
                {createChild()}
            </Card>
            <NapPretermDeleteDialog open={deleteDialog} buildIds={pretermBuilds?.map(b => b.id) ?? []} onPositiveClick={deleteNap} onClose={toggleDeleteDialog} />
        </React.Fragment>
    );
}
