import '@rmwc/drawer/styles';
import './drawer-workspace.scss';

import React from 'react';

import { Divider } from '@mui/material';
import { Drawer } from '@rmwc/drawer';

import { WorkspaceDrawerBody } from './body/drawer-workspace-body';
import { useWorkspaceDrawer } from './drawer-workspace.hooks';
import { WorkspaceDrawerFilterContextProvider } from './filter/drawer-workspace-filter-context';
import { WorkspaceDrawerHeader } from './header/drawer-workspace-header';
import { WorkspaceDrawerSubHeader } from './sub-header/drawer-workspace-subheader';

export const WorkspaceDrawer = () => {
    const { open } = useWorkspaceDrawer();
  
    return (
        <Drawer className="workspace-drawer" dismissible open={open} >
            <WorkspaceDrawerHeader />
            <Divider />
            <WorkspaceDrawerFilterContextProvider>
                <WorkspaceDrawerSubHeader />
                <WorkspaceDrawerBody />
            </WorkspaceDrawerFilterContextProvider>
        </Drawer>
    );
}