import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../../../../models/design-mode';
import { createCanvasSegment } from '../../../../../redux/build.state';
import { DialogType, showDialog } from '../../../../../redux/overlay.state';
import {
    designModeSelector, dialogSelector, segmentsSelector, selectedSegmentIdSelector
} from '../../../../../selectors/root.selectors';

export const useAddCanvasSegmentDialog = () => {
    const dispatch = useDispatch();

    const dialog = useSelector(dialogSelector);
    const mode = useSelector(designModeSelector);
    const selectedSegmentId = useSelector(selectedSegmentIdSelector);
    const isOpen = mode === DesignMode.CanvasMode &&
        dialog === DialogType.CanvasAddSegment &&
        !!selectedSegmentId;

    const segments = useSelector(segmentsSelector);
    const selectedSegment = segments.find(s => s.id === selectedSegmentId);

    const [designSpan, setDesignSpan] = useState(0);

    const isValueNotValid = (value: number): boolean => {
        return isNaN(value) || value <= 0;
    };
    const addDisabled = isValueNotValid(designSpan);

    const handleCloseClick = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const handleAddClick = useCallback(() => {
        if (!selectedSegment) return;

        dispatch(createCanvasSegment(selectedSegment.buildId, { position: selectedSegment.position + 1, span: designSpan }));
        dispatch(showDialog());
    }, [dispatch, designSpan, selectedSegment]);

    const handleDesignSpanUpdate = (e: React.FormEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.valueAsNumber;
        setDesignSpan(value);
    };

    return {
        isOpen,
        handleCloseClick, handleAddClick,
        designSpan, handleDesignSpanUpdate,
        unit: selectedSegment?.designSpanUnit,
        addDisabled
    }
};