/* eslint-disable react/prop-types */
import './card.component.scss';

import classNames from 'classnames';
import i18n from 'i18next';
import React from 'react';

import { Tooltip } from '@mui/material';

import { TooltipLocalizationKeys } from '../../locales/types';

interface CardCollapsibleProps {
    id: string;
    label: string;
    className?: string;
    collapsed?: boolean;
}

interface CardStateType {
    [index: string]: boolean;
}

interface CardCollapsibleState {
    collapsed: boolean;
}

export class CardCollapsibleComponent extends React.Component<CardCollapsibleProps, CardCollapsibleState> {

    constructor(props: CardCollapsibleProps) {
        super(props);
        this.state.collapsed = this.getThisCardState();
    }

    public state: CardCollapsibleState = {
        collapsed: false,
    };

    public componentDidUpdate(prevProps: CardCollapsibleProps): void {
        const { collapsed } = this.props;
        if (collapsed !== prevProps.collapsed) {
            this.setState({ collapsed: !!collapsed });
            this.setThisCardState(!!collapsed);
        }
    }

    private toggleCollapse = (): void => {
        const newCollapsed = !this.state.collapsed;
        this.setState({ collapsed: newCollapsed });
        this.setThisCardState(newCollapsed);
    }

    private getCardState = (): CardStateType => JSON.parse(localStorage.getItem('cardState') || '{}');
    private getThisCardState = (): boolean => !!this.getCardState()[this.props.id];
    private setThisCardState = (newState: boolean): void => {
        const currentState = this.getCardState();
        localStorage.setItem('cardState', JSON.stringify({ ...currentState, [this.props.id]: newState }));
    }

    public render(): JSX.Element | null {
        const { collapsed } = this.state;
        const { id, label, className } = this.props;
        const cs = classNames('collapsible-content', className, { collapsed: !!collapsed });
        const collapseAlt = collapsed ? "Reveal card" : "Collapse card";
        const collapseSvg = collapsed ? './icon_open_chevron.svg' : './icon_close_chevron.svg';
        const collapseImg = <img alt={collapseAlt} src={require(`${collapseSvg}`)} />;
        return (
            <div id={id} className="card-content card-collapsible">
                <div className="collapsible-header">
                    <label className="subheader"><b>{label}</b></label>
                    <Tooltip arrow placement="bottom" title={i18n.t(collapsed ? TooltipLocalizationKeys.Expand : TooltipLocalizationKeys.Collapse).toLowerCase()}>
                        <button className="icon-button" onClick={this.toggleCollapse}>{collapseImg}</button>
                    </Tooltip>
                </div>
                <div className={cs}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
