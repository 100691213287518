import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { IconButtonProps } from "@orbit/icon-button";
import { MenuProps } from "@mui/material";

import { showDialog, DialogType } from "../../../redux/overlay.state";
import { setDownloadState } from '../../../redux/download.state';
import { useExport } from '../../../hooks/export.hooks';

import { LocalizationKeys } from "../../../locales/types";
import { BuildActionsMenuProps } from "./build-actions-menu.types";

export const useBuildActionsMenu = ({ build, splicePlanDisabled, bomDisabled, buildInfoDisabled }: BuildActionsMenuProps) => {
    const { generateFlexNAPBOM } = useExport();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
    
    const onMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(e.currentTarget);
    };

    const onMenuClose = () => {
        setMenuAnchorEl(undefined);
    };

    const icon: Omit<IconButtonProps, "icon"> = {
        palette: "primary",
        token: "main",
        title: t(LocalizationKeys.MoreOptions),
        placement: "bottom",
        onClick: onMenuClick
    };

    const props: MenuProps = {
        open: !!menuAnchorEl,
        anchorEl: menuAnchorEl,
        onClose: onMenuClose
    };

    const onDownloadGISClick = useCallback((): void => {
        setMenuAnchorEl(undefined);
        if (build) {
            dispatch(setDownloadState({
                buildIds: [build.id],
                designAreaNames: [],
                designAreaIds: []
            }));
            dispatch(showDialog(DialogType.DownloadGisData));
        }
    }, [build, dispatch]);

    const onSplicePlanClick = useCallback((): void => {
        setMenuAnchorEl(undefined);
        dispatch(showDialog(DialogType.SplicePlan));
    }, [dispatch]);

    const onDownloadBOMClick = useCallback(() => {
        setMenuAnchorEl(undefined);
        if (build) {
            generateFlexNAPBOM([build.id]);
        }
    }, [build, generateFlexNAPBOM]);

    const onBuildInfoClick = useCallback((): void => {
        setMenuAnchorEl(undefined);
        dispatch(showDialog(DialogType.BuildInfo));
    }, [dispatch]);

    const options = {
        gis: { label: t(LocalizationKeys.DownloadGisData), onClick: onDownloadGISClick },
        splicePlan: { label: t(LocalizationKeys.SplicePlanButton), onClick: onSplicePlanClick, disabled: splicePlanDisabled },
        bom: { label: t(LocalizationKeys.DownloadBOM), onClick: onDownloadBOMClick, disabled: bomDisabled },
        buildInfo: { label: t(LocalizationKeys.BuildInfo), onClick: onBuildInfoClick, disabled: buildInfoDisabled }
    };

    return {
        menu: {
            icon,
            props,
            options
        }
    }
}