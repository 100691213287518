import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesignMode } from '../../../models/design-mode';
import { updateLastUsedDesignMode } from '../../../redux/workspace.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { buildTypeSelector, designModeSelector, presetSelector, schrodingerPresetSelector, workspaceSelector } from '../../../selectors/root.selectors';
import { BuildType } from '../../../models/build-type';

export const useNoDefaultPresetWarningDialog = () => {
    const buildType = useSelector(buildTypeSelector);
    const workspace = useSelector(workspaceSelector);
    const designMode = useSelector(designModeSelector)
    const { loaded: flexNapLoaded, presets } = useSelector(presetSelector);
    const { loaded: schrodingerLoaded, presets: schrodingerPresets } = useSelector(schrodingerPresetSelector);
    const workspaceId = workspace.currentWorkspace?.id;
    const dispatch = useDispatch();
    const [dismissed, setDismissed] = useState(false);


    const noDefaultFlexNapPreset = (presets.length > 0 && flexNapLoaded && !presets.find(p => p.isDefault) && (buildType === BuildType.FlexNap && designMode === DesignMode.GISMode));
    const noDefaultSchrodingerPreset = (schrodingerPresets.length > 0 && schrodingerLoaded) && !schrodingerPresets.find(p => p.isDefault) && (buildType === BuildType.Schrodinger)

    const open = (noDefaultFlexNapPreset || noDefaultSchrodingerPreset) && !dismissed;

    const onClose = useCallback(() => {
        setDismissed(true);
    }, [setDismissed]);

    const onNegativeClick = useCallback(() => {
        setDismissed(true);
        if (designMode === DesignMode.CanvasMode) {
            workspaceId && dispatch(updateLastUsedDesignMode(DesignMode.GISMode))
        }
    }, [dispatch, workspaceId, designMode]);

    const onPresetOpen = useCallback(() => {
        onClose();
        dispatch(showDialog(DialogType.Preset));
    }, [onClose, dispatch]);



    return { open, buildType, designMode, onClose, onPresetOpen, onNegativeClick };
}