import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PointLike } from '../models/pointlike';
import { Trench, TrenchData } from '../models/trench';
import { TrenchService } from '../services/trench.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { IMPORT_LOAD_THRESHOLD } from './import.state';

export interface TrenchState {
    trenches: Trench[];
}

const initialState: TrenchState = {
    trenches: [],
};

const loadAllReducer = (state: TrenchState, action: PayloadAction<Trench[]>) => { state.trenches = action.payload; };

const addTrenchReducer = (state: TrenchState, action: PayloadAction<Trench>) => { state.trenches.push(action.payload); };

const addTrenchesReducer = (state: TrenchState, action: PayloadAction<Trench[]>) => { state.trenches.push(...action.payload); };

const updateTrenchReducer = (state: TrenchState, action: PayloadAction<Trench>) => {
    const trench: Trench = action.payload;
    const tIdx = state.trenches.findIndex((t) => t.id === trench.id);
    if (tIdx < 0) {
        state.trenches.push(trench);
    }
    else {
        state.trenches[tIdx] = trench;
    }
};

const deleteTrenchReducer = (state: TrenchState, action: PayloadAction<number>) => {
    state.trenches = state.trenches.filter((t) => t.id !== action.payload);
};

const deleteTrenchesReducer = (state: TrenchState, action: PayloadAction<number[]>) => {
    const trenchIds = new Set(action.payload);
    state.trenches = state.trenches.filter((t) => !trenchIds.has(t.id));
};

const deleteImportedTrenchesReducer = (state: TrenchState, action: PayloadAction<number>) => {
    state.trenches = state.trenches.filter((t) => t.importedGisDataId !== action.payload);
};

const { actions, reducer } = createSlice({
    name: 'trench',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        addTrench: addTrenchReducer,
        addTrenches: addTrenchesReducer,
        updateTrench: updateTrenchReducer,
        deleteTrench: deleteTrenchReducer,
        deleteTrenches: deleteTrenchesReducer,
        deleteImportedTrenches: deleteImportedTrenchesReducer,
    },
});

export { reducer as TrenchReducer };

export const { loadAll: loadTrenches, deleteTrench, updateTrench, deleteTrenches, deleteImportedTrenches } = actions;

export const getTrenches = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new TrenchService();
    const trenches = await service.getTrenches(bbox, required) || [];
    dispatch(actions.loadAll(trenches));
};

export const addTrench = (fromElementId: number, toElementId: number, points: PointLike[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new TrenchService();
        const trench = await service.addTrench({fromElementId, toElementId, points});
        if (trench) {
            dispatch(actions.addTrench(trench));
        }
    });
};

export const addTrenches = (data: TrenchData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new TrenchService();
        const success = await requestIsSuccess(service, service.addTrenches(data, importedGisId));
        if (data.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
            getTrenches(bbox)(dispatch);
        }
    });
};
