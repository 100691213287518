import { CpqCablePart } from '../../models/cpqcablepart';
import { DataValue } from '../../models/datavalue';
import { PretermLateral } from '../../models/preterm-lateral';
import { BuildData } from './build-data';
import { DesignAreaData } from './design-area-data';
import { NapData } from './nap-data';
import { SegmentData } from './segment-data';

export class FlexNapBuildData extends BuildData {
    public coSlack: DataValue;
    public fiberCount: number;
    public naps: NapData[];
    public installationType?: string;
    public part?: CpqCablePart;
    public pretermLateral?: PretermLateral;

    constructor(id: number, name: string, coSlack: DataValue, fieldSlack: DataValue, fiberCount: number, segments: SegmentData[], naps: NapData[], designAreas: DesignAreaData[], installationType?: string, part?: CpqCablePart, pretermLateral?: PretermLateral) {
        super(id, name, fieldSlack, segments, designAreas);
        this.coSlack = coSlack;
        this.fieldSlack = fieldSlack;
        this.fiberCount = fiberCount;
        this.naps = naps;
        this.designAreas = designAreas;
        this.installationType = installationType;
        this.part = part;
        this.pretermLateral = pretermLateral;
    }
}
