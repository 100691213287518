import { BuildSegment } from '../models/build-segment';
import { Nap } from '../models/nap';
import { Path } from '../models/path';
import { SplicePoint } from '../models/splice-point';
import { TerminalSegment } from '../models/terminal-segment';

export class SegmentHelper {
    public static getSegmentsOnElement(elementId: number, segments: BuildSegment[]): BuildSegment[] {
        return segments.filter((s) => s.fromId === elementId || s.toId === elementId);
    }

    public static getBuildSegmentsOnPath(path: Path, segments: BuildSegment[]): BuildSegment[] {
        return segments.filter(s => 
            (s.fromId === path.fromElementId && s.toId === path.toElementId) ||
            (s.fromId === path.toElementId && s.toId === path.fromElementId)
        );
    }

    public static getTerminalSegmentsOnPath(path: Path, segments: TerminalSegment[]): TerminalSegment[] {
        return segments.filter(s => 
            (s.fromId === path.fromElementId && s.toId === path.toElementId) ||
            (s.fromId === path.toElementId && s.toId === path.fromElementId)
        );
    }

    public static getAccessPointIndexOnBuildSegments(accessPoint: Nap | SplicePoint, segments: BuildSegment[], accessPoints: Nap[] | SplicePoint[]): number {
        const orderedElementIds = segments.filter(bs => bs.buildId === accessPoint.buildId)
                .sort((a, b) => a.position - b.position)
                .flatMap(bs => [bs.fromId, bs.toId]);
        return accessPoints.filter(sp => sp.buildId === accessPoint.buildId)
                .sort((a, b) => orderedElementIds.indexOf(a.elementId) - orderedElementIds.indexOf(b.elementId))
                .findIndex(sp => sp.id === accessPoint.id);
    }
}