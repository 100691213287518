import BaseLayer from 'ol/layer/Base';

import { LayerGroup } from './layer-group';

export default class Layer {

    #layer: BaseLayer;
    #name: string;
    #group: LayerGroup;
    #isSpecial: boolean;

    constructor(layer: BaseLayer, name: string, group: LayerGroup, isSpecial = false) {
        this.#layer = layer;
        this.#name = name;
        this.#group = group;
        this.#isSpecial = isSpecial;
    }

    get layer(): BaseLayer {
        return this.#layer;
    }

    get name(): string {
        return this.#name;
    }

    get group(): LayerGroup {
        return this.#group;
    }

    get isSpecial(): boolean {
        return this.#isSpecial;
    }
}