import { PointLike } from '../../models/pointlike';
import { Units } from '../../models/units';
import { Data } from './data';

export class BoreData extends Data {
    public route: PointLike[];
    public measuredLength: number;
    public lengthUnit: Units;
    public isImported: boolean;
    public conduitSize: number;

    constructor(id: number, route: PointLike[], measuredLength: number, lengthUnit: Units, isImported: boolean, conduitSize: number) {
        super(id);
        this.route = route;
        this.measuredLength = measuredLength;
        this.lengthUnit = lengthUnit;
        this.isImported = isImported;
        this.conduitSize = conduitSize;
    }

    public getExtent(): number[] {
        const xs = this.route.map((r) => r.x);
        const ys = this.route.map((r) => r.y);
        const minx = Math.min(...xs);
        const miny = Math.min(...ys);
        const maxy = Math.max(...ys);
        const maxx = Math.max(...xs);

        return [
            minx, miny,
            maxx, maxy,
        ];
    }
}
