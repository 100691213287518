import React from 'react';

import { BuilderHelper } from '../../../helpers/build-helper';
import { CardPropertyInline } from '../../card/card-controls';
import { useBuildInfo } from './design-area.hooks';
import { DesignAreaBuildsListProps } from './design-area.types';

export const DesignAreaBuildsList = (props: DesignAreaBuildsListProps): JSX.Element | null => {
    const getBuildInfo = useBuildInfo();

    if (!props.builds.length) {
        return null;
    }

    return (
        <>
            {props.builds.map((b) => CardPropertyInline(BuilderHelper.getBuildDisplayId(b), getBuildInfo(b) ?? ""))}
        </>
    );
};
