import { DesignMode } from '../../models/design-mode';
import { Tap } from '../../models/schrodinger/tap';
import { Tether } from '../../models/schrodinger/tether';
import { SecuredService } from '../abstract-secured-v2.service';

export class TapService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/schrodinger/tap';

    public getAll(workspaceId: number, designMode: DesignMode): Promise<{ taps: Tap[]; tethers: Tether[] } | undefined> {
        return this.get(`${this.baseUrl}?workspaceId=${workspaceId}&designMode=${designMode}`);
    }

    public getForBuild(buildId: number): Promise<{ taps: Tap[]; tethers: Tether[] } | undefined> {
        return this.get(`${this.baseUrl}/build/${buildId}`);
    }

    public addTap(elementId: number, workspaceId: number, buildId: number | null): Promise<Tap | undefined> {
        return this.post<Tap>(this.baseUrl, { elementId, workspaceId, buildId });
    }

    public deleteTap(tapId: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${tapId}`);
    }

    public undoDeleteTap(id: number): Promise<TapWithTethersDto | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${id}`);
    }

    public isElementUsedByTaps(elementId: number): Promise<boolean | undefined> {
        return this.get(`${this.baseUrl}/iselementused/${elementId}`);
    }

    public linkTapsOnElementsToBuild(elementIds: number[], buildId: number): Promise<void> {
        return this.post(this.baseUrl + '/linkto', { elementIds, buildId });
    }

    public linkTapsToBuild(tapIds: number[], buildId: number): Promise<TapsResponse | undefined> {
        return this.post(`${this.baseUrl}/link/build/${buildId}`, { tapIds });
    }

    public unlinkTapFromBuild(tapId: number): Promise<void> {
        return this.post(this.baseUrl + '/' + tapId + '/unlink');
    }

    public unlinkTapsFromBuilds(tapIds: number[]): Promise<TapsResponse | undefined> {
        return this.post(`${this.baseUrl}/unlink`, { tapIds });
    }

    public lockTapsConnectedToBuilds(buildIds: number[], workspaceId: number): Promise<Tap[] | undefined> {
        return this.post(this.baseUrl + '/lock', { buildIds, workspaceId });
    }

    public lockAllTapsForWorkspace(workspaceId: number): Promise<Tap[] | undefined> {
        return this.post(this.baseUrl + `/lockall/${workspaceId}`);
    }

    public unlockTapsConnectedToBuilds(buildIds: number[], workspaceId: number): Promise<Tap[] | undefined> {
        return this.post(this.baseUrl + '/unlock', { buildIds, workspaceId });
    }

    public unlockAllTapsForWorkspace(workspaceId: number): Promise<Tap[] | undefined> {
        return this.post(this.baseUrl + `/unlockall/${workspaceId}`);
    }

    public modifyTap(tapId: number): Promise<Tap | undefined> {
        return this.post(this.baseUrl + '/modify', { tapId });
    }

    public releaseTap(tapId: number): Promise<Tap | undefined> {
        return this.post(this.baseUrl + '/release', { tapId });
    }

    public moveTap(tapId: number, toElementId: number, toBuildId: number | null): Promise<TapWithTethersDto | undefined> {
        return this.post<TapWithTethersDto>(`${this.baseUrl}/move/${tapId}`, { toElementId, toBuildId });
    }
}

export interface TapWithTethersDto extends Tap {
    tethers: Tether[];
}

interface TapsResponse {
    taps: Tap[];
    tethers: Tether[];
}
