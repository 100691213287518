import { SecuredService } from './abstract-secured-v2.service';
import { FileResult } from './file.result';

export class DebugService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/debug';

    public resetWorkspaces(): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/workspaces`);
    }

    public resetGis(): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/gis`);
    }

    public resetPresets(): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/resetPresets`);
    }

    public resetDefaultPresets(): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/resetDefaultpresets`);
    }

    public countAffectedByResetGis(): Promise<number | undefined> {
        return this.get(`${this.baseUrl}/gis`);
    }

    public getSessionIdByBuildIdAsync(selectedBuildId: number): Promise<string | undefined> {
        return this.get(`${this.baseUrl}/sessionid/build/${selectedBuildId}`);
    }

    public getSessionIdByTapIdAsync(selectedTapId: number): Promise<string | undefined> {
        return this.get(`${this.baseUrl}/sessionid/tap/${selectedTapId}`);
    }

    public getSessionJsonByBuildIdAsync(buildId: number): Promise<FileResult | undefined> {
        return this.postDownload(`${this.baseUrl}/sessionid/build/${buildId}`);
    }

    public endSessionAsync(buildId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/sessionid/build/${buildId}`);
    }
}
