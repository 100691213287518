import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainedButton, TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../../../locales/types';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { DNASwitch } from '../../../ui-elements/dna-switch';
import { PresetAttributeGroup } from './preset-attribute-group';
import { usePresetAttributes } from './preset-attributes.hooks';
import { PresetAttributesProps } from './preset-attributes.types';

export const PresetAttributes = (props: PresetAttributesProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        loading, attributeGroups, isDefault, disableDefault, disabled,
        toggleDefault, handleCancelClick, handleSavePreset
    } = usePresetAttributes(props);
    return (<>
        {
            loading ? <div className="dna-spinner-container"><DnaSpinner /></div>
                :
                <div className="card properties">
                    <div className="cable-properties">
                        <img alt="Preset Icon" className="preset-icon" src={require(`./../../preset_cable.jpg`)} />

                        <div className="cable-summary">
                            {attributeGroups.map((ag) => <PresetAttributeGroup key={ag.stepLabel} title={ag.title} attributes={ag.attributes} />)}
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="properties-footer">
                        <DNASwitch id="isDefault" label={t(LocalizationKeys.SetAsDefault)} checked={isDefault} onChange={toggleDefault} disabled={disableDefault} />
                        <div>
                            <TextButton onClick={handleCancelClick}>{t(LocalizationKeys.Cancel)}</TextButton>
                            <ContainedButton
                                className="save-button"
                                type="submit"
                                disabled={disabled}
                                onClick={handleSavePreset}
                            >
                                {t(LocalizationKeys.SavePreset)}
                            </ContainedButton>
                        </div>
                    </div>
                </div>
        }
    </>);
};
