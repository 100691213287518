import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../locales/types';
import { lockBuilds, unlockBuilds } from '../../redux/build.state';
import { Command } from '../command';

export class UnlockBuildCommand extends Command {
    private buildIds: number[];
    private workspaceId: number;

    constructor({ ids: buildIds, workspaceId }) {
        super();
        this.buildIds = buildIds;
        this.workspaceId = workspaceId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildUnlock };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildUnlock };
    }

    public undo(dispatch: Dispatch): void {
        lockBuilds(this.buildIds, this.workspaceId, true)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        unlockBuilds(this.buildIds, this.workspaceId, true)(dispatch);
        super.redo(dispatch);
    }
}