import { TFunction } from 'i18next';

import { CardContextMenuItemProps } from '../components/ui-elements/card-context-menu';
import { LocalizationKeys } from '../locales/types';
import { BuildSegment } from '../models/build-segment';
import { BulkBuild } from '../models/bulk/bulk-build';
import { FlexNapBuild } from '../models/flexnap-build';
import { Nap } from '../models/nap';
import { SplicePoint } from '../models/splice-point';
import { OpenlayerStyleFactory } from '../openlayers/openlayer-style.factory';
import { SegmentHelper } from './segment-helper';

export class AccessPointHelper {
    public static createNapContextMenuItemProps(nap: Nap, builds: FlexNapBuild[], onSelect: (napId: number) => void, t: TFunction): CardContextMenuItemProps {
        const napBuild = builds.find(b => b.id === nap.buildId);
        return {
            id: nap.id,
            title: `${t(LocalizationKeys.Build)} ${nap.buildId}`,
            color: `${napBuild ? OpenlayerStyleFactory.getCableColor(napBuild.fiberCount, napBuild.cableColorHex).enabled : null}`,
            onSelection: onSelect
        }
    }

    public static createSplicePointContextMenuItemProps(splicePoint: SplicePoint, splicePoints: SplicePoint[], builds: BulkBuild[], buildSegments: BuildSegment[], onSelect: (napId: number) => void, t: TFunction): CardContextMenuItemProps {
        const splicePointIndex = SegmentHelper.getAccessPointIndexOnBuildSegments(splicePoint, buildSegments, splicePoints);
        return {
            id: splicePoint.id,
            title: `${t(LocalizationKeys.Bulk)} ${splicePoint.buildId}: ${t(LocalizationKeys.Splice)} ${splicePointIndex + 1}`,
            color: `${builds.find(b => b.id === splicePoint.buildId)?.cableColorHex}`,
            onSelection: onSelect
        }
    }
}