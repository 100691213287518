import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';

import { LocalizationKeys } from '../../../locales/types';
import { DataValue } from '../../../models/datavalue';
import { Units } from '../../../models/units';
import { CardCollapsibleComponent } from '../../card/card-collapsible.component';
import { CardPropertyInline } from '../../card/card-controls';
import { InlineText } from '../../ui-elements/inline-text';
import { useSegmentCard } from './segment-card.hooks';
import { RISER_SPAN_MINIMUM_FEET, SegmentCardProps } from './segment-card.types';

export const SegmentCard = (props: SegmentCardProps): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        segment, collapsed, displayUnits, designSpan, calculatedSpan, measuredSpan, riserSpan, slackLoop, designSpanIsValid, riserSpanErrorMsg, slackLoopIsValid, riserSpanVisible,
        validateDesignSpan, validateRiserSpan, validateSlackLoop, handleSlackLoopUpdate, handleDesignSpanUpdate, handleRiserSpanUpdate, disabled
    } = useSegmentCard(props);

    const renderDesignSpan = useCallback((designSpan: DataValue): JSX.Element | null => {
        return (
            <InlineText
                id="designSpan"
                label={t(LocalizationKeys.DesignSpan)}
                value={designSpan.value.toFixed(2)}
                disabled={disabled}
                units={UnitInterpreter.toShort(displayUnits)}
                onUpdate={handleDesignSpanUpdate}
                onInputChange={validateDesignSpan}
                onCancel={() => validateDesignSpan(designSpan.value)}
                error={!designSpanIsValid}
                errorMessage={t(LocalizationKeys.OnlyPositiveValues)}
            />
        );
    }, [t, disabled, displayUnits, handleDesignSpanUpdate, validateDesignSpan, designSpanIsValid]);

    const renderRiserSpan = useCallback((riserSpan: DataValue): JSX.Element | null => {
        if (!riserSpanVisible) { return null; }
        return (
            <InlineText
                id="riserSpan"
                label={t(LocalizationKeys.RiserSpan)}
                value={riserSpan.value.toFixed(2) ?? new DataValue(RISER_SPAN_MINIMUM_FEET, Units.feet).toUnit(displayUnits)}
                disabled={disabled}
                units={UnitInterpreter.toShort(displayUnits)}
                onUpdate={handleRiserSpanUpdate}
                onInputChange={validateRiserSpan}
                onCancel={() => validateRiserSpan(riserSpan.value ?? new DataValue(RISER_SPAN_MINIMUM_FEET, Units.feet))}
                error={!!riserSpanErrorMsg}
                errorMessage={riserSpanErrorMsg}
            />
        );
    }, [riserSpanVisible, t, displayUnits, disabled, handleRiserSpanUpdate, validateRiserSpan, riserSpanErrorMsg]);

    const renderSlackLoop = useCallback((slackLoop: DataValue): JSX.Element | null => {
        return (
            <InlineText
                id="slackLoop"
                label={t(LocalizationKeys.SlackLoop)}
                value={slackLoop.value.toFixed(2)}
                disabled={disabled}
                units={UnitInterpreter.toShort(displayUnits)}
                onUpdate={handleSlackLoopUpdate}
                onInputChange={validateSlackLoop}
                onCancel={() => validateSlackLoop(slackLoop.value)}
                error={!slackLoopIsValid}
                errorMessage={t(LocalizationKeys.OnlyPositiveValues)}
            />
        );
    }, [t, disabled, displayUnits, handleSlackLoopUpdate, validateSlackLoop, slackLoopIsValid]);

    if (!segment) {
        return null;
    }

    return (
        <CardCollapsibleComponent id="segment-info" label="Segment" collapsed={collapsed}>
            <div className="caption">
                {t(LocalizationKeys.DesignSpanCaptionDefinition)}
            </div>
            {!!designSpan && renderDesignSpan(designSpan)}
            {calculatedSpan ?
                CardPropertyInline(t(LocalizationKeys.CalculatedSpan), calculatedSpan.value.toFixed(2), UnitInterpreter.toShort(displayUnits))
                :
                CardPropertyInline(t(LocalizationKeys.CalculatedSpan), "", t(LocalizationKeys.NotApplicable))
            }
            {measuredSpan ?
                CardPropertyInline(t(LocalizationKeys.MeasuredSpan), measuredSpan?.value.toFixed(2), UnitInterpreter.toShort(displayUnits))
                :
                CardPropertyInline(t(LocalizationKeys.MeasuredSpan), "", t(LocalizationKeys.NotApplicable))
            }
            {!!riserSpan && renderRiserSpan(riserSpan)}
            {!!slackLoop && renderSlackLoop(slackLoop)}
        </CardCollapsibleComponent>
    );
}
