import i18next from 'i18next';
import React from 'react';

import { TextButton } from '@orbit/button';
import { Tooltip } from '@mui/material';

import { ToolType } from '../../../design-tools/tooltype';
import { LocalizationKeys } from '../../../locales/types';
import { Build } from '../../../models/build';
import { BuildSegment } from '../../../models/build-segment';
import { BuildType } from '../../../models/build-type';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { OpenlayerStyleFactory } from '../../../openlayers/openlayer-style.factory';
import { ItemType } from '../../../validation/item-type';
import { Card } from '../../card/card';
import { CardCollapsibleComponent } from '../../card/card-collapsible.component';
import { CardProperty, CardPropertyInline } from '../../card/card-controls';
import { CardErrorMessage } from '../../card/card-error.component';
import { CardHeaderComponent } from '../../card/card-header.component';
import { ColorSelectorProperty } from '../../color-selector/color-selector-property';
import { defaultBuildColors } from '../build/build-card.types';
import { SegmentCard } from '../segment/segment-card';
import { BuildActionsMenu } from '../build/build-actions-menu';
import { useFlexNapBuildCard } from './flexnap-build-card.hooks';
import { FlexNapCableCard } from './flexnap-cable-card';

export const FlexNapBuildCard = () => {
    const { editDisabled, unlockDisabled, buildLocked, collapsed, build, segments, segment, naps: buildNaps, tetherFiberCount,
        selectedTool, clickedNotification, unselectBuild, onDeleteBuildClick, onLockBuild,
        onUnlockBuild, onUpdateColor, onResetColor, toggleCollapse } = useFlexNapBuildCard();

    if (!build || build.type !== BuildType.FlexNap || selectedTool === ToolType.Modification) {
        return null;
    }

    const renderBuildProperties = (buildSegments: BuildSegment[], isLocked: boolean): JSX.Element => {
        const serving = 0; // Number of parcels
        const locations = buildSegments.length + 1;
        const napCount = buildNaps.length;
        const notEnoughFibers = build.fiberCount < tetherFiberCount;
        const splicePlanButtonDisabled = !napCount || !tetherFiberCount || notEnoughFibers;

        return (
            <CardCollapsibleComponent id="build-info" label={i18next.t(LocalizationKeys.Build)} collapsed={collapsed}>
                {!!build?.hybrisCcsBuildId && CardPropertyInline(i18next.t(LocalizationKeys.CCSBuildId), build?.hybrisCcsBuildId)}
                {!!build?.hybrisProjectKey && CardPropertyInline(i18next.t(LocalizationKeys.HybrisProjectKey), build?.hybrisProjectKey)}
                {CardPropertyInline(i18next.t(LocalizationKeys.Locations), locations)}
                {CardPropertyInline(i18next.t(LocalizationKeys.NAPs), napCount)}
                {CardPropertyInline(i18next.t(LocalizationKeys.Serving), serving)}
                {CardProperty(i18next.t(LocalizationKeys.Color), <ColorSelectorProperty color={OpenlayerStyleFactory.getCableColor(build?.fiberCount ?? 0, build?.cableColorHex).enabled} colorOptions={defaultBuildColors} onUpdate={onUpdateColor} resetEnabled={true} onReset={onResetColor} />)}
                <div className="card-footer">                 
                    <div className="footer-menu">
                        <Tooltip arrow placement="bottom" title={i18next.t(LocalizationKeys.Delete).toLowerCase()}>
                            <div>
                                <TextButton palette="error" className="negative card-icon-button" disabled={editDisabled} onClick={onDeleteBuildClick}>
                                    <i className="material-icons">delete</i>
                                </TextButton>
                            </div>
                        </Tooltip>
                        <Tooltip arrow placement="bottom" title={i18next.t(isLocked ? LocalizationKeys.Unlock : LocalizationKeys.Lock).toLowerCase()}>
                            <div>
                                <TextButton className="card-icon-button" disabled={unlockDisabled} onClick={isLocked ? onUnlockBuild : onLockBuild}>
                                    {isLocked ? <i className="material-icons">lock_open</i> : <i className="material-icons">lock</i>}
                                </TextButton>
                            </div>
                        </Tooltip>
                    </div>
                    <BuildActionsMenu build={build} splicePlanDisabled={splicePlanButtonDisabled} buildInfoDisabled={editDisabled} />
                </div>
                {notEnoughFibers && <CardErrorMessage label={i18next.t(LocalizationKeys.SplicePlanBuildError)} />}
            </CardCollapsibleComponent>
        );
    }

    const renderCableProperties = (build: FlexNapBuild, segments: BuildSegment[], disabled?: boolean): JSX.Element => {
        return <FlexNapCableCard build={build} segments={segments} collapsed={collapsed} disabled={disabled} />;
    }

    const renderSegmentProperties = (segment: BuildSegment, build: Build, disabled?: boolean): JSX.Element => {
        return <SegmentCard segment={segment} build={build} collapsed={collapsed} disabled={disabled} />;
    }

    let segmentToShow = segment;
    if (clickedNotification?.itemType === ItemType.Segment && segments) {
        segmentToShow = segments.find(s => s.id === clickedNotification.itemId);
    }
    const icon = editDisabled ? require('./../../../icons/locked.png') : require('./../../../icons/cable.png');

    return (
        <Card id="flexnap-build-info" onCloseClick={unselectBuild} onToggleCollapse={toggleCollapse}>
            <CardHeaderComponent title={i18next.t(LocalizationKeys.BuildNumber, { buildId: build.id })} tooltip={build.id} icon={icon} />
            {renderBuildProperties(segments, buildLocked ?? false)}
            {renderCableProperties(build, segments, editDisabled)}
            {segmentToShow ? renderSegmentProperties(segmentToShow, build, editDisabled) : null}
        </Card>
    );

}