import { Graphics } from 'pixi.js';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { TetherType } from '../../../../models/schrodinger/tether-type';
import {
    selectBuild, selectSegment, selectTapSchrodinger, selectTetherSchrodinger
} from '../../../../redux/selection.state';
import { setSelectHighlighted } from '../../../../redux/viewport.state';
import {
    selectedTapIdSchrodingerSelector, tethersSchrodingerSelector
} from '../../../../selectors/root.selectors';
import {
    CO_PROPORTIONS, IIndexedFrameProps,
    LOCATION_PROPORTIONS, LOCATION_TEXT_HIGHLIGHT_PROPORTIONS
} from '../build.types';
import { useHighlights } from '../location/location.hooks';
import tapSelected from './assets/tap-selected.svg';
import tap from './assets/tap.svg';
import { TapProps } from './tap.types';

export const useTap = ({ index, position, tapId }: IIndexedFrameProps & TapProps) => {
    const { highlightAlpha, highlightImage, highlightScale, drawHighlightForText } = useHighlights({ position, index });

    const selectedTapId = useSelector(selectedTapIdSchrodingerSelector);
    const tethers = useSelector(tethersSchrodingerSelector);

    const dispatch = useDispatch();

    const { x: xBase, y: yBase } = position;
    const x = (xBase + CO_PROPORTIONS.WIDTH / 2) + ((index + 1) * LOCATION_PROPORTIONS.GAP) - (2 * LOCATION_PROPORTIONS.RADIUS);
    const y = yBase + (CO_PROPORTIONS.HEIGHT / 2);
    const anchor = { x: 0.5, y: 0.5 };
    const zIndex = index + 1;
    const isTapSelected = tapId === selectedTapId;
    const image = isTapSelected ? tapSelected : tap;
    const tapTethers = tethers.filter((t) => t.tapId === tapId);
    const tapCount = tapTethers.length.toString();
    const hasOpticalTether = tapTethers.some(({ type }) => type === TetherType.Optical);
    const hasPowerTether = tapTethers.some(({ type }) => type === TetherType.Power);

    const { t } = useTranslation();
    const text = t(LocalizationKeys.BuildLocationIndex, { locationIndex: (index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) });

    const drawBackgroundForText = (graphics: Graphics) => {
        graphics.clear();
        drawHighlightForText(graphics);
        graphics.drawRoundedRect(
            x + LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.X_SHIFT,
            y + LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.Y_SHIFT,
            LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.WIDTH,
            LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.HEIGHT,
            LOCATION_TEXT_HIGHLIGHT_PROPORTIONS.RADIUS);
        graphics.endFill();
    }

    const handleClick = useCallback(() => {
        dispatch(selectTetherSchrodinger());
        dispatch(selectTapSchrodinger(tapId));
        dispatch(setSelectHighlighted(index));
        dispatch(selectBuild())
        dispatch(selectSegment());
    }, [dispatch, index, tapId]);

    return {
        image,
        x,
        y,
        anchor,
        zIndex,
        tapCount,
        hasOpticalTether,
        hasPowerTether,
        highlightImage,
        highlightAlpha,
        highlightScale,
        text,
        drawBackgroundForText,
        handleClick
    };
};
