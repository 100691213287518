import { PluggableMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import LineString from 'ol/geom/LineString';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Pixel } from 'ol/pixel';

import { store } from '../dna';
import { PointHelper } from '../helpers/point-helper';
import { DeleteSegmentNodePayload } from '../history/build/delete-segment-node-command';
import { ModifyCablePayload } from '../history/build/modify-cable-command';
import { CommandType } from '../history/command-type';
import { BuildSegment } from '../models/build-segment';
import { BuildType } from '../models/build-type';
import { DesignMode } from '../models/design-mode';
import { FlexNapBuild } from '../models/flexnap-build';
import { MapItemDefinition } from '../models/map-item-definition';
import { ModifiableNode } from '../models/modifiable-node';
import { Nap } from '../models/nap';
import { PointLike } from '../models/pointlike';
import { Tap } from '../models/schrodinger/tap';
import { SplicePoint } from '../models/splice-point';
import { MouseEventButton } from '../openlayers/event.types';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { FeatureType } from '../openlayers/openlayer-feature.factory';
import { LayerFactory } from '../openlayers/openlayer-layer.factory';
import { OpenlayerUtility } from '../openlayers/openlayer-utility';
import {
    createSegments, createTemporaryPatchedSegments, deleteSegment, patchSegments,
    revertPretermLateral
} from '../redux/build.state';
import {
    linkSplicePointToBuild, unlinkSplicePointFromBuild
} from '../redux/bulk/splice-point.state';
import { push } from '../redux/history.state';
import { linkTapsToBuild, unlinkTapFromBuild } from '../redux/schrodinger/tap.state';
import { dragItems } from '../redux/selection.state';
import { linkNapToBuild, unlinkNapFromBuild } from '../redux/tap.state';
import { BuildSegmentRequest, PatchSegmentRequest } from '../services/segment.service';
import { Modifiable } from './modifiable';

export interface MapSegment {
    id: number;
    geometry: Geometry;
    coordinates: Coordinate[];
    connectedFeatures: MapItemDefinition[];
}

export enum SegmentModificationResult {
    CreateUpdate,
    Update,
    Invalid
}

export class BaseCableTool implements Modifiable {

    protected modifiableTypes = new Set<FeatureType>([
        InteractableFeatureType.FLEXNAP_SEGMENT,
        InteractableFeatureType.SCHRODINGER_SEGMENT,
        InteractableFeatureType.BULK_SEGMENT
    ]);

    protected moveToTypes = new Set<FeatureType>([
        InteractableFeatureType.HANDHOLE,
        InteractableFeatureType.MANHOLE,
        InteractableFeatureType.POLE,
        InteractableFeatureType.VAULT,
        InteractableFeatureType.CABINET,
        InteractableFeatureType.DESIGN_AREA
    ]);
    
    protected moveToLayerTypes = new Set<string>([
        LayerFactory.LAYER_HANDHOLES,
        LayerFactory.LAYER_MANHOLES,
        LayerFactory.LAYER_POLES,
        LayerFactory.LAYER_VAULTS,
        LayerFactory.LAYER_CABINETS,
        LayerFactory.LAYER_DESIGN_AREAS
    ]);

    protected initialMapSegments: MapSegment[] = [];
    private startAccessPointId = -1;
    private endAccessPointId = -1;

    public modifyStart(event: ModifyEvent) {
        const { map, pixel } = event.mapBrowserEvent;
        const olSegments = event.features.getArray();
        const mapSegments = this.olSegmentsToMapSegments(map, olSegments);
        this.startAccessPointId = this.findAccessPointAtPixel(map, pixel);
        this.initialMapSegments = mapSegments;
    }

    public async modifyEnd(event: ModifyEvent) {
        const { map, pixel, originalEvent } = event.mapBrowserEvent;
        const olSegments = event.features.getArray();

        if (!originalEvent || (originalEvent as PointerEvent).button !== MouseEventButton.MAIN) {
            this.undoSegmentsModification(olSegments);
            this.endModification();
            return;
        }

        const mapSegments = this.olSegmentsToMapSegments(map, olSegments);
        const connectedFeatureIds = mapSegments[0].connectedFeatures.map(f => f.id);
        const uniqueConnectedFeatures = mapSegments[0].connectedFeatures.filter((feature, index) => connectedFeatureIds.indexOf(feature.id) === index);
        mapSegments[0].connectedFeatures = uniqueConnectedFeatures;

        this.endAccessPointId = this.findAccessPointAtPixel(map, pixel);
        const res = this.validateSegmentsModification(mapSegments);
        switch (res) {
            case SegmentModificationResult.CreateUpdate:
                await this.createAndUpdateSegments(mapSegments);
                this.handleAccessPoints();
                break;
            case SegmentModificationResult.Update:
                await this.updateSegments(mapSegments);
                this.handleAccessPoints();
                break;
            case SegmentModificationResult.Invalid:
                this.undoSegmentsModification(olSegments);
                break;
        }

        this.endModification();
    }

    public findAccessPointAtPixel(map: PluggableMap, pixel: Pixel): number {
        return -1;
    }

    private olSegmentsToMapSegments(map: PluggableMap, olSegments: Feature<Geometry>[]): MapSegment[] {
        const segmentsTypes = olSegments.map(s => OpenlayerUtility.getFeatureDefinition(s).type).filter(t => this.modifiableTypes.has(t)).distinct();
        if (segmentsTypes.length === 0) {
            console.warn("Trying to fetch connected features out of features that aren't segments");
            return [];
        } else if (segmentsTypes.length > 1) {
            console.warn("The segments should be from the same type", segmentsTypes);
            return [];
        }
        return olSegments.map(s => this.olSegmentToMapSegment(map, s));
    }

    private olSegmentToMapSegment(map: PluggableMap, olSegment: Feature<Geometry>): MapSegment {
        const geometry = this.getGeometryFromSegment(olSegment);
        const coordinates = geometry!.getCoordinates();
        const connectedFeatures = PointHelper.findConnectedFeaturesFromCoordinates(map, coordinates, this.moveToLayerTypes);
        return ({
            id: olSegment.get("id"),
            geometry: geometry!.clone(),
            coordinates: coordinates,
            connectedFeatures: connectedFeatures
        });
    }

    private getGeometryFromSegment(segment: Feature<Geometry>): LineString | undefined {
        const geometry = segment.getGeometry();
        if (geometry?.getType() === GeometryType.LINE_STRING) {
            return geometry as LineString;
        } else {
            console.warn("Cannot fetch geometry from a feature that is not a segment");
            return undefined;
        }
    }

    private validateSegmentsModification(mapSegments: MapSegment[]): SegmentModificationResult {
        const connectedFeaturesIds = mapSegments.flatMap(s => s.connectedFeatures.map(f => f.id)).distinct();
        const initialConnectedFeatureIds = this.initialMapSegments.flatMap(s => s.connectedFeatures.map(f => f.id)).distinct();
        if (connectedFeaturesIds.length > initialConnectedFeatureIds.length) { // Cable is connected to a new node
            return SegmentModificationResult.CreateUpdate;
        } else if (connectedFeaturesIds.length < initialConnectedFeatureIds.length) { // Cable is connected to less features. We will consider this as an invalid modification for now
            return SegmentModificationResult.Invalid;
        } else { // Cable is connected to the same number of features
            const newConnectedFeatureIds = connectedFeaturesIds.filter(b => !initialConnectedFeatureIds.find(a => a === b));
            if (newConnectedFeatureIds.length > 0) { // Cable has a new configuration
                return SegmentModificationResult.Update;
            } else { // Cable hasn't changed. We will consider this as an invalid modification
                return SegmentModificationResult.Invalid;
            }
        }
    }

    protected async createAndUpdateSegments(mapSegments: MapSegment[]): Promise<void> {
        const updatedSegment = mapSegments.find(s => s.connectedFeatures.length > 2);
        if (!updatedSegment) return;

        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        const { segments } = build;
        const oldBuildSegment = segments.find(s => s.id === updatedSegment.id);
        if (oldBuildSegment) {
            const newBuildSegments: BuildSegmentRequest[] = [];
            const updatedBuildSegments: PatchSegmentRequest[] = [];
            for (let i = 1; i < updatedSegment.connectedFeatures.length; i++) {
                const fromId = updatedSegment.connectedFeatures[i - 1].id;
                const toId = updatedSegment.connectedFeatures[i].id;
                if (oldBuildSegment.fromId !== fromId) {
                    const position = oldBuildSegment.position + (i - 1);
                    newBuildSegments.push({ position: position, fromId: fromId, toId: toId });
                } else {
                    updatedBuildSegments.push({
                        oldSegment: oldBuildSegment,
                        newSegment: { ...oldBuildSegment, fromId: fromId, toId: toId }
                    });
                }
            }
            const segmentsToReposition = segments.filter(s => s.buildId === selectedBuildId && s.position > oldBuildSegment.position);
            for (const segmentToReposition of segmentsToReposition) {
                const updatedPosition = segmentToReposition.position + newBuildSegments.length;
                updatedBuildSegments.push({
                    oldSegment: segmentToReposition,
                    newSegment: { ...segmentToReposition, position: updatedPosition }
                });
            }
            // since the patch request is awaiting the response from the create request (that might take a few seconds), 
            // we need to set temporary segments on the map before awaiting the create request.
            createTemporaryPatchedSegments(updatedBuildSegments)(store.dispatch);
            const buildType = [
                ...build.flexnapBuilds,
                ...build.schrodingerBuilds,
                ...build.bulkBuilds
            ].find(({ id }) => id === selectedBuildId)?.type;
            const awaitSessionUpdate = buildType === BuildType.Schrodinger;
            const newSegments = await createSegments(store.dispatch, selectedBuildId!, newBuildSegments, buildType, awaitSessionUpdate);
            await patchSegments(updatedBuildSegments, false, awaitSessionUpdate)(store.dispatch);

            const payload: ModifyCablePayload = {
                buildId: selectedBuildId ?? -1,
                buildType,
                type: SegmentModificationResult.CreateUpdate,
                startAccessPointId: this.startAccessPointId,
                endAccessPointId: this.endAccessPointId,
                newSegments,
                patchedSegments: updatedBuildSegments,
                childPretermBuilds: [],
                awaitSessionUpdate,
            };

            store.dispatch(push({ type: CommandType.ModifyCable, payload: payload }));
        }
    }

    protected async updateSegments(mapSegments: MapSegment[]): Promise<number | undefined> {
        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        const { flexnapBuilds, schrodingerBuilds, bulkBuilds, segments } = build;

        const buildSegmentsFromMap = mapSegments.map(s => {
            return {
                id: s.id,
                fromId: s.connectedFeatures[0].id,
                toId: s.connectedFeatures[1].id
            }
        });

        const oldBuildSegments = segments.filter(s => s.buildId === selectedBuildId);
        const updatedOldBuildSegments: BuildSegment[] = [];
        let removedNodeElementId: number | undefined;
        let newNodeElementId: number | undefined;
        const patchSegmentRequest: PatchSegmentRequest[] = [];
        for (const oldBuildSegment of oldBuildSegments) {
            const buildSegmentModifiedNodes = buildSegmentsFromMap.find(s => s.id === oldBuildSegment.id && (s.fromId !== oldBuildSegment.fromId || s.toId !== oldBuildSegment.toId));
            if (buildSegmentModifiedNodes) {
                const updatedBuildSegment: BuildSegment = { ...oldBuildSegment, fromId: buildSegmentModifiedNodes.fromId, toId: buildSegmentModifiedNodes.toId };
                patchSegmentRequest.push({
                    oldSegment: oldBuildSegment,
                    newSegment: updatedBuildSegment
                });
                updatedOldBuildSegments.push(oldBuildSegment);
                removedNodeElementId = oldBuildSegment.fromId !== updatedBuildSegment.fromId ? oldBuildSegment.fromId : oldBuildSegment.toId;
                newNodeElementId = oldBuildSegment.fromId !== updatedBuildSegment.fromId ? updatedBuildSegment.fromId : updatedBuildSegment.toId;
            }
        }

        const revertedPretermBuilds = this.revertIfPreterm(removedNodeElementId, updatedOldBuildSegments, selectedBuildId);

        const buildType = [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds].find(({ id }) => id === selectedBuildId)?.type;
        const awaitSessionUpdate = buildType === BuildType.Schrodinger;
        await patchSegments(patchSegmentRequest, false, awaitSessionUpdate)(store.dispatch);

        const payload: ModifyCablePayload = {
            buildId: selectedBuildId ?? -1,
            buildType,
            type: SegmentModificationResult.Update,
            startAccessPointId: this.startAccessPointId,
            endAccessPointId: this.endAccessPointId,
            childPretermBuilds: revertedPretermBuilds,
            patchedSegments: patchSegmentRequest,
            newSegments: [],
            awaitSessionUpdate,
        };
        store.dispatch(push({ type: CommandType.ModifyCable, payload: payload }));

        return newNodeElementId;
    }

    /**
     * If the build is a preterm, revert when: 
     *  - The preterm build's start point was modified
     *  - The parent build's segments that were connected to the parent NAP were modified
     * @param nodeElementId The elementId of the node that was removed from the parent build
     * @param segmentsModified Array of segments that were modified (with the old data)
     * @param selectedBuildId The buildId of the build that was modified
     * 
     * @returns {FlexNapBuild[]} Reverted preterm lateral builds
     */
    private revertIfPreterm(nodeElementId?: number, segmentsModified?: BuildSegment[], selectedBuildId?: number): FlexNapBuild[] {
        const { build, taps } = store.getState();
        const { flexnapBuilds } = build;
        const { naps } = taps;
        let pretermBuilds: FlexNapBuild[] = [];
        if (!segmentsModified?.every(s => s.fromId === nodeElementId || s.toId === nodeElementId)) { // ensure the nodeElementId is part of segments modified
            return pretermBuilds;
        }
        if (selectedBuildId && segmentsModified?.some(s => s.position === 0 && s.fromId === nodeElementId)) {
            const pretermBuild = flexnapBuilds.find(b => !!b.pretermLateral && b.id === selectedBuildId);  // preterm build's first segment was changed
            if (pretermBuild) {
                pretermBuilds.push(pretermBuild);
            }
        }
        if (!pretermBuilds.length) {
            const napOnElement = naps.find(n => n.elementId === nodeElementId);
            pretermBuilds = flexnapBuilds.filter(b => !!b.pretermLateral
                && b.pretermLateral.parentBuildId === selectedBuildId // selected build is a parentBuild for a preterm lateral
                && b.pretermLateral.parentNapId === napOnElement?.id); // nap on the element is a parentNap for a preterm lateral
        }
        if (pretermBuilds.length) {
            pretermBuilds.forEach((b) => revertPretermLateral(b.id)(store.dispatch));
        }

        return pretermBuilds;
    }

    protected undoSegmentsModification(olSegments: Feature<Geometry>[]) {
        const { draggedItemsIds } = store.getState().selection;
        if (draggedItemsIds && draggedItemsIds.length > 0) { // Will focus the undo on the dragged segments
            olSegments = olSegments.filter(s => draggedItemsIds.includes(s.get("id")));
        }

        for (const olSegment of olSegments) {
            const initialSegment = this.initialMapSegments.find(s => s.id === olSegment.get("id"));
            if (initialSegment) {
                const initialGeometry = initialSegment.geometry;
                olSegment.setGeometry(initialGeometry);
            }
        }
    }

    private endModification() {
        this.initialMapSegments = [];
        this.startAccessPointId = -1;
        this.endAccessPointId = -1;
        store.dispatch(dragItems());
    }

    public getModifiableNode(features: MapItemDefinition[], point?: PointLike): ModifiableNode | undefined {
        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        if (selectedBuildId) {
            const { segments } = build;
            const selectedBuildSegments = segments.filter(s => s.buildId === selectedBuildId);
            if (selectedBuildSegments.length > 1) {
                let modifiableNodes = features.filter(f => this.moveToTypes.has(f.type));
                if (modifiableNodes.length > 1 && point) { // multiple features are on the map at the pixel where we want to get the modifiable node (images are overlapping)
                    const segmentElementIds = selectedBuildSegments.flatMap(s => [s.fromId, s.toId]);
                    const featuresConnectedToBuild = modifiableNodes.filter(n => segmentElementIds.includes(n.id));
                    const closestFeatureToCoordinate = PointHelper.findClosestFeatureToCoordinate(point, featuresConnectedToBuild);
                    if (closestFeatureToCoordinate) {
                        modifiableNodes = [closestFeatureToCoordinate];
                    }
                }
                if (modifiableNodes.length === 1) {
                    const modifiableMapItem = modifiableNodes[0];
                    const connectedBuildIds = segments.filter(s => s.fromId === modifiableMapItem.id || s.toId === modifiableMapItem.id).map(s => s.buildId).distinct();
                    if (connectedBuildIds.length > 0 && connectedBuildIds.includes(selectedBuildId)) {
                        return { mapItem: modifiableMapItem, canDelete: true };
                    }
                }
            } else {
                console.warn("A build must always be connected to two nodes");
            }
        } else {
            console.warn("A build must be selected to get a modifiable node");
        }
    }

    public deleteNode(node: MapItemDefinition): boolean {
        if (!this.moveToTypes.has(node.type)) {
            console.warn("Cannot delete a TAP of this type", node);
            return false;
        }

        const { selection, build, taps, tapsSchrodinger, splicePoints } = store.getState();
        const { selectedBuildId } = selection;
        const { flexnapBuilds, schrodingerBuilds, bulkBuilds, segments } = build;

        const segmentsModified = segments.filter(s => s.buildId === selectedBuildId && (s.fromId === node.id || s.toId === node.id));
        let revertedPretermBuilds: FlexNapBuild[] = [];
        if (selectedBuildId) {
            revertedPretermBuilds = this.revertIfPreterm(node.id, segmentsModified, selectedBuildId);
        }

        const buildSegments = segments.filter(s => s.buildId === selectedBuildId);
        const selectedBuild = [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds].find((b) => b.id === selectedBuildId)!;
        const awaitSessionUpdate = selectedBuild.type === BuildType.Schrodinger;
        const [deletedSegment, deleteFirstLocation, patchedSegments] = this.deleteConnectedNodeFromBuild(node, buildSegments, awaitSessionUpdate);
        if (deletedSegment) {
            const accessPoint: Nap | Tap | SplicePoint | undefined = [
                ...taps.naps,
                ...splicePoints.splicePoints,
                ...tapsSchrodinger.taps
            ].find(({ elementId, buildId }) => elementId === node.id && buildId === selectedBuildId);
            accessPoint && this.unlinkAccessPointFromBuild(accessPoint.id, selectedBuild.id, selectedBuild.type);

            if (patchedSegments) {
                const payload: DeleteSegmentNodePayload = {
                    buildId: selectedBuildId ?? -1,
                    buildType: selectedBuild.type,
                    segment: deletedSegment,
                    deleteFirstLocation,
                    patchedSegments,
                    childPretermBuilds: revertedPretermBuilds,
                    accessPointId: accessPoint?.id,
                    awaitSessionUpdate,
                };
                store.dispatch(push({ type: CommandType.DeleteSegmentNode, payload }));
            }
        }

        return !!deletedSegment;
    }

    private deleteConnectedNodeFromBuild(node: MapItemDefinition, segments: BuildSegment[], awaitSessionUpdate?: boolean): [BuildSegment | undefined, boolean, PatchSegmentRequest[] | undefined] {
        const connectedSegments = segments.filter(s => s.fromId === node.id || s.toId === node.id).sort((a, b) => a.position - b.position);
        if (connectedSegments && connectedSegments.length > 0) {
            let segmentToDelete: BuildSegment;
            const updatedBuildSegments: PatchSegmentRequest[] = [];
            if (connectedSegments.length === 1) {
                segmentToDelete = connectedSegments[0];
            } else if (connectedSegments.length === 2) {
                segmentToDelete = connectedSegments[0];
                const segmentToUpdate = connectedSegments[1];
                updatedBuildSegments.push({
                    oldSegment: segmentToUpdate,
                    newSegment: { ...segmentToUpdate, fromId: segmentToDelete.fromId, position: segmentToUpdate.position - 1 }
                });
            } else {
                console.warn("There should not be more than two connected segments from a build to this node", connectedSegments);
                return [undefined, false, undefined];
            }

            // When deleting a node, the segment preceding the node gets deleted.
            // Exception: When deleting the first node. In that case, the segment succeeding the node gets deleted
            // This segmentsToReposition has a slightly different filter function depending on the node getting deleted
            // This is to prevent a weird behaviour where the build has no start. See DNA-1302 for more information
            // https://dev.azure.com/CorningCTCM/DNA/_git/DNA/pullRequest/2481#1676401991
            let segmentsToReposition;
            if (connectedSegments.length === 1 && segmentToDelete.position === 0) {
                segmentsToReposition = segments.filter(s => s.position > segmentToDelete.position);
            } 
            else {
               segmentsToReposition = segments.filter(s => s.position > (segmentToDelete.position + 1));
            }

            for (const segmentToReposition of segmentsToReposition) {
                const updatedPosition = segmentToReposition.position - 1;
                updatedBuildSegments.push({
                    oldSegment: segmentToReposition,
                    newSegment: { ...segmentToReposition, position: updatedPosition }
                });
            }

            const deleteFirstLocation = node.id === segmentToDelete.fromId;
            deleteSegment(segmentToDelete, deleteFirstLocation, DesignMode.GISMode, undefined, awaitSessionUpdate)(store.dispatch).then(() => {
                if (updatedBuildSegments.length > 0) {
                    patchSegments(updatedBuildSegments)(store.dispatch);
                }
            });
            return [segmentToDelete, deleteFirstLocation, updatedBuildSegments];
        } else {
            return [undefined, false, undefined];
        }
    }

    private async handleAccessPoints(): Promise<void> {
        if (this.startAccessPointId === this.endAccessPointId) {
            console.warn("There are no access points to link/unlink");
            return;
        }

        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        const { flexnapBuilds, schrodingerBuilds, bulkBuilds } = build;
        const selectedBuild = [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds].find(b => b.id === selectedBuildId);
        if (selectedBuild) {
            if (this.startAccessPointId !== -1) {
                this.unlinkAccessPointFromBuild(this.startAccessPointId, selectedBuild.id, selectedBuild.type);
            }

            if (this.endAccessPointId !== -1) {
                this.linkAccessPointToBuild(this.endAccessPointId, selectedBuild.id, selectedBuild.type)
            }
        }
    }

    private linkAccessPointToBuild(accessPointId: number, buildId: number, buildType: BuildType): void {
        switch (buildType) {
            case BuildType.FlexNap:
                linkNapToBuild(accessPointId, buildId)(store.dispatch);
                break;
            case BuildType.Schrodinger: {
                const { taps } = store.getState().tapsSchrodinger;
                const tap = taps.find(({ id }) => id === accessPointId);
                tap && linkTapsToBuild([tap], buildId, true)(store.dispatch);
                break;
            }
            case BuildType.Bulk:
                linkSplicePointToBuild(accessPointId, buildId)(store.dispatch);
                break;
        }
    }

    private unlinkAccessPointFromBuild(accessPointId: number, buildId: number, buildType: BuildType): void {
        switch (buildType) {
            case BuildType.FlexNap:
                unlinkNapFromBuild(accessPointId, buildId)(store.dispatch);
                break;
            case BuildType.Schrodinger:
                unlinkTapFromBuild(accessPointId, buildId)(store.dispatch);
                break;
            case BuildType.Bulk:
                unlinkSplicePointFromBuild(accessPointId, buildId)(store.dispatch);
                break;
        }
    }
}
