import { MapItemDefinition } from './map-item-definition';

export enum MapClickEventType {
    SINGLE_CLICK,
    DOUBLE_CLICK,
    SHIFT_CLICK,
}

export interface MapClickEvent {
    type: MapClickEventType;
    x: number;
    y: number;
    items: MapItemDefinition[];
    contextMenuCallback?: (prop: unknown) => void;
}
