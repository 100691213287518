import { Graphics } from 'pixi.js';

import { CO_PROPORTIONS, IFrameProps } from '../build.types';

export const useCo = ({ position }: IFrameProps) => {
    const { x, y } = position;
    const xPositionText = x + CO_PROPORTIONS.WIDTH / 2;
    const yPositionText = y + CO_PROPORTIONS.TEXT_TOP_POSITION;
    const xPositionArrow = xPositionText;
    const yPositionArrow = y + CO_PROPORTIONS.ARROW_TOP_POSITION;

    const drawFrame = (graphics: Graphics) => {
        graphics.clear();
        graphics.beginFill(0xEEEEEE, 1);
        graphics.lineStyle(4, 0x424242, 1);
        graphics.drawRoundedRect(x, y, CO_PROPORTIONS.WIDTH, CO_PROPORTIONS.HEIGHT, 8);
        graphics.endFill();
    };


    return { 
        drawFrame,
        xPositionText, yPositionText, xPositionArrow, yPositionArrow
     };
};