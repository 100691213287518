import { DeleteSegmentNodeCommand } from './build/delete-segment-node-command';
import { ExportBuildCommand } from './build/export-build-command';
import { LockBuildCommand } from './build/lock-build-command';
import { ModifyCableCommand as ModifyFlexnapCableCommand } from './build/modify-cable-command';
import { UnlockBuildCommand } from './build/unlock-build-command';
import { Command } from './command';
import { CommandType } from './command-type';
import { CreateDesignAreaCommand } from './design-area/create-design-area-command';
import { DeleteDesignAreaCommand } from './design-area/delete-design-area-command';
import { ModifyDesignAreaCommand } from './design-area/modify-design-area-command';
import { CreateElementCommand } from './element/create-element-command';
import { DeleteElementCommand } from './element/delete-element-command';
import {
    CreateBuildCommand as CreateFlexnapBuildCommand
} from './flexnap/build/create-build-command';
import { CreatePretermCommand } from './flexnap/build/create-preterm-command';
import {
    DeleteBuildCommand as DeleteFlexnapBuildCommand
} from './flexnap/build/delete-build-command';
import {
    DeleteCableCommand as DeleteFlexnapCableCommand
} from './flexnap/build/delete-cable-command';
import { CreateNapCommand } from './flexnap/nap/create-nap-command';
import { DeleteNapCommand } from './flexnap/nap/delete-nap-command';
import { MoveNapCommand } from './flexnap/nap/move-nap-command';
import { HistoryPayload } from './history-payload';
import { AddSpanCommand } from './schrodinger/build/canvas/add-span-command';
import { DeleteSpanCommand } from './schrodinger/build/canvas/delete-span-command';
import {
    CreateBuildCommand as CreateSchrodingerBuildCommand
} from './schrodinger/build/create-build-command';
import {
    DeleteBuildsCommand as DeleteSchrodingerBuildsCommand
} from './schrodinger/build/delete-builds-command';
import {
    DeleteCableCommand as DeleteSchrodingerCableCommand
} from './schrodinger/build/delete-cable-command';
import { CreateTapCommand } from './schrodinger/tap/create-tap-command';
import { CreateTetherCommand } from './schrodinger/tap/create-tether-command';
import { DeleteTapCommand } from './schrodinger/tap/delete-tap-command';
import { DeleteTetherCommand } from './schrodinger/tap/delete-tether-command';
import { MoveTapCommand } from './schrodinger/tap/move-tap-command';
import { ConvertBuildCommand } from './bulk/build/convert-build-command';

export class CommandFactory {

    public static GetCommand(history: HistoryPayload | undefined): Command | null {
        const { type, payload } = history ?? {};
        switch (type) {
            case CommandType.CreateFlexNapCable: return new CreateFlexnapBuildCommand(payload);
            case CommandType.DeleteFlexNapCable: return new DeleteFlexnapCableCommand(payload);
            case CommandType.DeleteFlexNapBuild: return new DeleteFlexnapBuildCommand(payload);
            case CommandType.ExportFlexNapBuild: return new ExportBuildCommand(payload);

            case CommandType.ModifyCable: return new ModifyFlexnapCableCommand(payload);
            case CommandType.DeleteSegmentNode: return new DeleteSegmentNodeCommand(payload);

            case CommandType.ConvertBulkToFlexnapCable: return new ConvertBuildCommand(payload);

            case CommandType.CreateSchrodingerBuild: return new CreateSchrodingerBuildCommand(payload);
            case CommandType.ModifySchrodingerCable: throw new Error("Not yet implemented"); // new ModifySchrodingerCableCommand(payload);
            case CommandType.DeleteSchrodingerCable: return new DeleteSchrodingerCableCommand(payload);
            case CommandType.DeleteSchrodingerBuilds: return new DeleteSchrodingerBuildsCommand(payload);

            case CommandType.AddCanvasSpan: return new AddSpanCommand(payload);
            case CommandType.DeleteCanvasSpan: return new DeleteSpanCommand(payload);

            case CommandType.CreatePreterm: return new CreatePretermCommand(payload);

            case CommandType.CreateFlexNapNap: return new CreateNapCommand(payload);
            case CommandType.MoveFlexNapNap: return new MoveNapCommand(payload);
            case CommandType.DeleteFlexNapNap: return new DeleteNapCommand(payload);

            case CommandType.CreateSchrodingerTap: return new CreateTapCommand(payload);
            case CommandType.DeleteSchrodingerTap: return new DeleteTapCommand(payload);
            case CommandType.MoveSchrodingerTap: return new MoveTapCommand(payload);
            case CommandType.CreateSchrodingerTether: return new CreateTetherCommand(payload);
            case CommandType.DeleteSchrodingerTether: return new DeleteTetherCommand(payload);

            case CommandType.LockBuild: return new LockBuildCommand(payload);
            case CommandType.UnlockBuild: return new UnlockBuildCommand(payload);

            case CommandType.CreateDesignArea: return new CreateDesignAreaCommand(payload);
            case CommandType.ModifyDesignArea: return new ModifyDesignAreaCommand(payload);
            case CommandType.DeleteDesignArea: return new DeleteDesignAreaCommand(payload);

            case CommandType.CreateElement: return new CreateElementCommand(payload);
            case CommandType.DeleteElement: return new DeleteElementCommand(payload);

            default: return null;
        }
    }
}