import { Viewport as PixiViewport } from 'pixi-viewport';
import React from 'react';

import { PixiComponent, TilingSprite } from '@inlet/react-pixi';

import { IViewportState } from '../../redux/viewport.state';
import { useViewport } from './viewport.hooks';
import { IViewportComponentProps, ViewportProps } from './viewport.types';

const ReactViewport = PixiComponent<ViewportProps, PixiViewport>('Viewport', {
    create: (props) => {
        return props.viewport || new PixiViewport();
    },
    applyProps: (instance: PixiViewport, oldProps: IViewportState, newProps: IViewportState) => {
        const positionChanged = newProps.position !== oldProps.position && (newProps.position!.x !== instance.position.x || newProps.position!.y !== instance.position.y);
        if (positionChanged) {
            instance.animate({
                position: newProps.position,
                time: 250,
            });
        }
    }
});

export const Viewport: React.FC<IViewportComponentProps> = (props) => {
    const { ref, state, handleClick } = useViewport(props);
    return (
        <ReactViewport viewport={ref.current} {...state}>
            <TilingSprite
                image={require('../../components/canvas/assets/bg-pattern.png')}
                width={ref.current.worldWidth}
                height={ref.current.worldHeight}
                tilePosition={{x: 0, y: 0}}
                tileScale={{ x: 0.265, y: 0.265 }}
                interactive
                click={handleClick}
            />
            {props.children}
        </ReactViewport>
    );
}