import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { CpqInstallationType } from '../../models/cpqinstallationtype';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class SideSlackValidator implements Validator<FlexNapBuildData> {

    private installationTypes: CpqInstallationType[];
    private units: Units;

    constructor(installationTypes: CpqInstallationType[], units: Units) {
        this.installationTypes = installationTypes;
        this.units = units;
    }

    public validate(build: FlexNapBuildData): ValidationResult[] | null {
        const res: ValidationResult[] = [];
        const installationType = this.installationTypes.find((i) => i.description === build.installationType);
        if (!installationType) {
            throw new Error(`Installation type is invalid ${build.installationType}`);
        }
        const { cO_Slack_Minimum: minCoSlack, field_Slack_Minimum: minFieldSlack } = installationType;
        const coSlack = build.coSlack.toUnit(Units.feet);
        const fieldSlack = build.fieldSlack.toUnit(Units.feet);
        if (coSlack.value < minCoSlack && !build.pretermLateral) {
            const coSlackMinimum = new DataValue(minCoSlack, Units.feet);
            res.push({
                buildId: build.id,
                itemId: null,
                itemType: ItemType.Build,
                shortDescription: i18next.t(LocalizationKeys.ErrorCoSlackInsufficient),
                longDescription: i18next.t(LocalizationKeys.ErrorCoSlackInsufficientLong, { value: build.coSlack.format(this.units, 2), minimum: coSlackMinimum.format(this.units, 2) }),
                type: ValidationResultType.Error,
                extent: build.getExtent()
            });
        }
        if (fieldSlack.value < minFieldSlack) {
            const fieldSlackMinimum = new DataValue(minFieldSlack, Units.feet);
            res.push({
                buildId: build.id,
                itemId: null,
                itemType: ItemType.Build,
                shortDescription: i18next.t(LocalizationKeys.ErrorFieldSlackInsufficient),
                longDescription: i18next.t(LocalizationKeys.ErrorFieldSlackInsufficientLong, { value: build.fieldSlack.format(this.units, 2), minimum: fieldSlackMinimum.format(this.units, 2) }),
                type: ValidationResultType.Error,
                extent: build.getExtent()
            });
        }
        return res.length > 0 ? res : null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapBuildData && entity.installationType !== null;
    }
}
