import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { store } from '../../dna';
import { BuilderHelper } from '../../helpers/build-helper';
import { LocalizationKeys } from '../../locales/types';
import { DesignMode } from '../../models/design-mode';
import { OpticalTether } from '../../models/schrodinger/optical-tether';
import { TetherType } from '../../models/schrodinger/tether-type';
import { StateModel } from '../../redux/reducers';
import { deleteTap } from '../../redux/schrodinger/tap.state';
import { selectTapSchrodinger, selectTerminalSchrodinger } from '../../redux/selection.state';
import { setSelectHighlighted } from '../../redux/viewport.state';
import { displayedBuildAwaitingSessionUpdateSelector } from '../../selectors/build.selectors';
import { designModeSelector, schrodingerBuildsSelector } from '../../selectors/root.selectors';
import { selectedTapBuildSelector } from '../../selectors/schrodinger/build.selector';
import { selectedTapOrderedSiblingsSelector } from '../../selectors/schrodinger/taps.selectors';
import {
    selectedTetherSelector, selectedTetherSiblingsSelector
} from '../../selectors/schrodinger/tether.selector';
import { TapCardProps } from './tap-card.component.types';

export const useTapCard = ({ selectedTap: tap }: TapCardProps) => {
    const dispatch = useDispatch();
    const state = store.getState();
    const { t } = useTranslation();
    const { userId } = state.authentication;
    const { selectedTapIdSchrodinger: selectedTapId, selectedTetherIdSchrodinger: selectedTetherId, selectedTerminalIdSchrodinger: selectedSchrodingerTerminalId } = state.selection;

    const parentBuild = useSelector(selectedTapBuildSelector);
    const siblingTaps = useSelector(selectedTapOrderedSiblingsSelector);
    const siblingTethers = useSelector(selectedTetherSiblingsSelector);
    const selectedTether = useSelector(selectedTetherSelector);
    const schrodingerBuilds = useSelector(schrodingerBuildsSelector);
    const awaitingSessionUpdate = useSelector(displayedBuildAwaitingSessionUpdateSelector);
    const designMode = useSelector(designModeSelector)

    const locationId = useTapLocationId(state, tap?.buildId, tap?.elementId);

    const [tapCardTitle, setTapCardTitle] = useState('');

    const disabled = parentBuild ? parentBuild.modifiedById !== userId || !!parentBuild.lockedById : !!tap?.modifiedById && tap.modifiedById !== userId;

    const selectedTapNumber = (tap && siblingTaps) ? (siblingTaps.findIndex((t) => t.id === selectedTapId) + 1) : 0;
    const selectedTetherNumber = siblingTethers.findIndex((t) => t.id === selectedTetherId) + 1;

    const hasSelectedTerminal = selectedSchrodingerTerminalId !== undefined;

    const opticalTethers = siblingTethers.filter((t) => t.type === TetherType.Optical).map((t, idx) => ({ t: t as OpticalTether, number: idx + 1 }));
    const selectedTerminalsTetherWithIndex = hasSelectedTerminal ? opticalTethers.find((suid) => suid.t && suid.t.terminal && suid.t.terminal.id === selectedSchrodingerTerminalId) : undefined;
    const selectedTerminal = selectedTerminalsTetherWithIndex?.t.terminal;
    const selectedTerminalNumber = selectedTerminalsTetherWithIndex?.number;

    const tapCardSubtitle = useMemo(() => {
        const build = schrodingerBuilds.find((b) => b.id === tap?.buildId);
        return build ? BuilderHelper.getBuildDisplayId(build) : t(LocalizationKeys.Unconnected);
    }, [schrodingerBuilds, t, tap]);

    useEffect(() => {
        setTapCardTitle(`${t(LocalizationKeys.Tap)} ${selectedTapNumber || ""}`);
    }, [t, selectedTapNumber]);

    const unselectTap = useCallback((): void => {
        dispatch(selectTapSchrodinger());
        if (designMode === DesignMode.CanvasMode) {
            dispatch(setSelectHighlighted())
        }
    }, [designMode, dispatch]);

    const unselectTerminal = useCallback((): void => {
        dispatch(selectTerminalSchrodinger);
    }, [dispatch]);

    const deleteSelectedTap = useCallback((): void => {
        if (tap) {
            dispatch(deleteTap(tap, false, true))
        }
    }, [dispatch, tap]);

    return {
        disabled: disabled || awaitingSessionUpdate,
        locationId, selectedTether, selectedTetherNumber, selectedTerminal, selectedTerminalNumber, tapCardTitle, tapCardSubtitle,
        unselectTap, unselectTerminal, deleteSelectedTap
    };
};

const useTapLocationId = (state: StateModel, buildId: number | null | undefined, elementId: number | undefined): string => {
    if (!elementId || !buildId) {
        return "";
    }
    const { segments } = state.build;
    const buildSegments = segments.filter((s) => s.buildId === buildId).sort((a, b) => a.position - b.position);
    const index = buildSegments.findIndex((s) => s.toId === elementId || s.fromId === elementId);
    if (index < 0) {
        return "";
    }
    const segment = buildSegments[index];
    const location = segment.toId === elementId ? index + 2 : index + 1;
    return `${location * 10 < 100 ? 0 : ''}${location * 10}`;
};