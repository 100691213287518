import { Build } from '../../../models/build';
import { BuildSegment } from '../../../models/build-segment';
import { ElementType } from '../../../models/element-type';
import { ValidationResultType } from '../../../validation/validation-result-type';

export const RISER_SPAN_MINIMUM_FEET = 20;
export interface SegmentCardProps {
    segment: BuildSegment;
    build: Build;
    collapsed?: boolean;
    disabled?: boolean;
}

export const elementTypesWithRiser: ElementType[] = [
    ElementType.Handhole,
    ElementType.Manhole,
    ElementType.Vault,
];

export interface SegmentValidationResult {
    valid: boolean;
    status?: ValidationResultType;
    measure?: string;
}