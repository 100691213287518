import React from "react";
import { useTranslation } from "react-i18next";
import "./cabinet-port-number-manual.scss";

import { Typography } from "@orbit/theme-provider";

import { LocalizationKeys } from "../../../locales/types";
import { CardCheckbox, CardInput } from "../../card/card-controls";
import { DialogComponent } from "../dialog.component";
import { useCabinetPortNumberManualDialog } from "./cabinet-port-number-manual-dialog.hooks";

export const CabinetPortNumberManualDialog = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        isOpen,
        title,
        fiberCount,
        buildId,
        lowPortNumber,
        highPortNumber,
        includeUnused,
        unusedFibersLabel,
        handleLowPortNumberChange,
        handleHighPortNumberChange,
        handleIncludeUnusedCheckboxChange,
        onSave,
        onClose,
    } = useCabinetPortNumberManualDialog();

    return !isOpen ? null : (
        <DialogComponent
            open={isOpen}
            title={title}
            positiveTitle={t(LocalizationKeys.Save)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={onSave}
            onNegativeClick={onClose}
            onClose={onClose}
        >
            <div id="manual-port-number-content">
                <Typography variant={"body1"} label={`${t(LocalizationKeys.FiberCount)}: ${fiberCount}`} />
                <Typography variant={"body1"} label={unusedFibersLabel} />
                {CardCheckbox(t(LocalizationKeys.IncludeUnusedFiber), includeUnused, handleIncludeUnusedCheckboxChange)}
                <div className="selects-row">
                    {CardInput({
                        label: t(LocalizationKeys.Low),
                        value: lowPortNumber,
                        name: `${t(LocalizationKeys.Low)}-${buildId}`,
                        onChange: handleLowPortNumberChange,
                    })}
                    {CardInput({
                        label: t(LocalizationKeys.High),
                        value: highPortNumber,
                        name: `${t(LocalizationKeys.High)}-${buildId}`,
                        onChange: handleHighPortNumberChange,
                    })}
                </div>
            </div>
        </DialogComponent>
    );
};
