import { Dispatch } from 'redux';
import { Command } from '../../command';
import { deleteNap, undoDeleteNap } from '../../../redux/tap.state';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class DeleteNapCommand extends Command {
    private napId: number;
    private pretermBuilds?: FlexNapBuild[];

    constructor({ id: napId, pretermBuilds }) {
        super();
        this.napId = napId;
        this.pretermBuilds = pretermBuilds;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoNapDeleting };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoNapDeleting };
    }

    public undo(dispatch: Dispatch): void {
        undoDeleteNap(this.napId, this.pretermBuilds)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        deleteNap(this.napId, this.pretermBuilds, true)(dispatch);
        super.redo(dispatch);
    }
}