export enum InteractableFeatureType {
    POLE = 'POLE',
    PARCEL = 'PARCEL',
    FLEXNAP_SEGMENT = 'FLEXNAP_SEGMENT',
    SCHRODINGER_SEGMENT = 'SCHRODINGER_SEGMENT',
    BULK_SEGMENT = 'BULK_SEGMENT',
    CABINET = 'CABINET',
    NAP = 'NAP',
    TAP = 'TAP',
    SPLICE_POINT = 'SPLICE_POINT',
    TERMINAL = 'TERMINAL',
    TERMINAL_SEGMENT = 'TERMINAL_SEGMENT',
    MANHOLE = 'MANHOLE',
    HANDHOLE = 'HANDHOLE',
    VAULT = 'VAULT',
    TRENCH = 'TRENCH',
    BORE = 'BORE',
    TAP_SCHRODINGER = 'TAP_SCHRODINGER',
    POWER_SCHRODINGER = 'POWER_SCHRODINGER',
    POWER_LABEL_SCHRODINGER = 'POWER_LABEL_SCHRODINGER',
    OPTICAL_SCHRODINGER = 'OPTICAL_SCHRODINGER',
    LOCKED_BUILD = 'LOCKED_BUILD',
    REJECTED_BUILD = 'REJECTED_BUILD',
    EXPORTED_BUILD = 'EXPORTED_BUILD',
    DESIGN_AREA = 'DESIGN_AREA',
}
