import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../locales/types';
import { selectSegment } from '../../redux/selection.state';
import { setCurrentHighlighted, setPreviewHighlighted } from '../../redux/viewport.state';
import { canvasLocationsSelector } from '../../selectors/build.selectors';
import { viewportSelector } from '../../selectors/root.selectors';
import { FirstIcon } from '../dialogs/splice-plan/first-icon';
import { LastIcon } from '../dialogs/splice-plan/last-icon';
import { NextIcon } from '../dialogs/splice-plan/next-icon';
import { PreviousIcon } from '../dialogs/splice-plan/previous-icon';
import { IPanTool, PanKeys, PanControls } from './pan-tool.types';

export const usePanTool = (): IPanTool => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locations = useSelector(canvasLocationsSelector);
    const { highlighted } = useSelector(viewportSelector);

    const [hoveredControl, setHoveredControl] = useState<PanControls>();

    const updateHighlighted = useCallback((value: number) => {
        const index = clamp(value, { max: locations.length });
        dispatch(setCurrentHighlighted(index));
        dispatch(selectSegment());
    }, [locations, dispatch])

    const updatePreviewHighlight = useCallback((value: number) => {
        if (value >= 0 && value <= locations.length) {
            dispatch(setPreviewHighlighted(value));
        }
    }, [locations, dispatch])

    const unsetPreviewHighlight = useCallback(() => {
        dispatch(setPreviewHighlighted());
        setHoveredControl(undefined);
    }, [dispatch])

    const onNext = useCallback(() => {
        updateHighlighted((highlighted.current ?? 0) + 1);
    }, [highlighted, updateHighlighted]);

    const onPrevious = useCallback(() => {
        updateHighlighted((highlighted.current ?? 1) - 1);
    }, [highlighted, updateHighlighted]);

    const onFirst = useCallback(() => {
        updateHighlighted(0);
    }, [updateHighlighted]);

    const onLast = useCallback(() => {
        updateHighlighted(locations.length);
    }, [updateHighlighted, locations]);

    const handlePanShortcut = useCallback((e: KeyboardEvent) => {
        if (e.shiftKey && e.key === PanKeys.ArrowLeft) { onFirst(); }
        else if (e.key === PanKeys.ArrowLeft) { onPrevious(); }
        else if (e.shiftKey && e.key === PanKeys.ArrowRight) { onLast(); }
        else if (e.key === PanKeys.ArrowRight) { onNext(); }
    }, [onFirst, onPrevious, onLast, onNext]);

    const onNextHover = useCallback(() => {
        if (hoveredControl !== PanControls.Next) {
            setHoveredControl(PanControls.Next);
        }
    }, [hoveredControl]);

    const onPreviousHover = useCallback(() => {
        if (hoveredControl !== PanControls.Previous) {
            setHoveredControl(PanControls.Previous);
        }
    }, [hoveredControl]);

    const onFirstHover = useCallback(() => {
        if (hoveredControl !== PanControls.First) {
            setHoveredControl(PanControls.First);
        }
    }, [hoveredControl]);

    const onLastHover = useCallback(() => {
        if (hoveredControl !== PanControls.Last) {
            setHoveredControl(PanControls.Last);
        }
    }, [hoveredControl]);

    useEffect(() => {
        if (hoveredControl === PanControls.Previous)
            updatePreviewHighlight(highlighted.current !== undefined ? highlighted.current - 1 : 0)
        if (hoveredControl === PanControls.Next)
            updatePreviewHighlight(highlighted.current !== undefined ? highlighted.current + 1 : 1)
        if (hoveredControl === PanControls.First)
            updatePreviewHighlight(0)
        if (hoveredControl === PanControls.Last)
            updatePreviewHighlight(locations.length)
    }, [highlighted, hoveredControl, locations.length, updatePreviewHighlight])

    return {
        handlePanShortcut,
        toolItems: {
            first: {
                source: FirstIcon,
                tooltip: t(LocalizationKeys.PanFirst),
                onClick: onFirst,
                onMouseEnter: onFirstHover,
                onMouseLeave: unsetPreviewHighlight
            },
            previous: {
                source: PreviousIcon,
                tooltip: t(LocalizationKeys.PanPrevious),
                onClick: onPrevious,
                onMouseEnter: onPreviousHover,
                onMouseLeave: unsetPreviewHighlight
            },
            next: {
                source: NextIcon,
                tooltip: t(LocalizationKeys.PanNext),
                onClick: onNext,
                onMouseEnter: onNextHover,
                onMouseLeave: unsetPreviewHighlight
            },
            last: {
                source: LastIcon,
                tooltip: t(LocalizationKeys.PanLast),
                onClick: onLast,
                onMouseEnter: onLastHover,
                onMouseLeave: unsetPreviewHighlight
            }
        }
    }
}

export const clamp = (value: number, { min = 0, max }) => {
    return Math.min(Math.max(value, min), max);
}