import './design-area-card.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Typography } from '@orbit/theme-provider';
import { TextField } from '@orbit/text-field';
import { Select, SelectItem } from '@orbit/select';
import { LocalizationKeys } from '../../../../locales/types';
import { DialogComponent } from '../../../dialogs/dialog.component';
import { useDesignAreaCard } from './design-area-card.hooks';
import { DesignAreaCardProps } from './design-area-card.types';

export const DesignAreaCard = (props: DesignAreaCardProps): JSX.Element | null => {
    const {
        dialogProps,
        nameProps,
        projectIdProps,
        descriptionProps,
        zipCodeProps,
        cityProps,
        countryProps,
        regionProps,
        countries, regions,
    } = useDesignAreaCard(props);
    const { t } = useTranslation();

    return (
        ReactDOM.createPortal(
            <DialogComponent {...dialogProps}>
                <div className="canvas-design-area-card">
                    <div className="info-helper-text">
                        <Typography variant='body1' label={t(LocalizationKeys.WorkspaceInfoHelperText)} />
                    </div>
                    <div className='input-container'>
                        <TextField {...nameProps} />
                        <TextField {...projectIdProps} />
                        <TextField {...descriptionProps} />
                        <Select {...countryProps}>
                            {countries.map((c) => <SelectItem key={c} value={c}>{c}</SelectItem>)}
                        </Select>
                        <Select {...regionProps}>
                            {regions.map((r) => <SelectItem key={r} value={r}>{r}</SelectItem>)}
                        </Select>
                        <TextField {...cityProps} />
                        <TextField {...zipCodeProps} />
                    </div>
                </div>
            </DialogComponent>
            , document.getElementById('react-app-root')!)
    );
};
