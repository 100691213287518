import { PointLike } from '../../models/pointlike';
import { Data } from './data';

export class CabinetData extends Data {
    public point: PointLike;
    public portCount: number;

    constructor(id: number, point: PointLike, portCount: number) {
        super(id);
        this.point = point;
        this.portCount = portCount;
    }

    public getExtent(): number[] {
        const { x, y } = this.point;
        return [x, y, x, y];
    }
}
