import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { selectedBuildIdSelector, selectedNapIdSelector, selectedTapIdSchrodingerSelector, flexnapBuildsSelector, schrodingerBuildsSelector, bulkBuildsSelector, napsSelector, tapsSchrodingerSelector, selectedSplicePointIdSelector, splicePointsSelector } from '../../selectors/root.selectors';
import { BuildType } from "../../models/build-type";
import { FlexNapBuild } from "../../models/flexnap-build";
import { modifyBuild, releaseBuild } from "../../redux/build.state";
import { modifyNap, releaseNap } from "../../redux/tap.state";
import { modifyTap, releaseTap } from "../../redux/schrodinger/tap.state";
import { modifySplicePoint, releaseSplicePoint } from '../../redux/bulk/splice-point.state';

export const EditManager = () => {
    const selectedBuildId = useSelector(selectedBuildIdSelector);
    const flexnapBuilds = useSelector(flexnapBuildsSelector);
    const schrodingerBuilds = useSelector(schrodingerBuildsSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);
    const [lastSelectedBuildId, setLastSelectedBuildId] = useState(-1);
    const selectedNapId = useSelector(selectedNapIdSelector);
    const [lastSelectedNapId, setLastSelectedNapId] = useState(-1);
    const naps = useSelector(napsSelector);
    const selectedTapId = useSelector(selectedTapIdSchrodingerSelector);
    const [lastSelectedTapId, setLastSelectedTapId] = useState(-1);
    const taps = useSelector(tapsSchrodingerSelector);
    const selectedSplicePointId = useSelector(selectedSplicePointIdSelector);
    const [lastSelectedSplicePointId, setLastSelectedSplicePointId] = useState(-1);
    const splicePoints = useSelector(splicePointsSelector);
    const [modifiedBuildId, setModifiedBuildId] = useState(-1);
    const dispatch = useDispatch();

    const editBuild = useCallback((buildId: number) => {
        const builds = [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds];
        let build = builds.find(b => b.id === buildId);
        if (build?.type === BuildType.FlexNap) { // If the build is preterm lateral, we will refer to the parent build
            const flexnapBuild = build as FlexNapBuild;
            if (flexnapBuild?.pretermLateral) {
                const parentBuildId = flexnapBuild.pretermLateral.parentBuildId;
                build = builds.find(b => b.id === parentBuildId);
            }
        }
        if (build) {
            modifyBuild(build.id)(dispatch);
            setModifiedBuildId(build.id);
        }
    }, [flexnapBuilds, schrodingerBuilds, bulkBuilds, dispatch]);

    useEffect(() => {
        if (selectedBuildId) {
            if (lastSelectedBuildId !== selectedBuildId) {
                editBuild(selectedBuildId);
                setLastSelectedBuildId(selectedBuildId);
            }
        } else {
            if (lastSelectedBuildId !== -1 && !selectedNapId && !selectedTapId && !selectedSplicePointId) {
                releaseBuild(modifiedBuildId)(dispatch);
                setModifiedBuildId(-1);
                setLastSelectedBuildId(-1);
                setLastSelectedNapId(-1);
                setLastSelectedTapId(-1);
                setLastSelectedSplicePointId(-1);
            }
        }
    }, [selectedBuildId, lastSelectedBuildId, editBuild, selectedNapId, selectedTapId, selectedSplicePointId, modifiedBuildId, dispatch]);

    useEffect(() => {
        if (selectedNapId) {
            const nap = naps.find(n => n.id === selectedNapId);
            if (nap) {
                if (nap.buildId) { // If the nap is assigned to a build, we will refer to the parent build
                    if (lastSelectedBuildId !== nap.buildId) {
                        editBuild(nap.buildId);
                        setLastSelectedBuildId(nap.buildId);
                    }
                } else {
                    if (lastSelectedNapId !== nap.id) {
                        modifyNap(nap.id)(dispatch);
                        setLastSelectedNapId(nap.id);
                    }
                }
            }
        } else {
            if (lastSelectedNapId !== -1 && !selectedBuildId) {
                releaseNap(lastSelectedNapId)(dispatch);
                setLastSelectedNapId(-1);
            }
        }
    }, [selectedNapId, lastSelectedNapId, naps, lastSelectedBuildId, editBuild, selectedBuildId, dispatch]);

    useEffect(() => {
        if (selectedTapId) {
            const tap = taps.find(n => n.id === selectedTapId);
            if (tap) {
                if (tap.buildId) { // If the TAP is assigned to a build, we will refer to the parent build
                    if (lastSelectedBuildId !== tap.buildId) {
                        editBuild(tap.buildId);
                        setLastSelectedBuildId(tap.buildId);
                    }
                } else {
                    if (lastSelectedTapId !== tap.id) {
                        modifyTap(tap.id)(dispatch);
                        setLastSelectedTapId(selectedTapId);
                    }
                }
            }
        } else {
            if (lastSelectedTapId !== -1 && !selectedBuildId) {
                releaseTap(lastSelectedTapId)(dispatch);
                setLastSelectedTapId(-1);
            }
        }
    }, [selectedTapId, lastSelectedTapId, taps, lastSelectedBuildId, editBuild, selectedBuildId, dispatch]);

    useEffect(() => {
        if (selectedSplicePointId) {
            const splicePoint = splicePoints.find(s => s.id === selectedSplicePointId);
            if (splicePoint) {
                if (splicePoint.buildId) {
                    if (lastSelectedBuildId !== splicePoint.buildId) {
                        editBuild(splicePoint.buildId);
                        setLastSelectedBuildId(splicePoint.buildId);
                    }
                } else {
                    if (lastSelectedSplicePointId !== splicePoint.id) {
                        modifySplicePoint(splicePoint.id)(dispatch);
                        setLastSelectedSplicePointId(selectedSplicePointId);
                    }
                }
            }
        } else {
            if (lastSelectedSplicePointId !== -1 && !selectedBuildId) {
                releaseSplicePoint(lastSelectedSplicePointId)(dispatch);
                setLastSelectedSplicePointId(-1);
            }
        }
    }, [selectedSplicePointId, lastSelectedSplicePointId, splicePoints, selectedBuildId, lastSelectedBuildId, editBuild, dispatch])

    return null;
}