import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LayerState {
    layers: Layer[];
}

export interface Layer {
    id: string;
    name: string;
    group: string;
    visible: boolean;
}

const initialState: LayerState = {
    layers: [],
};

const registerLayerReducer = (state: LayerState, action: PayloadAction<Layer>) => {
    const layer = action.payload as Layer;
    if (state.layers.find((l) => l.id === layer.id)) {
        console.warn('Trying to re-register layer', layer.id);
        return;
    }
    state.layers.push(layer);
};

const unregisterLayerReducer = (state: LayerState, action: PayloadAction<string>) => {
    const id = action.payload;
    let layers = state.layers;

    layers = layers.filter(l => l.id !== id);
    
    state.layers = layers;
}

const toggleLayerReducer = (state: LayerState, action: PayloadAction<string>) => {
    const layerId = action.payload;
    const stateLayer = state.layers.find((l) => l.id === layerId);
    if (stateLayer) {
        stateLayer.visible = !stateLayer.visible;
    }
    else {
        console.warn('Trying to toggle non-existent layer', layerId);
    }
};

const toggleLayerGroupReducer = (state: LayerState, action: PayloadAction<string>) => {
    const group = action.payload;
    const groupLayers = state.layers.filter((l) => l.group === group);
    const visible = groupLayers.some((l) => l.visible);
    for (const layer of groupLayers) {
        layer.visible = !visible;
    }
};

const { actions, reducer } = createSlice({
    name: 'layer',
    initialState,
    reducers: {
        registerLayer: registerLayerReducer,
        unregisterLayer: unregisterLayerReducer,
        toggleLayer: toggleLayerReducer,
        toggleLayerGroup: toggleLayerGroupReducer,
    },
});

export { reducer as LayerReducer };
export const { registerLayer, unregisterLayer, toggleLayer, toggleLayerGroup } = actions;
