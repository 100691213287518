import { PluggableMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
import VectorLayer from 'ol/layer/Vector';

import { CableCalculationsHelper } from '../helpers/cable-calculations-helper';
import { MapItemDefinition } from '../models/map-item-definition';
import { PointLike } from '../models/pointlike';
import { OpenlayerUtility } from '../openlayers/openlayer-utility';

export class PointHelper {
    public static findConnectedFeaturesFromCoordinates(map: PluggableMap, coordinates: Coordinate[] | PointLike[], moveToLayerTypes: Set<string>): MapItemDefinition[] {
        const connectedFeatures: MapItemDefinition[] = [];
        for (const coordinate of coordinates) {
            const infrastructureFeatures: MapItemDefinition[] = [];
            // The Coordinate type is just a number[], so checking if coordinate is an array will let us differentiate if it's a Coordinate or a PointLike
            const coordinatePoint: PointLike = Array.isArray(coordinate) ? { x: coordinate[0], y: coordinate[1] } : coordinate;
            const moveToLayers = map.getLayers().getArray().filter(layer => moveToLayerTypes.has(layer.get('id')) && layer instanceof VectorLayer) as VectorLayer[];
            for (const layer of moveToLayers) {
                infrastructureFeatures.push(...layer.getSource().getFeatures().map(f => OpenlayerUtility.getFeatureDefinition(f)));
            }
            if (infrastructureFeatures.length > 0) {
                const closestOverallFeatureToCoordinate = this.findClosestFeatureToCoordinate(coordinatePoint, infrastructureFeatures);
                if (closestOverallFeatureToCoordinate) {
                    connectedFeatures.push(closestOverallFeatureToCoordinate);
                }
            }
        }
        return connectedFeatures;
    }

    public static findClosestFeatureToCoordinate(coordinatePoint: PointLike, features: MapItemDefinition[]) {
        if (features.length === 1) {
            return features[0];
        }
        let shortestDistance: number | undefined = undefined;
        let indexShortest = 0;
        for (let i = 0; i < features.length; i++) {
            const coordinates = features[i].elementCoordinates;
            if (coordinates) {
                const featurePoint: PointLike = { x: coordinates.x, y: coordinates.y };
                const distance = CableCalculationsHelper.getDistanceBetween(coordinatePoint, featurePoint);
                if (shortestDistance === undefined || distance.value < shortestDistance) {
                    shortestDistance = distance.value;
                    indexShortest = i;
                }
            }
        }
        return features[indexShortest];
    }
}