import * as rfc from 'rfc6902';

import { BuildSegment } from '../../models/build-segment';
import { BulkBuild } from '../../models/bulk/bulk-build';
import { SecuredService } from '../abstract-secured-v2.service';
import { AddBuildRequest } from '../build.service';
import { FlexNapBuildResult } from '../flexnap-build.service';

export class BulkBuildService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/build/bulk';

    public addBulkBuild(createBulkBuildRequest: AddBuildRequest): Promise<AddBulkBuildResult | undefined> {
        return this.post(`${this.baseUrl}`, createBulkBuildRequest);
    }

    public updateBuild(current: BulkBuild, updated: BulkBuild): Promise<BulkBuild | undefined> {
        const patch = rfc.createPatch(current, updated);
        return this.patch(`${this.baseUrl}/${current.id}`, patch);
    }

    public deleteBuild(buildId: number): Promise<number[] | undefined> {
        return this.delete(`${this.baseUrl}/${buildId}`);
    }

    public deleteBuilds(buildIds: number[]): Promise<number[] | undefined> {
        return this.post(this.baseUrl + '/delete', { buildIds });
    }

    public convertToFlexnapBuild(convertRequest: ConvertToFlexnapRequest): Promise<FlexNapBuildResult | undefined> {
        return this.post(`${this.baseUrl}/convert`, convertRequest);
    }

    public undoConvertToFlexnapBuild(undoConvertRequest: UndoConvertToFlexnapRequest): Promise<AddBulkBuildResult | undefined> {
        return this.post(`${this.baseUrl}/undo/convert`, undoConvertRequest);
    }
}

export interface AddBulkBuildResult {
    build: BulkBuild;
    segments: BuildSegment[];
}

export interface ConvertToFlexnapRequest {
    bulkBuildId: number;
    flexnapBuildId?: number;
}

export interface UndoConvertToFlexnapRequest {
    bulkBuildId: number;
    flexnapBuildId: number;
}