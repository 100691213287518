import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { NapData } from '../data/nap-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class PretermNapValidator implements Validator<NapData> {
    validate(entity: NapData): ValidationResult | null {
        if (!entity.childPretermBuilds) {
            return null;
        }

        for (const build of entity.childPretermBuilds) {
            const pretermLateral = build.pretermLateral;
            if (!pretermLateral?.parentTetherIds || !pretermLateral.parentTetherIds.length) {
                return null;
            }

            const pretermStartingTetherId = pretermLateral.parentTetherIds[0];
            const pretermTap = entity.taps.find(t => t.tethers.some(th => th.id === pretermStartingTetherId));
            const pretermTether = pretermTap?.tethers.find(th => th.id === pretermStartingTetherId);
            
            if (!pretermTap || !pretermTether) {
                return null;
            }

            const parentOtherPretermTetherIds = entity.childPretermBuilds.filter(b => b.id !== build.id).flatMap(b => b.pretermLateral?.parentTetherIds);

            let notValid = false;
            for (let tapIndex = 1; tapIndex <= pretermTap.tapIndex; tapIndex++) {
                const tap = entity.taps.find(t => t.tapIndex === tapIndex);
                if (!tap || !tap.tethers || tap.tethers.length === 0) {
                    notValid = true;
                    break;
                }

                for (let tetherIndex = 1; tetherIndex < (tap.id === pretermTap.id ? pretermTether.tetherIndex : 3); tetherIndex++) {
                    const tether = tap.tethers.find(th => th.tetherIndex === tetherIndex);
                    if (!tether || (!tether.terminal && !parentOtherPretermTetherIds?.includes(tether.id))) {
                        notValid = true;
                        break;
                    }
                }
            }
            
            if (notValid) {
                return this.validationResult(ValidationResultType.Error, entity, i18next.t(LocalizationKeys.PretermLateralUnusedFibers))
            }
        }

        return null;
    }

    canValidate(entity: unknown): boolean {
        return entity instanceof NapData && !!entity.childPretermBuilds && entity.childPretermBuilds.length > 0;
    }

    private validationResult(resultType: ValidationResultType, nap: NapData, message: string): ValidationResult {
        return {
            buildId: null,
            itemId: nap.id,
            itemType: ItemType.Nap,
            shortDescription: message,
            type: resultType,
            extent: nap.getExtent()
        };
    }
}