import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LocalizationKeys } from '../../../../locales/types';
import { UnitInterpreter } from '../../../../helpers/unit-interpreter';
import { NotificationService } from '../../../../services/notification.service';

import { deletePreset } from '../../../../redux/schrodinger-preset.state';
import { PresetConfigMode, PresetManagerContext } from '../../preset-manager.types';
import { AttributeGroup } from '../preset-attributes/preset-attributes.types';
import { PresetWizardStepMaterials, PresetWizardSteps, PresetWizardCstics } from '../preset-config/preset-config.types';
import { PresetPropertiesProps } from './preset-properties.types';

export const usePresetProperties = (props: PresetPropertiesProps) => {
    const { t } = useTranslation();
    const { preset } = props;

    const [attributeGroups, setAttributeGroups] = useState<AttributeGroup[]>([]);

    const dispatch = useDispatch();

    const { updateConfigMode } = useContext(PresetManagerContext);

    useEffect(() => {
        const attributeGroupLabelOverrides = {
            [PresetWizardSteps.PowerTetherAttributes]: t(LocalizationKeys.PowerTether),
            [PresetWizardSteps.OpticalTetherAttributes]: t(LocalizationKeys.OpticalTether)
        };

        const updatedAttributeGroups: AttributeGroup[] = [];

        for (const step in PresetWizardStepMaterials) {
            const stepLabel = PresetWizardSteps[step];
            const stepTitle = attributeGroupLabelOverrides[step] || stepLabel;

            const cableType = preset.cstics.find((c) => c.csticId === PresetWizardCstics.CONFIGURATION_TYPE_CODE)?.valueId;
            const skipAttribute = (cableType === 'O' && stepTitle === t(LocalizationKeys.PowerTether))
                || (cableType === 'P' && stepTitle === t(LocalizationKeys.OpticalTether))
            if (skipAttribute) continue;

            if (PresetWizardStepMaterials[step]) {
                PresetWizardStepMaterials[step].forEach(material => {
                    let attributeGroup = updatedAttributeGroups.find(a => a.stepLabel === stepLabel);
                    if (!attributeGroup) {
                        attributeGroup = { stepLabel, title: stepTitle, attributes: [] };
                        updatedAttributeGroups.push(attributeGroup);
                    }

                    attributeGroup.attributes.push(...preset.cstics.filter(c => c.materialId === material));
                });
            }
        }

        const coSlackAttribute = { csticId: 'co-slack', csticName: t(LocalizationKeys.COSlack), valueId: '', valueName: `${preset.coSlack.toFixed(2)} ${UnitInterpreter.toShort(preset.slackUnit)}` };
        const fieldSlackAttribute = { csticId: 'field-slack', csticName: t(LocalizationKeys.FieldSlack), valueId: '', valueName: `${preset.fieldSlack.toFixed(2)} ${UnitInterpreter.toShort(preset.slackUnit)}` };
        updatedAttributeGroups.push({ stepLabel: PresetWizardSteps[PresetWizardSteps.SlackSelection], title: t(LocalizationKeys.Slack), attributes: [coSlackAttribute, fieldSlackAttribute] });

        setAttributeGroups(updatedAttributeGroups);
    }, [t, preset]);

    const handleEditClick = useCallback(() => {
        updateConfigMode(PresetConfigMode.Edit);
    }, [updateConfigMode]);

    const handleDeleteClick = useCallback(() => {
        deletePreset(preset.id)(dispatch);
        NotificationService.info(t(LocalizationKeys.PresetWasDeleted, { presetName: preset.name }));
    }, [dispatch, t, preset]);

    return {
        preset, attributeGroups, handleEditClick, handleDeleteClick
    };
};