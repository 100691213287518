import './preset-properties.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';
import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { csticAliases } from '../../../../locales/cstic-aliases';
import { LocalizationKeys } from '../../../../locales/types';
import { PresetAttribute } from '../preset-attributes/preset-attribute';
import { PresetAttributeGroup } from '../preset-attributes/preset-attribute-group';
import { PresetWizardCstics, PresetWizardSteps } from '../preset-config/preset-config.types';
import { usePresetProperties } from './preset-properties.hooks';
import { PresetPropertiesProps } from './preset-properties.types';

export const PresetProperties = (props: PresetPropertiesProps): JSX.Element => {
    const { t } = useTranslation();
    const { preset, attributeGroups, handleDeleteClick, handleEditClick } = usePresetProperties(props);

    const cableAttributes = attributeGroups.find(g => g.stepLabel === PresetWizardSteps[PresetWizardSteps.AttributeSelection])?.attributes;
    const otherAttributeGroups = attributeGroups.filter(g => g.stepLabel !== PresetWizardSteps[PresetWizardSteps.AttributeSelection]);

    return (<>
        {
            cableAttributes ?
                <div className="card properties">
                    <div className="cable-properties">
                        <img alt="Preset Icon" className="preset-icon" src={require(`./../../preset_cable.jpg`)} />
                        <div className="cable-summary">
                            <div className="preset-property-header schrodinger-properties-header">
                                <div>
                                    <span className="title">{preset.name}</span>
                                    <div>
                                        <span className="body">{
                                            cableAttributes.find(a => a.csticId === PresetWizardCstics.PRODUCT_NUMBER)?.valueName
                                        }</span>
                                    </div>
                                </div>
                                {preset.isDefault && <Chip id="defaultSchrodingerChip" className="default" label={t(LocalizationKeys.Default)} avatar={<img alt={t(LocalizationKeys.Default)} src={require('./../../icon_default.svg')} />} />}
                            </div>
                            <div className="divider" />
                            <div className="attribute-group">
                                <div className="attributes">
                                    {cableAttributes.map(a => a.csticId === PresetWizardCstics.PRODUCT_NUMBER ? '' : <PresetAttribute key={`${a.csticId}_${a.csticName}`} title={csticAliases[a.csticId] ?? a.csticName} description={a.valueName} />)}
                                </div>
                            </div>
                            {otherAttributeGroups.map(g => <PresetAttributeGroup key={g.stepLabel} title={g.title} attributes={g.attributes} />)}
                            <div className="divider" />
                            <div>
                                <TextButton palette={MainPalettes.error} onClick={handleDeleteClick} disabled={preset.isDefault}>{t(LocalizationKeys.Delete)}</TextButton>
                                <TextButton onClick={handleEditClick}>{t(LocalizationKeys.Edit)}</TextButton>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
        }
    </>);
};
