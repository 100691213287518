import React from 'react';

import { ButtonGroup, ClickAwayListener, MenuList, Paper, Popper } from '@mui/material';
import { TextButton } from '@orbit/button';
import { CloudDownload, Delete, FileUpload, Lock, LockOpen } from '@mui/icons-material';

import { DownloadBOMIcon } from '../../../../cards/build/assets/download-bom-icon';
import { useDrawerSelectionActionGroup } from './drawer-selection-action-group.hooks';
import {
    DrawerSelectionActionItem
} from './drawer-selection-action-item/drawer-selection-action-item';

export const DrawerSelectionActionGroup = () => {
    const {
        buildOptionsRef,
        selectAllProps,
        lockProps,
        unlockProps,
        exportProps,
        downloadGISProps,
        downloadBOMProps,
        deleteProps,
        isOptionsMenuOpen,
        handleOptionsMenuClick,
    } = useDrawerSelectionActionGroup();

    return (
        <div>
            <ButtonGroup variant="outlined" ref={buildOptionsRef}>
                <TextButton className="button-group-button" onClick={selectAllProps.onClick}>
                    {selectAllProps.label}
                </TextButton>
                <TextButton className="button-group-button" onClick={handleOptionsMenuClick}>
                    <img src={require('../../../icon_dropdown.svg')} alt="" />
                </TextButton>
            </ButtonGroup>
            <Popper
                open={isOptionsMenuOpen}
                className="options-menu"
                anchorEl={buildOptionsRef.current}
                placement="bottom-end"
                role={undefined}
                transition={true}
                disablePortal={true}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleOptionsMenuClick}>
                        <MenuList id="options-menu">
                            <DrawerSelectionActionItem {...lockProps} icon={<Lock />} />
                            <DrawerSelectionActionItem {...unlockProps} icon={<LockOpen />} />
                            <DrawerSelectionActionItem {...exportProps} icon={<FileUpload />} />
                            <DrawerSelectionActionItem {...downloadGISProps} icon={<CloudDownload />} />
                            <DrawerSelectionActionItem {...downloadBOMProps} icon={<DownloadBOMIcon disabled={downloadBOMProps.disabled} />} />
                            <DrawerSelectionActionItem {...deleteProps} icon={<Delete />} />
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </div>
    );
};
