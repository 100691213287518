import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { DesignMode } from '../../../models/design-mode';
import { ReviewStatus } from '../../../models/review-status';
import { SchrodingerBuild } from '../../../models/schrodinger-build';
import { useWindowSize } from '../../../pixi/viewport/viewport.hooks';
import { DialogType, DrawerType, showDialog, showDrawer } from '../../../redux/overlay.state';
import { activeBuildSelector } from '../../../selectors/build.selectors';
import {
    designAreaSelector, designModeSelector, notificationSelector, validationSelector,
    workspaceSelector
} from '../../../selectors/root.selectors';
import { ValidationResult } from '../../../validation/validation-result';
import { ValidationResultType } from '../../../validation/validation-result-type';

export const useMainMenu = () => {
    const { currentWorkspace } = useSelector(workspaceSelector);
    const designMode = useSelector(designModeSelector);
    const currentBuild = useSelector(activeBuildSelector);
    const { validationResults } = useSelector(validationSelector);
    const { designAreas, designAreaBuilds } = useSelector(designAreaSelector);
    const { notifications } = useSelector(notificationSelector);
    const { width: windowWidth } = useWindowSize();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let menuTitle = '';
    let showUploadBuild = false;
    let catalogCode: string | undefined = undefined;
    let buildValidationErrors: ValidationResult[] | undefined;
    let reviewStatusValid = false;

    if (currentWorkspace && windowWidth >= 768) { // hide the menu title when window width is smaller (UX decision)
        const workspaceDisplay = currentWorkspace.name ?? currentWorkspace.id;
        menuTitle = workspaceDisplay;

        if (currentBuild && currentBuild.type === BuildType.Schrodinger) {
            const build = currentBuild as SchrodingerBuild;
            const buildId = build.id;
            const lastReviewStatus = build.lastReviewStatus;
            const designAreaBuild = designAreaBuilds.find((b) => b.buildIds.includes(buildId));
            const designArea = designAreas.find((a) => a.id === designAreaBuild?.designAreaId);

            let designAreaName = '';
            if (designArea && designArea.name) {
                designAreaName = `/${designArea.name}`;
            }
            else if (designMode === DesignMode.CanvasMode) {
                designAreaName = `/${t(LocalizationKeys.Unassigned)}`;
            }

            menuTitle = `${workspaceDisplay}${designAreaName}/${t(LocalizationKeys.BuildNumber, { buildId })}`;
            showUploadBuild = true;
            catalogCode = build.catalogCode;
            reviewStatusValid = lastReviewStatus === ReviewStatus.Approved || !lastReviewStatus || !build.lockedById;

            const allNotifications = validationResults[currentWorkspace.id] && designMode === DesignMode.GISMode ? [...notifications, ...validationResults[currentWorkspace.id]] : [...notifications];
            buildValidationErrors = allNotifications.filter((n) => n.buildId === build?.id && n.type === ValidationResultType.Error);
        }
    }

    const uploadBuildDisabled = !!catalogCode || !!buildValidationErrors?.length || !reviewStatusValid;

    const handleMenuButtonClicked = useCallback((): void => {
        dispatch(showDrawer(DrawerType.MainMenu));
    }, [dispatch]);

    const handleUploadBuildClick = useCallback((): void => {
        dispatch(showDialog(DialogType.UploadBuild));
    }, [dispatch]);

    return {
        menuTitle,
        showUploadBuild,
        uploadBuildDisabled,
        catalogCode,
        handleMenuButtonClicked,
        handleUploadBuildClick,
    };
}
