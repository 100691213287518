import { CpqCablePart } from '../models/cpqcablepart';
import { CpqInstallationType } from '../models/cpqinstallationtype';
import { CpqTetherPart } from '../models/cpqtetherpart';
import { useValidation } from './validation.hooks'

export interface ValidationProps {
    cableParts: CpqCablePart[];
    tetherParts: CpqTetherPart[];
    installationTypes: CpqInstallationType[];
}

export const Validation = (props: ValidationProps): JSX.Element | null => {
    useValidation(props);
    return null;
}