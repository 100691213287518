import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { showDrawer, DrawerType } from "../../../../redux/overlay.state";

export const useWorkspaceDrawerHeader = () => {
    const dispatch = useDispatch();

    const onPreviousClick = useCallback(() => {
        dispatch(showDrawer(DrawerType.MainMenu));
    }, [dispatch]);

    const onCloseClick = useCallback(() => {
        dispatch(showDrawer());
    }, [dispatch]);

    return { onPreviousClick, onCloseClick };
}