import { store } from '../../dna';
import { BuilderHelper } from '../../helpers/build-helper';
import { SegmentHelper } from '../../helpers/segment-helper';
import { SchrodingerBuild } from '../../models/schrodinger-build';
import { MapClickEvent } from '../../models/map-click-event';
import { InteractableFeatureType } from '../../openlayers/interactable-feature-type';
import { addTap } from '../../redux/schrodinger/tap.state';
import { NAPTool } from '../nap-tool';
import { ToolDefinition } from '../tool-definition';
import { ToolType } from '../tooltype';

export class TapTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.TapSchrodinger,
        name: 'Tap',
        shortcut: 'n',
    };

    public static execute(event: MapClickEvent): void {
        const selectedItems = event.items;
        if (!selectedItems) {
            return;
        }
        const elementId = selectedItems
            .filter((f) => NAPTool.allowedElementTypes.includes(f.type) || f.type === InteractableFeatureType.CABINET)
            .map((f) => f.id)
            .find((f) => !!f);
        if (!elementId || elementId < 1) {
            return;
        }

        const elementCoordinates = { x: event.x, y: event.y };

        const state = store.getState();
        const { segments, schrodingerBuilds } = state.build;
        const { taps } = state.tapsSchrodinger;
        const segmentsOnElement = SegmentHelper.getSegmentsOnElement(elementId, segments);
        const buildsOnElement = BuilderHelper.getBuildsFromSegments(schrodingerBuilds, segmentsOnElement) as SchrodingerBuild[];

        if (buildsOnElement?.length > 1 || taps.some(t => t.buildId === buildsOnElement[0]?.id && t.elementId === elementId)) {
            if (event.contextMenuCallback) {
                event.contextMenuCallback({
                    nap: {
                        elementId,
                        type: InteractableFeatureType.TAP_SCHRODINGER,
                        isCluster: false,
                        elementCoordinates
                    },
                    draggingNap: false
                });
            }

            return;
        }

        this.createTap(elementId, buildsOnElement[0]?.id);
    }

    public static createTap(elementId: number, buildId?: number): void {
        const state = store.getState();

        const { currentWorkspace } = state.workspace;

        if (!currentWorkspace?.id) {
            return;
        }

        addTap(elementId, currentWorkspace.id, buildId)(store.dispatch);
    }
}
