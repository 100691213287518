import React from 'react';

export const HandholeIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <circle cx="3.5" cy="3.5" r="3.5" transform="translate(2 17)" fill="#005293" />
            <circle cx="3.5" cy="3.5" r="3.5" transform="translate(15 17)" fill="#005293" />
            <path d="M 3 3 L 3 17 L 21 17 L 21 3 L 3 3 M 0 0 L 24 0 L 24 20 L 0 20 L 0 0 Z" stroke="none" fill="#005293" />
            <text transform="translate(5 14)" fill="#005293" fontSize="10" fontFamily="'TheSansC4s', Arial"><tspan x="0" y="0">HH</tspan></text>
        </svg>
    );
};
