export enum KnownLayers {
    Poles = 'Poles',
    FlexNAPCables = 'FlexNAP Cables',
    SchrodingerCables = 'Faraday Cables',
    BulkCables = 'Bulk Cables',
    FlexNAP = 'NAPs',
    SchrodingerTAP = 'TAPs (Faraday)',
    Tap = 'TAPs',
    SplicePoints = 'Splice Points',
    PortNumbers = 'Port Numbers',
    Terminals = 'Terminals',
    TerminalSegments = 'Terminal Segments',
    OpticalTethers = 'Optical Tethers',
    PowerTethers = 'Power Tethers',
    Parcels = 'Parcels',
    DropCables = 'Drop Cables',
    Cabinets = 'Cabinets',
    Manholes = 'Manholes',
    Handholes = 'Handholes',
    Vaults = 'Vaults',
    Trenches = 'Trenches',
    Bores = 'Bores',
    DesignAreas = 'Design Areas',
    Shadow = 'Shadow',
    InfrastructureIds = 'Infrastructure IDs',
    BuildCallouts = 'Build / Cable Callouts',
    CabinetCallouts = 'Cabinet Callouts',
    BuildIds = 'Build / Cable IDs',
    BuildState = 'Build State'
}
