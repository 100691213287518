import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uploadBuild } from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { designModeSelector, dialogSelector, selectedBuildIdSelector } from '../../../selectors/root.selectors';
import { DesignMode } from '../../../models/design-mode';
import { displayedBuildIdSelector } from '../../../selectors/build.selectors';

export const useUploadBuildDialog = () => {
    const dialog = useSelector(dialogSelector);
    const designMode = useSelector(designModeSelector);
    const selectedBuildId = useSelector(selectedBuildIdSelector)
    const displayedBuildId = useSelector(displayedBuildIdSelector);
    const buildId = designMode === DesignMode.CanvasMode ? displayedBuildId : selectedBuildId;
    const dispatch = useDispatch();

    const open = dialog === DialogType.UploadBuild;

    const handleUploadClick = useCallback(() => {
        buildId && dispatch(uploadBuild(buildId))
        dispatch(showDialog());
    }, [buildId, dispatch]);

    const handleClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    return {
        open,
        buildId,
        handleUploadClick,
        handleClose,
    };
};