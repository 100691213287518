import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../../locales/types';
import { deleteBulkBuilds, deleteFlexNapBuilds, deleteSchrodingerBuilds } from '../../../../redux/build.state';
import {
    bulkBuildsSelector, flexnapBuildsSelector, schrodingerBuildsSelector, tapsSchrodingerSelector,
    workspaceSelector
} from '../../../../selectors/root.selectors';
import { DrawerMenuContext } from '../drawer-menu.types';

export const useDeleteBuildsDialog = () => {
    const { currentWorkspace } = useSelector(workspaceSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);
    const schrodingerBuilds = useSelector(schrodingerBuildsSelector);
    const bulkBuilds = useSelector(bulkBuildsSelector);
    const taps = useSelector(tapsSchrodingerSelector);

    const {
        isDeleteDialogOpen,
        selectedBuildIds,
        singleBuildIdToDelete,
        setIsDeleteDialogOpen,
        setSelectedBuildIds,
        setSingleBuildIdToDelete,
    } = useContext(DrawerMenuContext);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const buildIdsToDelete = useMemo(() => singleBuildIdToDelete ? [singleBuildIdToDelete] : selectedBuildIds, [selectedBuildIds, singleBuildIdToDelete]);
    const open = !!buildIdsToDelete.length && isDeleteDialogOpen;
    const title = buildIdsToDelete.length > 1
        ? t(LocalizationKeys.DeleteBuildsGeneric, { buildCount: buildIdsToDelete.length })
        : t(LocalizationKeys.DeleteBuildGeneric, { buildId: buildIdsToDelete[0] ?? -1 });

    const handleCloseClick = useCallback((): void => {
        setIsDeleteDialogOpen(false);
        setSingleBuildIdToDelete(undefined);
    }, [setIsDeleteDialogOpen, setSingleBuildIdToDelete]);

    const handleDeleteBuildsClick = useCallback((): void => {
        handleCloseClick();

        // Flexnap
        const flexNapBuildIdsToDelete = flexNapBuilds.filter((b) => buildIdsToDelete.includes(b.id)).map(b => b.id);
        const childPretermBuilds = flexNapBuilds.filter((b) => b.pretermLateral && buildIdsToDelete.includes(b.pretermLateral.parentBuildId));

        // Schrodinger
        const schrodingerBuildIdsToDelete = schrodingerBuilds.filter((b) => buildIdsToDelete.includes(b.id)).map(b => b.id);
        const tapIds = taps.filter(({ buildId }) => buildId && buildIdsToDelete.includes(buildId)).map(({ id }) => id);

        // Bulk
        const bulkBuildIdsToDelete = bulkBuilds.filter((b) => buildIdsToDelete.includes(b.id)).map(b => b.id);
        const childBulkBuildIds = [...flexNapBuilds, ...bulkBuilds]
            .filter(b => b.buildSplice?.parentBuildId && buildIdsToDelete.includes(b.buildSplice?.parentBuildId))
            .map(b => b.id);

        dispatch(deleteFlexNapBuilds(flexNapBuildIdsToDelete, childPretermBuilds, true));
        dispatch(deleteSchrodingerBuilds(schrodingerBuildIdsToDelete, tapIds, currentWorkspace?.id));
        dispatch(deleteBulkBuilds(bulkBuildIdsToDelete, childBulkBuildIds));

        setSelectedBuildIds([]);
        setSingleBuildIdToDelete(undefined);
    }, [handleCloseClick, flexNapBuilds, schrodingerBuilds, bulkBuilds, taps, dispatch, currentWorkspace, setSelectedBuildIds, setSingleBuildIdToDelete, buildIdsToDelete]);

    return {
        currentWorkspace,
        open,
        title,
        handleDeleteBuildsClick,
        handleCloseClick,
        t,
    };
};
