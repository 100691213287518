import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ToolType } from '../../../design-tools/tooltype';
import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import CorningRoles from '../../../redux/corning-roles.json';
import { StateModel } from '../../../redux/reducers';
import { setBuildType, setSelectedTool } from '../../../redux/tool.state';
import { GenericSnackbarAction } from '../../ui-elements/generic-snackbar/generic-snackbar.types';

const isOpen = (tool: ToolType): boolean => {
    return tool === ToolType.FlexNAPCable || tool === ToolType.SchrodingerCable || tool === ToolType.BulkCable;
};

export const useCableSelectionSnackbar = () => {
    const state = useSelector((state: StateModel) => state);
    const { buildType, selectedTool } = state.tool;
    const { roles } = state.role;
    const { t } = useTranslation();
    const [open, setOpen] = useState(isOpen(selectedTool));
    const [actions, setActions] = useState([] as GenericSnackbarAction[]);

    const dispatch = useDispatch();

    const selectBuildType = useCallback((e: React.MouseEvent<any>): void => {
        const value = e?.currentTarget?.value ? +e.currentTarget.value : undefined;
        if (value) {
            dispatch(setBuildType(value))
            switch (value) {
                case BuildType.FlexNap as number:
                    dispatch(setSelectedTool(ToolType.FlexNAPCable));
                    break;
                case BuildType.Schrodinger as number:
                    dispatch(setSelectedTool(ToolType.SchrodingerCable));
                    break;
                case BuildType.Bulk as number:
                    dispatch(setSelectedTool(ToolType.BulkCable));
                    break;
            }
        }
    }, [dispatch]);

    useEffect(() => {
        setOpen(isOpen(selectedTool));
    }, [selectedTool]);

    useEffect(() => {
        const roleActions: GenericSnackbarAction[] = [];

        if (roles && (roles.some((role) => role === CorningRoles.FlexNAP || role === CorningRoles.FlexNAPAdmin))) {
            roleActions.push({ label: t(LocalizationKeys.FlexNap), value: BuildType.FlexNap, onProperty: buildType, onAction: selectBuildType });
        }
        if (roles && (roles.some((role) => role === CorningRoles.Schrodinger))) {
            roleActions.push({ label: t(LocalizationKeys.Schrodinger), value: BuildType.Schrodinger, onProperty: buildType, onAction: selectBuildType });
        }
        roleActions.push({ label: t(LocalizationKeys.Bulk), value: BuildType.Bulk, onProperty: buildType, onAction: selectBuildType });

        setActions(roleActions);
    }, [roles, buildType, selectBuildType, t]);

    return { open, actions };
};