import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Mark } from '@mui/base';

import { LocalizationKeys } from '../../locales/types';
import { Tether } from '../../models/tether';
import { Units } from '../../models/units';
import { connect } from '../../redux/reducers';
import { patchTether } from '../../redux/tap.state';
import { TetherService } from '../../services/tether.service';
import { CardPropertyInline, CardSlider } from '../card/card-controls';
import { InlineSelect } from '../ui-elements/inline-select';

interface TetherComponentProps {
    tether: Tether;
    disabled: boolean;
    viewOnlyMode?: boolean;
}

interface TetherComponentState {
    fiberCount: number;
    fiberCounts: number[];
    fiberCountsMarks: Mark[];
    tetherLegLengths: number[];
}
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = {
    patchTether
};

type props = TetherComponentProps & Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>> & WithTranslation;
@(connect(mapStateToProps, mapDispatchToProps) as any)
class TetherComponentClass extends React.Component<props, TetherComponentState> {
    private static readonly id = 'tether';
    public state: TetherComponentState = {
        fiberCount: -1,
        fiberCounts: [],
        fiberCountsMarks: [],
        tetherLegLengths: [],
    };

    public async componentDidMount() {
        const { tether } = this.props;
        await this.updateTetherConfiguration(tether);
    }

    public async componentDidUpdate(prevProps: props, prevState: TetherComponentState) {
        const { tether } = this.props;
        const prevId = prevProps?.tether?.id || -1;
        if (prevId !== tether.id) {
            this.setState({ fiberCount: -1 });
            await this.updateTetherConfiguration(tether);
        }
        const prevTetherFiberCount = prevState?.fiberCounts;
        if (prevTetherFiberCount !== this.state.fiberCounts) {
            this.setState({ fiberCountsMarks: this.generateFiberCountMarks() });
        }
    }

    private updateTetherConfiguration = async (tether: Tether) => {
        const tetherService = new TetherService();
        const tetherConfig = await tetherService.getTetherConfiguration(tether);
        if (tetherConfig) {
            this.setState({
                fiberCounts: tetherConfig.fiberCounts,
                tetherLegLengths: tether.legLengthUnit === Units.meters ? tetherConfig.legLengths.m : tetherConfig.legLengths.ft
            });
        }
    }

    private handleLegLengthInputChange = (value: any) => {
        const name = 'legLength';
        if (value) {
            const { tether } = this.props;
            this.props.patchTether!(tether, { ...tether, [name]: value });
        }
    }

    private generateFiberCountMarks = () => {
        const { fiberCounts } = this.state;
        const fiberCountMarks: Mark[] = [];
        let interval = 0;
        if (fiberCounts) {
            let offset = 0;
            if (fiberCounts.length > 0) {
                offset = fiberCounts[0];
            }
            if (fiberCounts.length >= 2) {
                interval = fiberCounts[1] - fiberCounts[0];
            } else {
                interval = fiberCounts[0];
            }
            fiberCounts.forEach((fiberValue, index) => {
                fiberCountMarks.push({ value: offset + index * interval, label: `${fiberValue}` });
            });
        }
        return fiberCountMarks;
    }

    private handleSliderChange = (_: Event, value: number | number[]) => {
        if (typeof value === 'number') {
            const fiberCount = value === 3 ? 4 : value < 10 ? value : 12;
            this.setState({ fiberCount });
        }
    }

    private commitChanges = () => {
        const { fiberCount } = this.state;
        const { tether } = this.props;
        this.props.patchTether!(tether, { ...tether, fiberCount });
    }

    private valueLabelFormat = (value: number, label: number): React.ReactNode => {
        return value === 3 ? '4' : value < 10 ? `${value}` : '12';
    }

    public render() {
        const { tether, disabled, viewOnlyMode } = this.props;
        let fiberCount = this.state.fiberCount;
        const legLengths = this.state.tetherLegLengths;
        const fiberCounts = this.state.fiberCountsMarks;
        if (fiberCount === -1) {
            fiberCount = tether.fiberCount;
        }
        const lengthLabel = `${this.props.t(LocalizationKeys.Length)} (${tether.legLengthUnit})`;
        const sliderValue = fiberCounts.map((f) => f.value);
        const step = Math.floor((Math.max(...sliderValue) - Math.min(...sliderValue)) / (sliderValue.length - 1));
        return (
            <div id={TetherComponentClass.id}>
                {CardPropertyInline(this.props.t(LocalizationKeys.PartNumber), tether.partNumber)}
                {
                    viewOnlyMode ?
                        [
                            CardPropertyInline(lengthLabel, tether.legLength),
                            CardPropertyInline(this.props.t(LocalizationKeys.Fibers), tether.fiberCount)
                        ]
                        :
                        [
                            (<InlineSelect
                                key={'legLength'}
                                label={lengthLabel}
                                value={tether.legLength}
                                values={legLengths}
                                onUpdate={this.handleLegLengthInputChange}
                                disabled={disabled}
                            />),
                            CardSlider(this.props.t(LocalizationKeys.Fibers), 'fiberCount', Math.min(...sliderValue), Math.max(...sliderValue), fiberCounts, fiberCount, step, this.handleSliderChange, this.commitChanges, this.valueLabelFormat, disabled)
                        ]
                }
            </div>
        );
    }
}

export const TetherComponent = withTranslation()(TetherComponentClass);