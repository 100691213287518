/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';
import { TerminalDiagramProps } from '../../nap-diagram.types';

export const useTerminalDiagramSvg = (props: TerminalDiagramProps) => {
    const { tapIndex, tetherIndex, tether } = props;

    const [terminal, setTerminal] = useState(tether?.terminal);
    const [terminalIndex, setTerminalIndex] = useState(tapIndex === 1 ? tetherIndex : tetherIndex + 2);

    const [portCount, setPortCount] = useState(terminal?.portCount ?? 0);

    const { terminalHeight, terminalWidth, legTranslation, connectorPadding, x, y, height, connectorObjs, className } = useTerminalSvg(tapIndex, portCount, terminalIndex);

    useEffect(() => {
        setTerminal(tether?.terminal);
    }, [tether]);

    useEffect(() => {
        setTerminalIndex(tapIndex === 1 ? tetherIndex : tetherIndex + 2);
    }, [tapIndex, tetherIndex]);

    useEffect(() => {
        setPortCount(terminal?.portCount ?? 0);
    }, [terminal]);

    return {
        terminal, tapIndex, tetherIndex, terminalIndex, portCount,
        terminalHeight, terminalWidth, legTranslation, connectorPadding, x, y, height, connectorObjs, className
    };
};

const useTerminalSvg = (tapIndex: number, portCount: number, terminalIndex: number) => {
    const [isLeftTap] = useState(tapIndex === 1);
    const [isWide, setIsWide] = useState(portCount >= 12);
    const [isTall, setIsTall] = useState(portCount >= 6);
    const [terminalWidth, setTerminalWidth] = useState(isWide ? 56 : 48);
    const [legTranslation, setLegTranslation] = useState(terminalWidth / 2 - 6);
    const [terminalHeight, setTerminalHeight] = useState(isTall ? 96 : 72);
    const [connectorPadding, setConnectorPadding] = useState(isWide ? 12.5 : 16);
    const [terminalSpacing] = useState(80);
    const [marginLeft, setMarginLeft] = useState(24 + (isLeftTap ? 0 : 14));
    const [x, setX] = useState(marginLeft + ((terminalIndex - 1) * terminalSpacing) + (isWide ? 0 : 4));
    const [y] = useState(174);
    const [height] = useState(40);
    const [className] = useState('terminal');
    const [connectorObjs, setConnectorObjs] = useState<any[]>([]);

    useEffect(() => {
        setIsWide(portCount >= 12);
        setIsTall(portCount >= 6);
    }, [portCount]);

    useEffect(() => {
        const terminalWidth = isWide ? 56 : 48;
        setTerminalWidth(terminalWidth);
        setLegTranslation(terminalWidth / 2 - 6);
        setConnectorPadding(isWide ? 12.5 : 16);
    }, [isWide]);

    useEffect(() => {
        setTerminalHeight(isTall ? 96 : 72);
    }, [isTall]);

    useEffect(() => {
        setMarginLeft(24 + (isLeftTap ? 0 : 14));
    }, [isLeftTap]);

    useEffect(() => {
        setX(marginLeft + ((terminalIndex - 1) * terminalSpacing) + (isWide ? 0 : 4));
    }, [marginLeft, terminalSpacing, isWide, terminalIndex]);

    useEffect(() => {
        const cObjs: any[] = [];
        const connectorSpacing = 16;
        const cols = isWide ? 3 : 2;
        for (let i = 0; i < portCount; i++) {
            const xx = i % cols;
            const yy = Math.floor(i / (cols));
            cObjs.push({
                key: i,
                x: (connectorSpacing * xx),
                y: (connectorSpacing * yy),
            });
        }
        setConnectorObjs(cObjs);
    }, [isWide, portCount]);

    return { terminalWidth, terminalHeight, legTranslation, connectorPadding, x, y, height, className, connectorObjs };
};