import React from 'react';
import { GenericSnackbar } from '../../ui-elements/generic-snackbar/generic-snackbar';
import { useRemoveGisDataSnackbar } from './remove-gis-data-snackbar.hooks';

export const RemoveGisDataSnackbar = (): JSX.Element => {
    const { snackbar, onClose } = useRemoveGisDataSnackbar();

    return (
        <GenericSnackbar
            open={!!snackbar}
            type={snackbar?.type}
            message={snackbar?.message}
            dismissIcon={true}
            timeout={-1}
            showProgress={snackbar?.showProgress}
            onClose={onClose}
        />
    );
};
