import React from 'react';

import { Container, Graphics, Sprite } from '@inlet/react-pixi';

import { IIndexedFrameProps } from '../../build/build.types';
import { useToolbarItem } from './toolbar-item.hooks';
import { ToolbarItemProps } from './toolbar-item.types';
import { Tooltip } from './tooltip/tooltip';

export const ToolbarItem: React.FC<IIndexedFrameProps & ToolbarItemProps> = (props) => {
    const {
        xPosition, yPosition,
        iconTexture, hitArea, isHovered,
        drawHoverShade, handleMouseOver, handleMouseOut, handleClick
    } = useToolbarItem(props);

    const { disabled, toolTip } = props;

    return <Container
        interactive={true}
        hitArea={hitArea}
        cursor={!disabled ? 'pointer' : ''}
        click={!disabled ? handleClick : undefined}
        mouseover={handleMouseOver}
        mouseout={handleMouseOut}
    >

        {isHovered && !disabled &&
            <>
                <Graphics draw={drawHoverShade} />
                {toolTip && <Tooltip position={{ x: xPosition, y: yPosition }} tooltipText={toolTip} />}
            </>
        }
        <Sprite
            tint={disabled ? 0x000000 : 0xFFFFFF}
            texture={iconTexture}
            x={xPosition}
            y={yPosition}
            anchor={{ x: 0.5, y: 0.5 }}
            alpha={disabled ? 0.26 : 1}
        />

    </Container>;
};