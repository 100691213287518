import classNames from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BuilderHelper } from '../../../../../../helpers/build-helper';
import { LocalizationKeys } from '../../../../../../locales/types';
import { PretermLateral } from '../../../../../../models/preterm-lateral';
import { StateModel } from '../../../../../../redux/reducers';
import { buildNapLastUpdatedsSelector } from '../../../../../../selectors/root.selectors';
import { DrawerMenuContext } from '../../../drawer-menu.types';
import { BuildRowProps } from './build-row.types';

export const useBuildRow = (props: BuildRowProps) => {
    const { t } = useTranslation();
    const { build } = props;
    const { selectedBuildIds } = useContext(DrawerMenuContext);
    const buildNapLastUpdateds = useSelector(buildNapLastUpdatedsSelector);

    const [workspaceId, setWorkspaceId] = useState(-1);
    const [lastUpdated, setLastUpdated] = useState<string>();
    const [isPreterm, setIsPreterm] = useState(false);

    const { onRowEnter, onRowLeave, showTools } = useRowEvents(build.id, selectedBuildIds);
    const preterm = useBuildFlexNapPreterm(build.id);
    const rowClassName = classNames('build-row', {
        'locked': build.lockedById,
        'exported': build.uploadedDateTime,
        'selected': selectedBuildIds.includes(build.id),
    });

    const displayId = isPreterm
        ? t(LocalizationKeys.PretermLateralNumber, { buildId: build.id })
        : BuilderHelper.getBuildDisplayId(build);

    useEffect(() => {
        if (build) {
            const buildNapLastUpdated = buildNapLastUpdateds.find((e) => e.buildId === build.id);
            const lastUpdated = buildNapLastUpdated && buildNapLastUpdated.dateTime > build.lastUpdated
                ? buildNapLastUpdated.dateTime
                : build.lastUpdated;
            setWorkspaceId(build.workspaceId);
            setLastUpdated(t(LocalizationKeys.LastUpdate, {
                dateTime: new Date(lastUpdated).toLocaleString(),
                interpolation: { escapeValue: false },
            }));
            setIsPreterm(!!preterm);
        }
    }, [build, t, preterm, buildNapLastUpdateds]);

    return { rowClassName, onRowEnter, onRowLeave, displayId, workspaceId, lastUpdated, showTools, build };
}

const useRowEvents = (buildId: number, selectedBuildIds: number[]) => {
    const [showTools, setShowTools] = useState(false);

    useEffect(() => {
        if (!showTools) {
            setShowTools(selectedBuildIds.includes(buildId));
        }
    }, [showTools, selectedBuildIds, selectedBuildIds.length, buildId]);

    const onRowEnter = useCallback((hoveredBuildId: number) => {
        if (hoveredBuildId === buildId) {
            setShowTools(true);
        }
    }, [buildId]);

    const onRowLeave = useCallback(() => {
        if (!selectedBuildIds.includes(buildId)) {
            setShowTools(false);
        }
    }, [selectedBuildIds, buildId]);

    return { onRowEnter, onRowLeave, showTools };
}

const useBuildFlexNapPreterm = (buildId: number): PretermLateral | undefined => {
    return useSelector((state: StateModel) => {
        const build = state.build.flexnapBuilds.find(b => b.id === buildId);
        return build?.pretermLateral;
    });
};