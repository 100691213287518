import React from 'react';

export const DesignAreaIcon = (): JSX.Element => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path fill="#005293" d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z"/>
        </svg>
    );
};
