import 'react-base-table/styles.css';

import i18next from 'i18next';
import React from 'react';
import BaseTable, { Column } from 'react-base-table';

import { LocalizationKeys } from '../../../../locales/types';
import { FiberLocation } from '../../../../models/bulk/splice-plan';
import { getColorLabel } from '../../../../models/color-type';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { PipeColor } from '../pipe-color';
import { TubeColor } from '../tube-color';
import { useSplicePlanTable } from './splice-plan-table.hooks';
import { SplicePlanTableProps } from './splice-plan-table.types';

export const SplicePlanTable = (props: SplicePlanTableProps): JSX.Element | null => {
    const {
        rowHeight,
        tableHeight,
        tableData,
        frozenData,
        handleScroll,
        rowClassName,
        getTubeStripeColor,
        tableRef,
    } = useSplicePlanTable(props);

    return (
        <div className="splice-plan-dialog-table">
            <BaseTable
                ref={tableRef}
                fixed
                rowKey="key"
                width={848}
                height={tableHeight}
                data={tableData}
                frozenData={frozenData}
                rowClassName={rowClassName}
                rowHeight={rowHeight}
                headerHeight={rowHeight}
                onScroll={handleScroll}
                emptyRenderer={<DnaSpinner />}
            >
                <Column
                    key="location"
                    dataKey="locationLabel"
                    className="splice-plan-dialog-table-cell-location"
                    width={150}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingLocation)}
                />
                <Column
                    key="splice-point"
                    dataKey="splicePointIndex"
                    width={120}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingSplicePoint)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="subunit-color"
                    dataKey="subunitColor"
                    width={200}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingTubeColor)}
                    cellRenderer={({ cellData, rowData }) => (
                        <div className="splice-plan-dialog-table-cell-column">
                            {!(rowData as FiberLocation).isCategory && ((rowData as FiberLocation).subunitName.includes("Ribbon")
                                ? <PipeColor color={cellData} />
                                : <TubeColor color={cellData} colorName={(rowData as FiberLocation).subunitName} stripeColor={getTubeStripeColor({ rowData: (rowData as FiberLocation) })} />)}
                            <span>
                                {(rowData as FiberLocation).subunitName.indexOf("Dash") < 0
                                    ? (rowData as FiberLocation).subunitName
                                    : getColorLabel(cellData) + "/" + getTubeStripeColor({ rowData: (rowData as FiberLocation) }) + " Stripe"
                                }
                            </span>
                        </div>
                    )}
                />
                <Column
                    key="fiber-color"
                    dataKey="fiberColor"
                    width={120}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberColor)}
                    cellRenderer={({ cellData, rowData }) => (
                        <>{!(rowData as FiberLocation).isCategory && (
                            <div className="splice-plan-dialog-table-cell-column">
                                <PipeColor color={cellData} />
                                <span>{getColorLabel(cellData)}</span>
                            </div>)}
                        </>
                    )}
                />
                <Column
                    key="fiber-id"
                    dataKey="fiberId"
                    width={144}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberId)}
                    cellRenderer={({ cellData, rowData }) => (
                        <>{!(rowData as FiberLocation).isCategory && cellData}</>
                    )}
                />
                <Column
                    key="port"
                    dataKey="portNumber"
                    width={75}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingPort)}
                    align={Column.Alignment.RIGHT}
                    cellRenderer={({ cellData, rowData }) => (
                        <>{!(rowData as FiberLocation).isCategory && (cellData ?? i18next.t(LocalizationKeys.NotApplicable))}</>
                    )}
                />
            </BaseTable>
        </div>
    )
};
