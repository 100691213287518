import './splice-plan-dialog.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { DialogComponent } from '../dialog.component';
import {
    SplicePlanDialogContent as BulkSplicePlanDialogContent
} from './bulk/splice-plan-dialog-content';
import {
    SplicePlanDialogContent as SchrodingerSplicePlanDialogContent
} from './schrodinger/splice-plan-dialog-content';
import { SplicePlanDialogContent } from './splice-plan-dialog-content';
import { useSplicePlanDialog } from './splice-plan-dialog-hooks';

export const SplicePlanDialog = (): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        isDialogOpen,
        onClose,
        openSetup,
        buildId,
        buildType,
        titleLabel,
        setupLabel,
        canModify,
        currentLocation,
        setCurrentLocation,
    } = useSplicePlanDialog();
    if (!isDialogOpen) {
        return null;
    }

    return (
        <DialogComponent open={isDialogOpen} className="dialog splice-plan-dialog" onClose={onClose} title={titleLabel} muiDialogProps={{ maxWidth: "lg" }}>
            <div className="splice-plan-dialog-setup">
                {setupLabel}
                {canModify &&
                    <button className="splice-plan-dialog-setup-open" onClick={openSetup}>
                        {t(LocalizationKeys.Modify)}
                    </button>
                }
            </div>
            <div className="splice-plan-dialog-content">
                {buildType === BuildType.FlexNap
                    ? <SplicePlanDialogContent isDialogOpen={isDialogOpen} buildId={buildId} currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
                    : buildType === BuildType.Schrodinger
                        ? <SchrodingerSplicePlanDialogContent isDialogOpen={isDialogOpen} buildId={buildId} currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
                        : <BulkSplicePlanDialogContent isDialogOpen={isDialogOpen} buildId={buildId} currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
                }
            </div>
        </DialogComponent>
    )
}
