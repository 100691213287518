import { transform } from 'ol/proj';

export class ProjectionConverter {

    static latlong = 'EPSG:4326'; // WGS84
    static utm = 'EPSG:3857'; // WebMercator
    static map = ProjectionConverter.utm;

    public static convertFromTo(coordinates: number[], from: string, to: string): number[] {
        const res: number[] = [];
        coordinates = [...coordinates]; // copy
        while(coordinates.length >= 2) {
            const x = coordinates.shift();
            const y = coordinates.shift();
            if(x === undefined || y === undefined) {
                throw new Error('ProjectionConverter.convertFromTo expects an even array, got ' + coordinates.length + ' elements');
            }
            res.push(...transform([x,y], from, to));
        }
        
        return res;
    }

    public static convertToMapProjection(coordinates: number[], from: string): number[] {
        return this.convertFromTo(coordinates, from, this.map);
    }

    public static convertLatLongToMap(coordinates: number[]): number[] {
        return this.convertFromTo(coordinates.reverse(), this.latlong, this.map);
    }

    public static convertMapToLatLong(coordinates: number[]): number[] {
        return this.convertFromTo(coordinates, this.map, this.latlong);
    }
}