import { MapClickEvent } from '../models/map-click-event';
import { CabinetTool } from './cabinet-tool';
import { DropCableTool } from './drop-cable-tool';
import { HandholeTool } from './handhole-tool';
import { ManholeTool } from './manhole-tool';
import { NAPTool } from './nap-tool';
import { SplicePointTool } from './splice-point-tool';
import { PoleTool } from './pole-tool';
import { TapTool } from './schrodinger/tap-tool';
import { SelectionTool } from './selection-tool';
import { ToolType } from './tooltype';
import { VaultTool } from './vault-tool';

export class DesignToolsExecuter {

    public static execute(toolType: ToolType, event: MapClickEvent): void {
        switch (toolType) {
            case ToolType.Selection:
                SelectionTool.execute(event);
                break;
            case ToolType.Cabinet:
                CabinetTool.execute(event);
                break;
            case ToolType.DropCable:
                DropCableTool.execute(event);
                break;
            case ToolType.NAP:
                NAPTool.execute(event);
                break;
            case ToolType.SplicePoint:
                SplicePointTool.execute(event);
                break;
            case ToolType.Manhole:
                ManholeTool.execute(event);
                break;
            case ToolType.Handhole:
                HandholeTool.execute(event);
                break;
            case ToolType.Vault:
                VaultTool.execute(event);
                break;
            case ToolType.Pole:
                PoleTool.execute(event);
                break;
            case ToolType.TapSchrodinger:
                TapTool.execute(event);
                break;
        }
    }
}
