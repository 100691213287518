import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class TotalFiberCountValidator implements Validator<FlexNapBuildData> {

    public validate(build: FlexNapBuildData): ValidationResult | null {
        const tethers = build.naps.flatMap((n) => n.taps).flatMap((t) => t.tethers);
        const fiberCount = tethers.map((t) => t.fiberCount).reduce((prv, t) => prv + t, 0);
        if (fiberCount > build.fiberCount) {
            return this.buildValidationResult(build, ValidationResultType.Error);
        }
        return null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof FlexNapBuildData;
    }

    private buildValidationResult(build: FlexNapBuildData, type: ValidationResultType): ValidationResult {
        const extent = build.getExtent();
        return {
            buildId: build.id,
            itemId: null,
            itemType: ItemType.Build,
            shortDescription: i18next.t(LocalizationKeys.ErrorFiberCountInsufficient),
            longDescription: i18next.t(LocalizationKeys.ErrorFiberCountInsufficientLong),
            type,
            extent
        };
    }
}
