import './card.component.scss';

import classNames from 'classnames';
import React from 'react';

interface CardContentComponentProps {
    id?: string;
    className?: string;
}

export class CardContentComponent extends React.Component<CardContentComponentProps> {

    public render(): JSX.Element {
        // eslint-disable-next-line react/prop-types
        const { id, children, className } = this.props;
        const cs = classNames('card-content', className);
        return (
            <div id={id} className={cs}>
                {children}
            </div>
        );
    }
}
