import React from 'react';
import { useTranslation } from 'react-i18next';

import { UnitInterpreter } from '../../../../../helpers/unit-interpreter';
import { LocalizationKeys } from '../../../../../locales/types';
import { CardInput } from '../../../../card/card-controls';
import { DialogComponent } from '../../../dialog.component';
import { useAddCanvasSegmentDialog } from './add-segment-dialog.hooks';

export const AddCanvasSegmentDialog = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        isOpen,
        handleCloseClick, handleAddClick,
        designSpan, handleDesignSpanUpdate,
        unit,
        addDisabled
    } = useAddCanvasSegmentDialog();

    return (
        !isOpen ? null :
        <DialogComponent
            open={isOpen}
            onClose={handleCloseClick}
            negativeTitle={t(LocalizationKeys.Cancel)}
            positiveTitle={t(LocalizationKeys.Add)}
            onNegativeClick={handleCloseClick}
            onPositiveClick={handleAddClick}
            disablePositive={addDisabled}
            title={t(LocalizationKeys.AddNewLocation)}
        >
            {
                CardInput({
                    name: "newSegmentDesignSpan",
                    label: t(LocalizationKeys.DesignSpan),
                    type: "number",
                    value: designSpan,
                    units: unit ? UnitInterpreter.toShort(unit) : '',
                    onChange: handleDesignSpanUpdate
                })
            }
        </DialogComponent>
    );
};