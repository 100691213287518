import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useConvertBulkBuildDialog } from './convert-bulk-build-dialog.hooks';

export const ConvertBulkBuildDialog = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        isOpen,
        onClose, onConvertBuild,
        title,
        message,
    } = useConvertBulkBuildDialog();

    return (
        !isOpen ? null :
            <DialogComponent
                open={isOpen}
                title={title}
                message={message}
                positiveTitle={t(LocalizationKeys.Convert)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={onConvertBuild}
                onNegativeClick={onClose}
                onClose={onClose}
            />
    );
};