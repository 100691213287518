import { SecuredService } from './abstract-secured-v2.service';

export class DnaHubService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/dnahub';

    public addToGroup(connectionId: string): Promise<void> {
        return this.post(`${this.baseUrl}/group/add`, { connectionId });
    }

    public removeFromGroup(connectionId: string): Promise<void> {
        return this.post(`${this.baseUrl}/group/remove`, { connectionId });
    }
}