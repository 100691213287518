import * as rfc from 'rfc6902';

import { Parcel, ParcelData } from '../models/parcel';
import { PointLike } from '../models/pointlike';
import { Pole, PoleData } from '../models/pole';
import { PoleSpan, PoleSpanData } from '../models/polespan';
import { SecuredService } from './abstract-secured-v2.service';

export class GeoserverService extends SecuredService {
    public readonly baseUrl = this.rootUrl + 'api/geo';

    get geoserverUrl(): string {
        return this.baseUrl + '/geoserver';
    }

    public getImageWms(): Promise<Blob> {
        return this.downloadBlob(this.geoserverUrl);
    }

    public getGeoProjects(): Promise<GeoProject[] | undefined> {
        return this.get<GeoProject[]>(`${this.baseUrl}/project`);
    }

    public getPoles(): Promise<Pole[] | undefined> {
        return this.get<Pole[]>(`${this.baseUrl}/pole`);
    }

    public getPolesIn(bbox: number[], required?: number[]): Promise<Pole[] | undefined> {
        return this.post<Pole[]>(`${this.baseUrl}/pole/bbox`, { bbox, required });
    }

    public getPolesByIds(ids: number[]): Promise<Pole[] | undefined> {
        return this.post<Pole[]>(`${this.baseUrl}/pole/byids`, ids);
    }

    public addPole(point: PointLike): Promise<Pole | undefined> {
        return this.post(`${this.baseUrl}/pole`, point);
    }

    public importPoles(poles: PoleData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/pole/import`, { data: poles, importedGisDataId });
    }

    public patchPole(oldPole: Pole, newPole: Pole): Promise<Pole | undefined> {
        const patch = rfc.createPatch(oldPole, newPole);
        return this.patch(`${this.baseUrl}/pole/${oldPole.id}`, patch);
    }

    public getParcels(): Promise<Parcel[] | undefined> {
        return this.get(`${this.baseUrl}/parcel`);
    }

    public getParcelsIn(bbox: number[]): Promise<Parcel[] | undefined> {
        return this.post<Parcel[]>(`${this.baseUrl}/parcel/bbox`, { bbox });
    }

    public getParcelsInDesignArea(polygon: PointLike[]): Promise<number | undefined> {
        return this.post<number>(`${this.baseUrl}/parcel/designarea`, { polygon });
    }

    public importParcels(parcels: ParcelData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/parcel/import`, { data: parcels, importedGisDataId });
    }

    public patchParcel(oldParcel: Parcel, newParcel: Parcel): Promise<Parcel | undefined> {
        const patch = rfc.createPatch(oldParcel, newParcel);
        return this.patch(`${this.baseUrl}/parcel/${oldParcel.id}`, patch);
    }

    public getPoleSpans(): Promise<PoleSpan[] | undefined> {
        return this.get(`${this.baseUrl}/pole/span`);
    }

    public getPoleSpansIn(bbox: number[], required?: number[]): Promise<PoleSpan[] | undefined> {
        return this.post<PoleSpan[]>(`${this.baseUrl}/pole/span/bbox`, { bbox, required });
    }

    public addPoleSpans(request: PoleSpanRequest): Promise<PoleSpanResult | undefined> {
        return this.post(`${this.baseUrl}/pole/span`, request);
    }
}

export interface PoleSpanResult {
    poles: Pole[];
    poleSpans: PoleSpan[];
}

export interface PoleSpanRequest {
    poles: PoleData[];
    poleSpans: PoleSpanData[];
    importedGisDataId?: number;
}

export interface GeoProject {
    id: string;
    name: string;
    coordinates: number[][];
    description?: string;
}
