import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import { Cabinet, CabinetData } from '../models/cabinet';
import { CabinetSplicePlanService } from '../services/cabinet-splice-plan.service';
import { CabinetService } from '../services/cabinet.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { PortMode } from './cabinet-splice-plan.state';
import { updateSchrodingerBuildLocationIds } from './element.actions';
import { push } from './history.state';
import { IMPORT_LOAD_THRESHOLD } from './import.state';
import { updateSelectedElement } from './selection.state';

export interface CabinetState {
    cabinets: Cabinet[];
}

const initialState: CabinetState = {
    cabinets: [],
};

const loadCabinetsReducer = (state: CabinetState, action: PayloadAction<Cabinet[]>): void => { state.cabinets = action.payload || []; };
const addCabinetReducer = (state: CabinetState, action: PayloadAction<Cabinet>): void => {
    const cabinet = action.payload;
    if (cabinet.id < 0) {
        throw new Error('Add cabinet with no persisted id');
    }
    if (state.cabinets.findIndex((c) => c.id === cabinet.id) >= 0) {
        throw new Error('Add cabinet with duplicate id');
    }
    state.cabinets.push(cabinet);
};

const addCabinetsReducer = (state: CabinetState, action: PayloadAction<Cabinet[]>): void => { state.cabinets.push(...action.payload); };

const updateCabinetReducer = (state: CabinetState, action: PayloadAction<Cabinet>): void => {
    const cabinet: Cabinet = action.payload;
    const cIdx = state.cabinets.findIndex((c) => c.id === cabinet.id);
    if (cIdx < 0) {
        state.cabinets.push(cabinet);
    }
    else {
        state.cabinets[cIdx] = cabinet;
    }
};

const deleteCabinetReducer = (state: CabinetState, action: PayloadAction<number>) => {
    state.cabinets = state.cabinets.filter((c) => c.id !== action.payload);
};

const deleteImportedCabinetsReducer = (state: CabinetState, action: PayloadAction<number>) => {
    state.cabinets = state.cabinets.filter((c) => c.importedGisDataId !== action.payload);
};

const { actions, reducer } = createSlice({
    name: 'cabinet',
    initialState,
    reducers: {
        loadCabinets: loadCabinetsReducer,
        addCabinet: addCabinetReducer,
        addCabinets: addCabinetsReducer,
        updateCabinet: updateCabinetReducer,
        deleteCabinet: deleteCabinetReducer,
        deleteImportedCabinets: deleteImportedCabinetsReducer,
    },
});

export { reducer as CabinetReducer };
export const { loadCabinets, addCabinet: addCabinetAction, deleteCabinet, deleteImportedCabinets, updateCabinet: modifyCabinet } = actions;

export const getCabinets = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new CabinetService();
    const cabinets = await service.getCabinets(bbox, required);
    if (cabinets) {
        dispatch(actions.loadCabinets(cabinets));
    }
};

export const getCabinetsByIds = (ids: number[]) => async dispatch => {
    const service = new CabinetService();
    const cabinets = await service.getCabinetsByIds(ids);
    if (cabinets) {
        cabinets.forEach(cabinet => dispatch(actions.updateCabinet(cabinet)));
    }
};

export const addCabinet = (x: number, y: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CabinetService();
        const cabinet = await service.addCabinet(x, y);
        if (cabinet) {
            dispatch(actions.addCabinet(cabinet));
            dispatch(push({ type: CommandType.CreateElement, payload: { id: cabinet.id, type: cabinet.type } }));
        }
    });
};

export const addCabinets = (data: CabinetData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CabinetService();
        const success = await requestIsSuccess(service, service.addCabinets(data, importedGisId), true);
        if (data.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
            getCabinets(bbox)(dispatch);
        }
    });
};

export const updateCabinet = (oldCabinet: Cabinet, newCabinet: Cabinet) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CabinetService();
        const cabinet = await service.patchCabinet(oldCabinet, newCabinet);
        if (cabinet) {
            dispatch(actions.updateCabinet(cabinet));
            dispatch(updateSelectedElement(cabinet));
            if (oldCabinet.tagOverride !== cabinet.tagOverride) {
                updateSchrodingerBuildLocationIds(cabinet);
            }
        }
    });
};

export const updateCabinetSplicePlanMode = (workspaceId: number, cabinetId: number, mode: PortMode) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new CabinetSplicePlanService();
        const cabinet = await service.updateCabinetSplicePlanMode(workspaceId, cabinetId, mode);
        if (cabinet) {
            dispatch(actions.updateCabinet(cabinet));
        }
    });
}
