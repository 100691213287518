import './build-toolbar.scss';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewStatusLocalization } from '../../../../../../locales/types';
import { BuildType } from '../../../../../../models/build-type';
import { ReviewStatus } from '../../../../../../models/review-status';
import { SchrodingerBuild } from '../../../../../../models/schrodinger-build';
import { BuildToolbarStatusProps } from './build-toolbar.types';

export const BuildStatus = (props: BuildToolbarStatusProps) => {
    const { t } = useTranslation();
    const { build } = props;

    if (build.type === BuildType.Schrodinger) {
        const schrodingerBuild = build as SchrodingerBuild;
        const lastReviewStatus = schrodingerBuild.lastReviewStatus;
        const statusClasses = (type: 'text' | 'icon') => {
            return classNames(`status-${type}`, {
                'uploaded': !!schrodingerBuild.catalogCode,
                'in-review': lastReviewStatus === ReviewStatus.Open,
                'approved': lastReviewStatus === ReviewStatus.Approved,
                'rejected': lastReviewStatus === ReviewStatus.Rejected
            })
        };

        return (
            <div className="build-toolbar-container">
                <div className="build-toolbar">
                    {lastReviewStatus && (
                        <div className={statusClasses('text')}>
                            {t(ReviewStatusLocalization[lastReviewStatus])}
                        </div>
                    )}
                    <div className={statusClasses('icon')}>
                        <i className="material-icons">lock</i>
                    </div>
                    <div className="status-icon"></div>
                </div>
            </div>
        );
    }
    else {
        const statusClasses = classNames('status-icon', {
            'locked': build.lockedById,
            'exported': build.uploadedDateTime,
        });
        return (
            <div className="build-toolbar-container">
                <div className="build-toolbar">
                    <div className={statusClasses}>
                        <i className="material-icons">lock</i>
                    </div>
                    <div className="status-icon"></div>
                </div>
            </div>
        );
    }
}