import i18next from 'i18next';
import React from 'react';
import { LocalizationKeys } from '../../../locales/types';
import { FirstIcon } from './first-icon';
import { LastIcon } from './last-icon';
import { NextIcon } from './next-icon';
import { PreviousIcon } from './previous-icon';
import { SplicePlanNavigationProps } from './splice-plan-dialog.types';
import { SplicePlanNavigationButton } from './splice-plan-navigation-button';
import { useSplicePlanNavigation } from './splice-plan-navigation-hooks';


export const SplicePlanNavigation = (props: SplicePlanNavigationProps): JSX.Element => {
    const { currentLocation, setCurrentLocation, } = props;
    const {
        isLastLocation,
        isFirstLocation,
        width,
        isStartTruncated,
        isEndTruncated,
        displayedLocations,
        setFirstLocation,
        setLastLocation,
        setNextLocation,
        setPreviousLocation,
    } = useSplicePlanNavigation(props);

    return (
        <div className="splice-plan-dialog-navigation">
            <SplicePlanNavigationButton
                index={'first'}
                toolTipLabel={i18next.t(LocalizationKeys.First)}
                handleClick={setFirstLocation}
                disabled={isFirstLocation}
                >
                <FirstIcon />
            </SplicePlanNavigationButton>
            <SplicePlanNavigationButton
                index={'previous'}
                toolTipLabel={i18next.t(LocalizationKeys.Previous)}
                handleClick={setPreviousLocation}
                disabled={isFirstLocation}
            >
                <PreviousIcon />
            </SplicePlanNavigationButton>

            <div className="splice-plan-dialog-navigation-content" style={{ width }}>
                {isStartTruncated && (
                    <SplicePlanNavigationButton index={'previousTruncated'} disabled>
                        {i18next.t(LocalizationKeys.Ellipsis)}
                    </SplicePlanNavigationButton>
                )}
                {displayedLocations.map(({ indexLabel, arrayIndex }) => (
                    <SplicePlanNavigationButton
                        key={arrayIndex}
                        index={arrayIndex}
                        handleClick={() => setCurrentLocation(arrayIndex)}
                        isSelected={currentLocation === arrayIndex}
                        toolTipLabel={i18next.t(LocalizationKeys.SplicePlanLocationIndex, { indexLabel })}
                    >
                        {indexLabel}
                    </SplicePlanNavigationButton>
                ))}
                {isEndTruncated && (
                    <SplicePlanNavigationButton index={'endTruncated'} disabled>
                        {i18next.t(LocalizationKeys.Ellipsis)}
                    </SplicePlanNavigationButton>
                )}
            </div>

            <SplicePlanNavigationButton
                index={'next'}
                toolTipLabel={i18next.t(LocalizationKeys.Next)}
                handleClick={setNextLocation}
                disabled={isLastLocation}
            >
                <NextIcon />
            </SplicePlanNavigationButton>
            <SplicePlanNavigationButton
                index={'last'}
                toolTipLabel={i18next.t(LocalizationKeys.Last)}
                handleClick={setLastLocation}
                disabled={isLastLocation}
            >
                <LastIcon />
            </SplicePlanNavigationButton>
        </div>
    )
};
