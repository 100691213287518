import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GeoJsonFeatureConverter } from '../../../../helpers/geojson-feature-converter';
import { LocalizationKeys } from '../../../../locales/types';
import { WebServiceErrorHandlingBehavior } from '../../../../services/abstract-web-v2.service';
import { ImportService } from '../../../../services/import.service';
import { useImportInfrastructure } from '../common/import-infrastructure.hooks';
import { CustomerFormatStepsEnum, ImportDialogContentProps } from '../import.card.types';

export const useImportCustomerFormatContent = (props: ImportDialogContentProps) => {
    const { t } = useTranslation();


    const [isLoading, setIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [logOutput, setLogOutput] = useState<string[]>([]);

    const [prevStep, setPrevStep] = useState(-1);
    const [prevLeftButtonClickCount, setPrevLeftButtonClickCount] = useState(-1);

    const { importedInfraDataTotal, importedInfraDataHasDuplicates, onFilterInfraData, zoomAtInfraBbox, importInfra } = useImportInfrastructure({ setErrorMessages, setIsLoading, setLogOutput });

    const onTransformData = useCallback(async (): Promise<void> => {
        props.setIsLoadingStep(true);
        const { files } = props;
        const zipFile = files.find(file => file.type === 'application/x-zip-compressed' || file.type === 'application/zip')
        if (zipFile) {
            try {
                const service = new ImportService();
                service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
                const result = await service.transformToCorningFormatAsync(zipFile);
                const tags: string[] = [];
                if (result?.features) {
                    const convertedImportData = GeoJsonFeatureConverter.convert(result.features, tags);
                    onFilterInfraData([{ data: convertedImportData, bbox: result?.bbox }], zipFile.name);
                    props.setStepCount(CustomerFormatStepsEnum.Confirmation);
                } else {
                    props.setStepCount(CustomerFormatStepsEnum.Log);
                }
            }
            catch (err) {
                const _err: any = err;
                if (_err instanceof Error) {
                    setErrorMessages(previous => [...previous, _err.message]);
                }
                else {
                    const msg = _err.response?.data ?? "Error transforming data to Corning format.";
                    setErrorMessages(previous => [...previous, msg]);
                }
            }
            props.setDisableNext(false);
        }
        props.setIsLoadingStep(false);
    }, [onFilterInfraData, props]);

    useEffect(() => { // Go to log step directly when there are errors
        if (errorMessages.length > 0 && props.stepCount !== CustomerFormatStepsEnum.Log) {
            props.setStepCount(CustomerFormatStepsEnum.Log)
        }
    }, [errorMessages.length, props]);

    useEffect(() => {
        if (prevLeftButtonClickCount !== props.leftButtonClickCount) {
            if (props.stepCount === CustomerFormatStepsEnum.Confirmation) {
                zoomAtInfraBbox();
            }
        }
        setPrevLeftButtonClickCount(props.leftButtonClickCount);
    }, [prevLeftButtonClickCount, props.stepCount, props.leftButtonClickCount, zoomAtInfraBbox]);

    useEffect(() => {
        if (prevStep !== props.stepCount) {
            if (props.stepCount === CustomerFormatStepsEnum.Transformation) {
                onTransformData();
            }
            if (props.stepCount === CustomerFormatStepsEnum.Confirmation) {
                props.setLeftButtonTitle(LocalizationKeys.FindOnMap);
            }
            if (props.stepCount === CustomerFormatStepsEnum.Log) {
                importInfra();
                props.setLeftButtonTitle("");
                props.setPositiveButtonTitle(t(LocalizationKeys.Done));
            }
            if (props.stepCount > CustomerFormatStepsEnum.Log) {
                props.onClose();
            }
        }
        setPrevStep(props.stepCount);
    }, [importInfra, onTransformData, prevStep, props, t]);

    return { errorMessages, isLoading, importedInfraDataTotal, importedInfraDataHasDuplicates, logOutput };
}
