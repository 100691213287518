import JSZip from 'jszip';
import React from 'react';

import { TextButton } from '@orbit/button';

import { clear as clearExport } from '../../redux/export.state';
import { connect, StateModel } from '../../redux/reducers';
import { ExportService } from '../../services/export.service';

const mapStateToProps = (state: StateModel) => {
    return state.export;
};

const mapDispatchToProps = {
    clearExport,
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ExportConfirmationComponent extends React.Component<props> {

    private onOkClick = (): void => {
        if (this.props.clearExport) {
            this.props.clearExport();
        }
    }

    private onLogClick = (): void => {
        const { designAreaConfigurationId, excelFile } = this.props;
        if (!designAreaConfigurationId || !excelFile) {
            return;
        }
        const logPromise = new ExportService().getExportErrors(designAreaConfigurationId);
        logPromise.then((log: string) => {
            const zip = new JSZip();
            zip.file('export-log.txt', log);
            zip.file(excelFile.name, excelFile);
            zip.generateAsync({ type: 'blob' }).then((zipFile) => {
                const url = window.URL.createObjectURL(zipFile);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export-artifacts.zip');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
            });
        });
    }

    private determineState = (status: string) => {
        const progress = this.props.progress;
        let msg: string;
        let spanClass = '';
        let showOk = false;
        let showLog = false;
        if (status === 'RUNNING') {
            msg = 'Export in progress, ' + progress + ' %...';
        }
        else if (status === 'ERROR') {
            msg = 'Failed to export the selected builds';
            showOk = true;
            showLog = true;
            spanClass = 'exp-error';
        }
        else if (status === 'WARN') {
            msg = 'Warnings occurred while exporting the selected builds';
            showOk = true;
            showLog = true;
            spanClass = 'exp-warning';
        }
        else {
            msg = 'Successfully exported the selected builds';
            showOk = true;
            spanClass = 'exp-success';
        }
        return {
            msg, spanClass, showOk, showLog
        };
    }

    public render(): JSX.Element | null {
        const { status, error } = this.props;
        if (!status) {
            return null;
        }

        if (error) {
            // no place in the UI for this, and this error is different from the errors in the export log; it could indicate unsupported file format or server error
            console.warn('An error occurred while exporting the builds to hybris: ', error);
            alert('An error occurred while exporting the builds to hybris: ' + error);
        }

        const { msg, spanClass, showLog, showOk } = this.determineState(status);

        return (
            <div className="map-topbar">
                <span className={spanClass}>{msg}</span>
                {showOk ? <TextButton onClick={this.onOkClick}>Ok</TextButton> : null}
                {showLog ? <TextButton onClick={this.onLogClick}>Download ZIP</TextButton> : null}
            </div>
        );
    }
}
