import React from 'react';
import { useTranslation } from 'react-i18next';
import { DiagramButtonProps } from '../nap-diagram.types';
import { LocalizationKeys } from '../../../locales/types';

export const DiagramDeleteButton = (props: DiagramButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const { onClick, disabled } = props;
    return (
        <g className={`btn-delete-tether ${disabled ? "disabled" : ""}`} transform="translate(0 56)" onClick={disabled ? undefined : onClick}>
            <circle cx="20" cy="20" r="20" filter="url(#btnShadow)" />
            <g transform="translate(8 8)">
                <path className="trashcan" d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM19,4H15.5l-1-1h-5l-1,1H5V6H19Z" />
                <path d="M0,0H24V24H0Z" fill="none" />
            </g>
            <title>{t(LocalizationKeys.DeleteATether)}</title>
        </g>
    );
};
