import './preset-config.scss';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { CablePresetConfig } from '../cable-preset-config';
import { EditPresetComponent } from '../edit-preset.component';
import { PresetConfigMode } from '../preset-manager.types';
import { EditorMode, PresetPropertiesEditorComponent } from '../preset-properties-editor.component';
import { PresetType } from '../presets-list/presets-list.types';
import { EditPreset } from '../schrodinger/edit-preset/edit-preset';
import { PresetConfig } from '../schrodinger/preset-config/preset-config';
import { PresetProperties } from '../schrodinger/preset-properties/preset-properties';
import { usePresetConfig } from './preset-config.hooks';

export const PresetConfigComponent = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        displayUnits, selectedSchrodingerPreset, configMode, selectedTab, flexNapPresetProps,
        onDeletePreset, onEditPreset, onCancelNewPreset, onNewPreset
    } = usePresetConfig();

    const renderPresetConfig = useCallback((): JSX.Element => {
        if (configMode === PresetConfigMode.CreateFlexNap) {
            return (
                <CablePresetConfig />
            );
        }
        else if (configMode === PresetConfigMode.CreateSchrodinger) {
            return (
                <PresetConfig />
            );
        }
        else if (selectedTab === PresetType.FlexNap && flexNapPresetProps.selectedPreset && configMode === PresetConfigMode.Edit) {
            return (
                <EditPresetComponent
                    preset={flexNapPresetProps.selectedPreset}
                    onCancel={onCancelNewPreset}
                />
            );
        }
        else if (selectedTab === PresetType.Schrodinger && configMode === PresetConfigMode.Edit && selectedSchrodingerPreset){
            return (
                <EditPreset 
                    preset={selectedSchrodingerPreset}
                    onCancel={onCancelNewPreset}
                />
            );
        }
        else if (selectedTab === PresetType.Schrodinger && configMode === undefined && selectedSchrodingerPreset) {
            return <PresetProperties preset={selectedSchrodingerPreset} />
        }
        else if (selectedTab === PresetType.FlexNap && flexNapPresetProps.selectedPreset) {
            return (
                <PresetPropertiesEditorComponent
                    mode={EditorMode.Viewing}
                    preset={flexNapPresetProps.selectedPreset}
                    displayUnits={displayUnits}
                    disableEditing={flexNapPresetProps.disableEditing}
                    onDeletePreset={onDeletePreset}
                    onEditPreset={onEditPreset}
                />
            );
        }
        else {
            return (
                <div className="empty-preset-container">
                    {((selectedTab === PresetType.FlexNap && !flexNapPresetProps.presets?.length) || (selectedTab === PresetType.Schrodinger)) && <span className="title">{t(LocalizationKeys.NoPresetAvailable)}</span>}
                    <span className="menu">{t(LocalizationKeys.ToCreateAPresetClickOn)} <span className="underline" onClick={onNewPreset}>{t(LocalizationKeys.New)}</span></span>
                </div>
            );
        }
    }, [t, configMode, flexNapPresetProps, selectedSchrodingerPreset, displayUnits, selectedTab,
        onNewPreset, onCancelNewPreset, onDeletePreset, onEditPreset]);

    return (
        <div className="preset-config">
            {renderPresetConfig()}
        </div>
    );
}
