import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLockPermission } from '../../../../hooks/lock-permission.hooks';
import { DesignArea } from '../../../../models/design-area';
import { DesignMode } from '../../../../models/design-mode';
import { ReviewStatus } from '../../../../models/review-status';
import { SchrodingerBuild } from '../../../../models/schrodinger-build';
import { OpticalTether } from '../../../../models/schrodinger/optical-tether';
import { PowerTether } from '../../../../models/schrodinger/power-tether';
import { TetherType } from '../../../../models/schrodinger/tether-type';
import { lockBuilds, unlockBuilds, updateSchrodingerBuild } from '../../../../redux/build.state';
import { updateBuildDesignArea } from '../../../../redux/design-area.state';
import { DialogType, showDialog } from '../../../../redux/overlay.state';
import { finishReview } from '../../../../redux/review.state';
import { displayedBuildAwaitingSessionUpdateSelector } from '../../../../selectors/build.selectors';
import { lastReviewSelectorFactory } from '../../../../selectors/review.selectors';
import {
    authenticationSelector, designAreaSelector, segmentsSelector, tapsSchrodingerSelector,
    tethersSchrodingerSelector, validationSelector, workspaceSelector
} from '../../../../selectors/root.selectors';
import { getDesignAreaDisplayText, getDesignAreaId } from '../../build/build-card.utility';
import { BuildPropertiesProps, defaultSchrodingerBuildColor } from '../build/build-card.types';

export const useBuildProperties = (props: BuildPropertiesProps) => {
    const { collapsed, build } = props;

    const designAreaState = useSelector(designAreaSelector);
    const segments = useSelector(segmentsSelector);
    const taps = useSelector(tapsSchrodingerSelector);
    const { userId } = useSelector(authenticationSelector);
    const tethers = useSelector(tethersSchrodingerSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const { validationResults } = useSelector(validationSelector);
    const lastReview = useSelector(lastReviewSelectorFactory(build.id));
    const awaitingSessionUpdate = useSelector(displayedBuildAwaitingSessionUpdateSelector);
    const {
        lockButtonDisabled,
        unlockButtonDisabled,
    } = useLockPermission();

    const [color, setColor] = useState(build.cableColorHex ?? defaultSchrodingerBuildColor);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);

    const dispatch = useDispatch();

    const designAreaDisplayText = getDesignAreaDisplayText(build.id, designAreaState);
    const designAreaDisplayId = getDesignAreaId(build.id, designAreaState);

    const designAreaList = designAreaState.designAreas
        .filter((dab) => dab.workspaceId === currentWorkspace?.id)

    const locationsCount = segments.filter((s) => s.buildId === build.id).length + 1;
    const buildTaps = taps.filter((t) => t.buildId === build.id);
    const tapCount = buildTaps.length;
    const serving = 0; // Number of parcels
    const isLocked = !!build.lockedById;
    const editDisabled = build?.modifiedById !== userId || isLocked || awaitingSessionUpdate;
    const unlockDisabled = isLocked ? unlockButtonDisabled(build.id) : lockButtonDisabled(build.id);
    const buildTapIds = buildTaps.map((t) => t.id);
    const buildTethers = tethers.filter((t) => buildTapIds.includes(t.tapId));
    const hasAnyConfiguredTether = buildTethers.find(t => t.type === TetherType.Optical ? !!(t as OpticalTether).fiberCountId : !!(t as PowerTether).partNumber);
    const splicePlanDisabled = !tapCount || !hasAnyConfiguredTether;
    const isCanvasBuild = build.designMode === DesignMode.CanvasMode;
    const requestReviewDisabled = !!validationResults[build.workspaceId]?.filter((r) => r.buildId === build.id).length || unlockDisabled;
    const cancelReviewDisabled = lastReview?.submittedById !== userId;
    const uploadBuildDisabled = !!build.catalogCode;

    const designAreaDisplaySelector = (value: DesignArea | number) => {
        if (typeof value === 'number') {
            const designArea = designAreaList.find((dab) => dab.id === value);
            if (designArea) {
                return designArea.name ? designArea.name + ': ' + designArea.projectId : designArea.projectId;
            }
            return '';
        } else {
            return value.name ? value.name + ': ' + value.projectId : value.projectId;
        }
    }

    const handleUpdateColor = useCallback((value: string) => {
        const newBuild: SchrodingerBuild = { ...build, cableColorHex: value };
        updateSchrodingerBuild(build, newBuild)(dispatch);
        setColor(value);
    }, [build, dispatch]);

    const handleResetColor = useCallback(() => {
        handleUpdateColor("");
    }, [handleUpdateColor]);

    const handleToggleDeleteBuildDialog = useCallback(() => {
        dispatch(showDialog(DialogType.DeleteSchrodingerBuild));
    }, [dispatch]);

    const handleUnlockBuild = useCallback(() => {
        if (currentWorkspace) {
            unlockBuilds([build.id], currentWorkspace.id)(dispatch);
        }
    }, [build, currentWorkspace, dispatch]);

    const handleLockBuild = useCallback(() => {
        if (currentWorkspace) {
            lockBuilds([build.id], currentWorkspace.id)(dispatch);
        }
    }, [build, currentWorkspace, dispatch]);

    const handleSplicePlanClick = useCallback(() => {
        setMoreMenuAnchorEl(undefined);
        dispatch(showDialog(DialogType.SplicePlan));
    }, [dispatch]);

    const handleBuildInfoClick = useCallback(() => {
        setMoreMenuAnchorEl(undefined);
        dispatch(showDialog(DialogType.BuildInfo));
    }, [dispatch]);

    const handleUpdateDesignArea = useCallback((value: any) => {
        const newDesignAreaName = designAreaList.find(d => d.id === value)?.name;
        dispatch(updateBuildDesignArea({
            buildId: build.id,
            designAreaId: value
        }, newDesignAreaName ?? value.toString()
        ));
    }, [designAreaList, dispatch, build]);

    const handleMoreMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        setMoreMenuAnchorEl(e.currentTarget);
    };

    const handleMoreMenuClose = () => {
        setMoreMenuAnchorEl(undefined);
    }

    const handleRequestReviewClick = () => {
        setMoreMenuAnchorEl(undefined);
        dispatch(showDialog(DialogType.ReviewRequest));
    }

    const handleCancelReviewClick = useCallback(() => {
        setMoreMenuAnchorEl(undefined);
        lastReview && dispatch(finishReview(lastReview.id, ReviewStatus.Canceled));
    }, [dispatch, lastReview]);

    const handleUploadBuildClick = useCallback((): void => {
        dispatch(showDialog(DialogType.UploadBuild));
    }, [dispatch]);

    return {
        build,
        collapsed,
        designAreaDisplayId,
        locationsCount,
        tapCount,
        serving,
        color,
        editDisabled,
        unlockDisabled,
        isLocked,
        splicePlanDisabled,
        designAreaList,
        designAreaDisplayText,
        isCanvasBuild,
        moreMenuAnchorEl,
        requestReviewDisabled,
        cancelReviewDisabled,
        uploadBuildDisabled,
        designAreaDisplaySelector,
        handleUpdateDesignArea,
        handleUpdateColor,
        handleResetColor,
        handleToggleDeleteBuildDialog,
        handleUnlockBuild,
        handleLockBuild,
        handleSplicePlanClick,
        handleBuildInfoClick,
        handleMoreMenuClick,
        handleMoreMenuClose,
        handleRequestReviewClick,
        handleCancelReviewClick,
        handleUploadBuildClick
    };
};
