import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loadConfigurations } from '../../../redux/cpq.state';
import { StateModel } from '../../../redux/reducers';
import { deletePreset } from '../../../redux/preset.state';
import { NotificationService } from "../../../services/notification.service";
import { PresetConfigMode, PresetManagerContext } from '../preset-manager.types';
import { LocalizationKeys } from '../../../locales/types';
import { PresetType } from "../presets-list/presets-list.types";

export const usePresetConfig = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const workspace = useSelector((state: StateModel) => state);
    const { displayUnits, userId } = workspace.authentication;
    const { presets, selectedPreset } = workspace.preset;
    const { selectedPreset: selectedSchrodingerPreset } = workspace.schrodingerpreset;

    const { selectedTab, configMode, updateConfigMode } = useContext(PresetManagerContext);

    useEffect(() => {
        loadConfigurations()(dispatch);
    }, [dispatch]);

    const onDeletePreset = useCallback((): void => {
        if (selectedPreset && selectedPreset.userId === userId) {
            deletePreset(selectedPreset.id)(dispatch);
            NotificationService.info(t(LocalizationKeys.PresetWasDeleted, { presetName: selectedPreset.name}));
        }
    }, [t, dispatch, selectedPreset, userId]);

    const onEditPreset = useCallback((): void => {
        updateConfigMode(PresetConfigMode.Edit);
    }, [updateConfigMode]);

    const onCancelNewPreset = useCallback((): void => {
        updateConfigMode();
    }, [updateConfigMode]);

    const onNewPreset = useCallback((): void => {
        updateConfigMode(selectedTab === PresetType.FlexNap ? PresetConfigMode.CreateFlexNap : PresetConfigMode.CreateSchrodinger);
    }, [selectedTab, updateConfigMode]);

    const flexNapPresetProps = {
        presets,
        selectedPreset,
        disableEditing: !selectedPreset || selectedPreset.userId !== userId,
    }

    return {
        displayUnits, selectedSchrodingerPreset, configMode, selectedTab, flexNapPresetProps,
        onDeletePreset, onEditPreset, onCancelNewPreset, onNewPreset
    };
};