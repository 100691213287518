import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { TetherDiagramProps } from '../../nap-diagram.types';

export const useTetherDiagramSvg = (props: TetherDiagramProps) => {
    const { tapIndex, tetherIndex, tether, selectedTetherId, pretermBuild } = props;
    const tetherConnector = 9;
    const [connected, setConnected] = useState(!!tether);
    const [fiberCount, setFiberCount] = useState(tether ? tether.fiberCount : -1);
    const [className, setClassName] = useState('tether');
    const isLeftTap = tapIndex === 1;
    const isLeftTether = tetherIndex === 1;
    const btnXDefault = pretermBuild ? -8 : -4;

    const [xOffset, setXOffset] = useState(33 + (isLeftTap ? 0 : 174));
    const [path, setPath] = useState(isLeftTether ? 'M78,23 h6 a5,5 0 0 1 5,5 v28 a5,5 0 0 1 -5,5 h-64 a5,5 0 0 0 -5,5 v75' : 'M78,16 h12 a5,5 0 0 1 5,5 v122');
    const [tetherConnectorX, setTetherConnectorX] = useState(tetherConnector + (isLeftTether ? 0 : 80));
    const [btnX, setBtnX] = useState(btnXDefault + (isLeftTether ? 0 : 80));

    useEffect(() => {
        setConnected(!!tether);
        setFiberCount(tether ? tether.fiberCount : -1);
    }, [tether]);

    useEffect(() => {
        const cn = classnames('tether', {
            connected: !!connected,
            disconnected: !connected,
            selected: tether && tether.id === selectedTetherId,
        });
        setClassName(cn);
    }, [connected, tether, selectedTetherId]);

    useEffect(() => {
        setXOffset(33 + (isLeftTap ? 0 : 174));
    }, [isLeftTap]);

    useEffect(() => {
        setPath(isLeftTether ? 'M78,23 h6 a5,5 0 0 1 5,5 v28 a5,5 0 0 1 -5,5 h-64 a5,5 0 0 0 -5,5 v75' : 'M78,16 h12 a5,5 0 0 1 5,5 v122');
        setTetherConnectorX(tetherConnector + (isLeftTether ? 0 : 80));
        setBtnX(btnXDefault + (isLeftTether ? 0 : 80));
    }, [isLeftTether, btnXDefault]);

    return {
        xOffset, className, path, tetherConnectorX, btnX, connected, isLeftTether,
        fiberCount, tether, pretermBuild
    };
};