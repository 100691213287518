import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flexNapSplicePlanLocationsWithCategoriesSelector } from '../../../selectors/splice-plan.selectors';
import { loadFlexNapSplicePlan } from '../../../redux/splice-plan.state';
import { selectedBuildNapsSelector } from '../../../selectors/taps.selectors';
import { SplicePlanDialogContentProps, SplicePlanDialogContentHooks } from './splice-plan-dialog.types';

export const useSplicePlanDialogContent = (props: SplicePlanDialogContentProps): SplicePlanDialogContentHooks => {
    const {
        isDialogOpen, buildId, currentLocation,
        setCurrentLocation
    } = props;

    const locations = useSelector(flexNapSplicePlanLocationsWithCategoriesSelector);
    const tethers = [...locations[currentLocation]?.tethers || []];
    const naps = useSelector(selectedBuildNapsSelector);

    const dispatch = useDispatch();
    
    useEffect(() => {
            isDialogOpen && buildId && naps.length && dispatch(loadFlexNapSplicePlan(buildId));
    }, [dispatch, buildId, naps, isDialogOpen]);

    return {
        tethers, locations, currentLocation,
        setCurrentLocation
    }
}
