import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { CabinetSplicePlan, CabinetSplicePlanBuild } from '../models/cabinet-splice-plan';
import { CabinetSplicePlanService } from '../services/cabinet-splice-plan.service';

export enum PortMode {
    Manual = 1,
    Automatic = 2,
}
export interface CabinetSplicePlanState {
    currentSplicePlan?: CabinetSplicePlan;
    cabinetSplicePlans: {
        [cabinetId: number]: CabinetSplicePlan;
    };
    portMode: PortMode;
    currentBuildInManualPortEdit?: CabinetSplicePlanBuild;
    buildIdRequestingCabinetSplicePlan?: number;
}

const initialState: CabinetSplicePlanState = {
    currentSplicePlan: undefined,
    cabinetSplicePlans: {},
    portMode: PortMode.Automatic
};

const loadCabinetSplicePlanReducer = (state: CabinetSplicePlanState, action: PayloadAction<CabinetSplicePlan>): void => {
    state.currentSplicePlan = action.payload;
    if (state.currentSplicePlan?.cabinetId) {
        state.cabinetSplicePlans[state.currentSplicePlan?.cabinetId] = action.payload;
    }

};

const loadCabinetSplicePlansReducer = (state: CabinetSplicePlanState, action: PayloadAction<CabinetSplicePlan[]>): void => {
    const splicePlans = action.payload;
    state.cabinetSplicePlans = splicePlans.reduce((plans, plan) => {
        plans[plan.cabinetId] = plan;
        return plans;
    }, {});
};

const removeCabinetSplicePlansReducer = (state: CabinetSplicePlanState): void => {
    state.cabinetSplicePlans = {};
    state.currentSplicePlan = undefined;
};

const setPortModeReducer = (state: CabinetSplicePlanState, action: PayloadAction<PortMode>): void => {
    state.portMode = action.payload;
}

const setCurrentBuildInPortEditReducer = (state: CabinetSplicePlanState, action: PayloadAction<CabinetSplicePlanBuild>): void => {
    state.currentBuildInManualPortEdit = action.payload;
}

const setBuildIdRequestingCabinetSplicePlanReducer = (state: CabinetSplicePlanState, action: PayloadAction<number | undefined>): void => {
    state.buildIdRequestingCabinetSplicePlan = action.payload;
}

const { actions, reducer } = createSlice({
    name: "cabinetSplicePlan",
    initialState,
    reducers: {
        loadCabinetSplicePlan: loadCabinetSplicePlanReducer,
        loadCabinetSplicePlans: loadCabinetSplicePlansReducer,
        removeCabinetSplicePlans: removeCabinetSplicePlansReducer,
        setPortMode: setPortModeReducer,
        setCurrentBuildInManualPortEdit: setCurrentBuildInPortEditReducer,
        setBuildIdRequestingCabinetSplicePlan: setBuildIdRequestingCabinetSplicePlanReducer
    },
});

export { reducer as CabinetSplicePlanReducer };
export const { loadCabinetSplicePlan, removeCabinetSplicePlans, setPortMode, setCurrentBuildInManualPortEdit, setBuildIdRequestingCabinetSplicePlan } = actions;

export const loadCabinetSplicePlans = (workspaceId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new CabinetSplicePlanService();
        const splicePlans = await service.loadSplicePlans(workspaceId);
        if (splicePlans) {
            dispatch(actions.loadCabinetSplicePlans(splicePlans));
        }
    };
};

export const loadCabinetSplicePlanFromBuildId = (workspaceId: number, buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new CabinetSplicePlanService();
        const splicePlan = await service.updateCabinetSplicePlanByBuild(workspaceId, buildId);
        if (splicePlan) {
            dispatch(actions.loadCabinetSplicePlan(splicePlan));
            dispatch(actions.setBuildIdRequestingCabinetSplicePlan(buildId));
        }
        else {
            dispatch(actions.setBuildIdRequestingCabinetSplicePlan(-1));
        }
    };
}

export const loadCabinetSplicePlanFromCabinetId = (workspaceId: number, cabinetId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new CabinetSplicePlanService();
        const splicePlan = await service.loadSplicePlan(workspaceId, cabinetId);
        if (splicePlan) {
            dispatch(actions.loadCabinetSplicePlan(splicePlan));
        }
    };
}

export const updateCabinetSplicePlans = (workspaceId: number, cabinetId: number, cabinetBuildAction: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new CabinetSplicePlanService();
        const splicePlans = await service.updateCabinetBuildPositions(workspaceId, cabinetId, cabinetBuildAction);
        if (splicePlans) {
            dispatch(actions.loadCabinetSplicePlan(splicePlans));
        }
    };
};