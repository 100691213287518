import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AccessPointHelper } from '../../../helpers/access-point-helper';
import { LocalizationKeys } from '../../../locales/types';
import { CableType } from '../../../models/cable-type';
import { DataValue } from '../../../models/datavalue';
import { SplicePoint } from '../../../models/splice-point';
import {
    connectBuildToSplicePoint, deleteBulkCable, disconnectBuildFromSplicePoint, updateBulkBuild
} from '../../../redux/build.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { selectBuild, selectSegment, selectSplicePoint } from '../../../redux/selection.state';
import { possibleSplicePointsToConnectSelector } from '../../../selectors/bulk/splice-points.selectors';
import { rolesSelector } from '../../../selectors/root.selectors';
import { CardContextMenuItemProps } from '../../ui-elements/card-context-menu';
import { useTotalLength } from '../build/build-card.utility';
import CorningRoles from '../../../redux/corning-roles.json';
import { BulkCableProps, BulkCableType } from './bulk.types';

const DEFAULT_FIBER_COUNT = 144;

const availableFiberCounts = {
    [CableType.Ribbon]: [12, 24, 36, 48, 60, 72, 96, 144, 192, 216, 288, 432, 576, 864],
    [CableType.LooseTube]: [12, 24, 36, 48, 60, 72, 96, 144, 192, 216, 288, 432]
};

export const useBulkCableCard = (props: BulkCableProps) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const workspace = useSelector((state: StateModel) => state);
    const { displayUnits } = workspace.authentication;
    const { bulkBuilds, flexnapBuilds, segments: buildSegments } = workspace.build;
    const { splicePoints } = workspace.splicePoints;

    const { roles } = useSelector(rolesSelector);
    const showConvertButton = roles && (roles.some((role) => role === CorningRoles.FlexNAP));

    const { build, segments, collapsed, disabled } = props;
    const cardLabel = t(LocalizationKeys.Bulk);
    const cableTypes: BulkCableType[] = [
        { value: CableType.Ribbon, display: t(LocalizationKeys.Ribbon) },
        { value: CableType.LooseTube, display: t(LocalizationKeys.LooseTube) }
    ];

    const [cableType, setCableType] = useState(build.cableType);
    const [fiberCount, setFiberCount] = useState(build.fiberCount);
    const [isFieldSlackValid, setIsFieldSlackValid] = useState(true);

    const totalLength = useTotalLength(workspace, build, segments, false);

    const isConnected = !!build.buildSplice;
    const possibleSplicePoints: SplicePoint[] = useSelector(possibleSplicePointsToConnectSelector);
    const canConnect = !!possibleSplicePoints.length;

    const hasSplicedBuilds = bulkBuilds.some(b => b.buildSplice?.parentBuildId === build.id) || flexnapBuilds.some(b => b.buildSplice?.parentBuildId === build.id);
    
    const labelButtonConnect = t(isConnected ? LocalizationKeys.Disconnect : LocalizationKeys.Connect);
    const disabledButtonConnect = disabled || (!isConnected && !canConnect);

    const [contextMenuItems, setContextMenuItems] = useState<CardContextMenuItemProps[]>([]);
    const [contextMenuPosition, setContextMenuPosition] = useState({ right: 0, bottom: 0 });

    useEffect(() => {
        if (cableType !== build.cableType) {
            let fiberCount = build.fiberCount;
            if (!availableFiberCounts[cableType].includes(fiberCount)) {
                // If the newly selected Cable type does not allow the current Fiber Count, set it to the default value
                fiberCount = DEFAULT_FIBER_COUNT;
            }
            const updatedBuild = { ...build, cableType, fiberCount };
            updateBulkBuild(build, updatedBuild)(dispatch).then(() => {
                setFiberCount(fiberCount);
            });
        }
    }, [build, cableType, dispatch]);

    const updateFiberCount = useCallback((value: number) => {
        const updatedBuild = { ...build, fiberCount: value };
        updateBulkBuild(build, updatedBuild)(dispatch).then(() => {
            setFiberCount(value);
        });
    }, [build, dispatch]);

    const validateFieldSlack = useCallback((value: number): boolean => {
        const isValid = !(isNaN(value) || Math.sign(value) < 0);
        setIsFieldSlackValid(isValid);
        return isValid;
    }, []);

    const handleFieldSlackUpdate = useCallback((value: string): void => {
        const updatedFieldSlack = new DataValue(parseFloat(value), displayUnits);
        const requiresUpdate = build.fieldSlack !== updatedFieldSlack.value || build.slackUnit !== displayUnits;
        if (requiresUpdate) {
            const isValid = validateFieldSlack(updatedFieldSlack.value);
            if (isValid) {
                const updatedBuild = { ...build, fieldSlack: updatedFieldSlack.value, slackUnit: displayUnits };
                updateBulkBuild(build, updatedBuild)(dispatch);
            }
        }
    }, [build, displayUnits, validateFieldSlack, dispatch]);

    const handleDeleteClick = useCallback((): void => {
        if (!build) return;
        if (hasSplicedBuilds) {
            dispatch(showDialog(DialogType.DeleteBulkCable));
        } else {
            deleteBulkCable(build.id)(dispatch);
        }
    }, [build, hasSplicedBuilds, dispatch]);

    const connectToSplicePoint = useCallback((splicePointId: number): void => {
        connectBuildToSplicePoint(build.id, splicePointId)(dispatch);
        setContextMenuItems([]);
        dispatch(selectBuild());
        dispatch(selectSegment());
        dispatch(selectSplicePoint(splicePointId));
    }, [build, dispatch]);

    const onConnect = useCallback((event: React.MouseEvent): void => {
        if (!possibleSplicePoints.length) {
            return;
        }
        if (possibleSplicePoints.length === 1) {
            connectToSplicePoint(possibleSplicePoints[0].id);
        } else {
            const { innerWidth: pageWidth, innerHeight: pageHeight } = window;
            const { right, bottom } = event.currentTarget.getBoundingClientRect();
            setContextMenuPosition({ right: pageWidth - right, bottom: pageHeight - bottom });
            setContextMenuItems(possibleSplicePoints.map(s => AccessPointHelper.createSplicePointContextMenuItemProps(s, splicePoints, bulkBuilds, buildSegments, connectToSplicePoint, t)));
        }
    }, [possibleSplicePoints, bulkBuilds, buildSegments, splicePoints, connectToSplicePoint, t]);

    const onDisconnect = useCallback((): void => {
        disconnectBuildFromSplicePoint(build.id)(dispatch);
    }, [build, dispatch]);

    const onButtonConnectClick = useCallback((event: React.MouseEvent): void => {
        if (isConnected) {
            onDisconnect();
        } else {
            onConnect(event);
        }
    }, [isConnected, onConnect, onDisconnect]);

    const handleButtonConnect = disabledButtonConnect ? undefined : onButtonConnectClick;

    const onCancelContextMenu = (): void => {
        setContextMenuItems([]);
    };

    const handleButtonConvert = useCallback((): void => {
        dispatch(showDialog(DialogType.ConvertBulkBuild));
    }, [dispatch]);

    return {
        build,
        cableType, fiberCount,
        totalLength, displayUnits,
        cableTypes, availableFiberCounts,
        cardLabel, collapsed, disabled,
        setCableType, updateFiberCount,
        isFieldSlackValid, validateFieldSlack, handleFieldSlackUpdate,
        handleDeleteClick, handleButtonConnect, labelButtonConnect, disabledButtonConnect, handleButtonConvert,
        showConvertButton, contextMenuItems, contextMenuPosition, onCancelContextMenu
    };
};
