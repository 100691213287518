import { MapClickEvent } from '../models/map-click-event';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class DropCableTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.DropCable,
        name: 'Drop Cable',
        shortcut: '',
    };

    public static execute(event: MapClickEvent): void {
        //
    }
}
