import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PathHelper } from '../../../helpers/path-helper';
import { SegmentHelper } from '../../../helpers/segment-helper';
import { DataValue } from '../../../models/datavalue';
import { Trench } from '../../../models/trench';
import { deletePath, updatePath } from '../../../redux/path.actions';
import { StateModel } from '../../../redux/reducers';
import { selectTrench } from '../../../redux/selection.state';

export const useTrenchCard = () => {
    const workspace = useSelector((state: StateModel) => state);
    const selectedTrenchId = workspace.selection.selectedTrenchId;
    const selectedTrench = selectedTrenchId ? workspace.trench.trenches.find((t) => t.id === selectedTrenchId) : undefined;
    const displayUnits = workspace.authentication.displayUnits;
    const buildSegments = workspace.build.segments;
    const terminalSegments = workspace.taps.tethers.flatMap(t => t.terminal?.terminalExtension?.segments ?? []);
    const { selectedTool } = workspace.tool;

    const [calculatedLength, setCalculatedLength] = useState<DataValue | undefined>(undefined);
    const [measuredLength, setMeasuredLength] = useState<DataValue | undefined>(undefined);
    const [measuredLengthIsValid, setMeasuredLengthIsValid] = useState(true);
    const [containsSegment, setContainsSegment] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedTrench) {
            setCalculatedLength(PathHelper.calculatePathLength(selectedTrench.route).toUnit(displayUnits));
            setMeasuredLength(new DataValue(selectedTrench.measuredLength || 0, selectedTrench.lengthUnit || displayUnits).toUnit(displayUnits));
            setMeasuredLengthIsValid(true);
        }
    }, [selectedTrench, displayUnits]);

    useEffect(() => {
        setContainsSegment(!!selectedTrench && (!!SegmentHelper.getBuildSegmentsOnPath(selectedTrench, buildSegments).length || !!SegmentHelper.getTerminalSegmentsOnPath(selectedTrench, terminalSegments).length));
    }, [selectedTrench, buildSegments, terminalSegments]);

    const unselectTrench = useCallback(() => {
        dispatch(selectTrench());
    }, [dispatch]);

    const handleDeleteTrench = useCallback(() => {
        if (selectedTrench) {
            if (containsSegment) {
                return;
            }

            deletePath(selectedTrench)(dispatch);
            unselectTrench();
        }
    }, [dispatch, selectedTrench, containsSegment, unselectTrench]);

    const validateMeasuredLength = useCallback((value: any): boolean => {
        const valid = parseFloat(value) >= 0;
        setMeasuredLengthIsValid(valid);
        return valid;
    }, []);

    const handleLengthUpdate = useCallback((value: any) => {
        if(!selectedTrench) {
            return;
        }
        if (validateMeasuredLength(value)) {
            const measuredLength = parseFloat(value) || 0;
            const newTrench: Trench = { ...selectedTrench, measuredLength: measuredLength, lengthUnit: displayUnits };
            updatePath(selectedTrench, newTrench)(dispatch);
            dispatch(selectTrench(newTrench.id));
        }
    }, [dispatch, selectedTrench, displayUnits, validateMeasuredLength]);

    const handleIdOverrideUpdate = useCallback((value: string): void => {
        if (!selectedTrench) {
            return;
        }

        const newBore: Trench = { ...selectedTrench, tagOverride: value ? value : undefined };
        updatePath(selectedTrench, newBore)(dispatch);
        dispatch(selectTrench(newBore.id));
    }, [dispatch, selectedTrench]);

    return {
        selectedTrench,  calculatedLength, measuredLength, measuredLengthIsValid, displayUnits, containsSegment, selectedTool,
        unselectTrench, deleteTrench: handleDeleteTrench, handleLengthUpdate, handleIdOverrideUpdate, validateMeasuredLength,
    };
}