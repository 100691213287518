import { Graphics, Rectangle, Texture } from 'pixi.js';
import { useEffect, useState } from 'react';

import { IIndexedFrameProps, TOOLBAR_PROPORTIONS } from '../../build/build.types';
import { ToolbarItemProps } from './toolbar-item.types';

export const useToolbarItem = ({ position, index, icon, onClick }: IIndexedFrameProps & ToolbarItemProps) => {
    const { x, y } = position;
    const indexPosition = x + (TOOLBAR_PROPORTIONS.ITEM_WIDTH * index);
    const xPosition = (indexPosition + (TOOLBAR_PROPORTIONS.ITEM_WIDTH / 2));
    const yPosition = y + (TOOLBAR_PROPORTIONS.ITEM_HEIGHT / 2);
    const hitArea = new Rectangle(indexPosition, y, TOOLBAR_PROPORTIONS.ITEM_WIDTH, TOOLBAR_PROPORTIONS.ITEM_HEIGHT);
    const [iconTexture, setIconTexture] = useState<Texture>(Texture.from(icon));

    useEffect(() => {
        Texture.fromURL(icon, { resolution: devicePixelRatio }).then(loadedTexture => setIconTexture(loadedTexture));
    }, [icon]);

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const drawHoverShade = (graphics: Graphics) => {
        graphics.clear();
        graphics.beginFill(0x005293, 0.08);
        graphics.drawRoundedRect(indexPosition, y, TOOLBAR_PROPORTIONS.ITEM_WIDTH, TOOLBAR_PROPORTIONS.ITEM_HEIGHT, TOOLBAR_PROPORTIONS.ROUNDED_CORNER);
        graphics.endFill();
    };

    return {
        xPosition, yPosition,
        iconTexture, hitArea, isHovered,
        drawHoverShade, handleMouseOver, handleMouseOut,
        handleClick: onClick,
    }
};