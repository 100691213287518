import React from 'react';
import { useSelector } from 'react-redux';

import { getTools } from '../../design-tools/tool-definition';
import { MapType } from '../../openlayers/openlayer-factory.service';
import { StateModel } from '../../redux/reducers';
import { WorkspaceDrawer } from '../drawers/workspace/drawer-workspace';
import { GisMenuBar } from '../map-tools/gis-menu-bar';
import { MainMenu } from '../map-tools/main-menu/main-menu';
import { MapToolsComponent } from '../map-tools/map-tools.component';
import { ZoomTool } from '../map-tools/zoom-tool';
import { PresetManager } from '../presets/preset-manager';

interface GisOverlayProperties {
    map: MapType;
}

export const GisOverlayComponent = (props: GisOverlayProperties): JSX.Element | null => {
    const { map } = props;
    const workspace = useSelector((state: StateModel) => state);
    const { buildType } = workspace.tool;
    const buildTools = getTools(buildType);
    return (
        <div className="overlay">
            <GisMenuBar />
            <PresetManager />
            <MainMenu />
            <WorkspaceDrawer />
            <MapToolsComponent map={map} buildTools={buildTools} />
            <ZoomTool map={map} />
        </div>
    );
}
