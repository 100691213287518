import React from 'react';

import { LocalizationKeys } from '../../../../locales/types';
import { DialogComponent } from '../../../dialogs/dialog.component';
import { useDeleteBuildsDialog } from './delete-builds-dialog.hooks';

export const DeleteBuildsDialog = (): JSX.Element | null => {
    const {
        currentWorkspace,
        open,
        title,
        handleDeleteBuildsClick,
        handleCloseClick,
        t,
    } = useDeleteBuildsDialog();

    if (!currentWorkspace) {
        return null;
    }

    return (
        <DialogComponent
            open={open}
            title={title}
            positiveTitle={t(LocalizationKeys.Delete)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={handleDeleteBuildsClick}
            onClose={handleCloseClick}
            negativeStyleButton
        />
    );
};
