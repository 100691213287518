import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../models/design-mode';
import { loadNewCanvasBuildSpans, setDisplayedBuildId } from '../../redux/build.state';
import { DialogType, showDialog } from '../../redux/overlay.state';
import { updateWorkspace } from '../../redux/workspace.state';
import {
    buildSelector, designModeSelector, workspaceSelector
} from '../../selectors/root.selectors';

export const WorkspaceManager = () => {
    const designMode = useSelector(designModeSelector)
    const { currentWorkspace } = useSelector(workspaceSelector);
    const { schrodingerBuilds: builds, schrodingerBuildsLoaded: loaded, displayedBuildId } = useSelector(buildSelector);

    const dispatch = useDispatch();

    const [previousDisplayId, setPreviousDisplayId] = useState(displayedBuildId);

    useEffect(() => {
        if (currentWorkspace && displayedBuildId && (displayedBuildId !== previousDisplayId)) {
            const newWorkspace = { ...currentWorkspace, recentBuildId: displayedBuildId };
            dispatch(updateWorkspace(currentWorkspace, newWorkspace));
        }
        setPreviousDisplayId(displayedBuildId);
    }, [currentWorkspace, previousDisplayId, designMode, displayedBuildId, dispatch]);

    useEffect(() => {
        // extra check if any GIS builds are loaded when is Canvas mode
        // that means that the design mode has been switched, but the Canvas builds have not been loaded yet
        if (loaded && designMode === DesignMode.CanvasMode && !displayedBuildId && !builds.some(({ designMode }) => designMode === DesignMode.GISMode)) {
            const recentBuild = builds.find(({ id }) => id === currentWorkspace?.recentBuildId);
            if (recentBuild) {
                dispatch(setDisplayedBuildId(recentBuild.id));
                dispatch(showDialog());
                dispatch(loadNewCanvasBuildSpans());
            }
            else {
                dispatch(showDialog(DialogType.CreateNewBuild));
            }
        }
    }, [builds, currentWorkspace, designMode, dispatch, displayedBuildId, loaded]);

    return null;
}