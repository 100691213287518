import React from 'react';
import { useTranslation } from 'react-i18next';
import { DiagramButtonProps } from '../nap-diagram.types';
import { LocalizationKeys } from '../../../locales/types';

export const DiagramAddButton = (props: DiagramButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const { onClick, disabled } = props;
    return (
        <g className={`btn-add-tether ${disabled ? "disabled" : ""}`} onClick={disabled ? undefined : onClick} >
            <circle cx="20" cy="20" r="20" filter="url(#btnShadow)" />
            <line className="btn-add-cross" x1="13" x2="27" y1="20" y2="20" />
            <line className="btn-add-cross" x1="20" x2="20" y1="13" y2="27" />
            <title>{t(LocalizationKeys.AddATether)}</title>
        </g>
    );
};
