import './import.card.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ContainedButton, OutlinedButton, TextButton } from '@orbit/button';
import { CircularProgressIndeterminate } from '@orbit/progress-indicator';
import { MainPalettes, Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardHeaderComponent } from '../../card/card-header.component';
import { ImportCustomerFormatContent } from './customerFormat/import-customer-format-content';
import { ImportGeojsonContent } from './geoJson/import-geojson-content';
import { useImportDialog } from './import.card.hooks';
import { ImportWorkflow } from './import.card.types';
import { ImportShapefileContent } from './shapefile/import-shapefile-content';
import { UploadContent } from './upload-content';

export const ImportCard = (): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        open,
        files,
        importWorkflow,
        disableNext,
        leftButtonTitle,
        stepCount,
        leftButtonClickCount,
        positiveButtonTitle,
        formatSelectionValue,
        isLoadingStep,
        fmeImportFlexNap,
        onClose,
        onCancel,
        onNext,
        onLeftButtonClicked,
        setFiles,
        setDisableNext,
        setLeftButtonTitle,
        setPositiveButtonTitle,
        setStepCount,
        onFormatSelectionChange,
        setIsLoadingStep
    } = useImportDialog();

    if (open) {
        return (
            <div className="import-card-wrapper">
                <Card className="import-card" id="import-card" onCloseClick={onCancel} onToggleCollapse={undefined /**TODO toggleCollapsed*/}>
                    <CardHeaderComponent title="Importing GIS Data" subheader=" " className="import-card-header" />
                    <CardContentComponent className="import-card-content">
                        {stepCount === 0 &&
                            <div className="format-selection">
                                {fmeImportFlexNap && (
                                    <FormControl style={{ flexDirection: 'row' }}>
                                        <Typography variant="body1" label="Select the data format:" />
                                        <RadioGroup
                                            className="data-format-radio-group"
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={formatSelectionValue}
                                            onChange={onFormatSelectionChange}
                                            row
                                        >
                                            <FormControlLabel value="corning-format" control={<Radio color="primary" />} label="Corning" />
                                            <FormControlLabel value="customer-format" control={<Radio color="primary" />} label="Generic" disabled />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            </div>
                        }
                        <div>
                            {stepCount === 0 &&
                                <UploadContent setFiles={setFiles} setDisableNext={setDisableNext}></UploadContent>
                            }
                            {stepCount > 0 && importWorkflow === ImportWorkflow.CorningFormatInfrastructureAndDesignGeoJson &&
                                <ImportGeojsonContent files={files} setDisableNext={setDisableNext} setLeftButtonTitle={setLeftButtonTitle} onClose={onClose} setPositiveButtonTitle={setPositiveButtonTitle} stepCount={stepCount} setStepCount={setStepCount} leftButtonClickCount={leftButtonClickCount} setIsLoadingStep={setIsLoadingStep} />
                            }
                            {stepCount > 0 && importWorkflow === ImportWorkflow.CorningFormatInfrastructureShapefile &&
                                <ImportShapefileContent files={files} setDisableNext={setDisableNext} setLeftButtonTitle={setLeftButtonTitle} onClose={onClose} setPositiveButtonTitle={setPositiveButtonTitle} stepCount={stepCount} setStepCount={setStepCount} leftButtonClickCount={leftButtonClickCount} setIsLoadingStep={setIsLoadingStep} />
                            }
                            {stepCount > 0 && importWorkflow === ImportWorkflow.CustomerFormatInfrastructureAny &&
                                <ImportCustomerFormatContent files={files} setDisableNext={setDisableNext} setLeftButtonTitle={setLeftButtonTitle} onClose={onClose} setPositiveButtonTitle={setPositiveButtonTitle} stepCount={stepCount} setStepCount={setStepCount} leftButtonClickCount={leftButtonClickCount} setIsLoadingStep={setIsLoadingStep} />
                            }
                        </div>
                        <div className="import-card-footer">
                            {!!leftButtonTitle && <TextButton className="left" onClick={onLeftButtonClicked}>{leftButtonTitle}</TextButton>}
                            <div className="push-right">
                                <OutlinedButton className="negative-button" onClick={onCancel}>{t(LocalizationKeys.Cancel)}</OutlinedButton>
                                {!isLoadingStep ?
                                    <ContainedButton className="positive-button" disabled={disableNext} onClick={onNext}>{positiveButtonTitle}</ContainedButton>
                                    :
                                    <TextButton className="positive-button" disabled={true} onClick={onNext}><CircularProgressIndeterminate palette={MainPalettes.primary} className="positive-button-loading" size="20" /></TextButton>
                                }
                            </div>
                        </div>
                    </CardContentComponent>
                </Card>
            </div>
        );
    } else {
        return null;
    }
};