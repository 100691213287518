import { PointLike } from '../models/pointlike';
import { SearchService } from '../services/search.service';
import { ProjectionConverter } from './projection-converter';

export class DesignAreaHelper {

    public static isPointInsidePolygon(point: PointLike, polygon: PointLike[]): boolean {
        if (!polygon)
            return false;
        // Draw horizontal line to the right of eachpoint and extend to infinity
        // Count number of times line intersects with polygon edges
        // Point inside polygon = odd count of intersections or on edge of polygon else outside polygon
        let intersects = 0;
        for (let i = 0; i < polygon.length - 1; i++) {
            const p1 = polygon[i];
            const p2 = polygon[i + 1];
            const isYValid = (point.y >= p1.y && point.y <= p2.y) || (point.y <= p1.y && point.y >= p2.y);
            if (!isYValid) {
                continue;
            }
            const m = (p2.y - p1.y) / (p2.x - p1.x);
            const b = p1.y - (m * p1.x);
            const x = (point.y - b) / m;
            const isXValid = (x >= p1.x && x <= p2.x) || (x <= p1.x && x >= p2.x);
            if (isXValid && x >= point.x) {
                intersects++;
            }
        }
        return intersects % 2 === 1;
    }

    public static getPolygonCenter(polygon: PointLike[] | undefined): PointLike | undefined {
        if (!polygon) {
            return undefined;
        }

        let min: PointLike = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER };
        let max: PointLike = { x: Number.MIN_SAFE_INTEGER, y: Number.MIN_SAFE_INTEGER };

        polygon.forEach((p) => { // Finds the bounding box min/max coordinates of the polygon
            min = { x: Math.min(p.x, min.x), y: Math.min(p.y, min.y) };
            max = { x: Math.max(p.x, max.x), y: Math.max(p.y, max.y) };
        });

        return { x: min.x + ((max.x - min.x) * 0.5), y: min.y + ((max.y - min.y) * 0.5) };
    }

    public static async getLocation(polygon: PointLike[] | undefined): Promise<string> {
        const polygonCenter = this.getPolygonCenter(polygon);
        if (!polygonCenter) {
            return "";
        }
        const latLongLocation = ProjectionConverter.convertMapToLatLong([polygonCenter.x, polygonCenter.y]);
        const searchService = new SearchService();
        return await searchService.findLocation(latLongLocation.reverse()) ?? "";
    }
}