import { Dispatch } from 'redux';
import { Command } from '../../command';
import { convertToPretermLateral, revertPretermLateral } from '../../../redux/build.state';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class CreatePretermCommand extends Command {
    private buildId: number;
    private parentNapId: number;
    private parentBuildId: number;

    constructor({ id: buildId, parentNapId, parentBuildId }) {
        super();
        this.buildId = buildId;
        this.parentNapId = parentNapId;
        this.parentBuildId = parentBuildId;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoPretermCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoPretermCreation };
    }

    public undo(dispatch: Dispatch): void {
        revertPretermLateral(this.buildId)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        convertToPretermLateral(this.buildId, this.parentNapId, this.parentBuildId, true)(dispatch);
        super.redo(dispatch);
    }
}