import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { Build } from '../../../models/build';
import { BuildType } from '../../../models/build-type';
import { DesignArea } from '../../../models/design-area';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { SchrodingerBuild } from '../../../models/schrodinger-build';
import { deleteDesignArea, updateDesignArea } from '../../../redux/design-area.state';
import { setDownloadState } from '../../../redux/download.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { selectDesignArea } from '../../../redux/selection.state';
import { CabinetService } from '../../../services/cabinet.service';
import { GeoserverService } from '../../../services/geoserver.service';
import { useExport } from '../../../hooks/export.hooks';
import { DesignAreaColorSelectorProps, DesignAreaInformationProps } from './design-area.types';
import { IconButtonProps } from '@orbit/icon-button';

const getDesignAreaParcels = (da: DesignArea, callback: (value: number) => void): void => {
    if (!da.polygon) {
        callback(0);
        return;
    }
    new GeoserverService()
        .getParcelsInDesignArea(da.polygon)
        .then((res) => {
            if (res) {
                callback(res);
            }
        });
};

const getDesignAreaCabinets = (da: DesignArea, callback: (values: string[]) => void): void => {
    if (!da.polygon) {
        callback([]);
        return;
    }
    new CabinetService()
        .getCabinetsInDesignArea(da.polygon)
        .then((cabinets) => {
            if (cabinets) {
                callback(cabinets.map(c => c.tagOverride ?? c.tag));
            }
        });
};

const useSelectedDesignArea = (id?: number) => {
    const { designAreas } = useSelector((state: StateModel) => state.designarea);
    const selectedDesignArea = designAreas.find((da) => da.id === id);
    const [parcelCount, setParcelCount] = useState(0);
    const [cabinetIds, setCabinetIds] = useState<string[]>([]);

    useEffect(() => {
        if (selectedDesignArea) {
            getDesignAreaParcels(selectedDesignArea, setParcelCount);
            getDesignAreaCabinets(selectedDesignArea, setCabinetIds);
        }
        setParcelCount(0);
        setCabinetIds([]);
    }, [selectedDesignArea]);

    return { selectedDesignArea, parcelCount, cabinetIds };
};

const useDesignAreaNaps = (daBuildIds: Set<number>) => {
    return useSelector((state: StateModel) => {
        const naps = state.taps.naps.filter((n) => n.buildId && daBuildIds.has(n.buildId));
        return naps;
    });
};

const useDesignAreaTaps = (daBuildIds: Set<number>) => {
    return useSelector((state: StateModel) => {
        const taps = state.tapsSchrodinger.taps.filter((s) => s.buildId && daBuildIds.has(s.buildId));
        const tapIds = new Set(taps.map((s) => s.id));
        const tethers = state.tapsSchrodinger.tethers.filter((ap) => tapIds.has(ap.tapId));
        return { taps, tethers };
    });
};

const useDesignAreaBuilds = (id?: number) => {
    const { designAreaBuilds } = useSelector((state: StateModel) => state.designarea);
    const designAreaBuildIds = designAreaBuilds.find((da) => da.designAreaId === id)?.buildIds ?? [];
    const daBuildIds = new Set(designAreaBuildIds);
    const { daBuilds, daFnapBuilds } = useSelector((state: StateModel) => {
        const builds = [...state.build.flexnapBuilds, ...state.build.bulkBuilds, ...state.build.schrodingerBuilds];
        return {
            daBuilds: builds.filter((b) => daBuildIds.has(b.id)),
            daFnapBuilds: state.build.flexnapBuilds.filter(b => daBuildIds.has(b.id))
        };
    });
    const naps = useDesignAreaNaps(daBuildIds);
    const { taps, tethers } = useDesignAreaTaps(daBuildIds);

    return { daBuilds, daFnapBuilds, naps, taps, tethers };
};

const useDesignAreaCountryRegions = (designAreaId?: number) => {
    const { countryStates } = useSelector((state: StateModel) => state.cpq);
    const { designAreas } = useSelector((state: StateModel) => state.designarea);
    const countries = countryStates.map((c) => c.country);
    const designAreaCountry = designAreas.find((da) => da.id === designAreaId)?.country;
    const regions = countryStates.find((c) => c.country === designAreaCountry)?.states.slice().sort() || [];

    return { countries, regions };
};

export const useDesignAreaCard = () => {
    const { t } = useTranslation();
    const {
        selection: { selectedDesignAreaId },
        tool: { selectedTool }
    } = useSelector((state: StateModel) => state);
    const { selectedDesignArea, parcelCount, cabinetIds } = useSelectedDesignArea(selectedDesignAreaId);
    const { daBuilds, daFnapBuilds, naps, taps, tethers } = useDesignAreaBuilds(selectedDesignAreaId);
    const [deleteDialog, showDeleteDialog] = useState(false);
    const [cabinetLabel, setCabinetLabel] = useState(t(LocalizationKeys.CabinetId) as string);
    const [hasFlexNapBuild, setHasFlexNapBuild] = useState(false);
    const [hasSchrodingerBuild, setHasSchrodingerBuild] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const { countries, regions } = useDesignAreaCountryRegions(selectedDesignAreaId);
    const { generateFlexNAPBOM } = useExport()
    const dispatch = useDispatch();

    const unselectDesignArea = useCallback((): void => {
        dispatch(selectDesignArea());
    }, [dispatch]);

    const onDeleteDesignArea = useCallback((): void => {
        if (selectedDesignArea) {
            deleteDesignArea(selectedDesignArea.id)(dispatch);
        }
        unselectDesignArea();
        showDeleteDialog(false);
    }, [dispatch, selectedDesignArea, unselectDesignArea]);

    const onDownloadClick = useCallback((): void => {
        if (selectedDesignArea) {
            dispatch(setDownloadState({
                buildIds: daBuilds.map((b) => b.id),
                designAreaNames: [selectedDesignArea.name ?? selectedDesignArea.projectId],
                designAreaIds: [selectedDesignArea.id]
            }));
            dispatch(showDialog(DialogType.DownloadGisData));
        }
    }, [daBuilds, dispatch, selectedDesignArea]);

    const onDownloadBOMClick = useCallback(() => {
        if (selectedDesignArea) {
            generateFlexNAPBOM(daFnapBuilds.map((b) => b.id));
        }
    }, [selectedDesignArea, generateFlexNAPBOM, daFnapBuilds]);

    const downloadBOM: Omit<IconButtonProps, "icon"> = {
        className: "download-bom",
        palette: "primary",
        token: "main",
        title: t(LocalizationKeys.DownloadBOM),
        placement: "bottom",
        onClick: onDownloadBOMClick,
        disabled: daFnapBuilds.length === 0
    }

    const onUpdateDesignArea = useCallback((value: any, property: string): void => {
        if (selectedDesignArea) {
            const newDa: DesignArea = { ...selectedDesignArea, [property]: value };
            updateDesignArea(selectedDesignArea, newDa)(dispatch);
            dispatch(selectDesignArea(newDa.id));
        }
    }, [dispatch, selectedDesignArea]);

    const toggleCollapsed = useCallback((collapsed: boolean): void => {
        setCollapsed(collapsed);
    }, [setCollapsed]);

    useEffect(() => {
        const key = cabinetIds.length > 1 ? LocalizationKeys.CabinetIds : LocalizationKeys.CabinetId;
        setCabinetLabel(t(key));
    }, [t, cabinetIds]);

    useEffect(() => {
        setHasFlexNapBuild(daBuilds.some((b) => b.type === BuildType.FlexNap));
    }, [daBuilds]);

    useEffect(() => {
        setHasSchrodingerBuild(daBuilds.some((b) => b.type === BuildType.Schrodinger));
    }, [daBuilds]);

    return {
        selectedDesignArea, selectedTool, parcelCount, cabinetIds,
        daBuilds, naps, taps, tethers,
        deleteDialog, showDeleteDialog,
        unselectDesignArea, onDeleteDesignArea, onUpdateDesignArea,
        cabinetLabel, hasFlexNapBuild, hasSchrodingerBuild,
        countries, regions,
        collapsed, toggleCollapsed,
        onDownloadClick,
        downloadBOM
    };
};

export const useDesignAreaInformation = (props: DesignAreaInformationProps) => {
    const { buildType } = useSelector((state: StateModel) => state.tool);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState(true);
    const [isProjectIdValid, setIsProjectIdValid] = useState(true);
    const [isValidCity, setIsValidCity] = useState(true);
    const [schrodingerMode] = useState(buildType === BuildType.Schrodinger);

    const isValidStringValue = useCallback((value: string): boolean => {
        return !!value && value.trim().length > 0;
    }, []);

    const validateDescription = useCallback((value: string): boolean => {
        const valid = isValidStringValue(value);
        setIsDescriptionValid(valid);
        return valid;
    }, [isValidStringValue]);

    const validateName = useCallback((value: string): boolean => {
        const valid = isValidStringValue(value);
        setIsNameValid(valid);
        return valid;
    }, [isValidStringValue]);

    const validateProjectId = useCallback((value: string): boolean => {
        const valid = isValidStringValue(value);
        setIsProjectIdValid(valid);
        return valid;
    }, [isValidStringValue]);

    const validateCity = useCallback((value: string): boolean => {
        const valid = isValidStringValue(value);
        setIsValidCity(valid);
        return valid;
    }, [isValidStringValue]);

    const updateDesignAreaInformationWithValidation = useCallback((value: string, property: string, validationMethod: (val: string) => boolean): void => {
        if (!schrodingerMode || validationMethod(value)) {
            props.onUpdate(value, property);
        }
    }, [props, schrodingerMode]);

    return {
        isDescriptionValid,
        isProjectIdValid,
        isValidCity,
        schrodingerMode,
        isNameValid,
        updateDesignAreaInformationWithValidation,
        validateDescription,
        validateProjectId,
        validateCity,
        validateName,
    };
}

export const useSelectedColorUpdated = (props: DesignAreaColorSelectorProps) => {
    const [selectedColor, setSelectedColor] = useState(props.color);

    useEffect(() => {
        setSelectedColor(props.color);
    }, [props.color]);

    const onColorUpdated = useCallback(() => {
        props.onApplyColor(selectedColor ?? "");
        props.onClose();
    }, [props, selectedColor]);

    return { selectedColor, onColorUpdated, setSelectedColor };
};

export const useBuildInfo = () => {
    const { t } = useTranslation();

    return useCallback((build: Build): string | null => {
        if (build.type === BuildType.FlexNap) {
            const flexnapBuild = (build as FlexNapBuild);
            return flexnapBuild ? t(LocalizationKeys.BuildFibers, { fiberCount: flexnapBuild.fiberCount }) : null;
        }
        else if (build.type === BuildType.Schrodinger) {
            const schrodingerBuild = (build as SchrodingerBuild);
            if (!schrodingerBuild) {
                return null;
            }
            return schrodingerBuild.subunitCount ? t(LocalizationKeys.BuildSubunits, { subunitCount: schrodingerBuild.subunitCount }) : '';
        }
        return null;
    }, [t]);
};

export const useColorSelector = () => {
    const [colorSelector, showColorSelector] = useState(false);

    return { colorSelector, showColorSelector };
};