import { Dispatch } from 'redux';
import { deleteFlexNapBuilds, undoDeleteFlexNapBuilds } from '../../../redux/build.state';
import { Command } from '../../command';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class DeleteBuildCommand extends Command {
    private buildIds: number[];
    private childPretermBuilds: FlexNapBuild[];

    constructor({ ids: buildIds, childPretermBuilds }) {
        super();
        this.buildIds = buildIds;
        this.childPretermBuilds = childPretermBuilds;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildDeleting };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildDeleting };
    }

    public undo(dispatch: Dispatch): void {
        undoDeleteFlexNapBuilds(this.buildIds, this.childPretermBuilds)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        deleteFlexNapBuilds(this.buildIds, this.childPretermBuilds, true)(dispatch)
        super.redo(dispatch);
    }
}