/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { FileHelper } from '../helpers/file-helper';
import { FileResult } from './file.result';
import { NotificationService } from './notification.service';

interface Header {
    name: string;
    value?: string;
    delegate?: () => string | null;
}

export enum WebServiceErrorHandlingBehavior {
    defaultNotifyOnError,
    rethrowError
}

export abstract class WebService {

    protected isUnstableApi = true;

    protected rootUrl = process.env.REACT_APP_MULESOFT_PROXY_ENDPOINT ?? '';
    protected client: AxiosInstance;
    private headers: Header[] = [];
    private errorHandlingBehavior = WebServiceErrorHandlingBehavior.defaultNotifyOnError;


    constructor() {
        if (this.rootUrl === '') {
            this.rootUrl = window.location.href.includes('corningstorefront') ? '' : '/';
        }
        this.client = axios.create();
        this.client.interceptors.request.use((config) => this.setHeaders(config));
    }

    public setErrorHandlingBehavior(errorHandlingBehavior: WebServiceErrorHandlingBehavior): void {
        this.errorHandlingBehavior = errorHandlingBehavior;
    }

    protected async get<T = unknown>(url: string): Promise<T | undefined> {
        const req = this.client.get<T>(url);
        return await this.wrap(req);
    }
    protected async post<T = unknown>(url: string, data?: unknown): Promise<undefined | T> {
        const req = this.client.post<T>(url, data);
        return await this.wrap(req);
    }

    protected async put<T = unknown>(url: string, data: unknown): Promise<T | undefined> {
        const req = this.client.put<T>(url, data);
        return await this.wrap(req);
    }

    protected async patch<T = unknown>(url: string, data: unknown): Promise<T | undefined> {
        const req = this.client.post<T>(url, data);
        return await this.wrap(req);
    }

    protected async delete<T = unknown>(url: string, data?: unknown): Promise<T | undefined> {
        const req = this.client.delete<T>(url, { data });
        return await this.wrap(req);
    }

    protected async postDownload(url: string, payload?: unknown): Promise<FileResult | undefined> {
        const result = await this.client.post(url, payload, { responseType: 'blob' });
        if (!result) {
            return undefined;
        }
        const filename = FileHelper.extractFilename(result.headers);
        return { filename, data: result.data };
    }

    protected async download(url: string): Promise<FileResult> {
        const result = await this.client.get(url);
        const filename = FileHelper.extractFilename(result.headers);
        return { filename, data: result.data };
    }

    protected async downloadBlob(url: string): Promise<Blob> {
        const result = await this.client.get(url, { responseType: 'blob' });
        return result.data;
    }

    private async wrap<T>(call: Promise<AxiosResponse<T>>): Promise<T | undefined> {
        try {
            const result = await call;
            return result.data;
        }
        catch (err) {
            const _err = err as any;
            switch (this.errorHandlingBehavior) {
                case WebServiceErrorHandlingBehavior.defaultNotifyOnError:
                    if (_err?.response?.data) {
                        const responseData = _err.response.data;
                        if (responseData && Object.prototype.toString.call(responseData) === "[object String]") {
                            NotificationService.error(responseData);
                        }
                    }
                    return;
                case WebServiceErrorHandlingBehavior.rethrowError:
                    throw _err;
            }
        }
    }

    protected addHeader(name: string, value: string): void {
        if (this.isHeaderAlreadyAdded(name)) {
            console.warn(`WebService.addHeader: Duplicate header name ${name}`);
            return;
        }
        this.headers.push({ name, value });
    }

    protected addDynamicHeader(name: string, delegate: () => string | null): void {
        if (this.isHeaderAlreadyAdded(name)) {
            console.warn(`WebService.addDynamicHeader: Duplicate header name ${name}`);
            return;
        }
        this.headers.push({ name, delegate });
    }

    private isHeaderAlreadyAdded(name: string): boolean {
        return this.headers.find((h) => h.name === name) !== undefined;
    }

    protected setHeaders(config: AxiosRequestConfig): AxiosRequestConfig {
        for (const header of this.headers) {
            const name = header.name;
            const value = header.value || (header.delegate && header.delegate());
            if (value === null || !value) {
                console.debug(`WebService.setHeaders: Skipping header '${name}' since value is null`);
                continue;
            }
            if (config?.headers) {
                config.headers[name] = value;
            }
        }
        return config;
    }
}
