import { Viewport } from 'pixi-viewport';
import { Point } from 'pixi.js';
import { MutableRefObject, PropsWithChildren } from 'react';

import { IViewportState } from '../../redux/viewport.state';

export const INCREMENT = 0.1;
export const VIEWPORT_X_BUFFER = 100;
export const VIEWPORT_Y_BUFFER = 200;

interface IViewportInstance {
    viewport?: Viewport
}

export interface IViewportComponentProps {
    viewportRef: MutableRefObject<Viewport | undefined>
}

export interface IViewportDragEvent {
    screen: Point;
    world: Point;
    viewport: Viewport;
}

export type ViewportProps = IViewportInstance & Partial<IViewportState> & PropsWithChildren<unknown>;