/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { updateFlexNapPretermLateral } from '../../../redux/build.state';
import { CpqService } from '../../../services/cpq.service';
import { NotificationService } from '../../../services/notification.service';
import { PretermPartsResult, FlexNapPretermProps } from './flexnap.types';

export const useFlexNapPretermProperties = (props: FlexNapPretermProps) => {
    const dispatch = useDispatch();

    const { build, disabled } = props;
    const pretermPartNumber = build.pretermLateral?.pretermPartNumber;
    const { connectorType, furcationType, legType, legTypes } = usePretermParts(build.partNumber, pretermPartNumber);

    const handleLegTypeChange = useCallback((value: any) => {
        updateFlexNapPretermLateral(build.id, value as string)(dispatch);
    }, [dispatch, build.id]);

    return {
        connectorType, furcationType, legType, legTypes, pretermPartNumber,
        handleLegTypeChange, disabled
    };
}

const usePretermParts = (cablePartNumber: string, pretermPart?: string): PretermPartsResult => {
    const [connectorType, setConnectorType] = useState("");
    const [furcationType, setFurcationType] = useState("");
    const [legType, setLegType] = useState("");
    const [legTypes, setLegTypes] = useState<string[]>([]);

    const resetStates = useCallback(() => {
        setConnectorType("");
        setFurcationType("");
        setLegType("");
        setLegTypes([]);
    }, []);

    const getPretermsForCablePartNumber = useCallback(async (cablePartNumber: string, pretermPart?: string) => {
        if (pretermPart) {
            const service = new CpqService();
            const pretermParts = await service.getPretermsForCablePartNumber(cablePartNumber)
            if (pretermParts) {
                const legTypes = pretermParts.map((p) => p.legType).distinct().sort() || [];
                setLegTypes(legTypes);

                const preterm = pretermParts.find((p) => p.preterm === pretermPart);
                setConnectorType(preterm?.connectorType ?? "");
                setFurcationType(preterm?.furcationType ?? "");
                setLegType(preterm?.legType ?? "");
            }
            else {
                resetStates();
                NotificationService.error("Could not load preterm part.");
            }
        }
    }, [resetStates]);

    useEffect(() => {
        getPretermsForCablePartNumber(cablePartNumber, pretermPart);
        return resetStates;
    }, [cablePartNumber, pretermPart, getPretermsForCablePartNumber, resetStates]);

    return { connectorType, furcationType, legType, legTypes };
}