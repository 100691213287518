import { FlexNapBuild } from '../models/flexnap-build';
import { Units } from '../models/units';
import { DownloadRequest } from '../components/dialogs/download-gis-data/download-gis-data-dialog.types';
import { SecuredService } from './abstract-secured-v2.service';
import { FileResult } from './file.result';

export class ExportService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/export';

    public async exportFlexNAPBuilds(buildIds: number[], units: Units): Promise<FileResult | undefined> {
        return this.postDownload(`${this.baseUrl}`, { buildIds, units });
    }

    public async generateFlexNAPBOM(buildIds: number[], units: Units): Promise<FileResult | undefined> {
        return this.postDownload(`${this.baseUrl}/bom`, { buildIds, units });
    }

    public getJsonFile(downloadRequest: DownloadRequest): Promise<FileResult | undefined> {
        return this.postDownload(`${this.baseUrl}/json`, downloadRequest);
    }

    public async exportToHybris(accountId: string, file: File): Promise<HybrisImportStatusResponse> {
        const fd = new FormData();
        fd.append('accountId', accountId);
        fd.append('importFile', file);
        const resp = await this.client.post(this.rootUrl + 'import', fd);
        return resp.data;
    }

    public async getExportStatus(importTaskId: string): Promise<HybrisImportStatusResponse> {
        const resp = await this.client.get('/corningstorefront/corning/en/USD/design-areas/imports/' + importTaskId);
        return resp.data;
    }

    public async getExportErrors(designAreaConfigurationId: string | number): Promise<any> {
        const resp = await this.client.get('/corningstorefront/corning/en/USD/design-areas/' + designAreaConfigurationId + '/import-log');
        return resp.data;
    }

    public async exportUpdatedData(designAreaConfigurationId: string | number): Promise<FlexNapBuild[] | undefined> {
        return this.post(this.rootUrl + 'imported-data/export-to-dna?projectUid=' + designAreaConfigurationId);
    }
}

export interface HybrisImportStatusResponse {
    completionPercentage: number;
    designAreaConfigurationId: any;
    cancelled: boolean;
    id: string;
    error: any;
    done: boolean;
    status: string;
}