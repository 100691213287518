import { store } from '../dna';
import { BuilderHelper } from '../helpers/build-helper';
import { SegmentHelper } from '../helpers/segment-helper';
import { FlexNapBuild } from '../models/flexnap-build';
import { MapClickEvent } from '../models/map-click-event';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { FeatureType } from '../openlayers/openlayer-feature.factory';
import { addNapTapTether } from '../redux/tap.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class NAPTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.NAP,
        name: 'NAP',
        shortcut: 'n',
    };

    public static allowedElementTypes: FeatureType[] = [
        InteractableFeatureType.HANDHOLE,
        InteractableFeatureType.MANHOLE,
        InteractableFeatureType.POLE,
        InteractableFeatureType.VAULT,
    ];

    public static execute(event: MapClickEvent): void {
        const selectedFeatures = event.items;
        if (!selectedFeatures) {
            return;
        }


        const elementId = selectedFeatures
            .filter((f) => this.allowedElementTypes.includes(f.type))
            .map((f) => f.id)
            .find((f) => !!f);
        if (!elementId || elementId < 1) {
            return;
        }

        const elementCoordinates = { x: event.x, y: event.y };

        const state = store.getState();
        const { segments, flexnapBuilds } = state.build;
        const { naps } = state.taps;
        const segmentsOnElement = SegmentHelper.getSegmentsOnElement(elementId, segments);
        const buildsOnElement = BuilderHelper.getBuildsFromSegments(flexnapBuilds, segmentsOnElement) as FlexNapBuild[];

        if (buildsOnElement?.length > 1 || naps.some(n => n.buildId === buildsOnElement[0]?.id && n.elementId === elementId)) {
            if (event.contextMenuCallback) {
                event.contextMenuCallback({
                    nap: {
                        elementId,
                        type: InteractableFeatureType.NAP,
                        isCluster: false,
                        elementCoordinates
                    },
                    draggingNap: false
                });
            }

            return;
        }

        this.createNap(elementId, buildsOnElement[0]?.id);
    }

    public static createNap(elementId: number, buildId?: number): void {
        const state = store.getState();

        const { currentWorkspace } = state.workspace;

        if (!currentWorkspace?.id) {
            return;
        }

        addNapTapTether(elementId, currentWorkspace.id, buildId)(store.dispatch);
    }
}
