import React, { useContext } from 'react';

import { TextButton } from '@orbit/button';

import { DrawerMenuContext, DrawerSelectionActionItemProps } from '../../../drawer-menu.types';

export const DrawerSelectionActionItem = ({ onClick, icon, disabled, label }: DrawerSelectionActionItemProps): JSX.Element => {
    const { setIsOptionsMenuOpen } = useContext(DrawerMenuContext);
    const handleClick = () => {
        setIsOptionsMenuOpen(false);
        onClick();
    };
    return <TextButton onClick={handleClick} startIcon={icon} disabled={disabled}>{label}</TextButton>
};
