/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';
import { PretermDiagramProps } from '../../nap-diagram.types';

export const usePretermDiagramSvg = (props: PretermDiagramProps) => {
    const { 
        pretermBuild, pretermTetherIds, tether,
        tapIndex, tetherIndex
    } = props;

    const getPretermIndex = (tapIndex: number, tetherIndex: number): number => {
        return tapIndex === 1 ? tetherIndex : tetherIndex + 2;
    };
    
    // Draw the Preterm box at the last Tether so that it is drawn over the other elements in the diagram
    const [showPretermBox, setShowPretermBox] = useState(pretermTetherIds && pretermTetherIds.length > 0 && tether.id === pretermTetherIds[pretermTetherIds.length - 1]);
    const [pretermIndex] = useState(getPretermIndex(tapIndex, tetherIndex));

    const { pretermWidth, pretermHeight, legTranslation, x, y, className } = usePretermSvg(tapIndex, pretermIndex, pretermTetherIds.length, showPretermBox);

    useEffect(() =>{
        setShowPretermBox(pretermTetherIds && pretermTetherIds.length > 0 && tether.id === pretermTetherIds[pretermTetherIds.length - 1]);
    }, [pretermTetherIds, tether]);

    return {
        pretermBuild, showPretermBox, pretermWidth, pretermHeight, legTranslation, x, y, className
    };
};

const usePretermSvg = (tapIndex: number, pretermIndex: number, width: number, showPretermBox: boolean) => {
    const [isLeftTap] = useState(tapIndex === 1);
    if (showPretermBox) {
        tapIndex = pretermIndex <= 3 ? 1 : 2;
        pretermIndex -= (width - 1);
    }
    const [pretermSpacing] = useState(80);
    const [pretermWidth, setPretermWidth] = useState(56 + (pretermSpacing * (width - 1)));
    const [legTranslation, setLegTranslation] = useState(22 + (showPretermBox ? pretermSpacing * (width - 1) : 0));
    const [pretermHeight] = useState(48);
    const [marginLeft, setMarginLeft] = useState(24 + (isLeftTap ? 0 : 14));
    const [x, setX] = useState(marginLeft + ((pretermIndex - 1) * pretermSpacing));
    const [y] = useState(175);
    const [className] = useState('preterm');

    useEffect(() => {
        setMarginLeft(24 + (isLeftTap ? 0 : 14));
    }, [isLeftTap]);

    useEffect(() => {
        setX(marginLeft + ((pretermIndex - 1) * pretermSpacing));
    }, [marginLeft, pretermSpacing, pretermIndex]);

    useEffect(() => {
        setLegTranslation(22 + (showPretermBox ? pretermSpacing * (width - 1) : 0));
        setPretermWidth(56 + (pretermSpacing * (width - 1)));
    }, [showPretermBox, pretermSpacing, width]);

    return { pretermWidth, pretermHeight, legTranslation, x, y, className };
};