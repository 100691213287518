import './build-actions-menu.scss';

import React from "react";

import { Menu } from "@mui/material";
import { IconButton } from "@orbit/icon-button";
import { TextButton } from "@orbit/button";
import { CloudDownload, MoreVert, Info } from "@mui/icons-material";

import { useBuildActionsMenu } from "./build-actions-menu-hooks";
import { BuildActionsMenuProps } from "./build-actions-menu.types";
import { SplicePlanIcon } from "./assets/splice-plan-icon";
import { DownloadBOMIcon } from "./assets/download-bom-icon";

export const BuildActionsMenu = (props: BuildActionsMenuProps) => {
    const { menu } = useBuildActionsMenu(props);
    return (
        <>
            <IconButton {...menu.icon} icon={<MoreVert />} />
            <Menu {...menu.props}>
                <div className="build-actions-menu">
                    <TextButton onClick={menu.options.gis.onClick} startIcon={<CloudDownload />}>
                        {menu.options.gis.label}
                    </TextButton>
                    <TextButton onClick={menu.options.splicePlan.onClick} startIcon={<SplicePlanIcon disabled={menu.options.splicePlan.disabled} />} disabled={menu.options.splicePlan.disabled}>
                        {menu.options.splicePlan.label}
                    </TextButton>
                    <TextButton onClick={menu.options.bom.onClick} startIcon={<DownloadBOMIcon disabled={menu.options.bom.disabled} />} disabled={menu.options.bom.disabled}>
                        {menu.options.bom.label}
                    </TextButton>
                    <TextButton onClick={menu.options.buildInfo.onClick} startIcon={<Info />} disabled={menu.options.buildInfo.disabled}>
                        {menu.options.buildInfo.label}
                    </TextButton>
                </div>
            </Menu>
        </>
    )
}