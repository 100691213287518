import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';
import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { UnitInterpreter } from '../../../../helpers/unit-interpreter';
import { LocalizationKeys } from '../../../../locales/types';
import { DesignMode } from '../../../../models/design-mode';
import { CardCollapsibleComponent } from '../../../card/card-collapsible.component';
import { CardPropertyInline } from '../../../card/card-controls';
import { InlineSelect } from '../../../ui-elements/inline-select';
import { CableLengthProperties } from '../../cable-length/cable-length-properties';
import { useSchrodingerCableComponent } from './schrodinger-cable-card.hooks';
import { SchrodingerCableProps } from './schrodinger-cable-card.types';

export const SchrodingerCableCard = (props: SchrodingerCableProps): JSX.Element | null => {

    const { t } = useTranslation();

    const { partNumber, length, displayUnits, build, subunitCount, collapsed, disabled, presetsOptions, configurationType, onDeleteCableClick } = useSchrodingerCableComponent(props);

    return (
        <CardCollapsibleComponent id="cable-info" label={t(LocalizationKeys.SchrodingerCable)} collapsed={collapsed}>
            <InlineSelect
                key={presetsOptions.name}
                label={presetsOptions.label}
                value={presetsOptions.value ? presetsOptions.value : presetsOptions.emptyChoice}
                values={presetsOptions.values?.map(p => p.id)}
                displayValues={presetsOptions.values?.map(p => p.name)}
                onUpdate={presetsOptions.onChange}
                disabled={presetsOptions.disabled}
            />
            {CardPropertyInline(t(LocalizationKeys.PartNumber), partNumber || `${t(LocalizationKeys.Undefined)}`)}
            {CardPropertyInline(t(LocalizationKeys.SubunitCount), subunitCount ?? `${t(LocalizationKeys.Undefined)}`)}
            {CardPropertyInline(t(LocalizationKeys.ConfigurationType), configurationType ?? `${t(LocalizationKeys.Undefined)}`)}
            {CardPropertyInline(t(LocalizationKeys.Length), length.value.toFixed(2), UnitInterpreter.toShort(displayUnits))}
            <CableLengthProperties build={build} />
            {build.designMode === DesignMode.GISMode && (
                <div className="card-footer">
                    <Tooltip arrow placement="bottom" title={t(LocalizationKeys.Delete).toLowerCase()}>
                        <div>
                            <TextButton palette={MainPalettes.error} disabled={disabled} onClick={onDeleteCableClick}>
                                <i className="material-icons">delete</i>
                            </TextButton>
                        </div>
                    </Tooltip>
                </div>
            )}
        </CardCollapsibleComponent>
    );
}
