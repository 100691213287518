import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ReviewStatus } from '../../../models/review-status';
import { finishReview } from '../../../redux/review.state';
import { useShowReviewBuild } from '../review-list.hooks';
import { ReviewListItemProps } from '../review-list.types';

export const usePendingReviewListItem = ({ review }: ReviewListItemProps) => {
    const dispatch = useDispatch();

    const { handleShowBuildClick } = useShowReviewBuild(review);

    const handleCancelReviewClick = useCallback(() => {
        dispatch(finishReview(review.id, ReviewStatus.Canceled));
    }, [dispatch, review.id]);

    return {
        ...review,
        handleCancelReviewClick,
        handleShowBuildClick
    };
};