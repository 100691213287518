import { Dispatch } from 'redux';
import { Command } from '../command';
import { undoExportFlexNapBuilds, redoExportFlexNapBuilds } from '../../redux/build.state';
import { FlexNapBuild } from '../../models/flexnap-build';
import { UndoRedoLocalizationKeys } from '../../locales/types';

interface ExportBuildPayload {
    builds: FlexNapBuild[];
}

export class ExportBuildCommand extends Command {
    private builds: FlexNapBuild[];
    private workspaceId: number;

    constructor({ builds }: ExportBuildPayload) {
        super();
        this.builds = builds;
        this.workspaceId = builds.length ? builds[0].workspaceId : -1;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildExport };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildExport };
    }

    public undo(dispatch: Dispatch): void {
        if (this.workspaceId > 0) {
            undoExportFlexNapBuilds(this.builds, this.workspaceId)(dispatch);
        }
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        if (this.workspaceId > 0) {
            redoExportFlexNapBuilds(this.builds, this.workspaceId)(dispatch);
        }
        super.redo(dispatch);
    }
}