import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { Tap } from '../../../models/schrodinger/tap';
import { deleteTether, undoDeleteTether } from '../../../redux/schrodinger/tap.state';
import { Command } from '../../command';

interface DeleteTetherCommandPayload {
    id: number;
    tap: Tap
}

export class DeleteTetherCommand extends Command {
    private id: number;
    private tap: Tap;

    constructor({ id, tap }: DeleteTetherCommandPayload) {
        super();
        this.id = id;
        this.tap = tap;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoTetherDeletion };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoTetherDeletion };
    }

    public undo(dispatch: Dispatch): void {
        undoDeleteTether(this.id, this.tap)(dispatch);
        super.undo(dispatch);
    }
    
    public redo(dispatch: Dispatch): void {
        deleteTether(this.id, this.tap, true)(dispatch);
        super.redo(dispatch);
    }
}