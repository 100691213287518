import '../../extensions/string.extension';

import React from 'react';
import i18next from 'i18next';
import { LocalizationKeys } from '../../locales/types';
import { ProjectionConverter } from '../../helpers/projection-converter';
import { zoomAt } from '../../redux/map.state';
import { connect, StateModel } from '../../redux/reducers';
import { NotificationService } from '../../services/notification.service';
import { AutoSuggestResult, SearchResult, SearchService } from '../../services/search.service';
import { ToolItem } from '../ui-elements/tool-item';


interface SearchComponentState {
    open: boolean;
    input: string;
    suggestionIndex: number;
    suggestions?: AutoSuggestResult[];
    lastInput?: string;
    results?: SearchResult[];
}

interface ISearchComponentProps {
    enabled?: boolean;
}

const mapStateToProps = (state: StateModel) => {
    const { viewport } = state.map;
    return { viewport };
};

const mapDispatchToProps = {
    zoomAt
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>> & ISearchComponentProps;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SearchComponent extends React.Component<props, SearchComponentState> {

    public state: SearchComponentState = {
        open: false,
        input: '',
        suggestionIndex: -1
    };

    private toggleSearch = (): void => {
        this.setState({ open: !this.state.open, input: '', results: undefined, suggestions: undefined, lastInput: undefined, suggestionIndex: -1 });
    }

    private search = (): void => {
        const { input, lastInput } = this.state;
        if (!input) {
            return;
        }
        if (input === lastInput) {
            return;
        }
        new SearchService()
            .search(input)
            .then((results) => {
                if (results) {
                    this.setState({ results, lastInput: input, suggestions: undefined });
                }
            })
            .catch(NotificationService.error);
    }

    private searchResultClicked = (result: SearchResult): void => {
        if (this.props.zoomAt) {
            this.props.zoomAt(result.bbox);
        }
    }

    private autosuggestClicked = (result: AutoSuggestResult): void => {
        if (result) {
            this.setState({ input: result.formattedAddress, suggestions: undefined });
        }
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { viewport } = this.props;
        const input = e.target.value;
        this.setState({ input, suggestions: undefined });
        if (!viewport || !input.length) {
            return;
        }

        const location = [
            (viewport[0] + viewport[2]) / 2, // longitude
            (viewport[1] + viewport[3]) / 2 // latitude
        ]

        new SearchService()
            .autocomplete(input, ProjectionConverter.convertMapToLatLong(location))
            .then((result) => {
                if (result)
                    this.setState({ suggestions: result });
            })
            .catch(NotificationService.error);
    }

    private handleSearchKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            this.search();
        }
        const { suggestions, suggestionIndex } = this.state;
        if (suggestions && e.key === 'ArrowDown') {
            const index = suggestionIndex < suggestions.length - 1 ? suggestionIndex + 1 : suggestionIndex;
            this.setState({ input: suggestions[index].formattedAddress, suggestionIndex: index });
        }
        if (suggestions && e.key === 'ArrowUp') {
            const index = suggestionIndex > 0 ? suggestionIndex - 1 : suggestionIndex;
            if (index < 0) {
                return;
            }
            this.setState({ input: suggestions[index].formattedAddress, suggestionIndex: index });
        }
    }

    public searchResultRow = (result: SearchResult, index: number): JSX.Element => {
        return (
            <div key={'result-' + index} className="result" onClick={this.searchResultClicked.bind(this, result)}>
                <span className="number">{index + 1}</span>
                <img src={require('./icons/icon_pin.svg')} alt="Location Pin" />
                <span className="label">{result.name}</span>
            </div>
        );
    }

    public autosuggestRow = (result: AutoSuggestResult, index: number): JSX.Element => {
        const { suggestionIndex } = this.state;
        return (
            <div key={'result-' + index} className={suggestionIndex === index ? "result selected" : "result"} onClick={this.autosuggestClicked.bind(this, result)}>
                <span className="number">{index + 1}</span>
                <img src={require('./icons/icon_pin.svg')} alt="Location Pin" />
                <span className="label">{result.address}</span>
            </div>
        );
    }

    public render(): JSX.Element | null {
        const { input, suggestions, open, results } = this.state;
        const { enabled } = this.props;

        if (!enabled && open) {
            this.toggleSearch();
        }

        if (!open) {
            return <ToolItem source={<img src={require('./icons/icon_search.svg')} alt="Search" />} onClick={this.toggleSearch} tooltip={i18next.t(LocalizationKeys.Search).toLowerCase()}/>;
        }

        let resultsElement: JSX.Element | null = null;

        if (results) {
            if (results.length) {
                resultsElement = (
                    <div className="search-results">
                        {results.map((r, idx) => this.searchResultRow(r, idx))}
                    </div>
                );
            }
            else {
                resultsElement = (
                    <div className="search-results">
                        <span className="zero-results">0 results for <span>{input}</span></span>
                    </div>
                );
            }
        }
        else if (suggestions) {
            if (suggestions.length) {
                resultsElement = (
                    <div className="search-results">
                        {suggestions.map((r, idx) => this.autosuggestRow(r, idx))}
                    </div>
                );
            }
        }

        return (
            <div>
                <div className="search">
                    <img className="search-btn" src={require('./icons/icon_search.svg')} alt="Search" onClick={this.search} />
                    <div className="input-with-suggest">
                        <input autoComplete="off" type="text" id="search-input" placeholder="Search for a location" value={input} onChange={this.handleInputChange} onKeyUp={this.handleSearchKeyUp} />
                    </div>
                    <img className="clear-btn" src={require('./icons/icon_clear.svg')} alt="Clear" onClick={this.toggleSearch} />
                </div>
                {resultsElement}
            </div>
        );
    }
}
