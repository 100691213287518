import '@orbit/select/build/index.css';

import React, { ChangeEvent, Component } from 'react';

import { OutlinedSelect, SelectItem } from '@orbit/select';
import { MainPalette, MainPalettes } from '@orbit/theme-provider';

interface SelectProps {
    palette?: MainPalette;
    label?: string;
    name: string;
    value: string;
    values: string[];
    displayValues?: string[];
    disabled?: boolean;
    dense?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export class Select extends Component<SelectProps> {
    public render() {
        const { palette = MainPalettes.primary, label, name, value, values, displayValues, disabled, dense, onChange } = this.props;

        return (
            <OutlinedSelect palette={palette} name={name} key={name} label={label} value={value} disabled={disabled} dense={dense} onChange={onChange}>
                {values.map((v, i) => <SelectItem key={i} value={v}>{displayValues ? displayValues[i] : v}</SelectItem>)}
            </OutlinedSelect>
        );
    }
}
