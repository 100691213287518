import React from 'react';

export const LoadWorkspaceIcon = () => {
    return (
        <div className="load-workspace-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g transform="translate(-632 -271)">
                    <circle fill="#005293" opacity="0.9" cx="20" cy="20" r="20" transform="translate(632 271)"/>
                    <g transform="translate(640 279)">
                        <path fill="none" d="M0,0H24V24H0Z"/>
                        <path fill="#fafafa" d="M19.35,10.04a7.492,7.492,0,0,0-14-2A6,6,0,0,0,6,20H19a4.986,4.986,0,0,0,.35-9.96ZM14,13v4H10V13H7l5-5,5,5Z"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}