export enum SnackbarTypes {
    Selection = "selection",
    Information = "info",
    Warning = "warning",
    Error = "error",
    Success = "success",
}

export interface GenericSnackbarProps {
    open?: boolean;
    type?: SnackbarTypes;
    hideIcon?: boolean;
    message?: string;
    actionLabel?: string;
    dismissIcon?: boolean;
    showProgress?: boolean;
    timeout?: number;
    onClose?: () => void;
    onAction?: () => void;
}

export interface GenericSnackbarAction {
    label: string;
    value?: string | number;
    onAction: (e: any) => void;
    onProperty?: string | number;
}