export enum SurveyType {
    Pole = 0,
    Parcel = 1,
    PoleSpan = 2,
    Manhole = 3,
    Handhole = 4,
    Vault = 5,
    Cabinet = 6,
    Trench = 7,
    Bore = 8,
}
