import { Dispatch } from '@reduxjs/toolkit';

import { Bore, BoreData } from '../models/bore';
import { HandholeData } from '../models/handhole';
import { ManholeData } from '../models/manhole';
import { Path } from '../models/path';
import { PathType } from '../models/path-type';
import { PointLike } from '../models/pointlike';
import { Trench, TrenchData } from '../models/trench';
import { VaultData } from '../models/vault';
import { NotificationService } from '../services/notification.service';
import { PathService } from '../services/path.service';
import { ManholeService } from '../services/manhole.service';
import { HandholeService } from '../services/handhole.service';
import { VaultService } from '../services/vault.service';
import { BoreService } from '../services/bore.service';
import { TrenchService } from '../services/trench.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { deleteBore, deleteImportedBores, getBores, loadBores, updateBore } from './bore.state';
import { updateSegments } from './build.state';
import { loadPoleSpans } from './pole.state';
import { updateTerminal } from './tap.state';
import { deleteImportedTrenches, deleteTrench, getTrenches, loadTrenches, updateTrench } from './trench.state';
import { getManholes } from './manhole.state';
import { getHandholes } from './handhole.state';
import { getVaults } from './vault.state';
import { IMPORT_LOAD_THRESHOLD } from './import.state';

export const loadPaths = (bbox: number[], required?: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new PathService();
        const paths = await service.getPaths(bbox, required);
        if (paths) {
            dispatch(loadPoleSpans(paths.poleSpans));
            dispatch(loadTrenches(paths.trenches));
            dispatch(loadBores(paths.bores));
        }
    };
};

export const unloadPaths = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(loadPoleSpans([]));
        dispatch(loadTrenches([]));
        dispatch(loadBores([]));
    };
};

export const deletePath = (path: Path) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new PathService();
        const successDeletePath = await requestIsSuccess(service, service.deletePath(path.id), true);
        if (successDeletePath) {
            switch (path.type) {
                case PathType.Bore:
                    dispatch(deleteBore(path.id));
                    break;
                case PathType.Trench:
                    dispatch(deleteTrench(path.id));
                    break;
            }
        }
    };
};

export const deleteImportedPaths = (importedGisDataId: number) => {
    return (dispatch: Dispatch): void => {
        dispatch(deleteImportedBores(importedGisDataId));
        dispatch(deleteImportedTrenches(importedGisDataId));
    };
};

export const updatePath = (path: Path, newPath: Path) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new PathService();
        const result = await service.updatePath(path, newPath);
        if (result?.path) {
            switch (result.path.type) {
                case PathType.Bore:
                    dispatch(updateBore(result.path as Bore));
                    break;
                case PathType.Trench:
                    dispatch(updateTrench(result.path as Trench));
                    break;
            }
        }
    };
};

export const updatePathSegments = (pathId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new PathService();
        const result = await service.updateRelatedSegments(pathId);
        if (result) {
            if (result.buildSegments) {
                dispatch(updateSegments(result.buildSegments));
            }
            if (result.terminals) {
                result.terminals.forEach(t => dispatch(updateTerminal(t)));
            }
            if (result.warningMessages) {
                result.warningMessages.forEach(m => NotificationService.infoPermanent(m));
            }
        }
    };
};

export const updatePathsConnectedToElement = (elementId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new PathService();
        const paths = await service.updateConnectedToElement(elementId);
        if (paths) {
            for (const path of paths) {
                switch (path.type) {
                    case PathType.Bore: dispatch(updateBore(path as Bore)); break;
                    case PathType.Trench: dispatch(updateTrench(path as Trench)); break;
                }
            }
        }
    }
}

export const updatePathCoordinates = (path: Path, route: PointLike[], fromElementId: number, toElementId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const service = new PathService();
        const result = await service.updatePathCoordinates({...path, route, fromElementId, toElementId});
        if (result) {
            result.type === PathType.Bore && dispatch(updateBore(result as Bore));
            result.type === PathType.Trench && dispatch(updateTrench(result as Trench));
        } else {
            // Reset path if update was not successful
            path.type === PathType.Bore && dispatch(updateBore({...path} as Bore));
            path.type === PathType.Trench && dispatch(updateTrench({...path} as Trench));
        }
    };
};

export const addPaths = (manholes?: ManholeData[], handholes?: HandholeData[], vaults?: VaultData[], bores?: BoreData[], trenches?: TrenchData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        let loadCount = 0;
        let manholesImported = false;
        if (manholes) {
            const service = new ManholeService();
            manholesImported = await requestIsSuccess(service, service.addManholes(manholes, importedGisId), true);
            loadCount += manholes.length;
        }
        let handholesImported = false;
        if (handholes) {
            const service = new HandholeService();
            handholesImported = await requestIsSuccess(service, service.addHandholes(handholes, importedGisId), true);
            loadCount += handholes.length;
        }
        let vaultsImported = false;
        if (vaults) {
            const service = new VaultService();
            vaultsImported = await requestIsSuccess(service, service.addVaults(vaults, importedGisId), true);
            loadCount += vaults.length;
        }
        let boresImported = false;
        if (bores) {
            const service = new BoreService();
            boresImported = await requestIsSuccess(service, service.addBores(bores, importedGisId), true);
            loadCount += bores.length;
        }
        let trenchesImported = false;
        if (trenches) {
            const service = new TrenchService();
            trenchesImported = await requestIsSuccess(service, service.addTrenches(trenches, importedGisId), true);
            loadCount += trenches.length;
        }
        if (loadCount <= IMPORT_LOAD_THRESHOLD && bbox) {
            if (manholesImported) {
                getManholes(bbox)(dispatch);
            }
            if (handholesImported) {
                getHandholes(bbox)(dispatch);
            }
            if (vaultsImported) {
                getVaults(bbox)(dispatch);
            }
            if (boresImported) {
                getBores(bbox)(dispatch);
            }
            if (trenchesImported) {
                getTrenches(bbox)(dispatch);
            }
        }
    });
};