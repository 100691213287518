import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LocalizationKeys } from "../../../locales/types";
import { loadCabinetSplicePlanFromCabinetId, PortMode, setPortMode } from "../../../redux/cabinet-splice-plan.state";
import { updateCabinetSplicePlanMode } from "../../../redux/cabinet.state";
import { DialogType, showDialog } from "../../../redux/overlay.state";
import { selectedCabinetSelector } from "../../../selectors/elements.selectors";
import {
    dialogSelector,
    selectedSplicePlanCabinetId,
    workspaceSelector,
} from "../../../selectors/root.selectors";

export interface CabinetPortModeDialogProps {
    isOpen?: boolean;
    title: string;
    selectedMode: PortMode;
    handleSelectModeChange: (event) => void;
    onSave: () => void;
    onClose: () => void;
}

export const useCabinetPortModeDialog = (): CabinetPortModeDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const dialog = useSelector(dialogSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const cabinetId = useSelector(selectedSplicePlanCabinetId);
    const cabinet = useSelector(selectedCabinetSelector);
    const portMode = cabinet && !cabinet.automaticSplicePlanMode ? PortMode.Manual : PortMode.Automatic;
    const [selectedMode, setSelectedMode] = useState(portMode);

    const isOpen = dialog === DialogType.CabinetPortMode;
    const title = t(LocalizationKeys.PortNumberSettings);

    const onSave = useCallback(() => {
        dispatch(setPortMode(selectedMode));
        if (currentWorkspace && cabinetId) {
            dispatch(updateCabinetSplicePlanMode(currentWorkspace.id, cabinetId, selectedMode));
            dispatch(loadCabinetSplicePlanFromCabinetId(currentWorkspace?.id, cabinetId));
        }
        dispatch(showDialog());
    }, [dispatch, selectedMode, currentWorkspace, cabinetId]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const handleSelectModeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = +event.currentTarget.value;
            if (cabinetId && currentWorkspace) {
                switch (value) {
                    case PortMode.Manual:
                        setSelectedMode(PortMode.Manual);
                        break;
                    default:
                        setSelectedMode(PortMode.Automatic);
                }
            }
        },
        [cabinetId, currentWorkspace]
    );

    return {
        isOpen,
        title,
        selectedMode,
        handleSelectModeChange,
        onSave,
        onClose,
    };
};
