import React from 'react';

import { TextButton } from '@orbit/button';

import { NotificationService } from '../../../../services/notification.service';
import { useDebugTools } from './debug-tools.hooks';

export const DebugTools = (): JSX.Element => {
    const {
        toggleShowErrorsAndWarningsText,
        email,
        isAuthorizedToManageRoles,
        buildDate,
        showSelectedBuildOrTapSessionId,
        exportSelectedBuildSessionAsCFG,
        handleToggleShowErrorsClick,
        handleTurnOnAutoDropCableClick,
        handleLogoutClick,
        resetWorkspaces,
        resetGis,
        uploadToConfigurator,
        removeAllPresets,
        removeAllDefaultPresets,
        showRoleManagementTool,
        downloadSessionJson,
        endSession,
    } = useDebugTools();

    return (
        <div className="debugmenu">
            <TextButton className="negative" onClick={showSelectedBuildOrTapSessionId}>SessionId: Show for Selected Build or Tap</TextButton>
            <TextButton className="negative" onClick={exportSelectedBuildSessionAsCFG}>Export build session as CSV</TextButton>
            <TextButton className="negative" onClick={downloadSessionJson}>Download Session JSON for Selected Build</TextButton>
            <TextButton className="negative" onClick={endSession}>End Session for Selected Build</TextButton>
            <TextButton className="negative" onClick={(): void => NotificationService.success('This is a success message')}>Show success popup</TextButton>
            <TextButton className="negative" onClick={(): void => NotificationService.info('This is an information message')}>Show information popup</TextButton>
            <TextButton className="negative" onClick={(): void => NotificationService.warning('This is a warning message')}>Show warning popup</TextButton>
            <TextButton className="negative" onClick={(): void => NotificationService.error('This is an error message. Error message error message error-message errormessage. Error message?')}>Show error popup</TextButton>
            <TextButton className="negative" onClick={handleToggleShowErrorsClick}>{toggleShowErrorsAndWarningsText}</TextButton>
            <TextButton className="negative" onClick={handleTurnOnAutoDropCableClick}>Toggle auto dropcable (experiment)</TextButton>
            <TextButton className="negative" onClick={handleLogoutClick}>Logout {email}</TextButton>
            <TextButton className="negative" onClick={resetWorkspaces}>Reset my workspaces</TextButton>
            <TextButton className="negative" onClick={resetGis}>Reset the GIS data</TextButton>
            <TextButton className="negative" onClick={uploadToConfigurator}>Upload to configurator</TextButton>
            <TextButton className="negative" onClick={removeAllPresets}>Remove All Presets</TextButton>
            <TextButton className="negative" onClick={removeAllDefaultPresets}>Remove All Default Presets</TextButton>
            {isAuthorizedToManageRoles && <TextButton className="negative" onClick={showRoleManagementTool}>Manage roles</TextButton>}
            <label style={{ color: 'lightgray' }}>Built {buildDate}</label>
        </div>
    );
};
