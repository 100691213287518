import { Bore, BoreData } from '../models/bore';
import { SecuredService } from './abstract-secured-v2.service';
import { AddPathRequest } from './trench.service';

export class BoreService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/bore';

    public getAll(): Promise<Bore[] | undefined> {
        return this.get<Bore[]>(this.baseUrl);
    }

    public getBores(bbox: number[], required?: number[]): Promise<Bore[] | undefined> {
        return this.post<Bore[]>(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public addBore(request: AddPathRequest): Promise<Bore | undefined> {
        return this.post(this.baseUrl, request);
    }

    public addBores(data: BoreData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/import`, { data, importedGisDataId });
    }

    public deleteBore(id: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${id}`);
    }
}
