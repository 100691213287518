import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Build } from '../models/build';
import { BuildType } from '../models/build-type';
import { ReviewStatus } from '../models/review-status';
import { SchrodingerBuild } from '../models/schrodinger-build';
import { SchrodingerReferenceType } from '../models/schrodinger/reference-id';
import { allBuildsSelector } from '../selectors/build.selectors';
import {
    authenticationSelector, awaitingSchrodingerSessionUpdatesSelector
} from '../selectors/root.selectors';


export const useLockPermission = () => {
    const { userId } = useSelector(authenticationSelector);
    const awaitingSchrodingerSessionUpdates = useSelector(awaitingSchrodingerSessionUpdatesSelector);
    const builds = useSelector(allBuildsSelector);

    const lockToggleDisabled = useCallback((build: Build) => {
        if (build.type === BuildType.Schrodinger) {
            const schrodingerBuild = (build as SchrodingerBuild);
            if (schrodingerBuild.catalogCode) return true;

            if (schrodingerBuild.lastReviewStatus === ReviewStatus.Open) return true;

            const awaitingSessionUpdate = awaitingSchrodingerSessionUpdates
                .find(({ id, type }) => type === SchrodingerReferenceType.Build && id === build.id);
            if (awaitingSessionUpdate) return true;
        }

        return false;
    }, [awaitingSchrodingerSessionUpdates]);

    const lockButtonDisabled = useCallback((buildId: number) => {
        const build = builds.find(({ id }) => id === buildId);
        if (!build) return true;

        return lockToggleDisabled(build) ? true : !!build.lockedById;
    }, [builds, lockToggleDisabled]);

    const unlockButtonDisabled = useCallback((buildId: number) => {
        const build = builds.find(({ id }) => id === buildId);
        if (!build) return true;

        return lockToggleDisabled(build) ? true : !build.lockedById || build.lockedById !== userId;
    }, [builds, lockToggleDisabled, userId]);

    return { lockButtonDisabled, unlockButtonDisabled };
};
