import './preset-row.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { usePresetRow } from './preset-row.hooks';
import { PresetRowProps } from './preset-row.types';
import { TooltipDark } from './tooltip-dark';

export const PresetRow = (props: PresetRowProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        name, icon, isDefault, className, createdByUserName, onSelect, onSetAsDefault, ref
    } = usePresetRow(props);

    return (
        <li className={className} onClick={onSelect} ref={ref}>
            <img alt="Preset Icon" className="preset-icon" src={icon} />
            <div className="preset-container">
                <label className="subheader">{name}</label>
                {createdByUserName ? <label className="caption">{t(LocalizationKeys.CreatedBy, { createdBy: createdByUserName })}</label> : null}
            </div>
            {isDefault
                ? <i className="material-icons default-icon">check_circle</i>
                : <TooltipDark arrow={true} placement="bottom" title={t(LocalizationKeys.SetAsDefault)?.toLowerCase()}>
                    <div className="set-as-default" onClick={onSetAsDefault} />
                </TooltipDark>
            }
        </li>
    );
};
