import i18next from 'i18next';
import React from 'react';

import { LocalizationKeys } from '../../locales/types';
import { Build } from '../../models/build';
import { ReviewStatus } from '../../models/review-status';
import { SchrodingerBuild } from '../../models/schrodinger-build';
import { unlockBuilds, unlockBuildsEs } from '../../redux/build.state';
import { DialogType, showDialog } from '../../redux/overlay.state';
import { connect, StateModel } from '../../redux/reducers';
import { selectBuild, selectBuilds } from '../../redux/selection.state';
import { DialogComponent } from './dialog.component';

const mapStateToProps = (state: StateModel) => {
    const { flexnapBuilds, schrodingerBuilds } = state.build;
    const { dialog } = state.overlay;
    const { selectedBuildId, selectedBuildIds } = state.selection;
    const builds: Build[] = [...flexnapBuilds, ...schrodingerBuilds];
    const { currentWorkspace } = state.workspace;
    return { dialog, selectedBuildId, selectedBuildIds, builds, currentWorkspace };
};

const mapDispatchToProps = {
    showDialog,
    selectBuild,
    selectBuilds,
    unlockBuilds,
    unlockBuildsEs
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class UnlockBuildDialog extends React.Component<props> {

    private unlockBuild = () => {
        const { selectedBuildId, selectedBuildIds, currentWorkspace } = this.props;
        if (currentWorkspace) {
            if (selectedBuildIds) {
                this.props.unlockBuildsEs!(selectedBuildIds, currentWorkspace.id);
                this.props.selectBuilds!();
            } else if (selectedBuildId) {
                this.props.unlockBuilds!([selectedBuildId], currentWorkspace.id);
            }
        }
        this.props.showDialog!();
    }

    private close = () => {
        const { selectedBuildIds } = this.props;
        if (selectedBuildIds) {
            this.props.selectBuilds!();
        } else {
            this.props.selectBuild!();
        }
        this.props.showDialog!();
    }

    private getTitle = (build: Build | undefined): string => {
        if (build?.uploadedDateTime)
            return i18next.t(LocalizationKeys.ExportedBuild, { selectedBuildId: build.id });
        if (build && (build as SchrodingerBuild).lastReviewStatus === ReviewStatus.Rejected)
            return i18next.t(LocalizationKeys.RejectedBuild, { selectedBuildId: build.id });
        if (this.props.selectedBuildIds)
            return i18next.t(LocalizationKeys.UnlockBuilds);
        return i18next.t(LocalizationKeys.UnlockBuild, { selectedBuildId: build?.id ?? -1 });
    }

    private renderMessage = (build: Build | undefined) => {
        const messageStyle = {
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '8px 0 8px 25px',
            fontSize: '1.25rem'
        };
        if (this.props.selectedBuildIds)
            return <div style={messageStyle}>{this.props.selectedBuildIds.join(', ')}</div>;
        if (build && (build as SchrodingerBuild).lastReviewStatus === ReviewStatus.Rejected) // TODO or exported
            return <div style={messageStyle}>Unlock the build?</div>;
        return null;
    }

    public render() {
        const { dialog, selectedBuildId, selectedBuildIds, builds } = this.props;
        const build = builds?.find(b => b.id === selectedBuildId);

        return (
            <DialogComponent
                open={dialog === DialogType.UnlockBuild && (selectedBuildId! > 0 || !!selectedBuildIds?.length)}
                title={this.getTitle(build)}
                positiveTitle="Unlock"
                negativeTitle="Cancel"
                onPositiveClick={this.unlockBuild}
                onNegativeClick={this.close}
                onClose={this.close}
            >
                {this.renderMessage(build)}
            </DialogComponent>
        );
    }
}
