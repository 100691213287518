import { FlexNapSplicePlan } from '../models/flexnap-splice-plan';
import { SecuredService } from './abstract-secured-v2.service';

export class FlexNapSplicePlanService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/flexnapspliceplan';

    public getSplicePlan(buildId: number): Promise<FlexNapSplicePlan | undefined> {
        return this.get(`${this.baseUrl}/build/${buildId}/locations`);
    }

    public loadWorkspaceSplicePlans(workspaceId: number): Promise<FlexNapSplicePlan[] | undefined> {
        return this.post(`${this.baseUrl}/workspace/${workspaceId}`);
    }

    public updateSplicePlan(request: UpdateSplicePlanRequest): Promise<FlexNapSplicePlan | undefined> {
        return this.post(`${this.baseUrl}`, request);
    }

    public updatePretermBuildsSplicePlans(parentBuildId: number, request: UpdatePretermBuildsSplicePlanRequest): Promise<FlexNapSplicePlan[] | undefined> {
        return this.post(`${this.baseUrl}/parentbuild/${parentBuildId}`, request);
    }

    public deleteSplicePlan(buildId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/build/${buildId}`);
    }
}

export interface UpdateSplicePlanRequest {
    buildId: number;
    fiberAssignment?: number;
    startingPort?: number;
    includeUnusedFibers: boolean;
}

export interface UpdatePretermBuildsSplicePlanRequest {
    updatedParentBuildStartingPort?: number;
}