import React from 'react';

import { Container, Graphics } from '@inlet/react-pixi';

import { IFrameProps } from '../build/build.types';
import { useToolbar } from './toolbar.hooks';

export const Toolbar: React.FC<IFrameProps> = ({ position, children }) => {
    const numberOfChildren = React.Children.count(children);
    const {
        xPosition, yPosition,
        drawBackground
    } = useToolbar(position, numberOfChildren);

    return <Container position={{ x: xPosition, y: yPosition }}>
        <Graphics draw={drawBackground} />
        { children }
    </Container>;
};