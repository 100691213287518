import i18next from 'i18next';

import { CabinetSplicePlanHelper } from '../../helpers/cabinet-splice-plan-helper';
import { LocalizationKeys } from '../../locales/types';
import { CabinetSplicePlan } from '../../models/cabinet-splice-plan';
import { CabinetData } from '../data/cabinet-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class CabinetSplicePlanPortValidator implements Validator<CabinetData> {

    private cabinetSplicePlans: { [cabinetId: number]: CabinetSplicePlan };

    constructor(splicePlans: { [cabinetId: number]: CabinetSplicePlan }) {
        this.cabinetSplicePlans = splicePlans;
    }

    public validate(cabinet: CabinetData): ValidationResult[] | null {
        const cabinetSplicePlan = this.cabinetSplicePlans[cabinet.id];

        if (!cabinetSplicePlan) {
            return null;
        }

        const { builds } = cabinetSplicePlan;
        const buildInfos = CabinetSplicePlanHelper.buildsToBuildInfos(builds, 0, 1, cabinet.portCount);

        const results: ValidationResult[] = [];
        for (const buildInfo of buildInfos) {
            if (buildInfo.overlap) {
                results.push({
                    buildId: buildInfo.build.buildId,
                    itemId: cabinet.id,
                    itemType: ItemType.Cabinet,
                    shortDescription: i18next.t(LocalizationKeys.CabinetOverlappingPortNumbers),
                    type: ValidationResultType.Warning,
                    extent: cabinet.getExtent()
                })
            }
        }

        return results;
    }

    public canValidate(entity: any): boolean {
        return entity instanceof CabinetData;
    }
}
