import { DesignAreaHelper } from '../../helpers/design-area-helper';
import { PointLike } from '../../models/pointlike';
import { BuildData } from '../data/build-data';
import { SegmentData } from '../data/segment-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class DesignAreaBuildValidator implements Validator<BuildData> {

    public validate(build: BuildData): ValidationResult | null {
        const designAreas = build.designAreas;
        if (designAreas.length > 1) {
            return this.buildValidationResult(build, `Build ${build.id} is in more than one DA.`, ValidationResultType.Error);
        }
        for (const da of designAreas) {
            const polygon = da.polygon;
            if (build.segments.some((s) => !this.isSegmentInPolygon(s, polygon))) {
                return this.buildValidationResult(build, `For Build ${build.id}, not all points are within DA.`, ValidationResultType.Warning);
            }
        }
        return null;
    }

    public canValidate(entity: unknown): boolean {
        return entity instanceof BuildData && entity.designAreas.length > 0;
    }

    private buildValidationResult(build: BuildData, message: string, type: ValidationResultType): ValidationResult {
        const extent = build.getExtent();
        return { buildId: build.id, itemId: null, itemType: ItemType.Build, shortDescription: message, type, extent };
    }

    private isSegmentInPolygon(segment: SegmentData, polygon: PointLike[]): boolean {
        return DesignAreaHelper.isPointInsidePolygon(segment.from.location, polygon) && DesignAreaHelper.isPointInsidePolygon(segment.to.location, polygon)
    }
}
