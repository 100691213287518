import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesignMode } from '../../models/design-mode';
import { Review } from '../../models/review';
import {
    loadNewCanvasBuildSpans, setDisplayedBuildId, setSchrodingerBuildsLoaded
} from '../../redux/build.state';
import { zoomToBuild } from '../../redux/map.state';
import { showControl, showDialog } from '../../redux/overlay.state';
import { changeWorkspace, updateLastUsedDesignMode } from '../../redux/workspace.state';
import {
    designerReviewRequestsSelector,
    reviewerResolvedReviewsSelector, designerResolvedReviewsSelector,
    reviewerReviewRequestsSelector,
    resolvedReviewsSelector
} from '../../selectors/review.selectors';
import { designModeSelector, workspaceSelector } from '../../selectors/root.selectors';
import { ReviewListTab } from './review-list.types';

const submissionTimeSort = (a: Review, b: Review) => new Date(a.submissionDateTime).getTime() - new Date(b.submissionDateTime).getTime();
const completionTimeSort = (a: Review, b: Review) => new Date(b.completionDateTime ?? b.submissionDateTime).getTime() - new Date(a.completionDateTime ?? a.submissionDateTime).getTime();

export const useReviewList = () => {
    const dispatch = useDispatch();

    const designerRequestedReviews = useSelector(designerReviewRequestsSelector);
    const reviewerRequestedReviews = useSelector(reviewerReviewRequestsSelector);
    const resolvedRequests = useSelector(reviewerResolvedReviewsSelector);
    const archivedReviews = useSelector(resolvedReviewsSelector);
    const newlyResolvedReviews = useSelector(designerResolvedReviewsSelector);

    const [tab, setTab] = useState(ReviewListTab.Request);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);

    const resolved = newlyResolvedReviews.sort(completionTimeSort);
    const requests = [...reviewerRequestedReviews, ...resolvedRequests].sort(submissionTimeSort);
    const pending = designerRequestedReviews.sort(submissionTimeSort);
    let archive = archivedReviews.sort(completionTimeSort);
    if (searchValue.length > 0) {
        archive = archivedReviews.filter(r => r.buildId.toString().includes(searchValue)).sort(completionTimeSort);
    }
    const itemsPerPage = 3;
    const numPages = Math.ceil(archive.length / itemsPerPage);

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: ReviewListTab) => {
        setTab(newValue);
    }, []);

    const handleCloseClick = useCallback(() => {
        dispatch(showControl());
    }, [dispatch]);

    const handleSearchInputChange = useCallback((event) => {
        const id: string = event.target.value;
        setSearchValue(id);
        setPage(1);
    }, []);

    const clearSearch = useCallback(() => {
        setSearchValue("");
        setPage(1);
    }, []);

    const handlePageChange = useCallback((event, value) => {
        setPage(value);
    }, []);

    return {
        tab,
        resolved,
        requests,
        archive,
        pending,
        searchValue,
        page,
        numPages,
        itemsPerPage,
        handleTabChange,
        handleCloseClick,
        handleSearchInputChange,
        clearSearch,
        handlePageChange
    };
};

export const useShowReviewBuild = (review: Review) => {
    const dispatch = useDispatch();

    const designMode = useSelector(designModeSelector);
    const { currentWorkspace, workspaces } = useSelector(workspaceSelector);
    const currentWorkspaceId = currentWorkspace?.id;
    const newWorkspace = workspaces.find((w) => w.id === review?.workspaceId);

    const handleShowBuildClick = useCallback(() => {
        if (review.designMode !== designMode) {
            dispatch(updateLastUsedDesignMode(review.designMode));
        }
        if (newWorkspace && currentWorkspaceId !== newWorkspace.id) {
            dispatch(setSchrodingerBuildsLoaded());
            dispatch(showDialog());
            dispatch(loadNewCanvasBuildSpans());
            dispatch(changeWorkspace(newWorkspace, false));
        }
        dispatch(showControl());

        if (review.designMode === DesignMode.GISMode) {
            dispatch(zoomToBuild(review.buildId));
        }
        else if (review.designMode === DesignMode.CanvasMode) {
            dispatch(setDisplayedBuildId(review.buildId));
        }
    }, [currentWorkspaceId, designMode, dispatch, newWorkspace, review.buildId, review.designMode]);

    return {
        handleShowBuildClick
    }
}