import React from 'react';

export const PoleIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <defs>
                <clipPath id="circle-clip">
                    <circle cx="50%" cy="50%" r="11" />
                </clipPath>
            </defs>
            <circle cx="50%" cy="50%" r="10" stroke="#005293" fill="none" strokeWidth="3" />
            <line x2="24" y2="24" fill="none" stroke="#005293" strokeWidth="2" clipPath="url(#circle-clip)" />
            <line x1="24" y2="24" fill="none" stroke="#005293" strokeWidth="2" clipPath="url(#circle-clip)" />
        </svg>
    );
};
