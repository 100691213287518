import './tether-svg.scss';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { TetherType } from '../../../models/schrodinger/tether-type';
import { powerDeviceSvg } from '../device-svgs/power-device-svg';
import { terminalPlaceholderSvg } from '../device-svgs/terminal-placeholder-svg';
import { useTetherSvg } from './tether-svg.hooks';
import { TetherSvgProps } from './tether-svg.types';

export const TetherSvgDefs = (
    <>
        <filter id="editIconShadow" filterUnits="userSpaceOnUse">
            <feOffset dy="3" in="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="d" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="d" />
            <feComposite in="SourceGraphic" />
        </filter>
        <filter id="powerDeviceShadow" filterUnits="userSpaceOnUse">
            <feOffset dy="3" in="SourceAlpha" />
            <feGaussianBlur stdDeviation="4.5" result="b" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="b" />
            <feComposite in="SourceGraphic" />
        </filter>
    </>
);

export const TetherSvg = (props: TetherSvgProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        pagePosition, showEditIcon, backdropClass, subunitType, labelText, tetherTypeClass, index,
        handleMouseEnter, handleMouseLeave, handleClick
    } = useTetherSvg(props);

    return (
        <g className={classNames({ 'tether-svg': showEditIcon })} transform={`translate(${72 * pagePosition})`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
            {backdrop(backdropClass)}
            {subunitType === TetherType.Optical
                ? terminalPlaceholderSvg(t(LocalizationKeys.NoTerminalLine1), t(LocalizationKeys.NoTerminalLine2))
                : powerDeviceSvg()}
            {tether(pagePosition, tetherTypeClass)}
            {connector(tetherTypeClass)}
            {indexLabel(index)}
            {showEditIcon ? editIcon(tetherTypeClass) : labelText ? infoIcon(labelText) : null}
        </g>
    );
};

const backdrop = (backdropClass: string | null) => (
    <rect className={classNames('backdrop', backdropClass)} x="32" y="63" width="72" height="272" rx="4" />
);

const tether = (pagePosition: number, tetherTypeClass: string): JSX.Element | undefined => (
    <g className={classNames('tether', tetherTypeClass)} transform={tetherTransform(pagePosition)}>
        <path className="outline" d={tetherOutlinePath(pagePosition)} />
        <path className="fill" d={tetherFillPath(pagePosition)} />
    </g>
);

const tetherTransform = (pagePosition: number): string | undefined => {
    if (pagePosition === 0) return "translate(-16, -81)";
    if (pagePosition === 1) return "translate(-88, -81)";
    if (pagePosition === 2) return "translate(-124, -81)";
    if (pagePosition === 3) return "translate(-124, -81)";
    return undefined;
};

const tetherOutlinePath = (pagePosition: number): string | undefined => {
    if (pagePosition === 0) return "M84,217V155.675a5.855,5.855,0,0,1,1.149-2.9,6.692,6.692,0,0,1,2.8-1.654L192,128";
    if (pagePosition === 1) return "M192,128l-32.159,21.315a14.722,14.722,0,0,0-3.018,3.228,6.044,6.044,0,0,0-.823,3.438V217";
    if (pagePosition === 2) return "M155.986,128l32.159,21.315a14.722,14.722,0,0,1,3.018,3.228,6.044,6.044,0,0,1,.823,3.438V217";
    if (pagePosition === 3) return "M192,217V155.675a5.855,5.855,0,0,0-1.149-2.9,6.692,6.692,0,0,0-2.8-1.654L84,128";
    return undefined;
};

const tetherFillPath = (pagePosition: number): string | undefined => {
    if (pagePosition === 0) return "M84,217V155.675a5.855,5.855,0,0,1,1.149-2.9,6.692,6.692,0,0,1,2.8-1.654L192,128";
    if (pagePosition === 1) return "M192,128l-32.159,21.315a14.722,14.722,0,0,0-3.018,3.228,6.044,6.044,0,0,0-.823,3.438V217";
    if (pagePosition === 2) return "M155.986,128l32.159,21.315a14.722,14.722,0,0,1,3.018,3.228,6.044,6.044,0,0,1,.823,3.438V217";
    if (pagePosition === 3) return "M192,217V155.675a5.855,5.855,0,0,0-1.149-2.9,6.692,6.692,0,0,0-2.8-1.654L84,128";
    return undefined;
};

const connector = (tetherTypeClass: string): JSX.Element => (
    <g className={classNames('connector', tetherTypeClass)} transform="translate(62, 136)">
        <path className="fill" d="M3,0H9a3,3,0,0,1,3,3v7a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" />
        <path className="outline" d="M3,.5H9A2.5,2.5,0,0,1,11.5,3V9a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,9V3A2.5,2.5,0,0,1,3,.5Z" />
    </g>
);

const editIcon = (tetherTypeClass: string): JSX.Element => (
    <g className="edit-icon">
        <circle cx="68" cy="105" r="18" className={tetherTypeClass} />
        <path transform="translate(56, 93)" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z" />
    </g>
);

const infoIcon = (labelText: string): JSX.Element => (
    <g className="info-icon">
        <circle cx="68" cy="105" r="18" />
        <text x="68" y="105" textAnchor="middle" dominantBaseline="central">{labelText}</text>
    </g>
);

const indexLabel = (index: number): JSX.Element => (
    <text className="index-label" x="68" y="320" textAnchor="middle" dominantBaseline="central">{index}</text>
);
