import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserPreset, UserPresetData } from '../models/userpreset';
import { NotificationService } from '../services/notification.service';
import { LoadAllPresetsResult, PresetService } from '../services/preset.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';

export interface PresetState {
    selectedPreset?: UserPreset;
    presets: UserPreset[];
    loaded: boolean;
}

const initialState: PresetState = {
    selectedPreset: undefined,
    presets: [],
    loaded: false,
};

const loadPresetsReducer = (state: PresetState, action: PayloadAction<LoadAllPresetsResult>) => {
    const { presets, defaultPresetId } = action.payload;
    state.presets = presets?.map((p) => ({ ...p, isDefault: p.id === defaultPresetId })) ?? [];
    state.loaded = true;
};

const addPresetReducer = (state: PresetState, action: PayloadAction<UserPreset>) => {
    const preset: UserPreset = action.payload;
    if (preset.isDefault) {
        for (const otherPreset of state.presets) {
            otherPreset.isDefault = false;
        }
    }
    if (!state.presets.some((p) => p.id === preset.id)) {
        state.presets.push(preset);
    }
};

const selectPresetReducer = (state: PresetState, action: PayloadAction<UserPreset | undefined>) => {
    state.selectedPreset = action.payload;
};

const selectDefaultPresetReducer = (state: PresetState, _: PayloadAction<undefined>) => {
    const defaultPreset = state.presets.find((p) => p.isDefault);
    state.selectedPreset = defaultPreset;
};

const deletePresetReducer = (state: PresetState, action: PayloadAction<number>) => {
    state.presets = state.presets.filter((p) => p.id !== action.payload);
    if (state.selectedPreset?.id === action.payload) {
        state.selectedPreset = undefined;
    }
};

const updatePresetReducer = (state: PresetState, action: PayloadAction<UserPreset>) => {
    const preset = action.payload;
    const pIdx = state.presets.findIndex((p) => p.id === preset.id);
    const defaultPreset = state.presets.find((p) => p.isDefault);
    if (defaultPreset?.id === preset.id) {
        preset.isDefault = true;
    }
    if (pIdx < 0) {
        state.presets.push(preset);
    }
    else {
        state.presets[pIdx] = preset;
    }
    state.selectedPreset = preset;
};

const setDefaultPresetReducer = (state: PresetState, action: PayloadAction<number | undefined>) => {
    state.presets.forEach((p) => p.isDefault = false);
    const defaultPreset = state.presets.find((p) => p.id === action.payload);
    if (defaultPreset) {
        defaultPreset.isDefault = true;
    }
};

const notifyPresetAppliedToBuildReducer = (state: PresetState, action: PayloadAction<{ presetId: number; buildId: number }>) => {
    const { presetId, buildId } = action.payload;
    const preset = state.presets.find(p => p.id === presetId);
    if (preset) {
        NotificationService.success(`${preset.name} applied to the build ${buildId}`);
    }
}

const { actions, reducer } = createSlice({
    name: 'preset',
    initialState,
    reducers: {
        loadPresets: loadPresetsReducer,
        addPreset: addPresetReducer,
        selectPreset: selectPresetReducer,
        selectDefaultPreset: selectDefaultPresetReducer,
        deletePreset: deletePresetReducer,
        updatePreset: updatePresetReducer,
        setDefaultPreset: setDefaultPresetReducer,
        notifyPresetAppliedToBuild: notifyPresetAppliedToBuildReducer,
    },
});

export { reducer as PresetReducer };
export const { addPreset: addPresetAction, updatePreset: updatePresetAction, deletePreset: deletePresetAction } = actions;

export const loadPresets = () => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new PresetService();
        const res = await service.getAll();
        if (res) {
            dispatch(actions.loadPresets(res));
        }
    });
};

export const addPreset = (preset: UserPresetData) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new PresetService();
        const addedPreset = await service.addPreset(preset);
        if (addedPreset) {
            dispatch(actions.addPreset({ ...addedPreset, isDefault: preset.isDefault }));
            dispatch(actions.selectPreset(addedPreset));
        }
    });
};

export const deletePreset = (id: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new PresetService();
        const res = await requestIsSuccess(service, service.deletePreset(id), true);
        if (res) {
            dispatch(actions.deletePreset(id));
            dispatch(actions.selectDefaultPreset());
        }
    });
};

export const updatePreset = (oldPreset: UserPreset, newPreset: UserPreset) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new PresetService();
        // set createdBy to undefined so we can patch the preset without it
        const preset = await service.patchPreset({ ...oldPreset, createdByUserName: undefined }, { ...newPreset, createdByUserName: undefined });
        if (preset) {
            dispatch(actions.updatePreset(preset));
        }
    });
};

export const setDefaultPreset = (presetId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new PresetService();
        const res = await requestIsSuccess(service, service.setDefaultPreset(presetId), true);
        if (res) {
            dispatch(actions.setDefaultPreset(presetId));
        }
    });
};

export const { selectPreset, selectDefaultPreset, notifyPresetAppliedToBuild } = actions;
