import { createContext } from 'react';

import { DialogType } from '../../../redux/overlay.state';

export enum LayerKey {
    None = 0,
    DesignArea = 1 << 0,
    FlexnapCablePath = 1 << 1,
    Nap = 1 << 2,
    FlexnapSplicePlan = 1 << 3,
    Pole = 1 << 4,
    ManholeHandholeVault = 1 << 5,
    TrenchBore = 1 << 6,
    Cabinet = 1 << 7,
    BulkCablePath = 1 << 8,
    SplicePoint = 1 << 9,
    BulkSplicePlan = 1 << 10,
    DAInfrastructure = 1 << 11
}

export interface DownloadRequest {
    buildIds: number[];
    entireDesignArea: boolean;
    includeLayers: number;
    designAreaIds: number[];
}

export interface DownloadLayer {
    key: LayerKey;
    labelLocalizationKey: string;
}

export interface DownloadGisDataDialogHooks {
    dialog?: DialogType;
    includeLayers: number;
    buildCount: number;
    buildId: number;
    buildIds: number[];
    designAreaCount: number;
    designAreaNames: string[];
    onLayerClick: (layerKey: LayerKey) => void;
    onCloseClick: () => void;
    onDownloadClick: () => void;
}

export interface DownloadLayersContextProps {
    includeLayers: number;
    onLayerClick: (layerKey: LayerKey) => void;
}

const initialDownloadLayersContextProps: DownloadLayersContextProps = {
    includeLayers: 0,
    onLayerClick: () => undefined,
}

export const DownloadLayersContext = createContext(initialDownloadLayersContextProps);
