import { createSelector } from '@reduxjs/toolkit';

import { TetherLocation } from '../../models/schrodinger/splice-plan';
import { schrodingerSplicePlanSelector } from '../root.selectors';

interface TethersGroupBySubunitNumber {
    [subunitNumber: number]: TetherLocation[];
}

export const splicePlanLocationsWithCategoriesSelector = createSelector(
    schrodingerSplicePlanSelector,
    splicePlan => splicePlan?.locations
        // Discard locations with no tethers
        ?.filter(({ tethers }) => tethers.length > 0)
        // Improve locations definition and add categories before each aggregate
        .map((location, i) => {
            const { index, tethers, id: poleId } = location
            const indexLabel = `${index * 10 < 100 ? 0 : ''}${index * 10}`;

            // Group by tube color to create color categories
            const tethersGroupBySubunit: TethersGroupBySubunitNumber = tethers.reduce(
                (tGrouped: TethersGroupBySubunitNumber, t: TetherLocation) => {
                    const { subunitNumber, fiber, polarity } = t;
                    const locationLabel = `${indexLabel} / ${poleId}`;
                    const fiberPolarity = fiber ?? polarity;
                    const key = `${fiberPolarity ?? ""}-${t.fiberColor}-${t.layer}-${t.subunitNumber}`;
                    const tether = { ...t, locationLabel, fiberPolarity, isCategory: false, key };

                    // Create new category if not existing
                    if (!tGrouped[subunitNumber])
                        tGrouped[subunitNumber] = [{
                            ...tether,
                            key: `cat-${key}`,
                            isCategory: true,
                            fiberId: `cat-${poleId}-${subunitNumber}`,
                        }];
                    // Push every locations in the color category
                    tGrouped[subunitNumber].push(tether);

                    return tGrouped;
                }, {});

            // Transform locations grouped to a flat location array
            const tethersAndCategories: TetherLocation[] = Object.values(tethersGroupBySubunit).flat();

            return {
                ...location,
                indexLabel,
                arrayIndex: i,
                tethers: tethersAndCategories,
            }
        })
        || []
);
