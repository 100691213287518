import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    GeoJsonFeatureConverter, ImportedData
} from '../../../../helpers/geojson-feature-converter';
import { LocalizationKeys } from '../../../../locales/types';
import { BuildType } from '../../../../models/build-type';
import { GeoJsonFeature } from '../../../../models/geojson';
import {
    connectBuildToSplicePoint, convertToPretermLateral, loadBuildSegmentsFromImport,
    loadBulkBuildFromImport, loadFlexNapBuildFromImport
} from '../../../../redux/build.state';
import { addDesignArea } from '../../../../redux/design-area.state';
import { loadSplicePointsFromImport } from '../../../../redux/bulk/splice-point.state';
import { StateModel } from '../../../../redux/reducers';
import { loadNapsTapsAndTethersFromImport } from '../../../../redux/tap.state';
import { WebServiceErrorHandlingBehavior } from '../../../../services/abstract-web-v2.service';
import {
    BuildToImportRow, ImportBuildPayload, ImportBuildRequest, ImportDesignAreaPayload, ImportDesignFileFormat, ImportDesignAreasRequest, ImportService
} from '../../../../services/import.service';
import { useImportInfrastructure } from '../common/import-infrastructure.hooks';
import {
    BuildTypes, GeoJsonStepsEnum, ImportBuildStatus, ImportDialogContentProps, ImportStatus
} from '../import.card.types';
import { DesignArea } from '../../../../models/design-area';
import { DesignAreaAccessPoints } from './design-areas-table-row.types';

export const useImportGeojsonDialogContent = (props: ImportDialogContentProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const workspace = useSelector((state: StateModel) => state);
    const currentWorkspace = workspace.workspace.currentWorkspace;
    const currentWorkspaceId = currentWorkspace?.id;
    const { presets } = workspace.preset;

    // Global 
    const [prevStep, setPrevStep] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    // File parsing results
    const [importedFileData, setImportedFileData] = useState<ImportDesignFileFormat | undefined>(undefined);
    const [importedInfraData, setImportedInfraData] = useState<ImportedData | undefined>(undefined);
    const [importBuildPayloads, setImportBuildPayloads] = useState<ImportBuildPayload[]>([]);
    const [importDesignAreaPayloads, setImportDesignAreaPayloads] = useState<ImportDesignAreaPayload[]>([]);
    const [buildsToImportRows, setBuildsToImportRows] = useState<BuildToImportRow[] | undefined>(undefined);
    const [designAreasToImportRows, setDesignAreasToImportRows] = useState<DesignArea[]>([]);

    const [designAreasAccessPointsCount, setDesignAreasAccessPointsCount] = useState<DesignAreaAccessPoints[]>([]);

    // Step: Import Infrastructure
    const [hasFilteredInfraData, setHasFilteredInfraData] = useState(false);
    const [infraLogOutput, setInfraLogOutput] = useState<string[]>([]);
    const [importedGisDataId, setImportedGisDataId] = useState<number | undefined>(undefined);

    const { importedInfraDataTotal, importedInfraDataHasDuplicates, onFilterInfraData, recordGisData, onImportConfirm: importInfra } = useImportInfrastructure({ setErrorMessages, setIsLoading, setLogOutput: setInfraLogOutput });

    // Step: Select Presets
    const [selectedBuildsForImport, setSelectedBuilds] = useState<{ buildId: string; isSelected: boolean; presetId: string; parentCustomerBuildId: string; }[]>([]);

    // Step: Import Builds
    const [importStatuses, setImportStatus] = useState<ImportBuildStatus[]>([]);
    const [buildTypes, setBuildTypes] = useState<BuildTypes>({});


    const readFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsText(file);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fr.onload = (_: any): void => {
                resolve(fr.result as string);
            };
            fr.onerror = reject;
        });
    }

    const convertToImportDesignAreaPayloads = useCallback((importDesignFile: ImportDesignFileFormat): void => {
        if (!importDesignFile.designAreas) return;
        const designAreaAccessPoints: DesignAreaAccessPoints[] = [];
        const payloads: ImportDesignAreaPayload[] = [];
        for (const designAreaFeature of importDesignFile.designAreas.features) {
            const importDesignAreaPayload: ImportDesignAreaPayload = {
                ...designAreaFeature,
                id: designAreaFeature.properties['id'],
                name: designAreaFeature.properties['name'],
                projectId: designAreaFeature.properties['projectId'],
                description: designAreaFeature.properties['description'],
                country: designAreaFeature.properties['country'],
                region: designAreaFeature.properties['region'],
                city: designAreaFeature.properties['city'],
                zipCode: designAreaFeature.properties['zipCode'],
                color: designAreaFeature.properties['color']
            };
            let designAreaAccessPointCount = 0;
            for (const buildFeature of importDesignFile.builds.features) {
                if (buildFeature.properties['designAreaId'] !== designAreaFeature.properties['name']) continue;
                const buildId = buildFeature.properties['id'];
                const accessPoints = importDesignFile.accessPoints.features.filter(f => !!f.properties['buildId'] && f.properties['buildId'] === buildId);
                designAreaAccessPointCount += accessPoints.length;
            }
            designAreaAccessPoints.push({
                designAreaName: designAreaFeature.properties['name'],
                accessPointCount: designAreaAccessPointCount
            })
            payloads.push(importDesignAreaPayload);
        }
        setDesignAreasAccessPointsCount(designAreaAccessPoints);
        setImportDesignAreaPayloads(payloads);
    }, []);
    
    const loadInfraData = useCallback(async (): Promise<void> => {
        if (isLoading) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let jsonObj: any;
            try {
                const file = await readFile(props.files[0]);
                jsonObj = JSON.parse(file);
                setImportedFileData(jsonObj);
            } catch (err) {
                setErrorMessages(['Unable to parse file']);
                props.setStepCount(GeoJsonStepsEnum.Error);
                props.setPositiveButtonTitle(t(LocalizationKeys.Finish));
            }
            if (jsonObj?.elements || jsonObj?.paths) {
                try {
                    const tags: string[] = [];
                    const featuresToConvert: GeoJsonFeature[] = [];
                    if (jsonObj?.elements?.features) featuresToConvert.push(...jsonObj.elements.features);
                    if (jsonObj?.paths?.features) featuresToConvert.push(...jsonObj.paths.features);
                    const convertedImportData = GeoJsonFeatureConverter.convert(featuresToConvert, tags);
                    if (convertedImportData) {
                        setImportedInfraData(convertedImportData);
                    }
                } catch (err) {
                    if (err instanceof Error) {
                        setErrorMessages(['Unable to convert infrastructure data', err.message]);
                        props.setStepCount(GeoJsonStepsEnum.Error);
                        props.setPositiveButtonTitle(t(LocalizationKeys.Finish));
                    }
                }
            }
            if (jsonObj?.designAreas) {
                convertToImportDesignAreaPayloads(jsonObj);
            }
        }
    }, [convertToImportDesignAreaPayloads, isLoading, props, t]);

    const convertToImportBuildPayloads = useCallback((importDesignFile: ImportDesignFileFormat) => {
        const importBuildPayloads: ImportBuildPayload[] = [];
        for (const buildFeature of importDesignFile.builds.features) {
            const buildId = buildFeature.properties['id'];
            const buildType = buildFeature.properties['buildType'];
            const slackLoops = importDesignFile.slackLoops.features.filter(f => !!f.properties['buildId'] && f.properties['buildId'] === buildId);
            const accessPoints = importDesignFile.accessPoints.features.filter(f => !!f.properties['buildId'] && f.properties['buildId'] === buildId);
            const importBuildPayload: ImportBuildPayload = {
                ...buildFeature,
                id: buildId,
                buildType,
                cableName: buildFeature.properties['cableName'],
                fiberCount: buildFeature.properties['fiberCount'],
                calculatedLength: buildFeature.properties['calculatedLength'],
                measuredLength: buildFeature.properties['measuredLength'],
                slackLoops,
                accessPoints,
            };
            importBuildPayloads.push(importBuildPayload);
        }
        setImportBuildPayloads(importBuildPayloads);
        return importBuildPayloads;
    }, []);


    const loadDesignPayloads = useCallback(async (): Promise<void> => {
        if (!importedFileData?.builds) return;
        const importBuildPayloads = convertToImportBuildPayloads(importedFileData);
        const importService = new ImportService();
        const buildsToImportRows: BuildToImportRow[] = []
        for (const buildPayload of importBuildPayloads) {
            buildsToImportRows.push({
                buildType: buildPayload.buildType,
                customerBuildId: buildPayload.id,
                fiberCount: parseInt(buildPayload.fiberCount),
                accessPointCount: buildPayload.accessPoints.length,
                parentCustomerBuildIdOptions: await importService.getParentBuildOptions(buildPayload, importedFileData.accessPoints.features)
            });
        }
        if (buildsToImportRows.length > 0) {
            setBuildsToImportRows(buildsToImportRows);
            setSelectedBuilds(buildsToImportRows.map(build => { return { buildId: build.customerBuildId, isSelected: true, presetId: '', parentCustomerBuildId: '' } })); // default: select all builds
        } else {
            setErrorMessages(['No builds to import.']);
            props.setStepCount(GeoJsonStepsEnum.Error);
            props.setPositiveButtonTitle(t(LocalizationKeys.Finish))
        }
    }, [convertToImportBuildPayloads, importedFileData, props, t]);

    const handleImportResultError = (err: unknown, buildId: string): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _err: any = err;
        const responseData: string = _err?.response?.data;
        setImportStatus(previous =>
            [
                {
                    buildId,
                    importResult: [...(previous.find(p => p.buildId === buildId)?.importResult ?? []), ...["Errors:", responseData]],
                    importStatus: ImportStatus.Error
                },
                ...previous.filter(p => p.buildId !== buildId)
            ]
        );
    }

    const importFlexNapDesign = useCallback(async (importBuildRequest): Promise<number | undefined> => {
        const importService = new ImportService();
        importService.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);

        const currentImportStatus: ImportBuildStatus = { buildId: importBuildRequest.id, importStatus: ImportStatus.Start, importResult: [] };
        try {
            const res = await importService.importFlexNapBuild(importBuildRequest);
            if (res) {
                currentImportStatus.importStatus = ImportStatus.Complete;
                currentImportStatus.importResult = [
                    res.build.customerBuildId,
                    "Imported:",
                    `${res.segments?.length ?? 0} segments, ${res.naps?.length ?? 0} naps, ${res.taps?.length ?? 0} taps, ${res.tethers?.length ?? 0} tethers`
                ];
                if (res.warnings.length > 0) { currentImportStatus.importResult.push(`${t(LocalizationKeys.Warnings)}: ${res?.warnings}`) }
                dispatch(loadFlexNapBuildFromImport(res.build));
                dispatch(loadBuildSegmentsFromImport(res.segments));
                dispatch(loadNapsTapsAndTethersFromImport({ naps: res.naps, taps: res.taps, tethers: res.tethers }));
                setImportStatus(previous => [currentImportStatus, ...previous.filter(p => p.buildId !== currentImportStatus.buildId)]);
                return res.build.id;
            }
        } catch (err) {
            handleImportResultError(err, importBuildRequest.id);
        }
    }, [dispatch, t]);

    const importBulkDesign = useCallback(async (importBuildRequest): Promise<number | undefined> => {
        const importService = new ImportService();
        importService.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);

        const currentImportStatus: ImportBuildStatus = { buildId: importBuildRequest.id, importStatus: ImportStatus.Start, importResult: [] };
        try {
            const res = await importService.importBulkBuild(importBuildRequest);
            if (res) {
                currentImportStatus.importStatus = ImportStatus.Complete;
                currentImportStatus.importResult = [
                    "Imported:",
                    `${res.segments?.length ?? 0} segments, ${res.splicePoints?.length ?? 0} splicePoints`
                ];
                if (res.warnings.length > 0) { currentImportStatus.importResult.push(`${t(LocalizationKeys.Warnings)}: ${res?.warnings}`) }
                dispatch(loadBulkBuildFromImport(res.build));
                dispatch(loadBuildSegmentsFromImport(res.segments));
                dispatch(loadSplicePointsFromImport(res.splicePoints));
                setImportStatus(previous => [currentImportStatus, ...previous.filter(p => p.buildId !== currentImportStatus.buildId)]);
                return res.build.id;
            }
        } catch (err) {
            handleImportResultError(err, importBuildRequest.id);
        }
    }, [dispatch, t]);

    /**
     * Uses the unique database build.Id and parent build.Id. The property build.CustomerBuildId is not unique.
     */
    const convertFlexNapBuildToPreterm = useCallback(async (customerBuildId: string, buildId: number, parentBuildId: number): Promise<void> => {
        const importService = new ImportService();
        importService.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const accessPointId = await importService.getParentAccessPointId(buildId, parentBuildId);
            if (!accessPointId) throw new Error(`Unable to find access point to connect build`);
            dispatch(convertToPretermLateral(buildId, accessPointId, parentBuildId, true, true));
            setImportStatus(previous =>
                [
                    {
                        buildId: customerBuildId,
                        importResult: [...previous.find(p => p.buildId === customerBuildId)?.importResult ?? [], 'Converted to preterm lateral'],
                        importStatus: ImportStatus.Complete
                    },
                    ...previous.filter(p => p.buildId !== customerBuildId)
                ]
            );
        } catch (err) {
            handleImportResultError(err, customerBuildId);
        }
    }, [dispatch, setImportStatus]);

    /**
     * Uses the unique database build.Id and parent build.Id. The property build.CustomerBuildId is not unique.
     */
    const connectBulkBuildToSplicePoint = useCallback(async (customerBuildId: string, buildId: number, parentBuildId: number): Promise<void> => {
        const importService = new ImportService();
        importService.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            const accessPointId = await importService.getParentAccessPointId(buildId, parentBuildId);
            if (!accessPointId) throw new Error(`Unable to find splice point to connect build`);
            dispatch(connectBuildToSplicePoint(buildId, accessPointId));
            setImportStatus(previous =>
                [
                    {
                        buildId: customerBuildId,
                        importResult: [...previous.find(p => p.buildId === customerBuildId)?.importResult ?? [], 'Connected to parent build'],
                        importStatus: ImportStatus.Complete
                    },
                    ...previous.filter(p => p.buildId !== customerBuildId)
                ]
            );
        } catch (err) {
            handleImportResultError(err, customerBuildId);
        }
    }, [dispatch]);

    const importDesign = useCallback(async (): Promise<void> => {
        if (!importBuildPayloads) return;
        props.setPositiveButtonTitle(t(LocalizationKeys.Finish));

        // create the initial import statuses that are displayed in the table
        const startImportStatuses: { buildId: string; importStatus: ImportStatus; importResult: string[] }[] = [];
        const buildTypes: BuildTypes = {};
        const importBuildRequests: ImportBuildRequest[] = []
        for (const selectedBuild of selectedBuildsForImport) {
            const buildPayload = importBuildPayloads.find(buildPayload => buildPayload.id === selectedBuild.buildId);
            if (!selectedBuild?.presetId || !buildPayload)
                continue;

            const importBuildRequest: ImportBuildRequest = { ...buildPayload, presetId: +selectedBuild.presetId, workspaceId: currentWorkspaceId ?? 0, parentCustomerBuildId: selectedBuild.parentCustomerBuildId };
            importBuildRequests.push(importBuildRequest);
            startImportStatuses.push({ buildId: buildPayload.id, importStatus: ImportStatus.Start, importResult: [''] });
            buildTypes[buildPayload.id] = buildPayload.buildType;
        }
        setImportStatus(startImportStatuses);
        setBuildTypes(buildTypes);

        const buildIdsMapping = new Map();
        // import all the builds individually
        for (const importBuildRequest of importBuildRequests) {
            if (importBuildRequest.buildType === BuildType.FlexNap) {
                const dbBuildId = await importFlexNapDesign(importBuildRequest);
                buildIdsMapping.set(importBuildRequest.id, dbBuildId);
            } else if (importBuildRequest.buildType === BuildType.Bulk) {
                const dbBuildId = await importBulkDesign(importBuildRequest);
                buildIdsMapping.set(importBuildRequest.id, dbBuildId);
            }
        }

        // after importing all builds, connect/convert the preterms/buildSplices
        props.setDisableNext(true);
        for (const importBuildRequest of importBuildRequests) {
            if (importBuildRequest.parentCustomerBuildId) {
                if (importBuildRequest.buildType === BuildType.FlexNap) {
                    await convertFlexNapBuildToPreterm(importBuildRequest.id, buildIdsMapping.get(importBuildRequest.id), buildIdsMapping.get(importBuildRequest.parentCustomerBuildId));
                } else if (importBuildRequest.buildType === BuildType.Bulk) {
                    await connectBulkBuildToSplicePoint(importBuildRequest.id, buildIdsMapping.get(importBuildRequest.id), buildIdsMapping.get(importBuildRequest.parentCustomerBuildId));
                }
            }
        }
        props.setDisableNext(false);
    }, [importBuildPayloads, props, t, selectedBuildsForImport, currentWorkspaceId, importFlexNapDesign, importBulkDesign, convertFlexNapBuildToPreterm, connectBulkBuildToSplicePoint]);

    const importDesignAreas = useCallback(async (): Promise<void> => {
        if (!importDesignAreaPayloads) return;
        const importedGisDataId = await recordGisData();
        const importService = new ImportService();
        const importDARequest: ImportDesignAreasRequest = { designAreaPayloads: importDesignAreaPayloads, workspaceId: currentWorkspaceId ?? 0, importedGisDataId };
        const importedDesignAreas = await importService.importDesignArea(importDARequest);
        if (importedDesignAreas) {
            for (const designArea of importedDesignAreas) {
                dispatch(addDesignArea(designArea));
            }
            setDesignAreasToImportRows(importedDesignAreas);
            setImportedGisDataId(importedGisDataId);
        }
    }, [currentWorkspaceId, dispatch, importDesignAreaPayloads, recordGisData]);

    useEffect((): void => { // Disable the 'next' button
        if (props.stepCount === GeoJsonStepsEnum.Infrastructure) {
            props.setDisableNext(!hasFilteredInfraData);
        }
        if (props.stepCount === GeoJsonStepsEnum.SelectPresets) {
            props.setDisableNext(!!selectedBuildsForImport.length && 
                (!selectedBuildsForImport.find(b => b.isSelected) || 
                !!selectedBuildsForImport.find(b => b.isSelected && !b.presetId))) // none selected || one build that is selected does not have a preset selected
        }
        if (props.stepCount === GeoJsonStepsEnum.Save) {
            props.setDisableNext(!!importStatuses
                && importStatuses.length === selectedBuildsForImport.filter(b => b.isSelected && b.presetId).length
                && importStatuses.some(status => status.importStatus === ImportStatus.Start))
        }
    }, [hasFilteredInfraData, importStatuses, isLoading, props, selectedBuildsForImport]);

    useEffect((): void => { // Load infrastructure data
        if (importedInfraData && !hasFilteredInfraData) {
            onFilterInfraData([{ data: importedInfraData, bbox: undefined }], props.files[0]?.name);
            setHasFilteredInfraData(true);
        }
    }, [hasFilteredInfraData, importedInfraData, onFilterInfraData, props]);

    useEffect(() => {
        if (prevStep !== props.stepCount) {
            if (props.stepCount === GeoJsonStepsEnum.Infrastructure) {
                loadInfraData();
            }
            if (props.stepCount === GeoJsonStepsEnum.DesignAreas) {
                importDesignAreas();
            }
            if (props.stepCount === GeoJsonStepsEnum.SelectPresets) {
                importInfra(importedGisDataId);
                loadDesignPayloads();
            }
            if (props.stepCount === GeoJsonStepsEnum.Save) {
                importDesign();
            }
            if ((props.stepCount > GeoJsonStepsEnum.Save && props.stepCount < GeoJsonStepsEnum.Error) || props.stepCount > GeoJsonStepsEnum.Error) {
                props.onClose();
            }
        }
        setPrevStep(props.stepCount);
    }, [prevStep, props, props.stepCount, loadDesignPayloads, importDesign, importedGisDataId, importInfra, loadInfraData, importDesignAreas, t]);

    return { 
        errorMessages, 
        isLoading, 
        infraLogOutput, 
        buildsToImportRows, 
        importStatuses, 
        designAreasToImportRows, 
        importedInfraDataTotal, 
        importedInfraDataHasDuplicates, 
        presets, 
        buildTypes,
        designAreasAccessPointsCount,
        setSelectedBuilds 
    };
}