import { Cabinet } from '../models/cabinet';
import { CabinetSplicePlan } from '../models/cabinet-splice-plan';
import { PortMode } from '../redux/cabinet-splice-plan.state';
import { SecuredService } from './abstract-secured-v2.service';

export class CabinetSplicePlanService extends SecuredService {
    private readonly baseUrl = `${this.rootUrl}api/cabinetspliceplan`;

    public loadSplicePlans(workspaceId: number): Promise<CabinetSplicePlan[] | undefined> {
        const path = `${this.baseUrl}/${workspaceId}`;
        return this.get(path);
    }

    public loadSplicePlan(workspaceId: number, cabinetId: number): Promise<CabinetSplicePlan | undefined> {
        const path = `${this.baseUrl}/${workspaceId}/${cabinetId}`;
        return this.get(path);
    }

    public updateCabinetSplicePlanByBuild(workspaceId: number, buildId: number): Promise<CabinetSplicePlan | undefined> {
        const path = `${this.baseUrl}/${workspaceId}/build/${buildId}`;
        return this.post(path);
    }

    public updateCabinetBuildPositions(workspaceId: number, buildId: number, cabinetBuildAction: number): Promise<CabinetSplicePlan | undefined> {
        const path = `${this.baseUrl}/${workspaceId}/build/${buildId}/position`;
        return this.post(path, { cabinetBuildAction });
    }

    public updateCabinetSplicePlanMode(workspaceId: number, cabinetId: number, mode: PortMode): Promise<Cabinet | undefined> {
        const path = `${this.baseUrl}/${workspaceId}/${cabinetId}/mode`;
        return this.post(path, { isAutomaticCabinetSplicePlanMode: mode === PortMode.Automatic });
    }
}
