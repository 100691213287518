import { MapClickEvent } from '../models/map-click-event';
import { store } from '../dna';
import { addHandhole } from '../redux/handhole.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class HandholeTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Handhole,
        name: 'Handhole',
        shortcut: 'H',
    };

    public static execute(event: MapClickEvent): void {
        addHandhole({ x: event.x, y: event.y })(store.dispatch);
    }
}
