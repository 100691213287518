import { createContext } from "react";
import { PresetType } from './presets-list/presets-list.types';

export enum PresetConfigComponents {
    PartSelection,
    Wizard,
}

export enum PresetConfigMode {
    CreateFlexNap,
    CreateSchrodinger,
    Edit,
}

export interface PresetTab {
    type: PresetType;
    label: string;
}

export interface PresetManagerContextProps {
    selectedTab: PresetType;
    setSelectedTab: (selectedTab: PresetType) => void;
    configMode?: PresetConfigMode;
    updateConfigMode: (configMode?: PresetConfigMode) => void;
}

const initialPresetManagerContextProps: PresetManagerContextProps = {
    selectedTab: PresetType.FlexNap,
    setSelectedTab: () => undefined,
    configMode: undefined,
    updateConfigMode: () => undefined,
}

export const PresetManagerContext = createContext(initialPresetManagerContextProps);