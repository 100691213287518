import React from 'react';
import classNames from 'classnames';

import { NotificationElementProps } from './notification-element.types';
import { useNotificationElement } from './notification-element.hooks';

export const NotificationElement = (props: NotificationElementProps): JSX.Element => {
    const {
        icon, alt, messageClass, title, shortDescription, longDescription,
        handleClick
    } = useNotificationElement(props);

    return (
        <table className={classNames("notification-element", messageClass)} onClick={handleClick}>
            <tbody>
                <tr>
                    <th className="icon-cell"><img alt={alt} src={icon} /></th>
                    <th className="title">{title}</th>
                </tr>
                <tr>
                    <td />
                    <td className="short-description">{shortDescription}</td>
                </tr>
                {longDescription &&
                    <tr>
                        <td />
                        <td className="long-description">{longDescription}</td>
                    </tr>
                }
            </tbody>
        </table>
    );
};
