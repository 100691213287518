import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { dialogSelector } from '../../../selectors/root.selectors';

export interface CabinetPortNumberAutomaticDialogProps {
    isOpen?: boolean;
    title: string;
    onSave: () => void;
    onClose: () => void;
}

export const useCabinetPortNumberAutomaticDialog = (): CabinetPortNumberAutomaticDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const dialog = useSelector(dialogSelector);

    const isOpen = dialog === DialogType.CabinetPortNumberAutomatic;
    const title = t(LocalizationKeys.AutomaticPortNumberUpdate);

    const onSave = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    return {
        isOpen,
        title,
        onSave,
        onClose,
    }
};