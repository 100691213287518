/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { Tooltip } from '@mui/material';
import { LocalizationKeys, TooltipLocalizationKeys } from '../../../../locales/types';
import { Card } from '../../../card/card';
import { CardContentComponent } from '../../../card/card-content.component';
import { CardPropertyInline } from '../../../card/card-controls';
import { CardHeaderComponent } from '../../../card/card-header.component';
import { InlineSelect } from '../../../ui-elements/inline-select';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { useOpticalTetherCard } from './optical-tether-card.hooks';
import { OpticalTetherProps } from './optical-tether-card.types';

export const OpticalTetherCard = (props: OpticalTetherProps): JSX.Element | null => {
    const { selectedTetherNumber, disabled } = props;

    const { t } = useTranslation();

    const {
        subheader, lengthId, fiberCountId, tetherTypeId, opticalTetherOptions, partNumber, disabledWhenAwaiting,
        unselectTether, handleFiberChange, handleTetherTypeChange, handleLengthChange, handleDeleteTether
    } = useOpticalTetherCard(props);

    const tetherIcon = disabled ? require('../../../../icons/locked.png') : require('../../../../icons/optical-tether.png');

    return (
        <Card id="Tether-info" onCloseClick={unselectTether}>
            <CardHeaderComponent title={`${t(LocalizationKeys.Optical)} ${selectedTetherNumber}`} subheader={subheader} icon={tetherIcon} iconBorder={true} />
            <CardContentComponent>
                {disabledWhenAwaiting ? (
                    <div className="spinner" ><DnaSpinner /></div>
                ) : (
                    <>
                        {partNumber ? CardPropertyInline(t(LocalizationKeys.PartNumber), partNumber) : CardPropertyInline(t(LocalizationKeys.PartNumber), "", t(LocalizationKeys.NotApplicable))}
                        {opticalTetherOptions &&
                            <>
                                <InlineSelect
                                    label={t(LocalizationKeys.FiberCount)}
                                    value={fiberCountId ?? ""}
                                    values={opticalTetherOptions.fiberCounts?.map(f => f.id)}
                                    displayValues={opticalTetherOptions.fiberCounts?.map(f => f.shortDescription ?? f.id)}
                                    onUpdate={handleFiberChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                    maxHeight={120}
                                />
                                <InlineSelect
                                    label={t(LocalizationKeys.Type)}
                                    value={tetherTypeId ?? ""}
                                    values={opticalTetherOptions.tetherTypes?.map(t => t.id)}
                                    displayValues={opticalTetherOptions.tetherTypes?.map(f => f.shortDescription ?? f.id)}
                                    onUpdate={handleTetherTypeChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                    maxHeight={96}
                                />
                                <InlineSelect
                                    label={t(LocalizationKeys.Length)}
                                    value={lengthId ?? ""}
                                    values={opticalTetherOptions.lengths?.map(t => t.id)}
                                    displayValues={opticalTetherOptions.lengths?.map(f => f.shortDescription ?? f.id)}
                                    onUpdate={handleLengthChange}
                                    disabled={disabled || disabledWhenAwaiting}
                                    maxHeight={72}
                                />
                            </>}
                        <div className="card-footer">
                            <Tooltip arrow={true} placement="top" title={t(TooltipLocalizationKeys.DeleteTether)?.toLowerCase()}>
                                <div>
                                    <TextButton palette={MainPalettes.error} onClick={handleDeleteTether} disabled={disabled || disabledWhenAwaiting}>
                                        <i className="material-icons">delete</i>
                                    </TextButton>
                                </div>
                            </Tooltip>
                        </div>
                    </>
                )}
            </CardContentComponent>
        </Card>
    );
}
