import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';

import { store } from '../dna';

import { TerminalSegment } from '../models/terminal-segment';

import { TerminalSegmentRequest } from '../services/terminal.service';
import { createTerminalSegments, updateTerminalSegments, deleteTerminalSegment } from '../redux/tap.state';

import { FeatureType } from '../openlayers/openlayer-feature.factory';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { MapItemDefinition } from '../models/map-item-definition';
import { ModifiableNode } from '../models/modifiable-node';

import { BaseCableTool, MapSegment } from './base-cable-tool';

export class TerminalCableTool extends BaseCableTool {
    protected modifiableTypes = new Set<FeatureType>([
        InteractableFeatureType.TERMINAL_SEGMENT
    ]);

    protected async createAndUpdateSegments(mapSegments: MapSegment[]): Promise<void> {
        const updatedSegment = mapSegments.find(s => s.connectedFeatures.length > 2);
        if (!updatedSegment) {
            return;
        }
        const { selection } = store.getState();
        const { selectedTerminalId } = selection;
        if (!selectedTerminalId) {
            return;
        }

        const segments = this.getTerminalSegments(selectedTerminalId);
        if (!segments) {
            return;
        }
        
        const oldTerminalSegment = segments?.find(s => s.id === updatedSegment.id);
        if (oldTerminalSegment) {
            const newTerminalSegments: TerminalSegmentRequest[] = [];
            let numberOfNewSegments = 0;
            for (let i = 1; i < updatedSegment.connectedFeatures.length; i++) {
                const fromId = updatedSegment.connectedFeatures[i - 1].id;
                const toId = updatedSegment.connectedFeatures[i].id;
                if (oldTerminalSegment.fromId !== fromId) {
                    const position = oldTerminalSegment.position + (i - 1);
                    newTerminalSegments.push({ position: position, fromId: fromId, toId: toId });
                    numberOfNewSegments++;
                } else {
                    newTerminalSegments.push({  ...oldTerminalSegment, fromId: fromId, toId: toId });
                }
            }

            for (const segment of segments.filter(s => s.position > oldTerminalSegment.position)) {
                newTerminalSegments.push({ ...segment, position: segment.position + numberOfNewSegments });
            }

            await createTerminalSegments(selectedTerminalId, newTerminalSegments)(store.dispatch);
        }
    }

    protected async updateSegments(mapSegments: MapSegment[]): Promise<number | undefined> {
        const { selection } = store.getState();
        const { selectedTerminalId } = selection;
        if (!selectedTerminalId) {
            return;
        }

        const terminalSegmentsFromMap = mapSegments.map(s => {
            return {
                id: s.id,
                fromId: s.connectedFeatures[0].id,
                toId: s.connectedFeatures[1].id
            }
        });

        const segments = this.getTerminalSegments(selectedTerminalId);
        if (!segments) {
            return;
        }

        const updatedTerminalSegments: TerminalSegmentRequest[] = [];
        for (const oldSegment of segments) {
            const terminalSegmentModifiedNodes = terminalSegmentsFromMap.find(s => s.id === oldSegment.id && (s.fromId !== oldSegment.fromId || s.toId !== oldSegment.toId));
            if (terminalSegmentModifiedNodes) {
                updatedTerminalSegments.push({ ...oldSegment, fromId: terminalSegmentModifiedNodes.fromId, toId: terminalSegmentModifiedNodes.toId });
            }
        }

        await updateTerminalSegments(selectedTerminalId, updatedTerminalSegments)(store.dispatch);
        
        return 0;
    }

    protected undoSegmentsModification(olSegments: Feature<Geometry>[]) {
        for (const olSegment of olSegments) {
            const initialSegment = this.initialMapSegments.find(s => s.id === olSegment.get("id"));
            if (initialSegment) {
                const initialGeometry = initialSegment.geometry;
                olSegment.setGeometry(initialGeometry);
            }
        }
    }

    public getModifiableNode(features: MapItemDefinition[]): ModifiableNode | undefined {
        const { selection } = store.getState();
        const { selectedTerminalId } = selection;
        if (selectedTerminalId) {
            const segments = this.getTerminalSegments(selectedTerminalId);
            if (segments && segments.length > 1) {
                const modifiableNodes = features.filter(f => this.moveToTypes.has(f.type));
                // If there is only one feature of type TERMINAL_SEGMENT, that means one of the ends of the segment has been clicked, and these are not deletable
                const isEndOfSegment = features.filter(f => f.type === InteractableFeatureType.TERMINAL_SEGMENT).length === 1;
                if (modifiableNodes.length === 1 && !isEndOfSegment) {
                    const modifiableMapItem = modifiableNodes[0];
                    return { mapItem: modifiableMapItem, canDelete: true };
                }
            }
        }
    }

    public deleteNode(node: MapItemDefinition): boolean {
        if (!this.moveToTypes.has(node.type)) {
            return false;
        }
        
        const { selection } = store.getState();
        const { selectedTerminalId } = selection;
        if (!selectedTerminalId) {
            return false;
        }
        
        const segments = this.getTerminalSegments(selectedTerminalId);
        if (segments) {
            return this.deleteConnectedNodeFromSegment(node, selectedTerminalId, segments);
        }

        return false;
    }

    private deleteConnectedNodeFromSegment(node: MapItemDefinition, terminalId: number, segments: TerminalSegment[]): boolean {
        const connectedSegments = segments.filter(s => s.fromId === node.id || s.toId === node.id).sort((a, b) => a.position - b.position);
        if (connectedSegments && connectedSegments.length === 2) {
            const segmentToDelete = connectedSegments[1];
            const updatedTerminalSegments = [{ ...connectedSegments[0], toId: segmentToDelete.toId }];

            segments.filter(s => s.position > segmentToDelete.position).forEach(s =>
                {
                updatedTerminalSegments.push({ ...s, position: s.position - 1 })}
            );
            
            deleteTerminalSegment(terminalId, segmentToDelete.id)(store.dispatch).then(() => {
                if (updateTerminalSegments.length > 0) {
                    updateTerminalSegments(terminalId, updatedTerminalSegments)(store.dispatch);
                }
            });
        } else {
            return false;
        }

        return true;
    }

    private getTerminalSegments(terminalId: number): TerminalSegment[] | undefined {
        const { taps } = store.getState();
        return taps?.tethers?.find(t => t.terminal?.id === terminalId)?.terminal?.terminalExtension?.segments;
    }
}