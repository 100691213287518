import React from 'react';

import { WorkspaceDrawer } from '../drawers/workspace/drawer-workspace';
import { CanvasMenuBar } from '../map-tools/canvas-menu-bar';
import { MainMenu } from '../map-tools/main-menu/main-menu';
import { PanTool } from '../map-tools/pan-tool';
import { PresetManager } from '../presets/preset-manager';

export const CanvasOverlayComponent = (): JSX.Element | null => {
    return (
        <div className="overlay">
            <CanvasMenuBar />
            <PresetManager />
            <MainMenu />
            <WorkspaceDrawer />
            <PanTool />
        </div>
    );
}
