import React from 'react';
import { TerminalLegProps } from '../nap-diagram.types';
import { TerminalExtender } from './terminal-extender';

export const TerminalLeg = (props: TerminalLegProps): JSX.Element => {
    const fill = props.fill ?? "#606060";
    const stroke = props.stroke ?? "#9e9e9e";
    return (
        <g transform="translate(0 -15)">
            {
                props.isExtended ?
                    <TerminalExtender />
                    :
                    <rect x="4.5" y="9.5" width="3" height={props.longConnector ? 104 : 44} fill={fill} stroke={stroke}/>
            }
            <path d="M9,9.5H3A2.5,2.5,0,0,1,.5,7V1A.5.5,0,0,1,1,.5H11a.5.5,0,0,1,.5.5V7A2.5,2.5,0,0,1,9,9.5Z" fill={fill} stroke={stroke}/>
            {
                props.longConnector ? 
                        null
                        :
                        <path d="M3,53.5H9A2.5,2.5,0,0,1,11.5,56V66a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,66V56A2.5,2.5,0,0,1,3,53.5Z" fill={fill} stroke={stroke}/>
            }
        </g>
    );
};
