
import i18next from 'i18next';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DesignAreaHelper } from '../helpers/design-area-helper';
import { CommandType } from '../history/command-type';
import i18n from '../locales/i18n';
import { LocalizationKeys } from '../locales/types';
import { DesignArea } from '../models/design-area';
import { DesignAreaBuilds } from '../models/design-area-builds';
import { DesignMode } from '../models/design-mode';
import { WebServiceErrorHandlingBehavior } from '../services/abstract-web-v2.service';
import {
    CreateDesignAreaRequest, DesignAreaService, UpdateBuildDesignAreaRequest
} from '../services/design-area.service';
import { NotificationService } from '../services/notification.service';
import { createSecuredAsyncAction } from './action';
import { push } from './history.state';
import { selectDesignArea } from './selection.state';
import { setBuildAwaiting, unsetBuildAwaiting } from './workspace.state';

export interface DesignAreaState {
    designAreas: DesignArea[];
    designAreaBuilds: DesignAreaBuilds[];
    unassignedBuilds: number[];
}

const initialState: DesignAreaState = {
    designAreas: [],
    designAreaBuilds: [],
    unassignedBuilds: [],
};

const loadAllReducer = (state: DesignAreaState, action: PayloadAction<DesignArea[]>): void => { state.designAreas = action.payload; };

const loadDesignAreaBuildsReducer = (state: DesignAreaState, action: PayloadAction<DesignAreaBuilds[]>): void => { state.designAreaBuilds = action.payload; };

const loadUnassignedBuildsReducer = (state: DesignAreaState, action: PayloadAction<number[]>): void => { state.unassignedBuilds = action.payload; };

const addDesignAreaReducer = (state: DesignAreaState, action: PayloadAction<DesignArea>): void => { state.designAreas.push(action.payload); };

const addDesignAreasReducer = (state: DesignAreaState, action: PayloadAction<DesignArea[]>): void => { state.designAreas.push(...action.payload); };

const updateDesignAreaReducer = (state: DesignAreaState, action: PayloadAction<DesignArea>): void => {
    const designArea: DesignArea = action.payload;
    const dIdx = state.designAreas.findIndex((d) => d.id === designArea.id);
    if (dIdx < 0) {
        state.designAreas.push(designArea);
    }
    else {
        state.designAreas[dIdx] = designArea;
    }
};

const deleteDesignAreaReducer = (state: DesignAreaState, action: PayloadAction<number>): void => {
    state.designAreas = state.designAreas.filter((d) => d.id !== action.payload);
};

const deleteDesignAreasReducer = (state: DesignAreaState, action: PayloadAction<number[]>) => {
    const designAreaIds = action.payload;
    state.designAreas = state.designAreas.filter((d) => !designAreaIds.includes(d.id));
};

const addDesignAreaBuildReducer = (state: DesignAreaState, action: PayloadAction<{ designAreaId: number, buildId: number }>): void => {
    const selectedDesignAreaBuilds = state.designAreaBuilds.find((d) => d.designAreaId === action.payload.designAreaId);
    if (selectedDesignAreaBuilds) {
        selectedDesignAreaBuilds.buildIds.push(action.payload.buildId);
    }
    else {
        state.designAreaBuilds.push({ designAreaId: action.payload.designAreaId, buildIds: [action.payload.buildId] });
    }
}

const { actions, reducer } = createSlice({
    name: 'design-area',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        loadDesignAreaBuilds: loadDesignAreaBuildsReducer,
        loadUnassignedBuilds: loadUnassignedBuildsReducer,
        addDesignArea: addDesignAreaReducer,
        addDesignAreas: addDesignAreasReducer,
        updateDesignArea: updateDesignAreaReducer,
        deleteDesignArea: deleteDesignAreaReducer,
        deleteDesignAreas: deleteDesignAreasReducer,
        addDesignAreaBuild: addDesignAreaBuildReducer
    },
});

export { reducer as DesignAreaReducer };
export const { addDesignAreaBuild, addDesignArea, deleteDesignAreas } = actions;

export const loadDesignAreas = (workspaceId: number, designMode = DesignMode.GISMode) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new DesignAreaService();
        const designAreas = await service.getAll(workspaceId, designMode);
        if (designAreas) {
            for (const designArea of designAreas) {
                if (!designArea.location) {
                    const newDa = { ...designArea, location: await DesignAreaHelper.getLocation(designArea.polygon) } // keep this request for already existing DAs
                    updateDesignArea(designArea, newDa)(dispatch);
                }
            }
            dispatch(actions.loadAll(designAreas));
        }
    });
};

export const loadDesignAreaBuilds = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new DesignAreaService();
        const data = await service.getDesignAreaBuilds(workspaceId);
        if (data) {
            if (data.designAreas) {
                dispatch(actions.loadDesignAreaBuilds(data.designAreas))
            }
            if (data.unassignedBuildIds) {
                dispatch(actions.loadUnassignedBuilds(data.unassignedBuildIds));
            }
        }
    });
};

export const loadCanvasDesignAreaBuilds = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new DesignAreaService();
        const data = await service.getCanvasDesignAreaBuilds(workspaceId);
        if (data) {
            if (data.designAreas) {
                dispatch(actions.loadDesignAreaBuilds(data.designAreas))
            }
            if (!data.designAreas.length) {
                const defaultDARequest: CreateDesignAreaRequest = {
                    name: i18next.t(LocalizationKeys.DefaultDesignArea, { index: data.designAreas.length + 1 }),
                    workspaceId: workspaceId,
                    designMode: DesignMode.CanvasMode,
                    projectId: `${workspaceId}`,
                    description: i18next.t(LocalizationKeys.DefaultDesignAreaDescriptionCanvas, { workspaceId }),
                };
                addNewDesignArea(defaultDARequest, true)(dispatch);
            }
        }
    });
};

export const addNewDesignArea = (request: CreateDesignAreaRequest, ignoreSideEffects?: boolean, setDesignArea = true) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.CreatingDesignArea));
        const service = new DesignAreaService();
        const designArea = await service.addDesignArea(request);
        if (designArea) {
            dispatch(actions.addDesignArea(designArea));
            if (!ignoreSideEffects) {
                if (setDesignArea) dispatch(selectDesignArea(designArea.id));
                dispatch(push({ type: CommandType.CreateDesignArea, payload: { id: designArea.id } }));
            }
            NotificationService.success(i18n.t(LocalizationKeys.DesignAreaSuccessfullyCreated));
        }
        NotificationService.clear(loadingToast);
    });
};

export const updateBuildDesignArea = (request: UpdateBuildDesignAreaRequest, newDesignAreaName: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(setBuildAwaiting(request.buildId));
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.Updating));
        const service = new DesignAreaService();
        const result = await service.updateCanvasDesignArea(request);
        if (result?.designAreas) {
            dispatch(actions.loadDesignAreaBuilds(result.designAreas));
            NotificationService.success(i18n.t(LocalizationKeys.BuildDesignAreaChangeSuccessfully, { buildId: request.buildId, designAreaId: newDesignAreaName }));
        }
        NotificationService.clear(loadingToast);
        dispatch(unsetBuildAwaiting(request.buildId));
    });
}

export const updateDesignArea = (oldDa: DesignArea, newDa: DesignArea) => {
    return createSecuredAsyncAction(async (dispatch) => {
        dispatch(actions.updateDesignArea(newDa));
        const service = new DesignAreaService();
        const designArea = await service.patchDesignArea(oldDa, newDa);
        if (designArea) {
            dispatch(actions.updateDesignArea(designArea));
        }
        else {
            dispatch(actions.updateDesignArea(oldDa));
        }
    });
};

export const updateDesignAreaCoordinates = (oldDa: DesignArea, newDa: DesignArea, ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.UpdatingDesignArea));
        dispatch(actions.updateDesignArea(newDa));
        const service = new DesignAreaService();
        const designArea = await service.updateDesignAreaCoordinates(newDa);

        dispatch(actions.updateDesignArea(designArea ? designArea : oldDa));

        if (designArea?.workspaceId) {
            dispatch(loadDesignAreaBuilds(designArea.workspaceId));
        }

        if (!ignoreHistory) {
            dispatch(push({ type: CommandType.ModifyDesignArea, payload: { oldDa, newDa } }));
        }

        NotificationService.success(i18n.t(LocalizationKeys.DesignAreaSuccessfullyUpdated));
        NotificationService.clear(loadingToast);
    });
};

export const deleteDesignArea = (id: number, ignoreHistory = false) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const loadingToast = NotificationService.loading(i18n.t(LocalizationKeys.DeletingDesignArea));
        const service = new DesignAreaService();
        service.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
        try {
            await service.deleteDesignArea(id);
            dispatch(actions.deleteDesignArea(id));
            if (!ignoreHistory) {
                dispatch(push({ type: CommandType.DeleteDesignArea, payload: { id } }));
            }
            NotificationService.success(i18n.t(LocalizationKeys.DesignAreaSuccessfullyDeleted));
        } catch (ex) {
            NotificationService.error(i18n.t(LocalizationKeys.DesignAreaErrorDeleting));
        }

        NotificationService.clear(loadingToast);
    });
};

export const undoDelete = (id: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new DesignAreaService();
        const designArea = await service.undoDelete(id);
        if (designArea) {
            dispatch(actions.addDesignArea(designArea));
        }
    });
};
