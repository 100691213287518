import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FlexNapSplicePlan } from '../models/flexnap-splice-plan';
import {
    FlexNapSplicePlanService, UpdateSplicePlanRequest
} from '../services/flexnap-splice-plan.service';
import { createSecuredAsyncAction } from './action';

export interface FlexNapSplicePlanState {
    currentflexNapSplicePlan?: FlexNapSplicePlan;
    flexNapSplicePlans: {
        [buildId: string]: FlexNapSplicePlan;
    };
}

const initialState: FlexNapSplicePlanState = {
    currentflexNapSplicePlan: undefined,
    flexNapSplicePlans: {},
};


const loadFlexNapSplicePlanReducer = (
    state: FlexNapSplicePlanState, action: PayloadAction<FlexNapSplicePlan>
): void => {
    const splicePlan = action.payload;
    state.currentflexNapSplicePlan = splicePlan;
};

const updateFlexNapSplicePlanReducer = (
    state: FlexNapSplicePlanState, action: PayloadAction<FlexNapSplicePlan>
): void => {
    const splicePlan = action.payload;
    state.currentflexNapSplicePlan = splicePlan;
    state.flexNapSplicePlans[splicePlan.buildId] = splicePlan;
};

const loadFlexNapSplicePlansReducer = (
    state: FlexNapSplicePlanState, action: PayloadAction<FlexNapSplicePlan[]>
): void => {
    const splicePlans = action.payload;
    state.flexNapSplicePlans = splicePlans.reduce((plans, plan) => {
        plans[plan.buildId] = plan;
        return plans;
    }, {});
};

const updatePretermBuildsSplicePlansReducer = (
    state: FlexNapSplicePlanState, action: PayloadAction<FlexNapSplicePlan[]>
): void => {
    const splicePlans = action.payload;
    for (const splicePlan of splicePlans) {
        state.flexNapSplicePlans[splicePlan.buildId] = splicePlan;
    }
};


const deleteSplicePlanReducer = (
    state: FlexNapSplicePlanState, action: PayloadAction<number>
): void => {
    const buildId = action.payload;
    delete state.flexNapSplicePlans[buildId];
};

const removeAllSplicePlansReducer = (
    state: FlexNapSplicePlanState
): void => {
    state.flexNapSplicePlans = {};
};

const { actions, reducer } = createSlice({
    name: 'splicePlan',
    initialState,
    reducers: {
        loadFlexNapSplicePlan: loadFlexNapSplicePlanReducer,
        updateFlexNapSplicePlan: updateFlexNapSplicePlanReducer,
        loadFlexNapSplicePlans: loadFlexNapSplicePlansReducer,
        updatePretermBuildsSplicePlans: updatePretermBuildsSplicePlansReducer,
        deleteSplicePlan: deleteSplicePlanReducer,
        removeAllSplicePlans: removeAllSplicePlansReducer
    },
});

export { reducer as SplicePlanReducer };
export const { removeAllSplicePlans } = actions;

export const loadFlexNapSplicePlan = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapSplicePlanService();
        const splicePlan = await service.getSplicePlan(buildId);
        if (splicePlan) {
            dispatch(actions.loadFlexNapSplicePlan(splicePlan));
        }
    });
};

export const loadFlexNapSplicePlans = (workspaceId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapSplicePlanService();
        const splicePlans = await service.loadWorkspaceSplicePlans(workspaceId);
        if (splicePlans) {
            dispatch(actions.loadFlexNapSplicePlans(splicePlans));
        }
    });
};

export const updateFlexNapSplicePlan = (request: UpdateSplicePlanRequest) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapSplicePlanService();
        const splicePlan = await service.updateSplicePlan(request);
        if (splicePlan) {
            dispatch(actions.updateFlexNapSplicePlan(splicePlan));
        }
    });
};

export const updatePretermBuildsSplicePlans = (parentBuildId: number, updatedParentBuildStartingPort?: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapSplicePlanService();
        const splicePlans = await service.updatePretermBuildsSplicePlans(parentBuildId, { updatedParentBuildStartingPort });
        if (splicePlans) {
            dispatch(actions.updatePretermBuildsSplicePlans(splicePlans));
        }
    });
};

export const deleteSplicePlan = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new FlexNapSplicePlanService();
        await service.deleteSplicePlan(buildId);
        dispatch(actions.deleteSplicePlan(buildId));
    });
};
