import { Radio, RadioGroup, RadioProps } from '@orbit/radio';
import { MainPalettes } from '@orbit/theme-provider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { PortMode } from '../../../redux/cabinet-splice-plan.state';
import { DialogComponent } from '../dialog.component';
import { useCabinetPortModeDialog } from './cabinet-port-mode-dialog.hooks';
import './cabinet-port-mode.scss';

export const CabinetPortModeDialog = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        isOpen,
        title,
        selectedMode,
        handleSelectModeChange,
        onSave,
        onClose,
    } = useCabinetPortModeDialog();

    const commonRadioProps: RadioProps = {
        palette: MainPalettes.primary,
        size: "medium",
        labelPlacement: "end",
    }

    return (
        !isOpen ? null :
            <DialogComponent
                open={isOpen}
                title={title}
                positiveTitle={t(LocalizationKeys.Save)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={onSave}
                onNegativeClick={onClose}
                onClose={onClose}
            >
                <RadioGroup id='port-mode-radio' palette={MainPalettes.primary} value={selectedMode} onChange={handleSelectModeChange}>
                    <Radio {...commonRadioProps} label={t(LocalizationKeys.Manual)} value={PortMode.Manual} checked={selectedMode === PortMode.Manual} />
                    <p className='mode-description'>{t(LocalizationKeys.PortNumberManualDescription)}</p>
                    <Radio {...commonRadioProps} label={t(LocalizationKeys.Automatic)} value={PortMode.Automatic} checked={selectedMode === PortMode.Automatic} />
                    <p className='mode-description'>{t(LocalizationKeys.PortNumberAutomaticDescription)}</p>
                </RadioGroup>
            </DialogComponent>
    );
};