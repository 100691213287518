import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedButton, TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../locales/types';

interface PresetNavigationFooterProps {
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    showPrevious?: boolean;
    onPreviousClick?: () => void;
    onNextClick?: () => void;
}

export const PresetNavigationFooter = ({ previousDisabled, nextDisabled, showPrevious, onPreviousClick, onNextClick }: PresetNavigationFooterProps): JSX.Element => {
    const { t } = useTranslation();

    const previousIcon = useCallback((disabled?: boolean) => {
        return <img alt={t(LocalizationKeys.Previous)} src={disabled ? require('./icon_chevron_left_disabled.svg') : require('./icon_chevron_left.svg')} />;
    }, [t]);

    return (
        <div className="navigation-container" style={{ justifyContent: showPrevious ? 'space-between' : 'flex-end' }}>
            {showPrevious && <TextButton startIcon={previousIcon(previousDisabled)} onClick={onPreviousClick} disabled={previousDisabled}>{t(LocalizationKeys.Previous)}</TextButton>}
            <OutlinedButton onClick={onNextClick} disabled={nextDisabled}>{t(LocalizationKeys.Next)}</OutlinedButton>
        </div>
    );
};
