import { Data } from './data';
import { PointLike } from '../../models/pointlike';

export class DesignAreaData extends Data {
    public workspaceId: number;
    public projectId: string;
    public description: string;
    public country: string;
    public region: string;
    public polygon: PointLike[];

    constructor(id: number, workspaceId: number, projectid: string, description: string, country: string, region: string, polygon: PointLike[]) {
        super(id);
        this.workspaceId = workspaceId;
        this.projectId = projectid;
        this.description = description;
        this.country = country;
        this.region = region;
        this.polygon = polygon;
    }

    public getExtent(): number[] {
        const xs = this.polygon.map((p) => p.x);
        const ys = this.polygon.map((p) => p.y);
        const minx = Math.min(...xs);
        const miny = Math.min(...ys);
        const maxy = Math.max(...ys);
        const maxx = Math.max(...xs);

        return [
            minx, miny,
            maxx, maxy,
        ];
    }
}
