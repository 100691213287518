import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainedButton, OutlinedButton, TextButton } from '@orbit/button';
import { OutlinedTextField } from '@orbit/text-field';
import { MainPalettes } from '@orbit/theme-provider';

import { DateHelper } from '../../../helpers/date-helper';
import { LocalizationKeys } from '../../../locales/types';
import { ReviewStatus } from '../../../models/review-status';
import { TooltipDark } from '../../presets/preset/tooltip-dark';
import {
    ReviewListItemProps, ReviewListItemRequestProps,
    ReviewListItemRespondingProps
} from '../review-list.types';
import {
    useRequestReviewListItem, useRequestingListItem, useResolvedListItem,
    useRespondingListItem
} from './request-review-list-item.hooks';

export const RequestReviewListItem = (props: ReviewListItemProps): JSX.Element | null => {
    const {
        responseStatus,
        review,
        handleResponseClick,
        handleCancelResponseClick,
    } = useRequestReviewListItem(props);

    if (review.completionDateTime) return <ResolvedListItem review={review} />;

    if (responseStatus) return (
        <RespondingListItem
            review={review}
            responseStatus={responseStatus}
            onCancelClick={handleCancelResponseClick}
        />
    );

    return <RequestingListItem review={review} onResponseClick={handleResponseClick} />;
};

const ResolvedListItem = (props: ReviewListItemProps): JSX.Element => {
    const { buildId, handleDismissClick } = useResolvedListItem(props);
    const { t } = useTranslation();

    return (
        <div className="resolved">
            <div className="status">{t(LocalizationKeys.Resolved)}</div>
            <div className="description">{t(LocalizationKeys.ReviewRequestResolved, { buildId })}</div>
            <div className="footer">
                <TextButton onClick={handleDismissClick}>{t(LocalizationKeys.Dismiss)}</TextButton>
            </div>
        </div>
    );
};

const RespondingListItem = (props: ReviewListItemRespondingProps): JSX.Element => {
    const {
        responseStatus,
        note,
        buildId,
        handleNoteChange,
        handleCancelClick,
        handleResolveClick,
    } = useRespondingListItem(props);
    const { t } = useTranslation();

    return (
        <div className="item responding">
            <OutlinedTextField
                palette={MainPalettes.primary}
                label={t(responseStatus === ReviewStatus.Approved ? LocalizationKeys.ReviewRequestApproveLabel : LocalizationKeys.ReviewRequestRejectLabel, { buildId })}
                value={note}
                placeholder={t(LocalizationKeys.ReviewRequestNotePlaceholder)}
                onChange={handleNoteChange}
                autoFocus
            />
            <div className="footer">
                <OutlinedButton onClick={handleCancelClick}>{t(LocalizationKeys.Cancel)}</OutlinedButton>
                <ContainedButton onClick={handleResolveClick}>
                    {t(responseStatus === ReviewStatus.Approved ? LocalizationKeys.Approve : LocalizationKeys.Reject)}
                </ContainedButton>
            </div>
        </div>
    );
};

const RequestingListItem = (props: ReviewListItemRequestProps): JSX.Element => {
    const {
        hovered,
        review,
        handleMouseEnter,
        handleMouseLeave,
        handleShowBuildClick,
        handleResponseClick,
    } = useRequestingListItem(props);
    const { t } = useTranslation();
    const {
        buildId,
        submissionDateTime,
        submittedByName: name,
        submittedByEmail: email,
        submissionNotes,
    } = review;

    return (
        <div className={classNames('item', 'request', { hovered })} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="title">
                {t(LocalizationKeys.ReviewRequestTitle, {
                    buildId,
                    submissionDateTime: DateHelper.formatDateTimetoLocale(submissionDateTime),
                    interpolation: { escapeValue: false },
                })}
                <div className="response-buttons">
                    <TooltipDark arrow={true} placement="bottom" title={t(LocalizationKeys.LoadBuild)?.toLowerCase()}>
                        <TextButton onClick={handleShowBuildClick}><span className="material-icons">search</span></TextButton>
                    </TooltipDark>
                    <TooltipDark arrow={true} placement="bottom" title={t(LocalizationKeys.RejectBuild)?.toLowerCase()}>
                        <TextButton onClick={handleResponseClick(ReviewStatus.Rejected)}><span className="material-icons">highlight_off</span></TextButton>
                    </TooltipDark>
                    <TooltipDark arrow={true} placement="bottom" title={t(LocalizationKeys.ApproveBuild)?.toLowerCase()}>
                        <TextButton onClick={handleResponseClick(ReviewStatus.Approved)}><span className="material-icons">check_circle_outline</span></TextButton>
                    </TooltipDark>
                </div>
            </div>
            <div className="submitter">{t(LocalizationKeys.ReviewRequestFrom, { name, email })}</div>
            <div className="message">{submissionNotes}</div>
        </div>
    );
};
