import { store } from '../dna';
import { Element } from '../models/element';
import { MapClickEvent } from '../models/map-click-event';
import { Parcel } from '../models/parcel';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { DialogType, showDialog } from '../redux/overlay.state';
import {
    clearSelection, selectBore, selectBuild, selectDesignArea, selectElement, selectNap,
    selectParcel, selectPole, selectSegment, selectTap, selectTapSchrodinger, selectTerminal, selectSplicePoint,
    selectTether, selectTrench
} from '../redux/selection.state';
import { Tether } from '../models/tether';
import { notificationClicked } from '../redux/map.state';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class SelectionTool {

    public static definition: ToolDefinition = {
        toolType: ToolType.Selection,
        name: 'Selection',
        shortcut: 's',
    };

    public static execute(event: MapClickEvent): void {
        const selectedItem = event.items && event.items.length && event.items[0]; // first feature is topmost
        const state = store.getState();
        store.dispatch(clearSelection());
        store.dispatch(notificationClicked());
        if (!selectedItem) {
            return;
        }
        const featureId = selectedItem.id;

        if (featureId === undefined) {
            return;
        }

        switch (selectedItem.type) {
            case InteractableFeatureType.POLE: SelectionTool.selectPole(featureId); break;
            case InteractableFeatureType.NAP: SelectionTool.selectNap(featureId); break;
            case InteractableFeatureType.TAP: SelectionTool.selectTap(featureId, selectedItem.napId, state.selection.selectedTetherId, state.taps.tethers.filter(t => t.tapId === featureId).sort((a, b) => a.id - b.id), !!state.build.flexnapBuilds.find((b) => b.pretermLateral?.parentNapId === selectedItem.napId)); break;
            case InteractableFeatureType.TERMINAL: SelectionTool.selectTerminal(featureId, selectedItem.napId, state.taps.tethers.find(t => t.terminal?.id === featureId)?.id); break;
            case InteractableFeatureType.SPLICE_POINT: SelectionTool.selectSplicePoint(featureId); break;
            case InteractableFeatureType.FLEXNAP_SEGMENT: SelectionTool.selectSegment(featureId, selectedItem.buildId); break;
            case InteractableFeatureType.SCHRODINGER_SEGMENT: SelectionTool.selectSegment(featureId, selectedItem.buildId); break;
            case InteractableFeatureType.BULK_SEGMENT: SelectionTool.selectSegment(featureId, selectedItem.buildId); break;
            case InteractableFeatureType.PARCEL: SelectionTool.selectParcel(featureId, state.parcel.parcels); break;
            case InteractableFeatureType.CABINET: SelectionTool.selectElement(featureId, state.cabinet.cabinets); break;
            case InteractableFeatureType.MANHOLE: SelectionTool.selectElement(featureId, state.manhole.manholes); break;
            case InteractableFeatureType.HANDHOLE: SelectionTool.selectElement(featureId, state.handhole.handholes); break;
            case InteractableFeatureType.VAULT: SelectionTool.selectElement(featureId, state.vault.vaults); break;
            case InteractableFeatureType.TRENCH: SelectionTool.selectTrench(featureId); break;
            case InteractableFeatureType.BORE: SelectionTool.selectBore(featureId); break;
            case InteractableFeatureType.TAP_SCHRODINGER: SelectionTool.selectTapSchrodinger(featureId); break;
            case InteractableFeatureType.LOCKED_BUILD:
            case InteractableFeatureType.REJECTED_BUILD:
            case InteractableFeatureType.EXPORTED_BUILD: SelectionTool.selectBuildAndShowUnlockDialog(selectedItem.buildId); break;
            case InteractableFeatureType.DESIGN_AREA: SelectionTool.selectDesignArea(featureId); break;
        }
    }

    private static selectPole(id: number) {
        store.dispatch(selectPole(id));
    }

    private static selectNap(id: number) {
        store.dispatch(selectNap(id));
    }

    private static selectTap(id: number, napId: any, selectedTetherId: any, tethers: Tether[] | undefined, isPretermParentNap: boolean): void {
        store.dispatch(selectNap(napId));
        if (tethers !== undefined && !isPretermParentNap) {
            if (tethers.length === 1) {
                store.dispatch(selectTether(tethers[0].id));
                store.dispatch(selectTerminal(tethers[0].terminal?.id));
            } else {
                if (selectedTetherId !== tethers[0].id) {
                    store.dispatch(selectTether(tethers[0].id));
                    store.dispatch(selectTerminal(tethers[0].terminal?.id));
                } else {
                    store.dispatch(selectTether(tethers[1].id));
                    store.dispatch(selectTerminal(tethers[1].terminal?.id));
                }
            }
        }
        store.dispatch(selectTap(id));
    }

    private static selectTerminal(terminalId: number, napId: any, tetherId?: number) {
        store.dispatch(selectNap(napId));
        if (tetherId) {
            store.dispatch(selectTether(tetherId));
        }
        store.dispatch(selectTerminal(terminalId));
    }

    private static selectSplicePoint(id: number) {
        store.dispatch(selectSplicePoint(id));
    }

    private static selectSegment(segmentId: number, buildId: any) {
        if (segmentId === -1) {
            return;
        }
        store.dispatch(selectSegment(segmentId));
        store.dispatch(selectBuild(buildId));
    }

    private static selectParcel(id: number, parcels: Parcel[]) {
        const parcel = parcels.find((p) => p.id === id);
        store.dispatch(selectParcel(parcel));
    }

    private static selectElement(id: number, elements: Element[]) {
        const element = elements.find((e) => e.id === id);
        store.dispatch(selectElement(element));
    }

    private static selectBore(id: number) {
        store.dispatch(selectBore(id));
    }

    private static selectTrench(id: number) {
        store.dispatch(selectTrench(id));
    }

    private static selectTapSchrodinger(id: number): void {
        store.dispatch(selectTapSchrodinger(id));
    }

    private static selectBuildAndShowUnlockDialog(buildId?: number) {
        store.dispatch(selectBuild(buildId));
        store.dispatch(showDialog(DialogType.UnlockBuild));
    }

    private static selectDesignArea(id: number) {
        store.dispatch(selectDesignArea(id));
    }
}
