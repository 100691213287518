import { IPointData } from 'pixi.js';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReviewStatus } from '../../../../models/review-status';
import { SchrodingerBuild } from '../../../../models/schrodinger-build';
import { unlockBuilds } from '../../../../redux/build.state';
import { workspaceSelector } from '../../../../selectors/root.selectors';
import { BUTTON_PROPORTIONS, IFrameProps } from '../build.types';
import ApprovedIcon from './assets/approved-icon.svg';
import ExportIcon from './assets/exported-icon.svg';
import LockedIcon from './assets/locked-icon.svg';
import PendingIcon from './assets/pending-review-icon.svg';
import RejectedIcon from './assets/rejected-icon.svg';
import UnlockedIcon from './assets/unlocked-icon.svg';
import { CanvasStatusButtonProps } from './status-button.types';

export const getLocationPosition = (position: IPointData) => {
    const { x, y } = position;
    const xPos = x + BUTTON_PROPORTIONS.X_AXIS;
    const yPos = y + BUTTON_PROPORTIONS.Y_AXIS;
    return { x: xPos, y: yPos }
}

const getImageAndInteraction = (isHovered: boolean, build: SchrodingerBuild): { image: string | undefined, interactive: boolean } => {
    if (build.catalogCode)
        return {
            image: ExportIcon,
            interactive: false,
        }
    if (build.lastReviewStatus === ReviewStatus.Approved)
        return {
            image: ApprovedIcon,
            interactive: false,
        };
    if (build.lastReviewStatus === ReviewStatus.Rejected)
        return {
            image: RejectedIcon,
            interactive: false,
        };
    if (build.lastReviewStatus === ReviewStatus.Open)
        return {
            image: PendingIcon,
            interactive: false,
        };
    if (build.lockedById)
        return {
            image: isHovered ? UnlockedIcon : LockedIcon,
            interactive: true,
        };
    return {
        image: undefined,
        interactive: false,
    };
};

export const useStatusButton = (props: IFrameProps & CanvasStatusButtonProps) => {
    const { position, build } = props;
    const { x: xPositionButton, y: yPositionButton } = getLocationPosition(position);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const [isHovered, setIsHovered] = useState(false);
    const workspaceID = currentWorkspace?.id;
    const { id: buildId } = build;

    const dispatch = useDispatch();

    const anchor = { x: 0, y: 0.5 };
    const { image, interactive } = getImageAndInteraction(isHovered, build);

    const handleMouseOver = () => setIsHovered(true);

    const handleMouseOut = () => setIsHovered(false);

    const handleClick = useCallback(() => {
        if (buildId && workspaceID) {
            dispatch(unlockBuilds([buildId], workspaceID));
        }
    }, [dispatch, buildId, workspaceID]);

    return {
        xPositionButton, yPositionButton,
        anchor,
        image,
        interactive,
        handleMouseOver,
        handleMouseOut,
        handleClick,
    };
};
