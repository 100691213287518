import '@orbit/select/build/index.css';

import React, { ChangeEvent } from 'react';

import { OutlinedSelect, SelectItem } from '@orbit/select';
import { MainPalette, MainPalettes } from '@orbit/theme-provider';

export interface SelectObjectProps {
    palette?: MainPalette;
    label?: string;
    name: string;
    value: any;
    values: any[];
    valueProp: string;
    displayProp: string;
    emptyChoice?: string;
    disabled?: boolean;
    dense?: boolean;
    adornmentPosition?: 'start' | 'end';
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SelectObject = (props: SelectObjectProps): JSX.Element => {
    const { palette = MainPalettes.primary, label, name, value, values, valueProp, displayProp, onChange, emptyChoice, disabled, dense, adornmentPosition } = props;
    const options = emptyChoice ? [<SelectItem key="empty">{emptyChoice}</SelectItem>] : [];
    options.push(...values.map((v, i) => <SelectItem key={i} value={v[valueProp]}>{v[displayProp]}</SelectItem>));

    return (
        <OutlinedSelect palette={palette} name={name} key={name} label={label} value={value} disabled={disabled} dense={dense} onChange={onChange} adornmentPosition={adornmentPosition}>
            {options}
        </OutlinedSelect>
    );
};
