import { PointLike } from '../../models/pointlike';
import { Data } from './data';

export class PoleData extends Data {
    public tag: string;
    public point: PointLike;

    constructor(id: number, tag: string, point: PointLike) {
        super(id);
        this.tag = tag;
        this.point = point;
    }

    public getExtent(): number[] {
        const { x, y } = this.point;
        return [x, y, x, y];
    }
}
