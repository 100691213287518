/* eslint-disable @typescript-eslint/no-explicit-any */
import './card.component.scss';

import React, { SyntheticEvent } from 'react';

import { Mark } from '@mui/base';
import { Checkbox, Slider } from '@mui/material';

import { FontTokens, Typography } from '@orbit/theme-provider';
import { EditText } from '../ui-elements/edit-text';
import { Select } from '../ui-elements/select';
import { SelectObject, SelectObjectProps } from '../ui-elements/select-object';
import { InlineText } from '../ui-elements/inline-text';
import { CardInputProps, CardSelectProps } from './card.types';


export const CardProperty = (name: any, value: any, key: any = name): JSX.Element => {
    return (
        <div key={key} className="split-item">
            <span className='item-name'><Typography variant='body1' label={name} /></span>
            <span className='item-value'><Typography variant='inputText' label={value} fontToken={FontTokens.disabled} /></span>
        </div>
    );
};

export const CardPropertyInline = (name: string, value: string | number, units?: any, key: any = name): JSX.Element => {
    return (
        <InlineText key={key} label={name} value={value} units={units} disabled />
    );
};

export const CardSelect = (props: CardSelectProps): JSX.Element => {
    const { label, name, value, values, disabled, displayValues, onChange } = props;
    return (
        <div className="card-select">
            <Select label={label} name={name} value={value} values={values} displayValues={displayValues} disabled={disabled} onChange={onChange} />
        </div>
    );
};

export const CardObjectSelect = ({
    label,
    name,
    value,
    values,
    valueProp,
    displayProp,
    emptyChoice,
    onChange,
    disabled,
}: SelectObjectProps): JSX.Element => (
    <div className="card-select">
        <SelectObject
            label={label}
            name={name}
            value={value}
            values={values}
            valueProp={valueProp}
            displayProp={displayProp}
            emptyChoice={emptyChoice}
            onChange={onChange}
            disabled={disabled}
        />
    </div>
);

export const CardInputWithCount = (props: CardInputProps): JSX.Element => {
    const { label, name, value, type, maxLength, disabled, onChange, onKeyDown, error, helperText } = props;
    const characterCount = value ? (value).length : 0;

    return (
        <EditText
            label={label}
            name={name}
            type={type ?? "text"}
            value={value}
            error={error}
            helperText={helperText}
            onChange={onChange}
            onKeyDown={onKeyDown}
            characterCounter={characterCount}
            maxCharacterLength={maxLength}
            disabled={disabled}
        />
    );
};

export const CardInput = (props: CardInputProps): JSX.Element => {
    const { label, name, value, type, min, step, disabled, onChange, onKeyDown, error, helperText, trailingIcon, units } = props;
    return (
        <EditText
            label={label}
            name={name}
            value={value}
            type={type ?? "text"}
            min={min}
            step={step}
            error={error}
            disabled={disabled}
            helperText={helperText}
            trailingIcon={trailingIcon}
            units={units}
            onChange={onChange}
            onKeyDown={onKeyDown}
        />
    );
};

export const CardSlider = (
    label: string,
    name: string,
    min: number,
    max: number,
    marks: Mark[],
    value: number,
    step: number | null,
    onChange: (event: Event, value: number | number[]) => void,
    onChangeCommitted?: (event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => void,
    valueLabelFormat?: (value: number, label: number) => React.ReactNode,
    disabled?: boolean): JSX.Element => {
    return (
        <div className="slider-container">
            <label className="caption">{label}</label>
            <Slider
                min={min}
                max={max}
                marks={marks}
                value={value}
                step={step}
                valueLabelDisplay="auto"
                onChange={onChange}
                onChangeCommitted={onChangeCommitted}
                valueLabelFormat={valueLabelFormat}
                disabled={disabled}
            />
        </div>
    );
};

export const CardCheckbox = (
    label: string,
    checked: boolean,
    onChange: (event: React.ChangeEvent<any>) => void,
    disabled?: boolean): JSX.Element => {
    return (
        <div className="checkbox-container">
            <Checkbox
                className={disabled ? "checkbox-disabled" : "checkbox-checked"}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <label className="caption">{label}</label>
        </div>
    );
};
