import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { ToolType } from '../../../design-tools/tooltype';
import { LocalizationKeys } from '../../../locales/types';
import { DataValue } from '../../../models/datavalue';
import { Units } from '../../../models/units';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { InlineText } from '../../ui-elements/inline-text';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';
import { useBoreCard } from './bore-card.hooks';

export const BoreCard = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        selectedBore, calculatedLength, measuredLength, measuredLengthIsValid, displayUnits, containsSegment, selectedTool,
        unselectBore, deleteBore, handleLengthUpdate, handleIdOverrideUpdate, validateMeasuredLength
    } = useBoreCard();

    const renderOverrideId = useCallback((idOverride: string): JSX.Element => {
        return (
            <InlineText
                id="idOverride"
                label={t(LocalizationKeys.MandatoryField, { fieldName: t(LocalizationKeys.IDOverride) })}
                value={idOverride}
                align={'right'}
                onUpdate={handleIdOverrideUpdate}
            />
        );
    }, [t, handleIdOverrideUpdate]);

    const renderMeasuredLength = useCallback((measuredLength: DataValue, displayUnits: Units): JSX.Element => {
        return (
            <InlineText
                id="measuredLength"
                label={t(LocalizationKeys.MeasuredLength)}
                value={measuredLength.toUnit(displayUnits).value}
                units={UnitInterpreter.toShort(displayUnits)}
                onUpdate={handleLengthUpdate}
                onInputChange={validateMeasuredLength}
                onCancel={() => validateMeasuredLength(measuredLength.toUnit(displayUnits).value)}
                align={'right'}
                error={!measuredLengthIsValid}
                errorMessage={t(LocalizationKeys.OnlyPositiveValues)}
            />
        );
    }, [t, handleLengthUpdate, validateMeasuredLength, measuredLengthIsValid]);

    if (!selectedBore || selectedTool === ToolType.Modification) {
        return null;
    }

    return (
        <Card id="bore-info" onCloseClick={unselectBore}>
            <CardHeaderComponent title={t(LocalizationKeys.Bore)} subheader={selectedBore.tagOverride ?? selectedBore.tag} icon={require('./../../../icons/bore.png')} />
            <CardContentComponent>
                {renderOverrideId(selectedBore.tagOverride ?? selectedBore.tag)}
                {calculatedLength && CardPropertyInline(t(LocalizationKeys.CalculatedLength), `${calculatedLength.value.toFixed(2)}`, UnitInterpreter.toShort(displayUnits))}
                {measuredLength && renderMeasuredLength(measuredLength, displayUnits)}
                <div className="split-item extra-info">{t(LocalizationKeys.IdUsedForCableLabeling)}</div>
                <div className="card-footer">
                    <TextButton palette={MainPalettes.error} onClick={deleteBore} disabled={containsSegment}>
                        <i className="material-icons">delete</i>
                    </TextButton>
                </div>
            </CardContentComponent>
        </Card>
    );
}
