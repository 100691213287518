import { DataValue } from '../../models/datavalue';
import { Data } from "./data";
import { DesignAreaData } from "./design-area-data";
import { SegmentData } from "./segment-data";

export class BuildData extends Data {
    public name: string;
    public fieldSlack: DataValue;
    public segments: SegmentData[];
    public designAreas: DesignAreaData[];

    constructor(id: number, name: string, fieldSlack: DataValue, segments: SegmentData[], designAreas: DesignAreaData[]) {
        super(id);
        this.name = name;
        this.fieldSlack = fieldSlack
        this.segments = segments;
        this.designAreas = designAreas;
    }

    public getExtent(): number[] {
        const points = this.segments.flatMap((s) => [s.from.location, s.to.location]);
        const xs = points.map((p) => p.x);
        const ys = points.map((p) => p.y);
        const minx = Math.min(...xs);
        const miny = Math.min(...ys);
        const maxy = Math.max(...ys);
        const maxx = Math.max(...xs);

        return [
            minx, miny,
            maxx, maxy,
        ];
    }

    public getExtentCenter(): number[] {
        const extent = this.getExtent();
        return [
            (extent[0] + extent[2]) / 2,
            (extent[1] + extent[3]) / 2
        ];
    }
}