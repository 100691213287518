import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../../locales/types';
import { DialogComponent } from '../../../dialogs/dialog.component';
import { DeleteDesignAreaProps } from '../design-area.types';

export const DeleteDesignAreaDialog = (props: DeleteDesignAreaProps): JSX.Element | null => {
    const { t } = useTranslation();

    const rootElement = document.getElementById('react-app-root');

    return !props.open || !rootElement ? null :
        createPortal(
            <DialogComponent
                open={props.open}
                title={t(LocalizationKeys.DeleteDesignArea, { designAreaId: props.designAreaId })}
                message={t(LocalizationKeys.DeleteDesignAreaMessage)}
                positiveTitle={t(LocalizationKeys.Delete)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={props.onDelete}
                onNegativeClick={props.onClose}
                negativeStyleButton
            />,
            rootElement
        );
};
