import { useCallback, useEffect, useRef, useState } from 'react';

import { PresetRowProps } from './preset-row.types';

export const usePresetRow = (props: PresetRowProps) => {
    const { id, name, icon, isDefault, selected, createdByUserName, onSelect, onSetAsDefault } = props;
    const [className, setClassName] = useState(selected ? 'preset-row--selected' : 'preset-row');
    const ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
        setClassName(selected ? 'preset-row--selected' : 'preset-row');
        if (selected && ref.current) {
            // Scroll newly added preset to view if it is added at the end of a long list
            ref.current.scrollIntoView();
        }
    }, [selected]);

    const handleOnSelect = useCallback(() => {
        onSelect(id);
    }, [id, onSelect]);

    const handleOnSetAsDefault = useCallback(() => {
        if (onSetAsDefault) {
            onSetAsDefault(id);
        }
    }, [id, onSetAsDefault]);

    return {
        id, name, icon, isDefault, className, createdByUserName, onSelect: handleOnSelect, onSetAsDefault: handleOnSetAsDefault, ref
    };
};