import React from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipLocalizationKeys } from '../../../../locales/types';
import { Card } from '../../../card/card';
import { CardHeaderComponent } from '../../../card/card-header.component';
import { SegmentCard } from '../../segment/segment-card';
import { BuildProperties } from '../build-properties/build-properties';
import { SchrodingerCableCard } from '../schrodinger-cable/schrodinger-cable-card';
import { useBuildCard } from './build-card.hooks';
import { BuildCardProps } from './build-card.types';

export const BuildCard = (props: BuildCardProps): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        collapsed,
        title,
        icon,
        build,
        subunitCount,
        editDisabled,
        segment,
        handleCloseClick,
        handleToggleCollapse,
    } = useBuildCard(props);

    return (
        <Card
            id="schrodinger-build-info"
            onCloseClick={handleCloseClick}
            onToggleCollapse={handleToggleCollapse}
            expandTooltip={t(TooltipLocalizationKeys.ExpandAll)}
            collapseTooltip={t(TooltipLocalizationKeys.CollapseAll)}
        >
            <CardHeaderComponent title={title} subheader="Components" tooltip={title} icon={icon} iconBorder={true} />
            <BuildProperties build={build} collapsed={collapsed} />
            <SchrodingerCableCard build={build} subunitCount={subunitCount} collapsed={collapsed} disabled={editDisabled} />
            {segment && <SegmentCard segment={segment} build={build} collapsed={collapsed} disabled={editDisabled} />}
        </Card>
    );
};
