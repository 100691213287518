import { useCallback, useEffect, useRef, useState } from 'react';

import { ConfigSession } from '../models/config-session';
import { Cstic } from '../models/cstic';
import { NotificationService } from '../services/notification.service';
import { SchrodingerPresetService } from '../services/schrodinger-preset.service';

interface SessionProps {
    presetId?: number;
    errorCallback?: () => void;
}

const loadingTimer = 1000;

export const useSscSession = ({ errorCallback, presetId }: SessionProps) => {
    const [session, setSession] = useState<ConfigSession | undefined>();
    const [loading, setLoading] = useState(false);

    const timer = useRef<NodeJS.Timeout | null>(null);

    const applyPresetToSession = useCallback((session) => {
        if (!session || !presetId) {
            return;
        }
        const service = new SchrodingerPresetService();
        service.applyPresetToSession(session.sessionId, presetId)
            .then((newSession) => {
                setSession(newSession);
            })
    }, [presetId]);

    useEffect(() => {
        setLoading(true);
        const service = new SchrodingerPresetService();
        service.startPreset()
            .then((session) => {
                setSession(session);
                applyPresetToSession(session);
                if (!session && errorCallback) {
                    errorCallback();
                }
                setLoading(false);
            })
            .catch((err) => {
                if (errorCallback) {
                    errorCallback();
                }
                setLoading(false);
                NotificationService.error(err);
            });
    }, [errorCallback, applyPresetToSession]);

    const startTimer = useCallback(() => {
        timer.current = setTimeout(() => {
            setLoading(true);
        }, loadingTimer);
    }, []);

    const stopTimer = useCallback(() => {
        timer.current && clearTimeout(timer.current);
        setLoading(false);
    }, []);

    const updateSession = useCallback(async (instanceId: string, csticId: string, valueId: string) => {
        if (!session) {
            return;
        }
        setLoading(true);

        try {
            const service = new SchrodingerPresetService();
            const res = await service.next(session.sessionId, instanceId, csticId, valueId);
            setSession(res);
        }
        catch (err) {
            if (errorCallback) {
                errorCallback();
            }
            NotificationService.error(err as any);
        } finally {
            setLoading(false);
        }
    }, [errorCallback, session, setLoading]);

    const undoCstics = useCallback(async (cstics: Cstic[]) => {
        if (!session) {
            return;
        }
        startTimer();

        const service = new SchrodingerPresetService();
        let updatedSession: ConfigSession | undefined = session;
        for (const cstic of cstics) {
            try {
                updatedSession = await service.next(session.sessionId, cstic.instanceId, cstic.id, "");
                if (!updatedSession && errorCallback) {
                    errorCallback();
                    return;
                }
            }
            catch (err) {
                if (errorCallback) {
                    errorCallback();
                }
                NotificationService.error(err as any);
            }
        }
        setSession(updatedSession);
        stopTimer();
    }, [session, startTimer, stopTimer, errorCallback]);

    return { session, loading, updateSession, undoCstics };
}