import './thirdparty.component.scss';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { LocalizationKeys } from '../../locales/types';
import { DialogType, showDialog } from '../../redux/overlay.state';
import { overlaySelector } from '../../selectors/root.selectors';
import { DialogComponent } from '../dialogs/dialog.component';
import { OSS } from './thirdparty';

export const ThirdpartyComponent = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dialog } = useSelector(overlaySelector);

    const onClose = useCallback(() => {
        dispatch(showDialog());
    }, [dispatch]);

    return (
        <DialogComponent className="dialog-tdp" open={dialog === DialogType.Thirdparty} onClose={onClose} title={t(LocalizationKeys.ThirdPartyOssCopyright)}>
            <Table>
                <TableHead>
                    <TableRow className="text-common text-head">
                        <TableCell className="width-component">{t(LocalizationKeys.Component)}</TableCell>
                        <TableCell className="width-version">{t(LocalizationKeys.Version)}</TableCell>
                        <TableCell className="width-disclaimer">{t(LocalizationKeys.Disclaimer)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {OSS.thirdparties.map((element) => (
                        <TableRow className="text-common" key={element.project}>
                            <TableCell className="width-component">
                                <a className="oss-component clickable" href={element.license}>{element.project}</a>
                            </TableCell>
                            <TableCell className="width-version">{element.version}</TableCell>
                            <TableCell className="width-disclaimer">{element.disclaimer}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </DialogComponent>
    );
};
