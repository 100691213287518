import { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSscSession } from '../../../../hooks/ssc-session.hooks';
import { UpdatePresetRequest } from '../../../../services/schrodinger-preset.service';
import { updatePreset } from '../../../../redux/schrodinger-preset.state';
import { PresetManagerContext } from '../../preset-manager.types';
import { EditPresetProps } from './edit-preset.types';
import { CardInputMaxLength } from '../../../card/card.types';

export const useEditPreset = (props: EditPresetProps) => {
    const { preset, onCancel } = props;
    const [presetName, setPresetName] = useState(preset?.name);
    const [showSummary, setShowSummary] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const isPresetNameValid = presetName && presetName.trim().length !== 0;

    const dispatch = useDispatch();

    const { updateConfigMode } = useContext(PresetManagerContext);

    const { session, loading, updateSession, undoCstics } = useSscSession({ presetId: preset.id, errorCallback: updateConfigMode });

    const handlePresetNameChange = (event: React.ChangeEvent<any>): void => {
        if (event.target.name === "presetName") {
            const newPresetName = event.target.value;
            if (newPresetName.length <= CardInputMaxLength.PRESET_NAME) {
                setPresetName(newPresetName);
            }
        } else {
            throw new Error('handlePresetNameChange needs a name');
        }
    }

    useEffect(() => {
        setShowForm(!!session?.coSlackMinimum.valueId && !!session.fieldSlackMinimum.valueId);
    }, [session])

    const goToValidation = useCallback(() => {
        setShowSummary(true);
    }, [setShowSummary]);

    const onSavePreset = useCallback((isDefault: boolean): void => {
        if (!preset || !session) {
            return;
        }
        if (isPresetNameValid && presetName.length <= CardInputMaxLength.PRESET_NAME) {
            const req: UpdatePresetRequest = {
                isDefault: isDefault,
                coSlack: parseFloat(session?.coSlack.valueId ?? "0"),
                fieldSlack: parseFloat(session?.fieldSlack.valueId ?? "0"),
                presetName
            }

            updatePreset(preset.id, req)(dispatch);

            if (onCancel) {
                onCancel();
            }

        }
    }, [preset, session, presetName, dispatch, onCancel, isPresetNameValid]);

    return {
        preset,
        presetName,
        session,
        loading,
        showSummary,
        showForm,
        isPresetNameValid,
        goToValidation,
        handlePresetNameChange,
        onCancel,
        updateSession,
        undoCstics,
        savePreset: onSavePreset
    };
}