import React from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';

import { ImportStepBuildsProgressProps } from '../import.card.types';
import { ImportSaveTableHeader, ImportSaveTableRow } from './import-save-table-row';

export const ImportStepSave = (props: ImportStepBuildsProgressProps): JSX.Element => {
    return (
        <div className='build-list'>
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <ImportSaveTableHeader />
                    <TableBody>
                        {props.importStatuses?.map(b =>
                            <ImportSaveTableRow key={b.buildId} status={b} buildType={props.buildTypes[b.buildId]} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}