import React from 'react';
import { ColorType, getBackgroundColorClassname } from '../../../models/color-type';
import { PipeColorProps } from './splice-plan-dialog.types';

export const PipeColor = ({ color, children }: PipeColorProps): JSX.Element => {
    const border = `1px solid ${color === ColorType.WHT ? '#ddd' : 'transparent'}`;
    const className = `splice-plan-dialog-pipe-color ${getBackgroundColorClassname(color)}`;

    return (
        <div style={{ border }} className={className}>
            {children}
        </div>
    );
};
