import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BuilderHelper } from '../../../helpers/build-helper';
import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import {
    FiberAssignmentType, getFiberAssignmentLabel
} from '../../../models/fiber-assignment-type';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { loadCabinetSplicePlanFromBuildId, setBuildIdRequestingCabinetSplicePlan } from '../../../redux/cabinet-splice-plan.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import {
    selectedBuildSelector, selectedFlexNapBuildIsPreterm
} from '../../../selectors/build.selectors';
import {
    bulkSplicePlanSelector, dialogSelector, flexnapBuildsSelector, flexNapSplicePlanSelector,
    schrodingerSplicePlanSelector, userIdSelector, workspaceSelector
} from '../../../selectors/root.selectors';
import { SplicePlanDialogProps } from './splice-plan-dialog.types';

export const useSplicePlanDialog = (): SplicePlanDialogProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [currentLocation, setCurrentLocation] = useState<number>(0);

    const dialog = useSelector(dialogSelector);
    const isPreterm = useSelector(selectedFlexNapBuildIsPreterm);
    const userId = useSelector(userIdSelector);
    const build = useSelector(selectedBuildSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const { startingPortNumber: flexNapStartingPortNumber = 0, fiberAssignment: flexNapFiberAssignment } = useSelector(flexNapSplicePlanSelector) || {};
    const { startingPortNumber: schrodingerStartingPortNumber = 0, fiberAssignment: schrodingerFiberAssignment } = useSelector(schrodingerSplicePlanSelector) || {};
    const { startingPortNumber: bulkStartingPortNumber = 0, fiberAssignment: bulkFiberAssignment } = useSelector(bulkSplicePlanSelector) || {};
    const flexnapBuilds = useSelector(flexnapBuildsSelector);

    const buildId = build?.id;
    const buildType = build?.type;
    const workspaceId = currentWorkspace?.id;
    const startingPortNumber = buildType === BuildType.FlexNap ? flexNapStartingPortNumber : buildType === BuildType.Schrodinger ? schrodingerStartingPortNumber : bulkStartingPortNumber;
    const fiberAssignment = buildType === BuildType.FlexNap ? flexNapFiberAssignment : buildType === BuildType.Schrodinger ? schrodingerFiberAssignment : bulkFiberAssignment;
    const parentBuild = flexnapBuilds.find(b => b.id === (build as FlexNapBuild)?.pretermLateral?.parentBuildId)
    const editDisabled = parentBuild ? parentBuild.modifiedById !== userId : build?.modifiedById !== userId;

    const canModify = !editDisabled && ((!isPreterm && buildType === BuildType.FlexNap) || buildType === BuildType.Bulk); // Limit to FlexNap and Bulk for now
    const isDialogOpen = dialog === DialogType.SplicePlan || dialog === DialogType.SplicePlanSetup;

    const titleLabel = build ? `${t(LocalizationKeys.SplicePlanDialogTitle)} ${BuilderHelper.getBuildDisplayId(build)}` : "";
    const fiberAssignmentLabel = getFiberAssignmentLabel(fiberAssignment || FiberAssignmentType.HighToLowCoToField);
    const setupLabel = `${fiberAssignmentLabel}; ${t(LocalizationKeys.SplicePlanPortStart, { startingPortNumber })}`;

    const onClose = useCallback(() => {
        dispatch(showDialog());
        setCurrentLocation(0);
    }, [dispatch]);
    const openSetup = useCallback(() => {
        if (buildId && workspaceId) {
            dispatch(setBuildIdRequestingCabinetSplicePlan(undefined));
            dispatch(loadCabinetSplicePlanFromBuildId(workspaceId, buildId));
        }
        dispatch(showDialog(DialogType.SplicePlanSetup))
    }, [buildId, dispatch, workspaceId]);

    return {
        isDialogOpen,
        onClose,
        openSetup,
        titleLabel,
        setupLabel,
        buildId,
        buildType,
        canModify,
        currentLocation,
        setCurrentLocation
    };
};
