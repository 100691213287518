import { UnitInterpreter } from '../helpers/unit-interpreter';
import { Units } from './units';

export interface DataValue {
    value: number;
    units: Units;
}

export class DataValue implements DataValue {
    public value: number;
    public units: Units;

    constructor(value: number, units: Units, digits?: number) {
        this.value = value;
        if (digits) {
            this.value = +value.toFixed(digits)
        }
        this.units = units;
    }

    public toUnit(units: Units, digits?: number): DataValue {
        const multiplier = this.getMultiplier(units);
        if (digits) {
            return new DataValue(+(this.value * multiplier).toFixed(digits), units);
        }
        return new DataValue(this.value * multiplier, units);
    }

    private getMultiplier(units: Units): number {
        if (this.units === units) {
            return 1;
        }
        return units === Units.feet ? 3.28084 : 0.3048;
    }

    public format(units: Units, fractionDigits: number): string {
        const convertedData = this.toUnit(units);
        return fractionDigits
            ? `${convertedData.value.toFixed(fractionDigits)} ${UnitInterpreter.toShort(convertedData.units)}`
            : `${convertedData.value} ${UnitInterpreter.toShort(convertedData.units)}`;
    }
}
