import { Element } from './element';
import { ElementType } from './element-type';
import { PointLike } from './pointlike';

export interface ElementDto {
    id: number;
    tag: string;
    tagOverride?: string;
    createdDate: Date;
    userId: number;
    groupId: number;
    isImported: boolean;
    type: ElementType;
    importedGisDataId?: number;
    point: PointLike;
}

export const toElementDto = (element: Element): ElementDto => {
    const { x, y, ...props } = element;
    return { point: { x, y }, ...props };
};
