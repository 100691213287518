import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogType, showDialog } from "../../../redux/overlay.state";
import { overlaySelector } from '../../../selectors/root.selectors';
import { SnackbarTypes } from '../../ui-elements/generic-snackbar/generic-snackbar.types';
import { RemoveGisDataSnackbar } from '../remove-gis-data.types';
import { LocalizationKeys } from '../../../locales/types';

export const useRemoveGisDataSnackbar = () => {
    const { t } = useTranslation();
    const { dialog } = useSelector(overlaySelector);
    const [snackbar, setSnackbar] = useState<RemoveGisDataSnackbar | undefined>();

    const dispatch = useDispatch();

    useEffect(() => {
        switch (dialog) {
            case DialogType.RemoveImportedGisDataProgress:
                setSnackbar({ type: SnackbarTypes.Warning, message: t(LocalizationKeys.RemoveGisDataProgressMessage), showProgress: true });
                break;
            case DialogType.RemoveImportedGisDataSuccess:
                setSnackbar({ type: SnackbarTypes.Success, message: t(LocalizationKeys.RemoveGisDataSuccessMessage) });
                break;
            default:
                break;
        }
    }, [t, dialog]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
        setSnackbar(undefined);
    }, [dispatch]);

    return {
        snackbar, onClose
    };
};