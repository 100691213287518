import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadImportedGisData, setImportedGisDataToRemove } from '../../redux/import.state';
import { DialogType, showControl, showDialog } from '../../redux/overlay.state';
import {
    importedGisDataSelector, isRemovingGisDataSelector
} from '../../selectors/import.selectors';
import { removeGisDataControlSelector } from '../../selectors/overlay.selectors';

export const useRemoveGisData = () => {
    const open = useSelector(removeGisDataControlSelector);
    const importedGisData = useSelector(importedGisDataSelector);
    const isRemovingGisData = useSelector(isRemovingGisDataSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        loadImportedGisData()(dispatch);
    }, [dispatch]);

    const handleControlClose = useCallback((): void => {
        dispatch(showControl());
    }, [dispatch]);

    const handleDelete = useCallback((id: number) => {
        return () => {
            dispatch(setImportedGisDataToRemove(id));
            dispatch(showDialog(DialogType.RemoveGisData));
            dispatch(showControl());
        }
    }, [dispatch]);

    return {
        open, importedGisData, isRemovingGisData, handleControlClose, handleDelete,
    }
};