import LineString from 'ol/geom/LineString';
import { getLength } from 'ol/sphere';
import { DataValue } from '../models/datavalue';
import { Path } from '../models/path';
import { PointLike } from '../models/pointlike';
import { Units } from '../models/units';

export class PathHelper {

    public static calculatePathLength = (route: PointLike[]): DataValue => {
        const length = getLength(new LineString(route.map((r) => [r.x, r.y])));
        return new DataValue(length, Units.meters);
    }

    public static findConnectedPath(paths: Path[], fromId: number, toId: number): Path | undefined {
        return paths.find((p) => {
            return (p.fromElementId === fromId && p.toElementId === toId)
                || (p.toElementId === fromId && p.fromElementId === toId);
        });
    }
}
