import { useTranslation } from 'react-i18next';

import { StateModel } from '../../redux/reducers';
import { LocalizationKeys } from '../../locales/types';

export const useAccessPointLocationId = (state: StateModel, elementId: number, buildId?: number | null): string => {
    const { t } = useTranslation();
    
    if (!elementId || !buildId) {
        return t(LocalizationKeys.Loc);
    }
    const { segments } = state.build;
    const buildSegments = segments.filter(s => s.buildId === buildId).sort((a, b) => a.position - b.position);
    const index = buildSegments.findIndex(s => s.toId === elementId || s.fromId === elementId);
    if (index < 0) {
        return t(LocalizationKeys.Loc);
    }
    const segment = buildSegments[index];
    const location = segment.toId === elementId ? index + 2 : index + 1;
    return `${location * 10 < 100 ? 0 : ''}${location * 10}`;
};