import { ElectricalUnits } from '../models/electricalUnits';
import { Units } from '../models/units';

export class UnitInterpreter {

    public static toShort(unit: Units | ElectricalUnits) {
        switch (unit) {
            case Units.feet: return 'ft';
            case Units.meters: return 'm';
            case ElectricalUnits.volts: return 'V';
            case ElectricalUnits.amperes: return 'A';
            case ElectricalUnits.watts: return 'W';
            case ElectricalUnits.kilowatts: return 'kW';
            default: return '';
        }
    }
}
