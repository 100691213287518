import i18next from 'i18next';

import { LocalizationKeys } from '../locales/types';

export enum ColorType {
    AQU = "AQU",
    BLK = "BLK",
    BLU = "BLU",
    BRN = "BRN",
    GRN = "GRN",
    ORG = "ORG",
    RED = "RED",
    ROS = "ROS",
    SLT = "SLT",
    VLT = "VLT",
    WHT = "WHT",
    YLW = "YLW",
    CPR = "CPR"
}

export const getColorType = (colorTypeLabel: string): ColorType | undefined => {
    switch (colorTypeLabel) {
        case 'Aqua':
            return ColorType.AQU;
        case 'Black':
            return ColorType.BLK;
        case 'Blue':
            return ColorType.BLU;
        case 'Brown':
            return ColorType.BRN;
        case 'Green':
            return ColorType.GRN;
        case 'Orange':
            return ColorType.ORG;
        case 'Red':
            return ColorType.RED;
        case 'Rose':
            return ColorType.ROS;
        case 'Slate':
            return ColorType.SLT;
        case 'Violet':
            return ColorType.VLT;
        case 'White':
            return ColorType.WHT;
        case 'Yellow':
            return ColorType.YLW;
        case 'Copper':
            return ColorType.CPR;
        default:
            return undefined;
    }
}

export const getBackgroundColorClassname = (colorType: ColorType): string => {
    switch (colorType) {
        case ColorType.AQU:
            return 'pipe-color-aqua';
        case ColorType.BLK:
            return 'pipe-color-black';
        case ColorType.BLU:
            return 'pipe-color-blue';
        case ColorType.BRN:
            return 'pipe-color-brown';
        case ColorType.GRN:
            return 'pipe-color-green';
        case ColorType.ORG:
            return 'pipe-color-orange';
        case ColorType.RED:
            return 'pipe-color-red';
        case ColorType.ROS:
            return 'pipe-color-rose';
        case ColorType.SLT:
            return 'pipe-color-slate';
        case ColorType.VLT:
            return 'pipe-color-violet';
        case ColorType.WHT:
            return 'pipe-color-white';
        case ColorType.YLW:
            return 'pipe-color-yellow';
        case ColorType.CPR:
            return 'pipe-color-copper';

        default:
            return ''
    }
}

export const getColorLabel = (colorType: ColorType): string => {
    switch (colorType) {
        case ColorType.AQU:
            return i18next.t(LocalizationKeys.ColorAqua);
        case ColorType.BLK:
            return i18next.t(LocalizationKeys.ColorBlack);
        case ColorType.BLU:
            return i18next.t(LocalizationKeys.ColorBlue);
        case ColorType.BRN:
            return i18next.t(LocalizationKeys.ColorBrown);
        case ColorType.GRN:
            return i18next.t(LocalizationKeys.ColorGreen);
        case ColorType.ORG:
            return i18next.t(LocalizationKeys.ColorOrange);
        case ColorType.RED:
            return i18next.t(LocalizationKeys.ColorRed);
        case ColorType.ROS:
            return i18next.t(LocalizationKeys.ColorRose);
        case ColorType.SLT:
            return i18next.t(LocalizationKeys.ColorSlate);
        case ColorType.VLT:
            return i18next.t(LocalizationKeys.ColorViolet);
        case ColorType.WHT:
            return i18next.t(LocalizationKeys.ColorWhite);
        case ColorType.YLW:
            return i18next.t(LocalizationKeys.ColorYellow);
        case ColorType.CPR:
            return i18next.t(LocalizationKeys.ColorCopper);

        default:
            return i18next.t(LocalizationKeys.ColorNo);
    }
}

