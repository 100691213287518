import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StateModel } from '../../../redux/reducers'
import { DrawerType } from '../../../redux/overlay.state';

export const useWorkspaceDrawer = () => {
    const { drawer } = useSelector((state: StateModel) => state.overlay);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(drawer === DrawerType.WorkspaceDrawer);
    }, [drawer]);

    return { open };
}