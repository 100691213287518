import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReviewStatus } from '../../../models/review-status';
import { archiveReview, finishReview } from '../../../redux/review.state';
import { useShowReviewBuild } from '../review-list.hooks';
import {
    ReviewListItemProps, ReviewListItemRequestProps,
    ReviewListItemRespondingProps
} from '../review-list.types';

export const useRequestReviewListItem = (props: ReviewListItemProps) => {
    const { review } = props;

    const [responseStatus, setResponseStatus] = useState<ReviewStatus>();

    const handleResponseClick = (status: ReviewStatus) => () => setResponseStatus(status);

    const handleCancelResponseClick = () => setResponseStatus(undefined);

    return {
        responseStatus,
        review,
        handleResponseClick,
        handleCancelResponseClick,
    };
};

export const useResolvedListItem = ({ review }: ReviewListItemProps) => {
    const { buildId } = review;

    const dispatch = useDispatch();

    const handleDismissClick = useCallback(() => {
        dispatch(archiveReview(review.id));
    }, [dispatch, review]);

    return {
        buildId,
        handleDismissClick,
    };
};

export const useRespondingListItem = ({ review, responseStatus, onCancelClick }: ReviewListItemRespondingProps) => {
    const [note, setNote] = useState<string>();

    const dispatch = useDispatch();

    const { buildId } = review;

    const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNote(e.currentTarget.value);
    };

    const handleResolveClick = useCallback(() => {
        dispatch(finishReview(review.id, responseStatus, note));
        onCancelClick();
    }, [dispatch, note, onCancelClick, responseStatus, review]);

    return {
        responseStatus,
        note,
        buildId,
        handleResolveClick,
        handleNoteChange,
        handleCancelClick: onCancelClick,
    };
};

export const useRequestingListItem = (props: ReviewListItemRequestProps) => {
    const { review, onResponseClick: handleResponseClick } = props;

    const [hovered, setHovered] = useState(false);

    const { handleShowBuildClick } = useShowReviewBuild(review);

    const handleMouseEnter = () => setHovered(true);

    const handleMouseLeave = () => setHovered(false);

    return {
        hovered,
        review,
        handleMouseEnter,
        handleMouseLeave,
        handleShowBuildClick,
        handleResponseClick,
    };
};