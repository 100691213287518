import { useCallback, useEffect, useMemo } from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useDispatch } from "react-redux";
import { addPresetAction, deletePresetAction, updatePresetAction } from '../../redux/preset.state';
import { DnaHubService } from '../../services/dnahub.service';
import { UserPreset } from '../../models/userpreset';
import { requestIsSuccess } from "../../redux/action";
import { DnaHubConstants, dnaHubUrl, hubOptions } from './dna-hub.types';

export const useDnaHub = (): void => {
    const dispatch = useDispatch();

    const service = useMemo(() => {
        return new DnaHubService();
    }, []);

    const connection = useMemo((): HubConnection => {
        return new HubConnectionBuilder()
            .withUrl(dnaHubUrl, hubOptions)
            .configureLogging(LogLevel.Information)
            .build();
    }, []);

    const setupConnection = useCallback(async () => {
        try {
            await connection.start();

            let connectionId = connection.connectionId;
            if (!connectionId) {
                connectionId = await connection.invoke(DnaHubConstants.GetConnectionId);
            }
            
            if (!connectionId) return;

            const success = await requestIsSuccess(service, service.addToGroup(connectionId));
            if (success) {
                connection.on(DnaHubConstants.AddPreset, (preset: UserPreset) => dispatch(addPresetAction(preset)));
    
                connection.on(DnaHubConstants.UpdatePreset, (preset: UserPreset) => dispatch(updatePresetAction(preset)));
    
                connection.on(DnaHubConstants.DeletePreset, (presetId: number) => dispatch(deletePresetAction(presetId)));
            }
        }
        // eslint-disable-next-line no-empty
        catch (_) { }
    }, [connection, service, dispatch]);

    useEffect(() => {
        if (connection.state !== HubConnectionState.Connected) {
            setupConnection();
        }

        return (): void => {
            if (connection.connectionId) {
                service.removeFromGroup(connection.connectionId);
            }
            connection.stop();
        };
    }, [connection, service, setupConnection]);
};