import React from 'react';

import { DesignMode } from '../../models/design-mode';
import { AboutComponent } from '../about/about.component';
import { ImportCard } from '../cards/import/import.card';
import { PresetManager } from '../presets/preset-manager';
import {
    RemoveGisDataDialog
} from '../remove-gis-data/remove-gis-data-dialog/remove-gis-data-dialog';
import {
    RemoveGisDataSnackbar
} from '../remove-gis-data/remove-gis-data-snackbar/remove-gis-data-snackbar';
import {
    UpdateInfrastructureSnackbar
} from '../snackbars/update-infrastructure/update-infrastructure-snackbar';
import { ThirdpartyComponent } from '../thirdparty/thirdparty.component';
import { BuildInformationDialog } from './build-information-dialog.component';
import { CabinetPortModeDialog } from './cabinet-port-mode/cabinet-port-mode-dialog';
import {
    CabinetPortNumberAutomaticDialog
} from './cabinet-port-number-automatic/cabinet-port-number-automatic-dialog';
import {
    CabinetPortNumberManualDialog
} from './cabinet-port-number-manual/cabinet-port-number-manual-dialog';
import { CableSelectionSnackbar } from './cable-selection-snackbar/cable-selection-snackbar';
import { AddCanvasSegmentDialog } from './canvas/segment/add-segment/add-segment-dialog';
import { DeleteCanvasSegmentDialog } from './canvas/segment/delete-segment/delete-segment-dialog';
import { ConvertBulkBuildDialog } from './convert-bulk-build/convert-bulk-build-dialog';
import { DeleteBulkBuildDialog } from './delete-build/delete-bulk-build-dialog';
import { DeleteFlexNapBuildDialog } from './delete-build/delete-flexnap-build-dialog';
import { DeleteSchrodingerBuildDialog } from './delete-build/delete-schrodinger-build-dialog';
import { useDialogs } from './dialogs.hooks';
import { DownloadGisDataDialog } from './download-gis-data/download-gis-data-dialog';
import { PresetSnackbar } from './preset-snackbar/preset-snackbar';
import { ConvertToPretermDialog } from './preterm-lateral/convert-to-preterm.dialog';
import { RevertPretermDialog } from './preterm-lateral/revert-preterm.dialog';
import { RequestReviewDialog } from './request-review-dialog/request-review-dialog';
import {
    ResetTerminalExtensionDialog
} from './reset-terminal-extension/reset-terminal-extension.dialog';
import {
    NoDefaultPresetWarningDialog
} from './schrodinger-preset-warning/no-default-preset-warning.dialog';
import { NoPresetWarningDialog } from './schrodinger-preset-warning/no-preset-warning.dialog';
import { SplicePlanDialog } from './splice-plan/splice-plan-dialog';
import { SplicePlanSetupDialog } from './splice-plan/splice-plan-setup-dialog';
import { UnlockBuildDialog } from './unlock-build.component';
import { UploadBuildDialog } from './upload-build-dialog/upload-build-dialog';

export const Dialogs = (): JSX.Element => {
    const { designMode } = useDialogs();
    return (
        <>
            <BuildInformationDialog />
            <AboutComponent />
            <ThirdpartyComponent />
            <SplicePlanDialog />
            <SplicePlanSetupDialog />
            <PresetManager />
            <UnlockBuildDialog />
            <DeleteFlexNapBuildDialog />
            <DeleteSchrodingerBuildDialog />
            <DeleteBulkBuildDialog />
            <ConvertToPretermDialog />
            <RevertPretermDialog />
            <ResetTerminalExtensionDialog />
            {designMode === DesignMode.GISMode && <CableSelectionSnackbar />}
            <PresetSnackbar />
            <RemoveGisDataSnackbar />
            <UpdateInfrastructureSnackbar />
            <DownloadGisDataDialog />
            <ImportCard />
            <NoPresetWarningDialog />
            <NoDefaultPresetWarningDialog />
            <AddCanvasSegmentDialog />
            <DeleteCanvasSegmentDialog />
            <UploadBuildDialog />
            <RequestReviewDialog />
            <ConvertBulkBuildDialog />
            <RemoveGisDataDialog />
            <CabinetPortModeDialog />
            <CabinetPortNumberManualDialog />
            <CabinetPortNumberAutomaticDialog />
        </>
    );
}
