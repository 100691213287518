import * as rfc from 'rfc6902';

import { CsticValue } from '../../models/cstic-value';
import { OpticalTetherOptions } from '../../models/schrodinger/optical-tether-options';
import { OpticalTetherWithOptionsDto } from '../../models/schrodinger/optical-tether-update-dto';
import { PowerTetherOptions } from '../../models/schrodinger/power-tether-options';
import { PowerTetherWithOptionsDto } from '../../models/schrodinger/power-tether-update-dto';
import { Tether } from '../../models/schrodinger/tether';
import { TetherType } from '../../models/schrodinger/tether-type';
import { SecuredService } from '../abstract-secured-v2.service';

export class TetherService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/schrodinger/tether';

    public getOpticalTetherOptions(tetherId: number): Promise<OpticalTetherOptions | undefined> {
        return this.get<OpticalTetherOptions>(`${this.baseUrl}/${tetherId}/optical`);
    }

    public getPowerTetherOptions(tetherId: number): Promise<PowerTetherOptions | undefined> {
        return this.get<PowerTetherOptions>(`${this.baseUrl}/${tetherId}/power`);
    }

    public updateOpticalTether(tetherId: number, updatedCsticValue: CsticValue): Promise<OpticalTetherWithOptionsDto | undefined> {
        return this.post<OpticalTetherWithOptionsDto>(`${this.baseUrl}/${tetherId}/optical`, updatedCsticValue);
    }

    public updatePowerTether(tetherId: number, updatedCsticValue: CsticValue): Promise<PowerTetherWithOptionsDto | undefined> {
        return this.post<PowerTetherWithOptionsDto>(`${this.baseUrl}/${tetherId}/power`, updatedCsticValue);
    }

    public createTether(tapId: number, tetherType: TetherType): Promise<Tether | undefined> {
        return this.post<Tether>(this.baseUrl, { tapId, tetherType });
    }

    public patchTether(oldTether: Tether, newTether: Tether): Promise<Tether | undefined> {
        const patch = rfc.createPatch(oldTether, newTether);
        return this.patch(`${this.baseUrl}/${oldTether.id}`, patch);
    }

    public deleteTether(id: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${id}`);
    }

    public undoDeleteTether(id: number): Promise<Tether | undefined> {
        return this.post(`${this.baseUrl}/undo/delete/${id}`);
    }
}
