import { CpqBuildConfiguration } from '../models/cpqbuildconfiguration';
import { CpqCablePart } from '../models/cpqcablepart';
import { CpqTetherPart } from '../models/cpqtetherpart';

export class PartHelper {

    public static buildFlexNapPartNumber(part: string, fiberCount: number | string): string {
        const prefix = 'FNAP-CBL-';
        const suffix = part.slice(12);
        return `${prefix}${String(fiberCount).padStart(3, '0')}${suffix}`;
    }

    public static getFlexNapPartNumberParts(part: string): { prefix: string; fibers: string; suffix: string } {
        const prefix = part.slice(0, 9);
        const fibers = part.slice(9, 12);
        const suffix = part.slice(12);
        return { prefix, fibers, suffix };
    }

    public static buildTetherPartNumber(part: string, fiberCount: number | string): string {
        const prefix = part.slice(0, 5);
        const suffix = part.slice(7);
        return `${prefix}${String(fiberCount).padStart(2, '0')}${suffix}`;
    }

    public static getTetherPartNumberParts(part: string): { prefix: string; fibers: string; suffix: string } {
        const prefix = part.slice(0, 5);
        const fibers = part.slice(5, 7);
        const suffix = part.slice(7);
        return { prefix, fibers, suffix };
    }

    public static getConfigurationWithParts(
        partCode: string,
        tetherPart: string,
        fiberCount: number,
        tetherFiberCount: number,
        parts: CpqCablePart[],
        tethers: CpqTetherPart[],
        configurations: CpqBuildConfiguration[],
        installationType?: string): { part: CpqCablePart; tether: CpqTetherPart; configuration: CpqBuildConfiguration } {
        const part = parts!.find((p) => p.part.match(`${partCode}$`) && fiberCount === p.fiber_Count);
        if (!part) {
            throw new Error(`Part "${partCode}" could not be found or is invalid.`);
        }
        const tether = tethers!.find((p) => {
            return p.installation_Type === installationType
                && PartHelper.matchesTetherPartNumber(p.tether_Item, tetherPart)
                && tetherFiberCount === p.fiber_Count;
        });
        if (!tether) {
            throw new Error(`Tether "${tetherPart}" could not be found or is invalid.`);
        }
        const configuration = configurations!.find((c) => PartHelper.matchesConfiguration(part, c) && c.taP_Type === tether.taP_Type && c.installation_Type === installationType);
        if (!configuration) {
            throw new Error('Build Configuration could not be found.');
        }

        return { part, tether, configuration };
    }

    private static matchesTetherPartNumber(value: string, tetherPart: string): boolean {
        const prefix = tetherPart.slice(0, 5);
        const suffix = tetherPart.slice(7);
        return value.match(`^${prefix}.*${suffix}$`) !== null;
    }

    public static getPartFiberCounts(parts: CpqCablePart[], tethers: CpqTetherPart[], part: string, tether: string, installationType?: string): { fiberCounts: number[]; tetherFiberCounts: number[] } {
        const tetherParts = tethers.filter((p) => p.installation_Type === installationType && PartHelper.matchesTetherPartNumber(p.tether_Item, tether));
        if (tetherParts.length > 0) {
            const tetherFiberCounts = tetherParts
                .map((p) => p.fiber_Count)
                .distinct()
                .sort(PartHelper.sortByAscending);
            const firstTether = tetherParts[0];
            const partCode = part.slice(12);
            const cableParts = parts.filter((p) => PartHelper.isCompatible(p, firstTether) && p.part.match(`${partCode}$`));
            const fiberCounts = cableParts
                .map((p) => p.fiber_Count)
                .distinct()
                .sort(PartHelper.sortByAscending);
            return { fiberCounts, tetherFiberCounts };
        }
        return { fiberCounts: [], tetherFiberCounts: [] };
    }

    public static getCableCompatibleFiberCounts(parts: CpqCablePart[], cablePart: string): number[] {
        const suffix = this.getCablePartSuffix(cablePart);

        return parts
            .filter((p) => this.getCablePartSuffix(p.part) === suffix)
            .map((p) => +p.fiber_Count)
            .distinct();
    }

    public static getCablePartSuffix(cablePart: string): string {
        return cablePart.substr(12);
    }

    public static isCompatible(part: CpqCablePart, tether: CpqTetherPart): boolean {
        return part.support_Type === tether.support_Type
            && part.toneable === tether.toneable
            && part.fiber_Mode_Type === tether.fiber_Mode_Type
            && part.fill_Type === tether.fill_Type
            && part.armor_Type === tether.armor_Type
            && part.flame_Retardant === tether.flame_Retardant
            && part.cable_Type === tether.cable_Type;
    }

    public static matchesConfiguration(part: CpqCablePart, configuration: CpqBuildConfiguration): boolean {
        return part.support_Type === configuration.support_Type
            && part.toneable === configuration.toneable
            && part.fiber_Mode_Type === configuration.fiber_Mode_Type
            && part.fill_Type === configuration.fill_Type
            && part.armor_Type === configuration.armor_Type
            && part.flame_Retardant === configuration.flame_Retardant
            && part.cable_Type === configuration.cable_Type;
    }

    private static sortByAscending = (a: number, b: number): number => {
        return a > b ? 1 : -1;
    }

    public static buildPartDescription(configuration: CpqBuildConfiguration): { [s: string]: any } {
        const description: { [s: string]: any } = {};
        description['Environment Type'] = configuration.environment_Type;
        description['Installation Type'] = configuration.installation_Type;
        description['Armor Type'] = configuration.armor_Type;
        description['Cable Type'] = configuration.cable_Type;
        description['Fiber Mode Type'] = configuration.fiber_Mode_Type;
        description['Buffer-tube Fill Type'] = configuration.fill_Type;
        description['Support Type'] = configuration.support_Type;
        description['Toneable Type'] = configuration.toneable;
        description['Flame Retardant'] = configuration.flame_Retardant;
        description['Duct Size'] = configuration.duct_Size;
        description['TAP Type'] = configuration.taP_Type;

        return description;
    }

    public static buildTetherPartDescription(part: CpqTetherPart): { [s: string]: any } {
        const description: { [s: string]: any } = {};
        description['Tether Type'] = part.tether_Type;
        description.Loopback = part.loopback === "YYY" ? 'Yes' : part.loopback === "NNN" ? 'N/A' : 'Orange End Cap';

        return description;
    }
}
