import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { deleteSchrodingerCable, undoDeleteSchrodingerCable } from '../../../redux/build.state';
import { Command } from '../../command';
import { LinkTapsCommand, LinkTapsPayload } from '../tap/link-tap-command';

export interface CreateBuildPayload {
    buildId: number;
    workspaceId: number;
    linkTapsPayload: LinkTapsPayload;
}

export class CreateBuildCommand extends Command {
    private buildId: number;
    private workspaceId: number;
    private linkTapsSubCommand: LinkTapsCommand;

    constructor({ buildId, workspaceId, linkTapsPayload }: CreateBuildPayload) {
        super();
        this.buildId = buildId;
        this.workspaceId = workspaceId;
        this.linkTapsSubCommand = new LinkTapsCommand({ ...linkTapsPayload, isSubCommand: true });
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildCreation };
    }

    public undo(dispatch: Dispatch): void {
        this.linkTapsSubCommand.undo(dispatch);
        deleteSchrodingerCable(this.buildId, this.workspaceId, [], true)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        undoDeleteSchrodingerCable(this.buildId)(dispatch);
        this.linkTapsSubCommand.redo(dispatch);
        super.redo(dispatch);
    }
}
