import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../locales/types';
import { Slack } from '../slack/slack';
import { PresetContext } from '../preset-config/preset-config.types';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { PresetAttributes } from '../preset-attributes/preset-attributes';
import { useEditPreset } from './edit-preset.hooks';
import { EditPresetProps } from './edit-preset.types';
import { CardInputWithCount } from '../../../card/card-controls';
import { CardInputMaxLength } from '../../../card/card.types';

export const EditPreset = (props: EditPresetProps): JSX.Element => {
    const { t } = useTranslation();
    const { preset, presetName, session, loading, showSummary, showForm, isPresetNameValid, handlePresetNameChange,
        onCancel, goToValidation, updateSession, undoCstics, savePreset } = useEditPreset(props);

    const renderForm = (): JSX.Element => {
        return <Slack onPreviousClick={onCancel} onNextClick={goToValidation} />;
    }

    const renderSummary = (): JSX.Element => {
        return <PresetAttributes isDefault={preset.isDefault} disableDefault={preset.isDefault} onCancelClick={onCancel} />;
    }

    const renderProperties = (): JSX.Element => {
        if (showSummary) {
            return renderSummary();
        } else if (showForm) {
            return renderForm();
        } else {
            return (<div className="dna-spinner-container"><DnaSpinner /></div>);
        }
    }

    return (
        <PresetContext.Provider value={{
            session, presetName, loading, updateSession, undoCstics, savePreset
        }}>
            <div className="card">
                <div className="title">{t(LocalizationKeys.EditCablePreset)}</div>
                {CardInputWithCount({
                    error: !isPresetNameValid,
                    helperText: isPresetNameValid ? undefined : t(LocalizationKeys.PresetNeedsAName),
                    label: t(LocalizationKeys.PresetName),
                    value: presetName,
                    name: 'presetName',
                    maxLength: CardInputMaxLength.PRESET_NAME,
                    onChange: handlePresetNameChange,
                })}
                {renderProperties()}
            </div>
        </PresetContext.Provider>
    );
}