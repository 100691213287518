import { ValidationResult } from '../validation/validation-result';
import { SecuredService } from './abstract-secured-v2.service';

export class ValidationResultService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/build/schrodinger';

    public getBuildValidation(buildId: number): Promise<ValidationResult[] | undefined> {
        return this.get(`${this.baseUrl}/${buildId}/validation`);
    }
}
