export class DateHelper {
    public static formatStringToLocale(utcStrDate?: string, defaultStr = ''): string {
        if (!utcStrDate) {
            return defaultStr;
        }

        return new Date(utcStrDate)?.toLocaleDateString() ?? defaultStr;
    }

    public static formatDateTimetoLocale(datetime: Date) {
        return new Date(datetime).toLocaleString(navigator.language, {
            'month': 'long',
            'day': 'numeric',
            'year': 'numeric',
            'hour': 'numeric',
            'minute': '2-digit',
            'second': '2-digit',
        });
    }
}