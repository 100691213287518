import React from 'react';
import { TetherTerminalContainerProps } from '../../nap-diagram.types';
import { useTetherTerminalContainer } from './tether-terminal-container.hooks';

export const TetherTerminalContainer = (props: TetherTerminalContainerProps): JSX.Element => {
    const { x, y, height, width, className, children, showEditButton, onClick } = useTetherTerminalContainer(props);

    return (
        <g className={className}>
            <rect className="tether-terminal-backdrop" transform={`translate(${x} ${y})`} width={width} height={height} rx="4" onClick={onClick} />
            <g className="tether-terminal-children">
                {children}
                { showEditButton ?
                    <g className="tether-edit-icon" transform={`translate(${x - 32}, 11)`}>
                        <circle cx="68" cy="105" r="18" className="tether" />
                        <path transform="translate(56, 93)" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z" />
                    </g>
                    : null
                }
            </g>
        </g>
    );
};
