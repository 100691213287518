import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GeoJsonFeatureConverter } from '../../../../helpers/geojson-feature-converter';
import { GeoJsonConverter, IGeoSet } from '../../../../lib/shp2geojson/preprocess';
import { LocalizationKeys } from '../../../../locales/types';
import { GeoJsonFeatureCollection } from '../../../../models/geojson';
import { useImportInfrastructure } from '../common/import-infrastructure.hooks';
import {
    ImportDialogContentProps, MAX_INFRASTRUCTURE_ELEMENTS, ShapefileStepsEnum
} from '../import.card.types';
import { ShapefileReaderHelper } from './shapefile-reader-helper';

export const useImportShapefileDialogContent = (props: ImportDialogContentProps) => {
    const { t } = useTranslation();


    const [isLoading, setIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [logOutput, setLogOutput] = useState<string[]>([]);

    const [prevStep, setPrevStep] = useState(-1);
    const [prevLeftButtonClickCount, setPrevLeftButtonClickCount] = useState(-1);

    const { importedInfraDataTotal, importedInfraDataHasDuplicates, onFilterInfraData, zoomAtInfraBbox, importInfra } = useImportInfrastructure({ setErrorMessages, setIsLoading, setLogOutput });

    const setErrorAndCompleteImport = useCallback((err: Error) => {
        setErrorMessages(previous => [...previous, err.message]);
        setIsLoading(false);
    }, []);

    const onLoadImportData = useCallback((): void => {
        setIsLoading(true);
        const { files } = props;
        const zipFile = files.find(file => file.type === 'application/x-zip-compressed' || file.type === 'application/zip')
        const shpFile = files.find(file => file.name.toLowerCase().endsWith('.shp'))
        const dbfFile = files.find(file => file.name.toLowerCase().endsWith('.dbf'))
        const hasValidFile = zipFile || (dbfFile && shpFile);
        if (!hasValidFile) {
            setErrorMessages(previous => [...previous, 'Upload requires a .zip file or a .dbf/.shp pair']);
            setIsLoading(false);
            return;
        }
        const convertToGeojson = (sets: IGeoSet[], fileName: string): void => {
            try {
                const totalRecords = sets.reduce((prv, set) => prv + set.shp.records.length, 0);
                if (totalRecords > MAX_INFRASTRUCTURE_ELEMENTS) {
                    throw new Error(`Error: the number of elements inside your files exceeds the limit of ${MAX_INFRASTRUCTURE_ELEMENTS.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.`); /** regex adds commas to the number */
                }
                const geoJsonEntries: GeoJsonFeatureCollection[] = [];
                for (const set of sets) {
                    if (set.dbf.records.length !== set.shp.records.length) {
                        throw new Error(`Amount of records in '${set.shp.fileName}' and '${set.dbf.fileName}' files don't match, make sure these 2 files are related`);
                    }
                    geoJsonEntries.push(GeoJsonConverter.convert(set));
                }
                const tags: string[] = [];
                const convertedDataPayload = geoJsonEntries.map((g) => ({ data: GeoJsonFeatureConverter.convert(g.features, tags), bbox: g.bbox }));
                onFilterInfraData(convertedDataPayload, fileName);
            }
            catch (err) {
                if (err instanceof Error) {
                    const msg = err.message;
                    setErrorMessages(previous => [...previous, msg]);
                }
            }
            finally {
                setIsLoading(false);
            }
        };
        if (zipFile) {
            ShapefileReaderHelper.loadZip(zipFile)
                .then((sets) => convertToGeojson(sets, zipFile.name))
                .catch((err: Error) => setErrorAndCompleteImport(err));
        }
        else if (shpFile && dbfFile) {
            ShapefileReaderHelper.loadFileset(shpFile, dbfFile)
                .then((set) => [set])
                .then((sets) => convertToGeojson(sets, shpFile.name))
                .catch((err: Error) => setErrorAndCompleteImport(err));
        }
    }, [onFilterInfraData, props, setErrorAndCompleteImport]);

    useEffect(() => { // Go to log step directly when there are errors
        if (errorMessages.length > 0 && props.stepCount !== ShapefileStepsEnum.Log) {
            props.setStepCount(ShapefileStepsEnum.Log)
        }
    }, [errorMessages.length, props]);

    useEffect(() => {
        if (prevLeftButtonClickCount !== props.leftButtonClickCount) {
            if (props.stepCount === ShapefileStepsEnum.Confirmation) {
                zoomAtInfraBbox();
            }
        }
        setPrevLeftButtonClickCount(props.leftButtonClickCount);
    }, [prevLeftButtonClickCount, props.stepCount, props.leftButtonClickCount, zoomAtInfraBbox]);

    useEffect(() => {
        if (prevStep !== props.stepCount) {
            if (props.stepCount === ShapefileStepsEnum.Confirmation) {
                props.setLeftButtonTitle(LocalizationKeys.FindOnMap);
                onLoadImportData();
            }
            if (props.stepCount === ShapefileStepsEnum.Log) {
                importInfra();
                props.setLeftButtonTitle("");
                props.setPositiveButtonTitle(t(LocalizationKeys.Done));
            }
            if (props.stepCount > ShapefileStepsEnum.Log) {
                props.onClose();
            }
        }
        setPrevStep(props.stepCount);
    }, [importInfra, onLoadImportData, prevStep, props, t]);

    return { errorMessages, isLoading, importedInfraDataTotal, importedInfraDataHasDuplicates, logOutput };
}
