import './toastr-override.scss';

import toastr from 'toastr';

export class NotificationService {

    public static init(): void {
        toastr.options.positionClass = 'toast-bottom-center';
        toastr.options.progressBar = false;
        toastr.options.closeButton = true;
    }

    public static success(...message: string[]): void {
        toastr.success(message.join(' '));
    }

    public static info(...message: string[]): void {
        toastr.info(message.join(' '), undefined);
    }

    public static infoPermanent(...message: string[]): void {
        toastr.info(message.join(' '), undefined, { timeOut: 0 });
    }

    public static loading(...message: string[]): JQuery<HTMLElement> {
        const toastrOptions = { timeOut: 0, closeButton: false, closeOnHover: false, tapToDismiss: false, iconClass: 'toast-loading' }
        return toastr.info(message.join(' '), undefined, toastrOptions);
    }

    public static warning(...message: string[]): void {
        toastr.warning(message.join(' '));
    }

    public static warningPermanent(...message: string[]): void {
        toastr.warning(message.join(' '), undefined, { timeOut: 0 });
    }

    public static error(...message: string[]): void {
        toastr.error(message.join(' '));
    }

    public static serviceError(error: any, baseMessage: string): void {
        const responseBlob = error?.response?.data?.text();
        if (responseBlob) {
            responseBlob.then((text: string) => {
                let msg;
                try {
                    const apiResponse = JSON.parse(text);
                    msg = apiResponse.reason;
                }
                catch
                {
                    msg = text;
                }
                NotificationService.error(msg);
            });
        }
        else if (error?.response?.statusText) {
            NotificationService.error(`${baseMessage}: ${error?.response?.statusText}.`);
        }
        else {
            NotificationService.error(`${baseMessage}: ${error}.`);
        }
    }

    public static clear(toast?: JQuery<HTMLElement>): void {
        toastr.clear(toast);
    }
}

NotificationService.init();