import React from 'react';
import { useColorSelector } from './design-area.hooks';
import { DesignAreaColorSelector } from './design-area-color-selector';
import { DesignAreaColorPropertyProps } from './design-area.types';
import { createPortal } from 'react-dom';

export const DesignAreaColorProperty = (props: DesignAreaColorPropertyProps): JSX.Element => {
    const { colorSelector, showColorSelector } = useColorSelector();

    const rootElement = document.getElementById('react-app-root');

    return (
        <React.Fragment>
            <div className="design-area-color" style={{ backgroundColor: props.designAreaColor }} onClick={(): void => showColorSelector(true)} />
            {colorSelector && rootElement ? createPortal(<DesignAreaColorSelector color={props.designAreaColor} onClose={(): void => showColorSelector(false)} onApplyColor={(value): void => props.onUpdate(value, "color")} />, rootElement) : null}
        </React.Fragment>
    );
};
