import Layer from './layer';
import {LayerGroup} from './layer-group';
import BaseLayer from 'ol/layer/Base';

export default class LayerCollection {

    #layers: Array<Layer>;
    #specialLayers: Array<Layer>;

    constructor() {
        this.#layers = new Array<Layer>();
        this.#specialLayers = new Array<Layer>();
    }

    public add(layer: Layer): void {
        if(layer.isSpecial) {
            this.#specialLayers.push(layer);
        }
        else {
            this.#layers.push(layer);
        }
    }

    get layers(): Layer[] {
        return this.#layers;
    }

    get specialLayers(): Layer[] {
        return this.#specialLayers;
    }

    public getSpecialLayerByName<T extends BaseLayer>(name: string): T {
        const foundLayer = this.#specialLayers.find(layer => layer.name === name);

        if(foundLayer) {
            const concreteMapLayer = foundLayer.layer as T;
            if(concreteMapLayer) {
                return concreteMapLayer;
            }
        }
        
        throw Error(`Layer requested does not exist: ${name}`);
    }

    public getLayerByName<T extends BaseLayer>(name: string): T {
        const foundLayer = this.#layers.find(layer => layer.name === name);

        if(foundLayer) {
            const concreteMapLayer = foundLayer.layer as T;
            if(concreteMapLayer) {
                return concreteMapLayer;
            }
        }
        
        throw Error(`Layer requested does not exist: ${name}`);
    }

    public getByGroup(group: LayerGroup): Layer[] {
        return this.#layers.filter(layer => layer.group === group);
    }

    public hasLayer(name: string): boolean {
        return this.#layers.some(layer => layer.name === name);
    }

    public hasSpecialLayer(name: string): boolean {
        return this.#specialLayers.some(layer => layer.name === name);
    }
}

