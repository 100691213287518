import { IPointData } from 'pixi.js';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CO_PROPORTIONS, LOCATION_PROPORTIONS } from '../components/canvas/build/build.types';
import { VIEWPORT_X_BUFFER, VIEWPORT_Y_BUFFER } from '../pixi/viewport/viewport.types';

export interface LocationHighlight {
    preview?: number;
    current?: number;
    select?: number;
}
export interface IViewportState {
    scale: number;
    isDragging: boolean;
    position: IPointData;
    highlighted: LocationHighlight;
}

export const initialViewportState: IViewportState = {
    scale: 1,
    isDragging: false,
    position: {
        x: window.innerWidth - VIEWPORT_X_BUFFER + CO_PROPORTIONS.WIDTH / 2 + LOCATION_PROPORTIONS.GAP - 2 * LOCATION_PROPORTIONS.RADIUS,
        y: window.innerHeight - VIEWPORT_Y_BUFFER + CO_PROPORTIONS.HEIGHT / 2,
    },
    highlighted: {
        preview: undefined,
        current: 0
    }
};

export const setScaleReducer = (state: IViewportState, action: PayloadAction<number>) => {
    state.scale = action.payload;
};

export const setIsDraggingReducer = (state: IViewportState, action: PayloadAction<boolean>) => {
    state.isDragging = action.payload;
};

export const setPositionReducer = (state: IViewportState, action: PayloadAction<IPointData>) => {
    state.position = action.payload;
}

export const setCurrentHighlightReducer = (state: IViewportState, action: PayloadAction<number | undefined>) => {
    state.highlighted.current = action.payload;
}

export const setPreviewHighlightReducer = (state: IViewportState, action: PayloadAction<number | undefined>) => {
    state.highlighted.preview = action.payload;
}

export const setSelectHighlightReducer = (state: IViewportState, action: PayloadAction<number | undefined>) => {
    state.highlighted.select = action.payload;
}

const { actions, reducer } = createSlice({
    name: "viewport",
    initialState: initialViewportState,
    reducers: {
        setScale: setScaleReducer,
        setIsDragging: setIsDraggingReducer,
        setPosition: setPositionReducer,
        setCurrentHighlighted: setCurrentHighlightReducer,
        setPreviewHighlighted: setPreviewHighlightReducer,
        setSelectHighlighted: setSelectHighlightReducer,
    }
});

export { reducer as ViewportReducer };
export const { setScale, setIsDragging, setPosition, setCurrentHighlighted, setPreviewHighlighted, setSelectHighlighted } = actions;