import i18next from 'i18next';

import { NapData } from '../data/nap-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { LocalizationKeys } from '../../locales/types';
import { Validator } from './validator';

export class AssignedNapValidator implements Validator<NapData> {

    public validate(nap: NapData): ValidationResult | null {
        if (nap.buildId === null) {
            const extent = nap.getExtent();
            return { 
                buildId: null, 
                itemId: nap.id, 
                itemType: ItemType.Nap, 
                shortDescription: i18next.t(LocalizationKeys.WarningUnconnectedNap), 
                type: ValidationResultType.Warning, 
                extent 
            };
        }

        return null;
    }

    public canValidate(entity: any): boolean {
        return entity instanceof NapData;
    }
}
