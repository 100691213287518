import { createSelector } from '@reduxjs/toolkit';
import { selectedSplicePointIdSelector, splicePointsSelector, segmentsSelector, selectedBuildIdSelector, flexnapBuildsSelector, bulkBuildsSelector } from '../root.selectors';
import { selectedSegmentsSortedSelector, fiberCountBuildsSelector } from '../build.selectors';

export const selectedSplicePointSelector = createSelector(
    selectedSplicePointIdSelector,
    splicePointsSelector,
    (splicePointId, splicePoints) =>
        splicePoints.find(s => s.id === splicePointId)
);

export const selectedSplicePointBuildOrderedElementIdsSelector = createSelector(
    selectedSplicePointSelector,
    segmentsSelector,
    (selectedSplicePoint, segments) => [
        ...new Set(
            segments
                .filter(s => !!selectedSplicePoint && s.buildId === selectedSplicePoint.buildId)
                .sort((a, b) => a.position - b.position)
                .flatMap(s => [s.fromId, s.toId])
        )
    ]
);

/*
 * Get all Splice Points (ordered by sequence in the Build) from the same Build as the selected Splice Point
 */
export const selectedSplicePointOrderedSiblingsSelector = createSelector(
    selectedSplicePointSelector,
    splicePointsSelector,
    selectedSplicePointBuildOrderedElementIdsSelector,
    (selectedSplicePoint, splicePoints, orderedElementIds) =>
        splicePoints
            .filter(s => selectedSplicePoint && !!s.buildId && s.buildId === selectedSplicePoint.buildId)
            .sort((a, b) => orderedElementIds.indexOf(a.elementId) - orderedElementIds.indexOf(b.elementId))
);

export const selectedBuildSplicePointSelector = createSelector(
    selectedSplicePointIdSelector,
    selectedBuildIdSelector,
    splicePointsSelector,
    (splicePointId, buildId, splicePoints) => splicePoints.find((t) => t.id === splicePointId || t.buildId === buildId)
)

export const selectedBuildIdFromSlicePointSelector = createSelector(
    selectedBuildIdSelector,
    selectedBuildSplicePointSelector,
    (buildId, splicePoint) => buildId || splicePoint?.buildId,
)

export const selectedBuildSplicePointsSelector = createSelector(
    selectedBuildIdFromSlicePointSelector,
    splicePointsSelector,
    (buildId, splicePoints) => splicePoints.filter((t) => t.buildId === buildId) || []
);

export const possibleSplicePointsToConnectSelector = createSelector(
    splicePointsSelector,
    flexnapBuildsSelector,
    bulkBuildsSelector,
    selectedSegmentsSortedSelector,
    (splicePoints, flexNapBuilds, bulkBuilds, selectedSegments) => {
        const [firstSegment] = selectedSegments;
        if (!firstSegment) {
            return [];
        }
        const buildToConvert = 
            flexNapBuilds.find(b => b.id === firstSegment.buildId) ??
            bulkBuilds.find(b => b.id === firstSegment.buildId);

        // if the build is locked, or is already connected to a splice point
        if (!buildToConvert || buildToConvert.lockedById || !!buildToConvert.buildSplice) {
            return [];
        }

        return splicePoints.filter(s => {
            if (s.elementId !== firstSegment.fromId) return false;
            
            const parentBuildId = s.buildId;
            if (!parentBuildId || parentBuildId === buildToConvert?.id) return false;

            return bulkBuilds.some((b) => b.id === parentBuildId && !b.lockedById);
        });
    }
);

export const selectedBulkBuildConnectedFiberCountSelector = createSelector(
    selectedBuildIdSelector,
    fiberCountBuildsSelector,
    (selectedBuildId, builds) => {
        return builds.filter((b) => b.buildSplice && b.buildSplice.parentBuildId === selectedBuildId)
            .reduce((fiberCount, currentBuild) => {
                return fiberCount + currentBuild.fiberCount;
            }, 0);
    }
)