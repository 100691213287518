export const ONLY_NUM_REGEX = new RegExp('^(0|[1-9][0-9]*)$');
export const ONLY_ALPHA_REGEX = new RegExp("^[^0-9()]+$");
export const SPECIAL_CHAR_REGEX = new RegExp('[^A-Za-z0-9\\s]');

export type CharacterType = 'numeric' | 'alphanumeric' | 'non-numeric';

export interface IValidationProps {
    empty?: boolean;
    specialCharacters?: boolean;
    characterType?: CharacterType;
}