import React from 'react';

export const EditToolIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <rect width="24" height="24" fill="none"/>
            <g transform="translate(-4 2)" fill="#005293">
                <circle cx="4" cy="4" r="4" transform="translate(12 6)" />
                <path d="M-1973-4741l-3,3h2v2h2v-2h2Z" transform="translate(1989 4741)" />
                <path d="M-1967-4733v2h2v2l3-3-3-3v2Z" transform="translate(1988 4742)" />
                <path d="M-1978-4732v2h-2v2l-3-3,3-3v2Z" transform="translate(1989 4741)" />
                <path d="M-1974-4726h2v2h2l-3,3-3-3h2Z" transform="translate(1989 4741)" />
            </g>
        </svg>
    );
}; 
