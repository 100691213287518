import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateModel } from '../../../redux/reducers';
import { elementsSelector } from '../../../selectors/elements.selectors';
import { pathsSelector } from '../../../selectors/paths.selectors';
import { patchTerminalExtension } from '../../../redux/tap.state';
import { CableCalculationsHelper } from '../../../helpers/cable-calculations-helper';

import { FlexNapTetherCardProps } from './flexnap-tether-card.types';

export const useFlexNapTetherExtenderCard = (props: FlexNapTetherCardProps) => {
    const { tether, disabled } = props;

    const dispatch = useDispatch();
    const displayUnits = useSelector((state: StateModel) => state.authentication.displayUnits);
    const workspace = useSelector((state: StateModel) => state);
    const elements = useSelector(elementsSelector);
    const paths = useSelector(pathsSelector);

    const tetherIcon = disabled ? require('./../../../icons/locked.png') : require('./../../../icons/tether.png');

    const [collapsed, setCollapsed] = useState(false);
    const [calculatedSpan, setCalculatedSpan] = useState(0);
    const [measuredSpan, setMeasuredSpan] = useState<number | undefined>(undefined);
    const [availableCpqLengths, setAvailableCpqLengths] = useState([tether.terminal?.terminalExtension?.length ?? 0]);

    const terminalExtension = tether.terminal?.terminalExtension;
    const terminalExtensionSegments = terminalExtension?.segments;
    const temrinalPortCount = tether.terminal?.portCount;

    useEffect(() => {
        if (terminalExtensionSegments) {
            let calculatedSpan = 0, measuredSpan = 0;
            let hasAMeasuredSpan = false;
            terminalExtensionSegments.forEach(s => {
                const segmentLength = CableCalculationsHelper.calculateSegmentSpan(s, elements, paths)?.toUnit(displayUnits).value;
                calculatedSpan += segmentLength ?? 0;
                if (s.measuredSpan) {
                    measuredSpan += s.measuredSpan;
                    hasAMeasuredSpan = true;
                } else {
                    measuredSpan += segmentLength ?? 0;
                }
            });

            setCalculatedSpan(calculatedSpan);
            setMeasuredSpan(hasAMeasuredSpan ? measuredSpan : undefined);
        }
    }, [terminalExtensionSegments, displayUnits, elements, paths]);

    useEffect(() => {
        if (workspace.cpq.multiportParts) {
            const minimumCpqLength = measuredSpan ?? calculatedSpan;
            setAvailableCpqLengths(workspace.cpq.multiportParts.filter(mp => mp.portCount === temrinalPortCount && mp.length > minimumCpqLength).map(mp => mp.length).distinct());
        }
    }, [temrinalPortCount, calculatedSpan, measuredSpan, workspace.cpq.multiportParts]);

    const toggleCollapse = (): void => {
        const newCollapsed = !collapsed;
        setCollapsed(newCollapsed);
    }

    const handleLengthChange = useCallback((value: any) => {
        if (!terminalExtension) {
            throw new Error('handleLengthChange, changing value for non-existent tether extender');
        }
        const length = parseFloat(value);
        patchTerminalExtension(terminalExtension, { ...terminalExtension, length })(dispatch);
    }, [dispatch, terminalExtension]);

    return { tetherIcon, tether, collapsed, disabled, calculatedSpan, measuredSpan, availableCpqLengths, displayUnits, toggleCollapse, handleLengthChange };
}