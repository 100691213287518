import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { SchrodingerSplicePlan } from '../../models/schrodinger/splice-plan';
import { SchrodingerSplicePlanService } from '../../services/schrodinger/splice-plan.service';

export interface SchrodingerSplicePlanState {
    currentSplicePlan?: SchrodingerSplicePlan;
}

const initialState: SchrodingerSplicePlanState = {
    currentSplicePlan: undefined
};

const loadSchrodingerSplicePlanReducer = (
    state: SchrodingerSplicePlanState, action: PayloadAction<SchrodingerSplicePlan | undefined>
): void => {
    const splicePlan = action.payload;
    state.currentSplicePlan = splicePlan;
};

const { actions, reducer } = createSlice({
    name: "schrodingerSplicePlan",
    initialState,
    reducers: {
        loadSchrodingerSplicePlan: loadSchrodingerSplicePlanReducer
    }
});

export { reducer as SplicePlanReducer };

export const loadSchrodingerSplicePlan = (buildId: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(actions.loadSchrodingerSplicePlan());
        const service = new SchrodingerSplicePlanService();
        const splicePlan = await service.getSplicePlan(buildId);
        splicePlan && dispatch(actions.loadSchrodingerSplicePlan(splicePlan));
    };
};
