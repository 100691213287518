import { LocalizationKeys } from "../locales/types";
import i18next from 'i18next';

export enum FiberAssignmentType {
    HighToLowCoToField,
    HighToLowFieldToCo,
    LowToHighCoToField,
    LowToHighFieldToCo,
    BestFit,
}

export const getFiberAssignmentLabel = (type: FiberAssignmentType): string => {
    switch (type) {
        case FiberAssignmentType.HighToLowCoToField:
            return i18next.t(LocalizationKeys.HighToLowCoToField);
        case FiberAssignmentType.HighToLowFieldToCo:
            return i18next.t(LocalizationKeys.HighToLowFieldToCo);
        case FiberAssignmentType.LowToHighCoToField:
            return i18next.t(LocalizationKeys.LowToHighCoToField);
        case FiberAssignmentType.LowToHighFieldToCo:
            return i18next.t(LocalizationKeys.LowToHighFieldToCo);

        default:
            return String(type);
    }
}


export const fiberAssignments = Object.keys(FiberAssignmentType)
    .filter(f => isNaN(Number(f)))
    .map(f => ({
        value: FiberAssignmentType[f],
        label: getFiberAssignmentLabel(FiberAssignmentType[f]),
    }));