import i18next from 'i18next';

import packageJSON from '../../../package.json';
import { LocalizationKeys } from '../../locales/types';

interface ThirdpartyComponent {
    project: string;
    version?: string;
    license?: string;
    disclaimer?: string;
}

interface OSS {
    thirdparties: ThirdpartyComponent[];
}

export const OSS: OSS = {
    thirdparties: [{
        project: i18next.t(LocalizationKeys.React),
        version: packageJSON.dependencies.react.replace('^', ''),
        license: "https://github.com/facebook/react/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.Redux),
        version: packageJSON.dependencies.redux.replace('^', ''),
        license: "https://github.com/reduxjs/redux/blob/master/LICENSE.md",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.Toastr),
        version: packageJSON.dependencies.toastr.replace('^', ''),
        license: "https://github.com/CodeSeven/toastr/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.JSZip),
        version: packageJSON.dependencies.jszip.replace('^', ''),
        license: "https://github.com/Stuk/jszip/blob/master/LICENSE.markdown",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.Shp2geojsonJS),
        version: i18next.t(LocalizationKeys.Shp2geojsonJSVersion),
        license: "https://github.com/gipong/shp2geojson.js/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.MathJS),
        version: packageJSON.dependencies.mathjs.replace('^', ''),
        license: "https://github.com/openjournals/math-js/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.MathJSDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.ProJ4),
        version: packageJSON.dependencies.proj4.replace('^', ''),
        license: "https://github.com/proj4js/proj4js/blob/master/LICENSE.md",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.MaterialUI),
        version: packageJSON.dependencies['@mui/material'].replace('^', ''),
        license: "https://github.com/mui/material-ui/blob/HEAD/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.Axios),
        version: packageJSON.dependencies.axios.replace('^', ''),
        license: "https://github.com/axios/axios/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.OpenLayers),
        version: packageJSON.dependencies.ol.replace('^', ''),
        license: "https://github.com/openlayers/openlayers/blob/master/LICENSE.md",
        disclaimer: i18next.t(LocalizationKeys.OpenLayersDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.EFCore),
        version: i18next.t(LocalizationKeys.EFCoreVersion),
        license: "https://github.com/dotnet/efcore/blob/master/LICENSE.txt",
        disclaimer: i18next.t(LocalizationKeys.EFCoreDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.NpgSQL),
        version: i18next.t(LocalizationKeys.NpgSQLVersion),
        license: "https://github.com/npgsql/efcore.pg/blob/dev/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.NpgSQLDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.NTS),
        version: i18next.t(LocalizationKeys.NTSVersion),
        license: "https://github.com/NetTopologySuite/NetTopologySuite/blob/develop/License.md",
        disclaimer: i18next.t(LocalizationKeys.NTSDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.i18next),
        version: packageJSON.dependencies["i18next"].replace('^', ''),
        license: "https://github.com/i18next/i18next/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.RMWC),
        version: packageJSON.dependencies["@rmwc/drawer"].replace('^', ''),
        license: "https://github.com/jamesmfriedman/rmwc/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.ReactBaseTable),
        version: packageJSON.dependencies["react-base-table"].replace('^', ''),
        license: "https://github.com/Autodesk/react-base-table/blob/master/LICENSE.md",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.PixiJs),
        version: packageJSON.dependencies["pixi.js"].replace('^', ''),
        license: "https://github.com/googlearchive/pixi-js/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.PixiViewport),
        version: packageJSON.dependencies["pixi-viewport"].replace('^', ''),
        license: "https://github.com/davidfig/pixi-viewport/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.BingMapsRESTToolkit),
        version: "1.1.4",
        license: "https://github.com/microsoft/BingMapsRESTToolkit/blob/master/LICENSE.md",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.NetTopologySuiteIOGeoJSON),
        version: "2.0.4",
        license: "https://github.com/NetTopologySuite/NetTopologySuite.IO.GeoJSON/blob/develop/License.md",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.NetTopologySuiteIOGeoJSON4ST),
        version: "2.1.1",
        license: "https://github.com/NetTopologySuite/NetTopologySuite.IO.GeoJSON/blob/develop/License.md",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.SwashbuckleAspNetCore),
        version: "6.3.1",
        license: "https://github.com/domaindrivendev/Swashbuckle.AspNetCore/blob/master/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.SerilogAspNetCore),
        version: "3.4.0",
        license: "https://github.com/serilog/serilog-aspnetcore/blob/dev/LICENSE",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    {
        project: i18next.t(LocalizationKeys.typescript),
        version: packageJSON.dependencies["typescript"].replace('^', ''),
        license: "https://github.com/microsoft/TypeScript/blob/main/LICENSE.txt",
        disclaimer: i18next.t(LocalizationKeys.GenericDisclaimer)
    },
    ]
};