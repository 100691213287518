import { Dispatch } from 'redux';
import { deleteElement, undoDelete } from '../../redux/element.actions';
import { ElementType, getElementTypeDescription } from '../../models/element-type';
import { Command } from '../command';
import { UndoRedoLocalizationKeys } from '../../locales/types';

export class DeleteElementCommand extends Command {
    private id: number;
    private type: ElementType;

    constructor({ id, type }) {
        super();
        this.id = id;
        this.type = type;
        const elementTypeDescription = getElementTypeDescription(type).toLowerCase();
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoElementDeleting, options: { element: elementTypeDescription } };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoElementDeleting, options: { element: elementTypeDescription } };
    }

    public undo(dispatch: Dispatch): void {
        undoDelete(this.id)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        deleteElement(this.id, this.type, true)(dispatch);
        super.redo(dispatch);
    }
}