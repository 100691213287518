import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, SelectChangeEvent, TableCell, TableHead, TableRow } from '@mui/material';

import { LocalizationKeys } from '../../../../locales/types';
import { Select } from '../../../ui-elements/select';
import { SelectObject } from '../../../ui-elements/select-object';
import {
    ImportStepSelectPresetsHeaderProps, ImportStepSelectPresetsRowProps, ImportTabs
} from '../import.card.types';

export const SelectPresetsTableHeader = (props: ImportStepSelectPresetsHeaderProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        tab, selectedBuildIds, parsedBuildsToImport,
        presets,
        onSelectAllChange, onApplyPresetToAll
    } = props;

    const accessPointTitle = tab === ImportTabs.Flexnap ? t(LocalizationKeys.Taps) : t(LocalizationKeys.SplicePoints);

    const [selectedFlexnapPreset, setSelectedFlexnapPreset] = useState<string>();
    const [selectedBulkPreset, setSelectedBulkPreset] = useState<string>();

    const handlePresetChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        onApplyPresetToAll(value);
        if (tab === ImportTabs.Bulk) {
            setSelectedBulkPreset(value)
        }
        else {
            setSelectedFlexnapPreset(value)
        }
    }, [onApplyPresetToAll, tab]);

    return (
        <TableHead>
            <TableRow>
                <TableCell className="build-list-table-cell table-column checkbox"><Checkbox className="checkbox-checked" checked={selectedBuildIds.length === parsedBuildsToImport?.length} disabled={false} onChange={onSelectAllChange} /></TableCell>
                <TableCell className="build-list-table-cell table-column build-id">{t(LocalizationKeys.Build) + ' ' + t(LocalizationKeys.ID)}</TableCell>
                <TableCell className="build-list-table-cell table-column counts" align="right">{t(LocalizationKeys.FiberCount)}</TableCell>
                <TableCell className="build-list-table-cell table-column counts" align="right">{accessPointTitle}</TableCell>
                <TableCell className="build-list-table-cell table-column presets">{t(LocalizationKeys.ParentBuild) + ' ' + t(LocalizationKeys.ID)}</TableCell>
                <TableCell className="build-list-table-cell table-column presets">
                    <SelectObject
                        label={tab === ImportTabs.Flexnap ? t(LocalizationKeys.Presets) : t(LocalizationKeys.FiberType)}
                        name="presets-apply-all"
                        value={(selectedFlexnapPreset || selectedFlexnapPreset) ? (tab === ImportTabs.Flexnap ? selectedFlexnapPreset : selectedBulkPreset) : undefined}
                        values={presets}
                        valueProp={"value"}
                        displayProp={"display"}
                        onChange={handlePresetChange}
                        adornmentPosition={"end"}
                        dense
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export const SelectPresetsTableRow = (props: ImportStepSelectPresetsRowProps): JSX.Element => {
    const { t } = useTranslation();

    const {
        parsedBuildToImport, selected,
        presets, selectedPresetIds, selectedParentBuildIds,
        onBuildSelectionChange, onSelectedPresetChange, onSelectedParentBuildIdChange
    } = props;

    const parentBuildOptions = [t(LocalizationKeys.Na)];
    let disableParentIdSelect = true;

    if (parsedBuildToImport.parentCustomerBuildIdOptions && parsedBuildToImport.parentCustomerBuildIdOptions.length) {
        disableParentIdSelect = false;
        parentBuildOptions.push(...parsedBuildToImport.parentCustomerBuildIdOptions);
    }

    const [hasSelectedParentID, setHasSelectedParentID] = useState(false);

    const onSelectChange = useCallback((event: SelectChangeEvent<any>) => {
        const value = event.target.value;
        if (value)
            setHasSelectedParentID(true);
        onSelectedParentBuildIdChange(event);
    }, [onSelectedParentBuildIdChange])

    return (
        <TableRow className="build-list-item" key={parsedBuildToImport.customerBuildId}>
            <TableCell><Checkbox className="checkbox-checked" name={parsedBuildToImport.customerBuildId} checked={selected} onChange={onBuildSelectionChange} /></TableCell>
            <TableCell>{parsedBuildToImport.customerBuildId}</TableCell>
            <TableCell align="right">{parsedBuildToImport.fiberCount}</TableCell>
            <TableCell align="right">{parsedBuildToImport.accessPointCount}</TableCell>
            <TableCell className={".table-text-field-container"}>
                {
                    parentBuildOptions && parentBuildOptions.length > 1 /* N/A is an option, so the minimum is 2 */ &&
                    <Select
                        name={parsedBuildToImport.customerBuildId}
                        values={parentBuildOptions}
                        value={selectedParentBuildIds[parsedBuildToImport.customerBuildId] ?? (hasSelectedParentID ? t(LocalizationKeys.Na) : '')}
                        disabled={disableParentIdSelect}
                        onChange={onSelectChange}
                        dense
                    />
                }
            </TableCell>
            <TableCell>
                <SelectObject
                    name={parsedBuildToImport.customerBuildId}
                    value={selectedPresetIds[parsedBuildToImport.customerBuildId] ?? ''}
                    values={presets}
                    valueProp={"value"}
                    displayProp={"display"}
                    disabled={false}
                    onChange={onSelectedPresetChange}
                    dense
                />
            </TableCell>
        </TableRow>
    );
};