import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SetHelper } from '../../../../../../helpers/set-helper';
import { Build } from '../../../../../../models/build';
import { DesignMode } from '../../../../../../models/design-mode';
import { StateModel } from '../../../../../../redux/reducers';
import { clearSelection, selectDesignArea } from '../../../../../../redux/selection.state';
import { DrawerMenuContext } from '../../../drawer-menu.types';
import { DesignAreaRowProps } from './design-area-row.types';

export const useDesignAreaRow = (props: DesignAreaRowProps) => {
    const { designArea } = props;
    const { id, name, projectId, location: gisLocation } = designArea;
    const workspace = useSelector((state: StateModel) => state);
    const dispatch = useDispatch();
    const { designAreas, designAreaBuilds } = workspace.designarea;
    const { flexnapBuilds, schrodingerBuilds, bulkBuilds } = workspace.build;
    const { recentDesignMode } = workspace.workspace;
    const {
        selectedBuildIds,
        selectedDesignAreaIds,
        selectedDesignAreaNames,
        setSelectedBuildIds,
        setSelectedDesignAreaIds,
        setSelectedDesignAreaNames,
        setSingleDesignAreaIdToDelete,
        setIsDeleteDialogOpen,
    } = useContext(DrawerMenuContext);

    const [showBuilds, setShowBuilds] = useState(false);
    const [rowSelected, setRowSelected] = useState(false);
    const [isRowHovered, setIsRowHovered] = useState(false);

    const builds = useMemo(() => {
        const foundDesignAreaBuild = designAreaBuilds.find(d => d.designAreaId === designArea.id);
        if (foundDesignAreaBuild) {
            const sortedBuilds = [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds].sort((a, b) => a.id - b.id) as Build[];
            return sortedBuilds.filter(b => foundDesignAreaBuild.buildIds.includes(b.id));
        }
        return [];
    }, [designArea.id, designAreaBuilds, flexnapBuilds, schrodingerBuilds, bulkBuilds]);

    const displayName = name ? `${name}: ${projectId}` : projectId;
    const rowClassName = classNames("design-area-row", { selected: rowSelected });
    const showActions = isRowHovered || rowSelected;
    const showEdit = showActions && recentDesignMode === DesignMode.CanvasMode
    const showDelete = showActions && designAreas.filter(da => da.designMode === DesignMode.CanvasMode).length > 1;
    const showSelect = showActions;
    const expandDisabled = builds.length === 0;
    const expandIcon = showBuilds ? "unfold_less" : "unfold_more";
    const location = recentDesignMode === DesignMode.GISMode ? gisLocation : `${designArea.city}, ${designArea.region}, ${designArea.country}`;

    const handleRowEnter = useCallback(() => {
        setIsRowHovered(true);
    }, []);

    const handleRowLeave = useCallback(() => {
        setIsRowHovered(false);
    }, []);

    const handleExpandClick = useCallback(() => {
        setShowBuilds(!showBuilds);
    }, [showBuilds]);

    const handleSelectionChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;
        const buildIds = builds.map((b) => b.id);
        const newSelectedBuildIds = checked
            ? SetHelper.union(selectedBuildIds, buildIds)
            : SetHelper.difference(selectedBuildIds, buildIds);
        setSelectedBuildIds(newSelectedBuildIds);

        const newSelectedDesignAreaIds =  checked 
            ? [...selectedDesignAreaIds, designArea.id] 
            : selectedDesignAreaIds.filter(dId => dId !== designArea.id)
        setSelectedDesignAreaIds(newSelectedDesignAreaIds);

        const newSelectedDesignAreaNames =  checked 
            ? [...selectedDesignAreaNames, designArea.name ?? designArea.projectId] 
            : selectedDesignAreaNames.filter(name => name !== designArea.name && name !== designArea.projectId)
        setSelectedDesignAreaNames(newSelectedDesignAreaNames);
        setRowSelected(checked)

    }, [builds, designArea.id, designArea.name, designArea.projectId, selectedBuildIds, selectedDesignAreaIds, selectedDesignAreaNames, setSelectedBuildIds, setSelectedDesignAreaIds, setSelectedDesignAreaNames]);

    const handleDeleteClick = useCallback(() => {
        setSingleDesignAreaIdToDelete(designArea.id);
        setIsDeleteDialogOpen(true);
    }, [designArea.id, setIsDeleteDialogOpen, setSingleDesignAreaIdToDelete]);

    const handleEditClick = useCallback(() => {
        if (designArea) {
            dispatch(clearSelection());
            dispatch(selectDesignArea(designArea.id));
        }
    }, [dispatch, designArea]);

    useEffect(() => {
        if (!selectedDesignAreaIds.length) setRowSelected(false)
    }, [selectedDesignAreaIds.length]);

    return {
        id,
        location,
        displayName,
        rowClassName,
        showBuilds,
        builds,
        showDelete,
        showSelect,
        rowSelected,
        expandDisabled,
        expandIcon,
        showEdit,
        handleRowEnter,
        handleRowLeave,
        handleDeleteClick,
        handleSelectionChanged,
        handleExpandClick,
        handleEditClick
    };
};
