import './select-with-help.scss';

import React from 'react';

import { SelectChangeEvent, Tooltip } from '@mui/material';

import { Select } from '../select';

interface SelectWithHelpProps {
    label: string;
    name: string;
    value: any;
    values: any[];
    onChange: (event: SelectChangeEvent<any>) => void;
    helpText?: string;
    disabled?: boolean;
}

export const SelectWithHelp = (props: SelectWithHelpProps): JSX.Element => {
    const {
        label, name, value, values, onChange, helpText, disabled
    } = props;
    return (
        <div className="select-with-help">
            <Select disabled={disabled} label={label} name={name} value={value} values={values} onChange={onChange} />
            <Tooltip className="tooltip" title={<span className="caption">{helpText}</span>} placement="left">
                <button className="icon-button"><img alt="Help" src={require('./icon_help.svg')} /></button>
            </Tooltip>
        </div>
    );
};
