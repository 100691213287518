import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    GeoJsonFeatureConverter, ImportedData
} from '../../../../helpers/geojson-feature-converter';
import { LocalizationKeys } from '../../../../locales/types';
import { addCabinets } from '../../../../redux/cabinet.state';
import { addHandholes } from '../../../../redux/handhole.state';
import {
    addImportedGisData, clearImportedFileValues, filter, setFileName, setFoundDuplicates
} from '../../../../redux/import.state';
import { addManholes } from '../../../../redux/manhole.state';
import { zoomAt } from '../../../../redux/map.state';
import { addParcels } from '../../../../redux/parcel.state';
import { addPoles, addPoleSpans } from '../../../../redux/pole.state';
import { StateModel } from '../../../../redux/reducers';
import { addVaults } from '../../../../redux/vault.state';
import { ImportPayload, ImportService } from '../../../../services/import.service';
import { ImportInfrastructureProps } from '../import.card.types';
import { addPaths } from '../../../../redux/path.actions';

export const useImportInfrastructure = (props: ImportInfrastructureProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { viewport: bbox } = useSelector((state: StateModel) => state.map);
    const importState = useSelector((state: StateModel) => state.import);
    const [prevImportState, setPrevImportState] = useState(importState);

    const [importedInfraDataTotal, setImportedDataTotal] = useState<number>(-1);
    const [importedInfraDataBbox, setImportedInfraDataBbox] = useState<number[] | undefined>(undefined);
    const [importedInfraDataHasDuplicates, setImportedDataHasDuplicates] = useState<boolean>();


    useEffect(() => {
        const { poles, parcels, cabinets, manholes, handholes, vaults, trenches, bores, foundDuplicates } = importState;
        if (prevImportState !== importState) {
            const total = (poles?.length ?? 0) +
                (parcels?.length ?? 0) +
                (cabinets?.length ?? 0) +
                (manholes?.length ?? 0) +
                (handholes?.length ?? 0) +
                (vaults?.length ?? 0) +
                (trenches?.length ?? 0) +
                (trenches?.length ?? 0) +
                (bores?.length ?? 0);
            setImportedDataTotal(total);
            setImportedDataHasDuplicates(foundDuplicates);
            const importStateError = importState.importError;
            if (importStateError) {
                props.setErrorMessages([importStateError]);
            }
            setPrevImportState(importState);
        }
    }, [importState, prevImportState, props, t]);

    const onFilterInfraData = useCallback((dataPayload: { data: ImportedData; bbox: number[] | undefined }[], fileName: string) => {
        const importedPoles = dataPayload.flatMap((c) => c.data.poles);
        const importedPoleSpans = dataPayload.flatMap((c) => c.data.poleSpans);
        const importedParcels = dataPayload.flatMap((c) => c.data.parcels);
        const importedManholes = dataPayload.flatMap((c) => c.data.manholes);
        const importedHandholes = dataPayload.flatMap((c) => c.data.handholes);
        const importedVaults = dataPayload.flatMap((c) => c.data.vaults);
        const importedCabinets = dataPayload.flatMap((c) => c.data.cabinets);
        const importedTrenches = dataPayload.flatMap((c) => c.data.trenches);
        const importedBores = dataPayload.flatMap((c) => c.data.bores);
        const foundDuplicates = dataPayload.flatMap(c => c.data.foundDuplicates).some(b => b);
        const payload: ImportPayload = {
            poles: importedPoles,
            poleSpans: importedPoleSpans,
            parcels: importedParcels,
            manholes: importedManholes,
            handholes: importedHandholes,
            vaults: importedVaults,
            cabinets: importedCabinets,
            trenches: importedTrenches,
            bores: importedBores,
            fileName,
        }
        dispatch(filter(payload));
        dispatch(setFoundDuplicates(foundDuplicates));

        const gsonWithPoles = dataPayload.find((c) => c.data.poles.length);
        const gsonWithParcels = dataPayload.find((c) => c.data.parcels.length);
        const gsonWithManholes = dataPayload.find((c) => c.data.manholes.length);
        const gsonWithHandholes = dataPayload.find((c) => c.data.handholes.length);
        const gsonWithVaults = dataPayload.find((c) => c.data.vaults.length);
        const gsonWithCabinets = dataPayload.find((c) => c.data.cabinets.length);
        const gsonWithTrenches = dataPayload.find((c) => c.data.trenches.length);
        const gsonWithBores = dataPayload.find((c) => c.data.bores.length);
        const bbox = (gsonWithPoles || gsonWithParcels
            || gsonWithManholes || gsonWithHandholes || gsonWithVaults || gsonWithCabinets
            || gsonWithTrenches || gsonWithBores
            || { bbox: undefined }).bbox; // takes the first bbox of dataPayload that is not undefined
        setImportedInfraDataBbox(bbox);
    }, [dispatch]);

    const zoomAtInfraBbox = useCallback(() => {
        if (importedInfraDataBbox) {
            if (importedInfraDataBbox) {
                const utmBbox = GeoJsonFeatureConverter.bboxToUtm(importedInfraDataBbox);
                dispatch(zoomAt(utmBbox));
            }
        }
    }, [dispatch, importedInfraDataBbox]);

    const recordGisData = useCallback(async () => {
        const { fileName } = importState;
        const importedGisData = await new ImportService().addImportedGisData(fileName ?? "");
        if (importedGisData) {
            dispatch(addImportedGisData(importedGisData));
            dispatch(setFileName());
        }
        return importedGisData?.id;
    }, [importState, dispatch]);

    const onImportConfirm = useCallback(async (importedGisDataId?: number) => {
        if (importedInfraDataTotal <= 0) {
            props.setIsLoading(false);
            dispatch(clearImportedFileValues());
            return;
        }

        const { poles, parcels, polespan, cabinets, manholes, handholes, vaults, trenches, bores } = importState;
        const hasPaths = !!((trenches && trenches.length > 0) || (bores && bores.length > 0)); // Used to avoid race condition
        const promises: unknown[] = [];
        if (poles && polespan) {
            promises.push(dispatch(addPoleSpans(poles, polespan, importedGisDataId, bbox)));
        }
        else if (poles) {
            promises.push(dispatch(addPoles(poles, importedGisDataId, bbox)));
            props.setLogOutput(previous => [...previous, `${poles.length} ${t(LocalizationKeys.Pole)} elements`])
        }
        if (parcels) {
            promises.push(dispatch(addParcels(parcels, importedGisDataId, bbox)));
            props.setLogOutput(previous => [...previous, `${parcels.length} ${t(LocalizationKeys.Parcel)} elements`])
        }
        if (cabinets) {
            promises.push(dispatch(addCabinets(cabinets, importedGisDataId, bbox)));
            props.setLogOutput(previous => [...previous, `${cabinets.length} ${t(LocalizationKeys.Cabinet)} elements`])
        }
        if (hasPaths) { // Since paths must be connected to either a manhole, handhole or vault, we want to ensure they are added first.
            promises.push(dispatch(addPaths(manholes, handholes, vaults, bores, trenches, importedGisDataId, bbox)));
            if (manholes) {
                props.setLogOutput(previous => [...previous, `${manholes.length} ${t(LocalizationKeys.Manhole)} elements`])
            }
            if (handholes) {
                props.setLogOutput(previous => [...previous, `${handholes.length} ${t(LocalizationKeys.Handhole)} elements`])
            }
            if (vaults) {
                props.setLogOutput(previous => [...previous, `${vaults.length} ${t(LocalizationKeys.Vault)} elements`])
            }
            if (trenches) {
                props.setLogOutput(previous => [...previous, `${trenches.length} ${t(LocalizationKeys.Trench)} paths`])
            }
            if (bores) {
                props.setLogOutput(previous => [...previous, `${bores.length} ${t(LocalizationKeys.Bore)} paths`])
            }
        } else {
            if (manholes) {
                promises.push(dispatch(addManholes(manholes, importedGisDataId, bbox)));
                props.setLogOutput(previous => [...previous, `${manholes.length} ${t(LocalizationKeys.Manhole)} elements`])
            }
            if (handholes) {
                promises.push(dispatch(addHandholes(handholes, importedGisDataId, bbox)));
                props.setLogOutput(previous => [...previous, `${handholes.length} ${t(LocalizationKeys.Handhole)} elements`])
            }
            if (vaults) {
                promises.push(dispatch(addVaults(vaults, importedGisDataId, bbox)));
                props.setLogOutput(previous => [...previous, `${vaults.length} ${t(LocalizationKeys.Vault)} elements`])
            }
        }
        
        dispatch(clearImportedFileValues());

        Promise.all(promises)
            .catch((err) => {
                if (err instanceof Error) {
                    props.setErrorMessages(previous => [...previous, err.message]);
                } else {
                    props.setErrorMessages(previous => [...previous, "Error import infrastrucure."]);
                }
            })
            .finally(() => props.setIsLoading(false));
    }, [bbox, dispatch, importState, importedInfraDataTotal, props, t]);

    const importInfra = useCallback(async () => {
        const gisDataId = await recordGisData();
        onImportConfirm(gisDataId);
    }, [recordGisData, onImportConfirm]);

    return { importedInfraDataTotal, importedInfraDataHasDuplicates, onFilterInfraData, zoomAtInfraBbox, recordGisData, onImportConfirm, importInfra };
}
