import classNames from 'classnames';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../../../locales/types';
import { BuildType } from '../../../../models/build-type';
import iconClose from '../../../card/icon_close_chevron.svg';
import iconOpen from '../../../card/icon_open_chevron.svg';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import iconError from '../../../ui-elements/icon_error.svg';
import iconComplete from '../../../ui-elements/icon_progress_end_dark.svg';
import { ImportStatus } from '../import.card.types';
import { ImportSaveTableRowProps } from './import-save-table-row.types';

export const ImportSaveTableHeader = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell className="build-list-table-cell table-column icon"></TableCell>
                <TableCell className="build-list-table-cell table-column build-id">{t(LocalizationKeys.Build) + ' ' + t(LocalizationKeys.ID)}</TableCell>
                <TableCell className="build-list-table-cell table-column build-id">{t(LocalizationKeys.CableType)}</TableCell>
                <TableCell className="build-list-table-cell table-column address">{t(LocalizationKeys.City)}</TableCell>
                <TableCell className="build-list-table-cell table-column icon">{t(LocalizationKeys.Details)}</TableCell>
            </TableRow>
        </TableHead>
    );
};

export const ImportSaveTableRow = (props: ImportSaveTableRowProps): JSX.Element => {

    const { t } = useTranslation();

    const buildImportStatus = useCallback((status: ImportStatus): JSX.Element => {
        switch (status) {
            case ImportStatus.Start:
                return <DnaSpinner />
            case ImportStatus.Complete:
                return <div className="build-import-icon"><img alt={t(LocalizationKeys.Complete)} src={iconComplete} /></div>
            case ImportStatus.Error:
                return <div className="build-import-icon"><img alt={t(LocalizationKeys.Error)} src={iconError} /></div>
        }
    }, [t]);

    const { buildId, importStatus, importResult } = props.status;
    let buildTypeString: string = "";
    switch (props.buildType) {
        case BuildType.Bulk:
            buildTypeString = t(LocalizationKeys.Bulk);
            break;
        case BuildType.FlexNap:
            buildTypeString = t(LocalizationKeys.FlexNap);
            break;
    }


    const [collapsed, setCollapsed] = useState(true);
    const [collapsedClassNames, setCollapsedClassNames] = useState(classNames({ collapsed }));

    const toggleCollapse = useCallback((): void => {
        const isCollapsed = !collapsed;
        setCollapsed(isCollapsed);
        setCollapsedClassNames(classNames({ collapsed: isCollapsed }));
    }, [collapsed]);

    return (
        <Fragment>
            <TableRow className="build-list-item" key={buildId}>
                <TableCell className="build-list-table-cell icon">{buildImportStatus(importStatus)}</TableCell>
                <TableCell className="build-list-table-cell">{buildId}</TableCell>
                <TableCell className="build-list-table-cell">{buildTypeString}</TableCell>
                <TableCell className="build-list-table-cell"></TableCell>
                <TableCell className="build-list-table-cell icon">
                    {
                        props.status.importStatus !== ImportStatus.Start &&
                        <TextButton className="icon-button" onClick={toggleCollapse}>
                            {
                                collapsed ?
                                    <img alt={t(LocalizationKeys.RevealErrors)} src={iconOpen} />
                                    :
                                    <img alt={t(LocalizationKeys.HideErrors)} src={iconClose} />
                            }
                        </TextButton>
                    }
                </TableCell>
            </TableRow>
            {
                <TableRow className={`build-list-item-errors ${collapsedClassNames}`}>
                    <TableCell className="build-list-table-cell" colSpan={4}>
                        <div className="build-errors">
                            {
                                importResult?.map((error, index) => {
                                    if (index > 0) { // the first index is currently being used for the Build ID
                                        return (
                                            <div className="build-list-item-error">
                                                {error}
                                            </div>
                                        );
                                    }

                                    return undefined;
                                })
                            }
                        </div>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    );
};