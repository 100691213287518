import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { PoleData } from '../data/pole-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class DuplicatePoleValidator implements Validator<PoleData[]> {

    public validate(poles: PoleData[]): ValidationResult[] | null {
        const res: ValidationResult[] = [];
        poles.forEach((p, i) => {
            if (poles.findIndex((pole) => this.matchesPoleTag(pole, p)) !== i) {
                const extent = p.getExtent();
                res.push({
                    buildId: null,
                    itemId: p.id,
                    itemType: ItemType.Pole,
                    shortDescription: i18next.t(LocalizationKeys.WarningDuplicatePoleIds),
                    longDescription: i18next.t(LocalizationKeys.WarningDuplicatePoleIdsLong, { id: p.tag }),
                    type: ValidationResultType.Warning,
                    extent
                });
            }
        });
        return res.length > 0 ? res : null;
    }

    public canValidate(entity: any): boolean {
        if (entity instanceof Array) {
            return entity.every((e) => e instanceof PoleData);
        }
        else {
            return false;
        }
    }

    private matchesPoleTag(p1: PoleData, p2: PoleData): boolean {
        return p1.tag !== null
            && p2.tag !== null
            && p1.tag.toLowerCase().trim() === p2.tag.toLowerCase().trim();
    }
}
