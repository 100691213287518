import { Workspace } from '../../../../models/workspace';

export interface WorkspaceDrawerFilterProps {
    workspaceList: Workspace[]; 
    setWorkspaceList: React.Dispatch<React.SetStateAction<Workspace[]>>;
}

export const initialWorkspaceDrawerFilterProps: WorkspaceDrawerFilterProps = {
    workspaceList: [],
    setWorkspaceList: () => undefined,
}
