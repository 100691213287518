import React from 'react';

import { DesignMode } from '../models/design-mode';
import { CanvasComponent } from './canvas/canvas';
import { DataManager } from './data-management/data-manager';
import { DesignModeSelect } from './design-mode/design-mode-select';
import { Dialogs } from './dialogs/dialogs';
import { DnaHub } from './dna-hub/dna-hub';
import { DrawerRight } from './drawers/drawer-right';
import { useMainPage } from './main-page.hooks';
import { ExportConfirmationComponent } from './map/export-confirmation.component';
import { MapComponent } from './map/map.component';
import { Shortcuts } from './shortcuts';
import { Validation } from './validation';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    interface Window { 
        ctcmDesignerToolConfiguration: any,
        dataLayer?: object[] // use Google Tag Manager dataLayer that is already set up in Hybris
    }
}

export const MainPageComponent = (): JSX.Element | null => {

    const { currentWorkspace, cableParts, tetherParts, installationTypes, designMode, hasRolesLoaded } = useMainPage();

    const validation = () => {
        if (!cableParts || !tetherParts || !installationTypes || cableParts.length < 1 || tetherParts.length < 1 || installationTypes.length < 1) {
            return null;
        }

        return <Validation cableParts={cableParts} tetherParts={tetherParts} installationTypes={installationTypes} />;
    }

    if (!currentWorkspace || !hasRolesLoaded) {
        return null;
    }

    if (!designMode) {
        return (
            <DesignModeSelect workspace={currentWorkspace} />
        );
    }
    else {
        return (
            <div className="app-root">
                <ExportConfirmationComponent />
                <div className="design-container">
                    {designMode === DesignMode.CanvasMode ? <CanvasComponent /> : <MapComponent />}
                    <Dialogs />
                    <Shortcuts />
                    {validation()}
                    <DataManager />
                    <DrawerRight />
                    <DnaHub />
                </div>
            </div>
        );
    }
}