import './preset-manager.scss';

import i18next from 'i18next';
import memoize from 'memoize-one';
import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { OutlinedButton } from '@orbit/button';

import { PartHelper } from '../../helpers/part-helper';
import { PresetHelper } from '../../helpers/preset-helper';
import { LocalizationKeys } from '../../locales/types';
import { CpqCablePart } from '../../models/cpqcablepart';
import { CpqTetherPart } from '../../models/cpqtetherpart';
import { Units } from '../../models/units';
import { UserPreset } from '../../models/userpreset';
import { connect, StateModel } from '../../redux/reducers';
import { CpqService } from '../../services/cpq.service';
import { NotificationService } from '../../services/notification.service';
import { SelectWithHelp } from '../ui-elements/select-with-help/select-with-help';
import { CableValues, FiberCountComponent, FiberCountData } from './fiber-count.component';
import { PresetDefinition } from './preset-definition';
import { PresetConfigComponents } from './preset-manager.types';
import { EditorMode, PresetPropertiesEditorComponent } from './preset-properties-editor.component';

interface PartSelectionProps {
    onSavePreset: (preset: UserPreset, isDefault: boolean) => void;
    onConfigChange?: (component: PresetConfigComponents) => void;
    presetName: string;
}

interface PartSelectionState {
    cablePart?: string;
    tetherPart?: string;
    cableValues?: CableValues;
    installationType?: string;
    showFiberCount: boolean;
    showValidation: boolean;
    tetherPartsForThisCable: CpqTetherPart[];
}

const mapStateToProps = (state: StateModel) => {
    const { cableParts, installationTypes, configurations } = state.cpq;
    const { displayUnits, customer } = state.authentication;
    const soldTo = customer?.account?.soldTo;
    return { cableParts, configurations, installationTypes, displayUnits, soldTo };
};

const mapDispatchToProps = {
};

type props = PartSelectionProps & Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class PartSelectionComponent extends React.Component<props, PartSelectionState> {

    public state: PartSelectionState = {
        cablePart: undefined,
        tetherPart: undefined,
        cableValues: undefined,
        installationType: undefined,
        showFiberCount: false,
        showValidation: false,
        tetherPartsForThisCable: [],
    };

    // memoize will only execute if props/state/arguments have changed or else it will use last values
    private createPartOptions = memoize((parts: CpqCablePart[]): string[] => {
        const uniqueParts = this.filterAndSortArray(parts.map((p) => PartHelper.buildFlexNapPartNumber(p.part, 'xxx')));
        const partNumbers = ['Select', ...uniqueParts];
        return partNumbers;
    });

    private createTetherPartOptions = memoize((tetherParts: CpqTetherPart[]): string[] => {
        const uniqueParts = this.filterAndSortArray(tetherParts.map((p) => PartHelper.buildTetherPartNumber(p.tether_Item, 'xx')));
        const partNumbers = ['Select', ...uniqueParts];
        return partNumbers;
    });

    private createInstallationTypeOptions = memoize((tetherParts: CpqTetherPart[], tetherPart?: string): string[] => {
        const installationTypes: string[] = [];
        if (tetherPart) {
            const tetherPrefix = tetherPart.slice(0, 5);
            const tetherSuffix = tetherPart.slice(7);
            const types = tetherParts.filter((p) => p.tether_Item.match(`^${tetherPrefix}.*${tetherSuffix}$`)).map((p) => p.installation_Type);
            const uniqueTypes = this.filterAndSortArray(types);
            installationTypes.push(...uniqueTypes);
            if (uniqueTypes.length > 0) {
                this.setState({ installationType: installationTypes[0] })
            }
        }
        return installationTypes;
    });

    private filterAndSortArray<T>(array: T[]): T[] {
        return array.filter((p, i, a) => a.indexOf(p) === i).sort();
    }

    private handleInputChange = (event: SelectChangeEvent<any>): void => {
        const name = event.target.name;
        const value = event.target.value;
        if (!name) {
            throw new Error('handleInputChange needs a name');
        }
        this.setState({ [name]: value, showFiberCount: false } as any as PartSelectionState);
        if (name === 'cablePart') {
            const part = value as string;
            new CpqService()
                .getTethers(part.slice(12), this.props.soldTo)
                .then((response) => {
                    const tetherParts = response || [];
                    this.setState({ ...this.state, tetherPartsForThisCable: tetherParts });
                });
        }
    }

    private toggleFiberCount = (): void => {
        this.setState({ showFiberCount: !this.state.showFiberCount });
    }

    private startWizard = (): void => {
        if (this.props.onConfigChange) {
            this.props.onConfigChange(PresetConfigComponents.Wizard);
        }
    }

    private handleNextClicked = (cableValues: CableValues): void => {
        this.setState({ showValidation: true, cableValues });
    }

    private cancelValidation = (): void => {
        this.setState({ showValidation: false, cableValues: undefined });
    }

    private createPreset = (cablePart: string, tetherPart: string): UserPreset | null => {
        const { cableValues, tetherPartsForThisCable } = this.state;
        if (cableValues) {
            const { displayUnits, cableParts, configurations } = this.props;
            try {
                return PresetHelper.createFakePreset(this.props.presetName, cableValues, cablePart, tetherPart, displayUnits!, cableParts!, tetherPartsForThisCable!, configurations!);
            } catch (ex) {
                if (ex?.message) {
                    NotificationService.error(ex.message);
                }
                this.cancelValidation();
            }
        }

        return null;
    }

    private createFiberCountData(): FiberCountData {
        const { cablePart, tetherPart, tetherPartsForThisCable, installationType } = this.state;
        const { cableParts, installationTypes } = this.props;
        const { fiberCounts, tetherFiberCounts } = PartHelper.getPartFiberCounts(cableParts!, tetherPartsForThisCable!, cablePart || '', tetherPart || '', installationType);
        const it = installationTypes!.find((i) => i.description === installationType) ?? installationTypes![0];
        return { slackUnit: Units.feet, fiberCounts, tetherFiberCounts, installationType: it };
    }

    private renderFiberCount = (): JSX.Element => {
        const data = this.createFiberCountData();
        return (
            <FiberCountComponent
                data={data}
                displayUnits={this.props.displayUnits!}
                currentStepIndex={0}
                onPreviousClicked={this.toggleFiberCount}
                onNextClicked={this.handleNextClicked}
            />
        );
    }

    private renderValidation = (): JSX.Element | null => {
        const { displayUnits } = this.props;
        const cablePart = this.state.cablePart || '';
        const tetherPart = this.state.tetherPart || '';
        const preset = this.createPreset(cablePart, tetherPart);
        if (!preset) {
            return null;
        }
        return (
            <PresetPropertiesEditorComponent
                mode={EditorMode.Editing}
                preset={preset}
                displayUnits={displayUnits!}
                onCancel={this.cancelValidation}
                onSavePreset={this.props.onSavePreset}
            />
        );
    }

    private renderPartSelection = (): JSX.Element => {
        const { cablePart, tetherPart, installationType, showFiberCount, tetherPartsForThisCable } = this.state;
        const { cableParts } = this.props;
        if (!cableParts) {
            throw new Error('No parts available, cannot create part selection widget');
        }
        const cablePartHelp = PresetDefinition.help['Cable Part Number'];
        const tetherPartHelp = PresetDefinition.help['Tether Part Number'];
        const installationTypeHelp = PresetDefinition.help['Installation Type'];
        const partOptions = this.createPartOptions(cableParts);
        const tetherOptions = this.createTetherPartOptions(tetherPartsForThisCable);
        const installationTypes = this.createInstallationTypeOptions(tetherPartsForThisCable, tetherPart);
        return (
            <div>
                <div className="part-selection">
                    <SelectWithHelp
                        label={i18next.t(LocalizationKeys.CablePartNumber)}
                        name='cablePart'
                        value={cablePart}
                        values={partOptions}
                        onChange={this.handleInputChange}
                        helpText={cablePartHelp} />
                    <SelectWithHelp
                        label={i18next.t(LocalizationKeys.TetherPartNumber)}
                        name='tetherPart'
                        value={tetherPart}
                        values={tetherOptions}
                        onChange={this.handleInputChange}
                        helpText={tetherPartHelp} />
                    {installationTypes.length > 0 && installationType &&
                        (
                            <SelectWithHelp
                                label={i18next.t(LocalizationKeys.InstallationType)}
                                name='installationType'
                                value={installationType}
                                values={installationTypes}
                                onChange={this.handleInputChange}
                                helpText={installationTypeHelp}
                                disabled={installationTypes.length <= 1} />
                        )
                    }
                    <div className='navigation-container'>
                        <OutlinedButton className='continue-btn' onClick={this.toggleFiberCount} disabled={cablePart === undefined || tetherPart === undefined}>{i18next.t(LocalizationKeys.Continue)}</OutlinedButton>
                    </div>
                    {showFiberCount && this.renderFiberCount()}
                </div>
            </div>
        )
    }

    public render(): JSX.Element | null {
        const { showValidation } = this.state;
        if (showValidation) {
            return this.renderValidation();
        }
        else {
            return this.renderPartSelection();
        }
    }
}
