import React from 'react';

export const SchrodingerTapIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect width="24" height="24" fill="rgba(255, 255, 255, 0)" />
            <path d="M 15 0 l 5 8.9 l -5 8.9 H 5 L 0 8.9 L 5 0 Z" transform="translate(2,3)" fill="#005293" />
        </svg>
    );
};
