import { createSelector } from '@reduxjs/toolkit';

import { schrodingerBuildsSelector, selectedBuildIdSelector, selectedSegmentIdSelector, segmentsSelector } from '../root.selectors';
import { selectedTapSelector } from './taps.selectors';

export const selectedSchrodingerBuildSelector = createSelector(
    selectedBuildIdSelector,
    schrodingerBuildsSelector,
    (selectedBuildId, schrodingerBuilds) =>
        selectedBuildId ? schrodingerBuilds.find((b) => b.id === selectedBuildId) : undefined
);

export const selectedSchrodingerSegmentSelector = createSelector(
    selectedSegmentIdSelector,
    segmentsSelector,
    schrodingerBuildsSelector,
    (selectedSegmentId, segments, schrodingerBuilds) => segments.find((s) => s.id === selectedSegmentId && schrodingerBuilds.find((b) => b.id === s.buildId))
);

export const selectedTapBuildSelector = createSelector(
    selectedTapSelector,
    schrodingerBuildsSelector,
    (selectedTap, schrodingerBuilds) =>
        selectedTap?.buildId && schrodingerBuilds.find((b) => b.id === selectedTap.buildId)
);