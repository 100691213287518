import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UnitInterpreter } from '../../../../helpers/unit-interpreter';
import { DesignMode } from '../../../../models/design-mode';
import { Units } from '../../../../models/units';
import {
    createCanvasSchrodingerBuild, loadNewCanvasBuildSpans
} from '../../../../redux/build.state';
import { showDialog } from '../../../../redux/overlay.state';
import { setCurrentHighlighted } from '../../../../redux/viewport.state';
import { schrodingerPresetSortedSelector } from '../../../../selectors/preset.selectors';
import { designAreaSelector, workspaceSelector } from '../../../../selectors/root.selectors';

export const useCreateBuild = () => {
    const dispatch = useDispatch();

    const { currentWorkspace } = useSelector(workspaceSelector);
    const { designAreas } = useSelector(designAreaSelector);
    const presets = useSelector(schrodingerPresetSortedSelector);
    const defaultPreset = presets.find(p => p.isDefault);

    const units = UnitInterpreter.toShort(Units.feet) //* hardcoded to feet (TBD)
    const canvasDesignAreas = designAreas.filter(d => d.designMode === DesignMode.CanvasMode);

    const [designAreaId, setSelectedDesignAreaId] = useState<number | undefined>(canvasDesignAreas[0]?.id ?? undefined);
    const [presetId, setSelectedPresetId] = useState<number | undefined>(defaultPreset?.id ?? presets[0]?.id ?? undefined);
    const [designSpans, setDesignSpans] = useState<number[]>([]);
    const [invalidSpan, setInvalidSpan] = useState<number>(-1);
    const [newSpan, setNewSpan] = useState<string>("0");

    const locations = designSpans.length + 1;
    const cableLength = designSpans.reduce((a, b) => (+a) + (+b), 0);
    const createDisabled = !(!!currentWorkspace && !!designAreaId && !!presetId && designSpans.length > 0);
    const spanFieldDisabled = locations >= 99;

    useEffect(() => {
        dispatch(loadNewCanvasBuildSpans(designSpans));
    }, [dispatch, designSpans]);

    const onChangeDesignArea = useCallback((value) => {
        setSelectedDesignAreaId(value);
    }, []);

    const onChangePreset = useCallback((value) => {
        setSelectedPresetId(value);
    }, []);

    const onEditDesignSpan = useCallback((i: number) => {
        return function (value) {
            setInvalidSpan(-1);
            if (isNaN(+value) || +value < 0) {
                setInvalidSpan(i);
            }
        }
    }, []);

    const submitDesignSpan = useCallback((i: number) => {
        return function (value) {
            if (invalidSpan < 0 && +value > 0) {
                const spans = [...designSpans];
                if (i < designSpans.length) {
                    spans[i] = +value;
                }
                else if (i === designSpans.length) {
                    setNewSpan(value);
                    spans.push(+value);
                }
                setDesignSpans(spans);
                dispatch(setCurrentHighlighted(spans.length));
            }
        }
    }, [invalidSpan, designSpans, dispatch]);

    const onCancelEdit = useCallback((i: number) => {
        return function () {
            setInvalidSpan(-1);
            if (i < designSpans.length && (isNaN(designSpans[i]) || designSpans[i] < 0)) {
                setInvalidSpan(i);
            }
        }
    }, [designSpans]);

    const handleFocus = useCallback((event) => {
        event.preventDefault();
        const { target } = event;
        target.focus();
        target.select()
    }, []);

    const deleteSpanField = useCallback((event) => {
        const id = +event.currentTarget.getAttribute('id');
        if (designSpans.length > 0) {
            const spans = designSpans.filter((s, i) => i !== id);
            setDesignSpans(spans);
            dispatch(setCurrentHighlighted(spans.length))
        }
    }, [dispatch, designSpans]);

    const closeCard = useCallback(() => {
        dispatch(showDialog());
        dispatch(loadNewCanvasBuildSpans());
        dispatch(setCurrentHighlighted(0))
    }, [dispatch]);

    const cancelBuildCreation = useCallback(() => {
        closeCard();
    }, [closeCard]);

    const onCreateClick = useCallback(() => {
        if (currentWorkspace && designAreaId && presetId && designSpans.length > 0) {
            dispatch(createCanvasSchrodingerBuild({ workspaceId: currentWorkspace.id, designAreaId, presetId, spans: designSpans }));
            closeCard();
        }
    }, [dispatch, closeCard, currentWorkspace, designAreaId, presetId, designSpans]);

    return {
        designAreaId,
        canvasDesignAreas,
        presetId,
        presets,
        designSpans,
        cableLength,
        units,
        locations,
        createDisabled,
        spanFieldDisabled,
        newSpan,
        invalidSpan,
        onChangeDesignArea,
        onChangePreset,
        onEditDesignSpan,
        submitDesignSpan,
        onCancelEdit,
        handleFocus,
        deleteSpanField,
        cancelBuildCreation,
        onCreateClick
    };
}