import React, { Component } from 'react';

import { ContainedButton, TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { Units } from '../../models/units';
import { UserPreset } from '../../models/userpreset';
import { DNASwitch } from '../ui-elements/dna-switch';
import { PresetPropertiesComponent } from './preset-properties.component';

export enum EditorMode {
    Editing,
    Viewing,
}

interface EditorProps {
    mode: EditorMode;
    preset: UserPreset;
    displayUnits: Units;
    disableDefault?: boolean;
    disableEditing?: boolean;
    onCancel?: () => void;
    onDeletePreset?: (id: number) => void;
    onEditPreset?: () => void;
    onSavePreset?: (preset: UserPreset, isDefault: boolean) => void;
}

interface EditorState {
    isDefault: boolean;
}

export class PresetPropertiesEditorComponent extends Component<EditorProps, EditorState> {

    public state: EditorState = {
        isDefault: this.props.preset.isDefault,
    };

    public onSwitchToggled = (): void => {
        this.setState({ isDefault: !this.state.isDefault });
    }

    public onSave = (): void => {
        const { preset } = this.props;
        const { isDefault } = this.state;
        this.props.onSavePreset!(preset, isDefault);
    }

    public onDelete = (): void => {
        this.props.onDeletePreset!(this.props.preset.id);
    }

    public renderEditFooter = (): JSX.Element => {
        const { isDefault } = this.state;
        const { disableDefault, preset } = this.props;
        const disabled = !preset.name || preset.name.trim().length === 0;
        return (
            <div className="properties-footer">
                {preset.id > 0 ? <div /> : <DNASwitch id="isDefault" label="SET AS DEFAULT" checked={isDefault} onChange={this.onSwitchToggled} disabled={disableDefault} />}
                <div>
                    <TextButton onClick={this.props.onCancel}>Cancel</TextButton>
                    <ContainedButton
                        className="save-button"
                        type="submit"
                        disabled={disabled}
                        onClick={this.onSave}
                    >
                        Save Preset
                    </ContainedButton>
                </div>
            </div>
        );
    }

    public renderViewFooter = (): JSX.Element | null => {
        return this.props.disableEditing ? null : (
            <div>
                <TextButton palette={MainPalettes.error} onClick={this.onDelete} disabled={this.props.preset.isDefault}>Delete</TextButton>
                <TextButton onClick={this.props.onEditPreset}>Edit</TextButton>
            </div>
        );
    }

    public renderFooter = (): JSX.Element | null => {
        const { mode } = this.props;
        switch (mode) {
            case EditorMode.Editing:
                return this.renderEditFooter();
            case EditorMode.Viewing:
                return this.renderViewFooter();
        }
    }

    public render(): JSX.Element {
        const { preset, displayUnits, disableEditing } = this.props;
        return (
            <PresetPropertiesComponent preset={preset} displayUnits={displayUnits!}>
                {disableEditing ? null : <div className="divider" />}
                {this.renderFooter()}
            </PresetPropertiesComponent>
        );
    }
}
