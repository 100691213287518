import './inline-edit.scss';

import classNames from 'classnames';
import React from 'react';

import {
    InlineSelect as OrbitInlineSelect, InlineSelectProps as OrbitInlineSelectProps
} from '@orbit/inline-edit';
import { MainPalettes, Typography } from '@orbit/theme-provider';

interface DNAInlineSelectProps {
    label?: string;
}
type InlineSelectProps = DNAInlineSelectProps & OrbitInlineSelectProps;



export const InlineSelect = (props: InlineSelectProps) => {

    const { label, palette = MainPalettes.primary, value, disabled, values, displayValues, align = 'right', maxHeight, units, error, onUpdate, onConfirm, onCancel } = props;

    const inlineClasses = classNames({
        'inline-select-container': true,
    });

    const propClasses = classNames({
        'inline-prop': true,
    });

    return (
        <div className={propClasses}>
            {
                label &&
                <div className='prop-label'>
                    <Typography label={label} variant='body1' />
                </div>
            }
            <div className={inlineClasses}>
                <OrbitInlineSelect
                    palette={palette}
                    value={value}
                    values={values}
                    displayValues={displayValues}
                    disabled={disabled}
                    align={align}
                    maxHeight={maxHeight}
                    units={units}
                    error={error}
                    onUpdate={onUpdate}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />
            </div>
        </div>
    )
}