import React from 'react';

import { Build } from '../../../../../../models/build';
import { BuildType } from '../../../../../../models/build-type';
import { FlexNapBuild } from '../../../../../../models/flexnap-build';
import { ReviewStatus } from '../../../../../../models/review-status';
import { SchrodingerBuild } from '../../../../../../models/schrodinger-build';
import { BuildIconProps } from './build-icon.types';
import { HoverBuildIconPath } from './hover-build-icon-path';

const getfillColor = (build: Build): string => {
    if (build.lockedById) return "#757575";
    if (build.type === BuildType.FlexNap) return "#38bcb8";
    if (build.type === BuildType.Bulk) return "#EB58D3";
    return "#f28d2d";
};

const getFlexnapPath = (build: FlexNapBuild) => {
    if (build.pretermLateral)
        return "M3.9,12A3.1,3.1,0,0,1,7,8.9h4V7H7A5,5,0,0,0,7,17h4V15.1H7A3.1,3.1,0,0,1,3.9,12ZM8,13h8V11H8Zm9-6H13V8.9h4a3.1,3.1,0,1,1,0,6.2H13V17h4A5,5,0,0,0,17,7Z";

    return "M5,2A1,1,0,0,0,3,2V6H1v6H7V6H5ZM9,16a3.01,3.01,0,0,0,2,2.82V23h2V18.82A2.991,2.991,0,0,0,15,16V14H9ZM1,16a3.01,3.01,0,0,0,2,2.82V23H5V18.82A3.01,3.01,0,0,0,7,16V14H1ZM21,6V2a1,1,0,0,0-2,0V6H17v6h6V6ZM13,2a1,1,0,0,0-2,0V6H9v6h6V6H13Zm4,14a3.01,3.01,0,0,0,2,2.82V23h2V18.82A2.991,2.991,0,0,0,23,16V14H17Z";
}

const getSchrodingerPath = (build: SchrodingerBuild) => {
    if (build.catalogCode)
        return "M 13.5,10.1 15.9,7 13.5,4 v 2.3 h -4 v 1.5 h 3.9 v 2.3 z M 8.7,17 c -0.8,0 -1.5,0.7 -1.5,1.5 0,0.8 0.7,1.5 1.5,1.5 0.8,0 1.5,-0.7 1.5,-1.5 C 10.3,17.7 9.6,17 8.7,17 Z m 7.9,0 c -0.8,0 -1.5,0.7 -1.5,1.5 0,0.8 0.7,1.5 1.5,1.5 0.8,0 1.5,-0.7 1.5,-1.5 C 18.2,17.7 17.5,17 16.6,17 Z M 8.9,14.6 v 0 l 0.7,-1.4 h 5.9 c 0.6,0 1.1,-0.3 1.4,-0.8 L 20,7 18.6,6.3 v 0 l -0.8,1.5 -2.3,3.9 H 9.9 L 9.8,11.5 8,7.8 7.3,6.3 6.6,4.8 H 4 v 1.5 h 1.5 l 2.8,5.9 -1,1.8 c -0.1,0.2 -0.2,0.5 -0.2,0.7 0,0.8 0.7,1.5 1.5,1.5 h 9.5 v -1.5 h -9 c -0.1,0 -0.2,0 -0.2,-0.1 z"
    if (build.lastReviewStatus === ReviewStatus.Approved)
        return "M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z";
    if (build.lastReviewStatus === ReviewStatus.Rejected)
        return "M21.19,21.19,2.81,2.81,1.39,4.22,3.66,6.49A9.994,9.994,0,0,0,17.51,20.34l2.27,2.27ZM10.59,16.6,6.35,12.36l1.41-1.41,2.83,2.83.18-.18,1.41,1.41Zm3-5.84-7.1-7.1A9.994,9.994,0,0,1,20.34,17.51L15,12.17l2.65-2.65L16.24,8.11Z";
    if (build.lastReviewStatus === ReviewStatus.Open)
        return "M18,22l-.01-6L14,12l3.99-4.01L18,2H6V8l4,4L6,15.99V22ZM8,7.5V4h8V7.5l-4,4Z";

    return "M5,2A1,1,0,0,0,3,2V6H1v6H7V6H5ZM9,16a3.01,3.01,0,0,0,2,2.82V23h2V18.82A2.991,2.991,0,0,0,15,16V14H9ZM1,16a3.01,3.01,0,0,0,2,2.82V23H5V18.82A3.01,3.01,0,0,0,7,16V14H1ZM21,6V2a1,1,0,0,0-2,0V6H17v6h6V6ZM13,2a1,1,0,0,0-2,0V6H9v6h6V6H13Zm4,14a3.01,3.01,0,0,0,2,2.82V23h2V18.82A2.991,2.991,0,0,0,23,16V14H17Z";
}

const getPath = (build: Build): string => {
    const buildType = build.type;
    switch (buildType) {
        case BuildType.Schrodinger:
            return getSchrodingerPath(build as SchrodingerBuild);
        case BuildType.Bulk:
            return "M 5 10 L 5 16 L 11 16 C 11.549999 16 12 16.450001 12 17 L 12 26 L 15 26 L 15 13 C 15 11.340002 13.659998 10 12 10 L 5 10 z M 14.640625 10 C 15.470624 10.729999 16 11.800001 16 13 L 16 16 L 20 16 C 20.549999 16 21 16.450001 21 17 L 21 26 L 24 26 L 24 13 C 24 11.340002 22.659998 10 21 10 L 14.640625 10 z M 23.619141 10 C 24.45914 10.729999 25 11.800001 25 13 L 25 16 L 31 16 L 31 10 L 23.619141 10 z";
        default:
            return getFlexnapPath(build as FlexNapBuild);
    }
};

export const BuildIconFactory = (props: BuildIconProps): JSX.Element => {
    const { build, onClick } = props;

    const fillColor = getfillColor(build);
    const opacity = !build.lockedById ? "0.8" : "0.4";
    const path = getPath(build);

    return (
        <div className="build-icon" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g transform="translate(-32 -16)" className="build-icon-path">
                    <circle fill={fillColor} cx="20" cy="20" r="20" transform="translate(32 16)" opacity={opacity} />
                    <g transform={build.type !== BuildType.Bulk ? "translate(40 24)" : "translate(34 20)"}>
                        <path fill="#fdfdfd" d={path} />
                    </g>
                </g>
                <HoverBuildIconPath />
            </svg>
        </div>
    );
};
