import { Dispatch } from 'redux';

import { store } from '../../dna';
import { UndoRedoLocalizationKeys } from '../../locales/types';
import { BuildSegment } from '../../models/build-segment';
import { BuildType } from '../../models/build-type';
import { FlexNapBuild } from '../../models/flexnap-build';
import {
    deleteSegment, patchSegments, revertPretermLateral, undoDeleteSegment, undoRevertPretermLateral
} from '../../redux/build.state';
import {
    linkSplicePointToBuild, unlinkSplicePointFromBuild
} from '../../redux/bulk/splice-point.state';
import { linkNapToBuild, unlinkNapFromBuild } from '../../redux/tap.state';
import { PatchSegmentRequest } from '../../services/segment.service';
import { Command } from '../command';
import { UnlinkTapsCommand } from '../schrodinger/tap/unlink-tap-command';

export interface DeleteSegmentNodePayload {
    buildId: number;
    buildType: BuildType;
    segment: BuildSegment;
    deleteFirstLocation: boolean;
    patchedSegments: PatchSegmentRequest[];
    childPretermBuilds: FlexNapBuild[];
    awaitSessionUpdate: boolean;
    accessPointId?: number;
}

export class DeleteSegmentNodeCommand extends Command {
    private buildId: number;
    private buildType: BuildType;
    private segment: BuildSegment;
    private deleteFirstLocation: boolean;
    private patchedSegments: PatchSegmentRequest[];
    private childPretermBuilds: FlexNapBuild[];
    private accessPointId?: number;
    private awaitSessionUpdate: boolean;
    private unlinkTapsCommand?: UnlinkTapsCommand;

    constructor({ buildId, buildType, segment, deleteFirstLocation, patchedSegments, childPretermBuilds, accessPointId, awaitSessionUpdate }: DeleteSegmentNodePayload) {
        super();
        this.buildId = buildId;
        this.buildType = buildType;
        this.segment = segment;
        this.deleteFirstLocation = deleteFirstLocation;
        this.patchedSegments = patchedSegments;
        this.childPretermBuilds = childPretermBuilds;
        this.accessPointId = accessPointId;
        this.awaitSessionUpdate = awaitSessionUpdate;
        if (accessPointId && buildType === BuildType.Schrodinger) {
            const tap = store.getState().tapsSchrodinger.taps.find(({ id }) => id === accessPointId);
            if (tap) this.unlinkTapsCommand = new UnlinkTapsCommand({ taps: [tap], buildId, isSubCommand: true });
        }
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoBuildEditing };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoBuildEditing };
    }

    public async undo(dispatch: Dispatch): Promise<void> {
        const undoUpdatedSegments: PatchSegmentRequest[] = this.patchedSegments.map((s) => ({ oldSegment: s.newSegment, newSegment: s.oldSegment }));
        await undoDeleteSegment(this.segment, this.deleteFirstLocation, this.awaitSessionUpdate)(dispatch);
        await patchSegments(undoUpdatedSegments, false, this.awaitSessionUpdate)(dispatch);
        this.childPretermBuilds.forEach((b) => b.pretermLateral && undoRevertPretermLateral(b.id, b.pretermLateral)(dispatch));

        if (this.accessPointId) {
            switch (this.buildType) {
                case BuildType.FlexNap: linkNapToBuild(this.accessPointId, this.buildId)(dispatch); break;
                case BuildType.Bulk: linkSplicePointToBuild(this.accessPointId, this.buildId)(dispatch); break;
            }
        }
        if (this.unlinkTapsCommand) {
            this.unlinkTapsCommand.undo(dispatch);
        }
        super.undo(dispatch);
    }

    public async redo(dispatch: Dispatch): Promise<void> {
        this.childPretermBuilds.forEach((b) => revertPretermLateral(b.id)(dispatch));
        if (this.unlinkTapsCommand) {
            this.unlinkTapsCommand.redo(dispatch);
        }
        await deleteSegment(this.segment, this.deleteFirstLocation, undefined, undefined, this.awaitSessionUpdate)(dispatch);
        await patchSegments(this.patchedSegments, false, this.awaitSessionUpdate)(dispatch);

        if (this.accessPointId) {
            switch (this.buildType) {
                case BuildType.FlexNap: unlinkNapFromBuild(this.accessPointId, this.buildId)(dispatch); break;
                case BuildType.Bulk: unlinkSplicePointFromBuild(this.accessPointId, this.buildId)(dispatch); break;
            }
        }
        super.redo(dispatch);
    }
}
