export enum ElementType {
    Cabinet = 1,
    Handhole = 2,
    Manhole = 3,
    Parcel = 4,
    Vault = 5,
    Pole = 6,
    Generic = 7,
}

export const getElementTypeDescription = (type: ElementType): string => {
    switch (type) {
        case ElementType.Cabinet: return "Cabinet";
        case ElementType.Handhole: return "Handhole";
        case ElementType.Manhole: return "Manhole";
        case ElementType.Parcel: return "Parcel";
        case ElementType.Vault: return "Vault";
        case ElementType.Pole: return "Pole";
        case ElementType.Generic: return "Generic";
    }
}