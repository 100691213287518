import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardHeaderComponent } from '../../card/card-header.component';
import { useSelectedColorUpdated } from './design-area.hooks';
import { defaultDesignAreaColors, DesignAreaColorSelectorProps } from './design-area.types';

const renderColor = (color: string, selected: boolean, onClick: () => void): JSX.Element => {
    return (
        <div>
            <div key={color} className="color-item" style={{ backgroundColor: color }} onClick={onClick}>
                {selected ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="#fafafa" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" /></svg> : null}
            </div>
        </div>
    );
}

export const DesignAreaColorSelector = (props: DesignAreaColorSelectorProps): JSX.Element => {
    const { t } = useTranslation();
    const { selectedColor, onColorUpdated, setSelectedColor } = useSelectedColorUpdated(props);

    return (
        <div className="card-container">
            <Card id="design-area-color-selector" className="design-area-color-selector" hideToggleCollapse={true} onCloseClick={props.onClose}>
                <CardHeaderComponent title={t(LocalizationKeys.SelectAColor)} subheader=" " />
                <CardContentComponent>
                    <div className="color-selector-content">
                        {defaultDesignAreaColors.map((c) => renderColor(c, c.toLowerCase() === selectedColor?.toLowerCase(), () => setSelectedColor(c)))}
                    </div>
                    <TextButton className="negative" onClick={props.onClose}>{t(LocalizationKeys.Cancel)}</TextButton>
                    <TextButton onClick={onColorUpdated}>{t(LocalizationKeys.ApplyColor)}</TextButton>
                </CardContentComponent>
            </Card>
        </div>
    );
};