import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useDeleteBulkBuildDialog } from './delete-bulk-build-dialog.hooks';

export const DeleteBulkBuildDialog = (): JSX.Element | null => {
    const { t } = useTranslation();

    const {
        isOpen,
        onClose, onDeleteBuild,
        title
    } = useDeleteBulkBuildDialog();

    return (
        !isOpen ? null :
            <DialogComponent
                open={isOpen}
                title={title}
                positiveTitle={t(LocalizationKeys.Delete)}
                negativeTitle={t(LocalizationKeys.Cancel)}
                onPositiveClick={onDeleteBuild}
                onNegativeClick={onClose}
                onClose={onClose}
                negativeStyleButton
            />
    );
};