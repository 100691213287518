import { DesignMode } from '../models/design-mode';
import { Element } from '../models/element';
import { Path } from '../models/path';
import { Review } from '../models/review';
import { Tap } from '../models/schrodinger/tap';
import { AuthenticationState } from '../redux/authentication.state';
import { DebugState } from '../redux/debug.state';
import { DesignAreaState } from '../redux/design-area.state';
import { MapState } from '../redux/map.state';
import { StateModel } from '../redux/reducers';
import { ValidationState } from '../redux/schrodinger/validation.state';
import { WorkspaceState } from '../redux/workspace.state';

export const authenticationSelector = (state: StateModel): AuthenticationState => state.authentication;
export const hybrisCustomerSelector = (state: StateModel) => state.authentication.customer;
export const userIdSelector = (state: StateModel) => state.authentication.userId;

export const boresSelector = (state: StateModel) => state.bore.bores;

export const buildSelector = (state: StateModel) => state.build;
export const flexnapBuildsSelector = (state: StateModel) => state.build.flexnapBuilds;
export const schrodingerBuildsSelector = (state: StateModel) => state.build.schrodingerBuilds;
export const bulkBuildsSelector = (state: StateModel) => state.build.bulkBuilds;
export const segmentsSelector = (state: StateModel) => state.build.segments;

export const bulkSplicePlanSelector = (state: StateModel) => state.bulkSplicePlan.currentSplicePlan;

export const cabinetSplicePlanSelector = (state: StateModel) => state.cabinetSplicePlan;
export const cabinetSplicePlansSelector = (state: StateModel) => state.cabinetSplicePlan.cabinetSplicePlans;

export const debugSelector = (state: StateModel): DebugState => state.debug;

export const designAreaSelector = (state: StateModel): DesignAreaState => state.designarea;

export const downloadSelector = (state: StateModel) => state.download;

export const featureFlagsSelector = (state: StateModel) => state.featureFlags.featureFlags;

export const mapSelector = (state: StateModel): MapState => state.map;

export const notificationSelector = (state: StateModel) => state.notification;

export const overlaySelector = (state: StateModel) => state.overlay;
export const dialogSelector = (state: StateModel) => state.overlay.dialog;
export const controlSelector = (state: StateModel) => state.overlay.control;
export const snackbarPayloadSelector = (state: StateModel) => state.overlay.snackbarPayload;

export const presetSelector = (state: StateModel) => state.preset;

export const reviewsSelector = (state: StateModel): Review[] => state.review.reviews;

export const rolesSelector = (state: StateModel) => state.role;

export const schrodingerPresetSelector = (state: StateModel) => state.schrodingerpreset;

export const schrodingerSplicePlanSelector = (state: StateModel) => state.schrodingerSplicePlan.currentSplicePlan;

export const selectedDesignAreaIdSelector = (state: StateModel): number | undefined => state.selection.selectedDesignAreaId;
export const selectedBuildIdSelector = (state: StateModel) => state.selection.selectedBuildId;
export const selectedNapIdSelector = (state: StateModel) => state.selection.selectedNapId;
export const selectedSegmentIdSelector = (state: StateModel) => state.selection.selectedSegmentId;
export const selectedElementSelector = (state: StateModel): Element | undefined => state.selection.selectedElement;
export const selectedSplicePlanCabinetId = (state: StateModel): number | undefined => state.selection.selectedSplicePlanCabinetId;
export const selectedTapIdSchrodingerSelector = (state: StateModel) => state.selection.selectedTapIdSchrodinger;
export const selectedTetherIdSchrodingerSelector = (state: StateModel) => state.selection.selectedTetherIdSchrodinger;
export const selectedSplicePointIdSelector = (state: StateModel) => state.selection.selectedSplicePointId;

export const flexNapSplicePlansSelector = (state: StateModel) => state.splicePlan.flexNapSplicePlans;
export const flexNapSplicePlanSelector = (state: StateModel) => state.splicePlan.currentflexNapSplicePlan;

export const splicePointsSelector = (state: StateModel) => state.splicePoints.splicePoints;

export const napsSelector = (state: StateModel) => state.taps.naps;
export const tapsSelector = (state: StateModel) => state.taps.taps;
export const tethersSelector = (state: StateModel) => state.taps.tethers;
export const buildNapLastUpdatedsSelector = (state: StateModel) => state.taps.buildNapLastUpdateds;

export const tapsSchrodingerSelector = (state: StateModel): Tap[] => state.tapsSchrodinger.taps;
export const tethersSchrodingerSelector = (state: StateModel) => state.tapsSchrodinger.tethers;

export const buildTypeSelector = (state: StateModel) => state.tool.buildType;

export const pathsSelector = (state: StateModel): Path[] => [...state.bore.bores, ...state.trench.trenches];

export const validationSelector = (state: StateModel): ValidationState => state.validation;

export const viewportSelector = (state: StateModel) => state.viewport;

export const designModeSelector = (state: StateModel): DesignMode | undefined => state.workspace.recentDesignMode;

export const recentBuildIdSelector = (state: StateModel) => state.workspace.currentWorkspace?.recentBuildId;

export const workspaceSelector = (state: StateModel): WorkspaceState => state.workspace;

export const awaitingSchrodingerSessionUpdatesSelector = (state: StateModel) => state.workspace.awaitingSchrodingerSessionUpdates;



