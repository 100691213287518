import React from 'react';

export const TrenchIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M18,4,14,8h3v7a2,2,0,0,1-4,0V8A4,4,0,0,0,5,8v7H2l4,4,4-4H7V8a2,2,0,0,1,4,0v7a4,4,0,0,0,8,0V8h3Z" fill="#005293" />
            <path d="M0,0H24V24H0Z" fill="none" />
        </svg>
    );
};
