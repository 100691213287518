import { Review } from '../../models/review';
import { ReviewStatus } from '../../models/review-status';

export enum ReviewListTab {
    Request,
    Archived,
    Resolved,
    Pending
}

export interface ReviewListItemProps {
    review: Review;
}

export interface ReviewListItemRequestProps extends ReviewListItemProps {
    onResponseClick: (status: ReviewStatus) => () => void;
}

export interface ReviewListItemRespondingProps extends ReviewListItemProps {
    responseStatus: ReviewStatus;
    onCancelClick: () => void;
}
