import React from 'react';
import { createPortal } from 'react-dom';

import { ColorSelector } from './color-selector';
import { useColorSelector } from './color-selector.hooks';
import { ColorSelectorPropertyProps } from './color-selector.types';

export const ColorSelectorProperty = (props: ColorSelectorPropertyProps): JSX.Element => {
    const { colorSelector, showColorSelector } = useColorSelector();

    const rootElement = document.getElementById('react-app-root');

    return (
        <React.Fragment>
            <div className="design-area-color" style={{ backgroundColor: props.color }} onClick={(): void => showColorSelector(true)} />
            {colorSelector && rootElement ? createPortal(<ColorSelector color={props.color} colorOptions={props.colorOptions} onClose={(): void => showColorSelector(false)} onApplyColor={(value): void => props.onUpdate(value)} resetEnabled={props.resetEnabled} onReset={props.onReset} />, rootElement) : null}
        </React.Fragment>
    );
};
