import classnames from 'classnames';
import React from 'react';

import { useProgressItem } from './progress-item.hooks';
import { ProgressItemProps } from './progress-item.types';

export const ProgressItem = (props: ProgressItemProps): JSX.Element => {
    const {
        data, first, selected, ref,
        onClick, onLoad
    } = useProgressItem(props);

    return (
        <div id={data.id} key={data.id} className="indicator-progress-container" onLoad={onLoad} ref={ref}>
            {!first && <img alt="Icon" src={require('../icon_progress.svg')} />}
            <div id={data.id} className="indicator-container" onClick={onClick}>
                <label className={classnames("subheader", { selected })}>{data.title}</label>
                <label className="caption">{data.value}</label>
            </div>
        </div>
    );
};