import { Dispatch } from 'redux';
import { moveNap, resetNapExtenders } from '../../../redux/tap.state';
import { Command } from '../../command';
import { FlexNapBuild } from '../../../models/flexnap-build';
import { revertPretermLateral, undoRevertPretermLateral } from '../../../redux/build.state';
import { UndoRedoLocalizationKeys } from '../../../locales/types';

export class MoveNapCommand extends Command {
    private napId: number;
    private fromElementId: number;
    private toElementId: number;
    private fromBuildId: number | null;
    private toBuildId: number | null;
    private pretermBuilds: FlexNapBuild[];

    constructor({ id: napId, fromElementId, toElementId, fromBuildId, toBuildId, pretermBuilds }) {
        super();
        this.napId = napId;
        this.fromElementId = fromElementId;
        this.toElementId = toElementId;
        this.fromBuildId = fromBuildId;
        this.toBuildId = toBuildId;
        this.pretermBuilds = pretermBuilds;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoNapMoving };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoNapMoving };
        if (fromElementId === toElementId) {
            if (fromBuildId && !toBuildId) {
                this.undoMessage = { message: UndoRedoLocalizationKeys.UndoNapUnconnection };
                this.redoMessage = { message: UndoRedoLocalizationKeys.RedoNapUnconnection };
            }
            else if (!fromBuildId && toBuildId) {
                this.undoMessage = { message: UndoRedoLocalizationKeys.UndoNapConnection };
                this.redoMessage = { message: UndoRedoLocalizationKeys.RedoNapConnection };
            }
        }
    }

    public undo(dispatch: Dispatch): void {
        moveNap(this.napId, this.toElementId, this.fromElementId, this.toBuildId, this.fromBuildId, this.pretermBuilds, true)(dispatch)
            .then(() => {
                if (this.toElementId !== this.fromElementId) {
                    resetNapExtenders(this.napId)(dispatch);
                }
            });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (this.pretermBuilds) {
            this.pretermBuilds.forEach((b) => !!b.pretermLateral && undoRevertPretermLateral(b.id, b.pretermLateral!)(dispatch));
        }
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        moveNap(this.napId, this.fromElementId, this.toElementId, this.fromBuildId, this.toBuildId, this.pretermBuilds, true)(dispatch)
            .then(() => {
                if (this.toElementId !== this.fromElementId) {
                    resetNapExtenders(this.napId)(dispatch);
                }
            });
        if (this.pretermBuilds) {
            this.pretermBuilds.forEach((b) => revertPretermLateral(b.id)(dispatch));
        }
        super.redo(dispatch);
    }
}