import React from 'react';
import { TooltipLight } from './tooltip-light';
import { SplicePlanNavigationButtonProps } from './splice-plan-dialog.types';

export const SplicePlanNavigationButton = ({
    index, isSelected, handleClick, toolTipLabel = '', children, disabled
}: SplicePlanNavigationButtonProps): JSX.Element => (
        <TooltipLight arrow placement="top" title={toolTipLabel} disableHoverListener={!toolTipLabel}>
            <span>
                <button
                    disabled={disabled}
                    type="button"
                    key={index}
                    onClick={handleClick}
                    className={`splice-plan-dialog-navigation-button ${isSelected ? 'selected' : ''}`}
                >
                    {children}
                </button>
            </span>
        </TooltipLight>
    );

