import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { CardCollapsibleComponent } from '../../card/card-collapsible.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardContextMenu } from '../../ui-elements/card-context-menu';
import { CableLengthProperties } from '../cable-length/cable-length-properties';
import { InlineSelect } from '../../ui-elements/inline-select';
import { UnitInterpreter } from '../../../helpers/unit-interpreter';
import { FlexNapCableDeleteDialog } from './flexnap-cable-delete.dialog';
import { useFlexNapCableCard } from './flexnap-cable.hooks';
import { FlexNapPretermProperties } from './flexnap-preterm-properties';
import { FlexNapCableProps } from './flexnap.types';

export const FlexNapCableCard = (props: FlexNapCableProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        build, build: { fiberCount, partNumber, id, appliedPresetId: presetId }, collapsed, presets, compatibleFiberCounts, cardLabel, partNumberLabel, deleteDialog, toggleDeleteDialog, totalLength, displayUnits,
        handleFiberCountChange, handlePresetChange, onDeleteCableClick, onDeleteCable, isPreterm, labelButtonConnect, handleButtonConnect, disabledButtonConnect, disabled,
        contextMenuItems, contextMenuPosition, onCancelContextMenu
    } = useFlexNapCableCard(props);

    return (
        <>
            <CardCollapsibleComponent id="cable-info" label={cardLabel} collapsed={collapsed}>
                <InlineSelect
                    key={"cable-preset"}
                    label={t(LocalizationKeys.Preset)}
                    value={presetId}
                    values={presets.map(p => p.id)}
                    displayValues={presets.map(p => p.name)}
                    onUpdate={handlePresetChange}
                />
                {!isPreterm &&
                    <InlineSelect
                        key={"fiberCount"}
                        label={t(LocalizationKeys.FiberCount)}
                        value={fiberCount}
                        values={compatibleFiberCounts}
                        onUpdate={handleFiberCountChange}
                    />}
                {CardPropertyInline(partNumberLabel, partNumber)}
                {isPreterm && <FlexNapPretermProperties build={build} disabled={disabled} />}
                {CardPropertyInline(t(LocalizationKeys.Length), totalLength.value.toFixed(2), UnitInterpreter.toShort(displayUnits))}
                <CableLengthProperties build={build} isPreterm={isPreterm} />
                <div className="card-footer">
                    <TextButton palette={MainPalettes.error} className="negative" disabled={disabled} onClick={onDeleteCableClick}><i className="material-icons">delete</i></TextButton>
                    <TextButton className="connect-button" disabled={disabledButtonConnect} onClick={handleButtonConnect}>
                        {labelButtonConnect}
                    </TextButton>
                </div>
                <FlexNapCableDeleteDialog open={deleteDialog} buildId={id} onPositiveClick={onDeleteCable} onClose={toggleDeleteDialog} />
            </CardCollapsibleComponent>
            {
                contextMenuItems.length ?
                    <CardContextMenu
                        title={t(LocalizationKeys.ConnectTo)}
                        position={{ bottom: contextMenuPosition.bottom, right: contextMenuPosition.right }}
                        items={contextMenuItems}
                        onCancel={onCancelContextMenu}
                    />
                    : null
            }
        </>
    );
};
