export class SetHelper {
    public static union<T>(a: Array<T>, b: Array<T>): Array<T> {
        const union = new Set([...a, ...b]);
        return Array.from(union);
    }

    public static difference<T>(a: Array<T>, b: Array<T>): Array<T> {
        const difference = new Set(a);
        for (const element of b) {
            difference.delete(element);
        }
        return Array.from(difference);
    }
}
