import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { clearImportedFileValues } from '../../../redux/import.state';
import { DialogType, showDialog } from '../../../redux/overlay.state';
import { StateModel } from '../../../redux/reducers';
import { clearSelection } from '../../../redux/selection.state';
import { featureFlagsSelector } from '../../../selectors/root.selectors';
import { ImportWorkflow } from './import.card.types';

export const useImportDialog = () => {
    const workspace = useSelector((state: StateModel) => state);
    const { fmeImportFlexNap } = useSelector(featureFlagsSelector);
    const { dialog } = workspace.overlay;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [disableNext, setDisableNext] = useState(true);
    const [leftButtonTitle, setLeftButtonTitle] = useState('');
    const [positiveButtonTitle, setPositiveButtonTitle] = useState(`${t(LocalizationKeys.Continue)}`);
    const [formatSelectionValue, setFormatSelectionValue] = useState('corning-format');

    const [importWorkflow, setImportWorkflow] = useState<ImportWorkflow | undefined>(undefined)

    const [stepCount, setStepCount] = useState(0);
    const [isLoadingStep, setIsLoadingStep] = useState(false);
    const [leftButtonClickCount, setLeftButtonClickCount] = useState(0);

    useEffect(() => {
        setOpen(dialog === DialogType.Import);
    }, [dialog]);

    useEffect(() => {
        if (!importWorkflow && stepCount === 1) { // after finished uploading
            if (formatSelectionValue === "corning-format") {
                if (files.every(file => file.type === "application/x-zip-compressed"
                    || file.type === "application/zip"
                    || file.name.toLowerCase().endsWith(".shp")
                    || file.name.toLowerCase().endsWith(".dbf"))) {
                    setImportWorkflow(ImportWorkflow.CorningFormatInfrastructureShapefile);
                }
                if (files.every(file => file.type === "application/json")) {
                    setImportWorkflow(ImportWorkflow.CorningFormatInfrastructureAndDesignGeoJson);
                }
            } else if (formatSelectionValue === "customer-format") {
                setImportWorkflow(ImportWorkflow.CustomerFormatInfrastructureAny);
            }
        }
    }, [files, formatSelectionValue, importWorkflow, stepCount]);

    const onClose = useCallback(() => {
        dispatch(showDialog());
        setTimeout(() => {
            setFiles([]);
            setStepCount(0);
            setLeftButtonTitle('');
            setPositiveButtonTitle(`${t(LocalizationKeys.Continue)}`);
            setImportWorkflow(undefined);
        }, 500); // timeout to give enough time for the dialog to close before resetting values
    }, [dispatch, t]);

    const onCancel = useCallback(() => {
        onClose();
        dispatch(clearImportedFileValues());
        dispatch(clearSelection());
    }, [dispatch, onClose]);

    const onNext = useCallback(() => {
        setStepCount(current => current + 1);
    }, [setStepCount]);

    const onLeftButtonClicked = useCallback(() => {
        setLeftButtonClickCount(current => current + 1);
    }, [setLeftButtonClickCount]);

    const onFormatSelectionChange = useCallback((event: React.ChangeEvent<any>) => {
        setFormatSelectionValue(event.currentTarget.value);
    }, []);

    return {
        open,
        files,
        importWorkflow,
        disableNext,
        stepCount,
        leftButtonClickCount,
        leftButtonTitle,
        positiveButtonTitle,
        formatSelectionValue,
        isLoadingStep,
        fmeImportFlexNap,
        onClose,
        onCancel,
        onNext,
        onLeftButtonClicked,
        setFiles,
        setDisableNext,
        setLeftButtonTitle,
        setPositiveButtonTitle,
        setStepCount,
        onFormatSelectionChange,
        setIsLoadingStep
    };
};
