import './presets-list.scss';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@mui/material';
import { OutlinedButton } from '@orbit/button';

import { LocalizationKeys } from '../../../locales/types';
import { BasePreset } from '../../../models/base-preset';
import { PresetRow } from '../preset/preset-row';
import presetCable from '../preset_cable.jpg';
import { usePresetsList } from './presets-list.hooks';
import { PresetType } from './presets-list.types';

export const PresetsListComponent = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        presetTab, activeIndex, selectedPresetId, selectedSchrodingerPresetId, presets, schrodingerPresets, onNewPreset, handleSelectedPreset, handleActiveIndex, handleOnSetAsDefault
    } = usePresetsList();

    const renderCablePresets = useCallback((presets: BasePreset[], selectedPresetId: number | undefined): JSX.Element | null => {
        return (
            <div className="presets-list-container">
                <div className="presets-list-count">
                    {t(LocalizationKeys.PresetCount, { count: presets.length })}
                </div>
                {presets.map((preset) => {
                    return (
                        <PresetRow
                            key={preset.id}
                            id={preset.id}
                            name={preset.name}
                            icon={presetCable}
                            isDefault={preset.isDefault}
                            selected={selectedPresetId === preset.id}
                            createdByUserName={preset.createdByUserName}
                            onSelect={handleSelectedPreset}
                            onSetAsDefault={handleOnSetAsDefault}
                        />
                    );
                })}
            </div>
        );
    }, [t, handleSelectedPreset, handleOnSetAsDefault]);

    const renderPresetsList = useCallback((): JSX.Element | null => {
        if (!presetTab) {
            return null;
        }
        const tab = presetTab;
        if (!tab) {
            console.warn(`presets-list: Preset Tab at ${activeIndex} not available`);
            return null;
        }
        switch (tab.type) {
            case PresetType.FlexNap: return renderCablePresets(presets, selectedPresetId);
            case PresetType.Schrodinger: return renderCablePresets(schrodingerPresets, selectedSchrodingerPresetId);
            default: return null;
        }
    }, [presetTab, activeIndex, presets, schrodingerPresets, selectedPresetId, selectedSchrodingerPresetId, renderCablePresets]);

    return (
        <div className="presets-list">
            <div className="presets-container">
                {!presetTab ? null : <Tabs
                    className="presets-tab-bar"
                    value={activeIndex}
                    onChange={handleActiveIndex}
                >
                    {[presetTab].map((t, i) => {
                        return (
                            <Tab key={i} label={t.label} />
                        );
                    })}
                </Tabs>}
                {renderPresetsList()}
            </div>
            <OutlinedButton className={"new-button"} onClick={onNewPreset}>{t(LocalizationKeys.New)}</OutlinedButton>
        </div>
    );
}
