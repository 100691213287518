import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FileHelper } from '../helpers/file-helper';
import { HybrisImportStatusType } from '../models/hybris-import-status-type';
import { exportUpdatedData, updateFlexNapBuilds } from '../redux/build.state';
import { setExcelFile, updateExportState } from '../redux/export.state';
import { lockNapsConnectedToBuilds } from '../redux/tap.state';
import {
    authenticationSelector, flexnapBuildsSelector, napsSelector, workspaceSelector
} from '../selectors/root.selectors';
import { BuildService } from '../services/build.service';
import { ExportService } from '../services/export.service';
import { NotificationService } from '../services/notification.service';
import { WorkspaceService } from '../services/workspace.service';
import { WebServiceErrorHandlingBehavior } from '../services/abstract-web-v2.service';

export const useExport = () => {
    const { customer, displayUnits } = useSelector(authenticationSelector);
    const { currentWorkspace } = useSelector(workspaceSelector);
    const naps = useSelector(napsSelector);
    const flexNapBuilds = useSelector(flexnapBuildsSelector);

    const dispatch = useDispatch();

    const exportUpdatedDataFromHybris = useCallback(async (designAreaConfigurationId: string): Promise<void> => {
        if (customer?.account) { // i.e. user is on hybris
            /*
             * export the updated build configuration spreadsheet 
             * that includes newly added hybris identifiers (CCS Build Id).
             * This will also update the state of the builds that were
             * updated with their respective CCS build id.
             */
            dispatch(exportUpdatedData(designAreaConfigurationId));
        }
    }, [customer, dispatch]);

    const updateExportStatus = useCallback(async (importTaskId: string, buildIds: number[], workspaceId: number): Promise<void> => {
        const exportService = new ExportService();
        const { status, designAreaConfigurationId, completionPercentage, error } = await exportService.getExportStatus(importTaskId);
        dispatch(updateExportState({ status, designAreaConfigurationId, progress: completionPercentage, error }));
        if (status === HybrisImportStatusType.Running) {
            setTimeout(() => updateExportStatus(importTaskId, buildIds, workspaceId), 100);
        }
        else if (status === HybrisImportStatusType.Success || status === HybrisImportStatusType.Warning) {
            // TODO : Probably need to log and/or throw if current workspace is null?
            const workspaceId = currentWorkspace?.id ?? -1;
            const workspaceService = new WorkspaceService();
            await workspaceService.updateWorkspaceBuilds(workspaceId, buildIds);
            const buildService = new BuildService();
            await buildService.lockBuilds(buildIds, workspaceId);
            const now = Date.now();
            const uniqueBuildIds = new Set<number>(buildIds);

            const builds = flexNapBuilds.filter((b) => uniqueBuildIds.has(b.id));
            const updatedBuilds = builds.map((b) => { return { ...b, uploadedDateTime: new Date(now), lockedById: workspaceId } });
            if (updatedBuilds.length) {
                dispatch(updateFlexNapBuilds(updatedBuilds));
                dispatch(lockNapsConnectedToBuilds(updatedBuilds.map((b) => b.id), workspaceId));
            }
            await exportUpdatedDataFromHybris(designAreaConfigurationId);
        }
    }, [currentWorkspace, dispatch, exportUpdatedDataFromHybris, flexNapBuilds]);

    const validateExport = useCallback((buildIds: number[]) => {
        if (buildIds.length < 1 || !naps) {
            return false;
        }

        const napBuildIds: number[] = [];
        naps.forEach((nap) => { if (nap.buildId) napBuildIds.push(nap.buildId) });
        const napBuildIdsSet = new Set(napBuildIds);
        const buildIdsWithoutNaps = buildIds.filter((id) => !napBuildIdsSet.has(id));

        if (buildIdsWithoutNaps.length > 0) {
            NotificationService.error(`Can't export. Build(s) ${buildIdsWithoutNaps.join(", ")} have no naps.`);
            return false;
        }

        return true;
    }, [naps]);

    const exportFlexNapBuilds = useCallback(async (buildIds: number[]): Promise<void> => {
        if (currentWorkspace === undefined || !validateExport(buildIds)) return;

        try {
            const esvc = new ExportService();
            const unitOfMeasure = customer?.account?.unitOfMeasure ?? displayUnits;
            const res = await esvc.exportFlexNAPBuilds(buildIds, unitOfMeasure);
            if (res?.data) {
                if (customer?.account) {
                    const file = new File([res.data], res.filename);
                    dispatch(setExcelFile(file));
                    const exportStatus = await esvc.exportToHybris(customer.account.uid, file);
                    setTimeout(() => updateExportStatus(exportStatus.id, [...buildIds], currentWorkspace.id), 200);
                } else {
                    const filename = res.filename;
                    FileHelper.downloadFile(filename, res.data);
                }
            }
        }
        catch (error) {
            NotificationService.serviceError(error, "Can't export build");
        }
    }, [validateExport, currentWorkspace, customer, dispatch, displayUnits, updateExportStatus]);

    const generateFlexNAPBOM = useCallback(async (buildIds: number[]): Promise<void> => {
        if (currentWorkspace === undefined || !validateExport(buildIds)) return;
        
        try {
            const esvc = new ExportService();
            const unitOfMeasure = customer?.account?.unitOfMeasure ?? displayUnits;
            esvc.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
            const res = await esvc.generateFlexNAPBOM(buildIds, unitOfMeasure);
            if (res?.data) {
                const filename = res.filename;
                FileHelper.downloadFile(filename, res.data);
            }
        } catch (error) {
            NotificationService.serviceError(error, "Can't generate bill of materials");
        }
    }, [currentWorkspace, validateExport, customer, displayUnits]);

    return {
        exportFlexNapBuilds,
        generateFlexNAPBOM
    };
};
