import React from 'react';

export const powerDeviceSvg = (): JSX.Element => (
    <g className="power-device" transform="translate(32 63)">
        <rect fill="none" opacity="0.3" width="72" height="272" rx="4"/>
        <rect fill="#9E9E9E" width="4" height="18" transform="translate(34 91)"/>
        <rect fill="#606060" width="2" height="18" transform="translate(35 91)"/>
        <g fill="#606060" stroke="#9E9E9E" transform="translate(42 92) rotate(180)">
            <path stroke="none" d="M3,0H9a3,3,0,0,1,3,3v7a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z"/>
            <path fill="none" d="M3,.5H9A2.5,2.5,0,0,1,11.5,3V9a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,9V3A2.5,2.5,0,0,1,3,.5Z"/>
        </g>
        <line fill="none" stroke="#5F5F5F" strokeWidth="2" y2="8" transform="translate(48 146)"/>
        <line fill="none" stroke="#5F5F5F" strokeWidth="2" y2="8" transform="translate(45 146)"/>
        <line fill="none" stroke="#5F5F5F" strokeWidth="2" y2="8" transform="translate(42 146)"/>
        <line fill="none" stroke="#5F5F5F" strokeWidth="2" y2="8" transform="translate(39 146)"/>
        <g filter="url(#powerDeviceShadow)" transform="matrix(1, 0, 0, 1, 0, 0)">
            <g fill="#606060" stroke="#9E9E9E" transform="translate(16 151)">
                <rect stroke="none" width="40" height="93" rx="4"/>
                <rect fill="none" x="0.5" y="0.5" width="39" height="92" rx="3.5"/>
            </g>
        </g>
        <g fill="#606060" stroke="#9E9E9E" transform="translate(30 107)">
            <path stroke="none" d="M3,0H9a3,3,0,0,1,3,3V14a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z"/>
            <path fill="none" d="M3,.5H9A2.5,2.5,0,0,1,11.5,3V13a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,13V3A2.5,2.5,0,0,1,3,.5Z"/>
        </g>
        <g filter="url(#powerDeviceShadow)" transform="matrix(1, 0, 0, 1, 0, 0)">
            <g fill="#606060" stroke="#9E9E9E" transform="translate(16 120)">
                <rect stroke="none" width="40" height="28" rx="4"/>
                <rect fill="none" x="0.5" y="0.5" width="39" height="27" rx="3.5"/>
            </g>
        </g>
        <g fill="none" stroke="#AAA" transform="translate(30 128)">
            <circle stroke="none" cx="6" cy="6" r="6"/>
            <circle fill="none" cx="6" cy="6" r="5.5"/>
        </g>
        <line fill="none" stroke="#AAA" x2="5" transform="translate(36.5 134)"/>
        <path fill="none" stroke="#818181" strokeWidth="2" d="M4933.072-9442.5l-24.481-6.368Zm-15.907,0-8.309-2.161Zm-8.574-11.367,37.458,9.74Zm0-4.8,38,9.883Zm0-4,38,9.883Zm0-4,38,9.883Zm0-3.882,38,9.882Zm0-3.883,38,9.883Zm0-3.882,38,9.881Zm0-3.882,38,9.883Zm0-3.885,38,9.885Zm0-3.882,38,9.882Zm0-3.881,38,9.882Zm0-3.884,38,9.883Zm0-3.882,38,9.883Zm0-3.883,38,9.883Zm0-3.882,38,9.883Zm0-3.882,38,9.883Zm0-3.882,38,9.882Zm0-3.883,38,9.883Zm0-3.882,38,9.883Zm0-3.884,38,9.884Zm1.974-3.369,36.026,9.369Zm13.581-.351,22.445,5.838Zm14.927,0,7.1,1.848Z" transform="translate(-4891.59 9684.5)"/>
        <g fill="none" stroke="#9E9E9E" transform="translate(16 151)">
            <rect stroke="none" width="40" height="93" rx="4"/>
            <rect fill="none" x="0.5" y="0.5" width="39" height="92" rx="3.5"/>
        </g>
        <circle fill="#606060" cx="12.5" cy="12.5" r="12.5" transform="translate(24 185)"/>
        <g transform="translate(24 186)">
            <rect fill="none" width="24" height="24"/>
            <path fill="#EB8D64" d="M7,2V12.967H9.091V21.94L13.97,9.976H11.182L13.97,2Z" transform="translate(2 1)"/>
        </g>
    </g>
);
