import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSecuredAsyncAction } from '../action';
import { BulkSplicePlan } from '../../models/bulk/splice-plan';
import { SplicePlanService as BulkSplicePlanService, UpdateSplicePlanRequest } from '../../services/bulk/bulk-splice-plan.service';

export interface SplicePlanState {
    currentSplicePlan?: BulkSplicePlan;
}

const initialState: SplicePlanState = { };

const setCurrentSplicePlanReducer = (state: SplicePlanState, action: PayloadAction<BulkSplicePlan>): void => {
    state.currentSplicePlan = action.payload;
};

const { actions, reducer } = createSlice({
    name: 'bulk-splice-plan',
    initialState,
    reducers: {
        setCurrentSplicePlan: setCurrentSplicePlanReducer,
    }
});

export { reducer as SplicePlanReducer };

export const getSplicePlan = (buildId: number) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkSplicePlanService();
        const splicePlan = await service.getSplicePlan(buildId);
        if (splicePlan) {
            dispatch(actions.setCurrentSplicePlan(splicePlan));
        }
    });
};

export const updateSplicePlan = (request: UpdateSplicePlanRequest) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new BulkSplicePlanService();
        const splicePlan = await service.updateSplicePlan(request);
        if (splicePlan) {
            dispatch(actions.setCurrentSplicePlan(splicePlan));
        }
    });
};