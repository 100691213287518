import './access-denied.component.scss';

import React from 'react';

import { Modal } from '@mui/material';

import { connect, StateModel } from '../../redux/reducers';

const mapStateToProps = (state: StateModel) => {
    const {roles} = state.role;
    return { roles };
};

type props = Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps) as any)
export class AccessDeniedComponent extends React.Component<props> {

    public render(): JSX.Element {
        return (
            <Modal open={true} disableAutoFocus={true} disableEnforceFocus={true}>
                <div className='forbidden-access-panel'>
                    <img className="forbidden-access-image" src={require('./icon_error.png')} alt="Fobidden Access"></img>
                    <div>
                        <h5 className="headline">DNA Design Tool</h5>
                        <p className="body">Your account does not have permission to access this application.</p>
                        <p className="body">Contact your account administrator.</p>
                    </div>
                </div>
            </Modal>
        );
    }
}