import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PresetAttribute } from '../components/presets/cable-wizard.component';
import { WizardService } from '../services/wizard.service';
import { NotificationService } from '../services/notification.service';
import { createSecuredAsyncAction } from './action';

export interface WizardState {
    past: PresetAttribute[];
    current?: PresetAttribute;
}

const initialState: WizardState = {
    past: [],
    current: undefined,
};

const undoReducer = (state: WizardState, _: PayloadAction<undefined>): void => {
    const { past } = state;
    const previous = past[past.length - 1];
    const newPast = past.slice(0, past.length - 1);
    state.past = newPast;
    state.current = { ...previous, selected: undefined };
};

const nextReducer = (state: WizardState, action: PayloadAction<PresetAttribute | undefined>): void => {
    const current = action.payload as PresetAttribute;
    if (state.current) {
        state.past.push(state.current);
    }
    if (current) {
        state.current = current;
    }
};

const clearReducer = (state: WizardState, _: PayloadAction<undefined>): void => {
    state.past = [];
    state.current = undefined;
};

const updateCurrentReducer = (state: WizardState, action: PayloadAction<string>): void => {
    if (state.current) {
        state.current = { ...state.current, selected: action.payload };
    }
}

const { actions, reducer } = createSlice({
    name: 'wizard',
    initialState,
    reducers: {
        undo: undoReducer,
        next: nextReducer,
        clear: clearReducer,
        updateCurrent: updateCurrentReducer,
    },
});

export { reducer as WizardReducer };
export const { undo, clear } = actions;

export const next = (attribute: string | null, selected: string | null, options: { [s: string]: any }, soldTo?: string) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new WizardService();
        const wizardOptionResult = await service.getNextOptions(attribute, selected, options, soldTo);
        const { attribute: nextAttribute, options: nextOptions } = wizardOptionResult || {};
        if (nextAttribute && nextOptions) {
            dispatch(actions.updateCurrent(selected ?? ""));
            dispatch(actions.next({ name: nextAttribute, options: nextOptions.sort((a, b) => a > b ? 1 : -1) }));
            if (!nextOptions.length && nextAttribute !== 'Fiber Count') {
                NotificationService.error("There's no compatible part numbers associated with your contract. Please contact your administrator.");
            }
            else if (nextOptions.length === 1) {
                if (attribute) {
                    options[attribute] = selected;
                }
                dispatch(next(nextAttribute, nextOptions[0], options));
            }
        }
    })
};

