import { PluggableMap } from 'ol';
import { Pixel } from 'ol/pixel';

import { store } from '../dna';
import { InteractableFeatureType } from '../openlayers/interactable-feature-type';
import { createFlexNapBuild } from '../redux/build.state';
import { BaseCableTool } from './base-cable-tool';
import { ToolDefinition } from './tool-definition';
import { ToolType } from './tooltype';

export class FlexNAPCableTool extends BaseCableTool {
    public static definition: ToolDefinition = {
        toolType: ToolType.FlexNAPCable,
        name: 'FlexNAP',
        shortcut: 'c',
    };

    public static finishCable(elementIds: number[]) {
        const state = store.getState();
        const workspaceId = state.workspace.currentWorkspace?.id;
        if (!workspaceId) {
            throw new Error('Can\'t create a build without a parent workspace');
        }
        elementIds = elementIds.filter((id, index) => elementIds.indexOf(id) === index);
        const segments: Array<{ position: number; fromId: number; toId: number }> = [];
        for (let i = 1; i < elementIds.length; i++) {
            const position = i - 1;
            const fromId = elementIds[i - 1];
            const toId = elementIds[i];
            segments.push({ position, fromId, toId });
        }
        if (segments.length !== 0) {
            createFlexNapBuild(workspaceId, segments)(store.dispatch); // will set state.build.currentBuild to the created build
        }

    }

    public findAccessPointAtPixel(map: PluggableMap, pixel: Pixel): number {
        const { selection, build } = store.getState();
        const { selectedBuildId } = selection;
        const { flexnapBuilds } = build;
        const selectedBuild = flexnapBuilds.find((b) => b.id === selectedBuildId)!;

        if (selectedBuild) {
            const napFeature = map.getFeaturesAtPixel(pixel).find(f => f.get('type') === InteractableFeatureType.NAP);
            if (napFeature) {
                return napFeature.get('id') as number;
            }
        }

        return super.findAccessPointAtPixel(map, pixel);
    }
}
