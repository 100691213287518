import i18next from 'i18next';
import React from 'react';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { ProjectionConverter } from '../../../helpers/projection-converter';
import { LocalizationKeys } from '../../../locales/types';
import { ElementCardState } from '../../../models/element-card-state';
import { Pole } from '../../../models/pole';
import { getPowerStatus, PowerStatus, powerStatuses } from '../../../models/power-status';
import { deleteElement } from '../../../redux/element.actions';
import { updatePole } from '../../../redux/pole.state';
import { connect, StateModel } from '../../../redux/reducers';
import { selectPole } from '../../../redux/selection.state';
import { Card } from '../../card/card';
import { CardContentComponent } from '../../card/card-content.component';
import { CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { InlineText } from '../../ui-elements/inline-text';
import { InlineSelect } from '../../ui-elements/inline-select';
import { DeleteElementDialog } from './delete-element-dialog';

const mapStateToProps = (state: StateModel) => {
    const { selectedPoleId, selectedNapId } = state.selection;
    const selectedPole = selectedPoleId && state.pole.poles.find((p) => p.id === selectedPoleId);
    const isConnectedToPath = !!selectedPoleId && !![...state.bore.bores, ...state.trench.trenches].find((p) => p.fromElementId === selectedPoleId || p.toElementId === selectedPoleId);
    const isConnectedToSegment = !!selectedPoleId && !![...state.build.segments].find((s) => s.fromId === selectedPoleId || s.toId === selectedPoleId);
    return { selectedPole, selectedNapId, isConnectedToPath, isConnectedToSegment };
};
const mapDispatchToProps = {
    selectPole,
    updatePole,
    deleteElement
};
type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class PoleCardComponent extends React.Component<props, ElementCardState>  {
    constructor(props) {
        super(props);
        this.state = { deleteDialog: false };
    }

    public componentDidMount(): void {
        document.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    private unselectPole = () => {
        this.props.selectPole!();
    }

    private handleKeyUp = (e: KeyboardEvent) => {
        const { selectedPole, isConnectedToPath, isConnectedToSegment } = this.props;

        if (e.target instanceof HTMLInputElement) {
            return;
        }

        if (!selectedPole || isConnectedToPath || isConnectedToSegment) {
            return;
        }

        if (e.key === 'Delete') {
            if (selectedPole) {
                if (selectedPole.isImported) {
                    this.showDeleteDialog(true);
                } else {
                    this.deletePole();
                }
            }
        }
    }

    private renderPoleProperties = () => {
        const { selectedPole } = this.props;
        if (selectedPole && selectedPole.properties) {
            const properties = JSON.parse(selectedPole.properties);
            const keys = Object.keys(properties);
            return (
                <div>
                    {keys.map((key) => properties[key] ? CardPropertyInline(key, properties[key]) : null)}
                </div>
            );
        }
        else {
            return null;
        }
    }

    private deletePole = () => {
        const { selectedPole } = this.props;
        if (selectedPole) {
            this.props.deleteElement!(selectedPole.id, selectedPole.type);
        }
        this.showDeleteDialog(false);
    }

    private showDeleteDialog = (show: boolean) => {
        this.setState({ deleteDialog: show });
    }

    private handlePowerStatusUpdate = (value?: string) => {
        const powerStatus: PowerStatus | undefined = value && PowerStatus[value];
        const { selectedPole } = this.props;
        if (selectedPole && powerStatus) {
            const newPole: Pole = { ...selectedPole, powerStatus };
            this.props.updatePole!(selectedPole, newPole);
            this.props.selectPole!(newPole.id);
        }
    }

    private handleIdOverrideUpdate = (value: string): void => {
        const { selectedPole } = this.props;
        if (selectedPole) {
            const newPole: Pole = { ...selectedPole, tagOverride: value ? value : undefined };
            this.props.updatePole!(selectedPole, newPole);
            this.props.selectPole!(newPole.id);
        }
    }

    public render() {
        const { selectedPole, selectedNapId, isConnectedToPath, isConnectedToSegment } = this.props;
        if (!selectedPole || selectedNapId !== undefined) { // only show when no nap selected
            return null;
        }

        const isImported = selectedPole.isImported;
        const powerStatus = selectedPole.powerStatus ?? PowerStatus.None;
        const coordinates = ProjectionConverter.convertMapToLatLong([selectedPole.x, selectedPole.y]);
        let titleDeleteBtn = '';
        if (isConnectedToPath) {
            titleDeleteBtn = i18next.t(LocalizationKeys.ConnectedPathWarning);
        } else if (isConnectedToSegment) {
            titleDeleteBtn = i18next.t(LocalizationKeys.ConnectedSegmentWarning);
        }
        return (
            <Card id="pole-info" onCloseClick={this.unselectPole}>
                <CardHeaderComponent title={i18next.t(LocalizationKeys.Pole)} subheader={selectedPole.tagOverride ?? selectedPole.tag} icon={require('./../../../icons/pole.png')} />
                <CardContentComponent>
                    <InlineText
                        id="idOverride"
                        label={i18next.t(LocalizationKeys.MandatoryField, { fieldName: i18next.t(LocalizationKeys.IDOverride) })}
                        value={selectedPole.tagOverride ?? selectedPole.tag}
                        align={'right'}
                        onUpdate={this.handleIdOverrideUpdate}
                    />
                    {CardPropertyInline(i18next.t(LocalizationKeys.Longitude), `${coordinates[0].toFixed(5)}`)}
                    {CardPropertyInline(i18next.t(LocalizationKeys.Latitude), `${coordinates[1].toFixed(5)}`)}
                    <InlineSelect
                        key="powerStatus"
                        label={i18next.t(LocalizationKeys.Power)}
                        value={powerStatus}
                        values={powerStatuses}
                        displayValues={powerStatuses.map(getPowerStatus)}
                        onUpdate={this.handlePowerStatusUpdate}
                        maxHeight={96}
                    />
                    {this.renderPoleProperties()}
                    <div className="split-item extra-info">{i18next.t(LocalizationKeys.IdUsedForCableLabeling)}</div>
                    <div className="card-footer" title={titleDeleteBtn}>
                        <TextButton
                            palette={MainPalettes.error}
                            disabled={isConnectedToPath || isConnectedToSegment}
                            onClick={(): void => isImported ? this.showDeleteDialog(true) : this.deletePole()}
                        >
                            <i className="material-icons">delete</i>
                        </TextButton>
                    </div>
                </CardContentComponent>
                <DeleteElementDialog open={this.state.deleteDialog} elementId={selectedPole.id} onDelete={this.deletePole} onClose={(): void => this.showDeleteDialog(false)} />
            </Card>
        );
    }
}
