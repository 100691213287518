import i18next from 'i18next';
import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { NapData } from '../data/nap-data';
import { SegmentData } from '../data/segment-data';
import { ItemType } from '../item-type';
import { Validator } from './validator';

const BUFFER_TUBE_FIBERS = 12;

export class BufferTubeValidator implements Validator<FlexNapBuildData> {
    public validate(build: FlexNapBuildData): ValidationResult[] | null {
        const orderedSegments: SegmentData[] = build.segments.sort((a, b) => a.position - b.position);
        const orderedLocations = orderedSegments.flatMap(s => [s.from.id, s.to.id]).distinct();
        let previousTubeRemainingFibers = 0;
        for (const location of orderedLocations) {
            const nap = build.naps.find(n => n.elementId === location);
            if (nap) {
                for (const tap of nap.taps.reverse()) { // Splice plan fiber assignment starts from the last TAP at a given location (High to Low - CO to Field)
                    const tapFibers = tap.tethers.map((t) => t.fiberCount).reduce((prv, t) => prv + t, 0);
                    if (previousTubeRemainingFibers > 0) { // Remaining fibers is normally within the range 2F-10F
                        const firstTether = tap.tethers.find(t => t.tetherIndex === 1);
                        const secondTether = tap.tethers.find(t => t.tetherIndex === 2);
                        if (firstTether && secondTether // Individual fiber count of the tethers must be greater than the remaining fibers
                            && firstTether.fiberCount > previousTubeRemainingFibers && secondTether.fiberCount > previousTubeRemainingFibers
                            && (firstTether.fiberCount === BUFFER_TUBE_FIBERS || secondTether.fiberCount === BUFFER_TUBE_FIBERS)) { // TAP must have one tether with 12F
                            return this.bufferTubeValidationResult(nap, i18next.t(LocalizationKeys.BufferTubeValidationMsg, { tapIndex: tap.tapIndex }), ValidationResultType.Error);
                        } else {
                            if (previousTubeRemainingFibers > tapFibers) {
                                previousTubeRemainingFibers -= tapFibers;
                            } else {
                                const remainingFibers = tapFibers - previousTubeRemainingFibers;
                                previousTubeRemainingFibers = BUFFER_TUBE_FIBERS - remainingFibers;
                            }
                        }
                    } else {
                        const nbUsedTubes = Math.ceil(tapFibers / BUFFER_TUBE_FIBERS);
                        previousTubeRemainingFibers = (nbUsedTubes * BUFFER_TUBE_FIBERS) - tapFibers
                    }
                }
            }
        }

        return null;
    }

    public canValidate(entity: unknown): boolean {
        if (entity instanceof FlexNapBuildData) {
            return entity.part?.cable_Type === i18next.t(LocalizationKeys.LooseTube);
        } else {
            return false;
        }
    }

    private bufferTubeValidationResult(nap: NapData, message: string, type: ValidationResultType): ValidationResult[] {
        const extent = nap.getExtent();
        const results = [{ buildId: nap.buildId, itemId: nap.id, itemType: ItemType.Nap, shortDescription: message, type, extent }];
        if (nap.childPretermBuilds) {
            nap.childPretermBuilds.forEach(b => results.push({ buildId: b.id, itemId: nap.id, itemType: ItemType.Nap, shortDescription: message, type, extent }));
        }

        return results;
    }
}