import './upload-content.scss';

import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, DialogContent, Icon } from '@mui/material';
import { OutlinedButton } from '@orbit/button';
import { Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { UploadDialogContentProps } from './import.card.types';
import { useUploadDialogContent } from './upload-content.hooks';

const renderChip = (id: string, label: string, setFiles: Dispatch<SetStateAction<File[]>>): JSX.Element => {
    return (
        <Chip id={id} label={label} key={id} deleteIcon={<img alt="Remove" src={require('./icon_chip_close.svg')} />} onDelete={/* Deletes the file from the list*/ (): void => setFiles(previousFiles => previousFiles.filter(file => file.name !== label))} />
    );
}

export const UploadContent = (props: UploadDialogContentProps): JSX.Element => {
    const { t } = useTranslation();
    const { errorMsg, isDragging, files, fileUpload,
        handleFileSelection, showFileSelection, setFiles,
        onDragOver, onDragLeave, onDrop } = useUploadDialogContent(props);
    const errorElement = !errorMsg ? (<div className="error-box" />) : (
        <div className="error-box">
            <Icon className="warning-icon" /><span>{errorMsg}</span>
        </div>
    );
    const dropAreaStyle = 'drop-area' + (isDragging ? ' dragging' : '');
    return (<DialogContent className="dialog-content upload-dialog-content">
        <div className={dropAreaStyle} onDragOver={onDragOver} onDragEnter={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
            <div className="file-selection-container">
                <div>
                    <Typography variant="h5" label={t(LocalizationKeys.DragAndDropFilesHere)} />
                    <Typography variant="body1" label="OR" />
                </div>
                <input type="file" accept=".zip,.shp,.dbf,.json,.kmz,.gdb,.csv,.prj,.geojson" ref={fileUpload} multiple={true} onChange={handleFileSelection} />
                <OutlinedButton className="upload-button" onClick={showFileSelection}>{t(LocalizationKeys.SelectFileFromComputer)}</OutlinedButton>
            </div>
            <div className="file-chip-set">
                {files.map(file => renderChip(`${file.type}`, file.name, setFiles))}
            </div>
        </div>
        {errorElement}
    </DialogContent >
    );
}

