import '@material/snackbar/dist/mdc.snackbar.css';
import './generic-snackbar.scss';

import React, { useMemo } from 'react';

import { TextButton } from '@orbit/button';
import { Snackbar } from '@rmwc/snackbar/dist/snackbar';

import { useGenericSnackbar } from './generic-snackbar.hooks';
import { GenericSnackbarProps } from './generic-snackbar.types';

export const GenericSnackbar = (props: GenericSnackbarProps): JSX.Element => {
    const { open, type, message, icon, hideIcon, actionLabel, dismissIcon, timeout, showProgress, onClose, onAction } = useGenericSnackbar(props);

    const action = useMemo((): React.ReactNode[] => {
        const actions: React.ReactNode[] = [];
        if (actionLabel) {
            actions.push(<TextButton className="generic-snackbar-action-button" onClick={onAction}>{actionLabel}</TextButton>);
        }
        if (dismissIcon) {
            actions.push(<TextButton className="generic-snackbar-close-button" onClick={onClose}><i className="material-icons">close</i></TextButton>);
        }
        return actions;
    }, [actionLabel, dismissIcon, onAction, onClose]);

    const progress = useMemo((): React.ReactNode => {
        return !showProgress ? null : (
            <div className="snackbar-progress">
                <div className="bar"><div className="slider" /></div>
            </div>
        );
    }, [showProgress]);

    return (
        <div className="snackbar-container">
            <Snackbar
                open={open}
                className={type ? `generic-snackbar ${type}` : 'generic-snackbar'}
                dismissesOnAction={true}
                message={message}
                icon={hideIcon ? null : <i className="material-icons">{icon}</i>}
                dismissIcon={false}
                action={action}
                timeout={timeout}
                onClose={onClose}
            >
                {progress}
            </Snackbar>
        </div>
    );
};
