export { };

if (!Date.prototype.toShortString) {
    // eslint-disable-next-line
    Date.prototype.toShortString = function (this: Date): string {
        const mm = (this.getMonth() + 1).toString().padStart(2, "0");
        const dd = this.getDate().toString().padStart(2, "0");
        const yy = (this.getFullYear() % 100).toString().padStart(2, "0");
        const hh = this.getHours().toString().padStart(2, "0");
        const m = this.getMinutes().toString().padStart(2, "0");
        return `${mm}/${dd}/${yy} ${hh}:${m}`;
    };
}

declare global {
    // tslint:disable-next-line: interface-name
    interface Date {
        /**
         * Converts a date and time to a short string.
        */
        toShortString(): string;
    }
}
