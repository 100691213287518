import Feature from 'ol/Feature';
import * as layer from 'ol/layer';
import { getUid } from 'ol/util';

declare module 'ol/layer' {
    // tslint:disable-next-line: interface-name
    export interface Vector {
        /**
         * Sorts OpenLayers VectorLayer Features by Uid.
         */
        sortFeaturesByUid(): Feature[];
    }
}

if (!layer.Vector.prototype.sortFeaturesByUid) {
    // eslint-disable-next-line
    layer.Vector.prototype.sortFeaturesByUid = function(this: layer.Vector): Feature[] {
        const features = this.getSource().getFeatures();
        return features.sort((f1, f2) => +getUid(f1) - +getUid(f2));
    };
}
