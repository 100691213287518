import '../cards.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextButton } from '@orbit/button';
import { IconButton } from '@orbit/icon-button';

import { ToolType } from '../../../design-tools/tooltype';
import { LocalizationKeys } from '../../../locales/types';
import { Card } from '../../card/card';
import { CardCollapsibleComponent } from '../../card/card-collapsible.component';
import { CardContentComponent } from '../../card/card-content.component';
import { CardProperty, CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { DownloadBOMIcon } from '../build/assets/download-bom-icon';
import { DesignAreaBuildsList } from './design-area-builds-list';
import { DesignAreaColorProperty } from './design-area-color-property';
import { DesignAreaInformation } from './design-area-information';
import { useDesignAreaCard } from './design-area.hooks';
import { DeleteDesignAreaDialog } from './dialogs/delete-design-area-dialog';

export const DesignAreaCard = (): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        selectedDesignArea, selectedTool, parcelCount, cabinetIds,
        daBuilds, naps, taps, tethers,
        deleteDialog, showDeleteDialog,
        unselectDesignArea, onDeleteDesignArea, onUpdateDesignArea,
        cabinetLabel, hasFlexNapBuild, hasSchrodingerBuild,
        countries, regions,
        collapsed, toggleCollapsed,
        onDownloadClick, downloadBOM
    } = useDesignAreaCard();

    if (!selectedDesignArea || selectedTool === ToolType.Modification) {
        return null;
    }

    return (
        <Card id="design-area-info" onCloseClick={unselectDesignArea} onToggleCollapse={toggleCollapsed}>
            <CardHeaderComponent title={selectedDesignArea.name || selectedDesignArea.projectId} icon={require('./../../../icons/design-area.png')} />
            <CardContentComponent>
                <CardCollapsibleComponent id="designAreaProperties" label={t(LocalizationKeys.Properties)} collapsed={collapsed}>
                    {CardPropertyInline(t(LocalizationKeys.Parcels), parcelCount)}
                    {CardPropertyInline(t(LocalizationKeys.Builds), daBuilds.length)}
                    {hasFlexNapBuild && CardPropertyInline(t(LocalizationKeys.NAPs), naps.length)}
                    {hasSchrodingerBuild && CardPropertyInline(t(LocalizationKeys.RadioAntennas), 0)}
                    {CardPropertyInline(cabinetLabel, cabinetIds.length ? cabinetIds.sort().join(', ') : `${t(LocalizationKeys.Undefined)}`)}
                    {selectedDesignArea.color && CardProperty(t(LocalizationKeys.Color), <DesignAreaColorProperty designAreaColor={selectedDesignArea.color} onUpdate={onUpdateDesignArea} />)}
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>
                            <TextButton className="card-icon-button" palette='error' onClick={(): void => showDeleteDialog(true)}><i className="material-icons">delete</i></TextButton>
                            <TextButton className="card-icon-button" onClick={onDownloadClick}><i className="material-icons">file_download</i></TextButton>
                        </div>
                        <IconButton {...downloadBOM} icon={<DownloadBOMIcon disabled={downloadBOM.disabled} />} />
                    </div>
                </CardCollapsibleComponent>
                <CardCollapsibleComponent id="designAreaInformation" label={t(LocalizationKeys.Information)} collapsed={collapsed}>
                    <DesignAreaInformation designArea={selectedDesignArea} countries={countries} regions={regions} onUpdate={onUpdateDesignArea} />
                </CardCollapsibleComponent>
                <CardCollapsibleComponent id="designAreaBuildList" label={t(LocalizationKeys.BuildList)} collapsed={collapsed}>
                    <DesignAreaBuildsList builds={daBuilds} taps={taps} tethers={tethers} />
                </CardCollapsibleComponent>
            </CardContentComponent>
            <DeleteDesignAreaDialog open={deleteDialog} designAreaId={selectedDesignArea.id} onDelete={onDeleteDesignArea} onClose={(): void => showDeleteDialog(false)} />
        </Card>
    );
}