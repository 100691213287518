import { createSelector } from '@reduxjs/toolkit';

import { Tether } from '../../models/schrodinger/tether';
import { selectedTetherIdSchrodingerSelector, tethersSchrodingerSelector } from '../root.selectors';

export const selectedTetherSelector = createSelector(
    selectedTetherIdSchrodingerSelector,
    tethersSchrodingerSelector,
    (tetherId, tethers) =>
        tethers.find((t: Tether) => t.id === tetherId)
)

/**
 * Get all tethers from the same selected tether's tap
 */
export const selectedTetherSiblingsSelector = createSelector(
    selectedTetherSelector,
    tethersSchrodingerSelector,
    (selectedTether, tethers) =>
        tethers.filter((t: Tether) => selectedTether && t.tapId === selectedTether.tapId)
            .sort((tetherA, tetherB) => tetherA.id - tetherB.id)
)