import React from "react"
import { Graphics, Text } from "@inlet/react-pixi";
import { useTooltip } from "./tooltip.hooks";
import { TooltipProps } from "./tooltip.types";

export const Tooltip = (props: TooltipProps) => {
    const { tooltipText, drawTooltip, textProps } = useTooltip(props);

    return (
        <>
            <Graphics draw={drawTooltip} />
            <Text text={tooltipText} {...textProps} />
        </>
    )
}