import { PointLike } from '../models/pointlike';
import { Trench, TrenchData } from '../models/trench';
import { SecuredService } from './abstract-secured-v2.service';

export class TrenchService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/trench';

    public getAll(): Promise<Trench[] | undefined> {
        return this.get<Trench[]>(this.baseUrl);
    }

    public getTrenches(bbox: number[], required?: number[]): Promise<Trench[] | undefined> {
        return this.post<Trench[]>(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public addTrench(request: AddPathRequest): Promise<Trench | undefined> {
        return this.post(this.baseUrl, request);
    }

    public addTrenches(data: TrenchData[], importedGisDataId?: number): Promise<void> {
        return this.post(`${this.baseUrl}/import`, { data, importedGisDataId });
    }

    public deleteTrench(id: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${id}`);
    }
}

export interface AddPathRequest {
    fromElementId: number;
    toElementId: number;
    points: PointLike[];
}
