import { CpqInstallationType } from '../models/cpqinstallationtype';
import { CpqCablePart } from '../models/cpqcablepart';
import { CpqTetherPart } from '../models/cpqtetherpart';
import { CpqBuildConfiguration } from '../models/cpqbuildconfiguration';
import { SecuredService } from './abstract-secured-v2.service';

export class WizardService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/wizard';

    public getNextOptions(attribute: string | null, selected: string | null, options: { [s: string]: any }, soldTo?: string): Promise<WizardOptionResult | undefined> {
        return this.post<WizardOptionResult>(this.baseUrl, { attribute, selected, options, soldTo });
    }

    public getFiberCounts(installationType: string, options: { [s: string]: any }, soldTo?: string): Promise<FiberCountResult | undefined> {
        return this.post<FiberCountResult>(`${this.baseUrl}/fibercounts`, { installationType, options, soldTo });
    }
}

interface WizardOptionResult {
    attribute: string;
    options: string[];
}

interface FiberCountResult {
    installationType: CpqInstallationType;
    cableParts: CpqCablePart[];
    tetherParts: CpqTetherPart[];
    configurations: CpqBuildConfiguration[];
}
