
import { OktaServerConfig } from '../okta/okta.config';
import { SecuredService } from './abstract-secured-v2.service';
export class AuthenticationService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/auth';

    constructor() {
        super();
        this.isUnstableApi = false;
    }

    public login(username: string, password: string): Promise<string | undefined> {
        return this.post(this.baseUrl + '/login', { username, password });
    }

    public oidConfig(): Promise<OktaServerConfig | undefined> {
        return this.get(this.baseUrl + '/oid');
    }

    public oidLogin(token: string): Promise<string | undefined> {
        return this.post(this.baseUrl + '/oid', { token });
    }

    public logout(): Promise<void> {
        return this.post(this.baseUrl + '/logout', {});
    }

    public getUserId(): Promise<number | undefined> {
        return this.get(this.baseUrl + '/userId');
    }
}