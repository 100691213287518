import { createSelector } from '@reduxjs/toolkit';
import { DesignMode } from '../models/design-mode';

import { SchrodingerReferenceType } from '../models/schrodinger/reference-id';
import {
    awaitingSchrodingerSessionUpdatesSelector, buildSelector, bulkBuildsSelector, designModeSelector,
    flexnapBuildsSelector, schrodingerBuildsSelector, segmentsSelector, selectedBuildIdSelector
} from './root.selectors';

export const allBuildsSelector = createSelector(
    flexnapBuildsSelector,
    schrodingerBuildsSelector,
    bulkBuildsSelector,
    (flexnapBuilds, schrodingerBuilds, bulkBuilds) => [...flexnapBuilds, ...schrodingerBuilds, ...bulkBuilds]
);

export const selectedBuildSelector = createSelector(
    selectedBuildIdSelector,
    allBuildsSelector,
    (buildId, builds) => buildId ? builds.find((b) => b.id === buildId) : undefined
);

export const selectedFiberCountBuildSelector = createSelector(
    selectedBuildIdSelector,
    flexnapBuildsSelector,
    bulkBuildsSelector,
    (buildId, flexnapBuilds, bulkBuilds) => buildId
        ? [...flexnapBuilds, ...bulkBuilds].find((b) => b.id === buildId)
        : undefined
);

export const selectedFlexNapBuildSelector = createSelector(
    selectedBuildIdSelector,
    flexnapBuildsSelector,
    (selectedBuildId, flexnapBuilds) =>
        selectedBuildId ? flexnapBuilds.find((b) => b.id === selectedBuildId) : undefined
);

export const selectedSchrodingerBuildSelector = createSelector(
    selectedBuildIdSelector,
    schrodingerBuildsSelector,
    (selectedBuildId, schrodingerBuilds) =>
        selectedBuildId ? schrodingerBuilds.find((b) => b.id === selectedBuildId) : undefined
);

export const selectedBulkBuildSelector = createSelector(
    selectedBuildIdSelector,
    bulkBuildsSelector,
    (selectedBuildId, bulkBuilds) =>
        selectedBuildId ? bulkBuilds.find(b => b.id === selectedBuildId) : undefined
);

export const selectedSegmentsSortedSelector = createSelector(
    selectedBuildIdSelector,
    segmentsSelector,
    (selectedBuildId, segments) =>
        segments.filter((s) => s.buildId === selectedBuildId).sort((a, b) => a.position - b.position)
);

export const selectedFlexNapBuildIsPreterm = createSelector(
    selectedBuildIdSelector,
    flexnapBuildsSelector,
    (selectedBuildId, flexnapBuilds) =>
        selectedBuildId && !!flexnapBuilds.find((b) => b.id === selectedBuildId)?.pretermLateral
);

export const flexNapPretermBuildsSelector = createSelector(
    flexnapBuildsSelector,
    (flexNapBuilds) =>
        flexNapBuilds.filter(fnb => !!fnb.pretermLateral)
);

export const fiberCountBuildsSelector = createSelector(
    flexnapBuildsSelector,
    bulkBuildsSelector,
    (flexnapBuilds, bulkBuilds) =>
        [...flexnapBuilds, ...bulkBuilds]
);

export const tempCanvasBuildSpansSelector = createSelector(
    buildSelector,
    (build) => build.tempCanvasBuildSpans
)

export const displayedBuildSelector = createSelector(
    buildSelector,
    (build) => {
        const { displayedBuildId, schrodingerBuilds, tempCanvasBuildSpans } = build;
        if (tempCanvasBuildSpans) return undefined;
        return schrodingerBuilds.find(({ id }) => id === displayedBuildId);
    }
)

export const canvasLocationsSelector = createSelector(
    displayedBuildSelector,
    segmentsSelector,
    tempCanvasBuildSpansSelector,
    (displayedBuild, segments, tempSpans) => tempSpans ?? segments.filter(s => s.buildId === displayedBuild?.id)
)

export const displayedBuildAwaitingSessionUpdateSelector = createSelector(
    displayedBuildSelector,
    awaitingSchrodingerSessionUpdatesSelector,
    (build, awaitingSchrodingerReferenceId) => !!awaitingSchrodingerReferenceId.find(b => b.id === build?.id && b.type === SchrodingerReferenceType.Build)
)

export const displayedBuildIdSelector = createSelector(
    displayedBuildSelector,
    (build) => build?.id
)

export const activeBuildSelector = createSelector(
    designModeSelector,
    selectedBuildSelector,
    displayedBuildSelector,
    (mode, selectedBuild, displayedBuild) => mode === DesignMode.CanvasMode ? displayedBuild : selectedBuild
)

