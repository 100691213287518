import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GenericSnackbar } from '../../ui-elements/generic-snackbar/generic-snackbar';
import { showDialog, DialogType } from '../../../redux/overlay.state';
import { LocalizationKeys } from '../../../locales/types';
import { SnackbarTypes } from '../../ui-elements/generic-snackbar/generic-snackbar.types';
import { defaultPresetSelector, presetsSelector } from '../../../selectors/preset.selectors';
import { designModeSelector, dialogSelector } from '../../../selectors/root.selectors';
import { DesignMode } from '../../../models/design-mode';

export const PresetSnackbar = (): JSX.Element | null => {
    const { t } = useTranslation();
    const dialog = useSelector(dialogSelector);
    const defaultPreset = useSelector(defaultPresetSelector);
    const presets = useSelector(presetsSelector);
    const [open, setOpen] = useState(false);
    const designMode = useSelector(designModeSelector)
    const dispatch = useDispatch();

    useEffect(() => {
        setOpen(!!presets.length && !defaultPreset && !dialog && designMode === DesignMode.GISMode);
    }, [presets, defaultPreset, dialog, designMode]);

    const managePresets = useCallback(() => {
        dispatch(showDialog(DialogType.Preset));
    }, [dispatch]);

    return (
        <>
            <GenericSnackbar
                open={open}
                type={SnackbarTypes.Error}
                message={t(LocalizationKeys.NoDefaultPreset)}
                actionLabel={t(LocalizationKeys.ManagePresets)}
                dismissIcon={false}
                timeout={-1}
                onAction={managePresets}
            />
        </>
    );
};