import './drawer.scss';

import React from 'react';

import { DesignMode } from '../../models/design-mode';
import { ControlType, DialogType } from '../../redux/overlay.state';
import { BoreCard } from '../cards/bore/bore-card';
import { BulkBuildCard } from '../cards/bulk/bulk-build-card';
import { CabinetSplicePlanCard } from '../cards/cabinet-splice-plan/cabinet-splice-plan-card';
import { CabinetCard } from '../cards/cabinet/cabinet-card';
import { CreateBuildComponent } from '../cards/canvas/create-build/create-build';
import {
    DesignAreaCard as CanvasDesignAreaCard
} from '../cards/canvas/design-area/design-area-card';
import { DesignAreaCard } from '../cards/design-area/design-area-card';
import { HandholeCardComponent } from '../cards/element/handhole.component';
import { ManholeCardComponent } from '../cards/element/manhole.component';
import { ParcelCardComponent } from '../cards/element/parcel.component';
import { PoleCardComponent } from '../cards/element/pole.component';
import { VaultCardComponent } from '../cards/element/vault.component';
import { FlexNapBuildCard } from '../cards/flexnap/flexnap-build-card';
import { BuildCard as SchrodingerBuildCard } from '../cards/schrodinger/build/build-card';
import { TrenchCard } from '../cards/trench/trench-card';
import { NapCard } from '../nap-diagram/nap-card';
import { Notifications } from '../notifications/notifications';
import { ReviewList } from '../review-list/review-list';
import { SplicePointCard } from '../splice-point-diagram/splice-point-card';
import { TapCard } from '../tap-diagram/tap-card.component';
import { useDrawerRight } from './drawer-right.hooks';
import { DrawerRightProps } from './drawer-right.types';

export const DrawerRight = (): JSX.Element => {
    const { designMode, ...drawerRightProps } = useDrawerRight();
    return (
        <>
            <aside className="right-side-drawer">
                <div className="card-container">
                    <NotificationsDrawerRight {...drawerRightProps} />
                </div>
                <div className="card-container">
                    {designMode === DesignMode.GISMode && <GISDrawerRight {...drawerRightProps} />}
                    {designMode === DesignMode.CanvasMode && <CanvasDrawerRight {...drawerRightProps} />}
                </div>
            </aside>
        </>
    );
};

const NotificationsDrawerRight = ({ control }: DrawerRightProps) => {
    return (
        <>
            {control === ControlType.Warnings && <Notifications />}
            {control === ControlType.Review && <ReviewList />}
        </>
    )
};

const GISDrawerRight = ({ selectedSchrodingerBuild, selectedTap }: DrawerRightProps) => {
    return (
        <>
            <DesignAreaCard />
            <PoleCardComponent />
            <FlexNapBuildCard />
            {selectedSchrodingerBuild && <SchrodingerBuildCard build={selectedSchrodingerBuild} />}
            <BulkBuildCard />
            {selectedTap && <TapCard selectedTap={selectedTap} />}
            <NapCard />
            <SplicePointCard />
            <ParcelCardComponent />
            <ManholeCardComponent />
            <HandholeCardComponent />
            <VaultCardComponent />
            <CabinetCard />
            <TrenchCard />
            <BoreCard />
            <CabinetSplicePlanCard />
        </>
    )
}

const CanvasDrawerRight = ({ selectedSchrodingerBuild, selectedDesignAreaId, dialog, selectedTap }: DrawerRightProps) => {
    return (
        <>
            {selectedDesignAreaId && <CanvasDesignAreaCard designAreaId={selectedDesignAreaId} />}
            {dialog === DialogType.CreateNewBuild && <CreateBuildComponent />}
            {selectedSchrodingerBuild && <SchrodingerBuildCard build={selectedSchrodingerBuild} />}
            {selectedTap && <TapCard selectedTap={selectedTap} />}
        </>
    )
}