import { useState, useEffect } from 'react';
import { NoTerminalProps } from '../../nap-diagram.types';

export const useNoTerminalSvg = (props: NoTerminalProps) => {
    const { tapIndex, tetherIndex } = props;

    const [index, setIndex] = useState(-1);
    const [x, setX] = useState(0);
    const marginLeftDefault = 24;

    useEffect(() => {
        setIndex(tapIndex === 1 ? tetherIndex : tetherIndex + 2);
    }, [tapIndex, tetherIndex]);

    useEffect(() => {
        const terminalSpacing = 80;
        const isLeftTap = tapIndex === 1;
        const marginLeft = marginLeftDefault + (isLeftTap ? 0 : 14);
        setX(marginLeft + ((index - 1) * terminalSpacing));
    }, [tapIndex, index]);

    return { terminalIndex: index, x };
};