import * as rfc from 'rfc6902';

import { Bore } from '../models/bore';
import { BuildSegment } from '../models/build-segment';
import { Path } from '../models/path';
import { PoleSpan } from '../models/polespan';
import { Terminal } from '../models/terminal';
import { Trench } from '../models/trench';
import { SecuredService } from './abstract-secured-v2.service';

export class PathService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/geo/path';

    public async getPaths(bbox: number[], required?: number[]): Promise<PathsDto | undefined> {
        return this.post(`${this.baseUrl}/bbox`, { bbox, required });
    }

    public async deletePath(pathId: number): Promise<void> {
        return this.delete(`${this.baseUrl}/${pathId}`);
    }

    public async updatePath(oldPath: Path, newPath: Path): Promise<PathOperationDto | undefined> {
        const patch = rfc.createPatch(oldPath, newPath);
        return this.patch(`${this.baseUrl}/${oldPath.id}`, patch);
    }

    public async updateRelatedSegments(pathId: number): Promise<PathOperationDto | undefined> {
        return this.post(`${this.baseUrl}/${pathId}/updatesegments`);
    }

    public async updateConnectedToElement(elementId: number): Promise<Path[] | undefined> {
        return this.post(`${this.baseUrl}/connectedTo/${elementId}/resetPaths`);
    }

    public async updatePathCoordinates(path: Path): Promise<Path | undefined> {
        const { route: coordinates, fromElementId, toElementId } = path;
        return this.post(`${this.baseUrl}/${path.id}/coordinates`, { coordinates, fromElementId, toElementId });
    }
}

export interface PathsDto {
    poleSpans: PoleSpan[];
    trenches: Trench[];
    bores: Bore[];
}

export interface PathOperationDto {
    path?: Path;
    terminals?: Terminal[];
    buildSegments?: BuildSegment[];
    warningMessages?: string[];
}