import { Units } from '../../../../models/units';

export interface SlackProps {
    index: number;
    type: SlackType;
    span?: number;
    unit?: Units;
    locked?: boolean;
}

export enum SlackType {
    CO,
    FIELD,
}
