import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DesignArea } from '../models/design-area';
import { BuildService } from '../services/build.service';
import { ValidationResult } from '../validation/validation-result';

export interface MapState {
    viewport: number[];
    resolution?: number;
    desiredViewport?: number[];
    autoDropCable: boolean;
    simpleMap: boolean;
    isFullscreen: boolean;
    clickedBuild?: number;
    clickedNotification?: ValidationResult;
}

const initialSimpleMap = localStorage.getItem('simpleMap') === 'true';
const initialFullscreen = (window as any).designerFullScreen || false;
const initialState: MapState = { viewport: [], autoDropCable: false, simpleMap: initialSimpleMap, isFullscreen: initialFullscreen };

function zoomAtReducer(state: MapState, action: PayloadAction<number[] | undefined>): void {
    state.desiredViewport = action.payload;
}

function mapMoveReducer(state: MapState, action: PayloadAction<number[]>): void {
    state.viewport = action.payload;
}

function buildClickedReducer(state: MapState, action: PayloadAction<number | undefined>): void {
    state.clickedBuild = action.payload;
}

function setResolutionReducer(state: MapState, action: PayloadAction<number | undefined>): void {
    state.resolution = action.payload;
}

function toggleMapStyleReducer(state: MapState): void {
    state.simpleMap = !state.simpleMap;
    localStorage.setItem('simpleMap', state.simpleMap ? 'true' : 'false');
}

function turnOnAutoDropCableReducer(state: MapState, action: PayloadAction<boolean>): void {
    state.autoDropCable = !!action.payload;
}

function toggleFullscreenReducer(state: MapState): void {
    state.isFullscreen = !state.isFullscreen;
    window.parent.postMessage("ctcm.designer.toggleFullscreen", window.location.href);
}

function notificationClickedReducer(state: MapState, action: PayloadAction<ValidationResult | undefined>): void {
    state.clickedNotification = action.payload;
}

const { actions, reducer } = createSlice({
    name: 'map',
    initialState,
    reducers: {
        zoomAt: zoomAtReducer,
        mapMove: mapMoveReducer,
        buildClicked: buildClickedReducer,
        setResolution: setResolutionReducer,
        toggleMapStyle: toggleMapStyleReducer,
        turnOnAutoDropCable: turnOnAutoDropCableReducer,
        toggleFullscreen: toggleFullscreenReducer,
        notificationClicked: notificationClickedReducer,
    },
});

export { reducer as MapReducer };
export const {
    zoomAt,
    mapMove,
    buildClicked,
    setResolution,
    turnOnAutoDropCable,
    toggleMapStyle,
    toggleFullscreen,
    notificationClicked
} = actions;

export const zoomToCoordinates = (xCoords: number[], yCoords: number[]) => dispatch => {
    const boundingBox = [
        Math.min(...xCoords),
        Math.min(...yCoords),
        Math.max(...xCoords),
        Math.max(...yCoords)
    ];
    dispatch(zoomAt(boundingBox));
}

export const zoomToBuild = (buildId: number) => async dispatch => {
    const buildService = new BuildService();
    const coordinates = await buildService.getBuildCoordinates(buildId);
    if (coordinates && coordinates.xCoords.length && coordinates.yCoords.length) {
        const { xCoords, yCoords } = coordinates;
        zoomToCoordinates(xCoords, yCoords)(dispatch);
    }
};

export const zoomToDesignArea = (designArea: DesignArea) => async dispatch => {
    if (designArea.polygon) {
        const xCoords = designArea.polygon.map((p) => p.x);
        const yCoords = designArea.polygon.map((p) => p.y);
        zoomToCoordinates(xCoords, yCoords)(dispatch);
    }
};
