import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@orbit/theme-provider';

import { DateHelper } from '../../../helpers/date-helper';
import { LocalizationKeys, ReviewStatusLocalization } from '../../../locales/types';
import { ReviewStatus } from '../../../models/review-status';
import { ReviewListItemProps } from '../review-list.types';

export const ArchiveReviewListItem = ({ review }: ReviewListItemProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        buildId,
        submissionDateTime,
        submittedByName,
        submittedByEmail,
        submissionNotes,
        completionDateTime,
        completionNotes,
        status
    } = review;

    const approved = status === ReviewStatus.Approved;
    const rejected = status === ReviewStatus.Rejected;

    const icon = approved ? require('../icon_approved.svg') : require('../icon_rejected.svg');

    const titleClass = classNames('status', {
        'approved': approved,
        'rejected': rejected
    })

    return (
        <div className={classNames('item', 'request')}>
            <div className='title archive-tab'>
                <img alt='Status' src={icon} />
                <div className={titleClass}>
                    <Typography variant='buttonSmall' label={t(ReviewStatusLocalization[status])} />
                </div>
                <Typography variant='body1' label={` : ${t(LocalizationKeys.Build)} ${buildId.toString()}`} />
            </div>
            <div id='archive-review'>
                <div className='actor-info'>
                    {completionDateTime &&
                        <Typography
                            variant='body2'
                            label={t(LocalizationKeys.ReviewDateTime,
                                {
                                    completionDateTime: DateHelper.formatDateTimetoLocale(completionDateTime),
                                    interpolation: { escapeValue: false },
                                }
                            )}
                        />
                    }
                </div>
                {completionNotes && <div className="message">{completionNotes}</div>}
            </div>
            <div id='archive-request'>
                <div className='actor-info'>
                    <Typography
                        variant='body2'
                        label={t(LocalizationKeys.RequestDateTime,
                            {
                                submissionDateTime: DateHelper.formatDateTimetoLocale(submissionDateTime),
                                interpolation: { escapeValue: false },
                            }
                        )}
                    />
                    <div className="submitter">{t(LocalizationKeys.ReviewRequestFrom, { name: submittedByName, email: submittedByEmail })}</div>
                </div>
                {submissionNotes && <div className="message">{submissionNotes}</div>}
            </div>
        </div>
    );
};