import i18next from 'i18next';

import { RadioProps } from '@orbit/radio';

import { LocalizationKeys } from '../../../../locales/types';

export const recentFilter: RadioProps = {
    label: i18next.t(LocalizationKeys.RecentFilter).toString(), value: i18next.t(LocalizationKeys.RecentFilter)
}

export const alphabeticalFilter: RadioProps = {
    label: i18next.t(LocalizationKeys.AlphabeticalFilter).toString(), value: i18next.t(LocalizationKeys.AlphabeticalFilter)
}

export const defaultFilterOptions: RadioProps[] = [
    recentFilter,
    alphabeticalFilter
];