import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/types';
import { closeSnackbar, SnackbarDesignation, UpdateInfrastructureSnackbarPayload } from '../../../redux/overlay.state';
import { snackbarPayloadSelector } from '../../../selectors/root.selectors';
import { SnackbarTypes, GenericSnackbarProps } from '../../ui-elements/generic-snackbar/generic-snackbar.types';

export const useUpdateInfrastructureSnackbar = (): GenericSnackbarProps | undefined => {
    const { t } = useTranslation();
    const snackbarPayload = useSelector(snackbarPayloadSelector);
    const [snackbarProps, setSnackbarProps] = useState<GenericSnackbarProps>();

    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(closeSnackbar());
    }, [dispatch]);

    useEffect(() => {
        if (snackbarPayload?.designation === SnackbarDesignation.UpdateInfrastructure) {
            const { elementTag } = snackbarPayload as UpdateInfrastructureSnackbarPayload;
            setSnackbarProps({
                open: true,
                type: SnackbarTypes.Warning,
                message: t(LocalizationKeys.CalculatedSpansUpdatedWarning, { elementTag }),
                actionLabel: t(LocalizationKeys.Undo),
                timeout: 5000,
                onClose,
            });
        }
        else {
            setSnackbarProps(undefined);
        }
    }, [onClose, snackbarPayload, t]);

    return snackbarProps;
};
