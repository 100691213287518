import { Dispatch } from 'redux';

import { UndoRedoLocalizationKeys } from '../../../locales/types';
import { Tap } from '../../../models/schrodinger/tap';
import { deleteTap, undoDeleteTap } from '../../../redux/schrodinger/tap.state';
import { Command } from '../../command';

interface CreateTapCommandPayload {
    tap: Tap;
    awaitSessionUpdate: boolean;
}

export class CreateTapCommand extends Command {
    private tap: Tap;
    private awaitSessionUpdate: boolean;

    constructor({ tap, awaitSessionUpdate }: CreateTapCommandPayload) {
        super();
        this.awaitSessionUpdate = awaitSessionUpdate;
        this.tap = tap;
        this.undoMessage = { message: UndoRedoLocalizationKeys.UndoTapCreation };
        this.redoMessage = { message: UndoRedoLocalizationKeys.RedoTapCreation };
    }

    public undo(dispatch: Dispatch): void {
        deleteTap(this.tap, true, this.awaitSessionUpdate)(dispatch);
        super.undo(dispatch);
    }

    public redo(dispatch: Dispatch): void {
        undoDeleteTap(this.tap, this.awaitSessionUpdate)(dispatch);
        super.redo(dispatch);
    }
}