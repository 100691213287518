/* eslint-disable no-undef */

import { OktaStorageService } from '../okta/okta.storage.service';
import { WebService } from './abstract-web-v2.service';
import { CustomerStorageService } from './customer.storage.service';

export abstract class SecuredService extends WebService {
    constructor() {
        super();
        if (process.env.REACT_APP_MULESOFT_PROXY_ENDPOINT !== undefined) {
            const oktaSession = OktaStorageService.getOktaSession();
            const customer = CustomerStorageService.getHybrisCustomer();
            this.addDynamicHeader('sessionId', () => oktaSession?.id ?? null);
            this.addDynamicHeader('client_id', () => process.env.REACT_APP_MULESOFT_CLIENT_ID ?? null);
            this.addDynamicHeader('client_secret', () => process.env.REACT_APP_MULESOFT_CLIENT_SECRET ?? null);
            this.addDynamicHeader('implementationEnvironment', () => process.env.REACT_APP_MULESOFT_ENVIRONMENT ?? null);
            this.addDynamicHeader('X-Hybris-UID', () => customer?.uid ?? null);
            this.addDynamicHeader('X-Hybris-Account', () => customer?.uid ?? null);
            this.addDynamicHeader('X-Hybris-SoldTo', () => customer?.account.soldTo ?? null);
            this.addDynamicHeader('X-Hybris-UOM', () => customer?.account.unitOfMeasure ?? null);
        }
        this.addDynamicHeader('Authorization', () => {
            const token = OktaStorageService.getToken();
            if (!token || !token.token || token.isExpired()) {
                return null;
            }
            return 'Bearer ' + token.token;
        });
    }
}
