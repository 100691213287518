import i18next from 'i18next';
import React from 'react';
import BaseTable, { Column } from 'react-base-table';

import { LocalizationKeys } from '../../../../locales/types';
import { getColorLabel } from '../../../../models/color-type';
import { TetherLocation } from '../../../../models/flexnap-splice-plan';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { PipeColor } from '../pipe-color';
import { useSplicePlanTable } from './splice-plan-table.hooks';
import { SplicePlanTableProps } from './splice-plan-table.types';

import 'react-base-table/styles.css';

export const SplicePlanTable = (props: SplicePlanTableProps): JSX.Element | null => {
    const {
        rowHeight,
        tableHeight,
        tableData,
        frozenData,
        handleScroll,
        rowClassName,
        tableRef,
    } = useSplicePlanTable(props);

    return (
        <div className="splice-plan-dialog-table">
            <BaseTable
                ref={tableRef}
                fixed
                rowKey="key"
                width={904}
                height={tableHeight}
                data={tableData}
                frozenData={frozenData}
                rowClassName={rowClassName}
                rowHeight={rowHeight}
                headerHeight={rowHeight}
                onScroll={handleScroll}
                emptyRenderer={<DnaSpinner />}
            >
                <Column
                    key="location"
                    dataKey="locationLabel"
                    className="splice-plan-dialog-table-cell-location"
                    width={124}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingLocation)}
                />
                <Column
                    key="tap"
                    dataKey="tapIndex"
                    width={55}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingTap)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="tether"
                    dataKey="tetherIndex"
                    width={75}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingTether)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="layer"
                    dataKey="layer"
                    width={70}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingLayer)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="subunit-number"
                    dataKey="subunitNumber"
                    width={100}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingSubunitNumber)}
                    align={Column.Alignment.RIGHT}
                />
                <Column
                    key="fiber-color"
                    dataKey="fiberColor"
                    width={120}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberColorConductor)}
                    cellRenderer={({ cellData, rowData }) => (
                        <>{!(rowData as TetherLocation).isCategory && (
                            <div className="splice-plan-dialog-table-cell-column">
                                <PipeColor color={cellData} />
                                <span>{getColorLabel(cellData)}</span>
                            </div>)}
                        </>
                    )}
                />
                <Column
                    key="fiber-polarity"
                    dataKey="fiberPolarity"
                    width={130}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberPolarity)}
                    cellRenderer={({ cellData, rowData }) => (
                        <>{!(rowData as TetherLocation).isCategory && cellData}</>
                    )}
                />
                <Column
                    key="fiber-id"
                    dataKey="fiberId"
                    width={130}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingFiberId)}
                    cellRenderer={({ cellData, rowData }) => (
                            <>{!(rowData as TetherLocation).isCategory && cellData}</>
                        )}
                />
                <Column
                    key="port"
                    dataKey="portNumber"
                    width={75}
                    title={i18next.t(LocalizationKeys.SplicePlanHeadingPort)}
                    align={Column.Alignment.RIGHT}
                    cellRenderer={({ cellData, rowData }) => (
                            <>{!(rowData as TetherLocation).isCategory && (cellData ?? i18next.t(LocalizationKeys.NotApplicable))}</>
                        )}
                />
            </BaseTable>
        </div>
    )
};
