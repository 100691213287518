import React from 'react';

export const NapIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <clipPath id="clip-Icon_NAP">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip-Icon_NAP)">
                <rect width="24" height="24" fill="rgba(255,0,0,0)" />
                <g>
                    <rect width="24" height="24" fill="none" />
                    <path d="M9.393.464a1,1,0,0,1,1.214,0l8.839,6.752a1,1,0,0,1,.348,1.09L16.4,19.3a1,1,0,0,1-.955.7H4.557a1,1,0,0,1-.955-.7L.206,8.306a1,1,0,0,1,.348-1.09Z" transform="translate(2 2)" fill="#005293" />
                </g>
            </g>
        </svg>

    );
};
