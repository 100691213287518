import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../locales/types';
import { showControl } from '../../redux/overlay.state';
import { StateModel } from '../../redux/reducers';
import { notificationSelector } from '../../selectors/root.selectors';
import { ValidationResultType } from '../../validation/validation-result-type';

export const useNotifications = () => {
    const { t } = useTranslation();

    const [showErrors, setShowErrors] = useState(true);
    const [showWarnings, setShowWarnings] = useState(true);

    const workspace = useSelector((state: StateModel) => state);
    const { recentDesignMode } = workspace.workspace;
    const { notifications } = useSelector(notificationSelector);

    const errors = notifications.filter((n) => n.type === ValidationResultType.Error);
    const warnings = notifications.filter((n) => n.type === ValidationResultType.Warning);
    const displayedNotifications = [
        ...(showErrors ? errors : []),
        ...(showWarnings ? warnings : [])
    ];
    const { designAreas } = workspace.designarea;
    const { selectedBuildId, selectedDesignAreaId } = workspace.selection;
    const selectedDesignArea = designAreas.find(d => d.id === selectedDesignAreaId);
    const notificationFilter = selectedDesignArea ? `${t(LocalizationKeys.DesignArea)} ${selectedDesignArea.projectId}` : selectedBuildId ? `${t(LocalizationKeys.Build)} ${selectedBuildId}` : t(LocalizationKeys.GeographicArea);

    const dispatch = useDispatch();
    const handleClose = useCallback((): void => {
        dispatch(showControl());
    }, [dispatch]);

    const handleErrorsToggle = useCallback((): void => {
        setShowErrors(!showErrors);
    }, [setShowErrors, showErrors]);

    const handleWarningsToggle = useCallback((): void => {
        setShowWarnings(!showWarnings);
    }, [setShowWarnings, showWarnings]);

    return {
        recentDesignMode, notifications: displayedNotifications, notificationFilter, showErrors, errorCount: errors.length, showWarnings, warningCount: warnings.length,
        handleClose, handleErrorsToggle, handleWarningsToggle
    };
};
