import './review-list.scss';

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Pagination, Tab, Tabs } from '@mui/material';
import { IconButton } from '@orbit/icon-button';
import { TextField } from '@orbit/text-field';
import { MainPalettes, MainThemeTokens, Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../locales/types';
import { Card } from '../card/card';
import { CardContentComponent } from '../card/card-content.component';
import { CardHeaderComponent } from '../card/card-header.component';
import { ArchiveReviewListItem } from './archive-list-item/archive-review-list-item';
import { ResolvedReviewListItem } from './resolved-list-item/resolved-review-list-item';
import { RequestReviewListItem } from './review-list-item/request-review-list-item';
import { useReviewList } from './review-list.hooks';
import { ReviewListTab } from './review-list.types';
import { PendingReviewListItem } from './pending-list-item/pending-review-list-item';

export const ReviewList = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        tab,
        resolved,
        requests,
        archive,
        pending,
        searchValue,
        page,
        numPages,
        itemsPerPage,
        handleTabChange,
        handleCloseClick,
        handleSearchInputChange,
        clearSearch,
        handlePageChange
    } = useReviewList();

    const showClear = searchValue && searchValue.length > 0;
    const textFieldSvg = showClear ? require('../card/icon_close.svg') : require('../map-tools/icons/icon_search.svg');
    const textFieldIcon: ReactNode = <img alt={t(LocalizationKeys.Search)} src={textFieldSvg}></img>
    const textFieldControl = <IconButton palette={MainPalettes.primary} token={MainThemeTokens.dark} disabled={!showClear} placement='bottom' icon={textFieldIcon} onClick={clearSearch} />

    return (
        <Card id="review-list" onCloseClick={handleCloseClick}>
            <CardHeaderComponent title={t(LocalizationKeys.ReviewList)} subheader={false} />
            <CardContentComponent>
                <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
                    <Tab
                        label={t(LocalizationKeys.ReviewListPendingTabTitle, {
                            requestCount: pending.length < 100 ? pending.length : "99+"
                        })}
                        value={ReviewListTab.Pending}
                    />
                    <Tab
                        label={t(LocalizationKeys.ReviewListResolvedTabTitle, {
                            resolvedCount: resolved.length < 100 ? resolved.length : "99+"
                        })}
                        value={ReviewListTab.Resolved}
                    />
                    <Tab
                        label={t(LocalizationKeys.ReviewListRequestTabTitle, {
                            requestCount: requests.length < 100 ? requests.length : "99+"
                        })}
                        value={ReviewListTab.Request}
                    />
                    <Tab
                        label={t(LocalizationKeys.ReviewListArchiveTabTitle, {
                            archiveCount: archive.length < 100 ? archive.length : "99+"
                        })}
                        value={ReviewListTab.Archived}
                    />
                </Tabs>
                {tab === ReviewListTab.Pending && (
                    <div className="requests">
                        {pending.map((r, i) => <PendingReviewListItem key={i} review={r} />)}
                    </div>
                )}
                {tab === ReviewListTab.Resolved && (
                    <div className="requests">
                        {resolved.map((r, i) => <ResolvedReviewListItem key={i} review={r} />)}
                    </div>
                )}
                {tab === ReviewListTab.Request && (
                    <div className="requests">
                        {requests.map((r, i) => <RequestReviewListItem key={i} review={r} />)}
                    </div>
                )}
                {tab === ReviewListTab.Archived && (
                    <div>
                        <div className="archive-controls">
                            <TextField
                                size='small'
                                value={searchValue}
                                palette={MainPalettes.primary}
                                placeholder={t(LocalizationKeys.SearchByBuild)}
                                adornmentPosition='end'
                                InputProps={{ startAdornment: textFieldControl }}
                                onChange={handleSearchInputChange}
                            />
                            {numPages > 0 &&
                                <Pagination
                                    count={numPages}
                                    page={page}
                                    variant="outlined"
                                    color="primary"
                                    shape="rounded"
                                    siblingCount={0}
                                    onChange={handlePageChange}
                                    sx={{ paddingTop: '0.2rem' }}
                                />
                            }
                        </div>
                        {archive.length > 0 ?
                            (<div className="requests archive">
                                {archive.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((r, i) => <ArchiveReviewListItem key={i} review={r} />)}
                            </div>)
                            : searchValue.length > 0 ?
                                (<div className="no-results"><Typography variant='caption' label={t(LocalizationKeys.NoReviewsFoundInArchive, { buildId: searchValue })} /></div>)
                                :
                                (<div className="no-results"><Typography variant='caption' label={t(LocalizationKeys.NoReviewHistory)} /></div>)
                        }
                    </div>
                )}
            </CardContentComponent>
        </Card>
    );
};
