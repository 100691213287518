import { FlexNapBuild } from '../models/flexnap-build';
import { PretermLateral } from '../models/preterm-lateral';
import { Tap } from '../models/tap';
import { Tether } from '../models/tether';
import { SecuredService } from './abstract-secured-v2.service';

export class FlexNapPretermLateralService extends SecuredService {
    private readonly baseUrl = this.rootUrl + 'api/preterm/flexnap';

    public convertToPretermLateral(buildId: number, parentNapId: number, parentBuildId: number): Promise<PretermConfigResult | undefined> {
        return this.post(this.baseUrl + '/convert', { buildId, parentNapId, parentBuildId });
    }

    public updatePretermLateral(buildId: number, legType: string): Promise<FlexNapBuild | undefined> {
        return this.post(`${this.baseUrl}/${buildId}`, { legType });
    }

    public revertPretermLateral(buildId: number): Promise<FlexNapBuild | undefined> {
        return this.post(this.baseUrl + `/revert/${buildId}`);
    }

    public undoRevertPretermLateral(buildId: number, pretermLateral: PretermLateral): Promise<FlexNapBuild | undefined> {
        return this.post(this.baseUrl + `/undo/revert/${buildId}`, pretermLateral);
    }

    public applyPretermConfig(buildId: number, fiberCount: number, startIndex: number): Promise<PretermConfigResult | undefined> {
        return this.post(this.baseUrl + `/config/${buildId}`, { fiberCount, startIndex });
    }
}

interface PretermConfigResult {
    build: FlexNapBuild;
    taps: Tap[];
    tethers: Tether[];
}
