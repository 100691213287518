export class FileHelper {
    public static downloadFile(filename: string, blob: Blob): void {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
    }

    public static extractFilename(headers: any): string {
        const contentDisposition: string | undefined = headers['content-disposition'];
        let filename = contentDisposition?.match(/filename\*=UTF-8''(.*)/)?.[1];
        if (!filename) {
            filename = contentDisposition?.match(/filename="(.*)"/)?.[1];
        }
        return filename?.trim() ?? 'filename.ext';
    }
}
