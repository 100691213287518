import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardActions, CardContent, CardMedia, Container, Grid } from '@mui/material';
import { ContainedButton } from '@orbit/button';

import { LocalizationKeys } from '../../locales/types';
import { Workspace } from '../../models/workspace';
import { useDesignModeSelect } from './design-mode-select.hooks';

export interface DesignModeSelectProps {
    workspace: Workspace | undefined;
}

export const DesignModeSelect = (props: DesignModeSelectProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        showSelect,
        onMapModeClicked, onCanvasModeClicked
    } = useDesignModeSelect(props);

    return (
        showSelect ?
        <div className="app-root-design">
            <Container max-width="md" sx={{ height: "100%" }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={8}
                    sx={{ height: "100%" }}>
                    <Grid item>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                component="img"
                                height="194"
                                src={require('./map-mode-preview.png')}
                                alt="Map Mode"
                            />
                            <CardContent>
                                {t(LocalizationKeys.GISModeDescription)}
                            </CardContent>
                            <CardActions disableSpacing>
                                <ContainedButton onClick={onMapModeClicked}>
                                    {t(LocalizationKeys.StartGISMode)}
                                </ContainedButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                component="img"
                                height="194"
                                src={require('./canvas-mode-preview.png')}
                                alt="Canvas Mode"
                            />
                            <CardContent>
                                {t(LocalizationKeys.CanvasModeDescription)}
                            </CardContent>
                            <CardActions disableSpacing>
                                <ContainedButton onClick={onCanvasModeClicked}>
                                    {t(LocalizationKeys.StartCanvasMode)}
                                </ContainedButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
        : <></>
    );
}