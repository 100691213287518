import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { DialogComponent } from '../dialog.component';
import { useUploadBuildDialog } from './upload-build-dialog.hooks';

export const UploadBuildDialog = (): JSX.Element => {
    const {
        open,
        buildId,
        handleUploadClick,
        handleClose,
    } = useUploadBuildDialog();
    const { t } = useTranslation();

    return (
        <DialogComponent
            open={open}
            title={t(LocalizationKeys.UploadBuild)}
            positiveTitle={t(LocalizationKeys.Upload)}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onPositiveClick={handleUploadClick}
            onNegativeClick={handleClose}
            onClose={handleClose}
        >
            <Typography variant='body1' label={t(LocalizationKeys.UploadBuildNotice1, { buildId: buildId ?? -1 })} />
            <Typography variant='body1' label={t(LocalizationKeys.UploadBuildNotice2)} />
        </DialogComponent>
    );
};
