import './create-build.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainedButton, OutlinedButton, TextButton } from '@orbit/button';
import { MainPalettes, Typography } from '@orbit/theme-provider';
import { InlineSelect } from '../../../ui-elements/inline-select';
import { InlineText } from '../../../ui-elements/inline-text';

import { Card } from '../../../../components/card/card';
import { CardFooterComponent } from '../../../../components/card/card-footer.component';
import { LocalizationKeys } from '../../../../locales/types';
import { CardContentComponent } from '../../../card/card-content.component';
import { CardHeaderComponent } from '../../../card/card-header.component';
import { useCreateBuild } from './create-build.hooks';

export const CreateBuildComponent = () => {
    const { t } = useTranslation();

    const { designAreaId, canvasDesignAreas, presetId, presets, designSpans, newSpan, cableLength, units, locations, createDisabled, invalidSpan, onChangeDesignArea,
        onChangePreset, onEditDesignSpan, submitDesignSpan, onCancelEdit, handleFocus, deleteSpanField, cancelBuildCreation, onCreateClick } = useCreateBuild();

    return (
        <Card
            id="canvas-create-build-card"
            onCloseClick={cancelBuildCreation}
            hideToggleCollapse={true}
        >
            <CardHeaderComponent title={<Typography label={t(LocalizationKeys.NewBuild)} variant='h6' />} subheader={false} />
            <CardContentComponent className='create-build-content'>
                <div className="selectors">
                    <InlineSelect
                        label={`${t(LocalizationKeys.DesignArea)}*`}
                        key={"design-area"}
                        value={designAreaId ?? ""}
                        values={canvasDesignAreas.map((d) => d.id)}
                        displayValues={canvasDesignAreas.map((d) => d.name ?? d.projectId)}
                        onUpdate={onChangeDesignArea}
                        disabled={false}
                    />
                    <InlineSelect
                        label={`${t(LocalizationKeys.Preset)}*`}
                        key={"preset"}
                        value={presetId ?? ""}
                        values={presets.map((p) => p.id)}
                        displayValues={presets.map((p) => p.name)}
                        onUpdate={onChangePreset}
                        disabled={false}
                    />
                </div>
                <div className="cable">
                    <InlineText
                        label={`${t(LocalizationKeys.CableLength)}*`}
                        value={`${cableLength}`}
                        units={units}
                        disabled
                    />
                    <InlineText
                        label={t(LocalizationKeys.Locations)}
                        value={locations.toString()}
                        disabled
                    />
                </div>
                <div className="spans">
                    <Typography label={t(LocalizationKeys.Spans)} variant='body1' />
                    <div className={`span-list ${designSpans.length > 4 ? 'scroll' : ''}`}>
                        {[...designSpans, +newSpan].map((s, i) => (
                            <div key={i} className={`span-item${i === designSpans.length && designSpans.length > 0 ? " new-span" : ""}`}>
                                <InlineText
                                    label={`Span ${i + 1}`}
                                    units={units}
                                    value={s}
                                    variant="standard"
                                    error={invalidSpan === i}
                                    errorMessage={"Enter a numerical value."}
                                    onUpdate={submitDesignSpan(i)}
                                    isEditing={designSpans.length > 0 && i === designSpans.length}
                                    onInputChange={onEditDesignSpan(i)}
                                    onCancel={onCancelEdit(i)}
                                    disabled={invalidSpan >= 0 && invalidSpan !== i}
                                    onFocus={handleFocus}
                                />
                                {designSpans.length > 1 && i !== designSpans.length && <TextButton palette={MainPalettes.error} id={`${i}`} className="negative card-icon-button" onClick={deleteSpanField}><i className="material-icons">delete</i></TextButton>}
                            </div>
                        ))}
                    </div>
                </div>
            </CardContentComponent>
            <CardFooterComponent align='right'>
                <OutlinedButton onClick={cancelBuildCreation}>{t(LocalizationKeys.Cancel)}</OutlinedButton>
                <ContainedButton onClick={onCreateClick} disabled={createDisabled}>{t(LocalizationKeys.CreateBuild)}</ContainedButton>
            </CardFooterComponent>
            <div className='extra-footer-content'>
                <Typography label={t(LocalizationKeys.NewBuildNote)} variant='subtitle2' />
            </div>
        </Card>
    );
}