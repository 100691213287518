import i18n from 'i18next';

import { LocalizationKeys } from '../../locales/types';

export enum ConfigurationType {
    OpticalOnly = 1,
    PowerOnly = 2,
    OpticalPowerHybrid = 3,
}

export const ConfigurationTypes = [
    { value: ConfigurationType.OpticalPowerHybrid, display: i18n.t(LocalizationKeys.OpticalPowerHybrid) },
    { value: ConfigurationType.OpticalOnly, display: i18n.t(LocalizationKeys.OpticalOnly) },
    { value: ConfigurationType.PowerOnly, display: i18n.t(LocalizationKeys.PowerOnly) },
];
