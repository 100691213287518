import React from 'react';

export const terminalPlaceholderSvg = (textLine1: string, textLine2: string): JSX.Element => (
    <g className="terminal-placeholder">
        <g transform="translate(32 63)">
            <rect fill="none" width="72" height="272" rx="4"/>
            <g transform="matrix(1, 0, 0, 1, -5.5, 0)">
                <g fill="none" stroke="#9E9E9E" transform="translate(13.5 148)">
                    <rect stroke="none" width="56" height="96" rx="6"/>
                    <rect fill="none" x="0.5" y="0.5" width="55" height="95" rx="5.5"/>
                </g>
            </g>
            <rect fill="none" stroke="#9E9E9E" width="3" height="44" transform="translate(34.5 91.5)"/>
            <g fill="none" stroke="#9E9E9E" transform="translate(42 92) rotate(180)">
                <path stroke="none" d="M3,0H9a3,3,0,0,1,3,3v7a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z"/>
                <path fill="none" d="M3,.5H9A2.5,2.5,0,0,1,11.5,3V9a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,9V3A2.5,2.5,0,0,1,3,.5Z"/>
            </g> 
            <g fill="none" stroke="#9E9E9E" transform="translate(30 135)">
                <path stroke="none" d="M3,0H9a3,3,0,0,1,3,3V14a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z"/>
                <path fill="none" d="M3,.5H9A2.5,2.5,0,0,1,11.5,3V13a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,13V3A2.5,2.5,0,0,1,3,.5Z"/>
            </g>
        </g>
        <text y="257" width="56" height="48" textAnchor="middle">
            <tspan x="68" dy="0">{textLine1}</tspan>
            <tspan x="68" dy="16">{textLine2}</tspan>
        </text>
    </g>
);
