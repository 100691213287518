import React, { Component } from 'react';

import { Chip } from '@mui/material';

import { PartHelper } from '../../helpers/part-helper';
import { DataValue } from '../../models/datavalue';
import { Units } from '../../models/units';
import { UserPreset } from '../../models/userpreset';

interface PresetPropertiesProps {
    preset: UserPreset;
    displayUnits: Units;
}

export class PresetPropertiesComponent extends Component<PresetPropertiesProps> {

    private renderAttribute = (key: any, value: any): JSX.Element => {
        return (
            <div className="attribute" key={key}>
                <span className="attribute-title">{key}</span>
                <span className="attribute-value">{value}<br />
                </span>
            </div>
        );
    }

    private renderCable = (preset: UserPreset): JSX.Element => {
        const { displayUnits } = this.props;
        const { prefix, fibers, suffix } = PartHelper.getFlexNapPartNumberParts(preset.cablePart);
        const partNumber = <span className="body">{prefix}<span className="fiber-count">{fibers}</span>{suffix}</span>;
        const description = JSON.parse(preset.cableDescription || '{}');
        const keys = Object.keys(description);
        const coSlack = new DataValue(+preset.coSlack, preset.slackUnit);
        const fieldSlack = new DataValue(+preset.fieldSlack, preset.slackUnit);

        return (
            <div>
                <div className="preset-property-header">
                    <div>
                        <span className="title">{preset.name}</span>
                        <div>{partNumber}</div>
                    </div>
                    {preset.id > 0 && preset.isDefault && <Chip id="defaultChip" className="default" label="Default" avatar={<img alt="Default" src={require('./icon_default.svg')} />} />}
                </div>
                <div className="divider" />
                {/* <p className="description">{cableDescription}<br /></p> */}
                <div className="attributes">
                    {keys.map((k) => this.renderAttribute(k, description[k]))}
                    <div className="attribute"><span className="attribute-title">CO Slack</span> <span className="attribute-value">{coSlack.format(displayUnits!, 2)}<br /></span></div>
                    <div className="attribute"><span className="attribute-title">Field Slack</span> <span className="attribute-value">{fieldSlack.format(displayUnits!, 2)}<br /></span></div>
                </div>
            </div>
        );
    }

    private renderTether = (preset: UserPreset): JSX.Element | null => {
        if (!preset.tetherPart || preset.tetherPart.length < 15) {
            return null;
        }
        const { prefix, fibers, suffix } = PartHelper.getTetherPartNumberParts(preset.tetherPart);
        const partNumber = <span className="body">{prefix}<span className="fiber-count">{fibers}</span>{suffix}</span>;
        const tetherDescription = JSON.parse(preset.tetherDescription || '{}');
        const tetherKeys = Object.keys(tetherDescription);

        return (
            <div>
                <span className="title">Tether</span>
                <div>{partNumber}</div>
                <div className="divider" />
                <div className="attributes">
                    {tetherKeys && tetherKeys.map((k) => this.renderAttribute(k, tetherDescription[k]))}
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const { preset } = this.props;
        return (
            <div className="card properties">
                <div className="cable-properties">
                    <img alt="Preset Icon" className="preset-icon" src={require(`./preset_cable.jpg`)} />
                    <div className="cable-summary">
                        {this.renderCable(preset)}
                        {this.renderTether(preset)}
                        {
                            // eslint-disable-next-line react/prop-types
                            this.props.children
                        }
                    </div>
                </div>
            </div>
        );
    }
}
