/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';

import { OktaStorageService } from '../okta/okta.storage.service';
import { AuthenticationMode, logout } from '../redux/authentication.state';
import { StateModel } from '../redux/reducers';

const mapStateToProps = (state: StateModel) => {
    const { loggedIn, expiresAt, oktaSession, authenticationMode } = state.authentication;
    const tokenExpired = !expiresAt || Date.now() > expiresAt;
    return { loggedIn, tokenExpired, oktaSession, authenticationMode };
};
const mapDispatchToProps = {
    logout,
};

type props = RouteProps & Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SecureRoute extends React.Component<props> {
    public async componentDidMount(): Promise<void> {
        const { loggedIn, tokenExpired, authenticationMode } = this.props;
        if (loggedIn && tokenExpired && this.props.logout) {
            this.props.logout();
        }

        if (authenticationMode === AuthenticationMode.HybrisCustomerHeadersWithOktaSession) {
            await OktaStorageService.loadOktaSession();
        }
    }

    public render(): JSX.Element {
        const { children, loggedIn, oktaSession, authenticationMode } = this.props;
        if (authenticationMode !== AuthenticationMode.HybrisCustomerHeadersWithOktaSession || oktaSession) {
            return (
                <Route>
                    {loggedIn ? children : <Redirect to="/login" />}
                </Route>
            );
        } else {
            return (<div />);
        }
    }
}
