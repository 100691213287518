import React from 'react';

interface PresetProps {
    title: string;
    titleClass?: string;
    children?: any;
}

export const PresetComponent = ({ title, titleClass, children }: PresetProps): JSX.Element => {
    return (
        <div className="card">
            <div className={titleClass ?? "title"}>{title}</div>
            <div className="card-content">
                {children}
            </div>
        </div>
    );
}
