import { combineReducers } from 'redux';

import { AuthenticationReducer } from './authentication.state';
import { BoreReducer } from './bore.state';
import { BuildReducer } from './build.state';
import { SplicePlanReducer as BulkSplicePlanReducer } from './bulk/splice-plan.state';
import { SplicePointsReducer } from './bulk/splice-point.state';
import { CabinetSplicePlanReducer } from './cabinet-splice-plan.state';
import { CabinetReducer } from './cabinet.state';
import { CpqReducer } from './cpq.state';
import { DebugReducer } from './debug.state';
import { DesignAreaReducer } from './design-area.state';
import { DownloadReducer } from './download.state';
import { ExportReducer } from './export.state';
import { FeatureFlagsReducer } from './feature-flags.state';
import { HandholeReducer } from './handhole.state';
import { HistoryReducer } from './history.state';
import { ImportReducer } from './import.state';
import { LayerReducer } from './layer.state';
import { ManholeReducer } from './manhole.state';
import { MapReducer } from './map.state';
import { NotificationReducer } from './notification.state';
import { OverlayReducer } from './overlay.state';
import { ParcelReducer } from './parcel.state';
import { PoleReducer } from './pole.state';
import { PresetReducer } from './preset.state';
import { ReviewReducer } from './review.state';
import { RoleReducer } from './roles.state';
import { SchrodingerPresetReducer } from './schrodinger-preset.state';
import { SplicePlanReducer as SchrodingerSplicePlanReducer } from './schrodinger/splice-plan.state';
import { TapReducer } from './schrodinger/tap.state';
import { ValidationReducer } from './schrodinger/validation.state';
import { SelectionReducer } from './selection.state';
import { SplicePlanReducer } from './splice-plan.state';
import { TapsReducer } from './tap.state';
import { ToolReducer } from './tool.state';
import { TrenchReducer } from './trench.state';
import { VaultReducer } from './vault.state';
import { ViewportReducer } from './viewport.state';
import { WizardReducer } from './wizard.state';
import { WorkspaceReducer } from './workspace.state';

export { connect } from 'react-redux';

const rootReducer = combineReducers({
    authentication: AuthenticationReducer,
    bore: BoreReducer,
    build: BuildReducer,
    bulkSplicePlan: BulkSplicePlanReducer,
    cabinet: CabinetReducer,
    cabinetSplicePlan: CabinetSplicePlanReducer,
    cpq: CpqReducer,
    debug: DebugReducer,
    designarea: DesignAreaReducer,
    download: DownloadReducer,
    export: ExportReducer,
    featureFlags: FeatureFlagsReducer,
    handhole: HandholeReducer,
    history: HistoryReducer,
    import: ImportReducer,
    layer: LayerReducer,
    manhole: ManholeReducer,
    map: MapReducer,
    notification: NotificationReducer,
    overlay: OverlayReducer,
    parcel: ParcelReducer,
    pole: PoleReducer,
    preset: PresetReducer,
    review: ReviewReducer,
    role: RoleReducer,
    schrodingerpreset: SchrodingerPresetReducer,
    schrodingerSplicePlan: SchrodingerSplicePlanReducer,
    selection: SelectionReducer,
    splicePlan: SplicePlanReducer,
    splicePoints: SplicePointsReducer,
    taps: TapsReducer,
    tapsSchrodinger: TapReducer,
    tool: ToolReducer,
    trench: TrenchReducer,
    validation: ValidationReducer,
    vault: VaultReducer,
    viewport: ViewportReducer,
    wizard: WizardReducer,
    workspace: WorkspaceReducer,
});

export default rootReducer;
export type StateModel = ReturnType<typeof rootReducer>;
