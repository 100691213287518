import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommandType } from '../history/command-type';
import { PointLike } from '../models/pointlike';
import { Vault, VaultData } from '../models/vault';
import { VaultService } from '../services/vault.service';
import { createSecuredAsyncAction, requestIsSuccess } from './action';
import { updateSchrodingerBuildLocationIds } from './element.actions';
import { push } from './history.state';
import { IMPORT_LOAD_THRESHOLD } from './import.state';
import { updateSelectedElement } from './selection.state';

export interface VaultState {
    vaults: Vault[];
}

const initialState: VaultState = {
    vaults: [],
};

const loadAllReducer = (state: VaultState, action: PayloadAction<Vault[] | undefined>) => { state.vaults = action.payload || []; };

const addVaultReducer = (state: VaultState, action: PayloadAction<Vault>) => { state.vaults.push(action.payload); };

const addVaultsReducer = (state: VaultState, action: PayloadAction<Vault[]>) => { state.vaults.push(...action.payload); };

const updateVaultReducer = (state: VaultState, action: PayloadAction<Vault>) => {
    const vault: Vault = action.payload;
    const vIdx = state.vaults.findIndex((v) => v.id === vault.id);
    if (vIdx < 0) {
        state.vaults.push(vault);
    }
    else {
        state.vaults[vIdx] = vault;
    }
};

const deleteVaultReducer = (state: VaultState, action: PayloadAction<number>) => {
    state.vaults = state.vaults.filter((v) => v.id !== action.payload);
};

const deleteImportedVaultsReducer = (state: VaultState, action: PayloadAction<number>) => {
    state.vaults = state.vaults.filter((v) => v.importedGisDataId !== action.payload);
};

const { actions, reducer } = createSlice({
    name: 'vault',
    initialState,
    reducers: {
        loadAll: loadAllReducer,
        addVault: addVaultReducer,
        addVaults: addVaultsReducer,
        updateVault: updateVaultReducer,
        deleteVault: deleteVaultReducer,
        deleteImportedVaults: deleteImportedVaultsReducer
    },
});

export { reducer as VaultReducer };
export const { loadAll: loadVaults, addVault: addVaultAction, deleteVault, deleteImportedVaults, updateVault: modifyVault } = actions;

export const getVaults = (bbox: number[], required?: number[]) => async dispatch => {
    const service = new VaultService();
    const vaults = await service.getVaults(bbox, required);
    if (vaults) {
        dispatch(actions.loadAll(vaults));
    }
};

export const getVaultsByIds = (ids: number[]) => async dispatch => {
    const service = new VaultService();
    const vaults = await service.getVaultsByIds(ids);
    if (vaults) {
        vaults.forEach(vault => dispatch(actions.updateVault(vault)));
    }
};

export const addVault = (point: PointLike) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new VaultService();
        const vault = await service.addVault(point);
        if (vault) {
            dispatch(actions.addVault(vault));
            dispatch(push({ type: CommandType.CreateElement, payload: { id: vault.id, type: vault.type } }));
        }
    });
};

export const addVaults = (data: VaultData[], importedGisId?: number, bbox?: number[]) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new VaultService();
        const success = await requestIsSuccess(service, service.addVaults(data, importedGisId), true);
        if (data.length <= IMPORT_LOAD_THRESHOLD && bbox && success) {
            getVaults(bbox)(dispatch);
        }
    });
};

export const updateVault = (oldVault: Vault, newVault: Vault) => {
    return createSecuredAsyncAction(async (dispatch) => {
        const service = new VaultService();
        const vault = await service.patchVault(oldVault, newVault);
        if (vault) {
            dispatch(actions.updateVault(vault));
            dispatch(updateSelectedElement(vault));
            if (oldVault.tagOverride !== vault.tagOverride) {
                updateSchrodingerBuildLocationIds(vault);
            }
        }
    });
};
