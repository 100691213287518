import { useCallback, useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import { BuildType } from '../../../../models/build-type';
import {
    ImportStepSelectPresetsProps, ImportTabs, SelectedParentIds, SelectedPresetIds
} from '../import.card.types';

export const useImportStepSelectPreset = (props: ImportStepSelectPresetsProps) => {

    const [tab, setTab] = useState<ImportTabs>(ImportTabs.Flexnap);
    const [selectedFlexnapBuildIds, setSelectedFlexnapBuildIds] = useState<string[]>([]);
    const [selectedBulkBuildIds, setSelectedBulkBuildIds] = useState<string[]>([]);
    const [selectedFlexnapPresetIds, setSelectedFlexnapPresetIds] = useState<SelectedPresetIds>({});
    const [selectedFlexnapParentIds, setSelectedFlexnapParentIds] = useState<SelectedParentIds>({});
    const [selectedBulkPresetIds, setSelectedBulkPresetIds] = useState<SelectedPresetIds>({});
    const [selectedBulkParentIds, setSelectedBulkParentIds] = useState<SelectedParentIds>({});

    const {
        buildsToImportRows,
        setSelectedBuildsResult,
        setDisableNext
    } = props;

    // Select all builds initially by default
    useEffect(() => {
        setSelectedFlexnapBuildIds(buildsToImportRows?.filter(b => b.buildType === BuildType.FlexNap).map(b => b.customerBuildId) ?? []);
        setSelectedBulkBuildIds(buildsToImportRows?.filter(b => b.buildType === BuildType.Bulk).map(b => b.customerBuildId) ?? []);
        const initialSelectedFnapParentIds = {};
        const initialSelectedBulkParentIds = {};
        if (buildsToImportRows) {
            for (const buildToImportRow of buildsToImportRows) {
                if (buildToImportRow.buildType === BuildType.FlexNap) {
                    if (buildToImportRow.parentCustomerBuildIdOptions && buildToImportRow.parentCustomerBuildIdOptions.length > 0) {
                        initialSelectedFnapParentIds[buildToImportRow.customerBuildId] = buildToImportRow.parentCustomerBuildIdOptions[0];
                    }
                } else if (buildToImportRow.buildType === BuildType.Bulk) {
                    if (buildToImportRow.parentCustomerBuildIdOptions && buildToImportRow.parentCustomerBuildIdOptions.length > 0) {
                        initialSelectedBulkParentIds[buildToImportRow.customerBuildId] = buildToImportRow.parentCustomerBuildIdOptions[0];
                    }
                }
            }
            setSelectedFlexnapParentIds(initialSelectedFnapParentIds);
            setSelectedBulkParentIds(initialSelectedBulkParentIds);
        }
        setDisableNext(true);
    }, [buildsToImportRows, setDisableNext, setSelectedFlexnapParentIds, setSelectedBulkParentIds]);

    // Effect to control whether the user can continue to the Save step - depends on builds selected and their presets
    useEffect(() => {
        let disabled = false;

        selectedBulkBuildIds.forEach(buildId => {
            if (!(buildId in selectedBulkPresetIds) || !selectedBulkPresetIds[buildId]) {
                disabled = true;
            }
        });

        selectedFlexnapBuildIds.forEach(buildId => {
            if (!(buildId in selectedFlexnapPresetIds) || !selectedFlexnapPresetIds[buildId]) {
                disabled = true;
            }
        })

        setDisableNext(disabled);
    }, [buildsToImportRows, selectedBulkBuildIds, selectedBulkPresetIds, selectedFlexnapBuildIds, selectedFlexnapPresetIds, setDisableNext]);


    // Handler to set build results for the Save step
    const onSetSelectedBuildsResult = useCallback((buildId: string, isSelected: boolean, presetId: string, parentCustomerBuildId: string) => {
        setSelectedBuildsResult(previous => {
            return [...previous.filter(b => b.buildId !== buildId), { buildId, presetId, isSelected, parentCustomerBuildId }];
        });
    }, [setSelectedBuildsResult]);


    // Handlers for checkboxes
    const onBuildSelectionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const buildId = e.target.name;
        const isBuildSelected = e.target.checked;
        if (tab === ImportTabs.Bulk) {
            if (isBuildSelected) {
                setSelectedBulkBuildIds(previous => [...previous, buildId]);
            }
            else {
                setSelectedBulkBuildIds(previous => previous.filter(s => s !== buildId));
            }
            onSetSelectedBuildsResult(buildId, isBuildSelected, selectedBulkPresetIds[buildId], selectedBulkParentIds[buildId]);
        }
        else {
            if (isBuildSelected) {
                setSelectedFlexnapBuildIds(previous => [...previous, buildId]);
            }
            else {
                setSelectedFlexnapBuildIds(previous => previous.filter(s => s !== buildId));
            }
            onSetSelectedBuildsResult(buildId, isBuildSelected, selectedFlexnapPresetIds[buildId], selectedFlexnapParentIds[buildId]);
        }
    }, [onSetSelectedBuildsResult, selectedBulkParentIds, selectedBulkPresetIds, selectedFlexnapParentIds, selectedFlexnapPresetIds, tab]);

    const onSelectAllChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        if (buildsToImportRows && buildsToImportRows?.length > 0) {
            if (tab === ImportTabs.Bulk) {
                const bulkBuildIds = buildsToImportRows.filter(b => b.buildType === BuildType.Bulk).map(b => b.customerBuildId);
                if (isChecked) {
                    setSelectedBulkBuildIds(bulkBuildIds);
                }
                else {
                    setSelectedBulkBuildIds([]);
                }
                bulkBuildIds.forEach(f => onSetSelectedBuildsResult(f, isChecked, selectedBulkPresetIds[f], selectedBulkParentIds[f]));
            }
            else {
                const flexnapBuildIds = buildsToImportRows.filter(b => b.buildType === BuildType.FlexNap).map(b => b.customerBuildId);
                if (isChecked) {
                    setSelectedFlexnapBuildIds(flexnapBuildIds);
                }
                else {
                    setSelectedFlexnapBuildIds([]);
                }
                flexnapBuildIds.forEach(f => onSetSelectedBuildsResult(f, isChecked, selectedFlexnapPresetIds[f], selectedFlexnapParentIds[f]));
            }
        }
    }, [buildsToImportRows, tab, onSetSelectedBuildsResult, selectedBulkPresetIds, selectedBulkParentIds, selectedFlexnapPresetIds, selectedFlexnapParentIds]);


    // Handlers for preset changes
    const onApplyPresetToAll = useCallback((presetIdentifier: string): void => {
        if (tab === ImportTabs.Bulk) {
            const newBulkPresets: SelectedPresetIds = {};
            if (buildsToImportRows) {
                buildsToImportRows.filter(b => b.buildType === BuildType.Bulk).forEach(build => {
                    newBulkPresets[build.customerBuildId] = presetIdentifier;
                    onSetSelectedBuildsResult(build.customerBuildId, !!selectedBulkBuildIds.find(b => b === build.customerBuildId), presetIdentifier, selectedBulkParentIds[build.customerBuildId] ?? '');
                });
            }
            setSelectedBulkPresetIds(newBulkPresets);
        }
        else {
            const newFlexnapPresets: SelectedPresetIds = {};
            if (buildsToImportRows) {
                buildsToImportRows.filter(b => b.buildType === BuildType.FlexNap).forEach(build => {
                    newFlexnapPresets[build.customerBuildId] = presetIdentifier;
                    onSetSelectedBuildsResult(build.customerBuildId, !!selectedFlexnapBuildIds.find(b => b === build.customerBuildId), presetIdentifier, selectedFlexnapParentIds[build.customerBuildId] ?? '');
                });
            }
            setSelectedFlexnapPresetIds(newFlexnapPresets);
        }
    }, [tab, buildsToImportRows, onSetSelectedBuildsResult, selectedBulkBuildIds, selectedBulkParentIds, selectedFlexnapBuildIds, selectedFlexnapParentIds]);

    const onSelectedPresetChange = useCallback((event: SelectChangeEvent<any>) => {
        const presetIdentifier = event.target.value;
        const buildId = event.target.name;
        if (tab === ImportTabs.Bulk) {
            selectedBulkPresetIds[buildId] = presetIdentifier;
            setSelectedBulkPresetIds({ ...selectedBulkPresetIds });
            onSetSelectedBuildsResult(buildId, !!selectedBulkBuildIds.find(b => b === buildId), presetIdentifier, selectedBulkParentIds[buildId] ?? '');
        }
        else {
            selectedFlexnapPresetIds[buildId] = presetIdentifier;
            setSelectedFlexnapPresetIds({ ...selectedFlexnapPresetIds });
            onSetSelectedBuildsResult(buildId, !!selectedFlexnapBuildIds.find(b => b === buildId), presetIdentifier, selectedFlexnapParentIds[buildId] ?? '');
        }
    }, [onSetSelectedBuildsResult, selectedBulkBuildIds, selectedBulkParentIds, selectedBulkPresetIds, selectedFlexnapBuildIds, selectedFlexnapParentIds, selectedFlexnapPresetIds, tab]);


    // Other handlers
    const onSelectedParentBuildIdChange = useCallback((event: SelectChangeEvent<any>) => {
        const value = event.target.value;
        const parentBuildId = value === '' || value === 'N/A' ? undefined : value;
        const buildId = event.target.name;

        if (tab === ImportTabs.Bulk) {
            selectedBulkParentIds[buildId] = parentBuildId;
            setSelectedBulkParentIds({ ...selectedBulkParentIds });

            onSetSelectedBuildsResult(buildId, !!selectedBulkBuildIds.find(b => b === buildId), selectedBulkPresetIds[buildId] ?? '', parentBuildId);
        }
        else {
            selectedFlexnapParentIds[buildId] = parentBuildId;
            setSelectedFlexnapParentIds({ ...selectedFlexnapParentIds });

            onSetSelectedBuildsResult(buildId, !!selectedFlexnapBuildIds.find(b => b === buildId), selectedFlexnapPresetIds[buildId] ?? '', parentBuildId);
        }
    }, [onSetSelectedBuildsResult, selectedBulkBuildIds, selectedBulkParentIds, selectedBulkPresetIds, selectedFlexnapBuildIds, selectedFlexnapParentIds, selectedFlexnapPresetIds, tab])

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: ImportTabs) => {
        setTab(newValue);
    }, []);

    return { tab, selectedFlexnapBuildIds, selectedBulkBuildIds, selectedFlexnapPresetIds, selectedBulkPresetIds, selectedFlexnapParentIds, selectedBulkParentIds, onBuildSelectionChange, onSelectAllChange, onSelectedPresetChange, onSelectedParentBuildIdChange, onApplyPresetToAll, handleTabChange };
}