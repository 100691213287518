import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { LocalizationKeys } from '../../../../locales/types';
import { DnaSpinner } from '../../../ui-elements/dna-spinner';
import { EditText } from '../../../ui-elements/edit-text';
import { PresetDefinition } from '../../preset-definition';
import { PresetNavigationFooter } from '../../preset-navigation-footer';
import { useSlack } from './slack.hooks';
import { SlackProps } from './slack.types';

export const Slack = (props: SlackProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        coSlack, fieldSlack, coMinimum, fieldMinimum, coMinimumLabel, fieldMinimumLabel, loading, nextDisabled,
        handleCoSlackChange, handleFieldSlackChange, handlePreviousClick, handleNextClick
    } = useSlack(props);

    const renderHelpIcon = useCallback((helpText?: string): JSX.Element => {
        return (
            <Tooltip className="tooltip" title={<span className="caption">{helpText}</span>} placement="left">
                <button className="icon-button">
                    <img alt="Help" src={require('../../icon_help.svg')} />
                </button>
            </Tooltip>
        );
    }, []);

    return (
        <>
            {
                loading ? <div className="dna-spinner-container"><DnaSpinner /></div>
                    :
                    <>
                        <EditText
                            label={t(LocalizationKeys.COSlack)}
                            value={coSlack}
                            type="number"
                            name="coSlack"
                            helperText={coMinimumLabel}
                            error={coSlack < coMinimum}
                            disabled={loading}
                            onChange={handleCoSlackChange}
                            trailingIcon={renderHelpIcon(PresetDefinition.help['CO Slack'])}
                        />
                        <EditText
                            label={t(LocalizationKeys.FieldSlack)}
                            value={fieldSlack}
                            type="number"
                            name="fieldSlack"
                            helperText={fieldMinimumLabel}
                            error={fieldSlack < fieldMinimum}
                            disabled={loading}
                            onChange={handleFieldSlackChange}
                            trailingIcon={renderHelpIcon(PresetDefinition.help['Field Slack'])}
                        />
                        <PresetNavigationFooter
                            previousDisabled={loading}
                            nextDisabled={nextDisabled}
                            showPrevious={true}
                            onPreviousClick={handlePreviousClick}
                            onNextClick={handleNextClick}
                        />
                    </>
            }
        </>
    );
};
