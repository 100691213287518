import i18next from 'i18next';

import { LocalizationKeys } from '../../locales/types';
import { FlexNapBuildData } from '../data/flexnap-build-data';
import { NapData } from '../data/nap-data';
import { ItemType } from '../item-type';
import { ValidationResult } from '../validation-result';
import { ValidationResultType } from '../validation-result-type';
import { Validator } from './validator';

export class LooseTubeValidator implements Validator<FlexNapBuildData> {
    public validate(build: FlexNapBuildData): ValidationResult | null {
        const { part } = build;
        if (part?.outer_Fiber_Count) {
            const { fiber_Count: fiberCount, outer_Fiber_Count: outerFiberCount } = part;
            const checkInnerLayer = fiberCount === 216 && outerFiberCount === 144;
            const orderedSegments = build.segments.sort((a, b) => a.position - b.position);
            const orderedLocations = orderedSegments.flatMap(s => [s.from.id, s.to.id]).distinct();
            let assignedFibers = 0;
            for (const location of orderedLocations) {
                const nap = build.naps.find(n => n.elementId === location);
                if (nap) {
                    const tethers = nap.taps.flatMap(t => t.tethers);
                    const nbFibersToAssign = tethers.map((t) => t.fiberCount).reduce((prv, t) => prv + t, 0);

                    if (checkInnerLayer) { // Our engineering rules states with a cable containing 216F, we cannot use fibers from both the inner and outer layer within the same NAP
                        const remainingOuterFibers = outerFiberCount - assignedFibers;
                        if (remainingOuterFibers > 0 && nbFibersToAssign > remainingOuterFibers) {
                            return this.looseTubeValidationResult(nap, i18next.t(LocalizationKeys.LooseTube216FValidationMsg, { nbFibersToUpsize: remainingOuterFibers }), ValidationResultType.Error)
                        }
                    } else if (assignedFibers + nbFibersToAssign > outerFiberCount) { // Our engineering rules states that with cables containing more >= 288f, we can only access the outer layer fibers
                        const excessFibers = (assignedFibers + nbFibersToAssign) - outerFiberCount;
                        return this.looseTubeValidationResult(nap, i18next.t(LocalizationKeys.LooseTubeMaximumFibersValidationMsg, { maxOuterFibers: outerFiberCount, fiberCount: fiberCount, excessFibers: excessFibers }), ValidationResultType.Error);
                    }

                    assignedFibers += nbFibersToAssign;
                }
            }
        }

        return null;
    }

    public canValidate(entity: unknown): boolean {
        if (entity instanceof FlexNapBuildData) {
            return entity.part?.cable_Type === i18next.t(LocalizationKeys.LooseTube);
        } else {
            return false;
        }
    }

    private looseTubeValidationResult(nap: NapData, message: string, type: ValidationResultType): ValidationResult {
        const extent = nap.getExtent();
        return { 
            buildId: nap.buildId,
            itemId: nap.id,
            itemType: ItemType.Nap,
            shortDescription: message, 
            type, 
            extent 
        };
    }
}