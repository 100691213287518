import './card-error.component.scss';

import React from 'react';

import { CardErrorMessageProps } from './card.types';

export const CardErrorMessage = ({ label }: CardErrorMessageProps): JSX.Element => (
    <div className="card-error-message">
        <img className="card-error-message-icon" alt="Error" src={require('./icon_error.svg')} />
        <div>{label}</div>
    </div>
);
