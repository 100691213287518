import i18next from 'i18next';
import React from 'react';

import { TextButton } from '@orbit/button';
import { Tooltip } from '@mui/material';

import { ToolType } from '../../../design-tools/tooltype';
import { BuilderHelper } from '../../../helpers/build-helper';
import bulkCableIcon from '../../../icons/bulk-cable.png';
import lockedIcon from '../../../icons/locked.png';
import { LocalizationKeys } from '../../../locales/types';
import { BuildType } from '../../../models/build-type';
import { OpenlayerStyleFactory } from '../../../openlayers/openlayer-style.factory';
import { Card } from '../../card/card';
import { CardCollapsibleComponent } from '../../card/card-collapsible.component';
import { CardProperty, CardPropertyInline } from '../../card/card-controls';
import { CardHeaderComponent } from '../../card/card-header.component';
import { ColorSelectorProperty } from '../../color-selector/color-selector-property';
import { defaultBuildColors } from '../build/build-card.types';
import { SegmentCard } from '../segment/segment-card';
import { BuildActionsMenu } from '../build/build-actions-menu';
import { useBulkBuildCard } from './bulk-build-card.hooks';
import { BulkCableCard } from './bulk-cable-card';

export const BulkBuildCard = (): JSX.Element | null => {
    const {
        collapsed,
        editDisabled, unlockDisabled, buildLocked,
        build, buildSegments, selectedSegment, splicePoints,
        selectedTool, splicePlanButtonDisabled,
        designAreaDisplayText,
        onDeleteBuild, onLockBuild, onUnlockBuild, onUpdateColor, onResetColor,
        toggleCollapse, unselectBuild
    } = useBulkBuildCard();

    if (!build || build.type !== BuildType.Bulk || selectedTool === ToolType.Modification) {
        return null;
    }

    const icon = editDisabled ? lockedIcon : bulkCableIcon;
    const locations = buildSegments.length + 1;

    return (
        <Card id="bulk-build-info" onCloseClick={unselectBuild} onToggleCollapse={toggleCollapse}>
            <CardHeaderComponent title={BuilderHelper.getBuildDisplayId(build)} tooltip={build.id} icon={icon} />
            <CardCollapsibleComponent id="build-info" label={i18next.t(LocalizationKeys.Build)} collapsed={collapsed}>
                {CardPropertyInline(i18next.t(LocalizationKeys.DesignArea), designAreaDisplayText)}
                {CardPropertyInline(i18next.t(LocalizationKeys.Locations), locations)}
                {CardPropertyInline(i18next.t(LocalizationKeys.SplicePoints), splicePoints ? splicePoints.length : 0)}
                {CardProperty(i18next.t(LocalizationKeys.Color), <ColorSelectorProperty color={OpenlayerStyleFactory.getCableColor(0, build?.cableColorHex).enabled} colorOptions={defaultBuildColors} onUpdate={onUpdateColor} resetEnabled={true} onReset={onResetColor} />)}
                <div className="card-footer">
                    <div className="footer-menu">
                        <Tooltip arrow placement="bottom" title={i18next.t(LocalizationKeys.Delete).toLowerCase()}>
                            <div>
                                <TextButton palette="error" className="negative card-icon-button" disabled={editDisabled} onClick={onDeleteBuild}>
                                    <i className="material-icons">delete</i>
                                </TextButton>
                            </div>
                        </Tooltip>
                        <Tooltip arrow placement="bottom" title={i18next.t(buildLocked ? LocalizationKeys.Unlock : LocalizationKeys.Lock).toLowerCase()}>
                            <div>
                                <TextButton className="card-icon-button" disabled={unlockDisabled} onClick={buildLocked ? onUnlockBuild : onLockBuild}>
                                    {buildLocked ? <i className="material-icons">lock_open</i> : <i className="material-icons">lock</i>}
                                </TextButton>
                            </div>
                        </Tooltip>
                    </div>
                    <BuildActionsMenu build={build} splicePlanDisabled={splicePlanButtonDisabled} bomDisabled buildInfoDisabled={editDisabled} />
                </div>
            </CardCollapsibleComponent>
            <BulkCableCard build={build} segments={buildSegments!} collapsed={collapsed} disabled={editDisabled} />
            {selectedSegment ?
                <SegmentCard segment={selectedSegment} build={build} collapsed={collapsed} disabled={editDisabled} />
                :
                null
            }

        </Card>
    );
};




