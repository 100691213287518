import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ToolType } from '../../design-tools/tooltype';
import { BuildSegment } from '../../models/build-segment';
import { BulkBuild } from '../../models/bulk/bulk-build';
import { DataValue } from '../../models/datavalue';
import { DesignMode } from '../../models/design-mode';
import { SplicePoint } from '../../models/splice-point';
import { Units } from '../../models/units';
import { patchSegment, updateBulkBuild } from '../../redux/build.state';
import { StateModel } from '../../redux/reducers';
import {
    bulkBuildsSelector, designModeSelector, segmentsSelector, splicePointsSelector
} from '../../selectors/root.selectors';

export const SplicePointManager = (): null => {
    const dispatch = useDispatch();

    const splicePoints = useSelector(splicePointsSelector);
    const prevSplicePoints = useRef<SplicePoint[]>([]);
    const prevBuildsSegments = useRef<BuildSegment[]>([]);
    const prevBulkBuilds = useRef<BulkBuild[]>([]);

    const bulkBuilds = useSelector(bulkBuildsSelector);
    const buildSegments = useSelector(segmentsSelector);
    const designMode = useSelector(designModeSelector);
    const { selectedTool } = useSelector((state: StateModel) => state.tool);

    const updateSplicePointSlack = useCallback((splicePoint: SplicePoint, bulkBuilds: BulkBuild[], buildSegments: BuildSegment[], reset?: boolean): void => {
        const segments = buildSegments.filter(s => s.buildId === splicePoint.buildId);
        // If this is a mid-point Splice (not at the last location of the cable), add 100ft to the slack loop of the next segment
        const midSegment = segments?.find(s => s.fromId === splicePoint.elementId);
        if (midSegment) {
            patchSegment({ oldSegment: midSegment, newSegment: { ...midSegment, slackLoop: reset ? 0 : new DataValue(100, Units.feet, 2).toUnit(midSegment.slackUnit).value }, ignoreSelected: true })(dispatch);
        }
        // If this a tail Splice (at the last location of the cable), add 50ft to the field slack of the build
        const lastSegment = segments?.find(s => s.toId === splicePoint.elementId && s.position === segments.length - 1);
        if (lastSegment) {
            const build = bulkBuilds.find(b => b.id === splicePoint.buildId);
            if (build) {
                updateBulkBuild(build, { ...build, fieldSlack: reset ? 0 : new DataValue(50, Units.feet, 2).toUnit(build.slackUnit).value })(dispatch);
            }
        }
    }, [dispatch]);

    const resetBuildSlack = useCallback((build: BulkBuild): void => {
        updateBulkBuild(build, { ...build, fieldSlack: 0 })(dispatch);
    }, [dispatch]);

    const resetSegmentSlack = useCallback((segment: BuildSegment): void => {
        patchSegment({ oldSegment: segment, newSegment: { ...segment, slackLoop: 0 }, ignoreSelected: true })(dispatch);
    }, [dispatch]);

    const resetBuildSegmentsSlackAtSplicePoint = useCallback((builds: BulkBuild[], splicePoint: SplicePoint, buildSegments: BuildSegment[]): void => {
        builds
            .filter(b => b.buildSplice?.parentSplicePointId === splicePoint.id)
            .forEach(b => {
                const segment = buildSegments.find(s => s.buildId === b.id && s.position === 0);
                if (segment) {
                    resetSegmentSlack(segment);
                }
            });
    }, [resetSegmentSlack]);

    const updateSplicedSegmentSlack = useCallback((segment: BuildSegment): void => {
        patchSegment({ oldSegment: segment, newSegment: { ...segment, slackLoop: new DataValue(50, Units.feet, 2).toUnit(segment.slackUnit).value }, ignoreSelected: true })(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (designMode === DesignMode.GISMode) {
            if (splicePoints.length >= prevSplicePoints.current.length && selectedTool === ToolType.SplicePoint) { // Splice Point added by the Splice Point tool
                const newSplicePoint = splicePoints.find(s => !prevSplicePoints.current.includes(s));
                if (newSplicePoint && newSplicePoint.buildId) {
                    updateSplicePointSlack(newSplicePoint, bulkBuilds, buildSegments);
                }
            } else if ((prevSplicePoints.current.length - splicePoints.length) === 1) {
                // Splice points can only be deleted one at a time using the app UI
                // If more than one splice points is deleted, that means the state was cleared and the following does not apply
                const deletedSplicePoint = prevSplicePoints.current.find(prev => !splicePoints.includes(prev));
                if (deletedSplicePoint) {
                    if (deletedSplicePoint.buildId) {
                        updateSplicePointSlack(deletedSplicePoint, bulkBuilds, buildSegments, true);
                    }
    
                    resetBuildSegmentsSlackAtSplicePoint(bulkBuilds, deletedSplicePoint, buildSegments);
                }
            } else if (splicePoints.length === prevSplicePoints.current.length && selectedTool === ToolType.Modification) { // Number of Splice Points has not changed, but a Splice Point has been moved
                const movedSplicePoint = splicePoints.find(s => prevSplicePoints.current.some(prev => prev.id === s.id && (prev.elementId !== s.elementId || (s.buildId && prev.buildId !== s.buildId))));
                if (movedSplicePoint) {
                    // Splice Point was moved
                    updateSplicePointSlack(movedSplicePoint, bulkBuilds, buildSegments);
    
                    const prevSplicePoint = prevSplicePoints.current.find(prev => prev.id === movedSplicePoint.id && !(prev.elementId === movedSplicePoint.elementId && prev.buildId === movedSplicePoint.buildId));
                    if (prevSplicePoint) {
                        // Reset the slack at the previous location
                        updateSplicePointSlack(prevSplicePoint, bulkBuilds, buildSegments, true);
    
                        resetBuildSegmentsSlackAtSplicePoint(bulkBuilds, prevSplicePoint, buildSegments);
                    }
                } else {
                    const segmentUnlinkedFromMidSplicePoint = buildSegments.find(s => prevBuildsSegments.current.some(prev =>
                        s.slackLoop &&
                        prev.id === s.id &&
                        prev.fromId !== s.fromId &&
                        splicePoints.some(sp => sp.buildId === prev.buildId && sp.elementId === prev.fromId)
                    ));
                    if (segmentUnlinkedFromMidSplicePoint) {
                        resetSegmentSlack(segmentUnlinkedFromMidSplicePoint);
                    } else {
                        const segmentUnlinkedFromEndSplicePoint = buildSegments.find(s =>
                            s.position === buildSegments.filter(bs => bs.buildId === s.buildId).length - 1 &&
                            prevBuildsSegments.current.some(prev =>
                                prev.id === s.id &&
                                prev.toId !== s.toId &&
                                splicePoints.some(sp => sp.buildId === prev.buildId && sp.elementId === prev.toId)
                            )
                        );
                        const unlinkedBuild = bulkBuilds.find(b => b.id === segmentUnlinkedFromEndSplicePoint?.buildId);
                        if (unlinkedBuild) {
                            resetBuildSlack(unlinkedBuild);
                        }
                    }
                }
            } else {// Check if any bulk build have been connected to a splice point
                const buildWithBuildSpliceChanged = bulkBuilds.find(b =>
                    prevBulkBuilds.current.some(prev =>
                        prev.id === b.id &&
                        b.buildSplice !== prev.buildSplice
                    )
                );
                if (buildWithBuildSpliceChanged) {
                    const buildSegmentToReset = buildSegments.find(s => s.buildId === buildWithBuildSpliceChanged.id && s.position === 0);
                    if (buildSegmentToReset) {
                        if (buildWithBuildSpliceChanged.buildSplice) {
                            updateSplicedSegmentSlack(buildSegmentToReset);
                        } else {
                            resetSegmentSlack(buildSegmentToReset);
                        }
                    }
                }
            }
        }

        prevSplicePoints.current = splicePoints;
        prevBuildsSegments.current = buildSegments;
        prevBulkBuilds.current = bulkBuilds;
    }, [splicePoints, selectedTool, bulkBuilds, buildSegments, designMode, updateSplicePointSlack, resetBuildSlack, resetSegmentSlack, updateSplicedSegmentSlack, resetBuildSegmentsSlackAtSplicePoint]);

    return null;
};
