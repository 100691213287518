import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { CardComponentProps, CardStateType } from './card.types';

export const useCard = (props: CardComponentProps) => {
    const {
        id,
        className,
        hideToggleCollapse,
        collapseTooltip,
        expandTooltip,
        children,
        onCloseClick,
        onToggleCollapse,
    } = props;

    const [collapsed, setCollapsed] = useState(getThisCardState(id));

    const cardClassName = classNames('card', className, { collapsed: !hideToggleCollapse && !!collapsed && !onToggleCollapse });

    const toggleCollapse = useCallback(() => {
        const newCollapsed = !collapsed;
        setCollapsed(newCollapsed);
        setThisCardState(id, newCollapsed);
        onToggleCollapse && onToggleCollapse(newCollapsed);
    }, [collapsed, id, onToggleCollapse, setCollapsed]);

    return {
        id,
        hideToggleCollapse,
        cardClassName,
        collapsed,
        collapseTooltip,
        expandTooltip,
        children,
        onCloseClick,
        toggleCollapse,
    };
};

function getCardState(): CardStateType {
    return JSON.parse(localStorage.getItem('cardState') || '{}');
}

export function getThisCardState(id: string): boolean {
    const cardState = getCardState();
    return !!cardState[id];
}

export function setThisCardState(id: string, newState: boolean): void {
    const currentState = getCardState();
    localStorage.setItem('cardState', JSON.stringify({
        ...currentState,
        [id]: newState
    }));
}
