import './tap-card.component.scss';

import React from 'react';

import { TextButton } from '@orbit/button';
import { MainPalettes } from '@orbit/theme-provider';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OpticalTether } from '../../models/schrodinger/optical-tether';
import { PowerTether } from '../../models/schrodinger/power-tether';
import { TetherType } from '../../models/schrodinger/tether-type';
import { Card } from '../card/card';
import { CardContentComponent } from '../card/card-content.component';
import { CardHeaderComponent } from '../card/card-header.component';
import { OpticalTetherCard } from '../cards/schrodinger/optical-tether-card/optical-tether-card';
import { PowerTetherCard } from '../cards/schrodinger/power-tether-card/power-tether-card';
import { TooltipLocalizationKeys } from '../../locales/types';
import { useTapCard } from './tap-card.component.hooks';
import { TapCardProps } from './tap-card.component.types';
import { TapDiagram } from './tap-diagram';

export const TapCard = (props: TapCardProps): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        disabled, locationId, selectedTether, selectedTetherNumber, tapCardTitle, tapCardSubtitle,
        unselectTap, deleteSelectedTap
    } = useTapCard(props);

    const tapIcon = disabled ? require('./../../icons/locked.png') : require('./../../icons/schrodinger_tap.png');
    return (
        <>
            <Card id="tap-card" className="tap-card" onCloseClick={unselectTap}>
                <CardHeaderComponent title={tapCardTitle} subheader={tapCardSubtitle} icon={tapIcon} iconBorder={true} />
                <CardContentComponent className="tap-card-diagram">
                    <TapDiagram disabled={disabled} locationId={locationId} />
                    {!selectedTether && (
                        <div className="card-footer">
                            <Tooltip arrow={true} placement="bottom" title={t(TooltipLocalizationKeys.DeleteTap)?.toLowerCase()}>
                                <div>
                                    <TextButton palette={MainPalettes.error} disabled={disabled} onClick={deleteSelectedTap}>
                                        <i className="material-icons">delete</i>
                                    </TextButton>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </CardContentComponent>
            </Card>
            {selectedTether
                ? (selectedTether.type === TetherType.Optical
                    ? <OpticalTetherCard selectedTether={selectedTether as OpticalTether} selectedTetherNumber={selectedTetherNumber} disabled={disabled} />
                    : <PowerTetherCard selectedTether={selectedTether as PowerTether} selectedTetherNumber={selectedTetherNumber} disabled={disabled} />
                )
                : null
            }
        </>
    );
}
