import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CableCalculationsHelper } from '../../../helpers/cable-calculations-helper';
import { LocalizationKeys } from '../../../locales/types';
import { TerminalExtensionType } from '../../../models/terminal-extension-type';
import { StateModel } from '../../../redux/reducers';
import { patchTerminal } from '../../../redux/tap.state';
import { elementsSelector } from '../../../selectors/elements.selectors';
import { pathsSelector } from '../../../selectors/paths.selectors';
import { portCounts, TerminalCardProps } from './terminal-card.types';

export const useTerminalCard = (props: TerminalCardProps) => {
    const { t } = useTranslation();

    const elements = useSelector(elementsSelector);
    const paths = useSelector(pathsSelector);
    const displayUnits = useSelector((state: StateModel) => state.authentication.displayUnits);

    const { disabled, tether, onAddTerminal, onDeleteTerminal } = props;
    const workspace = useSelector((state: StateModel) => state);

    const selectedTerminalId = workspace.selection.selectedTerminalId;
    const selectedTerminalTether = selectedTerminalId
        ? workspace.taps.tethers.find((t) => t.terminal?.id === selectedTerminalId)
        : undefined;
    const selectedTerminal = selectedTerminalTether?.terminal;

    const [lengthIsValid, setLengthIsValid] = useState(true);
    const [portCount, setPortCount] = useState(-1);
    const [matchTetherFiberCount, setMatchTetherFiberCount] = useState(true);
    const [isLongtail, setIsLongtail] = useState(false);
    const [calculatedSpan, setCalculatedSpan] = useState(0);
    const [measuredSpan, setMeasuredSpan] = useState<number | undefined>(undefined);
    const [availableCpqLengths, setAvailableCpqLengths] = useState([selectedTerminal?.length ?? 0]);
    const allowedPortCounts = selectedTerminalTether ? portCounts.filter(item => item.value >= selectedTerminalTether.fiberCount || (item.value === 10 && selectedTerminalTether.fiberCount === 12)) : portCounts;

    const selectedTerminalExtensionType = selectedTerminal?.terminalExtension?.terminalExtensionType;
    const selectedTerminalExtensionSegments = selectedTerminal?.terminalExtension?.segments;

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedTerminal) {
            setLengthIsValid(true);
            setPortCount(Math.min(selectedTerminal.portCount, 12));
            setMatchTetherFiberCount(selectedTerminal.matchTetherFiberCount);
        }
    }, [selectedTerminal]);

    useEffect(() => {
        setIsLongtail(selectedTerminalExtensionType === TerminalExtensionType.LongTail);
    }, [selectedTerminalExtensionType]);

    useEffect(() => {
        if (isLongtail && selectedTerminalExtensionSegments) {
            let calculatedSpan = 0, measuredSpan = 0;
            let hasAMeasuredSpan = false;
            selectedTerminalExtensionSegments.forEach(s => {
                const segmentLength = CableCalculationsHelper.calculateSegmentSpan(s, elements, paths)?.toUnit(displayUnits).value;
                calculatedSpan += segmentLength ?? 0;
                if (s.measuredSpan) {
                    measuredSpan += s.measuredSpan;
                    hasAMeasuredSpan = true;
                } else {
                    measuredSpan += segmentLength ?? 0;
                }
            });

            setCalculatedSpan(calculatedSpan);
            setMeasuredSpan(hasAMeasuredSpan ? measuredSpan : undefined);
        }
    }, [isLongtail, selectedTerminalExtensionSegments, displayUnits, elements, paths]);

    useEffect(() => {
        if (workspace.cpq.multiportParts) {
            const minimumCpqLength = measuredSpan ?? calculatedSpan;
            setAvailableCpqLengths(workspace.cpq.multiportParts.filter(mp => mp.portCount === portCount && mp.length > minimumCpqLength).map(mp => mp.length).distinct());
        }
    }, [portCount, calculatedSpan, measuredSpan, workspace.cpq.multiportParts]);

    const validateLength = useCallback((value: any): boolean => {
        const valid = parseFloat(value) >= 0;
        setLengthIsValid(valid);
        return valid;
    }, []);

    const handleInputChange = useCallback((value: any) => {
        if (!selectedTerminal) {
            throw new Error('handleInputChange, changing value for non-existent terminal');
        }
        const length = parseFloat(value);
        if (!validateLength(length)) {
            return;
        }
        patchTerminal(selectedTerminal, { ...selectedTerminal, length })(dispatch);
    }, [dispatch, selectedTerminal, validateLength]);

    const handleSliderChange = useCallback((_: Event | undefined, value: number | number[]) => {
        if (typeof value === 'number') {
            const portCount = value < 10 ? value : 12;
            setPortCount(portCount);
        }
    }, []);

    const commitSliderChanges = useCallback(() => {
        if (!selectedTerminal) {
            throw new Error('Terminal commit changes without terminal');
        }
        patchTerminal(selectedTerminal, { ...selectedTerminal, portCount })(dispatch);
    }, [dispatch, selectedTerminal, portCount]);

    const valueLabelFormat = useCallback((value: number): React.ReactNode => {
        return value < 10 ? value : '12';
    }, []);

    const handleCheckboxChange = useCallback((event: React.ChangeEvent<any>) => {
        const checked = event.target.checked;
        setMatchTetherFiberCount(checked);
        let terminalPatchMessage = '';

        if (!selectedTerminal) {
            throw new Error('Terminal commit changes without terminal');
        }
        let newPortCount = portCount;
        if (checked) {
            newPortCount = selectedTerminalTether ? selectedTerminalTether.fiberCount : portCount;
            handleSliderChange(undefined, newPortCount);
            terminalPatchMessage = t(LocalizationKeys.TerminalPortUpdateMessageMatchTetherPortCount);
        }
        patchTerminal(selectedTerminal, { ...selectedTerminal, matchTetherFiberCount: checked, portCount: newPortCount }, terminalPatchMessage)(dispatch);
    }, [dispatch, handleSliderChange, t, selectedTerminalTether, selectedTerminal, portCount]);

    const handleAddTerminal = useCallback(() => {
        if (onAddTerminal) {
            if (tether && tether.id) {
                onAddTerminal(tether.id);
            }
        }
    }, [onAddTerminal, tether]);

    const handleDeleteTerminal = useCallback(() => {
        if (onDeleteTerminal && tether && tether.terminal) {
            onDeleteTerminal(tether.terminal);
        }
    }, [onDeleteTerminal, tether]);

    return {
        disabled, selectedTerminal, allowedPortCounts, portCount, calculatedSpan, measuredSpan, lengthIsValid, matchTetherFiberCount, isLongtail, availableCpqLengths, displayUnits, validateLength, handleInputChange, handleSliderChange, commitSliderChanges, handleCheckboxChange, valueLabelFormat,
        handleAddTerminal, handleDeleteTerminal
    };
}