import './splice-plan-setup-dialog.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@orbit/theme-provider';

import { LocalizationKeys } from '../../../locales/types';
import { fiberAssignments, FiberAssignmentType } from '../../../models/fiber-assignment-type';
import { CardObjectSelect } from '../../card/card-controls';
import { DnaSpinner } from '../../ui-elements/dna-spinner';
import { EditText } from '../../ui-elements/edit-text';
import { DialogComponent } from '../dialog.component';
import { useSplicePlanSetupDialog } from './splice-plan-setup-hooks';

export const SplicePlanSetupDialog = (): JSX.Element | null => {
    const { t } = useTranslation();
    const {
        isOpen,
        positiveTitle,
        isValid,
        onCancel,
        onClose,
        onChange,
        onPositiveClick,
        startingPort,
        fiberCount,
        fiberAssignment,
        buildId,
        buildIdRequestingCabinetSplicePlan
    } = useSplicePlanSetupDialog();

    const hasNoCabinetContent = (
        <>
            {CardObjectSelect({
                label: t(LocalizationKeys.FiberAssignment),
                name: 'fiberAssignment',
                value: fiberAssignment || FiberAssignmentType.HighToLowCoToField,
                values: fiberAssignments,
                onChange: () => void 0,
                valueProp: "value",
                displayProp: "label",
                disabled: true,
            })}
            <EditText
                type="text"
                name="starting-port"
                label={t(LocalizationKeys.SplicePlanPortStartWith)}
                value={startingPort}
                onChange={onChange}
                error={!isValid}
                helperText={isValid ? undefined : t(LocalizationKeys.SplicePlanPortStartError, { fiberCount })}
            />
        </>
    );

    const hasCabinetContent = (
        <>
            <Typography variant={'body1'} label={t(LocalizationKeys.GoToCabinetSplicePlanMessage)} />
        </>
    );

    const isLoadingContent = (
        <div className='spinner'>
            <DnaSpinner />
        </div>
    )

    return (
        <DialogComponent
            open={isOpen}
            className="dialog splice-plan-setup-dialog"
            onClose={onClose}
            title={t(LocalizationKeys.SplicePlanSetup)}
            positiveTitle={positiveTitle}
            onPositiveClick={onPositiveClick}
            disablePositive={!isValid}
            negativeTitle={t(LocalizationKeys.Cancel)}
            onNegativeClick={onCancel}
            hideCloseButton
        >
            {
                buildIdRequestingCabinetSplicePlan === undefined ?
                    (isLoadingContent) :
                    buildIdRequestingCabinetSplicePlan === buildId ?
                        (hasCabinetContent) :
                        (hasNoCabinetContent)
            }
        </DialogComponent>
    )
}
