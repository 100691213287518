import memoize from 'memoize-one';
import React from 'react';

import { AnalyticsAction, AnalyticsHelper } from '../../helpers/analytics-helper';
import { PartHelper } from '../../helpers/part-helper';
import { CpqCablePart } from '../../models/cpqcablepart';
import { CpqInstallationType } from '../../models/cpqinstallationtype';
import { CpqTetherPart } from '../../models/cpqtetherpart';
import { UserPreset } from '../../models/userpreset';
import { updatePreset } from '../../redux/preset.state';
import { connect, StateModel } from '../../redux/reducers';
import { NotificationService } from '../../services/notification.service';
import { CableValues, FiberCountComponent, FiberCountData } from './fiber-count.component';
import { EditorMode, PresetPropertiesEditorComponent } from './preset-properties-editor.component';
import { PresetComponent } from './preset.component';
import { CardInputWithCount } from '../card/card-controls';
import { CardInputMaxLength } from '../card/card.types';

interface EditPresetProps {
    preset: UserPreset;
    onCancel?: () => void;
}

interface EditPresetState {
    presetName: string;
    showValidation: boolean;
    cableValues?: CableValues;
}

const mapStateToProps = (state: StateModel) => {
    const { displayUnits } = state.authentication;
    const { cableParts, tetherParts, configurations, installationTypes } = state.cpq;
    return { units: displayUnits, cableParts, tetherParts, configurations, installationTypes };
};

const mapDispatchToProps = {
    updatePreset,
};

type props = EditPresetProps & Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditPresetComponent extends React.Component<props, EditPresetState> {

    constructor(props: EditPresetProps) {
        super(props);
        this.state = {
            presetName: props.preset.name,
            showValidation: false,
            cableValues: undefined,
        };
    }

    private handleInputChange = (event: React.ChangeEvent<any>): void => {
        const name = event.target.name;
        const value = event.target.value;
        if (!name) {
            throw new Error('handleInputChange needs a name');
        }
        if (value?.length <= CardInputMaxLength.PRESET_NAME) {
            this.setState({ [name]: value } as any as EditPresetState);
        }
    }

    private showValidation = (cableValues: CableValues): void => {
        this.setState({ showValidation: true, cableValues });
    }

    private onSavePreset = (newPreset: UserPreset, isDefault: boolean): void => {
        AnalyticsHelper.pushSelectContentEvent(AnalyticsAction.savePresets);

        const { preset } = this.props;
        if (!preset) {
            throw new Error('Update preset without a selected preset');
        }
        if (preset.name && preset.name.trim().length !== 0 && preset.name.length <= CardInputMaxLength.PRESET_NAME) {
            newPreset.isDefault = isDefault;
            if (preset.id > -1) {
                this.props.updatePreset!(preset, newPreset);
                if (this.props.onCancel) {
                    this.props.onCancel();
                }
            }
        }
    }

    private createPreset = (cablePart: string, tetherPart: string): UserPreset | null => {
        const { cableValues, presetName } = this.state;
        if (!cableValues) {
            return null;
        }
        const { preset, units, cableParts, tetherParts, configurations } = this.props;
        const installationType = preset.installationType;
        const partNumber = cablePart.slice(12);
        let configurationWithParts;
        try {
            configurationWithParts =
                PartHelper.getConfigurationWithParts(
                    partNumber, tetherPart,
                    +cableValues.fiberCount, +cableValues.tetherFiberCount,
                    cableParts!, tetherParts!, configurations!,
                    installationType);
        } catch (ex) {
            if (ex?.message) {
                NotificationService.error(ex.message);
            }
            return null;
        }
        const { part, tether, configuration } = configurationWithParts;
        return {
            id: preset.id,
            name: presetName || '',
            coSlack: cableValues.coSlack,
            fieldSlack: cableValues.fieldSlack,
            slackUnit: units!,
            isDefault: preset.isDefault,
            cablePart: part.part,
            tetherPart: tether.tether_Item,
            cableDescription: JSON.stringify(PartHelper.buildPartDescription(configuration)),
            tetherDescription: JSON.stringify(PartHelper.buildTetherPartDescription(tether)),
            installationType,
            userId: preset.userId,
        };
    }

    // memoize will only execute if props/state/arguments have changed or else it will use last values
    private createFiberCountData = memoize((preset: UserPreset, parts: CpqCablePart[], tetherParts: CpqTetherPart[], installationTypes: CpqInstallationType[]): FiberCountData => {
        const { fiberCounts, tetherFiberCounts } = PartHelper.getPartFiberCounts(parts, tetherParts, preset.cablePart, preset.tetherPart, preset.installationType);
        const tether = tetherParts.find((t) => t.tether_Item === preset.tetherPart && t.installation_Type === preset.installationType);
        const part = parts.find((c) => c.part === preset.cablePart);
        const installationType = installationTypes.find((i) => i.description === preset.installationType) ?? installationTypes[0];
        return {
            coSlack: preset.coSlack, fieldSlack: preset.fieldSlack, slackUnit: preset.slackUnit,
            fiberCount: part?.fiber_Count, tetherFiberCount: tether?.fiber_Count,
            fiberCounts, tetherFiberCounts, installationType
        };
    });

    private renderValidation = (): JSX.Element | null => {
        const { preset, units } = this.props;
        const newPreset = this.createPreset(preset.cablePart, preset.tetherPart);
        if (!newPreset) {
            this.setState({ showValidation: false });
            return null;
        }
        return (
            <PresetPropertiesEditorComponent
                mode={EditorMode.Editing}
                preset={newPreset}
                displayUnits={units!}
                disableDefault={preset.isDefault}
                onCancel={this.props.onCancel}
                onSavePreset={this.onSavePreset}
            />
        );
    }

    private renderFiberCount = (): JSX.Element => {
        const { preset, units, cableParts, tetherParts, installationTypes } = this.props;
        const data = this.createFiberCountData(preset, cableParts!, tetherParts!, installationTypes!);
        return (
            <FiberCountComponent
                data={data}
                displayUnits={units!}
                currentStepIndex={0}
                onPreviousClicked={this.props.onCancel}
                onNextClicked={this.showValidation}
            />
        );
    }

    private renderEditComponent = (): JSX.Element | null => {
        const { showValidation } = this.state;
        if (showValidation) {
            return this.renderValidation();
        }
        else {
            return this.renderFiberCount();
        }
    }

    public render(): JSX.Element {
        const { presetName } = this.state;
        const isPresetNameValid = presetName && presetName.trim().length !== 0;
        return (
            <PresetComponent title="Edit cable preset">
                {CardInputWithCount({
                    error: !isPresetNameValid,
                    helperText: isPresetNameValid ? undefined : "Preset needs a name",
                    label: "Preset Name",
                    value: presetName,
                    name: 'presetName',
                    maxLength: CardInputMaxLength.PRESET_NAME,
                    onChange: this.handleInputChange,
                })}
                {this.renderEditComponent()}
            </PresetComponent>
        );
    }
}
