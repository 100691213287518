import './dna.scss';

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { CTCMThemeProvider } from '@orbit/theme-provider';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { LoginComponent } from './components/login/login.component';
import { OpenIdCallbackComponent } from './components/login/openid-callback.component';
import { MainPageComponent } from './components/main-page';
import { SecureRoute } from './components/secure-route.component';
import reducers from './redux/reducers';

const middleware = getDefaultMiddleware();
export const store = configureStore({
    reducer: reducers,
    middleware,
});
const queryClient = new QueryClient();

export class DNA extends React.Component {
    public render = (): JSX.Element => (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                <Router>
                    <Switch>
                        <Route path="/login" component={LoginComponent} />
                        <Route path="/oid_login" component={OpenIdCallbackComponent} />
                        <Route path="/oid_logout" component={LoginComponent} />
                        <SecureRoute strict={true} path="/">
                            <CTCMThemeProvider>
                                <MainPageComponent />
                            </CTCMThemeProvider>
                        </SecureRoute>
                    </Switch>
                </Router>
            </Provider>
        </QueryClientProvider>
    )
}
