import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocalizationKeys } from '../../../locales/types';
import { BasePreset } from '../../../models/base-preset';
import { BuildType } from '../../../models/build-type';
import { SchrodingerPreset } from '../../../models/schrodinger-preset';
import { UserPreset } from '../../../models/userpreset';
import CorningRoles from '../../../redux/corning-roles.json';
import { selectDefaultPreset, selectPreset, setDefaultPreset } from '../../../redux/preset.state';
import { StateModel } from '../../../redux/reducers';
import {
    selectDefaultPreset as selectDefaultSchrodingerPreset, selectPreset as selectSchrodingerPreset,
    updatePreset as updateSchrodingerPreset
} from '../../../redux/schrodinger-preset.state';
import { PresetConfigMode, PresetManagerContext, PresetTab } from '../preset-manager.types';
import { PresetType } from './presets-list.types';

const presetCompareFn = (a: BasePreset, b: BasePreset): number => {
    if (a.isDefault) {
        return -1;
    }
    else if (b.isDefault) {
        return 1;
    }
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};

export const usePresetsList = () => {
    const { t } = useTranslation();
    const { setSelectedTab, updateConfigMode } = useContext(PresetManagerContext);

    const workspace = useSelector((state: StateModel) => state);
    const { presets, selectedPreset } = workspace.preset;
    const { presets: schrodingerPresets, selectedPreset: selectedSchrodingerPreset } = workspace.schrodingerpreset;
    const { roles } = workspace.role;
    const buildType = roles && (roles.some((role) => role === CorningRoles.Schrodinger)) ? BuildType.Schrodinger : BuildType.FlexNap;
    const selectedPresetId = selectedPreset?.id;
    const selectedSchrodingerPresetId = selectedSchrodingerPreset?.id;

    const [presetTab, setPresetTab] = useState<PresetTab>();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [sortedPresets, setSortedPresets] = useState<UserPreset[]>([]);
    const [sortedSchrodingerPresets, setSortedSchrodingerPresets] = useState<SchrodingerPreset[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setSortedPresets(presets.slice().sort(presetCompareFn));
    }, [presets]);

    useEffect(() => {
        setSortedSchrodingerPresets(schrodingerPresets.slice().sort(presetCompareFn));
    }, [schrodingerPresets]);

    useEffect(() => {
        let tab: PresetTab;
        if (!roles?.length) {
            console.warn(`presets-list.hooks: No Designer Roles found`);
            return;
        }
        if (buildType === BuildType.FlexNap) {
            tab = { type: PresetType.FlexNap, label: t(LocalizationKeys.Cable) };
        } else {
            tab = { type: PresetType.Schrodinger, label: t(LocalizationKeys.Cable) };
        }
        setPresetTab(tab);
        setActiveIndex(0);
        setSelectedTab(tab.type ?? PresetType.FlexNap);
    }, [t, roles, setSelectedTab, buildType]);

    const handleSelectedPreset = useCallback((id: number): void => {
        switch (buildType) {
            case BuildType.FlexNap:
                dispatch(selectPreset(presets.find((p) => p.id === id)));
                break;
            case BuildType.Schrodinger:
                dispatch(selectSchrodingerPreset(schrodingerPresets.find((p) => p.id === id)));
                break;
            default:
                dispatch(selectPreset());
                dispatch(selectSchrodingerPreset());
                break;
        }
    }, [buildType, dispatch, presets, schrodingerPresets]);

    const handleActiveIndex = useCallback((event: React.SyntheticEvent<Element, Event>, value: any): void => {
        setActiveIndex(value);
        const tab = presetTab;
        if (!tab) {
            return;
        }
        setSelectedTab(tab.type);
        switch (tab.type) {
            case PresetType.FlexNap:
                dispatch(selectDefaultPreset());
                dispatch(selectSchrodingerPreset());
                break;
            case PresetType.Schrodinger:
                dispatch(selectPreset());
                dispatch(selectDefaultSchrodingerPreset());
                break;
            default:
                dispatch(selectPreset());
                dispatch(selectSchrodingerPreset());
                break;
        }
        updateConfigMode();
    }, [dispatch, presetTab, setSelectedTab, updateConfigMode]);

    const handleNewPreset = useCallback(() => {
        const tab = presetTab;
        if (!tab) {
            return;
        }
        switch (tab.type) {
            case PresetType.FlexNap:
                dispatch(selectPreset());
                updateConfigMode(PresetConfigMode.CreateFlexNap)
                break;
            case PresetType.Schrodinger:
                dispatch(selectSchrodingerPreset());
                updateConfigMode(PresetConfigMode.CreateSchrodinger);
                break;
            default:
                dispatch(selectPreset());
                dispatch(selectSchrodingerPreset());
                updateConfigMode();
                break;
        }
    }, [dispatch, presetTab, updateConfigMode]);

    const handleOnSetAsDefault = useCallback((id: number) => {
        if (!presetTab) {
            return;
        }
        switch (presetTab.type) {
            case PresetType.FlexNap:
                setDefaultPreset(id)(dispatch);
                break;
            case PresetType.Schrodinger: {
                const preset = schrodingerPresets.find((p) => p.id === id);
                if (preset) {
                    const request = {
                        isDefault: true,
                        coSlack: preset.coSlack,
                        fieldSlack: preset.fieldSlack,
                        presetName: preset.name
                    };
                    updateSchrodingerPreset(id, request)(dispatch);
                }
                break;
            }
            default:
                break;
        }
    }, [dispatch, schrodingerPresets, presetTab]);

    return {
        presetTab: presetTab, activeIndex, selectedPresetId, selectedSchrodingerPresetId,
        presets: sortedPresets, schrodingerPresets: sortedSchrodingerPresets,
        onNewPreset: handleNewPreset, handleSelectedPreset, handleActiveIndex, handleOnSetAsDefault
    };
};